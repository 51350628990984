import type { Location } from 'history';
import { useState } from 'react';
import { useLocation } from 'react-router';

import { checkPermission } from '@zen/Auth/authHelper';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Pagination, useTableConfiguration } from '@zen/DesignSystem';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import { useSubscriptionContext } from '@zen/Shipments/contexts/SubscriptionContext';
import { SortingOrder, SortInput } from '@zen/types';
import useUrlPagination from '@zen/utils/hooks/pagination/useUrlPagination';
import useAccount from '@zen/utils/hooks/useAccount';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import NoResults from './components/NoResults';
import NoShipments from './components/NoShipments';
import Filters from './Filters';
import { initialFilters, prepareFilterVariables } from './Filters/helpers';
import {
  GetShipmentsQueryResult,
  GetShipmentsQueryVariables,
  useControlTowerPermissionsQuery,
  useGetShipmentsQuery,
  useShipmentsCountQuery
} from './graphql';
import { prepareFlatShipments } from './helpers';
import ShipmentsDataHeader from './ShipmentsDataHeader';
import SubscriptionIndicator from './SubscriptionIndicator';
import TableView from './TableView';
import { getColumns } from './TableView/tableConfiguration';
import type { FlatShipment, Shipment, ShipmentFilters, ShipmentsListCapabilities } from './types';

export const shipmentsPathPrefix = '/dashboard/shipments';

const initialOrder: SortInput = {
  field: 'createdAt',
  direction: SortingOrder.ASC
};

const Shipments = () => {
  const { accountUuid: accountId } = useAccount();
  const { showSubscribed } = useSubscriptionContext();
  const { filters, search } = useUrlFilters<ShipmentFilters>(initialFilters);
  const tableLocation: Location = useLocation();
  const { hiddenColumns, tableId } = useTableConfiguration();
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const {
    data,
    loading: totalCountLoading,
    error: totalCountError
  } = useShipmentsCountQuery({ variables: { ...(accountId && { customerUuid: accountId }) } });
  const hasShipment: boolean = !!(data?.bookings.totalCount || 0);
  const results = useUrlPagination<GetShipmentsQueryResult, GetShipmentsQueryVariables, Shipment>(
    useGetShipmentsQuery,
    'bookings',
    {
      ...(accountId && { customerUuid: accountId }),
      ...prepareFilterVariables(filters),
      showSubscribed,
      order,
      textContains: search
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' },
    20
  );

  const { nodes, paginationInfo, totalCount, loading, error } = results;

  const { data: controlTowerPermissions } = useControlTowerPermissionsQuery({
    variables: { uuid: accountId },
    skip: !accountId
  });

  const controlTower = controlTowerPermissions?.account?.controlTower;
  const canViewForwarder = controlTower
    ? checkPermission<typeof controlTower>(controlTower, 'canViewForwardersOnShipments')
    : false;
  const capabilities: ShipmentsListCapabilities = { canViewForwarder };

  const columns = getColumns({ capabilities, tableLocation });

  return (
    <Page filterSwitcher={<SubscriptionIndicator />} title="Shipments">
      <QueryHandler data={hasShipment} error={!!totalCountError} isLoading={totalCountLoading} noResults={<NoShipments />}>
        {() => (
          <>
            <Filters isOpened={isFiltersOpen} onToggle={setIsFiltersOpen} />
            <ShipmentsDataHeader capabilities={capabilities} />
            <QueryHandler
              data={nodes}
              error={!!error}
              isLoading={loading}
              loadingComponent={
                <SkeletonTableLoading columns={columns} hiddenColumns={hiddenColumns} order={order} tableId={tableId} />
              }
              noResults={<NoResults onButtonClick={() => setIsFiltersOpen(true)} />}
            >
              {(shipments: Shipment[]) => {
                const flatShipments: FlatShipment[] = prepareFlatShipments(shipments);

                return (
                  <>
                    <TableView
                      columns={columns}
                      onOrderChange={setOrder}
                      order={order}
                      shipments={flatShipments}
                      totalCount={totalCount}
                    />
                    <Pagination pageInfo={paginationInfo} />
                  </>
                );
              }}
            </QueryHandler>
          </>
        )}
      </QueryHandler>
      <OrderDetailsContainer originPath="/dashboard/shipments" />
    </Page>
  );
};

export default Shipments;
