import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTradelanesQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
}>;

export type GetTradelanesQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & {
      tradeLanes?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'TradeLane' } & Pick<GraphQLTypes.TradeLane, 'hashValue' | 'importExport'> & {
              origin?: GraphQLTypes.Maybe<
                | ({ __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'name' | 'label'>)
                | ({ __typename?: 'Warehouse' } & Pick<GraphQLTypes.Warehouse, 'address' | 'countryCode' | 'postalCode'>)
              >;
              destination?: GraphQLTypes.Maybe<
                | ({ __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'name' | 'label'>)
                | ({ __typename?: 'Warehouse' } & Pick<GraphQLTypes.Warehouse, 'address' | 'countryCode' | 'postalCode'>)
              >;
            }
        >
      >;
    }
  >;
};

export const GetTradelanesDocument = /* #__PURE__ */ gql`
  query getTradelanes($accountUuid: String!) {
    account(uuid: $accountUuid) {
      tradeLanes {
        hashValue
        importExport
        origin {
          ... on Warehouse {
            address
            countryCode
            postalCode
          }
          ... on Terminal {
            unlocode
            name
            label
          }
        }
        destination {
          ... on Warehouse {
            address
            countryCode
            postalCode
          }
          ... on Terminal {
            unlocode
            name
            label
          }
        }
      }
    }
  }
`;

/**
 * __useGetTradelanesQuery__
 *
 * To run a query within a React component, call `useGetTradelanesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradelanesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradelanesQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *   },
 * });
 */
export function useGetTradelanesQuery(
  baseOptions: Apollo.QueryHookOptions<GetTradelanesQueryResult, GetTradelanesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTradelanesQueryResult, GetTradelanesQueryVariables>(GetTradelanesDocument, options);
}
export function useGetTradelanesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTradelanesQueryResult, GetTradelanesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTradelanesQueryResult, GetTradelanesQueryVariables>(GetTradelanesDocument, options);
}
export type GetTradelanesQueryHookResult = ReturnType<typeof useGetTradelanesQuery>;
export type GetTradelanesLazyQueryHookResult = ReturnType<typeof useGetTradelanesLazyQuery>;
