import type { FC } from 'react';

import LabelledValue from '@zen/Components/LabelledValue';
import { formatDate, formatTimeRange } from '@zen/utils/dateTime';

import type { DateWithTimeRange } from '../../types';

interface Props {
  dateLabel: string;
  dateWithTimeRange: DateWithTimeRange;
  timeLabel: string;
}

const DateWithTimeRangePresenter: FC<Props> = ({ dateWithTimeRange, dateLabel, timeLabel }) => {
  const { date, startTime, endTime } = dateWithTimeRange;

  return (
    <div className="flex" data-testid="date-with-time-range-presenter">
      <LabelledValue className="w-24" label={dateLabel}>
        {date ? formatDate(date) : '-'}
      </LabelledValue>
      <LabelledValue label={timeLabel}>{startTime ? formatTimeRange(startTime, endTime, false) : '-'}</LabelledValue>
    </div>
  );
};

export default DateWithTimeRangePresenter;
