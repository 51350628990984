import { upperFirst } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import { formatDate } from '@zen/utils/dateTime';

import { ActionItem, ActivityFeedItemTypeEnum, ActivityFeedUser } from '../../types';
import ActivityFeedItemDetails from '../ActivityFeedItemDetails/ActivityFeedItemDetails';

const DATE_FORMAT = 'h:mma';

const getOverdueText = (dueDate: string): string => {
  const diffInDays: number = moment().diff(dueDate, 'days');

  if (diffInDays <= 0) {
    return `Deadline is ${formatDate(dueDate)}`;
  }

  return `Deadline was ${formatDate(dueDate)}.
    ${diffInDays} ${pluralize('day', diffInDays)} overdue`;
};

interface ConfigItemMap {
  [key: string]: {
    className: string;
    icon: IconName;
    subtitle?: string;
    title: string;
  };
}

interface Props {
  actionItem: ActionItem;
  actionItemType: ActivityFeedItemTypeEnum;
  createdAt: string;
  user?: ActivityFeedUser;
}

const ActionItemDetails: FC<Props> = (props) => {
  const { actionItem, actionItemType, createdAt, user = undefined } = props;
  const { dueDate, resolvedAt, title } = actionItem;

  const actionItemWithDetailsConfig: ConfigItemMap = {
    [ActivityFeedItemTypeEnum.APPROVED_ACTION_ITEM]: {
      icon: 'zicon-tickoval',
      className: 'text-green-dark',
      title,
      subtitle: 'approved'
    },
    [ActivityFeedItemTypeEnum.CANCELLED_ACTION_ITEM]: {
      icon: 'zicon-warning-triangle',
      className: 'text-red-dark',
      title,
      subtitle: 'cancelled'
    },
    [ActivityFeedItemTypeEnum.RESOLVED_ACTION_ITEM]: {
      icon: 'zicon-tickoval',
      className: 'text-green-dark',
      title,
      subtitle: 'resolved'
    },
    [ActivityFeedItemTypeEnum.ADDED_ACTION_ITEM]: {
      icon: 'zicon-warning-triangle',
      className: 'text-orange-base',
      title,
      subtitle: getOverdueText(dueDate)
    }
  };

  const currentActionItem = actionItemWithDetailsConfig[actionItemType];
  const { icon, className, title: itemTitle } = currentActionItem;
  const actionItemTitle = `Please ${itemTitle}`;

  const getSubtitle = (): ReactNode => {
    // Not using resolvedAt as it has only date without time
    const dateTime = moment(createdAt).format(DATE_FORMAT);
    let text: string = upperFirst(`${currentActionItem.subtitle} at ${dateTime}`);

    if (actionItemType === ActivityFeedItemTypeEnum.ADDED_ACTION_ITEM) {
      text = getOverdueText(dueDate);
    }

    return <div className={className}>{text}</div>;
  };

  return (
    <ActivityFeedItemDetails
      createdAt={createdAt}
      icon={<Icon className={className} icon={icon} />}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
      resolvedAt={resolvedAt}
      subtitle={getSubtitle()}
      title={actionItemTitle}
      user={user}
    />
  );
};

export default ActionItemDetails;
