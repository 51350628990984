import type { MarkerType } from './Marker';
import type { SuperclusterPointFeature } from './types';

// we need to fit bounds when the marker list has changed
// so instead of extending marker type with a required `id` prop
// we use lat and lng of the markers
export const getMarkerLocations = (markers: MarkerType[]): string => {
  return markers.reduce((prev, { lat, lng }) => `${prev}${[lat, lng].join(',')}|`, '|');
};

export const getClusterAttributes = (
  clusterLeaves: SuperclusterPointFeature<MarkerType>[]
): Pick<MarkerType, 'color' | 'isHighlighted'> => {
  const isHighlighted: boolean = clusterLeaves.some(({ properties }) => properties.isHighlighted);
  const { color } = clusterLeaves[0].properties;

  return {
    color,
    isHighlighted
  };
};

export const preparePoints = (markers: MarkerType[]): SuperclusterPointFeature<MarkerType>[] => {
  return markers.map((marker: MarkerType, index: number) => {
    return {
      type: 'Feature',
      properties: {
        cluster: false,
        index,
        ...marker
      },
      geometry: {
        type: 'Point',
        coordinates: [marker.lng, marker.lat]
      }
    };
  });
};
