import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import LoadingButton from '@zen/Components/LoadingButton';
import type { ButtonType, ButtonVariant } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import type { ButtonClick } from '@zen/types';

import type { FormButtonsLayout } from './types';

interface Props {
  children?: ReactNode;
  className?: string;
  containerClassName?: string;
  iconLeft?: IconName;
  isDisabled?: boolean;
  isSubmitting: boolean;
  layout?: FormButtonsLayout;
  onClick?: (e: ButtonClick) => void;
  text?: string;
  type?: ButtonType;
  variant?: ButtonVariant;
}

const FormButtons: FC<Props> = (props) => {
  const {
    children = null,
    className = '',
    containerClassName,
    iconLeft,
    isDisabled = false,
    isSubmitting,
    layout = 'static',
    onClick = undefined,
    text = 'Submit',
    type = 'submit',
    variant = 'primary'
  } = props;

  const containerClassNames: string = cx(
    {
      'my-6': !containerClassName && layout !== 'fixed',
      'fixed bottom-0 left-0 right-0 rounded-b bg-white flex-row-reverse space-x-reverse border-t border-solid border-grey-lighter py-4 px-6':
        layout === 'fixed'
    },
    'flex space-x-4 mx-0',
    containerClassName
  );

  return (
    <div className={containerClassNames} data-testid="form-buttons">
      <LoadingButton
        className={className}
        data-testid="submit-button"
        iconLeft={iconLeft}
        isDisabled={isSubmitting || isDisabled}
        isInProgress={isSubmitting}
        onClick={onClick}
        text={text}
        type={type}
        variant={variant}
      />
      {children}
    </div>
  );
};

export default FormButtons;
