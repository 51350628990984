import type { FC } from 'react';

import { Select } from '@zen/DesignSystem';
import { incotermsOptions } from '@zen/Shipment/helpers';
import type { IncotermsValue } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { useUpdateIncotermsMutation } from '../graphql';

interface Props {
  canUpdateIncoterms: boolean;
  incoterms: IncotermsValue;
  refetchShipmentDetails: () => void;
  zencargoReference: string;
}

const IncotermsCargoInfoInput: FC<Props> = ({ incoterms, zencargoReference, refetchShipmentDetails, canUpdateIncoterms }) => {
  const { addSuccess, addError } = useNotification();
  const [updateIncoterms] = useUpdateIncotermsMutation();

  const handleUpdateIncoterms = async (incotermsValue: Nullable<IncotermsValue>): Promise<void> => {
    if (incotermsValue) {
      await performMutation({
        mutationFn: () =>
          updateIncoterms({
            variables: {
              input: {
                zencargoReference,
                incoterms: incotermsValue
              }
            }
          }),
        onError: () => addError(),
        onSuccess: () => {
          refetchShipmentDetails();
          addSuccess('Incoterms updated.');
        }
      });
    }
  };

  return (
    <Select
      className="w-28"
      isDisabled={!canUpdateIncoterms}
      name="incoterms"
      onChange={handleUpdateIncoterms}
      options={incotermsOptions}
      overLabel="Incoterms"
      value={incoterms}
    />
  );
};

export default IncotermsCargoInfoInput;
