import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetBillingDetailsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetBillingDetailsQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'uuid'> & {
        billingDetails?: GraphQLTypes.Maybe<
          { __typename?: 'BillingDetails' } & Pick<
            GraphQLTypes.BillingDetails,
            'firstName' | 'lastName' | 'email' | 'phone' | 'street' | 'country' | 'city' | 'postalCode' | 'state'
          >
        >;
      }
  >;
};

export const GetBillingDetailsDocument = /* #__PURE__ */ gql`
  query getBillingDetails($accountId: String!) {
    account(uuid: $accountId) {
      uuid
      billingDetails {
        firstName
        lastName
        email
        phone
        street
        country
        city
        postalCode
        state
      }
    }
  }
`;

/**
 * __useGetBillingDetailsQuery__
 *
 * To run a query within a React component, call `useGetBillingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetBillingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBillingDetailsQueryResult, GetBillingDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetBillingDetailsQueryResult, GetBillingDetailsQueryVariables>(GetBillingDetailsDocument, options);
}
export function useGetBillingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBillingDetailsQueryResult, GetBillingDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetBillingDetailsQueryResult, GetBillingDetailsQueryVariables>(GetBillingDetailsDocument, options);
}
export type GetBillingDetailsQueryHookResult = ReturnType<typeof useGetBillingDetailsQuery>;
export type GetBillingDetailsLazyQueryHookResult = ReturnType<typeof useGetBillingDetailsLazyQuery>;
