import type { FC } from 'react';

import { CargoDimensionsUnitEnum } from '@zen/Cargo';
import { FormHiddenInput, FormSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';

import FormNumberInput from '../FormNumberInput';

const metricOptions: Option<CargoDimensionsUnitEnum>[] = [
  { label: 'cm', value: CargoDimensionsUnitEnum.CM },
  { label: 'mm', value: CargoDimensionsUnitEnum.MM },
  { label: 'in', value: CargoDimensionsUnitEnum.IN }
];

interface Props {
  disabled?: boolean;
  hideErrorMessage?: boolean;
  isNewCargo?: boolean;
  name: string;
}

const FormCargoDimensionsInput: FC<Props> = ({ disabled, hideErrorMessage, name, isNewCargo = false }) => {
  const className: string = 'w-36 mr-4';
  const unitFieldName: string = isNewCargo ? `${name}.unit` : `${name}.metric`;

  return (
    <div>
      <div className="flex flex-1">
        <FormNumberInput
          className={className}
          disabled={disabled}
          hideErrorMessage={hideErrorMessage}
          label="Length"
          name={`${name}.length`}
          placeholder="0.0"
          step={1}
        />
        <FormNumberInput
          className={className}
          disabled={disabled}
          hideErrorMessage={hideErrorMessage}
          label="Width"
          name={`${name}.width`}
          placeholder="0.0"
          step={1}
        />
        <FormNumberInput
          className={className}
          hideErrorMessage={hideErrorMessage}
          label="Height"
          name={`${name}.height`}
          placeholder="0.0"
          step={1}
        />
        <FormSelect
          hideErrorMessage={hideErrorMessage}
          isDisabled={disabled}
          isSearchable={false}
          label="Unit"
          name={unitFieldName}
          options={metricOptions}
        />
      </div>
      <div className="-mt-4 mb-4">
        <FormHiddenInput name={`${name}.length`} />
        <FormHiddenInput name={`${name}.width`} />
        <FormHiddenInput name={`${name}.height`} />
      </div>
    </div>
  );
};

export default FormCargoDimensionsInput;
