import type { FC } from 'react';

import { QuoteOptionState } from '@zen/Quotes';

const headline: Record<QuoteOptionState, string> = {
  [QuoteOptionState.ACTIVE]: 'Active quote option',
  [QuoteOptionState.ACCEPTED]: 'Accepted quote option',
  [QuoteOptionState.DRAFT]: 'Draft quote option',
  [QuoteOptionState.REJECTED]: 'Rejected quote option',
  [QuoteOptionState.EXPIRED]: 'Expired quote option',
  [QuoteOptionState.ARCHIVED]: 'Archived quote option'
};

interface Props {
  quoteState: QuoteOptionState;
}

const QuoteOptionHeader: FC<Props> = ({ quoteState }) => {
  return (
    <div className="mb-1" data-testid="headline">
      <span className="mr-2 text-navy-base text-lg font-bold">{headline[quoteState]}</span>
    </div>
  );
};

export default QuoteOptionHeader;
