import type { ReactNode } from 'react';

import type { ConsolidationLegType } from '@zen/Consolidation/types';
import type { TableColumn } from '@zen/DesignSystem';

import { formatDate } from '../../utils/dateTime/date/formatting';

export const getConsolidatedShipmentLegColumnsConfiguration = (): TableColumn<ConsolidationLegType>[] => {
  return [
    {
      key: 'from',
      title: 'From',
      render: (_, { networksOrigin }): ReactNode => {
        return <span>{networksOrigin?.label?.long || ''}</span>;
      },
      ellipsis: true,
      sortable: false,
      width: '200px'
    },
    {
      key: 'to',
      title: 'To',
      render: (_, { networksDestination }): ReactNode => {
        return <span>{networksDestination?.label?.long || ''}</span>;
      },
      ellipsis: true,
      sortable: false,
      width: '200px'
    },
    {
      key: 'estimatedDepartureDate',
      title: 'Estimated departure date',
      render: (_, { estimatedDepartureDate }): ReactNode => {
        return <span>{formatDate(estimatedDepartureDate)}</span>;
      },
      sortable: false
    },
    {
      key: 'actualDepartureDate',
      title: 'Actual departure date',
      render: (_, { actualDepartureDate }): ReactNode => {
        return <span>{formatDate(actualDepartureDate || '')}</span>;
      },
      sortable: false
    },
    {
      key: 'estimatedArrivalDate',
      title: 'Estimated arrival date',
      render: (_, { estimatedArrivalDate }): ReactNode => {
        return <span>{formatDate(estimatedArrivalDate || '')}</span>;
      },
      sortable: false
    },
    {
      key: 'actualArrivalDate',
      title: 'Actual arrival date',
      render: (_, { actualArrivalDate }): ReactNode => {
        return <span>{formatDate(actualArrivalDate || '')}</span>;
      },
      sortable: false
    }
  ];
};

export default getConsolidatedShipmentLegColumnsConfiguration;
