import type { FC, ReactNode } from 'react';

import { Form, FormButtons } from '@zen/Components/Form';
import FormRichText from '@zen/Components/Form/FormRichText';
import QueryHandler from '@zen/Components/QueryHandler';
import SectionDescription from '@zen/Components/SectionDescription';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import { useNoteQuery, useUpdateNoteMutation } from '@zen/Shipment/Notes/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import type { Note } from './types';

interface Props {
  zencargoReference: string;
}

const Notes: FC<Props> = ({ zencargoReference }) => {
  const { addError, addSuccess } = useNotification();
  const [updateNote] = useUpdateNoteMutation();
  const {
    data: notesData,
    error,
    loading
  } = useNoteQuery({
    variables: {
      id: zencargoReference,
      type: 'booking'
    }
  });

  const handleSubmit = async ({ body }: Note): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateNote({
          variables: {
            input: {
              id: zencargoReference,
              type: 'booking',
              body
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Notes have been saved.');
      }
    });
  };

  const renderFormButtons = (): ReactNode => <FormButtons containerClassName="-mt-14 ml-4" isSubmitting={false} text="Save" />;

  return (
    <QueryHandler
      data={notesData?.note}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonLoading className="my-5" height={50} />}
    >
      {(notes: Pick<Note, 'body' | 'id'>) => (
        <div className="mb-6">
          <SectionDescription text="Notes are only visible to Zencargo employees." />
          <Form enableReinitialize={true} formButtons={renderFormButtons} initialValues={notes} onSubmit={handleSubmit}>
            {() => <FormRichText hideLabel={true} name="body" placeholder="Write notes here..." toolbarClassName="h-28" />}
          </Form>
        </div>
      )}
    </QueryHandler>
  );
};

export default Notes;
