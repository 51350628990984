import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RoadTrackingDetailsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type RoadTrackingDetailsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              roadTracking?: GraphQLTypes.Maybe<
                { __typename?: 'RoadTrackingDetails' } & {
                  trackingInput?: GraphQLTypes.Maybe<
                    { __typename?: 'RoadTrackingInput' } & Pick<GraphQLTypes.RoadTrackingInput, 'trackable'> & {
                        trackingSubscription?: GraphQLTypes.Maybe<
                          { __typename?: 'RoadTrackingSubscription' } & Pick<
                            GraphQLTypes.RoadTrackingSubscription,
                            'mapUrl' | 'status'
                          >
                        >;
                      }
                  >;
                }
              >;
            }
        >
      >
    >;
  };
};

export const RoadTrackingDetailsDocument = /* #__PURE__ */ gql`
  query roadTrackingDetails($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        roadTracking {
          trackingInput {
            trackable
            trackingSubscription {
              mapUrl
              status
            }
          }
        }
        zencargoReference
      }
    }
  }
`;

/**
 * __useRoadTrackingDetailsQuery__
 *
 * To run a query within a React component, call `useRoadTrackingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadTrackingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadTrackingDetailsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useRoadTrackingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<RoadTrackingDetailsQueryResult, RoadTrackingDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RoadTrackingDetailsQueryResult, RoadTrackingDetailsQueryVariables>(RoadTrackingDetailsDocument, options);
}
export function useRoadTrackingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoadTrackingDetailsQueryResult, RoadTrackingDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RoadTrackingDetailsQueryResult, RoadTrackingDetailsQueryVariables>(
    RoadTrackingDetailsDocument,
    options
  );
}
export type RoadTrackingDetailsQueryHookResult = ReturnType<typeof useRoadTrackingDetailsQuery>;
export type RoadTrackingDetailsLazyQueryHookResult = ReturnType<typeof useRoadTrackingDetailsLazyQuery>;
