import type { FC, ReactNode } from 'react';

import PageContent from '@zen/Components/PageContent';
import { Icon } from '@zen/DesignSystem';

import { ReactComponent as Emoti } from './face.svg';
import noOrdersSrc from './no-orders.png';

const highligths = [
  <>
    Give merchandising and buying teams <span className="font-bold">visibility</span> into <br />
    order statuses, exceptions and delay reasons
  </>,
  <>
    Get early <span className="font-bold">warnings when orders are at risk</span> of missing <br />
    fulfillment targets or required delivery schedules
  </>,
  <>
    Drill down to <span className="font-bold">SKU level</span> and deep link into intake <br />
    planning to know where your inventory is
  </>
];

const NoOrders: FC = () => {
  const renderHighlight = (reason: ReactNode, index: number): ReactNode => {
    return (
      <li key={index} className="mb-4 flex">
        <Icon className="text-green-base text-2xl mt-1 mr-5" icon="zicon-tickoval" />
        <div>{reason}</div>
      </li>
    );
  };

  return (
    <div className="flex h-full flex-col">
      <PageContent className="pt-8 flex justify-between relative" width="wide">
        <div>
          <div className="font-bold text-grey-dark text-lg mb-12 flex">
            You don’t have any POs in Zencargo
            <Emoti className="ml-1" />
          </div>
          <div className="mb-6 text-grey-dark">
            Add your POs to <span className="font-bold">unlock the following</span>...
          </div>
          <ul className="leading-normal text-grey-dark mb-24">{highligths.map(renderHighlight)}</ul>
        </div>
        <div className="absolute right-0 -bottom-10 hidden lg:block">
          <img alt="No orders" className="max-w-xl" src={noOrdersSrc} />
        </div>
      </PageContent>
      <div className="flex-1 bg-grey-lightest py-12 px-10">
        <div className="text-lg font-bold text-grey-dark leading-normal mb-5">Need a hand getting started?</div>
        <div className="leading-normal text-grey-dark text-sm mb-4">
          There are lots of simple ways to add POs to Zencargo, including <br />
          integrating with your systems. Speak with our customer success <br />
          team or your account manager and we’ll get you setup.
        </div>
        <a className="text-navy-dark text-sm group font-bold" data-testid="contact-us" href="mailto:success@zencargo.com">
          <span className="group-hover:underline mr-2">Email the Zencargo customer success team</span>
          <Icon icon="zicon-arrow-right" />
        </a>
      </div>
    </div>
  );
};

export default NoOrders;
