import { countBy, mapKeys } from 'lodash';
import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';

import { Pill } from '@zen/DesignSystem';
import { buildIssueLabel, getActiveIssues } from '@zen/Issues/issueHelpers';
import type { PillIssue } from '@zen/Issues/types';

interface Props {
  issues: PillIssue[];
  showDescription?: boolean;
}

const IssueCounterPill: FC<Props> = ({ issues, showDescription = false }) => {
  const activeIssues: PillIssue[] = getActiveIssues(issues);

  const generatedIssues: PillIssue[] = activeIssues.filter((issue: PillIssue) => !!issue.title);
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(Maybe<string> | undefined)[]' is not assign... Remove this comment to see the full error message
  const generatedIssueTitles: string[] = generatedIssues.map((issue: PillIssue) => issue.title);

  const customIssues: PillIssue[] = activeIssues.filter((issue: PillIssue) => !issue.title);
  const customIssueTitles: string[] = customIssues.map((issue: PillIssue) => buildIssueLabel(issue));

  const groupedGeneratedLabels: Record<string, number> = countBy(generatedIssueTitles, (label: string) => label);
  const groupedCustomLabels: Record<string, number> = countBy(customIssueTitles, (label: string) => label);

  const customLabels: Record<string, number> = mapKeys(groupedCustomLabels, (value: number, key: string) =>
    pluralize(key, value)
  );

  const label: number = activeIssues.length;

  const groupedLabels: Record<string, number> = { ...groupedGeneratedLabels, ...customLabels };

  const tooltip: ReactNode = Object.keys(groupedLabels).map((issueLabel: string, i: number) => {
    const issueCount: number = groupedLabels[issueLabel];

    return (
      <div key={i} className="leading-6 font-bold">
        {issueCount} {issueLabel}
      </div>
    );
  });

  const description: string = showDescription ? pluralize('Issue', activeIssues.length) : '';

  if (!activeIssues.length) {
    return null;
  }

  return (
    <Pill tooltip={tooltip}>
      {label} {description}
    </Pill>
  );
};

export default IssueCounterPill;
