import type { RoutesConfig } from './types';

export const SETTINGS_PREFIX = '/dashboard/settings';

interface SettingsRoutes extends RoutesConfig {
  notifications: {
    getUrl: () => string;
    path: string;
  };
  settingsIndex: {
    getUrl: () => string;
    path: string;
  };
}

const settingsRoutes: SettingsRoutes = {
  settingsIndex: {
    path: SETTINGS_PREFIX,
    getUrl: () => SETTINGS_PREFIX
  },
  notifications: {
    path: `${SETTINGS_PREFIX}/notifications`,
    getUrl: () => `${SETTINGS_PREFIX}/notifications`
  }
};

export default settingsRoutes;
