import type { OnSubscriptionDataOptions } from '@apollo/client';
import { FC, useState } from 'react';

import Documents from '@zen/Components/Documents';
import { BookingDocumentState, Document } from '@zen/Components/Documents/types';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import { useConsumeLocationState } from '@zen/utils/hooks/useConsumeLocationState';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import {
  useArchiveBookingDocumentMutation,
  useBookingDocumentGenerationSubscription,
  useGetBookingDocumentsQuery,
  useUpdateBookingDocumentMutation
} from './graphql';
import { getDocuments } from './helpers';
import type { BookingDocumentGenerationSubscriptionResult } from './types';

interface Props {
  zencargoReference: string;
}

const DocumentsContainer: FC<Props> = ({ zencargoReference }) => {
  const [documentGenerationLoading, setDocumentGenerationLoading] = useState<boolean>(false);

  useConsumeLocationState((isDocumentGenerating: boolean) => {
    if (isDocumentGenerating) {
      setDocumentGenerationLoading(true);
    }
  }, 'isDocumentGenerating');

  const {
    data: documentsData,
    loading: documentsLoading,
    error: documentsError,
    refetch: documentsRefetch
  } = useGetBookingDocumentsQuery({
    variables: { zencargoReference },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const [archiveBookingDocument] = useArchiveBookingDocumentMutation();
  const [updateBookingDocument] = useUpdateBookingDocumentMutation();

  const { addSuccess, addError } = useNotification();

  useBookingDocumentGenerationSubscription({
    variables: { zencargoReference },
    fetchPolicy: 'no-cache',
    onSubscriptionData: (options: OnSubscriptionDataOptions<BookingDocumentGenerationSubscriptionResult>) => {
      const state: string | undefined = options.subscriptionData.data?.bookingDocumentGeneration?.state;

      if (state === BookingDocumentState.UPLOADED) {
        documentsRefetch();
        setDocumentGenerationLoading(false);
      }
    }
  });

  const handleEdit = async (document: Document): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () =>
        updateBookingDocument({
          variables: {
            input: {
              id: document.uuid,
              description: document.description,
              bookingDocumentTypeName: document.documentType,
              permissions: {
                agentForwarder: document.permissions.agentForwarder,
                customerUser: document.permissions.customerUser,
                manufacturer: document.permissions.manufacturer
              }
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Document has been edited');
        documentsRefetch();
      }
    });

  const handleDelete = async (id: string): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () =>
        archiveBookingDocument({
          variables: {
            input: { id }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Document has been removed');
        documentsRefetch();
      }
    });

  const documents: Document[] | undefined = getDocuments(documentsData);

  return (
    <QueryHandler
      data={documentsData}
      error={!!documentsError}
      isLoading={documentsLoading}
      loadingComponent={<SkeletonLoading className="my-5" height={50} />}
    >
      {() => {
        return (
          <Documents
            documents={documents}
            isGeneratingDocument={documentGenerationLoading}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        );
      }}
    </QueryHandler>
  );
};

export default DocumentsContainer;
