import type { FC } from 'react';

interface IPercentile {
  percentile: number;
  value: number;
}

interface Props {
  leadTimeDistribution: IPercentile[];
}

const LeadTime: FC<Props> = ({ leadTimeDistribution }) => (
  <div className="flex flex-col justify-center h-full">
    {leadTimeDistribution &&
      leadTimeDistribution.map(({ percentile, value }, i: number) => (
        <div key={i} className="mb-5 whitespace-nowrap text-center">
          <span className="text-grey-dark text-3xl mr-3">{Math.round(percentile)} %</span>
          <span className="text-grey-base text-3xl mr-5">under</span>
          <span className="text-navy-base text-4xl font-bold mr-2">{Math.round(value)}</span>
          <span className="text-navy-base text-2xl font-bold">days</span>
        </div>
      ))}
  </div>
);

export default LeadTime;
