import * as Yup from 'yup';

import { CargoModeEnum } from '@zen/Cargo';
import { ScheduleAction } from '@zen/OperationsShipments/EditRoadShipment/types';

import { createDateAndTimeValidationSchema } from '../../RoadShipmentForm.validation';

const defaultValidationSchema = Yup.object().shape({
  collection: createDateAndTimeValidationSchema(false),
  delivery: createDateAndTimeValidationSchema(false)
});

const fclValidationSchema = Yup.object().shape({
  collection: createDateAndTimeValidationSchema(),
  delivery: createDateAndTimeValidationSchema()
});

const looseValidationSchema = Yup.object().shape({
  collection: createDateAndTimeValidationSchema(),
  delivery: createDateAndTimeValidationSchema(false)
});

export const createValidationSchema = (cargoMode: CargoModeEnum, scheduleAction: ScheduleAction) => {
  if (scheduleAction === ScheduleAction.REQUEST_SCHEDULE) {
    return defaultValidationSchema;
  }

  return cargoMode === CargoModeEnum.FTL ? fclValidationSchema : looseValidationSchema;
};
