import type { FC } from 'react';

import DatesDifference from '@zen/Components/DatesDifference';
import { formatDate } from '@zen/utils/dateTime';

interface Props {
  newDate: string | undefined;
  previousDate: string;
}

const DatesIndicator: FC<Props> = ({ newDate, previousDate }) => (
  <div className="flex items-center pt-8 mb-4">
    <div className="flex items-center flex-1">
      <div className="w-5 h-5 bg-grey-dark rounded mr-3" />
      Previous: <span className="font-bold">&nbsp;{formatDate(previousDate)}</span>
    </div>
    <div className="flex items-center flex-1">
      <div className="w-5 h-5 bg-navy-base rounded mr-3" />
      New: <span className="font-bold">&nbsp;{newDate && formatDate(newDate)}</span>
      {newDate && <DatesDifference className="ml-3" date={newDate} referenceDate={previousDate} />}
    </div>
  </div>
);

export default DatesIndicator;
