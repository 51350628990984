import type { FC } from 'react';

import { FormMultiSelect } from '@zen/Components/Form';
import { prepareRoleOptions } from '@zen/Settings/ProfileDetails/ProfileDetailsForm/helper';

interface Props {
  className?: string;
  name?: string;
}

const FormRoleSelect: FC<Props> = ({ name = 'roles', className = '' }) => (
  <FormMultiSelect className={className} isSearchable={true} label="Role(s)" name={name} options={prepareRoleOptions()} />
);

export default FormRoleSelect;
