import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import ErrorBoundary from '@zen/Components/ErrorBoundary';
import Sidebar from '@zen/Layout/Sidebar';
import TopNavigation from '@zen/Layout/TopNavigation';
import { authRoutes } from '@zen/Routes';
import CaptureProfileRolesPage from '@zen/Settings/CaptureProfileRolesPage';
import useAccount from '@zen/utils/hooks/useAccount';
import useTracking from '@zen/utils/hooks/useTracking';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import DashboardRoutes from '../DashboardRoutes';

const Dashboard: FC = () => {
  const { accountUuid, userProfile, refetchUser } = useAccount();
  const { check } = useGlobalPermissions();
  const { identify } = useTracking();
  const role = useRole();
  const { navigate } = useNavigationHistory();

  const canSwitchAccount: boolean = check('accounts.canSwitch');
  const { preferences, isAdmin } = userProfile;
  const { level, roles } = preferences;

  const hasMissingRolesAndLevels: boolean = !level || isEmpty(roles);
  const shouldCaptureRole: boolean = hasMissingRolesAndLevels && !isAdmin;

  // Send user profile
  useEffect(() => {
    identify(accountUuid, userProfile, role);
  }, [identify, accountUuid, userProfile, role]);

  if (canSwitchAccount && !accountUuid) {
    navigate(authRoutes.accountSwitcher.getUrl());

    return null;
  }

  if (shouldCaptureRole) {
    return <CaptureProfileRolesPage onSuccess={refetchUser} />;
  }

  return (
    <div className="flex flex-col h-full bg-white" data-testid="main-application">
      <TopNavigation />
      <div className="flex flex-row">
        <Sidebar />
        <div className="flex-1 min-w-0">
          <ErrorBoundary>
            <DashboardRoutes />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
