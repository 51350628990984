import { FC, useEffect, useState } from 'react';

import CloseIcon from '@zen/Components/Icons/CloseIcon';
import InfiniteScroll from '@zen/Components/InfiniteScroll';
import { ContextMenu, Loading, MenuItemType } from '@zen/DesignSystem';
import { settingsRoutes } from '@zen/Routes';
import useInfiniteScroll from '@zen/utils/hooks/useInfiniteScroll';
import { performMutation } from '@zen/utils/performMutation';

import { useNotification } from '../../utils/hooks/useNotification';
import type { IOkOrErrorResult } from '../../utils/OkOrErrorResult';
import {
  GetNotificationsDocument,
  useGetPurchaseOrderReferencesLazyQuery,
  useGetTextMessagesContentLazyQuery,
  useNotificationsMarkAllAsReadMutation
} from '../graphql';
import type { TargetNotifications } from '../types';
import { NotificationsTargetTypeEnum } from '../types';
import {
  getPurchaseOrderIds,
  getPurchaseOrderReferences,
  getTextMessageQueryVariables,
  getTextMessagesContent
} from './notificationListHelper';
import NotificationListItem from './NotificationListItem';

interface Props {
  onClose: () => void;
}

const NotificationList: FC<Props> = ({ onClose }) => {
  const [list, hasMoreResults, refetch, isLoading] = useInfiniteScroll<TargetNotifications>({
    query: GetNotificationsDocument,
    responsePath: 'data.notifications.notificationsPerTarget',
    variables: {},
    dependencies: []
  });

  const [textMessagesContent, setTextMessagesContent] = useState<{ [key: string]: string }>({});
  const [queryTextMessages, { data: textMessages }] = useGetTextMessagesContentLazyQuery();

  const [purchaseOrderReferences, setPurchaseOrderReferences] = useState<{ [key: string]: string }>({});
  const [queryPurchaseOrderReference, { data: purchaseOrderReferencesData }] = useGetPurchaseOrderReferencesLazyQuery();

  const [markAllNotificationAsRead] = useNotificationsMarkAllAsReadMutation();
  const { addSuccess, addError } = useNotification();

  useEffect(() => {
    if (list.length > 0) {
      const variables = getTextMessageQueryVariables(list, textMessagesContent);

      queryTextMessages({
        variables
      });

      const idsForMissingOrderReferences = getPurchaseOrderIds(list, purchaseOrderReferences);

      if (idsForMissingOrderReferences.length) {
        queryPurchaseOrderReference({
          variables: { ids: idsForMissingOrderReferences }
        });
      }
    }
  }, [list]); //eslint-disable-line

  useEffect(() => {
    if (purchaseOrderReferencesData) {
      const missingPurchaseOrderReferences = getPurchaseOrderReferences(purchaseOrderReferencesData.purchaseOrders.nodes);

      setPurchaseOrderReferences({ ...purchaseOrderReferences, ...missingPurchaseOrderReferences });
    }
  }, [purchaseOrderReferencesData]); //eslint-disable-line

  useEffect(() => {
    if (textMessages) {
      const displayTextMessages = getTextMessagesContent(textMessages.activityFeed.textMessages);

      setTextMessagesContent({ ...textMessagesContent, ...displayTextMessages });
    }
  }, [textMessages]); //eslint-disable-line

  const targetReference = (notification: TargetNotifications): string => {
    switch (notification.targetType) {
      case NotificationsTargetTypeEnum.BOOKING:
        return notification.targetId;
      case NotificationsTargetTypeEnum.PURCHASE_ORDER:
        return purchaseOrderReferences[notification.targetId];
    }
  };

  const handleMarkAllAsRead = (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        markAllNotificationAsRead({
          variables: {
            input: {}
          }
        }),
      onError: addError,
      onSuccess: () => {
        refetch(null);

        addSuccess('Notifications marked as read');
      }
    });
  };

  const hasUnreadNotifications: boolean = !!list && list.some((item: TargetNotifications) => !!item.unreadNotificationIds.length);

  const menuItems: MenuItemType[] = [
    {
      disabled: !hasUnreadNotifications,
      icon: 'zicon-tick',
      label: 'Mark all as read',
      onClick: () => handleMarkAllAsRead()
    },
    {
      icon: 'zicon-adjust',
      label: 'Notification settings',
      linkTo: settingsRoutes.notifications.getUrl(),
      onClick: () => onClose()
    }
  ];

  return (
    <InfiniteScroll hasMoreResults={hasMoreResults} onLoad={refetch} useWindow={false}>
      <div className="border-b border-solid border-grey-lighter bg-grey-lightest flex justify-between items-center h-16 px-5 font-bold text-navy-base text-sm sticky top-0 z-20">
        <span>Notifications</span>
        <div className="flex">
          <ContextMenu items={menuItems} />
          <CloseIcon onClick={onClose} />
        </div>
      </div>
      {isLoading && <Loading />}
      {!isLoading &&
        list.map((notification: TargetNotifications, i: number) => (
          <NotificationListItem
            key={i}
            displayText={textMessagesContent[notification.lastNotificationItem.itemId]}
            notification={notification}
            onClick={onClose}
            targetReference={targetReference(notification)}
          />
        ))}
    </InfiniteScroll>
  );
};

export default NotificationList;
