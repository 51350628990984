import type { ListItemType } from '@zen/Components/VerticalList/ListItem';

function filterList(listToFilter: ListItemType[], typedQuery: string) {
  const filterQuery = (item: ListItemType): boolean => {
    const { label } = item;

    return label.toLowerCase().includes(typedQuery.toLowerCase());
  };

  return listToFilter.filter(filterQuery);
}

function isSearchableListItem(arg: ListItemType) {
  return arg && arg.id !== undefined && arg.label !== undefined;
}

function isSearchableListItemArray(arg: ListItemType[]) {
  return Array.isArray(arg) && arg.length > 0 && isSearchableListItem(arg[0]);
}
export { filterList, isSearchableListItem, isSearchableListItemArray };
