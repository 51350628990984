import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetActivityFeedQueryVariables = GraphQLTypes.Exact<{
  itemTypes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.ActivityFeedItemTypeEnum> | GraphQLTypes.ActivityFeedItemTypeEnum>;
  targetId: GraphQLTypes.Scalars['String'];
  targetType: GraphQLTypes.ActivityFeedTargetTypeEnum;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetActivityFeedQueryResult = { __typename?: 'Query' } & {
  activityFeed: { __typename?: 'ActivityFeed' } & {
    activities?: GraphQLTypes.Maybe<
      { __typename?: 'ActivityConnection' } & Pick<GraphQLTypes.ActivityConnection, 'totalCount'> & {
          nodes?: GraphQLTypes.Maybe<
            Array<
              GraphQLTypes.Maybe<
                { __typename?: 'Activity' } & Pick<
                  GraphQLTypes.Activity,
                  'id' | 'createdAt' | 'itemId' | 'itemType' | 'targetId' | 'targetType'
                > & {
                    user?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksContact' } & Pick<
                        GraphQLTypes.NetworksContact,
                        'firstName' | 'lastName' | 'avatarUrl' | 'email' | 'assignedRoles'
                      > & {
                          organisation:
                            | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'name'>)
                            | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'name'>)
                            | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'name'>)
                            | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'name'>);
                        }
                    >;
                    permissions: { __typename?: 'Permissions' } & Pick<
                      GraphQLTypes.Permissions,
                      'agentForwarder' | 'customerUser' | 'manufacturer'
                    >;
                    metadata?: GraphQLTypes.Maybe<
                      | ({ __typename?: 'BookingCollectionMetadataType' } & Pick<
                          GraphQLTypes.BookingCollectionMetadataType,
                          'collectedOn' | 'reference' | 'locationLabel' | 'scheduledAt'
                        >)
                      | ({ __typename?: 'BookingDeliveryMetadataType' } & Pick<
                          GraphQLTypes.BookingDeliveryMetadataType,
                          'deliveredOn' | 'reference' | 'locationLabel' | 'scheduledAt'
                        >)
                      | ({ __typename?: 'CargoMetadataContainerType' } & Pick<
                          GraphQLTypes.CargoMetadataContainerType,
                          'containerNumber' | 'containerType' | 'cargoType'
                        >)
                      | ({ __typename?: 'CargoMetadataLooseCargoType' } & Pick<
                          GraphQLTypes.CargoMetadataLooseCargoType,
                          'palletType' | 'quantity' | 'looseCargoType' | 'cargoType'
                        >)
                      | ({ __typename?: 'CargoMetadataVehicleType' } & Pick<
                          GraphQLTypes.CargoMetadataVehicleType,
                          'vehicleType' | 'quantity' | 'looseCargoType' | 'cargoType'
                        >)
                      | ({ __typename?: 'IssueActivityMetadataType' } & Pick<
                          GraphQLTypes.IssueActivityMetadataType,
                          'category' | 'problemDescription' | 'severity' | 'state' | 'title'
                        > & {
                            openedBy: { __typename?: 'NetworksContact' } & Pick<
                              GraphQLTypes.NetworksContact,
                              'firstName' | 'lastName'
                            >;
                            resolvedBy?: GraphQLTypes.Maybe<
                              { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
                            >;
                            startedBy?: GraphQLTypes.Maybe<
                              { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
                            >;
                          })
                      | ({ __typename?: 'PurchaseOrderCargoReadyDatesMetadataType' } & Pick<
                          GraphQLTypes.PurchaseOrderCargoReadyDatesMetadataType,
                          'additionalInformation' | 'confidenceLevel' | 'cargoReadyDate' | 'lotIds' | 'reasonCategory'
                        >)
                      | ({ __typename?: 'PurchaseOrderClosedMetadataType' } & Pick<
                          GraphQLTypes.PurchaseOrderClosedMetadataType,
                          'manually'
                        >)
                      | ({ __typename?: 'PurchaseOrderDeliveryEstimatesMetadataType' } & Pick<
                          GraphQLTypes.PurchaseOrderDeliveryEstimatesMetadataType,
                          'additionalInformation' | 'confidenceLevel' | 'deliveryDate' | 'lotIds'
                        >)
                      | ({ __typename?: 'ShipmentEtaMetadataType' } & Pick<
                          GraphQLTypes.ShipmentEtaMetadataType,
                          'reason' | 'description'
                        > & {
                            newEstimate?: GraphQLTypes.Maybe<
                              { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                            >;
                            previousEstimate?: GraphQLTypes.Maybe<
                              { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date'>
                            >;
                          })
                    >;
                  }
              >
            >
          >;
          pageInfo: { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >;
        }
    >;
  };
};

export const GetActivityFeedDocument = /* #__PURE__ */ gql`
  query getActivityFeed(
    $itemTypes: [ActivityFeedItemTypeEnum!]
    $targetId: String!
    $targetType: ActivityFeedTargetTypeEnum!
    $after: String
    $before: String
  ) {
    activityFeed {
      activities(itemTypes: $itemTypes, targetId: $targetId, targetType: $targetType, after: $after, before: $before) {
        nodes {
          id
          createdAt
          user {
            firstName
            lastName
            avatarUrl
            email
            assignedRoles
            organisation {
              name
            }
          }
          itemId
          itemType
          targetId
          targetType
          permissions {
            agentForwarder
            customerUser
            manufacturer
          }
          metadata {
            ... on BookingCollectionMetadataType {
              collectedOn
              reference
              locationLabel
              scheduledAt
            }
            ... on BookingDeliveryMetadataType {
              deliveredOn
              reference
              locationLabel
              scheduledAt
            }
            ... on IssueActivityMetadataType {
              category
              openedBy {
                firstName
                lastName
              }
              problemDescription
              resolvedBy {
                firstName
                lastName
              }
              severity
              startedBy {
                firstName
                lastName
              }
              state
              title
            }
            ... on CargoMetadataContainerType {
              containerNumber
              containerType
              cargoType
            }
            ... on CargoMetadataVehicleType {
              vehicleType
              quantity
              looseCargoType
              cargoType
            }
            ... on CargoMetadataLooseCargoType {
              palletType
              quantity
              looseCargoType
              cargoType
            }
            ... on PurchaseOrderDeliveryEstimatesMetadataType {
              additionalInformation
              confidenceLevel
              deliveryDate
              lotIds
            }
            ... on PurchaseOrderCargoReadyDatesMetadataType {
              additionalInformation
              confidenceLevel
              cargoReadyDate
              lotIds
              reasonCategory
            }
            ... on PurchaseOrderClosedMetadataType {
              manually
            }
            ... on ShipmentEtaMetadataType {
              newEstimate {
                date
                timeZone
              }
              previousEstimate {
                date
              }
              reason
              description
            }
          }
        }
        totalCount
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetActivityFeedQuery__
 *
 * To run a query within a React component, call `useGetActivityFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityFeedQuery({
 *   variables: {
 *      itemTypes: // value for 'itemTypes'
 *      targetId: // value for 'targetId'
 *      targetType: // value for 'targetType'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetActivityFeedQuery(
  baseOptions: Apollo.QueryHookOptions<GetActivityFeedQueryResult, GetActivityFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetActivityFeedQueryResult, GetActivityFeedQueryVariables>(GetActivityFeedDocument, options);
}
export function useGetActivityFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActivityFeedQueryResult, GetActivityFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetActivityFeedQueryResult, GetActivityFeedQueryVariables>(GetActivityFeedDocument, options);
}
export type GetActivityFeedQueryHookResult = ReturnType<typeof useGetActivityFeedQuery>;
export type GetActivityFeedLazyQueryHookResult = ReturnType<typeof useGetActivityFeedLazyQuery>;
