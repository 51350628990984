import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';

import type { OrganisationTypeValue } from '../types';

interface Props {
  type: OrganisationTypeValue;
}

const OrganisationType: FC<Props> = ({ type }) => {
  const organisationTypeDetails = {
    agent: {
      icon: 'zicon-agent',
      label: 'Agent'
    },
    forwarder: {
      icon: 'zicon-agent',
      label: 'Forwarder'
    }
  };

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const orgType = organisationTypeDetails[type];

  // don't render if organisation type has no details
  if (!orgType) {
    return null;
  }

  const { icon, label } = orgType;

  return (
    <div className="text-sm inline-flex items-center leading-tight p-2 rounded mr-4 cursor-pointer bg-azure-lightest text-navy-dark">
      <Icon className="mr-2 text-base" icon={icon} />
      <span>{label}</span>
    </div>
  );
};

export default OrganisationType;
