import type { FC } from 'react';

import SkeletonLoading from '@zen/Components/SkeletonLoading';

interface Props {
  count?: number;
}
const SkeletonMilestones: FC<Props> = ({ count = 1 }) => (
  <>
    {[...Array(count)].map((_, i) => (
      <div key={i} className="flex justify-between my-8">
        <div className="flex">
          <SkeletonLoading circle={true} height={16} width="w-4" />
          <SkeletonLoading height={17} width="w-28" />
        </div>
        <SkeletonLoading height={17} width="w-20" />
      </div>
    ))}
  </>
);

export type { Props as SkeletonMilestonesProps };

export default SkeletonMilestones;
