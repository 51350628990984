import type { FC } from 'react';

import ActionItemDetails from '../../components/ActionItemDetails/ActionItemDetails';
import ActivityFeedItemWrapper from '../../components/ActivityFeedItemWrapper/index';
import type { ActionItem, Activity } from '../../types';

interface Props {
  actionItem: ActionItem;
  activityMetadata: Activity;
}

const NonActionableActionItem: FC<Props> = ({ activityMetadata, actionItem }) => {
  const { createdAt, itemType, user } = activityMetadata;

  return (
    <ActivityFeedItemWrapper>
      <ActionItemDetails actionItem={actionItem} actionItemType={itemType} createdAt={createdAt} user={user} />
    </ActivityFeedItemWrapper>
  );
};

export default NonActionableActionItem;
