import type { FC, ReactNode } from 'react';

import CollapsibleSection from '@zen/Components/CollapsibleSection';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import { InlineEmptyState, Pagination } from '@zen/DesignSystem';
import { shipmentRoutes } from '@zen/Routes';
import { shipmentDetailsPageCollapsibleItemsKeyName } from '@zen/Shipment/ShipmentDetailsPage/shipmentDetailsPageConfig';
import usePagination from '@zen/utils/hooks/pagination/usePagination';
import { useCollapsibleElement } from '@zen/utils/hooks/useCollapsibleElement';

import BookingOrderListItem from './BookingOrderListItem';
import CargoReadyDateSummary from './CargoReadyDateSummary';
import { GetLotsQueryResult, GetLotsQueryVariables, useGetLotsQuery } from './graphql';
import type { CargoLot, OrderLot as OrderLotType } from './types';
import { prepareOrders } from './utils';

interface Props {
  zencargoReference: string;
}

const OrderList: FC<Props> = ({ zencargoReference }) => {
  const { nodes, loading, error, paginationInfo } = usePagination<GetLotsQueryResult, GetLotsQueryVariables, OrderLotType>(
    useGetLotsQuery,
    'bookings.nodes[0].lots',
    { zencargoReference },
    20,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );

  const [isExpanded, setIsExpanded] = useCollapsibleElement('ordersList', false, shipmentDetailsPageCollapsibleItemsKeyName);

  const noResults: ReactNode = (
    <InlineEmptyState title="No orders are linked to this booking.">
      Orders describe information about the goods being carried in the shipment.
    </InlineEmptyState>
  );

  return (
    <div data-testid="lots-list">
      <CargoReadyDateSummary className="mb-4" zencargoReference={zencargoReference} />
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonLoading className="my-5" height={50} />}
        noResults={noResults}
      >
        {(ordersData: OrderLotType[]) => {
          const orders: [string, CargoLot[]][] = prepareOrders(ordersData);

          const renderBookingOrderListItem = ([orderNumber, lots]: [string, CargoLot[]], index: number) => {
            if (lots[0].orderId) {
              const linkTo: string = shipmentRoutes.shipmentDetailsPageOrders.getUrl(zencargoReference, lots[0].orderId, 'items');

              return <BookingOrderListItem key={index} linkTo={linkTo} lots={lots} orderReferenceNumber={orderNumber} />;
            }
          };

          return (
            <CollapsibleSection
              collapseLabel="Hide orders"
              expandLabel="Show orders"
              isExpandedInitially={isExpanded}
              onToggle={() => setIsExpanded(!isExpanded)}
            >
              <div>{orders.map(renderBookingOrderListItem)}</div>
              <Pagination pageInfo={paginationInfo} />
            </CollapsibleSection>
          );
        }}
      </QueryHandler>
    </div>
  );
};

export default OrderList;
