import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateDocumentTemplateMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateDocumentTemplateInput;
}>;

export type UpdateDocumentTemplateMutationResult = { __typename?: 'Mutation' } & {
  documentTemplatesUpdateDocumentTemplate?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateDocumentTemplatePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      documentTemplate?: GraphQLTypes.Maybe<
        { __typename?: 'DocumentTemplate' } & Pick<GraphQLTypes.DocumentTemplate, 'id' | 'name'>
      >;
    }
  >;
};

export const UpdateDocumentTemplateDocument = /* #__PURE__ */ gql`
  mutation updateDocumentTemplate($input: UpdateDocumentTemplateInput!) {
    documentTemplatesUpdateDocumentTemplate(input: $input) {
      errors {
        message
        path
      }
      documentTemplate {
        id
        name
      }
    }
  }
`;

/**
 * __useUpdateDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentTemplateMutation, { data, loading, error }] = useUpdateDocumentTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDocumentTemplateMutationResult, UpdateDocumentTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateDocumentTemplateMutationResult, UpdateDocumentTemplateMutationVariables>(
    UpdateDocumentTemplateDocument,
    options
  );
}
export type UpdateDocumentTemplateMutationHookResult = ReturnType<typeof useUpdateDocumentTemplateMutation>;
