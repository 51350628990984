import GoogleMap, { ChangeEventValue } from 'google-map-react';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import useSupercluster from 'use-supercluster';

import staticConfig from '@zen/utils/staticConfig';
import type { Nullable, Optional } from '@zen/utils/typescript';

import Cluster from './Cluster';
import { getClusterAttributes, getMarkerLocations, preparePoints } from './helpers';
import Marker, { MarkerType } from './Marker';
import type { GeoCoordinates, MapApi, SuperclusterPointFeature } from './types';

const maxZoomValue: number = 16;
const maxClusterZoomValue: number = maxZoomValue - 1;
const defaultCenter: GeoCoordinates = { lat: 51.5074, lng: 0.1278 };
const defaultBounds: number[] = [-180, 6, 180, 74];
const mapOptions = { fullscreenControl: false, minZoom: 2, maxZoom: maxZoomValue };

interface Props {
  defaultZoom?: number;
  markerDraggable?: boolean;
  markers?: MarkerType[];
  onMarkerClick?: (index: number) => void;
  onMarkerDrag?: (lat: number, lng: number, index?: number) => void;
}

const Map: FC<Props> = (props) => {
  const { defaultZoom = 0, markers = [], markerDraggable = false, onMarkerClick, onMarkerDrag } = props;

  const [googleMap, setGoogleMap] = useState<Nullable<MapApi>>(null);
  const [isDraggable, setIsDraggable] = useState<boolean>(true);
  const [bounds, setBounds] = useState<Nullable<number[]>>(defaultBounds);
  const [zoom, setZoom] = useState<number>(maxZoomValue);

  const points: SuperclusterPointFeature<MarkerType>[] = useMemo(() => preparePoints(markers), [markers]);
  const markerLocations: string = useMemo(() => getMarkerLocations(markers), [markers]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      maxZoom: maxClusterZoomValue,
      radius: 75
    }
  });

  const fitBounds = (): void => {
    const latLngBounds: Optional<google.maps.LatLngBounds> = getMapBounds();

    if (latLngBounds) {
      googleMap?.map.fitBounds(latLngBounds);
    }
  };

  const getMarkerGooglePostition = ({ lat, lng }: MarkerType): Optional<google.maps.LatLng> => {
    if (!googleMap) return;

    return new googleMap.maps.LatLng(lat, lng);
  };

  const getMapBounds = (): Optional<google.maps.LatLngBounds> => {
    if (!googleMap) return;

    const newBounds = new googleMap.maps.LatLngBounds();

    markers.forEach((marker: MarkerType): void => {
      const latLng: Optional<google.maps.LatLng> = getMarkerGooglePostition(marker);

      if (latLng) {
        newBounds.extend(latLng);
      }
    });

    return newBounds;
  };

  const handleMarkerMove = (index: number, childProps: GeoCoordinates, mouse: GeoCoordinates): void => {
    setIsDraggable(false);
    setZoom(googleMap?.map.getZoom() || defaultZoom);
    if (onMarkerDrag) {
      onMarkerDrag(mouse.lat, mouse.lng, index);
    }
  };

  const handleMapChange = ({ zoom: newZoom, bounds: newBounds }: ChangeEventValue): void => {
    setZoom(newZoom);
    setBounds([newBounds.nw.lng, newBounds.se.lat, newBounds.se.lng, newBounds.nw.lat]);
  };

  const renderCluster = (cluster: SuperclusterPointFeature<MarkerType>, index: number): ReactNode => {
    const [lng, lat] = cluster.geometry.coordinates;
    const { cluster: isCluster, point_count: pointCount = 0 } = cluster.properties;

    if (isCluster) {
      const clusterLeaves: SuperclusterPointFeature<MarkerType>[] = supercluster.getLeaves(cluster.id);
      const { color, isHighlighted } = getClusterAttributes(clusterLeaves);

      const handleClusterClick = (): void => {
        const expansionZoom: number = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);

        googleMap?.map.setZoom(expansionZoom);
        googleMap?.map.panTo({ lat, lng });
      };

      return (
        <Cluster
          key={cluster.id}
          color={color}
          isHighlighted={isHighlighted}
          lat={lat}
          lng={lng}
          onClick={handleClusterClick}
          pointCount={pointCount}
          totalPoints={points.length}
        />
      );
    }

    return <Marker key={`marker-${index}`} {...cluster.properties} onClick={() => onMarkerClick?.(cluster.properties.index)} />;
  };

  useEffect(() => {
    if (!googleMap) return;

    const selectedMarker: Optional<MarkerType> = markers.find((marker: MarkerType) => marker.isSelected);

    if (selectedMarker) {
      googleMap?.map.panTo({ lat: selectedMarker.lat, lng: selectedMarker.lng });
    }
  }, [markers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!googleMap || !markers.length || !isDraggable) return;

    fitBounds();
  }, [googleMap, markerLocations]); // eslint-disable-line react-hooks/exhaustive-deps

  const markerDraggableProps = markerDraggable
    ? {
        draggable: isDraggable,
        onChildMouseDown: handleMarkerMove,
        onChildMouseMove: handleMarkerMove,
        onChildMouseUp: () => setIsDraggable(true)
      }
    : {};

  return (
    <div className="h-full" data-testid="map">
      <GoogleMap
        bootstrapURLKeys={{ key: staticConfig.googleMapsKey, libraries: ['places'] }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onChange={handleMapChange}
        onGoogleApiLoaded={setGoogleMap}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
        {...markerDraggableProps}
      >
        {clusters.map(renderCluster)}
      </GoogleMap>
    </div>
  );
};

export default Map;
