import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { Quill } from 'react-quill';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

const icons = Quill.import('ui/icons');

interface Props {
  className?: string;
  toolbarId: string;
}

export const EditorToolbar: FC<Props> = ({ className, toolbarId }) => {
  const toolbarClassNames: string = cx('bg-grey-lightest', className);

  const renderToolbarButton = (quillClassName: string, key: string, iconName: IconName, iconClassName?: string): ReactNode => {
    const buttonClassName: string = cx(
      quillClassName,
      {
        'text-black-light': !iconClassName
      },
      iconClassName
    );

    return <button className={buttonClassName}>{(icons[key] = <Icon icon={iconName} />)}</button>;
  };

  return (
    <div className={toolbarClassNames} data-testid="editor-toolbar" id={toolbarId}>
      <div className="ql-formats">
        {renderToolbarButton('ql-bold', 'bold', 'zicon-bold')}
        {renderToolbarButton('ql-italic', 'italic', 'zicon-italic')}
        {renderToolbarButton('ql-strike', 'strike', 'zicon-strike-through')}
      </div>
      <div className="ql-formats">{renderToolbarButton('ql-list', 'list.bullet', 'zicon-list')}</div>
      <div className="ql-formats">{renderToolbarButton('ql-image', 'image', 'zicon-attach')}</div>
      <div className="ql-formats -ml-3">
        <Icon className="text-grey-lighter" icon="zicon-line" />
      </div>
      <div className="ql-formats -ml-3">
        {renderToolbarButton('ql-undo', 'undo', 'zicon-undo')}
        {renderToolbarButton('ql-redo', 'redo', 'zicon-redo')}
      </div>
    </div>
  );
};

export default EditorToolbar;
