import type { FC } from 'react';

import InlineEditableField from '@zen/Components/InlineEditableField';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  forwarderName: Optional<string>;
  forwarderReference?: string;
  onForwarderReferenceChange?: (value: string) => void;
}

const ForwarderInfoHeader: FC<Props> = ({ forwarderName, forwarderReference, onForwarderReferenceChange }) => {
  return (
    <div className="flex items-center" data-testid="forwarder-info-header">
      Forwarded by <span className="pl-1">{forwarderName}</span>
      {forwarderReference && (
        <>
          <div className="mx-3 text-grey-lighter">&bull;</div>
          <div className="mr-1">Ref: </div>
          <div className="flex">
            {onForwarderReferenceChange && (
              <InlineEditableField
                className="p-1"
                onUpdate={onForwarderReferenceChange}
                placeholder="Enter forwarder reference"
                value={forwarderReference}
              />
            )}
            {!onForwarderReferenceChange && forwarderReference}
          </div>
        </>
      )}
    </div>
  );
};

export default ForwarderInfoHeader;
