import type { FC } from 'react';
import * as Yup from 'yup';

import { getCargoAddressLabel } from '@zen/CollectionAndDelivery/collectionAndDeliveryHelper';
import type { CargoAddress } from '@zen/CollectionAndDelivery/types';
import { Form, FormInstance } from '@zen/Components/Form';
import { useNetworksAssignMutation } from '@zen/Networks/graphql';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, INetworksOrgLoc } from '@zen/Networks/types';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import { useMutationIteration } from '@zen/utils/hooks/useMutationIteration';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import ScheduleActionFormButtons from '../ScheduleActionFormButtons';

interface Props {
  accountUuid: string;
  assignment: AssignmentTypeValue;
  cargoIds: string[];
  field: string;
  onCancel?: () => void;
  onError?: (errorIds: string[], message: string) => void;
  onSuccess: () => void;
  values: CargoAddress[];
}

const SelectAddress: FC<Props> = (props) => {
  const { accountUuid, cargoIds, assignment, field, values, onCancel = () => {}, onSuccess, onError } = props;
  const [assignAction] = useNetworksAssignMutation();
  const { addSuccess } = useNotification();
  const label: string = `${removeSpecialCharactersAndUpperFirst(field)} from`;
  const multiCargo = cargoIds.length > 1;

  const handleError = (errorItems: string[]) => {
    if (!onError) {
      return;
    }
    const totalErrors = errorItems.length;
    const message = multiCargo ? `There was an error updating ${totalErrors} items` : 'There was an error updating this address';

    onError(errorItems, message);
  };

  const handleSuccess = (successResult: string) => {
    const message = multiCargo ? `${successResult} selected addresses have been updated` : 'Address has ben updated';

    addSuccess(message);
  };

  const { handleMutation, loading } = useMutationIteration<string>(cargoIds, handleSuccess, handleError);

  const handleSubmit = async (formValues: INetworksOrgLoc): Promise<IOkOrErrorResult> => {
    const mutate = (id: string) => {
      return assignAction({
        variables: {
          input: {
            assignmentName: assignment,
            targetId: id,
            targetType: AssignmentTargetTypeEnum.BOOKING_CARGO,
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            assignableId: formValues[field].id
          }
        }
      });
    };

    return handleMutation(mutate);
  };

  const validation = Yup.object().shape({
    [field]: Yup.object().nullable().required('Location is required.')
  });

  const renderFormButtons = () => <ScheduleActionFormButtons isSubmitting={loading} onCancel={onCancel} />;

  const getInitialValue = (addresses: CargoAddress[]) => {
    if (addresses?.length === 0) {
      return null;
    }

    const hasSameValues = addresses.every((address) => address.id === addresses[0].id);

    return hasSameValues ? { ...values[0], name: getCargoAddressLabel(values[0]) } : null;
  };

  return (
    <div className="max-w-lg">
      <div className="text-grey-dark leading-normal mb-4 font-bold">Select {field} address</div>
      <Form
        formButtons={renderFormButtons}
        formName="SelectAddressForm"
        initialValues={{ [field]: getInitialValue(values) }}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        validationSchema={validation}
      >
        {({ setFieldValue }: FormInstance<{ origin: string }>) => (
          <NetworksContactFormInput
            accountUuid={accountUuid}
            assignmentType={assignment}
            domainName={AssignmentTargetTypeEnum.BOOKING_CARGO}
            formTitle={`Add ${label}`}
            label={label}
            name={field}
          />
        )}
      </Form>
    </div>
  );
};

export default SelectAddress;
