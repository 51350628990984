import moment from 'moment';
import type { FC } from 'react';

import ScheduleActionFormButtons from '@zen/CollectionAndDelivery/Details/ScheduleActionFormButtons';
import type { ScheduleDetails, ScheduleItemType } from '@zen/CollectionAndDelivery/Details/types';
import {
  useAddDelayReasonMutation,
  useCompleteCollectionMutation,
  useCompleteDeliveryMutation
} from '@zen/CollectionAndDelivery/graphql';
import { Form } from '@zen/Components/Form';
import FormDatePicker from '@zen/Components/Form/FormDatePicker';
import { VoyageMilestoneNameEnum } from '@zen/graphql/types.generated';
import FormMilestoneDelayReasonFields from '@zen/Shipment/RouteProgress/VoyageMilestones/components/FormMilestoneDelayReasonFields';
import {
  getDateValidationMessage,
  getRangeValidationMessage,
  useMilestoneDateValidation
} from '@zen/Shipment/ShipmentDetailsPage/MilestoneDateProvider';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';
import { useMutationIteration } from '@zen/utils/hooks/useMutationIteration';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import { createValidationSchema } from './CompleteScheduleForm.validation';

interface DatePickerFormValue {
  description?: Optional<string>;
  reason?: Optional<string>;
  selectedDate: string;
}

interface Props {
  cargoIds: string[];
  onCancel?: () => void;
  onError?: (errorIds: string[], message: string) => void;
  onSuccess: () => void;
  schedules: ScheduleDetails[];
  type: ScheduleItemType;
  zencargoReference: string;
}

const CompleteScheduleForm: FC<Props> = (props) => {
  const { cargoIds, zencargoReference, type, onCancel, onSuccess, schedules, onError } = props;
  const isCollection = type === 'collection';
  const action = isCollection ? 'collected' : 'delivered';
  const { addSuccess } = useNotification();
  const milestoneName: VoyageMilestoneNameEnum = isCollection
    ? VoyageMilestoneNameEnum.COLLECTED
    : VoyageMilestoneNameEnum.DELIVERED;
  const isActualDateUpdate: boolean = true;
  const { minDate, maxDate, isValid } = useMilestoneDateValidation(milestoneName, isActualDateUpdate);
  const multiCargo = cargoIds.length > 1;
  const allowDelayReasonEntry: boolean = schedules.some((schedule: ScheduleDetails) => schedule.scheduleDate);

  const handleError = (errorItems: string[]) => {
    const totalErrors = errorItems.length;
    const errorMessage = multiCargo
      ? `There was an error marking ${totalErrors} items as ${action}`
      : `There was an error making this cargo as ${action}`;

    onError?.(errorItems, errorMessage);
  };

  const handleSuccess = (successResult: string) => {
    const successMessage = multiCargo
      ? `${successResult} selected cargos have been marked as ${action}`
      : `Cargo marked as ${action}`;

    addSuccess(successMessage);
  };

  const { handleMutation, loading } = useMutationIteration(cargoIds, handleSuccess, handleError);

  const [completeCollection] = useCompleteCollectionMutation({
    refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
    awaitRefetchQueries: true
  });

  const [completeDelivery] = useCompleteDeliveryMutation({
    refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
    awaitRefetchQueries: true
  });

  const [addDelayReason] = useAddDelayReasonMutation();

  const scheduleInitialDate = schedules.length > 1 ? null : schedules[0]?.completedOn || schedules[0]?.scheduleDate;

  const handleMarkAsCollected = (collectedOn: string, id: string) => {
    return completeCollection({
      variables: {
        input: {
          zencargoReference,
          id,
          collectedOn
        }
      }
    });
  };

  const handleMarkAsDelivered = (deliveredOn: string, id: string) => {
    return completeDelivery({
      variables: {
        input: {
          zencargoReference,
          id,
          deliveredOn
        }
      }
    });
  };

  const markSchedule = isCollection ? handleMarkAsCollected : handleMarkAsDelivered;

  const handleSubmit = ({ selectedDate, reason, description }: DatePickerFormValue): Promise<IOkOrErrorResult> => {
    if (reason) {
      performMutation({
        mutationFn: () =>
          addDelayReason({
            variables: {
              zencargoReference,
              milestoneName,
              delay: { reason, description: description || '' }
            }
          }),
        onError: () => onError?.([], 'There was an error adding delay reason. Please try again.')
      });
    }

    const mutate = (id: string) => markSchedule(selectedDate, id);

    return handleMutation(mutate);
  };

  const getInitialdate = () => {
    const defaultDate = scheduleInitialDate || minDate;
    const isInitialDateInFuture = scheduleInitialDate ? moment().isBefore(defaultDate) : false;
    const isMultiSchedule = schedules.length > 1;

    return isInitialDateInFuture || isMultiSchedule ? moment().format(FORMAT_DATE_TRANSFERABLE) : defaultDate;
  };

  const renderFormButtons = () => <ScheduleActionFormButtons isSubmitting={loading} onCancel={() => onCancel?.()} />;

  const initialValues = {
    selectedDate: getInitialdate(),
    reason: null,
    description: null
  };

  return (
    <div>
      <div className="text-grey-dark leading-normal mb-4 font-bold">Confirm {type} date</div>
      <Form
        formButtons={renderFormButtons}
        formName="CompleteScheduleForm"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        validationSchema={createValidationSchema()}
      >
        {() => (
          <>
            <FormDatePicker
              disabledDayTooltip={getDateValidationMessage(minDate, maxDate)}
              helperText={getRangeValidationMessage(!isValid)}
              hideLabel={true}
              maxDate={maxDate}
              minDate={minDate}
              name="selectedDate"
            />
            {allowDelayReasonEntry && <FormMilestoneDelayReasonFields />}
          </>
        )}
      </Form>
    </div>
  );
};

export default CompleteScheduleForm;
