import { capitalize } from 'lodash';
import type { FC } from 'react';

import ScheduleActionFormButtons from '@zen/CollectionAndDelivery/Details/ScheduleActionFormButtons';
import type { ScheduleItemType } from '@zen/CollectionAndDelivery/Details/types';
import { useUpdateCollectionMutation, useUpdateDeliveryMutation } from '@zen/CollectionAndDelivery/graphql';
import { Form, FormInput } from '@zen/Components/Form';
import { isRoadShipment, ModeOfTransport } from '@zen/Shipments';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import { useMutationIteration } from '@zen/utils/hooks/useMutationIteration';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { shouldSynchroniseRoadDetails } from '../helpers';
import ScheduleRoadFields from '../ScheduleRoadFields';
import type { ScheduleDetailsValues } from './types';

export interface Props {
  cargoIds: string[];
  initialValues: ScheduleDetailsValues;
  modeOfTransport: ModeOfTransport;
  onCancel: () => void;
  onError?: (errorIds: string[], message: string) => void;
  onSuccess: () => void;
  scheduleType: ScheduleItemType;
  zencargoReference: string;
}

const ScheduleDetailsForm: FC<Props> = (props) => {
  const { initialValues, cargoIds, modeOfTransport, scheduleType, onSuccess, onCancel, onError, zencargoReference } = props;
  const { addSuccess, addError } = useNotification();

  const [updateCollectionMutation] = useUpdateCollectionMutation({
    refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
    awaitRefetchQueries: true
  });
  const [updateDeliveryMutation] = useUpdateDeliveryMutation({
    refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
    awaitRefetchQueries: true
  });

  const multiCargo: boolean = cargoIds.length > 1;

  const handleError = (errorItems: string[]): void => {
    const totalErrors: number = errorItems.length;
    const errorMessage: string = multiCargo
      ? `There was an error updating ${totalErrors} items`
      : `There was an error updating ${scheduleType} details`;

    onError?.(errorItems, errorMessage);
  };

  const handleSuccess = (successResult: string): void => {
    const successMessage: string = multiCargo
      ? `${successResult} selected items have been updated`
      : `${capitalize(scheduleType)} details have been updated`;

    addSuccess(successMessage);
  };

  const { handleMutation, loading } = useMutationIteration(cargoIds, handleSuccess, handleError);

  const handleUpdate = async (values: ScheduleDetailsValues): Promise<IOkOrErrorResult> => {
    const { driverDetails, reference, vehiclePlateNumber } = values;

    const mutate = (cargoId: string) => {
      if (scheduleType === 'collection') {
        return updateCollectionMutation({
          variables: {
            input: {
              cargoId,
              driverDetails,
              collectionReference: reference,
              vehiclePlateNumber,
              zencargoReference
            }
          }
        });
      }

      return updateDeliveryMutation({
        variables: {
          input: {
            cargoId,
            driverDetails,
            deliveryReference: reference,
            vehiclePlateNumber,
            zencargoReference
          }
        }
      });
    };

    return handleMutation(mutate);
  };

  const synchroniseRoadDetails = async (values: ScheduleDetailsValues) => {
    const { driverDetails, driverDetailsEqual, vehiclePlateNumber, vehiclePlateNumberEqual } = values;

    const results = cargoIds.map((cargoId: string) => {
      const mutation = scheduleType === 'collection' ? updateDeliveryMutation : updateCollectionMutation;

      return performMutation({
        mutationFn: () =>
          mutation({
            variables: {
              input: {
                zencargoReference,
                cargoId: cargoId!,
                driverDetails: driverDetailsEqual ? driverDetails : undefined,
                vehiclePlateNumber: vehiclePlateNumberEqual ? vehiclePlateNumber : undefined
              }
            }
          }),
        onError: () => addError()
      });
    });

    return Promise.all(results);
  };

  const handleSubmit = async (values: ScheduleDetailsValues): Promise<IOkOrErrorResult> => {
    await handleUpdate(values);

    if (shouldSynchroniseRoadDetails(values)) {
      await synchroniseRoadDetails(values);
    }

    return Promise.resolve({ ok: true, error: null });
  };

  const renderFormButtons = () => <ScheduleActionFormButtons isSubmitting={loading} onCancel={onCancel} />;

  return (
    <Form
      formButtons={renderFormButtons}
      formName="ScheduleDetailsForm"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      {() => (
        <>
          <div className="text-grey-dark leading-normal mb-4 font-bold">Update {scheduleType} details</div>

          <FormInput
            className="w-72"
            label={`${removeSpecialCharactersAndUpperFirst(scheduleType)} reference`}
            name="reference"
            placeholder="e.g. HG123354"
          />
          {isRoadShipment(modeOfTransport) && <ScheduleRoadFields isBulkUpdateEnabled={true} />}
        </>
      )}
    </Form>
  );
};

export default ScheduleDetailsForm;
