import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetBookingStaleDataQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetBookingStaleDataQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              staleData?: GraphQLTypes.Maybe<
                { __typename?: 'BookingStaleData' } & Pick<GraphQLTypes.BookingStaleData, 'enabled' | 'freshnessThreshold'> & {
                    canReconfirm: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                    dataUpdatedAt?: GraphQLTypes.Maybe<
                      { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'dateTime'>
                    >;
                  }
              >;
            }
        >
      >
    >;
  };
};

export const GetBookingStaleDataDocument = /* #__PURE__ */ gql`
  query getBookingStaleData($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        staleData {
          canReconfirm {
            value
          }
          enabled
          freshnessThreshold
          dataUpdatedAt {
            dateTime
          }
        }
      }
    }
  }
`;

/**
 * __useGetBookingStaleDataQuery__
 *
 * To run a query within a React component, call `useGetBookingStaleDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingStaleDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingStaleDataQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetBookingStaleDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetBookingStaleDataQueryResult, GetBookingStaleDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetBookingStaleDataQueryResult, GetBookingStaleDataQueryVariables>(GetBookingStaleDataDocument, options);
}
export function useGetBookingStaleDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookingStaleDataQueryResult, GetBookingStaleDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetBookingStaleDataQueryResult, GetBookingStaleDataQueryVariables>(
    GetBookingStaleDataDocument,
    options
  );
}
export type GetBookingStaleDataQueryHookResult = ReturnType<typeof useGetBookingStaleDataQuery>;
export type GetBookingStaleDataLazyQueryHookResult = ReturnType<typeof useGetBookingStaleDataLazyQuery>;
