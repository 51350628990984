import { LanguageTag } from '@zen/utils/languageSettings';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { parseAndFormatDate } from '../jodaConnector';
import type { DateFormat, DateFormatOptions, DateTimeLocaleAndOptions } from '../types';

const dateFormats: DateFormatOptions = {
  MMM_D_YYYY: {
    locale: LanguageTag.AmericanEnglish,
    options: { year: 'numeric', month: 'short', day: 'numeric' }
  },
  D_MMMM_YYYY: {
    locale: LanguageTag.BritishEnglish,
    options: { day: 'numeric', month: 'long', year: 'numeric' }
  },
  MMM_D: {
    locale: LanguageTag.AmericanEnglish,
    options: { month: 'short', day: 'numeric' }
  },
  DDDD: {
    locale: LanguageTag.BritishEnglish,
    options: { weekday: 'long' }
  }
};

const getDateFormat = (formatString: DateFormat): DateTimeLocaleAndOptions => {
  return dateFormats[formatString];
};

export const formatDate = (inputString: string, dateFormatString: DateFormat = 'MMM_D_YYYY'): string => {
  const dateFormat: DateTimeLocaleAndOptions = getDateFormat(dateFormatString);

  return parseAndFormatDate(inputString, dateFormat) || '-';
};

export const formatDateRange = (
  startDate: string,
  endDate: Optional<string> = undefined,
  dateFormatString: DateFormat = 'MMM_D_YYYY'
): Nullable<string> => {
  const dateFormat: DateTimeLocaleAndOptions = getDateFormat(dateFormatString);
  const formattedStartDate = parseAndFormatDate(startDate, dateFormat);

  if (!endDate) {
    return formattedStartDate;
  }

  const formattedEndDate = parseAndFormatDate(endDate, dateFormat);

  if (!formattedEndDate || formattedStartDate === formattedEndDate) {
    return formattedStartDate;
  }

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const formatDateAsISO8601 = (inputString: string): Nullable<string> => {
  return parseAndFormatDate(inputString, undefined);
};
