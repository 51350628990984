import type { RoutesConfig } from './types';

export const ACCRUAL_ROUTE_PREFIX = '/dashboard/accruals';

interface AccrualRoutes extends RoutesConfig {
  accrualDetails: {
    getUrl: (accountId: string, zencargoReference: string, accrualId: string, templateId?: string) => string;
    path: string;
  };
  accrualIndex: {
    getUrl: (accountId: string, zencargoReference: string) => string;
    path: string;
  };
}

const accrualRoutes: AccrualRoutes = {
  accrualDetails: {
    path: `${ACCRUAL_ROUTE_PREFIX}/:accountId/:id/:accrualId`,
    getUrl: (accountId: string, zencargoReference: string, accrualId: string, templateId: string = ''): string => {
      const queryParams = templateId ? `?templateId=${templateId}` : '';

      return `${ACCRUAL_ROUTE_PREFIX}/${accountId}/${zencargoReference}/${accrualId}${queryParams}`;
    }
  },
  accrualIndex: {
    path: `${ACCRUAL_ROUTE_PREFIX}/:accountId/:id`,
    getUrl: (accountId: string, zencargoReference: string) => `${ACCRUAL_ROUTE_PREFIX}/${accountId}/${zencargoReference}`
  }
};

export default accrualRoutes;
