import type { FC } from 'react';

import ActionItemDetails from '@zen/ActivityFeed/components/ActionItemDetails/ActionItemDetails';
import { ActionItem, ActionItemStatus, ActivityFeedItemTypeEnum } from '@zen/ActivityFeed/types';
import { Button } from '@zen/DesignSystem';
import api from '@zen/utils/api';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  actionItem: ActionItem;
  actionItemType: ActivityFeedItemTypeEnum;
  createdAt: string;
  zencargoReference: string;
}

const ActionItemApproval: FC<Props> = (props) => {
  const { actionItem, actionItemType, createdAt, zencargoReference } = props;
  const { addError, addSuccess } = useNotification();

  const { status } = actionItem;
  const isActionable = status === ActionItemStatus.PENDING;

  const handleApprove = async () => {
    try {
      await api.approveActionItem({ zencargo_reference: zencargoReference, id: actionItem.id });
      addSuccess('You approved this item successfully.');
    } catch (err) {
      addError();
    }
  };

  return (
    <div className="flex justify-between">
      <ActionItemDetails actionItem={actionItem} actionItemType={actionItemType} createdAt={createdAt} />
      {isActionable && (
        <Button className="fs-af-approve-action-item" iconLeft="zicon-tick" onClick={handleApprove}>
          Approve
        </Button>
      )}
    </div>
  );
};

export default ActionItemApproval;
