import type { ReactNode } from 'react';

import type { BookingManager } from '@zen/Components/BookingCoordinatorSelect/types';
import type { TableColumn } from '@zen/DesignSystem';
import { TextLink } from '@zen/DesignSystem';

import type { OperationsCoordinatorItem } from './types';

export const getColumnsConfiguration = (
  onEdit: (zencargoReference: string, operationsCoordinator: BookingManager) => void
): TableColumn<OperationsCoordinatorItem>[] => {
  return [
    {
      key: 'zencargoReference',
      title: 'Zencargo reference'
    },
    {
      key: 'name',
      title: 'Operations coordinator',
      render: (_, { operationsManager: operationsCoordinator }: OperationsCoordinatorItem): ReactNode => {
        const coordinatorName: string = `${operationsCoordinator?.firstName || ''} ${operationsCoordinator?.lastName || ''}`;

        return coordinatorName;
      }
    },
    {
      key: 'edit',
      title: 'Action',
      render: (_, { zencargoReference, operationsManager: operationsCoordinator }: OperationsCoordinatorItem): ReactNode => {
        return <TextLink onClick={() => onEdit(zencargoReference, operationsCoordinator as BookingManager)}>Edit</TextLink>;
      },
      sortable: false,
      width: '100px'
    }
  ];
};

export default getColumnsConfiguration;
