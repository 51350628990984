import type { BusinessHours, TimeRange } from '@zen/Networks';
import { hasTimeRange } from '@zen/Networks/networksHelpers';
import { formatDate } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

import { TimeRangeEnum } from './TimeRange';
import type { TimeRangeMapping } from './types';

export const defaultAllDayTimeRange: TimeRange = {
  startTime: '08:00',
  endTime: '17:00'
};

export const getAllDayTimeRange = (date: Optional<string>, businessHours: Optional<BusinessHours>): TimeRange => {
  if (!date || !businessHours) {
    return defaultAllDayTimeRange;
  }

  const dayName = formatDate(date, 'DDDD').toLocaleLowerCase() as keyof BusinessHours;
  const timeRange: TimeRange = businessHours[dayName];

  return hasTimeRange(timeRange) ? timeRange : defaultAllDayTimeRange;
};

export const getTimeRangeMapping = (date: Optional<string>, businessHours: Optional<BusinessHours>): TimeRangeMapping => {
  return {
    [TimeRangeEnum.CUSTOM]: {
      startTime: '',
      endTime: ''
    },
    [TimeRangeEnum.MORNING]: {
      startTime: '08:00',
      endTime: '12:00'
    },
    [TimeRangeEnum.AFTERNOON]: {
      startTime: '12:00',
      endTime: '17:00'
    },
    [TimeRangeEnum.ALL_DAY]: getAllDayTimeRange(date, businessHours)
  };
};

const covertTimeRangeMappingToTimeMapping = (timeRangeMapping: TimeRangeMapping): Record<string, TimeRangeEnum> => {
  return Object.entries(timeRangeMapping).reduce(
    (prev, [key, { startTime, endTime }]) => ({
      ...prev,
      [`${startTime}_${endTime}`]: key
    }),
    {}
  );
};

export const calculateTimeRangeValue = (
  startTime: string,
  endTime: string,
  timeRangeMapping: TimeRangeMapping
): TimeRangeEnum => {
  const timeMapping = covertTimeRangeMappingToTimeMapping(timeRangeMapping);

  return timeMapping[`${startTime}_${endTime}`] || TimeRangeEnum.CUSTOM;
};
