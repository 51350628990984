import { uniq, uniqBy } from 'lodash';
import { FC, ReactChild, useState } from 'react';

import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import { prepareBasketItems } from '@zen/Components/OrderBasket/utils';
import type { OrderBasketLot, OrderListViewItem, OrderListViewLot } from '@zen/Orders/types';
import { getLotIds } from '@zen/Orders/utils';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  children: ReactChild;
  zencargoReference: string;
}

const LotAssignmentsBookingBasketProvider: FC<Props> = ({ children, zencargoReference }) => {
  const [availableOrderLotIds, setAvailableOrderLotIds] = useState<Record<string, string[]>>();
  const [initializedOrderIds, setInitializedOrderIds] = useState<string[]>([]);
  const [initialItemIds, setInitialItemIds] = useState<string[]>([]);
  const [items, setItems] = useState<OrderBasketLot[]>([]);

  const isSelected = (id: string): boolean => items.some((item) => item.id === id);

  const isOrderInitiallySelected = ({ lotsBookedCount, lotsTotalCount }: OrderListViewItem): boolean => {
    if (!lotsTotalCount) {
      return false;
    }

    if (lotsBookedCount === lotsTotalCount) {
      return true;
    }

    return false;
  };

  const isOrderSelected = (order: OrderListViewItem, orderLots: Optional<OrderListViewLot[]>): boolean => {
    if (!orderLots && initializedOrderIds.includes(order.id)) {
      return !!availableOrderLotIds?.[order.id].every(isSelected);
    }

    if (!orderLots || !initializedOrderIds.includes(order.id)) {
      return isOrderInitiallySelected(order);
    }

    return getLotIds(orderLots).every(isSelected);
  };

  const isOrderPartiallySelected = (order: OrderListViewItem, orderLots: Optional<OrderListViewLot[]>): boolean => {
    if (!orderLots && initializedOrderIds.includes(order.id)) {
      return !!availableOrderLotIds?.[order.id].some(isSelected) && !availableOrderLotIds?.[order.id].every(isSelected);
    }

    if (!orderLots) {
      return (
        !!order?.bookings?.some((booking): boolean => booking.zencargoReference === zencargoReference) &&
        !isOrderSelected(order, orderLots)
      );
    }

    const lotIds: string[] = getLotIds(orderLots);

    return lotIds.some(isSelected) && !lotIds.every(isSelected);
  };

  const initializeOrderLots = (orderLots: OrderListViewLot[]): void => {
    const orderId: string = orderLots[0].purchaseOrder.id;
    const filteredOrderLots: OrderListViewLot[] = orderLots.filter((lot) => lot.booking?.zencargoReference === zencargoReference);

    const newItemsIds: string[] = uniq([...initialItemIds, ...filteredOrderLots.map(({ id }) => id)]);
    const newOrderIds: string[] = uniq([...initializedOrderIds, orderId]);

    setInitialItemIds(newItemsIds);
    setInitializedOrderIds(newOrderIds);
    setAvailableOrderLotIds({ ...availableOrderLotIds, [orderId]: getLotIds(orderLots) });

    addItems(filteredOrderLots);
  };

  const addItems = (orderLots: OrderListViewLot[]): void => {
    const orderBasketLots: OrderBasketLot[] = prepareBasketItems(orderLots);
    const newItems: OrderBasketLot[] = uniqBy([...items, ...orderBasketLots], 'id');

    setItems(newItems);
  };

  const removeItems = (ids: string[]): void => setItems([...items.filter((i) => !ids.includes(i.id))]);

  const context = {
    addItems,
    initialItemIds,
    initializeOrderLots,
    isBasketMode: true,
    isOrderSelected,
    isOrderPartiallySelected,
    items,
    isSelected,
    removeItems
  };

  return <BasketContext.Provider value={context}>{children}</BasketContext.Provider>;
};

export default LotAssignmentsBookingBasketProvider;
