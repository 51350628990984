import cx from 'classnames';
import type { CSSProperties, FC, MutableRefObject } from 'react';

import useResizeObserver from '@zen/utils/hooks/useResizeObserver';
import type { Nullable, Optional, Undefinable } from '@zen/utils/typescript';

import type { StageIndicatorType } from './StageIndicator';
import StageIndicator from './StageIndicator/StageIndicator';

const calculatePathsMiddlePoint = (element: Optional<HTMLElement>): string => {
  if (!element) {
    return '50%';
  }

  const elementTopOffset: number = element.offsetTop;
  const elementHeight: number = element.offsetHeight;

  return `${elementTopOffset + elementHeight / 2}px`;
};

interface Props {
  isCompleted?: boolean;
  isCurrent?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  referenceElementRef: MutableRefObject<Nullable<HTMLElement>>;
}

const VerticalPathIndicators: FC<Props> = (props) => {
  const { isCompleted = false, isCurrent = false, isFirst = false, isLast = false, referenceElementRef } = props;
  const shouldRenderFullPath: boolean = !isFirst && !isLast;

  // reacts to element changes and rerenders if any dimension is changed
  useResizeObserver<Nullable<HTMLElement>>(referenceElementRef);

  const offsetTop = referenceElementRef?.current?.offsetTop;

  const pathClassNames: string = cx(
    {
      'bottom-0': isFirst,
      'top-0': isLast,
      'top-0 bottom-0': shouldRenderFullPath
    },
    'absolute w-px bg-grey-light'
  );

  const getPathStyles = (): Undefinable<CSSProperties> => {
    if (shouldRenderFullPath) {
      return;
    }

    const elementMiddleInPixels: string = calculatePathsMiddlePoint(referenceElementRef?.current);

    return isFirst ? { top: elementMiddleInPixels } : { height: elementMiddleInPixels };
  };

  const getIndicatorType = (): StageIndicatorType => {
    return isCompleted || isCurrent ? 'completed' : 'future';
  };

  return (
    <div className="absolute z-10 top-0 bottom-0 left-6">
      <div>
        <div className={pathClassNames} data-testid="top-progress-chunk" style={getPathStyles()} />
      </div>
      <div className="relative" data-testid="stage-icon" style={{ left: '-0.5rem', top: offsetTop }}>
        <StageIndicator type={getIndicatorType()} />
      </div>
    </div>
  );
};

export default VerticalPathIndicators;
