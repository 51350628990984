import accounting from 'accounting';

import { DimensionUnit, MetricValueWithSi, PackageInfo, ProductProperty } from '@zen/Products/types';
import type { Money } from '@zen/types';
import { formatNumber } from '@zen/utils/formatting';
import type { DeepNullable, Nullable, Optional } from '@zen/utils/typescript';

import type { ProductDetail } from './types';

export const renderPriceValue = (val?: Nullable<Money>): string => {
  if (val) {
    return accounting.formatMoney(val.value, { symbol: val.currency, format: '%v %s' });
  }

  return '- GBP';
};

export const renderUnitValue = (val?: Nullable<MetricValueWithSi>): string => (val ? `${val.value} ${val.unit}` : '-');

export const calculatePerUnitCbm = (packageInfo: DeepNullable<PackageInfo>, unitsPerMasterCarton: Optional<number>): string => {
  const { width, length, depth } = packageInfo;

  if (!width?.value || !length?.value || !depth?.value || !unitsPerMasterCarton) {
    return '';
  }

  const divider = width.unit === DimensionUnit.CM ? 1000000 : 1;
  const cbm = (width.value * length.value * depth.value) / divider;
  const calculatedPerUnitCbm = cbm / unitsPerMasterCarton;

  return formatNumber(calculatedPerUnitCbm, 10);
};

export const getPropertiesAttributes = (properties: ProductProperty[], categoryName: Optional<string>): ProductDetail[] => {
  const categoryProperty = [
    {
      key: 'Category',
      value: categoryName || 'No category assigned'
    }
  ];

  const otherProperties = properties.filter(({ name }) => name).map(({ name: key, value }) => ({ key, value }));

  return [...categoryProperty, ...otherProperties];
};
