import type { FC } from 'react';
import { Link } from 'react-router-dom';

import TooltipSwitch from '@zen/Components/TooltipSwitch';
import { Icon } from '@zen/DesignSystem';

interface Props {
  isEnabled: boolean;
  onChange: () => void;
}

const PreferencesIndicator: FC<Props> = ({ isEnabled, onChange }) => {
  const tooltip = (
    <div className="text-center">
      <div className="py-2 px-5" data-testid="preferences-title">
        This filters the page by categories and <br />
        manufacturers that are important to you
      </div>
      <Link className="block p-2 text-sm group" to="/dashboard/settings/preferences">
        <Icon className="mr-3" icon="zicon-settings" />
        <span className="group-hover:underline">Edit preferences</span>
      </Link>
    </div>
  );

  return <TooltipSwitch isEnabled={isEnabled} label="Filter by my preferences" onChange={onChange} tooltip={tooltip} />;
};

export default PreferencesIndicator;
