import type { RoutesConfig } from './types';

export const ACCOUNTS_ROUTE_PREFIX: string = '/dashboard/accounts';

interface AuthRoutes extends RoutesConfig {
  accountSwitcher: {
    getUrl: () => string;
    path: string;
  };
}

const authRoutes: AuthRoutes = {
  accountSwitcher: {
    path: '/account_switcher',
    getUrl: (): string => '/account_switcher'
  }
};

export default authRoutes;
