import { uniq, uniqBy } from 'lodash';
import { FC, ReactChild, useState } from 'react';

import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import type { OrderBasketLot, OrderListViewItem, OrderListViewLot } from '@zen/Orders/types';
import { getLotIds } from '@zen/Orders/utils';
import type { Optional } from '@zen/utils/typescript';

import { prepareBasketItems } from '../helpers';

interface Props {
  cargoId: string;
  children: ReactChild;
}

const LotAssignmentsCargoBasketProvider: FC<Props> = ({ cargoId, children }) => {
  const [availableOrderLotIds, setAvailableOrderLotIds] = useState<Record<string, string[]>>();
  const [initializedOrderIds, setInitializedOrderIds] = useState<string[]>([]);
  const [initialItemIds, setInitialItemIds] = useState<string[]>([]);
  const [items, setItems] = useState<OrderBasketLot[]>([]);

  const isSelected = (id: string): boolean => items.some((item) => item.id === id);

  const isOrderInitiallySelected = ({ lotsAvailableForCargoCount, lotsBookedForCargoCount }: OrderListViewItem): boolean => {
    if (lotsAvailableForCargoCount === lotsBookedForCargoCount) {
      return true;
    }

    return false;
  };

  const isOrderSelected = (order: OrderListViewItem, orderLots: Optional<OrderListViewLot[]>): boolean => {
    if (!orderLots && initializedOrderIds.includes(order.id)) {
      return !!availableOrderLotIds?.[order.id].every(isSelected);
    }

    if (!orderLots || !initializedOrderIds.includes(order.id)) {
      return isOrderInitiallySelected(order);
    }

    return getLotIds(orderLots).every(isSelected);
  };

  const isOrderPartiallySelected = (order: OrderListViewItem, orderLots: Optional<OrderListViewLot[]>): boolean => {
    if (!orderLots && initializedOrderIds.includes(order.id)) {
      return !!availableOrderLotIds?.[order.id].some(isSelected) && !availableOrderLotIds[order.id].every(isSelected);
    }

    if (!orderLots) {
      const bookedForCargoCount: number = order?.lotsBookedForCargoCount || 0;

      return order.lotsAvailableForCargoCount !== bookedForCargoCount && bookedForCargoCount > 0;
    }

    const lotIds: string[] = getLotIds(orderLots);

    return lotIds.some(isSelected) && !lotIds.every(isSelected);
  };

  const initializeOrderLots = (orderLots: OrderListViewLot[]): void => {
    const orderId: Optional<string> = orderLots[0].purchaseOrder?.id;
    const filteredOrderLots: OrderListViewLot[] = orderLots.filter((lot) => {
      const lotCargoId = lot.cargos?.id || lot.cargo?.id;

      return lotCargoId === cargoId && !initialItemIds.includes(lot.id);
    });

    if (!orderId) {
      return;
    }

    const newItemsIds: string[] = uniq([...initialItemIds, ...filteredOrderLots.map(({ id }) => id)]);
    const newOrderIds: string[] = uniq([...initializedOrderIds, orderId]);

    setInitialItemIds(newItemsIds);
    setInitializedOrderIds(newOrderIds);
    setAvailableOrderLotIds({ ...availableOrderLotIds, [orderId]: getLotIds(orderLots) });

    addItems(filteredOrderLots);
  };

  const addItems = (orderLots: OrderListViewLot[]): void => {
    const orderBasketLots: OrderBasketLot[] = prepareBasketItems(orderLots);
    const newItems: OrderBasketLot[] = uniqBy([...items, ...orderBasketLots], 'id');

    setItems(newItems);
  };

  const removeItems = (ids: string[]): void => setItems([...items.filter((i) => !ids.includes(i.id))]);

  const removeAllItems = (): void => {
    setItems([]);
    setInitialItemIds([]);
    setInitializedOrderIds([]);
    setAvailableOrderLotIds({});
  };

  const resetBasket = (): void => {
    const newInitialItemIds: string[] = items.map(({ id }) => id);

    setInitialItemIds(newInitialItemIds);
  };

  const context = {
    addItems,
    initialItemIds,
    initializedOrderIds,
    initializeOrderLots,
    isBasketMode: true,
    isOrderSelected,
    isOrderPartiallySelected,
    items,
    isSelected,
    removeItems,
    removeAllItems,
    resetBasket
  };

  return <BasketContext.Provider value={context}>{children}</BasketContext.Provider>;
};

export default LotAssignmentsCargoBasketProvider;
