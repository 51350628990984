import type { FC } from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';

import { trackedShipmentRoutes } from '@zen/Routes';
import ShipmentDetailsPageContainer from '@zen/Shipment/ShipmentDetailsPageContainer';
import useAccount from '@zen/utils/hooks/useAccount';

import TrackedShipments from './TrackedShipments';

const TrackedShipmentsContainer: FC = () => {
  const { accountUuid: customerId } = useAccount();

  return (
    <Switch>
      <Route path={trackedShipmentRoutes.trackedShipmentDetails.path}>
        <ShipmentDetailsPageContainer />
      </Route>
      <Route path={trackedShipmentRoutes.trackedShipmentIndex.path}>
        <TrackedShipments key={customerId} />
      </Route>
    </Switch>
  );
};

export default TrackedShipmentsContainer;
