import moment from 'moment';

import { AmPm, SpecificTime } from '../../../types';

type Moment = moment.Moment;

interface RiskInput {
  estimatedDeparture: string;
  freeTimeBegins: string;
}

class CollectionRisk {
  private riskInput: RiskInput;

  constructor(riskInput: RiskInput) {
    this.riskInput = riskInput;
  }

  public dateRisk(d: string): string {
    const date = moment.utc(d);

    if (date.isBefore(this.riskInput.freeTimeBegins)) {
      return 'You will incur demurrage if you select this date';
    }

    if (this.isWeekend(date)) {
      return 'Selecting a weekend might increase collection costs';
    }

    if (moment(this.riskInput.estimatedDeparture).isValid()) {
      if (this.stripDate(d) === this.stripDate(this.riskInput.estimatedDeparture)) {
        return 'You may miss the vessel on this date';
      }

      const missDate = moment.utc(this.riskInput.estimatedDeparture).subtract(1, 'days').format();

      if (this.stripDate(d) === this.stripDate(missDate)) {
        return 'You may miss the vessel on this date';
      }
    }

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    return null;
  }

  public timeRisk(t: SpecificTime): string {
    if (
      (t.hour < 9 && t.ampm === AmPm.AM) ||
      (t.hour === 12 && t.ampm === AmPm.AM) ||
      (t.hour >= 5 && t.hour < 12 && t.ampm === AmPm.PM)
    ) {
      return 'The selected collection option may be subject to surcharge';
    }

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    return null;
  }

  private stripDate(date: string) {
    const d = moment.utc(date);

    return d.startOf('day').get('date');
  }

  private isWeekend(d: Moment) {
    return d.isoWeekday() === 6 || d.isoWeekday() === 7;
  }
}

export default CollectionRisk;
