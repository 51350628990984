import type { FC, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string;
  title?: string;
}

const InlineEmptyState: FC<Props> = ({ children, className = '', title }) => {
  return (
    <div className={`text-grey-base text-sm text-left leading-relaxed ${className}`} data-testid="inline-empty-state">
      {title && <div className="font-bold text-grey-dark">{title}</div>}
      {children}
    </div>
  );
};

export default InlineEmptyState;
export type { Props as InlineEmptyStateProps };
