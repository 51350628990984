import type { FC, ReactNode } from 'react';

import type { BusinessHours, TimeRange } from '@zen/Networks';
import { formatTimeRange } from '@zen/utils/dateTime';

const daysOfTheWeek: Array<keyof BusinessHours> = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

interface Props {
  businessHours: BusinessHours;
}

const LocationBusinessHours: FC<Props> = ({ businessHours }) => {
  const renderBusinessHours = (dayOfTheWeek: keyof BusinessHours, index: number): ReactNode => {
    const timeRange: TimeRange = businessHours[dayOfTheWeek];

    return (
      <div key={index} className="flex mb-2">
        <div className="w-24 capitalize">{dayOfTheWeek}:</div>
        <div data-testid={`${dayOfTheWeek}-business-hours`}>
          {formatTimeRange(timeRange.startTime, timeRange.endTime, false) || '-'}
        </div>
      </div>
    );
  };

  return (
    <div data-testid="location-business-hours">
      <div className="font-bold py-4">Opening hours</div>
      {daysOfTheWeek.map(renderBusinessHours)}
    </div>
  );
};

export default LocationBusinessHours;
