import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import { useBookingRequestsUpdateForwarderApprovedMutation } from '@zen/BookingRequests/graphql';
import QueryHandler from '@zen/Components/QueryHandler';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import BookingManagementSwitches from '../components/BookingManagementSwitches';
import SkeletonMilestones from '../VoyageMilestones/Loading/SkeletonMilestones';
import { useGetBookingManagementSettingsQuery, useUpdatePricingRequiredMutation } from './graphql';
import type { BookingManagementNode, BookingManagementPermissons, BookingManagementSettingsResults } from './types';

interface Props {
  onSettingsChanged?: () => void;
  zencargoReference: string;
}

const BookingManagement: FC<Props> = ({ onSettingsChanged, zencargoReference }) => {
  const {
    data: bookingManagementData,
    loading,
    error,
    refetch
  } = useGetBookingManagementSettingsQuery({
    variables: {
      zencargoReference
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const { addError, addSuccess } = useNotification();

  const [approveRequestForwarder, { loading: updateForwarderApprovalLoading }] =
    useBookingRequestsUpdateForwarderApprovedMutation();
  const [updatePricingRequired, { loading: updatePricingIsLoading }] = useUpdatePricingRequiredMutation();

  const handleSuccess = (settingType: string): void => {
    addSuccess(`${settingType} settings has been updated successfully`);
    handleSettingsChange();
  };

  const handlePricingRequiredToggle = async (value: boolean): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        updatePricingRequired({
          variables: {
            input: {
              zencargoReference,
              pricingRequired: value
            }
          },
          refetchQueries: ['getShipmentDetailsPageData'],
          awaitRefetchQueries: true
        }),
      onError: () => addError(),
      onSuccess: () => handleSuccess('Pricing')
    });
  };

  const handleForwarderApprovedToggle = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        approveRequestForwarder({
          variables: {
            input: {
              zencargoReference,
              forwarderApproved: false,
              customerApprovalRequired: false
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => handleSuccess('Forwarder')
    });
  };

  const getPermissions = (bookingDetails: BookingManagementNode): BookingManagementPermissons => {
    const canUpdateForwarderApproved: boolean = bookingDetails.bookingRequest
      ? checkPermission(bookingDetails.bookingRequest, 'canUpdateForwarderApproved')
      : false;

    const canUpdatePricingRequired: boolean = checkPermission<BookingManagementNode>(bookingDetails, 'canUpdatePricingRequired');
    const canViewForwarderApproved: boolean = checkPermission<BookingManagementNode>(bookingDetails, 'canViewForwarderApproved');

    return { canUpdateForwarderApproved, canUpdatePricingRequired, canViewForwarderApproved };
  };

  const handleSettingsChange = (): void => {
    refetch();

    if (onSettingsChanged) {
      onSettingsChanged();
    }
  };

  return (
    <QueryHandler
      data={bookingManagementData?.bookings.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonMilestones />}
    >
      {(bookingDetails: BookingManagementNode) => {
        const bookingManagementSettings: BookingManagementSettingsResults = bookingDetails?.bookingManagementSettings;

        return (
          <>
            <div className="text-sm font-bold mr-6 mb-2 text-grey-dark leading-normal">Booking details</div>
            <div className="flex border-solid border-grey-lighter border-b pb-5 mb-6">
              <BookingManagementSwitches
                isForwarderApproved={!!bookingDetails?.bookingRequest?.forwarderApproved}
                isLoading={updatePricingIsLoading || updateForwarderApprovalLoading}
                isPricingRequired={!!bookingManagementSettings?.pricingRequired}
                onForwarderSuccess={() => handleSuccess('Forwarder')}
                onForwarderToggleChange={handleForwarderApprovedToggle}
                onPricingRequiredToggle={handlePricingRequiredToggle}
                permissions={getPermissions(bookingDetails)}
                zencargoReference={zencargoReference}
              />
            </div>
          </>
        );
      }}
    </QueryHandler>
  );
};

export default BookingManagement;
