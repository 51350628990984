import { useRemoveTransportScheduleMutation } from '@zen/Shipment/TransportSchedule/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

export const useRemoveTransportSchedule = () => {
  const { addError, addSuccess } = useNotification();

  const [removeTransportScheduleMutation, { loading: isRemoving }] = useRemoveTransportScheduleMutation();

  const removeTransportSchedule = async (
    zencargoReference: string,
    transportScheduleId: string,
    onSuccess?: () => void
  ): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        removeTransportScheduleMutation({
          variables: { input: { transportScheduleId, zencargoReference } },
          refetchQueries: ['getShipmentDetailsBoard']
        }),
      onError: () => addError('There was an error removing new transport schedule')
    });

    onSuccess?.();
    addSuccess(`Transport schedule removed from ${zencargoReference}`);
  };

  return { removeTransportSchedule, isRemoving };
};
