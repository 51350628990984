import type { FC } from 'react';

import SkeletonLoading from '@zen/Components/SkeletonLoading';

const MapSkeleton: FC = () => {
  return (
    <div className="flex w-full">
      <div className="grid grid-cols-1 gap-8 mr-6">
        {[...Array(3)].map((_, index: number) => (
          <div
            key={index}
            className="w-56 border border-solid border-grey-lighter rounded px-3 py-4"
            data-testid="filters-skeleton"
          >
            <SkeletonLoading height={10} width="w-32" />
            <SkeletonLoading className="mt-2" height={16} width="w-4" />
          </div>
        ))}
      </div>
      <div className="flex border border-solid border-grey-lighter rounded pt-2 pb-3 px-1 flex-1" data-testid="map-skeleton">
        <SkeletonLoading height="100%" width="w-full" />
      </div>
    </div>
  );
};

export default MapSkeleton;
