import type { OrderBasketLot, OrderListViewLot } from '../types';

export const prepareBasketItems = (orderLots: OrderListViewLot[]): OrderBasketLot[] => {
  return orderLots.map((lot: OrderListViewLot): OrderBasketLot => {
    const { cbm, id, orderedLineItem, purchaseOrder, quantityFulfilled } = lot;

    return {
      id,
      orderId: purchaseOrder?.id,
      orderReferenceNumber: purchaseOrder?.orderReferenceNumber || '',
      quantityFulfilled,
      cbm,
      manufacturerName: purchaseOrder?.manufacturer?.label?.short,
      productName: orderedLineItem?.product?.name,
      productSkuCode: orderedLineItem?.product?.skuCode
    };
  });
};
