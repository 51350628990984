import { isEqual, omitBy } from 'lodash';
import moment from 'moment';
import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInstance } from '@zen/Components/Form';
import FormChipSelect from '@zen/Components/Form/FormChipSelect';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import FormRolePermissionToggles from '@zen/Components/Form/FormRolePermissionToggles';
import FormSelect from '@zen/Components/Form/FormSelect';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import { Button } from '@zen/DesignSystem';
import type { IssueAssociatedObject } from '@zen/Shipment/types';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { IssueFlatData, IssueSeverity, IssueState, IssueStateInput, IssueVisibility } from '../../types';
import { getIssueTypeOptions, severityOptions, stateOptions } from '../issue.form.helpers';
import { issueFormInitialValues } from './helpers';
import { createSchema } from './IssueForm.validations';

export interface IssueSubmitData extends Omit<IssueFlatData, 'state'> {
  state: IssueStateInput; // opening issue allows only two of available states to be used
}

interface Props {
  allowChooseState?: boolean;
  allowEditCategory?: boolean;
  allowEditProblemDescription?: boolean;
  allowEditVisibility?: boolean;
  associatedType?: IssueAssociatedObject;
  initialValues?: IssueFlatData;
  onCancel: () => void;
  onSubmit: (values: IssueSubmitData, changedValues?: Partial<IssueSubmitData>) => void;
  onSuccess?: () => void;
}

const IssueForm: FC<Props> = ({
  initialValues = issueFormInitialValues,
  allowChooseState = true,
  allowEditCategory = true,
  allowEditProblemDescription = true,
  allowEditVisibility = true,
  onSubmit,
  onCancel,
  onSuccess,
  associatedType
}) => {
  const handleSubmit = (values: IssueSubmitData): Promise<IOkOrErrorResult> => {
    const changedValues = omitBy(values, (value, key) => {
      return isEqual(initialValues[key as keyof IssueFlatData], value);
    });

    onSubmit(values, changedValues);

    return Promise.resolve({ ok: true, error: null });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<IssueFlatData>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Save">
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  const buildVisibilityLabel = (issueVisibility: IssueVisibility) => {
    if (!issueVisibility) {
      return;
    }

    const isVisibleToMoreThanAdmin = !!Object.keys(issueVisibility)
      .filter((key: string) => key !== '__typename')
      .find((key: string) => {
        const value = issueVisibility[key as keyof IssueVisibility];

        return typeof value === 'boolean' && value;
      });

    return (
      <>
        <span className="mr-1">Issue visible to...</span>
        {isVisibleToMoreThanAdmin && (
          <span className="font-normal text-red-base text-xs">
            - This will make the content above visible to the selected parties
          </span>
        )}
      </>
    );
  };

  const maxOccurredDate = moment().format(FORMAT_DATE_TRANSFERABLE);
  const initialOccurredDate = initialValues?.occurredOn ? null : moment().format(FORMAT_DATE_TRANSFERABLE);

  return (
    <Form
      className="w-160"
      formButtons={renderFormButtons}
      formName="IssueForm"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={createSchema()}
    >
      {({ values }: FormInstance<IssueFlatData>) => {
        return (
          <>
            <div className="flex">
              <FormSelect
                className="w-1/2"
                isDisabled={!allowEditCategory}
                label="Select an issue type"
                name="category"
                options={getIssueTypeOptions(associatedType)}
              />
              <FormChipSelect<IssueSeverity>
                className="w-1/2 ml-12"
                label="Select a severity"
                name="severity"
                options={severityOptions}
              />
            </div>
            <div className="flex">
              {allowChooseState && (
                <FormChipSelect<IssueState> className="flex-1" label="Status of the issue" name="state" options={stateOptions} />
              )}
              <FormDatePickerInput
                className="flex-1"
                initialDate={initialOccurredDate}
                label="Date issue began"
                maxDate={maxOccurredDate}
                name="occurredOn"
              />
            </div>
            <FormTextarea
              disabled={!allowEditProblemDescription}
              label="What is the issue?"
              name="problemDescription"
              textareaRows={4}
            />
            <FormTextarea label="What action is required?" name="solutionDescription" textareaRows={4} />
            {allowEditVisibility && (
              <FormRolePermissionToggles label={buildVisibilityLabel(values.visibility)} name="visibility" />
            )}
          </>
        );
      }}
    </Form>
  );
};

export default IssueForm;
