import cx from 'classnames';
import type { FC, MouseEvent, MouseEventHandler } from 'react';

import { useHover } from '@zen/utils/hooks/useHover';

import Icon from '../Icon';

interface Props {
  className?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  label: string;
  onDelete?: MouseEventHandler<HTMLDivElement> | undefined;
  onSelect?: () => void;
}

const Chip: FC<Props> = (props) => {
  const { className, label, onDelete, onSelect, isDisabled, isSelected } = props;

  const [ref, isHovered] = useHover();

  const chipClassNames: string = cx(
    {
      'active:bg-azure-light': !isDisabled,
      'bg-grey-lighter text-grey-dark': !isDisabled && !isHovered,
      'bg-azure-base text-white': !isDisabled && isHovered,
      'bg-grey-lightest text-grey-base cursor-not-allowed': isDisabled
    },
    'text-sm inline-flex items-center leading-tight p-2 rounded mr-4 my-1 cursor-pointer',
    className
  );

  const handleClick = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>): void => {
    if (isDisabled) {
      return;
    }

    if (isSelected && onDelete) {
      onDelete(event);
    } else if (onSelect) {
      onSelect();
    }
  };

  return (
    <div
      ref={ref}
      className={chipClassNames}
      data-component="chip"
      data-testid={isSelected ? 'selected-chip' : 'chip'}
      onClick={handleClick}
    >
      <span className="px-1 whitespace-nowrap">{label}</span>
      {isSelected && onDelete && <Icon className="ml-2" icon="zicon-close" />}
    </div>
  );
};

export type { Props as ChipProps };

export default Chip;
