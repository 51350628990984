import type { FC } from 'react';

import { ActivityFeedTargetTypeEnum } from '@zen/ActivityFeed/types';

import ActivityFeedItemWithIconWrapper from '../ActivityFeedItemWithIconWrapper';
import { ReactComponent as Bubbles } from './bubbles.svg';

interface Props {
  targetType: ActivityFeedTargetTypeEnum;
}

const EmptyState: FC<Props> = ({ targetType }) => {
  const label: string = targetType === ActivityFeedTargetTypeEnum.BOOKING ? 'booking' : 'order';

  return (
    <div className="my-10">
      <ActivityFeedItemWithIconWrapper backgroundIcon={<Bubbles />} className="group" iconClassName="-my-20 w-48">
        <div className="p-6 leading-normal w-2/3 ml-5">
          We automatically create an activity feed for every {label} you create so you can keep a log of{' '}
          <b>all the decisions you make</b>, <b>actions that are taken </b>
          and <b>milestones that are hit</b> in one continuous thread.
        </div>
      </ActivityFeedItemWithIconWrapper>
    </div>
  );
};

export default EmptyState;
