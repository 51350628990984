import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCarriersQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetCarriersQueryResult = { __typename?: 'Query' } & {
  carriers?: GraphQLTypes.Maybe<Array<{ __typename?: 'Carrier' } & Pick<GraphQLTypes.Carrier, 'name' | 'scacCode'>>>;
};

export const GetCarriersDocument = /* #__PURE__ */ gql`
  query getCarriers {
    carriers {
      name
      scacCode
    }
  }
`;

/**
 * __useGetCarriersQuery__
 *
 * To run a query within a React component, call `useGetCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarriersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCarriersQuery(baseOptions?: Apollo.QueryHookOptions<GetCarriersQueryResult, GetCarriersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCarriersQueryResult, GetCarriersQueryVariables>(GetCarriersDocument, options);
}
export function useGetCarriersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCarriersQueryResult, GetCarriersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCarriersQueryResult, GetCarriersQueryVariables>(GetCarriersDocument, options);
}
export type GetCarriersQueryHookResult = ReturnType<typeof useGetCarriersQuery>;
export type GetCarriersLazyQueryHookResult = ReturnType<typeof useGetCarriersLazyQuery>;
