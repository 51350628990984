import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetConsolidatedShipmentLegsQueryVariables = GraphQLTypes.Exact<{
  consolidationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetConsolidatedShipmentLegsQueryResult = { __typename?: 'Query' } & {
  consolidations?: GraphQLTypes.Maybe<
    { __typename?: 'ConsolidatedShipmentConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'ConsolidatedShipment' } & Pick<GraphQLTypes.ConsolidatedShipment, 'reference'> & {
                legs: Array<
                  { __typename?: 'ConsolidationLegType' } & Pick<
                    GraphQLTypes.ConsolidationLegType,
                    'id' | 'estimatedDepartureDate' | 'actualDepartureDate' | 'estimatedArrivalDate' | 'actualArrivalDate'
                  > & {
                      networksDestination:
                        | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            })
                        | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            })
                        | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            });
                      networksOrigin:
                        | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            })
                        | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            })
                        | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            });
                    }
                >;
              }
          >
        >
      >;
    }
  >;
};

export const GetConsolidatedShipmentLegsQueryDocument = /* #__PURE__ */ gql`
  query getConsolidatedShipmentLegsQuery($consolidationId: String) {
    consolidations(consolidatedShipmentId: $consolidationId) {
      nodes {
        reference
        legs {
          id
          estimatedDepartureDate
          actualDepartureDate
          estimatedArrivalDate
          actualArrivalDate
          networksDestination {
            id
            label {
              long
            }
          }
          networksOrigin {
            id
            label {
              long
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetConsolidatedShipmentLegsQuery__
 *
 * To run a query within a React component, call `useGetConsolidatedShipmentLegsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsolidatedShipmentLegsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsolidatedShipmentLegsQuery({
 *   variables: {
 *      consolidationId: // value for 'consolidationId'
 *   },
 * });
 */
export function useGetConsolidatedShipmentLegsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetConsolidatedShipmentLegsQueryResult, GetConsolidatedShipmentLegsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetConsolidatedShipmentLegsQueryResult, GetConsolidatedShipmentLegsQueryVariables>(
    GetConsolidatedShipmentLegsQueryDocument,
    options
  );
}
export function useGetConsolidatedShipmentLegsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConsolidatedShipmentLegsQueryResult, GetConsolidatedShipmentLegsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetConsolidatedShipmentLegsQueryResult, GetConsolidatedShipmentLegsQueryVariables>(
    GetConsolidatedShipmentLegsQueryDocument,
    options
  );
}
export type GetConsolidatedShipmentLegsQueryHookResult = ReturnType<typeof useGetConsolidatedShipmentLegsQuery>;
export type GetConsolidatedShipmentLegsQueryLazyQueryHookResult = ReturnType<typeof useGetConsolidatedShipmentLegsQueryLazyQuery>;
