import type { FC } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Route } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import Search from '@zen/Components/Search';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Pagination, Table } from '@zen/DesignSystem';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import { consolidationRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import { performMutation } from '@zen/utils/performMutation';

import { useNotification } from '../../utils/hooks/useNotification';
import type { Undefinable } from '../../utils/typescript';
import { useUpdateBookingsAssignedToConsolidatedShipmentMutation } from '../graphql';
import { getColumnsConfiguration } from './consolidationAvailableShipmentsTableConfiguration';
import ConsolidationManageShipmentsButtons from './ConsolidationManageShipmentsButtons';
import useConsolidationAvailableShipments from './hooks/useConsolidationAvailableShipments';
import ShipmentsForConsolidationList from './ShipmentsForConsolidationList';
import type { ConsolidationShipment } from './types';

const ConsolidationAddShipments: FC = () => {
  const { consolidationId } = useParams<{ consolidationId: string }>();
  const { navigateBack } = useNavigationHistory();
  const { addSuccess, addError } = useNotification();
  const [updateConsolidationBookings] = useUpdateBookingsAssignedToConsolidatedShipmentMutation();

  const [searchText, setSearchText] = useState<string>('');
  const { nodes, loading: isLoading, error, paginationInfo } = useConsolidationAvailableShipments(searchText);
  const [shipmentsForConsolidation, saveShipmentForConsolidation] = useLocalStorage<Record<string, string[]>>(
    'shipmentsForConsolidation',
    {}
  );

  const selectedShipmentsCollection: Undefinable<string[]> = shipmentsForConsolidation?.[consolidationId];

  const isShipmentSelected = (zencargoReference: string): boolean => !!selectedShipmentsCollection?.includes(zencargoReference);

  const handleShipmentSelect = (zencargoReference: string): void => {
    const getNewAvaibaleShipmentsList = (): string[] => {
      if (!selectedShipmentsCollection) {
        return [zencargoReference];
      }

      const isShipmentAlreadySelected: boolean = isShipmentSelected(zencargoReference);

      if (isShipmentAlreadySelected) {
        return selectedShipmentsCollection.filter((shipment: string) => shipment !== zencargoReference);
      }

      return [...selectedShipmentsCollection, zencargoReference];
    };

    saveShipmentForConsolidation({
      ...shipmentsForConsolidation,
      [consolidationId]: getNewAvaibaleShipmentsList()
    });
  };

  const clearConsolidationShipmentsSelection = async (): Promise<void> => {
    const newShipmentsForConsolidation = { ...shipmentsForConsolidation };

    delete newShipmentsForConsolidation[consolidationId];

    await saveShipmentForConsolidation(newShipmentsForConsolidation);
  };

  const handleCancelSelection = async (): Promise<void> => {
    await clearConsolidationShipmentsSelection();
    navigateBack(consolidationRoutes.consolidationShipments.getUrl(consolidationId));
  };

  const handleSaveSelection = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        updateConsolidationBookings({
          variables: {
            input: {
              consolidatedShipmentId: consolidationId,
              zencargoReferencesToAssign: selectedShipmentsCollection
            }
          }
        }),
      onError: addError,
      onSuccess: () => addSuccess('Shipments added to consolidated shipment.')
    });

    await clearConsolidationShipmentsSelection();
    navigateBack(consolidationRoutes.consolidationShipments.getUrl(consolidationId));
  };

  const columns = getColumnsConfiguration(handleShipmentSelect, isShipmentSelected, consolidationId);

  return (
    <Page
      actionButtons={
        <ConsolidationManageShipmentsButtons
          onCancel={handleCancelSelection}
          onSave={handleSaveSelection}
          savingDisabled={!selectedShipmentsCollection?.length}
        />
      }
      defaultBackUrl={consolidationRoutes.consolidationShipments.getUrl(consolidationId)}
      title="Add shipments to consolidation"
    >
      <div className="mb-6">
        <Search onClear={() => setSearchText('')} onSubmit={(query: string) => setSearchText(query)} />
      </div>
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={isLoading}
        loadingComponent={<SkeletonTableLoading columns={columns} tableId="consolidationAvailableShipments" />}
      >
        {(availableShipments: ConsolidationShipment[]) => (
          <>
            <ShipmentsForConsolidationList
              onDelete={(zencargoReference: string) => handleShipmentSelect(zencargoReference)}
              shipmentReferencesList={selectedShipmentsCollection ?? []}
            />

            <Table<ConsolidationShipment> columns={columns} data={availableShipments} tableId="consolidationAvailableShipments" />
            <Pagination pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
      <Route path={consolidationRoutes.consolidationAddShipmentsOrderDetailsPage.path}>
        <OrderDetailsContainer originPath={consolidationRoutes.consolidationAddShipments.path} />
      </Route>
    </Page>
  );
};

export default ConsolidationAddShipments;
