import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateModeOfTransportMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.BookingUpdateModeOfTransportInput;
}>;

export type UpdateModeOfTransportMutationResult = { __typename?: 'Mutation' } & {
  bookingUpdateModeOfTransport?: GraphQLTypes.Maybe<
    { __typename?: 'BookingUpdateModeOfTransportPayload' } & Pick<
      GraphQLTypes.BookingUpdateModeOfTransportPayload,
      'modeOfTransport'
    > & { errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>> }
  >;
};

export const UpdateModeOfTransportDocument = /* #__PURE__ */ gql`
  mutation updateModeOfTransport($input: BookingUpdateModeOfTransportInput!) {
    bookingUpdateModeOfTransport(input: $input) {
      errors {
        path
        message
      }
      modeOfTransport
    }
  }
`;

/**
 * __useUpdateModeOfTransportMutation__
 *
 * To run a mutation, you first call `useUpdateModeOfTransportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModeOfTransportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModeOfTransportMutation, { data, loading, error }] = useUpdateModeOfTransportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModeOfTransportMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateModeOfTransportMutationResult, UpdateModeOfTransportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateModeOfTransportMutationResult, UpdateModeOfTransportMutationVariables>(
    UpdateModeOfTransportDocument,
    options
  );
}
export type UpdateModeOfTransportMutationHookResult = ReturnType<typeof useUpdateModeOfTransportMutation>;
