import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { Container, Dialog, IconButton } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks';
import { useDeleteOrganisationMutation } from '@zen/Networks/graphql';
import OrganisationType from '@zen/Networks/OrganisationType';
import { useNotification } from '@zen/utils/hooks/useNotification';

import OrganisationRoles from '../../OrganisationRoles/OrganisationRoles';
import type { OrganisationTypeValue, Role } from '../../types';

interface Props {
  id: string;
  name: string;
  roles: Role[];
  type: OrganisationTypeValue;
}

const OrganisationHeader: FC<Props> = ({ id, name, roles, type }) => {
  const { push } = useHistory();
  const hasAssignedRoles = roles && !!roles.length;
  const { routes } = useNetworksContext();
  const [deleteOrganisation] = useDeleteOrganisationMutation({
    variables: {
      input: {
        organisationId: id
      }
    },
    onCompleted: () => {
      addSuccess('Organisation has been deleted.');
      push(routes.myOrganisation.details.getUrl());
    },
    onError: () => addError()
  });
  const { addSuccess, addError } = useNotification();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  return (
    <>
      <Container
        actions={
          <div className="flex">
            <Link className="mr-4" to={routes.organisation.edit.getUrl(id)}>
              <IconButton icon="zicon-edit" title="Edit" variant="secondary" />
            </Link>
            <IconButton icon="zicon-trash" onClick={() => setShowDeleteModal(true)} title="Delete" variant="secondary" />
          </div>
        }
        className="mb-6"
        title="Organisation name"
      >
        <div className="flex items-center">
          <span className="text-navy-dark text-lg font-bold mr-4">{name}</span>
          {hasAssignedRoles && <OrganisationRoles roles={roles} />}
          <OrganisationType type={type} />
        </div>
      </Container>
      <Dialog
        confirmLabel="Delete"
        header="Are you sure you want to delete this organisation ?"
        isOpen={showDeleteModal}
        message="Deleting this organisation will permanently remove it plus all the locations and contacts associated with it."
        onClose={() => setShowDeleteModal(false)}
        onConfirm={deleteOrganisation}
      />
    </>
  );
};

export default OrganisationHeader;
