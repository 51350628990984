import { createContext, useContext } from 'react';

import type { CargoModeEnum } from '@zen/Cargo';

import type { CargoSchedulePermissions } from './types';

export const initialCollectionAndDeliveryContext = {
  zencargoReference: null,
  deliveryScheduleRequired: null,
  collectionScheduleRequired: null,
  cargoMode: null,
  estimatedArrival: null,
  estimatedDeparture: null,
  polDemurrageFreeTimeBegins: null,
  podDemurrageFreeTimeEnds: null,
  podDetentionFreeTime: null,
  cargoSchedulePermissions: null,
  refetchCargoDetails: () => {},
  isSingleCargoRoadShipment: false,
  canViewOperationsDashboard: false
};

export interface CollectionAndDeliveryContextValues {
  canViewOperationsDashboard: boolean;
  cargoMode?: CargoModeEnum;
  cargoSchedulePermissions: CargoSchedulePermissions;
  collectionScheduleRequired: boolean;
  deliveryScheduleRequired: boolean;
  estimatedArrival?: string;
  estimatedDeparture?: string;
  isSingleCargoRoadShipment: boolean;
  podDemurrageFreeTimeEnds?: string;
  podDetentionFreeTime?: number;
  polDemurrageFreeTimeBegins?: string;
  refetchCargoDetails: () => void;
  zencargoReference: string;
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ zencargoReference: null; deliv... Remove this comment to see the full error message
const CollectionAndDeliveryContext = createContext<CollectionAndDeliveryContextValues>(initialCollectionAndDeliveryContext);

const useCollectionAndDelivery = () => useContext(CollectionAndDeliveryContext);

export { useCollectionAndDelivery };

export default CollectionAndDeliveryContext;
