import cx from 'classnames';
import type { ReactNode } from 'react';

interface FormHeadlineProps {
  className?: string;
  isCompact?: boolean;
  tagline?: string | ReactNode;
  text: string;
}

const FormHeadline = ({ className = 'text-grey-dark', isCompact, tagline, text }: FormHeadlineProps) => {
  const classNames: string = cx({ 'mb-4': !isCompact }, className);

  return (
    <div className={classNames}>
      <div className="text-lg leading-snug font-bold" data-testid="form-headline-text">
        {text}
      </div>
      {tagline && (
        <div className="leading-snug mt-1" data-testid="form-headline-tagline">
          {tagline}
        </div>
      )}
    </div>
  );
};

export default FormHeadline;
