import { formatDate, formatDateRange, formatTimeRange, formatTimeZone, noTimeZoneMessage } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

import type { LocalDateTimeRange, ScheduleDateLabels, ScheduleDetails } from '../types';

// until we refactor the ScheduleDetails type to not return random strings as times, we need to
// maintain the old contract for rendering the label. Once we remove this, we can simply call the first function
export const getDateTimeLabel = (scheduleDetails: ScheduleDetails, completed: boolean): ScheduleDateLabels => {
  const { latestDate, location } = scheduleDetails;

  if (latestDate && latestDate.startDateTime?.time) {
    return getNewScheduleDateAndTime(latestDate, location?.timeZone);
  }

  return getLegacyScheduleDateAndTime(scheduleDetails, completed);
};

const getNewScheduleDateAndTime = (latestDate: LocalDateTimeRange, timeZone: Optional<string>): ScheduleDateLabels => {
  const { startDateTime, endDateTime } = latestDate;
  const startDate = startDateTime.date;
  const endDate = endDateTime?.date;
  const timeString = startDateTime?.time && formatTimeRange(startDateTime.time, endDateTime?.time, false);

  return { date: formatDateRange(startDate, endDate) || '-', time: timeString, timeZone: formatTimeZone(startDate, timeZone) };
};

const getLegacyScheduleDateAndTime = (scheduleDetails: ScheduleDetails, completed: boolean): ScheduleDateLabels => {
  const { completedOn, scheduleTime, scheduleDate } = scheduleDetails;
  const timeZone: Optional<string> = scheduleDetails.location?.timeZone;

  if (completedOn && completed) {
    return { date: formatDate(completedOn), time: null, timeZone: formatTimeZone(completedOn, timeZone) };
  }

  if (scheduleDate) {
    return { date: formatDate(scheduleDate), time: scheduleTime, timeZone: formatTimeZone(scheduleDate, timeZone) };
  }

  return { date: '-', time: null, timeZone: noTimeZoneMessage };
};
