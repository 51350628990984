import { pick } from 'lodash';

export const STORAGE_PREFIX = 'zencargo';

export const LOCAL_STORAGE_ITEMS = ['dashboardShipment.hiddenColumns', 'dashboardShipment.selectedView'];

export const loadState = (PREFIX = STORAGE_PREFIX) => {
  try {
    const serializedState = (localStorage && localStorage.getItem(PREFIX)) || '{}';

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const stateToPersist = pick(state, LOCAL_STORAGE_ITEMS);
    const serializedState = JSON.stringify(stateToPersist);

    localStorage.setItem(STORAGE_PREFIX, serializedState);
  } catch (err) {
    // ignore write errors.
    // console.log("failed writing to local storage", err);
  }
};

export const saveItem = (key, item) => {
  const state = loadState();

  state[key] = item;
  localStorage.setItem(STORAGE_PREFIX, JSON.stringify(state));
};

export const removeItem = (key) => {
  const state = loadState();

  delete state[key];
  localStorage.setItem(STORAGE_PREFIX, JSON.stringify(state));
};

export const getItem = (key) => {
  const state = loadState() || {};

  return state[key] || null;
};

export const getUserItem = (userUuid, key) => {
  const state = loadState(userUuid) || {};

  return state[key] || null;
};

export const saveUserItem = (userUuid, key, item) => {
  const state = loadState(userUuid);

  state[key] = item;
  localStorage.setItem(userUuid, JSON.stringify(state));
};
