import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type MilestoneEstimatesQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type MilestoneEstimatesQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              delay?: GraphQLTypes.Maybe<{ __typename?: 'Delay' } & Pick<GraphQLTypes.Delay, 'days' | 'description' | 'type'>>;
              milestones?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'VoyageMilestone' } & Pick<GraphQLTypes.VoyageMilestone, 'id' | 'name'> & {
                      latestEstimate?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                      >;
                      originalEstimate?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                      >;
                    }
                >
              >;
            }
        >
      >
    >;
  };
};

export const MilestoneEstimatesDocument = /* #__PURE__ */ gql`
  query milestoneEstimates($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        delay {
          days
          description
          type
        }
        milestones {
          id
          name
          latestEstimate {
            date
            timeZone
          }
          originalEstimate {
            date
            timeZone
          }
        }
      }
    }
  }
`;

/**
 * __useMilestoneEstimatesQuery__
 *
 * To run a query within a React component, call `useMilestoneEstimatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilestoneEstimatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilestoneEstimatesQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useMilestoneEstimatesQuery(
  baseOptions: Apollo.QueryHookOptions<MilestoneEstimatesQueryResult, MilestoneEstimatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<MilestoneEstimatesQueryResult, MilestoneEstimatesQueryVariables>(MilestoneEstimatesDocument, options);
}
export function useMilestoneEstimatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MilestoneEstimatesQueryResult, MilestoneEstimatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<MilestoneEstimatesQueryResult, MilestoneEstimatesQueryVariables>(
    MilestoneEstimatesDocument,
    options
  );
}
export type MilestoneEstimatesQueryHookResult = ReturnType<typeof useMilestoneEstimatesQuery>;
export type MilestoneEstimatesLazyQueryHookResult = ReturnType<typeof useMilestoneEstimatesLazyQuery>;
