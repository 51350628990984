import * as Yup from 'yup';

import type { NewCargoType } from '@zen/types';
import { cargoValues } from '@zen/types';

import type { ContainerNewCargoType } from '../../types';
import { quantityValidator } from '../validation';

export const validationSchema = Yup.object().shape({
  actualCbm: Yup.number().nullable().positive('Must be greater than 0.'),
  quantity: quantityValidator(false),
  grossWeight: Yup.object().shape({
    value: Yup.number().nullable().positive('Must be greater than 0.')
  })
});

const maxContainerCapacityMapping: Record<ContainerNewCargoType, number> = {
  [cargoValues.containerX20Dv]: 33.2,
  [cargoValues.containerX20Hc]: 37.3,
  [cargoValues.containerX40Dv]: 67.7,
  [cargoValues.containerX40Hc]: 76.3,
  [cargoValues.containerX45Hc]: 85.7
};

export const isActualCbmValid = (containerType: NewCargoType, actualCbm: number): boolean => {
  const maxContainerCapacity: number = maxContainerCapacityMapping[containerType as ContainerNewCargoType];

  return actualCbm <= maxContainerCapacity;
};
