import { FC, RefObject, useRef } from 'react';

import { FormHiddenInput, FormLabel } from '@zen/Components/Form';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import FormTimeInput from '@zen/Components/Form/FormTimeInput';
import { useForm } from '@zen/Components/Form/useForm';
import { Icon, Tooltip } from '@zen/DesignSystem';
import type { BusinessHours } from '@zen/Networks';
import { getToday } from '@zen/utils/date';
import type { TimeRange as TimeRangeType } from '@zen/utils/dateTime';
import { formatTimeZone } from '@zen/utils/dateTime';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { calculateTimeRangeValue, getTimeRangeMapping } from './helpers';
import TimeRange, { TimeRangeEnum } from './TimeRange';
import type { DateWithTimeRangeFieldsType, TimeRangeMapping } from './types';

interface Props {
  businessHours?: BusinessHours;
  isRequired?: boolean;
  label: string;
  name: string;
  timeZone?: Optional<string>;
}

const DateWithTimeRangeFields: FC<Props> = ({ businessHours, isRequired, label, name, timeZone }) => {
  const { setFieldValue, values } = useForm<DateWithTimeRangeFieldsType>();
  const inputRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const { date, startTime, endTime } = values[name];

  const timeRangeMapping: TimeRangeMapping = getTimeRangeMapping(date, businessHours);
  const timeRange: TimeRangeEnum = calculateTimeRangeValue(startTime, endTime, timeRangeMapping);

  const handleChange = (range: TimeRangeEnum): void => {
    setFieldValue(`${name}.startTime`, timeRangeMapping[range].startTime);
    setFieldValue(`${name}.endTime`, timeRangeMapping[range].endTime);

    if (range === TimeRangeEnum.CUSTOM) {
      inputRef?.current?.focus();
    }
  };

  const handleDateChange = (selectedDate: Nullable<string>): void => {
    if (timeRange === TimeRangeEnum.ALL_DAY) {
      const rangeMapping: TimeRangeMapping = getTimeRangeMapping(selectedDate, businessHours);
      const range: TimeRangeType = rangeMapping[TimeRangeEnum.ALL_DAY];

      setFieldValue(`${name}.startTime`, range.startTime);
      setFieldValue(`${name}.endTime`, range.endTime);
    }
  };

  return (
    <div data-testid="date-with-time-range-fields">
      <FormLabel className="font-bold" isRequired={isRequired} label={label} />
      <div className="flex">
        <FormDatePickerInput
          className="w-40 mr-2"
          hideErrorMessage={true}
          isClearable={true}
          label="Date"
          name={`${name}.date`}
          noMargin={true}
          onChange={handleDateChange}
          placeholder="July 21, 2021"
        />
        <FormTimeInput
          ref={inputRef}
          className="mr-2"
          hideErrorMessage={true}
          label="Between"
          name={`${name}.startTime`}
          noMargin={true}
          placeholder="12:00"
        />
        <FormTimeInput hideErrorMessage={true} label="And" name={`${name}.endTime`} noMargin={true} placeholder="17:00" />
        <Tooltip
          placement="right"
          tooltipContent={formatTimeZone(date || getToday(), timeZone)}
          triggerClassName="pl-2 mt-6 h-10"
        >
          <Icon className="text-grey-base text-base leading-10" icon="zicon-information" />
        </Tooltip>
      </div>
      <div className="mb-4">
        <FormHiddenInput name={`${name}.date`} />
        <FormHiddenInput name={`${name}.startTime`} />
        <FormHiddenInput name={`${name}.endTime`} />
      </div>
      <div className="-mt-3">
        <TimeRange onChange={handleChange} value={timeRange} />
      </div>
    </div>
  );
};

export default DateWithTimeRangeFields;
