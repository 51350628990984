import type { FC } from 'react';

import SectionTitle from '@zen/Components/SectionTitle';

import type { AccountDetailsItem } from '../types';
import AccountChangeMode from './AccountChangeMode';
import AccountChangeStatus from './AccountChangeStatus';

interface Props {
  accountDetails: AccountDetailsItem;
  accountId: string;
  onStatusChange: () => void;
}

const AccountStatus: FC<Props> = ({ accountId, accountDetails, onStatusChange }) => {
  const { isActive, isDemo, referencePrefix } = accountDetails;

  return (
    <>
      <SectionTitle title="Account status" />
      <AccountChangeStatus
        accountId={accountId}
        accountReference={referencePrefix}
        isActive={isActive}
        onStatusChange={onStatusChange}
      />
      <AccountChangeMode
        accountId={accountId}
        accountReference={referencePrefix}
        isActive={isActive}
        isDemo={isDemo}
        onStatusChange={onStatusChange}
      />
    </>
  );
};

export default AccountStatus;
