import qs from 'qs';
import type { RouteComponentProps } from 'react-router';

import type { SortInput } from '@zen/types';

export const createSortingHandler = (location: RouteComponentProps['location'], history: RouteComponentProps['history']) => {
  if (!location || !history) {
    return () => {};
  }
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (order: SortInput) => {
    const values = {
      ...params,
      ...order
    };

    history.replace({
      ...location,
      search: qs.stringify(values)
    });
  };
};
