import type { RoutesConfig } from './types';

interface BackofficeRoutes extends RoutesConfig {
  shipmentActivity: {
    getUrl: (zencargoReference: string, accountUuid: string) => string;
    path: string;
  };
}

const backofficeRoutes: BackofficeRoutes = {
  shipmentActivity: {
    path: '/backoffice/accounts/:account_uuid/booking_forms/:zencargo_reference?tab_booking_form=activity',
    getUrl: (zencargoReference: string, accountUuid: string) => {
      return `/backoffice/accounts/${accountUuid}/booking_forms/${zencargoReference}?tab_booking_form=activity`;
    }
  }
};

export default backofficeRoutes;
