import type { FC, ReactNode } from 'react';

import StageCheckItem from './StageCheckItem';
import type { BookingStageCheck } from './types';

interface Props {
  checklist: BookingStageCheck[];
}

const StageChecklist: FC<Props> = ({ checklist }) => {
  const renderCheck = (check: BookingStageCheck, index: number): ReactNode => {
    return <StageCheckItem key={index} check={check} />;
  };

  return (
    <div className="flex" data-testid="stage-checklist">
      {checklist.map(renderCheck)}
    </div>
  );
};

export type { Props as StageChecklistProps };

export default StageChecklist;
