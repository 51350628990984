import type { FC, ReactNode } from 'react';

import { Container } from '@zen/DesignSystem';

interface Props {
  actions?: ReactNode;
  anchor: string;
  children: ReactNode;
  enabled?: boolean;
  showInDefaultContainer?: boolean;
  title: string;
}

const Section: FC<Props> = ({ children, title, showInDefaultContainer = true, enabled = true, anchor, actions = null }) => {
  if (!enabled) {
    return null;
  }

  const renderContent = (): ReactNode => {
    if (showInDefaultContainer) {
      return (
        <Container actions={actions} title={title}>
          {children}
        </Container>
      );
    }

    return children;
  };

  return (
    <div className="my-6" data-testid="section" id={anchor}>
      {renderContent()}
    </div>
  );
};

export default Section;
