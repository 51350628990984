import { RefObject, useEffect } from 'react';
import { useKey } from 'react-use';

const useClickAway = (ref: RefObject<HTMLElement | null>, onClickAway: () => void) => {
  // Wherever clickAway is implied, "Escape" button should work as well!
  useKey('Escape', onClickAway);
  useEffect(() => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    const handler = (event) => {
      const { current: el } = ref;

      if (el && !el.contains(event.target)) {
        onClickAway();
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });
};

export default useClickAway;
