import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Button, ButtonVariant } from '@zen/DesignSystem';

interface Props {
  buttonText: string;
  buttonVariant?: ButtonVariant;
  children: ReactNode;
  isDisabled?: boolean;
  isUpdateMode: boolean;
  label: string;
  onUpdate: () => void;
}

const EditableSection: FC<Props> = ({ buttonText, buttonVariant, children, isDisabled, isUpdateMode, label, onUpdate }) => {
  const className: string = cx({ 'text-grey-base': isDisabled, 'p-2': isUpdateMode }, 'flex-1');
  const buttonClassName: string = cx({ 'pointer-events-none': isDisabled });

  return (
    <>
      <div className="text-sm text-grey-dark h-4 mb-2">{label}</div>
      <div className="p-2 border border-solid border-grey-lighter flex justify-between items-center mb-4">
        <div className={className}>{children}</div>
        {!isUpdateMode && (
          <Button className={buttonClassName} disabled={isDisabled} onClick={onUpdate} variant={buttonVariant}>
            {buttonText}
          </Button>
        )}
      </div>
    </>
  );
};

export type { Props as EditableSectionProps };

export default EditableSection;
