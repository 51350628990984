import type { FC } from 'react';

import { Icon, Tooltip, TooltipProps } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import { useHover } from '@zen/utils/hooks/useHover';

interface Props extends TooltipProps {
  className?: string;
}

const InformationIcon: FC<Props> = ({ className, tooltipContent, placement }) => {
  const [ref, isHovered] = useHover();

  const iconName: IconName = isHovered ? 'zicon-information-in' : 'zicon-information';

  return (
    <Tooltip placement={placement} tooltipContent={tooltipContent}>
      <span ref={ref} className={className} data-testid="icon-container">
        <Icon className="text-base" icon={iconName} />
      </span>
    </Tooltip>
  );
};

export type { Props as InformationIconProps };

export default InformationIcon;
