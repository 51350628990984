import type { FC } from 'react';

import { CargoModeEnum } from '@zen/Cargo';
import FormMultiStopSelect from '@zen/Components/Form/FormMultiStopSelect';
import FormTruckSwapSelect from '@zen/Components/Form/FormTruckSwapSelect';
import { useForm } from '@zen/Components/Form/useForm';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';
import useAccount from '@zen/utils/hooks/useAccount';

import type { RoadShipmentFields } from '../../types';

interface Props {
  autoFocusedField?: string;
}

const RouteFields: FC<Props> = ({ autoFocusedField }) => {
  const { accountUuid } = useAccount();
  const { values } = useForm<RoadShipmentFields>();

  const isFtlShipment: boolean = values.cargoMode === CargoModeEnum.FTL;

  return (
    <div data-testid="route-fields">
      <NetworksContactFormInput
        accountUuid={accountUuid}
        assignmentType={AssignmentTypeValue.COLLECTION_LOCATION}
        autoFocus={autoFocusedField === 'collectionLocation'}
        domainName={AssignmentTargetTypeEnum.BOOKING_CARGO}
        formTitle="Add a new collection warehouse"
        isRequired={true}
        label="Origin/Collection address"
        name="collectionLocation"
      />
      <NetworksContactFormInput
        accountUuid={accountUuid}
        assignmentType={AssignmentTypeValue.DELIVERY_LOCATION}
        autoFocus={autoFocusedField === 'deliveryLocation'}
        domainName={AssignmentTargetTypeEnum.BOOKING_CARGO}
        formTitle="Add a new delivery warehouse"
        isRequired={true}
        label="Destination/Delivery address"
        name="deliveryLocation"
      />
      {isFtlShipment && (
        <>
          <FormMultiStopSelect name="multiStop" />
          <FormTruckSwapSelect name="truckSwap" />
        </>
      )}
    </div>
  );
};

export default RouteFields;
