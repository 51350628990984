import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetIssuesAndActionsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetIssuesAndActionsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              issues?: GraphQLTypes.Maybe<
                Array<
                  { __typename: 'BookingIssue' } & Pick<
                    GraphQLTypes.BookingIssue,
                    | 'associatedId'
                    | 'associatedType'
                    | 'category'
                    | 'id'
                    | 'problemDescription'
                    | 'severity'
                    | 'solutionDescription'
                    | 'state'
                    | 'title'
                  > & {
                      canArchive: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canModifyVisibility: { __typename?: 'AuthorizationResult' } & Pick<
                        GraphQLTypes.AuthorizationResult,
                        'value'
                      >;
                      canResolve: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canStart: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canUpdate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canUpdateCategory: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canUpdateProblemDescription: { __typename?: 'AuthorizationResult' } & Pick<
                        GraphQLTypes.AuthorizationResult,
                        'value'
                      >;
                      occurredOn: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>;
                      openedAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>;
                      openedBy: { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>;
                      resolvedAt?: GraphQLTypes.Maybe<
                        { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
                      >;
                      resolvedBy?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
                      >;
                      startedAt?: GraphQLTypes.Maybe<
                        { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
                      >;
                      startedBy?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
                      >;
                      visibility: { __typename?: 'IssueVisibility' } & Pick<
                        GraphQLTypes.IssueVisibility,
                        'agentForwarder' | 'customerUser' | 'manufacturer'
                      >;
                    }
                >
              >;
              actionItems?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'ActionItem' } & Pick<
                    GraphQLTypes.ActionItem,
                    'description' | 'dueDate' | 'id' | 'name' | 'title' | 'type' | 'status'
                  >
                >
              >;
              canModifyIssueVisibility: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canOpenIssue: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            }
        >
      >
    >;
  };
};

export const GetIssuesAndActionsDocument = /* #__PURE__ */ gql`
  query getIssuesAndActions($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        issues {
          __typename
          associatedId
          associatedType
          category
          canArchive {
            value
          }
          canModifyVisibility {
            value
          }
          canResolve {
            value
          }
          canStart {
            value
          }
          canUpdate {
            value
          }
          canUpdateCategory {
            value
          }
          canUpdateProblemDescription {
            value
          }
          id
          occurredOn {
            date
          }
          openedAt {
            date
            dateTime
          }
          openedBy {
            firstName
            lastName
          }
          problemDescription
          resolvedAt {
            date
            dateTime
          }
          resolvedBy {
            firstName
            lastName
          }
          severity
          solutionDescription
          startedAt {
            date
            dateTime
          }
          startedBy {
            firstName
            lastName
          }
          state
          title
          visibility {
            agentForwarder
            customerUser
            manufacturer
          }
        }
        actionItems {
          description
          dueDate
          id
          name
          title
          type
          status
        }
        canModifyIssueVisibility {
          value
        }
        canOpenIssue {
          value
        }
      }
    }
  }
`;

/**
 * __useGetIssuesAndActionsQuery__
 *
 * To run a query within a React component, call `useGetIssuesAndActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesAndActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesAndActionsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetIssuesAndActionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetIssuesAndActionsQueryResult, GetIssuesAndActionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetIssuesAndActionsQueryResult, GetIssuesAndActionsQueryVariables>(GetIssuesAndActionsDocument, options);
}
export function useGetIssuesAndActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIssuesAndActionsQueryResult, GetIssuesAndActionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetIssuesAndActionsQueryResult, GetIssuesAndActionsQueryVariables>(
    GetIssuesAndActionsDocument,
    options
  );
}
export type GetIssuesAndActionsQueryHookResult = ReturnType<typeof useGetIssuesAndActionsQuery>;
export type GetIssuesAndActionsLazyQueryHookResult = ReturnType<typeof useGetIssuesAndActionsLazyQuery>;
