import type { Optional } from '@zen/utils/typescript';

import { FlatShipment, ModeOfTransport, Shipment } from './types';

export const isAirShipment = (modeOfTransport: Optional<ModeOfTransport>): boolean => modeOfTransport === ModeOfTransport.AIR;

export const isOceanShipment = (modeOfTransport: Optional<ModeOfTransport>): boolean => modeOfTransport === ModeOfTransport.OCEAN;

export const isRailShipment = (modeOfTransport: Optional<ModeOfTransport>): boolean => modeOfTransport === ModeOfTransport.RAIL;

export const isRoadShipment = (modeOfTransport: Optional<ModeOfTransport>): boolean => modeOfTransport === ModeOfTransport.TRUCK;

export const prepareFlatShipments = (shipments: Shipment[]): FlatShipment[] => {
  return shipments.map((shipment: Shipment) => {
    const {
      bookingStage,
      calculatedInfo,
      cargo,
      carriage,
      clientReference,
      customsOnly,
      delay,
      estimatedArrival,
      estimatedDeparture,
      estimatedCollection,
      estimatedDelivery,
      incoterms,
      modeOfTransport,
      consigneeLabel,
      consignorLabel,
      forwarder,
      forwarderReference,
      customer,
      networksDestination,
      networksOrigin,
      pinned,
      issues,
      stage,
      zencargoReference,
      updatedAt,
      actionItems,
      note,
      purchaseOrderReferences,
      voyageMilestone
    } = shipment;

    return {
      actionItems,
      modeOfTransport,
      cargoSummary: calculatedInfo?.cargoSummary,
      clientReference,
      customsOnly,
      zencargoReference,
      stage: stage || null,
      stageName: voyageMilestone?.name || bookingStage?.name,
      delay,
      issues,
      origin: networksOrigin?.label?.long || '',
      originShort: networksOrigin?.label?.short || '',
      destination: networksDestination?.label?.long || '',
      destinationShort: networksDestination?.label?.short || '',
      cargo,
      consignee: consigneeLabel || '',
      consignor: consignorLabel || '',
      forwarder: forwarder?.label?.short || '',
      forwarderReference: forwarderReference || '',
      customer,
      incoterms: incoterms?.value,
      pinned,
      portOfLoad: carriage?.portOfLoad?.name || '',
      portOfDestination: carriage?.portOfDestination?.name || '',
      purchaseOrderReferences,
      vesselName: carriage?.vesselName || '',
      estimatedArrival,
      estimatedDeparture,
      estimatedCollection,
      estimatedDelivery,
      updatedAt: updatedAt?.dateTime || '',
      note,
      shippingOrderStatus: cargo?.tracking?.shippingOrderState || null
    };
  });
};
