import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import { IconButton } from '@zen/DesignSystem';
import { formatDate } from '@zen/utils/dateTime';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';
import { mergeQueryParams } from '@zen/utils/QueryParams/queryParams';

interface Props {
  endDate: string;
  startDate: string;
}

const WeekSwitcher = ({ endDate, startDate }: Props) => {
  const { replace } = useHistory();
  const location = useLocation();

  const handleWeekChange = (weekDifference: number) => {
    const newStartDate = moment(startDate).add(weekDifference, 'w').format(FORMAT_DATE_TRANSFERABLE);

    replace({
      ...location,
      search: mergeQueryParams(location.search, { startDate: newStartDate })
    });
  };

  return (
    <div className="font-bold">
      <div className="flex items-center">
        <div className="text-lg leading-normal text-grey-dark w-72">
          {formatDate(startDate)} - {formatDate(endDate)}
        </div>
        <div className="text-lg text-grey-dark flex">
          <IconButton className="mr-2" icon="zicon-arrow-left" onClick={() => handleWeekChange(-1)} variant="tertiary" />
          <IconButton className="ml-1" icon="zicon-arrow-right" onClick={() => handleWeekChange(1)} variant="tertiary" />
        </div>
      </div>
    </div>
  );
};

export default WeekSwitcher;
