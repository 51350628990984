import classnames from 'classnames';
import type { FC, SyntheticEvent } from 'react';

type ContentBoxType = 'white' | 'transparent';

interface Props {
  className?: string;
  hoverable?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  type?: ContentBoxType;
}

const ContentBox: FC<Props> = ({ className = '', children, hoverable = true, onClick, type = 'white', ...rest }) => {
  const contentBoxBackgroundMapping: Record<ContentBoxType, string> = {
    white: 'bg-white',
    transparent: 'bg-transparent'
  };

  const classNames = classnames(
    {
      'hover:bg-grey-lightest cursor-pointer': hoverable
    },
    'flex flex-col rounded-md border border-grey-light border-solid transition-all duration-150 ease-linear',
    contentBoxBackgroundMapping[type],
    className
  );

  return (
    <div className={classNames} onClick={onClick} {...rest}>
      {children}
    </div>
  );
};

export default ContentBox;
