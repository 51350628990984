import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  className?: string;
  icon?: IconName;
  text: ReactNode;
}

const SectionDescription: FC<Props> = ({ className = 'my-5', text, icon = 'zicon-information' }) => {
  const wrapperClassName: string = cx('flex text-grey-base', className);

  return (
    <div className={wrapperClassName} data-testid="section-description">
      <Icon className="mr-3 text-base" icon={icon} />
      <p className="leading-normal -mt-1 text-sm">{text}</p>
    </div>
  );
};

export type { Props as SectionDescriptionProps };

export default SectionDescription;
