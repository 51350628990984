import type { FC, ReactNode } from 'react';

import { FormSelect } from '@zen/Components/Form';
import FormDateFilter from '@zen/Components/Form/FormDateFilter';
import FormDateRangeFilter from '@zen/Components/Form/FormDateRangeFilter';
import { useForm } from '@zen/Components/Form/useForm';
import { Tooltip } from '@zen/DesignSystem';
import { BookingUpcomingEventEnum, ShipmentFilters, ShipmentFilterStageWithin, ShipmentFilterStatus } from '@zen/Shipments/types';
import type { Optional } from '@zen/utils/typescript';

import { daysFilterOptions, withinDaysFilterOptions } from '../helpers';

const StageWithinFields: FC = () => {
  const { setFieldValue, values } = useForm<Pick<ShipmentFilters, 'withinTimeStage' | 'withinTimeDays' | 'status'>>();
  const { status, withinTimeDays, withinTimeStage } = values;

  const isDeliveredStatus: boolean = status === ShipmentFilterStatus.DELIVERED;

  const handleChange = (stage: Optional<BookingUpcomingEventEnum>): void => {
    if (stage) {
      return;
    }

    setFieldValue('withinTimeDays', null);
  };

  const renderWithinTimeDaysField = (): ReactNode => {
    return (
      <FormSelect
        isClearable={true}
        isDisabled={!withinTimeStage || isDeliveredStatus}
        isSearchable={false}
        label="When..."
        name="withinTimeDays"
        options={daysFilterOptions}
      />
    );
  };

  const renderWithinTimeDaysFieldWithTooltip = (): ReactNode => {
    return (
      <Tooltip placement="bottom-end" tooltipContent="Please select the Shipment event first">
        {renderWithinTimeDaysField()}
      </Tooltip>
    );
  };

  return (
    <>
      <div className="flex justify-content">
        <FormSelect
          className="mr-4 w-2/3"
          isClearable={true}
          isDisabled={isDeliveredStatus}
          isSearchable={false}
          label="Shipment event"
          name="withinTimeStage"
          onChange={handleChange}
          options={withinDaysFilterOptions}
        />
        <div className="w-1/3">{withinTimeStage ? renderWithinTimeDaysField() : renderWithinTimeDaysFieldWithTooltip()}</div>
      </div>
      {withinTimeDays === ShipmentFilterStageWithin.DATE_RANGE && <FormDateRangeFilter namePrefix="withinTimeRange" />}
      {withinTimeDays === ShipmentFilterStageWithin.SPECIFIC_DATE && <FormDateFilter name="withinTimeDate" />}
    </>
  );
};

export default StageWithinFields;
