import cx from 'classnames';
import { FC, ReactNode, useRef } from 'react';
import { Portal } from 'react-portal';

import { MODAL_OVERLAY_ZINDEX, MODAL_ZINDEX } from '@zen/Layout';
import useClickAway from '@zen/utils/hooks/useClickAway';

import Icon from '../Icon';
import Content from './Content';
import Header from './Header';

interface Props {
  children: ReactNode;
  className?: string;
  closeOnClickAway?: boolean;
  isOpen: boolean;
  modalOverflowY?: string;
  onClose?: () => void;
  onCloseOnClickAway?: () => void;
  showCloseButton?: boolean;
}

export interface ModalComponent extends FC<Props> {
  Content: typeof Content;
  Header: typeof Header;
}
/**
 * @deprecated, Start using the new Modal.
 */
const MODAL_DEPRECATED: ModalComponent = (props) => {
  const {
    children,
    className,
    closeOnClickAway = true,
    isOpen,
    modalOverflowY = 'auto',
    onClose = () => false,
    showCloseButton = true,
    onCloseOnClickAway,
    ...rest
  } = props;
  const ref = useRef(null);

  const handleClickAway = (): void => {
    if (!closeOnClickAway) {
      return;
    }

    if (onCloseOnClickAway) {
      onCloseOnClickAway();

      return;
    }

    onClose();
  };

  useClickAway(ref, handleClickAway);

  if (!isOpen) {
    return null;
  }

  const modalClassNames: string = cx(`fixed inset-0 ${MODAL_ZINDEX} flex items-start mt-16 justify-center py-4`, className);

  const iconClassNames: string = `absolute right-2 top-2 text-xs p-2 hover:bg-grey-light
    hover:text-grey-dark rounded active:bg-grey-base text-grey-base font-bold cursor-pointer`;

  return (
    <Portal>
      <div className={`fixed inset-0 bg-grey-base opacity-25 ${MODAL_OVERLAY_ZINDEX}`} />
      <div className={modalClassNames} data-component="modal" data-testid="modal" {...rest}>
        <div ref={ref} className="relative rounded shadow-overlay bg-white flex flex-col max-h-full" style={{ maxWidth: '90vw' }}>
          {showCloseButton && <Icon className={iconClassNames} icon="zicon-close" onClick={onClose} />}
          <div className={`overflow-y-${modalOverflowY}`}>{children}</div>
        </div>
      </div>
    </Portal>
  );
};

export type { Props as ModalProps };

MODAL_DEPRECATED.Header = Header;
MODAL_DEPRECATED.Content = Content;

export default MODAL_DEPRECATED;
