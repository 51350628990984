import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateMilestoneMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateVoyageMilestoneTimeInput;
}>;

export type UpdateMilestoneMutationResult = { __typename?: 'Mutation' } & {
  bookingUpdateVoyageMilestoneTime?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateVoyageMilestoneTimePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateMilestoneDocument = /* #__PURE__ */ gql`
  mutation updateMilestone($input: UpdateVoyageMilestoneTimeInput!) {
    bookingUpdateVoyageMilestoneTime(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateMilestoneMutation__
 *
 * To run a mutation, you first call `useUpdateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMilestoneMutation, { data, loading, error }] = useUpdateMilestoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMilestoneMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMilestoneMutationResult, UpdateMilestoneMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateMilestoneMutationResult, UpdateMilestoneMutationVariables>(UpdateMilestoneDocument, options);
}
export type UpdateMilestoneMutationHookResult = ReturnType<typeof useUpdateMilestoneMutation>;
