import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import ForwarderInfoHeader from '@zen/Components/ForwarderInfoHeader';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import BookingStaleDataInfo from '@zen/Shipment/BookingStaleDataInfo';
import { useUpdateBookingForwarderReferenceMutation } from '@zen/Shipment/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import ClientReferenceContainer from '../ClientReferenceContainer';
import { useGetShipmentDetailsBoardHeaderQuery } from './graphql';
import type { ShipmentDetailsBoardHeaderData } from './types';

interface Props {
  canViewForwarder: boolean;
  clientReference: Optional<string>;
  zencargoReference: string;
}

const ShipmentDetailsBoardHeader: FC<Props> = ({ zencargoReference, canViewForwarder, clientReference }) => {
  const { addError, addSuccess } = useNotification();
  const { data, loading, error } = useGetShipmentDetailsBoardHeaderQuery({
    variables: { zencargoReference }
  });

  const [updateBookingForwarderReference] = useUpdateBookingForwarderReferenceMutation();

  const handleForwarderReferenceChange = async (forwarderRef: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateBookingForwarderReference({
          variables: {
            input: { zencargoReference, forwarderReference: forwarderRef }
          }
        }),
      onSuccess: () => addSuccess('Forwarder reference updated.'),
      onError: () => addError('Could not update Forwarder reference.')
    });
  };

  return (
    <QueryHandler
      data={data?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonLoading height={30} lighter={true} />}
    >
      {(shipment: ShipmentDetailsBoardHeaderData) => {
        const canViewStaleData: boolean = checkPermission<ShipmentDetailsBoardHeaderData>(shipment, 'canViewStaleData');
        const forwarderName: string = shipment?.forwarder?.label?.short!;
        const shouldShowSeparator: boolean = canViewForwarder && canViewStaleData;

        return (
          <>
            <ClientReferenceContainer clientReference={clientReference} zencargoReference={zencargoReference} />
            <div className="flex items-center justify-end">
              {canViewForwarder && (
                <ForwarderInfoHeader
                  forwarderName={forwarderName}
                  forwarderReference={shipment?.forwarderReference!}
                  onForwarderReferenceChange={handleForwarderReferenceChange}
                />
              )}
              {shouldShowSeparator && <span className="mx-3 text-grey-lighter">&bull;</span>}
              {canViewStaleData && (
                <BookingStaleDataInfo confirmationButtonClassname="text-navy-base" zencargoReference={zencargoReference} />
              )}
            </div>
          </>
        );
      }}
    </QueryHandler>
  );
};

export default ShipmentDetailsBoardHeader;
