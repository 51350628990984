import cx from 'classnames';
import type { FC, ReactNode, SyntheticEvent } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  autoFocus?: boolean;
  checked: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  indeterminate?: boolean;
  label?: ReactNode;
  name?: string;
  onChange?: (e: SyntheticEvent) => void;
}

const Checkbox: FC<Props> = (props) => {
  const {
    autoFocus = false,
    checked,
    className,
    disabled = false,
    error = false,
    indeterminate = false,
    label,
    name = '',
    onChange = () => null
  } = props;

  const showIcon: boolean = checked || indeterminate;
  const icon: IconName = indeterminate ? 'zicon-minus-small' : 'zicon-tick-small';

  const checkBoxDefault: string = cx(
    {
      'group-hover:border-azure-base cursor-pointer': !disabled && !error,
      'cursor-not-allowed': disabled,
      'text-white border-azure-base': showIcon && !disabled,
      'border-grey-light text-grey-light': (!checked && !indeterminate && !error) || disabled,
      'border-red-dark': error
    },
    'border border-solid w-4 h-4 min-w-4 rounded flex items-center justify-center'
  );

  const containerClassNames: string = cx('flex items-center relative group my-1', className);

  const inputWrapperClassNames: string = cx(checkBoxDefault, 'relative leading-none text-base', {
    'bg-grey-lightest': disabled,
    'bg-azure-base': showIcon
  });

  const textClassNames: string = cx(
    {
      'text-grey-light cursor-not-allowed': disabled,
      'text-grey-dark cursor-pointer': !disabled
    },
    'pl-2 text-sm'
  );

  const handleClick = (e: SyntheticEvent): void => {
    if (!disabled) {
      onChange(e);
    }
  };

  return (
    <div className={containerClassNames} data-component="checkbox" data-testid="checkbox" onClick={handleClick}>
      <div className={inputWrapperClassNames}>
        {showIcon && <Icon icon={icon} />}
        <input
          autoFocus={autoFocus}
          checked={checked}
          className="opacity-0 h-0 w-0"
          disabled={disabled}
          id={name}
          name={name}
          readOnly={true}
          type="checkbox"
        />
      </div>
      {label && <label className={textClassNames}>{label}</label>}
    </div>
  );
};

export type { Props as CheckboxProps };

export default Checkbox;
