import type { RoutesConfig } from './types';

export const ACCOUNTS_ROUTE_PREFIX: string = '/dashboard/accounts';

interface AccountsRoutes extends RoutesConfig {
  accountsDetails: {
    getUrl: (zencargoReference: string, tab?: string) => string;
    path: string;
  };
  accountsIndex: {
    getUrl: () => string;
    path: string;
  };
}

const accountsRoutes: AccountsRoutes = {
  accountsIndex: {
    path: ACCOUNTS_ROUTE_PREFIX,
    getUrl: () => ACCOUNTS_ROUTE_PREFIX
  },
  accountsDetails: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id`,
    getUrl: (accountId: string, tab?: string): string => {
      const url = `${ACCOUNTS_ROUTE_PREFIX}/${accountId}`;

      return `${url}/${tab || 'company-details'}`;
    }
  }
};

export default accountsRoutes;
