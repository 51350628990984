import type { FC } from 'react';

import InlineEditableField from '@zen/Components/InlineEditableField';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import { useUpdateClientReferenceMutation } from './graphql';

interface Props {
  clientReference: Optional<string>;
  zencargoReference: string;
}

const ClientReferenceContainer: FC<Props> = ({ zencargoReference, clientReference }) => {
  const { addError, addSuccess } = useNotification();

  const [updateClientReference] = useUpdateClientReferenceMutation();

  const handleUpdateClientReference = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateClientReference({
          variables: {
            input: {
              zencargoReference,
              clientReference: value
            }
          },
          refetchQueries: ['getShipmentDetailsPageData']
        }),
      onError: () => addError(),
      onSuccess: () => addSuccess('Client reference updated.')
    });
  };

  return (
    <div className="flex flex-col">
      <InlineEditableField
        className="py-1"
        onUpdate={handleUpdateClientReference}
        placeholder="Enter client reference"
        value={clientReference || ''}
      />
    </div>
  );
};

export default ClientReferenceContainer;
