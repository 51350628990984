import moment from 'moment';
import pluralize from 'pluralize';
import type { FC } from 'react';

import { formatDate } from '@zen/utils/dateTime';

import type { ActionItemType } from '../types';

interface Props {
  actionItem: Pick<ActionItemType, 'dueDate' | 'title'>;
}

const ActionItemDetails: FC<Props> = ({ actionItem }) => {
  const { dueDate, title } = actionItem;

  const diffInDays: number = moment().diff(dueDate, 'days');
  const isOverdue: boolean = diffInDays > 0;

  const message: string = isOverdue
    ? `Deadline was ${formatDate(dueDate)}. ${diffInDays} ${pluralize('day', diffInDays)} overdue`
    : `Deadline is ${formatDate(dueDate)}`;

  return (
    <div>
      <div className="font-bold text-grey-dark block mb-1">{title}</div>
      <div className="text-red-dark text-xs" data-testid="subtitle-overdue">
        {message}
      </div>
    </div>
  );
};

export default ActionItemDetails;
