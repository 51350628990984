import { FC, ReactNode, useContext } from 'react';

import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import type { OrderListViewLot } from '@zen/Orders/types';

import LotLineItem from './LotLineItem';

interface Props {
  orderLots: OrderListViewLot[];
}

const OrderListItemSimpleDetails: FC<Props> = ({ orderLots }) => {
  const { isBasketMode } = useContext(BasketContext);

  const renderLot = (lot: OrderListViewLot): ReactNode => <LotLineItem key={lot.id} lot={lot} />;

  return (
    <div className="bg-white" data-testid="order-list-item-details">
      <div className="my-4 px-4 py-6 bg-grey-lightest">
        <div className="flex font-bold text-sm mb-5 leading-normal text-grey-base">
          {isBasketMode && <div className="w-10" />}
          <div className="w-1/2 grow-0">Product / SKU</div>
          <div className="flex ml-auto min-w-md mr-3">
            <div className="w-36 mr-6">Quantity</div>
            <div>CBM</div>
          </div>
        </div>
        {orderLots.map(renderLot)}
      </div>
    </div>
  );
};

export default OrderListItemSimpleDetails;
