import type { FC } from 'react';

import { usePurchaseOrderDelayOptions } from '@zen/Components/Form/FormPurchaseOrderDelayReasonSelect/hooks';
import type { DateFormType } from '@zen/Shipment/OrderList/OrderLot/InlineCargoReadyDateForm/DateForm/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import DateForm from './DateForm';
import { useUpdateLotEstimateMutation } from './graphql';

interface Props {
  isInitialEntry?: boolean;
  lotId: string;
  onCancel: () => void;
  onSuccess?: () => void;
}

const InlineCargoReadyDateForm: FC<Props> = ({ lotId, onCancel, isInitialEntry = false, onSuccess }) => {
  const [updateLotEstimate] = useUpdateLotEstimateMutation();
  const { options } = usePurchaseOrderDelayOptions();
  const { addError } = useNotification();

  const onSubmit = ({ date, confidence, description, reason }: DateFormType): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateLotEstimate({
          variables: {
            input: {
              lotIds: [lotId],
              cargoReadyDate: {
                date,
                reasonForChange: {
                  reasonDescription: description,
                  reasonId: reason
                },
                confidenceLevel: confidence
              }
            }
          }
        }),
      onError: addError
    });
  };

  return (
    <DateForm
      delayReasonOptions={options}
      formButtonsLayout="fixed"
      isInitialDateEntry={isInitialEntry}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    />
  );
};

export default InlineCargoReadyDateForm;
