import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTaskListCountersQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetTaskListCountersQueryResult = { __typename?: 'Query' } & {
  taskListCount?: GraphQLTypes.Maybe<
    { __typename?: 'WorkflowTaskDetailsPayloadType' } & Pick<GraphQLTypes.WorkflowTaskDetailsPayloadType, 'activeTaskCount'>
  >;
};

export const GetTaskListCountersDocument = /* #__PURE__ */ gql`
  query getTaskListCounters {
    taskListCount: taskDetailsForCurrentUser {
      activeTaskCount
    }
  }
`;

/**
 * __useGetTaskListCountersQuery__
 *
 * To run a query within a React component, call `useGetTaskListCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskListCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskListCountersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaskListCountersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTaskListCountersQueryResult, GetTaskListCountersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTaskListCountersQueryResult, GetTaskListCountersQueryVariables>(GetTaskListCountersDocument, options);
}
export function useGetTaskListCountersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskListCountersQueryResult, GetTaskListCountersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTaskListCountersQueryResult, GetTaskListCountersQueryVariables>(
    GetTaskListCountersDocument,
    options
  );
}
export type GetTaskListCountersQueryHookResult = ReturnType<typeof useGetTaskListCountersQuery>;
export type GetTaskListCountersLazyQueryHookResult = ReturnType<typeof useGetTaskListCountersLazyQuery>;
