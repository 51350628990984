import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import IssuesAndActions from '@zen/Shipment/IssuesAndActions';

import { useGetIssuesAndActionsQuery } from './graphql';
import type { IssuesAndActionsResult } from './types';

interface Props {
  accountUuid: string;
  zencargoReference: string;
}

const IssuesAndActionsContainer: FC<Props> = ({ zencargoReference, accountUuid }) => {
  const { data, loading, error, refetch } = useGetIssuesAndActionsQuery({
    variables: { zencargoReference },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  return (
    <QueryHandler
      data={data?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonLoading className="my-5" height={50} />}
    >
      {(issuesAndActions: IssuesAndActionsResult) => {
        const canModifyIssueVisibility: boolean = checkPermission(issuesAndActions, 'canModifyIssueVisibility');
        const canOpenIssue: boolean = checkPermission(issuesAndActions, 'canOpenIssue');
        const { actionItems, issues } = issuesAndActions;

        return (
          <IssuesAndActions
            accountUuid={accountUuid}
            actionItems={actionItems}
            canEditIssueVisibility={canModifyIssueVisibility}
            canOpenIssue={canOpenIssue}
            issues={issues}
            onShipmentChange={refetch}
            zencargoReference={zencargoReference}
          />
        );
      }}
    </QueryHandler>
  );
};

export default IssuesAndActionsContainer;
