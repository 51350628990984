import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderOtherDetailsQueryVariables = GraphQLTypes.Exact<{
  orderId: GraphQLTypes.Scalars['String'];
}>;

export type OrderOtherDetailsQueryResult = { __typename?: 'Query' } & {
  purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id'> & {
              buyer?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'>)
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      typename: 'NetworksOrgLoc';
                    } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksOrgLocOrganisation,
                          'id' | 'name'
                        >
                      >;
                      location?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
                            address?: GraphQLTypes.Maybe<
                              { __typename?: 'Address' } & Pick<
                                GraphQLTypes.Address,
                                'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                              > & {
                                  country?: GraphQLTypes.Maybe<
                                    { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                  >;
                                }
                            >;
                          }
                      >;
                      contact?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocContact' } & Pick<
                          GraphQLTypes.NetworksOrgLocContact,
                          'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
              >;
              destination?: GraphQLTypes.Maybe<
                | ({ __typename: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'>)
                | ({ __typename: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & { typename: 'NetworksOrgLoc' } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksOrgLocOrganisation,
                          'id' | 'name'
                        >
                      >;
                      location?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
                            address?: GraphQLTypes.Maybe<
                              { __typename?: 'Address' } & Pick<
                                GraphQLTypes.Address,
                                'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                              > & {
                                  country?: GraphQLTypes.Maybe<
                                    { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                  >;
                                }
                            >;
                          }
                      >;
                      contact?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocContact' } & Pick<
                          GraphQLTypes.NetworksOrgLocContact,
                          'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                    })
                | ({ __typename: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
              >;
              manufacturer?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'>)
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      typename: 'NetworksOrgLoc';
                    } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksOrgLocOrganisation,
                          'id' | 'name'
                        >
                      >;
                      location?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
                            address?: GraphQLTypes.Maybe<
                              { __typename?: 'Address' } & Pick<
                                GraphQLTypes.Address,
                                'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                              > & {
                                  country?: GraphQLTypes.Maybe<
                                    { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                  >;
                                }
                            >;
                          }
                      >;
                      contact?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocContact' } & Pick<
                          GraphQLTypes.NetworksOrgLocContact,
                          'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
              >;
              origin?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'>)
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      typename: 'NetworksOrgLoc';
                    } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksOrgLocOrganisation,
                          'id' | 'name'
                        >
                      >;
                      location?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
                            address?: GraphQLTypes.Maybe<
                              { __typename?: 'Address' } & Pick<
                                GraphQLTypes.Address,
                                'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                              > & {
                                  country?: GraphQLTypes.Maybe<
                                    { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                  >;
                                }
                            >;
                          }
                      >;
                      contact?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocContact' } & Pick<
                          GraphQLTypes.NetworksOrgLocContact,
                          'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
              >;
              seller?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'>)
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      typename: 'NetworksOrgLoc';
                    } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksOrgLocOrganisation,
                          'id' | 'name'
                        >
                      >;
                      location?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
                            address?: GraphQLTypes.Maybe<
                              { __typename?: 'Address' } & Pick<
                                GraphQLTypes.Address,
                                'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                              > & {
                                  country?: GraphQLTypes.Maybe<
                                    { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                  >;
                                }
                            >;
                          }
                      >;
                      contact?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocContact' } & Pick<
                          GraphQLTypes.NetworksOrgLocContact,
                          'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
              >;
            }
        >
      >
    >;
  };
};

export const OrderOtherDetailsDocument = /* #__PURE__ */ gql`
  query orderOtherDetails($orderId: String!) {
    purchaseOrders(id: $orderId) {
      nodes {
        buyer {
          id
          ... on NetworksOrgLoc {
            typename: __typename
            organisation {
              id
              name
            }
            location {
              id
              name
              address {
                street
                city
                country {
                  name
                  code
                }
                postalCodeOrZip
                countyOrState
              }
            }
            contact {
              id
              firstName
              lastName
              email
              phoneNumber
            }
          }
        }
        destination {
          __typename
          id
          ... on NetworksOrgLoc {
            typename: __typename
            organisation {
              id
              name
            }
            location {
              id
              name
              address {
                street
                city
                country {
                  name
                  code
                }
                postalCodeOrZip
                countyOrState
              }
            }
            contact {
              id
              firstName
              lastName
              email
              phoneNumber
            }
          }
        }
        id
        manufacturer {
          id
          ... on NetworksOrgLoc {
            typename: __typename
            organisation {
              id
              name
            }
            location {
              id
              name
              address {
                street
                city
                country {
                  name
                  code
                }
                postalCodeOrZip
                countyOrState
              }
            }
            contact {
              id
              firstName
              lastName
              email
              phoneNumber
            }
          }
        }
        origin {
          id
          ... on NetworksOrgLoc {
            typename: __typename
            organisation {
              id
              name
            }
            location {
              id
              name
              address {
                street
                city
                country {
                  name
                  code
                }
                postalCodeOrZip
                countyOrState
              }
            }
            contact {
              id
              firstName
              lastName
              email
              phoneNumber
            }
          }
        }
        seller {
          id
          ... on NetworksOrgLoc {
            typename: __typename
            organisation {
              id
              name
            }
            location {
              id
              name
              address {
                street
                city
                country {
                  name
                  code
                }
                postalCodeOrZip
                countyOrState
              }
            }
            contact {
              id
              firstName
              lastName
              email
              phoneNumber
            }
          }
        }
      }
    }
  }
`;

/**
 * __useOrderOtherDetailsQuery__
 *
 * To run a query within a React component, call `useOrderOtherDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderOtherDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderOtherDetailsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderOtherDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderOtherDetailsQueryResult, OrderOtherDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderOtherDetailsQueryResult, OrderOtherDetailsQueryVariables>(OrderOtherDetailsDocument, options);
}
export function useOrderOtherDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderOtherDetailsQueryResult, OrderOtherDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderOtherDetailsQueryResult, OrderOtherDetailsQueryVariables>(OrderOtherDetailsDocument, options);
}
export type OrderOtherDetailsQueryHookResult = ReturnType<typeof useOrderOtherDetailsQuery>;
export type OrderOtherDetailsLazyQueryHookResult = ReturnType<typeof useOrderOtherDetailsLazyQuery>;
