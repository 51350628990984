import { Table, TableColumn } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks';
import {
  useInviteMutation,
  useNetworksDeleteContactMutation,
  useNetworksDeleteLocationContactsMutation,
  useResetPasswordMutation
} from '@zen/Networks/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';

import { getColumns } from './tableConfiguration';
import { ContactRemoveLevelType, ContactTableDataType } from './types';

export interface ContactTableProps<T extends ContactTableDataType> {
  contacts: T[];
  isMyOrganisationPage?: boolean;
  locationId?: string;
  onContactChange: () => void;
  onRemove: () => void;
  organisationId: string;
  removeType: ContactRemoveLevelType;
  throughLocationDetails?: boolean;
  totalCount: number;
}

const ContactTable = <T extends ContactTableDataType>(props: ContactTableProps<T>) => {
  const {
    contacts,
    organisationId,
    isMyOrganisationPage = false,
    throughLocationDetails = false,
    onRemove,
    removeType,
    locationId = null,
    onContactChange,
    totalCount
  } = props;

  const { routes } = useNetworksContext();

  const onDeleteCompleted = (): void => {
    addSuccess('Contact has been removed');
    onRemove();
  };

  const [deleteContact] = useNetworksDeleteContactMutation({
    onError: () => addError(),
    onCompleted: onDeleteCompleted
  });
  const [deleteLocationContacts] = useNetworksDeleteLocationContactsMutation({
    onError: () => addError(),
    onCompleted: onDeleteCompleted
  });

  const deleteRequest = {
    [ContactRemoveLevelType.ORGANISATION]: (contactId: string) =>
      deleteContact({
        variables: {
          input: {
            contactId
          }
        }
      }),
    [ContactRemoveLevelType.LOCATION]: (contactId: string) => {
      return (
        locationId &&
        deleteLocationContacts({
          variables: { input: { contactIds: [contactId], locationIds: [locationId] } }
        })
      );
    }
  };

  const [invite] = useInviteMutation({
    onError: () => addError(),
    onCompleted: () => {
      addSuccess('Contact has been invited');
      onContactChange();
    }
  });
  const [resetPassword] = useResetPasswordMutation({
    onError: () => addError(),
    onCompleted: () => addSuccess('Password has been reset')
  });

  const { addSuccess, addError } = useNotification();

  const handleRemove = (contactId: string): void => {
    deleteRequest[removeType](contactId);
  };

  const handleInvite = (contactId: string): void => {
    invite({
      variables: {
        input: {
          inviteeId: contactId
        }
      }
    });
  };

  const handleResendPassword = (contactId: string): void => {
    resetPassword({
      variables: {
        input: {
          userId: contactId
        }
      }
    });
  };

  const columns: TableColumn<T>[] = getColumns<T>({
    isMyOrganisationPage,
    locationId,
    onInvite: handleInvite,
    onRemove: handleRemove,
    onResendPassword: handleResendPassword,
    organisationId,
    removeType,
    routes,
    throughLocationDetails
  });

  return (
    <Table<T>
      columns={columns}
      data={contacts}
      tableId="contacts-table"
      totalCountConfig={{
        totalCount,
        entityName: 'contact'
      }}
    />
  );
};

export default ContactTable;
