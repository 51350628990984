import { FC, useEffect } from 'react';

import type { ScheduleItemType } from '@zen/CollectionAndDelivery/Details/types';
import { useCancelCollectionMutation, useCancelDeliveryMutation } from '@zen/CollectionAndDelivery/graphql';
import { useMutationIteration } from '@zen/utils/hooks/useMutationIteration';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  cargoIds: string[];
  handleError?: (errorIds: string[], message: string) => void;
  handleSuccess: () => void;
  scheduleType: ScheduleItemType;
  zencargoReference: string;
}

const CancelSchedule: FC<Props> = ({ zencargoReference, cargoIds, scheduleType, handleSuccess, handleError }) => {
  const multiCargo = cargoIds.length > 1;
  const { addSuccess } = useNotification();

  const onError = (errorItems: string[]) => {
    const totalErrors = errorItems.length;
    const errorMessage = multiCargo
      ? `There was an error cancelling ${totalErrors} cargos`
      : 'There was an error cancelling this cargo';

    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleError(errorItems, errorMessage);
  };

  const onSuccess = (successResult: string) => {
    const successMessage = multiCargo ? `${successResult} selected cargos have been cancelled` : 'Cargo has been cancelled';

    addSuccess(successMessage);
    handleSuccess();
  };

  const { handleMutation } = useMutationIteration(cargoIds, onSuccess, onError);

  const [cancelCollection] = useCancelCollectionMutation();

  const [cancelDelivery] = useCancelDeliveryMutation();

  const cancelSchedule = scheduleType === 'collection' ? cancelCollection : cancelDelivery;

  useEffect(() => {
    handleMutation((id: string) =>
      cancelSchedule({
        variables: { input: { id, zencargoReference } },
        refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
        awaitRefetchQueries: true
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelSchedule, cargoIds, zencargoReference]);

  return null;
};

export default CancelSchedule;
