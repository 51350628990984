import { IncotermsValue } from '@zen/types';

export const LABEL_FOR_INCOTERMS: Record<IncotermsValue, string> = {
  [IncotermsValue.CFR]: 'CFR',
  [IncotermsValue.CIF]: 'CIF',
  [IncotermsValue.CIP]: 'CIP',
  [IncotermsValue.CPT]: 'CPT',
  [IncotermsValue.DAP]: 'DAP',
  [IncotermsValue.DAT]: 'DAT',
  [IncotermsValue.DDP]: 'DDP',
  [IncotermsValue.EXWORKS]: 'EXW',
  [IncotermsValue.FAS]: 'FAS',
  [IncotermsValue.FCA]: 'FCA',
  [IncotermsValue.FOB]: 'FOB'
};

export const SEARCH_DEBOUNCE_DELAY: number = 300;
