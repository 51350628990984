import type { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import productSettingsRoutes from '@zen/Routes/productSettings';

import ManageTerminals from '../ManageTerminals';
import UNLOCodeTerminals from '../UNLOCodeTerminals';

const TerminalsContainer: FC = () => {
  return (
    <Switch>
      <Route path={productSettingsRoutes.unloCodeTerminals.path}>
        <UNLOCodeTerminals />
      </Route>
      <Route exact={true} path={productSettingsRoutes.manageTerminals.path}>
        <ManageTerminals />
      </Route>
    </Switch>
  );
};

export default TerminalsContainer;
