import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';

import { Pill } from '@zen/DesignSystem';
import type { Delay } from '@zen/Shipments/types';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  className?: string;
  delay: Optional<Delay>;
  hasArrived?: boolean;
  hasLatestDeliveryEstimate?: boolean;
}

const ShipmentStatus: FC<Props> = (props) => {
  const { className, delay, hasLatestDeliveryEstimate = false, hasArrived = false } = props;

  const showTooltip: boolean = delay?.days !== 0 && !!delay?.type;

  const tooltip: ReactNode = (
    <div className="p-4 min-w-64">
      {delay?.type && <div className="text-lg mb-2">{delay?.type}</div>}
      {delay?.description && <div>{delay?.description}</div>}
    </div>
  );

  const renderPill = (): ReactNode => {
    const prefix: string = delay?.days && delay?.days > 0 ? '+' : '';
    const label: string = delay?.days ? `${delay?.days} ${pluralize('day', delay?.days)}` : '';

    if (hasArrived) {
      return (
        <Pill className={className} type="success">
          Delivered
        </Pill>
      );
    }

    if (!hasLatestDeliveryEstimate && delay?.days === 0) {
      return null;
    }

    if (hasLatestDeliveryEstimate && delay?.days === 0) {
      return <Pill className={className}>On time</Pill>;
    }

    return (
      <Pill className={className} tooltip={showTooltip ? tooltip : null} type="problem">
        {prefix}
        {label}
      </Pill>
    );
  };

  return <div data-testid="shipment-status">{renderPill()}</div>;
};

export default ShipmentStatus;
