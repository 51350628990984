import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateCollectionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateCollectionInput;
}>;

export type UpdateCollectionMutationResult = { __typename?: 'Mutation' } & {
  bookingCollectionAndDeliveryUpdateCollection?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateCollectionPayload' } & {
      collection?: GraphQLTypes.Maybe<
        { __typename?: 'CollectionDetails' } & Pick<GraphQLTypes.CollectionDetails, 'id' | 'vehiclePlateNumber'>
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const UpdateCollectionMutationDocument = /* #__PURE__ */ gql`
  mutation updateCollectionMutation($input: UpdateCollectionInput!) {
    bookingCollectionAndDeliveryUpdateCollection(input: $input) {
      collection {
        id
        vehiclePlateNumber
      }
      errors {
        message
      }
    }
  }
`;

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCollectionMutationResult, UpdateCollectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateCollectionMutationResult, UpdateCollectionMutationVariables>(
    UpdateCollectionMutationDocument,
    options
  );
}
export type UpdateCollectionMutationHookResult = ReturnType<typeof useUpdateCollectionMutation>;
