import type { SortInput } from '@zen/types';
import useUrlPagination from '@zen/utils/hooks/pagination/useUrlPagination';
import useAccount from '@zen/utils/hooks/useAccount';

import type { ConsolidationsQueryResult, ConsolidationsQueryVariables } from '../../graphql';
import { useConsolidationsQuery } from '../../graphql';
import type { ConsolidatedShipment } from '../../types';

const useConsolidations = (order: SortInput, searchText: string) => {
  const {
    accountUuid,
    userProfile: { isAdmin }
  } = useAccount();

  return useUrlPagination<ConsolidationsQueryResult, ConsolidationsQueryVariables, ConsolidatedShipment>(
    useConsolidationsQuery,
    'consolidations',
    {
      order,
      textContains: searchText,
      ...(isAdmin && { customerUuid: accountUuid })
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' },
    20
  );
};

export default useConsolidations;
