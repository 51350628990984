import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import InformationIcon from '@zen/Components/InformationIcon';
import { Icon } from '@zen/DesignSystem';
import type { IconType } from '@zen/types';

import { activePillClasses, defaultPillClasses } from './helpers';
import type { PillType } from './types';

interface Props {
  children: ReactNode;
  className?: string;
  iconLeft?: IconType;
  iconRight?: IconType;
  onClick?: () => void;
  tooltip?: ReactNode;
  type?: PillType;
}

const renderIcon = (icon: IconType, iconPosition: 'left' | 'right'): ReactNode => {
  const alignmentClassName: string = iconPosition === 'left' ? 'mr-2' : 'ml-2';

  if (typeof icon !== 'string') {
    return icon;
  }

  return <Icon className={`text-base ${alignmentClassName}`} icon={icon} />;
};

const Pill: FC<Props> = (props) => {
  const { children, className, iconLeft, iconRight, type = 'default', onClick, tooltip } = props;

  const defineRightIcon: IconType | undefined = tooltip ? (
    <InformationIcon className="ml-2" tooltipContent={tooltip} />
  ) : (
    iconRight
  );

  const classNames: string = cx(
    defaultPillClasses[type],
    {
      [activePillClasses[type]]: onClick,
      'cursor-pointer': onClick
    },
    'border-transparent border border-solid h-6 text-sm inline-block px-4 whitespace-nowrap justify-center rounded-full inline-flex',
    className
  );

  return (
    <span className={classNames} data-component="pill" data-testid={`pill-${type}`} onClick={onClick}>
      <span className="flex items-center">
        {iconLeft && renderIcon(iconLeft, 'left')}
        <span>{children}</span>
        {defineRightIcon && renderIcon(defineRightIcon, 'right')}
      </span>
    </span>
  );
};

export type { Props as PillProps };
export default Pill;
