import type { FC, ReactNode } from 'react';

import { Headline } from '@zen/DesignSystem';

import { ReactComponent as Logo } from './logo.svg';
import portImage from './port.jpg';

interface Props {
  children: ReactNode;
  title: string;
}

const AccountFormWrapper: FC<Props> = ({ children, title }) => {
  return (
    <div className="bg-white flex flex-col h-screen">
      <header className="h-16 pl-6 bg-white border-b border-solid border-grey-lighter flex items-center">
        <Logo className="w-32" />
      </header>
      <div className="flex-1 flex relative">
        <div
          className="flex-1 flex h-full justify-center items-center flex-col p-8 bg-cover w-full"
          style={{ backgroundImage: `url(${portImage})` }}
        />
        <div className="flex-1 flex h-full justify-center items-center flex-col p-8">
          <div className="w-full max-w-lg">
            <Headline className="text-navy-base mb-6" level={1}>
              {title}
            </Headline>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountFormWrapper;
