import {
  bookingRequestRoutes,
  consolidationRoutes,
  customerAdminRoutes,
  exchangeRatesRoutes,
  opsDashboardRoutes,
  orderRoutes,
  quoteRoutes,
  shipmentRoutes,
  taskListRoutes,
  workflowsRoutes
} from '@zen/Routes';
import accountsRoutes from '@zen/Routes/accounts';
import { ACCRUAL_ROUTE_PREFIX } from '@zen/Routes/accruals';
import { DOCUMENT_ROUTE_PREFIX } from '@zen/Routes/documents';
import { OPS_SETTINGS_PREFIX } from '@zen/Routes/opsSettings';
import { PRODUCT_SETTINGS_PREFIX } from '@zen/Routes/productSettings';
import rolesRoutes from '@zen/Routes/roles';

import useGlobalPermissions from '../useGlobalPermissions';

const { accountsIndex, accountsDetails } = accountsRoutes;
const { manufacturerRequestsDetailsPage, rejectedDetailsPage, readyForApprovalDetailsPage } = bookingRequestRoutes;
const { dashboardIndex } = opsDashboardRoutes;
const { orderDetails, orderList, orderDetailsPage } = orderRoutes;
const {
  shipmentIndex,
  shipmentDetails,
  shipmentEditCargo,
  shipmentDetailsPage,
  shipmentTransportScheduleUpdate,
  shipmentTransportScheduleCreate
} = shipmentRoutes;
const { consolidationIndex, consolidationShipmentsDetailsPage, consolidationAddShipmentsDetailsPage } = consolidationRoutes;

export const routeToEntityMapping = {
  '/dashboard/analytics': 'analytics',
  [accountsIndex.path]: 'accounts',
  [accountsDetails.path]: 'accounts',
  [customerAdminRoutes.index.path]: 'customerAdmin',
  [customerAdminRoutes.allShipments.path]: 'customerAdmin',
  '/dashboard/booking-requests': 'bookingRequests',
  [manufacturerRequestsDetailsPage.path]: 'bookingRequests',
  [readyForApprovalDetailsPage.path]: 'bookingRequests',
  [rejectedDetailsPage.path]: 'bookingRequests',
  [ACCRUAL_ROUTE_PREFIX]: 'accruals',
  [shipmentIndex.path]: 'bookings',
  [shipmentDetails.path]: 'bookings',
  [shipmentEditCargo.path]: 'bookings',
  [shipmentDetailsPage.path]: 'bookings',
  [shipmentTransportScheduleCreate.path]: 'bookings.transportSchedules.create',
  [shipmentTransportScheduleUpdate.path]: 'bookings.transportSchedules.update',
  '/dashboard': 'dashboard',
  '/dashboard/:id?': 'dashboard',
  '/dashboard/intake-planning': 'intakePlanning',
  '/dashboard/networks': 'networks',
  '/dashboard/products': 'products',
  '/dashboard/products/csv-import': 'productsCsvImport',
  [PRODUCT_SETTINGS_PREFIX]: 'productSettings',
  [orderList.path]: 'purchaseOrders',
  [orderDetails.path]: 'purchaseOrders',
  [orderDetailsPage.path]: 'purchaseOrders',
  '/dashboard/quotes': 'quotes',
  '/dashboard/quotes/:id?': 'quotes',
  [quoteRoutes.quoteDetailsPage.path]: 'quotes',
  '/dashboard/settings': 'settings',
  '/dashboard/freight-spend': 'freightSpend',
  '/dashboard/freight-spend/:id?': 'freightSpend',
  '/dashboard/rules-engine': 'rulesEngine',
  [dashboardIndex.path]: 'operationsDashboard',
  '/dashboard/ops-shipments': 'operationsDashboard',
  '/dashboard/ops-quotes': 'operationsDashboard',
  '/dashboard/ops-shipments/all-shipments': 'operationsDashboard',
  '/dashboard/ops-shipments/road-shipments': 'operationsDashboard',
  [OPS_SETTINGS_PREFIX]: 'operationsSettings',
  [DOCUMENT_ROUTE_PREFIX]: 'documentCreation',
  '/dashboard/zencargo-network': 'zencargoNetwork',
  [exchangeRatesRoutes.manageExchangeRatesIndex.path]: 'backoffice',
  [consolidationIndex.path]: 'consolidation',
  [consolidationShipmentsDetailsPage.path]: 'consolidation',
  [consolidationAddShipmentsDetailsPage.path]: 'consolidation',
  [taskListRoutes.taskListIndex.path]: 'taskList',
  [workflowsRoutes.workflowsIndex.path]: 'workflows',
  [workflowsRoutes.workflowsFlows.path]: 'workflows',
  [workflowsRoutes.workflowsTriggers.path]: 'workflows'
};

const routesWhitelist: string[] = [
  '/dashboard/api-console',
  '/dashboard/tracked-shipments',
  '/dashboard/utilisation',
  rolesRoutes.index.path
];

const useRoutePermissions = () => {
  const { check } = useGlobalPermissions();

  const canAccessRoute = (url: string): boolean => {
    const entity = routeToEntityMapping[url];

    if (entity) {
      return check(`${entity}.canAccessRoute`);
    }

    return routesWhitelist.includes(url);
  };

  return {
    canAccessRoute
  };
};

export default useRoutePermissions;
