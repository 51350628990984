import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RoadTrackingDataQueryVariables = GraphQLTypes.Exact<{
  newCargoService: GraphQLTypes.Scalars['Boolean'];
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type RoadTrackingDataQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & {
                  cargoItems?: GraphQLTypes.Maybe<
                    Array<
                      | ({ __typename?: 'CargoContainerType' } & Pick<GraphQLTypes.CargoContainerType, 'id'> & {
                            collection?: GraphQLTypes.Maybe<
                              { __typename?: 'CollectionDetails' } & Pick<
                                GraphQLTypes.CollectionDetails,
                                'vehiclePlateNumber'
                              > & {
                                  confirmedByForwarder?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                    }
                                  >;
                                  location?: GraphQLTypes.Maybe<
                                    | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                        GraphQLTypes.NetworksLegacyOrgLoc,
                                        'id' | 'timeZone'
                                      > & {
                                          businessHours?: GraphQLTypes.Maybe<
                                            { __typename?: 'BusinessHours' } & {
                                              monday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              tuesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              wednesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              thursday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              friday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              saturday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              sunday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                            }
                                          >;
                                          label?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksLabelType' } & Pick<
                                              GraphQLTypes.NetworksLabelType,
                                              'long' | 'short'
                                            >
                                          >;
                                        })
                                    | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                        GraphQLTypes.NetworksOrgLoc,
                                        'id' | 'timeZone'
                                      > & {
                                          location?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksOrgLocLocation' } & {
                                              geolocation?: GraphQLTypes.Maybe<
                                                { __typename?: 'Geolocation' } & Pick<
                                                  GraphQLTypes.Geolocation,
                                                  'latitude' | 'longitude'
                                                >
                                              >;
                                            }
                                          >;
                                          organisation?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                                              GraphQLTypes.NetworksOrgLocOrganisation,
                                              'id'
                                            >
                                          >;
                                          businessHours?: GraphQLTypes.Maybe<
                                            { __typename?: 'BusinessHours' } & {
                                              monday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              tuesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              wednesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              thursday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              friday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              saturday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              sunday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                            }
                                          >;
                                          label?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksLabelType' } & Pick<
                                              GraphQLTypes.NetworksLabelType,
                                              'long' | 'short'
                                            >
                                          >;
                                        })
                                    | ({ __typename?: 'NetworksTerminal' } & Pick<
                                        GraphQLTypes.NetworksTerminal,
                                        'id' | 'timeZone'
                                      > & {
                                          businessHours?: GraphQLTypes.Maybe<
                                            { __typename?: 'BusinessHours' } & {
                                              monday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              tuesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              wednesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              thursday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              friday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              saturday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              sunday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                            }
                                          >;
                                          label?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksLabelType' } & Pick<
                                              GraphQLTypes.NetworksLabelType,
                                              'long' | 'short'
                                            >
                                          >;
                                        })
                                  >;
                                }
                            >;
                            delivery?: GraphQLTypes.Maybe<
                              { __typename?: 'DeliveryDetails' } & {
                                confirmedByForwarder?: GraphQLTypes.Maybe<
                                  { __typename?: 'LocalDateTimeRange' } & {
                                    startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                      GraphQLTypes.LocalDateTimeType,
                                      'date' | 'time'
                                    >;
                                    endDateTime?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                  }
                                >;
                                location?: GraphQLTypes.Maybe<
                                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                      GraphQLTypes.NetworksLegacyOrgLoc,
                                      'id' | 'timeZone'
                                    > & {
                                        businessHours?: GraphQLTypes.Maybe<
                                          { __typename?: 'BusinessHours' } & {
                                            monday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            tuesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            wednesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            thursday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            friday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            saturday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            sunday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                          }
                                        >;
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'long' | 'short'
                                          >
                                        >;
                                      })
                                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id' | 'timeZone'> & {
                                        location?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksOrgLocLocation' } & {
                                            geolocation?: GraphQLTypes.Maybe<
                                              { __typename?: 'Geolocation' } & Pick<
                                                GraphQLTypes.Geolocation,
                                                'latitude' | 'longitude'
                                              >
                                            >;
                                          }
                                        >;
                                        organisation?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                                            GraphQLTypes.NetworksOrgLocOrganisation,
                                            'id'
                                          >
                                        >;
                                        businessHours?: GraphQLTypes.Maybe<
                                          { __typename?: 'BusinessHours' } & {
                                            monday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            tuesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            wednesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            thursday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            friday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            saturday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            sunday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                          }
                                        >;
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'long' | 'short'
                                          >
                                        >;
                                      })
                                  | ({ __typename?: 'NetworksTerminal' } & Pick<
                                      GraphQLTypes.NetworksTerminal,
                                      'id' | 'timeZone'
                                    > & {
                                        businessHours?: GraphQLTypes.Maybe<
                                          { __typename?: 'BusinessHours' } & {
                                            monday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            tuesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            wednesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            thursday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            friday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            saturday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            sunday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                          }
                                        >;
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'long' | 'short'
                                          >
                                        >;
                                      })
                                >;
                              }
                            >;
                          })
                      | ({ __typename?: 'CargoLooseCargoType' } & Pick<GraphQLTypes.CargoLooseCargoType, 'id'> & {
                            collection?: GraphQLTypes.Maybe<
                              { __typename?: 'CollectionDetails' } & Pick<
                                GraphQLTypes.CollectionDetails,
                                'vehiclePlateNumber'
                              > & {
                                  confirmedByForwarder?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                    }
                                  >;
                                  location?: GraphQLTypes.Maybe<
                                    | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                        GraphQLTypes.NetworksLegacyOrgLoc,
                                        'id' | 'timeZone'
                                      > & {
                                          businessHours?: GraphQLTypes.Maybe<
                                            { __typename?: 'BusinessHours' } & {
                                              monday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              tuesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              wednesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              thursday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              friday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              saturday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              sunday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                            }
                                          >;
                                          label?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksLabelType' } & Pick<
                                              GraphQLTypes.NetworksLabelType,
                                              'long' | 'short'
                                            >
                                          >;
                                        })
                                    | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                        GraphQLTypes.NetworksOrgLoc,
                                        'id' | 'timeZone'
                                      > & {
                                          location?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksOrgLocLocation' } & {
                                              geolocation?: GraphQLTypes.Maybe<
                                                { __typename?: 'Geolocation' } & Pick<
                                                  GraphQLTypes.Geolocation,
                                                  'latitude' | 'longitude'
                                                >
                                              >;
                                            }
                                          >;
                                          organisation?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                                              GraphQLTypes.NetworksOrgLocOrganisation,
                                              'id'
                                            >
                                          >;
                                          businessHours?: GraphQLTypes.Maybe<
                                            { __typename?: 'BusinessHours' } & {
                                              monday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              tuesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              wednesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              thursday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              friday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              saturday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              sunday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                            }
                                          >;
                                          label?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksLabelType' } & Pick<
                                              GraphQLTypes.NetworksLabelType,
                                              'long' | 'short'
                                            >
                                          >;
                                        })
                                    | ({ __typename?: 'NetworksTerminal' } & Pick<
                                        GraphQLTypes.NetworksTerminal,
                                        'id' | 'timeZone'
                                      > & {
                                          businessHours?: GraphQLTypes.Maybe<
                                            { __typename?: 'BusinessHours' } & {
                                              monday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              tuesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              wednesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              thursday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              friday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              saturday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              sunday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                            }
                                          >;
                                          label?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksLabelType' } & Pick<
                                              GraphQLTypes.NetworksLabelType,
                                              'long' | 'short'
                                            >
                                          >;
                                        })
                                  >;
                                }
                            >;
                            delivery?: GraphQLTypes.Maybe<
                              { __typename?: 'DeliveryDetails' } & {
                                confirmedByForwarder?: GraphQLTypes.Maybe<
                                  { __typename?: 'LocalDateTimeRange' } & {
                                    startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                      GraphQLTypes.LocalDateTimeType,
                                      'date' | 'time'
                                    >;
                                    endDateTime?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                  }
                                >;
                                location?: GraphQLTypes.Maybe<
                                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                      GraphQLTypes.NetworksLegacyOrgLoc,
                                      'id' | 'timeZone'
                                    > & {
                                        businessHours?: GraphQLTypes.Maybe<
                                          { __typename?: 'BusinessHours' } & {
                                            monday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            tuesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            wednesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            thursday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            friday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            saturday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            sunday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                          }
                                        >;
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'long' | 'short'
                                          >
                                        >;
                                      })
                                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id' | 'timeZone'> & {
                                        location?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksOrgLocLocation' } & {
                                            geolocation?: GraphQLTypes.Maybe<
                                              { __typename?: 'Geolocation' } & Pick<
                                                GraphQLTypes.Geolocation,
                                                'latitude' | 'longitude'
                                              >
                                            >;
                                          }
                                        >;
                                        organisation?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                                            GraphQLTypes.NetworksOrgLocOrganisation,
                                            'id'
                                          >
                                        >;
                                        businessHours?: GraphQLTypes.Maybe<
                                          { __typename?: 'BusinessHours' } & {
                                            monday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            tuesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            wednesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            thursday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            friday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            saturday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            sunday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                          }
                                        >;
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'long' | 'short'
                                          >
                                        >;
                                      })
                                  | ({ __typename?: 'NetworksTerminal' } & Pick<
                                      GraphQLTypes.NetworksTerminal,
                                      'id' | 'timeZone'
                                    > & {
                                        businessHours?: GraphQLTypes.Maybe<
                                          { __typename?: 'BusinessHours' } & {
                                            monday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            tuesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            wednesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            thursday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            friday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            saturday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            sunday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                          }
                                        >;
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'long' | 'short'
                                          >
                                        >;
                                      })
                                >;
                              }
                            >;
                          })
                      | ({ __typename?: 'CargoVehicleType' } & Pick<GraphQLTypes.CargoVehicleType, 'trailerId' | 'id'> & {
                            collection?: GraphQLTypes.Maybe<
                              { __typename?: 'CollectionDetails' } & Pick<
                                GraphQLTypes.CollectionDetails,
                                'vehiclePlateNumber'
                              > & {
                                  confirmedByForwarder?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                    }
                                  >;
                                  location?: GraphQLTypes.Maybe<
                                    | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                        GraphQLTypes.NetworksLegacyOrgLoc,
                                        'id' | 'timeZone'
                                      > & {
                                          businessHours?: GraphQLTypes.Maybe<
                                            { __typename?: 'BusinessHours' } & {
                                              monday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              tuesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              wednesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              thursday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              friday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              saturday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              sunday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                            }
                                          >;
                                          label?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksLabelType' } & Pick<
                                              GraphQLTypes.NetworksLabelType,
                                              'long' | 'short'
                                            >
                                          >;
                                        })
                                    | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                        GraphQLTypes.NetworksOrgLoc,
                                        'id' | 'timeZone'
                                      > & {
                                          location?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksOrgLocLocation' } & {
                                              geolocation?: GraphQLTypes.Maybe<
                                                { __typename?: 'Geolocation' } & Pick<
                                                  GraphQLTypes.Geolocation,
                                                  'latitude' | 'longitude'
                                                >
                                              >;
                                            }
                                          >;
                                          organisation?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                                              GraphQLTypes.NetworksOrgLocOrganisation,
                                              'id'
                                            >
                                          >;
                                          businessHours?: GraphQLTypes.Maybe<
                                            { __typename?: 'BusinessHours' } & {
                                              monday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              tuesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              wednesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              thursday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              friday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              saturday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              sunday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                            }
                                          >;
                                          label?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksLabelType' } & Pick<
                                              GraphQLTypes.NetworksLabelType,
                                              'long' | 'short'
                                            >
                                          >;
                                        })
                                    | ({ __typename?: 'NetworksTerminal' } & Pick<
                                        GraphQLTypes.NetworksTerminal,
                                        'id' | 'timeZone'
                                      > & {
                                          businessHours?: GraphQLTypes.Maybe<
                                            { __typename?: 'BusinessHours' } & {
                                              monday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              tuesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              wednesday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              thursday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              friday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              saturday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                              sunday?: GraphQLTypes.Maybe<
                                                { __typename?: 'TimeRange' } & Pick<
                                                  GraphQLTypes.TimeRange,
                                                  'startTime' | 'endTime'
                                                >
                                              >;
                                            }
                                          >;
                                          label?: GraphQLTypes.Maybe<
                                            { __typename?: 'NetworksLabelType' } & Pick<
                                              GraphQLTypes.NetworksLabelType,
                                              'long' | 'short'
                                            >
                                          >;
                                        })
                                  >;
                                }
                            >;
                            delivery?: GraphQLTypes.Maybe<
                              { __typename?: 'DeliveryDetails' } & {
                                confirmedByForwarder?: GraphQLTypes.Maybe<
                                  { __typename?: 'LocalDateTimeRange' } & {
                                    startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                      GraphQLTypes.LocalDateTimeType,
                                      'date' | 'time'
                                    >;
                                    endDateTime?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                  }
                                >;
                                location?: GraphQLTypes.Maybe<
                                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                      GraphQLTypes.NetworksLegacyOrgLoc,
                                      'id' | 'timeZone'
                                    > & {
                                        businessHours?: GraphQLTypes.Maybe<
                                          { __typename?: 'BusinessHours' } & {
                                            monday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            tuesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            wednesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            thursday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            friday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            saturday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            sunday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                          }
                                        >;
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'long' | 'short'
                                          >
                                        >;
                                      })
                                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id' | 'timeZone'> & {
                                        location?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksOrgLocLocation' } & {
                                            geolocation?: GraphQLTypes.Maybe<
                                              { __typename?: 'Geolocation' } & Pick<
                                                GraphQLTypes.Geolocation,
                                                'latitude' | 'longitude'
                                              >
                                            >;
                                          }
                                        >;
                                        organisation?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                                            GraphQLTypes.NetworksOrgLocOrganisation,
                                            'id'
                                          >
                                        >;
                                        businessHours?: GraphQLTypes.Maybe<
                                          { __typename?: 'BusinessHours' } & {
                                            monday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            tuesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            wednesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            thursday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            friday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            saturday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            sunday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                          }
                                        >;
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'long' | 'short'
                                          >
                                        >;
                                      })
                                  | ({ __typename?: 'NetworksTerminal' } & Pick<
                                      GraphQLTypes.NetworksTerminal,
                                      'id' | 'timeZone'
                                    > & {
                                        businessHours?: GraphQLTypes.Maybe<
                                          { __typename?: 'BusinessHours' } & {
                                            monday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            tuesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            wednesday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            thursday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            friday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            saturday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                            sunday?: GraphQLTypes.Maybe<
                                              { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                                            >;
                                          }
                                        >;
                                        label?: GraphQLTypes.Maybe<
                                          { __typename?: 'NetworksLabelType' } & Pick<
                                            GraphQLTypes.NetworksLabelType,
                                            'long' | 'short'
                                          >
                                        >;
                                      })
                                >;
                              }
                            >;
                          })
                    >
                  >;
                }
              >;
              cargos?: GraphQLTypes.Maybe<Array<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'id'>>>;
              haulier?: GraphQLTypes.Maybe<{ __typename?: 'NetworkContact' } & Pick<GraphQLTypes.NetworkContact, 'id' | 'name'>>;
            }
        >
      >
    >;
  };
};

export const RoadTrackingDataDocument = /* #__PURE__ */ gql`
  query roadTrackingData($newCargoService: Boolean!, $zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        cargo {
          cargoItems {
            collection {
              confirmedByForwarder {
                startDateTime {
                  date
                  time
                }
                endDateTime {
                  date
                  time
                }
              }
              location {
                businessHours {
                  monday {
                    startTime
                    endTime
                  }
                  tuesday {
                    startTime
                    endTime
                  }
                  wednesday {
                    startTime
                    endTime
                  }
                  thursday {
                    startTime
                    endTime
                  }
                  friday {
                    startTime
                    endTime
                  }
                  saturday {
                    startTime
                    endTime
                  }
                  sunday {
                    startTime
                    endTime
                  }
                }
                id
                label {
                  long
                  short
                }
                ... on NetworksOrgLoc {
                  location {
                    geolocation {
                      latitude
                      longitude
                    }
                  }
                  organisation {
                    id
                  }
                }
                timeZone
              }
              vehiclePlateNumber
            }
            delivery {
              confirmedByForwarder {
                startDateTime {
                  date
                  time
                }
                endDateTime {
                  date
                  time
                }
              }
              location {
                businessHours {
                  monday {
                    startTime
                    endTime
                  }
                  tuesday {
                    startTime
                    endTime
                  }
                  wednesday {
                    startTime
                    endTime
                  }
                  thursday {
                    startTime
                    endTime
                  }
                  friday {
                    startTime
                    endTime
                  }
                  saturday {
                    startTime
                    endTime
                  }
                  sunday {
                    startTime
                    endTime
                  }
                }
                id
                label {
                  long
                  short
                }
                ... on NetworksOrgLoc {
                  location {
                    geolocation {
                      latitude
                      longitude
                    }
                  }
                  organisation {
                    id
                  }
                }
                timeZone
              }
            }
            id
            ... on CargoVehicleType {
              trailerId
            }
          }
        }
        cargos @include(if: $newCargoService) {
          id
        }
        haulier {
          id
          name
        }
        zencargoReference
      }
    }
  }
`;

/**
 * __useRoadTrackingDataQuery__
 *
 * To run a query within a React component, call `useRoadTrackingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadTrackingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadTrackingDataQuery({
 *   variables: {
 *      newCargoService: // value for 'newCargoService'
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useRoadTrackingDataQuery(
  baseOptions: Apollo.QueryHookOptions<RoadTrackingDataQueryResult, RoadTrackingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RoadTrackingDataQueryResult, RoadTrackingDataQueryVariables>(RoadTrackingDataDocument, options);
}
export function useRoadTrackingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoadTrackingDataQueryResult, RoadTrackingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RoadTrackingDataQueryResult, RoadTrackingDataQueryVariables>(RoadTrackingDataDocument, options);
}
export type RoadTrackingDataQueryHookResult = ReturnType<typeof useRoadTrackingDataQuery>;
export type RoadTrackingDataLazyQueryHookResult = ReturnType<typeof useRoadTrackingDataLazyQuery>;
