import type { Collection } from './Collection';

class FixedCollection<T> implements Collection<T> {
  private input: T[];

  private windowSizeVal: number;

  constructor(input: T[], windowSize: number) {
    this.input = input;
    this.windowSizeVal = windowSize;
  }

  public windowSize() {
    return this.windowSizeVal;
  }

  public windowAtPosition(position: number): T[] {
    let cutPosition = position;

    if (cutPosition > this.input.length - this.windowSizeVal) {
      cutPosition = this.input.length - this.windowSizeVal;
    }

    return this.input.slice(cutPosition, cutPosition + this.windowSizeVal);
  }

  public isPositionWithinBounds(position: number) {
    if (position < 0) {
      return false;
    }
    if (position > this.input.length - this.windowSizeVal) {
      return false;
    }

    return true;
  }

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  public findIndex(value, comparator): number {
    return this.input.findIndex((item) => comparator(value, item));
  }
}

export default FixedCollection;
