import type { FC } from 'react';

import { ContextMenu, MenuItemType } from '@zen/DesignSystem';
import { consolidationRoutes } from '@zen/Routes';
import type { IconName } from '@zen/Styleguide';
import { stringifyQueryParams } from '@zen/utils/QueryParams/queryParams';

interface Props {
  canCopy: boolean;
  canEdit: boolean;
  canManageShipments: boolean;
  consolidationId: string;
}

const ActionHandleContainer: FC<Props> = ({ canCopy, canEdit, canManageShipments, consolidationId }) => {
  const { consolidationEdit, consolidationShipments, consolidationCreate } = consolidationRoutes;

  const consolidationEditLink: string = consolidationEdit.getUrl(consolidationId);
  const manageShipmentsTabLink: string = consolidationShipments.getUrl(consolidationId);
  const cloneLink: { pathname: string; search: string } = {
    pathname: consolidationCreate.getUrl(),
    search: stringifyQueryParams({ clone: consolidationId })
  };

  const menuItems: MenuItemType[] = [
    ...(canEdit ? [{ icon: 'zicon-edit' as IconName, label: 'Edit consolidation details', linkTo: consolidationEditLink }] : []),
    ...(canManageShipments ? [{ icon: 'zicon-add' as IconName, label: 'Manage shipments', linkTo: manageShipmentsTabLink }] : []),
    ...(canCopy ? [{ icon: 'zicon-copy' as IconName, label: 'Clone', linkTo: cloneLink }] : [])
  ];

  return <ContextMenu inline={false} items={menuItems} />;
};

export default ActionHandleContainer;
