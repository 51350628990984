import { createContext } from 'react';

import type { ActivityFeedOriginViewType } from '@zen/ActivityFeed';
import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

export interface ShipmentDetailsPageContextData {
  activityFeedViewType?: ActivityFeedOriginViewType;
}

export const initialContext: ShipmentDetailsPageContextData = {};

const ShipmentDetailsPageContext = createContext<ShipmentDetailsPageContextData>(initialContext);

export const useShipmentDetailsPageContext = (): ShipmentDetailsPageContextData => {
  const shipmentDetailsPageData = useContextOrThrowError<ShipmentDetailsPageContextData>(
    ShipmentDetailsPageContext,
    'useShipmentDetailsPageContext must be used inside ShipmentDetailsPageContextProvider'
  );

  return shipmentDetailsPageData;
};

export default ShipmentDetailsPageContext;
