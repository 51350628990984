import { omitEmptyValues } from '@zen/utils/Filtering/helpers';

import type { PersistedFiltersType } from './types';

export const removeEmptyOperationsFilters = <T extends {}>(values: PersistedFiltersType<T>) => {
  const { issueTitle } = values;
  const consideredEmptyValues = issueTitle === '' ? { issueTitle } : {};

  return { ...omitEmptyValues(values), ...consideredEmptyValues };
};
