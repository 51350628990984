import type { FC } from 'react';

import Card from '@zen/Components/Card';
import { highlightQuery } from '@zen/Components/Form/utils';
import type { ProductListItem } from '@zen/Products/types';
import Routing from '@zen/utils/Routing/index';

interface Props {
  product: ProductListItem;
  query: string;
}

const ProductItem: FC<Props> = ({ product, query }) => {
  const { id, name, skuCode, hsCode } = product;

  return (
    <Card linkTo={Routing.showProductPath(id)} title={highlightQuery(name || '', query)}>
      <div className="text-sm mb-1" data-testid="sku">
        SKU: {highlightQuery(skuCode || '', query)}
      </div>
      <div className="text-sm" data-testid="hs">
        HS code: {highlightQuery(hsCode || '', query)}
      </div>
    </Card>
  );
};

export default ProductItem;
