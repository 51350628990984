import { kebabCase } from 'lodash';
import type { FC } from 'react';

import Gateway from '@zen/Components/Gateway';
import { Counter } from '@zen/DesignSystem';

import type { SavedFilterConfigItem } from '../types';

interface Props {
  isDisabled: boolean;
  item: SavedFilterConfigItem;
  onCounterClick: () => void;
  onSelect: () => void;
}

const SavedFilterItem: FC<Props> = ({ item, isDisabled, onSelect, onCounterClick }) => {
  const { title, subtitle, type, count } = item;

  return (
    <div data-component="saved-filter-item">
      <Gateway className={`py-2 px-4 mr-8 fs-${kebabCase(item.subtitle)}`} isDisabled={isDisabled} onClick={onSelect}>
        <div className="flex justify-between items-center">
          <div className="mr-6">
            <p className="font-bold">{title}</p>
            <p className="text-xs">{subtitle}</p>
          </div>
          <div onClick={onCounterClick}>
            <Counter className="cursor-pointer" type={type} value={count} />
          </div>
        </div>
      </Gateway>
    </div>
  );
};

export default SavedFilterItem;
