import cx from 'classnames';
import { omit, size } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';

import FiltersButton from '@zen/Components/FiltersButton';
import Slideout from '@zen/Components/Slideout';
import type { OrderFilters } from '@zen/Orders/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import OrdersSearch from '../OrdersSearch';
import FiltersForm from './FiltersForm';

interface Props {
  onClose?: () => void;
  openFilters: boolean;
  showSearch: boolean;
}

const Filters: FC<Props> = ({ onClose, openFilters, showSearch }) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const { appliedFilters, setAppliedFilters } = useAppliedFilters<OrderFilters>();

  const handleOutsideClick = useCallback(() => {
    setShowFilters(false);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (openFilters) {
      setShowFilters(true);
    }
  }, [openFilters]);

  const classNames: string = cx({ 'flex-1': showSearch }, 'flex justify-end');
  const filtersCount: number = size(appliedFilters);

  const initialValues: OrderFilters = {
    ...appliedFilters,
    // added for validation purposes
    cargoReadyDateBetween: {
      startDate: appliedFilters.cargoReadyDateBetween?.startDate || '',
      endDate: appliedFilters.cargoReadyDateBetween?.endDate || ''
    },
    ...(appliedFilters.cargoReadyDateBetween ? { cargoReadyDateIn: null } : {})
  };

  const clearFilters = (): void => setAppliedFilters({});

  const handleSubmit = (values: OrderFilters): Promise<IOkOrErrorResult> => {
    const { cargoReadyDateBetween, cargoReadyDateIn } = values;

    const pathsToOmit: string[] = [
      ...(!cargoReadyDateBetween?.startDate || cargoReadyDateIn ? ['cargoReadyDateBetween'] : []),
      ...(!cargoReadyDateIn ? ['cargoReadyDateIn'] : [])
    ];

    const filtersToApply: OrderFilters = omit(values, pathsToOmit);

    setAppliedFilters(filtersToApply);

    return Promise.resolve({ ok: true, error: null });
  };

  return (
    <div className={classNames} data-testid="order-filters">
      {showSearch && (
        <div className="flex-1">
          <OrdersSearch />
        </div>
      )}
      <FiltersButton className="fs-orders-filters ml-4" filterCount={filtersCount} onClick={() => setShowFilters(true)} />
      <Slideout onOutsideClick={handleOutsideClick} overlay={true} show={showFilters}>
        <FiltersForm initialValues={initialValues} onClose={handleOutsideClick} onReset={clearFilters} onSubmit={handleSubmit} />
      </Slideout>
    </div>
  );
};

export default Filters;
