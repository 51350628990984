import pluralize from 'pluralize';
import type { FC } from 'react';

import { Pill } from '@zen/DesignSystem';

import { ActionItem, ActionItemStatus } from '../types';

interface Props {
  actionItems: Pick<ActionItem, 'title' | 'status'>[];
  showDescription?: boolean;
}

const ActionItemCounterPill: FC<Props> = ({ actionItems, showDescription = false }) => {
  const activeActionItems = actionItems.filter(({ status }) => status === ActionItemStatus.PENDING);
  const tooltip = activeActionItems.map(({ title }, i) => (
    <div key={i} className="leading-6 font-bold">
      {title}
    </div>
  ));
  const description = showDescription ? pluralize('Action', activeActionItems.length) : '';

  if (!actionItems.length) {
    return null;
  }

  return (
    <Pill tooltip={tooltip} type="pending">
      {activeActionItems.length} {description}
    </Pill>
  );
};

export default ActionItemCounterPill;
