import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IntakePlanningDataQualityQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  dateRange: GraphQLTypes.DateRangeInput;
  productId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  destinationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  analyticsPage: GraphQLTypes.Scalars['Boolean'];
}>;

export type IntakePlanningDataQualityQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & {
      intakePlanningDataQuality: { __typename?: 'DataQuality' } & Pick<
        GraphQLTypes.DataQuality,
        'totalProducts' | 'totalPurchaseOrders'
      > & {
          productsWithIssues: Array<{ __typename?: 'Product' } & Pick<GraphQLTypes.Product, 'id' | 'name' | 'skuCode'>>;
          purchaseOrdersWithIssues: Array<
            { __typename?: 'PurchaseOrdersWithIssues' } & Pick<GraphQLTypes.PurchaseOrdersWithIssues, 'relatedIssues'> & {
                purchaseOrder: { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id' | 'orderReferenceNumber'>;
              }
          >;
        };
    }
  >;
};

export const IntakePlanningDataQualityDocument = /* #__PURE__ */ gql`
  query IntakePlanningDataQuality(
    $accountUuid: String!
    $dateRange: DateRangeInput!
    $productId: String
    $destinationId: String
    $analyticsPage: Boolean!
  ) {
    account(uuid: $accountUuid) {
      intakePlanningDataQuality(
        dateRange: $dateRange
        productId: $productId
        destinationId: $destinationId
        analyticsPage: $analyticsPage
      ) {
        productsWithIssues {
          id
          name
          skuCode
        }
        purchaseOrdersWithIssues {
          relatedIssues
          purchaseOrder {
            id
            orderReferenceNumber
          }
        }
        totalProducts
        totalPurchaseOrders
      }
    }
  }
`;

/**
 * __useIntakePlanningDataQualityQuery__
 *
 * To run a query within a React component, call `useIntakePlanningDataQualityQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntakePlanningDataQualityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntakePlanningDataQualityQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      dateRange: // value for 'dateRange'
 *      productId: // value for 'productId'
 *      destinationId: // value for 'destinationId'
 *      analyticsPage: // value for 'analyticsPage'
 *   },
 * });
 */
export function useIntakePlanningDataQualityQuery(
  baseOptions: Apollo.QueryHookOptions<IntakePlanningDataQualityQueryResult, IntakePlanningDataQualityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<IntakePlanningDataQualityQueryResult, IntakePlanningDataQualityQueryVariables>(
    IntakePlanningDataQualityDocument,
    options
  );
}
export function useIntakePlanningDataQualityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IntakePlanningDataQualityQueryResult, IntakePlanningDataQualityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<IntakePlanningDataQualityQueryResult, IntakePlanningDataQualityQueryVariables>(
    IntakePlanningDataQualityDocument,
    options
  );
}
export type IntakePlanningDataQualityQueryHookResult = ReturnType<typeof useIntakePlanningDataQualityQuery>;
export type IntakePlanningDataQualityLazyQueryHookResult = ReturnType<typeof useIntakePlanningDataQualityLazyQuery>;
