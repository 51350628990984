import type { FC } from 'react';

import { FormSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';
import { TrackingCarrierType, useGetCarriersQuery } from '@zen/Shipment/graphql';

const CarriersInput: FC = () => {
  const { data } = useGetCarriersQuery();
  const carriers: TrackingCarrierType[] = data?.carriers || [];

  const carrierOptions: Option<string>[] = carriers.map((carrier: TrackingCarrierType): Option<string> => {
    return {
      value: carrier.scacCode,
      label: `${carrier.name} (${carrier.scacCode})`
    };
  });

  return (
    <FormSelect isClearable={true} label="Carrier or SCAC" name="scacCode" options={carrierOptions} placeholder="e.g. MAEU" />
  );
};

export default CarriersInput;
