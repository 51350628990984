import type { FC } from 'react';
import { Route, Switch } from 'react-router';

import AccountDetails from '@zen/Accounts/AccountDetails';
import AuthorisedRoute from '@zen/Components/AuthorisedRoute';
import CustomerAdmin from '@zen/CustomerAdmin';
import ProductSettingsContainer from '@zen/ProductSettings/ProductSettingsContainer';
import {
  consolidationRoutes,
  exchangeRatesRoutes,
  newBookingRoutes,
  taskListRoutes,
  trackedShipmentRoutes,
  workflowsRoutes
} from '@zen/Routes';
import accountsRoutes from '@zen/Routes/accounts';
import { ACCRUAL_ROUTE_PREFIX } from '@zen/Routes/accruals';
import bookingRequestRoutes from '@zen/Routes/bookingRequests';
import customerAdminRoutes from '@zen/Routes/customerAdmin';
import { DOCUMENT_ROUTE_PREFIX } from '@zen/Routes/documents';
import opsDashboardRoutes from '@zen/Routes/opsDashboard';
import opsSettingsRoutes from '@zen/Routes/opsSettings';
import orderRoutes from '@zen/Routes/orders';
import { PACKING_LISTS_ROUTE_PREFIX } from '@zen/Routes/packingLists';
import productSettingsRoutes from '@zen/Routes/productSettings';
import quotesRoutes from '@zen/Routes/quotes';
import rolesRoutes from '@zen/Routes/roles';
import shipmentRoutes from '@zen/Routes/shipments';
import { StageValue } from '@zen/Shipment/types';
import TrackedShipments from '@zen/TrackedShipments';
import loadable from '@zen/utils/component/loadable';
import useAccount from '@zen/utils/hooks/useAccount';

const Accruals = loadable(
  () =>
    import(
      /* webpackChunkName: "accruals" */
      '@zen/Accruals/Accruals'
    )
);

const AccountList = loadable(
  () =>
    import(
      /* webpackChunkName: "all-accounts" */
      '@zen/Accounts/AccountList'
    )
);

const ManageExchangeRates = loadable(
  () =>
    import(
      /* webpackChunkName: "manage-exchange-rates" */
      '@zen/ManageExchangeRates'
    )
);
const APIConsole = loadable(
  () =>
    import(
      /* webpackChunkName: "api-console" */
      '../../../Components/APIConsole'
    )
);
const BookingRequests = loadable(
  () =>
    import(
      /* webpackChunkName: "booking-requests" */
      '@zen/BookingRequests'
    )
);
const Consolidation = loadable(
  () =>
    import(
      /* webpackChunkName: "consolidation" */
      '@zen/Consolidation'
    )
);
const CustomRoles = loadable(
  () =>
    import(
      /* webpackChunkName: "custom-roles" */
      '@zen/CustomRoles'
    )
);
const DashboardContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "dashboard-container" */
      '@zen/Dashboard'
    )
);
const DocumentsIndex = loadable(
  () =>
    import(
      /* webpackChunkName: "documents-index" */
      '@zen/Shipments/Documents/DocumentIndex'
    )
);
const DocumentParser = loadable(
  () =>
    import(
      /* webpackChunkName: "document-parser" */
      '@zen/Orders/DocumentParser'
    )
);
const EditCargo = loadable(
  () =>
    import(
      /* webpackChunkName: "edit-cargo" */
      '@zen/Cargo/EditCargo'
    )
);
const EmbeddedDashboardsContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "embedded-dashboards-container" */
      '@zen/EmbeddedDashboards/Display'
    )
);
const Finance = loadable(
  () =>
    import(
      /* webpackChunkName: "finance" */
      '@zen/Finance'
    )
);
const IntakePlanningContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "intake-planning-container" */
      '@zen/IntakePlanning'
    )
);
const ManageEmbeddedDashboards = loadable(
  () =>
    import(
      /* webpackChunkName: "manage-embedded-dashboards" */
      '@zen/EmbeddedDashboards/Manage'
    )
);
const NetworksContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "networks-container" */
      '@zen/Networks'
    )
);
const NewBookingContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "new-booking-container" */
      '@zen/Booking/NewBooking/NewBookingContainer'
    )
);

const NewTransportSchedule = loadable(
  () =>
    import(
      /* webpackChunkName: "new-transport-schedule" */
      '@zen/Shipment/TransportSchedule/NewTransportSchedule'
    )
);

const OperationsDashboardContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "operations-dashboard-container" */
      '@zen/OperationsDashboard'
    )
);
const OperationsSettingsContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "operations-settings" */
      '@zen/OperationsSettings/OperationsSettingsContainer'
    )
);
const OperationsShipmentsContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "operations-shipments" */
      '@zen/OperationsShipments'
    )
);
const OperationsQuotesContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "operations-quotes" */
      '@zen/OperationsQuotes'
    )
);
const OrdersContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "orders-container" */
      '@zen/Orders'
    )
);
const PackingListsContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "packing-list-container" */
      '@zen/PackingLists'
    )
);
const ProductsContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "products-container" */
      '@zen/Products'
    )
);
const RequestBookingContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "request-booking-container" */
      '@zen/Booking/NewBooking/RequestBookingContainer'
    )
);
const RulesEngineContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "rules-engine-container" */
      '@zen/RulesEngine'
    )
);
const SettingsContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "settings-container" */
      '@zen/Settings'
    )
);
const Shipments = loadable(
  () =>
    import(
      /* webpackChunkName: "shipments" */
      '@zen/Shipments'
    )
);
const ShipmentDetailsPageContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "shipment-details-page-container" */
      '@zen/Shipment/ShipmentDetailsPageContainer'
    )
);
const TransportScheduleSearchContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "transport-schedule-search-container" */
      '@zen/Shipment/TransportSchedule/TransportScheduleSearchContainer'
    )
);

const QuotesIndex = loadable(
  () =>
    import(
      /* webpackChunkName: "quotes-index" */
      '@zen/Quotes/QuotesIndex'
    )
);
const Utilisation = loadable(
  () =>
    import(
      /* webpackChunkName: "utilisation" */
      '@zen/Utilisation'
    )
);
const TaskListContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "task-list" */
      '@zen/TaskList/TaskListContainer'
    )
);

const WorkflowsContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "workflows" */
      '@zen/Workflows'
    )
);

const ZencargoNetwork = loadable(
  () =>
    import(
      /* webpackChunkName: "networks-zencargo-network" */
      '@zen/Networks/ZencargoNetwork'
    )
);

const DashboardRoutes: FC = () => {
  const { accountUuid } = useAccount();

  return (
    <div className="h-full" data-testid="dashboard-routes">
      <Switch>
        <AuthorisedRoute path={quotesRoutes.quoteDetailsPage.path}>
          <ShipmentDetailsPageContainer activityFeedViewType={StageValue.QUOTE_REQUESTED} />
        </AuthorisedRoute>
        <AuthorisedRoute path={quotesRoutes.quoteDetails.path}>
          <QuotesIndex />
        </AuthorisedRoute>
        <AuthorisedRoute path={shipmentRoutes.shipmentDetailsPage.path}>
          <ShipmentDetailsPageContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path={shipmentRoutes.shipmentEditCargo.path}>
          <EditCargo />
        </AuthorisedRoute>
        <AuthorisedRoute path={shipmentRoutes.shipmentTransportScheduleCreate.path}>
          <NewTransportSchedule />
        </AuthorisedRoute>
        <AuthorisedRoute path={shipmentRoutes.shipmentTransportScheduleUpdate.path}>
          <TransportScheduleSearchContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path={exchangeRatesRoutes.manageExchangeRatesIndex.path}>
          <ManageExchangeRates />
        </AuthorisedRoute>
        <AuthorisedRoute path={shipmentRoutes.shipmentDetails.path}>
          <Shipments />
        </AuthorisedRoute>
        <AuthorisedRoute path={DOCUMENT_ROUTE_PREFIX}>
          <DocumentsIndex />
        </AuthorisedRoute>
        <AuthorisedRoute path={ACCRUAL_ROUTE_PREFIX}>
          <Accruals />
        </AuthorisedRoute>
        <AuthorisedRoute path={customerAdminRoutes.index.path}>
          <CustomerAdmin />
        </AuthorisedRoute>
        <Route path={PACKING_LISTS_ROUTE_PREFIX}>
          <PackingListsContainer />
        </Route>
        <AuthorisedRoute path={bookingRequestRoutes.manufacturerRequestsDetailsPage.path}>
          <ShipmentDetailsPageContainer activityFeedViewType={StageValue.BOOKING_REQUESTED} />
        </AuthorisedRoute>
        <AuthorisedRoute path={bookingRequestRoutes.readyForApprovalDetailsPage.path}>
          <ShipmentDetailsPageContainer activityFeedViewType={StageValue.BOOKING_REQUESTED} />
        </AuthorisedRoute>
        <AuthorisedRoute path={bookingRequestRoutes.rejectedDetailsPage.path}>
          <ShipmentDetailsPageContainer activityFeedViewType={StageValue.BOOKING_REQUESTED} />
        </AuthorisedRoute>
        <AuthorisedRoute path={bookingRequestRoutes.requestIndex.path}>
          <BookingRequests />
        </AuthorisedRoute>
        <Route path="/dashboard/settings">
          <SettingsContainer />
        </Route>
        <Route path="/dashboard/analytics/manage">
          <ManageEmbeddedDashboards />
        </Route>
        <Route path="/dashboard/analytics">
          <EmbeddedDashboardsContainer />
        </Route>
        <AuthorisedRoute path="/dashboard/networks">
          <NetworksContainer networkId={accountUuid} />
        </AuthorisedRoute>
        <AuthorisedRoute path={orderRoutes.orderDetailsPage.path}>
          <ShipmentDetailsPageContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path={orderRoutes.orderDetails.path}>
          <OrdersContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path="/dashboard/intake-planning">
          <IntakePlanningContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path="/dashboard/products">
          <ProductsContainer />
        </AuthorisedRoute>
        <Route path={newBookingRoutes.newBookingFromZencargoReference.path}>
          <NewBookingContainer />
        </Route>
        <Route path="/dashboard/request-booking">
          <RequestBookingContainer />
        </Route>
        <Route path="/dashboard/api-console">
          <APIConsole accountUuid={accountUuid} />
        </Route>
        <Route path="/dashboard/csv-parser">
          <DocumentParser />
        </Route>
        <Route path="/dashboard/utilisation">
          <Utilisation />
        </Route>
        <AuthorisedRoute path={accountsRoutes.accountsDetails.path}>
          <AccountDetails />
        </AuthorisedRoute>
        <AuthorisedRoute path={accountsRoutes.accountsIndex.path}>
          <AccountList />
        </AuthorisedRoute>
        <AuthorisedRoute path={opsDashboardRoutes.dashboardIndex.path} redirectPath="/dashboard">
          <OperationsDashboardContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path="/dashboard/ops-shipments" redirectPath="/dashboard">
          <OperationsShipmentsContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path="/dashboard/ops-quotes" redirectPath="/dashboard">
          <OperationsQuotesContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path={opsSettingsRoutes.opsSettingsIndex.path}>
          <OperationsSettingsContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path={productSettingsRoutes.productSettingsIndex.path}>
          <ProductSettingsContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path="/dashboard/freight-spend/:id?" redirectPath="/dashboard">
          <Finance />
        </AuthorisedRoute>
        <AuthorisedRoute path="/dashboard/rules-engine" redirectPath="/dashboard">
          <RulesEngineContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path={rolesRoutes.index.path} redirectPath="/dashboard">
          <CustomRoles />
        </AuthorisedRoute>
        <AuthorisedRoute path="/dashboard/zencargo-network" redirectPath="/dashboard">
          <ZencargoNetwork />
        </AuthorisedRoute>
        <AuthorisedRoute path={consolidationRoutes.consolidationShipmentsDetailsPage.path}>
          <ShipmentDetailsPageContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path={consolidationRoutes.consolidationAddShipmentsDetailsPage.path}>
          <ShipmentDetailsPageContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path={consolidationRoutes.consolidationIndex.path}>
          <Consolidation />
        </AuthorisedRoute>
        <AuthorisedRoute path={trackedShipmentRoutes.trackedShipmentIndex.path}>
          <TrackedShipments />
        </AuthorisedRoute>
        <AuthorisedRoute path={taskListRoutes.taskListIndex.path}>
          <TaskListContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path={workflowsRoutes.workflowsIndex.path}>
          <WorkflowsContainer />
        </AuthorisedRoute>
        <AuthorisedRoute path="/dashboard/:id?" redirectPath={orderRoutes.orderList.path}>
          <DashboardContainer />
        </AuthorisedRoute>
      </Switch>
    </div>
  );
};

export default DashboardRoutes;
