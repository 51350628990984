import { FC, ReactNode, useState } from 'react';

import type { CargoModeEnum } from '@zen/Cargo';
import LastUpdated from '@zen/Components/LastUpdated';
import type { LastUpdatedType } from '@zen/Components/LastUpdated/types';
import QueryHandler from '@zen/Components/QueryHandler';
import type { ModeOfTransport } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional, Undefinable } from '@zen/utils/typescript';

import type { TrackingInformation } from './components/InTransitMilestone/types';
import MilestoneCard from './components/MilestoneCard';
import UpdateMilestoneModal from './components/UpdateMilestoneModal';
import type { MilestoneModalType } from './components/UpdateMilestoneModal/types';
import { useRemoveMilestoneDateMutation, useVoyageMilestonesQuery } from './graphql';
import { getLastUpdated } from './helpers';
import SkeletonMilestones from './Loading/SkeletonMilestones';
import type {
  MilestoneUpdateType,
  VoyageMilestoneBooking,
  VoyageMilestoneNameEnum,
  VoyageMilestoneTime,
  VoyageMilestoneTracking,
  VoyageMilestoneType
} from './types';
import VoyageMilestonesProvider from './VoyageMilestonesProvider';

interface Props {
  consolidatedShipmentId: Undefinable<string>;
  isCurrentInVoyageMilestones?: boolean;
  zencargoReference: string;
}

const VoyageMilestones: FC<Props> = ({ consolidatedShipmentId, isCurrentInVoyageMilestones = false, zencargoReference }) => {
  const [updateMilestone, setUpdateMilestone] = useState<MilestoneModalType>();
  const { addError, addSuccess } = useNotification();
  const { data, loading, error, refetch } = useVoyageMilestonesQuery({
    variables: { zencargoReference },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const [removeMilestoneDate] = useRemoveMilestoneDateMutation();

  const handleRemoveDate = async (milestoneName: VoyageMilestoneNameEnum, timeType: VoyageMilestoneTime): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        removeMilestoneDate({
          variables: { input: { milestoneName, timeType, zencargoReference } }
        }),
      onError: addError,
      onSuccess: () => {
        addSuccess('Milestone date has been removed');
        refetch();
      }
    });
  };

  const handleMilestoneUpdate = async (details: MilestoneModalType, updateType: MilestoneUpdateType): Promise<void> => {
    switch (updateType) {
      case 'remove':
        await handleRemoveDate(details.milestoneName, details.timeType);

        return;
      case 'update':
        setUpdateMilestone(details);
    }
  };

  const handleClose = (): void => setUpdateMilestone(undefined);

  return (
    <QueryHandler<VoyageMilestoneType[]>
      data={data?.bookings?.nodes?.[0]?.milestones}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonMilestones count={5} />}
    >
      {(milestones: VoyageMilestoneType[]) => {
        const booking: Optional<VoyageMilestoneBooking> = data?.bookings?.nodes?.[0];
        const transportMode: Optional<ModeOfTransport> = booking?.modeOfTransport;
        const tracking: Optional<VoyageMilestoneTracking> = booking?.cargo?.tracking;
        const cargoMode: Optional<CargoModeEnum> = booking?.cargo?.mode;
        const trackingInformation: TrackingInformation = {
          shippingOrderState: tracking?.shippingOrderState,
          shippingOrderReleasedOn: tracking?.shippingOrderReleasedOn,
          shippingOrderConfirmedOn: tracking?.shippingOrderConfirmedOn
        };
        const canUpdateStage: Undefinable<boolean> = booking?.canUpdateStage.value && isCurrentInVoyageMilestones;
        const lastUpdated: Undefinable<LastUpdatedType> = getLastUpdated(milestones);

        return (
          <VoyageMilestonesProvider milestones={milestones}>
            <div data-testid="voyage-milestones">
              {milestones.map((milestone: VoyageMilestoneType, index: number): ReactNode => {
                const isFinalMilestone: boolean = milestones.length - 1 === index;

                return (
                  <MilestoneCard
                    key={milestone.id}
                    canEdit={canUpdateStage}
                    cargoMode={cargoMode}
                    consolidatedShipmentId={consolidatedShipmentId}
                    isFinalMilestone={isFinalMilestone}
                    milestone={milestone}
                    onEdit={handleMilestoneUpdate}
                    onMilestoneDelayUpdate={refetch}
                    trackingInformation={trackingInformation}
                    transportMode={transportMode}
                  />
                );
              })}
            </div>
            {lastUpdated && (
              <div className="flex justify-end mt-4">
                <LastUpdated lastUpdate={lastUpdated} />
              </div>
            )}

            {updateMilestone && (
              <UpdateMilestoneModal milestone={updateMilestone} onClose={handleClose} zencargoReference={zencargoReference} />
            )}
          </VoyageMilestonesProvider>
        );
      }}
    </QueryHandler>
  );
};

export default VoyageMilestones;
