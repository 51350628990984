import Bowser from 'bowser';
import { FC, useEffect, useState } from 'react';

import { Headline, LegacyModal } from '@zen/DesignSystem';

import { ReactComponent as BrowserImage } from './browserImage.svg';

const OutdatedBrowser: FC = () => {
  const [isValidBrowser, setIsValidBrowser] = useState<boolean>(true);
  const [closed, setClosed] = useState<boolean>(false);

  useEffect(() => {
    checkBrowser();
  }, []);

  const checkBrowser = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValid = browser.satisfies({
      // declare browsers per OS
      windows: {
        'internet explorer': '>11'
      },
      macos: {
        safari: '>10.1'
      },

      // per platform (mobile, desktop or tablet)
      mobile: {
        safari: '>9',
        'android browser': '>3.10'
      },

      // or in general
      chrome: '>20.1.1432',
      chromium: '>20.1.1432',
      edge: '>20',
      electron: '>11',
      firefox: '>31',
      opera: '>22'
    });

    setIsValidBrowser(!!isValid);
  };

  if (isValidBrowser || closed) {
    return null;
  }

  return (
    <LegacyModal
      isOpen={true}
      onClose={() => {
        setClosed(true);
      }}
    >
      <div className="flex flex-col py-10 px-24 text-center w-160 leading-7">
        <div className="my-8 text-center">
          <BrowserImage className="mx-auto" />
        </div>
        <div className="mb-12">
          <Headline className="my-5" level={2}>
            Please upgrade your browser
          </Headline>
          <p>
            Your web browser is outdated, some features will not work. We strongly recommend you to upgrade or change browser.
          </p>
        </div>
      </div>
    </LegacyModal>
  );
};

export default OutdatedBrowser;
