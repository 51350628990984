import type { CargoModeEnum, NewCargo } from '@zen/Cargo';
import type { ModeOfTransport, StageValue, Tracking } from '@zen/graphql/types.generated';

export type {
  BookingSpecialInstructions,
  CargoContainerType,
  Carriage,
  Carrier,
  SchedulesSailingLegType,
  SchedulesSailingType,
  Tracking,
  TrackingContainerInput,
  TrackingInput,
  UpdateTrackingInput,
  UpdateTrackingPayload
} from '@zen/graphql/types.generated';
export {
  IncotermsValue,
  IssueAssociatedObject,
  ModeOfTransport,
  RoadTrackingStatus,
  RoadTrackingStatusReason,
  Shipment as ShipmentTypeEnum,
  ShippingOrderState,
  StageValue,
  TrackingMode,
  TrackingSubscriptionStatus,
  TradeRole
} from '@zen/graphql/types.generated';
export type { NetworksAssignableInterface } from '@zen/Networks/types';

export enum TabsType {
  actionItems = 'action-items',
  activity = 'activity',
  cargo = 'cargo',
  documents = 'documents',
  notes = 'notes',
  quoteOptions = 'quote-options',
  routeProgress = 'route-progress',
  tradeParties = 'trade-parties'
}
export type ShipmentDetailsViewType = StageValue.BOOKING_REQUESTED | StageValue.QUOTE_REQUESTED;

export interface TrackingData {
  cargoMode: CargoModeEnum;
  containers: NewCargo[];
  modeOfTransport: ModeOfTransport;
  tracking: Tracking;
}
