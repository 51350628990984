import './styles.css';

import { uniqueId } from 'lodash';
import RCTable from 'rc-table';
import type { DefaultRecordType, ExpandableConfig } from 'rc-table/lib/interface';
import { ReactElement, ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ChevronIcon from '@zen/Components/Icons/ChevronIcon';
import { HEADER_HEIGHT_IN_REM } from '@zen/Layout';
import type { SortInput } from '@zen/types';
import type { Undefinable } from '@zen/utils/typescript';

import {
  areColumnKeysUnique,
  buildColumnMap,
  calculateDropMarkerOffset,
  ColumnWidthMap,
  mergeColumnWidths,
  reorderColumns
} from './helpers';
import { useColumnOrder } from './hooks/useColumnOrder';
import { useColumnWidths } from './hooks/useColumnWidths';
import { useTableScroll } from './hooks/useTableScroll';
import TableCell, { Props as CellProps } from './TableCell';
import TableColumnActionIndicator from './TableColumnActionIndicator';
import TableHeaderCell, { Props as TableHeaderProps } from './TableHeaderCell';
import TableRow, { TableRowProperties } from './TableRow';
import TableTotal from './TableTotal';
import type { TableColumn, TotalCountConfig } from './types';

// intentionally private as it is specific to RCTable and should not be exposed outside component
interface RCTableColumn<T> extends Omit<TableColumn<T>, 'dataKey' | 'sortable' | 'sortKey'> {
  dataIndex: string;
}

const customComponents = {
  header: {
    cell: TableHeaderCell
  },
  body: {
    cell: TableCell,
    row: TableRow
  }
};

interface Props<T extends {}> {
  columns: TableColumn<T>[];
  data: T[];
  emptyText?: string;
  expandableConfig?: ExpandableConfig<T> & { rowKeyDataId: string };
  hiddenColumns?: string[];
  loading?: boolean;
  onOrderChange?: (order: SortInput) => void;
  order?: SortInput;
  renderRowActions?: (record: T) => ReactNode;
  stickyHeaderOffset?: number;
  tableId: string;
  totalCountConfig?: TotalCountConfig;
}

const Table = <T extends {}>(props: Props<T>): ReactElement => {
  const {
    columns,
    data,
    emptyText = 'No data',
    expandableConfig = undefined,
    stickyHeaderOffset = HEADER_HEIGHT_IN_REM,
    hiddenColumns = [],
    onOrderChange,
    order,
    tableId,
    totalCountConfig,
    renderRowActions,
    loading = false
  } = props;

  // safeguard for bad column configuration where keys are not unique
  if (!areColumnKeysUnique(columns)) {
    throw new Error(`Each column key for table ${tableId} must be unique`);
  }
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [isMarkerVisible, setMarkerVisible] = useState<boolean>(false);
  const [markerLeftOffset, setMarkerLeftOffset] = useState<number>(-1);
  const [pageScroll, setPageScroll] = useTableScroll(tableId);
  const [savedColumnWidths, setSavedColumnWidths] = useColumnWidths(tableId);
  const [columnOrder, setColumnOrder] = useColumnOrder(
    tableId,
    columns.map(({ fixed, key }) => ({ fixed, key }))
  );
  const [widths, setWidths] = useState<ColumnWidthMap>(mergeColumnWidths(columns, savedColumnWidths));
  const columnMapping: Record<TableColumn['key'], TableColumn<T>> = useMemo(() => buildColumnMap<T>(columns), [columns]);
  const orderedColumns: TableColumn<T>[] = columnOrder.map((key: string) => columnMapping[key]);
  const visibleColumns: TableColumn<T>[] = orderedColumns.filter(({ key }) => !hiddenColumns.includes(key));

  const handleColumnDraggedOver = ({
    targetColumnKey,
    draggedColumnKey,
    targetCellElement
  }: {
    draggedColumnKey: string;
    targetCellElement: HTMLTableCellElement | undefined;
    targetColumnKey: string;
  }): void => {
    if (targetColumnKey === draggedColumnKey) {
      setMarkerVisible(false);

      return;
    }

    const markerOffset: number = calculateDropMarkerOffset({
      columnOrder,
      targetColumnKey,
      draggedColumnKey,
      targetCellElement
    });

    setMarkerLeftOffset(markerOffset);
    setMarkerVisible(true);
  };

  const handleColumnDragEnd = (): void => {
    setMarkerVisible(false);
  };

  const handleColumnDropped = ({
    targetColumnKey,
    draggedColumnKey
  }: {
    draggedColumnKey: string;
    targetColumnKey: string;
  }): void => {
    setMarkerVisible(false);
    if (targetColumnKey === draggedColumnKey) {
      return;
    }
    const newColumnOrder: string[] = reorderColumns({
      columnOrder,
      targetColumnKey,
      draggedColumnKey
    });

    setColumnOrder(newColumnOrder);
  };

  const handleResizeEnd = (key: string, width: number): void => {
    const newWidths: ColumnWidthMap = {
      ...widths,
      [key]: width
    };

    setWidths(newWidths);
    setSavedColumnWidths(newWidths);
  };
  const handleOrderChange = useCallback(
    (newOrder: SortInput) => {
      const wrap = tableWrapperRef.current?.querySelector('.zen-table-content');
      const tableScrollPosition = Number(wrap?.scrollLeft) || 0;

      setPageScroll(tableScrollPosition);
      if (onOrderChange && newOrder) {
        setTimeout(() => onOrderChange(newOrder), 0);
      }
    },
    [setPageScroll, onOrderChange, tableWrapperRef]
  );
  const enhancedColumns: RCTableColumn<T>[] = visibleColumns.map((column: TableColumn<T>, index: number) => {
    const { key, dataKey, ellipsis, onEdit, sortKey, sortable = true, resizable = true, ...columnRest } = column;
    const dataIndex: string = dataKey || key;
    const sortByKey: string = sortKey || key;

    return {
      ...columnRest,
      width: widths[key],
      key,
      dataIndex,
      resizable,
      ellipsis: resizable || ellipsis,
      onCell: (record: T): Partial<CellProps> => ({
        columnConfiguration: {
          ...column,
          onEdit: onEdit ? () => onEdit(key, record) : undefined,
          width: widths[key]
        }
      }),
      onHeaderCell: ({ width, fixed }: RCTableColumn<T>): Partial<TableHeaderProps> => ({
        columnKey: key,
        draggable: !fixed,
        fixed: !!fixed,
        headerOffset: stickyHeaderOffset,
        offsetElementRef: tableWrapperRef,
        onColumnDropped: handleColumnDropped,
        onColumnDraggedOver: handleColumnDraggedOver,
        onColumnDragEnd: handleColumnDragEnd,
        onOrderChange: handleOrderChange,
        onResizeEnd: (resizedWidth: number) => handleResizeEnd(key, resizedWidth),
        order,
        resizable: index !== visibleColumns.length - 1 ? resizable : false,
        sortable,
        sortKey: sortByKey,
        tableId,
        width,
        loading
      })
    };
  });

  const onRow = (record: T): Partial<TableRowProperties> => {
    if (renderRowActions) {
      return {
        renderRowActions: () => renderRowActions(record)
      };
    }

    return {};
  };

  const renderExpandIcon = (parentRecordProps: DefaultRecordType): ReactNode => {
    const { record } = parentRecordProps;
    const expandableColumnName: Undefinable<string> = expandableConfig?.childrenColumnName;

    const hasExpandedContent: boolean = record[expandableColumnName || 'children']?.length > 0;

    return (
      <div className="w-6">
        {hasExpandedContent ? (
          <ChevronIcon
            className="cursor-pointer text-lg block h-full text-navy-base"
            collapsedIconPosition="right"
            expanded={parentRecordProps.expanded}
            expandedIconPosition="down"
            onClick={() => parentRecordProps.onExpand(record)}
            size="small"
          />
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    if (!loading) {
      const heads = tableWrapperRef?.current?.querySelectorAll('th');

      if (heads) {
        let newWidth = {};

        visibleColumns.forEach((item, i) => {
          const width = heads[i].offsetWidth;

          newWidth = { ...newWidth, [item.key]: width };
        });

        setSavedColumnWidths(newWidth);
      }
    }
  }, []);
  useLayoutEffect(() => {
    if (tableWrapperRef) {
      const wrap = tableWrapperRef.current?.querySelector('.zen-table-content');

      if (pageScroll && wrap) {
        wrap.scrollLeft = pageScroll;
      }
    }
  }, [tableWrapperRef, pageScroll]);

  const getRowKeyId = (record: DefaultRecordType): string => {
    return expandableConfig ? record[expandableConfig.rowKeyDataId] : uniqueId();
  };

  return (
    <div className="mb-2 relative">
      {totalCountConfig && <TableTotal className="mb-2" totalCountConfig={totalCountConfig} />}
      <DndProvider backend={HTML5Backend}>
        <div ref={tableWrapperRef} className="relative" data-testid="table-wrapper">
          <RCTable
            columns={enhancedColumns}
            components={customComponents}
            data={data}
            data-component="table"
            data-testid="table"
            emptyText={<div className="text-center">{emptyText}</div>}
            expandable={{ ...expandableConfig, expandIcon: renderExpandIcon }}
            id="tal"
            onRow={onRow}
            prefixCls="zen-table"
            rowKey={getRowKeyId}
            scroll={{ x: true }}
            sticky={false}
          />
          <TableColumnActionIndicator leftOffset={markerLeftOffset} visible={isMarkerVisible} />
        </div>
      </DndProvider>
    </div>
  );
};

export type { Props as TableProps };

export default Table;
