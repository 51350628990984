import pluralize from 'pluralize';
import { FC, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';

import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import DataExportButton from '@zen/Components/DataExportButton';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Table, TableColumn, useColumnConfiguration } from '@zen/DesignSystem';
import Filters from '@zen/IntakePlanning/Filters';
import { getFilters } from '@zen/IntakePlanning/Filters/helpers';
import { useIntakePlanningExportPeriodsDataMutation, useIntakePlanningPeriodsQuery } from '@zen/IntakePlanning/graphql';
import { getColumnKeys } from '@zen/IntakePlanning/helpers/dataExport';
import IntakeChart from '@zen/IntakePlanning/IntakeChart';
import type { IntakeFilterValues, IntakePlanningPeriod, PeriodsColumnEnum } from '@zen/IntakePlanning/types';
import type { NetworksAssignableInterface } from '@zen/Networks';
import type { Product } from '@zen/Products/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { getColumns } from './tableConfiguration';

interface Props {
  accountUuid: string;
  destinations: NetworksAssignableInterface[];
  filterByUserPreferences: boolean;
  filters: IntakeFilterValues;
  product: Product;
}

const WeeklyIntake: FC<Props> = (props) => {
  const {
    accountUuid,
    destinations,
    filters,
    product: { id, skuCode },
    filterByUserPreferences
  } = props;
  const location = useLocation();
  const columns: TableColumn<IntakePlanningPeriod>[] = useMemo(() => getColumns(location.search), [location.search]);
  const tableId: string = 'productWeeklyIntake';
  const { hiddenColumns, setHiddenColumns } = useColumnConfiguration(tableId);
  const { period } = getFilters(location.search);
  const {
    data,
    loading: isLoading,
    error,
    refetch
  } = useIntakePlanningPeriodsQuery({
    variables: {
      accountUuid,
      productId: id,
      ...filters
    }
  });

  const [exportRows] = useIntakePlanningExportPeriodsDataMutation();

  useEffect(() => {
    refetch();
  }, [location.search, filterByUserPreferences, refetch]);

  const handleExport = async (): Promise<IOkOrErrorResult> => {
    const input = {
      accountUuid,
      exportData: {
        columns: getColumnKeys(columns, hiddenColumns) as PeriodsColumnEnum[],
        dataFilters: {
          productId: id,
          dateRange: filters.dateRange,
          destinationId: filters.destinationId
        }
      }
    };
    const result = await exportRows({
      variables: {
        input
      }
    });

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const errors = (result.errors?.length || result.data?.intakePlanningExportPeriodsData?.errors?.length) > 0;

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'IntakePlanningExportPeriodsDataMutationResul... Remove this comment to see the full error message
    return { ok: result.data, error: errors };
  };

  return (
    <div>
      <Helmet title="Product intake view" />
      <Filters className="px-10" destinations={destinations} />
      <QueryHandler
        data={data?.account?.intakePlanningPeriods}
        error={!!error}
        isLoading={isLoading}
        loadingComponent={<SkeletonTableLoading columns={columns} tableId={tableId} />}
      >
        {(intakePeriods: IntakePlanningPeriod[]) => (
          <>
            <IntakeChart data={intakePeriods} dateRange={filters.dateRange} />
            <div className="px-10">
              <div className="flex justify-between mb-8">
                <div className="text-navy-base font-bold text-2xl">
                  <div>Total intake for SKU: {skuCode} </div>
                  <div className="text-xl mt-3">
                    Displaying {period} {pluralize('week', period)}
                  </div>
                </div>
                <div className="flex">
                  <CustomiseTableButton columns={columns} hiddenColumns={hiddenColumns} onChange={setHiddenColumns} />
                  <DataExportButton className="ml-3" confirmLabel="Return to intake planning" onClickPromise={handleExport} />
                </div>
              </div>
              <Table<IntakePlanningPeriod>
                columns={columns}
                data={intakePeriods}
                hiddenColumns={hiddenColumns}
                tableId={tableId}
              />
            </div>
          </>
        )}
      </QueryHandler>
    </div>
  );
};

export default WeeklyIntake;
