import { isEqual } from 'lodash';
import pDebounce from 'p-debounce';
import React, { ReactNode, useState } from 'react';
import AsyncSelectComponent from 'react-select/async';

import { SEARCH_DEBOUNCE_DELAY } from '@zen/utils/constants';
import type { Nullable } from '@zen/utils/typescript';

import { ClearIndicator, getMenuList, getNoOptionsMessage, OverLabel, ValueContainer } from '../components';
import { asyncSelectStyles, customTheme } from '../select-styles';
import type { CommonProps, SelectStyle } from '../types';

interface Props<Value> extends CommonProps {
  cacheOptions?: boolean;
  defaultOptions?: boolean | Value[];
  formatOptionLabel: (value: Value, inputValue: string) => ReactNode;
  loadOptions: (inputValue: string) => Promise<Value[]>;
  onChange?: (value: Nullable<Value>) => void;
  onInputChange?: (inputValue: string) => void;
  overLabel?: string;
  value?: Nullable<Value>;
}

const AsyncSelect = <ResultType extends {}>(props: Props<ResultType>) => {
  const {
    cacheOptions = true,
    defaultOptions,
    className,
    hasError,
    isClearable,
    isDisabled,
    placeholder,
    name,
    onInputChange,
    loadOptions,
    dropdownFooterRenderer,
    suggestion,
    onChange,
    overLabel,
    value,
    autoFocus,
    inputValue,
    isLoading,
    isSearchable = true,
    onBlur
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onInputValueChange = (input: string): void => {
    setIsMenuOpen(input.length > 0);

    onInputChange?.(input);
  };

  const selectStyle: SelectStyle = overLabel ? 'inline' : 'outlined';

  const handleFocus = (): void => setIsMenuOpen(true);

  const debouncedHandleInputChange = pDebounce(loadOptions, SEARCH_DEBOUNCE_DELAY);

  const formatLabel = (option: ResultType, { inputValue: input }: { inputValue: string }) => {
    return <div data-testid="async-select-option">{props.formatOptionLabel(option, input)}</div>;
  };

  const isOptionSelected = (selectedOption: ResultType, options: readonly ResultType[]) => {
    return !!options.find((option) => isEqual(selectedOption, option));
  };

  return (
    <div className="relative min-w-20">
      <OverLabel htmlFor={name} overLabel={overLabel} />
      <AsyncSelectComponent<ResultType>
        autoFocus={autoFocus}
        cacheOptions={cacheOptions}
        className={className}
        closeMenuOnSelect={true}
        components={{
          ValueContainer,
          MenuList: getMenuList(dropdownFooterRenderer),
          NoOptionsMessage: getNoOptionsMessage(suggestion, true),
          ClearIndicator
        }}
        defaultOptions={defaultOptions}
        formatOptionLabel={formatLabel}
        inputId={name}
        inputValue={inputValue}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={false}
        isOptionSelected={isOptionSelected}
        isSearchable={isSearchable}
        loadOptions={debouncedHandleInputChange}
        menuIsOpen={isMenuOpen}
        menuPlacement="auto"
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={defaultOptions ? handleFocus : undefined}
        onInputChange={onInputValueChange}
        placeholder={placeholder}
        styles={asyncSelectStyles(hasError, selectStyle)}
        theme={customTheme}
        value={value}
      />
    </div>
  );
};

export type { Props as AsyncSelectProps };

export default AsyncSelect;
