import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RemoveCollectionScheduleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.RemoveCollectionScheduleInput;
}>;

export type RemoveCollectionScheduleMutationResult = { __typename?: 'Mutation' } & {
  bookingCollectionAndDeliveryRemoveCollectionSchedule?: GraphQLTypes.Maybe<
    { __typename?: 'RemoveCollectionSchedulePayload' } & Pick<GraphQLTypes.RemoveCollectionSchedulePayload, 'clientMutationId'>
  >;
};

export const RemoveCollectionScheduleDocument = /* #__PURE__ */ gql`
  mutation removeCollectionSchedule($input: RemoveCollectionScheduleInput!) {
    bookingCollectionAndDeliveryRemoveCollectionSchedule(input: $input) {
      clientMutationId
    }
  }
`;

/**
 * __useRemoveCollectionScheduleMutation__
 *
 * To run a mutation, you first call `useRemoveCollectionScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollectionScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollectionScheduleMutation, { data, loading, error }] = useRemoveCollectionScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCollectionScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCollectionScheduleMutationResult, RemoveCollectionScheduleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RemoveCollectionScheduleMutationResult, RemoveCollectionScheduleMutationVariables>(
    RemoveCollectionScheduleDocument,
    options
  );
}
export type RemoveCollectionScheduleMutationHookResult = ReturnType<typeof useRemoveCollectionScheduleMutation>;
