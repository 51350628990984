import { CargoModeEnum, LooseCargoTypeEnum, PalletTypeEnum } from '@zen/Cargo';
import { cargoModeLabelMapping } from '@zen/Cargo/helpers';
import type { Option, ToggleOption } from '@zen/DesignSystem';
import { cargoTypeLabelMapping } from '@zen/types';

export const cargoModeOptions: ToggleOption<CargoModeEnum>[] = [
  { value: CargoModeEnum.FTL, label: cargoModeLabelMapping[CargoModeEnum.FTL] },
  { value: CargoModeEnum.LOOSE, label: cargoModeLabelMapping[CargoModeEnum.LOOSE] }
];

export const extendedLooseCargoTypeOptions: Option<LooseCargoTypeEnum | PalletTypeEnum>[] = [
  { value: LooseCargoTypeEnum.ASSORTED_CARGO, label: cargoTypeLabelMapping[LooseCargoTypeEnum.ASSORTED_CARGO] },
  { value: LooseCargoTypeEnum.BOXES_OR_CRATES, label: cargoTypeLabelMapping[LooseCargoTypeEnum.BOXES_OR_CRATES] },
  { value: PalletTypeEnum.EURO, label: cargoTypeLabelMapping[PalletTypeEnum.EURO] },
  { value: PalletTypeEnum.UK, label: cargoTypeLabelMapping[PalletTypeEnum.UK] },
  { value: PalletTypeEnum.US, label: cargoTypeLabelMapping[PalletTypeEnum.US] },
  { value: PalletTypeEnum.OTHER, label: cargoTypeLabelMapping[PalletTypeEnum.OTHER] }
];
