import type { FC, ReactNode } from 'react';

interface Props {
  subtitle?: ReactNode;
  title: ReactNode;
}

const Header: FC<Props> = ({ title, subtitle }) => {
  return (
    <div className="bg-grey-lightest py-5 px-8" data-testid="modal-header">
      <div className="font-bold text-lg leading-normal" data-testid="modal-title">
        {title}
      </div>
      {subtitle && (
        <div className="leading-normal text-navy-base text-sm" data-testid="modal-subtitle">
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default Header;
