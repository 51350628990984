import type { FC, ReactChild, ReactNode } from 'react';

import { Form, FormButtons, FormInput, FormInstance } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import { ShippingOrderState } from '@zen/Shipment/types';
import { getToday } from '@zen/utils/date';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import CarriersInput from './CarriersInput';
import ContainersInput from './ContainersInput';
import ShippingOrderDateInput from './ShippingOrderDateInput';
import ShippingOrderStatusInput from './ShippingOrderStatusInput';
import type { TrackingDetailsFormPermissions, TrackingFormInitialValues, TrackingFormValues } from './types';

interface Props {
  formPermissions: TrackingDetailsFormPermissions;
  initialValues: TrackingFormInitialValues;
  onCancel: () => void;
  onSubmit: (values: TrackingFormValues) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
}

const TrackingDetailsForm: FC<Props> = (props) => {
  const { initialValues, onSubmit, onCancel, formPermissions, onSuccess } = props;
  const { canSetShippingOrderNotRequired, canToggleShippingOrderConfirmed, canSetShippingOrderReleasedOn } = formPermissions;
  const { containers, shippingOrderState } = initialValues;

  const shippingOrderStateRequired: boolean = shippingOrderState !== ShippingOrderState.NOT_REQUIRED;
  const shouldRenderShippingOrderInput: boolean =
    canToggleShippingOrderConfirmed && (shippingOrderStateRequired || canSetShippingOrderNotRequired);

  const renderFormButtons = ({ isSubmitting }: { isSubmitting: boolean }): ReactChild => (
    <FormButtons containerClassName="mt-2" isSubmitting={isSubmitting}>
      <Button onClick={() => onCancel()} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  // This assures that when you have old shipment without date it won't show inaccurate data
  // but when state changes it will have the inital value as today.
  const onOrderStatusValueChange = (value: ShippingOrderState, form: FormInstance<TrackingFormValues>): void => {
    const { shippingOrderConfirmedOn } = form.values;
    const shouldSetInitialDate: boolean = !shippingOrderConfirmedOn && value === ShippingOrderState.CONFIRMED;

    if (shouldSetInitialDate) {
      form.setFieldValue('shippingOrderConfirmedOn', getToday());
    }
  };

  return (
    <Form
      buttonText="Save tracking"
      formButtons={renderFormButtons}
      formName="TrackingDetailsForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      {(form: FormInstance<TrackingFormValues>): ReactNode => {
        const isShippingOrderConfirmed: boolean = form.values.shippingOrderState === ShippingOrderState.CONFIRMED;

        return (
          <div className="grid grid-cols-2 gap-x-5" data-testid="tracking-details-form">
            <CarriersInput />
            <FormInput label="Master bill of lading" name="billOfLading" />
            <FormInput label="Carrier booking reference" name="carrierBookingReference" />
            {containers.length > 0 && <ContainersInput containers={containers} />}
            {shouldRenderShippingOrderInput && (
              <>
                <ShippingOrderStatusInput
                  canSetNotRequired={canSetShippingOrderNotRequired}
                  name="shippingOrderState"
                  onValueChange={(value: ShippingOrderState) => onOrderStatusValueChange(value, form)}
                />
                {isShippingOrderConfirmed && (
                  <ShippingOrderDateInput
                    canSetShippingOrderReleasedOn={canSetShippingOrderReleasedOn}
                    shippingOrderReleasedMinDate={form.values.shippingOrderConfirmedOn}
                  />
                )}
              </>
            )}
          </div>
        );
      }}
    </Form>
  );
};

export default TrackingDetailsForm;
