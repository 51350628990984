import cx from 'classnames';
import type { ReactChild } from 'react';

import Icon from '../Icon';
import type { ToggleOption } from './types';

interface Props<T> {
  inline?: boolean;
  name?: string;
  onChange: (value: T) => void;
  options: ToggleOption<T>[];
  value: T;
}

function Toggle<T = string>({ inline = true, name, onChange, options, value }: Props<T>) {
  const renderOption = ({ icon, label, value: optionValue }: ToggleOption<T>, i: number): ReactChild => {
    const isSelected: boolean = optionValue === value;
    const firstElement: boolean = i === 0;
    const lastElement: boolean = options.length - 1 === i;

    const classNames: string = cx(
      {
        'bg-azure-lightest border-azure-base [&+div]:border-l-azure-base selected': isSelected,
        'cursor-pointer bg-white border-grey-light active:bg-azure-lighter active:text-navy-base hover:border-azure-base':
          !isSelected,
        '-ml-px': !firstElement,
        'rounded-tl rounded-bl': firstElement,
        'rounded-tr rounded-br': lastElement,
        'flex-1': !inline
      },
      'flex items-center justify-center border border-solid px-4 h-10 transition duration-200 ease-in-out text-sm text-navy-base whitespace-nowrap font-bold',
      '[&:hover+div]:border-l-azure-base'
    );

    const handleClick = (): void => onChange(optionValue);

    return (
      <div key={i} className={classNames} data-component="toggle" data-name={name} data-value={optionValue} onClick={handleClick}>
        {icon && <Icon className="mr-2" icon={icon} />} {label}
      </div>
    );
  };

  return <div className="flex">{options.map(renderOption)}</div>;
}

export type { Props as ToggleProps };

export default Toggle;
