import type { FC, ReactNode } from 'react';

import CollapsableElement from '@zen/Components/CollapsableElement';
import ChevronIcon from '@zen/Components/Icons/ChevronIcon';
import { useCollapsibleElement } from '@zen/utils/hooks/useCollapsibleElement';
import { useRouter } from '@zen/utils/hooks/useRouter';

import type { SidebarLink } from '../../types';
import NavChildLink from '../NavChildLink';
import NavItem from '../NavItem';

interface Props {
  item: SidebarLink;
}

const NavDropdown: FC<Props> = ({ item }) => {
  const { children, icon, label, isExpandedByDefault } = item;
  const { isMatchingPathname } = useRouter();
  const [isExpanded, setIsExpanded] = useCollapsibleElement(label, isExpandedByDefault, 'sidebar-nav-config');

  const isAnySubItemActive: boolean = children?.some(({ url }) => isMatchingPathname(url)) || false;
  const isNavItemActive: boolean = isAnySubItemActive && !isExpanded;

  const handleClick = (): void => setIsExpanded(!isExpanded);

  const renderChildLink = ({ url, label: childLabel, openInNewTab }: SidebarLink, index: number): ReactNode => {
    return (
      <div key={index} className="pb-1">
        <NavChildLink isActive={isMatchingPathname(url)} label={childLabel} openInNewTab={openInNewTab} url={url} />
      </div>
    );
  };

  return (
    <>
      <NavItem icon={icon} isActive={isNavItemActive} label={label} onClick={handleClick}>
        <ChevronIcon className="text-lg mr-2" expanded={isExpanded} size="small" />
      </NavItem>
      <CollapsableElement isOpened={isExpanded}>
        <div className="pt-1">{children?.map(renderChildLink)}</div>
      </CollapsableElement>
    </>
  );
};

export type { Props as NavDropdownProps };

export default NavDropdown;
