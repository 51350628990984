import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetUnloCodesQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  query: GraphQLTypes.Scalars['String'];
  terminalType?: GraphQLTypes.InputMaybe<GraphQLTypes.TerminalTypeValue>;
  terminalTypes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.TerminalTypeValue> | GraphQLTypes.TerminalTypeValue>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetUnloCodesQueryResult = { __typename?: 'Query' } & {
  terminals?: GraphQLTypes.Maybe<
    { __typename?: 'TerminalConnection' } & Pick<GraphQLTypes.TerminalConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'Terminal' } & Pick<
                GraphQLTypes.Terminal,
                'unlocode' | 'name' | 'label' | 'terminalTypes' | 'iata' | 'subDiv' | 'location' | 'remarks' | 'functionField'
              >
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const GetUnloCodesDocument = /* #__PURE__ */ gql`
  query getUNLOCodes(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $query: String!
    $terminalType: TerminalTypeValue
    $terminalTypes: [TerminalTypeValue!]
    $order: SortInput
  ) {
    terminals(
      after: $after
      before: $before
      first: $first
      last: $last
      query: $query
      terminalType: $terminalType
      terminalTypes: $terminalTypes
      order: $order
      fuzzySearch: true
    ) {
      nodes {
        unlocode
        name
        label
        terminalTypes
        iata
        subDiv
        location
        remarks
        functionField
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetUnloCodesQuery__
 *
 * To run a query within a React component, call `useGetUnloCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnloCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnloCodesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      query: // value for 'query'
 *      terminalType: // value for 'terminalType'
 *      terminalTypes: // value for 'terminalTypes'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetUnloCodesQuery(baseOptions: Apollo.QueryHookOptions<GetUnloCodesQueryResult, GetUnloCodesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetUnloCodesQueryResult, GetUnloCodesQueryVariables>(GetUnloCodesDocument, options);
}
export function useGetUnloCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnloCodesQueryResult, GetUnloCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetUnloCodesQueryResult, GetUnloCodesQueryVariables>(GetUnloCodesDocument, options);
}
export type GetUnloCodesQueryHookResult = ReturnType<typeof useGetUnloCodesQuery>;
export type GetUnloCodesLazyQueryHookResult = ReturnType<typeof useGetUnloCodesLazyQuery>;
