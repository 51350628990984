import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import {
  CargoConsolidatedItemType,
  CargoItemSubTypeEnum,
  CargoModeEnum,
  CargoSummaryConsolidatedItems,
  CargoWeightType,
  ConsolidatedLooseCargoItem,
  LooseCargoTypeEnum
} from '@zen/Cargo';
import { cargoModeLabelMapping, formatWeight } from '@zen/Cargo/helpers';
import type { CalculatedInfoCargoSummary } from '@zen/Cargo/types';
import { cargoTypeLabelMapping, Money } from '@zen/types';
import { formatCurrency, formatNumber } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

import CargoInfo from '../CargoInfo';
import CargoRiskLevel from '../CargoRiskLevel';

const consolidatedCargoLabelMapping: Record<CargoModeEnum, string> = {
  [CargoModeEnum.FCL]: 'Container type',
  [CargoModeEnum.LOOSE]: 'Quantity',
  [CargoModeEnum.FTL]: 'Vehicle type'
};

export interface Props {
  cargoMode: CargoModeEnum;
  consolidatedItems: CargoSummaryConsolidatedItems;
  summary: CalculatedInfoCargoSummary;
  variant?: 'light' | 'dark';
}

const CargoSummary: FC<Props> = ({ cargoMode, consolidatedItems, summary, variant = 'dark' }) => {
  const { consolidatedLooseCargoItems, consolidatedCargoItems } = consolidatedItems;
  const { highestRiskLevel, totalActualCbm, totalGrossWeight, totalChargeableWeight, totalValueOfGoods } = summary;

  const classNames: string = cx('p-4 mb-6', {
    'bg-white shadow-overlay': variant === 'light',
    'bg-grey-lightest': variant === 'dark'
  });

  const renderCargoDetails = (quantity: Optional<number>, type: CargoItemSubTypeEnum | LooseCargoTypeEnum): ReactNode => {
    const prefix: string = quantity ? `${quantity} x ` : '';

    return (
      <div key={type}>
        {prefix} {cargoTypeLabelMapping[type]}
      </div>
    );
  };

  const renderPieceCount = (): ReactNode => {
    if (!consolidatedLooseCargoItems || consolidatedLooseCargoItems.length === 0) {
      return '-';
    }

    return consolidatedLooseCargoItems.map(renderLooseCargo);
  };

  const renderCargo = ({ quantity, subType }: CargoConsolidatedItemType): ReactNode => renderCargoDetails(quantity, subType);

  const renderLooseCargo = ({ quantity, type }: ConsolidatedLooseCargoItem): ReactNode => renderCargoDetails(quantity, type);

  const renderTotalValueOfGoods = ({ currency, value }: Money): ReactNode => {
    return (
      <div key={currency} className="mr-1">
        {formatCurrency(currency, value, 'symbol', true)}
      </div>
    );
  };

  const renderWeightValues = (weightArray: Optional<CargoWeightType[]>): ReactNode => {
    if (!weightArray || weightArray?.length < 1) {
      return null;
    }

    return weightArray.map(formatWeight).join(', ');
  };

  return (
    <div className={classNames} data-testid="cargo-summary">
      <div className="grid grid-cols-4 gap-4">
        <CargoInfo title="Cargo type">{cargoModeLabelMapping[cargoMode]}</CargoInfo>
        <CargoInfo title={consolidatedCargoLabelMapping[cargoMode]}>
          {consolidatedCargoItems && consolidatedCargoItems.map(renderCargo)}
        </CargoInfo>
      </div>
      <div className="grid grid-cols-4 gap-4 mt-4">
        {cargoMode !== CargoModeEnum.LOOSE && <CargoInfo title="Piece count">{renderPieceCount()}</CargoInfo>}
        <CargoInfo title="Total CBM">{totalActualCbm ? formatNumber(totalActualCbm) : '-'}</CargoInfo>
        {(totalValueOfGoods || highestRiskLevel) && (
          <CargoInfo title="Total value of cargo">
            <div className="flex items-center">
              {totalValueOfGoods?.map(renderTotalValueOfGoods)}
              {highestRiskLevel && <CargoRiskLevel riskLevel={highestRiskLevel} />}
            </div>
          </CargoInfo>
        )}
      </div>
      <div className="grid grid-cols-4 gap-4 mt-4">
        <CargoInfo title="Gross weight">{renderWeightValues(totalGrossWeight)}</CargoInfo>
        <CargoInfo title="Chargeable weight">{renderWeightValues(totalChargeableWeight)}</CargoInfo>
      </div>
    </div>
  );
};

export default CargoSummary;
