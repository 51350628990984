import type { Option } from '@zen/DesignSystem';

import type { ShipmentActionTypeOption } from './types';

export const getShipmentStatusOptions = (shipmentActionTypesList: ShipmentActionTypeOption[]): Option<string>[] => {
  return shipmentActionTypesList.map(
    (shipmentActionType: ShipmentActionTypeOption): Option<string> => ({
      value: shipmentActionType.name,
      label: shipmentActionType.name
    })
  );
};
