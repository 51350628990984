import type { Route, RoutesConfig } from './types';

export const WORKFLOWS_PREFIX = '/dashboard/workflows';

interface WorkflowsRoutes extends RoutesConfig {
  workflowsFlows: Route;
  workflowsIndex: Route;
  workflowsTriggers: Route;
  workflowsTriggersCreate: Route;
}

const workflowsRoutes: WorkflowsRoutes = {
  workflowsIndex: {
    path: WORKFLOWS_PREFIX,
    getUrl: () => WORKFLOWS_PREFIX
  },
  workflowsFlows: {
    path: `${WORKFLOWS_PREFIX}/flows`,
    getUrl: () => `${WORKFLOWS_PREFIX}/flows`
  },
  workflowsTriggers: {
    path: `${WORKFLOWS_PREFIX}/triggers`,
    getUrl: () => `${WORKFLOWS_PREFIX}/triggers`
  },
  workflowsTriggersCreate: {
    path: `${WORKFLOWS_PREFIX}/triggers/create`,
    getUrl: () => `${WORKFLOWS_PREFIX}/triggers/create`
  }
};

export default workflowsRoutes;
