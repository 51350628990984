import type { ReactNode } from 'react';

export interface TableColumn<T = {}> {
  className?: string;
  dataKey?: string;
  ellipsis?: boolean;
  fixed?: 'left' | 'right';
  key: string;
  onEdit?: (key: string, record: T) => void;
  render?: (value: unknown, record: T, index: number) => ReactNode;
  resizable?: boolean;
  sortKey?: string;
  sortable?: boolean;
  title: ReactNode;
  width?: number | string;
}

export type TableColumnOrder = Pick<TableColumn, 'fixed' | 'key'>;

export interface TotalCountConfig {
  entityName?: string;
  isLoading?: boolean;
  totalCount: number;
}

export const TableConfigurationTrackingCategory = 'TableConfiguration';

export enum TableConfigurationTrackingAction {
  CUSTOMISE = 'CustomiseTableColumn',
  REORDER = 'ReorderTableColumn',
  RESIZE = 'ResizeTableColumn',
  SORT = 'SortTableColumn'
}
