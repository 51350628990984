import type { FC } from 'react';

import type { BookingStageEnum } from '@zen/graphql/types.generated';

import { useGetMilestoneDatesQuery } from './graphql';
import { getDateValidation } from './helpers';
import MilestoneDateValidationContext from './MilestoneDateValidationContext';
import type {
  BookingStageDateType,
  DateValidationStageType,
  MilestoneDateType,
  MilestoneDateValidationType,
  VoyageMilestoneNameEnum
} from './types';

interface Props {
  zencargoReference: string;
}

const MilestoneDateValidationProvider: FC<Props> = ({ zencargoReference, children }) => {
  const { data } = useGetMilestoneDatesQuery({ variables: { zencargoReference } });

  const milestoneDates: MilestoneDateType[] = data?.bookings?.nodes?.[0]?.milestones || [];
  const bookingStages: BookingStageDateType[] = data?.bookings?.nodes?.[0]?.bookingStages || [];

  const bookingStagesDateValidations: DateValidationStageType[] = bookingStages.map((bookingStage: BookingStageDateType) => ({
    name: bookingStage.name,
    date: bookingStage.startedAt?.date || undefined
  }));

  const milestoneDateValidations: DateValidationStageType[] = milestoneDates.map((milestoneDate: MilestoneDateType) => ({
    name: milestoneDate.name,
    date: milestoneDate?.actual?.date || milestoneDate?.latestEstimate?.date || undefined
  }));

  const dateValidationStages: DateValidationStageType[] = [...bookingStagesDateValidations, ...milestoneDateValidations];

  const getMilestoneDateValidation = (
    name: VoyageMilestoneNameEnum | BookingStageEnum,
    isActualDateUpdate: boolean
  ): MilestoneDateValidationType => getDateValidation(dateValidationStages, name, isActualDateUpdate);

  return (
    <MilestoneDateValidationContext.Provider value={{ getMilestoneDateValidation }}>
      {children}
    </MilestoneDateValidationContext.Provider>
  );
};

export default MilestoneDateValidationProvider;
