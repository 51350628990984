import cx from 'classnames';
import { FC, useState } from 'react';

import Slideout from '@zen/Components/Slideout';
import { IconButton } from '@zen/DesignSystem';
import { HEADER_HEIGHT_IN_REM } from '@zen/Layout';

import { useUnreadNotificationsCounterUpdatedSubscription } from './graphql';
import NotificationList from './NotificationList';

const Notifications: FC = () => {
  const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState<boolean>(false);

  const { data: subscriptionResult } = useUnreadNotificationsCounterUpdatedSubscription({
    fetchPolicy: 'no-cache'
  });

  const updatedUnreadCount: number = subscriptionResult?.unreadNotificationsCounterUpdated || 0;

  const countClassNames = cx(
    'bg-navy-base text-white p-2 font-bold text-xs',
    'rounded-full flex items-center justify-center',
    'absolute top-1.5 right-1.5 pointer-events-none',
    {
      'w-5 h-5 -mr-2 -mt-3': updatedUnreadCount < 100,
      'w-6 h-6 -mr-3 -mt-4': updatedUnreadCount >= 100
    }
  );

  return (
    <div className="flex h-full">
      <div
        className="flex items-center justify-center"
        data-component="notifications-toggler"
        data-testid="indicator"
        onClick={() => setIsNotificationsMenuOpen(!isNotificationsMenuOpen)}
      >
        <div className="relative">
          <IconButton icon="zicon-bell" title="Notifications" variant="tertiary" />
          {updatedUnreadCount > 0 && (
            <span className={countClassNames} data-testid="unread-count">
              {updatedUnreadCount}
            </span>
          )}
        </div>
      </div>
      <Slideout
        onOutsideClick={() => setIsNotificationsMenuOpen(!isNotificationsMenuOpen)}
        show={isNotificationsMenuOpen}
        unmountOnClose={true}
      >
        <div className="bg-white overflow-y-scroll w-80" style={{ height: `calc(100vh - ${HEADER_HEIGHT_IN_REM}rem)` }}>
          <NotificationList onClose={() => setIsNotificationsMenuOpen(false)} />
        </div>
      </Slideout>
    </div>
  );
};

export default Notifications;
