import { IntakeViewOptions } from '../types';

export const periodOptions = [
  {
    label: '1',
    value: IntakeViewOptions.ONE_WEEK
  },
  {
    label: '2',
    value: IntakeViewOptions.TWO_WEEKS
  },
  {
    label: '4',
    value: IntakeViewOptions.FOUR_WEEKS
  },
  {
    label: '8',
    value: IntakeViewOptions.EIGHT_WEEKS
  },
  {
    label: '12',
    value: IntakeViewOptions.TWELVE_WEEKS
  },
  {
    label: '26',
    value: IntakeViewOptions.TWENTY_SIX_WEEKS
  },
  {
    label: '52',
    value: IntakeViewOptions.FIFTY_TWO_WEEKS
  }
];
