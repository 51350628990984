import type { FC, ReactNode } from 'react';

import { Hint } from '@zen/DesignSystem';

interface Props {
  children: ReactNode;
  hintText: string;
}

const HintWrapper: FC<Props> = ({ children, hintText }) => {
  return (
    <div className="flex items-start w-180">
      <div className="max-w-lg flex-1 mr-3">{children}</div>
      <Hint className="w-48 mt-7" text={hintText} />
    </div>
  );
};

export default HintWrapper;
