import { FC, forwardRef, useState } from 'react';

import { SearchInput } from '@zen/DesignSystem';

interface Props {
  autoFocus?: boolean;
  className?: string;
  helperText?: string;
  initialValue?: string;
  onClear: () => void;
  onSubmit: (searchQuery: string) => void;
  placeholder?: string;
}

const Search: FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { autoFocus, className, initialValue = '', onClear, onSubmit, helperText, placeholder = 'Search...' } = props;
  const [query, setQuery] = useState<string>(initialValue);

  const handleSearch = (): void => {
    onSubmit(query);
  };

  return (
    <SearchInput
      ref={ref}
      autoFocus={autoFocus}
      className={className}
      helperText={helperText}
      onChange={setQuery}
      onClear={onClear}
      onSearch={handleSearch}
      placeholder={placeholder}
      value={query}
    />
  );
});

export default Search;
