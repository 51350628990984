import { useContext, useMemo } from 'react';

import type { UserProfile } from '@zen/Auth/UserDataProvider/types';
import UserContext from '@zen/Auth/UserDataProvider/UserContext';

interface Account {
  accountUuid: string;
  refetchUser: () => void;
  userProfile: UserProfile;
}

const useAccount = (): Account => {
  const { accountUuid, userProfile, refetchUser } = useContext(UserContext);

  return useMemo(() => ({ accountUuid, userProfile, refetchUser }), [accountUuid, userProfile, refetchUser]);
};

export default useAccount;
