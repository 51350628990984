import type { Option } from '@zen/DesignSystem';
import { IssueAssociatedObject } from '@zen/Shipment/types';

import { categoryLabelMapping, severityLabelMapping } from '../issueHelpers';
import { IssueCategory, IssueSeverity, IssueState } from '../types';

export const stateLabelMapping: Record<IssueState.OPEN | IssueState.STARTED, string> = {
  [IssueState.OPEN]: 'Open',
  [IssueState.STARTED]: 'In progress'
};

const issueTypeOptions = [
  { label: categoryLabelMapping[IssueCategory.AGENT], value: IssueCategory.AGENT },
  { label: categoryLabelMapping[IssueCategory.CARRIER], value: IssueCategory.CARRIER },
  { label: categoryLabelMapping[IssueCategory.CUSTOMER], value: IssueCategory.CUSTOMER },
  { label: categoryLabelMapping[IssueCategory.CUSTOMS], value: IssueCategory.CUSTOMS },
  { label: categoryLabelMapping[IssueCategory.HAULIER], value: IssueCategory.HAULIER },
  { label: categoryLabelMapping[IssueCategory.MANUFACTURER], value: IssueCategory.MANUFACTURER },
  { label: categoryLabelMapping[IssueCategory.OTHER], value: IssueCategory.OTHER },
  { label: categoryLabelMapping[IssueCategory.PORT], value: IssueCategory.PORT },
  { label: categoryLabelMapping[IssueCategory.ZENCARGO], value: IssueCategory.ZENCARGO }
];
const ordersIssueTypes = [
  IssueCategory.AGENT,
  IssueCategory.CUSTOMER,
  IssueCategory.MANUFACTURER,
  IssueCategory.OTHER,
  IssueCategory.ZENCARGO
];

export const getIssueTypeOptions = (
  associatedObject: IssueAssociatedObject = IssueAssociatedObject.BOOKING
): Option<IssueCategory>[] => {
  if (associatedObject === IssueAssociatedObject.PURCHASE_ORDER) {
    const findOption = (issueType: IssueCategory) =>
      issueTypeOptions.find((option) => option.value === issueType) as Option<IssueCategory>;

    return ordersIssueTypes.map(findOption);
  }

  return issueTypeOptions;
};

export const severityOptions: Option<IssueSeverity>[] = [
  { label: severityLabelMapping[IssueSeverity.HIGH], value: IssueSeverity.HIGH },
  { label: severityLabelMapping[IssueSeverity.MEDIUM], value: IssueSeverity.MEDIUM },
  { label: severityLabelMapping[IssueSeverity.LOW], value: IssueSeverity.LOW }
];

export const stateOptions: Option<IssueState>[] = [
  { label: stateLabelMapping[IssueState.OPEN], value: IssueState.OPEN },
  { label: stateLabelMapping[IssueState.STARTED], value: IssueState.STARTED }
];
