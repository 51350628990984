import { Icon, Pill } from '@zen/DesignSystem';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

export interface Link {
  label: string;
  url: string;
}

interface IDataQualityIssueProps {
  issues: string[];
  link: Link;
  tagline?: string;
  text: string;
}

const DataQualityIssue = (props: IDataQualityIssueProps) => {
  const {
    link: { url, label },
    issues,
    tagline,
    text
  } = props;
  const { navigate } = useNavigationHistory();

  const renderIssue = (issue: string, i: number) => (
    <Pill key={i} className="mt-3 mr-2" type="problem">
      {issue}
    </Pill>
  );

  const handleClick = (): void => {
    navigate(url);
  };

  return (
    <div className="py-6 border-b border-solid border-grey-base flex justify-between items-center">
      <div style={{ width: '24rem' }}>
        <div className="text-grey-dark font-bold truncate">{text}</div>
        {tagline && <div className="text-grey-base text-sm truncate mt-2">{tagline}</div>}
        <div className="flex">{issues.map(renderIssue)}</div>
      </div>
      <a className="text-navy-base font-bold group" onClick={handleClick}>
        <Icon className="mr-2" icon="zicon-edit" />
        <span className="group-hover:underline">{label}</span>
      </a>
    </div>
  );
};

export default DataQualityIssue;
