import type { Optional } from '@zen/utils/typescript';

import type { CollectionDetails, DeliveryDetails } from '../types';

export type { LocalDateTimeRange } from '@zen/graphql/types.generated';

export enum ScheduleActionType {
  CANCEL = 'cancel',
  COMPLETE = 'complete',
  CONFIRM = 'confirm',
  SCHEDULE = 'schedule',
  UPDATE_ADDRESS = 'updateAddress',
  UPDATE_DETAILS = 'updateDetails'
}

export type ScheduleDetails = CollectionDetails | DeliveryDetails;

export type ScheduleDateLabels = { date: string; time: Optional<string>; timeZone: string };

export type ScheduleItemType = 'collection' | 'delivery';
