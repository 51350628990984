import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CustomerAdminAllShipmentsQueryVariables = GraphQLTypes.Exact<{
  accountIds?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>> | GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>
  >;
  active?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  bookingManagerIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  customsOnly?: GraphQLTypes.InputMaybe<
    | Array<GraphQLTypes.InputMaybe<GraphQLTypes.CustomsOnlyFilterEnum>>
    | GraphQLTypes.InputMaybe<GraphQLTypes.CustomsOnlyFilterEnum>
  >;
  destinations?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  issues?: GraphQLTypes.InputMaybe<GraphQLTypes.IssuesFilterInput>;
  origins?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  showInTransit?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  showSubscribed?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  transportModes?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.InputMaybe<GraphQLTypes.ModeOfTransport>> | GraphQLTypes.InputMaybe<GraphQLTypes.ModeOfTransport>
  >;
  withinTimeRange?: GraphQLTypes.InputMaybe<GraphQLTypes.WithinTimeRangeInput>;
  withSteps?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.BookingStep> | GraphQLTypes.BookingStep>;
}>;

export type CustomerAdminAllShipmentsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'Booking' } & Pick<
              GraphQLTypes.Booking,
              'clientReference' | 'consignorLabel' | 'consigneeLabel' | 'customsOnly' | 'modeOfTransport' | 'zencargoReference'
            > & {
                actionItems?: GraphQLTypes.Maybe<
                  Array<{ __typename?: 'ActionItem' } & Pick<GraphQLTypes.ActionItem, 'id' | 'title' | 'status'>>
                >;
                bookingStage?: GraphQLTypes.Maybe<{ __typename?: 'BookingStage' } & Pick<GraphQLTypes.BookingStage, 'name'>>;
                calculatedInfo: { __typename?: 'CalculatedInfo' } & {
                  cargoSummary?: GraphQLTypes.Maybe<
                    { __typename?: 'CargoSummary' } & Pick<GraphQLTypes.CargoSummary, 'highestRiskLevel' | 'label' | 'type'> & {
                        totalValueOfGoods?: GraphQLTypes.Maybe<
                          Array<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>
                        >;
                      }
                  >;
                  collectionInFull?: GraphQLTypes.Maybe<
                    { __typename?: 'InFull' } & {
                      dateTimeRange?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeRange' } & {
                          endDateTime?: GraphQLTypes.Maybe<
                            { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date'>
                          >;
                        }
                      >;
                    }
                  >;
                  deliveryInFull?: GraphQLTypes.Maybe<
                    { __typename?: 'InFull' } & {
                      dateTimeRange?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeRange' } & {
                          endDateTime?: GraphQLTypes.Maybe<
                            { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date'>
                          >;
                        }
                      >;
                    }
                  >;
                };
                cargo?: GraphQLTypes.Maybe<
                  { __typename?: 'Cargo' } & {
                    tracking?: GraphQLTypes.Maybe<
                      { __typename?: 'Tracking' } & Pick<GraphQLTypes.Tracking, 'shippingOrderState'>
                    >;
                  }
                >;
                carriage?: GraphQLTypes.Maybe<
                  { __typename?: 'Carriage' } & Pick<GraphQLTypes.Carriage, 'vesselName'> & {
                      portOfDestination?: GraphQLTypes.Maybe<
                        { __typename?: 'PortOfDestination' } & Pick<GraphQLTypes.PortOfDestination, 'name'>
                      >;
                      portOfLoad?: GraphQLTypes.Maybe<{ __typename?: 'PortOfLoad' } & Pick<GraphQLTypes.PortOfLoad, 'name'>>;
                    }
                >;
                customer?: GraphQLTypes.Maybe<
                  { __typename?: 'BookingCustomerType' } & Pick<GraphQLTypes.BookingCustomerType, 'uuid'> & {
                      division?: GraphQLTypes.Maybe<{ __typename?: 'Division' } & Pick<GraphQLTypes.Division, 'name'>>;
                    }
                >;
                delay?: GraphQLTypes.Maybe<{ __typename?: 'Delay' } & Pick<GraphQLTypes.Delay, 'days' | 'type' | 'description'>>;
                estimatedArrival?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                >;
                estimatedDeparture?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                >;
                estimatedDelivery?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                >;
                estimatedCollection?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                >;
                incoterms?: GraphQLTypes.Maybe<{ __typename?: 'Incoterms' } & Pick<GraphQLTypes.Incoterms, 'value'>>;
                issues?: GraphQLTypes.Maybe<
                  Array<
                    { __typename?: 'BookingIssue' } & Pick<
                      GraphQLTypes.BookingIssue,
                      'id' | 'severity' | 'category' | 'state' | 'title'
                    >
                  >
                >;
                networksDestination?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                >;
                networksOrigin?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                >;
                purchaseOrderReferences?: GraphQLTypes.Maybe<
                  { __typename?: 'PurchaseOrderReferences' } & Pick<GraphQLTypes.PurchaseOrderReferences, 'totalCount'> & {
                      nodes?: GraphQLTypes.Maybe<
                        Array<
                          { __typename?: 'PurchaseOrderReference' } & Pick<
                            GraphQLTypes.PurchaseOrderReference,
                            'orderReferenceNumber' | 'id'
                          >
                        >
                      >;
                    }
                >;
                stage?: GraphQLTypes.Maybe<
                  { __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'actionItemCount'> & {
                      statusUpdate?: GraphQLTypes.Maybe<
                        { __typename?: 'StatusUpdate' } & Pick<GraphQLTypes.StatusUpdate, 'title'>
                      >;
                    }
                >;
                voyageMilestone?: GraphQLTypes.Maybe<
                  { __typename?: 'VoyageMilestone' } & Pick<GraphQLTypes.VoyageMilestone, 'name'>
                >;
              }
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const CustomerAdminAllShipmentsDocument = /* #__PURE__ */ gql`
  query customerAdminAllShipments(
    $accountIds: [String]
    $active: Boolean
    $after: String
    $before: String
    $bookingManagerIds: [String!]
    $customsOnly: [CustomsOnlyFilterEnum]
    $destinations: [String!]
    $first: Int
    $last: Int
    $issues: IssuesFilterInput
    $origins: [String!]
    $order: SortInput
    $searchQuery: String
    $showInTransit: Boolean
    $showSubscribed: Boolean
    $transportModes: [ModeOfTransport]
    $withinTimeRange: WithinTimeRangeInput
    $withSteps: [BookingStep!]
  ) {
    bookings(
      active: $active
      after: $after
      before: $before
      customers: $accountIds
      customsOnly: $customsOnly
      destinations: $destinations
      first: $first
      last: $last
      issues: $issues
      onlyShipments: true
      operationsManagers: $bookingManagerIds
      origins: $origins
      order: $order
      showInTransit: $showInTransit
      showSubscribed: $showSubscribed
      textContains: $searchQuery
      transportModes: $transportModes
      withinTimeRange: $withinTimeRange
      withSteps: $withSteps
    ) {
      nodes {
        actionItems {
          id
          title
          status
        }
        bookingStage {
          name
        }
        calculatedInfo {
          cargoSummary {
            highestRiskLevel
            label
            totalValueOfGoods {
              currency
              value
            }
            type
          }
          collectionInFull {
            dateTimeRange {
              endDateTime {
                date
              }
            }
          }
          deliveryInFull {
            dateTimeRange {
              endDateTime {
                date
              }
            }
          }
        }
        cargo {
          tracking {
            shippingOrderState
          }
        }
        carriage {
          portOfDestination {
            name
          }
          portOfLoad {
            name
          }
          vesselName
        }
        customer {
          division {
            name
          }
          uuid
        }
        clientReference
        consignorLabel
        consigneeLabel
        customsOnly
        delay {
          days
          type
          description
        }
        estimatedArrival {
          date
          timeZone
        }
        estimatedDeparture {
          date
          timeZone
        }
        estimatedDelivery {
          date
          timeZone
        }
        estimatedCollection {
          date
          timeZone
        }
        incoterms {
          value
        }
        issues {
          id
          severity
          category
          state
          title
        }
        modeOfTransport
        networksDestination {
          id
          label {
            long
            short
          }
        }
        networksOrigin {
          id
          label {
            long
            short
          }
        }
        purchaseOrderReferences(first: 10) {
          nodes {
            orderReferenceNumber
            id
          }
          totalCount
        }
        stage {
          actionItemCount
          statusUpdate {
            title
          }
        }
        voyageMilestone {
          name
        }
        zencargoReference
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useCustomerAdminAllShipmentsQuery__
 *
 * To run a query within a React component, call `useCustomerAdminAllShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAdminAllShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAdminAllShipmentsQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      active: // value for 'active'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      bookingManagerIds: // value for 'bookingManagerIds'
 *      customsOnly: // value for 'customsOnly'
 *      destinations: // value for 'destinations'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      issues: // value for 'issues'
 *      origins: // value for 'origins'
 *      order: // value for 'order'
 *      searchQuery: // value for 'searchQuery'
 *      showInTransit: // value for 'showInTransit'
 *      showSubscribed: // value for 'showSubscribed'
 *      transportModes: // value for 'transportModes'
 *      withinTimeRange: // value for 'withinTimeRange'
 *      withSteps: // value for 'withSteps'
 *   },
 * });
 */
export function useCustomerAdminAllShipmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerAdminAllShipmentsQueryResult, CustomerAdminAllShipmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CustomerAdminAllShipmentsQueryResult, CustomerAdminAllShipmentsQueryVariables>(
    CustomerAdminAllShipmentsDocument,
    options
  );
}
export function useCustomerAdminAllShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerAdminAllShipmentsQueryResult, CustomerAdminAllShipmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CustomerAdminAllShipmentsQueryResult, CustomerAdminAllShipmentsQueryVariables>(
    CustomerAdminAllShipmentsDocument,
    options
  );
}
export type CustomerAdminAllShipmentsQueryHookResult = ReturnType<typeof useCustomerAdminAllShipmentsQuery>;
export type CustomerAdminAllShipmentsLazyQueryHookResult = ReturnType<typeof useCustomerAdminAllShipmentsLazyQuery>;
