import { useContext, useMemo } from 'react';

import type { FeatureFlags } from '@zen/Auth/UserDataProvider/types';
import UserContext from '@zen/Auth/UserDataProvider/UserContext';

const useFeatureFlags = (): FeatureFlags => {
  const { featureFlags } = useContext(UserContext);

  return useMemo(() => featureFlags, [featureFlags]);
};

export default useFeatureFlags;
