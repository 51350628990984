import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type InviteMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.InviteInput;
}>;

export type InviteMutationResult = { __typename?: 'Mutation' } & {
  invitationsInvite?: GraphQLTypes.Maybe<
    { __typename?: 'InvitePayload' } & {
      invitation?: GraphQLTypes.Maybe<
        { __typename?: 'Invitation' } & Pick<GraphQLTypes.Invitation, 'acceptUrl' | 'status'> & {
            canSend: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'message' | 'value'> & {
                reasons?: GraphQLTypes.Maybe<
                  { __typename?: 'FailureReasons' } & Pick<GraphQLTypes.FailureReasons, 'details' | 'fullMessages'>
                >;
              };
          }
      >;
    }
  >;
};

export const InviteDocument = /* #__PURE__ */ gql`
  mutation invite($input: InviteInput!) {
    invitationsInvite(input: $input) {
      invitation {
        acceptUrl
        canSend {
          message
          reasons {
            details
            fullMessages
          }
          value
        }
        status
      }
    }
  }
`;

/**
 * __useInviteMutation__
 *
 * To run a mutation, you first call `useInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMutation, { data, loading, error }] = useInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMutation(baseOptions?: Apollo.MutationHookOptions<InviteMutationResult, InviteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<InviteMutationResult, InviteMutationVariables>(InviteDocument, options);
}
export type InviteMutationHookResult = ReturnType<typeof useInviteMutation>;
