import type { FC, ReactNode } from 'react';

import ActionCard from '@zen/Components/ActionCard';
import type { ActionCardConfigItem } from '@zen/Components/ActionCard/types';
import Page from '@zen/Components/Page';

import { productSettingsConfig } from './productSettingsConfig';

const ProductSettings: FC = () => {
  const productSettingsActionCards: ReactNode = productSettingsConfig.map(
    ({ title, description, link }: ActionCardConfigItem): ReactNode => (
      <ActionCard key={title} description={description} link={link} title={title} />
    )
  );

  return (
    <Page tagline="Settings for Zencargo operations." title="Product settings">
      <div className="grid grid-cols-4 gap-8 mt-4">{productSettingsActionCards}</div>
    </Page>
  );
};

export default ProductSettings;
