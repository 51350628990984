import { FC, ReactChild, ReactNode, useState } from 'react';

import type { ContainerNewCargoType, NewCargo } from '@zen/Cargo/types';
import FormField from '@zen/Components/Form/FormField';
import FormLabel from '@zen/Components/Form/FormLabel';
import { TextInput } from '@zen/DesignSystem';
import { newCargoContainerTypeLabelMapping } from '@zen/types';

interface Props {
  containers: NewCargo[];
}

const renderContainerFields = (containers: NewCargo[]): ReactNode => {
  return containers.map((container, index) => (
    <FormField key={container.id} hideLabel={true} name={`containers[${index}].containerNumber`}>
      {(field: { name: string; value: string }) => (
        <div className="flex -mb-2" data-testid="container-field">
          <div className="flex self-stretch items-center w-20 h-10 px-2 border-solid border-grey-light border rounded-l">
            {container.cargoType && (
              <span className="text-sm whitespace-nowrap">
                {newCargoContainerTypeLabelMapping[container.cargoType as ContainerNewCargoType].short}
              </span>
            )}
          </div>
          <div className="w-full">
            <TextInput {...field} hasPrefix={true} id={field.name} />
          </div>
        </div>
      )}
    </FormField>
  ));
};

const ContainersInput: FC<Props> = ({ containers = [] }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const defaultContainerCount: number = 4;
  const visibleContainerCount: number = expanded ? containers.length : defaultContainerCount;
  const visibleContainers: NewCargo[] = containers.slice(0, visibleContainerCount);
  const hasRemainingContainers: boolean = containers.length > defaultContainerCount;

  const label: ReactChild = (
    <span>
      Container numbers - <span className="font-normal text-xs">All container numbers are required.</span>
    </span>
  );

  return (
    <div>
      <FormLabel label={label} />
      {renderContainerFields(visibleContainers)}
      {hasRemainingContainers && (
        <div
          className="text-navy-base text-sm font-bold cursor-pointer"
          data-testid="toggle-all-containers"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'Hide containers' : `View all ${containers.length} containers`}
        </div>
      )}
    </div>
  );
};

export default ContainersInput;
