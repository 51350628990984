import './flags.css';

import { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';

import CountrySelect from '@zen/Components/CountrySelect';
import type { Country } from '@zen/Components/CountrySelect/types';
import HelperText from '@zen/Components/HelperText';
import type { Option } from '@zen/DesignSystem';
import { Input } from '@zen/DesignSystem';
import { CountryCode } from '@zen/Networks';

import { getCountryCallingCode, getPhoneNumber, isCountrySupported, parsePhoneNumber } from './helpers';

interface Props {
  defaultCountry?: CountryCode;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  name?: string;
  onChange: (phoneNumber: string) => void;
  value?: string;
}

const PhoneNumberInput: FC<Props> = ({
  disabled = false,
  defaultCountry = CountryCode.GB,
  error,
  onChange,
  value,
  helperText,
  name = 'phone-number-input'
}) => {
  const [country, setCountry] = useState<CountryCode>(defaultCountry);
  const [input, setInput] = useState<string>('');

  useEffect(() => {
    if (value) {
      const { countryCode, nationalNumber } = parsePhoneNumber(value);

      setCountry(countryCode || defaultCountry);
      setInput(nationalNumber);
    }
    // disabled rule as we only need this once when the component initialised.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCountriesOptions = (countries: Country[]): Option<CountryCode>[] => {
    const supportedCountries: Country[] = countries.filter((countryItem: Country) => isCountrySupported(countryItem.code));

    return supportedCountries.map((supportedCountry: Country) => ({
      label: supportedCountry.name,
      value: supportedCountry.code
    }));
  };

  const getLabel = (option: Option<CountryCode>): ReactNode => {
    const flag = option.value ? <span className={`flag flag-${option.value.toLowerCase()} mr-2`} /> : '';
    const countryCode = getCountryCallingCode(option.value);

    return (
      <div className="flex items-center">
        {flag}
        <span>{`+ ${countryCode}`}</span>
      </div>
    );
  };

  const handleChange = (inputValue: string, countryCode: CountryCode = country): void => {
    const inputText: string = inputValue.trim();

    if (!inputText) {
      onChange('');
    } else {
      const { nationalNumber } = parsePhoneNumber(inputValue, countryCode);
      const phoneNumber: string = getPhoneNumber(countryCode, nationalNumber);

      onChange(phoneNumber);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { countryCode } = parsePhoneNumber(event.target.value);

    if (countryCode) {
      setCountry(countryCode);
    }

    setInput(event.target.value);
    handleChange(event.target.value);
  };

  const handleInputBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    const { nationalNumber } = parsePhoneNumber(event.target.value, country);

    setInput(nationalNumber);
    handleChange(event.target.value);
  };

  const handleSelectChange = (code: CountryCode): void => {
    setCountry(code);
    handleChange(input, code);
  };

  return (
    <>
      <div className="flex w-full">
        <CountrySelect
          className="w-32"
          disabled={disabled}
          formatLabel={getLabel}
          generateCountriesOptions={generateCountriesOptions}
          hasError={error}
          name="phone-number-country-select"
          onChange={handleSelectChange}
          value={country}
        />
        <div className="w-full">
          <Input
            className="ml-7"
            disabled={disabled}
            error={error}
            name={name}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            type="tel"
            value={input}
          />
        </div>
      </div>
      <HelperText helperText={helperText} />
    </>
  );
};

export type { Props as PhoneNumberInputProps };

export default PhoneNumberInput;
