import { sortBy, xor } from 'lodash';
import { ReactNode, Ref, useRef, useState } from 'react';

import type { TableColumn } from '@zen/DesignSystem';
import { Button, Checkbox } from '@zen/DesignSystem';
import useClickAway from '@zen/utils/hooks/useClickAway';

interface Props<T> {
  columns: TableColumn<T>[];
  defaultFilterValue?: string;
  hiddenColumns: string[];
  onChange: (hiddenColumns: string[]) => void;
}

type MultiSelectOptions<T> = {
  isSelected: boolean;
  value: string;
} & TableColumn<T>;

const CustomiseTableButton = <T extends {}>({ columns, defaultFilterValue = '', hiddenColumns, onChange }: Props<T>) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const ref: Ref<HTMLDivElement> = useRef(null);
  const generalFilteredColumn: string = 'actions';
  const columnsToSkipListing: string = defaultFilterValue || generalFilteredColumn;

  const filteredColumns: TableColumn<T>[] = columns.filter((item) => item.key !== columnsToSkipListing);
  const sortedColumns: TableColumn<T>[] = sortBy(filteredColumns, ['title']);

  const options: MultiSelectOptions<T>[] = sortedColumns.map(
    (col: TableColumn<T>): MultiSelectOptions<T> => ({
      ...col,
      value: col.key,
      isSelected: !hiddenColumns.includes(col.key)
    })
  );

  useClickAway(ref, () => setIsOpened(false));

  const handleChange = (key: string): void => {
    const hiddenCols = xor(hiddenColumns, [key]);

    onChange(hiddenCols);
  };

  const handleSelectAll = (): void => (!hiddenColumns.length ? onChange(filteredColumns.map((col) => col.key)) : onChange([]));

  const handleToggle = (): void => setIsOpened(!isOpened);

  return (
    <div className="relative fs-customiseTableButton" data-testid="customise-btn">
      <Button iconLeft="zicon-edit-table" onClick={handleToggle} variant="tertiary">
        Customise table
      </Button>
      {isOpened && (
        <div
          ref={ref}
          className="absolute right-0 z-20 w-64 pb-2 overflow-y-auto bg-white rounded top-12 max-h-56 shadow-overlay"
          data-testid="menu"
        >
          <Checkbox
            checked={!hiddenColumns.length}
            className="py-2.5 pr-2 pl-4 w-full mt-2 mb-2 hover:bg-grey-lightest text-sm cursor-pointer"
            label="Select all"
            onChange={handleSelectAll}
          />
          <div className="mb-2 border-b border-solid border-grey-lighter" />
          {options.map(
            ({ isSelected, title, value }, i: number): ReactNode => (
              <Checkbox
                key={i}
                checked={isSelected}
                className="py-2.5 pr-2 pl-4 w-full mt-0 mb-0 hover:bg-grey-lightest text-sm cursor-pointer"
                label={title}
                onChange={() => handleChange(value)}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export type { Props as CustomiseTableButtonProps };
export default CustomiseTableButton;
