import type { NetworksAssignable } from '@zen/Networks';
import useAccount from '@zen/utils/hooks/useAccount';

import { useUsedAssignablesQuery } from '../graphql';
import type { AssignmentTargetTypeEnum, AssignmentTypeValue } from '../types';

interface useUsedAssignablesResult {
  data: NetworksAssignable[];
}

const useUsedAssignables = (
  domainNames: AssignmentTargetTypeEnum[],
  assignmentTypes: AssignmentTypeValue[]
): useUsedAssignablesResult => {
  const { accountUuid: accountId } = useAccount();
  const { data } = useUsedAssignablesQuery({
    variables: {
      networkIds: accountId ? [accountId] : undefined,
      targetTypes: domainNames,
      assignmentNames: assignmentTypes
    },
    fetchPolicy: 'no-cache'
  });

  const assignables: NetworksAssignable[] = data?.networksUsedAssignables as NetworksAssignable[];

  return {
    data: assignables
  };
};

export default useUsedAssignables;
