import type { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useAsync, useLocalStorage } from 'react-use';

import useAuth from '@zen/Auth/hooks/useAuth';
import { Loading } from '@zen/DesignSystem';
import api from '@zen/utils/api';

import AccountIdContext from './AccountIdContext';

const AccountIdProvider: FC = ({ children }) => {
  const ACCOUNT_UUID_KEY = 'current_account_uuid';
  const [accountId, setAccountId, removeAccountId] = useLocalStorage<string>(ACCOUNT_UUID_KEY, '');
  const { logout } = useAuth();
  const { loading, error } = useAsync(async () => initialiseAccountId(), []);

  const fetchAccountId = async (): Promise<string> => {
    return api.getAccounts();
  };

  const initialiseAccountId = async () => {
    if (accountId) {
      return;
    }

    const id: string = await fetchAccountId();

    setAccountId(id);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    logout();

    return <Redirect to="/login" />;
  }

  return (
    <AccountIdContext.Provider value={{ accountId: accountId || '', setAccountId, removeAccountId, fetchAccountId }}>
      {children}
    </AccountIdContext.Provider>
  );
};

export default AccountIdProvider;
