import type { FC } from 'react';

import { Toggle, ToggleOption } from '@zen/DesignSystem';

import { ViewMode } from './types';

export const viewOptions: ToggleOption<ViewMode>[] = [
  { value: ViewMode.LIST, label: 'List' },
  { value: ViewMode.TABLE, label: 'Table' }
];

interface Props {
  onChange: (value: string) => void;
  selectedView: string;
}

const ViewSwitcher: FC<Props> = ({ onChange, selectedView }) => {
  return <Toggle onChange={onChange} options={viewOptions} value={selectedView} />;
};

export default ViewSwitcher;
