import type { Option } from '@zen/DesignSystem';
import { ProfileLevelEnum, ProfileRoleEnum } from '@zen/Settings/types';

const roleLabelMapping: Record<ProfileRoleEnum, string> = {
  [ProfileRoleEnum.ANALYST]: 'Analyst',
  [ProfileRoleEnum.BUYER]: 'Buyer',
  [ProfileRoleEnum.DEVELOPER]: 'Developer',
  [ProfileRoleEnum.FINANCE]: 'Finance',
  [ProfileRoleEnum.FORWARDER]: 'Forwarder',
  [ProfileRoleEnum.LOGISTICS]: 'Logistics',
  [ProfileRoleEnum.MANUFACTURING]: 'Manufacturing',
  [ProfileRoleEnum.MERCHANDISING]: 'Merchandising',
  [ProfileRoleEnum.PROCUREMENT]: 'Procurement',
  [ProfileRoleEnum.SALES]: 'Sales',
  [ProfileRoleEnum.WAREHOUSING]: 'Warehousing'
};

const levelLabelMapping: Record<ProfileLevelEnum, string> = {
  [ProfileLevelEnum.CSUITE]: 'C-Suite',
  [ProfileLevelEnum.DIRECTOR_VP]: 'Director/VP',
  [ProfileLevelEnum.HEAD_OF_DEPARTMENT]: 'Head of department',
  [ProfileLevelEnum.MANAGER]: 'Manager',
  [ProfileLevelEnum.PRACTITIONER]: 'Practitioner'
};

export const prepareRoleOptions = (): Option<ProfileRoleEnum>[] => {
  return Object.values(ProfileRoleEnum).map((value) => ({
    value,
    label: roleLabelMapping[value]
  }));
};

export const prepareLevelOptions = (): Option<ProfileLevelEnum>[] => {
  return Object.values(ProfileLevelEnum).map((value) => ({
    value,
    label: levelLabelMapping[value]
  }));
};
