import type { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { orderRoutes } from '@zen/Routes';
import { FiltersContextProvider } from '@zen/utils/Filtering/contexts/FiltersContext';

import { initialFilters } from './components/Filters/helpers';
import EditOrder from './EditOrder';
import LotAssignmentsContainer from './LotAssignments/LotAssignmentsContainer';
import NewOrder from './NewOrder';
import OrderListContainer from './OrderList/OrderListContainer';
import type { OrderFilters } from './types';

const OrdersContainer: FC = () => {
  const { lotAssignments, orderNew, orderEdit, orderDetails } = orderRoutes;

  return (
    <FiltersContextProvider<OrderFilters> filterName="orderListFilters" initialFilters={initialFilters}>
      <Switch>
        <Route path={orderNew.path}>
          <NewOrder />
        </Route>
        <Route path={orderEdit.path}>
          <EditOrder />
        </Route>
        <Route path={lotAssignments.path}>
          <LotAssignmentsContainer />
        </Route>
        <Route path={orderDetails.path}>
          <OrderListContainer />
        </Route>
      </Switch>
    </FiltersContextProvider>
  );
};

export default OrdersContainer;
