import type { TableColumn } from '@zen/DesignSystem';

import { PeriodsColumnEnum, ProductLotsColumnEnum, ProductsColumnEnum } from '../types';

type ColumnType = PeriodsColumnEnum | ProductsColumnEnum | ProductLotsColumnEnum;

export type ColumnMapKey = keyof ColumnMap | ColumnType;

type ColumnMap = {
  booking: ProductLotsColumnEnum[];
  dateRange: PeriodsColumnEnum[];
  orderedLineItem: ProductLotsColumnEnum[];
  product: ProductsColumnEnum[];
};

const columnMap: ColumnMap = {
  orderedLineItem: [ProductLotsColumnEnum.REQUIREDDELIVERYDATE],
  booking: [ProductLotsColumnEnum.BOOKINGSTATUS],
  dateRange: [PeriodsColumnEnum.STARTDATE, PeriodsColumnEnum.ENDDATE],
  product: [ProductsColumnEnum.PRODUCTNAME, ProductsColumnEnum.PRODUCTSKUCODE]
};

const extractColumnKeys = <T>(columns: TableColumn<T>[]): ColumnMapKey[] => {
  return columns.map(({ key }: TableColumn<T>) => key as ColumnMapKey);
};

const mapColumnKeys = (keys: Set<ColumnMapKey>): ColumnType[] => {
  return [...keys].reduce((acc: ColumnType[], key: ColumnMapKey) => acc.concat(columnMap[key as keyof ColumnMap] || [key]), []);
};

export const getColumnKeys = <T>(columns: TableColumn<T>[], hiddenColumnKeys: string[]): ColumnType[] => {
  const columnKeys: ColumnMapKey[] = extractColumnKeys(columns);
  const keys: Set<ColumnMapKey> = new Set(columnKeys.filter((key) => !hiddenColumnKeys.includes(key)));

  return mapColumnKeys(keys);
};
