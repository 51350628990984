import type { FC } from 'react';

import FormHeadline from '@zen/Components/Form/FormHeadline';
import CloseIcon from '@zen/Components/Icons/CloseIcon';

interface Props {
  onClose: () => void;
}

const IssueListHeader: FC<Props> = ({ onClose }) => {
  const tagline = (
    <>
      The data used to provide this view has some issues. In order to help us provide you with the best insights, please address
      the issues below. If you want to discuss giving us large amounts of data, contact
      <a className="text-navy-dark hover:underline ml-1" href="mailto:product@zencargo.com">
        product@zencargo.com
      </a>
    </>
  );

  return (
    <div className="flex flex-col">
      <div className="pt-10 px-10">
        <div className="flex justify-between">
          <FormHeadline tagline={tagline} text="Data quality" />
          <span className="-mt-4 -mr-4">
            <CloseIcon onClick={onClose} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default IssueListHeader;
