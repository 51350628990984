import type { FC } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import Search from '@zen/Components/Search';
import { Pagination, Table, TableColumn } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks';
import type { OrganisationsQueryResult, OrganisationsQueryVariables } from '@zen/Networks/graphql';
import { useOrganisationsQuery } from '@zen/Networks/graphql';
import { useUrlPagination } from '@zen/utils/hooks/pagination';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { getOrganisationTableConfig } from './tableConfig';
import type { Organisation } from './types';

interface Props {
  networkId: string;
}

const Organisations: FC<Props> = ({ networkId: accountUuid }) => {
  const { routes } = useNetworksContext();
  const { search, setSearch } = useUrlFilters();
  const { nodes, error, loading, paginationInfo } = useUrlPagination<
    OrganisationsQueryResult,
    OrganisationsQueryVariables,
    Organisation
  >(
    useOrganisationsQuery,
    'network.organisations',
    {
      accountUuid,
      nameContains: search
    },
    { fetchPolicy: 'cache-and-network' }
  );

  const clearSearchInput = (): void => setSearch(undefined);
  const handleSearch = (value: string): void => setSearch(value);

  const tableConfig: TableColumn<Organisation>[] = getOrganisationTableConfig(routes);

  return (
    <>
      <div className="flex mb-6">
        <div className="flex-1">
          <Search
            className="fs-organisation-search"
            initialValue={search}
            onClear={clearSearchInput}
            onSubmit={handleSearch}
            placeholder="Search for an organisation..."
          />
        </div>
      </div>
      <QueryHandler data={nodes} error={!!error} isLoading={loading}>
        {(organisations: Organisation[]) => {
          return (
            <>
              <Table columns={tableConfig} data={organisations} tableId="organisationsTable" />
              <Pagination pageInfo={paginationInfo} />
            </>
          );
        }}
      </QueryHandler>
    </>
  );
};

export default Organisations;
