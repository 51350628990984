import type { FC } from 'react';

import ContentBox from '@zen/Components/ContentBox';
import SkeletonLoading from '@zen/Components/SkeletonLoading';

interface Props {
  count?: number;
}

const RepeatedSkeletons: FC<Props> = ({ count }) => (
  <div data-testid="skeleton-product">
    {[...Array(count)].map((_, i) => (
      <div key={i} className="grid grid-cols-3 gap-4 mt-6">
        {[...Array(count)].map((__, index) => (
          <ContentBox key={index} className="py-4 px-6 text-grey-base h-full">
            <div className="mb-3 font-bold text-navy-base leading-normal">
              <SkeletonLoading height={25} lighter={true} width="w-32" />
            </div>
            <div className="text-sm mb-1">
              <SkeletonLoading height={20} width="w-40" />
            </div>
            <div className="text-sm">
              <SkeletonLoading height={20} width="w-48" />
            </div>
          </ContentBox>
        ))}
      </div>
    ))}
  </div>
);

const SkeletonProductListLoading: FC<Props> = ({ count = 3 }) => {
  return <RepeatedSkeletons count={count} />;
};

export type { Props as SkeletonProductListLoadingProps };

export default SkeletonProductListLoading;
