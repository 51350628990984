import type { FC } from 'react';
import * as Yup from 'yup';

import { Form, FormButtons, FormInstance } from '@zen/Components/Form';
import FormDatePicker from '@zen/Components/Form/FormDatePicker';
import FormPurchaseOrderDelayReasonSelect from '@zen/Components/Form/FormPurchaseOrderDelayReasonSelect';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import FormToggle from '@zen/Components/Form/FormToggle';
import StickyFooter from '@zen/Components/StickyFooter';
import type { ToggleOption } from '@zen/DesignSystem';
import type { CargoReadyDateMutationResponse } from '@zen/Orders/CargoReadyDateSlideout';
import { getToday } from '@zen/utils/date';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional } from '@zen/utils/typescript';

import { ConfidenceLevel } from '../../types';
import DatesIndicator from '../DatesIndicator';
import type { CargoReadyDateFormInitialValues, RichDateInput } from './types';

const initialValues: CargoReadyDateFormInitialValues = {
  date: null,
  reasonForChange: {
    reasonId: null,
    reasonDescription: ''
  },
  confidenceLevel: ConfidenceLevel.ESTIMATED
};

const options: ToggleOption<ConfidenceLevel>[] = [
  { label: 'Estimate', value: ConfidenceLevel.ESTIMATED },
  { label: 'Actual', value: ConfidenceLevel.ACTUAL }
];

const validationSchema = Yup.object().shape({
  date: Yup.string().nullable().required('Please select a new cargo ready date')
});

interface Props {
  cargoReadyDate?: string;
  onSubmit: (values: RichDateInput) => Promise<IOkOrErrorResult>;
  onSuccess?: (values: CargoReadyDateMutationResponse) => void;
  orderDate: Optional<string>;
}

const CargoReadyDateForm: FC<Props> = (props) => {
  const { cargoReadyDate, onSubmit, onSuccess, orderDate } = props;

  const getMaxDate = ({ confidenceLevel }: RichDateInput): string | undefined => {
    if (confidenceLevel === ConfidenceLevel.ACTUAL) {
      return getToday();
    }

    return undefined;
  };

  const SubmitButton = ({ isSubmitting }: FormInstance<RichDateInput>) => (
    <StickyFooter minWindowHeight={65}>
      <FormButtons isSubmitting={isSubmitting} text="Submit" />
    </StickyFooter>
  );

  return (
    <Form
      className="h-screen overflow-y-scroll relative px-8 pb-16"
      enableReinitialize={true}
      formButtons={SubmitButton}
      formName="CargoReadyDateForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <>
          <p className="font-bold text-grey-dark text-sm py-2">Estimate or actual date?</p>
          <FormToggle hideLabel={true} inline={false} name="confidenceLevel" options={options} />
          {cargoReadyDate && <DatesIndicator newDate={values.date} previousDate={cargoReadyDate} />}
          <FormDatePicker
            hideLabel={true}
            initialDate={cargoReadyDate}
            maxDate={getMaxDate(values)}
            minDate={orderDate || undefined}
            name="date"
          />
          {cargoReadyDate && (
            <FormPurchaseOrderDelayReasonSelect label="Select reason for delay" name="reasonForChange.reasonId" />
          )}
          <FormTextarea label="Add any additional information" name="reasonForChange.reasonDescription" textareaRows={7} />
        </>
      )}
    </Form>
  );
};

export default CargoReadyDateForm;
