import classNames from 'classnames';
import { Component, ReactNode } from 'react';

import { Icon } from '@zen/DesignSystem';

import type { Collection } from './Collection';

type CardGenerator<T> = (item: T) => ReactNode;

export interface CardPickerProps<T> {
  children: CardGenerator<T>;
  collection: Collection<T>;
  comparator?: (o1: T | undefined, o2: T) => boolean;
  itemWithRiskStrategy?: (o: T) => boolean;
  onSelect: (d: T) => void;
  selection?: T;
}

interface CardPickerState {
  position: number;
}

class CardPicker<T> extends Component<CardPickerProps<T>, CardPickerState> {
  public state = {
    position: 0
  };

  private compare: (o1: T | undefined, o2: T) => boolean;

  private isItemRisky: (o: T) => boolean;

  constructor(props: CardPickerProps<T>) {
    super(props);
    this.compare = props.comparator || ((o1: T | undefined, o2: T) => o1 === o2);
    this.isItemRisky = props.itemWithRiskStrategy || (() => false);
  }

  public componentDidMount() {
    if (this.props.selection) {
      const index = this.props.collection.findIndex(this.props.selection, this.compare);

      this.setState({ position: index });
    }
  }

  public render() {
    const arrowClassNames: string = 'cursor-pointer select-none';
    const iconClassNames: string = 'text-grey-base hover:text-navy-base';

    const renderLeftArrow = () => {
      if (this.props.collection.isPositionWithinBounds(this.state.position - 1)) {
        return (
          <div className={arrowClassNames} data-testid="left-arrow" onClick={() => this.movePosition(this.state.position - 1)}>
            <Icon className={iconClassNames} icon="zicon-chevron-left" />
          </div>
        );
      }

      return <div className={arrowClassNames} />;
    };

    const renderRightArrow = () => {
      if (this.props.collection.isPositionWithinBounds(this.state.position + 1)) {
        return (
          <div className={arrowClassNames} data-testid="right-arrow" onClick={() => this.movePosition(this.state.position + 1)}>
            <Icon className={iconClassNames} icon="zicon-chevron-right" />
          </div>
        );
      }

      return <div className={arrowClassNames} />;
    };

    const getCardClassNames = (item: T) => {
      const isRisky = this.isItemRisky(item);
      const isSelected = this.compare(this.props.selection, item);
      const isRegular = !isRisky && !isSelected;
      const isRiskySelected = isRisky && isSelected;

      return classNames(
        {
          'border-red-base text-red-base bg-white': isRisky && !isRiskySelected,
          'border-navy-base text-white bg-navy-base': isSelected && !isRiskySelected,
          'border-red-base text-white bg-red-base': isRiskySelected,
          'border-grey-dark text-grey-dark bg-white': isRegular
        },
        'flex items-center justify-center w-14 h-14 select-none border rounded-sm border-solid hover:border-2 cursor-pointer'
      );
    };

    return (
      <div className="flex items-center space-x-4">
        {renderLeftArrow()}
        <div className="flex flex-1 justify-between items-center">
          {this.props.collection.windowAtPosition(this.state.position).map((item: T, i: number) => (
            <div key={i} className={getCardClassNames(item)} onClick={() => this.props.onSelect(item)}>
              {this.props.children(item)}
            </div>
          ))}
        </div>
        {renderRightArrow()}
      </div>
    );
  }

  private movePosition(position: number) {
    if (position !== this.state.position) {
      this.setState({ position });
    }
  }
}

export default CardPicker;
