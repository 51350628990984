import cx from 'classnames';
import { ErrorMessage } from 'formik';
import { kebabCase } from 'lodash';
import type { FC } from 'react';

export const buildTestId = (fieldName: string): string => {
  return `form-field-error-${kebabCase(fieldName)}`;
};

interface Props {
  className?: string;
  fieldName: string;
}

const FormError: FC<Props> = ({ className, fieldName }) => {
  const classNames: string = cx('text-red-dark leading-tight mt-2 text-sm', className);

  return <ErrorMessage className={classNames} component="div" data-testid={buildTestId(fieldName)} name={fieldName} />;
};

export default FormError;
