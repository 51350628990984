import { Chip } from '@zen/DesignSystem';
import { SavedFilter, useSavedFilters } from '@zen/utils/Filtering/contexts/SavedFiltersContext';

interface Props<T extends {}> {
  filters: T;
  setFilters: (filters: Partial<T>) => void;
}

const QuickFilters = <T extends {}>({ filters, setFilters }: Props<T>) => {
  const { savedFilters, getActiveSavedFilter } = useSavedFilters();

  const activeQuickFilter = getActiveSavedFilter(filters);

  return (
    <>
      {savedFilters.map((savedFilter: SavedFilter<{}>) => (
        <Chip
          key={`qFilter-${savedFilter.name}`}
          isSelected={activeQuickFilter === savedFilter}
          label={savedFilter.name}
          onDelete={() => setFilters({})}
          onSelect={() => setFilters(savedFilter.filters)}
        />
      ))}
    </>
  );
};

export default QuickFilters;
