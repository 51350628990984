import type { FC } from 'react';

import AddressComponent from '@zen/Components/Address';
import Map from '@zen/Components/Map';
import type { Address, BusinessHours, Geolocation } from '@zen/Networks/types';
import type { Optional } from '@zen/utils/typescript';

import LocationBusinessHours from '../LocationBusinessHours';

interface Props {
  address: Address;
  businessHours: BusinessHours;
  geolocation: Optional<Geolocation>;
}

const LocationAddressDetails: FC<Props> = ({ address, businessHours, geolocation }) => {
  const titleClassNames: string = 'font-bold py-4';
  const detailClassNames: string = 'font-grey-dark mb-1';

  const marker = geolocation && {
    lat: geolocation.latitude,
    lng: geolocation.longitude,
    isSelected: true
  };

  return (
    <div className="flex py-12 border-b border-solid border-grey-lighter">
      <div className="w-1/3">
        <p className={titleClassNames}>Location address</p>
        <AddressComponent address={address} className={detailClassNames} />
        {geolocation && (
          <div data-testid="geolocation">
            <p className={titleClassNames}>Latitude</p>
            <p className={detailClassNames}>{geolocation.latitude}</p>
            <p className={titleClassNames}>Longitude</p>
            <p className={detailClassNames}>{geolocation.longitude}</p>
          </div>
        )}
        <LocationBusinessHours businessHours={businessHours} />
      </div>
      <div className="w-full h-100 py-4">
        <Map markers={(marker && [marker]) || []} />
      </div>
    </div>
  );
};

export default LocationAddressDetails;
