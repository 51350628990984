import { FC, useState } from 'react';

import OriginAndDestination from '@zen/Booking/OriginAndDestination';
import CreateEditModal from '@zen/Components/EditCreateModal';
import type { CreateEditModalConfiguration } from '@zen/Components/EditCreateModal/types';
import { ContextMenu, MenuItemType, Modal } from '@zen/DesignSystem';
import ShipmentStatusUpdate from '@zen/Shipment/ShipmentDetailsPage/ShipmentStatusUpdate';
import type { Optional } from '@zen/utils/typescript';

import AddDeliveryEstimate from '../AddDeliveryEstimate';
import UpdateDeliveryEstimate from '../UpdateDeliveryEstimate';

interface Props {
  canEditOriginAndDestination: boolean;
  canUpdateShipmentStatus: boolean;
  onDateChange: () => void;
  originalEstimate: Optional<string>;
  shipmentStatus: string;
  zencargoReference: string;
}

const DeliveryEstimateContextMenu: FC<Props> = (props) => {
  const {
    originalEstimate,
    zencargoReference,
    onDateChange,
    shipmentStatus,
    canEditOriginAndDestination,
    canUpdateShipmentStatus
  } = props;

  const [modalConfiguration, setModalConfiguration] = useState<CreateEditModalConfiguration<{ date: string }>>();
  const [showOriginDestinationModal, setShowOriginDestinationModal] = useState<boolean>(false);
  const [showShipmentStatusModal, setShowShipmentStatusModal] = useState<boolean>(false);

  const handleEdit = (): void => {
    setModalConfiguration({
      type: 'edit',
      initialValues: originalEstimate ? { date: originalEstimate } : undefined,
      title: 'Update estimated delivery date'
    });
  };

  const handleCreate = (): void => {
    setModalConfiguration({
      type: 'create',
      title: 'Add estimated delivery date'
    });
  };

  const handleCloseModal = (): void => setModalConfiguration(undefined);

  const originAndDestinationContextItem: MenuItemType = {
    label: 'Edit origin and destination',
    onClick: () => setShowOriginDestinationModal(true),
    icon: 'zicon-network'
  };

  const shipmentStatusContextItem: MenuItemType = {
    label: 'Update shipment status',
    onClick: () => setShowShipmentStatusModal(true),
    icon: 'zicon-adjust'
  };

  const contextMenuItems: MenuItemType[] = [
    { icon: 'zicon-delay', label: 'Add estimated delivery date', onClick: handleCreate },
    {
      icon: 'zicon-edit',
      label: 'Update estimated delivery date',
      onClick: handleEdit,
      disabled: !originalEstimate,
      addDivider: true
    },
    ...(canEditOriginAndDestination ? [originAndDestinationContextItem] : []),
    ...(canUpdateShipmentStatus ? [shipmentStatusContextItem] : [])
  ];

  return (
    <>
      <ContextMenu items={contextMenuItems} />
      <CreateEditModal
        configuration={modalConfiguration}
        createForm={({ onClose }) => (
          <AddDeliveryEstimate onClose={onClose} onSuccess={onDateChange} zencargoReference={zencargoReference} />
        )}
        editForm={({ onClose, item }) => (
          <UpdateDeliveryEstimate
            initialValues={item}
            onClose={onClose}
            onSuccess={onDateChange}
            zencargoReference={zencargoReference}
          />
        )}
        onClose={handleCloseModal}
      />
      <Modal
        isOpen={showOriginDestinationModal}
        modalOverflowY="visible"
        onClose={() => setShowOriginDestinationModal(false)}
        title="Edit origin and destination"
      >
        <OriginAndDestination onCancel={() => setShowOriginDestinationModal(false)} zencargoReference={zencargoReference} />
      </Modal>
      <Modal
        isOpen={showShipmentStatusModal}
        modalOverflowY="visible"
        onClose={() => setShowShipmentStatusModal(false)}
        title="Update shipment status"
      >
        <ShipmentStatusUpdate
          currentShipmentStatus={shipmentStatus}
          onCancel={() => setShowShipmentStatusModal(false)}
          zencargoReference={zencargoReference}
        />
      </Modal>
    </>
  );
};

export default DeliveryEstimateContextMenu;
