import type { FC, ReactNode } from 'react';

import { Form, FormInstance, FormNumberInput } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { Lot } from '../types';
import { validationSchema } from './LotForm.validation';

interface Props {
  initialValues: Lot;
  onSubmit: (value: Lot) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const LotForm: FC<Props> = ({ initialValues, onSubmit, onSuccess }) => {
  const renderFormButtons = ({ dirty, isSubmitting }: FormInstance<Lot>): ReactNode => {
    const isDisabled: boolean = !dirty || isSubmitting;

    return (
      <Button disabled={isDisabled} type="submit" variant="tertiary">
        Update
      </Button>
    );
  };

  const renderFields = (): ReactNode => (
    <div className="flex">
      <FormNumberInput className="w-36 mr-6" hideLabel={true} name="quantityFulfilled" noMargin={true} step={1} />
      <FormNumberInput className="w-36 mr-6" hideLabel={true} name="cbm" noMargin={true} />
    </div>
  );

  return (
    <Form
      className="flex"
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {renderFields}
    </Form>
  );
};

export default LotForm;
