import type { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { AssignmentTargetTypeEnum } from '@zen/Networks/types';
import { orderRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import { getPreviousUrl } from '@zen/utils/routing';
import type { Nullable } from '@zen/utils/typescript';

import OrderForm from '../components/OrderForm/OrderForm';
import { useOrderFormQuery, usePurchaseOrdersDeleteFieldMutation, usePurchaseOrdersUpdateOrderMutation } from '../graphql';
import type { OrderFormOrder, OrderFormOrderedLineItem, OrderFormPayload, OrderFormValues } from '../types';
import SkeletonEditOrderLoading from './Loading/SkeletonEditOrderLoading';

const prepareOrderLineItems = (orderedLineItems: OrderFormOrderedLineItem[]) =>
  orderedLineItems.map((lineItem) => ({
    ...lineItem,
    totalCost: {
      currency: lineItem.totalCost && lineItem.totalCost.currency,
      value: lineItem.totalCost && lineItem.totalCost.value
    },
    initialCargoReadyDate: lineItem.initialCargoReadyDate ? lineItem.initialCargoReadyDate.date : null,
    requiredDeliveryDate: lineItem.requiredDeliveryDate ? lineItem.requiredDeliveryDate.date : null
  }));

type OrderNetworkKeys = keyof Pick<
  OrderFormOrder,
  'buyer' | 'manufacturer' | 'origin' | 'destination' | 'forwarder' | 'originAgent' | 'seller'
>;

const getInitialValues = (order: OrderFormOrder, canAssignAgent: boolean): OrderFormValues => {
  const getValue = <T extends OrderNetworkKeys>(key: T): Nullable<OrderFormOrder[T]> => (order[key] ? order[key] : null);

  return {
    orderReferenceNumber: order.orderReferenceNumber,
    orderDate: order.orderDate,
    buyer: getValue('buyer'),
    manufacturer: getValue('manufacturer'),
    seller: getValue('seller'),
    portOfLoadUnlocode: order.portOfLoad,
    portOfDestinationUnlocode: order.portOfDestination,
    incoterms: order.incoterms ? order.incoterms.value : null,
    modeOfTransport: order.modeOfTransport,
    collectionWarehouse: getValue('origin'),
    deliveryWarehouse: getValue('destination'),
    forwarder: getValue('forwarder'),
    orderedLineItems: prepareOrderLineItems(order.orderedLineItems),
    lineItemIdsToDelete: [],
    ...(canAssignAgent && { originAgent: getValue('originAgent') })
  } as OrderFormValues;
};

const EditForm: FC = () => {
  const { push } = useHistory();
  const { id: orderId } = useParams<{ id: string }>();
  const { data, loading, error } = useOrderFormQuery({
    variables: { id: orderId },
    fetchPolicy: 'no-cache'
  });
  const { addSuccess, addError } = useNotification();
  const location = useLocation();
  const { check } = useGlobalPermissions();
  const canAssignAgent = check('purchaseOrders.canAssignAgent');
  const previousUrl: string = getPreviousUrl(location);
  const [updateOrder] = usePurchaseOrdersUpdateOrderMutation();
  const [deleteOrderFields] = usePurchaseOrdersDeleteFieldMutation();
  const backUrl: string = previousUrl || orderRoutes.orderItems.getUrl(orderId);

  const handleSubmit = async (
    { id, ...purchaseOrder }: OrderFormPayload,
    assignmentNames?: string[]
  ): Promise<IOkOrErrorResult> => {
    if (assignmentNames && assignmentNames.length > 0) {
      await updateOrder({ variables: { input: { purchaseOrderId: orderId, purchaseOrder } } });

      return performMutation({
        mutationFn: () =>
          deleteOrderFields({
            variables: { input: { targetType: AssignmentTargetTypeEnum.PURCHASE_ORDER, targetId: orderId, assignmentNames } }
          }),
        onError: addError
      });
    }

    return performMutation({
      mutationFn: () => updateOrder({ variables: { input: { purchaseOrderId: orderId, purchaseOrder } } }),
      onError: addError
    });
  };

  const handleSuccess = () => {
    if (!previousUrl) {
      addSuccess('Order has been updated.');
    }

    push(backUrl);
  };

  return (
    <QueryHandler
      data={data?.purchaseOrders?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonEditOrderLoading />}
    >
      {(orderData: OrderFormOrder) => (
        <Page defaultBackUrl={backUrl} title="Edit PO" width="medium">
          <OrderForm
            buttonText="Update PO"
            initialValues={getInitialValues(orderData, canAssignAgent)}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
          />
        </Page>
      )}
    </QueryHandler>
  );
};

export default EditForm;
