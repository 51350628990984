import type { RoutesConfig } from './types';

export const OPS_QUOTES_ROUTE_PREFIX = '/dashboard/ops-quotes';

interface OpsQuoteRoutes extends RoutesConfig {
  quoteDetails: {
    getUrl: (zencargoReference: string, tab?: string) => string;
    path: string;
  };
  quoteIndex: {
    getUrl: () => string;
    path: string;
  };
  quotesDetailsPage: {
    getUrl: (zencargoReference: string) => string;
    path: string;
  };
}

const opsQuoteRoutes: OpsQuoteRoutes = {
  quoteDetails: {
    path: `${OPS_QUOTES_ROUTE_PREFIX}/:id?`,
    getUrl: (zencargoReference: string, tab?: string): string => {
      const url = `${OPS_QUOTES_ROUTE_PREFIX}/${zencargoReference}`;

      return `${url}/${tab || 'activity'}`;
    }
  },
  quoteIndex: {
    path: OPS_QUOTES_ROUTE_PREFIX,
    getUrl: () => OPS_QUOTES_ROUTE_PREFIX
  },
  quotesDetailsPage: {
    path: `${OPS_QUOTES_ROUTE_PREFIX}/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${OPS_QUOTES_ROUTE_PREFIX}/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  }
};

export default opsQuoteRoutes;
