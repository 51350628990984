import type { FC } from 'react';

interface Props {
  htmlFor?: string;
  overLabel?: string;
}

const OverLabel: FC<Props> = ({ overLabel, htmlFor }) => {
  if (!overLabel) {
    return null;
  }

  return (
    <label className="absolute z-10 left-2 -top-2 px-0.5 text-grey-base bg-white cursor-pointer rounded" htmlFor={htmlFor}>
      {overLabel}
    </label>
  );
};

export default OverLabel;
