import type { FC } from 'react';

import Page from '@zen/Components/Page';
import productSettingsRoutes from '@zen/Routes/productSettings';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import DocumentTemplateForm from '../DocumentTemplateForm';
import { useCreateDocumentTemplateMutation } from '../graphql';
import { getDocumentTemplateFormInitialValues } from '../helpers';
import type { DocumentTemplateFormValues } from '../types';

const NewDocumentTemplate: FC = () => {
  const { navigate } = useNavigationHistory();
  const { addError, addSuccess } = useNotification();

  const [createNewDocumentTemplate] = useCreateDocumentTemplateMutation();

  const handleCreateNewDocumentTemplate = (input: DocumentTemplateFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        createNewDocumentTemplate({
          variables: {
            input
          }
        }),
      onError: addError
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Document template created');
    navigate(productSettingsRoutes.documentTemplatesIndex.getUrl());
  };

  return (
    <Page
      defaultBackUrl={productSettingsRoutes.documentTemplatesIndex.getUrl()}
      tagline="Create a new document template"
      title="Adding a new document template"
    >
      <DocumentTemplateForm
        initialValues={getDocumentTemplateFormInitialValues()}
        onSubmit={handleCreateNewDocumentTemplate}
        onSuccess={handleSuccess}
        submitButtonText="Create"
      />
    </Page>
  );
};

export default NewDocumentTemplate;
