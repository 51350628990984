import { useContext, useMemo } from 'react';

import TrackingContext from '@zen/Components/TrackingProvider/TrackingContext';

const useTracking = () => {
  const { trackEvent, trackTiming, identify } = useContext(TrackingContext);

  return useMemo(() => ({ trackEvent, trackTiming, identify }), [trackEvent, trackTiming, identify]);
};

export default useTracking;
