import { FC, useState } from 'react';

import type { NewCargo } from '@zen/Cargo';
import { useCollectionAndDelivery } from '@zen/CollectionAndDelivery/CollectionAndDeliveryContext';
import { getCargoAddressLabel } from '@zen/CollectionAndDelivery/collectionAndDeliveryHelper';
import { CargoAddress, CollectionAndDeliveryState } from '@zen/CollectionAndDelivery/types';
import { Checkbox } from '@zen/DesignSystem';
import { useMultiCargoSchedule } from '@zen/Shipment/Cargo/MultiCargoScheduleProvider';
import type { ModeOfTransport } from '@zen/types';

import ScheduleActionDropdown from '../ScheduleActionDropdown';
import ScheduleActions from '../ScheduleActions';
import ScheduleStatePill from '../ScheduleStatePill';
import type { ScheduleActionType, ScheduleDetails, ScheduleItemType } from '../types';
import DetailsItemInfo from './DetailsItemInfo';
import { getDateTimeLabel } from './helpers';

interface Props {
  accountUuid: string;
  cargo: NewCargo;
  detailsItemType: ScheduleItemType;
  modeOfTransport: ModeOfTransport;
  onCargoChange: () => void;
}

const DetailsItem: FC<Props> = ({ accountUuid, cargo, detailsItemType, modeOfTransport, onCargoChange }) => {
  const [actionType, setActionType] = useState<ScheduleActionType>();
  const { zencargoReference } = useCollectionAndDelivery();
  const { scheduleType, toggleCargo, isCargoSelected, setErrorIds, hasError } = useMultiCargoSchedule();

  const schedule: ScheduleDetails = cargo[detailsItemType]!;
  const { state, driverDetails, vehiclePlateNumber } = schedule;
  const shouldRenderPill = state !== CollectionAndDeliveryState.CANCELLED;
  const isCompleted = state === CollectionAndDeliveryState.COMPLETED;
  const isSelectedScheduleType = detailsItemType === scheduleType;

  // !scheduleType => there is no options (collection or delivery) selected from multi cargo select
  const displayCheckbox = isSelectedScheduleType || !scheduleType;

  const handleSuccess = () => {
    setActionType(undefined);
    if (isCargoSelected(cargo.id)) {
      toggleCargo(cargo, detailsItemType);
    }
    onCargoChange();
  };

  const handleError = (errorIds: string[]) => {
    setErrorIds(errorIds);
  };

  const itemConfig = {
    collection: {
      title: 'Collection',
      pillCompletedLabel: 'Collected',
      scheduleLabel: 'Collected on'
    },
    delivery: {
      title: 'Delivery',
      pillCompletedLabel: 'Delivered',
      scheduleLabel: 'Delivered on'
    }
  };

  const errorClassNames = hasError(cargo.id) && isSelectedScheduleType ? 'border border-solid border-red-base' : '';

  const handleCheckboxToggle = () => toggleCargo(cargo, detailsItemType);

  const { title, pillCompletedLabel, scheduleLabel } = itemConfig[detailsItemType];

  const getScheduleLabel = () => (isCompleted ? scheduleLabel : 'Scheduled at');
  const addressLabel = getCargoAddressLabel(schedule.location as CargoAddress);

  return (
    <>
      <div className={`pl-8 pr-4 pb-5 bg-grey-lightest ${errorClassNames}`}>
        <div className="flex mb-4">
          <div className={`${!displayCheckbox && 'opacity-0 pointer-events-none'} mr-2 -ml-6 pt-1`}>
            <Checkbox checked={isCargoSelected(cargo.id)} onChange={handleCheckboxToggle} />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <div className="font-bold text-grey-dark text-sm leading-normal mr-4">{title}</div>
              {shouldRenderPill && (
                <div className="mr-6" data-testid="schedule-pill">
                  <ScheduleStatePill completedlabel={pillCompletedLabel} state={state} />
                </div>
              )}
              <div className="text-sm">{addressLabel}</div>
            </div>
            <div className="ml-auto">
              <ScheduleActionDropdown cargo={cargo} optionsType={detailsItemType} setActionType={setActionType} />
            </div>
          </div>
        </div>
        <DetailsItemInfo
          driverDetails={driverDetails}
          modeOfTransport={modeOfTransport}
          reference={schedule.reference}
          scheduleDate={getDateTimeLabel(schedule, isCompleted)}
          scheduleLabel={getScheduleLabel()}
          vehiclePlateNumber={vehiclePlateNumber}
        />
      </div>
      {actionType && detailsItemType && (
        <div data-testid="action-components">
          <ScheduleActions
            accountUuid={accountUuid}
            cargoList={[cargo]}
            close={() => setActionType(undefined)}
            modeOfTransport={modeOfTransport}
            onError={handleError}
            onSuccess={handleSuccess}
            scheduleType={detailsItemType}
            type={actionType}
            zencargoReference={zencargoReference}
          />
        </div>
      )}
    </>
  );
};

export default DetailsItem;
