import type { FC } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { IconButton } from '@zen/DesignSystem';

interface Props {
  locationId: string;
  locationName: string;
  onCopy: () => void;
  onDelete: () => void;
  onEdit: () => void;
  organisationName: string;
}

const LocationDetailsHeader: FC<Props> = (props) => {
  const { locationId, locationName, organisationName, onCopy, onDelete, onEdit } = props;

  return (
    <div className="flex justify-between">
      <div>
        <p className="text-xl font-bold text-navy-dark pb-4">{organisationName}</p>
        <p className="text-3xl text-navy-dark font-bold">{locationName}</p>
      </div>
      <div className="flex items-start">
        <CopyToClipboard text={locationId}>
          <IconButton
            className="mr-2"
            icon="zicon-copy"
            onClick={onCopy}
            title="Click to copy this location id"
            variant="secondary"
          />
        </CopyToClipboard>
        <IconButton className="mr-2" icon="zicon-edit" onClick={onEdit} title="Edit" variant="secondary" />
        <IconButton icon="zicon-trash" onClick={onDelete} title="Delete" variant="secondary" />
      </div>
    </div>
  );
};

export default LocationDetailsHeader;
