import classnames from 'classnames';
import type { FC } from 'react';

import ActivityFeedDay from '../components/ActivityFeedDay';
import {
  ActionItem,
  ActivityFeedDataItem,
  ActivityFeedItemTypeEnum,
  BookingRequestType,
  CargoReadyDateItemType,
  DeliveryItemType,
  TextMessage
} from '../types';
import ActionItemRequestedItem from './ActionItemRequestedItem';
import BookingItem from './BookingItem';
import CargoItem from './CargoItem';
import CargoReadyDateUpdateItem from './CargoReadyDateUpdateItem';
import CollectionAndDeliveryItem from './CollectionAndDeliveryItem';
import ActivityFeedItemPermissionsContext from './context/ActivityFeedItemPermissionsContext';
import DeliveryItem from './DeliveryItem';
import EtaItem from './EtaItem';
import IssueFeedItem from './IssueFeedItem';
import NonActionableActionItem from './NonActionableActionItem';
import OrderItem from './PurchaseOrder/OrderItem';
import QuoteOptionItems from './QuoteOptionItems';
import RejectedBookingRequestItem from './RejectedBookingRequestItem';
import RejectedBookingResubmitForApproval from './RejectedBookingResubmitForApproval';
import TextMessageItem from './TextMessageItem';

interface Props {
  hideBullet?: boolean;
  item: ActivityFeedDataItem;
  shouldRenderDay: boolean;
  showUserData: boolean;
}

const ActivityFeedItem: FC<Props> = (props) => {
  const { item, shouldRenderDay, showUserData, hideBullet } = props;

  const { data, ...rest } = item;

  const getComponent = () => {
    switch (rest.itemType) {
      case ActivityFeedItemTypeEnum.TEXT_MESSAGE:
      case ActivityFeedItemTypeEnum.ZENSEI_TEXT_MESSAGE:
        return <TextMessageItem activityMetadata={rest} message={data as TextMessage} showUserData={showUserData} />;
      case ActivityFeedItemTypeEnum.QUOTE_OPTION:
      case ActivityFeedItemTypeEnum.ACCEPTED_QUOTE_OPTION:
      case ActivityFeedItemTypeEnum.AUTOMATICALLY_REJECTED_QUOTE_OPTION:
      case ActivityFeedItemTypeEnum.REJECTED_QUOTE_OPTION:
        return <QuoteOptionItems item={item} itemType={rest.itemType} />;
      case ActivityFeedItemTypeEnum.ADDED_ACTION_ITEM:
        return <ActionItemRequestedItem actionItem={data as ActionItem} activityMetadata={rest} />;
      case ActivityFeedItemTypeEnum.APPROVED_ACTION_ITEM:
      case ActivityFeedItemTypeEnum.CANCELLED_ACTION_ITEM:
      case ActivityFeedItemTypeEnum.RESOLVED_ACTION_ITEM:
        return <NonActionableActionItem actionItem={data as ActionItem} activityMetadata={rest} />;
      case ActivityFeedItemTypeEnum.REQUESTED_COLLECTION:
      case ActivityFeedItemTypeEnum.REQUESTED_DELIVERY:
      case ActivityFeedItemTypeEnum.REREQUESTED_COLLECTION:
      case ActivityFeedItemTypeEnum.REREQUESTED_DELIVERY:
      case ActivityFeedItemTypeEnum.CONFIRMED_COLLECTION:
      case ActivityFeedItemTypeEnum.CONFIRMED_DELIVERY:
      case ActivityFeedItemTypeEnum.COMPLETED_COLLECTION:
      case ActivityFeedItemTypeEnum.COMPLETED_DELIVERY:
        return <CollectionAndDeliveryItem activityMetadata={rest} />;
      case ActivityFeedItemTypeEnum.STARTED_ISSUE:
      case ActivityFeedItemTypeEnum.OPENED_ISSUE:
      case ActivityFeedItemTypeEnum.RESOLVED_ISSUE:
      case ActivityFeedItemTypeEnum.ARCHIVED_ISSUE:
      case ActivityFeedItemTypeEnum.UPDATED_ISSUE:
        return <IssueFeedItem activityMetaData={rest} />;
      case ActivityFeedItemTypeEnum.ADDED_CARGO_ITEM:
      case ActivityFeedItemTypeEnum.DELETED_CARGO_ITEM:
      case ActivityFeedItemTypeEnum.UPDATED_CARGO_ITEM:
      case ActivityFeedItemTypeEnum.UPDATED_CARGO_TYPE:
        return <CargoItem activityMetadata={rest} />;
      case ActivityFeedItemTypeEnum.UPDATED_DELIVERY_ESTIMATES:
        return <DeliveryItem activityMetadata={rest} deliveryItem={data as DeliveryItemType} />;
      case ActivityFeedItemTypeEnum.UPDATED_CARGO_READY_DATES:
        return <CargoReadyDateUpdateItem activityMetadata={rest} cargoReadyDateItem={data as CargoReadyDateItemType} />;
      case ActivityFeedItemTypeEnum.REQUIRED_CUSTOMER_APPROVAL:
      case ActivityFeedItemTypeEnum.MANUFACTURER_REQUESTED_BOOKING:
      case ActivityFeedItemTypeEnum.CUSTOMER_APPROVED_BOOKING:
      case ActivityFeedItemTypeEnum.FORWARDER_BYPASSED_CUSTOMER_APPROVAL_FOR_BOOKING:
      case ActivityFeedItemTypeEnum.FORWARDER_REVERTED_REJECTION_AND_BYPASSED_CUSTOMER_APPROVAL_FOR_BOOKING:
        return <BookingItem activityMetaData={rest} />;
      case ActivityFeedItemTypeEnum.FORWARDER_RESUBMITTED_BOOKING_FOR_CUSTOMER_APPROVAL:
      case ActivityFeedItemTypeEnum.MANUFACTURER_RESUBMITTED_BOOKING_FOR_FORWARDER_APPROVAL:
        return <RejectedBookingResubmitForApproval activityMetaData={rest} />;
      case ActivityFeedItemTypeEnum.REJECTED_BOOKING_REQUEST:
        return <RejectedBookingRequestItem activityMetaData={rest} bookingRequest={data as BookingRequestType} />;
      case ActivityFeedItemTypeEnum.CREATED_PURCHASE_ORDER:
      case ActivityFeedItemTypeEnum.CLOSED_PURCHASE_ORDER:
        return <OrderItem activityMetadata={rest} />;
      case ActivityFeedItemTypeEnum.ETA_REMOVED_ITEM:
      case ActivityFeedItemTypeEnum.ETA_UPDATED_ITEM:
        return <EtaItem activityMetadata={rest} />;
    }
  };

  const itemClassNames = classnames(
    {
      '-ml-8': !hideBullet,
      '-ml-6': hideBullet
    },
    'mb-6 z-10'
  );

  return (
    <ActivityFeedItemPermissionsContext.Provider value={rest.permissions}>
      <>
        {shouldRenderDay && <ActivityFeedDay date={item.createdAt} hideBullet={hideBullet} />}
        <div className={itemClassNames}>{getComponent()}</div>
      </>
    </ActivityFeedItemPermissionsContext.Provider>
  );
};

export default ActivityFeedItem;
