import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderListLotsAndPurchaseOrderQueryVariables = GraphQLTypes.Exact<{
  newCargoService: GraphQLTypes.Scalars['Boolean'];
  purchaseOrderIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  ids?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  availableForBooking?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  availableForCargo?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  onlyNotBooked?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  paginated?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
}>;

export type OrderListLotsAndPurchaseOrderQueryResult = { __typename?: 'Query' } & {
  purchaseOrdersLots?: GraphQLTypes.Maybe<
    { __typename?: 'LotConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'Lot' } & Pick<
              GraphQLTypes.Lot,
              'atRisk' | 'cbm' | 'estimatedDeliveryDate' | 'id' | 'quantityFulfilled'
            > & {
                booking?: GraphQLTypes.Maybe<{ __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'>>;
                cargo?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'CargoContainerType' } & Pick<GraphQLTypes.CargoContainerType, 'id'>)
                  | ({ __typename?: 'CargoLooseCargoType' } & Pick<GraphQLTypes.CargoLooseCargoType, 'id'>)
                  | ({ __typename?: 'CargoVehicleType' } & Pick<GraphQLTypes.CargoVehicleType, 'id'>)
                >;
                cargos?: GraphQLTypes.Maybe<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'id'>>;
                cargoReadyDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
                currentStage?: GraphQLTypes.Maybe<
                  { __typename?: 'ProgressStatus' } & Pick<GraphQLTypes.ProgressStatus, 'label' | 'name' | 'statusType'> & {
                      date?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
                    }
                >;
                deliveryStatus?: GraphQLTypes.Maybe<
                  { __typename?: 'ProgressStatus' } & Pick<GraphQLTypes.ProgressStatus, 'label' | 'statusType'> & {
                      date?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
                    }
                >;
                orderedLineItem?: GraphQLTypes.Maybe<
                  { __typename?: 'OrderedLineItem' } & Pick<GraphQLTypes.OrderedLineItem, 'cbm' | 'id'> & {
                      canUpdateDeliveryDate: { __typename?: 'AuthorizationResult' } & Pick<
                        GraphQLTypes.AuthorizationResult,
                        'value'
                      >;
                      product?: GraphQLTypes.Maybe<
                        { __typename?: 'Product' } & Pick<GraphQLTypes.Product, 'id' | 'name' | 'skuCode'>
                      >;
                    }
                >;
              }
          >
        >
      >;
    }
  >;
  purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'PurchaseOrder' } & Pick<
            GraphQLTypes.PurchaseOrder,
            'modeOfTransport' | 'fulfilledCbm' | 'id' | 'orderDate' | 'orderedCbm' | 'orderReferenceNumber'
          > & {
              cargoReadyDateEstimate?: GraphQLTypes.Maybe<
                { __typename?: 'PurchaseOrderEstimatedDateType' } & Pick<GraphQLTypes.PurchaseOrderEstimatedDateType, 'date'>
              >;
              destination?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >;
              forwarder?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
              >;
              incoterms?: GraphQLTypes.Maybe<{ __typename?: 'Incoterms' } & Pick<GraphQLTypes.Incoterms, 'value'>>;
              lastUpdatedAt?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
              manufacturer?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
              >;
              origin?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >;
            }
        >
      >
    >;
  };
};

export const OrderListLotsAndPurchaseOrderQueryDocument = /* #__PURE__ */ gql`
  query orderListLotsAndPurchaseOrderQuery(
    $newCargoService: Boolean!
    $purchaseOrderIds: [String!]
    $ids: [String!]
    $availableForBooking: String
    $availableForCargo: String
    $onlyNotBooked: Boolean
    $paginated: Boolean
  ) {
    purchaseOrdersLots(
      purchaseOrderIds: $purchaseOrderIds
      ids: $ids
      availableForBooking: $availableForBooking
      availableForCargo: $availableForCargo
      onlyNotBooked: $onlyNotBooked
      paginated: $paginated
    ) {
      nodes {
        atRisk
        booking {
          zencargoReference
        }
        cargo {
          id
        }
        cargos @include(if: $newCargoService) {
          id
        }
        cargoReadyDate {
          date
        }
        cbm
        currentStage {
          date {
            date
          }
          label
          name
          statusType
        }
        deliveryStatus {
          date {
            date
          }
          label
          statusType
        }
        estimatedDeliveryDate
        id
        orderedLineItem {
          canUpdateDeliveryDate {
            value
          }
          cbm
          id
          product {
            id
            name
            skuCode
          }
        }
        quantityFulfilled
      }
    }
    purchaseOrders(ids: $purchaseOrderIds) {
      nodes {
        cargoReadyDateEstimate {
          date
        }
        destination {
          id
          label {
            long
          }
        }
        modeOfTransport
        forwarder {
          id
          label {
            short
          }
        }
        fulfilledCbm
        id
        incoterms {
          value
        }
        lastUpdatedAt {
          date
        }
        manufacturer {
          id
          label {
            short
          }
        }
        orderDate
        orderedCbm
        orderReferenceNumber
        origin {
          id
          label {
            long
          }
        }
      }
    }
  }
`;

/**
 * __useOrderListLotsAndPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useOrderListLotsAndPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderListLotsAndPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderListLotsAndPurchaseOrderQuery({
 *   variables: {
 *      newCargoService: // value for 'newCargoService'
 *      purchaseOrderIds: // value for 'purchaseOrderIds'
 *      ids: // value for 'ids'
 *      availableForBooking: // value for 'availableForBooking'
 *      availableForCargo: // value for 'availableForCargo'
 *      onlyNotBooked: // value for 'onlyNotBooked'
 *      paginated: // value for 'paginated'
 *   },
 * });
 */
export function useOrderListLotsAndPurchaseOrderQuery(
  baseOptions: Apollo.QueryHookOptions<OrderListLotsAndPurchaseOrderQueryResult, OrderListLotsAndPurchaseOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderListLotsAndPurchaseOrderQueryResult, OrderListLotsAndPurchaseOrderQueryVariables>(
    OrderListLotsAndPurchaseOrderQueryDocument,
    options
  );
}
export function useOrderListLotsAndPurchaseOrderQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderListLotsAndPurchaseOrderQueryResult, OrderListLotsAndPurchaseOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderListLotsAndPurchaseOrderQueryResult, OrderListLotsAndPurchaseOrderQueryVariables>(
    OrderListLotsAndPurchaseOrderQueryDocument,
    options
  );
}
export type OrderListLotsAndPurchaseOrderQueryHookResult = ReturnType<typeof useOrderListLotsAndPurchaseOrderQuery>;
export type OrderListLotsAndPurchaseOrderQueryLazyQueryHookResult = ReturnType<
  typeof useOrderListLotsAndPurchaseOrderQueryLazyQuery
>;
