import * as Yup from 'yup';

export const documentTemplateFormValidationMessages: Record<string, string> = {
  name: 'Template name is required',
  externalId: 'Formstack ID is required',
  externalKey: 'Formstack Key is required'
};

export const documentTemplateFormValidation = Yup.object().shape({
  name: Yup.string().required(documentTemplateFormValidationMessages.name),
  externalId: Yup.string().required(documentTemplateFormValidationMessages.externalId),
  externalKey: Yup.string().required(documentTemplateFormValidationMessages.externalKey)
});
