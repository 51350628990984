import type { FC } from 'react';

import FiltersButton from '@zen/Components/FiltersButton';
import Search from '@zen/Components/Search';
import Slideout from '@zen/Components/Slideout';
import useTracking from '@zen/utils/hooks/useTracking';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import type { ShipmentFilters } from '../types';
import FiltersForm from './FiltersForm';
import { countFilters, emptyFilters, initialFilters, removeEmptyShipmentFilters } from './helpers';

interface Props {
  isOpened: boolean;
  onToggle: (isSlideoutOpened: boolean) => void;
}

const Filters: FC<Props> = ({ onToggle, isOpened }) => {
  const { trackEvent } = useTracking();
  const { filters, setFilters, search, setSearch } = useUrlFilters<ShipmentFilters>(initialFilters);

  const filtersCount: number = countFilters(filters);

  const clearSearchInput = (): void => setSearch(undefined);

  const handleSearch = (value: string): void => {
    setSearch(value);

    trackEvent({
      action: 'Shipments',
      category: 'Search',
      label: value
    });
  };

  const submitFilters = (values: Partial<ShipmentFilters>) => {
    setFilters(removeEmptyShipmentFilters(values));

    return Promise.resolve({ ok: { values }, error: null });
  };

  const closeSlideout = (): void => onToggle(false);

  const openSlideout = (): void => onToggle(true);

  const clearFilters = (): void => {
    closeSlideout();
    setFilters({});
  };

  return (
    <>
      <div className="flex mb-6">
        <div className="flex-1 mr-6">
          <Search
            className="fs-shipments-search"
            initialValue={search}
            onClear={clearSearchInput}
            onSubmit={handleSearch}
            placeholder="Search for a Shipment..."
          />
        </div>
        <FiltersButton filterCount={filtersCount} onClick={openSlideout} />
      </div>
      <Slideout onOutsideClick={closeSlideout} overlay={true} show={!!isOpened}>
        <FiltersForm
          initialValues={{ ...emptyFilters, ...filters }}
          onClose={closeSlideout}
          onReset={clearFilters}
          onSubmit={submitFilters}
          onSuccess={closeSlideout}
        />
      </Slideout>
    </>
  );
};

export default Filters;
