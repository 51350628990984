import type { TrackingEvent } from '@zen/Components/TrackingProvider/types';
import { getNowAsUTCString } from '@zen/utils/dateTime';

import { RoadShipmentTrackingAction, RoadShipmentTrackingCategory } from '../RoadShipments/types';
import type { RoadShipmentTrackedFields, TrackingInputMetricData } from './types';

export const buildTrackingInputMetricPayload = (formValues: Partial<RoadShipmentTrackedFields>): TrackingInputMetricData => {
  const { collectionRequestedDate, collectionConfirmedDate, deliveryConfirmedDate, vehiclePlateNumber } = formValues;

  const timestamp: string = getNowAsUTCString();

  const possibleValues: TrackingInputMetricData = {
    collectionConfirmedEnd: collectionConfirmedDate?.endTime,
    collectionConfirmedStart: collectionConfirmedDate?.startTime,
    collectionRequestedEnd: collectionRequestedDate?.endTime,
    collectionRequestedStart: collectionRequestedDate?.startTime,
    deliveryConfirmedEnd: deliveryConfirmedDate?.endTime,
    deliveryConfirmedStart: deliveryConfirmedDate?.startTime,
    vehiclePlateNumber
  };

  const timestampsForEnteredFieldsOnly: TrackingInputMetricData = Object.fromEntries(
    Object.entries(possibleValues)
      .filter(([_, v]) => v)
      .map(([k, _]) => [k, timestamp])
  );

  return timestampsForEnteredFieldsOnly;
};

export const buildRoadTrackingMetricTrackingEvent = (
  zencargoReference: string,
  cargoId: string,
  formValues: Partial<RoadShipmentTrackedFields>
): TrackingEvent => {
  return {
    action: RoadShipmentTrackingAction.ROAD_SHIPMENT_ROAD_TRACKING_INPUT_CHANGED,
    category: RoadShipmentTrackingCategory,
    label: 'RoadFormSubmitted',
    properties: {
      zencargoReference,
      cargoId,
      ...buildTrackingInputMetricPayload(formValues)
    }
  };
};
