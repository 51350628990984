import { FC, useState } from 'react';

import type { Option } from '@zen/DesignSystem';
import { Button, LegacyModal } from '@zen/DesignSystem';

import { FormLabel, FormSelect } from '..';
import { truckSwapLabelMapping } from './helpers';
import { ReactComponent as TruckSwapImage } from './truck-swap.svg';
import { TruckSwapEnum } from './types';

const options: Option<TruckSwapEnum>[] = [
  { value: TruckSwapEnum.TRUCK_SWAP, label: truckSwapLabelMapping[TruckSwapEnum.TRUCK_SWAP] },
  { value: TruckSwapEnum.NOT_TRUCK_SWAP, label: truckSwapLabelMapping[TruckSwapEnum.NOT_TRUCK_SWAP] },
  { value: TruckSwapEnum.UNKNOWN, label: truckSwapLabelMapping[TruckSwapEnum.UNKNOWN] }
];

interface Props {
  name: string;
}

const FormMultiStopSelect: FC<Props> = ({ name }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <FormLabel isRequired={true} label="Will the cargo swap trucks at any point during this shipment?" name={name} />
      <div className="flex">
        <FormSelect className="w-48 mr-4" hideLabel={true} isSearchable={false} name={name} options={options} />
        <Button iconLeft="zicon-information" onClick={() => setIsModalVisible(true)} variant="tertiary">
          Truck swap explained
        </Button>
      </div>
      <LegacyModal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <div className="p-12 max-w-lg flex flex-col items-center">
          <div className="font-bold text-lg leading-normal text-grey-dark mb-2">What is a truck swap shipment?</div>
          <div className="text-sm text-grey-dark text-center mb-5 w-96">
            Truck swap can also be called trailer swap or driver swap. This is where the cargo moved from one truck or trailer to
            another.
          </div>
          <TruckSwapImage />
        </div>
      </LegacyModal>
    </>
  );
};

export default FormMultiStopSelect;
