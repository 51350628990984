import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type SearchTransportSchedulesQueryVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.SearchTransportSchedulesInput;
}>;

export type SearchTransportSchedulesQueryResult = { __typename?: 'Query' } & {
  searchTransportSchedules?: GraphQLTypes.Maybe<
    { __typename?: 'SearchTransportSchedulesPayload' } & {
      transportSchedules?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'TransportSchedulePayloadType' } & Pick<
            GraphQLTypes.TransportSchedulePayloadType,
            | 'arrivalTime'
            | 'carrier'
            | 'cutOffDate'
            | 'departureTime'
            | 'from'
            | 'transitTime'
            | 'modeOfTransport'
            | 'to'
            | 'transportScheduleSourceReference'
          > & {
              legs?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'TransportScheduleLegType' } & Pick<
                    GraphQLTypes.TransportScheduleLegType,
                    | 'arrivalLocationCode'
                    | 'arrivalLocationName'
                    | 'carrier'
                    | 'departureLocationCode'
                    | 'departureLocationName'
                    | 'eta'
                    | 'etd'
                    | 'legNumber'
                    | 'transportIdentificationNumber'
                    | 'vesselName'
                    | 'voyageNumber'
                  >
                >
              >;
            }
        >
      >;
    }
  >;
};

export const SearchTransportSchedulesDocument = /* #__PURE__ */ gql`
  query searchTransportSchedules($input: SearchTransportSchedulesInput!) {
    searchTransportSchedules(input: $input) {
      transportSchedules {
        arrivalTime
        carrier
        cutOffDate
        departureTime
        from
        transitTime
        legs {
          arrivalLocationCode
          arrivalLocationName
          carrier
          departureLocationCode
          departureLocationName
          eta
          etd
          legNumber
          transportIdentificationNumber
          vesselName
          voyageNumber
        }
        modeOfTransport
        to
        transportScheduleSourceReference
      }
    }
  }
`;

/**
 * __useSearchTransportSchedulesQuery__
 *
 * To run a query within a React component, call `useSearchTransportSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTransportSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTransportSchedulesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchTransportSchedulesQuery(
  baseOptions: Apollo.QueryHookOptions<SearchTransportSchedulesQueryResult, SearchTransportSchedulesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<SearchTransportSchedulesQueryResult, SearchTransportSchedulesQueryVariables>(
    SearchTransportSchedulesDocument,
    options
  );
}
export function useSearchTransportSchedulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchTransportSchedulesQueryResult, SearchTransportSchedulesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<SearchTransportSchedulesQueryResult, SearchTransportSchedulesQueryVariables>(
    SearchTransportSchedulesDocument,
    options
  );
}
export type SearchTransportSchedulesQueryHookResult = ReturnType<typeof useSearchTransportSchedulesQuery>;
export type SearchTransportSchedulesLazyQueryHookResult = ReturnType<typeof useSearchTransportSchedulesLazyQuery>;
