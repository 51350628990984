import { isEqual } from 'lodash';
import moment from 'moment';
import type { FC } from 'react';
import * as Yup from 'yup';

import { checkPermission } from '@zen/Auth/authHelper';
import type { NewCargo } from '@zen/Cargo';
import { useCollectionAndDelivery } from '@zen/CollectionAndDelivery/CollectionAndDeliveryContext';
import { getCargoAddressLabel } from '@zen/CollectionAndDelivery/collectionAndDeliveryHelper';
import ScheduleActionFormButtons from '@zen/CollectionAndDelivery/Details/ScheduleActionFormButtons';
import {
  useAddDelayReasonMutation,
  useScheduleDeliveryMutation,
  useUpdateCollectionMutation
} from '@zen/CollectionAndDelivery/graphql';
import SchedulerHeader, { ScheduleType } from '@zen/CollectionAndDelivery/SchedulerHeader/SchedulerHeader';
import FormTimeConsigment from '@zen/CollectionAndDelivery/TimedConsignment/FormTimeConsigment';
import type { CargoAddress } from '@zen/CollectionAndDelivery/types';
import { Form, FormCheckbox, FormInput } from '@zen/Components/Form';
import FormDatePicker from '@zen/Components/Form/FormDatePicker';
import { DeliveryDetails, DeliverySchedule, TimedDelivery, VoyageMilestoneNameEnum } from '@zen/graphql/types.generated';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks/types';
import FormMilestoneDelayReasonFields from '@zen/Shipment/RouteProgress/VoyageMilestones/components/FormMilestoneDelayReasonFields';
import {
  getDateValidationMessage,
  getRangeValidationMessage,
  useMilestoneDateValidation
} from '@zen/Shipment/ShipmentDetailsPage/MilestoneDateProvider';
import { isRoadShipment, ModeOfTransport } from '@zen/Shipments';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';
import { useMutationIteration } from '@zen/utils/hooks/useMutationIteration';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { shouldSynchroniseRoadDetails } from '../helpers';
import ScheduleRoadFields from '../ScheduleRoadFields';
import type { RoadFields } from '../ScheduleRoadFields/types';
import DeliveryRisk from './DeliveryRisk';
import type { RequestedSchedule } from './types';

const validationSchema = Yup.object().shape({
  deliveryTo: Yup.object().required('Location must be selected.').nullable()
});

export interface DeliveryFormValues extends RoadFields {
  deliveryDate: string;
  deliveryReference: string;
  deliveryTime?: TimedDelivery;
  deliveryTo?: CargoAddress;
  description?: Optional<string>;
  reason?: Optional<{ label: string; value: string }>;
  showTimedDelivery: boolean;
}

interface Props {
  accountUuid: string;
  cargoList: NewCargo[];
  hideFormTitle?: boolean;
  modeOfTransport?: Nullable<ModeOfTransport>;
  onCancel: () => void;
  onError?: (errorItems: string[], message: string) => void;
  onSuccess: () => void;
  zencargoReference: string;
}

const ScheduleDeliveryForm: FC<Props> = (props) => {
  const { cargoList, accountUuid, onSuccess, onCancel, hideFormTitle, zencargoReference, modeOfTransport, onError } = props;

  const {
    collectionScheduleRequired,
    estimatedArrival,
    podDetentionFreeTime: detentionFreeTime,
    podDemurrageFreeTimeEnds: freeTimeEnds
  } = useCollectionAndDelivery();
  const { addSuccess, addError } = useNotification();
  const { maxDate, minDate, isValid } = useMilestoneDateValidation(VoyageMilestoneNameEnum.DELIVERED);

  const multiCargo = cargoList.length > 1;

  const handleError = (errorItems: NewCargo[]) => {
    const errorIds: string[] = errorItems.map(({ id }) => id);
    const totalErrors = errorItems.length;
    const errorMessage = multiCargo
      ? `There was an error scheduling ${totalErrors} cargo`
      : 'There was an error scheduling this cargo';

    if (onError) {
      onError(errorIds, errorMessage);
    }
  };

  const handleSuccess = (successResult: string) => {
    const errorMessage = multiCargo ? `${successResult} selected cargo have been scheduled` : 'Cargo has been scheduled';

    addSuccess(errorMessage);
  };

  const [updateCollection] = useUpdateCollectionMutation({
    refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
    awaitRefetchQueries: true
  });
  const { handleMutation, loading } = useMutationIteration(cargoList, handleSuccess, handleError);

  const [scheduleDelivery] = useScheduleDeliveryMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
    awaitRefetchQueries: true
  });

  const [addDelayReason] = useAddDelayReasonMutation();

  const handleScheduleDelivery = async (values: DeliverySchedule): Promise<IOkOrErrorResult> => {
    const mutate = (cargo: NewCargo) => {
      return scheduleDelivery({
        variables: {
          input: {
            id: cargo.id,
            zencargoReference,
            schedule: values
          }
        }
      });
    };

    return handleMutation(mutate);
  };

  const getDefaultDeliveryDate = (): string => {
    const defaultDate: string = minDate || moment.utc().format();

    return estimatedArrival ? moment.utc(estimatedArrival).add(1, 'day').format() : defaultDate;
  };

  const getDeliveryValue = (): Nullable<DeliveryDetails> => {
    if (cargoList?.length === 0) {
      return null;
    }

    const hasSameValues = cargoList.every((cargo) => isEqual(cargo.delivery, cargoList[0].delivery));

    return hasSameValues && cargoList[0].delivery ? cargoList[0].delivery : null;
  };

  const cargoDelivery = getDeliveryValue();
  const deliveryTo =
    cargoDelivery && cargoDelivery.location
      ? { ...cargoDelivery.location, name: getCargoAddressLabel(cargoDelivery.location) }
      : null;

  const deliveryTime = cargoDelivery?.timedDelivery
    ? { mode: cargoDelivery.timeMode, specificTime: cargoDelivery.specificTime }
    : null;

  const initialValues: DeliveryFormValues = {
    deliveryDate: getDefaultDeliveryDate(),
    deliveryReference: cargoDelivery?.reference || '',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ mode: Maybe<Mode> | undefined; specificTim... Remove this comment to see the full error message
    deliveryTime,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: Maybe<string> | undefined; id?: Mayb... Remove this comment to see the full error message
    deliveryTo,
    driverDetails: cargoDelivery?.driverDetails,
    driverDetailsEqual: false,
    showTimedDelivery: cargoDelivery?.timedDelivery || false,
    vehiclePlateNumber: cargoDelivery?.vehiclePlateNumber,
    vehiclePlateNumberEqual: false
  };
  const allowDelayReasonEntry: boolean = cargoList.some((cargo) => cargo.delivery?.scheduleDate);

  const detentionBegins = (): string => {
    return (
      moment
        .utc(initialValues.deliveryDate)
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        .add(detentionFreeTime + 1, 'days')
        .format()
    );
  };

  const synchroniseRoadDetails = async (values: DeliveryFormValues, cargoItems: NewCargo[]) => {
    const { driverDetails, driverDetailsEqual, vehiclePlateNumber, vehiclePlateNumberEqual } = values;

    const results = cargoItems.map((cargo) => {
      return performMutation({
        mutationFn: () =>
          updateCollection({
            variables: {
              input: {
                zencargoReference,
                cargoId: cargo.id!,
                driverDetails: driverDetailsEqual ? driverDetails : undefined,
                vehiclePlateNumber: vehiclePlateNumberEqual ? vehiclePlateNumber : undefined
              }
            }
          }),
        onError: () => addError()
      });
    });

    return Promise.all(results);
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'values' implicitly has an 'any' type.
  const handleSubmit = async (values): Promise<IOkOrErrorResult> => {
    const { deliveryDate, deliveryReference, driverDetails, showTimedDelivery, vehiclePlateNumber, reason, description } = values;

    if (reason) {
      performMutation({
        mutationFn: () =>
          addDelayReason({
            variables: {
              zencargoReference,
              milestoneName: VoyageMilestoneNameEnum.DELIVERED,
              delay: { reason, description: description || '' }
            }
          }),
        onError: addError
      });
    }

    const schedule: RequestedSchedule = {
      deliveryDate: moment(deliveryDate).format(FORMAT_DATE_TRANSFERABLE),
      deliveryReference,
      deliveryTo: values.deliveryTo && { locationId: values.deliveryTo.id! },
      driverDetails,
      vehiclePlateNumber,
      ...(showTimedDelivery && values.deliveryTime && { deliveryTime: values.deliveryTime })
    };

    await handleScheduleDelivery(schedule);

    if (shouldSynchroniseRoadDetails(values)) {
      await synchroniseRoadDetails(values, cargoList);
    }

    return Promise.resolve({ ok: true, error: null });
  };

  const renderFormButtons = () => <ScheduleActionFormButtons isSubmitting={loading} onCancel={onCancel} />;

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  const riskStrategy = new DeliveryRisk({ estimatedArrival, freeTimeEnds });
  const canManageDeliveryLocation = cargoList.every((cargo) => checkPermission<NewCargo>(cargo, 'canManageDeliveryLocation'));

  return (
    <div>
      <SchedulerHeader
        detentionDate={detentionBegins()}
        detentionFreeTime={detentionFreeTime}
        freeTime={freeTimeEnds}
        hideTitle={hideFormTitle}
        schedule={ScheduleType.DELIVERY}
        scheduleDate={estimatedArrival}
      />
      <Form
        enableReinitialize={true}
        formButtons={renderFormButtons}
        formName="ScheduleDeliveryForm"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        validationSchema={validationSchema}
      >
        {(form) => {
          return (
            <div>
              <NetworksContactFormInput
                accountUuid={accountUuid}
                assignmentType={AssignmentTypeValue.COLLECTION_LOCATION}
                domainName={AssignmentTargetTypeEnum.BOOKING_CARGO}
                formTitle="Add location"
                isDisabled={!canManageDeliveryLocation}
                label="Delivery to"
                name="deliveryTo"
              />
              <div>
                <FormInput className="w-2/3" name="deliveryReference" />
              </div>
              <FormDatePicker
                disabledDayTooltip={getDateValidationMessage(minDate, maxDate)}
                helperText={getRangeValidationMessage(!isValid)}
                label="Delivery date and time"
                maxDate={maxDate}
                minDate={minDate}
                name="deliveryDate"
              />
              <div className="text-red-base align-middle font-bold text-sm" data-testid="date-risk-warning">
                {riskStrategy.dateRisk(form.values.deliveryDate)}
              </div>
              <div className="mt-6">
                <FormCheckbox label="I would like a timed delivery" name="showTimedDelivery" />
                {form.values.showTimedDelivery && (
                  <FormTimeConsigment name="deliveryTime" noMargin={true} riskStrategy={riskStrategy} />
                )}
              </div>
              {isRoadShipment(modeOfTransport) && <ScheduleRoadFields isBulkUpdateEnabled={collectionScheduleRequired} />}
              {allowDelayReasonEntry && <FormMilestoneDelayReasonFields />}
            </div>
          );
        }}
      </Form>
    </div>
  );
};

export default ScheduleDeliveryForm;
