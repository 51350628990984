import type { ReactNode } from 'react';
import { useLocation } from 'react-router';

import type { ContextMenuState } from '@zen/DesignSystem';
import { Button, ContextMenu, MenuItemType } from '@zen/DesignSystem';
import { newBookingRoutes } from '@zen/Routes';

import ChevronIcon from '../Icons/ChevronIcon';
import { getOrdersUrl } from './bookingButtonHelper';

const BookingButton = () => {
  const location = useLocation();

  const menuItems: MenuItemType[] = [
    {
      label: 'Book a PO',
      linkTo: getOrdersUrl(location)
    },
    {
      label: 'Book without PO',
      linkTo: newBookingRoutes.newBookingIndex.getUrl()
    }
  ];

  return (
    <ContextMenu items={menuItems}>
      {({ isExpanded }: ContextMenuState): ReactNode => (
        <Button
          className="focus:outline-none group"
          data-testid="booking-button"
          iconRight={
            <ChevronIcon className="text-xl group-hover:text-white group-active:text-white" expanded={isExpanded} size="small" />
          }
          variant="secondary"
        >
          New booking
        </Button>
      )}
    </ContextMenu>
  );
};

export default BookingButton;
