import type { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import { getPreviousUrl } from '@zen/utils/routing';
import Routing from '@zen/utils/Routing/index';

import { useProductDetailsQuery, useProductsUpdateProductMutation } from '../graphql';
import ProductForm, { prepareInitialProductValues } from '../ProductForm';
import type { InitialProductFormValues, ProductFormType } from '../ProductForm/types';
import type { ProductDetails } from '../types';

const EditProduct: FC = () => {
  const { id: productId } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { data, loading, error } = useProductDetailsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: productId
    }
  });

  const { addSuccess } = useNotification();
  const location = useLocation();
  const [updateProduct] = useProductsUpdateProductMutation();
  const previousUrl = getPreviousUrl(location);

  const backUrl = previousUrl || `/dashboard/products/${productId}`;

  const handleSuccess = () => {
    if (!previousUrl) {
      addSuccess('Product has been saved.');
    }

    const url = previousUrl || Routing.showProductPath(productId);

    push(url);
  };

  const onSubmit = ({ archived, perUnitCbm, ...rest }: ProductFormType): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () => updateProduct({ variables: { input: { id: productId, product: rest } } }),
      onError: () => {}
    });
  };

  return (
    <Page defaultBackUrl={backUrl} title="Edit product" width="narrow">
      <QueryHandler data={data?.products.nodes?.[0]} error={!!error} isLoading={loading}>
        {(productDetails: ProductDetails) => {
          const product: InitialProductFormValues = prepareInitialProductValues(productDetails);

          return <ProductForm initialValues={product} onSubmit={onSubmit} onSuccess={handleSuccess} />;
        }}
      </QueryHandler>
    </Page>
  );
};

export default EditProduct;
