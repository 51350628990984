import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CustomersQueryVariables = GraphQLTypes.Exact<{
  searchContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type CustomersQueryResult = { __typename?: 'Query' } & {
  customers?: GraphQLTypes.Maybe<
    { __typename?: 'CustomersCustomerTypeConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'CustomersCustomerType' } & Pick<
              GraphQLTypes.CustomersCustomerType,
              'name' | 'uuid' | 'isParent' | 'isDivision'
            > & { division?: GraphQLTypes.Maybe<{ __typename?: 'Division' } & Pick<GraphQLTypes.Division, 'name'>> }
          >
        >
      >;
    }
  >;
};

export const CustomersQueryDocument = /* #__PURE__ */ gql`
  query customersQuery($searchContains: String) {
    customers(searchContains: $searchContains, paginated: false) {
      nodes {
        name
        uuid
        isParent
        isDivision
        division {
          name
        }
      }
    }
  }
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      searchContains: // value for 'searchContains'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQueryResult, CustomersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CustomersQueryResult, CustomersQueryVariables>(CustomersQueryDocument, options);
}
export function useCustomersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomersQueryResult, CustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CustomersQueryResult, CustomersQueryVariables>(CustomersQueryDocument, options);
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersQueryLazyQueryHookResult = ReturnType<typeof useCustomersQueryLazyQuery>;
