import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  children: ReactNode;
  icon?: IconName;
  isActive: boolean;
  label: string;
  onClick: () => void;
}

const NavItem: FC<Props> = ({ children, icon, isActive, label, onClick }) => {
  const classNames: string = cx(
    {
      'text-azure-base': isActive,
      'hover:text-azure-base': !isActive
    },
    'text-navy-base text-base xl:text-sm transition-all duration-200 ease-linear leading-normal',
    'flex items-center py-1.5 pl-6 cursor-pointer'
  );

  const activeIndicatorClassNames: string = cx(
    { 'bg-azure-base': isActive, 'bg-transparent': !isActive },
    'w-1 h-6 rounded-l transition-all duration-200 ease-linear'
  );

  return (
    <div className={classNames} data-component="nav-item" data-testid="nav-item" onClick={onClick}>
      {icon && <Icon className="text-sm xl:text-base mr-2" icon={icon} />}
      <span>{label}</span>
      <span className="ml-auto flex">{children}</span>
      <div className={activeIndicatorClassNames} />
    </div>
  );
};

export default NavItem;
