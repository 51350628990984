import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RequireCollectionScheduleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.RequireCollectionScheduleInput;
}>;

export type RequireCollectionScheduleMutationResult = { __typename?: 'Mutation' } & {
  bookingCollectionAndDeliveryRequireCollectionSchedule?: GraphQLTypes.Maybe<
    { __typename?: 'RequireCollectionSchedulePayload' } & Pick<GraphQLTypes.RequireCollectionSchedulePayload, 'clientMutationId'>
  >;
};

export const RequireCollectionScheduleDocument = /* #__PURE__ */ gql`
  mutation requireCollectionSchedule($input: RequireCollectionScheduleInput!) {
    bookingCollectionAndDeliveryRequireCollectionSchedule(input: $input) {
      clientMutationId
    }
  }
`;

/**
 * __useRequireCollectionScheduleMutation__
 *
 * To run a mutation, you first call `useRequireCollectionScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequireCollectionScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requireCollectionScheduleMutation, { data, loading, error }] = useRequireCollectionScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequireCollectionScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<RequireCollectionScheduleMutationResult, RequireCollectionScheduleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RequireCollectionScheduleMutationResult, RequireCollectionScheduleMutationVariables>(
    RequireCollectionScheduleDocument,
    options
  );
}
export type RequireCollectionScheduleMutationHookResult = ReturnType<typeof useRequireCollectionScheduleMutation>;
