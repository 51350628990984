import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import useTracking from '@zen/utils/hooks/useTracking';

import type { ColumnWidthMap } from '../helpers';
import { TableConfigurationTrackingAction, TableConfigurationTrackingCategory } from '../types';

export const useColumnWidths = (tableId: string): [ColumnWidthMap, (columnWidths: ColumnWidthMap) => void] => {
  const [columnWidths, saveColumnWidths] = useLocalStorage<Record<string, ColumnWidthMap>>('table-column-widths', {});
  const { trackEvent } = useTracking();

  const setColumnWidths = useCallback(
    (newWidths: ColumnWidthMap) => {
      trackEvent({
        category: TableConfigurationTrackingCategory,
        action: TableConfigurationTrackingAction.RESIZE,
        label: tableId
      });

      saveColumnWidths({
        ...columnWidths,
        [tableId]: newWidths
      });
    },
    [columnWidths, saveColumnWidths, tableId, trackEvent]
  );

  const widths: ColumnWidthMap = columnWidths?.[tableId] || {};

  return [widths, setColumnWidths];
};
