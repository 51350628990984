import { isEmpty, isEqual } from 'lodash';
import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import QuickFilters from '@zen/Components/QuickFilters';
import QuickFiltersAddRemoveButton from '@zen/Components/QuickFilters/QuickFiltersAddRemoveButton';
import type { TableColumn } from '@zen/DesignSystem';
import { useTableConfiguration } from '@zen/DesignSystem';
import type { ShipmentExportDataInput } from '@zen/graphql/types.generated';
import { emptyFilters, prepareFilterVariables } from '@zen/Shipments/Filters/helpers';
import { getColumns } from '@zen/Shipments/TableView/tableConfiguration';
import { useSavedFilters } from '@zen/utils/Filtering/contexts/SavedFiltersContext';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import ClearAllFiltersButton from '../ClearAllFiltersButton/ClearAllFiltersButton';
import type { FlatShipment, ShipmentFilters, ShipmentsListCapabilities } from '../types';
import ShipmentsDataExportButton from './ShipmentsDataExportButton';

interface Props {
  capabilities: ShipmentsListCapabilities;
}

const ShipmentsDataHeader: FC<Props> = ({ capabilities }) => {
  const { filters, setFilters } = useUrlFilters<ShipmentFilters>();
  const { savedFilters } = useSavedFilters();
  const { hiddenColumns, setHiddenColumns } = useTableConfiguration();
  const { check } = useGlobalPermissions();
  const canExportData = check('bookings.canExport');
  const canSetPreferences = check('settings.canSetPreferences');

  const columnsKeys: TableColumn<FlatShipment>[] = [...getColumns({ capabilities })];

  const areAppliedFiltersNonEmpty = !isEqual(filters, emptyFilters);

  const handleClear = (): void => setFilters({});

  return (
    <div className="flex justify-between mb-4">
      <div className="mb-1">
        <div className="mb-6">
          {areAppliedFiltersNonEmpty && canSetPreferences && (
            <>
              <QuickFiltersAddRemoveButton filters={filters} />
              <ClearAllFiltersButton onClear={handleClear} />
            </>
          )}
        </div>
        {!isEmpty(savedFilters) && canSetPreferences && (
          <div>
            <span className="mr-6">Saved filters:</span>
            <QuickFilters filters={filters} setFilters={setFilters} />
          </div>
        )}
      </div>
      <div className="shrink-0">
        <div className="flex justify-center items-center">
          <CustomiseTableButton
            columns={getColumns()}
            defaultFilterValue="references"
            hiddenColumns={hiddenColumns}
            onChange={setHiddenColumns}
          />
          {canExportData && (
            <ShipmentsDataExportButton<FlatShipment>
              columns={columnsKeys}
              dataFilters={prepareFilterVariables(filters) as ShipmentExportDataInput['dataFilters']}
              hiddenColumns={hiddenColumns}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShipmentsDataHeader;
