import { isEmpty, omit, omitBy } from 'lodash';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Route } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Pagination, Table, useTableConfiguration } from '@zen/DesignSystem';
import type { FiltersType } from '@zen/OperationsDashboard/context/OperationsContextProvider/OperationsContextProvider';
import useOperationsPersistedFilters from '@zen/OperationsDashboard/Filters/FiltersForm/hooks/useOperationsPersistedFilters';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import { ShipmentFilterStageWithin } from '@zen/Shipments';
import { countFilters } from '@zen/Shipments/Filters/helpers';
import { PageSize, SortingOrder, SortInput } from '@zen/types';
import { useUrlPagination } from '@zen/utils/hooks/pagination';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import useTracking from '@zen/utils/hooks/useTracking';

import { prepareFilterVariables } from '../components/FilterFields/helpers';
import Toolbar from '../components/Toolbar';
import {
  GetOperationsRoadShipmentsQueryResult,
  GetOperationsRoadShipmentsQueryVariables,
  useGetOperationsRoadShipmentsQuery,
  useGetOperationsRoadShipmentsTotalCountQuery
} from '../graphql';
import { OPS_SHIPMENTS_PAGE_SIZE_KEY } from '../OperationsShipments';
import type { OperationsShipmentsFiltersType } from '../types';
import { prepareShipments } from './helpers';
import { getColumnsConfiguration } from './roadShipmentsTableConfiguration';
import type { RoadShipment, RoadShipmentExtended, RoadShipmentQueryVariables } from './types';
import { RoadShipmentTrackingAction, RoadShipmentTrackingCategory } from './types';

const initialOrder: SortInput = {
  field: 'zencargoReference',
  direction: SortingOrder.ASC
};

const RoadShipments: FC = () => {
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [pageSize, setPageSize] = useLocalStorage(OPS_SHIPMENTS_PAGE_SIZE_KEY, PageSize.TWENTY);
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();
  const { filters, search } = useOperationsPersistedFilters<FiltersType<OperationsShipmentsFiltersType>>();
  const { pathname } = useLocation();
  const { trackEvent } = useTracking();
  const { push } = useHistory();
  const match = useRouteMatch();
  const filtersCount: number = countFilters(omit(filters, 'transportModes'));
  const { hiddenColumns } = useTableConfiguration();

  const initialRender = useRef(true);

  const roadShipmentsQueryVariables: RoadShipmentQueryVariables = {
    searchQuery: search,
    newCargoService: !!isNewCargoServiceEnabled,
    ...prepareFilterVariables(filters)
  };

  const { loading, error, nodes, paginationInfo } = useUrlPagination<
    GetOperationsRoadShipmentsQueryResult,
    GetOperationsRoadShipmentsQueryVariables,
    RoadShipment
  >(
    useGetOperationsRoadShipmentsQuery,
    'bookings',
    { order, ...roadShipmentsQueryVariables },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' },
    pageSize
  );

  const { data: totalCountData, loading: isLoadingTotalCount } = useGetOperationsRoadShipmentsTotalCountQuery({
    variables: roadShipmentsQueryVariables,
    fetchPolicy: 'cache-and-network'
  });

  const trackEditedCell = (zencargoReference: string, columnName: string): void => {
    trackEvent({
      action: RoadShipmentTrackingAction.ROAD_SHIPMENT_CELL_EDITED,
      category: RoadShipmentTrackingCategory,
      label: columnName,
      properties: {
        columnName,
        zencargoReference
      }
    });
  };

  const columns = getColumnsConfiguration({ pathname, navigate: push, onCellEdit: trackEditedCell });

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;

      return;
    }
    const { withinTimeRange, withinTimeDate, withinTimeDays, ...rest } = filters;

    const appliedFiltersForTracking: Partial<OperationsShipmentsFiltersType> = {
      ...rest,
      withinTimeDays,
      withinTimeRange: withinTimeDays === ShipmentFilterStageWithin.DATE_RANGE ? withinTimeRange : undefined,
      withinTimeDate: withinTimeDays === ShipmentFilterStageWithin.SPECIFIC_DATE ? withinTimeDate : undefined
    };

    const appliedFiltersWithValues = omitBy(appliedFiltersForTracking, isEmpty);

    trackEvent({
      action: RoadShipmentTrackingAction.ROAD_SHIPMENT_FILTERS_APPLIED,
      category: RoadShipmentTrackingCategory,
      label: 'RoadShipmentTable',
      properties: {
        // BigQuery handles nested objects by creating a column dynamically for each eventual key-value
        // so we send the names of the appliedFilters separately to save on lots of complicated SQL
        nameOfAppliedFilters: Object.keys(appliedFiltersWithValues),
        appliedFilters: appliedFiltersWithValues
      }
    });
  }, [filters, trackEvent]);

  return (
    <Page tagline="Road shipments that contain a single cargo item." title="Road shipments">
      <Toolbar<RoadShipmentExtended>
        columns={columns}
        filtersCount={filtersCount}
        onPageSizeChange={setPageSize}
        pageSize={pageSize}
      />
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={
          <SkeletonTableLoading<RoadShipmentExtended> columns={columns} order={order} tableId="operationsRoadShipments" />
        }
      >
        {(shipments: RoadShipment[]): ReactElement => (
          <>
            <Table<RoadShipmentExtended>
              columns={columns}
              data={prepareShipments(shipments)}
              hiddenColumns={hiddenColumns}
              onOrderChange={setOrder}
              order={order}
              tableId="operationsRoadShipments"
              totalCountConfig={{
                entityName: 'shipment',
                isLoading: !totalCountData && isLoadingTotalCount,
                totalCount: totalCountData?.bookings?.totalCount || 0
              }}
            />
            <Pagination pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
      <Route path={`${match?.path}/:id`}>
        <OrderDetailsContainer originPath={match?.url} />
      </Route>
    </Page>
  );
};

export default RoadShipments;
