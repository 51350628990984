import { FC, useState } from 'react';

import Slideout from '@zen/Components/Slideout';
import { Headline, IconButton } from '@zen/DesignSystem';
import type { NetworksOrgLocOption } from '@zen/Networks';
import EditLocation from '@zen/Networks/EditLocation';
import type { OrganisationLocationResponse } from '@zen/Networks/Form/EditLocationForm';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  locationId: string;
  onEdit: (data: NetworksOrgLocOption) => void;
  organisationId: string;
}

const NetworksContactEditButton: FC<Props> = ({ locationId, onEdit, organisationId }) => {
  const { addSuccess } = useNotification();
  const [isSlideoutVisible, setIsSlideoutVisible] = useState<boolean>(false);

  const handleCancel = (): void => setIsSlideoutVisible(false);

  const handleSuccess = ({ location, orgLoc }: OrganisationLocationResponse): void => {
    addSuccess('Location has been updated');
    setIsSlideoutVisible(false);

    onEdit({ ...orgLoc, geolocation: location?.geolocation });
  };

  return (
    <>
      <IconButton className="mt-6 mr-2" icon="zicon-edit" onClick={() => setIsSlideoutVisible(true)} variant="secondary" />
      <Slideout onOutsideClick={() => setIsSlideoutVisible(false)} overlay={true} show={isSlideoutVisible}>
        <div className="py-4 px-6 min-w-160 h-screen overflow-y-scroll">
          <Headline className="mb-6" level={2}>
            Edit Location
          </Headline>
          <EditLocation
            locationId={locationId}
            onCancel={handleCancel}
            onSuccess={handleSuccess}
            organisationId={organisationId}
          />
        </div>
      </Slideout>
    </>
  );
};

export default NetworksContactEditButton;
