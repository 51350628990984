import type { FC, ReactNode } from 'react';

import { Pill } from '@zen/DesignSystem';
import type { IconType } from '@zen/types';

interface Props {
  hazardous?: boolean;
  overweight?: boolean;
  reefer?: boolean;
  stackable?: boolean;
  tailLift?: boolean;
}

const CargoOptions: FC<Props> = ({ hazardous, overweight, reefer, stackable, tailLift }) => {
  const renderPill = (icon: IconType, label: string): ReactNode => {
    return (
      <Pill className="ml-2" iconLeft={icon} type="pending">
        {label}
      </Pill>
    );
  };

  return (
    <div className="flex">
      {hazardous && renderPill('zicon-hazardous', 'Hazardous')}
      {tailLift && renderPill('zicon-tail-lift', 'Tail lift')}
      {overweight && renderPill('zicon-overweight', 'Overweight')}
      {reefer && renderPill('zicon-refrigerated', 'Refrigerated')}
      {stackable && renderPill('zicon-stackable', 'Stackable')}
    </div>
  );
};

export type { Props as CargoOptionsType };

export default CargoOptions;
