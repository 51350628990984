import { createContext, useContext } from 'react';

import type { Account } from '@zen/utils/hooks/useAccounts';

import type { BookingManager } from '../../types';

interface OperationsContextData {
  accounts: Account[];
  bookingCoordinators: BookingManager[];
  closeFilters: () => void;
  openFilters: () => void;
}

const initialValues: OperationsContextData = {
  accounts: [],
  bookingCoordinators: [],
  openFilters: () => {},
  closeFilters: () => {}
};

const OperationsContext = createContext<OperationsContextData>(initialValues);

export const useOperationsContext = () => useContext(OperationsContext);

export default OperationsContext;
