import classnames from 'classnames';
import type { FC, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@zen/DesignSystem';
import type { IconType, LocationDescriptor } from '@zen/types';

interface Props {
  actions?: ReactNode;
  children?: ReactNode;
  className?: string;
  leftIcon?: IconType;
  linkTo?: LocationDescriptor;
  onClick?: () => void;
  rightIcon?: IconType;
  title?: ReactNode;
}

const Card: FC<Props> = (props) => {
  const { actions, rightIcon, leftIcon, className, children, linkTo, onClick, title, ...rest } = props;

  const classNames: string = classnames(
    {
      'hover:border-azure-base active:bg-azure-lighter cursor-pointer': onClick || linkTo
    },
    'flex justify-between min-w-0 p-4 space-x-4 rounded border border-grey-lighter border-solid transition-all duration-150 ease-linear',
    className
  );

  const renderCardIcon = (icon: IconType): ReactNode => {
    return typeof icon === 'string' ? <Icon className="text-grey-base text-2xl" icon={icon} /> : icon;
  };

  const renderContent = (): ReactNode => {
    return (
      <>
        {leftIcon && renderCardIcon(leftIcon)}
        <div className="flex flex-1 min-w-0 space-x-4 flex-wrap">
          <div className="flex-1 min-w-0 text-grey-dark leading-5 text-sm">
            {title && (
              <div className="font-bold truncate min-w-0 text-grey-dark mb-1" data-testid="card-title">
                {title}
              </div>
            )}
            {children}
          </div>
          {actions}
        </div>
        {rightIcon && renderCardIcon(rightIcon)}
      </>
    );
  };

  const renderLink = (url: LocationDescriptor): ReactElement => {
    return (
      <Link className={classNames} onClick={onClick} to={url} {...rest}>
        {renderContent()}
      </Link>
    );
  };

  const renderWrapper = (): ReactElement => {
    return (
      <div className={classNames} onClick={onClick} {...rest}>
        {renderContent()}
      </div>
    );
  };

  return linkTo ? renderLink(linkTo) : renderWrapper();
};

export type { Props as CardProps };

export default Card;
