import { useContext } from 'react';
import { useRouteMatch } from 'react-router';

import type { LocationDescriptorObject } from '@zen/types';

import NavigationHistoryContext from './NavigationHistoryContext';

const useNavigationHistory = () => {
  const { url } = useRouteMatch();
  const { navigateBack: goBack, navigate } = useContext(NavigationHistoryContext);

  const navigateBack = (
    defaultUrl: string,
    modifyTargetLocation?: (location: LocationDescriptorObject) => LocationDescriptorObject
  ) => {
    goBack(url, defaultUrl, modifyTargetLocation);
  };

  return { navigate, navigateBack };
};

export default useNavigationHistory;
