import type { Booking } from '@zen/Booking/types';
import { PageSize, SortInput } from '@zen/types';
import { useUrlPagination } from '@zen/utils/hooks/pagination';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { prepareFilterVariables } from '../FiltersForm/helpers';
import type { CustomerAdminShipmentFilters } from '../FiltersForm/types';
import {
  CustomerAdminAllShipmentsQueryResult,
  CustomerAdminAllShipmentsQueryVariables,
  useCustomerAdminAllShipmentsQuery
} from '../graphql';

interface Params {
  order: SortInput;
}

export const useAllShipments = ({ order }: Params) => {
  const { filters, search } = useUrlFilters<CustomerAdminShipmentFilters>();

  return useUrlPagination<CustomerAdminAllShipmentsQueryResult, CustomerAdminAllShipmentsQueryVariables, Booking>(
    useCustomerAdminAllShipmentsQuery,
    'bookings',
    {
      searchQuery: search,
      ...prepareFilterVariables(filters),
      order
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' },
    PageSize.TWENTY
  );
};
