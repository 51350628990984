import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateVehicleCargoItemMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateVehicleCargoItemInput;
}>;

export type UpdateVehicleCargoItemMutationResult = { __typename?: 'Mutation' } & {
  cargoUpdateVehicleCargoItem?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateVehicleCargoItemPayload' } & {
      cargoItem?: GraphQLTypes.Maybe<
        | ({ __typename?: 'CargoContainerType' } & Pick<
            GraphQLTypes.CargoContainerType,
            'id' | 'type' | 'cbm' | 'hazardous' | 'reefer' | 'looseCargoType' | 'quantity'
          > & {
              grossWeight?: GraphQLTypes.Maybe<
                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
              >;
            })
        | ({ __typename?: 'CargoLooseCargoType' } & Pick<
            GraphQLTypes.CargoLooseCargoType,
            'id' | 'type' | 'cbm' | 'hazardous' | 'reefer' | 'looseCargoType' | 'quantity'
          > & {
              grossWeight?: GraphQLTypes.Maybe<
                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
              >;
            })
        | ({ __typename?: 'CargoVehicleType' } & Pick<
            GraphQLTypes.CargoVehicleType,
            | 'vehicleType'
            | 'palletType'
            | 'riskLevel'
            | 'tailLift'
            | 'trailerId'
            | 'id'
            | 'type'
            | 'cbm'
            | 'hazardous'
            | 'reefer'
            | 'looseCargoType'
            | 'quantity'
          > & {
              dimensions?: GraphQLTypes.Maybe<
                { __typename?: 'CargoDimensionsType' } & Pick<
                  GraphQLTypes.CargoDimensionsType,
                  'length' | 'width' | 'height' | 'metric'
                >
              >;
              valueOfGoods?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>;
              grossWeight?: GraphQLTypes.Maybe<
                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
              >;
            })
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateVehicleCargoItemDocument = /* #__PURE__ */ gql`
  mutation updateVehicleCargoItem($input: UpdateVehicleCargoItemInput!) {
    cargoUpdateVehicleCargoItem(input: $input) {
      cargoItem {
        id
        type
        cbm
        hazardous
        reefer
        looseCargoType
        quantity
        grossWeight {
          metric
          value
        }
        ... on CargoVehicleType {
          vehicleType
          dimensions {
            length
            width
            height
            metric
          }
          palletType
          riskLevel
          tailLift
          valueOfGoods {
            currency
            value
          }
          trailerId
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateVehicleCargoItemMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleCargoItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleCargoItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleCargoItemMutation, { data, loading, error }] = useUpdateVehicleCargoItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleCargoItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVehicleCargoItemMutationResult, UpdateVehicleCargoItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateVehicleCargoItemMutationResult, UpdateVehicleCargoItemMutationVariables>(
    UpdateVehicleCargoItemDocument,
    options
  );
}
export type UpdateVehicleCargoItemMutationHookResult = ReturnType<typeof useUpdateVehicleCargoItemMutation>;
