import type { FC } from 'react';

import QuickFiltersActionsButton from '@zen/Components/QuickFilters/QuickFiltersActionsButton';
import { useHover } from '@zen/utils/hooks/useHover';

interface Props {
  onClear: () => void;
}

const ClearAllFiltersButton: FC<Props> = ({ onClear }) => {
  const [ref, isHovered] = useHover();

  return (
    <span ref={ref} className="inline-block">
      <QuickFiltersActionsButton isHovered={isHovered} label="Clear current filters" onClick={onClear} />
    </span>
  );
};

export default ClearAllFiltersButton;
