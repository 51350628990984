import cx from 'classnames';
import { FC, useContext } from 'react';
import { useLocation } from 'react-router';

import { Button, Checkbox } from '@zen/DesignSystem';
import CargoReadyDateHistory from '@zen/Orders/OrderDetails/LineItems/CargoReadyDateHistory';
import UpdateCRDContext from '@zen/Orders/OrderDetails/updateCRDContext';
import type { OrderLot, OrderRichDate, ProgressStatus } from '@zen/Orders/types';
import { shipmentRoutes } from '@zen/Routes';
import { formatNumber } from '@zen/utils/formatting';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import Timeline from './Timeline';

export interface LotPayload {
  cargoReadyDate: OrderRichDate;
  id: string;
  stages: ProgressStatus[];
}

interface Props {
  index: number;
  lot: OrderLot;
  lotCount: number;
  onUpdateSchedule: (lot: LotPayload) => void;
  quantityOrdered: number;
}

const Lot: FC<Props> = (props) => {
  const { pathname } = useLocation();
  const { navigate } = useNavigationHistory();
  const { index, quantityOrdered, lot, lotCount, onUpdateSchedule } = props;
  const { displayMask, selectLot, isSelected } = useContext(UpdateCRDContext);
  const { cargoReadyDate, cbm, id, booking, stages, purchaseOrder } = lot;
  // this needs to be fixed on the backend as the there are no lots without purchase orders.
  // Path prefix removes the order id from the url and gives the result with only booking domain urls attached
  const pathPrefix: string = purchaseOrder ? pathname.split(purchaseOrder.id)[0] : '';
  const quantityFulfilled: number = lot.quantityFulfilled || 0;
  const crdHistoryClassNames: string = cx('flex justify-end', {
    'pt-6 border-t border-solid border-grey-lighter': !!cargoReadyDate
  });
  const customLotClassNames: string = cx('flex-1 pt-8 pb-4 px-6', {
    'border-grey-light border-solid border-t': index > 1
  });

  const handleUpdateSchedule = () => {
    // this needs be fixed on the backend as there are no lots without stages.
    if (stages) {
      onUpdateSchedule({ id, cargoReadyDate, stages });
    }
  };

  const handleViewShipment = (zencargoReference: string): void => {
    // Products should be redirected to shipments
    const path: string = pathPrefix.includes('products')
      ? shipmentRoutes.shipmentDetailsPage.getUrl(zencargoReference)
      : `${pathPrefix}details/${zencargoReference}`;

    navigate(path);
  };

  return (
    <div className="bg-grey-lightest flex items-center">
      <div className={customLotClassNames}>
        <div className="flex -ml-6 mb-6">
          <div className="flex items-center justify-center w-16">
            {displayMask && <Checkbox checked={isSelected(id)} onChange={() => selectLot(id)} />}
          </div>
          <div className="mr-2">
            <p className="text-grey-dark mb-2 font-bold">
              {formatNumber(quantityFulfilled)}/{formatNumber(quantityOrdered)}
            </p>
            <p className="mb-2">{cbm && `${formatNumber(cbm)} CBM`}</p>
            <p className="mb-2">
              Lot {index}/{lotCount}
            </p>
          </div>
          <div className="flex-1 flex justify-end">
            {booking && (
              <Button
                className="mr-2"
                disabled={displayMask}
                onClick={() => handleViewShipment(booking.zencargoReference)}
                variant="secondary"
              >
                View shipment
              </Button>
            )}
            <Button className="fs-ready-date-open" disabled={displayMask} onClick={handleUpdateSchedule} variant="secondary">
              Update schedule
            </Button>
          </div>
        </div>

        <div className="flex mb-6">
          <Timeline booking={booking} stages={stages || []} />
        </div>
        <div className={crdHistoryClassNames}>
          <CargoReadyDateHistory cargoReadyDate={cargoReadyDate} />
        </div>
      </div>
    </div>
  );
};

export default Lot;
