import { FC, ReactNode, useState } from 'react';

import { useForm } from '@zen/Components/Form/useForm';
import { Button, Dialog } from '@zen/DesignSystem';

import { getEmptyCollectionDates, getEmptyDeliveryDates } from '../../helpers';
import { FormSections, RoadShipmentFields, ScheduleAction } from '../../types';
import CollectedOn from '../Collection/CollectedOn';
import DeliveredOn from '../Delivery/DeliveredOn';
import ForwarderScheduler from '../ForwarderScheduler';
import Section from '../Section';
import { hasData } from './helpers';

const CollectionAndDeliveryDateFields: FC = () => {
  const { setFieldValue, values } = useForm<RoadShipmentFields>();
  const [visibleModal, setVisibleModal] = useState<boolean>(false);

  const { collection, delivery } = values;

  const handleClearAllData = async (): Promise<void> => {
    setFieldValue('collection', {
      ...getEmptyCollectionDates(),
      clearSchedule: true
    });

    setFieldValue('delivery', {
      ...getEmptyDeliveryDates(),
      clearSchedule: true
    });

    setVisibleModal(false);
  };

  const renderClearAllDataButton = (): ReactNode => {
    const isButtonDisabled: boolean = !hasData(collection) && !hasData(delivery);

    return (
      <Button
        data-testid="clear-all-data-button"
        disabled={isButtonDisabled}
        onClick={() => setVisibleModal(true)}
        variant="danger"
      >
        Clear all data
      </Button>
    );
  };

  return (
    <div data-testid="collection-and-delivery-date-fields">
      <Section
        actionButton={renderClearAllDataButton()}
        data-testid="booking-details"
        id={FormSections.BOOKING_DETAILS}
        title="Booking details"
      >
        <ForwarderScheduler scheduleAction={ScheduleAction.REQUEST_SCHEDULE} />
        <ForwarderScheduler scheduleAction={ScheduleAction.CONFIRM_SCHEDULE} />
      </Section>
      <Section data-testid="route-progress" title="Route progress">
        <CollectedOn />
        <DeliveredOn />
      </Section>
      <Dialog
        buttonVariant="danger"
        cancelLabel="Cancel"
        confirmLabel="Clear all data"
        header="Clear all data"
        isOpen={visibleModal}
        message="Are you sure you want to clear all data? Doing this will delete all dates and times added to this sections below."
        onClose={() => setVisibleModal(false)}
        onConfirm={handleClearAllData}
      />
    </div>
  );
};

export default CollectionAndDeliveryDateFields;
