import { gql } from '@apollo/client';

import type * as GraphQLTypes from '../types.generated';

export type DateTimeRangeFragment = { __typename: 'LocalDateTimeRange' } & {
  startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
  endDateTime?: GraphQLTypes.Maybe<{ __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>>;
};

export const DateTimeRangeFragmentDoc = /* #__PURE__ */ gql`
  fragment DateTimeRange on LocalDateTimeRange {
    __typename
    startDateTime {
      date
      time
    }
    endDateTime {
      date
      time
    }
  }
`;
