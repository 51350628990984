import { FC, ReactNode, useState } from 'react';

import type { MenuItemType } from '@zen/DesignSystem';
import { ContextMenu } from '@zen/DesignSystem';
import { useMilestoneDateValidation } from '@zen/Shipment/ShipmentDetailsPage/MilestoneDateProvider';
import { useHover } from '@zen/utils/hooks/useHover';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional } from '@zen/utils/typescript';

import StageUpdateWrapper from '../../components/StageUpdateWrapper';
import BookingStageAction from '../components/BookingStageAction';
import BookingStageCard from '../components/BookingStageCard';
import { BookingStage, BookingStageEnum } from '../types';
import StageUpdateModal from './StageUpdateModal';
import type { StageUpdateModalType } from './StageUpdateModal/type';

interface Props {
  canConfirmStage: boolean;
  canEditStage: boolean;
  onUpdateStage: (stageName: BookingStageEnum, occurredAt?: Optional<string>) => Promise<IOkOrErrorResult>;
  stage: BookingStage;
}

const BookingConfirmedStage: FC<Props> = ({ canConfirmStage, stage, onUpdateStage, canEditStage }) => {
  const [modalType, setModalType] = useState<StageUpdateModalType | undefined>(undefined);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [ref, isHovered] = useHover<HTMLDivElement>();
  const { maxDate } = useMilestoneDateValidation(stage.name, true);

  const setInactive = (): void => setIsActive(false);

  const setActive = (): void => setIsActive(true);

  const closeModal = (): void => {
    setModalType(undefined);
    setInactive();
  };

  const handleRemoveConfirmation = async (): Promise<void> => {
    onUpdateStage(BookingStageEnum.BOOKING_RECEIVED);

    closeModal();
  };

  const renderContextMenu = (): ReactNode => {
    if (!canEditStage) {
      return null;
    }

    const contextMenuItems: MenuItemType[] = [
      { label: 'Update milestone', icon: 'zicon-edit', onClick: () => setModalType('edit') },
      { label: 'Remove confirmation', icon: 'zicon-trash', onClick: handleRemoveConfirmation }
    ];

    return (
      <StageUpdateWrapper isActive={isActive} isHovered={isHovered}>
        <ContextMenu inline={false} items={contextMenuItems} onClose={setInactive} onOpen={setActive} />
      </StageUpdateWrapper>
    );
  };

  const renderStageComponent = (): ReactNode => {
    if (canConfirmStage) {
      return <BookingStageAction icon="zicon-add-small" label="Mark booking confirmed" onClick={() => setModalType('confirm')} />;
    }

    return (
      <div ref={ref}>
        <BookingStageCard action={renderContextMenu()} isActive={isActive} stage={stage} />
      </div>
    );
  };

  return (
    <>
      {renderStageComponent()}
      <StageUpdateModal
        initialDate={stage.startedAt?.date}
        maxDate={maxDate}
        onClose={closeModal}
        onUpdate={onUpdateStage}
        type={modalType}
      />
    </>
  );
};

export default BookingConfirmedStage;
