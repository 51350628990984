import { get } from 'lodash';
import type { ReactNode } from 'react';

import AccrualStatePill from '@zen/Accruals/AccrualStatePill';
import type { CargoWeightType } from '@zen/Cargo';
import CargoValue from '@zen/Cargo/components/CargoValue';
import { cargoModeLabelMapping, formatWeight, getNewCargoTypeLabel } from '@zen/Cargo/helpers';
import CargoSummary from '@zen/Components/CargoSummary';
import DateTimeRange from '@zen/Components/DateTimeRange';
import ShipmentReferences from '@zen/Components/ShipmentReferences';
import ShipmentStageProgress from '@zen/Components/ShipmentStageProgress/ShipmentStageProgress';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { TableColumn, TableLink } from '@zen/DesignSystem';
import IssueCounterPill from '@zen/Issues/components/IssueCounterPill';
import { accrualRoutes, opsShipmentRoutes } from '@zen/Routes';
import { TabsType } from '@zen/Shipment';
import { shipmentTypeLabelMapping, tradeRoleLabelMapping } from '@zen/Shipment/helpers';
import type { NewCargoType } from '@zen/types/labels';
import { LABEL_FOR_INCOTERMS } from '@zen/utils/constants';
import { formatDate } from '@zen/utils/dateTime';
import { getNetworkContactLabel } from '@zen/utils/networks';
import type { Optional } from '@zen/utils/typescript';

import ActionHandleContainer from '../components/ActionHandleContainer';
import { FormSections } from '../EditRoadShipment';
import { getLatestStatePrefix } from './helpers';
import type { InFull, RoadShipmentExtended } from './types';

const renderDateTimeColumn = (dateTime: Optional<InFull>): ReactNode => {
  if (!dateTime?.dateTimeRange) {
    return '-';
  }

  return <DateTimeRange dateTimeRange={dateTime.dateTimeRange} prefix={getLatestStatePrefix(dateTime.state)} />;
};

interface Params {
  navigate: (pathname: string, state?: object) => void;
  onCellEdit: (zencargoReference: string, columnName: string) => void;
  pathname: string;
}

export const getColumnsConfiguration = ({ pathname, navigate, onCellEdit }: Params): TableColumn<RoadShipmentExtended>[] => {
  const handleEditScheduleDateAndTime = (columnName: string, { zencargoReference }: RoadShipmentExtended) => {
    onCellEdit(zencargoReference, columnName);
    redirectToEditForm(zencargoReference, { autoFocusedSection: FormSections.BOOKING_DETAILS });
  };

  const handleEditField = (columnName: string, { zencargoReference }: RoadShipmentExtended): void => {
    onCellEdit(zencargoReference, columnName);
    redirectToEditForm(zencargoReference, { autoFocusedField: columnName });
  };

  const redirectToEditForm = (
    zencargoReference: string,
    state: { autoFocusedField?: string; autoFocusedSection?: FormSections }
  ): void => {
    navigate(opsShipmentRoutes.editRoadShipment.getUrl(zencargoReference), state);
  };

  const redirectToVAs = (columnName: string, { zencargoReference, accountUuid }: RoadShipmentExtended): void => {
    onCellEdit(zencargoReference, columnName);
    navigate(accrualRoutes.accrualIndex.getUrl(accountUuid, zencargoReference));
  };

  return [
    {
      key: 'clientReference',
      title: 'References',
      onEdit: handleEditField,
      render: (_, { clientReference, zencargoReference }: RoadShipmentExtended): ReactNode => {
        return (
          zencargoReference && <ShipmentReferences clientReference={clientReference} zencargoReference={zencargoReference} />
        );
      },
      sortKey: 'zencargoReference',
      fixed: 'left',
      width: '200px'
    },
    {
      key: 'stage',
      title: 'Stage progress',
      render: (_, { bookingStage, voyageMilestone, modeOfTransport, stage }: RoadShipmentExtended): ReactNode => {
        if (!bookingStage || !modeOfTransport) {
          return '-';
        }

        return (
          <div className="min-w-72">
            <ShipmentStageProgress
              stageName={voyageMilestone?.name || bookingStage.name}
              statusUpdate={stage?.statusUpdate?.title}
              transportMode={modeOfTransport}
            />
          </div>
        );
      }
    },
    {
      key: 'collectionLocation',
      title: 'Origin',
      onEdit: handleEditField,
      render: (_, { networksOrigin }: RoadShipmentExtended): ReactNode => {
        return getNetworkContactLabel(networksOrigin, 'long');
      },
      sortable: false
    },
    {
      key: 'deliveryLocation',
      title: 'Destination',
      onEdit: handleEditField,
      render: (_, { networksDestination }: RoadShipmentExtended): ReactNode => {
        return getNetworkContactLabel(networksDestination, 'long');
      },
      sortable: false
    },
    {
      key: 'latestCollectionDate',
      title: 'Collection date',
      onEdit: handleEditScheduleDateAndTime,
      render: (_, { calculatedInfo }: RoadShipmentExtended): ReactNode => {
        return renderDateTimeColumn(calculatedInfo?.collectionInFull);
      }
    },
    {
      key: 'collectionReference',
      title: 'Collection reference',
      onEdit: handleEditField,
      render: (_, { cargo }: RoadShipmentExtended): ReactNode => {
        return <ValueWithDefault value={get(cargo, 'cargoList[0].collection.reference')} />;
      },
      sortable: false
    },
    {
      key: 'latestDeliveryDate',
      title: 'Delivery date',
      onEdit: handleEditScheduleDateAndTime,
      render: (_, { calculatedInfo }: RoadShipmentExtended): ReactNode => {
        return renderDateTimeColumn(calculatedInfo?.deliveryInFull);
      }
    },
    {
      key: 'deliveryReference',
      title: 'Delivery reference',
      onEdit: handleEditField,
      render: (_, { cargo }: RoadShipmentExtended): ReactNode => {
        return <ValueWithDefault value={get(cargo, 'cargoList[0].delivery.reference')} />;
      },
      sortable: false
    },
    {
      key: 'cargoType',
      title: 'Cargo type',
      onEdit: handleEditField,
      render: (_, { cargo }: RoadShipmentExtended): ReactNode => {
        return cargo?.mode && cargoModeLabelMapping[cargo.mode];
      }
    },
    {
      key: 'cargo',
      title: 'Cargo',
      onEdit: handleEditField,
      render: (_, { calculatedInfo, cargo }: RoadShipmentExtended): ReactNode => {
        const cargoType: Optional<NewCargoType> = get(cargo, 'cargoList[0].cargoType', null);

        return (
          <div>
            <div className="leading-normal mb-1">{getNewCargoTypeLabel(cargoType)}</div>
            <CargoSummary summary={calculatedInfo?.cargoSummary} />
          </div>
        );
      },
      sortable: false
    },
    {
      key: 'cargoReadyDate',
      title: 'Cargo ready date',
      onEdit: handleEditField,
      render: (_, { cargoReadyDate }: RoadShipmentExtended): ReactNode => {
        return cargoReadyDate?.date && formatDate(cargoReadyDate.date);
      }
    },
    {
      key: 'cbm',
      title: 'Cubic meters',
      onEdit: handleEditField,
      render: (_, { calculatedInfo }: RoadShipmentExtended): ReactNode => {
        return <ValueWithDefault value={calculatedInfo?.cargoSummary?.totalActualCbm || null} />;
      },
      sortable: false
    },
    {
      key: 'grossWeight',
      title: 'Gross weight',
      onEdit: handleEditField,
      render: (_, { calculatedInfo }: RoadShipmentExtended): ReactNode => {
        const totalGrossWeight: Optional<CargoWeightType[]> = calculatedInfo?.cargoSummary?.totalGrossWeight;

        if (!totalGrossWeight || !totalGrossWeight.length) {
          return '-';
        }

        return totalGrossWeight.map(formatWeight).join(', ');
      },
      sortable: false
    },
    {
      key: 'totalValueOfGoods',
      title: 'Cargo value',
      onEdit: handleEditField,
      render: (_, { calculatedInfo }: RoadShipmentExtended): ReactNode => {
        if (!calculatedInfo?.cargoSummary?.highestRiskLevel && !calculatedInfo?.cargoSummary?.totalValueOfGoods) {
          return <div className="text-right">-</div>;
        }

        const { highestRiskLevel, totalValueOfGoods } = calculatedInfo.cargoSummary;

        return <CargoValue highestRiskLevel={highestRiskLevel} totalValueOfGoods={totalValueOfGoods} />;
      }
    },
    {
      key: 'cargoDescription',
      title: 'Cargo description',
      onEdit: handleEditField,
      render: (_, { cargoDescription }: RoadShipmentExtended): ReactNode => {
        return (
          <div className="max-w-64 truncate">
            <ValueWithDefault value={cargoDescription} />
          </div>
        );
      }
    },
    {
      key: 'consignor',
      title: 'Consignor',
      onEdit: handleEditField,
      render: (_, { consignorLabel }: RoadShipmentExtended): ReactNode => {
        return <ValueWithDefault value={consignorLabel} />;
      }
    },
    {
      key: 'consignee',
      title: 'Consignee',
      onEdit: handleEditField,
      render: (_, { consigneeLabel }: RoadShipmentExtended): ReactNode => {
        return <ValueWithDefault value={consigneeLabel} />;
      }
    },
    {
      key: 'haulier',
      title: 'Haulier',
      onEdit: handleEditField,
      render: (_, { haulier }: RoadShipmentExtended): ReactNode => {
        return <ValueWithDefault value={haulier?.name} />;
      },
      sortable: false
    },
    {
      key: 'trailerId',
      title: 'Trailer ID',
      onEdit: handleEditField,
      render: (_, { cargo }: RoadShipmentExtended): ReactNode => {
        return <ValueWithDefault value={get(cargo, 'cargoItems[0].trailerId')} />;
      },
      sortable: false
    },
    {
      key: 'vehiclePlateNumber',
      title: 'Vehicle plate',
      onEdit: handleEditField,
      render: (_, { cargo }: RoadShipmentExtended): ReactNode => {
        return <ValueWithDefault value={cargo?.cargoList?.[0]?.collection?.vehiclePlateNumber} />;
      },
      sortable: false
    },
    {
      key: 'driverDetails',
      title: 'Driver details',
      onEdit: handleEditField,
      render: (_, { cargo }: RoadShipmentExtended): ReactNode => {
        return <ValueWithDefault value={cargo?.cargoList?.[0]?.collection?.driverDetails} />;
      },
      sortable: false
    },
    {
      key: 'incoterms',
      title: 'Incoterms',
      onEdit: handleEditField,
      render: (_, { incoterms }: RoadShipmentExtended): ReactNode => {
        return incoterms?.value && LABEL_FOR_INCOTERMS[incoterms.value];
      }
    },
    {
      key: 'shipmentType',
      title: 'Shipment type',
      onEdit: handleEditField,
      render: (_, { shipmentType }: RoadShipmentExtended): ReactNode => {
        const shipmentTypeLabel: Optional<string> = shipmentType && shipmentTypeLabelMapping[shipmentType];

        return <ValueWithDefault value={shipmentTypeLabel} />;
      }
    },
    {
      key: 'tradeRole',
      title: 'Trade role',
      onEdit: handleEditField,
      render: (_, { tradeRole }: RoadShipmentExtended): ReactNode => {
        const tradeRoleLabel: Optional<string> = tradeRole && tradeRoleLabelMapping[tradeRole];

        return <ValueWithDefault value={tradeRoleLabel} />;
      }
    },
    {
      key: 'accrualsEarliestState',
      title: 'V&As',
      onEdit: redirectToVAs,
      render: (_, { accrualsEarliestState }: RoadShipmentExtended): ReactNode => {
        if (!accrualsEarliestState) {
          return <div className="text-right">-</div>;
        }

        return <AccrualStatePill state={accrualsEarliestState} />;
      },
      sortable: false
    },
    {
      key: 'specialInstructions',
      title: 'Special instructions',
      onEdit: handleEditField,
      render: (_, { specialInstructions }: RoadShipmentExtended): ReactNode => {
        return (
          <div className="max-w-64 truncate">
            <ValueWithDefault value={specialInstructions?.message} />
          </div>
        );
      },
      sortable: false
    },
    {
      key: 'customsOnly',
      title: 'Customs',
      onEdit: handleEditField,
      render: (_, { customsOnly }: RoadShipmentExtended): ReactNode => {
        const customsOnlyLabel: string = customsOnly ? 'Customs only' : '';

        return <ValueWithDefault value={customsOnlyLabel} />;
      },
      sortable: false
    },
    {
      key: 'issues',
      title: 'Issues',
      render: (_, { issues, zencargoReference }: RoadShipmentExtended): ReactNode => {
        if (!issues || issues.length === 0) {
          return '-';
        }

        return (
          <TableLink linkTo={`${pathname}/${zencargoReference}/${TabsType.actionItems}`}>
            <IssueCounterPill issues={issues} />
          </TableLink>
        );
      },
      sortable: false
    },
    {
      key: 'actions',
      title: 'Actions',
      fixed: 'right',
      sortable: false,
      render: (_, { zencargoReference, accountUuid }: RoadShipmentExtended): ReactNode => {
        if (!zencargoReference) {
          return null;
        }

        return <ActionHandleContainer accountId={accountUuid} zencargoReference={zencargoReference} />;
      }
    }
  ];
};
