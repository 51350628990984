import type { FC } from 'react';

import { Icon, IconConfig } from '@zen/DesignSystem';

interface SizeConfig {
  circle: string;
  icon: string;
}

type TimelineIconSize = 'base' | 'lg';

const sizeClassNamesMap: Record<TimelineIconSize, SizeConfig> = {
  base: {
    circle: 'h-6 w-6',
    icon: 'text-base'
  },
  lg: {
    circle: 'h-10 w-10',
    icon: 'text-lg'
  }
};

interface Props {
  className?: string;
  iconConfig: IconConfig;
  size?: TimelineIconSize;
}

const TimelineIcon: FC<Props> = ({ iconConfig, size = 'base', className = '' }) => {
  const sizeClassNames: SizeConfig = sizeClassNamesMap[size];

  return (
    <div
      className={`rounded-full bg-white flex items-center justify-center ${sizeClassNames.circle} ${className}`}
      data-component="timeline-icon"
      data-testid="timeline-icon"
    >
      <Icon className={`${sizeClassNames.icon} ${iconConfig.className}`} icon={iconConfig.icon} />
    </div>
  );
};

export type { Props as TimelineIconProps };

export default TimelineIcon;
