import { FieldArray } from 'formik';
import type { FC, ReactNode } from 'react';

import type { FormFieldArrayHelpers } from './types';

export interface Props {
  children: (arrayHelpers: FormFieldArrayHelpers) => ReactNode;
  name: string;
}

const FormFieldArray: FC<Props> = (props) => {
  const { children, name } = props;

  return (
    <FieldArray name={name}>
      {(arrayHelpers: FormFieldArrayHelpers) => <div className="mb-6">{children(arrayHelpers)}</div>}
    </FieldArray>
  );
};

export default FormFieldArray;
