import type { NewCargoType } from '@zen/types';
import { cargoValues } from '@zen/types';
import type { Optional, Undefinable } from '@zen/utils/typescript';

import { CargoItemTypeEnum, CargoType, ContainerTypeEnum, LooseCargoTypeEnum, PalletTypeEnum, VehicleTypeEnum } from './types';

export const legacyNewTypesMapping: Record<CargoType, NewCargoType> = {
  [ContainerTypeEnum.X20DV]: cargoValues.containerX20Dv,
  [ContainerTypeEnum.X20HC]: cargoValues.containerX20Hc,
  [ContainerTypeEnum.X40DV]: cargoValues.containerX40Dv,
  [ContainerTypeEnum.X40HC]: cargoValues.containerX40Hc,
  [ContainerTypeEnum.X45HC]: cargoValues.containerX45Hc,
  [VehicleTypeEnum.TRAILER_13_6_M_BOX]: cargoValues.trailer136MBox,
  [VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED]: cargoValues.trailer136MCurtainSided,
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER]: cargoValues.trailer136MMegatrailer,
  [VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF]: cargoValues.trailer136MSlidingRoof,
  [VehicleTypeEnum.TRAILER_18_T_BOX]: cargoValues.trailer18TBox,
  [VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED]: cargoValues.trailer18TCurtainSided,
  [VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF]: cargoValues.trailer18TSlidingRoof,
  [VehicleTypeEnum.TRAILER_7_5_T_BOX]: cargoValues.trailer75TBox,
  [VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED]: cargoValues.trailer75TCurtainSided,
  [VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF]: cargoValues.trailer75TSlidingRoof,
  [VehicleTypeEnum.VAN_BOX]: cargoValues.trailerVanBox,
  [VehicleTypeEnum.VAN_CURTAIN_SIDED]: cargoValues.trailerVanCurtainSided,
  [VehicleTypeEnum.VAN_SLIDING_ROOF]: cargoValues.trailerVanSlidingRoof,
  [PalletTypeEnum.EURO]: cargoValues.loosePalletEuro,
  [PalletTypeEnum.OTHER]: cargoValues.loosePalletOther,
  [PalletTypeEnum.UK]: cargoValues.loosePalletUk,
  [PalletTypeEnum.US]: cargoValues.loosePalletUs,
  [LooseCargoTypeEnum.ASSORTED_CARGO]: cargoValues.looseAssortedCargo,
  [LooseCargoTypeEnum.BOXES_OR_CRATES]: cargoValues.looseBoxesOrCrates,
  [LooseCargoTypeEnum.PALLETS]: cargoValues.loosePallet
};

const containerTypes: NewCargoType[] = [
  cargoValues.containerX20Dv,
  cargoValues.containerX20Hc,
  cargoValues.containerX40Dv,
  cargoValues.containerX40Hc,
  cargoValues.containerX45Hc
];
const looseTypes: NewCargoType[] = [
  cargoValues.loosePalletEuro,
  cargoValues.loosePalletOther,
  cargoValues.loosePalletUk,
  cargoValues.loosePalletUs,
  cargoValues.looseAssortedCargo,
  cargoValues.looseBoxesOrCrates,
  cargoValues.loosePallet
];

export const templatedPalletTypes: NewCargoType[] = [
  cargoValues.loosePalletEuro,
  cargoValues.loosePalletUk,
  cargoValues.loosePalletUs
];

export const palletTypes: NewCargoType[] = [
  cargoValues.loosePalletEuro,
  cargoValues.loosePalletUk,
  cargoValues.loosePalletUs,
  cargoValues.loosePalletOther
];

const vehicleTypes: NewCargoType[] = [
  cargoValues.trailer136MBox,
  cargoValues.trailer136MCurtainSided,
  cargoValues.trailer136MMegatrailer,
  cargoValues.trailer136MSlidingRoof,
  cargoValues.trailer18TBox,
  cargoValues.trailer18TCurtainSided,
  cargoValues.trailer18TSlidingRoof,
  cargoValues.trailer75TBox,
  cargoValues.trailer75TCurtainSided,
  cargoValues.trailer75TSlidingRoof,
  cargoValues.trailerVanBox,
  cargoValues.trailerVanCurtainSided,
  cargoValues.trailerVanSlidingRoof
];

type LegacyCargoMappingItem = Record<CargoItemTypeEnum, NewCargoType[]>;

export const legacyCargoItemTypeMapping: LegacyCargoMappingItem = {
  [CargoItemTypeEnum.CONTAINER]: containerTypes,
  [CargoItemTypeEnum.LOOSE_CARGO]: looseTypes,
  [CargoItemTypeEnum.VEHICLE]: vehicleTypes
};

export const getLegacyCargoItemType = (cargoType: Optional<NewCargoType>): Optional<CargoItemTypeEnum> => {
  if (!cargoType) {
    return null;
  }

  return Object.keys(legacyCargoItemTypeMapping).find((key) =>
    legacyCargoItemTypeMapping[key as keyof typeof CargoItemTypeEnum].includes(cargoType)
  ) as CargoItemTypeEnum;
};

export const getLegacyCargoType = (cargoType: Optional<NewCargoType>): Undefinable<CargoType> => {
  return Object.keys(legacyNewTypesMapping).find(
    (keyItem) => legacyNewTypesMapping[keyItem as keyof typeof legacyNewTypesMapping] === cargoType
  ) as CargoType;
};
