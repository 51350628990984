import moment from 'moment';

import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';
import type { Nullable } from '@zen/utils/typescript';

import type { IntakeFilterValues } from '../types';

const getLastMonday = () => moment().startOf('isoWeek').format(FORMAT_DATE_TRANSFERABLE);

export const getFilters = (queryParams: string) => {
  const { destinationId, startDate = getLastMonday(), period = 4 } = parseQueryParams(queryParams);

  return {
    startDate,
    period,
    destinationId: destinationId || null
  };
};

export const prepareFilters = (queryParams: string, endDate: Nullable<string> = null): IntakeFilterValues => {
  const { field, direction } = parseQueryParams(queryParams);
  const { destinationId, startDate, period } = getFilters(queryParams);
  const calculatedEndDate = moment(startDate).add(period, 'w').add(-1, 'd').format(FORMAT_DATE_TRANSFERABLE);

  return {
    dateRange: {
      startDate,
      endDate: endDate || calculatedEndDate
    },
    destinationId: destinationId || null,
    order: field && { field, direction }
  };
};
