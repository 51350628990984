import type { FC, ReactNode } from 'react';

import CollapsableElement from '@zen/Components/CollapsableElement';
import { Icon } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  isEditMode: boolean;
  missingDataPoints: string[];
  onEdit: () => void;
  trackingStatus: Optional<string>;
  trackingType: string;
}

const ShipmentTrackingStatus: FC<Props> = (props) => {
  const { children, isEditMode, missingDataPoints, onEdit, trackingStatus, trackingType } = props;

  const hasMissingDataPoints: boolean = missingDataPoints.length > 0;

  const renderMissingDataPoint = (missingDataPoint: string, index: number): ReactNode => (
    <div key={index} className="mr-4">
      {missingDataPoint}
    </div>
  );

  return (
    <div className="bg-grey-lightest p-4 mb-4" data-testid="shipment-tracking-status">
      <div className="flex w-full text-sm">
        <div className="mr-4">
          Tracking type: <span className="font-bold">{trackingType}</span>
        </div>
        {trackingStatus && (
          <div>
            Tracking status: <span className="font-bold">{trackingStatus}</span>
          </div>
        )}
        {!isEditMode && (
          <div className="ml-auto">
            <Icon icon="zicon-edit" interactive={true} onClick={onEdit} />
          </div>
        )}
      </div>
      {hasMissingDataPoints && (
        <div className="mt-5">
          <div>Missing data points:</div>
          <div className="flex text-red-dark mt-2">{missingDataPoints.map(renderMissingDataPoint)}</div>
        </div>
      )}
      <CollapsableElement duration={500} isOpened={isEditMode}>
        <div className="mt-6">{children}</div>
      </CollapsableElement>
    </div>
  );
};

export default ShipmentTrackingStatus;
