import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetDocumentTemplateQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['Int'];
}>;

export type GetDocumentTemplateQueryResult = { __typename?: 'Query' } & {
  documentTemplate: { __typename?: 'DocumentTemplate' } & Pick<
    GraphQLTypes.DocumentTemplate,
    'id' | 'name' | 'externalId' | 'externalKey'
  > & {
      fields: { __typename?: 'DocumentTemplateFields' } & {
        cargo?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateCargoFields' } & Pick<
            GraphQLTypes.DocumentTemplateCargoFields,
            | 'authorisationCode'
            | 'cargoDescription'
            | 'cargoType'
            | 'cargoValue'
            | 'cbm'
            | 'containerNumbers'
            | 'cpc'
            | 'customsExport'
            | 'customsImport'
            | 'dan'
            | 'doorToPortFreight'
            | 'grossWeight'
            | 'importerOfRecordNumber'
            | 'incoterms'
            | 'insuranceValue'
            | 'markAndNumbers'
            | 'netWeight'
            | 'pieceCount'
            | 'productType'
            | 'ucrNumber'
          >
        >;
        collection?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateCollectionFields' } & Pick<
            GraphQLTypes.DocumentTemplateCollectionFields,
            | 'cargoReadyDate'
            | 'collectionContactName'
            | 'collectionDate'
            | 'collectionDateTime'
            | 'collectionPoint'
            | 'equipmentBooked'
          >
        >;
        contacts?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateContactsFields' } & Pick<GraphQLTypes.DocumentTemplateContactsFields, 'attn' | 'to'>
        >;
        delivery?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateDeliveryFields' } & Pick<
            GraphQLTypes.DocumentTemplateDeliveryFields,
            'deliveryContactName' | 'deliveryDate' | 'deliveryDetails'
          >
        >;
        invoiceDate?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateInvoiceDateFields' } & Pick<
            GraphQLTypes.DocumentTemplateInvoiceDateFields,
            'customerInvoiceDate'
          >
        >;
        manager?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateManagerFields' } & Pick<
            GraphQLTypes.DocumentTemplateManagerFields,
            'operationsManager' | 'operationsManagerEmail'
          >
        >;
        other?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateOtherFields' } & Pick<
            GraphQLTypes.DocumentTemplateOtherFields,
            'specialInstructions' | 'today'
          >
        >;
        rates?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateRatesFields' } & Pick<
            GraphQLTypes.DocumentTemplateRatesFields,
            'agreedRate' | 'clientSellRate' | 'supplierBuyRate' | 'vat'
          >
        >;
        references?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateReferencesFields' } & Pick<
            GraphQLTypes.DocumentTemplateReferencesFields,
            'clientReference' | 'supplierReference' | 'zencargoReference'
          >
        >;
        supplier?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateSupplierFields' } & Pick<
            GraphQLTypes.DocumentTemplateSupplierFields,
            'supplierCompanyName' | 'supplierContactName'
          >
        >;
        tradeParties?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateTradePartiesFields' } & Pick<
            GraphQLTypes.DocumentTemplateTradePartiesFields,
            | 'accountCompanyAddress'
            | 'accountCompanyName'
            | 'clientContactName'
            | 'consigneeAddress'
            | 'consigneeName'
            | 'notifyPartyDetails'
            | 'shipperAddress'
            | 'shipperName'
          >
        >;
        voyage?: GraphQLTypes.Maybe<
          { __typename?: 'DocumentTemplateVoyageFields' } & Pick<
            GraphQLTypes.DocumentTemplateVoyageFields,
            | 'arrivalPort'
            | 'countryOfOrigin'
            | 'departurePort'
            | 'flightNumber'
            | 'houseBolNumber'
            | 'originalBolNumber'
            | 'scac'
            | 'transhipmentPort'
            | 'vessel'
            | 'vesselEta'
            | 'vesselEtd'
            | 'vesselFirstUsArrival'
            | 'vesselNumber'
          >
        >;
      };
    };
};

export const GetDocumentTemplateDocument = /* #__PURE__ */ gql`
  query getDocumentTemplate($id: Int!) {
    documentTemplate(id: $id) {
      id
      name
      externalId
      externalKey
      fields {
        cargo {
          authorisationCode
          cargoDescription
          cargoType
          cargoValue
          cbm
          containerNumbers
          cpc
          customsExport
          customsImport
          dan
          doorToPortFreight
          grossWeight
          importerOfRecordNumber
          incoterms
          insuranceValue
          markAndNumbers
          netWeight
          pieceCount
          productType
          ucrNumber
        }
        collection {
          cargoReadyDate
          collectionContactName
          collectionDate
          collectionDateTime
          collectionPoint
          equipmentBooked
        }
        contacts {
          attn
          to
        }
        delivery {
          deliveryContactName
          deliveryDate
          deliveryDetails
        }
        invoiceDate {
          customerInvoiceDate
        }
        manager {
          operationsManager
          operationsManagerEmail
        }
        other {
          specialInstructions
          today
        }
        rates {
          agreedRate
          clientSellRate
          supplierBuyRate
          vat
        }
        references {
          clientReference
          supplierReference
          zencargoReference
        }
        supplier {
          supplierCompanyName
          supplierContactName
        }
        tradeParties {
          accountCompanyAddress
          accountCompanyName
          clientContactName
          consigneeAddress
          consigneeName
          notifyPartyDetails
          shipperAddress
          shipperName
        }
        voyage {
          arrivalPort
          countryOfOrigin
          departurePort
          flightNumber
          houseBolNumber
          originalBolNumber
          scac
          transhipmentPort
          vessel
          vesselEta
          vesselEtd
          vesselFirstUsArrival
          vesselNumber
        }
      }
    }
  }
`;

/**
 * __useGetDocumentTemplateQuery__
 *
 * To run a query within a React component, call `useGetDocumentTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<GetDocumentTemplateQueryResult, GetDocumentTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetDocumentTemplateQueryResult, GetDocumentTemplateQueryVariables>(GetDocumentTemplateDocument, options);
}
export function useGetDocumentTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTemplateQueryResult, GetDocumentTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetDocumentTemplateQueryResult, GetDocumentTemplateQueryVariables>(
    GetDocumentTemplateDocument,
    options
  );
}
export type GetDocumentTemplateQueryHookResult = ReturnType<typeof useGetDocumentTemplateQuery>;
export type GetDocumentTemplateLazyQueryHookResult = ReturnType<typeof useGetDocumentTemplateLazyQuery>;
