import type { PackingList } from '@zen/graphql/types.generated';
import type { OrderListViewLot } from '@zen/Orders';
import type { Product } from '@zen/Products/types';
import type { Optional } from '@zen/utils/typescript';

import type { PackingListLinesQueryResult } from '../PackingListDetails/graphql';
import type { PackingListOrder, PackingListOrderLot, PackingListOrderResult } from '../PackingListDetails/types';

export const prepareLots = (data: PackingListLinesQueryResult): Record<string, PackingListOrderLot[]> => {
  const packingList: Optional<PackingList> = data.packingLists?.nodes?.[0];
  const packingListLines: NonNullable<PackingList['lines']> = packingList?.lines || [];
  const products: Product[] = packingListLines.map(({ product }) => product) as Product[];
  const lots: OrderListViewLot[] = packingListLines.map(({ lot }) => lot) as OrderListViewLot[];
  const orderId: Optional<string> = lots?.[0]?.purchaseOrder?.id;
  const cargoId: Optional<string> = lots?.[0]?.cargos?.id || lots?.[0]?.cargo?.id;

  return {
    [`${orderId}_${cargoId}`]: lots.map((lot: OrderListViewLot, index: number): PackingListOrderLot => {
      const { id, cbm, quantityFulfilled } = lot;
      const product = products[index];

      return {
        id,
        cbm: cbm || 0,
        productName: product.name,
        productSkuCode: product.skuCode,
        productId: product.id,
        quantityFulfilled: quantityFulfilled || 0
      };
    })
  };
};

export const prepareOrders = (
  orders: PackingListOrderResult[],
  orderDetails: Record<string, PackingListOrderLot[]>,
  cargoId: string
): PackingListOrder[] => {
  return orders.map((order: PackingListOrderResult): PackingListOrder => {
    const { id: orderId, orderReferenceNumber, manufacturer } = order;

    return {
      id: orderId,
      orderReferenceNumber,
      manufacturerName: manufacturer?.label?.short,
      lots: orderDetails[`${orderId}_${cargoId}`]
    };
  });
};
