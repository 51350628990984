import { difference } from 'lodash';
import { FC, ReactNode, useContext, useEffect } from 'react';

import BasketHeader from '@zen/Components/BasketHeader';
import FixedHeader from '@zen/Components/FixedHeader';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import PageContent from '@zen/Components/PageContent';
import QueryHandler from '@zen/Components/QueryHandler';
import { Pagination } from '@zen/DesignSystem';
import OrdersSearch from '@zen/Orders/components/OrdersSearch';
import { shipmentRoutes } from '@zen/Routes';
import { ShipmentPageSectionUrl } from '@zen/Shipment/ShipmentDetailsPage/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import useOrdersListQuery from '../../hooks/useOrdersListView';
import OrderListItem from '../../OrderList/OrderListItem';
import type { OrderFilters, OrderListViewItem } from '../../types';
import { useUpdateAssignedLotsToBookingMutation } from '../graphql';

interface Props {
  zencargoReference: string;
}

const LotAssignmentsToBooking: FC<Props> = ({ zencargoReference }) => {
  const { navigateBack } = useNavigationHistory();
  const { addSuccess, addError } = useNotification();
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();

  const { initialItemIds, items } = useContext(BasketContext);
  const { setAppliedFilters } = useAppliedFilters<OrderFilters>();

  const itemIds: string[] = items.map(({ id }) => id);
  const lotIdsToAssign: string[] = difference(itemIds, initialItemIds || []);
  const lotIdsToUnassign: string[] = difference(initialItemIds, itemIds);
  const isSubmitDisabled: boolean = lotIdsToAssign.length === 0 && lotIdsToUnassign.length === 0;

  const { nodes, paginationInfo, loading, error } = useOrdersListQuery({ availableForBooking: zencargoReference });
  const [updateLots] = useUpdateAssignedLotsToBookingMutation();

  const shipmentCargoUrl: string = shipmentRoutes.shipmentDetailsPage.getUrl(zencargoReference, ShipmentPageSectionUrl.CARGO);

  const handleCancel = (): void => navigateBack(shipmentCargoUrl);

  const handleSubmit = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateLots({
          variables: {
            input: {
              lotIdsToAssign,
              lotIdsToUnassign,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Lots assigned to shipment have been updated.');

        navigateBack(shipmentCargoUrl);
      }
    });
  };

  useEffect(() => {
    // make sure to clear all filters when you enter the page
    setAppliedFilters({});
  }, []);

  return (
    <>
      <div className="bg-azure-lightest flex flex-col w-full">
        <FixedHeader>
          <BasketHeader
            headline={`Update lots assigned to booking (${zencargoReference})`}
            isSubmitDisabled={isSubmitDisabled}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            submitText="Update"
          />
        </FixedHeader>
      </div>
      <PageContent className="py-20">
        <OrdersSearch />
        <QueryHandler data={nodes} error={!!error} isLoading={loading}>
          {(orders: OrderListViewItem[]) => (
            <div className="mt-10" data-testid="order-list">
              {orders.map(
                (order: OrderListViewItem): ReactNode => (
                  <OrderListItem
                    key={order.id}
                    order={order}
                    orderLotsQueryVariables={{
                      availableForBooking: zencargoReference,
                      newCargoService: !!isNewCargoServiceEnabled
                    }}
                  />
                )
              )}
              <Pagination pageInfo={paginationInfo} />
            </div>
          )}
        </QueryHandler>
      </PageContent>
    </>
  );
};

export default LotAssignmentsToBooking;
