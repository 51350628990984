import cx from 'classnames';
import type { FC } from 'react';

import { useActivityFeedArchiveTextMessageMutation } from '@zen/ActivityFeed/graphql';
import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import { Icon } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  id: string;
  onArchive: () => void;
  variant: 'primary' | 'secondary';
}

const TextMessageDelete: FC<Props> = ({ id, onArchive, variant }) => {
  const { addError, addSuccess } = useNotification();
  const classNames: string = cx(
    {
      'bg-grey-lighter rounded-bl-lg': variant === 'primary',
      'bg-azure-light rounded-tr-lg': variant === 'secondary'
    },
    'opacity-0 group-hover:opacity-100',
    'self-stretch r-2 ',
    'px-2 -ml-8 z-10 mb-1',
    'flex shadow-md items-center'
  );

  const boxShadow = '-10px 0 20px -5px rgba(232, 238, 238, 0.8)';

  const mutationOptions = {
    onError: () => addError(),
    onCompleted: () => addSuccess('Message has been archived')
  };

  const [archiveMessageMutation, { loading }] = useActivityFeedArchiveTextMessageMutation(mutationOptions);

  const handleDelete = async (): Promise<void> => {
    const variables = { input: { id } };

    await archiveMessageMutation({ variables });
    onArchive();
  };

  const iconClasses: string = variant === 'primary' ? 'text-grey-dark' : 'text-black-base';

  return (
    <div className={classNames} style={{ boxShadow }}>
      <WithConfirmationModal message="Are you sure you want to archive this message" onConfirm={handleDelete}>
        <Icon className={iconClasses} disabled={loading} icon="zicon-trash" />
      </WithConfirmationModal>
    </div>
  );
};

export default TextMessageDelete;
