import * as Yup from 'yup';

export const getConsolidationValidationSchema = (
  isCustomerIdRequired: boolean,
  isOriginAgentRequired: boolean
): Yup.ObjectSchema<{}> => {
  return Yup.object().shape(
    {
      ...(isCustomerIdRequired && { customerId: Yup.string().nullable().required('Please select customer.') }),
      ...(isOriginAgentRequired && { originAgent: Yup.object().nullable() }),
      reference: Yup.string().nullable().required('Please add reference.'),
      cutOffDate: Yup.string().nullable().required('Please add cut off date.'),
      vehiclePlateNumber: Yup.string().nullable().required('Please add vehicle plate.'),
      cbm: Yup.number().nullable().positive('Must be greater than 0.').integer('Must be whole number.'),
      grossWeight: Yup.object()
        .nullable()
        .shape(
          {
            value: Yup.number()
              .nullable()
              .positive('Must be greater than 0.')
              .integer('Must be whole number.')
              .when('metric', {
                is: (metric: string) => !!metric,
                then: Yup.number().nullable().required('Please add the shipment weight')
              }),
            metric: Yup.string()
              .nullable()
              .when('value', {
                is: (value: number) => !!value,
                then: Yup.string().nullable().required('Please select the unit')
              })
          },
          [['value', 'metric']]
        ),
      quantity: Yup.number()
        .nullable()
        .positive('Must be greater than 0.')
        .integer('Must be whole number.')
        .when('looseCargoType', {
          is: (looseCargoType: string) => !!looseCargoType,
          then: Yup.number().nullable().required('Please add the quantity')
        }),
      looseCargoType: Yup.string()
        .nullable()
        .when('quantity', {
          is: (quantity: number) => !!quantity,
          then: Yup.string().nullable().required('Please select the loose cargo type')
        }),
      legs: Yup.array().of(
        Yup.object().shape({
          networksOrigin: Yup.object().nullable().required('Please add origin.'),
          networksDestination: Yup.object().nullable().required('Please add destination.'),
          estimatedDepartureDate: Yup.string().nullable().required('Please add Estimated departure date.'),
          estimatedArrivalDate: Yup.string().nullable().required('Please add Estimated arrival date.')
        })
      )
    },
    [['quantity', 'looseCargoType']]
  );
};
