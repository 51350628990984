import type { FC } from 'react';

import FormLabel from '@zen/Components/Form/FormLabel';
import { useNetworksAssignMutation } from '@zen/Networks/graphql';
import NetworksForwarderInput from '@zen/Networks/NetworksContactPicker/NetworksForwarderInput';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, NetworksAssignableInterface } from '@zen/Networks/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

interface Props {
  forwarder: Optional<NetworksAssignableInterface>;
  zencargoReference: string;
}

const ForwarderFields: FC<Props> = (props) => {
  const { forwarder, zencargoReference } = props;

  const [assignAction] = useNetworksAssignMutation({
    refetchQueries: ['getBookingTradeParties']
  });

  const { addSuccess, addError } = useNotification();

  const handleInputChange = async (value: Nullable<NetworksAssignableInterface>): Promise<void> => {
    if (value?.id) {
      const inputVariables = {
        assignmentName: AssignmentTypeValue.FORWARDER,
        targetId: zencargoReference,
        targetType: AssignmentTargetTypeEnum.BOOKING,
        assignableId: value.id
      };

      performMutation({
        mutationFn: () => assignAction({ variables: { input: inputVariables } }),
        onError: addError,
        onSuccess: () => addSuccess('Forwarder has been changed')
      });
    }
  };

  return (
    <div>
      <FormLabel className="text-grey-base" label="Forwarder" />
      <NetworksForwarderInput domainName={AssignmentTargetTypeEnum.BOOKING} onChange={handleInputChange} value={forwarder} />
    </div>
  );
};

export default ForwarderFields;
