import cx from 'classnames';
import { createRef, FC, RefObject, useEffect } from 'react';

import { FormHiddenInput } from '@zen/Components/Form';
import FormCountriesSelect from '@zen/Components/Form/FormCountriesSelect';
import FormGeosuggestAutocomplete from '@zen/Components/Form/FormGeosuggestAutocomplete';
import FormInput from '@zen/Components/Form/FormInput';
import FormLabel from '@zen/Components/Form/FormLabel';
import FormNumberInput from '@zen/Components/Form/FormNumberInput/FormNumberInput';
import FormRadioGroup from '@zen/Components/Form/FormRadioGroup/FormRadioGroup';
import { useForm } from '@zen/Components/Form/useForm';
import type { GeoSuggestAddress } from '@zen/Components/GeosuggestAutocomplete/types';
import Map from '@zen/Components/Map';
import type { RadioGroupOption } from '@zen/Components/RadioGroup';
import { LocationTypeValue } from '@zen/Networks/types';

import FormBusinessHoursFields from '../FormBusinessHoursFields';
import type { IAddressForm } from './types';

const locationTypeOptions: RadioGroupOption[] = [
  { label: 'Office', value: LocationTypeValue.OFFICE },
  { label: 'Warehouse', value: LocationTypeValue.WAREHOUSE }
];

const addressFields = ['city', 'countryCode', 'postalCode', 'state', 'street', 'lat', 'lng'];

const FormAddressFields: FC = () => {
  const { errors, setFieldError, setFieldValue, values } = useForm<IAddressForm>();
  const streetFieldRef: RefObject<HTMLInputElement> = createRef();

  const className: string = cx({ 'opacity-0 h-0 pointer-events-none': !values.addressFieldsVisible });

  const handleAddressSelect = (geoAddress: GeoSuggestAddress): void => {
    addressFields.forEach((key: string) => {
      setFieldValue(key, geoAddress[key as keyof GeoSuggestAddress]);
    });

    showAddressFields();

    setFieldValue('locationAddress', '');
  };

  const handleManualEntry = (): void => {
    addressFields.forEach((key: string) => setFieldValue(key, ''));

    showAddressFields();

    streetFieldRef.current?.focus();
  };

  const showAddressFields = () => setFieldValue('addressFieldsVisible', true);

  const handleMarkerDrag = (newLat: number, newLng: number): void => {
    setFieldValue('lat', newLat);
    setFieldValue('lng', newLng);
  };

  const handleCopyBusinessHours = (): void => {
    ['tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach((dayOfTheWeek: string) => {
      setFieldValue(`businessHours.${dayOfTheWeek}`, values.businessHours.monday);
    });
  };

  useEffect(() => {
    if (errors.timeZone) {
      setFieldError('lat', '');
      setFieldError('lng', '');
    }
  }, [errors, setFieldError]);

  return (
    <div className="mb-16" data-testid="form-address-fields">
      <FormRadioGroup name="locationType" noMargin={true} options={locationTypeOptions} radioAlignment="column" />

      <FormBusinessHoursFields onCopy={handleCopyBusinessHours} />

      <FormGeosuggestAutocomplete
        isRequired={!values.addressFieldsVisible}
        label="Location address"
        name="locationAddress"
        onManualEntry={handleManualEntry}
        onSuggestionSelect={handleAddressSelect}
      />

      <div className={className}>
        <FormInput ref={streetFieldRef} isRequired={true} label="Address line 1" name="street" />
        <FormInput label="Town / City" name="city" />
        <FormInput label="State / County" name="state" />
        <FormInput className="w-64" label="ZIP / Post code" name="postalCode" />
        <FormCountriesSelect isRequired={true} label="Country" name="countryCode" />

        <div className="flex">
          <FormNumberInput className="w-64 mr-8" isRequired={true} label="Latitude" name="lat" />
          <FormNumberInput className="w-64" isRequired={true} label="Longitude" name="lng" />
        </div>

        <div className="-mt-4 mb-2">
          <FormHiddenInput name="timeZone" />
        </div>

        <div>
          <FormLabel label="Map" />
          <div className="text-grey-dark text-sm mb-4 -mt-1">
            Does this location look right? If not you can move the pin to the correct location.
          </div>

          <div style={{ height: '20rem' }}>
            <Map
              markerDraggable={true}
              markers={values.lng && values.lat ? [{ lng: values.lng, lat: values.lat }] : []}
              onMarkerDrag={handleMarkerDrag}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddressFields;
