import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IntakePlanningProductsQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  dateRange: GraphQLTypes.DateRangeInput;
  destinationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  fulfilmentStatus?: GraphQLTypes.InputMaybe<GraphQLTypes.FulfilmentStatusEnum>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type IntakePlanningProductsQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & {
      intakePlanningProducts: { __typename?: 'ProductIntakeConnection' } & Pick<
        GraphQLTypes.ProductIntakeConnection,
        'totalCount'
      > & {
          nodes?: GraphQLTypes.Maybe<
            Array<
              GraphQLTypes.Maybe<
                { __typename?: 'ProductIntake' } & Pick<
                  GraphQLTypes.ProductIntake,
                  'quantityArriving' | 'quantityPlanned' | 'lastUpdated'
                > & {
                    product: { __typename?: 'Product' } & Pick<GraphQLTypes.Product, 'id' | 'name' | 'skuCode'>;
                    valueArriving?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>;
                    valuePlanned?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>;
                  }
              >
            >
          >;
          pageInfo: { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >;
        };
    }
  >;
};

export const IntakePlanningProductsDocument = /* #__PURE__ */ gql`
  query intakePlanningProducts(
    $accountUuid: String!
    $dateRange: DateRangeInput!
    $destinationId: String
    $fulfilmentStatus: FulfilmentStatusEnum
    $after: String
    $before: String
    $order: SortInput
  ) {
    account(uuid: $accountUuid) {
      intakePlanningProducts(
        dateRange: $dateRange
        destinationId: $destinationId
        fulfilmentStatus: $fulfilmentStatus
        order: $order
        after: $after
        before: $before
      ) {
        totalCount
        nodes {
          product {
            id
            name
            skuCode
          }
          quantityArriving
          quantityPlanned
          valueArriving {
            currency
            value
          }
          valuePlanned {
            currency
            value
          }
          lastUpdated
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useIntakePlanningProductsQuery__
 *
 * To run a query within a React component, call `useIntakePlanningProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntakePlanningProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntakePlanningProductsQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      dateRange: // value for 'dateRange'
 *      destinationId: // value for 'destinationId'
 *      fulfilmentStatus: // value for 'fulfilmentStatus'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useIntakePlanningProductsQuery(
  baseOptions: Apollo.QueryHookOptions<IntakePlanningProductsQueryResult, IntakePlanningProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<IntakePlanningProductsQueryResult, IntakePlanningProductsQueryVariables>(
    IntakePlanningProductsDocument,
    options
  );
}
export function useIntakePlanningProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IntakePlanningProductsQueryResult, IntakePlanningProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<IntakePlanningProductsQueryResult, IntakePlanningProductsQueryVariables>(
    IntakePlanningProductsDocument,
    options
  );
}
export type IntakePlanningProductsQueryHookResult = ReturnType<typeof useIntakePlanningProductsQuery>;
export type IntakePlanningProductsLazyQueryHookResult = ReturnType<typeof useIntakePlanningProductsLazyQuery>;
