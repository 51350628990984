import type { FC } from 'react';

import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { categoryLabelMapping, severityLabelMapping } from '@zen/Issues/issueHelpers';
import type { Issue } from '@zen/Issues/types';

import { severityTextColor } from './helper';

interface Props {
  issue: Issue;
  itemReference?: string;
}

const IssueDetailsTitle: FC<Props> = ({ issue, itemReference }) => {
  const { severity, category, title } = issue;

  return (
    <>
      <div className={`text-xs leading-normal ${severityTextColor[severity]}`}>
        {title || severityLabelMapping[severity]} issue
      </div>
      {categoryLabelMapping[category]}{' '}
      <span className="text-xs text-navy-base">
        <ValueWithDefault value={itemReference} />
      </span>
    </>
  );
};

export default IssueDetailsTitle;
