import type { FC, ReactNode } from 'react';
import * as Yup from 'yup';

import { Form, FormButtons, FormInput, FormInstance, FormPasswordInput } from '@zen/Components/Form';
import { TextLink } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { LoginPayload } from './types';

const emailCheckRegex: RegExp = /^(?!([A-Za-z0-9._%-]+@zencargo.com)$)/;
const emailMessageError: string =
  'To log in to front office with your Zencargo account, please use the account switcher from back office';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please provide email address')
    .email('Email address is not valid.')
    .matches(emailCheckRegex, emailMessageError),
  password: Yup.string().required('Please provide password')
});

const initialValues: LoginPayload = {
  email: '',
  password: ''
};

interface Props {
  onSubmit: (values: LoginPayload) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
}

const LoginForm: FC<Props> = ({ onSubmit, onSuccess }) => {
  const renderFormButtons = ({ isSubmitting }: FormInstance<LoginPayload>): ReactNode => (
    <FormButtons containerClassName="flex items-center text-xs mt-8" isSubmitting={isSubmitting} text="Login">
      <div>
        By logging in you're agreeing to our{' '}
        <TextLink isExternal={true} isStandalone={false} linkTo="https://www.zencargo.com/terms">
          terms and conditions
        </TextLink>{' '}
        and{' '}
        <TextLink isExternal={true} isStandalone={false} linkTo="https://www.zencargo.com/privacy-policy">
          privacy policy
        </TextLink>
      </div>
    </FormButtons>
  );

  return (
    <Form
      formButtons={renderFormButtons}
      formName="LoginForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {(form: FormInstance<LoginPayload>) => {
        const isEmailDisabled: boolean = form.errors.email === emailMessageError;

        return (
          <>
            <FormInput label="Email address" name="email" placeholder="Email" />
            <FormPasswordInput disabled={isEmailDisabled} name="password" placeholder="Password" />
            <TextLink className="flex text-xs -mt-1" isStandalone={false} linkTo="/forgot-password">
              Forgot password?
            </TextLink>
          </>
        );
      }}
    </Form>
  );
};

export default LoginForm;
