import type { FC } from 'react';

import type { PillType } from '@zen/DesignSystem';
import { Pill } from '@zen/DesignSystem';

const getPillType = (booked: boolean, atRisk: boolean): PillType => {
  if (booked) {
    return 'success';
  }

  if (atRisk) {
    return 'problem';
  }

  return 'pending';
};

interface Props {
  atRisk?: boolean;
  booked: boolean;
  className?: string;
}

const BookingStatus: FC<Props> = ({ atRisk = false, booked, className }) => (
  <Pill className={className} type={getPillType(booked, atRisk)}>
    {booked ? 'Booked' : 'Not booked'}
  </Pill>
);

export default BookingStatus;
