import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';
import { useUpdateEffect } from 'react-use';

import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import DataExportButton from '@zen/Components/DataExportButton';
import NoResults from '@zen/Components/NoResults';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Pagination, Table, TableColumn, useColumnConfiguration } from '@zen/DesignSystem';
import Filters from '@zen/IntakePlanning/Filters';
import { prepareFilters } from '@zen/IntakePlanning/Filters/helpers';
import {
  IntakePlanningProductLotsQueryResult,
  IntakePlanningProductLotsQueryVariables,
  useIntakePlanningExportProductLotsDataMutation,
  useIntakePlanningProductLotsQuery
} from '@zen/IntakePlanning/graphql';
import { getColumnKeys } from '@zen/IntakePlanning/helpers/dataExport';
import { createSortingHandler } from '@zen/IntakePlanning/helpers/sorting';
import type { ProductLotsColumnEnum } from '@zen/IntakePlanning/types';
import type { NetworksAssignableInterface } from '@zen/Networks';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import { ProductTabs } from '@zen/types';
import usePagination from '@zen/utils/hooks/pagination/usePagination';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

import type { ProductLot } from '../../types';
import { getColumns } from './tableConfiguration';

interface Props {
  accountUuid: string;
  destinations: NetworksAssignableInterface[];
  filterByUserPreferences: boolean;
  productId: string;
}

const PoIntake: FC<Props> = ({ accountUuid, destinations, productId, filterByUserPreferences }) => {
  const location = useLocation();
  const history = useHistory();
  const { addError } = useNotification();
  const filters = prepareFilters(location.search);
  const handleOrderChange = createSortingHandler(location, history);
  const tableId: string = 'poIntake';
  const { hiddenColumns, setHiddenColumns } = useColumnConfiguration(tableId);
  const originPath: string = `/dashboard/products/${productId}/${ProductTabs.PO}${location.search}`;
  const { showDataQuality } = parseQueryParams(location.search);
  const columns: TableColumn<ProductLot>[] = getColumns({
    pathname: location.pathname,
    search: location.search
  });
  const results = usePagination<IntakePlanningProductLotsQueryResult, IntakePlanningProductLotsQueryVariables, ProductLot>(
    useIntakePlanningProductLotsQuery,
    'account.intakePlanningProductLots',
    {
      accountUuid,
      productId,
      ...filters
    },
    20
  );

  const [exportRows] = useIntakePlanningExportProductLotsDataMutation();

  const { nodes, paginationInfo, loading, error, refetch, totalCount } = results;

  useUpdateEffect(() => {
    refetch();
  }, [refetch, filterByUserPreferences]);

  const handleExport = async (): Promise<IOkOrErrorResult> => {
    const exportColumns = getColumnKeys(columns, hiddenColumns) as ProductLotsColumnEnum[];

    return performMutation({
      mutationFn: () =>
        exportRows({
          variables: {
            input: {
              accountUuid,
              exportData: {
                columns: exportColumns,
                dataFilters: {
                  productId,
                  dateRange: filters.dateRange,
                  destinationId: filters.destinationId
                }
              }
            }
          }
        }),
      onError: () => addError()
    });
  };

  return (
    <div className="flex flex-col h-full px-10">
      <Helmet title="Product orders view" />
      <Filters destinations={destinations} />
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonTableLoading columns={columns} order={filters.order} tableId={tableId} />}
        noResults={
          <NoResults
            headline="We can't find any results"
            tagline="There are no results for your query, please adjust your search or filter criteria and try again."
          />
        }
      >
        {(productLots: ProductLot[]) => (
          <>
            <div className="flex justify-end mt-8 mb-8">
              <CustomiseTableButton columns={columns} hiddenColumns={hiddenColumns} onChange={setHiddenColumns} />
              <DataExportButton className="ml-3" confirmLabel="Return to intake planning" onClickPromise={handleExport} />
            </div>
            <Table<ProductLot>
              columns={columns}
              data={productLots}
              hiddenColumns={hiddenColumns}
              onOrderChange={handleOrderChange}
              order={filters.order}
              tableId={tableId}
              totalCountConfig={{
                totalCount,
                entityName: 'lot'
              }}
            />
            <Pagination pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
      {!showDataQuality && <OrderDetailsContainer originPath={originPath} />}
    </div>
  );
};

export default PoIntake;
