import { FC, useState } from 'react';
import { useParams } from 'react-router';

import {
  CargoDimensionsType,
  CargoModeEnum,
  CargoWeightType,
  getDefaultGrossWeight,
  isTemplatedPalletType,
  LooseCargoTypeEnum,
  PalletTypeEnum
} from '@zen/Cargo';
import FormCargoWeightInput from '@zen/Cargo/forms/FormCargoWeightInput';
import { getDefaultDimensions, isPalletType } from '@zen/Cargo/forms/LooseForm/helpers';
import { riskLevelOptions } from '@zen/Cargo/forms/VehicleForm/helpers';
import { useUpdateCargoModeMutation } from '@zen/Cargo/graphql';
import { vehicleOptions } from '@zen/Cargo/helpers';
import { FormCheckbox, FormHiddenInput, FormNumberInput, FormSelect } from '@zen/Components/Form';
import FormCargoDimensionsInput from '@zen/Components/Form/FormCargoDimensionsInput';
import FormCurrencyInput from '@zen/Components/Form/FormCurrencyInput';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import FormLabel from '@zen/Components/Form/FormLabel';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import { useForm } from '@zen/Components/Form/useForm';
import LoadingMask from '@zen/Components/LoadingMask';
import { Dialog, Toggle } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import type { RoadShipmentFields } from '../../types';
import { cargoModeOptions, extendedLooseCargoTypeOptions } from './helpers';

interface Props {
  autoFocusedField?: string;
}

const CargoFields: FC<Props> = ({ autoFocusedField }) => {
  const { setFieldValue, values } = useForm<RoadShipmentFields>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [isLoadingMaskVisible, setIsLoadingMaskVisible] = useState<boolean>(false);
  const [nextCargoMode, setNextCargoMode] = useState<CargoModeEnum>(values.cargoMode);
  const { addError, addSuccess } = useNotification();
  const { id: zencargoReference } = useParams<{ id: string }>();

  const { cargoMode, cargoItem } = values;
  const isFullTruckLoad: boolean = cargoMode === CargoModeEnum.FTL;
  const isLooseCargo = cargoMode === CargoModeEnum.LOOSE;
  const isAssortedCargo: boolean = cargoItem?.looseCargoType === LooseCargoTypeEnum.ASSORTED_CARGO;

  const [updateCargoMode] = useUpdateCargoModeMutation();

  const handleChange = (mode: CargoModeEnum): void => {
    setShowConfirmationModal(true);
    setNextCargoMode(mode);
  };

  const handleLooseCargoChange = (value: Nullable<LooseCargoTypeEnum | PalletTypeEnum>): void => {
    if (isPalletType(value)) {
      const dimensions: CargoDimensionsType = getDefaultDimensions(value as PalletTypeEnum);
      const grossWeight: CargoWeightType = getDefaultGrossWeight(value as PalletTypeEnum);

      setFieldValue('cargoItem.grossWeight', grossWeight);
      setFieldValue('cargoItem.dimensions', dimensions);
      setFieldValue('cargoItem.looseCargoType', LooseCargoTypeEnum.PALLETS);
      setFieldValue('cargoItem.palletType', value);
    } else {
      setFieldValue('cargoItem.looseCargoType', value);
      setFieldValue('cargoItem.palletType', null);
    }
  };

  const handleConfirm = (): void => {
    setShowConfirmationModal(false);
    setIsLoadingMaskVisible(true);

    performMutation({
      mutationFn: () =>
        updateCargoMode({
          variables: {
            input: {
              cargoMode: nextCargoMode,
              zencargoReference
            }
          },
          // we need to refetch the query, because under the hood, all cargo items are being removed
          refetchQueries: ['getOperationsRoadShipment']
        }),
      onError: () => {
        setIsLoadingMaskVisible(false);
        addError();
      },
      onSuccess: () => {
        setIsLoadingMaskVisible(false);
        addSuccess('Cargo type has been changed.');
      }
    });
  };

  return (
    <div data-testid="cargo-fields">
      {isLoadingMaskVisible && <LoadingMask />}
      <div className="mb-4">
        <FormLabel label="Cargo type" />
        <Toggle<CargoModeEnum> onChange={handleChange} options={cargoModeOptions} value={cargoMode} />
        <FormHiddenInput name="cargoMode" />
      </div>
      {isFullTruckLoad && (
        <FormSelect
          className="w-72"
          isRequired={true}
          isSearchable={false}
          label="Vehicle type"
          name="cargoItem.vehicleType"
          options={vehicleOptions}
        />
      )}
      <div className="flex w-96">
        <FormSelect
          autoFocus={autoFocusedField === 'cargo'}
          className="w-64 mr-4"
          isRequired={true}
          isSearchable={false}
          label="Loose cargo type"
          name="cargoItem.extendedLooseCargoType"
          onChange={handleLooseCargoChange}
          options={extendedLooseCargoTypeOptions}
        />
        {!isAssortedCargo && (
          <FormNumberInput
            className="w-32"
            isRequired={true}
            label="Quantity"
            name="cargoItem.quantity"
            placeholder="10"
            step={1}
          />
        )}
        <FormHiddenInput name="cargoItem.looseCargoType" />
        <FormHiddenInput name="cargoItem.palletType" />
      </div>
      <FormDatePickerInput
        autoFocus={autoFocusedField === 'cargoReadyDate'}
        className="w-48"
        isRequired={true}
        name="cargoReadyDate"
        placeholder="01/12/2021"
      />
      {!isLooseCargo && (
        <>
          <FormSelect
            className="w-64 mr-4"
            isSearchable={false}
            label="Risk level"
            name="cargoItem.riskLevel"
            options={riskLevelOptions}
          />
          <FormCurrencyInput
            autoFocus={autoFocusedField === 'totalValueOfGoods'}
            className="w-64"
            label="Value of cargo"
            name="cargoItem.valueOfGoods"
            placeholder="0.0"
          />
        </>
      )}
      <div className="flex mb-6">
        <FormCheckbox className="mr-6" label="Hazardous" name="cargoItem.hazardous" />
        <FormCheckbox className="mr-6" label="Refrigerated" name="cargoItem.reefer" />
        {isLooseCargo && <FormCheckbox className="mr-6" label="Stackable" name="cargoItem.stackable" />}
        {!isLooseCargo && <FormCheckbox label="Tail lift" name="cargoItem.tailLift" />}
      </div>
      <FormCargoDimensionsInput
        disabled={isTemplatedPalletType(cargoItem?.palletType)}
        hideErrorMessage={true}
        name="cargoItem.dimensions"
      />
      <FormCargoWeightInput autoFocus={autoFocusedField === 'grossWeight'} className="w-64" name="cargoItem.grossWeight" />
      <FormNumberInput
        autoFocus={autoFocusedField === 'cbm'}
        className="w-36"
        label="Cubic meters"
        name="cargoItem.cbm"
        placeholder="0.0"
        step={0.1}
      />
      <FormTextarea
        autoFocus={autoFocusedField === 'cargoDescription'}
        className="max-w-lg"
        name="cargoDescription"
        textareaRows={3}
      />
      <Dialog
        buttonVariant="danger"
        confirmLabel="Update cargo type"
        header="Change cargo type"
        isOpen={showConfirmationModal}
        message="Changing the cargo type will remove any cargo currently added to this booking. Are you sure you want to change cargo type?"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default CargoFields;
