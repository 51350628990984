import CardPicker, { FixedCollection } from '../CardPicker';
import { AmPm, SpecificTime } from '../types';

interface TimePickerProps {
  isTimeRisky?: (t: SpecificTime) => boolean;
  onSelect: (t: SpecificTime) => void;
  selection?: SpecificTime;
}

const TimePicker = (props: TimePickerProps) => {
  const { selection, isTimeRisky } = props;

  const comparator = (t1: SpecificTime | undefined, t2: SpecificTime): boolean => {
    if (!t1 || !t2) return false;

    return t1.hour === t2.hour && t1.ampm === t2.ampm;
  };

  const collection = new FixedCollection<SpecificTime>(
    [
      { hour: 12, ampm: AmPm.AM },
      { hour: 1, ampm: AmPm.AM },
      { hour: 2, ampm: AmPm.AM },
      { hour: 3, ampm: AmPm.AM },
      { hour: 4, ampm: AmPm.AM },
      { hour: 5, ampm: AmPm.AM },
      { hour: 6, ampm: AmPm.AM },
      { hour: 7, ampm: AmPm.AM },
      { hour: 8, ampm: AmPm.AM },
      { hour: 9, ampm: AmPm.AM },
      { hour: 10, ampm: AmPm.AM },
      { hour: 11, ampm: AmPm.AM },
      { hour: 12, ampm: AmPm.PM },
      { hour: 1, ampm: AmPm.PM },
      { hour: 2, ampm: AmPm.PM },
      { hour: 3, ampm: AmPm.PM },
      { hour: 4, ampm: AmPm.PM },
      { hour: 5, ampm: AmPm.PM },
      { hour: 6, ampm: AmPm.PM },
      { hour: 7, ampm: AmPm.PM },
      { hour: 8, ampm: AmPm.PM },
      { hour: 9, ampm: AmPm.PM },
      { hour: 10, ampm: AmPm.PM },
      { hour: 11, ampm: AmPm.PM }
    ],
    7
  );

  const isTimeRiskyStrategy = isTimeRisky || (() => false);

  return (
    <CardPicker
      collection={collection}
      comparator={comparator}
      itemWithRiskStrategy={isTimeRiskyStrategy}
      onSelect={props.onSelect}
      selection={selection}
    >
      {(time: SpecificTime) => {
        return (
          <div className="text-center mt-1 font-medium">
            <div className="text-lg font-bold mb-1">{time.hour}</div>
            <div className="text-xs uppercase">{time.ampm === AmPm.AM ? 'am' : 'pm'}</div>
          </div>
        );
      }}
    </CardPicker>
  );
};

export default TimePicker;
