import cx from 'classnames';

import { IconButton } from '@zen/DesignSystem';
import type { OrderBasketLot } from '@zen/Orders/types';
import { formatDate } from '@zen/utils/dateTime';
import { formatNumber } from '@zen/utils/formatting';

interface OrderBasketItemProps {
  className?: string;
  items: OrderBasketLot[];
  onDelete?: (lotId: string) => void;
  onEdit?: (lotId: string) => void;
}

const OrderBasketItem = ({ items, onEdit, onDelete, className = '' }: OrderBasketItemProps) => {
  const { orderReferenceNumber } = items[0];

  const orderCardClassName: string = 'bg-white border border-solid border-grey-light py-4 px-8 rounded';
  const refNumberClassName: string = 'text-grey-dark mb-4 text-lg font-bold';
  const headlineClassName: string = 'text-grey-base border-t-0 font-bold text-sm p-0';
  const itemClassName: string = 'border-t border-solid border-grey-light flex justify-between py-2 px-0 items-center';
  const textClassName: string = 'pl-4 first:pl-0';
  const productClassName: string = 'overflow-hidden overflow-ellipsis whitespace-nowrap leading-tight';
  const endColumnClassName: string = 'flex justify-end';
  const buttonWrapperClassName: string = 'mr-2 last:mr-0';

  const renderItem = ({ id, cbm, quantityFulfilled, cargoReadyDate, productName }: OrderBasketLot, i: number) => {
    const fulfilled: number = quantityFulfilled || 0;

    return (
      <div key={i} className={cx(itemClassName, 'first:border-t-0')}>
        <div className={cx(textClassName, 'flex-1')}>{formatNumber(fulfilled)}</div>
        <div className={cx(textClassName, 'flex-1')}>{cbm ? formatNumber(cbm) : '-'}</div>
        <div className={cx(textClassName, 'flex-[3]', productClassName)}>{productName}</div>
        <div className={cx(textClassName, 'flex-[1.5]')}>{cargoReadyDate?.date && formatDate(cargoReadyDate.date)}</div>
        <div className={cx(textClassName, 'flex-1', endColumnClassName)}>
          {onEdit && (
            <span className={buttonWrapperClassName}>
              <IconButton icon="zicon-edit" onClick={() => onEdit(id)} title="Edit" variant="secondary" />
            </span>
          )}
          {onDelete && (
            <span className={buttonWrapperClassName}>
              <IconButton icon="zicon-trash" onClick={() => onDelete(id)} title="Remove" variant="secondary" />
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={cx(orderCardClassName, className)}>
      <div className={refNumberClassName} data-testid="reference-number">
        PO: {orderReferenceNumber}
      </div>
      <div className={cx(headlineClassName, itemClassName)}>
        <div className={cx(textClassName, 'flex-1')}>Qty</div>
        <div className={cx(textClassName, 'flex-1')}>CBM</div>
        <div className={cx(textClassName, 'flex-[3]', productClassName)}>Description</div>
        <div className={cx(textClassName, 'flex-[1.5]')}>Ready date</div>
        <div className={cx(textClassName, 'flex-1', endColumnClassName)} />
      </div>
      <div data-testid="lots">{items.map(renderItem)}</div>
    </div>
  );
};

export default OrderBasketItem;
