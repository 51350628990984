import { FC, useContext } from 'react';
import { useHistory } from 'react-router';

import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import BasketHeader from '@zen/Components/BasketHeader';
import FixedHeader from '@zen/Components/FixedHeader';
import NavigationPrompt from '@zen/Components/NavigationPrompt';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import OrderBasketList from '@zen/Components/OrderBasketList';
import PageContent from '@zen/Components/PageContent';
import { Role } from '@zen/Networks';
import { newBookingRoutes, orderRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

interface Props {
  onCustomerChangeRequest: () => void;
}

const OrderBasketHeader: FC<Props> = ({ onCustomerChangeRequest }) => {
  const { check } = useGlobalPermissions();
  const role = useRole();
  const { push } = useHistory();
  const { navigateBack } = useNavigationHistory();
  const { items, hasItems, removeAllItems, removeItems } = useContext(BasketContext);

  const canRequestBooking: boolean = check('bookings.canRequest') && role === Role.MANUFACTURER;
  const bookingUrl: string = canRequestBooking ? '/dashboard/request-booking' : newBookingRoutes.newBookingIndex.getUrl();

  const handleCancel = (): void => {
    navigateBack(orderRoutes.orderList.getUrl());

    removeAllItems?.();
  };

  const handleSubmit = (): void => push(bookingUrl);

  return (
    <div className="bg-azure-lightest flex flex-col w-full -ml-10">
      <FixedHeader>
        <BasketHeader
          headline="Select all the line items / lots you want to book."
          isSubmitDisabled={!hasItems}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          openCustomerModal={onCustomerChangeRequest}
          submitText="Book"
        />
        <PageContent className="border-solid border-t border-navy-dark">
          {!hasItems && <div className="py-12 text-center text-grey-base italic">No lots selected</div>}
          {hasItems && <OrderBasketList items={items} onDelete={(id) => removeItems([id])} />}
        </PageContent>
      </FixedHeader>
      <NavigationPrompt
        allowedPaths={[bookingUrl]}
        header="Navigating away from this page will cancel the booking process."
        leaveLabel="I want to leave"
        onLeave={removeAllItems}
        stayLabel="Return to booking"
      />
    </div>
  );
};

export default OrderBasketHeader;
