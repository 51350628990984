import type { FC } from 'react';
import { useHistory, useParams } from 'react-router';

import Page from '@zen/Components/Page';
import { useNotification } from '@zen/utils/hooks/useNotification';

import { useNetworksContext } from '..';
import EditLocation from '../EditLocation';

const EditLocationPage: FC = () => {
  const { organisationId, locationId } = useParams<{ locationId: string; organisationId: string }>();
  const { push } = useHistory();
  const { addSuccess } = useNotification();
  const { routes } = useNetworksContext();

  const organisationPage: string = routes.location.details.getUrl(organisationId, locationId);

  const handleCancel = (): void => push(organisationPage);

  const handleSuccess = (): void => {
    addSuccess('Your location has been updated successfully.');

    push(organisationPage);
  };

  return (
    <Page defaultBackUrl={organisationPage} title="Edit location" width="narrow">
      <EditLocation locationId={locationId} onCancel={handleCancel} onSuccess={handleSuccess} organisationId={organisationId} />
    </Page>
  );
};

export default EditLocationPage;
