import type { FC } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import { AssignmentTargetTypeEnum } from '@zen/Networks/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useGetBookingOriginAndDestinationQuery, useUpdateOriginAndDestinationMutation } from './graphql';
import OriginAndDestinationForm from './OriginAndDestinationForm';
import type { OriginAndDestinationData, UpdateOriginAndDestinationInput } from './types';

interface Props {
  onCancel?: () => void;
  zencargoReference: string;
}

const OriginAndDestination: FC<Props> = ({ onCancel, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();

  const { data, error, loading } = useGetBookingOriginAndDestinationQuery({
    variables: { zencargoReference }
  });

  const [updateOriginAndDestination] = useUpdateOriginAndDestinationMutation();

  const handleUpdateOriginAndDestination = async (input: UpdateOriginAndDestinationInput): Promise<IOkOrErrorResult> => {
    const { assignableId, assignmentName } = input;

    return performFormMutation({
      mutationFn: () =>
        updateOriginAndDestination({
          variables: {
            input: {
              assignmentName,
              targetId: zencargoReference,
              targetType: AssignmentTargetTypeEnum.BOOKING,
              assignableId: assignableId || ''
            }
          },
          refetchQueries: ['getShipmentDetailsBoard']
        }),
      onError: addError
    });
  };

  const handleSuccess = (successMessage: string): void => {
    addSuccess(successMessage);
    onCancel?.();
  };

  return (
    <QueryHandler data={data?.bookings.nodes?.[0]} error={!!error} isLoading={loading}>
      {(originAndDestinationData: OriginAndDestinationData) => {
        return (
          <OriginAndDestinationForm
            accountId={originAndDestinationData.accountUuid}
            initialValues={originAndDestinationData}
            onCancel={onCancel}
            onSubmit={handleUpdateOriginAndDestination}
            onSuccess={handleSuccess}
          />
        );
      }}
    </QueryHandler>
  );
};

export default OriginAndDestination;
