import type { FC } from 'react';

import { isVoyageMilestoneCompleted } from './helpers';
import type { VoyageMilestoneNameEnum, VoyageMilestoneType } from './types';
import { VoyageMilestonesContext } from './VoyageMilestonesContext';

interface Props {
  milestones: VoyageMilestoneType[];
}

const VoyageMilestonesProvider: FC<Props> = ({ children, milestones }) => {
  const isMilestoneCompleted = (milestoneName: VoyageMilestoneNameEnum): boolean =>
    isVoyageMilestoneCompleted(milestones, milestoneName);

  return (
    <VoyageMilestonesContext.Provider value={{ isMilestoneCompleted, milestones }}>{children}</VoyageMilestonesContext.Provider>
  );
};

export default VoyageMilestonesProvider;
