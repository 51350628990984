import type { MutableRefObject } from 'react';

import type { PickerPosition } from './types';

export const getPickerPosition = (inputRef: MutableRefObject<any>, pickerRef: MutableRefObject<any>): PickerPosition => {
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;

  const inputRect = inputRef.current.getBoundingClientRect();
  const pickerRect = pickerRef.current.getBoundingClientRect();

  const datePickerHeight = inputRect.height + pickerRect.height;

  const shouldPositionAbove = inputRect.y + datePickerHeight > screenHeight;
  const shouldPositionLeft = inputRect.x < screenWidth / 2;

  const y = shouldPositionAbove ? 'above' : 'below';
  const x = shouldPositionLeft ? 'left' : 'right';

  return { y, x };
};
