import type { RoutesConfig } from './types';

export const DOCUMENT_ROUTE_PREFIX = '/dashboard/documents';

interface DocumentRoutes extends RoutesConfig {
  documentCreate: {
    getUrl: (accountId: string, zencargoReference: string) => string;
    path: string;
  };
}

const documentRoutes: DocumentRoutes = {
  documentCreate: {
    path: `${DOCUMENT_ROUTE_PREFIX}/:accountId/:id`,
    getUrl: (accountId: string, zencargoReference: string) => `${DOCUMENT_ROUTE_PREFIX}/${accountId}/${zencargoReference}/create`
  }
};

export default documentRoutes;
