import type { FC } from 'react';
import { useHistory } from 'react-router';

import Page from '@zen/Components/Page';
import { orderRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import OrderForm from '../components/OrderForm';
import { usePurchaseOrdersCreateOrderMutation } from '../graphql';
import type { CreateOrderResponse, PurchaseOrderInput } from '../types';

const NewOrder: FC = () => {
  const { addSuccess, addError } = useNotification();
  const { push } = useHistory();
  const { accountUuid } = useAccount();

  const [createOrder] = usePurchaseOrdersCreateOrderMutation();

  const handleSubmit = (purchaseOrder: PurchaseOrderInput): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () => createOrder({ variables: { input: { accountUuid, purchaseOrder } } }),
      onError: addError
    });
  };

  const handleSuccess = ({ purchaseOrder }: CreateOrderResponse): void => {
    addSuccess('New order has been created.');

    push(orderRoutes.orderItems.getUrl(purchaseOrder.id));
  };

  return (
    <Page defaultBackUrl={orderRoutes.orderList.getUrl()} title="Create PO" width="medium">
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '(purchaseOrder: PurchaseOrderInput) => Promi... Remove this comment to see the full error message */}
      <OrderForm buttonText="Create PO" onSubmit={handleSubmit} onSuccess={handleSuccess} />
    </Page>
  );
};

export default NewOrder;
