import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  lots: Yup.array()
    .of(
      Yup.object({
        quantityFulfilled: Yup.number()
          .nullable()
          .positive('Must be greater than 0')
          .integer('Must be whole number')
          .required('Quantity is required'),
        cbm: Yup.number().nullable().positive('Must be greater than 0')
      })
    )
    .required()
});
