import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import { Form, FormButtons, FormInstance } from '@zen/Components/Form';
import CustomerSelectFormInput from '@zen/Components/Form/CustomerSelectFormInput';
import { Button } from '@zen/DesignSystem';
import useZencargoNetwork from '@zen/Networks/hooks/useZencargoNetwork';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';
import useAccount from '@zen/utils/hooks/useAccount';

import type { IOkOrErrorResult } from '../../utils/OkOrErrorResult';
import type { Nullable } from '../../utils/typescript';
import type { ConsolidatedShipment, ConsolidatedShipmentMutationSuccess, ConsolidatedShipmentValues } from '../types';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, Role } from '../types';
import { getConsolidationValidationSchema } from './consolidationForm.validation';
import ConsolidationLegs from './ConsolidationLegs';
import GeneralInformation from './GeneralInformation';

interface Props {
  consolidation: ConsolidatedShipmentValues;
  onCancel: () => void;
  onSubmit: (values: ConsolidatedShipmentValues) => Promise<IOkOrErrorResult>;
  onSuccess: (data?: ConsolidatedShipmentMutationSuccess) => void;
  submitButtonText: string;
}

const ConsolidationForm: FC<Props> = (props) => {
  const { consolidation, onSubmit, onSuccess, onCancel, submitButtonText } = props;

  const role = useRole();
  const { accountUuid: accountId } = useAccount();
  const { data: zencargoNetwork } = useZencargoNetwork();

  const zencargoNetworkId: string = zencargoNetwork?.zencargoNetwork?.id ?? '';
  const [customerId, setCustomerId] = useState<Nullable<string>>(consolidation?.customerId || '');

  const networkId: string = customerId || accountId;
  const canViewCustomerField: boolean = role === Role.AGENT_FORWARDER;
  const canViewOriginAgentField: boolean = role === Role.ADMIN;

  const renderFormButtons = ({ isSubmitting }: FormInstance<ConsolidatedShipment>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} text={submitButtonText}>
      <Button data-testid="cancel-button" onClick={onCancel} variant="secondary">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      className="max-w-5xl"
      enableReinitialize={true}
      formButtons={renderFormButtons}
      initialValues={consolidation}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={getConsolidationValidationSchema(canViewCustomerField, canViewOriginAgentField)}
    >
      {({ values, resetForm }: FormInstance<ConsolidatedShipmentValues>) => {
        const showOverlay: boolean = canViewCustomerField && !values.customerId;
        const overlayStyles: string = showOverlay ? 'h-full bg-white opacity-25 z-50 pointer-events-none disabledSection' : '';

        const handleCustomerSelect = (id: Nullable<string>): void => {
          resetForm({
            values: {
              ...consolidation,
              customerId: id
            }
          });

          return setCustomerId(id);
        };

        return (
          <>
            {canViewCustomerField && (
              <CustomerSelectFormInput className="w-3/5" label="Customer" name="customerId" onSelect={handleCustomerSelect} />
            )}
            <div className={overlayStyles}>
              <GeneralInformation />

              {canViewOriginAgentField && (
                <NetworksContactFormInput
                  accountUuid={zencargoNetworkId}
                  assignmentType={AssignmentTypeValue.ORIGIN_AGENT}
                  className="max-w-3xl"
                  domainName={AssignmentTargetTypeEnum.CONSOLIDATED_SHIPMENT}
                  label="Origin agent"
                  name="originAgent"
                  showAddButton={false}
                />
              )}

              <ConsolidationLegs accountId={networkId} legs={values.legs} />
            </div>
          </>
        );
      }}
    </Form>
  );
};

export default ConsolidationForm;
