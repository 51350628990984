import { FC, ReactElement, ReactNode, useState } from 'react';
import { useParams } from 'react-router';

import { checkPermission } from '@zen/Auth/authHelper';
import CargoSummary from '@zen/Cargo/components/CargoSummary';
import { useCargoSummaryQuery } from '@zen/Cargo/graphql';
import AutomaticAccountSwitcher from '@zen/Components/AutomaticAccountSwitcher';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { InlineEmptyState, Pagination, SearchInput } from '@zen/DesignSystem';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import { packingListsRoutes, shipmentRoutes } from '@zen/Routes';
import { ShipmentPageSectionUrl } from '@zen/Shipment/ShipmentDetailsPage/types';
import { useConsumeLocationState } from '@zen/utils/hooks/useConsumeLocationState';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import { PackingListLinesQueryResult, usePackingListLinesQueryLazyQuery } from '../PackingListDetails/graphql';
import { PackingListFindByEnum, PackingListOrder, PackingListOrderLot } from '../PackingListDetails/types';
import { PackingListsTrackingAction, PackingListsTrackingCategory } from '../types';
import { prepareLots, prepareOrders } from './helpers';
import usePackingLists from './hooks/usePackingLists';
import PackingListItem from './PackingListItem';
import type { PackingListCargoSummary, PackingListOrderResult } from './types';

const PackingListOverview: FC = () => {
  const { addError } = useNotification();
  const { navigate } = useNavigationHistory();
  const { trackEvent } = useTracking();
  const { zencargoReference } = useParams<{ zencargoReference: string }>();
  const [orderDetails, setOrderDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const defaultBackUrl: string = shipmentRoutes.shipmentDetailsPage.getUrl(zencargoReference, ShipmentPageSectionUrl.CARGO);
  const originPath: string = packingListsRoutes.packingListIndex.getUrl(zencargoReference);
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();

  useConsumeLocationState((search: string) => {
    if (search) {
      setSearchTerm(search);
    }
  }, 'searchTerm');

  const { nodes, paginationInfo, error, loading } = usePackingLists({ searchTerm, zencargoReference });
  const packingListCustomerId = nodes && nodes[0]?.customerId;

  const {
    data: cargoData,
    loading: isLoadingCargoSummary,
    error: errorInCargoSummary
  } = useCargoSummaryQuery({
    variables: { zencargoReference },
    fetchPolicy: 'network-only'
  });

  const handleExpand = (orderId: string, cargoId: string): void => {
    getPackingListLines({
      variables: {
        newCargoService: !!isNewCargoServiceEnabled,
        findBy: { key: PackingListFindByEnum.CARGO_ID, value: cargoId },
        orderId
      }
    });
  };

  const handleComplete = (data: PackingListLinesQueryResult): void => {
    const orderLotData: Record<string, PackingListOrderLot[]> = prepareLots(data);

    setOrderDetails({ ...orderDetails, ...orderLotData });
  };

  const handleSearch = (): void => {
    trackEvent({
      action: PackingListsTrackingAction.SEARCH,
      category: PackingListsTrackingCategory,
      label: searchTerm
    });
  };

  const [getPackingListLines] = usePackingListLinesQueryLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: handleComplete,
    onError: () => addError()
  });

  const handleEdit = (cargoId: string): void => {
    const packingListDetailsPathname: string = packingListsRoutes.packingListDetails.getUrl(zencargoReference, cargoId);

    navigate(packingListDetailsPathname);
  };

  const renderCargoSummary = ({ cargo, calculatedInfo }: PackingListCargoSummary) => {
    const anyRequiredDataIsMissing: boolean =
      !cargo || !calculatedInfo || !cargo.consolidatedCargo || !cargo.mode || !calculatedInfo.cargoSummary;

    if (anyRequiredDataIsMissing) {
      return (
        <InlineEmptyState className="mb-4" title="There are no cargo items created for this booking.">
          Cargo information will be displayed when attached to the shipment.
        </InlineEmptyState>
      );
    }

    const { mode, consolidatedCargo: consolidatedItems } = cargo;
    const { cargoSummary: summary } = calculatedInfo;

    return <CargoSummary cargoMode={mode} consolidatedItems={consolidatedItems} summary={summary} />;
  };

  return (
    <>
      {packingListCustomerId && <AutomaticAccountSwitcher accountIdForUrl={packingListCustomerId} />}
      <Page
        defaultBackUrl={defaultBackUrl}
        headerClassName="pb-24"
        tagline={`For the booking ${zencargoReference}`}
        title="Packing lists"
        width="wide"
      >
        <QueryHandler data={cargoData?.bookings?.nodes?.[0]} error={!!errorInCargoSummary} isLoading={isLoadingCargoSummary}>
          {(packingListCargoSummaryData: PackingListCargoSummary): ReactElement => {
            const { modeOfTransport } = packingListCargoSummaryData;
            const canManageAssignedLots: boolean = checkPermission(packingListCargoSummaryData, 'canManageAssignedLots');
            const handleEditCallback: ((cargoId: string) => void) | undefined = canManageAssignedLots ? handleEdit : undefined;

            return (
              <>
                {renderCargoSummary(packingListCargoSummaryData)}
                <SearchInput
                  onChange={setSearchTerm}
                  onClear={() => setSearchTerm('')}
                  onSearch={handleSearch}
                  placeholder="Search for a container number, order number reference or SKU code"
                  value={searchTerm}
                />
                <QueryHandler data={nodes} error={!!error} isLoading={loading}>
                  {(packingLists: PackingListOrderResult[]) => {
                    return (
                      <div className="mt-4">
                        {packingLists.map(({ cargo: cargoDetails, orders: orderData }: PackingListOrderResult): ReactNode => {
                          const orders: PackingListOrder[] = prepareOrders(orderData, orderDetails, cargoDetails.id);

                          return (
                            <PackingListItem
                              key={cargoDetails.id}
                              cargo={cargoDetails}
                              modeOfTransport={modeOfTransport}
                              onEdit={handleEditCallback}
                              onOrderExpand={handleExpand}
                              orders={orders}
                            />
                          );
                        })}
                        <Pagination pageInfo={paginationInfo} />
                      </div>
                    );
                  }}
                </QueryHandler>
                <OrderDetailsContainer originPath={originPath} />
              </>
            );
          }}
        </QueryHandler>
      </Page>
    </>
  );
};

export default PackingListOverview;
