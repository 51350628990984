import type { FC } from 'react';
import { useLocation } from 'react-router';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { TableConfigurationProvider } from '@zen/DesignSystem';
import OperationsContextProvider from '@zen/OperationsDashboard/context/OperationsContextProvider';
import { SearchQueryContextProvider } from '@zen/Shipments/contexts/SearchQueryContext';
import { validationSchema } from '@zen/Shipments/Filters/FiltersForm/FiltersForm.validation';
import { ShipmentFilterStatus } from '@zen/Shipments/types';

import FilterFields from './components/FilterFields';
import type { OperationsShipmentsFiltersType } from './types';

export const OPS_SHIPMENTS_PAGE_SIZE_KEY = 'opsShipmentsPageSize';

const initialValues: Partial<OperationsShipmentsFiltersType> = {
  status: ShipmentFilterStatus.ACTIVE
};

const emptyFilterValues: OperationsShipmentsFiltersType = {
  customsOnly: [],
  issueTitle: null,
  origins: [],
  destinations: [],
  status: null,
  transportModes: null,
  withinTimeDays: null,
  withinTimeDate: '',
  withinTimeRange: {
    startDate: '',
    endDate: ''
  },
  withinTimeStage: null
};

const OperationsShipments: FC = ({ children }) => {
  const { check } = useGlobalPermissions();
  const { pathname } = useLocation();

  const canViewCustomers: boolean = check('bookings.canViewCustomers');
  const initialHiddenColumns: string[] = canViewCustomers ? [] : ['customerAndRef', 'shippingOrderStatus'];
  const isRoadShipmentsPage: boolean = pathname.includes('road-shipments');

  return (
    <OperationsContextProvider<OperationsShipmentsFiltersType>
      emptyValues={emptyFilterValues}
      filtersFields={() => <FilterFields showTransportMode={!isRoadShipmentsPage} />}
      filtersTitle="Filter shipments"
      filtersValidation={validationSchema}
      initialValues={initialValues}
    >
      <SearchQueryContextProvider>
        <TableConfigurationProvider initialHiddenColumns={initialHiddenColumns} tableId="operationsShipments">
          {children}
        </TableConfigurationProvider>
      </SearchQueryContextProvider>
    </OperationsContextProvider>
  );
};

export default OperationsShipments;
