import type { Option } from '@zen/DesignSystem';
import { cargoTypeLabelMapping, ContainerTypeEnum } from '@zen/types';

export const commonCargoOptions: Option<ContainerTypeEnum>[] = [
  { value: ContainerTypeEnum.X20DV, label: cargoTypeLabelMapping[ContainerTypeEnum.X20DV] },
  { value: ContainerTypeEnum.X40DV, label: cargoTypeLabelMapping[ContainerTypeEnum.X40DV] },
  { value: ContainerTypeEnum.X40HC, label: cargoTypeLabelMapping[ContainerTypeEnum.X40HC] }
];

export const otherCargoOptions: Option<ContainerTypeEnum>[] = [
  { value: ContainerTypeEnum.X20HC, label: cargoTypeLabelMapping[ContainerTypeEnum.X20HC] },
  { value: ContainerTypeEnum.X45HC, label: cargoTypeLabelMapping[ContainerTypeEnum.X45HC] }
];
