import moment from 'moment';
import { FC, useState } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import Form from '@zen/Components/Form/Form';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput/FormDatePickerInput';
import { Button, Dialog } from '@zen/DesignSystem';
import { formatDate } from '@zen/utils/dateTime';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { QuoteOption } from '../../types';
import QuoteOptionItemField from '../QuoteOptionItemField/QuoteOptionItemField';
import { activateQuoteValidationSchema } from './validation';

export type ActivateQuoteFormData = Pick<QuoteOption, 'expiryDate' | 'id' | 'zencargoReference'>;
export interface ActivateQuoteFormSubmitData {
  expiryDate: {
    date: string;
  };
  id: string;
  zencargoReference: string;
}

interface Props {
  initialValues: ActivateQuoteFormData;
  isReadOnly: boolean;
  onSubmit: (values: ActivateQuoteFormSubmitData) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const ActivateQuoteForm: FC<Props> = (props) => {
  const { initialValues, isReadOnly, onSubmit, onSuccess } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (initialValues.expiryDate === null) {
    initialValues.expiryDate = {
      date: ''
    };
  }

  if (isReadOnly) {
    return (
      <QuoteOptionItemField
        className="flex flex-col mb-2"
        content={initialValues?.expiryDate?.date ? formatDate(initialValues.expiryDate.date) : ''}
        label="Valid until"
      />
    );
  }

  return (
    <Form
      formButtons={() => null}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={activateQuoteValidationSchema()}
    >
      {(form: FormInstance<ActivateQuoteFormData>) => {
        const currentDay = moment().startOf('day').format(FORMAT_DATE_TRANSFERABLE);
        const handleSetActive = () => {
          form.submitForm();
          setIsModalOpen(false);
        };

        const handleValidation = () => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ date: true; }' is not assignable to type '... Remove this comment to see the full error message
          form.setTouched({ expiryDate: { date: true } });
          form.validateForm();

          if (form.isValid) {
            setIsModalOpen(true);
          }
        };

        return (
          <div className="flex">
            <div className="mr-4">
              <FormDatePickerInput
                label="Valid until:"
                minDate={currentDay}
                name="expiryDate.date"
                pickerPositionY="below"
                placeholder={formatDate(currentDay)}
              />
            </div>
            <div className="pt-6">
              <Button onClick={handleValidation}>Set as active</Button>
            </div>
            <Dialog
              isOpen={isModalOpen}
              message={"You're about to make the quote active and publish it to the customer"}
              onClose={() => setIsModalOpen(false)}
              onConfirm={handleSetActive}
            />
          </div>
        );
      }}
    </Form>
  );
};

export default ActivateQuoteForm;
