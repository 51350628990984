import type { FC } from 'react';

import type { CountryCode } from '@zen/Components/CountrySelect/types';
import PhoneNumberInput from '@zen/Components/PhoneNumberInput';

import FormField, { FormFieldProps } from '../FormField';

interface Props extends FormFieldProps {
  defaultCountry?: CountryCode;
  disabled?: boolean;
}

type PhoneNumberFormField = {
  error: boolean;
  name: string;
  value: string;
};

type PhoneNumberForm = {
  setFieldValue: (name: string, value: string) => void;
};

const FormPhoneNumber: FC<Props> = ({ disabled = false, defaultCountry, ...fieldProps }) => {
  return (
    <FormField {...fieldProps}>
      {({ error, name, value }: PhoneNumberFormField, form: PhoneNumberForm) => {
        const handleChange = (phoneNumber: string) => {
          form.setFieldValue(name, phoneNumber);
        };

        return (
          <PhoneNumberInput
            defaultCountry={defaultCountry}
            disabled={disabled}
            error={error}
            name={name}
            onChange={handleChange}
            value={value}
          />
        );
      }}
    </FormField>
  );
};

export default FormPhoneNumber;
