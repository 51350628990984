import type { FC, ReactNode } from 'react';

import { Icon } from '@zen/DesignSystem';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import type { Nullable, Undefinable } from '@zen/utils/typescript';

import ActivityFeedItemDetails from '../../components/ActivityFeedItemDetails';
import ActivityFeedItemWithIconWrapper from '../../components/ActivityFeedItemWithIconWrapper';
import { Activity, ActivityFeedItemTypeEnum, BookingCollectionMetadataType, CollectionAndDeliveryStatus } from '../../types';
import { ReactComponent as Calendar } from './calendar.svg';
import DateLabel from './DateLabel';

const checkCollectionDeliveryType = (type: string): Undefinable<string> => {
  const labelsToCheck = ['collection', 'delivery'];

  return labelsToCheck.find((label: string) => type.includes(label));
};

interface Props {
  activityMetadata: Activity;
}

const CollectionAndDeliveryItem: FC<Props> = ({ activityMetadata }) => {
  const { createdAt, itemType, metadata, user } = activityMetadata;
  const { locationLabel, scheduledAt } = metadata as BookingCollectionMetadataType;
  const collectionOrDeliveryType = checkCollectionDeliveryType(itemType);
  let status: Nullable<CollectionAndDeliveryStatus> = null;

  switch (itemType) {
    case ActivityFeedItemTypeEnum.REQUESTED_COLLECTION:
    case ActivityFeedItemTypeEnum.REQUESTED_DELIVERY:
      status = 'requested';
      break;
    case ActivityFeedItemTypeEnum.REREQUESTED_COLLECTION:
    case ActivityFeedItemTypeEnum.REREQUESTED_DELIVERY:
      status = 'rescheduled';
      break;
    case ActivityFeedItemTypeEnum.CONFIRMED_COLLECTION:
    case ActivityFeedItemTypeEnum.CONFIRMED_DELIVERY:
      status = 'confirmed';
      break;
    case ActivityFeedItemTypeEnum.COMPLETED_COLLECTION:
    case ActivityFeedItemTypeEnum.COMPLETED_DELIVERY:
      status = 'completed';
      break;
  }

  const title: string = collectionOrDeliveryType
    ? `${removeSpecialCharactersAndUpperFirst(collectionOrDeliveryType)} ${status}`
    : '';
  const getSubtitle = (): ReactNode => (
    <>
      <DateLabel
        collectionOrDeliveryStatus={status}
        itemType={itemType}
        metadata={metadata as BookingCollectionMetadataType}
        scheduledAt={scheduledAt}
      />
      <div className="mt-2 text-xs text-grey-base">{locationLabel}</div>
    </>
  );

  return (
    <ActivityFeedItemWithIconWrapper backgroundIcon={<Calendar />} className="group">
      <ActivityFeedItemDetails
        createdAt={createdAt}
        icon={<Icon className="text-green-dark" icon="zicon-tickoval" />}
        subtitle={getSubtitle()}
        title={title}
        user={user}
      />
    </ActivityFeedItemWithIconWrapper>
  );
};

export { checkCollectionDeliveryType };

export default CollectionAndDeliveryItem;
