import moment from 'moment';

import type { Optional } from '@zen/utils/typescript';

import { VoyageMilestoneTime } from '../../types';

// this is a temporary solution will be removed.
export const getIsoDate = (date: string): string => moment(date).utc(true).toISOString();

export const getModalTitle = (date: Optional<string>, label: Optional<string>, type: VoyageMilestoneTime): string => {
  if (!label) {
    return 'Updating milestone estimate';
  }

  const milestoneName: string = label.toLowerCase();

  if (type === VoyageMilestoneTime.ESTIMATED) {
    return `Updating ${milestoneName} estimate`;
  }

  return date ? `Updating ${milestoneName}` : `Set ${milestoneName} as current milestone`;
};
