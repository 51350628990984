import { useApolloClient } from '@apollo/client';
import Cookies from 'js-cookie';

import { useAccountId } from '@zen/App/AccountIdContext';
import api from '@zen/utils/api';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import authStore from '../authStore';

const useAuth = () => {
  const { setLoggedIn, getLoggedIn, unsetLoggedIn, removeAccountInfo } = authStore();
  const { resetStore } = useApolloClient();
  const { setAccountId, removeAccountId, fetchAccountId } = useAccountId();

  const isAuthenticated: boolean = !!getLoggedIn();

  const setAuthenticated = (authenticated: boolean): void => {
    if (authenticated) {
      setLoggedIn();
    } else {
      unsetLoggedIn();
    }
  };

  const loginWithAccountId = (id: string): void => {
    setAccountId(id);
    setAuthenticated(true);
  };

  const login = async (email: string, password: string): Promise<IOkOrErrorResult> => {
    try {
      resetStore();

      await api.logIn({ email, password });
      const accountId: string = await fetchAccountId();

      loginWithAccountId(accountId);
    } catch (e) {
      return Promise.resolve({ ok: null, error: 'Login failed. Email or password is not correct.' });
    }

    return Promise.resolve({ ok: true, error: false });
  };

  const logout = async (): Promise<void> => {
    removeAccountId();
    removeAccountInfo();
    setAuthenticated(false);
    Cookies.remove('ocean_csrf_token');
    await api.logOut();
  };

  return {
    login,
    logout,
    isAuthenticated,
    loginWithAccountId
  };
};

export default useAuth;
