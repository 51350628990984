import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetDocumentTemplatesListQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetDocumentTemplatesListQueryResult = { __typename?: 'Query' } & {
  documentTemplates: Array<{ __typename?: 'DocumentTemplate' } & Pick<GraphQLTypes.DocumentTemplate, 'name' | 'id'>>;
};

export const GetDocumentTemplatesListDocument = /* #__PURE__ */ gql`
  query getDocumentTemplatesList {
    documentTemplates {
      name
      id
    }
  }
`;

/**
 * __useGetDocumentTemplatesListQuery__
 *
 * To run a query within a React component, call `useGetDocumentTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTemplatesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocumentTemplatesListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDocumentTemplatesListQueryResult, GetDocumentTemplatesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetDocumentTemplatesListQueryResult, GetDocumentTemplatesListQueryVariables>(
    GetDocumentTemplatesListDocument,
    options
  );
}
export function useGetDocumentTemplatesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTemplatesListQueryResult, GetDocumentTemplatesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetDocumentTemplatesListQueryResult, GetDocumentTemplatesListQueryVariables>(
    GetDocumentTemplatesListDocument,
    options
  );
}
export type GetDocumentTemplatesListQueryHookResult = ReturnType<typeof useGetDocumentTemplatesListQuery>;
export type GetDocumentTemplatesListLazyQueryHookResult = ReturnType<typeof useGetDocumentTemplatesListLazyQuery>;
