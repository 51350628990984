import type { FC } from 'react';

import type { IconType } from '@zen/types';

import Icon from '../Icon';
import StyledButton, { StyledButtonProps } from './StyledButton';

interface Props extends StyledButtonProps {
  iconLeft?: IconType;
  iconRight?: IconType;
}

const Button: FC<Props> = (props) => {
  const { children, iconLeft, iconRight, disabled = false, onClick, className = '', type, ...rest } = props;

  const childrenClassNames = 'flex justify-center items-center whitespace-nowrap';

  const buttonClassNames = `${className} h-10 px-4 space-x-2 rounded`;

  const renderIcon = (icon: IconType) => {
    return typeof icon === 'string' ? <Icon className="text-sm" icon={icon} /> : icon;
  };

  return (
    <StyledButton
      className={buttonClassNames}
      data-component="button"
      disabled={disabled}
      onClick={onClick}
      type={type}
      {...rest}
    >
      {iconLeft && renderIcon(iconLeft)}
      <span className={childrenClassNames}>{children}</span>
      {iconRight && renderIcon(iconRight)}
    </StyledButton>
  );
};

export type { Props as ButtonProps };

export default Button;
