import { get } from 'lodash';

import type { LocalDateTimeRange, UpdateRoadTrackingMissingDataInput } from '@zen/graphql/types.generated';
import { getLocationData } from '@zen/Networks';
import { prepareDateTimePayload } from '@zen/OperationsShipments/EditRoadShipment/helpers';
import { DateAndTimeRange, formatTime } from '@zen/utils/dateTime';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { RoadTrackingFormValues } from './RoadTrackingDataForm/types';
import type { RoadTrackingData } from './types';

const prepareTimeField = (time: Optional<string>): Nullable<string> => {
  return time ? formatTime(time, false) : null;
};

const prepareDateTimeFields = (dateTime: Optional<LocalDateTimeRange>): DateAndTimeRange => {
  return {
    date: dateTime?.startDateTime?.date || '',
    startTime: prepareTimeField(dateTime?.startDateTime?.time) || '',
    endTime: prepareTimeField(dateTime?.endDateTime?.time) || ''
  };
};

export const prepareInitialValues = ({ cargo, haulier }: RoadTrackingData): RoadTrackingFormValues => {
  const cargoItem = cargo?.cargoItems?.[0];

  return {
    collectionConfirmedDate: prepareDateTimeFields(cargoItem?.collection?.confirmedByForwarder),
    collectionLocation: getLocationData(cargoItem?.collection?.location),
    deliveryConfirmedDate: prepareDateTimeFields(cargoItem?.delivery?.confirmedByForwarder),
    deliveryLocation: getLocationData(cargoItem?.delivery?.location),
    haulier,
    trailerId: get(cargoItem, 'trailerId', ''),
    vehiclePlateNumber: cargoItem?.collection?.vehiclePlateNumber
  };
};

export const prepareInput = (
  values: RoadTrackingFormValues,
  cargoId: string,
  zencargoReference: string
): UpdateRoadTrackingMissingDataInput => {
  const {
    collectionConfirmedDate,
    collectionLocation,
    deliveryConfirmedDate,
    deliveryLocation,
    haulier,
    trailerId,
    vehiclePlateNumber
  } = values;

  return {
    cargoId,
    collectionConfirmedByForwarder: collectionConfirmedDate.date ? prepareDateTimePayload(collectionConfirmedDate) : null,
    collectionWarehouseId: collectionLocation?.id,
    deliveryConfirmedByForwarder: deliveryConfirmedDate.date ? prepareDateTimePayload(deliveryConfirmedDate) : null,
    deliveryWarehouseId: deliveryLocation?.id,
    haulierId: haulier?.id,
    trailerId,
    vehiclePlateNumber,
    zencargoReference
  };
};
