import { StageValue } from '@zen/ActivityFeed/types';
import { checkPermission } from '@zen/Auth/authHelper';
import CargoOptions from '@zen/Cargo/CargoOptions';
import DocumentActionsContainer from '@zen/Components/Documents/DocumentActionsContainer';
import NewQuoteOption from '@zen/Quotes/BackofficeQuoteOptions/NewQuoteOption';
import QuoteOptionsContainer from '@zen/Quotes/QuoteOptionsContainer';
import RouteProgress from '@zen/Shipment/RouteProgress';
import DocumentsContainer from '@zen/Shipments/Documents/DocumentsContainer';
import TradeParties from '@zen/TradeParties';
import type { Optional } from '@zen/utils/typescript';

import Notes from '../Notes';
import OrderList from '../OrderList';
import OrderListButton from '../OrderList/OrderListButton';
import ActivityFeedContainer from './ActivityFeedContainer';
import CargoOverview from './CargoOverview';
import IssuesAndActionsContainer from './IssuesAndActionsContainer';
import type { NavItem } from './Navigation';
import {
  AccountPermissions,
  ConfigParams,
  RoutePermissions,
  ShipmentDetailsPageData,
  ShipmentDetailsPageSectionConfig,
  ShipmentPageSection,
  ShipmentPageSectionUrl,
  SortingOrder
} from './types';

export const shipmentDetailsPageCollapsibleItemsKeyName: string = 'shipment-details-page-config';

export const getOrderedColumns = (
  sectionConfig: ConfigParams,
  sortingOrder: SortingOrder
): ShipmentDetailsPageSectionConfig[][] => {
  const { issuesAndActions, activityFeed, cargo, orders, documents, notes, quoteOptions, routeProgress, tradeParties } =
    getSections(sectionConfig);

  let leftColumn: Optional<ShipmentDetailsPageSectionConfig[]> = null;
  let rightColumn: Optional<ShipmentDetailsPageSectionConfig[]> = null;

  switch (sortingOrder) {
    case SortingOrder.BOOKING_REQUESTED:
      leftColumn = [quoteOptions, routeProgress, cargo, orders, documents, tradeParties, issuesAndActions];
      rightColumn = [activityFeed, notes];
      break;

    case SortingOrder.SHIPMENT_CONFIRMED:
    default:
      leftColumn = [routeProgress, cargo, orders, documents, tradeParties, issuesAndActions, quoteOptions];
      rightColumn = [activityFeed, notes];
      break;
  }

  return [leftColumn, rightColumn];
};

export const prepareConfigParams = (
  shipment: ShipmentDetailsPageData,
  routePermissions: RoutePermissions,
  accountPermission: AccountPermissions
): ConfigParams => {
  const { accountUuid, zencargoReference, bookingRequest, quoteRequired } = shipment;
  const { canManageQuotes, canAccessBackoffice, canAccessAccruals, canAccessDocumentCreation } = routePermissions;
  const { canViewForwarder } = accountPermission;

  const canViewNotes: boolean = checkPermission<ShipmentDetailsPageData>(shipment, 'canViewNotes');
  const canViewActivity: boolean = checkPermission<ShipmentDetailsPageData>(shipment, 'canViewActivity');
  const canViewActionItems: boolean = checkPermission<ShipmentDetailsPageData>(shipment, 'canViewActionItems');
  const canViewTradeParties: boolean = checkPermission<ShipmentDetailsPageData>(shipment, 'canViewTradeParties');
  const canManageCargo: boolean = checkPermission<ShipmentDetailsPageData>(shipment, 'canManageCargo');
  const canOpenIssue: boolean = checkPermission<ShipmentDetailsPageData>(shipment, 'canOpenIssue');
  const canEditIssueVisibility: boolean = checkPermission<ShipmentDetailsPageData>(shipment, 'canModifyIssueVisibility');
  const canManageAssignedLots: boolean = checkPermission<ShipmentDetailsPageData>(shipment, 'canManageAssignedLots');
  const canManageCollectionAndDeliveryScheduling: boolean = checkPermission<ShipmentDetailsPageData>(
    shipment,
    'canManageCollectionAndDeliveryScheduling'
  );

  const forwarderApprovalRequired: boolean = !!bookingRequest?.forwarderApprovalRequired;
  const forwarderApproved: boolean = !!bookingRequest?.forwarderApproved;

  const showCargoOptions: boolean = canManageCargo || canManageCollectionAndDeliveryScheduling;
  const showNewQuoteButton: boolean = !(forwarderApprovalRequired && !forwarderApproved) && canAccessBackoffice;
  const shouldDisplayQuotesSection: boolean = canManageQuotes && !!quoteRequired;

  return {
    accountUuid,
    canAccessAccruals,
    canAccessDocumentCreation,
    canEditIssueVisibility,
    canManageAssignedLots,
    shouldDisplayQuotesSection,
    canOpenIssue,
    canViewActionItems,
    canViewActivity,
    canViewForwarder,
    canViewNotes,
    canViewTradeParties,
    showCargoOptions,
    showNewQuoteButton,
    zencargoReference
  };
};

/**
 * Configures all section components for rendering on the shipment details page. This function
 * should be updated with any future sections added to the shipments page
 * @param sectionConfig Inialising data for the shipment details page
 */
export const getSections = (sectionConfig: ConfigParams): ShipmentPageSection => {
  const {
    accountUuid,
    canAccessDocumentCreation,
    canManageAssignedLots,
    shouldDisplayQuotesSection,
    canViewActionItems,
    canViewActivity,
    canViewForwarder,
    canViewNotes,
    canViewTradeParties,
    showCargoOptions,
    showNewQuoteButton,
    zencargoReference
  } = sectionConfig;

  const issuesAndActions: ShipmentDetailsPageSectionConfig = {
    label: 'Issues & Actions',
    url: ShipmentPageSectionUrl.ISSUES_AND_ACTIONS,
    component: <IssuesAndActionsContainer accountUuid={accountUuid} zencargoReference={zencargoReference} />,
    showInDefaultContainer: false,
    enabled: canViewActionItems
  };
  const routeProgress: ShipmentDetailsPageSectionConfig = {
    label: 'Route progress',
    url: ShipmentPageSectionUrl.ROUTE_PROGRESS,
    component: <RouteProgress zencargoReference={zencargoReference} />
  };

  const cargo: ShipmentDetailsPageSectionConfig = {
    label: 'Cargo',
    url: ShipmentPageSectionUrl.CARGO,
    component: <CargoOverview zencargoReference={zencargoReference} />,
    actions: showCargoOptions && <CargoOptions zencargoReference={zencargoReference} />
  };

  const orders: ShipmentDetailsPageSectionConfig = {
    label: 'Orders',
    url: ShipmentPageSectionUrl.ORDERS,
    component: <OrderList zencargoReference={zencargoReference} />,
    actions: canManageAssignedLots && <OrderListButton zencargoReference={zencargoReference} />
  };

  const documents: ShipmentDetailsPageSectionConfig = {
    label: 'Documents',
    url: ShipmentPageSectionUrl.DOCUMENTS,
    actions: (
      <DocumentActionsContainer
        accountId={accountUuid}
        showDocumentCreation={canAccessDocumentCreation}
        zencargoReference={zencargoReference}
      />
    ),
    component: <DocumentsContainer zencargoReference={zencargoReference} />
  };

  const tradeParties: ShipmentDetailsPageSectionConfig = {
    label: 'Parties',
    url: ShipmentPageSectionUrl.TRADE_PARTIES,
    component: (
      <TradeParties accountUuid={accountUuid} canViewForwarder={canViewForwarder} zencargoReference={zencargoReference} />
    ),
    enabled: canViewTradeParties
  };

  const quoteOptions: ShipmentDetailsPageSectionConfig = {
    label: 'Quotes',
    url: ShipmentPageSectionUrl.QUOTE_OPTIONS,
    component: <QuoteOptionsContainer accountUuid={accountUuid} zencargoReference={zencargoReference} />,
    enabled: shouldDisplayQuotesSection,
    actions: showNewQuoteButton && <NewQuoteOption zencargoReference={zencargoReference} />
  };

  const activityFeed: ShipmentDetailsPageSectionConfig = {
    label: 'Recent messages',
    url: ShipmentPageSectionUrl.ACTIVITY_FEED,
    showInDefaultContainer: false,
    component: <ActivityFeedContainer accountId={accountUuid} zencargoReference={zencargoReference} />,
    enabled: canViewActivity,
    showInNavigation: false
  };

  const notes: ShipmentDetailsPageSectionConfig = {
    label: 'Notes',
    url: ShipmentPageSectionUrl.NOTES,
    component: <Notes zencargoReference={zencargoReference} />,
    enabled: canViewNotes,
    showInNavigation: false
  };

  return { issuesAndActions, activityFeed, cargo, documents, notes, quoteOptions, routeProgress, tradeParties, orders };
};

/**
 * Config function to decide the state and sorting of components in the shipment page. Update
 * this function with logic to determine sorting
 */
export const getSortingOrderType = (stage: StageValue): SortingOrder => {
  let sortingOrder: Optional<SortingOrder> = null;

  const isBookingRequested: boolean = [StageValue.BOOKING_REQUESTED, StageValue.QUOTE_REQUESTED, StageValue.PENDING].includes(
    stage
  );

  if (isBookingRequested) {
    sortingOrder = SortingOrder.BOOKING_REQUESTED;
  } else {
    sortingOrder = SortingOrder.SHIPMENT_CONFIRMED;
  }

  return sortingOrder;
};

export const getScrollableNavLinks = (sectionConfig: ConfigParams): NavItem[] => {
  const [defaultLeftColumn, defaultRightColumn] = getOrderedColumns(sectionConfig, SortingOrder.DEFAULT);

  const scrollLinks: NavItem[] = [...defaultLeftColumn, ...defaultRightColumn].map(
    ({ component, ...navItem }: ShipmentDetailsPageSectionConfig) => navItem
  );

  return scrollLinks;
};
