import classnames from 'classnames';
import { differenceBy } from 'lodash';
import moment from 'moment';
import { CSSProperties, ReactElement, ReactNode, useEffect } from 'react';
import { usePrevious } from 'react-use';

import InfiniteScroll from '@zen/Components/InfiniteScroll';

import ActivityFeedItem from '../ActivityFeedItem';
import { isItemWithMetadata, isItemWithNoAdditionalData } from '../helpers/activityFeedHelper';
import { Activity, ActivityFeedDataItem, ActivityFeedItemTypeEnum } from '../types';

interface Props<T> {
  activityFeed: Activity[];
  activityFeedItemsResult: T;
  fetchActivityItemsData: (activityFeed: Activity[]) => void;
  hasMoreResults: boolean;
  hideTimeline?: boolean;
  refetch: () => void;
}

const ActivityFeedList = <T extends {}>(props: Props<T>): ReactElement => {
  const { activityFeed, hasMoreResults, refetch, fetchActivityItemsData, activityFeedItemsResult, hideTimeline } = props;
  const prevActivityFeed = usePrevious(activityFeed);

  const renderedDates: string[] = [];
  const DAY_FORMAT = 'YYYY-DD-MM';

  useEffect(() => {
    const newIds: Activity[] = prevActivityFeed ? differenceBy(activityFeed, prevActivityFeed, 'id') : activityFeed;

    if (newIds.length > 0) {
      fetchActivityItemsData(newIds);
    }
  }, [activityFeed, fetchActivityItemsData]); // eslint-disable-line

  const renderItem = (item: ActivityFeedDataItem, i: number): ReactNode => {
    const itemWithData: ActivityFeedDataItem = {
      ...item,
      data: activityFeedItemsResult ? (activityFeedItemsResult as { [key: string]: object })[item.id] : null
    };

    if (!isItemWithMetadata(item.itemType) && !isItemWithNoAdditionalData(item.itemType) && !itemWithData.data) {
      return null;
    }

    const day: string = moment(item.createdAt).format(DAY_FORMAT);
    const shouldRenderDay: boolean = !renderedDates.includes(day);
    const previousMessage: Activity | false = i > 0 && activityFeed[i - 1];
    let hideUserData: boolean = false;

    if (previousMessage) {
      const isPreviousMessageTextType: boolean = previousMessage.itemType === ActivityFeedItemTypeEnum.TEXT_MESSAGE;

      hideUserData = previousMessage.user?.email === item.user?.email && isPreviousMessageTextType && !shouldRenderDay;
    }

    if (shouldRenderDay) {
      renderedDates.push(day);
    }

    return (
      <div key={i} className="pr-4">
        <ActivityFeedItem
          hideBullet={hideTimeline}
          item={itemWithData}
          shouldRenderDay={shouldRenderDay}
          showUserData={!hideUserData}
        />
      </div>
    );
  };

  const listClassNames = classnames(
    {
      'border-l-2 pl-8 border-azure-base border-dashed': !hideTimeline,
      'pl-10': hideTimeline
    },
    'pt-6 -mb-6 pb-6'
  );

  const listStyles: CSSProperties = hideTimeline ? {} : { marginLeft: '3.2rem' };

  return (
    <InfiniteScroll hasMoreResults={hasMoreResults} isReverse={true} loader={<span key={0} />} onLoad={refetch} useWindow={false}>
      <div className={listClassNames} style={listStyles}>
        {activityFeed.map((item: Activity, index: number) => renderItem(item as ActivityFeedDataItem, index))}
      </div>
    </InfiniteScroll>
  );
};

export default ActivityFeedList;
