import type { ReactNode } from 'react';
import * as Yup from 'yup';

import { Form, FormButtons, FormInput, FormInstance } from '@zen/Components/Form';
import { Button, Modal } from '@zen/DesignSystem';
import { useSavedFilters } from '@zen/utils/Filtering/contexts/SavedFiltersContext';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

interface FormValues {
  name: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required')
});

interface Props<T extends {}> {
  filters: T;
  onCancel: () => void;
  setShowModal: (showModal: boolean) => void;
  showModal: boolean;
}

const SaveQuickFiltersForm = <T extends {}>({ onCancel, showModal, setShowModal, filters }: Props<T>) => {
  const { addSavedFilter } = useSavedFilters();

  const onSubmit = ({ name }: FormValues): Promise<IOkOrErrorResult> => {
    addSavedFilter({ name, filters });
    setShowModal(false);

    return Promise.resolve({ ok: true, error: null });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<T>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Submit">
      <Button data-testid="cancel-button" onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Name your saved filter">
      <Form
        formButtons={renderFormButtons}
        formName="SaveQuickFiltersForm"
        initialValues={{ name: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <>
            <div className="mb-4">Providing a name for your filter helps to identify it easily.</div>
            <FormInput hideLabel={true} label="Name" name="name" placeholder="Filter name" />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default SaveQuickFiltersForm;
