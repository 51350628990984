import { usePagination } from '@zen/utils/hooks/pagination';

import {
  RoadTrackedShipmentsDetailsQueryResult,
  RoadTrackedShipmentsDetailsQueryVariables,
  useRoadTrackedShipmentsDetailsQuery
} from '../graphql';
import type { RoadTrackedResponseNode } from '../LiveShipments/types';
import { RoadTrackedShipmentParams, RoadTrackedShipmentStatus } from '../types';

export const useRoadTrackedShipmentsDetails = ({ status, ...rest }: RoadTrackedShipmentParams) => {
  const limit: number = 50;
  const variables = { ...rest, status: status || RoadTrackedShipmentStatus.ON_TIME };

  return usePagination<
    RoadTrackedShipmentsDetailsQueryResult,
    RoadTrackedShipmentsDetailsQueryVariables,
    RoadTrackedResponseNode
  >(useRoadTrackedShipmentsDetailsQuery, 'roadTrackedShipments', variables, limit, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !status
  });
};
