import type { FC } from 'react';

import PageTitle from '@zen/Components/PageTitle';
import { Icon, TextLink } from '@zen/DesignSystem';
import { consolidationRoutes } from '@zen/Routes';
import ShipmentTrackingIndicator from '@zen/Shipment/components/ShipmentTrackingIndicator';
import type { IconType, ModeOfTransport } from '@zen/types';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import type { Nullable, Undefinable } from '@zen/utils/typescript';

import type { TrackingDetails } from '../types';
import { getModeOfTransportIconName } from './helpers';

interface Props {
  consolidatedShipmentId: Undefinable<string>;
  consolidatedShipmentReference: Undefinable<string>;
  modeOfTransport: ModeOfTransport;
  trackingDetails: Nullable<TrackingDetails>;
  zencargoReference: string;
}

const ShipmentDetailsTitleContent: FC<Props> = (props) => {
  const { consolidatedShipmentId, consolidatedShipmentReference, modeOfTransport, trackingDetails, zencargoReference } = props;
  const { consolidation } = useFeatureFlags();

  const icon: IconType = getModeOfTransportIconName(modeOfTransport);

  return (
    <div className="flex mt-2.5">
      {trackingDetails && (
        <ShipmentTrackingIndicator
          className="mr-2 mt-2.5"
          isTrackable={trackingDetails.isTrackable}
          status={trackingDetails.status}
        />
      )}
      <div className="flex text-navy-dark items-baseline">
        <Icon className="text-2xl mr-2" icon={icon} />
        <PageTitle className="mr-6" title={zencargoReference} />
        {consolidatedShipmentId && consolidation && (
          <div className="flex items-center text-sm mr-4 text-navy-base font-normal">
            <Icon className="mr-2" icon="zicon-pallet" />
            <TextLink linkTo={consolidationRoutes.consolidationShipments.getUrl(consolidatedShipmentId)}>
              Consolidated shipment {consolidatedShipmentReference}
            </TextLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShipmentDetailsTitleContent;
