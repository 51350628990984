import type { FC, ReactNode } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import { MODES } from '@zen/Booking/TransportModeSelect';
import { Form, FormButtons, FormInstance, FormMultiSelect, FormSelect } from '@zen/Components/Form';
import HeadingWithAction from '@zen/Components/HeadingWithAction';
import StickyFooter from '@zen/Components/StickyFooter';
import { Button } from '@zen/DesignSystem';
import type { ShipmentsFilter } from '@zen/graphql/types.generated';
import { useUsedAssignables } from '@zen/Networks';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks/types';
import { useControlTowerPermissionsQuery, useGetShipmentFiltersQuery } from '@zen/Shipments/graphql';
import type { FilterDescription, ShipmentFilters } from '@zen/Shipments/types';
import useAccount from '@zen/utils/hooks/useAccount';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { buildFilters, incotermsOptions, stageFilterOptions, stageOptions } from '../helpers';
import StageWithinFields from '../StageWithinFields';
import { validationSchema } from './FiltersForm.validation';

const domainName = AssignmentTargetTypeEnum.BOOKING;

interface Props {
  initialValues: ShipmentFilters;
  onClose: () => void;
  onReset: () => void;
  onSubmit: (values: ShipmentFilters) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
}

const FiltersForm: FC<Props> = ({ initialValues, onClose, onReset, onSubmit, onSuccess }) => {
  const { accountUuid: accountId } = useAccount();

  const { data } = useGetShipmentFiltersQuery({
    variables: accountId ? { accountUuid: accountId } : undefined,
    fetchPolicy: 'no-cache'
  });

  const { data: controlTowerPermissions } = useControlTowerPermissionsQuery({
    variables: { uuid: accountId },
    fetchPolicy: 'no-cache'
  });

  const { data: origins = [] } = useUsedAssignables([domainName], [AssignmentTypeValue.ORIGIN]);
  const { data: destinations = [] } = useUsedAssignables([domainName], [AssignmentTypeValue.DESTINATION]);
  const { data: consignees = [] } = useUsedAssignables([domainName], [AssignmentTypeValue.CONSIGNEE]);
  const { data: consignors = [] } = useUsedAssignables([domainName], [AssignmentTypeValue.CONSIGNOR]);

  const controlTower = controlTowerPermissions?.account?.controlTower;

  const canViewForwarder: boolean = controlTower
    ? checkPermission<typeof controlTower>(controlTower, 'canViewForwardersOnShipments')
    : false;

  const filterOptions: ShipmentsFilter | undefined = data?.filters?.shipments as ShipmentsFilter | undefined;

  const additionalFilters: FilterDescription[] = buildFilters(
    filterOptions,
    {
      origins,
      destinations,
      consignees,
      consignors
    },
    { canViewForwarder }
  );

  const renderAdditionalFilters = (): ReactNode => {
    return additionalFilters.map(({ name, label, options, isMultiSelect = false }: FilterDescription, i: number) => {
      if (isMultiSelect) {
        return <FormMultiSelect key={i} label={label} name={name} options={options} />;
      }

      return <FormSelect key={i} isClearable={true} label={label} name={name} options={options} />;
    });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<ShipmentFilters>): ReactNode => (
    <StickyFooter>
      <FormButtons isSubmitting={isSubmitting} text="Apply filters">
        <Button data-testid="reset-btn" onClick={onReset} variant="secondary">
          Clear filters
        </Button>
      </FormButtons>
    </StickyFooter>
  );

  const renderForm = (): ReactNode => {
    return (
      <div className="pl-8 pr-2">
        <HeadingWithAction onClick={onClose} title="Filter my shipment list" />
        <div className="px-4 overflow-y-scroll" style={{ height: 'calc(100vh - 12rem)' }}>
          <FormSelect
            isClearable={true}
            label="Shipment status"
            name="status"
            options={stageFilterOptions}
            placeholder="Select..."
          />
          <FormMultiSelect label="Transport mode" name="transportModes" options={MODES} />
          <FormMultiSelect name="incoterms" options={incotermsOptions} />
          <FormMultiSelect label="Milestone" name="stages" options={stageOptions} />
          <StageWithinFields />
          {renderAdditionalFilters()}
        </div>
      </div>
    );
  };

  return (
    <div className="w-160">
      <Form
        formButtons={renderFormButtons}
        formName="ShipmentFiltersForm"
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        validationSchema={validationSchema}
      >
        {renderForm}
      </Form>
    </div>
  );
};

export default FiltersForm;
