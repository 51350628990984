import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import type { NewCargo } from '@zen/Cargo';
import { cargoValues, newCargoTypeLabelMapping } from '@zen/types';

import CargoActionDropdown from '../components/CargoActionDropdown';
import CargoDetails from '../components/CargoDetails';
import CargoInfo from '../components/CargoInfo';
import { formatDimensions, formatWeight, getNewCargoTypeLabel } from '../helpers';

interface Props {
  canManageCargo: boolean;
  cargo: NewCargo;
  onDelete: (cargoId: string) => void;
  zencargoReference: string;
}

const VehicleDetails: FC<Props> = (props) => {
  const { canManageCargo, cargo, onDelete, zencargoReference } = props;
  const {
    looseCargo,
    cargoType,
    dimensions,
    grossWeight,
    hazardous,
    id,
    quantity,
    refrigerated,
    tailLift,
    trailerId,
    volume = null
  } = cargo;

  const cargoQuantity = looseCargo?.quantity || quantity;
  const isAssortedType: boolean = cargoType === cargoValues.looseAssortedCargo;
  const looseCargoLabel: string = looseCargo?.cargoType ? newCargoTypeLabelMapping[looseCargo?.cargoType] : '';
  const quantityLabel: string = cargoQuantity && !isAssortedType ? `${cargoQuantity} x ` : '';
  const vehicleLabel: string = getNewCargoTypeLabel(cargoType);
  const title: string = `${vehicleLabel} - ${quantityLabel} ${looseCargoLabel}`;

  const canManageLots: boolean = checkPermission(cargo, 'canManageAssignedLots');
  const showCargoActionDropdown: boolean = canManageCargo || canManageLots;

  return (
    <CargoDetails
      cargoOptions={{ hazardous: !!hazardous, reefer: !!refrigerated, tailLift: !!tailLift }}
      cargoType={cargoType}
      isNewCargo={true}
      title={title}
    >
      {showCargoActionDropdown && (
        <CargoActionDropdown
          canManageAssignedLots={canManageLots}
          canManageCargo={canManageCargo}
          cargoId={cargo.id}
          onDelete={() => onDelete(id)}
          zencargoReference={zencargoReference}
        />
      )}

      <div className="grid grid-cols-4 gap-4">
        <CargoInfo title="Dimensions">{formatDimensions(dimensions)}</CargoInfo>
        <CargoInfo title="CBM">{volume?.value}</CargoInfo>
        <CargoInfo title="Gross weight">{formatWeight(grossWeight)}</CargoInfo>
        <CargoInfo title="Trailer ID">{trailerId}</CargoInfo>
      </div>
    </CargoDetails>
  );
};

export default VehicleDetails;
