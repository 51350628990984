import { formatNumber } from '@zen/utils/formatting';

import { getMonthDay } from '../chartHelpers';

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
const ChartTooltip = ({ payload: data }) => {
  if (data[0] && data[0].payload) {
    const { dateRange, quantityArriving, quantityPlanned, valueArriving, valuePlanned } = data[0].payload;
    const boxShadow = '2px 5px 23px -6px rgba(163,163,163,1)';

    return (
      <div className="flex bg-white p-4 rounded" style={{ boxShadow }}>
        <div className="flex flex-col justify-end">
          <p className="pb-3">Qty</p>
          <p>Value</p>
        </div>
        <div className="ml-4">
          <p className="font-bold pb-2">
            {getMonthDay(dateRange.startDate)} - {getMonthDay(dateRange.endDate)}
          </p>
          <p className="text-grey-base text-sm pb-4">Arriving/Planned</p>
          <p className="pb-3">
            <span className="font-bold text-navy-base">{formatNumber(quantityArriving)}</span>
            <span className="text-sm text-azure-base">{` / ${formatNumber(quantityPlanned)}`}</span>
          </p>
          <p>
            <span className="font-bold text-navy-base">{valueArriving}</span>
            <span className="text-sm text-azure-base">{` / ${valuePlanned}`}</span>
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export default ChartTooltip;
