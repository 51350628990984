import type { FC } from 'react';

import { FormCheckbox, FormInput, FormSelect } from '@zen/Components/Form';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';
import { incotermsOptions, shipmentTypeOptions, tradeRoleOptions } from '@zen/Shipment/helpers';
import useAccount from '@zen/utils/hooks/useAccount';

interface Props {
  autoFocusedField?: string;
}

const PartiesAndTermsFields: FC<Props> = ({ autoFocusedField }) => {
  const { accountUuid } = useAccount();

  return (
    <div data-testid="parties-and-terms-fields">
      <FormInput
        autoFocus={autoFocusedField === 'clientReference'}
        className="w-48"
        label="Client reference"
        name="clientReference"
        placeholder="e.g. TS17UTY"
      />
      <NetworksContactFormInput
        accountUuid={accountUuid}
        assignmentType={AssignmentTypeValue.CONSIGNOR}
        autoFocus={autoFocusedField === 'consignor'}
        domainName={AssignmentTargetTypeEnum.BOOKING}
        formTitle="Add new consignor"
        isRequired={true}
        label="Consignor"
        name="consignor"
      />
      <NetworksContactFormInput
        accountUuid={accountUuid}
        assignmentType={AssignmentTypeValue.CONSIGNEE}
        autoFocus={autoFocusedField === 'consignee'}
        domainName={AssignmentTargetTypeEnum.BOOKING}
        formTitle="Add new consignee"
        isRequired={true}
        label="Consignee"
        name="consignee"
      />
      <FormSelect
        autoFocus={autoFocusedField === 'incoterms'}
        className="w-48"
        isRequired={true}
        name="incoterms"
        options={incotermsOptions}
      />
      <FormCheckbox
        autoFocus={autoFocusedField === 'customsOnly'}
        className="mb-4"
        label="This is a customs only shipment"
        name="customsOnly"
      />
      <FormSelect
        autoFocus={autoFocusedField === 'shipmentType'}
        className="w-48"
        isRequired={true}
        isSearchable={false}
        name="shipmentType"
        options={shipmentTypeOptions}
      />
      <FormSelect
        autoFocus={autoFocusedField === 'tradeRole'}
        className="w-48"
        isRequired={true}
        isSearchable={false}
        name="tradeRole"
        options={tradeRoleOptions}
      />
    </div>
  );
};

export default PartiesAndTermsFields;
