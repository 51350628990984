import type { FC } from 'react';

import SkeletonLoading from '@zen/Components/SkeletonLoading';

interface Props {
  count?: number;
}
const RepeatedSkeletons: FC<Props> = ({ count }) => (
  <div>
    {[...Array(count)].map((_, i) => (
      <div key={i} className="skeleton-element">
        <div className="flex items-center">
          <div className="p-2 border border-solid border-grey-lighter flex justify-between items-center mb-4 ml-2 w-3/6">
            <div className="flex">
              <div>
                <SkeletonLoading className="mb-px   " height={20} width="w-20" />
                <SkeletonLoading height={10} width="w-4" />
              </div>
              <div>
                <SkeletonLoading className="mb-px" height={20} width="w-12" />
                <SkeletonLoading height={10} width="w-4" />
              </div>
            </div>
            <div className="flex">
              <div>
                <SkeletonLoading height={20} width="w-20" />
                <SkeletonLoading height={10} width="w-4" />
              </div>
              <div>
                <SkeletonLoading height={20} width="w-12" />
                <SkeletonLoading height={10} width="w-4" />
              </div>
            </div>
            <SkeletonLoading height={36} lighter={true} width="w-16" />
          </div>
          <SkeletonLoading className="mb-4 ml-4" height={40} width="w-40" />
        </div>
      </div>
    ))}
  </div>
);
const SkeletonEditRoadShipmentLoading: FC<Props> = ({ count = 2 }) => {
  return (
    <div data-testid="skeleton-edit-road">
      <SkeletonLoading height={28} lighter={true} width="w-32" />
      <SkeletonLoading className="mt-6 mb-2" height={20} width="w-44" />
      <RepeatedSkeletons count={count} />
      <div className="h-px w-3/6 bg-grey-lighter ml-2 mt-2 mb-8" />
      <SkeletonLoading height={28} lighter={true} width="w-32" />
      <SkeletonLoading className="mt-6 mb-2" height={20} width="w-44" />
      <RepeatedSkeletons count={count} />
      <div className="h-px w-3/6 bg-grey-lighter ml-2 mt-2 mb-8" />
      <div className="flex items-center">
        <SkeletonLoading height={36} lighter={true} width="w-24" />
        <SkeletonLoading height={36} width="w-32" />
        <SkeletonLoading height={28} width="w-20" />
      </div>
    </div>
  );
};

export type { Props as SkeletonEditRoadShipmentLoadingProps };

export default SkeletonEditRoadShipmentLoading;
