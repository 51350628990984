import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { Portal } from 'react-portal';

import { Icon } from '@zen/DesignSystem';

import { getOffset } from './helpers';
import TooltipArrow from './TooltipArrow';
import type { TooltipPlacement, TooltipTriggerEvent } from './types';

interface Props {
  children?: ReactNode;
  className?: string;
  delayHide?: number;
  delayShow?: number;
  offset?: [number, number];
  placement?: TooltipPlacement;
  renderInPortal?: boolean;
  showArrow?: boolean;
  tooltipContent: ReactNode;
  trigger?: TooltipTriggerEvent;
  triggerClassName?: string;
}

const Tooltip: FC<Props> = (props) => {
  const {
    children = <Icon className="text-azure-base" icon="zicon-help" />,
    className,
    delayHide = 50,
    delayShow = 50,
    offset,
    placement = 'top',
    renderInPortal = true,
    showArrow = true,
    tooltipContent,
    trigger = 'hover',
    triggerClassName
  } = props;

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    delayHide,
    delayShow,
    interactive: true,
    offset: offset || getOffset(placement),
    placement,
    trigger
  });

  const popoverClassNames: string = cx(
    'p-2 bg-white rounded shadow-interaction max-w-sm z-50',
    'font-sans font-normal text-xs text-grey-dark whitespace-normal leading-tight',
    className
  );

  const renderTooltipContent = (): ReactNode => {
    return (
      <div ref={setTooltipRef} role="tooltip" {...getTooltipProps({ className: popoverClassNames })}>
        <span>{tooltipContent}</span>
        {showArrow && <TooltipArrow {...getArrowProps()} placement={placement} />}
      </div>
    );
  };

  const renderTooltipContentInPortal = (): ReactNode => {
    return <Portal>{renderTooltipContent()}</Portal>;
  };

  return (
    <>
      <span ref={setTriggerRef} className={triggerClassName}>
        {children}
      </span>
      {visible && <div>{renderInPortal ? renderTooltipContentInPortal() : renderTooltipContent()}</div>}
    </>
  );
};

export type { Props as TooltipProps };

export default Tooltip;
