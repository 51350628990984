import { FC, useEffect, useState } from 'react';

import type { PermittedParties } from '@zen/ActivityFeed';
import ActivityFeed from '@zen/ActivityFeed';
import { Activity, ActivityFeedTargetTypeEnum } from '@zen/ActivityFeed/types';

import { useOrderActivityFeedItemsLazyQuery } from './graphql';
import { mergeItemsData, prepareActivityItemsIds } from './helpers/orderActivityFeed.helper';
import type { ActivityFeedDataState } from './types';

interface Props {
  accountId: string;
  orderId: string;
  permittedParties: PermittedParties;
}

const OrdersActivityFeed: FC<Props> = ({ accountId, orderId, permittedParties }) => {
  const [queryActivityFeedItems, activityFeedItemsResult] = useOrderActivityFeedItemsLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const [activityFeed, setActivityFeed] = useState<Activity[]>([]);
  const [feedData, setFeedData] = useState<ActivityFeedDataState>({});

  const { data: orderActivityFeedItems, loading } = activityFeedItemsResult;

  useEffect(() => {
    if (orderActivityFeedItems && !loading) {
      setFeedData(mergeItemsData(feedData, orderActivityFeedItems, activityFeed));
    }
  }, [orderActivityFeedItems, loading]); // eslint-disable-line

  useEffect(() => {
    if (activityFeed.length) {
      const feedIds = prepareActivityItemsIds(activityFeed);
      const variables = {
        textMessagesIdsByTarget: [
          {
            targetId: orderId,
            targetType: ActivityFeedTargetTypeEnum.PURCHASE_ORDER,
            ids: feedIds.textMessagesIds
          }
        ],
        orderId
      };

      queryActivityFeedItems({ variables });
    }
  }, [activityFeed]); // eslint-disable-line

  const fetchActivityFeedItemsData = (activityFeedItems: Activity[]) => {
    setActivityFeed([...activityFeedItems, ...activityFeed]);
  };

  return (
    <ActivityFeed
      accountId={accountId}
      activityFeedItemsResult={feedData}
      fetchActivityItemsData={fetchActivityFeedItemsData}
      permittedParties={permittedParties}
      targetId={orderId}
      targetType={ActivityFeedTargetTypeEnum.PURCHASE_ORDER}
    />
  );
};

export default OrdersActivityFeed;
