import type { FC } from 'react';
import { Link } from 'react-router-dom';

import BookingButtonContainer from '@zen/Components/BookingButton/BookingButtonContainer';
import GlobalSearch from '@zen/Components/GlobalSearch';
import { SIDEBAR_WIDTH_CLASS, TOP_NAVIGATION_HEADER_ZINDEX } from '@zen/Layout';
import Notifications from '@zen/Notifications';

import GuidesLink from '../components/GuidesLink';
import UserMenu from '../components/UserMenu';
import { ReactComponent as Logo } from './logo.svg';

const TopNavigation: FC = () => {
  return (
    <div
      className={`sticky top-0 h-16 shrink-0 bg-white ${TOP_NAVIGATION_HEADER_ZINDEX} border-b border-solid border-grey-lighter flex items-center`}
    >
      <Link className={`pl-6 ${SIDEBAR_WIDTH_CLASS}`} to="/dashboard">
        <Logo className="w-32" data-testid="logo" />
      </Link>
      <div className="flex-1 mr-8 max-w-xl -ml-px">
        <GlobalSearch />
      </div>
      <div className="flex items-center space-x-2 h-full ml-auto">
        <BookingButtonContainer />
        <GuidesLink />
        <Notifications />
        <UserMenu />
      </div>
    </div>
  );
};

export default TopNavigation;
