import cx from 'classnames';

import { Button } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

export interface AddHideButtonProps {
  classNames?: string;
  fullHeight?: boolean;
  hideElementMode?: boolean;
  onClick: () => void;
  text: string;
}

const AddHideButton = ({ classNames = '', fullHeight = false, hideElementMode = false, onClick, text }: AddHideButtonProps) => {
  const icon: IconName = hideElementMode ? 'zicon-minus' : 'zicon-add';
  const baseClassNames: string = 'w-full';

  const classes = cx(
    {
      [classNames]: classNames,
      'h-full': fullHeight
    },
    baseClassNames
  );

  return (
    <Button className={classes} data-testid="add-hide-button" iconLeft={icon} onClick={onClick} variant="secondary">
      {text}
    </Button>
  );
};

export default AddHideButton;
