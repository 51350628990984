import cx from 'classnames';
import { useState } from 'react';

import type { ListItemType } from '@zen/Components/VerticalList/ListItem';
import ListItem from '@zen/Components/VerticalList/ListItem';
import { IconButton } from '@zen/DesignSystem';

interface TemplatesListItemProps {
  handleDelete: (id: string) => void;
  handleSelect: (id: string) => void;
  hasOverwriteAction?: boolean;
  isSelected?: boolean;
  item: ListItemType;
}

const TemplatesListItem = (props: TemplatesListItemProps) => {
  const { handleSelect, handleDelete, isSelected, hasOverwriteAction, item } = props;
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [overwriteConfirmation, setOverwriteConfirmation] = useState<boolean>(false);

  const selectedListItemStyles = cx({
    'bg-navy-base text-white': isSelected,
    'border-red-base': (deleteConfirmation || overwriteConfirmation) && !isSelected
  });

  const actionConfirmation = (onConfirm: () => void, onCancel: () => void) => (
    <div className="flex jusity-between">
      <IconButton className="mr-2" icon="zicon-tick" onClick={onConfirm} title="Confirm" variant="tertiary" />
      <IconButton icon="zicon-cross" onClick={onCancel} title="Cancel" variant="tertiary" />
    </div>
  );

  const handleSelectAction = hasOverwriteAction ? () => setOverwriteConfirmation(true) : () => handleSelect(item.id);

  return (
    <div className="flex justify-between items-center mb-2">
      <ListItem
        isSelected={!!isSelected}
        item={{
          ...item,
          label: overwriteConfirmation ? 'Are you sure you want to overwrite?' : item.label
        }}
        onClick={handleSelectAction}
        selectedItemCustomStyles={selectedListItemStyles}
      />
      {!deleteConfirmation && !overwriteConfirmation && (
        <IconButton icon="zicon-trash" onClick={() => setDeleteConfirmation(true)} title="Delete" variant="secondary" />
      )}
      {deleteConfirmation &&
        actionConfirmation(
          () => handleDelete(item.id),
          () => setDeleteConfirmation(false)
        )}
      {overwriteConfirmation &&
        actionConfirmation(
          () => handleSelect(item.id),
          () => setOverwriteConfirmation(false)
        )}
    </div>
  );
};

export default TemplatesListItem;
