import cx from 'classnames';
import type { FC } from 'react';

import { IconButton } from '@zen/DesignSystem';

const GuidesLink: FC = () => {
  const url: string = 'https://intercom.help/zencargo/en/collections/539641-platform-guide';
  const classNames: string = cx('flex items-center h-full justify-center');

  return (
    <div>
      <a className={classNames} data-component="guides-link" data-testid="guides-link" href={url} target="_blank">
        <IconButton className="text-navy-base" icon="zicon-help-inverse" title="Platform guide" variant="tertiary" />
      </a>
      <span className="intercom-product-tour-hidden-initializer" />
    </div>
  );
};

export default GuidesLink;
