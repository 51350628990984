import cx from 'classnames';
import { kebabCase } from 'lodash';
import moment from 'moment';
import { FC, KeyboardEvent, ReactNode, SyntheticEvent, useRef, useState } from 'react';

import DatePicker from '@zen/Components/DatePicker';
import { DATE_FORMAT } from '@zen/Components/DatePicker/utils';
import HelperText from '@zen/Components/HelperText';
import { Icon, TextInput } from '@zen/DesignSystem';
import useClickAway from '@zen/utils/hooks/useClickAway';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { getPickerPosition } from './helper';
import type { Day, PickerPosition } from './types';

interface Props {
  autoFocus?: boolean;
  disabled?: boolean;
  disabledDayTooltip?: ReactNode;
  displayDateFormat?: string;
  enabledDays?: Day[];
  hasError?: boolean;
  helperText?: string;
  initialDate?: Optional<string>;
  isClearable?: boolean;
  maxDate?: string;
  minDate?: string;
  name?: string;
  onDayChange: (date: Nullable<string>) => void;
  pickerPositionX?: PickerPosition['x'];
  pickerPositionY?: PickerPosition['y'];
  placeholder?: string;
  selectedDate?: Optional<string>;
}

const DatePickerInput: FC<Props> = (props) => {
  const {
    autoFocus,
    disabled = false,
    disabledDayTooltip,
    displayDateFormat = 'll',
    initialDate,
    isClearable,
    minDate,
    maxDate,
    name,
    onDayChange,
    placeholder = 'Jul 5, 2016',
    selectedDate,
    hasError,
    enabledDays,
    pickerPositionX,
    pickerPositionY,
    helperText
  } = props;
  const pickerRef = useRef(null);
  const inputRef = useRef(null);

  const [pickerVisible, setPickerVisible] = useState(false);
  const [pickerPosition, setPickerPosition] = useState<PickerPosition>({ x: 'right', y: 'below' });

  useClickAway(pickerRef, () => setPickerVisible(false));

  const datePickerClassName = cx(
    {
      'opacity-100': pickerVisible,
      'opacity-0 pointer-events-none': !pickerVisible,
      'top-12': pickerPosition.y === 'below',
      'bottom-12': pickerPosition.y === 'above',
      'right-0': pickerPosition.x === 'right',
      'left-0': pickerPosition.x === 'left'
    },
    'z-50 absolute ease-linear transition-all duration-150 overflow-visible'
  );

  const initialSelectedDate = selectedDate && moment(selectedDate, DATE_FORMAT).isValid() ? selectedDate : null;
  const formattedDate =
    displayDateFormat && initialSelectedDate
      ? moment(initialSelectedDate, DATE_FORMAT).locale('en').format(displayDateFormat)
      : '';

  const handleDayChange = (date: string) => {
    setPickerVisible(false);
    onDayChange(date);
  };

  const handleClearDate = (event: SyntheticEvent): void => {
    event.stopPropagation();

    onDayChange(null);
  };

  const showPicker = () => {
    const calculatedPosition: PickerPosition = getPickerPosition(inputRef, pickerRef);
    const position: PickerPosition = {
      x: pickerPositionX || calculatedPosition.x,
      y: pickerPositionY || calculatedPosition.y
    };

    setPickerPosition(position);

    return setPickerVisible(true);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      showPicker();
    }
  };

  const handleClick = () => {
    if (disabled) {
      return false;
    }

    showPicker();
  };

  const showClearIcon: boolean = !!isClearable && !!formattedDate;

  return (
    <div className="relative flex-1" data-testid={`${kebabCase(name)}-datepicker-input`}>
      <div ref={inputRef} className="relative" onClick={() => handleClick()}>
        <TextInput
          autoFocus={autoFocus}
          className="cursor-pointer px-9"
          data-component="date-picker-input"
          disabled={disabled}
          error={hasError}
          id={name}
          name={name}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
          readOnly={true}
          value={formattedDate}
        />
        <Icon className="absolute top-3.5 ml-2 mr-4 cursor-pointer text-grey-light" icon="zicon-calendar" />
        {showClearIcon && (
          <div className="absolute top-3 right-1 border-grey-light">
            <Icon
              className="cursor-pointer"
              data-testid={`${kebabCase(name)}-datepicker-clear-icon`}
              icon="zicon-close"
              interactive={true}
              onClick={handleClearDate}
            />
          </div>
        )}
      </div>
      <div ref={pickerRef} className={datePickerClassName}>
        <DatePicker
          disabledDayTooltip={disabledDayTooltip}
          enabledDays={enabledDays}
          initialDate={initialDate}
          isInline={false}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleDayChange}
          selectedDate={initialSelectedDate}
          size="small"
        />
      </div>
      <HelperText helperText={helperText} />
    </div>
  );
};

export type { Props as DatePickerInputProps };
export default DatePickerInput;
