import type { LastUpdatedType } from '@zen/Components/LastUpdated/types';
import type { Undefinable } from '@zen/utils/typescript';

import type { VoyageMilestoneNameEnum, VoyageMilestoneType } from './types';

const sortByDate = (lastUpdated: LastUpdatedType, nextLastUpdated: LastUpdatedType): number => {
  if (lastUpdated.updatedAt && nextLastUpdated.updatedAt) {
    return lastUpdated.updatedAt < nextLastUpdated.updatedAt ? 1 : -1;
  }

  return -1;
};

export const getLastUpdated = (milestones: VoyageMilestoneType[]): Undefinable<LastUpdatedType> => {
  const sortedMilestones: LastUpdatedType[] = milestones
    .map((milestone: VoyageMilestoneType) => ({ updatedAt: milestone.updatedAt.date, updatedBy: milestone.updatedBy }))
    .sort(sortByDate);

  const lastUpdateMilestone: Undefinable<LastUpdatedType> = sortedMilestones[0];

  return lastUpdateMilestone;
};

export const isVoyageMilestoneCompleted = (
  milestones: VoyageMilestoneType[],
  milestoneName: VoyageMilestoneNameEnum
): boolean => {
  const milestoneIndex: number = milestones.findIndex(({ name }) => name === milestoneName);
  const currentMilestoneIndex: number = milestones.findIndex(({ current }) => current);

  if (currentMilestoneIndex === -1) {
    return false;
  }

  return currentMilestoneIndex >= milestoneIndex;
};
