import { createContext } from 'react';

import type { FeatureFlags, UserContextData } from './types';

export const featureFlags: FeatureFlags = {
  analytics: false,
  consolidation: false,
  contractId: false,
  controlTower: false,
  customRoles: false,
  documentParser: false,
  embeddedDashboards: false,
  freightSpend: false,
  newCargoService: false,
  packingListGlobalSearch: false,
  profileRoles: false,
  topLevelAccountSuspension: false,
  trackedShipments: false,
  utilisation: false,
  workflows: false
};

export const initialContext: UserContextData = {
  accountUuid: '',
  featureFlags,
  userProfile: {
    division: null,
    isAdmin: false,
    isDivision: false,
    isParent: false,
    firstName: '',
    lastName: '',
    role: null,
    email: '',
    phoneNumber: '',
    uuid: '',
    registeredCompanyName: '',
    preferences: {}
  },
  refetchUser: () => {}
};

const UserContext = createContext<UserContextData>(initialContext);

export default UserContext;
