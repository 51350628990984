import type { FC } from 'react';

import InlineEditableField from '@zen/Components/InlineEditableField';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateCarrierBookingReferenceMutation } from '../graphql';

interface Props {
  canUpdateCarrierBookingReference: boolean;
  carrierBookingReference: string;
  zencargoReference: string;
}

const CarrierBookingReferenceTransportScheduleInput: FC<Props> = ({
  carrierBookingReference,
  zencargoReference,
  canUpdateCarrierBookingReference
}) => {
  const { addSuccess, addError } = useNotification();

  const [updateCarrierBookingReference] = useUpdateCarrierBookingReferenceMutation();

  const handleUpdateClientCarrierBookingReference = async (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateCarrierBookingReference({
          variables: {
            input: {
              zencargoReference,
              carrierBookingReference: value
            }
          },
          refetchQueries: ['getTrackingData']
        }),
      onError: () => addError(),
      onSuccess: () => addSuccess('Carrier booking reference updated.')
    });
  };

  return (
    <InlineEditableField
      className="py-3.5 px-2"
      disabled={!canUpdateCarrierBookingReference}
      name="Carrier booking ref"
      onUpdate={handleUpdateClientCarrierBookingReference}
      overLabel="Carrier booking ref"
      value={carrierBookingReference}
    />
  );
};

export default CarrierBookingReferenceTransportScheduleInput;
