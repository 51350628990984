import type { ReactNode } from 'react';

import EditConsolidation from '../EditConsolidation/EditConsolidation';
import NewConsolidation from '../NewConsolidation/NewConsolidation';
import ShowConsolidationDetails from '../ShowConsolidationDetails/ShowConsolidationDetails';

export interface ConfigItem {
  component: ReactNode;
  pageTitle: string;
  path: string;
}

export enum ConsolidationDetailsTabType {
  CREATE = 'create',
  DETAILS = 'details',
  EDIT = 'edit'
}

export const consolidationDetailsTabConfig: Record<ConsolidationDetailsTabType, ConfigItem> = {
  create: {
    component: <NewConsolidation />,
    pageTitle: 'Adding a new consolidated shipment',
    path: ConsolidationDetailsTabType.CREATE
  },
  edit: {
    component: <EditConsolidation />,
    pageTitle: 'Editing a consolidated shipment',
    path: ConsolidationDetailsTabType.EDIT
  },
  details: {
    component: <ShowConsolidationDetails />,
    pageTitle: 'Consolidated shipment',
    path: ConsolidationDetailsTabType.DETAILS
  }
};
