import moment from 'moment';

import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';

import type { AnalyticsViewPeriod } from './types';

interface DataRange {
  endDate: string;
  startDate: string;
}

export const getDateRange = (analyticsPeriod: AnalyticsViewPeriod): DataRange => {
  const [amount, units]: any[] = analyticsPeriod.split('_');
  const endDate = moment().format(FORMAT_DATE_TRANSFERABLE);
  const startDate = moment().add(-amount, units).format(FORMAT_DATE_TRANSFERABLE);

  return {
    startDate,
    endDate
  };
};
