import { CargoType, LooseCargoTypeEnum, PalletTypeEnum } from '@zen/Cargo';
import type { NewCargoType } from '@zen/types';
import { cargoTypeLabelMapping, cargoValues, ContainerTypeEnum, newCargoTypeLabelMapping, VehicleTypeEnum } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

import x20 from './images/20-container@3x.png';
import x40 from './images/40-container@3x.png';
import x45 from './images/45-container@3x.png';
import assorted from './images/assorted.png';
import boxedTrailer from './images/boxed-trailer.png';
import boxes from './images/boxes.png';
import curtainSided from './images/curtain-sided.png';
import van from './images/express-van.png';
import pallet from './images/pallet.png';

const imageSourceMapping: Record<CargoType, string> = {
  [ContainerTypeEnum.X20DV]: x20,
  [ContainerTypeEnum.X20HC]: x20,
  [ContainerTypeEnum.X40DV]: x40,
  [ContainerTypeEnum.X40HC]: x40,
  [ContainerTypeEnum.X45HC]: x45,
  [PalletTypeEnum.EURO]: pallet,
  [PalletTypeEnum.UK]: pallet,
  [PalletTypeEnum.OTHER]: pallet,
  [PalletTypeEnum.US]: pallet,
  [LooseCargoTypeEnum.PALLETS]: pallet,
  [LooseCargoTypeEnum.BOXES_OR_CRATES]: boxes,
  [LooseCargoTypeEnum.ASSORTED_CARGO]: assorted,
  [VehicleTypeEnum.TRAILER_13_6_M_BOX]: boxedTrailer,
  [VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED]: curtainSided,
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER]: boxedTrailer,
  [VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF]: boxedTrailer,
  [VehicleTypeEnum.TRAILER_18_T_BOX]: boxedTrailer,
  [VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED]: curtainSided,
  [VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF]: boxedTrailer,
  [VehicleTypeEnum.TRAILER_7_5_T_BOX]: boxedTrailer,
  [VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED]: boxedTrailer,
  [VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF]: boxedTrailer,
  [VehicleTypeEnum.VAN_BOX]: van,
  [VehicleTypeEnum.VAN_CURTAIN_SIDED]: van,
  [VehicleTypeEnum.VAN_SLIDING_ROOF]: van
};

const newCargoImageSourceMapping: Record<NewCargoType, string> = {
  [cargoValues.containerX20Dv]: x20,
  [cargoValues.containerX20Hc]: x20,
  [cargoValues.containerX40Dv]: x40,
  [cargoValues.containerX40Hc]: x40,
  [cargoValues.containerX45Hc]: x45,
  [cargoValues.loosePalletEuro]: pallet,
  [cargoValues.loosePalletOther]: pallet,
  [cargoValues.loosePalletUk]: pallet,
  [cargoValues.loosePalletUs]: pallet,
  [cargoValues.loosePallet]: pallet,
  [cargoValues.looseBoxesOrCrates]: boxes,
  [cargoValues.looseAssortedCargo]: assorted,
  [cargoValues.trailer136MBox]: boxedTrailer,
  [cargoValues.trailer136MCurtainSided]: curtainSided,
  [cargoValues.trailer136MMegatrailer]: boxedTrailer,
  [cargoValues.trailer136MSlidingRoof]: boxedTrailer,
  [cargoValues.trailer18TBox]: boxedTrailer,
  [cargoValues.trailer18TCurtainSided]: curtainSided,
  [cargoValues.trailer18TSlidingRoof]: boxedTrailer,
  [cargoValues.trailer75TBox]: boxedTrailer,
  [cargoValues.trailer75TCurtainSided]: boxedTrailer,
  [cargoValues.trailer75TSlidingRoof]: boxedTrailer,
  [cargoValues.trailerVanBox]: van,
  [cargoValues.trailerVanCurtainSided]: van,
  [cargoValues.trailerVanSlidingRoof]: van
};

export const getCargoImage = (cargoType: NewCargoType | CargoType, isNewCargo: boolean = false): string => {
  return isNewCargo ? newCargoImageSourceMapping[cargoType as NewCargoType] : imageSourceMapping[cargoType as CargoType];
};

export const getCargoLabel = (cargoType: Optional<NewCargoType> | CargoType, isNewCargo: boolean = false): string => {
  if (!cargoType) {
    return '';
  }

  return isNewCargo ? newCargoTypeLabelMapping[cargoType as NewCargoType] : cargoTypeLabelMapping[cargoType as CargoType];
};
