import type { RoutesConfig } from './types';

export const PACKING_LISTS_ROUTE_PREFIX = '/dashboard/packing-lists';

interface PackingListsRoutes extends RoutesConfig {
  packingListDetails: {
    getUrl: (zencargoReference: string, cargoId: string) => string;
    path: string;
  };
  packingListIndex: {
    getUrl: (zencargoReference: string) => string;
    path: string;
  };
}

const packingListsRoutes: PackingListsRoutes = {
  packingListDetails: {
    path: `${PACKING_LISTS_ROUTE_PREFIX}/:zencargoReference/cargo/:cargoId/:id?`,
    getUrl: (zencargoReference: string, cargoId: string) => `${PACKING_LISTS_ROUTE_PREFIX}/${zencargoReference}/cargo/${cargoId}`
  },
  packingListIndex: {
    path: `${PACKING_LISTS_ROUTE_PREFIX}/:zencargoReference/:id?`,
    getUrl: (zencargoReference: string) => `${PACKING_LISTS_ROUTE_PREFIX}/${zencargoReference}`
  }
};

export default packingListsRoutes;
