import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type PackingListOrdersQueryVariables = GraphQLTypes.Exact<{
  findBy: GraphQLTypes.PackingListFindBy;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type PackingListOrdersQueryResult = { __typename?: 'Query' } & {
  packingLists?: GraphQLTypes.Maybe<
    { __typename?: 'PackingListConnection' } & Pick<GraphQLTypes.PackingListConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'PackingList' } & {
                orders?: GraphQLTypes.Maybe<
                  Array<
                    { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id' | 'orderReferenceNumber'> & {
                        manufacturer?: GraphQLTypes.Maybe<
                          | ({ __typename?: 'NetworksLegacyOrgLoc' } & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                              >;
                            })
                          | ({ __typename?: 'NetworksOrgLoc' } & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                              >;
                            })
                          | ({ __typename?: 'NetworksTerminal' } & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                              >;
                            })
                        >;
                      }
                  >
                >;
              }
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const PackingListOrdersQueryDocument = /* #__PURE__ */ gql`
  query packingListOrdersQuery($findBy: PackingListFindBy!, $after: String, $before: String, $first: Int, $last: Int) {
    packingLists(findBy: $findBy, after: $after, before: $before, first: $first, last: $last) {
      nodes {
        orders {
          id
          orderReferenceNumber
          manufacturer {
            label {
              short
            }
          }
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __usePackingListOrdersQuery__
 *
 * To run a query within a React component, call `usePackingListOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackingListOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackingListOrdersQuery({
 *   variables: {
 *      findBy: // value for 'findBy'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function usePackingListOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<PackingListOrdersQueryResult, PackingListOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<PackingListOrdersQueryResult, PackingListOrdersQueryVariables>(PackingListOrdersQueryDocument, options);
}
export function usePackingListOrdersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PackingListOrdersQueryResult, PackingListOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<PackingListOrdersQueryResult, PackingListOrdersQueryVariables>(
    PackingListOrdersQueryDocument,
    options
  );
}
export type PackingListOrdersQueryHookResult = ReturnType<typeof usePackingListOrdersQuery>;
export type PackingListOrdersQueryLazyQueryHookResult = ReturnType<typeof usePackingListOrdersQueryLazyQuery>;
