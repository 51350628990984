import type { FC } from 'react';

import Page from '@zen/Components/Page';
import PageNotFound from '@zen/Components/PageNotFound';
import { Loading } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks';
import { useMyOrganisationQuery } from '@zen/Networks/graphql';

import EditMyOrganisationForm from './EditMyOrganisationForm';

interface Props {
  networkId: string;
}

const EditMyOrganisation: FC<Props> = ({ networkId }) => {
  const { data, loading, error } = useMyOrganisationQuery({
    variables: {
      networkId
    }
  });

  const { routes } = useNetworksContext();
  const myOrganisation = data?.network?.myOrganisation;

  if (loading) {
    return <Loading />;
  }

  if (error || !myOrganisation) {
    return <PageNotFound />;
  }

  const { vat, eori, name, id } = myOrganisation;

  return (
    <Page defaultBackUrl={routes.myOrganisation.details.getUrl()} title="Edit my organisation" width="narrow">
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ initialValues: { vat: Maybe<string> | unde... Remove this comment to see the full error message */}
      <EditMyOrganisationForm initialValues={{ vat, eori, name }} organisationId={id} />
    </Page>
  );
};

export default EditMyOrganisation;
