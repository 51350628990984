import type { FC } from 'react';

import type { PageInfo } from '@zen/types';

import Button from '../Button';

interface Props {
  pageInfo: PageInfo;
}

const Pagination: FC<Props> = ({ pageInfo: { hasNextPage, hasPreviousPage, onNext, onPrevious } }) => {
  if (!hasPreviousPage && !hasNextPage) {
    return null;
  }

  return (
    <div className="flex justify-between w-full mt-3 text-sm" data-component="pagination" data-testid="pagination">
      <Button disabled={!hasPreviousPage} iconLeft="zicon-chevron-left" onClick={onPrevious} variant="tertiary">
        Previous
      </Button>
      <Button disabled={!hasNextPage} iconRight="zicon-chevron-right" onClick={onNext} variant="tertiary">
        Next
      </Button>
    </div>
  );
};

export type { Props as PaginationProps };

export default Pagination;
