import type { SidebarLink } from '../types';

export const config: SidebarLink[] = [
  {
    icon: 'zicon-shipment',
    label: 'Admin shipments',
    url: '',
    children: [
      {
        label: 'All shipments',
        url: '/dashboard/customer-admin/all-shipments'
      }
    ]
  }
];
