import type {
  AuthorizationResult,
  Cargo,
  CargoConsolidatedItemType,
  CargoContainerType,
  CargoDimensionsType,
  CargoItemTypeEnum,
  CargoLooseCargoType,
  CargoModeEnum,
  CargoVehicleType,
  CargoWeight,
  CargoWeightType,
  CollectionDetails,
  ConsolidatedLooseCargoItem,
  ContainerTypeEnum,
  CoreCargo,
  DeliveryDetails,
  Dimensions,
  LooseCargoTypeEnum,
  Money,
  PalletTypeEnum,
  RiskLevelsEnum,
  Tracking,
  VehicleTypeEnum
} from '@zen/graphql/types.generated';
import type { NewCargoType } from '@zen/types';
import type { DeepNullable, Modify, Nullable, Optional } from '@zen/utils/typescript';

import type { CargoItemsQueryResult, GetCargoDetailsQueryResult } from './graphql';

export type {
  AuthorizationResult,
  Cargo,
  CargoCargoItem,
  CargoConsolidatedItemType,
  CargoContainerType,
  CargoDimensionsInputType,
  CargoDimensionsType,
  CargoLooseCargoType,
  CargoRelation,
  CargoSummary,
  CargoVehicleType,
  CargoWeight,
  CargoWeightInputType,
  CargoWeightType,
  CollectionDetails,
  ConsolidatedCargo,
  ConsolidatedLooseCargoItem,
  CoreCargo,
  CreateContainerCargoItemInput,
  CreateLooseCargoItemInput,
  CreateVehicleCargoItemInput,
  DeliveryDetails,
  Dimensions,
  ModeOfTransport,
  Money,
  UpdateContainerCargoItemInput,
  UpdateLooseCargoItemInput,
  UpdateVehicleCargoItemInput,
  ValueOfGoods,
  Volume
} from '@zen/graphql/types.generated';
export {
  CargoDimensionsUnitEnum,
  CargoItemSubTypeEnum,
  CargoItemTypeEnum,
  CargoModeEnum,
  CargoWeightUnitEnum,
  ContainerTypeEnum,
  Currency,
  LooseCargoTypeEnum,
  PalletTypeEnum,
  RiskLevelsEnum,
  VehicleTypeEnum
} from '@zen/graphql/types.generated';

export type CargoType = ContainerTypeEnum | LooseCargoTypeEnum | PalletTypeEnum | VehicleTypeEnum;

export type CoreCargoExtended = Modify<
  CoreCargo,
  {
    cargoType: Nullable<NewCargoType>;
  }
>;

export interface NewCargo extends Omit<CoreCargo, 'deletedAt' | 'cargoType' | 'cargoRelations'> {
  canManageAssignedLots?: AuthorizationResult;
  canManageCollectionLocation?: AuthorizationResult;
  canManageDeliveryLocation?: AuthorizationResult;
  canUpdateVehicleTrailerId?: AuthorizationResult;
  canViewCollectionDetails?: AuthorizationResult;
  canViewDeliveryDetails?: AuthorizationResult;
  cargoType: Optional<NewCargoType>;
  collection?: CollectionDetails;
  delivery?: DeliveryDetails;
  id: string;
  looseCargo?: Optional<CoreCargoExtended>;
}

export interface CargoTransition {
  cargoList: Optional<NewCargo[]>;
  mode?: CargoModeEnum;
  tracking?: Tracking;
}

export type LegacyCargo = Partial<
  Modify<
    Cargo,
    {
      cargoItems?: Nullable<CargoItem[]>;
    }
  >
>;

export interface CargoItemType {
  canManageAssignedLots: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };
  canManageCollectionLocation: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };
  canManageDeliveryLocation: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };

  canUpdateVehicleTrailerId: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };
  canViewCollectionDetails: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };
  canViewDeliveryDetails: {
    __typename?: 'AuthorizationResult';
    value: boolean;
  };
  cargoDetails?: string;
  cbm?: number;
  chargeableWeight?: CargoWeightType;
  collection?: CollectionDetails;
  containerNumber?: string;
  containerSealNumber?: string;
  containerType?: ContainerTypeEnum;
  delivery?: DeliveryDetails;
  dimensions?: Nullable<CargoDimensionsType>;
  grossWeight?: CargoWeightType;
  hazardous?: boolean;
  id: string;
  label?: string;
  looseCargoType?: LooseCargoTypeEnum;
  overweight?: boolean;
  palletType?: PalletTypeEnum;
  quantity?: number;
  reefer?: boolean;
  riskLevel?: RiskLevelsEnum;
  scheduledAt?: string;
  stackable?: boolean;
  tailLift?: boolean;
  title?: string;
  trailerId?: string;
  type?: CargoItemTypeEnum;
  valueOfGoods?: Money;
  vehicleType?: VehicleTypeEnum;
}

export interface LooseCargoFormData {
  actualCbm: Optional<number>;
  cargoType: Optional<NewCargoType>;
  chargeableWeight: DeepNullable<CargoWeight>;
  containerNumber: Optional<string>;
  dimensions: Optional<Dimensions>;
  grossWeight: DeepNullable<CargoWeight>;
  hazardous: Optional<boolean>;
  id?: string;
  looseCargoType: Optional<NewCargoType>;
  quantity: Optional<number>;
  refrigerated: Optional<boolean>;
  stackable: Optional<boolean>;
  trailerId: Optional<string>;
}

export interface VehicleCargoFormData {
  actualCbm: Optional<number>;
  dimensions: Optional<Dimensions>;
  grossWeight: DeepNullable<CargoWeight>;
  hazardous: Optional<boolean>;
  looseCargoType: Optional<NewCargoType>;
  quantity: Optional<number>;
  refrigerated: Optional<boolean>;
  riskLevel: Optional<RiskLevelsEnum>;
  tailLift: Optional<boolean>;
  trailerId: Optional<string>;
  valueOfGoods: Optional<Money>;
  vehicleType: Optional<NewCargoType>;
}

export interface FullContainerFormData {
  actualCbm: Optional<number>;
  containerNumber: Optional<string>;
  containerSealNumber: Optional<string>;
  containerType: NewCargoType;
  grossWeight: DeepNullable<CargoWeight>;
  hazardous: Optional<boolean>;
  looseCargoType: Optional<NewCargoType>;
  overweight: Optional<boolean>;
  quantity: Optional<number>;
  refrigerated: Optional<boolean>;
}

// @ts-expect-error ts-migrate(2339) FIXME: Property '0' does not exist on type 'Maybe<Maybe<{... Remove this comment to see the full error message
export type CargoItemsData = CargoItemsQueryResult['bookings']['nodes'][0];

export interface CargoItem
  extends Partial<Omit<CargoContainerType, '__typename'>>,
    Partial<Omit<CargoLooseCargoType, '__typename'>>,
    Partial<Omit<CargoVehicleType, '__typename'>> {
  __typename?: string;
  id: string;
}

// @ts-expect-error ts-migrate(2339) FIXME: Property '0' does not exist on type 'Maybe<Maybe<{... Remove this comment to see the full error message
export type CargoDetailsData = GetCargoDetailsQueryResult['bookings']['nodes'][0];

export type CargoDetailsItem = CargoDetailsData['cargo']['cargoItems'][0];

export interface CargoUpdateMutationResponse {
  cargoItem: {
    type: CargoItemTypeEnum;
  };
}

export type ContainerNewCargoType = Extract<
  NewCargoType,
  'containerX20Dv' | 'containerX20Hc' | 'containerX40Dv' | 'containerX40Hc' | 'containerX45Hc'
>;

export type PalletNewCargoType = Extract<
  NewCargoType,
  'loosePalletEuro' | 'loosePalletOther' | 'loosePalletUk' | 'loosePalletUs'
>;

export const CargoManagementTrackingCategory = 'CargoManagement';

export enum CargoManagementTrackingAction {
  ADD_NEW_CARGO_ITEM = 'AddNewCargoItem',
  CHANGE_CARGO_MODE = 'ChangeCargoMode',
  DELETE_CARGO_ITEM = 'DeleteCargoItem',
  UPDATE_CARGO_ITEM = 'UpdateCargoItem'
}

export interface CargoSummaryConsolidatedItems {
  consolidatedCargoItems?: Optional<CargoConsolidatedItemType[]>;
  consolidatedLooseCargoItems?: Optional<ConsolidatedLooseCargoItem[]>;
}

export interface CalculatedInfoCargoSummary {
  highestRiskLevel?: Optional<RiskLevelsEnum>;
  label?: Optional<string>;
  totalActualCbm?: Optional<number>;
  totalChargeableWeight?: Optional<CargoWeightType[]>;
  totalGrossWeight?: Optional<CargoWeightType[]>;
  totalValueOfGoods?: Optional<Money[]>;
  type?: Optional<CargoItemTypeEnum>;
}
