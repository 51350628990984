import type { FC, ReactNode } from 'react';

import FormAsyncSelect from '@zen/Components/Form/FormAsyncSelect';
import { highlightQuery } from '@zen/Components/Form/utils';
import useZencargoNetwork from '@zen/Networks/hooks/useZencargoNetwork';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, NetworksOrgLocOption } from '@zen/Networks/types';

import { ForwardersQueryDocument } from '../../graphql';
import { getOrgLocLabel, handleChangeCallback, IHandleChangeCallback } from '../networksContactHelper';

interface Props {
  domainName: AssignmentTargetTypeEnum;
  isDisabled?: boolean;
  label: string;
  name: string;
}

const NetworksForwarderFormInput: FC<Props> = (props) => {
  const assignmentType = AssignmentTypeValue.FORWARDER;
  const { data: zencargoNetworkData } = useZencargoNetwork();
  const zencargoNetworkId = zencargoNetworkData?.zencargoNetwork?.id;
  const { domainName, name, label, isDisabled } = props;

  const handleInputChange: IHandleChangeCallback = handleChangeCallback({
    domainName,
    assignmentType,
    accountUuid: zencargoNetworkId,
    queryDocument: ForwardersQueryDocument,
    resultsPath: 'forwarders.nodes'
  });

  const formatOptionLabel = (organisationLocation: NetworksOrgLocOption, inputValue: string): ReactNode =>
    highlightQuery(getOrgLocLabel(organisationLocation), inputValue);

  return (
    <div className="flex justify-between">
      <div className="flex-1">
        <FormAsyncSelect<NetworksOrgLocOption>
          formatOptionLabel={formatOptionLabel}
          isClearable={false}
          isDisabled={isDisabled}
          label={label}
          loadOptions={handleInputChange}
          name={name}
        />
      </div>
    </div>
  );
};

export default NetworksForwarderFormInput;
