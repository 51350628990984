import { FC, useState } from 'react';

import type { NewCargo } from '@zen/Cargo';
import type { ScheduleItemType } from '@zen/CollectionAndDelivery/Details/types';
import type { Nullable } from '@zen/utils/typescript';

import MultiCargoScheduleContext from './MultiCargoScheduleContext';

const MultiCargoScheduleProvider: FC = ({ children }) => {
  const [active, setActive] = useState<boolean>(false);
  const [cargoList, setCargos] = useState<NewCargo[]>([]);
  const [scheduleType, setScheduleType] = useState<Nullable<ScheduleItemType>>(null);
  const [errors, setErrors] = useState<string[]>([]);

  const addCargo = (cargo: NewCargo, type: ScheduleItemType) => {
    if (!scheduleType) {
      setScheduleType(type);
    }
    setCargos([...cargoList, cargo]);
  };

  const isCargoSelected = (id: string) => !!cargoList.find((cargo) => cargo.id === id);

  const removeCargo = ({ id }: NewCargo) => {
    const filteredCargos = cargoList.filter((cargo) => cargo.id !== id);
    const filteredErrors = errors.filter((error) => id !== error);

    if (!filteredCargos.length) {
      setScheduleType(null);
    }

    setErrors(filteredErrors);
    setCargos(filteredCargos);
  };

  const toggleCargo = (cargo: NewCargo, type: ScheduleItemType) => {
    return isCargoSelected(cargo.id) ? removeCargo(cargo) : addCargo(cargo, type);
  };

  const handleActiveToggle = (value: boolean) => {
    setErrors([]);
    setActive(value);
  };

  const hasError = (id: string) => errors.includes(id);

  const setErrorIds = (ids: string[]) => {
    setErrors(ids);
    setCargos(cargoList.filter((cargo) => ids.includes(cargo.id)));
  };

  const resetCargos = () => {
    setCargos([]);
    setScheduleType(null);
  };

  return (
    <MultiCargoScheduleContext.Provider
      value={{
        active,
        handleActiveToggle,
        scheduleType,
        cargoList,
        setScheduleType,
        toggleCargo,
        isCargoSelected,
        hasError,
        setErrorIds,
        resetCargos,
        setCargos
      }}
    >
      {children}
    </MultiCargoScheduleContext.Provider>
  );
};

export default MultiCargoScheduleProvider;
