import type { FC } from 'react';

import type { IconName } from '@zen/Styleguide';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import AccountStatusControl from '../AccountStatusControl';
import { useDisableAccountDemoModeMutation, useEnableAccountDemoModeMutation } from '../graphql';

interface Props {
  accountId: string;
  accountReference: string;
  isActive?: boolean;
  isDemo: boolean;
  onStatusChange: () => void;
}

const AccountChangeMode: FC<Props> = ({ accountId, accountReference, isActive, isDemo, onStatusChange }) => {
  const { addSuccess, addError } = useNotification();

  const [enableAccountDemoMode] = useEnableAccountDemoModeMutation();
  const [disableAccountDemoMode] = useDisableAccountDemoModeMutation();

  const handleEnableAccountDemoMode = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        enableAccountDemoMode({
          variables: {
            input: {
              id: accountId
            }
          }
        }),
      onError: addError,
      onSuccess: () => {
        addSuccess('Real mode disabled');
        onStatusChange();
      }
    });
  };

  const handleDisableAccountDemoMode = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        disableAccountDemoMode({
          variables: {
            input: {
              id: accountId
            }
          }
        }),
      onError: addError,
      onSuccess: () => {
        addSuccess('Real mode enabled');
        onStatusChange();
      }
    });
  };

  const description: string = isDemo
    ? 'Demo accounts do not have real data. Setting an account as public will enable shipment booking and allow business analysis.'
    : 'Real accounts work with real data. Setting an account as internal will disable shipment booking and business analysis.';

  const accountModeLabel: string = isDemo ? 'Demo' : 'Real';
  const accountAccessLabel: string = isDemo ? 'internal' : 'customer';
  const oppositeLabel: string = isDemo ? 'real' : 'demo';
  const iconClassName: string = isDemo ? 'text-azure-base' : 'text-green-base';
  const buttonIconName: Optional<IconName> = isDemo ? undefined : 'zicon-warning-oval';

  return (
    <AccountStatusControl
      accountAccessLabel={accountAccessLabel}
      accountLabel={accountModeLabel}
      accountReference={accountReference}
      buttonIconName={buttonIconName}
      configurationType="mode"
      currentStatus={!isDemo}
      description={description}
      iconClassName={iconClassName}
      iconName="zicon-tickoval"
      isActive={isActive}
      onUpdate={isDemo ? handleDisableAccountDemoMode : handleEnableAccountDemoMode}
      oppositeLabel={oppositeLabel}
    />
  );
};

export default AccountChangeMode;
