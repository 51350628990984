import type { FC } from 'react';

import InlineEditableField from '@zen/Components/InlineEditableField';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateHouseBillOfLadingMutation } from '../graphql';

interface Props {
  canUpdateHouseBillOfLading: boolean;
  houseBillOfLading: string;
  zencargoReference: string;
}

const HouseBillOfLadingCargoInfoInput: FC<Props> = ({ houseBillOfLading, zencargoReference, canUpdateHouseBillOfLading }) => {
  const { addSuccess, addError } = useNotification();
  const [updateHouseBillOfLading] = useUpdateHouseBillOfLadingMutation();

  const handleUpdateHouseBillOfLading = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateHouseBillOfLading({
          variables: {
            input: {
              zencargoReference,
              houseBillOfLading: value
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('House bill of lading updated.');
      }
    });
  };

  return (
    <InlineEditableField
      className="py-3.5 px-2"
      disabled={!canUpdateHouseBillOfLading}
      name="House bill of lading"
      onUpdate={handleUpdateHouseBillOfLading}
      overLabel="House bill of lading"
      value={houseBillOfLading}
    />
  );
};

export default HouseBillOfLadingCargoInfoInput;
