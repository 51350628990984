import { get } from 'lodash';
import { FC, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Page from '@zen/Components/Page';
import { ViewMode, ViewModeProvider } from '@zen/Components/ViewSwitcher';
import { Button, IconButton } from '@zen/DesignSystem';
import { orderRoutes } from '@zen/Routes';
import PreferencesIndicator from '@zen/Settings/Preferences/PreferencesIndicator';
import useAccount from '@zen/utils/hooks/useAccount';
import usePermissionSuppress from '@zen/utils/hooks/usePermissionSuppress';

import NoOrders from '../components/NoOrders';
import useOrderCount from '../hooks/useOrderCount';
import OrderDetailsContainer from '../OrderDetailsContainer';
import OrderList from './OrderList';

const OrderListContainer: FC = () => {
  const { check } = useGlobalPermissions();
  const { userProfile } = useAccount();
  const { data } = useOrderCount();

  const [filterByUserPreferences, setUseUserPreferences] = useState<boolean>();
  const canCreateOrders = check('purchaseOrders.canCreate');
  const orderTotalCount: number = get(data, 'purchaseOrders.totalCount', 0);
  const orderListPathname = orderRoutes.orderList.getUrl();
  const { suppressPermission: suppressCanCreateOrders } = usePermissionSuppress();

  const actionButtons: ReactNode = (
    <>
      {canCreateOrders && !suppressCanCreateOrders && (
        <Link to={orderRoutes.orderNew.getUrl()}>
          <Button className="mr-4">Create a new PO</Button>
        </Link>
      )}
      {userProfile.isAdmin && !suppressCanCreateOrders && (
        <Link to="/dashboard/csv-parser">
          <IconButton icon="zicon-upload" title="Upload CSV" />
        </Link>
      )}
    </>
  );

  if (orderTotalCount === 0) {
    return (
      <Page actionButtons={actionButtons} contentClassName="pt-4" title="Purchase orders">
        <NoOrders />
      </Page>
    );
  }

  return (
    <Page
      actionButtons={actionButtons}
      filterSwitcher={<PreferencesIndicator onChange={setUseUserPreferences} />}
      title="Purchase orders"
    >
      <ViewModeProvider initialViewMode={ViewMode.TABLE}>
        <OrderList filterByUserPreferences={!!filterByUserPreferences} />
      </ViewModeProvider>
      <OrderDetailsContainer originPath={orderListPathname} />
    </Page>
  );
};

export default OrderListContainer;
