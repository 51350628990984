import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInstance } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import FormMilestoneDelayReasonFields from '../FormMilestoneDelayReasonFields';
import type { MilestoneDelayFormPayload, MilestoneDelayFormType } from './type';

interface Props {
  className?: string;
  initialValues: Partial<MilestoneDelayFormType>;
  onCancel: () => void;
  onSubmit: (values: MilestoneDelayFormType) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
}

const MilestoneDelayForm: FC<Props> = ({ onCancel, onSubmit, onSuccess, initialValues, className }) => {
  const renderFormButtons = ({ isSubmitting }: FormInstance<MilestoneDelayFormType>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Submit">
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  const handleSubmit = async ({ reason, description }: MilestoneDelayFormPayload): Promise<IOkOrErrorResult> => {
    return onSubmit({ reason, description });
  };

  const getInitialValues = (): MilestoneDelayFormPayload => {
    const { reason, description } = initialValues;

    return { reason: reason || '', description: description || '' };
  };

  return (
    <Form
      className={className}
      formButtons={renderFormButtons}
      initialValues={getInitialValues()}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      scrollToError={false}
    >
      {() => <FormMilestoneDelayReasonFields />}
    </Form>
  );
};

export default MilestoneDelayForm;
