import { createContext } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

import type { VoyageMilestoneNameEnum, VoyageMilestoneType } from './types';

export interface MilestonesContext {
  isMilestoneCompleted: (milestoneName: VoyageMilestoneNameEnum) => boolean;
  milestones: VoyageMilestoneType[];
}

const initialContext = {
  milestones: [],
  isMilestoneCompleted: () => false
};

const VoyageMilestonesContext = createContext<MilestonesContext>(initialContext);

const useVoyageMilestones = (): MilestonesContext => {
  return useContextOrThrowError<MilestonesContext>(
    VoyageMilestonesContext,
    'useVoyageMilestones must be used within VoyageMilestonesContext'
  );
};

export { useVoyageMilestones, VoyageMilestonesContext };
