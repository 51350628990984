import { CSSProperties, useCallback, useEffect, useState } from 'react';

import useScrollGestures, { ScrollGesture } from '@zen/utils/hooks/useScrollGestures';
import type { Nullable } from '@zen/utils/typescript';

import useBreakPoint from './useBreakPoints';

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLHtmlElement | null' is not a... Remove this comment to see the full error message
const getFontSize = (): number => parseInt(window.getComputedStyle(document.querySelector('html')).fontSize, 10);

const useTableHeaderStyle = ({
  referenceElement,
  topOffsetInRem
}: {
  referenceElement: Nullable<HTMLElement>;
  topOffsetInRem: number;
}): CSSProperties => {
  const [tableHeaderStyle, setTableHeaderStyle] = useState<CSSProperties>({});
  const { currentBreakPoint } = useBreakPoint();

  const trigger = useCallback((): boolean => {
    if (!referenceElement) {
      return false;
    }

    const targetTopOffset: number = referenceElement.getBoundingClientRect().top;
    const targetTopOffsetInRem: number = targetTopOffset / getFontSize();

    return targetTopOffsetInRem - topOffsetInRem < 0;
  }, [referenceElement, topOffsetInRem]);

  const { gesture } = useScrollGestures(trigger);

  useEffect(() => {
    if (topOffsetInRem === undefined || !referenceElement) {
      return;
    }

    if (gesture === null) {
      setTableHeaderStyle({ opacity: '1' });
    }

    if (gesture === ScrollGesture.STARTED) {
      setTableHeaderStyle({ opacity: '0' });
    }

    if (gesture === ScrollGesture.STOPPED) {
      const headerTopOffset: number = referenceElement.getBoundingClientRect().top;
      const headerTopOffsetInRem: number = headerTopOffset / getFontSize();
      const translateOffset: number = -1 * headerTopOffsetInRem + topOffsetInRem;

      const opacity: string = '1';
      const transition: string = 'opacity 0.2s ease-in';
      const transform: string = `translate(0, ${translateOffset}rem)`;

      setTableHeaderStyle({ transform, transition, opacity });
    }
  }, [gesture, referenceElement, topOffsetInRem]);

  useEffect(() => {
    if (topOffsetInRem === undefined || !referenceElement) {
      return;
    }
    if (trigger()) {
      const headerTopOffset: number = referenceElement.getBoundingClientRect().top;
      const fontSize: number = getFontSize();
      const headerTopOffsetInRem: number = headerTopOffset / fontSize;
      const translateOffset: number = -1 * headerTopOffsetInRem + topOffsetInRem;

      const transform: string = `translate(0, ${translateOffset}rem)`;

      setTableHeaderStyle({ transform });
    }
  }, [currentBreakPoint, topOffsetInRem, referenceElement, trigger]);

  return tableHeaderStyle;
};

export default useTableHeaderStyle;
