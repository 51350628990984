import type { Issue } from '@zen/Issues/types';
import { getTimeAgo } from '@zen/utils/date';
import { getFullName } from '@zen/utils/formatting';

import type { StateDescription, StateItem } from './types';

type StageChangesHolder = Pick<Issue, 'openedAt' | 'openedBy' | 'startedAt' | 'startedBy' | 'resolvedAt' | 'resolvedBy'>;

export const buildStateChangesList = (issue: StageChangesHolder): StateDescription => {
  const { openedAt, openedBy, startedAt, startedBy, resolvedAt, resolvedBy } = issue;
  const items: StateItem[] = [];

  if (openedAt) {
    items.push({
      action: 'Opened',
      actor: getFullName(openedBy),
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Maybe<string> | undefined' is no... Remove this comment to see the full error message
      timeAgo: getTimeAgo(openedAt.dateTime)
    });
  }
  if (startedAt) {
    items.push({
      action: 'Investigation started',
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ __typename?: "NetworksContact... Remove this comment to see the full error message
      actor: getFullName(startedBy),
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Maybe<string> | undefined' is no... Remove this comment to see the full error message
      timeAgo: getTimeAgo(startedAt.dateTime)
    });
  }
  if (resolvedAt) {
    items.push({
      action: 'Resolved',
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ __typename?: "NetworksContact... Remove this comment to see the full error message
      actor: getFullName(resolvedBy),
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Maybe<string> | undefined' is no... Remove this comment to see the full error message
      timeAgo: getTimeAgo(resolvedAt.dateTime)
    });
  }

  const currentState = items.pop();

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'StateItem | undefined' is not assignable to ... Remove this comment to see the full error message
  return { currentState, history: items };
};

export const generateLabel = ({ action, timeAgo, actor }: StateItem) => `${action} ${timeAgo} by ${actor}`;
