import { FC, useState } from 'react';

import type { Option } from '@zen/DesignSystem';
import { Button, LegacyModal } from '@zen/DesignSystem';

import { FormLabel, FormSelect } from '..';
import { multiStopLabelMapping } from './helpers';
import { ReactComponent as MultiStopImage } from './multi-stop.svg';
import { MultiStopEnum } from './types';

const options: Option<MultiStopEnum>[] = [
  { value: MultiStopEnum.MORE_THAN_TWO_STOPS, label: multiStopLabelMapping[MultiStopEnum.MORE_THAN_TWO_STOPS] },
  { value: MultiStopEnum.TWO_STOPS, label: multiStopLabelMapping[MultiStopEnum.TWO_STOPS] },
  { value: MultiStopEnum.UNKNOWN, label: multiStopLabelMapping[MultiStopEnum.UNKNOWN] }
];

interface Props {
  name: string;
}

const FormMultiStopSelect: FC<Props> = ({ name }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <FormLabel isRequired={true} label="Will this shipment have more than one collection or delivery stop?" name={name} />
      <div className="flex">
        <FormSelect className="w-48 mr-4" hideLabel={true} isSearchable={false} name={name} options={options} />
        <Button iconLeft="zicon-information" onClick={() => setIsModalVisible(true)} variant="tertiary">
          Multi-stop explained
        </Button>
      </div>
      <LegacyModal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <div className="p-12 max-w-lg flex flex-col items-center">
          <div className="font-bold text-lg leading-normal text-grey-dark mb-2">What is a multi-stop shipment?</div>
          <div className="text-sm text-grey-dark text-center mb-5 w-88">
            A multi-stop shipment is a shipment with more than one collection or delivery stop. Milk run and consolidation
            shipments are multi-stop shipments.
          </div>
          <MultiStopImage />
        </div>
      </LegacyModal>
    </>
  );
};

export default FormMultiStopSelect;
