import { FC, ReactNode, useState } from 'react';
import { Route, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import RouteTabs, { RouteTabConfig } from '@zen/Components/RouteTabs';
import RouteTabSwitcher from '@zen/Components/RouteTabSwitcher';
import { IconButton } from '@zen/DesignSystem';
import DataQualityContainer from '@zen/IntakePlanning/DataQuality';
import { prepareFilters } from '@zen/IntakePlanning/Filters/helpers';
import type { IntakeFilterValues } from '@zen/IntakePlanning/types';
import { useUsedAssignables } from '@zen/Networks';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks/types';
import PreferencesIndicator from '@zen/Settings/Preferences/PreferencesIndicator';
import type { IconName } from '@zen/Styleguide';
import { ProductTabs } from '@zen/types';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useProductDetailsQuery, useProductsArchiveProductMutation, useProductsUnarchiveProductMutation } from '../graphql';
import { productPathPrefix } from '../ProductsContainer';
import type { ProductDetails as ProductDetailsType } from '../types';
import Analytics from './Analytics';
import Details from './Details';
import PoIntake from './PoIntake';
import WeeklyIntake from './WeeklyIntake';

const ProductDetails: FC = () => {
  const { accountUuid } = useAccount();
  const location = useLocation<{ previousUrl: string }>();
  const { productId } = useParams<{ productId: string }>();
  const { addSuccess, addError } = useNotification();
  const [filterByUserPreferences, setUseUserPreferences] = useState<boolean>(false);
  const { data, loading, error } = useProductDetailsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: productId
    }
  });

  const { data: destinations = [] } = useUsedAssignables(
    [AssignmentTargetTypeEnum.PURCHASE_ORDER],
    [AssignmentTypeValue.DESTINATION]
  );

  const [archiveProduct] = useProductsArchiveProductMutation({
    variables: { input: { id: productId } }
  });
  const [unarchiveProduct] = useProductsUnarchiveProductMutation({
    variables: { input: { id: productId } }
  });

  const isDetailsPage: boolean = location.pathname.includes('details');
  const backUrl: string = location?.state?.previousUrl || productPathPrefix;
  const filters: IntakeFilterValues = prepareFilters(location.search);

  const getConfig = (product: ProductDetailsType): RouteTabConfig[] => [
    {
      label: 'Details',
      path: ProductTabs.DETAILS,
      component: <Details product={product} />,
      isDefault: true
    },
    {
      label: 'Orders',
      path: ProductTabs.PO,
      routePath: `${ProductTabs.PO}/:id?`,
      component: (
        <PoIntake
          accountUuid={accountUuid}
          destinations={destinations}
          filterByUserPreferences={filterByUserPreferences}
          productId={productId}
        />
      )
    },
    {
      label: 'Weekly intake',
      path: ProductTabs.WEEKLY,
      component: (
        <WeeklyIntake
          accountUuid={accountUuid}
          destinations={destinations}
          filterByUserPreferences={filterByUserPreferences}
          filters={filters}
          product={product}
        />
      )
    },
    {
      label: 'Analytics',
      path: ProductTabs.ANALYTICS,
      component: <Analytics accountUuid={accountUuid} productId={productId} />
    }
  ];

  const handleStatusChange = async (product: ProductDetailsType) => {
    const mutationFn = product.archived ? unarchiveProduct : archiveProduct;

    performMutation({
      mutationFn,
      onSuccess: () => addSuccess(`Product has been ${product.archived ? 'unarchived' : 'archived'}.`),
      onError: () => addError(`Product with SKU '${product.skuCode}' already exists.`)
    });
  };

  const qualityIndicator: ReactNode = !isDetailsPage ? (
    <Route path="/dashboard/products/:productId/:tab">
      <DataQualityContainer />
    </Route>
  ) : undefined;

  const preferencesIndicator: ReactNode = <PreferencesIndicator onChange={setUseUserPreferences} />;

  const getActionButtons = (product: ProductDetailsType): ReactNode => {
    const actionIcon: IconName = product.archived ? 'zicon-unarchive' : 'zicon-archive';
    const actionTitle: string = product.archived ? 'Unarchive' : 'Archive';

    if (!isDetailsPage) {
      return undefined;
    }

    const { skuCode, perUnitCbm, archived, ...initialValue } = product;

    return (
      <>
        <Link
          className="mr-4"
          to={{
            pathname: `${productPathPrefix}/new`,
            state: { initialValue }
          }}
        >
          <IconButton icon="zicon-copy" title="Duplicate" variant="secondary" />
        </Link>
        <Link className="mr-4" to={`${productPathPrefix}/${productId}/edit`}>
          <IconButton icon="zicon-edit" title="Edit" variant="secondary" />
        </Link>
        <IconButton icon={actionIcon} onClick={() => handleStatusChange(product)} title={actionTitle} variant="secondary" />
      </>
    );
  };

  return (
    <QueryHandler data={data?.products.nodes?.[0]} error={!!error} isLoading={loading}>
      {(product: ProductDetailsType) => (
        <Page
          actionButtons={getActionButtons(product)}
          contentClassName="pb-4"
          defaultBackUrl={backUrl}
          filterSwitcher={preferencesIndicator}
          qualityIndicator={qualityIndicator}
          tabsComponent={<RouteTabs tabsConfig={getConfig(product)} />}
          tagline={`SKU: ${product.skuCode}`}
          title={product.name}
        >
          <RouteTabSwitcher tabsConfig={getConfig(product)} />
        </Page>
      )}
    </QueryHandler>
  );
};

export default ProductDetails;
