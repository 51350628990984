import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';

import type { Direction } from '../types';

interface Props {
  onMonthChange: (direction: Direction) => void;
  title: string;
}

const Header: FC<Props> = ({ title, onMonthChange }) => {
  const iconClasses: string =
    'flex w-8 h-8 justify-center items-center text-2xl text-grey-base rounded transition duration-200 ease-in-out hover:bg-grey-lighter cursor-pointer';

  return (
    <div className="bg-grey-lightest flex justify-between items-center text-grey-base text-basept-6 pt-5 px-7">
      <div className={iconClasses} onClick={() => onMonthChange('previous')}>
        <Icon icon="zicon-chevron-left-small" />
      </div>
      <div className="text-grey-dark text-lg font-bold px-2 flex-1 text-center">{title}</div>
      <div className={iconClasses} onClick={() => onMonthChange('next')}>
        <Icon icon="zicon-chevron-right-small" />
      </div>
    </div>
  );
};

export default Header;
