import cx from 'classnames';
import type { ReactNode } from 'react';
import { components } from 'react-select';

const getMenuList =
  (footerRenderer?: (inputValue: string) => ReactNode): typeof components.MenuList =>
  (props) => {
    const classNames: string = cx(
      'text-xs italic p-2 text-navy-base hover:underline',
      'border-t border-grey-light border-solid bg-grey-lightest'
    );

    const {
      selectProps: { inputValue, isLoading }
    } = props;

    return (
      <>
        <components.MenuList {...props} />
        {footerRenderer && !isLoading && (
          <div className={classNames} data-testid="menu-footer">
            {footerRenderer(inputValue)}
          </div>
        )}
      </>
    );
  };

export default getMenuList;
