import type { FC } from 'react';

interface Props {
  background: string;
  border: string;
}

const FilterPointer: FC<Props> = ({ background, border }) => {
  return (
    <div
      className="absolute w-3 h-5 overflow-hidden -right-3"
      data-testid="filter-pointer"
      style={{
        top: 'calc(50% - 0.625rem)'
      }}
    >
      <div className={`w-5 h-5 ${background} ${border} border-solid border-r-2 border-t-2 transform rotate-45 -ml-3`} />
    </div>
  );
};

export default FilterPointer;
