import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RoadTrackingStatusQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type RoadTrackingStatusQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              roadTracking?: GraphQLTypes.Maybe<
                { __typename?: 'RoadTrackingDetails' } & {
                  trackingInput?: GraphQLTypes.Maybe<
                    { __typename?: 'RoadTrackingInput' } & Pick<GraphQLTypes.RoadTrackingInput, 'missingData' | 'trackable'> & {
                        trackingSubscription?: GraphQLTypes.Maybe<
                          { __typename?: 'RoadTrackingSubscription' } & Pick<
                            GraphQLTypes.RoadTrackingSubscription,
                            'statusReason'
                          >
                        >;
                      }
                  >;
                }
              >;
            }
        >
      >
    >;
  };
};

export const RoadTrackingStatusDocument = /* #__PURE__ */ gql`
  query roadTrackingStatus($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        roadTracking {
          trackingInput {
            missingData
            trackable
            trackingSubscription {
              statusReason
            }
          }
        }
      }
    }
  }
`;

/**
 * __useRoadTrackingStatusQuery__
 *
 * To run a query within a React component, call `useRoadTrackingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadTrackingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadTrackingStatusQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useRoadTrackingStatusQuery(
  baseOptions: Apollo.QueryHookOptions<RoadTrackingStatusQueryResult, RoadTrackingStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RoadTrackingStatusQueryResult, RoadTrackingStatusQueryVariables>(RoadTrackingStatusDocument, options);
}
export function useRoadTrackingStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoadTrackingStatusQueryResult, RoadTrackingStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RoadTrackingStatusQueryResult, RoadTrackingStatusQueryVariables>(
    RoadTrackingStatusDocument,
    options
  );
}
export type RoadTrackingStatusQueryHookResult = ReturnType<typeof useRoadTrackingStatusQuery>;
export type RoadTrackingStatusLazyQueryHookResult = ReturnType<typeof useRoadTrackingStatusLazyQuery>;
