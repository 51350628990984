import cx from 'classnames';
import moment from 'moment';
import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import { formatDate } from '@zen/utils/dateTime';

const DATE_FORMAT = 'h:mma';

interface Props {
  className?: string;
  date: string;
  resolvedAt?: string;
}

const Timestamp: FC<Props> = ({ className, date, resolvedAt = undefined }) => {
  const classNames: string = cx('flex items-center min-w-16 text-grey-base text-xs leading-normal font-normal', className);

  const time: string = moment(date).format(DATE_FORMAT);
  const dateTimeLabel: string = resolvedAt ? formatDate(resolvedAt) : time;

  return (
    <div className={classNames} data-testid="timestamp">
      <Icon className="mr-1" icon="zicon-time" />
      {resolvedAt && 'Resolved at'} {dateTimeLabel}
    </div>
  );
};

export default Timestamp;
