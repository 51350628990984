import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetShipmentDetailsBoardQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
  newCargoService: GraphQLTypes.Scalars['Boolean'];
}>;

export type GetShipmentDetailsBoardQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<
            GraphQLTypes.Booking,
            | 'customsOnly'
            | 'houseBillOfLading'
            | 'masterBillOfLading'
            | 'modeOfTransport'
            | 'shipmentType'
            | 'tradeRole'
            | 'zencargoReference'
          > & {
              archivedAt?: GraphQLTypes.Maybe<{ __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>>;
              bookingStage?: GraphQLTypes.Maybe<{ __typename?: 'BookingStage' } & Pick<GraphQLTypes.BookingStage, 'name'>>;
              canEditDestination: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canEditOrigin: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canManageSchedule: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateCarrierBookingReference: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canUpdateContractId: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateCustomsOnly: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateHouseBillOfLading: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canUpdateIncoterms: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateMasterBillOfLading: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canUpdateModeOfTransport: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateShipmentStatus: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateShipmentType: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateTradeRole: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateVehiclePlateNumber: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canViewContractId: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewKeyContact: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewMasterBillOfLading: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewOperationsManager: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewSchedule: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewShipmentTracking: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    cargoItems?: GraphQLTypes.Maybe<
                      Array<
                        | ({ __typename?: 'CargoContainerType' } & Pick<GraphQLTypes.CargoContainerType, 'id'> & {
                              canUpdateVehicleTrailerId: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<GraphQLTypes.CollectionDetails, 'vehiclePlateNumber'>
                              >;
                            })
                        | ({ __typename?: 'CargoLooseCargoType' } & Pick<GraphQLTypes.CargoLooseCargoType, 'trailerId' | 'id'> & {
                              canUpdateVehicleTrailerId: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<GraphQLTypes.CollectionDetails, 'vehiclePlateNumber'>
                              >;
                            })
                        | ({ __typename?: 'CargoVehicleType' } & Pick<GraphQLTypes.CargoVehicleType, 'trailerId' | 'id'> & {
                              canUpdateVehicleTrailerId: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<GraphQLTypes.CollectionDetails, 'vehiclePlateNumber'>
                              >;
                            })
                      >
                    >;
                    tracking?: GraphQLTypes.Maybe<
                      { __typename?: 'Tracking' } & Pick<GraphQLTypes.Tracking, 'carrierBookingReference'>
                    >;
                  }
              >;
              cargos?: GraphQLTypes.Maybe<Array<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'id' | 'trailerId'>>>;
              consignee?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
              >;
              consignor?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
              >;
              estimatedArrival?: GraphQLTypes.Maybe<
                { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
              >;
              estimatedDeparture?: GraphQLTypes.Maybe<
                { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
              >;
              incoterms?: GraphQLTypes.Maybe<{ __typename?: 'Incoterms' } & Pick<GraphQLTypes.Incoterms, 'value'>>;
              keyContact?: GraphQLTypes.Maybe<
                { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'id' | 'firstName' | 'lastName'>
              >;
              networksDestination?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
              >;
              networksOrigin?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
              >;
              operationsManager?: GraphQLTypes.Maybe<
                { __typename?: 'NetworksOrgLocContact' } & Pick<
                  GraphQLTypes.NetworksOrgLocContact,
                  'id' | 'firstName' | 'lastName'
                >
              >;
              specialInstructions?: GraphQLTypes.Maybe<
                { __typename?: 'BookingSpecialInstructions' } & Pick<GraphQLTypes.BookingSpecialInstructions, 'message'>
              >;
              stage?: GraphQLTypes.Maybe<
                { __typename?: 'Stage' } & {
                  statusUpdate?: GraphQLTypes.Maybe<{ __typename?: 'StatusUpdate' } & Pick<GraphQLTypes.StatusUpdate, 'title'>>;
                }
              >;
              transportSchedule?: GraphQLTypes.Maybe<
                { __typename?: 'TransportScheduleType' } & Pick<
                  GraphQLTypes.TransportScheduleType,
                  'id' | 'carrier' | 'from' | 'to'
                > & {
                    legs?: GraphQLTypes.Maybe<
                      Array<
                        { __typename?: 'TransportScheduleLegType' } & Pick<GraphQLTypes.TransportScheduleLegType, 'vesselName'>
                      >
                    >;
                  }
              >;
              voyageMilestone?: GraphQLTypes.Maybe<
                { __typename?: 'VoyageMilestone' } & Pick<GraphQLTypes.VoyageMilestone, 'name'>
              >;
            }
        >
      >
    >;
  };
};

export const GetShipmentDetailsBoardDocument = /* #__PURE__ */ gql`
  query getShipmentDetailsBoard($zencargoReference: String!, $newCargoService: Boolean!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        archivedAt {
          date
        }
        bookingStage {
          name
        }
        canEditDestination {
          value
        }
        canEditOrigin {
          value
        }
        canManageSchedule {
          value
        }
        canUpdateCarrierBookingReference {
          value
        }
        canUpdateContractId {
          value
        }
        canUpdateCustomsOnly {
          value
        }
        canUpdateHouseBillOfLading {
          value
        }
        canUpdateIncoterms {
          value
        }
        canUpdateMasterBillOfLading {
          value
        }
        canUpdateModeOfTransport {
          value
        }
        canUpdateShipmentStatus {
          value
        }
        canUpdateShipmentType {
          value
        }
        canUpdateTradeRole {
          value
        }
        canUpdateVehiclePlateNumber {
          value
        }
        canViewContractId {
          value
        }
        canViewKeyContact {
          value
        }
        canViewMasterBillOfLading {
          value
        }
        canViewOperationsManager {
          value
        }
        canViewSchedule {
          value
        }
        canViewShipmentTracking {
          value
        }
        cargo {
          cargoItems {
            canUpdateVehicleTrailerId {
              value
            }
            collection {
              vehiclePlateNumber
            }
            id
            ... on CargoLooseCargoType {
              trailerId
            }
            ... on CargoVehicleType {
              trailerId
            }
          }
          mode
          tracking {
            carrierBookingReference
          }
        }
        cargos @include(if: $newCargoService) {
          id
          trailerId
        }
        consignee {
          id
          label {
            short
          }
        }
        consignor {
          id
          label {
            short
          }
        }
        customsOnly
        estimatedArrival {
          date
          timeZone
        }
        estimatedDeparture {
          date
          timeZone
        }
        houseBillOfLading
        incoterms {
          value
        }
        keyContact {
          id
          firstName
          lastName
        }
        masterBillOfLading
        modeOfTransport
        networksDestination {
          id
          label {
            short
          }
        }
        networksOrigin {
          id
          label {
            short
          }
        }
        operationsManager {
          id
          firstName
          lastName
        }
        shipmentType
        specialInstructions {
          message
        }
        stage {
          statusUpdate {
            title
          }
        }
        tradeRole
        transportSchedule {
          id
          carrier
          from
          to
          legs {
            vesselName
          }
        }
        voyageMilestone {
          name
        }
        zencargoReference
      }
    }
  }
`;

/**
 * __useGetShipmentDetailsBoardQuery__
 *
 * To run a query within a React component, call `useGetShipmentDetailsBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentDetailsBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentDetailsBoardQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *      newCargoService: // value for 'newCargoService'
 *   },
 * });
 */
export function useGetShipmentDetailsBoardQuery(
  baseOptions: Apollo.QueryHookOptions<GetShipmentDetailsBoardQueryResult, GetShipmentDetailsBoardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetShipmentDetailsBoardQueryResult, GetShipmentDetailsBoardQueryVariables>(
    GetShipmentDetailsBoardDocument,
    options
  );
}
export function useGetShipmentDetailsBoardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentDetailsBoardQueryResult, GetShipmentDetailsBoardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetShipmentDetailsBoardQueryResult, GetShipmentDetailsBoardQueryVariables>(
    GetShipmentDetailsBoardDocument,
    options
  );
}
export type GetShipmentDetailsBoardQueryHookResult = ReturnType<typeof useGetShipmentDetailsBoardQuery>;
export type GetShipmentDetailsBoardLazyQueryHookResult = ReturnType<typeof useGetShipmentDetailsBoardLazyQuery>;
