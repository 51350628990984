import type { RoutesConfig } from './types';

export const OPS_SHIPMENT_ROUTE_PREFIX = '/dashboard/ops-shipments';

interface OpsShipmentRoutes extends RoutesConfig {
  allShipments: {
    getUrl: () => string;
    path: string;
  };
  allShipmentsDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
  };
  editRoadShipment: {
    getUrl: (zencargoReference: string) => string;
    path: string;
  };
  roadShipments: {
    getUrl: () => string;
    path: string;
  };
  roadShipmentsDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
  };
  shipmentDetails: {
    getUrl: (zencargoReference: string, tab?: string) => string;
    path: string;
  };
  shipmentIndex: {
    getUrl: () => string;
    path: string;
  };
}

const opsShipmentRoutes: OpsShipmentRoutes = {
  shipmentDetails: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/:id?`,
    getUrl: (zencargoReference: string, tab?: string): string => {
      const url = `${OPS_SHIPMENT_ROUTE_PREFIX}/${zencargoReference}`;

      return `${url}/${tab || 'activity'}`;
    }
  },
  shipmentIndex: {
    path: OPS_SHIPMENT_ROUTE_PREFIX,
    getUrl: () => OPS_SHIPMENT_ROUTE_PREFIX
  },
  allShipments: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/all-shipments`,
    getUrl: () => `${OPS_SHIPMENT_ROUTE_PREFIX}/all-shipments`
  },
  allShipmentsDetailsPage: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/all-shipments/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${OPS_SHIPMENT_ROUTE_PREFIX}/all-shipments/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  roadShipments: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/road-shipments`,
    getUrl: () => `${OPS_SHIPMENT_ROUTE_PREFIX}/road-shipments`
  },
  roadShipmentsDetailsPage: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/road-shipments/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${OPS_SHIPMENT_ROUTE_PREFIX}/road-shipments/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  editRoadShipment: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/road-shipments/:id/edit`,
    getUrl: (zencargoReference: string): string => `${OPS_SHIPMENT_ROUTE_PREFIX}/road-shipments/${zencargoReference}/edit`
  }
};

export default opsShipmentRoutes;
