import type { FC, ReactNode } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import { Form, FormButtons, FormInput, FormInstance } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks';
import { useNetworksUpdateMyOrganisationMutation } from '@zen/Networks/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

interface FormValues {
  eori: string;
  name: string;
  vat: string;
}

interface Props {
  initialValues: FormValues;
  organisationId: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name name is required')
});

const EditMyOrganisationForm: FC<Props> = (props) => {
  const { initialValues, organisationId } = props;
  const history = useHistory();
  const { addSuccess } = useNotification();
  const [updateMyOrganisation] = useNetworksUpdateMyOrganisationMutation();
  const { routes } = useNetworksContext();
  const myOrganisationIndex: string = routes.myOrganisation.details.getUrl();

  const renderFormButtons = ({ isSubmitting }: FormInstance<FormValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} text="Save changes">
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  const onCancel = (): void => history.push(myOrganisationIndex);

  const onSubmit = async (values: FormValues): Promise<IOkOrErrorResult> => {
    const { data, errors } = await updateMyOrganisation({
      variables: { input: { organisationId, ...values } }
    });

    return Promise.resolve({
      ok: data || null,
      error: errors
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Organisation saved.');
    history.push(myOrganisationIndex);
  };

  return (
    <Form
      className="mt-4"
      formButtons={renderFormButtons}
      formName="EditMyOrganisationForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={handleSuccess}
      validationSchema={validationSchema}
    >
      {() => (
        <>
          <FormInput isRequired={true} label="My organisation name" name="name" />
          <FormInput label="VAT number" name="vat" />
          <FormInput label="EORI number" name="eori" />
        </>
      )}
    </Form>
  );
};

export { EditMyOrganisationForm };

export default EditMyOrganisationForm;
