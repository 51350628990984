import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { useLocalStorage } from 'react-use';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

type BannerMessageType = 'announcement' | 'default' | 'warning';

interface Props {
  bannerkey?: string;
  className?: string;
  icon?: IconName;
  isClosable?: boolean;
  message: ReactNode;
  type?: BannerMessageType;
}

const Banner: FC<Props> = ({ bannerkey = '', className, icon, isClosable = false, message, type = 'default' }) => {
  const [isBannerDisplayed, saveIsBannerDisplayed] = useLocalStorage(`banner-${bannerkey}`, true);

  const typeClassNameMapping: Record<BannerMessageType, string> = {
    announcement: 'bg-azure-lighter',
    default: 'bg-grey-lighter text-grey-base',
    warning: 'bg-red-dark text-white'
  };

  const defaultIcon: Record<BannerMessageType, IconName> = {
    announcement: 'zicon-announcement',
    default: 'zicon-information',
    warning: 'zicon-warning-triangle'
  };

  const wrapperClassName: string = cx(
    'flex justify-between  items-center',
    {
      'p-4 rounded': !className
    },
    typeClassNameMapping[type],
    className
  );

  if (isClosable && !isBannerDisplayed) {
    return null;
  }

  return (
    <div className={wrapperClassName} data-testid="banner-component">
      <div className="flex items-center">
        <Icon className="mr-3 text-base" icon={icon || defaultIcon[type]} />
        {message}
      </div>
      {isClosable && (
        <Icon
          className="cursor-pointer text-grey-dark"
          icon="zicon-close"
          onClick={() => saveIsBannerDisplayed(!isBannerDisplayed)}
        />
      )}
    </div>
  );
};

export type { Props as BannerProps };

export default Banner;
