import type { RoutesConfig } from './types';

export const PRODUCTS_ROUTE_PREFIX = '/dashboard/products';

export enum PurchaseOrderTab {
  ANALYTICS = 'analytics',
  DETAILS = 'details',
  ORDER = 'po',
  WEEKLY = 'weekly'
}

interface ProductRoutes extends RoutesConfig {
  // @ts-expect-error ts-migrate(2411) FIXME: Property 'productDetails' of type '{ path: string;... Remove this comment to see the full error message
  productDetails: {
    getUrl: (id: string, tab: PurchaseOrderTab, startDate?: string, period?: string) => string;
    path: string;
  };
  productList: {
    getUrl: () => string;
    path: string;
  };
}

// @ts-expect-error ts-migrate(2322) FIXME: Type '{ productList: { path: string; getUrl: () =>... Remove this comment to see the full error message
const productRoutes: ProductRoutes = {
  productList: {
    path: PRODUCTS_ROUTE_PREFIX,
    getUrl: () => PRODUCTS_ROUTE_PREFIX
  },
  productDetails: {
    path: `${PRODUCTS_ROUTE_PREFIX}/:productId`,
    getUrl: (id: string, tab: PurchaseOrderTab, startDate?: string, period?: string) => {
      if (startDate && period) {
        return `${PRODUCTS_ROUTE_PREFIX}/${id}/${tab}?startDate=${startDate}&period=${period}`;
      }

      return `${PRODUCTS_ROUTE_PREFIX}/${id}/${tab}`;
    }
  }
};

export default productRoutes;
