import { FC, ReactChild, useState } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import type { CargoModeEnum } from '@zen/Cargo';
import CollectionAndDeliveryScheduling from '@zen/Cargo/CollectionAndDeliveryScheduling';
import { useUpdateCargoModeMutation } from '@zen/Cargo/graphql';
import QueryHandler from '@zen/Components/QueryHandler';
import { ContextMenu, MenuItemType } from '@zen/DesignSystem';
import { packingListsRoutes } from '@zen/Routes';
import { isRoadShipment } from '@zen/Shipments';
import type { IconName } from '@zen/Styleguide';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import CargoModeModal from '../components/CargoModeModal';
import { CargoManagementTrackingAction, CargoManagementTrackingCategory } from '../types';
import { useGetCargoOptionDetailsQuery } from './graphql';
import type { CargoDetails } from './types';

interface Props {
  zencargoReference: string;
}

const CargoOptions: FC<Props> = (props) => {
  const { zencargoReference } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [updateCargoMode] = useUpdateCargoModeMutation();
  const { trackEvent } = useTracking();
  const { addSuccess, addError } = useNotification();
  const { navigate } = useNavigationHistory();

  const { check } = useGlobalPermissions();
  const canAccessPackingLists: boolean = check('packingLists.canAccessRoute');

  const { data, error, loading, refetch } = useGetCargoOptionDetailsQuery({
    variables: { zencargoReference },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const dropdownItems: MenuItemType[] = [
    { icon: 'zicon-edit', label: 'Change cargo mode', onClick: () => setShowModal(true) },
    ...(canAccessPackingLists
      ? [
          {
            icon: 'zicon-packing-list' as IconName,
            label: 'View packing lists',
            onClick: () => navigate(packingListsRoutes.packingListIndex.getUrl(zencargoReference))
          }
        ]
      : [])
  ];

  const handleChange = async (mode: CargoModeEnum): Promise<void> => {
    setShowModal(false);

    performMutation({
      mutationFn: () =>
        updateCargoMode({
          variables: {
            input: {
              cargoMode: mode,
              zencargoReference
            }
          },
          refetchQueries: [
            'cargoSummary',
            'getCargoDetailsQuery',
            'getCargoOptionDetailsQuery',
            'voyageMilestones',
            'getRouteProgressDetails'
          ],
          awaitRefetchQueries: true
        }),
      onError: addError,
      onSuccess: () => {
        trackEvent({
          category: CargoManagementTrackingCategory,
          action: CargoManagementTrackingAction.CHANGE_CARGO_MODE,
          label: mode
        });
        addSuccess('Cargo mode has been changed.');
      }
    });
  };

  return (
    <QueryHandler data={data?.bookings.nodes?.[0]} error={!!error} isLoading={loading}>
      {(cargoDetails: CargoDetails) => {
        const canManageCollectionAndDeliveryScheduling: boolean = checkPermission<CargoDetails>(
          cargoDetails,
          'canManageCollectionAndDeliveryScheduling'
        );

        const { cargo, collectionScheduleRequired, deliveryScheduleRequired } = cargoDetails;

        const footer: Optional<ReactChild> =
          canManageCollectionAndDeliveryScheduling && !isRoadShipment(cargoDetails.modeOfTransport) ? (
            <CollectionAndDeliveryScheduling
              collectionScheduleRequired={!!collectionScheduleRequired}
              deliveryScheduleRequired={!!deliveryScheduleRequired}
              onChange={refetch}
              zencargoReference={zencargoReference}
            />
          ) : undefined;

        return (
          <>
            <ContextMenu footer={footer} items={dropdownItems} />
            {cargo?.mode && (
              <CargoModeModal
                cargoMode={cargo.mode}
                isOpen={showModal}
                onChange={handleChange}
                onClose={() => setShowModal(false)}
              />
            )}
          </>
        );
      }}
    </QueryHandler>
  );
};

export default CargoOptions;
