import moment from 'moment';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import DataExportButton from '@zen/Components/DataExportButton';
import ProductSelect from '@zen/Components/Form/ProductSelect';
import NoResults from '@zen/Components/NoResults';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Chip, Pagination, Table, TableColumn, useColumnConfiguration } from '@zen/DesignSystem';
import { productPathPrefix } from '@zen/Products/ProductsContainer';
import type { Product } from '@zen/Products/types';
import PreferencesIndicator from '@zen/Settings/Preferences/PreferencesIndicator';
import { FORMAT_DATE_TRANSFERABLE, removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import usePagination from '@zen/utils/hooks/pagination/usePagination';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import { mergeQueryParams, parseQueryParams } from '@zen/utils/QueryParams/queryParams';
import type { Nullable } from '@zen/utils/typescript';

import DataQualityContainer from '../DataQuality';
import { prepareFilters } from '../Filters/helpers';
import {
  IntakePlanningProductsQueryResult,
  IntakePlanningProductsQueryVariables,
  useIntakePlanningExportProductsDataMutation,
  useIntakePlanningProductsQuery,
  useIntakePlanningSkuOverviewQuery
} from '../graphql';
import { getColumnKeys } from '../helpers/dataExport';
import { createSortingHandler } from '../helpers/sorting';
import { intakePathPrefix } from '../IntakePlanningContainer';
import type { ProductIntake, ProductsColumnEnum } from '../types';
import WeekSwitcher from '../WeekSwitcher';
import SkuOverview from './SkuOverview';
import { getColumns } from './tableConfiguration';

const IntakeWeekly = () => {
  const location = useLocation();
  const history = useHistory();
  const { accountUuid } = useAccount();
  const tableId: string = 'intakeWeekly';
  const { hiddenColumns, setHiddenColumns } = useColumnConfiguration(tableId);
  const handleOrderChange = createSortingHandler(location, history);
  const { fulfilmentStatus, startDate } = parseQueryParams(location.search);
  const { addError } = useNotification();

  const endDate = useMemo(() => moment(startDate).add(6, 'd').format(FORMAT_DATE_TRANSFERABLE), [startDate]);
  const columns: TableColumn<ProductIntake>[] = useMemo(() => {
    return getColumns({ pathname: location.pathname, search: location.search });
  }, [location.pathname, location.search]);
  const filters = useMemo(() => prepareFilters(location.search, endDate), [location.search, endDate]);

  const [exportRows] = useIntakePlanningExportProductsDataMutation();

  const results = usePagination<IntakePlanningProductsQueryResult, IntakePlanningProductsQueryVariables, ProductIntake>(
    useIntakePlanningProductsQuery,
    'account.intakePlanningProducts',
    {
      accountUuid,
      fulfilmentStatus,
      ...filters
    },
    20
  );

  const { data: skuOverviewResponse, refetch: refetchSkuOverview } = useIntakePlanningSkuOverviewQuery({
    variables: {
      accountUuid,
      dateRange: filters.dateRange,
      destinationId: null
    }
  });

  const skuOverviewResults = skuOverviewResponse?.account?.intakePlanningSkuOverview;
  const state: { previousUrl: string } = { previousUrl: `${location.pathname}${location.search}` };

  const { nodes, paginationInfo, totalCount, loading, error, refetch: refetchProducts } = results;
  const hasResults = nodes.length > 0;

  const handleExport = async (): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () =>
        exportRows({
          variables: {
            input: {
              accountUuid,
              exportData: {
                columns: getColumnKeys(columns, hiddenColumns) as ProductsColumnEnum[],
                dataFilters: {
                  dateRange: filters.dateRange,
                  destinationId: filters.destinationId
                }
              }
            }
          }
        }),
      onError: addError
    });

  const handlePreferencesSwitch = () => {
    refetchProducts();
    refetchSkuOverview();
  };

  const removeStatusFilter = () => {
    history.replace({
      ...location,
      search: mergeQueryParams(location.search, { fulfilmentStatus: null })
    });
  };

  const redirectToProductPage = (product: Nullable<Product>) => {
    if (product) {
      history.push(`${productPathPrefix}/${product.id}/po`, state);
    }
  };

  const renderFilterStatus = () => {
    const label = removeSpecialCharactersAndUpperFirst(fulfilmentStatus);

    return <Chip isSelected={true} label={label} onDelete={removeStatusFilter} />;
  };

  return (
    <Page
      defaultBackUrl={`${intakePathPrefix}${location.search}`}
      filterSwitcher={<PreferencesIndicator onChange={handlePreferencesSwitch} />}
      qualityIndicator={<DataQualityContainer />}
      tagline={<WeekSwitcher endDate={endDate} startDate={startDate} />}
      title="Intake week view"
    >
      <ProductSelect onChange={redirectToProductPage} />
      {hasResults && <SkuOverview {...skuOverviewResults} />}
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonTableLoading columns={columns} order={filters.order} tableId={tableId} />}
        noResults={
          <NoResults
            headline="We can't find any results"
            tagline="There are no results for your query, please adjust your search or filter criteria."
          />
        }
      >
        {(productIntakes: ProductIntake[]) => (
          <>
            <div className="flex justify-between items-center mb-8">
              <div className="flex items-center">
                <div className="text-lg leading-normal text-grey-dark font-bold mr-4">
                  Displaying {productIntakes.length} of {totalCount} SKUs
                </div>
                {fulfilmentStatus && renderFilterStatus()}
              </div>
              <div>
                <div className="flex">
                  <CustomiseTableButton columns={columns} hiddenColumns={hiddenColumns} onChange={setHiddenColumns} />
                  <DataExportButton className="ml-3" confirmLabel="Return to intake planning" onClickPromise={handleExport} />
                </div>
              </div>
            </div>
            <Table<ProductIntake>
              columns={columns}
              data={productIntakes}
              hiddenColumns={hiddenColumns}
              onOrderChange={handleOrderChange}
              order={filters.order}
              tableId={tableId}
            />
            <Pagination pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
    </Page>
  );
};

export default IntakeWeekly;
