import { sumBy, toNumber } from 'lodash';
import moment from 'moment';

import { Currency, Money } from '@zen/types';
import { formatCurrency } from '@zen/utils/formatting';

import type { IntakeChartValue, WeeklyIntakeValue } from '../types';

const getMonthDay = (date: string): string => moment(date).format('MMM DD');
const getMonthDayYear = (date: string): string => moment(date).format('MMM DD, YYYY');
const getValue = (currency: Money): string =>
  currency ? formatCurrency(currency.currency, +currency.value, 'symbol', true) : formatCurrency(Currency.GBP, 0, 'symbol', true);

const getIntakeTotals = (data: WeeklyIntakeValue[]) => ({
  totalQuantityArrived: sumBy(data, (item: WeeklyIntakeValue) => item.quantityArriving),
  totalQuantityPlanned: sumBy(data, (item: WeeklyIntakeValue) => item.quantityPlanned),
  totalValuePlanned: {
    currency: Currency.GBP,
    value: sumBy(data, (item: WeeklyIntakeValue) => (item.valuePlanned ? toNumber(item.valuePlanned.value) : 0))
  },
  totalValueArrived: {
    currency: Currency.GBP,
    value: sumBy(data, (item: WeeklyIntakeValue) => (item.valueArriving ? toNumber(item.valueArriving.value) : 0))
  }
});

const getPreparedData = (data: WeeklyIntakeValue[]): IntakeChartValue[] =>
  data.map((period) => ({
    ...period,
    startDate: getMonthDay(period.dateRange.startDate),
    endDate: getMonthDay(period.dateRange.endDate),
    valueArriving: getValue(period.valueArriving),
    valuePlanned: getValue(period.valuePlanned),
    quantityArrivingPosition: period.quantityArriving - period.quantityPlanned
  }));

export { getIntakeTotals, getMonthDay, getMonthDayYear, getPreparedData };
