import type { FC } from 'react';

import { FormArray, FormArrayHelpers } from '@zen/Components/Form';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import { Button } from '@zen/DesignSystem';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';

import { getEmptyConsolidationLeg } from '../../consolidation.helper';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, ConsolidationLegValues } from '../../types';

interface Props {
  accountId: string;
  legs: ConsolidationLegValues[];
}

const ConsolidationLegs: FC<Props> = ({ accountId, legs }) => {
  const { networksDestination: previousLegDestination } = legs[legs.length - 1];

  return (
    <div data-testid="consolidation-legs">
      <FormArray addButtonText="Add leg" empty={getEmptyConsolidationLeg(previousLegDestination)} path="legs" values={legs}>
        {({ remove, getFieldName }: FormArrayHelpers<ConsolidationLegValues>) => {
          const shouldRenderDeleteButton: boolean = legs.length > 1;

          return (
            <div className="flex flex-col bg-grey-lightest p-4 max-w-3xl">
              <NetworksContactFormInput
                accountUuid={accountId}
                assignmentType={AssignmentTypeValue.ORIGIN}
                domainName={AssignmentTargetTypeEnum.CONSOLIDATED_SHIPMENT_LEG}
                isRequired={true}
                label="Origin"
                name={getFieldName('networksOrigin')}
                showAddButton={false}
              />
              <FormDatePickerInput
                isClearable={true}
                isRequired={true}
                label="Estimated departure date"
                name={getFieldName('estimatedDepartureDate')}
              />
              <FormDatePickerInput isClearable={true} label="Actual departure date" name={getFieldName('actualDepartureDate')} />

              <NetworksContactFormInput
                accountUuid={accountId}
                assignmentType={AssignmentTypeValue.ORIGIN}
                domainName={AssignmentTargetTypeEnum.CONSOLIDATED_SHIPMENT_LEG}
                isRequired={true}
                label="Destination"
                name={getFieldName('networksDestination')}
                showAddButton={false}
              />
              <FormDatePickerInput
                isClearable={true}
                isRequired={true}
                label="Estimated arrival date"
                name={getFieldName('estimatedArrivalDate')}
              />
              <FormDatePickerInput isClearable={true} label="Actual arrival date" name={getFieldName('actualArrivalDate')} />
              {shouldRenderDeleteButton && (
                <Button className="mt-4" iconLeft="zicon-trash" onClick={remove} variant="secondary">
                  Delete leg
                </Button>
              )}
            </div>
          );
        }}
      </FormArray>
    </div>
  );
};

export default ConsolidationLegs;
