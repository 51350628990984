import TableLink from '../../Components/TableLink';
import Table from './Table';

export { TableConfigurationProvider, useTableConfiguration } from './contexts/TableConfigurationContext';
export { useColumnConfiguration } from './hooks/useColumnConfiguration';
export { tableDataMock } from './table.mock';
export * from './types';

export { TableLink };
export type { TableProps } from './Table';

export default Table;
