import { FC, useState } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import ShipmentTrackingStatus from '@zen/Shipment/components/ShipmentTrackingStatus';
import SkeletonMilestones from '@zen/Shipment/RouteProgress/VoyageMilestones/Loading/SkeletonMilestones';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import { useGetTrackingDataQuery, useUpdateTrackingDataMutation } from './graphql';
import { prepareFormInitialValues, prepareFormPermissions, prepareTrackingData, prepareUpdateTrackingPayload } from './helpers';
import TrackingDetailsForm, { TrackingDetailsFormPermissions, TrackingFormValues } from './TrackingDetailsForm';
import type { OceanTrackingResults } from './types';

interface Props {
  onTrackingDataChange: () => void;
  zencargoReference: string;
}

const OceanTrackingStatus: FC<Props> = ({ onTrackingDataChange, zencargoReference }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [updateTracking] = useUpdateTrackingDataMutation();
  const { addSuccess, addError } = useNotification();
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();

  const { loading, data, refetch, error } = useGetTrackingDataQuery({
    variables: {
      newCargoService: !!isNewCargoServiceEnabled,
      zencargoReferences: [zencargoReference]
    },
    fetchPolicy: 'cache-and-network'
  });

  const trackingResults: Optional<OceanTrackingResults> = data?.bookings?.nodes?.[0];
  const formPermissions: TrackingDetailsFormPermissions = prepareFormPermissions(trackingResults);

  const handleCancel = (): void => setIsEditMode(false);

  const handleEdit = (): void => setIsEditMode(true);

  const handleSuccess = (): void => {
    setIsEditMode(false);
    onTrackingDataChange?.();
    refetch();
  };

  const handleSubmit = (formValues: TrackingFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateTracking({
          variables: {
            input: prepareUpdateTrackingPayload(zencargoReference, formValues, formPermissions)
          }
        }),
      onSuccess: () => addSuccess('Tracking updates added successfully'),
      onError: () => addError()
    });
  };

  return (
    <QueryHandler data={trackingResults} error={!!error} isLoading={loading} loadingComponent={<SkeletonMilestones />}>
      {(trackingData: OceanTrackingResults) => {
        const { trackingStatus, trackingType, missingDataPoints } = prepareTrackingData(trackingData);

        return (
          <ShipmentTrackingStatus
            isEditMode={isEditMode}
            missingDataPoints={missingDataPoints}
            onEdit={handleEdit}
            trackingStatus={trackingStatus}
            trackingType={trackingType}
          >
            <TrackingDetailsForm
              formPermissions={formPermissions}
              initialValues={prepareFormInitialValues(trackingData)}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              onSuccess={handleSuccess}
            />
          </ShipmentTrackingStatus>
        );
      }}
    </QueryHandler>
  );
};

export default OceanTrackingStatus;
