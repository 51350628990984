import type { FC } from 'react';

const Content: FC = ({ children }) => {
  return (
    <div className="bg-white pt-6 pb-8 px-8" data-testid="modal-content">
      {children}
    </div>
  );
};

export default Content;
