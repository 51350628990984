import type { FC } from 'react';
import { useLocation } from 'react-router';

import type { TabViewConfig } from '@zen/Components/TabViewController/types';
import type { ActionItemType } from '@zen/Dashboard/ActionItems/types';
import { ContainerWithTabs } from '@zen/DesignSystem';
import { getActiveIssues } from '@zen/Issues/issueHelpers';
import Issues from '@zen/Issues/Issues';
import AddIssueButton from '@zen/Issues/Issues/AddIssueButton';
import { Issue, IssueAssociatedObject } from '@zen/Issues/types';

import ActionItems from './ActionItems';

interface Props {
  accountUuid: string;
  actionItems: Pick<ActionItemType, 'id' | 'dueDate' | 'name' | 'title' | 'type'>[];
  canEditIssueVisibility: boolean;
  canOpenIssue: boolean;
  issues: Issue[];
  onShipmentChange: () => void;
  zencargoReference: string;
}

export enum ActionTab {
  ACTIONS = 'ACTIONS',
  ISSUES = 'ISSUES'
}

const IssuesAndActions: FC<Props> = (props) => {
  const { actionItems, issues, zencargoReference, onShipmentChange, accountUuid, canOpenIssue, canEditIssueVisibility } = props;
  const { state } = useLocation<{ defaultTab: ActionTab }>();
  const defaultTab: ActionTab = state?.defaultTab ? state.defaultTab : ActionTab.ISSUES;
  const actionItemCount: number = actionItems.length;
  const activeIssues: Issue[] = getActiveIssues<Issue>(issues);
  const issuesCount: number = activeIssues.length;

  const tabsConfig: TabViewConfig[] = [
    {
      label: 'Issues',
      totalCount: issuesCount,
      isDefault: defaultTab === ActionTab.ISSUES,
      component: <Issues associatedObjectReference={zencargoReference} issues={issues} onIssueChange={onShipmentChange} />
    },
    {
      label: 'Action items',
      totalCount: actionItemCount,
      isDefault: defaultTab === ActionTab.ACTIONS,
      component: (
        <ActionItems
          accountUuid={accountUuid}
          actionItems={actionItems}
          onShipmentChange={onShipmentChange}
          zencargoReference={zencargoReference}
        />
      )
    }
  ];

  return (
    <ContainerWithTabs
      actions={
        canOpenIssue && (
          <AddIssueButton
            associatedObjectReference={zencargoReference}
            associatedType={IssueAssociatedObject.BOOKING}
            canEditIssueVisibility={canEditIssueVisibility}
          />
        )
      }
      tabViewConfig={tabsConfig}
      title="Issues & Actions"
    />
  );
};

export default IssuesAndActions;
