import { FC, ReactNode, useState } from 'react';

import { Form, FormInstance } from '@zen/Components/Form';
import FormAsyncSelect from '@zen/Components/Form/FormAsyncSelect';
import FormButtons from '@zen/Components/Form/FormButtons/FormButtons';
import FormHeadline from '@zen/Components/Form/FormHeadline';
import FormInput from '@zen/Components/Form/FormInput/FormInput';
import { highlightQuery } from '@zen/Components/Form/utils';
import RadioGroup from '@zen/Components/RadioGroup';
import { Button } from '@zen/DesignSystem';
import type { NetworksOrganisationInterface } from '@zen/graphql/types.generated';
import { createBusinessHoursInput } from '@zen/Networks/businessHours.mock';
import { useNetworksCreateOrganisationLocationMutation, useNetworksCreateOrganisationMutation } from '@zen/Networks/graphql';
import useAllowedOrganisationTypes from '@zen/Networks/hooks/useAllowedOrganisationTypes';
import { prepareLocationRequestData } from '@zen/Networks/Locations/NewLocation/prepareLocationRequestData';
import { handleOrganisationInputLoad } from '@zen/Networks/networksHelpers';
import { prepareRequestData } from '@zen/Networks/Organisations/prepareRequestData';
import type {
  CreateOrganisationInput,
  CreateOrganisationLocationInput,
  LocationTypeValue,
  NetworksOrgLoc,
  Organisation,
  OrganisationTypeValue
} from '@zen/Networks/types';
import type { ButtonClick } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import { performFormMutation } from '@zen/utils/performMutation';
import type { DeepNullable, Nullable } from '@zen/utils/typescript';

import FormAddressFields from '../FormAddressFields';
import type { IAddressForm } from '../FormAddressFields/types';
import type { IOrganisationFormFields } from '../OrganisationForm/types';
import orgLocValidationSchema from './orgLocValidation';
import type { OrgLocFormType } from './types';

export interface IOrgLocFormFields extends IAddressForm {
  locationName?: string;
  locationType: LocationTypeValue;
  name?: string;
  organisation?: Nullable<Organisation>;
  organisationType?: string;
}

export const orgLocInitialValues: DeepNullable<IOrgLocFormFields> = {
  businessHours: createBusinessHoursInput(),
  city: '',
  countryCode: null,
  lat: null,
  lng: null,
  locationAddress: '',
  locationType: null,
  name: '',
  locationName: '',
  organisation: null,
  organisationType: '',
  postalCode: '',
  state: '',
  street: ''
};

interface Props {
  accountUuid: string;
  handleAdd?: (orgLoc: NetworksOrgLoc) => void;
  handleCancel: () => void;
}

const OrgLocForm: FC<Props> = ({ accountUuid, handleCancel, handleAdd }) => {
  const organisationTypes = useAllowedOrganisationTypes(accountUuid);
  const [formType, setFormType] = useState<OrgLocFormType>('location');

  const { addSuccess, addError } = useNotification();
  const { trackEvent } = useTracking();
  const [createOrganisation] = useNetworksCreateOrganisationMutation();
  const [createLocation] = useNetworksCreateOrganisationLocationMutation();
  const addNewOrganisation: boolean = formType === 'organisation';

  const handleSubmit = async (values: IOrgLocFormFields) => {
    const { organisation, organisationType, locationName, ...rest } = values;
    const organisationId = organisation?.id;

    if (organisationId) {
      const orgLoc = { ...rest, name: locationName };
      const data: CreateOrganisationLocationInput = prepareLocationRequestData(orgLoc, accountUuid, organisationId);

      return performFormMutation({
        mutationFn: () =>
          createLocation({
            variables: { input: { ...data } }
          }),
        onError: addError
      });
    }

    const data: CreateOrganisationInput = prepareRequestData(values as IOrganisationFormFields, accountUuid);

    return performFormMutation({
      mutationFn: () =>
        createOrganisation({
          variables: {
            input: {
              ...data,
              type: organisationType as OrganisationTypeValue
            }
          }
        }),
      onError: addError
    });
  };

  const handleSuccess = ({ orgLoc }: { orgLoc: NetworksOrgLoc }) => {
    const message: string = formType === 'organisation' ? 'New organisation has been created.' : 'New location has been created.';

    addSuccess(message);
    handleAdd?.(orgLoc);
  };

  const onOrganisationSelect = (organisation: Nullable<NetworksOrganisationInterface>): void => {
    trackEvent({
      action: 'Networks',
      category: 'Search',
      label: organisation?.name || ''
    });
  };

  if (organisationTypes.length === 1) {
    orgLocInitialValues.organisationType = organisationTypes[0].value;
  }

  const formatOptionLabel = (organisation: Organisation, query: string): ReactNode => {
    return highlightQuery(organisation.name, query);
  };

  return (
    <Form
      formButtons={() => null}
      formName="OrgLocForm"
      initialValues={orgLocInitialValues}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      validationSchema={orgLocValidationSchema(formType)}
    >
      {({ setFieldValue, isSubmitting, submitForm }: FormInstance<IOrgLocFormFields>) => {
        const handleSubmitButton = (e: ButtonClick): void => {
          e.stopPropagation();
          e.preventDefault();
          submitForm();
        };

        const handleFormTypeChange = (value: OrgLocFormType): void => {
          if (value === 'organisation') {
            setFieldValue('name', null);
          } else {
            setFieldValue('organisation', null);
          }

          setFormType(value);
        };

        return (
          <div className="pb-8">
            <div className="mb-16">
              <RadioGroup
                name="target"
                onChange={(value: string) => handleFormTypeChange(value as OrgLocFormType)}
                options={[
                  { label: 'Add to an existing organisation', value: 'location' },
                  { label: 'Create new organisation', value: 'organisation' }
                ]}
                radioAlignment="column"
                value={formType}
              />
              {addNewOrganisation && <FormInput isRequired={true} label="Organisation name" name="name" />}
              {!addNewOrganisation && (
                <FormAsyncSelect
                  formatOptionLabel={formatOptionLabel}
                  isClearable={true}
                  isRequired={true}
                  label="Choose organisation"
                  loadOptions={handleOrganisationInputLoad(accountUuid)}
                  name="organisation"
                  onSelect={(_, value: Nullable<Organisation>) => onOrganisationSelect(value)}
                  placeholder="Search my network..."
                />
              )}
            </div>

            <FormHeadline
              tagline="Please specify where your organisation is based.
            This information will be used in bills of lading and other documents."
              text="Address"
            />

            {!addNewOrganisation && <FormInput label="Location name" name="locationName" />}

            <FormAddressFields />

            <FormButtons isSubmitting={isSubmitting} onClick={handleSubmitButton} text="Save changes" type="button">
              <Button onClick={handleCancel} variant="tertiary">
                Cancel
              </Button>
            </FormButtons>
          </div>
        );
      }}
    </Form>
  );
};

export default OrgLocForm;
