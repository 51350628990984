import type { FC } from 'react';

import { FormInput } from '@zen/Components/Form';
import FormPhoneNumber from '@zen/Components/Form/FormPhoneNumber';

interface Props {
  isEmailDisabled?: boolean;
}

const FormContactFields: FC<Props> = ({ isEmailDisabled }) => (
  <div data-testid="form-contact-fields">
    <FormInput isRequired={true} name="firstName" />
    <FormInput isRequired={true} name="lastName" />
    <FormPhoneNumber isRequired={true} name="phoneNumber" />
    <FormInput disabled={isEmailDisabled} isRequired={true} name="email" />
  </div>
);

export default FormContactFields;
