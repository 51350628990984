import cx from 'classnames';
import { FC, useContext } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import BookingStatus from '@zen/Components/BookingStatus';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import type { MenuItemType } from '@zen/DesignSystem';
import { Checkbox, ContextMenu } from '@zen/DesignSystem';
import type { CargoReadyDateValues } from '@zen/Orders/components/CargoReadyDateForm';
import UpdateCRDContext from '@zen/Orders/contexts/UpdateCRDContext';
import ProductName from '@zen/Orders/OrderDetails/LineItems/LineItem/ProductName';
import type { OrderListViewLot } from '@zen/Orders/types';
import { productPathPrefix } from '@zen/Products/ProductsContainer';
import { shipmentRoutes } from '@zen/Routes';
import { getToday } from '@zen/utils/date';
import { formatDate } from '@zen/utils/dateTime';
import { formatNumber } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  lot: OrderListViewLot;
}

const LotLineItem: FC<Props> = ({ lot }) => {
  const { setCargoReadyDateValues } = useContext(UpdateCRDContext);
  const { check } = useGlobalPermissions();
  const { isBasketMode, isSelected, addItems, removeItems } = useContext(BasketContext);
  const {
    id,
    booking,
    quantityFulfilled,
    cargoReadyDate,
    cbm,
    atRisk,
    currentStage,
    deliveryStatus,
    orderedLineItem,
    purchaseOrder
  } = lot;

  const product = orderedLineItem?.product;
  const orderDate = purchaseOrder?.orderDate;
  const orderReferenceNumber = purchaseOrder?.orderReferenceNumber;

  const canAccessProductsRoute: boolean = check('products.canAccessRoute');
  const isBooked: boolean = booking !== null;
  const readyDate: Optional<string> = cargoReadyDate?.date;
  const deliveryDate: Optional<string> = deliveryStatus?.date?.date;
  const deliveryStatusClassname: Optional<string> = deliveryStatus?.statusType?.toLowerCase();

  const classNames: string = cx(
    {
      'text-red-base': deliveryStatusClassname === 'alert' || deliveryStatusClassname === 'problem',
      'text-orange-base': deliveryStatusClassname === 'warning'
    },
    'font-bold'
  );

  const openCargoReadyDateSlideout = (): void => {
    const cargoReadyDateValues: CargoReadyDateValues = {
      lotIds: [id],
      cargoReadyDate: readyDate || getToday(),
      orderDate,
      orderReferenceNumber
    };

    setCargoReadyDateValues(cargoReadyDateValues);
  };

  const getDropdownItems = (): MenuItemType[] => {
    const items: MenuItemType[] = [
      {
        label: 'Update ready date',
        onClick: openCargoReadyDateSlideout,
        icon: 'zicon-calendar'
      }
    ];

    if (booking) {
      items.push({
        label: 'View booking',
        linkTo: shipmentRoutes.shipmentDetailsPage.getUrl(booking.zencargoReference),
        icon: 'zicon-box'
      });
    }

    return items;
  };

  const productUrl: string | undefined = canAccessProductsRoute
    ? `${productPathPrefix}/${product?.id}/po?startDate=${getToday()}&period=4`
    : undefined;

  const handleChange = (): void => {
    if (isSelected(id)) {
      removeItems([id]);
    } else {
      addItems([lot]);
    }
  };

  return (
    <div className="relative" data-testid="lot-line-item">
      <div className="flex py-4 text-sm">
        {isBasketMode && (
          <div className="w-16">
            <Checkbox checked={isSelected(id)} onChange={handleChange} />
          </div>
        )}
        <div className="w-32">
          <div className="mb-2 text-base">{formatNumber(quantityFulfilled || 0)}</div>
          <div>{cbm ? formatNumber(cbm) : '-'}</div>
        </div>
        <div className="w-1/2 grow-0">
          <div className="mb-2 text-base" data-testid="product-name">
            <ProductName url={productUrl}>{product?.name}</ProductName>
          </div>
          <div>SKU code:{product?.skuCode}</div>
        </div>
        <div className="w-1/5 grow-0">
          <BookingStatus atRisk={!!atRisk} booked={isBooked} className="mb-2 w-32" />
          {readyDate && <div className="w-32 text-center">Ready {formatDate(readyDate, 'MMM_D')}</div>}
        </div>
        <div className="font-bold text-grey-dark text-base w-1/5">{currentStage?.name}</div>
        <div className="w-1/5 grow-0">
          <div className="font-bold text-grey-dark text-base mb-2">{deliveryDate ? formatDate(deliveryDate) : 'Calculating'}</div>
          <div className={classNames}>{deliveryStatus?.label}</div>
        </div>
        <div className="w-12">{!isBasketMode && <ContextMenu items={getDropdownItems()} />}</div>
      </div>
    </div>
  );
};

export default LotLineItem;
