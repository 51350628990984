import { difference } from 'lodash';
import { FC, ReactElement, ReactNode, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';

import NavigationPrompt from '@zen/Components/NavigationPrompt';
import NoResults from '@zen/Components/NoResults';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import QueryHandler from '@zen/Components/QueryHandler';
import SectionTitle from '@zen/Components/SectionTitle';
import { Button, Pagination } from '@zen/DesignSystem';
import type { OrderListViewItem } from '@zen/Orders';
import OrdersSearch from '@zen/Orders/components/OrdersSearch';
import useOrdersListQuery from '@zen/Orders/hooks/useOrdersListView';
import { useUpdateAssignedLotsToCargoMutation } from '@zen/Orders/LotAssignments/graphql';
import OrderListItem from '@zen/Orders/OrderList/OrderListItem';
import OrderListItemSimpleDetails from '@zen/Orders/OrderList/OrderListItem/OrderListItemSimpleDetails';
import OrderListItemSimpleHeadline from '@zen/Orders/OrderList/OrderListItem/OrderListItemSimpleHeadline';
import { packingListsRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';
import type { Undefinable } from '@zen/utils/typescript';

interface Props {
  cargoId: string;
  onSuccess: () => void;
  zencargoReference: string;
}

const PackingListManagement: FC<Props> = ({ cargoId, onSuccess, zencargoReference }) => {
  const { initialItemIds, items, removeAllItems, resetBasket } = useContext(BasketContext);
  const { addError } = useNotification();
  const { search } = useLocation();
  const { textContains } = parseQueryParams(search);

  const itemIds: string[] = items.map(({ id }) => id);
  const lotIdsToAssign: string[] = difference(itemIds, initialItemIds || []);
  const lotIdsToUnassign: string[] = difference(initialItemIds, itemIds);
  const isSubmitDisabled: boolean = lotIdsToAssign.length === 0 && lotIdsToUnassign.length === 0;
  const allowedPaths: string[] = [packingListsRoutes.packingListDetails.getUrl(zencargoReference, cargoId)];

  const { nodes, paginationInfo, loading, error } = useOrdersListQuery({
    availableForCargo: cargoId,
    withLotCountsForCargo: true
  });

  const noResults: Undefinable<ReactNode> = !textContains ? <NoResults headline="No POs available in your booking" /> : undefined;

  const [updateLots] = useUpdateAssignedLotsToCargoMutation();

  const handleSuccess = (): void => {
    onSuccess();
    resetBasket?.();
  };

  const handleSave = async () => {
    await performMutation({
      mutationFn: () =>
        updateLots({
          variables: {
            input: {
              lotIdsToAssign,
              lotIdsToUnassign,
              cargoId
            }
          }
        }),
      onError: () => addError(),
      onSuccess: handleSuccess
    });
  };

  useEffect(() => {
    // we need to clear basket when cargo id has changed
    removeAllItems?.();
  }, [cargoId]);

  return (
    <>
      <div className="flex items-start justify-between">
        <SectionTitle title="Packing list" />
        <Button disabled={isSubmitDisabled} onClick={handleSave}>
          Save
        </Button>
      </div>
      <OrdersSearch />
      <QueryHandler data={nodes} error={!!error} isLoading={loading} noResults={noResults}>
        {(orders: OrderListViewItem[]): ReactElement => {
          return (
            <div className="mt-4" data-testid="order-list">
              {orders.map(
                (order: OrderListViewItem): ReactNode => (
                  <OrderListItem
                    key={order.id}
                    order={order}
                    orderDetailsComponent={OrderListItemSimpleDetails}
                    orderLotsQueryVariables={{ availableForCargo: cargoId }}
                    orderOverviewComponent={OrderListItemSimpleHeadline}
                  />
                )
              )}
              <Pagination pageInfo={paginationInfo} />
            </div>
          );
        }}
      </QueryHandler>
      <NavigationPrompt
        allowedPaths={allowedPaths}
        confirmationNeeded={!isSubmitDisabled}
        header="You have unsaved changes, are you sure you want to leave?"
        leaveLabel="I want to leave"
        stayLabel="Return to packing list"
      />
    </>
  );
};

export default PackingListManagement;
