import type { FC, ReactNode } from 'react';
import type * as Yup from 'yup';

import { Form, FormInstance } from '@zen/Components/Form';
import DateWithTimeRangeFields from '@zen/Components/Form/DateWithTimeRangeFields';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import CollectionAndDeliveryFormButtons from '../../CollectionAndDeliveryFormButtons';
import type { ForwarderSchedulerFormValues, ScheduleData } from './types';

interface Props {
  collection: ScheduleData;
  delivery: ScheduleData;
  initialValues: ForwarderSchedulerFormValues;
  onCancel: () => void;
  onSubmit: (values: ForwarderSchedulerFormValues) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
  validationSchema?: Yup.ObjectSchema<{}>;
}

const ForwarderSchedulerForm: FC<Props> = (props) => {
  const { collection, delivery, initialValues, onCancel, onSubmit, onSuccess, validationSchema } = props;

  const renderFormButtons = ({ dirty, isSubmitting }: FormInstance<ForwarderSchedulerFormValues>): ReactNode => {
    return <CollectionAndDeliveryFormButtons isDisabled={!dirty} isSubmitting={isSubmitting} onCancel={onCancel} />;
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      initialValues={initialValues}
      isNested={true}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      submitOnEnter={false}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <div className="w-96 js-dateAndTimeRangeForm" data-testid="forwarder-scheduler-form">
            <DateWithTimeRangeFields
              businessHours={collection.businessHours}
              isRequired={collection.isRequired}
              label={collection.label}
              name="collection"
              timeZone={collection.timeZone}
            />
            <DateWithTimeRangeFields
              businessHours={delivery.businessHours}
              isRequired={delivery.isRequired}
              label={delivery.label}
              name="delivery"
              timeZone={delivery.timeZone}
            />
          </div>
        );
      }}
    </Form>
  );
};

export default ForwarderSchedulerForm;
