import type { FC } from 'react';

import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import { getToday } from '@zen/utils/date';

interface Props {
  canSetShippingOrderReleasedOn: boolean;
  shippingOrderReleasedMinDate: string;
}

const ShippingOrderDateInput: FC<Props> = ({ shippingOrderReleasedMinDate, canSetShippingOrderReleasedOn }) => {
  return (
    <div className="flex w-full space-x-4">
      <FormDatePickerInput
        label="Shipping order confirmed by carrier on..."
        maxDate={getToday()}
        name="shippingOrderConfirmedOn"
        pickerPositionX="left"
      />
      {canSetShippingOrderReleasedOn && (
        <FormDatePickerInput
          label="Shipping order released to supplier on..."
          maxDate={getToday()}
          minDate={shippingOrderReleasedMinDate}
          name="shippingOrderReleasedOn"
        />
      )}
    </div>
  );
};

export default ShippingOrderDateInput;
