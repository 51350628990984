import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import StageName from '@zen/Components/StageName';
import { Icon } from '@zen/DesignSystem';

import type { FlatShipmentDetailsBoard } from '../types';
import NetworksAndDeliveryEstimate from './NetworksAndDeliveryEstimate';

interface Props {
  shipment: FlatShipmentDetailsBoard;
  zencargoReference: string;
}

const DetailsBoardNetworksSection: FC<Props> = ({ shipment, zencargoReference }) => {
  const { archivedAt, networksDestination, networksOrigin, stageName, shipmentStatus, modeOfTransport } = shipment;

  const isShipmentArchived: boolean = !!archivedAt;

  const renderStatusIcon = (isArchived: boolean): ReactNode => {
    const backgroundColor: Record<string, boolean> = { 'bg-red-base': isArchived, 'bg-green-base': !isArchived };

    const statusIconClassName: string = cx('absolute h-full w-full rounded-full opacity-70', {
      ...backgroundColor,
      'animate-ping': !isArchived
    });

    return (
      <div className="self-center relative flex h-2 w-2 mx-2">
        <span className={statusIconClassName} />
        <span className={cx('relative rounded-full h-2 w-2', backgroundColor)} />
      </div>
    );
  };

  const renderStatus = (isArchived: boolean): ReactNode => {
    const stage: ReactNode = stageName ? <StageName name={stageName} transportMode={modeOfTransport} /> : '-';
    const stageLabel: ReactNode = isArchived ? 'Archived' : stage;

    return (
      <div className="flex items-center">
        {renderStatusIcon(isArchived)}
        <div className="flex flex-col ml-1">
          <span>{stageLabel}</span>
          {shipmentStatus && <span className="text-grey-base mt-1">{shipmentStatus}</span>}
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-between items-center py-6">
      <div className="flex items-center">
        <div className="font-bold">{networksOrigin}</div>
        <Icon className="mx-3" icon="zicon-arrow-right" />
        <div className="font-bold mr-3">{networksDestination}</div>
        {renderStatus(isShipmentArchived)}
      </div>
      <NetworksAndDeliveryEstimate shipment={shipment} zencargoReference={zencargoReference} />
    </div>
  );
};

export default DetailsBoardNetworksSection;
