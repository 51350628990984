import type { FC, ReactNode } from 'react';

import { InlineEmptyState } from '@zen/DesignSystem';
import CargoSummary from '@zen/PackingLists/components/CargoSummary';
import PackingListLine from '@zen/PackingLists/components/PackingListLine';
import type { PackingListOrder } from '@zen/PackingLists/PackingListDetails/types';
import type { ModeOfTransport } from '@zen/types';

import type { PackingListCargo } from '../types';

interface Props {
  cargo: PackingListCargo;
  modeOfTransport: ModeOfTransport;
  onEdit?: (id: string) => void;
  onOrderExpand: (orderId: string, cargoId: string) => void;
  orders: PackingListOrder[];
}

const PackingListItem: FC<Props> = ({ cargo, modeOfTransport, onEdit, onOrderExpand, orders }) => {
  const hasNoOrders: boolean = orders.length === 0;

  return (
    <div className="mb-6" data-testid="packing-list-item">
      <div className="border-solid border-l-2 border-r-2 border-grey-lightest mb-2">
        <CargoSummary cargo={cargo} modeOfTransport={modeOfTransport} onEdit={onEdit} />
        {orders.map(
          (order: PackingListOrder): ReactNode => (
            <PackingListLine key={order.id} onExpand={(orderId: string) => onOrderExpand(orderId, cargo.id)} order={order} />
          )
        )}
      </div>
      {hasNoOrders && (
        <InlineEmptyState className="mt-4" title="There are no purchase orders or SKUs in this cargo yet.">
          Purchase orders and SKUs information will be displayed when attached to the shipment.
        </InlineEmptyState>
      )}
    </div>
  );
};

export default PackingListItem;
