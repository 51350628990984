import { getShipmentStop } from '../helpers';
import { StopType } from '../types';
import type { RoadTrackedLiveShipment, RoadTrackedResponseNode } from './types';

export const prepareShipments = (shipments: RoadTrackedResponseNode[]): RoadTrackedLiveShipment[] => {
  return shipments.map((shipment) => {
    const { zencargoReference, booking, status, latestStopUpdates } = shipment;

    return {
      clientReference: booking?.clientReference,
      collection: getShipmentStop(latestStopUpdates, StopType.COLLECTION),
      delivery: getShipmentStop(latestStopUpdates, StopType.DELIVERY),
      originalDelivery: booking?.cargo?.cargoItems?.[0]?.delivery?.confirmedByForwarder,
      mapUrl: booking?.roadTracking?.trackingInput?.trackingSubscription?.mapUrl,
      status,
      zencargoReference
    };
  });
};
