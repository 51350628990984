import { useMemo } from 'react';

import type { TableProps } from '@zen/DesignSystem';
import { Table } from '@zen/DesignSystem';

import SkeletonLoading from '../SkeletonLoading';

type Props<T extends {}> = Pick<TableProps<T>, 'columns' | 'hiddenColumns' | 'order' | 'tableId'> & {
  rowCount?: number;
};

const GeneratedSkeleton = () => {
  return <SkeletonLoading className="ml-0 mr-0" height={20} width="w-full" />;
};

const SkeletonTableLoading = <T extends {}>({ rowCount = 20, columns, order, hiddenColumns = [], tableId }: Props<T>) => {
  const generatedRowCount: number[] = useMemo(() => Array.from({ length: rowCount }), [rowCount]);

  const generatedColumns = columns.map((column) => {
    return {
      title: column.title,
      dataIndex: column.key,
      key: column.key,
      sortKey: column.sortKey,
      ...(column?.sortable && { sortable: column.sortable }),
      ...(column?.resizable && { resizable: column.resizable }),
      ...(column?.fixed && { fixed: column.fixed }),
      ...(column?.width && { width: column.width })
    };
  });

  const loadingRowCount = useMemo(
    () =>
      generatedColumns.reduce((prev, { key }) => {
        return { ...prev, [key]: <GeneratedSkeleton /> };
      }, {}),
    [generatedColumns]
  );
  const data = generatedRowCount.map(() => ({ ...loadingRowCount }));

  return (
    <div className="py-6">
      <Table
        columns={generatedColumns}
        data={data}
        hiddenColumns={hiddenColumns}
        loading={true}
        order={order}
        renderRowActions={() => false}
        tableId={tableId}
      />
    </div>
  );
};

export type { Props as SkeletonTableLoadingProps };

export default SkeletonTableLoading;
