import cx from 'classnames';
import type { FC } from 'react';

import ValueWithDefault from '@zen/Components/ValueWithDefault';
import type { RoadTrackedShipmentStatus } from '@zen/TrackedShipments/types';
import type { Optional } from '@zen/utils/typescript';

import FilterPointer from './FilterPointer';
import { presentationDataForStatus } from './helpers';

interface Props {
  count: Optional<number>;
  isActive: boolean;
  onClick: (status: RoadTrackedShipmentStatus) => void;
  status: RoadTrackedShipmentStatus;
}

const Filter: FC<Props> = ({ isActive, onClick, status, count }) => {
  const { background, border, hover, label } = presentationDataForStatus(status);

  const className: string = cx(
    [`relative w-56 h-20 px-4 rounded-md border-solid border-2 transition-all duration-300 ${background}`],
    {
      [`${border} cursor-default`]: isActive,
      [`border-transparent ${hover} cursor-pointer`]: !isActive
    }
  );

  return (
    <div className={className} data-testid="filter" onClick={() => !isActive && onClick(status)}>
      <div className="flex flex-col h-full justify-center content-center">
        <div className="text-sm text-grey-base">{label}</div>
        <div className="text-grey-dark text-2xl font-black mt-2">
          <ValueWithDefault value={count} />
        </div>
      </div>
      {isActive && <FilterPointer background={background} border={border} />}
    </div>
  );
};

export default Filter;
