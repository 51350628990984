import type { CargoType } from '@zen/Cargo';
import { LooseCargoTypeEnum, ModeOfTransport } from '@zen/graphql/types.generated';
import { ContainerTypeEnum, PalletTypeEnum, VehicleTypeEnum } from '@zen/types';

import type { ContainerNewCargoType } from '../Cargo/types';

const getContainerLabel = (containerType: ContainerTypeEnum): string => containerTypeLabelMapping[containerType].long;

// TODO map values directly from cargo dictionary
export const CARGO_DICTIONARY_VALUES = [
  'containerX20Dv',
  'containerX20Hc',
  'containerX40Dv',
  'containerX40Hc',
  'containerX45Hc',
  'trailer136MBox',
  'trailer136MCurtainSided',
  'trailer136MMegatrailer',
  'trailer136MSlidingRoof',
  'trailer18TBox',
  'trailer18TCurtainSided',
  'trailer18TSlidingRoof',
  'trailer75TBox',
  'trailer75TCurtainSided',
  'trailer75TSlidingRoof',
  'trailerVanBox',
  'trailerVanCurtainSided',
  'trailerVanSlidingRoof',
  'loosePalletEuro',
  'loosePalletOther',
  'loosePalletUk',
  'loosePalletUs',
  'looseAssortedCargo',
  'looseBoxesOrCrates',
  // Added to temporarily to handle loose cargo type which is pallet but without palletType
  'loosePallet'
] as const;

// Generation cargo types union to get at least part of control on cargo type values uncless we provide enum
export type NewCargoType = typeof CARGO_DICTIONARY_VALUES[number];

// Generate instead of enum object that will contain all possible cargo dictionary values
export const cargoValues = CARGO_DICTIONARY_VALUES.reduce<{ [P in NewCargoType]: P }>(
  (prev: { [P in NewCargoType]: P }, next: NewCargoType) => {
    return {
      ...prev,
      [next]: next
    };
  },
  {} as { [P in NewCargoType]: P }
);

type ContainerTypeToLabel = Record<ContainerTypeEnum, { long: string; short: string }>;
type NewCargoContainerTypeToLabel = Record<ContainerNewCargoType, { long: string; short: string }>;

export const containerTypeLabelMapping: ContainerTypeToLabel = {
  [ContainerTypeEnum.X20DV]: {
    short: "20' ST",
    long: "20' standard"
  },
  [ContainerTypeEnum.X20HC]: {
    short: "20' HC",
    long: "20' high cube"
  },
  [ContainerTypeEnum.X40DV]: {
    short: "40' ST",
    long: "40' standard"
  },
  [ContainerTypeEnum.X40HC]: {
    short: "40' HC",
    long: "40' high cube"
  },
  [ContainerTypeEnum.X45HC]: {
    short: "45' HC",
    long: "45' high cube"
  }
};

export const newCargoContainerTypeLabelMapping: NewCargoContainerTypeToLabel = {
  [cargoValues.containerX20Dv]: {
    short: "20' ST",
    long: "20' standard"
  },
  [cargoValues.containerX20Hc]: {
    short: "20' HC",
    long: "20' high cube"
  },
  [cargoValues.containerX40Dv]: {
    short: "40' ST",
    long: "40' standard"
  },
  [cargoValues.containerX40Hc]: {
    short: "40' HC",
    long: "40' high cube"
  },
  [cargoValues.containerX45Hc]: {
    short: "45' HC",
    long: "45' high cube"
  }
};

export const cargoTypeLabelMapping: Record<CargoType, string> = {
  [ContainerTypeEnum.X20DV]: getContainerLabel(ContainerTypeEnum.X20DV),
  [ContainerTypeEnum.X20HC]: getContainerLabel(ContainerTypeEnum.X20HC),
  [ContainerTypeEnum.X40DV]: getContainerLabel(ContainerTypeEnum.X40DV),
  [ContainerTypeEnum.X40HC]: getContainerLabel(ContainerTypeEnum.X40HC),
  [ContainerTypeEnum.X45HC]: getContainerLabel(ContainerTypeEnum.X45HC),
  [LooseCargoTypeEnum.PALLETS]: 'Pallets',
  [LooseCargoTypeEnum.BOXES_OR_CRATES]: 'Boxes or crates',
  [LooseCargoTypeEnum.ASSORTED_CARGO]: 'Assorted cargo',
  [PalletTypeEnum.EURO]: 'Euro pallet',
  [PalletTypeEnum.UK]: 'UK standard pallet',
  [PalletTypeEnum.US]: 'US standard pallet',
  [PalletTypeEnum.OTHER]: 'Other pallet',
  [VehicleTypeEnum.TRAILER_13_6_M_BOX]: '13.6m - box',
  [VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED]: '13.6m - curtain sided',
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER]: '13.6m - megatrailer',
  [VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF]: '13.6m - sliding Roof',
  [VehicleTypeEnum.TRAILER_18_T_BOX]: '18t - box',
  [VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED]: '18t - curtain sided',
  [VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF]: '18t - sliding roof',
  [VehicleTypeEnum.TRAILER_7_5_T_BOX]: '7.5t - box',
  [VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED]: '7.5t - curtain sided',
  [VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF]: '7.5t - sliding roof',
  [VehicleTypeEnum.VAN_BOX]: 'Van - box',
  [VehicleTypeEnum.VAN_CURTAIN_SIDED]: 'Van - curtain sided',
  [VehicleTypeEnum.VAN_SLIDING_ROOF]: 'Van - sliding roof'
};

export const newCargoTypeLabelMapping: Record<NewCargoType, string> = {
  [cargoValues.containerX20Dv]: getContainerLabel(ContainerTypeEnum.X20DV),
  [cargoValues.containerX20Hc]: getContainerLabel(ContainerTypeEnum.X20HC),
  [cargoValues.containerX40Dv]: getContainerLabel(ContainerTypeEnum.X40DV),
  [cargoValues.containerX40Hc]: getContainerLabel(ContainerTypeEnum.X40HC),
  [cargoValues.containerX45Hc]: getContainerLabel(ContainerTypeEnum.X45HC),
  [cargoValues.loosePallet]: 'Pallets',
  [cargoValues.looseBoxesOrCrates]: 'Boxes or crates',
  [cargoValues.looseAssortedCargo]: 'Assorted cargo',
  [cargoValues.loosePalletEuro]: 'Euro pallet',
  [cargoValues.loosePalletUk]: 'UK standard pallet',
  [cargoValues.loosePalletUs]: 'US standard pallet',
  [cargoValues.loosePalletOther]: 'Other pallet',
  [cargoValues.trailer136MBox]: '13.6m - box',
  [cargoValues.trailer136MCurtainSided]: '13.6m - curtain sided',
  [cargoValues.trailer136MMegatrailer]: '13.6m - megatrailer',
  [cargoValues.trailer136MSlidingRoof]: '13.6m - sliding Roof',
  [cargoValues.trailer18TBox]: '18t - box',
  [cargoValues.trailer18TCurtainSided]: '18t - curtain sided',
  [cargoValues.trailer18TSlidingRoof]: '18t - sliding roof',
  [cargoValues.trailer75TBox]: '7.5t - box',
  [cargoValues.trailer75TCurtainSided]: '7.5t - curtain sided',
  [cargoValues.trailer75TSlidingRoof]: '7.5t - sliding roof',
  [cargoValues.trailerVanBox]: 'Van - box',
  [cargoValues.trailerVanCurtainSided]: 'Van - curtain sided',
  [cargoValues.trailerVanSlidingRoof]: 'Van - sliding roof'
};

export const bookingModeOfTransportLabelMapping: Record<ModeOfTransport, string> = {
  [ModeOfTransport.AIR]: 'Air',
  [ModeOfTransport.OCEAN]: 'Ocean',
  [ModeOfTransport.RAIL]: 'Rail',
  [ModeOfTransport.TRUCK]: 'Road'
};
