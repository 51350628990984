import { RoadTrackedShipmentStatus } from '@zen/TrackedShipments/types';

export type FilterPresentationData = {
  background: string;
  border: string;
  hover: string;
  label: string;
};

export const presentationDataForStatus = (status: RoadTrackedShipmentStatus): FilterPresentationData => {
  switch (status) {
    case RoadTrackedShipmentStatus.EARLY:
      return {
        background: 'bg-orange-light',
        border: 'border-orange-base',
        hover: 'hover:border-orange-base',
        label: 'Early shipments'
      };
    case RoadTrackedShipmentStatus.ON_TIME:
      return {
        background: 'bg-azure-lighter',
        border: 'border-azure-base',
        hover: 'hover:border-azure-base',
        label: 'On time shipments'
      };
    case RoadTrackedShipmentStatus.LATE:
      return {
        background: 'bg-red-light',
        border: 'border-red-base',
        hover: 'hover:border-red-base',
        label: 'Delayed shipments'
      };
    case RoadTrackedShipmentStatus.UNKNOWN:
      return {
        background: 'bg-grey-lighter',
        border: 'border-grey-base',
        hover: 'hover:border-grey-base',
        label: 'Unknown location shipments'
      };
  }
};
