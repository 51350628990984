import type { FC } from 'react';
import { useState } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import Search from '@zen/Components/Search';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Pagination, Table } from '@zen/DesignSystem';
import { SortingOrder, SortInput } from '@zen/types';

import NewConsolidationButton from '../NewConsolidationButton';
import type { ConsolidatedShipment } from '../types';
import { getColumnsConfiguration } from './consolidationListConfiguration';
import useConsolidations from './hooks/useConsolidations';

const ConsolidationList: FC = () => {
  const initialOrder: SortInput = {
    field: 'cutOffDate',
    direction: SortingOrder.DESC
  };
  const { check } = useGlobalPermissions();
  const canManageConsolidation: boolean = check('consolidation.canManageConsolidatedShipment');

  const [searchText, setSearchText] = useState<string>('');
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const { nodes, totalCount, loading: isLoading, error, paginationInfo } = useConsolidations(order, searchText);

  const columns = getColumnsConfiguration(canManageConsolidation);

  return (
    <Page
      actionButtons={<NewConsolidationButton />}
      tagline="A list of all consolidated shipments"
      title="Consolidated shipments"
    >
      <div className="mb-6">
        <Search onClear={() => setSearchText('')} onSubmit={(query: string) => setSearchText(query)} />
      </div>

      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={isLoading}
        loadingComponent={<SkeletonTableLoading columns={columns} tableId="consolidations" />}
      >
        {(consolidations: ConsolidatedShipment[]) => (
          <>
            <Table<ConsolidatedShipment>
              columns={columns}
              data={consolidations}
              onOrderChange={setOrder}
              order={order}
              tableId="consolidations"
              totalCountConfig={{
                totalCount,
                entityName: 'consolidation'
              }}
            />
            <Pagination pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
    </Page>
  );
};

export default ConsolidationList;
