import { FC, useState } from 'react';

import { Loading } from '@zen/DesignSystem';
import type { PackingListOrder } from '@zen/PackingLists/PackingListDetails/types';

import PackingListLineDetails from '../PackingListLineDetails';
import PackingListLineOverview from '../PackingListLineOverview';

interface Props {
  onExpand: (orderId: string) => void;
  order: PackingListOrder;
}

const PackingListLine: FC<Props> = ({ onExpand, order }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleToggle = (): void => {
    if (!order.lots) {
      onExpand(order.id);
    }

    setIsExpanded(!isExpanded);
  };

  return (
    <div className="border-b border-solid border-grey-lightest" data-testid="packing-list-line">
      <PackingListLineOverview expanded={isExpanded} onClick={handleToggle} order={order} />
      {isExpanded && (
        <div data-testid="packing-list-line-details">
          {order.lots && <PackingListLineDetails orderLots={order.lots} />}
          {!order.lots && (
            <div className="p-6">
              <Loading />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PackingListLine;
