import type { FC } from 'react';

import { Modal } from '@zen/DesignSystem';
import { useMilestoneDateValidation } from '@zen/Shipment/ShipmentDetailsPage/MilestoneDateProvider';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateMilestoneMutation } from '../../graphql';
import { VoyageMilestoneTime } from '../../types';
import MilestoneDateForm from '../MilestoneDateForm';
import type { MilestoneDateFormType } from '../MilestoneDateForm/types';
import { getIsoDate, getModalTitle } from './helper';
import type { MilestoneModalType } from './types';

interface Props {
  milestone: MilestoneModalType;
  onClose: () => void;
  zencargoReference: string;
}

const UpdateMilestoneModal: FC<Props> = ({ zencargoReference, onClose, milestone }) => {
  const { milestoneName, milestoneLabel, date, timeType, isInitialDateEntry } = milestone;
  const [updateMilestone] = useUpdateMilestoneMutation();
  const isActualDateUpdate: boolean = timeType === VoyageMilestoneTime.ACTUAL;
  const { minDate, maxDate, isValid } = useMilestoneDateValidation(milestoneName, isActualDateUpdate);
  const { addError, addSuccess } = useNotification();
  const title: string = getModalTitle(date, milestoneLabel, timeType);
  const initialValues: MilestoneDateFormType = {
    date: date || '',
    reason: undefined,
    description: undefined
  };

  const handleSubmit = (values: MilestoneDateFormType): Promise<IOkOrErrorResult> => {
    const { description } = values;
    const { reason } = values;
    const time: string = getIsoDate(values.date);

    return performMutation({
      mutationFn: () =>
        updateMilestone({
          variables: { input: { milestoneName, zencargoReference, timeType, time, reason, description } },
          refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
          awaitRefetchQueries: true
        }),
      onError: addError
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Milestone has been updated successfully');
    onClose();
  };

  return (
    <Modal isOpen={!!milestone} modalOverflowY="visible" onClose={onClose} title={title}>
      <MilestoneDateForm
        initialValues={initialValues}
        isDatePickerDisabled={!isValid}
        isInitialDateEntry={isInitialDateEntry}
        maxDate={maxDate}
        minDate={minDate}
        onCancel={onClose}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
      />
    </Modal>
  );
};

export default UpdateMilestoneModal;
