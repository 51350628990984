import moment from 'moment';
import type { FC, ReactNode } from 'react';

import type { Day } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type { DatePickerSize, Direction } from '../types';
import { DATE_FORMAT } from '../utils';
import { DatePickerContext } from './DatePickerContext';

interface Props {
  currentDate: string;
  disabledDayTooltip: ReactNode;
  enabledDays: Optional<Day[]>;
  handleMonthChange: (direction: Direction) => void;
  highlightInitialDate: boolean;
  initialDate: Optional<string>;
  maxDate: Optional<string>;
  minDate: Optional<string>;
  onChange: (date: string) => void;
  selectedDate: Optional<string>;
  size: DatePickerSize;
  suggestedDate: Optional<string>;
}

const DatePickerProvider: FC<Props> = (props) => {
  const {
    disabledDayTooltip,
    enabledDays,
    initialDate,
    onChange,
    minDate,
    maxDate,
    selectedDate,
    highlightInitialDate,
    currentDate,
    handleMonthChange,
    children,
    suggestedDate,
    size
  } = props;

  const isDisabled = (date: string) => {
    const momentDay = moment(date, DATE_FORMAT);

    const isBeforeMinDays = minDate ? momentDay.isBefore(minDate) : false;
    const isAfterMaxDays = maxDate ? momentDay.isAfter(maxDate) : false;
    const isInEnabledDays = enabledDays ? !enabledDays.includes(momentDay.weekday()) : true;

    return isBeforeMinDays || isAfterMaxDays || !isInEnabledDays;
  };

  const isInDisplayedMonth = (date: string) => moment(date, DATE_FORMAT).isSame(moment(currentDate, DATE_FORMAT), 'month');

  const onMonthChange = (date: string) => {
    const isDateAfter: boolean = moment(date, DATE_FORMAT).isAfter(moment(currentDate, DATE_FORMAT), 'month');
    const direction: Direction = isDateAfter ? 'next' : 'previous';

    return handleMonthChange(direction);
  };

  const isSameDay = (date: string, otherDate: string) => moment(date, DATE_FORMAT).isSame(otherDate, 'day');

  const isSelected = (date: string) => !!selectedDate && isSameDay(date, selectedDate);

  const isSuggestedHighlighted = (date: string) => (suggestedDate ? isSameDay(date, suggestedDate) : false);

  const isInitialHighlighted = (date: string) => {
    if (highlightInitialDate && initialDate) {
      return isSameDay(date, initialDate);
    }

    return false;
  };

  return (
    <DatePickerContext.Provider
      value={{
        disabledDayTooltip,
        isInDisplayedMonth,
        onMonthChange,
        isSelected,
        isDisabled,
        isInitialHighlighted,
        onChange,
        isSuggestedHighlighted,
        size
      }}
    >
      {children}
    </DatePickerContext.Provider>
  );
};

export default DatePickerProvider;
