import type { FC } from 'react';

import { FormCheckbox, FormInput } from '@zen/Components/Form';

interface Props {
  isBulkUpdateEnabled: boolean;
}

const ScheduleRoadFields: FC<Props> = ({ isBulkUpdateEnabled }) => {
  return (
    <div className="mt-6" data-testid="schedule-road-fields">
      <FormInput className="w-72" name="vehiclePlateNumber" placeholder="e.g. JD12345" />
      <FormCheckbox
        className="-mt-2 mb-5"
        disabled={!isBulkUpdateEnabled}
        label="Vehicle plate is the same for collection and delivery"
        name="vehiclePlateNumberEqual"
      />

      <FormInput className="w-72" name="driverDetails" placeholder="e.g. Josh Smith" />
      <FormCheckbox
        className="-mt-2 mb-5"
        disabled={!isBulkUpdateEnabled}
        label="Driver details are the same for collection and delivery"
        name="driverDetailsEqual"
      />
    </div>
  );
};

export default ScheduleRoadFields;
