import cx from 'classnames';
import type { FC } from 'react';

import type { CargoType } from '@zen/Cargo';
import { Headline } from '@zen/DesignSystem';
import { cargoTypeLabelMapping, ContainerTypeEnum } from '@zen/types';

import CargoTypeImage from '../CargoTypeImage';

export interface Props {
  cargoType: CargoType;
}

const CargoTypeCover: FC<Props> = ({ cargoType }) => {
  // we need to add a margin left to some container images
  // in order to center them (they contain a shadow on the right side)
  const classNames: string = cx(
    {
      'ml-5': cargoType === ContainerTypeEnum.X20DV || cargoType === ContainerTypeEnum.X20HC,
      'ml-6': cargoType === ContainerTypeEnum.X45HC,
      'ml-7': cargoType === ContainerTypeEnum.X40DV || cargoType === ContainerTypeEnum.X40HC
    },
    'h-18 my-5'
  );

  return (
    <div className="flex flex-col items-center justify-center w-full text-center">
      <CargoTypeImage cargoType={cargoType} className={classNames} />
      <Headline className="text-navy-base" level={3}>
        {cargoTypeLabelMapping[cargoType]}
      </Headline>
    </div>
  );
};

export default CargoTypeCover;
