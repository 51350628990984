import type { RoutesConfig } from './types';

export const TASK_LIST_ROUTE_PREFIX: string = '/dashboard/task-list';

interface TaskListRoutes extends RoutesConfig {
  taskListComplete: {
    getUrl: (taskId: string) => string;
    path: string;
  };
  taskListIndex: {
    getUrl: (tab?: string) => string;
    path: string;
  };
}

const taskListRoutes: TaskListRoutes = {
  taskListIndex: {
    path: TASK_LIST_ROUTE_PREFIX,
    getUrl: (tab: string = 'active'): string => {
      return `${TASK_LIST_ROUTE_PREFIX}/${tab}`;
    }
  },
  taskListComplete: {
    getUrl: (taskId: string) => `${TASK_LIST_ROUTE_PREFIX}/${taskId}/complete`,
    path: `${TASK_LIST_ROUTE_PREFIX}/:taskId/complete`
  }
};

export default taskListRoutes;
