import { ChangeEvent, FC, forwardRef, KeyboardEvent, ReactElement, ReactNode, Ref } from 'react';

import Icon from '../Icon';
import Input, { InputProps } from '../Input';

interface Props extends Omit<InputProps, 'type' | 'iconLeft' | 'iconRight' | 'onChange'> {
  onChange: (value: string) => void;
  onClear?: () => void;
  onSearch?: () => void;
  ref?: Ref<HTMLInputElement>;
  value: string;
}

const SearchInput: FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { onChange, onClear, onSearch, value, ...rest } = props;
  const hasText: boolean = !!value && value.length > 0;

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onSearch) {
      onSearch();
    }
  };

  const clearField = () => onChange('');

  const renderSearchIcon = (): ReactElement => <Icon icon="zicon-search" />;

  const renderClearIcon = (): ReactNode => {
    if (!hasText || !onClear) {
      return null;
    }

    return (
      <Icon
        className="p-3 cursor-pointer font-bold"
        icon="zicon-close"
        onClick={() => {
          clearField();
          onClear();
        }}
      />
    );
  };

  return (
    <Input
      {...rest}
      ref={ref}
      data-component="search-input"
      data-testid="search-input"
      iconLeft={renderSearchIcon()}
      iconRight={renderClearIcon()}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      onKeyPress={handleKeyPress}
      value={value}
    />
  );
});

export type { Props as SearchInputProps };

export default SearchInput;
