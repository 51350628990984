import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import PageTitle from '@zen/Components/PageTitle';
import { HEADER_HEIGHT } from '@zen/Layout';
import useIsVisible from '@zen/utils/hooks/useIsVisible';
import type { Undefinable } from '@zen/utils/typescript';

import BackLink from '../BackLink';

export const STICKY_HEADER_HEIGHT = 80;

interface Props {
  actionButtons?: ReactNode;
  browserTitle?: string;
  contentClassName?: string;
  defaultBackUrl?: string;
  filterSwitcher?: ReactNode;
  headerClassName?: string;
  qualityIndicator?: ReactNode;
  stickyHeader?: boolean;
  tabsComponent?: ReactNode;
  tagline?: ReactNode;
  title: ReactNode;
  width?: 'full' | 'wide' | 'medium' | 'narrow';
}

const Page: FC<Props> = (props) => {
  const [staticHeaderRef, isStaticHeaderVisible] = useIsVisible<HTMLDivElement>({
    rootMargin: `-${HEADER_HEIGHT}px 0px 0px 0px`
  });
  const {
    actionButtons,
    defaultBackUrl,
    browserTitle,
    children,
    contentClassName = 'pt-4 pb-10 px-10',
    headerClassName,
    filterSwitcher,
    qualityIndicator,
    stickyHeader,
    tabsComponent,
    tagline,
    title,
    width = 'full'
  } = props;

  const widthClassName = cx({
    'max-w-screen-xl': width === 'wide',
    'max-w-screen-lg': width === 'medium',
    'max-w-screen-md': width === 'narrow'
  });
  const headerClassNames = cx(
    {
      'pb-8': !tabsComponent
    },
    'relative px-10 pt-16',
    headerClassName,
    widthClassName
  );

  const helmetTitle: Undefinable<string> = typeof title === 'string' ? browserTitle || title : browserTitle;

  return (
    <div className="flex flex-col h-full" data-testid="page">
      <Helmet title={helmetTitle} />
      <div ref={staticHeaderRef} className="bg-grey-lightest border-b border-solid border-grey-lighter" data-testid="page-header">
        <div className={headerClassNames}>
          {defaultBackUrl && (
            <span className="absolute top-5 left-7">
              <BackLink path={defaultBackUrl} />
            </span>
          )}
          <div className="flex justify-between">
            <PageTitle tagline={tagline} title={title} />
            {actionButtons && <div className="-mt-2 flex items-start">{actionButtons}</div>}
          </div>
          {(filterSwitcher || qualityIndicator) && (
            <div className="absolute flex items-start top-5 right-10">
              {filterSwitcher && filterSwitcher}
              {qualityIndicator && <div className="ml-6">{qualityIndicator}</div>}
            </div>
          )}
          {tabsComponent && <div className="mt-4">{tabsComponent}</div>}
        </div>
      </div>
      {stickyHeader && (
        <div className="sticky top-16 z-20" data-testid="page-sticky-header">
          {!isStaticHeaderVisible && (
            <PageTitle
              className="flex flex-col justify-center px-10 h-20 bg-white shadow-interaction"
              tagline={tagline}
              title={title}
            />
          )}
        </div>
      )}
      <div className={cx('flex flex-col h-full', contentClassName, widthClassName)} data-testid="page-content">
        {children}
      </div>
    </div>
  );
};

export default Page;
