import cx from 'classnames';
import type { FC } from 'react';

import { formatCurrency, formatNumber } from '@zen/utils/formatting';

import IndicatorIcon from '../Icons/IndicatorIcon';
import { getIndicatorType } from '../Icons/IndicatorIcon/IndicatorIcon';
import { IndicatorType } from '../Icons/IndicatorIcon/types';

const formatValue = (value: number, currency?: string): string => {
  if (currency) {
    return formatCurrency(currency, value, 'symbol', true);
  }

  return formatNumber(value);
};

interface Props {
  className?: string;
  comparedValue: number;
  currency?: string;
  value: number;
}

const ValueIndicator: FC<Props> = ({ className = '', currency, value, comparedValue }) => {
  const type: IndicatorType = getIndicatorType(value, comparedValue);
  const isIncreasing: boolean = type === IndicatorType.INCREASING;
  const isEqual: boolean = type === IndicatorType.EQUAL;
  const difference: number = value - comparedValue;
  const classNames: string = cx('text-xs mt-1', { 'text-azure-base': isIncreasing, 'text-red-base': !isIncreasing });

  return (
    <div className={`${className} flex items-center`}>
      <IndicatorIcon type={type} />
      <div className="ml-2">
        <div className="font-bold">{formatValue(value, currency)}</div>
        {!isEqual && (
          <div className={classNames}>
            {isIncreasing && '+ '}
            {formatValue(difference, currency)}
          </div>
        )}
      </div>
    </div>
  );
};

export default ValueIndicator;
