import type { RoutesConfig } from './types';

export const DASHBOARD_ROUTE_PREFIX = '/dashboard';

interface DashboardRoutes extends RoutesConfig {
  dashboard: {
    getUrl: () => string;
    path: string;
  };
  dashboardSlideout: {
    getUrl: (zencargoReference: string) => string;
    path: string;
  };
}

const dashboardRoutes: DashboardRoutes = {
  dashboard: {
    path: DASHBOARD_ROUTE_PREFIX,
    getUrl: () => DASHBOARD_ROUTE_PREFIX
  },
  dashboardSlideout: {
    path: `${DASHBOARD_ROUTE_PREFIX}/:zencargoReference?`,
    getUrl: (zencargoReference: string) => `${DASHBOARD_ROUTE_PREFIX}/${zencargoReference}`
  }
};

export default dashboardRoutes;
