import { truncate, upperFirst } from 'lodash';
import React, { FC, ReactElement, ReactNode, useState } from 'react';

import { Icon, Tooltip } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { ActivityFeedUser } from '../../types';
import ActivityFeedVisibleParties from '../ActivityFeedVisibleParties';
import Timestamp from '../Timestamp';
import UserDetails from '../UserDetails';

export interface Props {
  additionalText?: Optional<string>;
  createdAt: string;
  icon: ReactNode;
  resolvedAt?: string;
  subtitle?: ReactNode;
  title: ReactNode;
  user?: Nullable<ActivityFeedUser>;
}

const ActivityFeedItemDetails: FC<Props> = (props) => {
  const { createdAt, icon, resolvedAt = undefined, subtitle = undefined, title, user = undefined, additionalText = null } = props;

  const [showAdditonalText, setShowAdditionalText] = useState<boolean>(false);
  const additionalTextTurncateLength: number = 80;
  const showViewMore: boolean = additionalText ? additionalText.length > additionalTextTurncateLength : false;

  const tooltipContent: ReactNode = (
    <div data-testid="tooltip-content">
      <div className="pl-4 pt-4">
        <Timestamp date={createdAt} resolvedAt={resolvedAt} />
      </div>
      <ActivityFeedVisibleParties />
    </div>
  );

  // Replace icon from ReactNode type to icon configuration i.e icon: { name, color }
  // When LayeredIcon is replaced with single icon
  let clonedIcon: Optional<ReactElement>;

  if (React.isValidElement(icon)) {
    clonedIcon = React.cloneElement(icon as ReactElement, {
      ...icon.props,
      className: `${icon.props.className} bg-white`
    });
  }

  const getTitle = (): ReactNode => {
    const isTitleReactNode: boolean = React.isValidElement(title);

    return isTitleReactNode ? title : <span className="font-bold">{title && upperFirst(title.toString())}</span>;
  };

  return (
    <div className="flex" data-testid="activity-feed-details">
      <div className="mr-4 text-2xl z-50" style={{ marginLeft: '-0.82rem' }}>
        {clonedIcon || icon}
      </div>
      <div>
        <div className="flex items-baseline leading-normal">
          {getTitle()}
          {user && <UserDetails showByWhom={true} user={user} />}
          <Tooltip tooltipContent={tooltipContent}>
            <div className="ml-2 relative cursor-pointer">
              <Icon className="text-navy-base" icon="zicon-information" />
            </div>
          </Tooltip>
        </div>

        {subtitle && (
          <div className="text-sm text-grey-base pt-2" data-testid="details-subtitle">
            {subtitle}
          </div>
        )}

        {additionalText && (
          <div className="text-sm leading-normal text-grey-base pt-2">
            {showAdditonalText && additionalText}
            {!showAdditonalText && truncate(additionalText, { length: additionalTextTurncateLength })}
            {showViewMore && (
              <div className="text-navy-base underline cursor-pointer" onClick={() => setShowAdditionalText(!showAdditonalText)}>
                {!showAdditonalText && '+ View more'}
                {showAdditonalText && '- View less'}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityFeedItemDetails;
