import { FC, ReactElement, useState } from 'react';
import { useLocation } from 'react-router';

import type { Booking } from '@zen/Booking/types';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Pagination, Table, TableColumn, useTableConfiguration } from '@zen/DesignSystem';
import type { FlatShipment } from '@zen/Shipments';
import { prepareFlatShipments } from '@zen/Shipments/helpers';
import { SortingOrder, SortInput } from '@zen/types';
import useAccount from '@zen/utils/hooks/useAccount';

import { CUSTOMER_ADMIN_SHIPMENTS_TABLE_ID } from './AllShipmentsContainer';
import { useAllShipments } from './hooks/useAllShipments';
import { getColumns } from './tableConfiguration';
import Toolbar from './Toolbar';

const AllShipments: FC = () => {
  const [order, setOrder] = useState<SortInput>({ field: 'createdAt', direction: SortingOrder.DESC });

  const { userProfile } = useAccount();
  const { pathname } = useLocation();
  const { hiddenColumns } = useTableConfiguration();
  const { loading, error, nodes, paginationInfo, totalCount } = useAllShipments({ order });

  const columns: TableColumn<FlatShipment>[] = getColumns(pathname);

  return (
    <Page tagline={userProfile.registeredCompanyName} title="All shipments">
      <Toolbar<FlatShipment> columns={columns} />
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={
          <SkeletonTableLoading
            columns={columns}
            hiddenColumns={hiddenColumns}
            order={order}
            tableId={CUSTOMER_ADMIN_SHIPMENTS_TABLE_ID}
          />
        }
      >
        {(data: Booking[]): ReactElement => (
          <>
            <Table<FlatShipment>
              columns={columns}
              data={prepareFlatShipments(data)}
              hiddenColumns={hiddenColumns}
              onOrderChange={setOrder}
              order={order}
              tableId={CUSTOMER_ADMIN_SHIPMENTS_TABLE_ID}
              totalCountConfig={{
                totalCount,
                entityName: 'shipment'
              }}
            />
            <Pagination pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
    </Page>
  );
};

export default AllShipments;
