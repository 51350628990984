import { FC, ReactNode, useEffect, useState } from 'react';

import MapComponent from '@zen/Components/Map';
import type { MarkerType } from '@zen/Components/Map/Marker';
import type { RoadTrackedShipment } from '@zen/TrackedShipments/graphql';
import type { RoadTrackedShipmentStatus } from '@zen/TrackedShipments/types';
import type { Nullable } from '@zen/utils/typescript';

import ShipmentInfo from '../ShipmentInfo';
import { getMarkers } from './helpers';

interface Props {
  activeStatus: RoadTrackedShipmentStatus;
  shipments: RoadTrackedShipment[];
}

const Map: FC<Props> = ({ activeStatus, shipments }) => {
  const [selectedShipmentIndex, setSelectedShipmentIndex] = useState<Nullable<number>>(null);
  const markers: MarkerType[] = getMarkers(shipments, activeStatus, selectedShipmentIndex);

  const handleMarkerClick = (index: number): void => {
    setSelectedShipmentIndex(index);
  };

  const handlePopupClose = (): void => {
    setSelectedShipmentIndex(null);
  };

  const renderShipmentInfo = (): ReactNode => {
    if (selectedShipmentIndex === null || !shipments[selectedShipmentIndex]) {
      return null;
    }

    const {
      estimatedArrivalWindowEndDateTime,
      estimatedArrivalWindowStartDateTime,
      destinationCountryCode,
      originCountryCode,
      zencargoReference
    } = shipments[selectedShipmentIndex];

    return (
      <ShipmentInfo
        arrivalEstimateEndDateTime={estimatedArrivalWindowEndDateTime}
        arrivalEstimateStartDateTime={estimatedArrivalWindowStartDateTime}
        destinationCountryCode={destinationCountryCode}
        onClose={handlePopupClose}
        originCountryCode={originCountryCode}
        status={activeStatus}
        zencargoReference={zencargoReference}
      />
    );
  };

  useEffect(() => {
    setSelectedShipmentIndex(null);
  }, [shipments, activeStatus]);

  return (
    <div className="relative h-full">
      <MapComponent markers={markers} onMarkerClick={handleMarkerClick} />
      {renderShipmentInfo()}
    </div>
  );
};

export default Map;
