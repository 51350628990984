import { kebabCase } from 'lodash';
import { FC, ReactNode, useState } from 'react';

import EditableSection from '@zen/Components/EditableSection';
import { useForm } from '@zen/Components/Form/useForm';
import type { ButtonVariant } from '@zen/DesignSystem';
import HintWrapper from '@zen/OperationsShipments/components/HintWrapper';
import type { RoadShipmentFields, ScheduleAction } from '@zen/OperationsShipments/EditRoadShipment/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import DateWithTimeRangePresenter from '../DateWithTimeRangePresenter';
import type { ForwarderSchedulerFormValues } from './ForwarderSchedulerForm';
import ForwarderSchedulerForm, { ScheduleData } from './ForwarderSchedulerForm';
import { createValidationSchema } from './ForwarderSchedulerForm/validation';
import { getLabels, isCollectionRequired, isDeliveryRequired } from './helpers';

interface Props {
  scheduleAction: ScheduleAction;
}

const ForwarderScheduler: FC<Props> = ({ scheduleAction }) => {
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  const { setFieldValue, values: formValues } = useForm<RoadShipmentFields>();

  const { cargoMode, collectionLocation, deliveryLocation } = formValues;
  const { collectionLabel, deliveryLabel, hintText, sectionTitle } = getLabels(scheduleAction);

  const initialValues: ForwarderSchedulerFormValues = {
    collection: formValues.collection[scheduleAction],
    delivery: formValues.delivery[scheduleAction]
  };

  const hasData: boolean = !!(initialValues.collection.date || initialValues.delivery.date);

  const buttonText: string = hasData ? 'Edit' : 'Add';
  const buttonVariant: ButtonVariant = hasData ? 'secondary' : 'primary';

  const handleSubmit = (values: ForwarderSchedulerFormValues): Promise<IOkOrErrorResult> => {
    setFieldValue('collection', {
      ...formValues.collection,
      [scheduleAction]: values.collection
    });

    setFieldValue('delivery', {
      ...formValues.delivery,
      [scheduleAction]: values.delivery
    });

    return Promise.resolve({ ok: {}, error: null });
  };

  const handleCancel = (): void => setIsUpdateMode(false);

  const handleSuccess = (): void => setIsUpdateMode(false);

  const renderForm = (): ReactNode => {
    const collection: ScheduleData = {
      businessHours: collectionLocation?.businessHours,
      isRequired: isCollectionRequired(scheduleAction),
      label: collectionLabel,
      timeZone: collectionLocation?.timeZone
    };

    const delivery: ScheduleData = {
      businessHours: deliveryLocation?.businessHours,
      isRequired: isDeliveryRequired(scheduleAction, cargoMode),
      label: deliveryLabel,
      timeZone: deliveryLocation?.timeZone
    };

    return (
      <ForwarderSchedulerForm
        collection={collection}
        delivery={delivery}
        initialValues={initialValues}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        validationSchema={createValidationSchema(cargoMode, scheduleAction)}
      />
    );
  };

  const renderValues = (): ReactNode => {
    return (
      <div className="grid gap-1 grid-cols-2 w-full">
        <DateWithTimeRangePresenter dateLabel="Collection date" dateWithTimeRange={initialValues.collection} timeLabel="Time" />
        <DateWithTimeRangePresenter dateLabel="Delivery date" dateWithTimeRange={initialValues.delivery} timeLabel="Time" />
      </div>
    );
  };

  return (
    <data data-testid={kebabCase(scheduleAction)}>
      <HintWrapper hintText={hintText}>
        <EditableSection
          buttonText={buttonText}
          buttonVariant={buttonVariant}
          isUpdateMode={isUpdateMode}
          label={sectionTitle}
          onUpdate={() => setIsUpdateMode(true)}
        >
          {isUpdateMode ? renderForm() : renderValues()}
        </EditableSection>
      </HintWrapper>
    </data>
  );
};

export default ForwarderScheduler;
