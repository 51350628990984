import { FC, MutableRefObject, ReactNode, useRef, useState } from 'react';

import type { CargoModeEnum } from '@zen/Cargo';
import CollapsableElement from '@zen/Components/CollapsableElement';
import DatesDifference from '@zen/Components/DatesDifference';
import DateWithDefault from '@zen/Components/DateWithDefault';
import { getStageLabel } from '@zen/Components/StageName/helpers';
import type { StageNameType } from '@zen/Components/StageName/types';
import type { MenuItemType } from '@zen/DesignSystem';
import { ContextMenu, Tooltip } from '@zen/DesignSystem';
import { VoyageMilestoneNameEnum, VoyageMilestoneTime } from '@zen/graphql/types.generated';
import DetailsCardContent from '@zen/Shipment/components/DetailsCardContent';
import ExpandableDetailsCard from '@zen/Shipment/components/ExpandableDetailsCard';
import type { ModeOfTransport } from '@zen/types';
import { formatTimeZone, noTimeZoneMessage } from '@zen/utils/dateTime';
import { useHover } from '@zen/utils/hooks/useHover';
import type { Nullable, Optional, Undefinable } from '@zen/utils/typescript';

import StageUpdateWrapper from '../../../components/StageUpdateWrapper';
import type { MilestoneDelay, MilestoneUpdateType, VoyageMilestoneType } from '../../types';
import InTransitMilestone from '../InTransitMilestone';
import type { TrackingInformation } from '../InTransitMilestone/types';
import type { MilestoneModalType } from '../UpdateMilestoneModal/types';
import { configureMenuItems, getIsPrimaryMilestone, shouldDisplayInTransit } from './helpers';
import MilestoneDelayItem from './MilestoneDelayItem';
import VerticalPathIndicators from './VerticalPathIndicators';

interface Props {
  canEdit?: boolean;
  cargoMode: Optional<CargoModeEnum>;
  consolidatedShipmentId: Undefinable<string>;
  isFinalMilestone?: boolean;
  milestone: VoyageMilestoneType;
  onEdit: (item: MilestoneModalType, type: MilestoneUpdateType) => void;
  onMilestoneDelayUpdate?: () => void;
  trackingInformation: TrackingInformation;
  transportMode: Optional<ModeOfTransport>;
}

const MilestoneCard: FC<Props> = (props) => {
  const {
    consolidatedShipmentId,
    milestone,
    transportMode,
    trackingInformation,
    cargoMode,
    onEdit,
    canEdit,
    isFinalMilestone = false,
    onMilestoneDelayUpdate
  } = props;
  const [isActive, setIsActive] = useState<boolean>(false);

  const [ref, isCardHovered] = useHover<HTMLDivElement>();
  const { name, occurred, current, actual, latestEstimate, originalEstimate, location, portName, delays } = milestone;
  const titleRef: MutableRefObject<Nullable<HTMLDivElement>> = useRef<HTMLDivElement>(null);
  const mainMilestoneDate: Optional<string> = actual?.date ? actual.date : latestEstimate?.date;
  const isPrimaryMilestone: boolean = getIsPrimaryMilestone(name);
  const milestoneNameType: StageNameType = occurred ? 'past' : 'future';
  const visibleDateLabel: string = actual?.date || !latestEstimate?.date || !isPrimaryMilestone ? '' : 'Est.';
  const editableMilestone: boolean = name !== VoyageMilestoneNameEnum.COLLECTED && name !== VoyageMilestoneNameEnum.DELIVERED;
  const shouldRenderEdit: boolean = !!canEdit && editableMilestone;
  const productTourSelector: Undefinable<string> = delays.length ? 'multiple-delay-reason' : undefined;
  const timeZone: Optional<string> = actual?.timeZone || latestEstimate?.timeZone || originalEstimate?.timeZone;
  const tooltipContent: string = originalEstimate?.date ? formatTimeZone(originalEstimate.date, timeZone) : noTimeZoneMessage;

  const setActive = (): void => setIsActive(true);
  const setInActive = (): void => setIsActive(false);

  const getLocationLabel = (): Optional<ReactNode> => {
    const label: Optional<string> = portName || location?.label?.long;

    if (label) {
      return <div className="mr-2">{label}</div>;
    }
  };

  const getDelayItems = () => {
    return delays.map(
      (delay: MilestoneDelay): ReactNode => (
        <MilestoneDelayItem
          key={delay.id}
          canUpdateMilestoneDelay={!!canEdit}
          delay={delay}
          onUpdateModalClose={setInActive}
          onUpdateModalOpen={setActive}
          onUpdateSuccess={onMilestoneDelayUpdate}
        />
      )
    );
  };

  const milestoneLabel: Optional<string> = transportMode && getStageLabel(name, transportMode, milestoneNameType);

  const handleEdit = (updateType: MilestoneUpdateType, timeType: VoyageMilestoneTime): void => {
    const date: Optional<string> = timeType === VoyageMilestoneTime.ESTIMATED ? latestEstimate?.date : actual?.date;
    const isInitialDateEntry: boolean = !(originalEstimate?.date || latestEstimate?.date || actual?.date);

    const item: MilestoneModalType = {
      milestoneName: name,
      milestoneLabel,
      date,
      timeType,
      isInitialDateEntry
    };

    onEdit(item, updateType);
    setInActive();
  };

  const renderAction = (): Undefinable<ReactNode> => {
    if (!shouldRenderEdit) {
      return;
    }

    const isFutureMilestone: boolean = !occurred && !actual;

    const items: MenuItemType[] = configureMenuItems(
      handleEdit,
      isFutureMilestone,
      !!latestEstimate,
      !!actual,
      isPrimaryMilestone
    );

    return (
      <StageUpdateWrapper isActive={isActive} isHovered={isCardHovered}>
        <ContextMenu inline={false} items={items} onClose={setInActive} onOpen={setActive} />
      </StageUpdateWrapper>
    );
  };

  const mainCardSection: ReactNode = (
    <DetailsCardContent
      action={<div className="h-8">{renderAction()}</div>}
      date={
        <Tooltip placement="top-start" tooltipContent={tooltipContent}>
          <div className="font-bold" data-testid="main-milestone-tooltip">
            <DateWithDefault date={mainMilestoneDate} />
          </div>
        </Tooltip>
      }
      dateDifference={
        mainMilestoneDate &&
        originalEstimate?.date && <DatesDifference date={mainMilestoneDate} referenceDate={originalEstimate?.date} />
      }
      detailLabel={<div data-testid="visible-date-label">{visibleDateLabel}</div>}
      title={<div ref={titleRef}>{milestoneLabel}</div>}
    />
  );

  const locationLabel: Optional<ReactNode> = getLocationLabel();

  const renderPrimarySubCardSection = (isHovered: boolean): ReactNode => (
    <CollapsableElement duration={300} isOpened={isHovered}>
      <DetailsCardContent
        date={
          <Tooltip placement="top-start" tooltipContent={tooltipContent}>
            <div className="font-bold" data-testid="sub-milestone-tooltip">
              <DateWithDefault date={originalEstimate?.date} />
            </div>
          </Tooltip>
        }
        detailLabel="Planned"
        title={locationLabel}
      />
    </CollapsableElement>
  );

  const renderSecondarySubCardSection = (isHovered: boolean): ReactNode =>
    locationLabel && (
      <CollapsableElement duration={300} isOpened={isHovered}>
        <DetailsCardContent className="mr-4" title={locationLabel} />
      </CollapsableElement>
    );

  const shouldRenderInTransit: boolean = transportMode ? shouldDisplayInTransit(transportMode, milestone.name) : false;

  return (
    <div ref={ref} className={productTourSelector}>
      <div data-testid={name}>
        <ExpandableDetailsCard
          animationDelay={500}
          details={getDelayItems()}
          indicator={
            <VerticalPathIndicators
              isCompleted={occurred}
              isCurrent={shouldRenderInTransit ? false : current}
              isLast={isFinalMilestone}
              referenceElementRef={titleRef}
            />
          }
          mainCardSection={mainCardSection}
          opened={isActive}
          renderSubCardSection={isPrimaryMilestone ? renderPrimarySubCardSection : renderSecondarySubCardSection}
        />
      </div>
      {transportMode && shouldDisplayInTransit(transportMode, milestone.name) && (
        <InTransitMilestone
          cargoMode={cargoMode}
          consolidatedShipmentId={consolidatedShipmentId}
          isCompleted={occurred}
          isCurrent={current}
          name={name}
          trackingInformation={trackingInformation}
          transportMode={transportMode}
          vesselName={milestone.vesselName}
        />
      )}
    </div>
  );
};

export default MilestoneCard;
