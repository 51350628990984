import DataExportButton from '@zen/Components/DataExportButton';
import type { TableColumn } from '@zen/DesignSystem';
import { useExportRowsShipmentsMutation } from '@zen/Shipments/graphql';
import type { ShipmentDataFiltersInput } from '@zen/Shipments/types';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { getColumnKeys } from '../../helpers/dataExport';

interface Props<T> {
  columns: TableColumn<T>[];
  dataFilters: ShipmentDataFiltersInput;
  hiddenColumns?: string[];
}

const ShipmentsDataExportButton = <T extends {}>({ columns, dataFilters, hiddenColumns = [] }: Props<T>) => {
  const { accountUuid } = useAccount();
  const [exportRows] = useExportRowsShipmentsMutation();
  const { addError } = useNotification();

  const accountId: Nullable<string> = accountUuid || null;
  const handleExport = async (): Promise<IOkOrErrorResult> => {
    const input = {
      accountUuid: accountId,
      exportData: {
        columns: getColumnKeys(columns, hiddenColumns),
        dataFilters
      }
    };

    return performMutation({
      mutationFn: () =>
        exportRows({
          variables: {
            input
          }
        }),
      onError: addError
    });
  };

  return <DataExportButton className="fs-shipments-export-csv ml-3" confirmLabel="Return" onClickPromise={handleExport} />;
};

export default ShipmentsDataExportButton;
