import type { FC } from 'react';

import { getColor } from '@zen/Components/DataQualityIndicator/helper';
import { DonutChart } from '@zen/DesignSystem';

import BarChart from './BarChart';

interface Props {
  averageEarliness: number;
  averageLateness: number;
  earlyPercentage: number;
  latePercentage: number;
  onTimePercentage: number;
}

const OnTimeChart: FC<Props> = ({ onTimePercentage, ...rest }) => (
  <>
    <div className="flex justify-center">
      <DonutChart color={getColor(onTimePercentage)} value={Math.round(onTimePercentage)} />
    </div>
    <div className="flex justify-center -mt-2">
      <BarChart onTimePercentage={onTimePercentage} {...rest} />
    </div>
  </>
);

export default OnTimeChart;
