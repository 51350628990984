import cx from 'classnames';
import { uniqueId } from 'lodash';
import { ChangeEvent, FC, FocusEvent, KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react';

import { Icon } from '@zen/DesignSystem';
import { useHover } from '@zen/utils/hooks/useHover';

import Copyable from '../Copyable';

interface Props {
  additionalElement?: ReactNode;
  className?: string;
  copyable?: boolean;
  disabled?: boolean;
  name?: string;
  onUpdate: (value: string) => void;
  overLabel?: string;
  placeholder?: string;
  value?: string;
}

const InlineEditableField: FC<Props> = (props) => {
  const {
    overLabel,
    value: defaultValue = '',
    onUpdate,
    name = uniqueId(),
    placeholder = 'Enter data',
    copyable = true,
    className = 'p-2',
    additionalElement,
    disabled = false
  } = props;
  const [ref, isHovered] = useHover();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [value, setValue] = useState<string>(defaultValue);
  const [currentValue, setCurrentValue] = useState<string>(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  const handleFocusChange = (event: FocusEvent<HTMLInputElement>): void => {
    setCurrentValue(event.target.value);
    setIsEditMode(true);
  };

  const handleUpdateValue = (): void => {
    if (value !== currentValue) {
      onUpdate(value);
    }

    setIsEditMode(false);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && inputRef.current) {
      setCurrentValue(inputRef.current.value);
      handleUpdateValue();
    }
  };
  const defaultSize: number = placeholder.replace(/\s+/g, '').length;

  const inputSizeValue: number = value?.length ? value.replace(/\s+/g, '').length * 1.2 : defaultSize;

  const showCopyIcon: boolean = copyable && isHovered && !isEditMode;

  const inputClassNames: string = cx(
    {
      'border rounded border-grey-light': isHovered,
      'bg-grey-lightest text-grey-base placeholder-grey-base': disabled
    },
    'mr-1 text-ellipsis min-w-0',
    'border rounded border-transparent',
    'focus:border-azure-base focus:outline-none',
    'bg-transparent',
    className
  );

  const showEditIcon: boolean = !isHovered && !isEditMode && !disabled;

  return (
    <div ref={ref} className="relative flex items-center group" data-testid="inline-editable-field">
      {overLabel && (
        <label className="absolute z-10 left-2 -top-2 px-0.5 text-grey-base bg-white cursor-pointer w-max rounded" htmlFor={name}>
          {overLabel}
        </label>
      )}
      <input
        ref={inputRef}
        className={inputClassNames}
        data-lpignore="true"
        disabled={disabled}
        id={name}
        name={name}
        onBlur={handleUpdateValue}
        onChange={handleChange}
        onFocus={handleFocusChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        size={inputSizeValue}
        type="text"
        value={value}
      />
      {showEditIcon && <Icon className="text-grey-base ml-1" icon="zicon-edit" />}
      {showCopyIcon && <Copyable text={null} textToCopy={value} />}
      {!isEditMode && additionalElement}
    </div>
  );
};

export type { Props as InlineEditableFieldProps };

export default InlineEditableField;
