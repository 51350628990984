import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import NoResults from '@zen/Components/NoResults';
import PageContent from '@zen/Components/PageContent/PageContent';
import QueryHandler from '@zen/Components/QueryHandler';
import ShadowBox from '@zen/Components/ShadowBox';
import { useIntakePlanningSkuAnalyticsQuery } from '@zen/IntakePlanning/graphql';
import { mergeQueryParams, parseQueryParams } from '@zen/utils/QueryParams/queryParams';

import Filters from './Filters';
import { getDateRange } from './Filters/helpers';
import { AnalyticsViewPeriod } from './Filters/types';
import InFullChart from './InFullChart';
import LeadTime from './LeadTime';
import OnTimeChart from './OnTimeChart/OnTimeChart';
import type { IntakePlanningSkuAnalytics } from './types';

interface Props {
  accountUuid: string;
  productId: string;
}

const Analytics: FC<Props> = ({ accountUuid, productId }) => {
  const history = useHistory();
  const location = useLocation();
  const { analyticsPeriod = AnalyticsViewPeriod.THREE_MONTHS } = parseQueryParams(location.search);
  const { data, error, loading } = useIntakePlanningSkuAnalyticsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid,
      dateRange: getDateRange(analyticsPeriod),
      productId,
      onTimeThreshold: undefined,
      inFullThreshold: undefined
    }
  });

  const handleFiltersChange = (period: AnalyticsViewPeriod) => {
    history.replace({
      ...location,
      search: mergeQueryParams(location.search, { analyticsPeriod: period })
    });
  };

  return (
    <div className="flex flex-col h-full">
      <Helmet title="Product analytics view" />
      <Filters onChange={handleFiltersChange} value={analyticsPeriod} />
      <div className="bg-grey-lightest border-t border-grey-lighter border-solid flex flex-1 flex-row py-8">
        <PageContent className="w-full" width="wide">
          <QueryHandler data={data?.account?.intakePlanningSkuAnalytics} error={!!error} isLoading={loading}>
            {(skuAnalytics: IntakePlanningSkuAnalytics) => {
              const hasData: boolean = !(
                !skuAnalytics.earlyPercentage &&
                !skuAnalytics.onTimePercentage &&
                !skuAnalytics.latePercentage
              );

              return (
                <>
                  {!hasData && (
                    <NoResults
                      headline="We can't find any results"
                      tagline="There are no results for your query, please adjust your search or filter criteria and try again."
                    />
                  )}
                  {hasData && (
                    <div className="flex">
                      <ShadowBox className="flex-1" title="On time">
                        <OnTimeChart
                          averageEarliness={skuAnalytics.averageEarliness}
                          averageLateness={skuAnalytics.averageLateness}
                          earlyPercentage={skuAnalytics.earlyPercentage}
                          latePercentage={skuAnalytics.latePercentage}
                          onTimePercentage={skuAnalytics.onTimePercentage}
                        />
                      </ShadowBox>
                      <ShadowBox className="flex-1 ml-6" title="In full">
                        <InFullChart
                          averageFulfilmentRate={skuAnalytics.averageFulfilmentRate}
                          inFullPercentage={skuAnalytics.inFullPercentage}
                        />
                      </ShadowBox>
                      <ShadowBox className="flex-1 ml-6" title="Lead time">
                        <LeadTime leadTimeDistribution={skuAnalytics.leadTimeDistribution} />
                      </ShadowBox>
                    </div>
                  )}
                </>
              );
            }}
          </QueryHandler>
        </PageContent>
      </div>
    </div>
  );
};

export default Analytics;
