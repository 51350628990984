import { FC, useState } from 'react';

import { LegacyModal, Modal } from '@zen/DesignSystem';
import useTracking from '@zen/utils/hooks/useTracking';

import IssueActionButtons from '../components/IssueActionButtons';
import IssueDetails from '../components/IssueDetails';
import IssueDetailsTitle from '../components/IssueDetailsTitle';
import IssueStateDescription from '../components/IssueStateDescription';
import EditIssue from '../EditIssue';
import type { Issue } from '../types';

interface Props {
  associatedObjectReference?: string;
  issue: Issue;
  onClose: () => void;
  onIssueChange: () => void;
}

const IssueModalContainer: FC<Props> = ({ issue, onClose, associatedObjectReference, onIssueChange }) => {
  const { trackEvent } = useTracking();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(!!issue);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);

  const handleIssueStateChange = () => {
    setDetailsOpen(false);
    onIssueChange();
    onClose();
  };

  const handleDetailsModalClose = () => {
    setDetailsOpen(false);
    onClose();
  };

  const handleIssueEdit = () => {
    trackEvent({
      action: 'EditIssue',
      category: 'Issues',
      label: issue.category
    });

    setDetailsOpen(false);
    setEditOpen(true);
  };

  const handleEditModalClose = () => {
    setEditOpen(false);
    onClose();
  };

  const handleIssueEditSuccess = () => {
    handleEditModalClose();
    onIssueChange();
  };

  return (
    <>
      <LegacyModal closeOnClickAway={false} isOpen={isDetailsOpen} onClose={handleDetailsModalClose}>
        <LegacyModal.Header
          subtitle={<IssueStateDescription issue={issue} showHistory={true} />}
          title={<IssueDetailsTitle issue={issue} itemReference={associatedObjectReference} />}
        />
        <LegacyModal.Content>
          <IssueDetails className="mb-10 w-180" issue={issue} />
          <IssueActionButtons issue={issue} onEdit={handleIssueEdit} onStateChange={handleIssueStateChange} />
        </LegacyModal.Content>
      </LegacyModal>

      <Modal
        isOpen={isEditOpen}
        onClose={handleEditModalClose}
        tagline={associatedObjectReference || issue.associatedId}
        title="Editing an issue"
      >
        <EditIssue issue={issue} onCancel={handleEditModalClose} onSuccess={handleIssueEditSuccess} />
      </Modal>
    </>
  );
};

export default IssueModalContainer;
