import type { IncotermType } from '@zen/Components/Form/FormIncotermsSelect/types';
import type {
  ConfidenceLevel,
  Currency,
  DateRangeInput,
  FilterOptionsEnum,
  IncotermsValue,
  LastUpdated,
  MoneyInput,
  NetworksAssignableInterface,
  PurchaseOrderBookingStateEnum,
  PurchaseOrderInput,
  PurchaseOrderStatusEnum,
  RichDate as RichDateType,
  StageValue,
  UpdatePurchaseOrderInput
} from '@zen/graphql/types.generated';
import type { ModeOfTransport } from '@zen/Shipments/types';
import type { Customer } from '@zen/utils/hooks/useCustomers';
import type { DeepNonNullable, Nullable, Optional } from '@zen/utils/typescript';

import type { INetworksLegacyOrgLocOption, IOrgLocOption, NetworksOrgLocOption, Terminal } from '../Networks/types';
import type {
  OrderDetailsLineItemsQueryResult,
  OrderFormQueryResult,
  OrderListLotsAndPurchaseOrderQueryResult,
  OrderListQueryResult,
  OrderTableQueryResult
} from './graphql';

export type {
  FiltersTerminalType as FiltersTerminal,
  IssuesFilterInput,
  LastUpdated,
  LotInput,
  OrderedLineItem,
  PurchaseOrderEstimatedDateType,
  PurchaseOrderInput,
  PurchaseOrderLotStageCountType,
  UpdateLotsInput
} from '@zen/graphql/types.generated';
export {
  ConfidenceLevel,
  FilterOptionsEnum,
  PurchaseOrderBookingStateEnum,
  PurchaseOrderLotStageEnum,
  PurchaseOrdersColumnEnum,
  PurchaseOrderStatusEnum,
  StageValue,
  Status
} from '@zen/graphql/types.generated';
export type { Product } from '@zen/Products/types';

export type { Customer };

export interface OrderFilters {
  bookingState?: PurchaseOrderBookingStateEnum[];
  cargoReadyDateBetween?: DateRangeInput;
  cargoReadyDateIn?: Nullable<number>;
  customer?: Customer;
  filterBy?: FilterOptionsEnum;
  incoterms?: IncotermType;
  issueTitle?: string;
  lastUpdatedBetween?: DateRangeInput;
  manufacturerIds?: string[];
  portOfDestinationUnlocode?: string;
  portOfLoadUnlocode?: string;
  status?: Nullable<PurchaseOrderStatusEnum>;
}

export interface BookingStage {
  actionItemCount?: number;
  description?: string;
  statusUpdate?: { title: string };
  value: StageValue;
}

export type OrderTableView = DeepNonNullable<OrderTableQueryResult, 'nodes'>['purchaseOrders']['nodes'];

export type OrderListViewItem = DeepNonNullable<OrderListQueryResult, 'nodes'>['purchaseOrders']['nodes'];

export type OrderListViewPurchaseOrder = DeepNonNullable<
  OrderListLotsAndPurchaseOrderQueryResult,
  'nodes'
>['purchaseOrders']['nodes'];

export type OrderListViewLotWithoutPurchaseOrder = DeepNonNullable<
  OrderListLotsAndPurchaseOrderQueryResult,
  'nodes'
>['purchaseOrdersLots']['nodes'];

export interface OrderListViewLot extends OrderListViewLotWithoutPurchaseOrder {
  purchaseOrder: OrderListViewPurchaseOrder;
}

export type OrderDetailsLineItem = DeepNonNullable<
  OrderDetailsLineItemsQueryResult,
  'nodes'
>['purchaseOrderOrderedLineItems']['nodes'];

export type OrderLineItem = OrderDetailsLineItem;

export type OrderLot = OrderDetailsLineItem['lots'][0];

export type OrderLotBooking = OrderLot['booking'];

export type ProgressStatus = DeepNonNullable<OrderLot, 'stages'>['stages'];

export type OrderLineItemProduct = OrderLineItem['product'];

export type OrderRichDate = OrderLot['cargoReadyDate'];

export interface OrderManufacturer {
  label: {
    short: string;
  };
}

export interface OrderBasketLot {
  cargoReadyDate?: Optional<Pick<RichDateType, 'date'>>;
  cbm: Optional<number>;
  destination?: Nullable<NetworksAssignableInterface>;
  id: string;
  incoterms?: Nullable<IncotermsValue>;
  manufacturerName?: Nullable<string>;
  modeOfTransport?: Nullable<ModeOfTransport>;
  orderDate?: Nullable<string>;
  orderId?: string;
  orderReferenceNumber: string;
  origin?: Nullable<NetworksAssignableInterface>;
  productName: Optional<string>;
  productSkuCode?: Optional<string>;
  quantityFulfilled: Optional<number>;
}

export interface CreateOrderResponse {
  purchaseOrder: {
    id: string;
  };
}

export type OrderFormOrder = DeepNonNullable<OrderFormQueryResult, 'nodes'>['purchaseOrders']['nodes'];

export type OrderFormOrderedLineItem = OrderFormOrder['orderedLineItems'][0];

export type OrderFormPayload = UpdatePurchaseOrderInput | PurchaseOrderInput;

export interface OrderFormValues {
  buyer?: Optional<NetworksOrgLocOption | INetworksLegacyOrgLocOption>;
  collectionWarehouse: Optional<IOrgLocOption>;
  deliveryWarehouse: Optional<IOrgLocOption>;
  forwarder?: Optional<NetworksOrgLocOption>;
  id?: number;
  incoterms: Optional<IncotermsValue>;
  lineItemIdsToDelete?: string[];
  manufacturer?: Optional<NetworksOrgLocOption | INetworksLegacyOrgLocOption>;
  modeOfTransport: Optional<ModeOfTransport>;
  orderDate: Optional<string>;
  orderReferenceNumber: Optional<string>;
  orderedLineItems: OrderLineItemValues[];
  originAgent?: Optional<NetworksOrgLocOption>;
  portOfDestinationUnlocode: Optional<Terminal>;
  portOfLoadUnlocode: Optional<Terminal>;
  seller?: Optional<NetworksOrgLocOption | INetworksLegacyOrgLocOption>;
}

export interface ReasonForChange {
  category: string;
  description: string;
}

export interface RichDate {
  confidenceLevel?: ConfidenceLevel;
  date?: string;
  history: RichDate[];
  lastUpdated: LastUpdated;
  reasonForChange: ReasonForChange;
}

export interface OrderLineItemPayload {
  cbm?: Optional<number>;
  id?: Optional<string>;
  initialCargoReadyDate?: Optional<string>;
  productSku: string;
  quantityOrdered: number;
  requiredDeliveryDate?: Optional<string>;
  totalCost?: Optional<MoneyInput>;
}

export interface OrderLineItemValues {
  cbm?: Optional<number>;
  id?: Optional<string>;
  initialCargoReadyDate?: Optional<string>;
  product: OrderLineItemProduct;
  quantityOrdered?: Optional<number>;
  requiredDeliveryDate?: Optional<string>;
  totalCost: {
    currency: Currency;
    value: Optional<number>;
  };
}

export enum ProductionStatusValue {
  ahead = 'ahead',
  at_risk = 'at_risk',
  delayed = 'delayed',
  no_update = 'no_update',
  on_time = 'on_time',
  ready = 'ready'
}
