import cx from 'classnames';
import type { FC } from 'react';

interface Props {
  className?: string;
  text: string;
}

const Hint: FC<Props> = ({ className, text }) => {
  const classNames: string = cx('p-2 bg-azure-lightest rounded color-grey-dark text-xs leading-snug', className);

  return <div className={classNames}>{text}</div>;
};

export type { Props as HintProps };

export default Hint;
