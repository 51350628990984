import type { FC } from 'react';

import FormIncotermsSelect from '@zen/Components/Form/FormIncotermsSelect';
import FormModeOfTransportSelect from '@zen/Components/Form/FormModeOfTransportSelect';
import FormTerminalsSelect from '@zen/Components/Form/FormTerminalsSelect';
import { Headline } from '@zen/DesignSystem';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput/NetworksContactFormInput';
import { AssignmentTypeValue } from '@zen/Networks/types';

import type { NetworkFieldProps } from '../OrderForm';

interface Props {
  getFieldProps: (name: string, label: string, assignmentType: AssignmentTypeValue) => NetworkFieldProps;
}

const RoutingInformation: FC<Props> = ({ getFieldProps }) => {
  return (
    <>
      <Headline level={2}>Routing information</Headline>
      <FormIncotermsSelect name="incoterms" />
      <FormModeOfTransportSelect name="modeOfTransport" />
      <NetworksContactFormInput {...getFieldProps('collectionWarehouse', 'Collection Warehouse', AssignmentTypeValue.ORIGIN)} />
      <NetworksContactFormInput {...getFieldProps('deliveryWarehouse', 'Delivery Warehouse', AssignmentTypeValue.DESTINATION)} />
      <FormTerminalsSelect className="w-64" label="Port of load" name="portOfLoadUnlocode" placeholder="Shanghai (CNSHA)" />
      <FormTerminalsSelect
        className="w-64"
        label="Port of destination"
        name="portOfDestinationUnlocode"
        placeholder="Shanghai (CNSHA)"
      />
    </>
  );
};

export default RoutingInformation;
