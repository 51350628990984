import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import NoResults from '../NoResults';
import { ReactComponent as PlaneImage } from './plane.svg';

class ErrorBoundary extends Component<RouteComponentProps, { hasError: boolean }> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.state.hasError && prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        hasError: false
      });
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-full" data-testid="error-page">
          <NoResults
            button={{ onClick: () => window.location.reload(), label: 'Try again' }}
            headline="We can't load the page"
            image={<PlaneImage />}
            tagline={<div className="w-96">Something went wrong when loading the page. We're sorry about that.</div>}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
