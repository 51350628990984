import { get } from 'lodash';
import type { FC, ReactElement } from 'react';
import { Bar, BarChart as BarChartComponent, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { Color } from '@zen/Styleguide';
import { formatNumber } from '@zen/utils/formatting';

import { getEarlyBarRadiusValue, getLateBarRadiusValue, getOnTimeBarRadiusValue } from './helpers';

interface Props {
  averageEarliness: number;
  averageLateness: number;
  earlyPercentage: number;
  latePercentage: number;
  onTimePercentage: number;
}

const BarChart: FC<Props> = (props) => {
  const { averageEarliness, averageLateness, earlyPercentage, onTimePercentage, latePercentage } = props;

  const data = {
    early: {
      percentage: earlyPercentage,
      average: averageEarliness,
      label: 'early'
    },
    onTime: {
      percentage: onTimePercentage,
      average: null,
      label: 'on time'
    },
    late: {
      percentage: latePercentage,
      average: averageLateness,
      label: 'late'
    }
  };

  const renderLegend = ({ payload }: { payload: { color: string; value: string }[] }): ReactElement => (
    <ul>
      {payload.map(({ color, value }, index: number) => {
        const [entry] = value.split('.');
        const label: string = get(data, `${entry}.label`);
        const percentage: number = get(data, `${entry}.percentage`);
        const averageDays: number = get(data, `${entry}.average`);

        return (
          <li key={index} className="flex mb-2 items-center text-grey-base">
            <div className="w-5 h-5 rounded mr-2" style={{ backgroundColor: color }} />
            <div className="w-20">{label}</div>
            <div>
              <span className="inline-block w-16">{formatNumber(percentage)}%</span>
              {averageDays && <span>avg {Math.round(averageDays)} days</span>}
            </div>
          </li>
        );
      })}
    </ul>
  );

  return (
    <ResponsiveContainer height={150} width="100%">
      <BarChartComponent data={[data]} layout="vertical">
        <Bar
          dataKey="early.percentage"
          fill={Color.ORANGE_BASE}
          maxBarSize={25}
          radius={getEarlyBarRadiusValue(onTimePercentage, latePercentage)}
          stackId="a"
        />
        <Bar
          dataKey="onTime.percentage"
          fill="#81DB51"
          maxBarSize={25}
          radius={getOnTimeBarRadiusValue(earlyPercentage, latePercentage)}
          stackId="a"
        />
        <Bar
          dataKey="late.percentage"
          fill={Color.RED_BASE}
          maxBarSize={25}
          radius={getLateBarRadiusValue(earlyPercentage, onTimePercentage)}
          stackId="a"
        />
        <XAxis hide={true} type="number" />
        <YAxis hide={true} type="category" />
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Legend content={renderLegend} layout="vertical" margin={{ top: 20 }} verticalAlign="bottom" wrapperStyle={{ left: 5 }} />
      </BarChartComponent>
    </ResponsiveContainer>
  );
};

export default BarChart;
