import type { FC } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button, LegacyModal, TextInput } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  invitationLink: string;
  isOpen: boolean;
  onClose: () => void;
}

const InvitationLinkModal: FC<Props> = ({ isOpen, onClose, invitationLink }) => {
  const { addSuccess } = useNotification();

  return (
    <LegacyModal isOpen={isOpen} onClose={onClose}>
      <div className="max-w-3xl min-w-xs p-8 text-centerm mx-auto">
        <div className="font-bold text-2xl text-navy-base mb-2 leading-tight">Re-invitation link</div>
        <div className="text-navy-base px-10 leading-normal">
          Using the link below the new user will be able to gain access to the platform.
        </div>
        <div className="flex justify-center mt-6">
          <div className="w-1/2">
            <TextInput className="mr-4" readOnly={true} value={invitationLink} />
          </div>
          <CopyToClipboard
            onCopy={() => {
              addSuccess('Copied to clipboard');
            }}
            text={invitationLink}
          >
            <Button
              onClick={() => {
                addSuccess('Copied to clipboard');
              }}
            >
              Copy link
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </LegacyModal>
  );
};

export default InvitationLinkModal;
