import type { FC } from 'react';
import { useParams } from 'react-router';

import QueryHandler from '@zen/Components/QueryHandler';
import { getNetworksFieldValueWithName } from '@zen/Networks';
import { consolidationRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { prepareConsolidationInput } from '../consolidation.helper';
import ConsolidationForm from '../ConsolidationForm';
import { useConsolidatedShipmentDetailsQuery, useConsolidationUpdateConsolidatedShipmentMutation } from '../graphql';
import type {
  ConsolidatedShipmentEditType,
  ConsolidatedShipmentUpdateInput,
  ConsolidatedShipmentValues,
  ConsolidationLegType,
  UpdateConsolidatedShipmentInput
} from '../types';

const EditConsolidation: FC = () => {
  const { navigateBack } = useNavigationHistory();
  const { addSuccess, addError } = useNotification();
  const { consolidationId } = useParams<{ consolidationId: string }>();
  const [updateConsolidatedShipment] = useConsolidationUpdateConsolidatedShipmentMutation();

  const {
    data: consolidatedShipmentData,
    loading,
    error
  } = useConsolidatedShipmentDetailsQuery({
    variables: {
      consolidationId
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const defaultBackUrl: string = consolidationRoutes.consolidationIndex.getUrl();
  const handleSubmit = (values: ConsolidatedShipmentValues): Promise<IOkOrErrorResult> => {
    const consolidatedShipmentUpdateInput: ConsolidatedShipmentUpdateInput = {
      ...prepareConsolidationInput(values)
    };

    const input: UpdateConsolidatedShipmentInput = {
      consolidatedShipment: consolidatedShipmentUpdateInput,
      consolidatedShipmentId: consolidationId
    };

    return performFormMutation({
      mutationFn: () =>
        updateConsolidatedShipment({
          variables: {
            input
          }
        }),
      onError: addError
    });
  };

  const handleSuccess = () => {
    addSuccess('Consolidation updated');
    navigateBack(defaultBackUrl);
  };

  const handleCancel = () => navigateBack(defaultBackUrl);

  const getConsolidatedShipment = (consolidatedShipment: ConsolidatedShipmentEditType): ConsolidatedShipmentValues => {
    const { accountUuid, ...rest } = consolidatedShipment;

    return {
      ...rest,
      customerId: consolidatedShipment.accountUuid,
      originAgent: getNetworksFieldValueWithName(consolidatedShipment.originAgent),
      legs: consolidatedShipment.legs.map((leg: ConsolidationLegType) => ({
        ...leg,
        networksOrigin: getNetworksFieldValueWithName(leg.networksOrigin),
        networksDestination: getNetworksFieldValueWithName(leg.networksDestination)
      }))
    };
  };

  return (
    <QueryHandler data={consolidatedShipmentData?.consolidations?.nodes?.[0]} error={!!error} isLoading={loading}>
      {(consolidatedShipment: ConsolidatedShipmentEditType) => (
        <ConsolidationForm
          consolidation={getConsolidatedShipment(consolidatedShipment)}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          submitButtonText="Save updates"
        />
      )}
    </QueryHandler>
  );
};

export default EditConsolidation;
