import type { FC } from 'react';

import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import Label from '@zen/Components/Label';

interface Props {
  namePrefix: string;
}

const FormDateRangeFilter: FC<Props> = ({ namePrefix }) => {
  return (
    <div className="mb-4 bg-grey-lightest p-3" data-testid="form-date-range-filter">
      <Label>Select custom date range</Label>
      <div className="flex">
        <FormDatePickerInput
          className="w-48 mr-4"
          label="From"
          name={`${namePrefix}.startDate`}
          noMargin={true}
          pickerPositionX="left"
          placeholder="01/01/2020"
        />
        <FormDatePickerInput
          className="w-48"
          label="To"
          name={`${namePrefix}.endDate`}
          noMargin={true}
          placeholder="01/01/2020"
        />
      </div>
    </div>
  );
};

export default FormDateRangeFilter;
