import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';

interface RouterHelpers {
  isMatchingPathname: (pathname: string, exact?: boolean) => boolean;
}

export const useRouter = (): RouterHelpers => {
  const { pathname } = useLocation();

  const isMatchingPathname = (path: string, exact: boolean = false): boolean => {
    return !!matchPath(pathname, { path, exact });
  };

  return {
    isMatchingPathname
  };
};
