import type { FC } from 'react';

import MilestoneDateForm from '@zen/Shipment/RouteProgress/VoyageMilestones/components/MilestoneDateForm';
import type { MilestoneDateFormType } from '@zen/Shipment/RouteProgress/VoyageMilestones/components/MilestoneDateForm/types';
import { VoyageMilestoneNameEnum } from '@zen/Shipment/RouteProgress/VoyageMilestones/types';
import { useMilestoneDateValidation } from '@zen/Shipment/ShipmentDetailsPage/MilestoneDateProvider';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useAddDeliveryEstimateMutation } from '../graphql';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  zencargoReference: string;
}

const AddDeliveryEstimate: FC<Props> = ({ onClose, zencargoReference, onSuccess }) => {
  const { addSuccess, addError } = useNotification();
  const [addDeliveryEstimate] = useAddDeliveryEstimateMutation();

  const { minDate } = useMilestoneDateValidation(VoyageMilestoneNameEnum.DELIVERED);

  const handleSubmit = async (values: MilestoneDateFormType): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () => addDeliveryEstimate({ variables: { input: { zencargoReference, edd: values.date } } }),
      onError: addError
    });
  };

  const handleSuccess = () => {
    onSuccess();
    addSuccess('New estimated delivery date added');
    onClose();
  };

  return (
    <MilestoneDateForm
      initialValues={{ date: '' }}
      isInitialDateEntry={true}
      minDate={minDate}
      onCancel={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default AddDeliveryEstimate;
