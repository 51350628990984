import cx from 'classnames';
import { FC, ReactChild, ReactNode, useRef } from 'react';
import { Portal } from 'react-portal';
import { useKey } from 'react-use';

import { Headline, IconButton } from '@zen/DesignSystem';
import { MODAL_OVERLAY_ZINDEX, MODAL_ZINDEX } from '@zen/Layout/constants';
import useClickAway from '@zen/utils/hooks/useClickAway';

interface Props {
  className?: string;
  closeOnClickAway?: boolean;
  control?: ReactNode;
  footer?: ReactNode;
  isOpen: boolean;
  modalOverflowY?: overflowYClassesKeys;
  onClose?: () => void;
  onCloseOnClickAway?: () => void;
  showCloseButton?: boolean;
  tagline?: string;
  title: ReactChild;
}

export type overflowYClassesKeys = 'auto' | 'visible' | 'scroll';

const overflowYClasses: Record<overflowYClassesKeys, string> = {
  auto: 'overflow-y-auto',
  visible: 'overflow-y-visible',
  scroll: 'overflow-y-scroll'
};

const Modal: FC<Props> = (props) => {
  const {
    className,
    closeOnClickAway = true,
    isOpen,
    modalOverflowY = 'auto',
    onClose = () => false,
    showCloseButton = true,
    onCloseOnClickAway,
    title,
    footer,
    children,
    control,
    tagline,
    ...rest
  } = props;

  const ref = useRef(null);

  const handleClickAway = (): void => {
    if (!closeOnClickAway) {
      return;
    }

    if (onCloseOnClickAway) {
      onCloseOnClickAway();

      return;
    }

    onClose();
  };

  useKey('Escape', handleClickAway);
  useClickAway(ref, handleClickAway);

  if (!isOpen) {
    return null;
  }

  const modalClassNames: string = cx(`fixed inset-0 ${MODAL_ZINDEX} flex items-start mt-16 justify-center py-4`, className);
  const contentClassNames: string = cx('pt-4 px-6 mb-20', overflowYClasses[modalOverflowY]);
  const headerClassNames: string = cx(
    {
      'pb-4': !control
    },
    'py-4 px-6 border-b border-solid border-grey-lighter space-y-2'
  );

  return (
    <Portal>
      <div className={`fixed inset-0 bg-grey-base opacity-25 ${MODAL_OVERLAY_ZINDEX}`} />
      <div className={modalClassNames} data-component="modal" data-testid="modal" {...rest}>
        <div
          ref={ref}
          className="relative transform rounded shadow-overlay bg-white flex flex-col max-h-full min-w-[32rem] max-w-screen-md"
        >
          <div className={headerClassNames}>
            <div className="flex justify-between items-center">
              <div data-testid="modal-header">
                <Headline className="mr-4 text-grey-dark" level={2}>
                  {title}
                </Headline>
                {tagline && <div className="text-sm text-grey-base leading-normal">{tagline}</div>}
              </div>
              {showCloseButton && <IconButton icon="zicon-close" onClick={onClose} variant="tertiary" />}
            </div>
            {control}
          </div>
          <div className={contentClassNames} data-testid="modal-content">
            {children}
          </div>
          {footer && <div className="flex justify-end items-center p-6 border-t border-solid border-grey-lighter">{footer}</div>}
        </div>
      </div>
    </Portal>
  );
};

export type { Props as ModalProps };
export default Modal;
