import type { QueryHookOptions, QueryResult } from '@apollo/client';
import type { ReactChild, ReactElement, ReactNode } from 'react';

import AddHideButton from '@zen/Components/AddHideButton';
import QueryHandler from '@zen/Components/QueryHandler';
import { Container, EmptyState, Pagination, TextLink } from '@zen/DesignSystem';
import { NetworksContact, useNetworksContext } from '@zen/Networks';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import type { PaginatedVariables } from '@zen/utils/hooks/pagination/usePagination';
import usePagination from '@zen/utils/hooks/pagination/usePagination';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import ContactTable, { ContactTableProps } from '../ContactTable';

type ContactListProps = Pick<
  ContactTableProps<NetworksContact>,
  'removeType' | 'organisationId' | 'isMyOrganisationPage' | 'locationId' | 'throughLocationDetails'
>;
interface Props<Result, Variables> extends ContactListProps {
  hasLocations?: boolean;
  locationId?: string;
  locationName?: string;
  paginatedQueryHook: (args: QueryHookOptions<Result, Variables>) => QueryResult<Result, Variables>;
  paginatedQueryPath: string;
  paginatedQueryVars: Variables;
}

const ContactTableContainer = <Result, Variables extends PaginatedVariables>(props: Props<Result, Variables>): ReactElement => {
  const { navigate } = useNavigationHistory();
  const { routes } = useNetworksContext();
  const {
    hasLocations = true,
    organisationId,
    isMyOrganisationPage,
    locationId,
    locationName,
    paginatedQueryHook,
    paginatedQueryPath,
    paginatedQueryVars,
    removeType,
    throughLocationDetails
  } = props;
  const { loading, error, nodes, paginationInfo, totalCount, refetch } = usePagination<Result, Variables, NetworksContact>(
    paginatedQueryHook,
    paginatedQueryPath,
    paginatedQueryVars,
    20,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );

  const { url, state, label } = hasLocations
    ? {
        url: routes.contact.new.getUrl(organisationId),
        state: {
          fromMyOrganisationPage: isMyOrganisationPage,
          locationId
        },
        label: 'Add a new contact'
      }
    : {
        url: routes.location.new.getUrl(organisationId),
        state: {
          fromMyOrganisationPage: isMyOrganisationPage
        },
        label: 'add a location here'
      };

  const handleClick = (): void => {
    navigate({
      pathname: url,
      state
    });
  };

  const hasContacts: boolean = Array.isArray(nodes) && !!nodes.length;
  const addContactOrLocation: ReactNode =
    hasLocations && hasContacts ? <AddHideButton onClick={handleClick} text={label} /> : null;

  const contactListTitle: string = locationName
    ? `Contacts that can be found at ${removeSpecialCharactersAndUpperFirst(locationName)}`
    : 'Contacts';

  const renderNoResultsBodyText = (): ReactChild => {
    if (hasLocations) {
      return (
        <>
          You can{' '}
          <TextLink isStandalone={false} onClick={handleClick}>
            {label}
          </TextLink>
        </>
      );
    }

    return (
      <>
        You need to{' '}
        <TextLink isStandalone={false} onClick={handleClick}>
          {label}
        </TextLink>{' '}
        first before adding a contact.
      </>
    );
  };

  return (
    <Container actions={addContactOrLocation} className="my-6" title={contactListTitle}>
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        noResults={<EmptyState bodyText={renderNoResultsBodyText()} headerText="You don't have any contacts" />}
      >
        {(contacts: NetworksContact[]) => (
          <>
            <ContactTable<NetworksContact>
              contacts={contacts}
              isMyOrganisationPage={isMyOrganisationPage}
              locationId={locationId}
              onContactChange={refetch}
              onRemove={refetch}
              organisationId={organisationId}
              removeType={removeType}
              throughLocationDetails={throughLocationDetails}
              totalCount={totalCount}
            />
            <Pagination pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
    </Container>
  );
};

export default ContactTableContainer;
