import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RequireDeliveryScheduleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.RequireDeliveryScheduleInput;
}>;

export type RequireDeliveryScheduleMutationResult = { __typename?: 'Mutation' } & {
  bookingCollectionAndDeliveryRequireDeliverySchedule?: GraphQLTypes.Maybe<
    { __typename?: 'RequireDeliverySchedulePayload' } & Pick<GraphQLTypes.RequireDeliverySchedulePayload, 'clientMutationId'>
  >;
};

export const RequireDeliveryScheduleDocument = /* #__PURE__ */ gql`
  mutation requireDeliverySchedule($input: RequireDeliveryScheduleInput!) {
    bookingCollectionAndDeliveryRequireDeliverySchedule(input: $input) {
      clientMutationId
    }
  }
`;

/**
 * __useRequireDeliveryScheduleMutation__
 *
 * To run a mutation, you first call `useRequireDeliveryScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequireDeliveryScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requireDeliveryScheduleMutation, { data, loading, error }] = useRequireDeliveryScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequireDeliveryScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<RequireDeliveryScheduleMutationResult, RequireDeliveryScheduleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RequireDeliveryScheduleMutationResult, RequireDeliveryScheduleMutationVariables>(
    RequireDeliveryScheduleDocument,
    options
  );
}
export type RequireDeliveryScheduleMutationHookResult = ReturnType<typeof useRequireDeliveryScheduleMutation>;
