import type { FC } from 'react';

import type { PillType } from '@zen/DesignSystem';
import { Pill } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import { ShippingOrderState } from './types';

interface PillConfig {
  label: string;
  tooltip: string;
  type: PillType;
}

type StatusPillMapping = {
  [key in ShippingOrderState]?: Optional<PillConfig>;
};

const pillConfigMapping: StatusPillMapping = {
  [ShippingOrderState.CONFIRMED]: {
    type: 'success',
    label: 'Confirmed',
    tooltip: 'The space for this booking has been confirmed by the carrier'
  },
  [ShippingOrderState.NOT_CONFIRMED]: {
    type: 'problem',
    label: 'Not confirmed',
    tooltip: 'The space for this booking has not been confirmed by the carrier'
  },
  [ShippingOrderState.NOT_REQUIRED]: undefined
};

interface Props {
  shippingOrderStatus: ShippingOrderState;
}

const ShippingOrderStatusPill: FC<Props> = ({ shippingOrderStatus }) => {
  const pillConfig: Optional<PillConfig> = pillConfigMapping[shippingOrderStatus];

  if (!pillConfig) {
    return null;
  }

  return (
    <Pill className="leading-none" tooltip={pillConfig.tooltip} type={pillConfig.type}>
      {pillConfig.label}
    </Pill>
  );
};

export type { Props as ShippingOrderStatusPillProps };

export default ShippingOrderStatusPill;
