import type { FC } from 'react';

import { FormSelect } from '@zen/Components/Form';
import FormHiddenInput from '@zen/Components/Form/FormHiddenInput/FormHiddenInput';
import FormNumberInput from '@zen/Components/Form/FormNumberInput/FormNumberInput';
import type { Option } from '@zen/DesignSystem';
import { DimensionUnit } from '@zen/Products/types';

const PackageInfo: FC = () => {
  const unitOptions: Option<DimensionUnit>[] = [
    { label: 'CM', value: DimensionUnit.CM },
    { label: 'M', value: DimensionUnit.M }
  ];

  return (
    <div>
      <div className="flex justify-between">
        <FormNumberInput
          className="mr-2"
          hideErrorMessage={true}
          hideLabel={true}
          name="packageInfo.length.value"
          placeholder="L"
        />
        <FormNumberInput
          className="mr-2"
          hideErrorMessage={true}
          hideLabel={true}
          name="packageInfo.width.value"
          placeholder="W"
        />
        <FormNumberInput
          className="mr-2"
          hideErrorMessage={true}
          hideLabel={true}
          name="packageInfo.depth.value"
          placeholder="D"
        />
        <FormSelect hideLabel={true} name="unit" options={unitOptions} />
      </div>
      <FormHiddenInput className="-mt-6" name="packageInfoValidator" />
    </div>
  );
};

export default PackageInfo;
