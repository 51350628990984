import { FC, ReactNode, useState } from 'react';

import type { IconName } from '@zen/Styleguide';
import type { Nullable } from '@zen/utils/typescript';

import Input, { InputProps } from '../Input';
import InputButton from '../InputButton';

interface Props extends Omit<InputProps, 'type' | 'iconLeft' | 'iconRight' | 'value'> {
  value: Nullable<string>;
}

const PasswordInput: FC<Props> = (props) => {
  const { value, ...rest } = props;
  const hasText: boolean = !!value && value.length > 0;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputType: string = showPassword ? 'text' : 'password';

  const renderPasswordIcon = (): ReactNode => {
    if (!hasText) {
      return null;
    }

    const icon: IconName = showPassword ? 'zicon-hidden' : 'zicon-visible';

    return (
      <InputButton
        icon={icon}
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      />
    );
  };

  return (
    <Input
      {...rest}
      data-component="password-input"
      data-testid="password-input"
      iconRight={renderPasswordIcon()}
      type={inputType}
      value={value}
    />
  );
};

export type { Props as PasswordInputProps };

export default PasswordInput;
