import {
  camelCase,
  isArray,
  isNil,
  isObject,
  isObjectLike,
  isPlainObject,
  isString,
  map,
  mapValues,
  mergeWith,
  set,
  snakeCase,
  transform
} from 'lodash';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'converter' implicitly has an 'any' type... Remove this comment to see the full error message
function createIteratee(converter, self) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
  return (result, value, key) => set(result, converter(key), isObjectLike(value) ? self(value) : value);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'keyConverter' implicitly has an 'any' t... Remove this comment to see the full error message
function createHumps(keyConverter) {
  // @ts-expect-error ts-migrate(7023) FIXME: 'humps' implicitly has return type 'any' because i... Remove this comment to see the full error message
  return function humps(node) {
    if (isArray(node)) {
      return map(node, humps);
    }
    if (isPlainObject(node)) {
      return transform(node, createIteratee(keyConverter, humps));
    }

    return node;
  };
}

export const convertObjectKeysToSnakeCase = (obj: any) => {
  return createHumps(snakeCase)(obj);
};

export const convertObjectKeysToCamelCase = (obj: any) => {
  return createHumps(camelCase)(obj);
};

// @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
const trimValue = (value: any) => {
  if (isArray(value)) {
    return value.map(trimValue);
  }

  if (isObject(value)) {
    return mapValues(value, trimValue);
  }

  if (isString(value)) {
    return value.trim();
  }

  return value;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'obj' implicitly has an 'any' type.
export const trimObjectValues = (obj) => {
  return mapValues(obj, trimValue);
};

const mergeNilValue = (a: any, b: any) => (isNil(a) ? b : a);

// @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
export const deepMergeNilValues = (a: any, b: any) => {
  if (isObject(a)) {
    return mergeWith(a, b, deepMergeNilValues);
  }

  return mergeNilValue(a, b);
};

export const convertStringToNumber = (value: string, defaultValue: number | null = null): number | null => {
  const parsedValue = parseFloat(value);

  // eslint-disable-next-line
  return isNaN(parsedValue) ? defaultValue : parsedValue;
};
