import cx from 'classnames';
import type { FC } from 'react';

export interface Props {
  className?: string;
  firstName: string;
  imageUrl?: string;
  lastName: string;
}

const getFirstChar = (str: string) => str && str[0].toUpperCase();

const Avatar: FC<Props> = (props) => {
  const { className, imageUrl, firstName, lastName } = props;
  const imagePresent = !!imageUrl;
  const fullName = `${firstName} ${lastName}`;
  const initials = `${getFirstChar(firstName)}${getFirstChar(lastName)}`;
  const classNames = cx('w-8 h-8 relative flex justify-center items-center bg-grey-dark rounded-full text-white', className);

  return (
    <div className={classNames} data-testid="avatar">
      {imagePresent && <img alt={fullName} className="rounded-full h-full w-auto" src={imageUrl} />}
      {!imagePresent && initials}
    </div>
  );
};

export default Avatar;
