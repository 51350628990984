import type { FC } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import type { Role } from '@zen/Auth/role';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performFormMutation } from '@zen/utils/performMutation';

import type { IssueSubmitData } from '../components/IssueForm';
import IssueForm from '../components/IssueForm';
import { IssuesOpenIssueMutationResult, useIssuesOpenIssueMutation } from '../graphql';
import { createNewIssueData } from '../issueHelpers';
import type { IssueAssociatedObject, IssueFlatData, OpenIssueInput } from '../types';

interface Props {
  associatedId: string;
  associatedType: IssueAssociatedObject;
  canEditVisibility: boolean;
  onCancel: () => void;
  onIssueChange?: () => void;
  onSuccess: () => void;
}

const NewIssue: FC<Props> = ({ canEditVisibility, onCancel, onSuccess, associatedType, associatedId, onIssueChange }) => {
  const { addError, addSuccess } = useNotification();
  const currentRole: Role = useRole();
  const initialValues: IssueFlatData = createNewIssueData(currentRole);

  const [openIssue] = useIssuesOpenIssueMutation();

  const handleSubmit = async (values: IssueSubmitData) => {
    const input: OpenIssueInput = {
      ...values,
      associatedId,
      associatedType
    } as OpenIssueInput;

    performFormMutation<IssuesOpenIssueMutationResult>({
      mutationFn: () =>
        openIssue({
          variables: {
            input
          },
          refetchQueries: ['getIssuesAndActions']
        }),
      onError: () => addError('There was an error creating this issue')
    });
  };

  const handleSuccess = (): void => {
    onIssueChange?.();
    onSuccess();
    addSuccess('Issue created successfully');
  };

  return (
    <IssueForm
      allowChooseState={canEditVisibility}
      allowEditVisibility={canEditVisibility}
      associatedType={associatedType}
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default NewIssue;
