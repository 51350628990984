import * as Yup from 'yup';

import type { Geolocation } from '@zen/Networks';
import type { Nullable, Optional } from '@zen/utils/typescript';

const dateTimeValidator = Yup.object().shape({
  date: Yup.string().nullable(),
  startTime: Yup.string()
    .nullable()
    .test('time validation', function (time: Optional<string>) {
      if (this.parent.date && !time) {
        return this.createError({ message: 'Please enter start time.' });
      }

      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .test('time validation', function (time: Optional<string>) {
      if (this.parent.date && !time) {
        return this.createError({ message: 'Please enter end time.' });
      }

      return true;
    })
});

const locationValidator = Yup.mixed()
  .nullable()
  .test('geolocation', function (location: Nullable<{ geolocation: Optional<Geolocation>; id: string }>) {
    if (location?.id && 'geolocation' in location && (!location?.geolocation?.latitude || !location?.geolocation?.longitude)) {
      return this.createError({ message: 'Address has no coordinates. Please edit the location to add lat and long.' });
    }

    return true;
  });

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  collectionLocation: locationValidator,
  collectionConfirmedDate: dateTimeValidator,
  deliveryConfirmedDate: dateTimeValidator,
  deliveryLocation: locationValidator
});
