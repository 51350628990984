import { IconButton } from '@zen/DesignSystem';

interface CloseIconProps {
  className?: string;
  onClick: () => void;
}

const CloseIcon = (props: CloseIconProps) => {
  return <IconButton icon="zicon-close" variant="tertiary" {...props} />;
};

export default CloseIcon;
