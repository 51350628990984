import cx from 'classnames';
import { differenceBy } from 'lodash';
import { FC, useCallback, useEffect, useReducer, useState } from 'react';

import { useGetBookingDocumentTypesQuery } from '@zen/BookingDocumentTypes/graphql';
import {
  addDocuments,
  addDocumentToQueue,
  initialState,
  reducer,
  removeDocumentsFromQueue,
  updateDocumentInQueue
} from '@zen/Components/Documents/document.reducer';
import DocumentDropZone from '@zen/Components/Documents/DocumentDropZone';
import DocumentUploader from '@zen/Components/Documents/DocumentUploader';
import { getDefaultDocumentPermissions, getDocumentTypeOptions } from '@zen/Components/Documents/helpers';
import QueryHandler from '@zen/Components/QueryHandler';
import type { Option } from '@zen/DesignSystem';
import { ContextMenu, LegacyModal, MenuItemType } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import type { Document, DocumentType, QueuedDocument } from '../types';

interface Props {
  createDocumentsLink?: string;
  documentType?: string;
  documents?: Document[];
  onAdd: (documents: Document[]) => void;
  showDocumentCreation?: boolean;
}

const DocumentActions: FC<Props> = (props) => {
  const { documents: existingDocuments, documentType, createDocumentsLink, showDocumentCreation, onAdd } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: documentTypesData, loading: documentTypesLoading, error: documentTypesError } = useGetBookingDocumentTypesQuery();
  const { addError } = useNotification();
  const [isUploadMode, setIsUploadMode] = useState<boolean>(false);
  const { navigate } = useNavigationHistory();

  const handleAdd = useCallback(
    ({ documents }, silent = false) => {
      dispatch(addDocuments(documents));
      setIsUploadMode(false);
      if (!silent) {
        onAdd(documents);
      }
    },
    [onAdd]
  );

  useEffect(() => {
    const newDocuments: Document[] = differenceBy(existingDocuments, state.documents, 'uuid');

    if (newDocuments.length > 0) {
      handleAdd({ documents: newDocuments }, true);
    }
  }, [existingDocuments, state.documents, handleAdd]);

  const handleStart = (document: QueuedDocument): void => {
    dispatch(addDocumentToQueue(document));
  };

  const handleProgress = (document: QueuedDocument): void => {
    dispatch(updateDocumentInQueue(document));
  };

  const handleFinish = (document: Document): void => {
    dispatch(
      updateDocumentInQueue({
        ...document,
        documentType,
        permissions: getDefaultDocumentPermissions()
      })
    );
  };

  const handleCancel = (): void => {
    dispatch(removeDocumentsFromQueue());
    setIsUploadMode(false);
  };

  const handleCreateDocument = (): void => {
    if (createDocumentsLink) {
      navigate(createDocumentsLink);
    }
  };

  const dropdownItems: MenuItemType[] = [
    {
      icon: 'zicon-upload' as IconName,
      label: 'Upload document',
      onClick: () => setIsUploadMode(true)
    },
    ...(showDocumentCreation
      ? [
          {
            icon: 'zicon-add' as IconName,
            label: 'Create document from a template',
            onClick: handleCreateDocument
          }
        ]
      : [])
  ];

  const hasDocumentsInQueue: boolean = state.documentsInQueue.length > 0;

  const modalContentClassnames: string = cx('flex justify-center flex-col w-180 px-10 pt-10', {
    'h-100 pb-10': !hasDocumentsInQueue,
    'pb-7': hasDocumentsInQueue
  });

  return (
    <>
      <ContextMenu items={dropdownItems} />

      <QueryHandler
        data={documentTypesData?.bookingDocumentTypes?.nodes as DocumentType[]}
        error={!!documentTypesError}
        isLoading={documentTypesLoading}
      >
        {(bookingDocumentTypes: DocumentType[]) => {
          const documentTypes: Option<string>[] = getDocumentTypeOptions(bookingDocumentTypes);

          return (
            <LegacyModal
              className="mt-4"
              closeOnClickAway={false}
              isOpen={isUploadMode}
              modalOverflowY="auto"
              onClose={handleCancel}
            >
              <div className={modalContentClassnames}>
                {hasDocumentsInQueue && <p className="mb-3 font-bold">Upload another document</p>}
                <DocumentDropZone
                  className="h-full"
                  dropZoneClassName={cx({ 'h-full': !hasDocumentsInQueue })}
                  onError={addError}
                  onFinish={handleFinish}
                  onProgress={handleProgress}
                  onStart={handleStart}
                />
                <DocumentUploader
                  documents={state.documentsInQueue}
                  documentTypes={documentTypes}
                  onCancel={handleCancel}
                  onSubmit={handleAdd}
                />
              </div>
            </LegacyModal>
          );
        }}
      </QueryHandler>
    </>
  );
};

export default DocumentActions;
