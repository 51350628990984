import type { ChangeEvent, FC, FocusEvent } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { NumberInput } from '@zen/DesignSystem';
import { convertStringToNumber } from '@zen/utils/utils';

import FormField, { FormFieldProps } from '../FormField';

type InputStepType = number | string;

interface Props extends FormFieldProps {
  disabled?: boolean;
  hasSuffix?: boolean;
  max?: number;
  min?: number;
  onBlur?: (event: FocusEvent<HTMLInputElement>, name: string, form: FormInstance<unknown>) => void;
  onChange?: (value: number, name: string, form: FormInstance<unknown>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>, name: string, form: FormInstance<unknown>) => void;
  step?: InputStepType;
}

const FormNumberInput: FC<Props> = ({ onBlur, onFocus, onChange, step = 0.01, ...rest }: Props) => (
  <FormField {...rest}>
    {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type. */}
    {(field, form) => {
      const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        field.onBlur(e);

        if (onBlur) {
          onBlur(e, field.name, form);
        }
      };

      const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
          onFocus(e, field.name, form);
        }
      };

      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const numValue = convertStringToNumber(e.currentTarget.value);

        form.setFieldValue(field.name, numValue);
        if (onChange) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
          onChange(numValue, field.name, form);
        }
      };

      return (
        <NumberInput
          {...field}
          id={field.name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          step={step}
          type="number"
        />
      );
    }}
  </FormField>
);

export default FormNumberInput;
