import type { FC, ReactNode } from 'react';

import ActivityFeedDates from '@zen/ActivityFeed/components/ActivityFeedDates';
import ActivityFeedItemDetails from '@zen/ActivityFeed/components/ActivityFeedItemDetails';
import ActivityFeedItemWrapper from '@zen/ActivityFeed/components/ActivityFeedItemWrapper';
import type { Activity, CargoReadyDateItemType, PurchaseOrderCargoReadyDatesMetadataType } from '@zen/ActivityFeed/types';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { Icon } from '@zen/DesignSystem';

export interface Props {
  activityMetadata: Activity;
  cargoReadyDateItem: CargoReadyDateItemType;
}

const CargoReadyDateUpdateItem: FC<Props> = ({ activityMetadata, cargoReadyDateItem }) => {
  if (!cargoReadyDateItem?.lotsInitialCargoReadyDate) {
    return null;
  }

  const { date, sameForAllLots } = cargoReadyDateItem.lotsInitialCargoReadyDate;
  const { createdAt, metadata, user } = activityMetadata;
  const { additionalInformation, confidenceLevel, cargoReadyDate, reasonCategory } =
    metadata as PurchaseOrderCargoReadyDatesMetadataType;
  const text: string = `Additional information: ${additionalInformation || '-'}`;

  const subtitle: ReactNode = (
    <p>
      <span className="font-bold">Reason for delay: </span>
      <ValueWithDefault value={reasonCategory} />
    </p>
  );

  return (
    <ActivityFeedItemWrapper className="flex justify-between">
      <ActivityFeedItemDetails
        additionalText={text}
        createdAt={createdAt}
        icon={<Icon className="text-orange-base" icon="zicon-warning-triangle" />}
        subtitle={subtitle}
        title="Cargo ready date updated"
        user={user}
      />
      <ActivityFeedDates
        actualDate={cargoReadyDate}
        confidenceLevel={confidenceLevel}
        dateLabel="Cargo ready date"
        hasInitialDateForAll={sameForAllLots}
        initialDate={date}
      />
    </ActivityFeedItemWrapper>
  );
};

export default CargoReadyDateUpdateItem;
