import cx from 'classnames';
import type { FC } from 'react';

import { Tooltip } from '@zen/DesignSystem';
import { RoadTrackingStatus } from '@zen/Shipment';
import type { Optional } from '@zen/utils/typescript';

const statusLabelMapping: Record<RoadTrackingStatus, string> = {
  [RoadTrackingStatus.TRACKING_NOT_LIVE]: 'This shipment cannot yet be tracked',
  [RoadTrackingStatus.TRACKING_FAILED]: 'Tracking failed',
  [RoadTrackingStatus.CURRENTLY_BEING_TRACKED]: 'This shipment is tracked',
  [RoadTrackingStatus.TRACKING_COMPLETED]: 'This shipment is complete'
};

interface Props {
  className?: string;
  isTrackable: boolean;
  status: Optional<RoadTrackingStatus>;
}

const ShipmentTrackingIndicator: FC<Props> = ({ className, isTrackable, status }) => {
  const classNames: string = cx(
    {
      'bg-grey-light border-grey-base': !isTrackable,
      'bg-orange-base border-orange-dark': isTrackable && (status === RoadTrackingStatus.TRACKING_NOT_LIVE || !status),
      'bg-red-base border-red-dark': isTrackable && status === RoadTrackingStatus.TRACKING_FAILED,
      'bg-green-base border-green-dark': isTrackable && status === RoadTrackingStatus.CURRENTLY_BEING_TRACKED,
      'bg-azure-light border-azure-base': isTrackable && status === RoadTrackingStatus.TRACKING_COMPLETED
    },
    'w-3 h-3 rounded-full border-solid border font-normal',
    className
  );

  const getTooltipContent = (): string => {
    if (!isTrackable) {
      return 'This shipment is not available to be tracked';
    }

    return status ? statusLabelMapping[status] : 'This shipment cannot yet be tracked';
  };

  return (
    <Tooltip placement="top-start" tooltipContent={getTooltipContent()}>
      <div className={classNames} data-testid="tracking-indicator" />
    </Tooltip>
  );
};

export default ShipmentTrackingIndicator;
