import type { FC } from 'react';

import { Pill } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type { PillConfig } from './types';

interface Props {
  dateOfRelease?: Optional<string>;
}

const getPillConfig = (isReleased: boolean): PillConfig => {
  if (isReleased) {
    return {
      type: 'success',
      label: 'Released',
      tooltip: 'The space for this booking has been released to the supplier'
    };
  }

  return {
    type: 'problem',
    label: 'Not released',
    tooltip: 'The space for this booking has not been released to the supplier'
  };
};

const ShippingOrderReleasePill: FC<Props> = ({ dateOfRelease }) => {
  const isReleased: boolean = !!dateOfRelease;

  const { label, tooltip, type }: PillConfig = getPillConfig(isReleased);

  return (
    <Pill tooltip={tooltip} type={type}>
      {label}
    </Pill>
  );
};

export default ShippingOrderReleasePill;
