import cx from 'classnames';
import { get } from 'lodash';
import pDebounce from 'p-debounce';
import type { FC, ReactNode } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import FormAsyncSelect from '@zen/Components/Form/FormAsyncSelect';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import { useForm } from '@zen/Components/Form/useForm';
import { highlightQuery } from '@zen/Components/Form/utils';
import type { AssignmentTargetTypeEnum, AssignmentTypeValue, NetworksOrgLocOption } from '@zen/Networks/types';
import { SEARCH_DEBOUNCE_DELAY } from '@zen/utils/constants';
import useAccount from '@zen/utils/hooks/useAccount';
import type { Nullable } from '@zen/utils/typescript';

import NetworksContactAddButton from '../NetworksContactAddButton/NetworksContactAddButton';
import NetworksContactEditButton from '../NetworksContactEditButton';
import { getOrgLocLabel, handleChangeCallback, IHandleChangeCallback } from '../networksContactHelper';

interface Props extends FormFieldProps {
  accountUuid: string;
  assignmentType?: AssignmentTypeValue;
  autoFocus?: boolean;
  className?: string;
  domainName?: AssignmentTargetTypeEnum;
  formTitle?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isEditable?: boolean;
  isRequired?: boolean;
  label: string;
  name: string;
  onSelect?: (name: string, value: Nullable<NetworksOrgLocOption>) => void;
  showAddButton?: boolean;
}

const NetworksContactFormInput: FC<Props> = (props) => {
  const {
    accountUuid,
    autoFocus,
    className,
    label,
    name,
    formTitle = 'Create new contact',
    isDisabled = false,
    isRequired,
    isEditable = false,
    domainName,
    assignmentType,
    onSelect,
    isClearable = true,
    showAddButton = true
  } = props;

  const { check } = useGlobalPermissions();
  const { setFieldValue, values } = useForm();
  const {
    userProfile: { division }
  } = useAccount();

  const accountId: string = division ? division.parentAccountId : accountUuid;
  const canManageNetworks: boolean = check('networks.canManage');
  const organisationId: string = get(values, `${name}.organisation.id`);
  const locationId: string = get(values, `${name}.id`);

  const handleInputChange: IHandleChangeCallback = handleChangeCallback({
    accountUuid: accountId,
    domainName,
    assignmentType
  });

  const handleAdd = (orgLoc: NetworksOrgLocOption): void => {
    setFieldValue(name, orgLoc);
  };

  const handleEdit = (orgLoc: NetworksOrgLocOption): void => {
    setFieldValue(name, orgLoc);
  };

  const debouncedHandleInputChange = pDebounce(handleInputChange, SEARCH_DEBOUNCE_DELAY);
  const showAddContactButton: boolean = canManageNetworks && showAddButton && !!accountUuid;
  const showEditContactButton: boolean = isEditable && !!organisationId && !!locationId;

  const wrapperClassNames: string = cx('flex justify-between items-top', className);
  const classNames: string = cx('flex-1', { 'pr-4': canManageNetworks && showAddButton });

  const formatOptionLabel = (organisationLocation: NetworksOrgLocOption, inputValue: string): ReactNode =>
    highlightQuery(getOrgLocLabel(organisationLocation), inputValue);

  return (
    <div className={wrapperClassNames}>
      <div className={classNames}>
        <FormAsyncSelect<NetworksOrgLocOption>
          autoFocus={autoFocus}
          formatOptionLabel={formatOptionLabel}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isRequired={isRequired}
          label={label}
          loadOptions={debouncedHandleInputChange}
          name={name}
          onSelect={onSelect}
        />
      </div>
      {showEditContactButton && (
        <NetworksContactEditButton locationId={locationId} onEdit={handleEdit} organisationId={organisationId} />
      )}
      {showAddContactButton && (
        <NetworksContactAddButton accountUuid={accountId} className="mt-6" handleAdd={handleAdd} title={formTitle} />
      )}
    </div>
  );
};

export default NetworksContactFormInput;
