import type { DialogProps } from '@zen/DesignSystem';
import type { AuthorizationResult, Invitation, NetworksLocationInContact } from '@zen/Networks/types';
import type { NetworksRoutes } from '@zen/Routes';
import type { Nullable } from '@zen/utils/typescript';

export { ManagerTypeEnum } from '@zen/graphql/types.generated';
export { CountryCode, InvitationStatus } from '@zen/Networks/types';

export enum ContactRemoveLevelType {
  LOCATION = 'location',
  ORGANISATION = 'organisation'
}

export type ContactTableDataType = {
  asMainContact?: boolean;
  canSendResetPasswordInstructions?: AuthorizationResult;
  email: string;
  firstName: string;
  id: string;
  invitation?: Nullable<Invitation>;
  lastName: string;
  locations?: Array<Partial<NetworksLocationInContact>>;
  main?: boolean;
  phoneNumber?: Nullable<string>;
};

export type ModalConfiguration = Pick<DialogProps, 'confirmLabel' | 'cancelLabel' | 'header' | 'onConfirm' | 'message'>;

export interface ContactTableDataInput {
  isMyOrganisationPage: boolean;
  locationId: Nullable<string>;
  onInvite: (contactId: string) => void;
  onRemove: (contactId: string) => void;
  onResendPassword: (contactId: string) => void;
  organisationId: string;
  removeType: ContactRemoveLevelType;
  routes: NetworksRoutes;
  throughLocationDetails: boolean;
}
