import type { FC, ReactNode } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { Role, useNetworksContext } from '@zen/Networks';
import ContactForm from '@zen/Networks/Form/ContactForm';
import {
  useNetworksDeleteLocationContactsMutation,
  useNetworksUpdateLocationContactMutation,
  useOrganisationContactQuery,
  useOrganisationDetailsQuery
} from '@zen/Networks/graphql';
import { ContactFormFields, InvitationStatus, UpdateLocationContactInput } from '@zen/Networks/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { prepareContactData, prepareContactLocationToEdit, prepareLocationsData } from '../contactsHelpers';
import ContactTitle from '../ContactTitle';

type LocationState = {
  fromMyOrganisationPage?: boolean;
  locationId?: string;
  throughContactDetails?: boolean;
  throughLocationDetails?: boolean;
};

type RouteParams = {
  contactId: string;
  organisationId: string;
};

interface Props {
  networkId: string;
}

export const EditContact: FC<Props> = ({ networkId }) => {
  const { contactId, organisationId } = useParams<RouteParams>();
  const { state } = useLocation<LocationState>();
  const history = useHistory();
  const isFromMyOrganisationPage: boolean = !!state.fromMyOrganisationPage;
  const throughContactDetails: boolean = !!state.throughContactDetails;
  const throughLocationDetails: boolean = !!state.throughLocationDetails;
  const locationId: string | undefined = state?.locationId;
  const { addError, addSuccess } = useNotification();
  const { routes } = useNetworksContext();

  const {
    data: contactData,
    loading: isContactLoading,
    error: contactErrors
  } = useOrganisationContactQuery({
    variables: {
      organisationId,
      contactId
    },
    fetchPolicy: 'no-cache'
  });

  const { data, loading, error } = useOrganisationDetailsQuery({
    variables: {
      accountUuid: networkId,
      id: organisationId
    }
  });

  const [updateContact] = useNetworksUpdateLocationContactMutation();
  const [removeContactAssignment] = useNetworksDeleteLocationContactsMutation();

  const firstOrganisation = data?.network?.organisations?.nodes?.[0];
  const organisationName = firstOrganisation?.name || '';
  const myOrganisationIndex: string = routes.myOrganisation.details.getUrl();
  const isCustomerOwnOrganisation = firstOrganisation?.assignedRoles?.includes(Role.CUSTOMER_USER) || false;

  const loadPreviousPage = (): { pathname: string; state?: LocationState } => {
    if (throughContactDetails) {
      return {
        pathname: routes.contact.details.getUrl(organisationId, contactId),
        state: { fromMyOrganisationPage: !!isFromMyOrganisationPage }
      };
    }

    if (throughLocationDetails && locationId) {
      return {
        pathname: routes.location.details.getUrl(organisationId, locationId),
        state: { fromMyOrganisationPage: !!isFromMyOrganisationPage }
      };
    }

    return {
      pathname: isFromMyOrganisationPage ? myOrganisationIndex : routes.organisation.details.getUrl(organisationId)
    };
  };

  const pageTitle: ReactNode = (
    <ContactTitle
      editMode={true}
      locationId={locationId || null}
      organisationId={organisationId}
      organisationName={organisationName}
    />
  );

  return (
    <Page browserTitle="Edit contact" defaultBackUrl={myOrganisationIndex} title={pageTitle} width="narrow">
      <QueryHandler data={contactData?.locationContact} error={!!contactErrors} isLoading={isContactLoading}>
        {(contact) => {
          return (
            <QueryHandler data={firstOrganisation} error={!!error} isLoading={loading}>
              {(organisationData) => {
                const { locations } = organisationData;
                const invitationStatus = contact?.invitation?.status;

                const initialValues = {
                  firstName: contact.firstName || '',
                  lastName: contact.lastName || '',
                  email: contact.email || '',
                  phoneNumber: contact.phoneNumber || '',
                  contactLocationAssignments: prepareContactLocationToEdit(contact.locations),
                  autoSubscribeToOrganisationTargets: contact.autoSubscribeToOrganisationTargets || false
                };

                const handleSubmit = async (values: ContactFormFields): Promise<IOkOrErrorResult> => {
                  const submitData = prepareContactData(values, organisationId, contactId) as UpdateLocationContactInput;

                  const { ok: updateData, error: updateError } = await performFormMutation({
                    mutationFn: () =>
                      updateContact({
                        variables: {
                          input: {
                            ...submitData
                          }
                        }
                      }),
                    onError: addError
                  });

                  if (values.locationAssignmentsToRemove && values.locationAssignmentsToRemove.length) {
                    const { ok: assignmentData, error: assignmentError } = await performFormMutation({
                      mutationFn: () =>
                        removeContactAssignment({
                          variables: {
                            input: {
                              locationIds: values.locationAssignmentsToRemove || [],
                              contactIds: [contactId]
                            }
                          }
                        }),
                      onError: addError
                    });

                    return Promise.resolve({
                      ok: assignmentData,
                      error: assignmentError || updateError
                    });
                  }

                  return Promise.resolve({
                    ok: updateData,
                    error: contactErrors
                  });
                };

                const handleSuccess = async () => {
                  addSuccess('Contact had been updated.');

                  history.push(loadPreviousPage());
                };

                const handleCancel = (): void => history.push(loadPreviousPage());

                return (
                  <ContactForm
                    initialValues={initialValues}
                    isCustomerOwnOrganisation={isCustomerOwnOrganisation}
                    isEmailDisabled={invitationStatus === InvitationStatus.PENDING}
                    locationId={locationId}
                    locationsList={prepareLocationsData(locations)}
                    onCancel={handleCancel}
                    onSubmit={handleSubmit}
                    onSuccess={handleSuccess}
                  />
                );
              }}
            </QueryHandler>
          );
        }}
      </QueryHandler>
    </Page>
  );
};

export default EditContact;
