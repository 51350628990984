import type { RoutesConfig } from './types';

const TRACKED_SHIPMENTS_ROUTE_PREFIX = '/dashboard/tracked-shipments';

interface TrackedShipmentRoutes extends RoutesConfig {
  trackedShipmentDetails: {
    getUrl: (zencargoReference: string) => string;
    path: string;
  };
  trackedShipmentIndex: {
    getUrl: () => string;
    path: string;
  };
}

const trackedShipmentRoutes: TrackedShipmentRoutes = {
  trackedShipmentDetails: {
    path: `${TRACKED_SHIPMENTS_ROUTE_PREFIX}/details/:id`,
    getUrl: (zencargoReference: string) => {
      return `${TRACKED_SHIPMENTS_ROUTE_PREFIX}/details/${zencargoReference}`;
    }
  },
  trackedShipmentIndex: {
    path: TRACKED_SHIPMENTS_ROUTE_PREFIX,
    getUrl: () => TRACKED_SHIPMENTS_ROUTE_PREFIX
  }
};

export default trackedShipmentRoutes;
