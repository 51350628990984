import type { FiltersType } from '@zen/OperationsDashboard/context/OperationsContextProvider/OperationsContextProvider';
import type { NetworksOrgLoc } from '@zen/Shipments';
import {
  getFilterOptionArray,
  getIssuesFilterValue,
  getShipmentStatus,
  getWithinTimeRange
} from '@zen/Shipments/Filters/helpers';
import type { Optional } from '@zen/utils/typescript';

import type { OperationsShipmentsFiltersType } from '../../types';
import type { OperationsShipmentFilterVariables } from './types';

export const getNetworksFilterOptionArray = (shipmentFilters: Optional<NetworksOrgLoc[]>): string[] | undefined => {
  if (shipmentFilters && shipmentFilters.length > 0) {
    return shipmentFilters.map(({ id }) => id || '');
  }

  return undefined;
};

export const prepareFilterVariables = (
  filters: FiltersType<OperationsShipmentsFiltersType>
): OperationsShipmentFilterVariables => {
  return {
    accountIds: getFilterOptionArray(filters.accountIds),
    bookingManagerIds: getFilterOptionArray(filters.bookingCoordinatorIds),
    customsOnly: getFilterOptionArray(filters.customsOnly),
    transportModes: getFilterOptionArray(filters.transportModes),
    ...getShipmentStatus(filters.status),
    origins: getNetworksFilterOptionArray(filters.origins),
    destinations: getNetworksFilterOptionArray(filters.destinations),
    withinTimeRange: getWithinTimeRange({
      withinTimeDays: filters.withinTimeDays,
      withinTimeStage: filters.withinTimeStage,
      withinTimeRange: filters.withinTimeRange,
      withinTimeDate: filters.withinTimeDate
    }),
    issues: getIssuesFilterValue(filters.issueTitle)
  };
};
