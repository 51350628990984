import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderDetailsQueryVariables = GraphQLTypes.Exact<{
  orderId: GraphQLTypes.Scalars['String'];
}>;

export type OrderDetailsQueryResult = { __typename?: 'Query' } & {
  purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id' | 'orderDate' | 'orderReferenceNumber'> & {
              canClose: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canRemove: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewCustomer: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              customer?: GraphQLTypes.Maybe<{ __typename?: 'Customer' } & Pick<GraphQLTypes.Customer, 'name' | 'uuid'>>;
              forwarder?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
              >;
              issues?: GraphQLTypes.Maybe<
                Array<{ __typename?: 'PurchaseOrderIssue' } & Pick<GraphQLTypes.PurchaseOrderIssue, 'id' | 'state'>>
              >;
            }
        >
      >
    >;
  };
};

export const OrderDetailsDocument = /* #__PURE__ */ gql`
  query orderDetails($orderId: String!) {
    purchaseOrders(id: $orderId) {
      nodes {
        canClose {
          value
        }
        canRemove {
          value
        }
        canUpdate {
          value
        }
        canViewCustomer {
          value
        }
        customer {
          name
          uuid
        }
        forwarder {
          id
          label {
            short
          }
        }
        id
        issues {
          id
          state
        }
        orderDate
        orderReferenceNumber
      }
    }
  }
`;

/**
 * __useOrderDetailsQuery__
 *
 * To run a query within a React component, call `useOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<OrderDetailsQueryResult, OrderDetailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderDetailsQueryResult, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
}
export function useOrderDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailsQueryResult, OrderDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderDetailsQueryResult, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
}
export type OrderDetailsQueryHookResult = ReturnType<typeof useOrderDetailsQuery>;
export type OrderDetailsLazyQueryHookResult = ReturnType<typeof useOrderDetailsLazyQuery>;
