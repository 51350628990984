import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IntakePlanningSkuOverviewQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  dateRange: GraphQLTypes.DateRangeInput;
  destinationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type IntakePlanningSkuOverviewQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & {
      intakePlanningSkuOverview: { __typename?: 'SkuOverviewType' } & Pick<
        GraphQLTypes.SkuOverviewType,
        'onTimeInFull' | 'overFulfilled' | 'partialFulfilment' | 'zeroStock'
      >;
    }
  >;
};

export const IntakePlanningSkuOverviewDocument = /* #__PURE__ */ gql`
  query intakePlanningSkuOverview($accountUuid: String!, $dateRange: DateRangeInput!, $destinationId: String) {
    account(uuid: $accountUuid) {
      intakePlanningSkuOverview(dateRange: $dateRange, destinationId: $destinationId) {
        onTimeInFull
        overFulfilled
        partialFulfilment
        zeroStock
      }
    }
  }
`;

/**
 * __useIntakePlanningSkuOverviewQuery__
 *
 * To run a query within a React component, call `useIntakePlanningSkuOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntakePlanningSkuOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntakePlanningSkuOverviewQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      dateRange: // value for 'dateRange'
 *      destinationId: // value for 'destinationId'
 *   },
 * });
 */
export function useIntakePlanningSkuOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<IntakePlanningSkuOverviewQueryResult, IntakePlanningSkuOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<IntakePlanningSkuOverviewQueryResult, IntakePlanningSkuOverviewQueryVariables>(
    IntakePlanningSkuOverviewDocument,
    options
  );
}
export function useIntakePlanningSkuOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IntakePlanningSkuOverviewQueryResult, IntakePlanningSkuOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<IntakePlanningSkuOverviewQueryResult, IntakePlanningSkuOverviewQueryVariables>(
    IntakePlanningSkuOverviewDocument,
    options
  );
}
export type IntakePlanningSkuOverviewQueryHookResult = ReturnType<typeof useIntakePlanningSkuOverviewQuery>;
export type IntakePlanningSkuOverviewLazyQueryHookResult = ReturnType<typeof useIntakePlanningSkuOverviewLazyQuery>;
