import type { FC } from 'react';

import type { HeadlineLevel } from '@zen/DesignSystem';
import { Headline, IconButton } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  className?: string;
  icon?: IconName;
  level?: HeadlineLevel;
  onClick: () => void;
  title: string;
}

const HeadingWithAction: FC<Props> = ({ className = 'mb-6', icon = 'zicon-close', level = 2, onClick, title }) => {
  return (
    <div className={`flex justify-between items-center ${className}`}>
      <Headline level={level}>{title}</Headline>
      <IconButton icon={icon} onClick={onClick} variant="tertiary" />
    </div>
  );
};

export default HeadingWithAction;
