import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ConsolidationShipmentsQueryVariables = GraphQLTypes.Exact<{
  newCargoService: GraphQLTypes.Scalars['Boolean'];
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  customerUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  consolidatedShipmentId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type ConsolidationShipmentsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'clientReference' | 'zencargoReference'> & {
                calculatedInfo: { __typename?: 'CalculatedInfo' } & {
                  cargoSummary?: GraphQLTypes.Maybe<
                    { __typename?: 'CargoSummary' } & Pick<GraphQLTypes.CargoSummary, 'label' | 'type'>
                  >;
                  collectionInFull?: GraphQLTypes.Maybe<
                    { __typename?: 'InFull' } & {
                      dateTimeRange?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeRange' } & {
                          endDateTime?: GraphQLTypes.Maybe<
                            { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date'>
                          >;
                        }
                      >;
                    }
                  >;
                };
                cargo?: GraphQLTypes.Maybe<
                  { __typename?: 'Cargo' } & {
                    vehicle?: GraphQLTypes.Maybe<
                      { __typename?: 'CargoVehicleType' } & Pick<GraphQLTypes.CargoVehicleType, 'vehicleType'>
                    >;
                  }
                >;
                cargos?: GraphQLTypes.Maybe<Array<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'cargoType'>>>;
                networksDestination?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                >;
                networksOrigin?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                >;
                purchaseOrderReferences?: GraphQLTypes.Maybe<
                  { __typename?: 'PurchaseOrderReferences' } & Pick<GraphQLTypes.PurchaseOrderReferences, 'totalCount'> & {
                      nodes?: GraphQLTypes.Maybe<
                        Array<
                          { __typename?: 'PurchaseOrderReference' } & Pick<
                            GraphQLTypes.PurchaseOrderReference,
                            'orderReferenceNumber' | 'id'
                          >
                        >
                      >;
                    }
                >;
              }
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const ConsolidationShipmentsQueryDocument = /* #__PURE__ */ gql`
  query consolidationShipmentsQuery(
    $newCargoService: Boolean!
    $after: String
    $before: String
    $first: Int
    $customerUuid: String
    $consolidatedShipmentId: String
    $textContains: String
  ) {
    bookings(
      after: $after
      before: $before
      first: $first
      customerUuid: $customerUuid
      consolidatedShipmentId: $consolidatedShipmentId
      textContains: $textContains
    ) {
      nodes {
        calculatedInfo {
          cargoSummary {
            label
            type
          }
          collectionInFull {
            dateTimeRange {
              endDateTime {
                date
              }
            }
          }
        }
        cargo {
          vehicle {
            vehicleType
          }
        }
        cargos @include(if: $newCargoService) {
          cargoType
        }
        clientReference
        networksDestination {
          id
          label {
            long
          }
        }
        networksOrigin {
          id
          label {
            long
          }
        }
        purchaseOrderReferences(first: 10) {
          nodes {
            orderReferenceNumber
            id
          }
          totalCount
        }
        zencargoReference
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useConsolidationShipmentsQuery__
 *
 * To run a query within a React component, call `useConsolidationShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsolidationShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsolidationShipmentsQuery({
 *   variables: {
 *      newCargoService: // value for 'newCargoService'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      customerUuid: // value for 'customerUuid'
 *      consolidatedShipmentId: // value for 'consolidatedShipmentId'
 *      textContains: // value for 'textContains'
 *   },
 * });
 */
export function useConsolidationShipmentsQuery(
  baseOptions: Apollo.QueryHookOptions<ConsolidationShipmentsQueryResult, ConsolidationShipmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ConsolidationShipmentsQueryResult, ConsolidationShipmentsQueryVariables>(
    ConsolidationShipmentsQueryDocument,
    options
  );
}
export function useConsolidationShipmentsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConsolidationShipmentsQueryResult, ConsolidationShipmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ConsolidationShipmentsQueryResult, ConsolidationShipmentsQueryVariables>(
    ConsolidationShipmentsQueryDocument,
    options
  );
}
export type ConsolidationShipmentsQueryHookResult = ReturnType<typeof useConsolidationShipmentsQuery>;
export type ConsolidationShipmentsQueryLazyQueryHookResult = ReturnType<typeof useConsolidationShipmentsQueryLazyQuery>;
