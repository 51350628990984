import * as Yup from 'yup';

import type { NewCargoType } from '@zen/types';
import { cargoValues } from '@zen/types';
import type { Nullable } from '@zen/utils/typescript';

import { quantityValidator } from '../validation';

export const validationSchema = Yup.object().shape({
  looseCargoType: Yup.string().nullable().required('Cargo type is required.'),
  cargoType: Yup.string()
    .nullable()
    .when('looseCargoType', {
      is: (val: Nullable<NewCargoType>) => val === cargoValues.loosePallet,
      then: Yup.string().nullable().required('Pallet type is required.')
    }),
  dimensions: Yup.object().shape({
    length: Yup.number().nullable().positive('Must be greater than 0.'),
    width: Yup.number().nullable().positive('Must be greater than 0.'),
    height: Yup.number().nullable().positive('Must be greater than 0.')
  }),
  actualCbm: Yup.number().nullable().positive('Must be greater than 0.'),
  chargeableWeight: Yup.object().shape({
    value: Yup.number().nullable().positive('Must be greater than 0.')
  }),
  grossWeight: Yup.object().shape({
    value: Yup.number().nullable().positive('Must be greater than 0.')
  }),
  quantity: Yup.number().when('looseCargoType', {
    is: (val: Nullable<NewCargoType>) => val !== cargoValues.looseAssortedCargo,
    then: quantityValidator()
  })
});
