import { camelCase } from 'lodash';

import type { Nullable, Optional, Undefinable } from '@zen/utils/typescript';

import type { NewCargoType } from '../types/labels';
import { legacyNewTypesMapping } from './cargoDictionaryMapping.helper';
import type {
  CargoItem,
  CargoTransition,
  CargoWeight,
  CoreCargo,
  CoreCargoExtended,
  Dimensions,
  LegacyCargo,
  NewCargo,
  ValueOfGoods,
  Volume
} from './types';
import { CargoItemTypeEnum, LooseCargoTypeEnum, PalletTypeEnum } from './types';

const getNewCargoType = (newCargoItem: Optional<CoreCargo>): Nullable<NewCargoType> => {
  return newCargoItem?.cargoType ? (camelCase(newCargoItem?.cargoType) as NewCargoType) : null;
};

const transitionCargo = (legacyCargo: Optional<LegacyCargo>, newCargo?: Optional<CoreCargo[]>): Optional<CargoTransition> => {
  if (!legacyCargo && !newCargo) {
    return null;
  }

  const prepareNewCargo = (newCargoItem: CoreCargo): NewCargo => {
    const { cargoRelations, ...rest } = newCargoItem;
    const inputCargo: Optional<CoreCargo> = newCargoItem?.cargoRelations?.[0]?.inputCargo;

    return {
      ...rest,
      cargoType: getNewCargoType(newCargoItem),
      ...(newCargoItem?.cargoRelations?.length && {
        looseCargo: {
          ...inputCargo,
          id: inputCargo?.id || '',
          cargoType: getNewCargoType(inputCargo)
        }
      })
    };
  };

  const prepareNewCargos = (newCargos: CoreCargo[]): NewCargo[] => {
    return newCargos.map(prepareNewCargo);
  };

  if (!legacyCargo && newCargo?.length) {
    // need to cast for UI to rely only on NewCargo type, later will be removed only to CoreCargo
    return {
      cargoList: prepareNewCargos(newCargo)
    };
  }

  const cargoToTransition: NewCargo[] = legacyCargo?.cargoItems?.length
    ? legacyCargo?.cargoItems?.map((cargoItem: CargoItem) => {
        const newCargoMatch: Undefinable<CoreCargo> = newCargo?.find(
          (newCargoItem: CoreCargo) => newCargoItem.id === cargoItem?.id
        );
        const {
          canManageAssignedLots,
          canManageCollectionLocation,
          canManageDeliveryLocation,
          canUpdateVehicleTrailerId,
          canViewCollectionDetails,
          canViewDeliveryDetails,
          collection,
          delivery
        } = cargoItem;
        const mappedLegacyCargo: NewCargo = mapCargo(cargoItem);

        return {
          ...(newCargoMatch ? prepareNewCargo(newCargoMatch) : mappedLegacyCargo),
          ...(!newCargoMatch && { cargoType: mappedLegacyCargo.cargoType }),
          // rest of cargoItems fields which aren't currently supported in cargo micro-service
          ...(canManageAssignedLots && { canManageAssignedLots }),
          ...(canManageCollectionLocation && { canManageCollectionLocation }),
          ...(canManageDeliveryLocation && { canManageDeliveryLocation }),
          ...(canUpdateVehicleTrailerId && { canUpdateVehicleTrailerId }),
          ...(canViewCollectionDetails && { canViewCollectionDetails }),
          ...(canViewDeliveryDetails && { canViewDeliveryDetails }),
          ...(collection && { collection }),
          ...(delivery && { delivery })
        };
      })
    : [];

  return {
    cargoList: cargoToTransition,
    // legacy cargo fields that will be tackled before we fully switch to new cargo
    ...(legacyCargo?.mode && { mode: legacyCargo.mode }),
    ...(legacyCargo?.tracking && { tracking: legacyCargo.tracking })
  };
};

const mapCargo = (cargoItem: CargoItem): NewCargo => {
  const {
    cbm,
    chargeableWeight,
    containerNumber,
    containerSealNumber,
    dimensions,
    grossWeight,
    hazardous,
    id,
    looseCargoType,
    overweight,
    palletType,
    quantity,
    reefer,
    riskLevel,
    stackable,
    tailLift,
    trailerId,
    type,
    valueOfGoods
  } = cargoItem;

  const looseCargo: Optional<LooseCargoTypeEnum | PalletTypeEnum> =
    looseCargoType === LooseCargoTypeEnum.PALLETS && !!palletType ? cargoItem.palletType : cargoItem.looseCargoType;
  const cargoTypeMapping = {
    [CargoItemTypeEnum.CONTAINER]: cargoItem.containerType,
    [CargoItemTypeEnum.LOOSE_CARGO]: looseCargo,
    [CargoItemTypeEnum.VEHICLE]: cargoItem.vehicleType
  };

  const newCargoGrossWeight: Nullable<CargoWeight> =
    grossWeight?.value && grossWeight?.metric
      ? {
          value: grossWeight?.value,
          unit: grossWeight?.metric
        }
      : null;

  const newCargoDimensions: Nullable<Dimensions> =
    (dimensions?.width || dimensions?.height || dimensions?.length) && dimensions?.metric
      ? {
          width: dimensions?.width,
          height: dimensions?.height,
          length: dimensions?.length,
          unit: dimensions?.metric
        }
      : null;

  const newCargoChargeableWeight: Nullable<CargoWeight> =
    chargeableWeight?.value && chargeableWeight?.metric
      ? {
          value: chargeableWeight?.value,
          unit: chargeableWeight?.metric
        }
      : null;

  const newCargoValueOfGoods: Nullable<ValueOfGoods> =
    valueOfGoods?.value && valueOfGoods?.currency
      ? {
          value: valueOfGoods?.value,
          currency: valueOfGoods?.currency
        }
      : null;

  const newCargoVolume: Nullable<Volume> = cbm
    ? {
        value: cbm,
        unit: 'CBM'
      }
    : null;

  const inputCargo: CoreCargoExtended = {
    cargoType: looseCargo ? legacyNewTypesMapping[looseCargo] : null,
    // in new cargo parent and child cargo will have different ids
    id,
    quantity
  };

  const canHaveChildCargo: boolean =
    (type === CargoItemTypeEnum.CONTAINER || type === CargoItemTypeEnum.VEHICLE) && !!looseCargoType;

  return {
    cargoType: type && cargoTypeMapping[type] ? legacyNewTypesMapping[cargoTypeMapping[type]!] : null,
    chargeableWeight: newCargoChargeableWeight,
    containerNumber,
    containerSealNumber,
    dimensions: newCargoDimensions,
    grossWeight: newCargoGrossWeight,
    hazardous,
    id,
    ...(canHaveChildCargo && { looseCargo: inputCargo }),
    overweight,
    quantity,
    refrigerated: reefer,
    riskLevel,
    stackable,
    tailLift,
    trailerId,
    valueOfGoods: newCargoValueOfGoods,
    volume: newCargoVolume
  };
};

export default transitionCargo;
