import type { FC, ReactNode } from 'react';

import { RoadTrackedShipmentStatus } from '@zen/TrackedShipments/types';
import type { Optional } from '@zen/utils/typescript';

import Filter from './Filter';
import type { StatusCount } from './types';

interface Props {
  activeStatus: RoadTrackedShipmentStatus;
  onChange: (newStatus: RoadTrackedShipmentStatus) => void;
  statusCounts: StatusCount[];
}

const StatusFilters: FC<Props> = ({ activeStatus, onChange, statusCounts }) => {
  const renderOneFilter = (status: RoadTrackedShipmentStatus): ReactNode => {
    const statusCount: Optional<StatusCount> = statusCounts.find((val) => val.status === status);

    const isActive: boolean = statusCount?.status === activeStatus;

    return <Filter count={statusCount?.count} isActive={isActive} onClick={onChange} status={status} />;
  };

  return (
    <div className="grid grid-cols-1 mr-6 gap-8" data-testid="status-filters">
      {renderOneFilter(RoadTrackedShipmentStatus.ON_TIME)}
      {renderOneFilter(RoadTrackedShipmentStatus.LATE)}
      {renderOneFilter(RoadTrackedShipmentStatus.EARLY)}
    </div>
  );
};

export default StatusFilters;
