import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import InformationIcon from '@zen/Components/InformationIcon';

interface Props {
  className?: string;
  infoMessage?: string;
  title: string | ReactNode;
  value: string;
}

const ShipmentDetailsBoardItem: FC<Props> = ({ title, value, className, infoMessage }) => {
  const testId: string =
    typeof title === 'string' ? `details-board-${title.replace(/\s+/g, '-').toLowerCase()}` : 'details-board-item';

  return (
    <div className={cx('flex flex-col', className)} data-testid={testId}>
      <span className="mb-2 text-grey-base">
        {title}
        {infoMessage && <InformationIcon className="ml-2" tooltipContent={infoMessage} />}
      </span>
      {value}
    </div>
  );
};

export default ShipmentDetailsBoardItem;
