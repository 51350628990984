import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInput, FormInstance } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { getAccountStatusControlFormConfiguration } from '../helpers';
import type { AccountStatusControlFormValues, AccountStatusControlType } from '../types';
import { accountStatusControlFormValidation } from './accountStatusControlForm.validation';

interface Props {
  accountReference: string;
  configurationType: AccountStatusControlType;
  initialValues?: AccountStatusControlFormValues;
  onCancel: () => void;
  onSubmit: () => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const AccountStatusControlForm: FC<Props> = (props) => {
  const { accountReference, initialValues = { confirmationText: '' }, onCancel, onSubmit, onSuccess, configurationType } = props;

  const { formDescription, confirmationText } = getAccountStatusControlFormConfiguration(configurationType, accountReference);

  const renderFormButtons = ({ isSubmitting }: FormInstance<AccountStatusControlFormValues>): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Confirm">
        <Button onClick={onCancel} variant="tertiary">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={accountStatusControlFormValidation(confirmationText)}
    >
      {() => (
        <>
          <p>{formDescription}</p>
          <FormInput className="mt-6" hideLabel={true} isRequired={true} name="confirmationText" placeholder={confirmationText} />
        </>
      )}
    </Form>
  );
};

export default AccountStatusControlForm;
