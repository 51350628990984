import type { FC, ReactNode } from 'react';

import { Form, FormInput, FormInstance } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { BillingDetails } from '@zen/Settings/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { validationSchema } from './billingDetailsForm.validation';

interface Props {
  initialValues: BillingDetails;
  onSubmit: (values: BillingDetails) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const emptyBillingDetails: BillingDetails = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  street: '',
  country: '',
  city: '',
  postalCode: '',
  state: ''
};

const BillingDetailsForm: FC<Props> = ({ initialValues, onSubmit, onSuccess }) => {
  const renderFields = (): ReactNode => (
    <>
      <FormInput className="max-w-sm" name="firstName" placeholder="Add first name" />
      <FormInput className="max-w-sm" name="lastName" placeholder="Add last name" />
      <FormInput className="max-w-sm" name="email" placeholder="Add email" />
      <FormInput className="max-w-sm" name="phone" placeholder="Add phone" />
      <FormInput className="max-w-lg" name="street" placeholder="Add street" />
      <FormInput className="max-w-lg" name="city" placeholder="Add city" />
      <FormInput className="max-w-lg" name="state" placeholder="Add state" />
      <FormInput className="max-w-sm" name="country" placeholder="Add country" />
      <FormInput className="max-w-xs" name="postalCode" placeholder="Add postal code" />
    </>
  );

  const renderFormButtons = ({ dirty, isSubmitting }: FormInstance<BillingDetails>): ReactNode => {
    const isDisabled: boolean = !dirty || isSubmitting;

    return (
      <Button disabled={isDisabled} type="submit" variant="primary">
        Save
      </Button>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues || emptyBillingDetails}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {renderFields}
    </Form>
  );
};

export default BillingDetailsForm;
