import type { FC, ReactNode } from 'react';

import ActionItemDetails from '../../components/ActionItemDetails';
import ActivityFeedItemWrapper from '../../components/ActivityFeedItemWrapper/index';
import { ActionItem, ActionItemActionType, Activity } from '../../types';
import ActionItemApproval from './ActionItemApproval';
import ActionItemFileUpload from './ActionItemFileupload';

interface Props {
  actionItem: ActionItem;
  activityMetadata: Activity;
}

const ActionItemRequestedItem: FC<Props> = ({ activityMetadata, actionItem }) => {
  const { createdAt, itemType, targetId } = activityMetadata;
  const { type } = actionItem;

  const getComponent = (): ReactNode => {
    switch (type) {
      case ActionItemActionType.APPROVAL:
        return (
          <ActionItemApproval
            actionItem={actionItem}
            actionItemType={itemType}
            createdAt={createdAt}
            zencargoReference={targetId}
          />
        );
      case ActionItemActionType.FILEUPLOAD:
        return (
          <ActionItemFileUpload
            actionItem={actionItem}
            actionItemType={itemType}
            createdAt={createdAt}
            zencargoReference={targetId}
          />
        );
      case ActionItemActionType.TEXTINPUT:
        return <ActionItemDetails actionItem={actionItem} actionItemType={itemType} createdAt={createdAt} />;
    }
  };

  return <ActivityFeedItemWrapper>{getComponent()}</ActivityFeedItemWrapper>;
};

export default ActionItemRequestedItem;
