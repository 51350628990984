import type { RoutesConfig } from './types';

export const NEW_BOOKING_ROUTE_PREFIX = '/dashboard/new-booking';

interface NewbookingRoutes extends RoutesConfig {
  newBookingFromZencargoReference: {
    getUrl: (zencargoReference: string) => string;
    path: string;
  };
  newBookingIndex: {
    getUrl: () => string;
    path: string;
  };
}

const newBookingRoutes: NewbookingRoutes = {
  newBookingIndex: {
    path: NEW_BOOKING_ROUTE_PREFIX,
    getUrl: () => NEW_BOOKING_ROUTE_PREFIX
  },
  newBookingFromZencargoReference: {
    path: `${NEW_BOOKING_ROUTE_PREFIX}/:fromZencargoReference?`,
    getUrl: (zencargoReference: string) => `${NEW_BOOKING_ROUTE_PREFIX}/${zencargoReference}`
  }
};

export default newBookingRoutes;
