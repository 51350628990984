import type { FC, ReactNode } from 'react';

import { CargoModeEnum } from '@zen/Cargo';
import NewFullContainer from '@zen/Cargo/NewFullContainer';
import NewLooseCargo from '@zen/Cargo/NewLooseCargo';
import NewVehicle from '@zen/Cargo/NewVehicle';
import type { ModeOfTransport } from '@zen/Shipment';

interface Props {
  cargoMode: CargoModeEnum;
  hasCargo?: boolean;
  modeOfTransport: ModeOfTransport;
  onSuccess: () => void;
  zencargoReference: string;
}

const NewCargoItem: FC<Props> = (props) => {
  const { cargoMode, modeOfTransport, hasCargo, onSuccess, zencargoReference } = props;

  const renderComponent = (): ReactNode => {
    switch (cargoMode) {
      case CargoModeEnum.FCL:
        return <NewFullContainer onSuccess={onSuccess} zencargoReference={zencargoReference} />;
      case CargoModeEnum.FTL:
        return !hasCargo ? <NewVehicle onSuccess={onSuccess} zencargoReference={zencargoReference} /> : null;
      case CargoModeEnum.LOOSE:
        return <NewLooseCargo modeOfTransport={modeOfTransport} onSuccess={onSuccess} zencargoReference={zencargoReference} />;
    }
  };

  return <div data-testid="new-cargo-item">{renderComponent()}</div>;
};

export default NewCargoItem;
