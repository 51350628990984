import { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { scroller } from 'react-scroll';

import { Form, FormButtons, FormInstance } from '@zen/Components/Form';
import { STICKY_HEADER_HEIGHT } from '@zen/Components/Page/Page';
import { Button, Dialog } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional } from '@zen/utils/typescript';

import type { FormSections, RoadShipmentFields } from '../types';
import CargoFields from './CargoFields';
import CollectionAndDeliveryDateFields from './CollectionAndDeliveryDateFields';
import CollectionAndDeliveryFields from './CollectionAndDeliveryFields';
import PartiesAndTermsFields from './PartiesAndTermsFields';
import { createValidationSchema } from './RoadShipmentForm.validation';
import RouteFields from './RouteFields';
import Section from './Section';
import SpecialInstructionFields from './SpecialInstructionFields';

interface Props {
  initialValues?: RoadShipmentFields;
  onCancel: () => void;
  onSubmit: (values: RoadShipmentFields) => Promise<IOkOrErrorResult>;
  onSuccess: (redirectToVAs: boolean, values: RoadShipmentFields) => void;
}

export const LEFT_MARGIN = 'ml-60';

const RoadShipmentForm: FC<Props> = ({ initialValues, onCancel, onSubmit, onSuccess }) => {
  const { state } = useLocation<{ autoFocusedField?: string; autoFocusedSection?: FormSections }>();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [redirectToVAs, setredirectToVAs] = useState<boolean>(false);
  const autoFocusedField: Optional<string> = state?.autoFocusedField;
  const autoFocusedSection: Optional<FormSections> = state?.autoFocusedSection;

  useEffect(() => {
    if (autoFocusedSection) {
      scroller.scrollTo(autoFocusedSection, { offset: -150 });
    }
  }, [autoFocusedSection]);

  const renderFormButtons = ({ isSubmitting, submitForm }: FormInstance<RoadShipmentFields>): ReactNode => (
    <div className="py-2 bg-white fixed bottom-0 z-20 left-0 right-0">
      <FormButtons
        className="ml-4"
        containerClassName={`flex-row max-w-screen-xl py-3 ${LEFT_MARGIN} pl-6`}
        isSubmitting={isSubmitting}
        text="Save edits"
      >
        <Button
          className="mr-4"
          data-testid="save-and-v-a-button"
          onClick={() => handleSubmitAndRedirect(submitForm)}
          variant="secondary"
        >
          Save and do V&As
        </Button>
        <Button data-testid="cancel-button" onClick={onCancel} variant="tertiary">
          Cancel
        </Button>
      </FormButtons>
    </div>
  );

  const handleSuccess = (_data: {}, values: RoadShipmentFields): void => {
    onSuccess(redirectToVAs, values);
  };

  const handleSubmitAndRedirect = (submitForm: () => void): void => {
    setredirectToVAs(true);
    submitForm();
  };

  const handleSubmit = (values: RoadShipmentFields) => {
    if (document.getElementsByClassName('js-dateAndTimeRangeForm').length > 0) {
      setIsModalOpened(true);

      return Promise.resolve({ ok: null, error: true });
    }

    return onSubmit(values);
  };

  return (
    <div data-testid="road-shipment-form">
      <Form
        className="mb-8"
        enableReinitialize={true}
        formButtons={renderFormButtons}
        formName="EditRoadShipment"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        scrollToErrorOffset={STICKY_HEADER_HEIGHT}
        validationSchema={createValidationSchema()}
      >
        {({ isValid }): ReactNode => (
          <>
            <CollectionAndDeliveryDateFields />
            <Section title="Collection and delivery details">
              <CollectionAndDeliveryFields autoFocusedField={autoFocusedField} />
            </Section>
            <Section title="Cargo information">
              <CargoFields autoFocusedField={autoFocusedField} />
            </Section>
            <Section title="Route">
              <RouteFields autoFocusedField={autoFocusedField} />
            </Section>
            <Section title="Parties and terms">
              <PartiesAndTermsFields autoFocusedField={autoFocusedField} />
            </Section>
            <Section title="Special instructions">
              <SpecialInstructionFields autoFocusedField={autoFocusedField} />
            </Section>
          </>
        )}
      </Form>
      <Dialog
        confirmLabel="OK"
        hasCancelButton={false}
        header="Cannot save form"
        isOpen={isModalOpened}
        message="Please ensure all collection and delivery sections are closed before attempting to save your edits."
        onClose={() => setIsModalOpened(false)}
        onConfirm={() => setIsModalOpened(false)}
      />
    </div>
  );
};

export default RoadShipmentForm;
