import type { RoutesConfig } from './types';

export const CONSOLIDATION_PREFIX = '/dashboard/consolidation';

interface ConsolidationRoutes extends RoutesConfig {
  consolidationAddShipments: {
    getUrl: (consolidationId: string) => string;
    path: string;
  };
  consolidationAddShipmentsDetailsPage: {
    getUrl: (consolidationId: string, zencargoReference: string) => string;
    path: string;
  };
  consolidationAddShipmentsOrderDetailsPage: {
    getUrl: (consolidationId: string, orderId: string, tab?: string) => string;
    path: string;
  };
  consolidationCreate: {
    getUrl: () => string;
    path: string;
  };
  consolidationDetails: {
    getUrl: (consolidationId: string) => string;
    path: string;
  };
  consolidationEdit: {
    getUrl: (consolidationId: string) => string;
    path: string;
  };
  consolidationIndex: {
    getUrl: () => string;
    path: string;
  };
  consolidationPage: {
    getUrl: (consolidationId: string) => string;
    path: string;
  };
  consolidationShipments: {
    getUrl: (consolidationId: string) => string;
    path: string;
  };
  consolidationShipmentsDetailsPage: {
    getUrl: (consolidationId: string, zencargoReference: string) => string;
    path: string;
  };
  consolidationShipmentsOrderDetailsPage: {
    getUrl: (consolidationId: string, orderId: string, tab?: string) => string;
    path: string;
  };
}

const consolidationRoutes: ConsolidationRoutes = {
  consolidationIndex: {
    path: CONSOLIDATION_PREFIX,
    getUrl: () => CONSOLIDATION_PREFIX
  },
  consolidationCreate: {
    path: `${CONSOLIDATION_PREFIX}/create`,
    getUrl: () => `${CONSOLIDATION_PREFIX}/create`
  },
  consolidationPage: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId`,
    getUrl: (consolidationId: string) => `${CONSOLIDATION_PREFIX}/${consolidationId}`
  },
  consolidationEdit: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/edit`,
    getUrl: (consolidationId: string) => `${CONSOLIDATION_PREFIX}/${consolidationId}/edit`
  },
  consolidationDetails: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/details`,
    getUrl: (consolidationId: string) => `${CONSOLIDATION_PREFIX}/${consolidationId}/details`
  },
  consolidationShipments: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments`,
    getUrl: (consolidationId: string) => `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments`
  },
  consolidationAddShipments: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/add`,
    getUrl: (consolidationId: string) => `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments/add`
  },
  consolidationShipmentsDetailsPage: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/details/:id`,
    getUrl: (consolidationId: string, zencargoReference: string) =>
      `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments/details/${zencargoReference}`
  },
  consolidationAddShipmentsDetailsPage: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/add/details/:id`,
    getUrl: (consolidationId: string, zencargoReference: string) =>
      `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments/add/details/${zencargoReference}`
  },
  consolidationShipmentsOrderDetailsPage: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/:id`,
    getUrl: (consolidationId: string, orderId: string, tab?: string) => {
      const url: string = `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments/${orderId}`;

      return `${url}/${tab || 'items'}`;
    }
  },
  consolidationAddShipmentsOrderDetailsPage: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/add/:id?`,
    getUrl: (consolidationId: string, orderId: string, tab?: string) => {
      const url: string = `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments/add/${orderId}`;

      return `${url}/${tab || 'items'}`;
    }
  }
};

export default consolidationRoutes;
