import { taskListRoutes, workflowsRoutes } from '@zen/Routes';
import accountsRoutes from '@zen/Routes/accounts';
import exchangeRatesRoutes from '@zen/Routes/exchangeRates';
import opsDashboardRoutes from '@zen/Routes/opsDashboard';
import opsSettingsRoutes from '@zen/Routes/opsSettings';
import productSettingsRoutes from '@zen/Routes/productSettings';
import rolesRoutes from '@zen/Routes/roles';

import type { SidebarLink } from '../types';

export const config: SidebarLink[] = [
  {
    icon: 'zicon-dashboard',
    label: 'Ops dashboard',
    url: opsDashboardRoutes.dashboardIndex.path
  },
  {
    icon: 'zicon-orders',
    label: 'Task list',
    url: taskListRoutes.taskListIndex.path,
    featureFlagName: 'workflows',
    counter: 'taskListCount'
  },
  {
    icon: 'zicon-quotes',
    label: 'Ops quotes',
    url: '/dashboard/ops-quotes'
  },
  {
    icon: 'zicon-shipment',
    label: 'Ops shipments',
    url: '',
    children: [
      {
        label: 'All shipments',
        url: '/dashboard/ops-shipments/all-shipments'
      },
      {
        label: 'Road shipments',
        url: '/dashboard/ops-shipments/road-shipments'
      }
    ]
  },
  {
    icon: 'zicon-adjust',
    label: 'Zencargo admin',
    url: '',
    children: [
      {
        label: 'All accounts',
        url: accountsRoutes.accountsIndex.path
      },
      {
        label: 'Zen network',
        url: '/dashboard/zencargo-network'
      },
      {
        label: 'Roles',
        url: rolesRoutes.index.getUrl(),
        featureFlagName: 'customRoles'
      },
      {
        label: 'Rules engine',
        url: '/dashboard/rules-engine'
      },
      {
        label: 'Operations settings',
        url: opsSettingsRoutes.opsSettingsIndex.path
      },
      {
        label: 'Product settings',
        url: productSettingsRoutes.productSettingsIndex.path
      },
      {
        label: 'Exchange rates',
        url: exchangeRatesRoutes.manageExchangeRatesIndex.path
      }
    ]
  },
  {
    icon: 'zicon-shipment',
    label: 'Workflows',
    url: '',
    featureFlagName: 'workflows',
    children: [
      {
        label: 'Flows',
        url: workflowsRoutes.workflowsFlows.path
      },
      {
        label: 'Triggers',
        url: workflowsRoutes.workflowsTriggers.path
      }
    ]
  }
];
