import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInput } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { Error } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

interface OrganisationResponse {
  id: string;
}

export interface EditOrganisationFormValues {
  name: string;
}

export interface IOrganisationFormResponse {
  errors: Error[];
  organisation: OrganisationResponse;
}

interface Props {
  initialValues: EditOrganisationFormValues;
  onCancel: () => void;
  onSubmit: (values: EditOrganisationFormValues) => Promise<IOkOrErrorResult>;
  onSuccess: (response: IOrganisationFormResponse) => void;
}

const EditOrganisationForm: FC<Props> = ({ initialValues, onCancel, onSubmit, onSuccess }) => {
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'isSubmitting' implicitly has an '... Remove this comment to see the full error message
  const renderFormButtons = ({ isSubmitting }): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} text="Save changes">
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      className="mt-4"
      formButtons={renderFormButtons}
      formName="EditOrganisationForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      {() => <FormInput isRequired={true} label="Organisation name" name="name" />}
    </Form>
  );
};

export default EditOrganisationForm;
