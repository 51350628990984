import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import NoResults, { ButtonConfig } from '@zen/Components/NoResults';
import { newBookingRoutes, orderRoutes } from '@zen/Routes';
import usePermissionSuppress from '@zen/utils/hooks/usePermissionSuppress';

const NoShipments: FC = () => {
  const { check } = useGlobalPermissions();
  const canCreateBooking: boolean = check('bookings.canCreate');
  const { suppressPermission: suppressCanCreateOrders } = usePermissionSuppress();

  const noShipmentsButtons: Record<'primary' | 'secondary', ButtonConfig> = {
    primary: {
      label: 'Book a PO',
      linkTo: { pathname: orderRoutes.orderList.getUrl(), search: '?booking=true' }
    },
    secondary: {
      label: 'Book without PO',
      linkTo: newBookingRoutes.newBookingIndex.getUrl()
    }
  };

  if (!canCreateBooking || suppressCanCreateOrders) {
    return <NoResults headline="You don't have any shipments" />;
  }

  return (
    <NoResults
      button={noShipmentsButtons.primary}
      headline="You don't have any shipments"
      secondaryButton={noShipmentsButtons.secondary}
      tagline="You can create a new shipment booking with or without a PO."
    />
  );
};

export default NoShipments;
