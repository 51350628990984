import type { FC } from 'react';

import CargoDetails from '@zen/Shipment/Cargo/CargoDetails';
import CargoSummaryContainer from '@zen/Shipment/Cargo/CargoSummaryContainer';

interface Props {
  zencargoReference: string;
}

const CargoOverview: FC<Props> = ({ zencargoReference }) => (
  <div className="flex flex-col" data-testid="shipment-overview">
    <CargoSummaryContainer showNoCargoMessage={false} zencargoReference={zencargoReference} />
    <CargoDetails zencargoReference={zencargoReference} />
  </div>
);

export default CargoOverview;
