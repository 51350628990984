import cx from 'classnames';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC, ReactElement } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import InformationIcon from '@zen/Components/InformationIcon';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';

import { GetBookingStaleDataDocument, useGetBookingStaleDataQuery } from './graphql/getBookingStaleData.generated';
import { useReconfirmBookingStaleDataMutation } from './graphql/reconfirmBookingStaleData.generated';
import type { BookingStaleData } from './types';

interface Props {
  confirmationButtonClassname?: string;
  zencargoReference: string;
}

const BookingStaleDataInfo: FC<Props> = ({ zencargoReference, confirmationButtonClassname }) => {
  const { data, loading } = useGetBookingStaleDataQuery({ variables: { zencargoReference } });
  const {
    userProfile: { isAdmin }
  } = useAccount();
  const { addSuccess, addError } = useNotification();
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<{ __typename?: "BookingStaleData" | un... Remove this comment to see the full error message
  const bookingStaleData: BookingStaleData = data?.bookings?.nodes[0]?.staleData;
  const timeFormat: string = 'MMM Do, hh:mma';
  const showDeadlineInfo = isAdmin;
  const showLastUpdatedInfo = !isAdmin;
  const canReconfirm = checkPermission<BookingStaleData>(bookingStaleData, 'canReconfirm');

  const [reconfirmStaleData] = useReconfirmBookingStaleDataMutation();

  if (loading || !bookingStaleData?.enabled || !bookingStaleData?.dataUpdatedAt) {
    return null;
  }

  const freshnessThresholdDuration = moment.duration(bookingStaleData.freshnessThreshold, 'minutes');

  const renderDeadlineInfo = (): ReactElement => {
    // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
    const updateDeadlineAt: Moment = moment(bookingStaleData.dataUpdatedAt.dateTime).add(
      bookingStaleData.freshnessThreshold,
      'minutes'
    );

    return (
      <b className="pr-1 text-red-dark" title={updateDeadlineAt.format(timeFormat)}>
        {updateDeadlineAt.fromNow()}
      </b>
    );
  };

  const renderLastUpdatedInfo = (): ReactElement => (
    // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
    <b className="pr-1">{moment(bookingStaleData.dataUpdatedAt.dateTime).format(timeFormat)}</b>
  );

  const handleReconfirm = async () => {
    try {
      const response = await reconfirmStaleData({
        variables: { input: { zencargoReference } },
        refetchQueries: [{ query: GetBookingStaleDataDocument, variables: { zencargoReference } }]
      });

      const errors = response?.data?.bookingStaleDataReconfirm?.errors;

      if (errors && errors.length > 0) {
        addError(errors[0].message);
      } else {
        addSuccess('Booking data has been confirmed as up to date');
      }
    } catch (error) {
      addError();
    }
  };

  const renderReconfirmButton = (): ReactElement => {
    return (
      <a className={`cursor-pointer hover:underline ${cx(confirmationButtonClassname)}`} onClick={handleReconfirm}>
        Confirm data is up to date
      </a>
    );
  };

  return (
    <div className="py-1" data-testid="booking-stale-data-info">
      <span className="mr-1">{isAdmin ? 'Update deadline' : 'Last updated'}:</span>
      {showDeadlineInfo && renderDeadlineInfo()}
      {showLastUpdatedInfo && renderLastUpdatedInfo()}
      <InformationIcon
        className="text-grey-base mr-3"
        tooltipContent={`New data is pulled at least every ${freshnessThresholdDuration.humanize()} for Ocean bookings`}
      />
      {canReconfirm && renderReconfirmButton()}
    </div>
  );
};

export default BookingStaleDataInfo;
