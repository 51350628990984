import { FC, ReactNode, useState } from 'react';

import { Button, Dialog, Headline, Icon, Modal } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Nullable, Optional } from '@zen/utils/typescript';

import AccountStatusControlForm from '../AccountStatusControlForm';
import { getAccountStatusControlUpdateConfiguration } from '../helpers';
import type { AccountStatusControlType } from '../types';

type ModalType = 'prompt' | 'confirmation';

interface Props {
  accountAccessLabel: string;
  accountLabel: string;
  accountReference: string;
  buttonIconName?: Optional<IconName>;
  configurationType: AccountStatusControlType;
  currentStatus: boolean;
  description: ReactNode;
  iconClassName?: string;
  iconName: IconName;
  isActive?: boolean;
  onUpdate: () => Promise<IOkOrErrorResult>;
  oppositeLabel: string;
}

const AccountStatusControl: FC<Props> = (props) => {
  const {
    accountAccessLabel,
    accountLabel,
    accountReference,
    buttonIconName,
    configurationType,
    currentStatus,
    description,
    iconClassName,
    iconName,
    isActive,
    onUpdate,
    oppositeLabel
  } = props;

  const [modalTypeContent, setModalTypeContent] = useState<Nullable<ModalType>>(null);

  const handleModalContent = (showPromptConfirmation: boolean): void => {
    if (showPromptConfirmation) {
      setModalTypeContent('prompt');
    } else {
      setModalTypeContent('confirmation');
    }
  };

  const { messageDescription } = getAccountStatusControlUpdateConfiguration(configurationType, accountReference, isActive);

  const handleModalClose = (): void => setModalTypeContent(null);

  const handleConfirmationSuccess = (): void => {
    onUpdate();
    handleModalClose();
  };

  const accountInformation: string = `${accountLabel} account`;
  const buttonLabel: string = `Set as ${oppositeLabel}`;
  const title: string = `Set ${accountReference} as ${oppositeLabel}?`;

  const titleHeadline: ReactNode = (
    <Headline className="text-navy-base text-center mb-4" level={2}>
      {title}
    </Headline>
  );

  return (
    <>
      <div className="flex items-center max-w-2xl mb-10">
        <div>
          <div className="flex items-center">
            <Icon className={`mr-2 ${iconClassName}`} icon={iconName} />
            {accountInformation} <span className="text-grey-base ml-2">({accountAccessLabel})</span>
          </div>
          <p className="leading-normal text-grey-base mt-6 w-4/5">{description}</p>
        </div>
        <Button iconLeft={buttonIconName || undefined} onClick={() => handleModalContent(currentStatus)}>
          {buttonLabel}
        </Button>
      </div>

      <Modal isOpen={modalTypeContent === 'prompt'} onClose={handleModalClose} title={title}>
        <AccountStatusControlForm
          accountReference={accountReference}
          configurationType={configurationType}
          onCancel={handleModalClose}
          onSubmit={onUpdate}
          onSuccess={handleModalClose}
        />
      </Modal>

      <Dialog
        header={titleHeadline}
        isOpen={modalTypeContent === 'confirmation'}
        message={messageDescription}
        onClose={handleModalClose}
        onConfirm={handleConfirmationSuccess}
      />
    </>
  );
};

export default AccountStatusControl;
