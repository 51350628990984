import type { FC } from 'react';

import Address from '@zen/Components/Address';
import Card from '@zen/Components/Card';
import { useNetworksContext } from '@zen/Networks';
import type { LocationDescriptorObject } from '@zen/types';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import type { LocationListLocation } from '../types';

interface Props {
  isMyOrganisationPage?: boolean;
  location: LocationListLocation;
  organisationId: string;
}

const LocationListItem: FC<Props> = ({ location, organisationId, isMyOrganisationPage = false }) => {
  const { routes } = useNetworksContext();
  const { navigate } = useNavigationHistory();

  const locationDetailsUrl: LocationDescriptorObject = {
    pathname: routes.location.details.getUrl(organisationId, location.id || ''),
    state: { fromMyOrganisationPage: isMyOrganisationPage }
  };

  const handleLocationCardClick = () => {
    navigate(locationDetailsUrl);
  };

  return (
    <Card
      className="h-full"
      data-testid="location-list-item"
      leftIcon="zicon-pin"
      onClick={handleLocationCardClick}
      title={location.name}
    >
      <Address address={location.address} />
    </Card>
  );
};

export default LocationListItem;
