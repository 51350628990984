import type { FC } from 'react';

import SearchableList from '@zen/Accruals/TemplatesList/SearchableList';
import type { ListItemType } from '@zen/Components/VerticalList/ListItem';
import { Loading } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';

import type { Undefinable } from '../../utils/typescript';
import { useFinanceDeleteAccrualTemplateMutation, useFinanceGetAccrualTemplatesQuery } from '../graphql';
import type { AccrualTemplate } from '../types';
import TemplatesListItem from './TemplatesListItem';

interface Props {
  handleSelect: (accrualTemplate: AccrualTemplate) => void;
  hasOverwriteAction?: boolean;
}

const TemplatesList: FC<Props> = (props) => {
  const { handleSelect, hasOverwriteAction = false } = props;
  const { addError, addSuccess } = useNotification();
  const {
    data,
    loading: isLoading,
    refetch
  } = useFinanceGetAccrualTemplatesQuery({
    onError: () => addError('There was and error with fetching templates')
  });
  const [handleDeleteTemplate] = useFinanceDeleteAccrualTemplateMutation();
  const templates: AccrualTemplate[] = data?.accrualTemplates || [];
  const noResultsMessage: string =
    'Sorry but we can’t find any templates matching this description. \
  Please try an alternative name or creating a new template.';

  const mapDataToListItems = (): ListItemType[] => {
    return templates.map((template: AccrualTemplate) => ({
      id: template.id,
      label: template.templateName
    }));
  };

  const handleRemoveTemplate = async (templateId: string): Promise<void> => {
    try {
      const { data: deleteResult, errors } = await handleDeleteTemplate({
        variables: { input: { templateId } }
      });

      if (!errors && deleteResult?.financeDeleteAccrualTemplate?.errors?.length === 0) {
        refetch();
        addSuccess('The template has been deleted.');
      } else {
        addError();
      }
    } catch (e) {
      addError();
    }
  };

  const onSelect = (id: string): void => {
    const accrualTemplate: Undefinable<AccrualTemplate> = templates.find((item: AccrualTemplate) => item.id === id);

    if (accrualTemplate) {
      handleSelect(accrualTemplate);
    }
  };

  return (
    <div className="my-4 h-100">
      {isLoading && <Loading />}
      {data && !isLoading && (
        <SearchableList
          customListItem={({ item }) => (
            <TemplatesListItem
              key={item.id}
              handleDelete={handleRemoveTemplate}
              handleSelect={onSelect}
              hasOverwriteAction={hasOverwriteAction}
              item={item}
            />
          )}
          list={mapDataToListItems()}
          noResultsMessage={noResultsMessage}
        />
      )}
    </div>
  );
};

export default TemplatesList;
