import cx from 'classnames';
import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  iconName?: IconName;
  isHovered: boolean;
  label: string;
  onClick: () => void;
}

const QuickFiltersActionsButton: FC<Props> = ({ onClick, isHovered, label, iconName }) => {
  const labelClassNames = cx({
    'hover:border-bottom-navy-base border-b border-solid': isHovered
  });

  return (
    <div
      className="cursor-pointer text-navy-base text-base font-bold mr-6"
      data-testid="quick-filters-action-button"
      onClick={onClick}
    >
      {iconName && <Icon className="mr-2 hover:text-navy-light" icon={iconName} />}
      <span className={labelClassNames}>{label}</span>
    </div>
  );
};

export default QuickFiltersActionsButton;
