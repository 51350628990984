import type { MarkerColor, MarkerType } from '@zen/Components/Map/Marker';
import type { RoadTrackedShipment } from '@zen/TrackedShipments/graphql';
import { RoadTrackedShipmentStatus } from '@zen/TrackedShipments/types';
import type { Nullable } from '@zen/utils/typescript';

const markerColorMapping: Record<RoadTrackedShipmentStatus, MarkerColor> = {
  [RoadTrackedShipmentStatus.EARLY]: 'orange',
  [RoadTrackedShipmentStatus.LATE]: 'red',
  [RoadTrackedShipmentStatus.ON_TIME]: 'blue',
  [RoadTrackedShipmentStatus.UNKNOWN]: 'blue'
};

export const getMarkers = (
  shipments: RoadTrackedShipment[],
  activeStatus: RoadTrackedShipmentStatus,
  selectedShipment: Nullable<number>
): MarkerType[] => {
  return shipments.map((shipment, index: number): MarkerType => {
    const { geolocation } = shipment;

    return {
      color: markerColorMapping[activeStatus],
      icon: 'truck',
      isSelected: selectedShipment === index,
      lat: geolocation.latitude,
      lng: geolocation.longitude
    };
  });
};
