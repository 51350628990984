import { CargoModeEnum } from '@zen/Cargo';

import { ScheduleAction } from '../../types';

interface ForwaredSchedulerSectionLabels {
  collectionLabel: string;
  deliveryLabel: string;
  hintText: string;
  sectionTitle: string;
}

const scheduleActionLabelMapping: Record<ScheduleAction, ForwaredSchedulerSectionLabels> = {
  [ScheduleAction.REQUEST_SCHEDULE]: {
    sectionTitle: 'Requested with Zencargo',
    hintText: 'This is the requested collection/delivery date',
    collectionLabel: 'Collection requested',
    deliveryLabel: 'Delivery requested'
  },
  [ScheduleAction.CONFIRM_SCHEDULE]: {
    sectionTitle: 'Confirmed with Zencargo',
    hintText: 'This is the agreed upon date between customer and haulier',
    collectionLabel: 'Collection confirmed',
    deliveryLabel: 'Delivery confirmed'
  }
};

export const getLabels = (scheduleAction: ScheduleAction): ForwaredSchedulerSectionLabels => {
  return scheduleActionLabelMapping[scheduleAction];
};

export const isCollectionRequired = (scheduleAction: ScheduleAction): boolean => {
  return scheduleAction === ScheduleAction.CONFIRM_SCHEDULE;
};

export const isDeliveryRequired = (scheduleAction: ScheduleAction, cargoMode: CargoModeEnum): boolean => {
  return scheduleAction === ScheduleAction.CONFIRM_SCHEDULE && cargoMode === CargoModeEnum.FTL;
};
