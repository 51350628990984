import cx from 'classnames';
import moment from 'moment';
import { FC, ReactNode, useState } from 'react';

import type { Day } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import DatePickerProvider from './context';
import Header from './Header';
import Month from './Month';
import type { DatePickerSize, Direction } from './types';
import { DATE_FORMAT, HEADER_FORMAT } from './utils';

interface Props {
  disabledDayTooltip?: ReactNode;
  enabledDays?: Day[];
  hasError?: boolean;
  highlightInitialDate?: boolean;
  initialDate?: Optional<string>;
  isInline?: boolean;
  maxDate?: string;
  minDate?: string;
  onChange: (date: string) => void;
  selectedDate?: Optional<string>;
  size?: DatePickerSize;
  suggestedDate?: string;
}

// Accepted string format for min, max, suggested, selected, intital date is YYYY-MM-DD

const DatePicker: FC<Props> = (props) => {
  const {
    hasError = false,
    disabledDayTooltip,
    highlightInitialDate = true,
    minDate,
    maxDate,
    enabledDays,
    initialDate,
    onChange,
    selectedDate,
    isInline = true,
    suggestedDate,
    size = 'medium'
  } = props;

  const isValidDate = (date: Optional<string>): boolean => {
    return !!date && moment(date, DATE_FORMAT).isValid();
  };

  const getInitialDay = (): string => {
    if (selectedDate && isValidDate(selectedDate)) {
      return selectedDate;
    }
    if (initialDate && isValidDate(initialDate)) {
      return initialDate;
    }

    return moment().format(DATE_FORMAT);
  };

  const [day, setDay] = useState<string>(getInitialDay());

  const handleMonthChange = (direction: Direction) => {
    const momentDay = moment(day, DATE_FORMAT);
    const value = direction === 'next' ? 1 : -1;

    setDay(momentDay.add(value, 'month').format(DATE_FORMAT));
  };

  const classes: string = cx(
    {
      'border border-solid border-grey-lighter': !hasError && isInline,
      'border border-solid border-red-base': hasError,
      'shadow-overlay': !isInline,
      'w-88': size === 'small',
      'max-w-sm min-w-sm': size === 'medium',
      'max-w-md min-w-lg min-h-lg': size === 'large'
    },
    'select-none bg-white rounded overflow-hidden'
  );

  const headerTitle = moment(day, DATE_FORMAT).format(HEADER_FORMAT);

  return (
    <DatePickerProvider
      currentDate={day}
      disabledDayTooltip={disabledDayTooltip}
      enabledDays={enabledDays}
      handleMonthChange={handleMonthChange}
      highlightInitialDate={highlightInitialDate}
      initialDate={initialDate}
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      selectedDate={selectedDate}
      size={size}
      suggestedDate={suggestedDate}
    >
      <div className={classes}>
        <Header onMonthChange={handleMonthChange} title={headerTitle} />
        <Month day={day} />
      </div>
    </DatePickerProvider>
  );
};

export default DatePicker;
