import { SortingOrder, SortInput } from '@zen/types';

export const initialOrder: SortInput = {
  field: 'name',
  direction: SortingOrder.ASC
};

export const columns = [
  {
    key: 'unlocode',
    title: 'UN/LOCODE'
  },
  {
    key: 'name',
    title: 'Name'
  },
  {
    key: 'functionField',
    title: 'Function'
  },
  {
    key: 'location',
    title: 'Location'
  }
];
