import type { FC } from 'react';

import CollapsibleSection from '@zen/Components/CollapsibleSection';

interface Props {
  specialInstructions: string;
}

const DetailsBoardInstructionsSection: FC<Props> = ({ specialInstructions }) => {
  return (
    <div className="flex flex-col pb-6 w-3/5">
      <span className="mb-2 text-grey-base">Special instructions</span>
      <CollapsibleSection collapseLabel="Hide" expandLabel="Show all" minDisplayedHeight={40}>
        <div
          className="bg-grey-lightest border border-solid border-grey-lighter rounded p-3 mb-2"
          data-testid="special-instructions"
        >
          <p className="font-mono leading-normal text-xs">{specialInstructions}</p>
        </div>
      </CollapsibleSection>
    </div>
  );
};

export default DetailsBoardInstructionsSection;
