import type { FC, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router';

import useSwitchAccount from '@zen/Auth/hooks/useSwitchAccount';
import AccountAvatar from '@zen/Components/AccountAvatar';
import { useTrackAccountSwitch, VisitedAccount } from '@zen/Components/VisitedAccounts';
import useAccount from '@zen/utils/hooks/useAccount';
import useAccounts, { Account } from '@zen/utils/hooks/useAccounts';
import { extractFirstLevelPath } from '@zen/utils/routing';

interface Props {
  onAccountChange?: () => void;
  type: 'lastVisited' | 'mostFrequentlyVisited';
  visitedAccounts: VisitedAccount[];
}

const VisitedAccountsList: FC<Props> = ({ onAccountChange, type, visitedAccounts }) => {
  const { accountUuid } = useAccount();
  const { data: accounts, error, isLoading } = useAccounts();
  const { switchToAccount } = useSwitchAccount();
  const trackAccountSwitch = useTrackAccountSwitch();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const title: string = type === 'lastVisited' ? 'Recently selected' : 'Most frequently visited';

  if (isLoading || error) {
    return null;
  }

  const handleSwitchAccount = (accountId: string): void => {
    onAccountChange?.();

    if (accountId === accountUuid) {
      return;
    }
    const newAccount = accounts?.find((account) => account.uuid === accountId);
    const newAccountCompanyName = newAccount?.registeredCompanyName;

    if (newAccountCompanyName) {
      trackAccountSwitch(newAccountCompanyName, type);
    }
    switchToAccount(accountId);
    push(extractFirstLevelPath(pathname));
  };

  const accountsWithInfo = (): Account[] => {
    const visitedAccountsIds = visitedAccounts.map((v) => v.id);

    return accounts?.filter((account) => visitedAccountsIds.includes(account.uuid)) || [];
  };

  return (
    <div className="py-2">
      <div className="text-xs text-grey-base mb-4">{title}</div>
      <ul>
        {accountsWithInfo().map(({ registeredCompanyName, uuid }): ReactNode => {
          return (
            <li key={uuid} className="cursor-pointer group flex items-center mb-2" onClick={() => handleSwitchAccount(uuid)}>
              <AccountAvatar accountName={registeredCompanyName} className="mr-2.5 shrink-0" />
              <span className="text-sm group-hover:underline text-navy-base truncate">{registeredCompanyName}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default VisitedAccountsList;
