import type { FC } from 'react';

import { FormButtons } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';

interface Props {
  isDisabled?: boolean;
  isSubmitting: boolean;
  onCancel: () => void;
}

const CollectionAndDeliveryFormButtons: FC<Props> = ({ isDisabled, isSubmitting, onCancel }) => {
  return (
    <FormButtons containerClassName="mt-6" isDisabled={isDisabled} isSubmitting={isSubmitting} text="Save">
      <Button data-testid="cancel-button" onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );
};

export default CollectionAndDeliveryFormButtons;
