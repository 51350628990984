import { FC, ReactElement, useEffect } from 'react';
import { useParams } from 'react-router';

import { checkPermission } from '@zen/Auth/authHelper';
import type { CargoItemsData, CargoTransition, NewCargo } from '@zen/Cargo';
import { useCargoItemsQuery } from '@zen/Cargo/graphql';
import { FormTrackingCategory } from '@zen/Components/Form/types';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import LotAssignmentsCargoBasketProvider from '@zen/Orders/LotAssignments/contexts/LotAssignmentsCargoBasketProvider';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import { packingListsRoutes, shipmentRoutes } from '@zen/Routes';
import { ShipmentPageSectionUrl } from '@zen/Shipment/ShipmentDetailsPage/types';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useTimeTracker } from '@zen/utils/hooks/useTimeTracking';
import useTracking from '@zen/utils/hooks/useTracking';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { Optional, Undefinable } from '@zen/utils/typescript';

import transitionCargo from '../../Cargo/cargoTransition.helper';
import CargoSwitcher from '../components/CargoSwitcher';
import NewCargoSummary from '../components/NewCargoSummary';
import { PackingListsTrackingAction, PackingListsTrackingCategory } from '../types';
import PackingListManagement from './PackingListManagement';

const PackingListDetails: FC = () => {
  const { navigate } = useNavigationHistory();
  const { addSuccess } = useNotification();
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();

  const { getTimeElapsed, resetTime } = useTimeTracker();
  const { trackEvent, trackTiming } = useTracking();
  const { cargoId, zencargoReference } = useParams<{ cargoId: string; zencargoReference: string }>();

  const shipmentUrl: string = shipmentRoutes.shipmentDetailsPage.getUrl(zencargoReference, ShipmentPageSectionUrl.CARGO);

  const defaultBackUrl: string = shipmentUrl;
  const originPath: string = packingListsRoutes.packingListDetails.getUrl(zencargoReference, cargoId);

  const { data, loading, error } = useCargoItemsQuery({
    variables: { newCargoService: !!isNewCargoServiceEnabled, zencargoReference },
    fetchPolicy: 'network-only'
  });

  const handleCargoChange = (newCargoId: string): void => {
    const url: string = packingListsRoutes.packingListDetails.getUrl(zencargoReference, newCargoId);

    trackEvent({
      category: PackingListsTrackingCategory,
      action: PackingListsTrackingAction.SWITCH_CARGO_ITEM,
      label: zencargoReference
    });

    navigate(url);
  };

  const handleCargoEdit = (): void => {
    navigate(shipmentRoutes.shipmentEditCargo.getUrl(zencargoReference, cargoId));
  };

  const handleSuccess = (): void => {
    trackEvent({
      category: PackingListsTrackingCategory,
      action: PackingListsTrackingAction.SUBMIT_PACKING_LIST,
      label: `${zencargoReference}|${cargoId}`
    });

    trackTiming({
      category: FormTrackingCategory,
      variable: 'PackingListForm',
      value: getTimeElapsed()
    });

    // we need to reset time after submitting the form
    resetTime();

    addSuccess('Packing list has been saved.');
  };

  useEffect(() => {
    // we need to reset time when the cargo is changed
    resetTime();
  }, [cargoId]);

  return (
    <LotAssignmentsCargoBasketProvider cargoId={cargoId}>
      <QueryHandler data={data?.bookings?.nodes?.[0]} error={!!error} isLoading={loading}>
        {(cargoItemsData: CargoItemsData): ReactElement => {
          const { cargo, cargos, modeOfTransport } = cargoItemsData;
          const newCargo: Optional<CargoTransition> = transitionCargo(cargo, cargos);
          const cargoIds: Undefinable<string[]> = newCargo?.cargoList?.map(({ id }: { id: string }) => id);
          const cargoInfo: Optional<NewCargo> = newCargo?.cargoList?.find(({ id }: { id: string }) => id === cargoId);
          const canManageCargo: boolean = checkPermission(cargoItemsData, 'canManageCargo');
          const handleEditCallback: Undefinable<() => void> = canManageCargo ? handleCargoEdit : undefined;

          return (
            <Page
              actionButtons={
                cargoIds && <CargoSwitcher cargoIds={cargoIds} currentCargoId={cargoId} onChange={handleCargoChange} />
              }
              defaultBackUrl={defaultBackUrl}
              headerClassName="pb-20"
              tagline={`For the booking ${zencargoReference}`}
              title="Edit packing list"
              width="wide"
            >
              {cargoInfo && (
                <>
                  <NewCargoSummary
                    cargo={cargoInfo}
                    className="-mt-20 mb-8 relative"
                    modeOfTransport={modeOfTransport}
                    onEdit={handleEditCallback}
                    variant="light"
                  />
                  <PackingListManagement cargoId={cargoInfo.id} onSuccess={handleSuccess} zencargoReference={zencargoReference} />
                  <OrderDetailsContainer originPath={originPath} />
                </>
              )}
            </Page>
          );
        }}
      </QueryHandler>
    </LotAssignmentsCargoBasketProvider>
  );
};

export default PackingListDetails;
