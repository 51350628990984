import { FC, ReactNode, useState } from 'react';

import { Dialog, IconButton } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useArchiveBookingMutation } from './graphql';

interface Props {
  zencargoReference: string;
}

const ArchiveShipment: FC<Props> = ({ zencargoReference }) => {
  const { addError, addSuccess } = useNotification();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const [archiveBooking] = useArchiveBookingMutation();

  const handleCloseModal = (): void => setShowConfirmationModal(false);

  const handleArchiveShipment = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        archiveBooking({
          variables: {
            input: {
              zencargoReference
            }
          },
          awaitRefetchQueries: true,
          refetchQueries: ['getShipmentDetailsBoard']
        }),
      onError: addError,
      onSuccess: () => {
        addSuccess('Shipment has been archived');
        handleCloseModal();
      }
    });
  };

  const archiveMessage: ReactNode = (
    <>
      Are you sure you want to archive this shipment?
      <br />
      <span className="font-bold text-red-dark"> If you continue you will not be able to undo your choice.</span>
    </>
  );

  return (
    <>
      <IconButton icon="zicon-trash" onClick={() => setShowConfirmationModal(true)} size="medium" variant="tertiary" />
      <Dialog
        buttonVariant="danger"
        confirmLabel="Archive shipment"
        header="Archive shipment"
        isOpen={showConfirmationModal}
        message={archiveMessage}
        onClose={handleCloseModal}
        onConfirm={handleArchiveShipment}
      />
    </>
  );
};

export default ArchiveShipment;
