import type { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { packingListsRoutes, shipmentRoutes } from '@zen/Routes';

import PackingListDetails from './PackingListDetails';
import PackingListOverview from './PackingListOverview';

const PackingListsContainer: FC = () => {
  return (
    <Switch>
      <Route path={packingListsRoutes.packingListDetails.path}>
        <PackingListDetails />
      </Route>
      <Route path={packingListsRoutes.packingListIndex.path}>
        <PackingListOverview />
      </Route>
      <Redirect to={shipmentRoutes.shipmentIndex.path} />
    </Switch>
  );
};

export default PackingListsContainer;
