import cx from 'classnames';
import { get } from 'lodash';
import type { FC, ReactNode } from 'react';

import { FormArray, FormArrayHelpers, FormCheckbox, FormErrors, FormTouched } from '@zen/Components/Form';
import FormLabel from '@zen/Components/Form/FormLabel';
import FormSelect from '@zen/Components/Form/FormSelect';
import type { Option } from '@zen/DesignSystem';
import { IconButton } from '@zen/DesignSystem';
import type { ContactFormFields, ContactLocationAssignment } from '@zen/Networks/types';
import type { Nullable } from '@zen/utils/typescript';

interface Props {
  errors: FormErrors<ContactFormFields>;
  locationId: string | undefined;
  locationsList: Option<string>[];
  setFieldValue: (field: string, value: unknown) => void;
  touched: FormTouched<ContactFormFields>;
  values: ContactFormFields;
}

export const ContactFormLocations: FC<Props> = ({
  errors,
  locationId: assignedLocationId,
  locationsList,
  setFieldValue,
  touched,
  values
}) => {
  const shouldShowNewLocation = values.contactLocationAssignments.length !== locationsList.length;

  return (
    <FormArray
      addButtonText="Assign another location"
      empty={{ locationId: '', mainContact: false }}
      hideAddButton={!shouldShowNewLocation}
      path="contactLocationAssignments"
      values={values.contactLocationAssignments}
    >
      {({ remove, getFieldName, index }: FormArrayHelpers<ContactLocationAssignment>) => {
        const isSelectDisabled = values.contactLocationAssignments[index].locationId === assignedLocationId;
        const isLocationFieldDisabled: boolean = values.contactLocationAssignments.length === 1 || isSelectDisabled;

        const handleDelete = () => {
          const { locationId } = values.contactLocationAssignments[index];
          const assignmentsToRemoveList = [...(values.locationAssignmentsToRemove || []), locationId];

          setFieldValue('locationAssignmentsToRemove', assignmentsToRemoveList);
          remove();
        };

        const handleLocationSelect = (value: Nullable<string>): void => {
          if (values.locationAssignmentsToRemove && values.locationAssignmentsToRemove.length) {
            const updatedAssignmentsToRemoveList = values.locationAssignmentsToRemove.filter(
              (locationId) => locationId !== value
            );

            setFieldValue('locationAssignmentsToRemove', updatedAssignmentsToRemoveList);
          }
        };

        const renderDeleteIcon = (): ReactNode => (
          <div className="mt-6">
            <IconButton icon="zicon-trash" onClick={handleDelete} title="Remove" variant="secondary" />
          </div>
        );

        const checkFieldState = (state: FormErrors<ContactFormFields> | FormTouched<ContactFormFields>): boolean =>
          get(state, `contactLocationAssignments[${index}].locationId`);

        const isLocationFieldError = checkFieldState(errors) && checkFieldState(touched);

        const classNames = cx(
          {
            'mr-2': !isLocationFieldDisabled,
            disabled: isSelectDisabled
          },
          'w-full'
        );

        return (
          <div key={index} className="flex" data-testid="location-select">
            <div className={classNames}>
              <FormLabel isRequired={true} label="Location" />
              <FormSelect
                hasError={isLocationFieldError}
                hideLabel={true}
                isDisabled={isSelectDisabled}
                name={getFieldName('locationId')}
                onChange={handleLocationSelect}
                options={locationsList}
                placeholder="Head office"
              />
              <div className="flex pb-6 items-center">
                <FormCheckbox hideCheckboxLabel={true} name={getFieldName('mainContact')} />
                <div className="ml-4 text-sm text-grey-base">
                  <span className="flex text-base text-grey-dark leading-snug">Become main contact for this location?</span>
                  This will replace the current main contact
                </div>
              </div>
            </div>
            {!isLocationFieldDisabled && renderDeleteIcon()}
          </div>
        );
      }}
    </FormArray>
  );
};

export default ContactFormLocations;
