import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { CargoItemType, CargoItemTypeEnum, LooseCargoTypeEnum } from '@zen/Cargo';
import CargoInfo from '@zen/Cargo/components/CargoInfo';
import { formatWeight } from '@zen/Cargo/helpers';
import { Icon } from '@zen/DesignSystem';
import { isAirShipment, isOceanShipment, isRoadShipment, ModeOfTransport } from '@zen/Shipments';
import { cargoTypeLabelMapping } from '@zen/types';
import { formatNumber } from '@zen/utils/formatting';

import CargoHeadline from '../CargoHeadline';

interface Props {
  cargo: Partial<CargoItemType>;
  className?: string;
  modeOfTransport: ModeOfTransport;
  onEdit?: (id: string) => void;
  variant?: 'light' | 'dark';
}

const CargoSummary: FC<Props> = ({ cargo, className, modeOfTransport, onEdit, variant = 'dark' }) => {
  const {
    id,
    chargeableWeight,
    containerSealNumber,
    containerNumber,
    cbm,
    quantity,
    grossWeight,
    looseCargoType,
    trailerId,
    type
  } = cargo;

  const classNames: string = cx('p-4', className, {
    'bg-white shadow-overlay': variant === 'light',
    'bg-grey-lightest': variant === 'dark'
  });

  const renderCommonFields = (): ReactNode => {
    return (
      <>
        {looseCargoType !== LooseCargoTypeEnum.ASSORTED_CARGO && <CargoInfo title="Piece count">{quantity}</CargoInfo>}
        <CargoInfo title="CBM">{cbm ? formatNumber(cbm) : '-'}</CargoInfo>
        <CargoInfo title="Gross weight">{formatWeight(grossWeight)}</CargoInfo>
      </>
    );
  };

  const renderContainerInfo = (): ReactNode => {
    return (
      <>
        <CargoInfo title="Container number">{containerNumber}</CargoInfo>
        <CargoInfo title="Seal number">{containerSealNumber}</CargoInfo>
        <CargoInfo title="Loose cargo type">{cargoTypeLabelMapping[looseCargoType as LooseCargoTypeEnum]}</CargoInfo>
        {renderCommonFields()}
      </>
    );
  };

  const renderLooseCargoInfo = (): ReactNode => {
    return (
      <>
        {renderCommonFields()}
        {isAirShipment(modeOfTransport) && <CargoInfo title="Chargeable weight">{formatWeight(chargeableWeight)}</CargoInfo>}
        {isOceanShipment(modeOfTransport) && <CargoInfo title="Container number">{containerNumber}</CargoInfo>}
        {isRoadShipment(modeOfTransport) && <CargoInfo title="Trailer id">{trailerId}</CargoInfo>}
      </>
    );
  };

  const renderVehicleInfo = (): ReactNode => {
    return (
      <>
        <CargoInfo title="Trailer id">{trailerId}</CargoInfo>
        <CargoInfo title="Loose cargo type">{cargoTypeLabelMapping[looseCargoType as LooseCargoTypeEnum]}</CargoInfo>
        {renderCommonFields()}
      </>
    );
  };

  return (
    <div className={classNames} data-testid="packing-list-cargo-summary">
      <div className="flex items-center justify-between mb-4">
        <CargoHeadline cargo={cargo} />
        {onEdit && (
          <div data-testid="edit-cargo-button" onClick={() => onEdit(id!)}>
            <Icon className="text-sm text-grey-base -mr-2" icon="zicon-edit" interactive={true} />
          </div>
        )}
      </div>
      <div className="grid grid-cols-6 gap-4">
        {type === CargoItemTypeEnum.CONTAINER && renderContainerInfo()}
        {type === CargoItemTypeEnum.LOOSE_CARGO && renderLooseCargoInfo()}
        {type === CargoItemTypeEnum.VEHICLE && renderVehicleInfo()}
      </div>
    </div>
  );
};

export type { Props as CargoSummaryProps };
export default CargoSummary;
