import type { CSSObject } from '@emotion/serialize';
import type { Theme } from 'react-select';

import { Color, FontSize } from '@zen/Styleguide';

import type { SelectStyle } from './types';

type SelectState = {
  isDisabled: boolean;
  isFocused: boolean;
  selectProps: {
    isSearchable: boolean;
  };
};

export const customStyles = (hasError: boolean = false, style: SelectStyle = 'outlined', displaySeparator: boolean = false) => {
  const borderColor: Record<SelectStyle, Color> = {
    outlined: Color.GREY_LIGHT,
    inline: Color.TRANSPARENT
  };

  const fontSize: Record<SelectStyle, FontSize> = {
    outlined: FontSize.SM,
    inline: FontSize.BASE
  };

  return {
    control: (styles: CSSObject, state: SelectState): CSSObject => {
      const getBorderColor = (): Color => {
        if (hasError) {
          return Color.RED_DARK;
        }

        return state.isFocused ? Color.AZURE_BASE : borderColor[style];
      };

      return {
        ...styles,
        cursor: state.selectProps.isSearchable ? 'text' : 'pointer',
        outline: 'none',
        padding: '0',
        minHeight: '2.5rem',
        borderRadius: '0.25rem',
        border: `1px solid ${getBorderColor()}`,
        boxShadow: 'none',
        flexWrap: 'nowrap',
        color: Color.GREY_DARK,
        background: state.isDisabled ? Color.GREY_LIGHTEST : Color.WHITE,
        fontFamily: 'inherit',
        fontSize: fontSize[style],
        lineHeight: 'inherit'
      };
    },
    dropdownIndicator: (styles: CSSObject): CSSObject => ({
      ...styles,
      padding: '0 0.5rem'
    }),
    group: (styles: CSSObject): CSSObject => ({
      ...styles,
      padding: 0
    }),
    groupHeading: (styles: CSSObject): CSSObject => ({
      ...styles,
      textTransform: 'none',
      display: 'flex',
      paddingLeft: '1.25rem',
      margin: '0',
      width: '100%',
      background: Color.GREY_LIGHTEST as string,
      boxShadow: `inset 4px 0px 0px 0px ${Color.NAVY_DARK}`,
      borderBottom: '1px solid #dde6ea'
    }),
    singleValue: (styles: CSSObject, state: Pick<SelectState, 'isDisabled'>): CSSObject => ({
      ...styles,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      color: state.isDisabled ? Color.GREY_BASE : Color.GREY_DARK
    }),
    valueContainer: (styles: CSSObject): CSSObject => ({
      ...styles,
      padding: style === 'inline' ? '0.125rem 0.5rem' : '0.125rem 0.75rem',
      height: '100%'
    }),
    option: (styles: CSSObject): CSSObject => ({
      ...styles,
      borderBottom: '1px solid #dde6ea',
      cursor: 'pointer',
      paddingLeft: '1.25rem',
      paddingRight: '1rem',
      paddingTop: '1rem',
      paddingBottom: '1rem'
    }),
    placeholder: (styles: CSSObject): CSSObject => ({
      ...styles,
      color: Color.GREY_BASE as string
    }),
    indicatorSeparator: (baseStyles: CSSObject): CSSObject => {
      return displaySeparator ? baseStyles : ({ display: 'none' } as CSSObject);
    },
    menu: (styles: CSSObject): CSSObject => ({
      ...styles,
      boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.15)',
      borderRadius: '4px',
      border: '1px solid #E6E7E9',
      overflow: 'hidden',
      zIndex: 150
    }),
    menuList: (styles: CSSObject): CSSObject => ({
      ...styles,
      padding: '0'
    })
  };
};

export const customTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral30: Color.AZURE_BASE,
    primary25: Color.AZURE_LIGHTEST,
    primary50: Color.AZURE_LIGHT,
    primary: Color.AZURE_BASE
  }
});

export const multiSelectStyles = (
  hasError: boolean = false,
  style: SelectStyle = 'outlined',
  displaySeparator: boolean = false
) => {
  return {
    ...customStyles(hasError, style, displaySeparator),
    multiValueLabel: (baseStyles: CSSObject): CSSObject => ({
      ...baseStyles,
      fontSize: '0.875rem',
      padding: '0.375rem 0.5rem'
    })
  };
};

export const asyncSelectStyles = (hasError: boolean = false, style: SelectStyle = 'outlined') => {
  return {
    ...customStyles(hasError, style),
    dropdownIndicator: (styles: CSSObject) => ({
      ...styles,
      display: 'none'
    })
  };
};
