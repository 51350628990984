import * as Yup from 'yup';

const activateQuoteValidationSchema = () =>
  Yup.object().shape({
    expiryDate: Yup.object()
      .shape({
        date: Yup.string().required('Expiry date is required.')
      })
      .required('Expiry date is required.')
  });

export { activateQuoteValidationSchema };
