import type { FC } from 'react';

import { useOrganisationLocationDetailsQuery } from '@zen/Networks/graphql';
import type { Nullable } from '@zen/utils/typescript';

interface Props {
  editMode?: boolean;
  locationId: Nullable<string>;
  organisationId: string;
  organisationName: string;
}

const ContactTitle: FC<Props> = ({ editMode = false, locationId, organisationId, organisationName }) => {
  const { data, loading } = useOrganisationLocationDetailsQuery({
    variables: { organisationId, locationId: locationId || '' },
    skip: locationId === null
  });

  const titlePrefix = editMode ? 'Edit contact in ' : 'Add new contact to ';
  const name = data?.location?.name;
  const titleElement = name || organisationName;

  return (
    <div>
      {titlePrefix}
      {!loading && titleElement}
    </div>
  );
};

export default ContactTitle;
