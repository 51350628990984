import { MissingRoadTrackingInputData } from '@zen/graphql/types.generated';
import { RoadTrackingStatusReason } from '@zen/Shipment';
import type { Optional } from '@zen/utils/typescript';

import type { TrackingData } from '../types';
import type { TrackingResponse } from './types';

const missingDataPointLabelMpping: Record<MissingRoadTrackingInputData, string> = {
  [MissingRoadTrackingInputData.APPOINTMENT_WINDOW]: 'Date & time',
  [MissingRoadTrackingInputData.HAULIER_ID]: 'Haulier',
  [MissingRoadTrackingInputData.LICENSE_PLATE]: 'Vehicle plate',
  [MissingRoadTrackingInputData.SHIPMENT_IDENTIFIER]: 'Shipment identifier',
  [MissingRoadTrackingInputData.SHIPMENT_STOP_GEO_COORDINATES]: 'Geo coordinates',
  [MissingRoadTrackingInputData.SHIPMENT_STOP_LOCATION]: 'Collection/delivery location',
  [MissingRoadTrackingInputData.TRAILER_ID]: 'Trailer ID'
};

const trackingStatusLabelMapping: Record<RoadTrackingStatusReason, string> = {
  [RoadTrackingStatusReason.ACQUIRING_LOCATION]: 'Acquiring location',
  [RoadTrackingStatusReason.APPROVAL_DENIED]: 'Approval denied',
  [RoadTrackingStatusReason.ARRIVED_FINAL_STOP]: 'Arrived final stop',
  [RoadTrackingStatusReason.CANCELED]: 'Canceled',
  [RoadTrackingStatusReason.DATA_ENTERED_TOO_LATE]: 'Data entered too late',
  [RoadTrackingStatusReason.DEPARTED_FINAL_STOP]: 'Delivered',
  [RoadTrackingStatusReason.FAILED_TO_ACQUIRE_LOCATION]: 'Failed to acquire location',
  [RoadTrackingStatusReason.HAULIER_NOT_ONBOARDED]: 'Haulier not onboarded',
  [RoadTrackingStatusReason.IDLE]: 'Idle',
  [RoadTrackingStatusReason.INFO]: 'Info',
  [RoadTrackingStatusReason.IN_MOTION]: 'In motion',
  [RoadTrackingStatusReason.PENDING_APPROVAL]: 'Pending approval',
  [RoadTrackingStatusReason.PENDING_CARRIER]: 'Pending carrier',
  [RoadTrackingStatusReason.PENDING_TRACKING_METHOD]: 'Vehicle plate number not recognised',
  [RoadTrackingStatusReason.SCHEDULED]: 'Scheduled',
  [RoadTrackingStatusReason.TIMED_OUT]: 'Timed out',
  [RoadTrackingStatusReason.UNKNOWN]: 'Unknown',
  [RoadTrackingStatusReason.UNKNOWN_VEHICLE]: 'Vehicle plate number not recognised'
};

const prepareMissingDataPoints = (missingDataPoints: Optional<MissingRoadTrackingInputData[]>): string[] => {
  const uniqueMissingPoints: MissingRoadTrackingInputData[] = [...new Set(missingDataPoints)];

  return uniqueMissingPoints.map((missingDataPoint) => missingDataPointLabelMpping[missingDataPoint]).sort();
};

export const prepareTrackingData = (data: TrackingResponse): TrackingData => {
  const trackingInput = data?.trackingInput;
  const trackingSubscription = data?.trackingInput?.trackingSubscription;
  const showMissingDataPoints: boolean = !!trackingInput?.missingData && !!trackingInput?.trackable;
  const statusReason: Optional<RoadTrackingStatusReason> = trackingSubscription?.statusReason;

  return {
    trackingStatus: statusReason ? trackingStatusLabelMapping[statusReason] : null,
    trackingType: trackingInput?.trackable ? 'Trackable' : 'Not trackable',
    missingDataPoints: showMissingDataPoints ? prepareMissingDataPoints(trackingInput?.missingData) : []
  };
};
