import type { FC } from 'react';

import type { Option } from '@zen/DesignSystem';
import { Select } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { useUpdateCustomsOnlyMutation } from '../graphql';

const options: Option<boolean>[] = [
  { value: true, label: 'Customs only' },
  { value: false, label: 'Not customs only' }
];

interface Props {
  customsOnly: boolean;
  zencargoReference: string;
}

const CustomsOnlyInput: FC<Props> = ({ customsOnly, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const [updateCustomsOnly] = useUpdateCustomsOnlyMutation();

  const handleCustomsOnlyChange = (isCustomsOnly: Nullable<boolean>): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateCustomsOnly({
          variables: {
            input: {
              zencargoReference,
              customsOnly: !!isCustomsOnly
            }
          }
        }),
      onError: addError,
      onSuccess: () => addSuccess('Customs updated.')
    });
  };

  return (
    <Select
      className="w-48"
      isSearchable={false}
      name="customsOnly"
      onChange={handleCustomsOnlyChange}
      options={options}
      overLabel="Customs"
      value={customsOnly}
    />
  );
};

export default CustomsOnlyInput;
