import type { FC, ReactElement } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import SectionTitle from '@zen/Components/SectionTitle';
import type { BillingDetails as BillingDetailsType } from '@zen/Settings/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import BillingDetailsForm from './BillingDetailsForm';
import { useGetBillingDetailsQuery, useUpdateBillingDetailsMutation } from './graphql';
import type { AccountBillingDetails } from './types';

interface Props {
  accountId: string;
}

const BillingDetails: FC<Props> = ({ accountId }) => {
  const { addSuccess, addError } = useNotification();

  const [updateBillingDetails] = useUpdateBillingDetailsMutation();

  const { data, error, loading, refetch } = useGetBillingDetailsQuery({
    variables: { accountId }
  });

  const handleUpdateBillingDetails = (values: BillingDetailsType): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateBillingDetails({
          variables: {
            input: {
              id: accountId,
              ...values
            }
          }
        }),
      onError: addError
    });
  };

  const handleOnSuccess = (): void => {
    refetch();
    addSuccess('Billing details updated');
  };

  return (
    <QueryHandler data={data?.account} error={!!error} isLoading={loading}>
      {(accountBillingDetails: AccountBillingDetails): ReactElement => {
        return (
          <div data-testid="billing-details-form">
            <SectionTitle title="Billing information" />

            <BillingDetailsForm
              initialValues={accountBillingDetails?.billingDetails as BillingDetailsType}
              onSubmit={handleUpdateBillingDetails}
              onSuccess={handleOnSuccess}
            />
          </div>
        );
      }}
    </QueryHandler>
  );
};

export default BillingDetails;
