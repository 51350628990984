import type { FC, ReactNode } from 'react';
import * as Yup from 'yup';

import { Form, FormButtons, FormInstance } from '@zen/Components/Form';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import { Button, Modal } from '@zen/DesignSystem';
import { BookingStageEnum } from '@zen/Shipment/RouteProgress/BookingStages/types';
import { formatDate } from '@zen/utils/dateTime';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional, Undefinable } from '@zen/utils/typescript';

import type { StageUpdateFormType, StageUpdateModalType } from './type';

const validation = Yup.object().shape({
  date: Yup.string().nullable().required('Date is required.')
});

interface Props {
  initialDate?: Optional<string>;
  maxDate?: Undefinable<string>;
  minDate?: Undefinable<string>;
  onClose: () => void;
  onUpdate: (stageName: BookingStageEnum, occurredAt?: Optional<string>) => Promise<IOkOrErrorResult>;
  type?: StageUpdateModalType;
}

const StageUpdateModal: FC<Props> = ({ onClose, onUpdate, type, initialDate, minDate, maxDate }) => {
  const isConfirmType: boolean = type === 'confirm';
  const title: string = isConfirmType ? 'Marking booking as confirmed' : 'Editing booking confirmed stage';
  const initialValues: StageUpdateFormType = {
    date: initialDate
  };

  const handleSubmit = async (values: StageUpdateFormType): Promise<IOkOrErrorResult> => {
    return onUpdate(BookingStageEnum.BOOKING_CONFIRMED, values.date);
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<StageUpdateFormType>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Submit">
      <Button onClick={onClose} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  const getDisabledTooltipMessage = (): Undefinable<string> => {
    if (maxDate) {
      return `Date selected must occur before ${formatDate(maxDate)}`;
    }
  };

  return (
    <Modal isOpen={!!type} modalOverflowY="visible" onClose={onClose} title={title}>
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={onClose}
        validationSchema={validation}
      >
        {() => {
          return (
            <FormDatePickerInput
              className="w-1/2"
              disabledDayTooltip={getDisabledTooltipMessage()}
              isRequired={true}
              label="New date"
              maxDate={maxDate}
              minDate={minDate}
              name="date"
              pickerPositionY="below"
            />
          );
        }}
      </Form>
    </Modal>
  );
};

export default StageUpdateModal;
