import type { FC } from 'react';
import { Portal } from 'react-portal';

import { Loading } from '@zen/DesignSystem';

const LoadingMask: FC = () => {
  return (
    <Portal>
      <div className="fixed z-20 left-0 top-0 right-0 bottom-0 flex items-center justify-center bg-white/75">
        <Loading size="large" />
      </div>
    </Portal>
  );
};

export default LoadingMask;
