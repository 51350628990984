import { FC, ReactNode, useState } from 'react';

import ActionItemDetails from '@zen/ActivityFeed/components/ActionItemDetails';
import { ActionItem, ActionItemStatus, ActivityFeedItemTypeEnum } from '@zen/ActivityFeed/types';
import { Button } from '@zen/DesignSystem';
import ActionItemDocumentUpload from '@zen/Shipment/IssuesAndActions/ActionItems/ActionItem/ActionItemDocumentUpload';
import useAccount from '@zen/utils/hooks/useAccount';

interface Props {
  actionItem: ActionItem;
  actionItemType: ActivityFeedItemTypeEnum;
  createdAt: string;
  zencargoReference: string;
}

const ActionItemFileUpload: FC<Props> = (props) => {
  const { actionItem, actionItemType, createdAt, zencargoReference } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const { accountUuid: accountId } = useAccount();

  const { name, status } = actionItem;
  const isActionable = status === ActionItemStatus.PENDING;

  const renderButton = (): ReactNode => {
    if (!expanded) {
      return (
        <Button className="fs-af-upload-document" iconLeft="zicon-upload" onClick={() => setExpanded(true)}>
          Upload document
        </Button>
      );
    }

    return (
      <Button onClick={() => setExpanded(false)} variant="tertiary">
        Cancel
      </Button>
    );
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex justify-between w-full">
        <ActionItemDetails actionItem={actionItem} actionItemType={actionItemType} createdAt={createdAt} />
        {isActionable && renderButton()}
      </div>
      {expanded && zencargoReference && (
        <div className="w-full mt-4 bg-grey-lightest p-6 rounded">
          <ActionItemDocumentUpload
            accountId={accountId}
            onSelect={() => setExpanded(false)}
            selectedDocumentType={name}
            zencargoReference={zencargoReference}
          />
        </div>
      )}
    </div>
  );
};

export default ActionItemFileUpload;
