import * as Yup from 'yup';

import { CargoDimensionsUnitEnum, CargoModeEnum, isValidHeightForRoadShipment, maxCargoHeightForRoadShipment } from '@zen/Cargo';
import { positiveWholeNumberValidator } from '@zen/Cargo/forms/validation';
import { ModeOfTransport } from '@zen/types';
import { isValidTime } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

import type { CollectionDates } from '../types';
import { isHaulierFieldRequired } from './helpers';

const getMessage = (fieldName: string): string => `Please select the ${fieldName}`;

const looseCargoTypeValidation = Yup.string().nullable().required(getMessage('loose cargo type'));

const dimensionsValidator = Yup.object().shape({
  height: positiveWholeNumberValidator().test('is-greater-than', function (value: Optional<number>) {
    const metric = this.parent.metric as CargoDimensionsUnitEnum;
    const isValid: boolean = isValidHeightForRoadShipment(value, metric, ModeOfTransport.TRUCK);
    const message: string = `Height can not be greater than ${
      maxCargoHeightForRoadShipment[metric]
    } ${metric.toLocaleLowerCase()}.`;

    return isValid || this.createError({ message });
  })
});

const multiStopAndTruckSwapValidator = Yup.string()
  .nullable()
  .test('multiStopAndTruckSwapValidator', function (value: Optional<string>) {
    if (this.parent.cargoMode === CargoModeEnum.FTL && !value) {
      return this.createError({ message: 'Please select an option.' });
    }

    if (
      this.parent.cargoMode === CargoModeEnum.FTL &&
      this.parent.collection.confirmedByForwarder.date &&
      this.parent.delivery.confirmedByForwarder.date &&
      value === 'UNKNOWN'
    ) {
      return this.createError({ message: 'Please select an option.' });
    }

    return true;
  });

export const createValidationSchema = () =>
  Yup.object().shape({
    cargoItem: Yup.object().when('cargoMode', {
      is: (value: CargoModeEnum) => value === CargoModeEnum.FTL,
      then: Yup.object().shape({
        dimensions: dimensionsValidator,
        extendedLooseCargoType: looseCargoTypeValidation,
        vehicleType: Yup.string().nullable().required(getMessage('vehicle type'))
      }),
      otherwise: Yup.object().shape({
        dimensions: dimensionsValidator,
        extendedLooseCargoType: looseCargoTypeValidation
      })
    }),
    cargoMode: Yup.string().nullable().required(getMessage('cargo type')),
    collectionLocation: Yup.object().nullable().required(getMessage('collection address')),
    consignor: Yup.object().nullable().required(getMessage('consignor')),
    consignee: Yup.object().nullable().required(getMessage('consignee')),
    deliveryLocation: Yup.object().nullable().required(getMessage('delivery address')),
    haulier: Yup.object()
      .nullable()
      .when(['collection', 'customsOnly'], {
        is: (collection: CollectionDates, customsOnly: boolean) => isHaulierFieldRequired(collection, customsOnly),
        then: Yup.object().nullable().required(getMessage('haulier'))
      }),
    incoterms: Yup.string().nullable().required(getMessage('incoterms')),
    shipmentType: Yup.string().nullable().required(getMessage('shipment type')),
    tradeRole: Yup.string().nullable().required(getMessage('trade role')),
    multiStop: multiStopAndTruckSwapValidator,
    truckSwap: multiStopAndTruckSwapValidator
  });

const dateValidator = Yup.string()
  .nullable()
  .when(['startTime', 'endTime'], {
    is: (startTime: string, endTime: string) => startTime || endTime,
    then: Yup.string().nullable().required('Please enter date.')
  });

const startTimeValidator = Yup.string()
  .nullable()
  .test('time validation', function (startTime: Optional<string>) {
    if (this.parent.endTime && !startTime) {
      return this.createError({ message: 'Please enter start time.' });
    }

    if (startTime && !isValidTime(startTime)) {
      return this.createError({ message: 'Please enter a valid start time.' });
    }

    return true;
  });

const endTimeValidator = Yup.string()
  .nullable()
  .test('time validation', function (endTime: Optional<string>) {
    if (!endTime) {
      return true;
    }

    if (!isValidTime(endTime)) {
      return this.createError({ message: 'Please enter a valid end time.' });
    }

    if (this.parent.startTime > endTime) {
      return this.createError({ message: 'End time can not be earlier than start time.' });
    }

    return true;
  });

export const createDateAndTimeValidationSchema = (isRequired: boolean = true, additionalValidators = {}) => {
  if (!isRequired) {
    return Yup.object().shape({
      date: dateValidator,
      startTime: startTimeValidator,
      endTime: endTimeValidator,
      ...additionalValidators
    });
  }

  return Yup.object().shape({
    date: dateValidator.required('Please enter date.'),
    startTime: startTimeValidator.required('Please enter start time.'),
    endTime: endTimeValidator.required('Please enter end time.'),
    ...additionalValidators
  });
};
