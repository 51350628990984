import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type EditOrganisationLocationQueryVariables = GraphQLTypes.Exact<{
  organisationId: GraphQLTypes.Scalars['String'];
  locationId: GraphQLTypes.Scalars['String'];
}>;

export type EditOrganisationLocationQueryResult = { __typename?: 'Query' } & {
  location?: GraphQLTypes.Maybe<
    { __typename?: 'NetworksLocation' } & Pick<GraphQLTypes.NetworksLocation, 'name' | 'id' | 'locationType'> & {
        organisation:
          | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'name'>)
          | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'name'>)
          | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'name'>)
          | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'name'>);
        address: { __typename?: 'Address' } & Pick<
          GraphQLTypes.Address,
          'street' | 'countyOrState' | 'city' | 'postalCodeOrZip'
        > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>> };
        businessHours?: GraphQLTypes.Maybe<
          { __typename?: 'BusinessHours' } & {
            monday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            tuesday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            wednesday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            thursday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            friday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            saturday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
            sunday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
          }
        >;
        geolocation?: GraphQLTypes.Maybe<
          { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
        >;
      }
  >;
};

export const EditOrganisationLocationQueryDocument = /* #__PURE__ */ gql`
  query editOrganisationLocationQuery($organisationId: String!, $locationId: String!) {
    location(organisationId: $organisationId, locationId: $locationId) {
      name
      id
      organisation {
        name
      }
      address {
        street
        country {
          code
          name
        }
        countyOrState
        city
        postalCodeOrZip
      }
      businessHours {
        monday {
          startTime
          endTime
        }
        tuesday {
          startTime
          endTime
        }
        wednesday {
          startTime
          endTime
        }
        thursday {
          startTime
          endTime
        }
        friday {
          startTime
          endTime
        }
        saturday {
          startTime
          endTime
        }
        sunday {
          startTime
          endTime
        }
      }
      geolocation {
        latitude
        longitude
      }
      locationType
    }
  }
`;

/**
 * __useEditOrganisationLocationQuery__
 *
 * To run a query within a React component, call `useEditOrganisationLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOrganisationLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOrganisationLocationQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useEditOrganisationLocationQuery(
  baseOptions: Apollo.QueryHookOptions<EditOrganisationLocationQueryResult, EditOrganisationLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EditOrganisationLocationQueryResult, EditOrganisationLocationQueryVariables>(
    EditOrganisationLocationQueryDocument,
    options
  );
}
export function useEditOrganisationLocationQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EditOrganisationLocationQueryResult, EditOrganisationLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EditOrganisationLocationQueryResult, EditOrganisationLocationQueryVariables>(
    EditOrganisationLocationQueryDocument,
    options
  );
}
export type EditOrganisationLocationQueryHookResult = ReturnType<typeof useEditOrganisationLocationQuery>;
export type EditOrganisationLocationQueryLazyQueryHookResult = ReturnType<typeof useEditOrganisationLocationQueryLazyQuery>;
