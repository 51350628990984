import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

// @ts-expect-error ts-migrate(7010) FIXME: 'useConsumeLocationState', which lacks return-type... Remove this comment to see the full error message
export function useConsumeLocationState<T>(callback: (state: T) => void);
// @ts-expect-error ts-migrate(7010) FIXME: 'useConsumeLocationState', which lacks return-type... Remove this comment to see the full error message
export function useConsumeLocationState<T>(callback: (state: T[keyof T]) => void, stateProp: keyof T);
export function useConsumeLocationState<T>(callback: (state: T | T[keyof T]) => void, stateProp?: keyof T) {
  const history = useHistory();
  const { search, pathname, state } = useLocation<T>();

  useEffect(() => {
    const data = stateProp ? state && state[stateProp] : state;

    if (data) {
      if (stateProp) {
        delete state[stateProp];
      }
      const newState = state;

      history.replace(`${pathname}${search}`, newState);
      callback(data);
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps
}
