import type { FC } from 'react';

import SkeletonLoading from '@zen/Components/SkeletonLoading';

interface Props {
  count?: number;
}

const RepeatedSkeletons: FC<Props> = ({ count }) => (
  <div>
    {[...Array(count)].map((_, i) => (
      <div key={i} className="skeleton-order-element">
        <div className="flex flex-col">
          <SkeletonLoading className="mb-1" height={16} width="w-20" />
          <div className="flex mb-2">
            <SkeletonLoading className="mr-10" height={35} width="w-9/12" />
            <SkeletonLoading height={35} width="w-10" />
          </div>
        </div>
      </div>
    ))}
  </div>
);

const SkeletonEditOrderLoading: FC<Props> = ({ count = 3 }) => {
  return (
    <div className="flex flex-col" data-testid="skeleton-edit-order">
      <div className="px-6 py-6 bg-grey-lightest border-b border-solid border-grey-lighter">
        <SkeletonLoading className="my-2" height={24} lighter={true} width="w-12" />
        <SkeletonLoading className="my-2" height={30} lighter={true} width="w-28" />
      </div>
      <div className="px-6 py-6">
        <div className="flex">
          <div className="flex flex-col mr-10">
            <SkeletonLoading height={16} width="w-20" />
            <SkeletonLoading height={35} width="w-96" />
          </div>
          <div className="flex flex-col">
            <SkeletonLoading height={16} width="w-20" />
            <SkeletonLoading height={35} width="w-40" />
          </div>
        </div>
        <SkeletonLoading className="my-10" height={32} lighter={true} width="w-40" />
        <RepeatedSkeletons count={count} />
        <SkeletonLoading className="my-10" height={32} lighter={true} width="w-60" />
        <RepeatedSkeletons count={count} />
        <SkeletonLoading className="m-2" height={32} width="w-32" />
        <SkeletonLoading className="m-2" height={32} lighter={true} width="w-20" />
      </div>
    </div>
  );
};

export type { Props as SkeletonEditOrderLoadingProps };

export default SkeletonEditOrderLoading;
