import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Loading } from '@zen/DesignSystem';

interface Props {
  className?: string;
  message: ReactNode;
}

const LoadingMessage: FC<Props> = (props) => {
  const { message, className } = props;

  return (
    <div className={cx('flex items-center', className)}>
      <Loading size="small" withMargin={false} />
      <div className="grow ml-3" data-testid="loading-message">
        {message}
      </div>
    </div>
  );
};

export type { Props as LoadingMessageProps };

export default LoadingMessage;
