import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInstance, FormMultiSelect, FormSelect } from '@zen/Components/Form';
import FormModeOfTransportSelect from '@zen/Components/Form/FormModeOfTransportSelect';
import HeadingWithAction from '@zen/Components/HeadingWithAction';
import StickyFooter from '@zen/Components/StickyFooter';
import { Button } from '@zen/DesignSystem';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, FormNetworksContactInput } from '@zen/Networks';
import { stageFilterOptions } from '@zen/Shipments/Filters/helpers';
import StageWithinFields from '@zen/Shipments/Filters/StageWithinFields';
import useAccounts from '@zen/utils/hooks/useAccounts';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { getDivisionOptions } from './helpers';
import type { CustomerAdminShipmentFilters } from './types';

interface Props {
  initialValues: Partial<CustomerAdminShipmentFilters>;
  onClose: () => void;
  onReset: () => void;
  onSubmit: (values: CustomerAdminShipmentFilters) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
}

const FiltersForm: FC<Props> = ({ initialValues, onClose, onReset, onSubmit, onSuccess }) => {
  const { data = [] } = useAccounts();

  const renderFormButtons = ({ isSubmitting }: FormInstance<CustomerAdminShipmentFilters>): ReactNode => (
    <StickyFooter>
      <FormButtons isSubmitting={isSubmitting} text="Apply filters">
        <Button data-testid="reset-btn" onClick={onReset} variant="secondary">
          Clear filters
        </Button>
      </FormButtons>
    </StickyFooter>
  );

  const renderForm = (): ReactNode => {
    return (
      <div className="px-8" data-testid="filters-form">
        <HeadingWithAction onClick={onClose} title="Filter shipments" />
        <div className="overflow-y-scroll" style={{ height: 'calc(100vh - 17rem)' }}>
          <FormMultiSelect label="Division" name="accountIds" options={getDivisionOptions(data)} />
          <FormSelect
            isClearable={true}
            label="Shipment status"
            name="status"
            options={stageFilterOptions}
            placeholder="Select..."
          />
          <FormModeOfTransportSelect isMulti={true} label="Transport mode" name="transportModes" />
          <FormNetworksContactInput
            assignmentTypes={[AssignmentTypeValue.ORIGIN]}
            domainNames={[AssignmentTargetTypeEnum.BOOKING]}
            label="Origin"
            name="origins"
          />
          <FormNetworksContactInput
            assignmentTypes={[AssignmentTypeValue.DESTINATION]}
            domainNames={[AssignmentTargetTypeEnum.BOOKING]}
            label="Destination"
            name="destinations"
          />
          <StageWithinFields />
        </div>
      </div>
    );
  };

  return (
    <Form
      className="w-160"
      enableReinitialize={true}
      formButtons={renderFormButtons}
      formName="customerAdminShipmentFilters"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      {renderForm}
    </Form>
  );
};

export default FiltersForm;
