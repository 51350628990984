import { FC, ReactNode, useState } from 'react';

import EditableSection from '@zen/Components/EditableSection';
import { useForm } from '@zen/Components/Form/useForm';
import type { ButtonVariant } from '@zen/DesignSystem';
import HintWrapper from '@zen/OperationsShipments/components/HintWrapper';
import type { DateWithTimeRangeAndDelayReason, RoadShipmentFields } from '@zen/OperationsShipments/EditRoadShipment/types';
import { shipmentDelayReasonValidation } from '@zen/Shipment/RouteProgress/VoyageMilestones/components/MilestoneDateForm/validation';
import { getToday } from '@zen/utils/date';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import DateWithTimeRangeForm, { FormLabels } from '../../DateWithTimeRangeForm';
import DateWithTimeRangePresenter from '../../DateWithTimeRangePresenter';
import { createDateAndTimeValidationSchema } from '../../RoadShipmentForm.validation';

const CollectedOn: FC = () => {
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  const { initialValues, setFieldValue, values: formValues } = useForm<RoadShipmentFields>();

  const { collection, collectionLocation } = formValues;
  const { collectedOn } = collection;

  const allowDelayReasonEntry: boolean = !!initialValues.collection.collectedOn.date;
  const buttonText: string = collectedOn.date ? 'Edit' : 'Add';
  const buttonVariant: ButtonVariant = collectedOn.date ? 'secondary' : 'primary';

  const handleSubmit = (values: DateWithTimeRangeAndDelayReason): Promise<IOkOrErrorResult> => {
    setFieldValue('collection', { ...collection, collectedOn: values });

    return Promise.resolve({ ok: {}, error: null });
  };

  const handleCancel = (): void => setIsUpdateMode(false);

  const handleSuccess = (): void => setIsUpdateMode(false);

  const renderForm = (): ReactNode => {
    const labels: FormLabels = {
      date: 'Collection date',
      startTime: 'Arrival time',
      endTime: 'Departure time'
    };

    return (
      <DateWithTimeRangeForm
        disabledDayTooltip="Date cannot be in the future"
        initialValues={collectedOn}
        labels={labels}
        maxDate={getToday()}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        timeZone={collectionLocation?.timeZone}
        validationSchema={createDateAndTimeValidationSchema(false, shipmentDelayReasonValidation)}
        withDelayReason={allowDelayReasonEntry}
      />
    );
  };

  const renderValues = (): ReactNode => {
    return <DateWithTimeRangePresenter dateLabel="Collection date" dateWithTimeRange={collectedOn} timeLabel="Time" />;
  };

  return (
    <div data-testid="collected-on">
      <HintWrapper hintText="This is the actual date the collection happened">
        <EditableSection
          buttonText={buttonText}
          buttonVariant={buttonVariant}
          isUpdateMode={isUpdateMode}
          label="Collected on date"
          onUpdate={() => setIsUpdateMode(true)}
        >
          {isUpdateMode ? renderForm() : renderValues()}
        </EditableSection>
      </HintWrapper>
    </div>
  );
};

export default CollectedOn;
