import { createContext, useState } from 'react';

export interface SearchQuery {
  searchQuery: string;
  setSearchQuery: (searchString: string) => void;
}

const initialContext = {
  searchQuery: '',
  setSearchQuery: () => {}
};

const SearchQueryContext = createContext<SearchQuery>(initialContext);

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const SearchQueryContextProvider = ({ children }) => {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
  const [searchQuery, setSearchQuery] = useState<string>(undefined);

  return (
    <SearchQueryContext.Provider
      value={{
        searchQuery,
        setSearchQuery
      }}
    >
      {children}
    </SearchQueryContext.Provider>
  );
};

export default SearchQueryContext;
