import type { FC } from 'react';
import { Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { opsShipmentRoutes } from '@zen/Routes';
import ShipmentDetailsPageContainer from '@zen/Shipment/ShipmentDetailsPageContainer';

import AllShipments from './AllShipments';
import EditRoadShipment from './EditRoadShipment';
import OperationsShipments from './OperationsShipments';
import RoadShipments from './RoadShipments';

const OperationsShipmentsContainer: FC = () => {
  return (
    <Switch>
      <Route path={opsShipmentRoutes.editRoadShipment.path}>
        <EditRoadShipment />
      </Route>
      <Route path={opsShipmentRoutes.allShipmentsDetailsPage.path}>
        <ShipmentDetailsPageContainer />
      </Route>
      <Route path={opsShipmentRoutes.allShipments.path}>
        <OperationsShipments>
          <AllShipments />
        </OperationsShipments>
      </Route>
      <Route path={opsShipmentRoutes.roadShipmentsDetailsPage.path}>
        <ShipmentDetailsPageContainer />
      </Route>
      <Route path={opsShipmentRoutes.roadShipments.path}>
        <OperationsShipments>
          <RoadShipments />
        </OperationsShipments>
      </Route>
      <Redirect to={opsShipmentRoutes.allShipments.path} />
    </Switch>
  );
};

export default OperationsShipmentsContainer;
