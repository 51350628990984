import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ScheduleDeliveryMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ScheduleDeliveryInput;
}>;

export type ScheduleDeliveryMutationResult = { __typename?: 'Mutation' } & {
  bookingCollectionAndDeliveryScheduleDelivery?: GraphQLTypes.Maybe<
    { __typename?: 'ScheduleDeliveryPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const ScheduleDeliveryMutationDocument = /* #__PURE__ */ gql`
  mutation scheduleDeliveryMutation($input: ScheduleDeliveryInput!) {
    bookingCollectionAndDeliveryScheduleDelivery(input: $input) {
      errors {
        message
      }
    }
  }
`;

/**
 * __useScheduleDeliveryMutation__
 *
 * To run a mutation, you first call `useScheduleDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleDeliveryMutation, { data, loading, error }] = useScheduleDeliveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleDeliveryMutation(
  baseOptions?: Apollo.MutationHookOptions<ScheduleDeliveryMutationResult, ScheduleDeliveryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ScheduleDeliveryMutationResult, ScheduleDeliveryMutationVariables>(
    ScheduleDeliveryMutationDocument,
    options
  );
}
export type ScheduleDeliveryMutationHookResult = ReturnType<typeof useScheduleDeliveryMutation>;
