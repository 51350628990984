import type { FC, ReactNode } from 'react';

import PageContent from '../PageContent';
import RouteTabs from '../RouteTabs';
import RouteTabSwitcher from '../RouteTabSwitcher';

export interface TabConfig {
  component: ReactNode;
  isDefault?: boolean;
  label: ReactNode;
  path: string;
  routePath?: string;
  totalCount?: number;
}

interface Props {
  contentClassName?: string;
  header: ReactNode;
  headerClassName?: string;
  headerMargin?: string;
  tabsConfig: TabConfig[];
}

const PageWithTabs: FC<Props> = ({ tabsConfig, header, contentClassName, headerClassName = '', headerMargin = 'mb-6' }) => {
  return (
    <>
      <div
        className={`flex border-b border-solid border-grey-lighter bg-grey-lightest ${headerMargin} ${headerClassName}`}
        data-testid="page-with-tabs"
      >
        <div className="w-full">
          <div className="pt-4">{header}</div>
          <PageContent width="wide">
            <div className="bottom-0">
              <RouteTabs tabsConfig={tabsConfig} />
            </div>
          </PageContent>
        </div>
      </div>
      <div className={contentClassName} id="pageContent">
        <RouteTabSwitcher tabsConfig={tabsConfig} />
      </div>
    </>
  );
};

export default PageWithTabs;
