import type { FC } from 'react';

import { Pill } from '@zen/DesignSystem';

import { isAccrualPublished } from '../helpers';
import { AccrualState } from '../types';

interface Props {
  state: AccrualState;
}

const statusLabels = {
  [AccrualState.DRAFT]: 'Draft',
  [AccrualState.PUBLISHED]: 'Published',
  [AccrualState.QUOTE_OPTION]: 'Quote option'
};

const AccrualStatePill: FC<Props> = (props) => {
  const { state } = props;
  const pillType = isAccrualPublished(state) ? 'default' : 'pending';
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const label: string = statusLabels[state];

  return <Pill type={pillType}>{label}</Pill>;
};

export default AccrualStatePill;
