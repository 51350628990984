import type { RoutesConfig } from './types';

export const REQUESTS_ROUTE_PREFIX = '/dashboard/booking-requests';

interface BookingRequestRoutes extends RoutesConfig {
  manufacturerRequestDetails: {
    getUrl: (zencargoReference: string, tab?: string) => string;
    path: string;
  };
  manufacturerRequests: {
    getUrl: () => string;
    path: string;
  };
  manufacturerRequestsDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
  };
  readyForApproval: {
    getUrl: () => string;
    path: string;
  };
  readyForApprovalDetails: {
    getUrl: (zencargoReference: string, tab?: string) => string;
    path: string;
  };
  readyForApprovalDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
  };
  rejected: {
    getUrl: () => string;
    path: string;
  };
  rejectedDetails: {
    getUrl: (zencargoReference: string, tab?: string) => string;
    path: string;
  };
  rejectedDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
  };
  requestIndex: {
    getUrl: () => string;
    path: string;
  };
}

const bookingRequestRoutes: BookingRequestRoutes = {
  requestIndex: {
    path: REQUESTS_ROUTE_PREFIX,
    getUrl: () => REQUESTS_ROUTE_PREFIX
  },
  manufacturerRequests: {
    path: `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests`,
    getUrl: () => `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests`
  },
  manufacturerRequestDetails: {
    path: `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests/:id?`,
    getUrl: (zencargoReference: string, tab?: string): string => {
      const url: string = `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests/${zencargoReference}`;

      return `${url}/${tab || 'activity'}`;
    }
  },
  manufacturerRequestsDetailsPage: {
    path: `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  readyForApproval: {
    path: `${REQUESTS_ROUTE_PREFIX}/ready-for-approval`,
    getUrl: () => `${REQUESTS_ROUTE_PREFIX}/ready-for-approval`
  },
  readyForApprovalDetails: {
    path: `${REQUESTS_ROUTE_PREFIX}/ready-for-approval/:id?`,
    getUrl: (zencargoReference: string, tab?: string): string => {
      const url: string = `${REQUESTS_ROUTE_PREFIX}/ready-for-approval/${zencargoReference}`;

      return `${url}/${tab || 'activity'}`;
    }
  },
  readyForApprovalDetailsPage: {
    path: `${REQUESTS_ROUTE_PREFIX}/ready-for-approval/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${REQUESTS_ROUTE_PREFIX}/ready-for-approval/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  rejected: {
    path: `${REQUESTS_ROUTE_PREFIX}/rejected`,
    getUrl: () => `${REQUESTS_ROUTE_PREFIX}/rejected`
  },
  rejectedDetails: {
    path: `${REQUESTS_ROUTE_PREFIX}/rejected/:id?`,
    getUrl: (zencargoReference: string, tab?: string): string => {
      const url: string = `${REQUESTS_ROUTE_PREFIX}/rejected/${zencargoReference}`;

      return `${url}/${tab || 'activity'}`;
    }
  },
  rejectedDetailsPage: {
    path: `${REQUESTS_ROUTE_PREFIX}/rejected/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${REQUESTS_ROUTE_PREFIX}/rejected/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  }
};

export default bookingRequestRoutes;
