import { isEqual } from 'lodash';
import moment from 'moment';
import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import type { NewCargo } from '@zen/Cargo';
import { useCollectionAndDelivery } from '@zen/CollectionAndDelivery/CollectionAndDeliveryContext';
import { getCargoAddressLabel } from '@zen/CollectionAndDelivery/collectionAndDeliveryHelper';
import ScheduleActionFormButtons from '@zen/CollectionAndDelivery/Details/ScheduleActionFormButtons';
import { useScheduleCollectionMutation, useUpdateDeliveryMutation } from '@zen/CollectionAndDelivery/graphql';
import { useAddDelayReasonMutation } from '@zen/CollectionAndDelivery/graphql/addDelayReason.generated';
import SchedulerHeader, { ScheduleType } from '@zen/CollectionAndDelivery/SchedulerHeader/SchedulerHeader';
import FormTimeConsigment from '@zen/CollectionAndDelivery/TimedConsignment/FormTimeConsigment';
import type { CargoAddress } from '@zen/CollectionAndDelivery/types';
import { Form, FormCheckbox, FormInput } from '@zen/Components/Form';
import FormDatePicker from '@zen/Components/Form/FormDatePicker';
import { CollectionDetails, CollectionSchedule, TimedCollection, VoyageMilestoneNameEnum } from '@zen/graphql/types.generated';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks/types';
import FormMilestoneDelayReasonFields from '@zen/Shipment/RouteProgress/VoyageMilestones/components/FormMilestoneDelayReasonFields';
import {
  getDateValidationMessage,
  getRangeValidationMessage,
  useMilestoneDateValidation
} from '@zen/Shipment/ShipmentDetailsPage/MilestoneDateProvider';
import { isRoadShipment, ModeOfTransport } from '@zen/Shipments';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';
import { useMutationIteration } from '@zen/utils/hooks/useMutationIteration';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { shouldSynchroniseRoadDetails } from '../helpers';
import ScheduleRoadFields from '../ScheduleRoadFields';
import type { RoadFields } from '../ScheduleRoadFields/types';
import CollectionRisk from './CollectionRisk';
import { createValidationSchema } from './ScheduleCollectionForm.validation';

interface CollectionFormValues extends RoadFields {
  collectionDate: string;
  collectionFrom: CargoAddress;
  collectionReference: string;
  collectionTime?: TimedCollection;
  description?: Optional<string>;
  reason?: Optional<string>;
  showTimedCollection: boolean;
}

interface Props {
  accountUuid: string;
  cargoList: NewCargo[];
  modeOfTransport: ModeOfTransport;
  onCancel?: () => void;
  onError?: (errorItems: string[], message: string) => void;
  onSuccess?: () => void;
  zencargoReference: string;
}

const ScheduleCollectionForm: FC<Props> = (props) => {
  const { accountUuid, cargoList, modeOfTransport, onCancel = () => {}, onSuccess, zencargoReference, onError } = props;
  const multiCargo = cargoList.length > 1;
  const { addSuccess, addError } = useNotification();
  const { deliveryScheduleRequired, estimatedDeparture, polDemurrageFreeTimeBegins: freeTimeBegins } = useCollectionAndDelivery();
  const { minDate, maxDate, isValid } = useMilestoneDateValidation(VoyageMilestoneNameEnum.COLLECTED);
  const allowDelayReasonEntry: boolean = cargoList.some((cargo) => !!cargo.collection?.scheduleDate);

  const handleError = (errorItems: NewCargo[]) => {
    const errorIds: string[] = errorItems.map(({ id }) => id);
    const totalErrors = errorItems.length;
    const errorMessage = multiCargo
      ? `There was an error scheduling ${totalErrors} cargo`
      : 'There was an error scheduling this cargo';

    if (onError) {
      onError(errorIds, errorMessage);
    }
  };

  const handleSuccess = (successResult: string) => {
    const successMessage = multiCargo ? `${successResult} selected cargo have been scheduled` : 'Cargo has been scheduled';

    addSuccess(successMessage);
  };

  const { handleMutation, loading } = useMutationIteration(cargoList, handleSuccess, handleError);

  const [scheduleCollection] = useScheduleCollectionMutation({
    refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
    awaitRefetchQueries: true
  });
  const [updateDelivery] = useUpdateDeliveryMutation({
    refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
    awaitRefetchQueries: true
  });

  const [addDelayReason] = useAddDelayReasonMutation();

  const handleScheduleCollection = async (values: CollectionSchedule): Promise<IOkOrErrorResult> => {
    const mutate = async (cargo: NewCargo) => {
      const submitData = {
        zencargoReference,
        id: cargo.id,
        schedule: values
      };

      return scheduleCollection({ variables: { input: { ...submitData } } });
    };

    return handleMutation(mutate);
  };

  const getCollectionValue = (): Nullable<CollectionDetails> => {
    if (cargoList?.length === 0) {
      return null;
    }

    const hasSameValues = cargoList.every((cargo) => isEqual(cargo.collection, cargoList[0].collection));

    return hasSameValues && cargoList[0].collection ? cargoList[0].collection : null;
  };

  const getDefaultCollectionDate = (): string => {
    const defaultDate: string = minDate || moment.utc().format();

    return estimatedDeparture ? moment.utc(estimatedDeparture).subtract(1, 'day').format() : defaultDate;
  };

  const cargoCollection: Nullable<CollectionDetails> = getCollectionValue();

  const collectionFrom = cargoCollection?.location
    ? { ...cargoCollection.location, name: getCargoAddressLabel(cargoCollection.location) }
    : null;

  const collectionTime = cargoCollection?.timedDelivery
    ? { mode: cargoCollection.timeMode, specificTime: cargoCollection.specificTime }
    : null;

  const initialValues: CollectionFormValues = {
    collectionDate: getDefaultCollectionDate(),
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: Maybe<string> | undefined; id?: Mayb... Remove this comment to see the full error message
    collectionFrom,
    collectionReference: cargoCollection?.reference || '',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ mode: Maybe<Mode> | undefined; specificTim... Remove this comment to see the full error message
    collectionTime,
    driverDetails: cargoCollection?.driverDetails,
    driverDetailsEqual: false,
    showTimedCollection: cargoCollection?.timedDelivery || false,
    vehiclePlateNumber: cargoCollection?.vehiclePlateNumber,
    vehiclePlateNumberEqual: false,
    reason: null,
    description: null
  };

  const synchroniseRoadDetails = async (values: CollectionFormValues, cargoItems: NewCargo[]) => {
    const { driverDetails, driverDetailsEqual, vehiclePlateNumber, vehiclePlateNumberEqual } = values;

    const results = cargoItems.map((cargo) => {
      return performMutation({
        mutationFn: () =>
          updateDelivery({
            variables: {
              input: {
                zencargoReference,
                cargoId: cargo.id!,
                driverDetails: driverDetailsEqual ? driverDetails : undefined,
                vehiclePlateNumber: vehiclePlateNumberEqual ? vehiclePlateNumber : undefined
              }
            }
          }),
        onError: () => addError()
      });
    });

    return Promise.all(results);
  };

  const handleSubmit = async (values: CollectionFormValues): Promise<IOkOrErrorResult> => {
    const { collectionReference, showTimedCollection, vehiclePlateNumber, driverDetails, reason, description } = values;

    const schedule = {
      collectionDate: moment(values.collectionDate).format(FORMAT_DATE_TRANSFERABLE),
      collectionReference,
      collectionFrom: values.collectionFrom && { locationId: values.collectionFrom.id! },
      driverDetails,
      vehiclePlateNumber,
      ...(showTimedCollection && values.collectionTime && { collectionTime: values.collectionTime })
    };

    await handleScheduleCollection(schedule);

    if (shouldSynchroniseRoadDetails(values)) {
      await synchroniseRoadDetails(values, cargoList);
    }

    if (reason) {
      performMutation({
        mutationFn: () =>
          addDelayReason({
            variables: {
              zencargoReference,
              milestoneName: VoyageMilestoneNameEnum.COLLECTED,
              delay: { reason, description: description || '' }
            }
          }),
        onError: addError
      });
    }

    return Promise.resolve({ ok: true, error: null });
  };

  const renderFormButtons = () => <ScheduleActionFormButtons isSubmitting={loading} onCancel={onCancel} />;

  const riskStrategy = new CollectionRisk({
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    estimatedDeparture,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    freeTimeBegins
  });
  const canManageCollectionLocation = cargoList.every((cargo) => {
    return checkPermission<NewCargo>(cargo, 'canManageCollectionLocation');
  });

  return (
    <div>
      <SchedulerHeader freeTime={freeTimeBegins} schedule={ScheduleType.COLLECTION} scheduleDate={estimatedDeparture} />
      <Form
        enableReinitialize={true}
        formButtons={renderFormButtons}
        formName="ScheduleCollectionForm"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        validationSchema={createValidationSchema()}
      >
        {(form) => (
          <div>
            <NetworksContactFormInput
              accountUuid={accountUuid}
              assignmentType={AssignmentTypeValue.COLLECTION_LOCATION}
              domainName={AssignmentTargetTypeEnum.BOOKING_CARGO}
              formTitle="Add location"
              isDisabled={!canManageCollectionLocation}
              label="Collection from"
              name="collectionFrom"
            />
            <div>
              <FormInput className="w-2/3" name="collectionReference" />
            </div>
            <FormDatePicker
              disabledDayTooltip={getDateValidationMessage(minDate, maxDate)}
              helperText={getRangeValidationMessage(!isValid)}
              initialDate={minDate}
              label="Collection date and time"
              maxDate={maxDate}
              minDate={minDate}
              name="collectionDate"
            />
            <div className="text-red-base align-middle font-bold text-sm" data-testid="date-risk-warning">
              {riskStrategy.dateRisk(form.values.collectionDate)}
            </div>
            <div>
              <FormCheckbox label="I would like a timed collection" name="showTimedCollection" />
              {form.values.showTimedCollection && (
                <FormTimeConsigment name="collectionTime" noMargin={true} riskStrategy={riskStrategy} />
              )}
            </div>
            {isRoadShipment(modeOfTransport) && <ScheduleRoadFields isBulkUpdateEnabled={deliveryScheduleRequired} />}
            {allowDelayReasonEntry && <FormMilestoneDelayReasonFields />}
          </div>
        )}
      </Form>
    </div>
  );
};

export default ScheduleCollectionForm;
