import type { AssignmentTargetTypeEnum, AssignmentTypeValue } from '../../types';
import { UsedAssignablesDocument } from '..';

export default (
  targetTypes: AssignmentTargetTypeEnum[],
  assignmentNames: AssignmentTypeValue[],
  networkIds = ['fake-account-id'],
  textContains: string = ''
) => ({
  request: {
    query: UsedAssignablesDocument,
    variables: {
      networkIds,
      targetTypes,
      assignmentNames,
      ...(textContains ? { textContains } : {})
    }
  },
  result: jest.fn(() => ({
    data: {
      networksUsedAssignables: [
        {
          id: 'id-1',
          label: {
            long: 'Zen Demo, UK DC, London, United Kingdom',
            short: 'Zen Demo, UK DC',
            __typename: 'NetworksLabelType'
          },
          __typename: 'NetworksOrgLoc'
        },
        {
          id: 'id-2',
          label: {
            long: 'New Company, Test Location, Poznań, Poland',
            short: 'New Company, Test Location',
            __typename: 'NetworksLabelType'
          },
          __typename: 'NetworksOrgLoc'
        }
      ]
    }
  }))
});
