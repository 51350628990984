import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCurrentUserQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetCurrentUserQueryResult = { __typename?: 'Query' } & {
  authorization: { __typename?: 'Authorization' } & Pick<GraphQLTypes.Authorization, 'isAdmin'>;
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'name' | 'isDivision' | 'isParent'> & {
        division?: GraphQLTypes.Maybe<{ __typename?: 'Division' } & Pick<GraphQLTypes.Division, 'name' | 'parentAccountId'>>;
      }
  >;
  currentUser?: GraphQLTypes.Maybe<
    { __typename?: 'CurrentUser' } & Pick<GraphQLTypes.CurrentUser, 'uuid'> & {
        profile?: GraphQLTypes.Maybe<
          { __typename?: 'current_user_profile' } & Pick<
            GraphQLTypes.CurrentUserProfile,
            'firstName' | 'lastName' | 'email' | 'phoneNumber'
          >
        >;
        preferences?: GraphQLTypes.Maybe<{ __typename?: 'Preference' } & Pick<GraphQLTypes.Preference, 'roles' | 'level'>>;
        featureFlags: Array<
          { __typename?: 'current_user_feature_flag' } & Pick<GraphQLTypes.CurrentUserFeatureFlag, 'name' | 'enabled'>
        >;
      }
  >;
};

export const GetCurrentUserDocument = /* #__PURE__ */ gql`
  query getCurrentUser($accountId: String!) {
    authorization {
      isAdmin
    }
    account(uuid: $accountId) {
      name
      isDivision
      isParent
      division {
        name
        parentAccountId
      }
    }
    currentUser {
      uuid
      profile {
        firstName
        lastName
        email
        phoneNumber
      }
      preferences {
        roles
        level
      }
      featureFlags(accountId: $accountId) {
        name
        enabled
      }
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetCurrentUserQueryResult, GetCurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCurrentUserQueryResult, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQueryResult, GetCurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCurrentUserQueryResult, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
