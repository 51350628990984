import type { Nullable } from '@zen/utils/typescript';

import type { ScheduleDetails } from '../types';
import type { ScheduleDetailsValues } from './ScheduleDetailsForm/types';
import type { RoadFields } from './ScheduleRoadFields/types';

export const areValuesEqual = (values: string[]): boolean =>
  values.every((value: string, i: number, arr: string[]) => value === arr[0]);

export const getField = (schedules: ScheduleDetailsType[], field: keyof ScheduleDetailsType): Nullable<string> => {
  const fields: string[] = schedules.filter((schedule) => schedule[field]).map((schedule) => schedule[field]!);

  return areValuesEqual(fields) ? fields[0] : null;
};

type ScheduleDetailsType = Pick<ScheduleDetails, 'driverDetails' | 'reference' | 'vehiclePlateNumber'>;

export const prepareScheduleDetailsInitialValues = <T extends ScheduleDetailsType>(schedules: T[]): ScheduleDetailsValues => {
  return {
    driverDetails: getField(schedules, 'driverDetails'),
    driverDetailsEqual: false,
    reference: getField(schedules, 'reference'),
    vehiclePlateNumber: getField(schedules, 'vehiclePlateNumber'),
    vehiclePlateNumberEqual: false
  };
};

export const shouldSynchroniseRoadDetails = <T extends RoadFields>(values: T): boolean => {
  const { driverDetailsEqual, vehiclePlateNumberEqual } = values;

  return driverDetailsEqual || vehiclePlateNumberEqual;
};
