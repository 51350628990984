import type { FC } from 'react';

import type { CargoType } from '@zen/Cargo';
import type { NewCargoType } from '@zen/types';

import { getCargoImage, getCargoLabel } from './cargoTypeImageLabel.helper';

interface Props {
  cargoType: NewCargoType | CargoType;
  className?: string;
  isNewCargo?: boolean;
}

const CargoTypeImage: FC<Props> = ({ className, cargoType, isNewCargo }) => {
  const cargoImage: string = getCargoImage(cargoType, isNewCargo);
  const cargoLabel: string = getCargoLabel(cargoType, isNewCargo);

  return <img alt={cargoLabel} className={className} data-testid="cargo-type-image" src={cargoImage} />;
};

export default CargoTypeImage;
