import { FC, ReactChild, ReactNode, useState } from 'react';

import type { CreateLooseCargoItemInput, LooseCargoFormData } from '@zen/Cargo';
import { CargoManagementTrackingAction, CargoManagementTrackingCategory, LooseCargoTypeEnum, PalletTypeEnum } from '@zen/Cargo';
import CargoSelector from '@zen/Cargo/components/CargoSelector';
import LooseForm from '@zen/Cargo/forms/LooseForm';
import { useCreateLooseCargoItemMutation } from '@zen/Cargo/graphql';
import { prepareLooseCargoRequestPayload } from '@zen/Cargo/helpers';
import type { FormInstance } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import { CargoItemTypeEnum } from '@zen/graphql/types.generated';
import type { ModeOfTransport } from '@zen/Shipment';
import type { NewCargoType } from '@zen/types';
import { cargoValues } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { legacyNewTypesMapping } from '../cargoDictionaryMapping.helper';
import CargoFormButtons from '../components/CargoFormButtons';
import CargoFormWrapper from '../components/CargoFormWrapper';
import { commonCargoOptions, otherCargoOptions } from './helpers';

type CargoType = PalletTypeEnum | LooseCargoTypeEnum;

export interface Props {
  modeOfTransport: ModeOfTransport;
  onSuccess: () => void;
  zencargoReference: string;
}

const NewLooseCargo: FC<Props> = ({ modeOfTransport, onSuccess, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const [selectedCargoType, setSelectedCargoType] = useState<Nullable<CargoType>>();
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const { trackEvent } = useTracking();
  const [createLooseCargo] = useCreateLooseCargoItemMutation();

  const handleSubmit = async (values: LooseCargoFormData): Promise<IOkOrErrorResult> => {
    const input: CreateLooseCargoItemInput = {
      zencargoReference,
      ...prepareLooseCargoRequestPayload(values)
    };

    return performFormMutation({
      mutationFn: () =>
        createLooseCargo({
          variables: {
            input
          },
          refetchQueries: ['cargoSummary', 'voyageMilestones', 'getRouteProgressDetails', 'getTrackingData'],
          awaitRefetchQueries: true
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (): void => {
    addSuccess('New cargo has been created');
    setInitialState();
    onSuccess();

    trackEvent({
      category: CargoManagementTrackingCategory,
      action: CargoManagementTrackingAction.ADD_NEW_CARGO_ITEM,
      label: CargoItemTypeEnum.LOOSE_CARGO
    });
  };

  const handleCargoTypeSelect = (cargoType: CargoType): void => {
    setSelectedCargoType(cargoType);
  };

  const handleButtonClick = (): void => {
    setIsFormVisible(true);
  };

  const setInitialState = (): void => {
    setIsFormVisible(false);
    setSelectedCargoType(null);
  };

  const renderButton = (): ReactChild => {
    return (
      <div className="flex mb-4">
        <Button className="inline-block" iconLeft="zicon-add" onClick={handleButtonClick} variant="secondary">
          Add cargo
        </Button>
      </div>
    );
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<LooseCargoFormData>): ReactNode => {
    return <CargoFormButtons isSubmitting={isSubmitting} onCancel={setInitialState} submitButtonText="Add" />;
  };

  const getInitialValues = (): Partial<LooseCargoFormData> => {
    if (!selectedCargoType) {
      return {};
    }

    const looseNewCargoType: NewCargoType = legacyNewTypesMapping[selectedCargoType];
    const isAssortedCargo: boolean = looseNewCargoType === cargoValues.looseAssortedCargo;
    const isBoxesOrCratesCargo: boolean = looseNewCargoType === cargoValues.looseBoxesOrCrates;
    const looseCargoType: NewCargoType = isAssortedCargo || isBoxesOrCratesCargo ? looseNewCargoType : cargoValues.loosePallet;

    return {
      looseCargoType,
      cargoType: legacyNewTypesMapping[selectedCargoType]
    };
  };

  const renderForm = (): ReactChild => {
    if (!selectedCargoType) {
      return (
        <CargoSelector<CargoType>
          commonCargoTypes={commonCargoOptions}
          onCancel={setInitialState}
          onSelect={handleCargoTypeSelect}
          otherCargoLabel="Other cargo"
          otherCargoOptions={otherCargoOptions}
        />
      );
    }

    return (
      <CargoFormWrapper>
        <LooseForm
          formButtons={renderFormButtons}
          initialValues={getInitialValues()}
          modeOfTransport={modeOfTransport}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
        />
      </CargoFormWrapper>
    );
  };

  return (
    <div className="mt-3" data-testid="new-loose-cargo">
      {isFormVisible ? renderForm() : renderButton()}
    </div>
  );
};

export default NewLooseCargo;
