import { isEqual, isNil } from 'lodash';
import type { ReactNode } from 'react';
import SelectComponent from 'react-select';

import type { Nullable, Optional } from '@zen/utils/typescript';

import { ClearIndicator, getMenuList, getNoOptionsMessage, OverLabel, ValueContainer } from '../components';
import { customStyles, customTheme } from '../select-styles';
import type { CommonSelectProps, Option, SelectStyle } from '../types';

interface Props<Value> extends CommonSelectProps<Value> {
  onChange?: (value: Nullable<Value>) => void;
  overLabel?: string;
  showSearchIcon?: boolean;
  value?: Optional<Value>;
}

const Select = <Value extends Nullable<{}>>(props: Props<Value>) => {
  const {
    autoFocus,
    className,
    dropdownFooterRenderer,
    formatOptionLabel,
    hasError,
    isClearable,
    isDisabled,
    isLoading,
    inputValue,
    isSearchable,
    name,
    onChange,
    onInputChange,
    options,
    shouldFilterResults = true,
    overLabel,
    placeholder,
    showSearchIcon,
    suggestion,
    value
  } = props;

  const selectedOption: Optional<Option<Value>> =
    options.find((option: Option<Value>) => option && isEqual(option.value, value)) || null;

  const handleChange = (option: Nullable<Option<Value>>): void => {
    onChange?.(option?.value || null);
  };

  const selectStyle: SelectStyle = overLabel ? 'inline' : 'outlined';

  const formatLabel = (option: Option<Value>): ReactNode => {
    return <div data-testid="select-option">{formatOptionLabel?.(option) || option.label}</div>;
  };

  const handleInputChange = (query: Nullable<string>) => {
    onInputChange?.(query || '');
  };

  const filterOption = shouldFilterResults ? undefined : () => true;

  return (
    <div className="relative min-w-20">
      <OverLabel htmlFor={name} overLabel={overLabel} />
      <SelectComponent
        autoFocus={autoFocus}
        className={className}
        components={{
          ClearIndicator,
          MenuList: getMenuList(dropdownFooterRenderer),
          NoOptionsMessage: getNoOptionsMessage<Option<Value>, false>(suggestion),
          ...(showSearchIcon ? { ValueContainer } : {})
        }}
        filterOption={filterOption}
        formatOptionLabel={formatLabel}
        inputId={name}
        inputValue={inputValue}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isSearchable={isSearchable}
        menuPlacement="auto"
        name={name}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles(hasError, selectStyle, isClearable && !isNil(value))}
        theme={customTheme}
        value={selectedOption}
      />
    </div>
  );
};

export type { Props as SelectProps };

export default Select;
