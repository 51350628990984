import { Toggle, ToggleOption } from '@zen/DesignSystem';

import FormField, { FormFieldProps } from '../FormField';
import type { FormInstance } from '../types';

interface Props<T> extends FormFieldProps {
  inline?: boolean;
  options: ToggleOption<T>[];
}

function FormToggle<T = string>(props: Props<T>) {
  return (
    <FormField {...props} type="radio">
      {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type. */}
      {(field, form: FormInstance<unknown>) => {
        const handleChange = (value: T): void => form.setFieldValue(field.name, value);

        return <Toggle {...field} onChange={handleChange} />;
      }}
    </FormField>
  );
}

export default FormToggle;
