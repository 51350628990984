import moment from 'moment';

import LabelledDateType from './LabelledDateType';

interface LabelledDateProps {
  date: string;
  label: string;
  type: LabelledDateType;
}

const dateStyle = (type: LabelledDateType) => {
  switch (type) {
    case LabelledDateType.info:
      return 'flex text-sm text-navy-base mr-2';
    case LabelledDateType.warning:
      return 'flex text-sm text-red-base mr-2';
  }
};

const LabelledDate = ({ label, date, type }: LabelledDateProps) => {
  const formattedDate = moment(date).format('MMM D, YYYY');

  return (
    <div className={dateStyle(type)}>
      <div className="mr-1">{label}</div>
      <div>{formattedDate}</div>
    </div>
  );
};

export default LabelledDate;
