import type { Money } from '@zen/types';
import { formatCurrency, formatNumber } from '@zen/utils/formatting';

import { getMonthDayYear } from '../chartHelpers';

interface ChartHeaderProps {
  endDate: string;
  startDate: string;
  totalQuantityArrived: number;
  totalQuantityPlanned: number;
  totalValueArrived: Money;
  totalValuePlanned: Money;
}

const ChartHeader = ({
  startDate,
  endDate,
  totalQuantityPlanned,
  totalQuantityArrived,
  totalValuePlanned,
  totalValueArrived
}: ChartHeaderProps) => (
  <div className="flex justify-end w-full pb-8 justify-between pr-8 text-grey-base">
    <div className="w-1/2 ml-16">
      <p className="text-xl text-navy-dark font-bold pb-3">Weekly intake overview</p>
      <p>
        Displaying intake for {getMonthDayYear(startDate)} - {getMonthDayYear(endDate)}
      </p>
    </div>
    <div>
      <div className="flex pb-4">
        <div className="bg-azure-base p-2 mr-2 rounded-full" />
        <p>Planned total</p>
      </div>
      <div className="flex">
        <div className="bg-navy-base p-2 mr-2 rounded-full" />
        <p>Arrived total</p>
      </div>
    </div>
    <div>
      <p className="pb-4">Qty {formatNumber(totalQuantityPlanned)}</p>
      <p>Qty {formatNumber(totalQuantityArrived)}</p>
    </div>
    <div>
      <p className="pb-4">Value {formatCurrency(totalValuePlanned.currency, +totalValuePlanned.value, 'symbol', true)} </p>
      <p>Value {formatCurrency(totalValueArrived.currency, +totalValueArrived.value, 'symbol', true)}</p>
    </div>
  </div>
);

export default ChartHeader;
