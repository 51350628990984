interface ListItemProps {
  isSelected: boolean;
  item: ListItemType;
  onClick?: () => void;
  selectedItemCustomStyles?: string;
}

export interface ListItemType {
  id: string;
  label: string;
}

const ListItem = (props: ListItemProps) => {
  const { isSelected, item, onClick = () => false, selectedItemCustomStyles } = props;
  let selectedListItemStyles = isSelected && 'bg-navy-base text-white';

  if (selectedItemCustomStyles) {
    selectedListItemStyles = ` ${selectedItemCustomStyles}`;
  }

  return (
    <div
      className={`flex h-12 w-full items-center border border-solid border-grey-lighter font-bold rounded \
          cursor-pointer mr-4 p-4 hover:bg-grey-lightest hover:text-grey-dark truncate text-left ${selectedListItemStyles}`}
      data-testid="searchable-list-item"
      onClick={onClick}
    >
      {item.label}
    </div>
  );
};

export default ListItem;
