import cx from 'classnames';
import pluralize from 'pluralize';
import type { FC } from 'react';

import LoadingDots from '@zen/Components/LoadingDots';

import type { TotalCountConfig } from '../types';

interface Props {
  className?: string;
  totalCountConfig: TotalCountConfig;
}

const renderTotalCount = (totalCount: number, entityName?: string): string => {
  return `${totalCount} ${pluralize(entityName || 'result', totalCount)} found`;
};

const TableTotal: FC<Props> = ({ className, totalCountConfig }) => {
  const { isLoading, totalCount, entityName } = totalCountConfig;

  return (
    <div className={cx('h-4', className)} data-component="table-total" data-testid="table-total">
      {isLoading && <LoadingDots />}
      {!isLoading && <span className="text-grey-dark text-xs leading-normal">{renderTotalCount(totalCount, entityName)}</span>}
    </div>
  );
};

export type { Props as TableTotalProps };

export default TableTotal;
