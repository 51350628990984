import cx from 'classnames';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@zen/DesignSystem';
import type { LocationDescriptor } from '@zen/types';

interface Props {
  className?: string;
  isExternal?: boolean;
  isStandalone?: boolean;
  linkTo?: LocationDescriptor;
  onClick?: () => void;
}

const TextLink: FC<Props> = ({ children, isExternal = false, linkTo, onClick, isStandalone = true, className }) => {
  const textLinkWrapperClassName: string = `text-sm group text-navy-base cursor-pointer hover:text-azure-base ${className}`;

  const textLinkClassName: string = cx('border-transparent border-solid border-b', {
    'group-hover:border-azure-base': isStandalone,
    'border-navy-base group-hover:border-transparent': !isStandalone
  });

  const TextLinkContent = (
    <span className={textLinkClassName} data-testid="text-link-content">
      {children}
    </span>
  );

  if (isExternal) {
    return (
      <a
        className={textLinkWrapperClassName}
        data-component="text-link"
        data-testid="text-link"
        href={linkTo as string}
        onClick={onClick}
        target="_blank"
      >
        {TextLinkContent}
        <Icon className="ml-2 text-sm" icon="zicon-diagonal-arrow" />
      </a>
    );
  }

  if (linkTo) {
    return (
      <Link className={textLinkWrapperClassName} data-component="text-link" data-testid="text-link" to={linkTo || ''}>
        {TextLinkContent}
      </Link>
    );
  }

  return (
    <span className={textLinkWrapperClassName} data-component="text-link" data-testid="text-link" onClick={onClick}>
      {TextLinkContent}
    </span>
  );
};

export type { Props as TextLinkProps };

export default TextLink;
