import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetRouteProgressDetailsQueryVariables = GraphQLTypes.Exact<{
  newCargoService: GraphQLTypes.Scalars['Boolean'];
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetRouteProgressDetailsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference' | 'modeOfTransport'> & {
              stage?: GraphQLTypes.Maybe<{ __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'value'>>;
              canUpdateBookingManagementSettings: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canViewShipmentTracking: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canManageSchedule: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdateStage: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              consolidatedShipment?: GraphQLTypes.Maybe<
                { __typename?: 'ConsolidatedShipment' } & Pick<GraphQLTypes.ConsolidatedShipment, 'id'>
              >;
              bookingStages?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'BookingStage' } & Pick<GraphQLTypes.BookingStage, 'current' | 'name' | 'started'> & {
                      startedAt?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                      >;
                      checklist: Array<
                        { __typename?: 'BookingStageCheck' } & Pick<GraphQLTypes.BookingStageCheck, 'name' | 'completed'>
                      >;
                    }
                >
              >;
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    cargoItems?: GraphQLTypes.Maybe<
                      Array<
                        | ({ __typename?: 'CargoContainerType' } & Pick<GraphQLTypes.CargoContainerType, 'id'>)
                        | ({ __typename?: 'CargoLooseCargoType' } & Pick<GraphQLTypes.CargoLooseCargoType, 'id'>)
                        | ({ __typename?: 'CargoVehicleType' } & Pick<GraphQLTypes.CargoVehicleType, 'id'>)
                      >
                    >;
                  }
              >;
              cargos?: GraphQLTypes.Maybe<Array<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'id'>>>;
            }
        >
      >
    >;
  };
};

export const GetRouteProgressDetailsDocument = /* #__PURE__ */ gql`
  query getRouteProgressDetails($newCargoService: Boolean!, $zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        stage {
          value
        }
        canUpdateBookingManagementSettings {
          value
        }
        canViewShipmentTracking {
          value
        }
        canManageSchedule {
          value
        }
        canUpdateStage {
          value
        }
        consolidatedShipment {
          id
        }
        modeOfTransport
        bookingStages {
          current
          name
          started
          startedAt {
            date
          }
          startedAt {
            date
            timeZone
          }
          checklist {
            name
            completed
          }
        }
        cargo {
          cargoItems {
            id
          }
          mode
        }
        cargos @include(if: $newCargoService) {
          id
        }
      }
    }
  }
`;

/**
 * __useGetRouteProgressDetailsQuery__
 *
 * To run a query within a React component, call `useGetRouteProgressDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteProgressDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteProgressDetailsQuery({
 *   variables: {
 *      newCargoService: // value for 'newCargoService'
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetRouteProgressDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRouteProgressDetailsQueryResult, GetRouteProgressDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetRouteProgressDetailsQueryResult, GetRouteProgressDetailsQueryVariables>(
    GetRouteProgressDetailsDocument,
    options
  );
}
export function useGetRouteProgressDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRouteProgressDetailsQueryResult, GetRouteProgressDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetRouteProgressDetailsQueryResult, GetRouteProgressDetailsQueryVariables>(
    GetRouteProgressDetailsDocument,
    options
  );
}
export type GetRouteProgressDetailsQueryHookResult = ReturnType<typeof useGetRouteProgressDetailsQuery>;
export type GetRouteProgressDetailsLazyQueryHookResult = ReturnType<typeof useGetRouteProgressDetailsLazyQuery>;
