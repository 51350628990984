import type { FC } from 'react';

import type { QuoteOption } from '@zen/Quotes/types';

import QuoteOptionContainer from '../../QuoteOptionContainer';

export interface Props {
  onReject?: (quoteOption: QuoteOption) => void;
  quoteOptions: QuoteOption[];
}

const QuoteOptionsList: FC<Props> = (props) => {
  const { quoteOptions, onReject } = props;

  const handleReject = (quoteOption: QuoteOption): void => {
    if (onReject) {
      onReject(quoteOption);
    }
  };

  return (
    <div>
      {quoteOptions.map((quoteOption: QuoteOption) => (
        <div key={quoteOption.id} className="mb-6" data-testid="list-item">
          <QuoteOptionContainer onReject={() => handleReject(quoteOption)} quoteOption={quoteOption} />
        </div>
      ))}
    </div>
  );
};

export default QuoteOptionsList;
