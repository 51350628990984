import { FC, ReactElement, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Route } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import type { Booking } from '@zen/Booking/types';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Pagination, Table, TableColumn, useTableConfiguration } from '@zen/DesignSystem';
import type { FiltersType } from '@zen/OperationsDashboard/context/OperationsContextProvider/OperationsContextProvider';
import useOperationsPersistedFilters from '@zen/OperationsDashboard/Filters/FiltersForm/hooks/useOperationsPersistedFilters';
import { prepareFilterVariables } from '@zen/OperationsShipments/components/FilterFields/helpers';
import Toolbar from '@zen/OperationsShipments/components/Toolbar';
import type { OperationsShipmentsFiltersType } from '@zen/OperationsShipments/types';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import type { FlatShipment, ShipmentsListCapabilities } from '@zen/Shipments';
import { countFilters } from '@zen/Shipments/Filters/helpers';
import { prepareFlatShipments } from '@zen/Shipments/helpers';
import { PageSize, SortingOrder, SortInput } from '@zen/types';
import useUrlPagination from '@zen/utils/hooks/pagination/useUrlPagination';

import {
  GetOperationsAllShipmentsQueryResult,
  GetOperationsAllShipmentsQueryVariables,
  useGetOperationsAllShipmentsQuery
} from '../graphql';
import { OPS_SHIPMENTS_PAGE_SIZE_KEY } from '../OperationsShipments';
import { getColumns } from './allShipmentsTableConfiguration';

const initialOrder = {
  field: 'createdAt',
  direction: SortingOrder.DESC
};

const AllShipments: FC = () => {
  const match = useRouteMatch();
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [pageSize, setPageSize] = useLocalStorage(OPS_SHIPMENTS_PAGE_SIZE_KEY, PageSize.TWENTY);

  const { filters, search } = useOperationsPersistedFilters<FiltersType<OperationsShipmentsFiltersType>>();
  const { pathname } = useLocation();
  const capabilities: ShipmentsListCapabilities = {
    canViewForwarder: false,
    canViewCustomsOnly: true
  };
  const { hiddenColumns } = useTableConfiguration();
  const { loading, error, nodes, paginationInfo, totalCount } = useUrlPagination<
    GetOperationsAllShipmentsQueryResult,
    GetOperationsAllShipmentsQueryVariables,
    Booking
    // booking type is too generic here
  >(
    useGetOperationsAllShipmentsQuery,
    'bookings',
    {
      searchQuery: search,
      ...prepareFilterVariables(filters),
      order
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' },
    pageSize
  );

  const filtersCount: number = countFilters(filters);
  const columns: TableColumn<FlatShipment>[] = getColumns(capabilities, pathname);

  return (
    <Page title="Operations shipments">
      <Toolbar<FlatShipment> columns={columns} filtersCount={filtersCount} onPageSizeChange={setPageSize} pageSize={pageSize} />
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={
          <SkeletonTableLoading columns={columns} hiddenColumns={hiddenColumns} order={order} tableId="operationsShipments" />
        }
      >
        {(data: Booking[]): ReactElement => (
          <>
            <Table<FlatShipment>
              columns={columns}
              data={prepareFlatShipments(data)}
              hiddenColumns={hiddenColumns}
              onOrderChange={setOrder}
              order={order}
              tableId="operationsShipments"
              totalCountConfig={{
                totalCount,
                entityName: 'shipment'
              }}
            />
            <Pagination pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
      <Route path={`${match?.path}/:id`}>
        <OrderDetailsContainer originPath={match?.url} />
      </Route>
    </Page>
  );
};

export default AllShipments;
