import type { FC } from 'react';

import { FormInput } from '@zen/Components/Form';
import { useForm } from '@zen/Components/Form/useForm';
import FormContactInput from '@zen/Contacts/FormContactInput';
import { ContactTypeEnum } from '@zen/graphql/types.generated';

import type { RoadShipmentFields } from '../../types';
import { isHaulierFieldRequired } from '../helpers';

interface Props {
  autoFocusedField?: string;
}

const CollectionAndDeliveryFields: FC<Props> = ({ autoFocusedField }) => {
  const { values } = useForm<RoadShipmentFields>();

  return (
    <div data-testid="collection-and-delivery-fields">
      <FormContactInput
        autoFocus={autoFocusedField === 'haulier'}
        className="mr-6"
        contactTypes={[ContactTypeEnum.HAULIER]}
        isRequired={isHaulierFieldRequired(values.collection, values.customsOnly)}
        label="Haulier"
        name="haulier"
      />
      <div className="flex">
        <FormInput
          autoFocus={autoFocusedField === 'vehiclePlateNumber'}
          className="w-48 mr-4"
          label="Vehicle plate"
          name="vehiclePlateNumber"
          placeholder="e.g. TS17UTY"
        />
        <FormInput
          autoFocus={autoFocusedField === 'trailerId'}
          className="w-48"
          label="Trailer ID"
          name="cargoItem.trailerId"
          placeholder="e.g. TS17UTY"
        />
      </div>
      <div className="flex">
        <FormInput
          autoFocus={autoFocusedField === 'collectionReference'}
          className="w-48 mr-4"
          label="Collection reference"
          name="collectionReference"
          placeholder="e.g. B011812S"
        />
        <FormInput
          autoFocus={autoFocusedField === 'deliveryReference'}
          className="w-48"
          label="Delivery reference"
          name="deliveryReference"
          placeholder="e.g. B011812S"
        />
      </div>
      <FormInput
        autoFocus={autoFocusedField === 'driverDetails'}
        className="w-48"
        label="Driver details"
        name="driverDetails"
        placeholder="e.g. John Smith"
      />
    </div>
  );
};

export default CollectionAndDeliveryFields;
