import { findIndex, orderBy, take } from 'lodash';
import type { ReactChild } from 'react';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@zen... Remove this comment to see the full error message
import { getUserItem, saveUserItem } from '@zen/utils/localStorage';

import type { VisitedAccount } from './types';
import VisitedAccountsContext from './VisitedAccountsContext';

interface Props {
  children: ReactChild;
  currentUserId: string;
}

const VISITED_ACCOUNTS_KEY = 'visitedAccounts';

const VisitedAccountsProvider = ({ currentUserId, children }: Props) => {
  const visitedAccounts: VisitedAccount[] = getUserItem(currentUserId, VISITED_ACCOUNTS_KEY) || [];

  const updateVisitedAccounts = (accountId: string) => {
    const savedAccountIndex = findIndex(visitedAccounts, { id: accountId });
    let visitedAccount: VisitedAccount;

    if (savedAccountIndex > -1) {
      visitedAccount = visitedAccounts[savedAccountIndex];
      ++visitedAccount.count;
      visitedAccount.lastVisitedTime = Date.now();
    } else {
      visitedAccount = { id: accountId, count: 1, lastVisitedTime: Date.now() };
      visitedAccounts.push(visitedAccount);
    }

    saveUserItem(currentUserId, VISITED_ACCOUNTS_KEY, visitedAccounts);
  };

  const lastVisitedAccounts = take(orderBy(visitedAccounts, 'lastVisitedTime', 'desc'), 3);

  const mostVisitedAccounts = take(orderBy(visitedAccounts, 'count', 'desc'), 3);

  const context = {
    visitedAccounts,
    updateVisitedAccounts,
    lastVisitedAccounts,
    mostVisitedAccounts
  };

  return <VisitedAccountsContext.Provider value={context}>{children}</VisitedAccountsContext.Provider>;
};

export default VisitedAccountsProvider;
