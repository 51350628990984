import type { FC, ReactNode } from 'react';

import Page from '@zen/Components/Page';
import type { RouteTabConfig } from '@zen/Components/RouteTabs';
import RouteTabs from '@zen/Components/RouteTabs';
import RouteTabSwitcher from '@zen/Components/RouteTabSwitcher';
import { Button } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks/NetworksContext';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import MyOrganisationDetails from './MyOrganisationDetails';
import Organisations from './Organisations';

interface Props {
  networkId: string;
}

enum OrganisationTab {
  ALL_ORGANISATION = 'all-organisations',
  MY_ORGANISATION = 'my-organisation'
}

const MyOrganisation: FC<Props> = ({ networkId }) => {
  const { isZencargoNetwork, routes } = useNetworksContext();
  const { userProfile } = useAccount();
  const { navigate } = useNavigationHistory();

  const title: string = isZencargoNetwork ? 'Agents and Forwarders' : 'Networks';
  const contextTitle: string = isZencargoNetwork ? 'agents and forwarders' : 'organisations';
  const myOrganisationDescription: string = `View add or manage ${contextTitle} within your network.`;
  const canManageOrganisations: boolean = isZencargoNetwork ? true : !userProfile.division;

  const handleNewOrganisationButtonClick = (): void => {
    navigate(routes.organisation.new.getUrl());
  };

  const actionButtons: ReactNode | undefined = canManageOrganisations ? (
    <Button onClick={handleNewOrganisationButtonClick}>Add a new organisation</Button>
  ) : undefined;

  const config: RouteTabConfig[] = [
    {
      label: 'My organisation',
      component: <MyOrganisationDetails networkId={networkId} />,
      path: OrganisationTab.MY_ORGANISATION
    },
    ...(canManageOrganisations
      ? [
          {
            label: 'All organisations',
            component: <Organisations networkId={networkId} />,
            path: OrganisationTab.ALL_ORGANISATION
          }
        ]
      : [])
  ];

  return (
    <Page
      actionButtons={actionButtons}
      browserTitle="Networks"
      tabsComponent={<RouteTabs tabsConfig={config} />}
      tagline={myOrganisationDescription}
      title={title}
    >
      <RouteTabSwitcher tabsConfig={config} />
    </Page>
  );
};

export default MyOrganisation;
