import classnames from 'classnames';
import type { FC, ReactNode } from 'react';

interface Props {
  action?: ReactNode;
  className?: string;
  date?: ReactNode;
  dateDifference?: ReactNode;
  detailLabel?: ReactNode;
  title: ReactNode;
}

const DetailsCardContent: FC<Props> = (props) => {
  const { title, detailLabel, date, dateDifference, action, className } = props;
  const containerClassNames: string = classnames(className, 'flex justify-between items-center leading-5 text-sm');

  return (
    <div className={containerClassNames}>
      <div className="pr-2">{title}</div>
      <div className="flex items-center whitespace-nowrap">
        <div className="text-right mr-2">{detailLabel}</div>
        <div className="w-20">{date}</div>
        <div className="flex w-32 items-center justify-between ml-2">
          {dateDifference}
          <span className="ml-auto">{action}</span>
        </div>
      </div>
    </div>
  );
};

export default DetailsCardContent;
