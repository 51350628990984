import classnames from 'classnames';
import type { FC, ReactNode } from 'react';

import CollapsableElement from '@zen/Components/CollapsableElement';
import { useHover } from '@zen/utils/hooks/useHover';

interface Props {
  animationDelay?: number;
  details?: ReactNode;
  indicator: ReactNode;
  mainCardSection: ReactNode;
  opened?: boolean;
  renderSubCardSection?: (isHovered: boolean) => ReactNode;
}

const ExpandableDetailsCard: FC<Props> = ({
  details,
  animationDelay,
  mainCardSection,
  renderSubCardSection,
  indicator,
  opened = false
}) => {
  const [ref, isHovered] = useHover();
  const hasDetails: boolean = Array.isArray(details) ? details.length > 0 : !!details;
  const delay: number | undefined = isHovered ? animationDelay : 0;
  const isOpened: boolean = isHovered || opened;

  const cardClassNames: string = classnames(
    { 'shadow-overlay': isOpened },
    'relative rounded transition duration-300 ease-linear'
  );

  const renderDetailItem = (detailItem: ReactNode, index?: number): ReactNode => (
    <div key={index} data-testid="details">
      {detailItem}
    </div>
  );

  const renderDetails = (): ReactNode => (Array.isArray(details) ? details.map(renderDetailItem) : renderDetailItem(details));

  return (
    <div ref={ref} className="group relative" data-testid="expandable-card">
      {indicator}
      <div className={cardClassNames}>
        <div className="pl-12 flex p-2">
          <div className="flex flex-col w-full">
            <div className="text-grey-dark" data-testid="expandable-card-main-section">
              {mainCardSection}
            </div>
            {renderSubCardSection && (
              <div className="text-grey-base" data-testid="expandable-card-sub-section">
                {renderSubCardSection(isOpened)}
              </div>
            )}
          </div>
        </div>
        {hasDetails && (
          <CollapsableElement delay={delay} duration={300} isOpened={isOpened}>
            <div className="pl-12 px-2 py-4 bg-grey-lightest text-grey-dark" data-testid="expandable-card-details-section">
              {renderDetails()}
            </div>
          </CollapsableElement>
        )}
      </div>
    </div>
  );
};

export default ExpandableDetailsCard;
