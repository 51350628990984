import type { RoutesConfig } from './types';

export const PRODUCT_SETTINGS_PREFIX = '/dashboard/product-settings';

interface ProductSettingsRoutes extends RoutesConfig {
  documentTemplatesCreate: {
    getUrl: () => string;
    path: string;
  };
  documentTemplatesEdit: {
    getUrl: (documentTemplateId: string) => string;
    path: string;
  };
  documentTemplatesIndex: {
    getUrl: () => string;
    path: string;
  };
  manageTerminals: {
    getUrl: () => string;
    path: string;
  };
  productSettingsIndex: {
    getUrl: () => string;
    path: string;
  };
  unloCodeTerminals: {
    getUrl: () => string;
    path: string;
  };
}

const productSettingsRoutes: ProductSettingsRoutes = {
  productSettingsIndex: {
    path: PRODUCT_SETTINGS_PREFIX,
    getUrl: () => PRODUCT_SETTINGS_PREFIX
  },
  manageTerminals: {
    path: `${PRODUCT_SETTINGS_PREFIX}/terminals`,
    getUrl: () => `${PRODUCT_SETTINGS_PREFIX}/terminals`
  },
  unloCodeTerminals: {
    path: `${PRODUCT_SETTINGS_PREFIX}/terminals/unlocodes`,
    getUrl: () => `${PRODUCT_SETTINGS_PREFIX}/terminals/unlocodes`
  },
  documentTemplatesIndex: {
    path: `${PRODUCT_SETTINGS_PREFIX}/document-templates`,
    getUrl: () => `${PRODUCT_SETTINGS_PREFIX}/document-templates`
  },
  documentTemplatesCreate: {
    path: `${PRODUCT_SETTINGS_PREFIX}/document-templates/create`,
    getUrl: () => `${PRODUCT_SETTINGS_PREFIX}/document-templates/create`
  },
  documentTemplatesEdit: {
    path: `${PRODUCT_SETTINGS_PREFIX}/document-templates/edit/:documentTemplateId`,
    getUrl: (documentTemplateId: string) => `${PRODUCT_SETTINGS_PREFIX}/document-templates/edit/${documentTemplateId}`
  }
};

export default productSettingsRoutes;
