import type { FC, FormEvent } from 'react';

import { NumberInput, Option, Select } from '@zen/DesignSystem';
import { Currency, Money } from '@zen/types';
import type { Modify, Nullable } from '@zen/utils/typescript';
import { convertStringToNumber } from '@zen/utils/utils';

export type MoneyType = Modify<Money, { value: Nullable<number> }>;

interface Props {
  autoFocus?: boolean;
  availableCurrencies?: Currency[];
  name: string;
  onChange: (value: MoneyType) => void;
  value: MoneyType;
}

const CurrencyInput: FC<Props> = ({ autoFocus, availableCurrencies, onChange, name, value }) => {
  const currencyValue: Currency = value?.currency;
  const inputValue: Nullable<number> = value?.value;
  const currencies: Currency[] = availableCurrencies || (Object.keys(Currency) as Currency[]);
  const currencyOptions: Option<Currency>[] = currencies.map((currency) => ({ value: currency, label: currency }));

  const handleChangeValue = (e: FormEvent<HTMLInputElement>): void => {
    onChange({ value: convertStringToNumber(e.currentTarget.value), currency: currencyValue });
  };

  const handleChangeCurrency = (currency: Nullable<Currency>): void => {
    if (currency) {
      onChange({ value: inputValue, currency });
    }
  };

  return (
    <div className="flex">
      <NumberInput
        autoFocus={autoFocus}
        className="mr-4"
        data-testid={name}
        name={name}
        onChange={handleChangeValue}
        placeholder="0.00"
        step="any"
        tabIndex={0}
        value={inputValue}
      />
      <Select
        className="min-w-24"
        isSearchable={false}
        name="currencyInput"
        onChange={handleChangeCurrency}
        options={currencyOptions}
        value={currencyValue}
      />
    </div>
  );
};

export default CurrencyInput;
