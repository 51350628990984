import { createContext, useContext } from 'react';

import type { BookingStageEnum, MilestoneDateValidationType, VoyageMilestoneNameEnum } from './types';

interface MilestoneValidationContextValues {
  getMilestoneDateValidation: (
    name: VoyageMilestoneNameEnum | BookingStageEnum,
    isActualDateUpdate: boolean
  ) => MilestoneDateValidationType;
}

const intitialValues = {
  getMilestoneDateValidation: () => ({})
};

const MilestoneDateValidationContext = createContext<MilestoneValidationContextValues>(intitialValues);

export const useMilestoneDateValidation = (
  name: VoyageMilestoneNameEnum | BookingStageEnum,
  isActualDateUpdate: boolean = false
): MilestoneDateValidationType => {
  const { getMilestoneDateValidation } = useContext(MilestoneDateValidationContext);

  return getMilestoneDateValidation(name, isActualDateUpdate);
};

export default MilestoneDateValidationContext;
