import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Button, ButtonVariant, TextLink } from '@zen/DesignSystem';

import { ReactComponent as ContainerImage } from './container.svg';
import type { ButtonConfig, HelperLinkConfig } from './types';

export interface Props {
  button?: ButtonConfig;
  className?: string;
  headline: string;
  helperLink?: HelperLinkConfig;
  image?: ReactNode;
  isCentered?: boolean;
  secondaryButton?: ButtonConfig;
  showImage?: boolean;
  tagline?: ReactNode;
}

const NoResults: FC<Props> = (props) => {
  const {
    button,
    className = 'mt-16',
    headline,
    image = <ContainerImage data-testid="image" />,
    isCentered = true,
    secondaryButton,
    showImage = true,
    tagline,
    helperLink
  } = props;

  const taglineClassnames: string = cx('flex justify-center mt-2 text-grey-base leading-normal text-sm', { 'mb-6': !!button });
  const renderButton = (buttonConfig: ButtonConfig, variant: ButtonVariant = 'primary'): ReactNode => {
    if (buttonConfig.linkTo) {
      return (
        <Link onClick={buttonConfig.onClick} to={buttonConfig.linkTo}>
          <Button variant={variant}>{buttonConfig.label}</Button>
        </Link>
      );
    }

    return (
      <Button onClick={buttonConfig.onClick} variant={variant}>
        {buttonConfig.label}
      </Button>
    );
  };

  return (
    <div
      className={`flex flex-col text-center justify-center py-12 ${isCentered ? 'h-full' : className}`}
      data-testid="no-results"
    >
      <div>
        {showImage && <div className="flex justify-center items-start mb-4">{image}</div>}
        <div className="text-lg leading-6 text-grey-dark font-bold">{headline}</div>
        {tagline && (
          <div className={taglineClassnames} data-testid="tagline">
            {tagline}
          </div>
        )}

        <div className="flex justify-center space-x-2">
          {button && renderButton(button)}
          {secondaryButton && renderButton(secondaryButton, 'secondary')}
        </div>
        {helperLink && (
          <div className="mt-6" data-testid="helper-link">
            <TextLink linkTo={helperLink.linkTo}>{helperLink.text}</TextLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default NoResults;
