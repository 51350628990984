import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateBillingDetailsMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateBillingDetailsInput;
}>;

export type UpdateBillingDetailsMutationResult = { __typename?: 'Mutation' } & {
  accountUpdateBillingDetails?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateBillingDetailsPayload' } & {
      billingDetails: { __typename?: 'BillingDetails' } & Pick<
        GraphQLTypes.BillingDetails,
        'firstName' | 'lastName' | 'email' | 'phone' | 'street' | 'country' | 'city' | 'postalCode' | 'state'
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const UpdateBillingDetailsDocument = /* #__PURE__ */ gql`
  mutation updateBillingDetails($input: UpdateBillingDetailsInput!) {
    accountUpdateBillingDetails(input: $input) {
      billingDetails {
        firstName
        lastName
        email
        phone
        street
        country
        city
        postalCode
        state
      }
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateBillingDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateBillingDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingDetailsMutation, { data, loading, error }] = useUpdateBillingDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBillingDetailsMutationResult, UpdateBillingDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateBillingDetailsMutationResult, UpdateBillingDetailsMutationVariables>(
    UpdateBillingDetailsDocument,
    options
  );
}
export type UpdateBillingDetailsMutationHookResult = ReturnType<typeof useUpdateBillingDetailsMutation>;
