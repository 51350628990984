import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ConsolidatedShipmentDetailsQueryVariables = GraphQLTypes.Exact<{
  consolidationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type ConsolidatedShipmentDetailsQueryResult = { __typename?: 'Query' } & {
  consolidations?: GraphQLTypes.Maybe<
    { __typename?: 'ConsolidatedShipmentConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'ConsolidatedShipment' } & Pick<
              GraphQLTypes.ConsolidatedShipment,
              | 'accountUuid'
              | 'cbm'
              | 'cutOffDate'
              | 'id'
              | 'looseCargoType'
              | 'quantity'
              | 'reference'
              | 'transitDocumentReference'
              | 'vehiclePlateNumber'
            > & {
                grossWeight?: GraphQLTypes.Maybe<{ __typename?: 'Weight' } & Pick<GraphQLTypes.Weight, 'metric' | 'value'>>;
                originAgent?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                >;
                legs: Array<
                  { __typename?: 'ConsolidationLegType' } & Pick<
                    GraphQLTypes.ConsolidationLegType,
                    'id' | 'estimatedDepartureDate' | 'actualDepartureDate' | 'estimatedArrivalDate' | 'actualArrivalDate'
                  > & {
                      networksDestination:
                        | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            })
                        | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            })
                        | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            });
                      networksOrigin:
                        | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            })
                        | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            })
                        | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                              >;
                            });
                    }
                >;
              }
          >
        >
      >;
    }
  >;
};

export const ConsolidatedShipmentDetailsQueryDocument = /* #__PURE__ */ gql`
  query consolidatedShipmentDetailsQuery($consolidationId: String) {
    consolidations(consolidatedShipmentId: $consolidationId) {
      nodes {
        accountUuid
        cbm
        cutOffDate
        grossWeight {
          metric
          value
        }
        id
        looseCargoType
        originAgent {
          id
          label {
            long
          }
        }
        quantity
        reference
        transitDocumentReference
        vehiclePlateNumber
        legs {
          id
          estimatedDepartureDate
          actualDepartureDate
          estimatedArrivalDate
          actualArrivalDate
          networksDestination {
            id
            label {
              long
            }
          }
          networksOrigin {
            id
            label {
              long
            }
          }
        }
      }
    }
  }
`;

/**
 * __useConsolidatedShipmentDetailsQuery__
 *
 * To run a query within a React component, call `useConsolidatedShipmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsolidatedShipmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsolidatedShipmentDetailsQuery({
 *   variables: {
 *      consolidationId: // value for 'consolidationId'
 *   },
 * });
 */
export function useConsolidatedShipmentDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<ConsolidatedShipmentDetailsQueryResult, ConsolidatedShipmentDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ConsolidatedShipmentDetailsQueryResult, ConsolidatedShipmentDetailsQueryVariables>(
    ConsolidatedShipmentDetailsQueryDocument,
    options
  );
}
export function useConsolidatedShipmentDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConsolidatedShipmentDetailsQueryResult, ConsolidatedShipmentDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ConsolidatedShipmentDetailsQueryResult, ConsolidatedShipmentDetailsQueryVariables>(
    ConsolidatedShipmentDetailsQueryDocument,
    options
  );
}
export type ConsolidatedShipmentDetailsQueryHookResult = ReturnType<typeof useConsolidatedShipmentDetailsQuery>;
export type ConsolidatedShipmentDetailsQueryLazyQueryHookResult = ReturnType<typeof useConsolidatedShipmentDetailsQueryLazyQuery>;
