import cx from 'classnames';
import type { CSSProperties, FC, ReactNode } from 'react';

import { useHover } from '@zen/utils/hooks/useHover';

interface Props {
  children: ReactNode;
  className: string;
  onClick: () => void;
  renderRowActions?: () => ReactNode;
  style: CSSProperties;
}

const TableRow: FC<Props> = (props) => {
  const { children, className, renderRowActions, ...rest } = props;
  const [ref, isItemHovered] = useHover<HTMLTableRowElement>();
  const rowClassNames: string = cx(className, 'relative');
  const actionContainerClassNames: string = cx(
    {
      'opacity-0 pointer-events-none': !isItemHovered,
      'opacity-100 pointer-events-auto': isItemHovered
    },
    'flex'
  );

  const renderRowWithActions = (): ReactNode => {
    if (!renderRowActions) {
      return null;
    }

    return (
      <tr {...rest} ref={ref} className={rowClassNames} data-component="table-row" data-testid="table-row-with-actions">
        {children}
        <td className="absolute right-0 h-12 pointer-events-none flex justify-end" style={{ zIndex: 3 }}>
          <div className={actionContainerClassNames} data-testid="table-row-action-container">
            <div className="h-full bg-gradient-to-r from-transparent to-grey-lighter px-12 pointer-events-none" />
            <div className="h-full pl-8 pr-2 flex bg-grey-lighter items-center">{renderRowActions()}</div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      {renderRowWithActions()}
      {!renderRowActions && (
        <tr className={className} {...rest}>
          {children}
        </tr>
      )}
    </>
  );
};

export type { Props as TableRowProperties };
export default TableRow;
