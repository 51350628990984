import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrganisationContactsQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  ids?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  nameContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type OrganisationContactsQueryResult = { __typename?: 'Query' } & {
  network?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      organisations?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksOrganisationInterfaceConnection' } & {
          nodes?: GraphQLTypes.Maybe<
            Array<
              GraphQLTypes.Maybe<
                | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'id' | 'name'> & {
                      locations: Array<{ __typename?: 'NetworksLocation' } & Pick<GraphQLTypes.NetworksLocation, 'name'>>;
                    })
                | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'id' | 'name'> & {
                      locations: Array<{ __typename?: 'NetworksLocation' } & Pick<GraphQLTypes.NetworksLocation, 'name'>>;
                    })
                | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'id' | 'name'> & {
                      locations: Array<{ __typename?: 'NetworksLocation' } & Pick<GraphQLTypes.NetworksLocation, 'name'>>;
                    })
                | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'id' | 'name'> & {
                      locations: Array<{ __typename?: 'NetworksLocation' } & Pick<GraphQLTypes.NetworksLocation, 'name'>>;
                    })
              >
            >
          >;
        }
      >;
    }
  >;
};

export const OrganisationContactsQueryDocument = /* #__PURE__ */ gql`
  query organisationContactsQuery($accountUuid: String!, $ids: [String!], $nameContains: String) {
    network(id: $accountUuid) {
      organisations(ids: $ids, nameContains: $nameContains) {
        nodes {
          id
          name
          locations {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useOrganisationContactsQuery__
 *
 * To run a query within a React component, call `useOrganisationContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationContactsQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      ids: // value for 'ids'
 *      nameContains: // value for 'nameContains'
 *   },
 * });
 */
export function useOrganisationContactsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganisationContactsQueryResult, OrganisationContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrganisationContactsQueryResult, OrganisationContactsQueryVariables>(
    OrganisationContactsQueryDocument,
    options
  );
}
export function useOrganisationContactsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganisationContactsQueryResult, OrganisationContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrganisationContactsQueryResult, OrganisationContactsQueryVariables>(
    OrganisationContactsQueryDocument,
    options
  );
}
export type OrganisationContactsQueryHookResult = ReturnType<typeof useOrganisationContactsQuery>;
export type OrganisationContactsQueryLazyQueryHookResult = ReturnType<typeof useOrganisationContactsQueryLazyQuery>;
