import type { FC } from 'react';
import { useLocation } from 'react-router';

import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { Role } from '@zen/Networks';
import { NEW_BOOKING_ROUTE_PREFIX } from '@zen/Routes/newBooking';
import usePermissionSuppress from '@zen/utils/hooks/usePermissionSuppress';

import BookingButton from './BookingButton';
import BookingRequestButton from './BookingRequestButton';

const BookingButtonContainer: FC = () => {
  const role = useRole();
  const { pathname } = useLocation();
  const { check } = useGlobalPermissions();

  const isBookingPage: boolean = pathname.includes(NEW_BOOKING_ROUTE_PREFIX);
  const canCreateBooking: boolean = check('bookings.canCreate');
  const canRequestBooking: boolean = check('bookings.canRequest') && role === Role.MANUFACTURER;
  const { suppressPermission: suppressCanCreateBookings } = usePermissionSuppress();

  if (isBookingPage || suppressCanCreateBookings) {
    return null;
  }

  if (canCreateBooking) {
    return <BookingButton />;
  }

  return canRequestBooking ? <BookingRequestButton /> : null;
};

export default BookingButtonContainer;
