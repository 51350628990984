import type { FC } from 'react';

import { MAIN_CONTENT_PADDING_CLASS } from '@zen/Layout';

interface Props {
  className?: string;
}

const MainContentContainer: FC<Props> = ({ children, className = '', ...rest }) => {
  const classNames = `${MAIN_CONTENT_PADDING_CLASS} ${className}`;

  return (
    <div className={classNames} data-testid="main-content-container" {...rest}>
      {children}
    </div>
  );
};

export type { Props as MainContentContainerProps };
export default MainContentContainer;
