import cx from 'classnames';
import type { FC } from 'react';

import type { Nullable, Undefinable } from '@zen/utils/typescript';

import ConsolidationDetailsInfo from '../ConsolidationDetailsInfo/ConsolidationDetailsInfo';

export interface Props {
  dataId: string;
  details: DetailsCardItem[];
}

export interface DetailsCardItem {
  title: string;
  value: Nullable<Undefinable<string | number>>;
}

const DetailsCard: FC<Props> = ({ dataId, details }) => {
  const cardDetails = details.map((infoDetail) => {
    return (
      <ConsolidationDetailsInfo key={infoDetail.title} title={infoDetail.title}>
        {infoDetail.value}
      </ConsolidationDetailsInfo>
    );
  });

  const classNames: string = cx('p-4 mb-6', {
    'bg-white shadow-overlay': false,
    'bg-grey-lightest': true
  });

  const colsNumber: number = details.length < 4 ? details.length : 4;

  return (
    <div className={classNames} data-testid={`${dataId}-info-summary`}>
      <div className={`grid grid-cols-${colsNumber} gap-3`}>{cardDetails}</div>
    </div>
  );
};

export default DetailsCard;
