import { FC, MutableRefObject, ReactNode, useRef } from 'react';

import { CargoModeEnum } from '@zen/Cargo';
import CollapsableElement from '@zen/Components/CollapsableElement';
import DateWithDefault from '@zen/Components/DateWithDefault';
import StageName from '@zen/Components/StageName';
import ExpandableDetailsCard from '@zen/Shipment/components/ExpandableDetailsCard';
import { isOceanShipment, ModeOfTransport, VoyageMilestoneNameEnum } from '@zen/Shipments';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import type { Nullable, Optional, Undefinable } from '@zen/utils/typescript';

import { ShippingOrderState } from '../../../types';
import { useVoyageMilestones } from '../../VoyageMilestonesContext';
import VerticalPathIndicators from '../MilestoneCard/VerticalPathIndicators';
import BookingConsolidationDetailsModal from './BookingConsolidationDetailsModal';
import ShippingOrderReleasePill from './ShippingOrderReleasePill';
import ShippingOrderStatusPill from './ShippingOrderStatusPill';
import type { TrackingInformation } from './types';

interface Props {
  cargoMode: Optional<CargoModeEnum>;
  consolidatedShipmentId: Undefinable<string>;
  isCompleted: boolean;
  isCurrent: boolean;
  name: VoyageMilestoneNameEnum;
  trackingInformation: TrackingInformation;
  transportMode: ModeOfTransport;
  vesselName: Optional<string>;
}

const InTransitMilestone: FC<Props> = (props) => {
  const { consolidation } = useFeatureFlags();
  const { isMilestoneCompleted } = useVoyageMilestones();
  const { cargoMode, consolidatedShipmentId, transportMode, trackingInformation, isCompleted, isCurrent, vesselName, name } =
    props;
  const { shippingOrderState, shippingOrderConfirmedOn, shippingOrderReleasedOn } = trackingInformation;
  const titleRef: MutableRefObject<Nullable<HTMLDivElement>> = useRef<HTMLDivElement>(null);
  const isTrackable: boolean = isOceanShipment(transportMode) && cargoMode === CargoModeEnum.FCL;
  const isCargoCollected: boolean = isMilestoneCompleted(VoyageMilestoneNameEnum.COLLECTED);
  const hasShippingOrderConfirmation: boolean = shippingOrderState
    ? shippingOrderState !== ShippingOrderState.NOT_REQUIRED
    : false;
  const displayCheckList: boolean = isTrackable && hasShippingOrderConfirmation && !isCargoCollected;
  const subCardSectionClassNames: string = 'flex min-w-40 justify-center';
  const showConsolidatedShipmentLink: boolean = !!consolidatedShipmentId && consolidation;

  const mainCardSection: ReactNode = (
    <div className="flex items-center text-sm justify-between h-8">
      <div ref={titleRef} className="leading-5">
        <StageName name={name} transportMode={transportMode} />
      </div>

      {showConsolidatedShipmentLink && <BookingConsolidationDetailsModal consolidatedShipmentId={consolidatedShipmentId ?? ''} />}
      {displayCheckList && (
        <div className="flex items-center mr-7" data-testid="stage-check-list">
          <div className={subCardSectionClassNames}>
            <ShippingOrderReleasePill dateOfRelease={shippingOrderReleasedOn} />
          </div>
          {shippingOrderState && (
            <div className={subCardSectionClassNames}>
              <ShippingOrderStatusPill shippingOrderStatus={shippingOrderState} />
            </div>
          )}
        </div>
      )}
    </div>
  );

  const renderSubCardSection = (isHovered: boolean): ReactNode => {
    if (!displayCheckList) {
      return null;
    }

    return (
      <CollapsableElement isOpened={isHovered}>
        <div className="flex items-baseline text-sm leading-normal justify-between pt-1">
          <div>{vesselName && <span data-testid="vessel-name">{vesselName}</span>}</div>
          {displayCheckList && (
            <div className="flex items-center mr-7">
              <div className={`${subCardSectionClassNames} mr-4`}>
                <DateWithDefault date={shippingOrderReleasedOn} />
              </div>
              {shippingOrderState && (
                <div className={subCardSectionClassNames}>
                  <DateWithDefault date={shippingOrderConfirmedOn} />
                </div>
              )}
            </div>
          )}
        </div>
      </CollapsableElement>
    );
  };

  return (
    <ExpandableDetailsCard
      animationDelay={500}
      indicator={<VerticalPathIndicators isCompleted={isCompleted} isCurrent={isCurrent} referenceElementRef={titleRef} />}
      mainCardSection={mainCardSection}
      renderSubCardSection={renderSubCardSection}
    />
  );
};

export default InTransitMilestone;
