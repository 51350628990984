import type { Location } from 'history';
import { get } from 'lodash';

export const getPreviousUrl = (location: Location) => get(location, 'state.previousUrl');

export const extractFirstLevelPath = (pathname: string): string => {
  const targetPathRegExp = /^\/dashboard(\/[^/]*)?/;

  const matches = targetPathRegExp.exec(pathname);

  // if the path name does not match regexp, we fall back to `/dashboard`
  return matches ? matches[0] : '/dashboard';
};
