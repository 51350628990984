import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookingRequestsQueryVariables = GraphQLTypes.Exact<{
  onlyManufacturerRequested?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  onlyReadyForApproval?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  onlyRejected?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  customerUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type BookingRequestsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'Booking' } & Pick<
              GraphQLTypes.Booking,
              'clientReference' | 'modeOfTransport' | 'zencargoReference'
            > & {
                bookingRequest?: GraphQLTypes.Maybe<
                  { __typename?: 'BookingRequest' } & Pick<
                    GraphQLTypes.BookingRequest,
                    'rejected' | 'customerApproved' | 'forwarderApproved' | 'id' | 'rejectedAdditionalInfo' | 'rejectedReason'
                  > & {
                      canReject: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canUpdateCustomerApproved: { __typename?: 'AuthorizationResult' } & Pick<
                        GraphQLTypes.AuthorizationResult,
                        'value'
                      >;
                      canUpdateForwarderApproved: { __typename?: 'AuthorizationResult' } & Pick<
                        GraphQLTypes.AuthorizationResult,
                        'value'
                      >;
                      canRevertRejection: { __typename?: 'AuthorizationResult' } & Pick<
                        GraphQLTypes.AuthorizationResult,
                        'value'
                      >;
                      canRevertRejectionAsManufacturer: { __typename?: 'AuthorizationResult' } & Pick<
                        GraphQLTypes.AuthorizationResult,
                        'value'
                      >;
                    }
                >;
                calculatedInfo: { __typename?: 'CalculatedInfo' } & {
                  cargoSummary?: GraphQLTypes.Maybe<
                    { __typename?: 'CargoSummary' } & Pick<GraphQLTypes.CargoSummary, 'highestRiskLevel' | 'label' | 'type'> & {
                        totalValueOfGoods?: GraphQLTypes.Maybe<
                          Array<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>
                        >;
                      }
                  >;
                };
                canApproveBookingRequest: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                cargoReadyDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
                carriage?: GraphQLTypes.Maybe<
                  { __typename?: 'Carriage' } & Pick<GraphQLTypes.Carriage, 'vesselName'> & {
                      portOfDestination?: GraphQLTypes.Maybe<
                        { __typename?: 'PortOfDestination' } & Pick<GraphQLTypes.PortOfDestination, 'name'>
                      >;
                      portOfLoad?: GraphQLTypes.Maybe<{ __typename?: 'PortOfLoad' } & Pick<GraphQLTypes.PortOfLoad, 'name'>>;
                    }
                >;
                delay?: GraphQLTypes.Maybe<{ __typename?: 'Delay' } & Pick<GraphQLTypes.Delay, 'days' | 'type' | 'description'>>;
                estimatedArrival?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                >;
                estimatedDeparture?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                >;
                estimatedDelivery?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                >;
                incoterms?: GraphQLTypes.Maybe<{ __typename?: 'Incoterms' } & Pick<GraphQLTypes.Incoterms, 'value'>>;
                customer?: GraphQLTypes.Maybe<
                  { __typename?: 'BookingCustomerType' } & Pick<GraphQLTypes.BookingCustomerType, 'name' | 'uuid'>
                >;
                networksDestination?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                >;
                networksOrigin?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                >;
                purchaseOrderReferences?: GraphQLTypes.Maybe<
                  { __typename?: 'PurchaseOrderReferences' } & Pick<GraphQLTypes.PurchaseOrderReferences, 'totalCount'> & {
                      nodes?: GraphQLTypes.Maybe<
                        Array<
                          { __typename?: 'PurchaseOrderReference' } & Pick<
                            GraphQLTypes.PurchaseOrderReference,
                            'orderReferenceNumber' | 'id'
                          >
                        >
                      >;
                    }
                >;
                stage?: GraphQLTypes.Maybe<
                  { __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'description' | 'value' | 'actionItemCount'> & {
                      statusUpdate?: GraphQLTypes.Maybe<
                        { __typename?: 'StatusUpdate' } & Pick<GraphQLTypes.StatusUpdate, 'title'>
                      >;
                    }
                >;
              }
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const BookingRequestsDocument = /* #__PURE__ */ gql`
  query bookingRequests(
    $onlyManufacturerRequested: Boolean
    $onlyReadyForApproval: Boolean
    $onlyRejected: Boolean
    $before: String
    $after: String
    $customerUuid: String
    $order: SortInput
  ) {
    bookings(
      onlyManufacturerRequested: $onlyManufacturerRequested
      onlyReadyForApproval: $onlyReadyForApproval
      onlyRejected: $onlyRejected
      after: $after
      before: $before
      customerUuid: $customerUuid
      order: $order
    ) {
      nodes {
        bookingRequest {
          canReject {
            value
          }
          rejected
          canUpdateCustomerApproved {
            value
          }
          customerApproved
          canUpdateForwarderApproved {
            value
          }
          canRevertRejection {
            value
          }
          canRevertRejectionAsManufacturer {
            value
          }
          forwarderApproved
          id
          rejectedAdditionalInfo
          rejectedReason
        }
        calculatedInfo {
          cargoSummary {
            highestRiskLevel
            label
            totalValueOfGoods {
              currency
              value
            }
            type
          }
        }
        canApproveBookingRequest {
          value
        }
        cargoReadyDate {
          date
        }
        carriage {
          portOfDestination {
            name
          }
          portOfLoad {
            name
          }
          vesselName
        }
        clientReference
        delay {
          days
          type
          description
        }
        estimatedArrival {
          date
          timeZone
        }
        estimatedDeparture {
          date
          timeZone
        }
        estimatedDelivery {
          date
          timeZone
        }
        incoterms {
          value
        }
        customer {
          name
          uuid
        }
        modeOfTransport
        networksDestination {
          id
          label {
            long
            short
          }
        }
        networksOrigin {
          id
          label {
            long
            short
          }
        }
        purchaseOrderReferences(first: 10) {
          nodes {
            orderReferenceNumber
            id
          }
          totalCount
        }
        stage {
          description
          statusUpdate {
            title
          }
          value
          actionItemCount
        }
        zencargoReference
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useBookingRequestsQuery__
 *
 * To run a query within a React component, call `useBookingRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestsQuery({
 *   variables: {
 *      onlyManufacturerRequested: // value for 'onlyManufacturerRequested'
 *      onlyReadyForApproval: // value for 'onlyReadyForApproval'
 *      onlyRejected: // value for 'onlyRejected'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      customerUuid: // value for 'customerUuid'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBookingRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<BookingRequestsQueryResult, BookingRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BookingRequestsQueryResult, BookingRequestsQueryVariables>(BookingRequestsDocument, options);
}
export function useBookingRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingRequestsQueryResult, BookingRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BookingRequestsQueryResult, BookingRequestsQueryVariables>(BookingRequestsDocument, options);
}
export type BookingRequestsQueryHookResult = ReturnType<typeof useBookingRequestsQuery>;
export type BookingRequestsLazyQueryHookResult = ReturnType<typeof useBookingRequestsLazyQuery>;
