import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  icon: IconName;
  label: string;
  onClick: () => void;
}

const BookingStageAction: FC<Props> = ({ icon, label, onClick }) => {
  return (
    <div className="flex items-center bg-grey-lightest py-3 px-4 text-navy-base cursor-pointer" onClick={onClick}>
      <Icon icon={icon} />
      <div className="font-bold text-sm ml-4 hover:underline">{label}</div>
    </div>
  );
};

export default BookingStageAction;
