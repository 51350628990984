import cx from 'classnames';
import type { FC } from 'react';

import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { formatDate } from '@zen/utils/dateTime';

import { useBookingCargoReadyDateQuery } from './graphql';

interface Props {
  className?: string;
  zencargoReference: string;
}

const CargoReadyDateSummary: FC<Props> = ({ zencargoReference, className }) => {
  const { data } = useBookingCargoReadyDateQuery({ variables: { zencargoReference } });
  const containerClassNames: string = cx('bg-grey-lightest px-6 py-5', className);
  const cargoReadyDate = data?.bookings?.nodes?.[0]?.cargoReadyDate?.date;

  if (!cargoReadyDate) {
    return null;
  }

  return (
    <div className={containerClassNames}>
      <div data-testid="original-crd">
        <div className="text-xs text-grey-base leading-normal">Cargo ready date</div>
        <div className="leading-normal text-sm text-grey-dark">
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '(date: string, format?: DateFormat) => strin... Remove this comment to see the full error message */}
          <ValueWithDefault formatValue={formatDate} value={cargoReadyDate} />
        </div>
      </div>
    </div>
  );
};

export default CargoReadyDateSummary;
