import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateCarrierBookingReferenceMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateCarrierBookingReferenceInput;
}>;

export type UpdateCarrierBookingReferenceMutationResult = { __typename?: 'Mutation' } & {
  bookingUpdateCarrierBookingReference?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateCarrierBookingReferencePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const UpdateCarrierBookingReferenceDocument = /* #__PURE__ */ gql`
  mutation updateCarrierBookingReference($input: UpdateCarrierBookingReferenceInput!) {
    bookingUpdateCarrierBookingReference(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateCarrierBookingReferenceMutation__
 *
 * To run a mutation, you first call `useUpdateCarrierBookingReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarrierBookingReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarrierBookingReferenceMutation, { data, loading, error }] = useUpdateCarrierBookingReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCarrierBookingReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCarrierBookingReferenceMutationResult,
    UpdateCarrierBookingReferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateCarrierBookingReferenceMutationResult, UpdateCarrierBookingReferenceMutationVariables>(
    UpdateCarrierBookingReferenceDocument,
    options
  );
}
export type UpdateCarrierBookingReferenceMutationHookResult = ReturnType<typeof useUpdateCarrierBookingReferenceMutation>;
