import type { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { mergeQueryParams } from '@zen/utils/QueryParams/queryParams';

import SkuOverviewTile from './SkuOverviewTile';
import { IntakeSkuOverview, IntakeSkuOverviewType } from './types';

const SkuOverview: FC<Partial<IntakeSkuOverview>> = ({ zeroStock, partialFulfilment, onTimeInFull, overFulfilled }) => {
  const { replace } = useHistory();
  const location = useLocation();

  const handleClick = (fulfilmentStatus: IntakeSkuOverviewType): void => {
    replace({
      ...location,
      search: mergeQueryParams(location.search, { fulfilmentStatus })
    });
  };

  return (
    <div className="pb-8 my-8 border-solid border-b border-grey-lighter">
      <div className="text-lg leading-normal text-grey-dark font-bold mb-4">SKU intake overview</div>
      <div className="flex">
        <SkuOverviewTile count={zeroStock} onClick={handleClick} type={IntakeSkuOverviewType.ZERO_STOCK} />
        <SkuOverviewTile count={partialFulfilment} onClick={handleClick} type={IntakeSkuOverviewType.PARTIAL_FULFILMENT} />
        <SkuOverviewTile count={onTimeInFull} onClick={handleClick} type={IntakeSkuOverviewType.ON_TIME_IN_FULL} />
        <SkuOverviewTile count={overFulfilled} onClick={handleClick} type={IntakeSkuOverviewType.OVER_FULFILLED} />
      </div>
    </div>
  );
};

export default SkuOverview;
