import { FC, ReactNode, useState } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import useRole from '@zen/Auth/hooks/useRole';
import { Role } from '@zen/Auth/types';
import { CargoModeEnum } from '@zen/Cargo';
import DateWithTimeZone from '@zen/Components/DateWithTimeZone';
import { ContextMenu, Dialog, Icon, MenuItemType } from '@zen/DesignSystem';
import { shipmentRoutes } from '@zen/Routes';
import { getTransportScheduleIdentifierLabel, getVesselNames } from '@zen/Shipment/TransportSchedule/helpers';
import { isAirShipment, isOceanShipment as isOcean, isRailShipment, isRoadShipment } from '@zen/Shipments';
import type { IconName } from '@zen/Styleguide';

import ShipmentDetailsBoardItem from '../ShipmentDetailsBoardItem';
import type { FlatShipmentDetailsBoard } from '../types';
import VehiclePlateNumberInput from '../VehiclePlateNumberInput';
import VehicleTrailerIdInput from '../VehicleTrailerIdInput';
import CarrierBookingReferenceTransportScheduleInput from './CarrierBookingReferenceTransportScheduleInput';
import { useRemoveTransportSchedule } from './useRemoveTransportSchedule';

interface Props {
  canManageSchedule: boolean;
  canViewSchedule: boolean;
  shipment: FlatShipmentDetailsBoard;
  zencargoReference: string;
}

const DetailsBoardTransportScheduleSection: FC<Props> = (props) => {
  const {
    canUpdateCarrierBookingReference,
    canUpdateVehiclePlateNumber,
    canViewShipmentTracking,
    cargoItems,
    cargoMode,
    carrierBookingReference,
    estimatedArrival,
    estimatedDeparture,
    modeOfTransport,
    transportSchedule
  } = props.shipment;

  const { canManageSchedule, zencargoReference, canViewSchedule } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const role = useRole();
  const { removeTransportSchedule, isRemoving } = useRemoveTransportSchedule();

  const isAdmin: boolean = role === Role.ADMIN;
  const hasCargo: boolean = cargoItems.length > 0;
  const isOceanShipment: boolean = isOcean(modeOfTransport);

  const isTransportScheduleEnabled: boolean =
    isOceanShipment || isAirShipment(modeOfTransport) || isRailShipment(modeOfTransport);
  const shouldShowTransportScheduleCreateOrRemove: boolean = isAdmin && isTransportScheduleEnabled;
  const shouldShowTransportScheduleInformation: boolean = (canManageSchedule || canViewSchedule) && isTransportScheduleEnabled;

  const isFTLRoadShipment: boolean = isRoadShipment(modeOfTransport) && cargoMode === CargoModeEnum.FTL && hasCargo;
  const hasTransportScheduleSet: boolean = !!transportSchedule;

  const shouldShowTransportScheduleUpdate: boolean = canManageSchedule && isOceanShipment;

  const carrierBookingRefClassName: string = shouldShowTransportScheduleInformation ? 'grow min-w-0' : 'w-1/2';

  const handleModalDisplay = (value: boolean = false): void => {
    setShowConfirmationModal(value);
  };

  const updateTransportScheduleItem: MenuItemType = {
    label: 'Assign transport schedule',
    linkTo: shipmentRoutes.shipmentTransportScheduleUpdate.getUrl(zencargoReference),
    icon: 'zicon-calendar' as IconName,
    disabled: isRemoving
  };

  const createTransportScheduleItem: MenuItemType = {
    label: 'Create transport schedule',
    linkTo: { pathname: shipmentRoutes.shipmentTransportScheduleCreate.getUrl(zencargoReference), state: { modeOfTransport } },
    icon: 'zicon-add' as IconName,
    disabled: isRemoving
  };

  const removeTransportScheduleItem: MenuItemType = {
    label: 'Remove transport schedule',
    onClick: () => handleModalDisplay(true),
    icon: 'zicon-trash' as IconName,
    disabled: !hasTransportScheduleSet || isRemoving
  };

  const contextMenuItems: MenuItemType[] = [
    ...(shouldShowTransportScheduleUpdate ? [updateTransportScheduleItem] : []),
    ...(shouldShowTransportScheduleCreateOrRemove ? [createTransportScheduleItem] : []),
    ...(shouldShowTransportScheduleCreateOrRemove ? [removeTransportScheduleItem] : [])
  ];

  const hasItemsToDisplay: boolean = contextMenuItems.length > 0;

  const origin: string = transportSchedule?.from || '';
  const destination: string = transportSchedule?.to || '';
  const transportCode: string = transportSchedule?.carrier || '';
  const vesselNames: string = getVesselNames(transportSchedule?.legs).join(', ');
  const canUpdateVehicleTrailerId: boolean = checkPermission(cargoItems[0], 'canUpdateVehicleTrailerId');

  const estimatedDepartureTitle: ReactNode = <DateWithTimeZone dateWithTimeZone={estimatedDeparture} />;
  const estimatedArrivalTitle: ReactNode = <DateWithTimeZone dateWithTimeZone={estimatedArrival} />;

  return (
    <div className="flex py-4 border-t border-solid border-grey-lighter">
      <div className="flex space-x-12 flex-1">
        {shouldShowTransportScheduleInformation && (
          <div className="flex w-2/5">
            <div className="flex w-1/2">
              <ShipmentDetailsBoardItem
                className="self-start"
                infoMessage="Estimated time of departure from port of load"
                title={estimatedDepartureTitle}
                value={origin}
              />
              <Icon className="self-center text-center grow" icon="zicon-arrow-right" />
            </div>
            <ShipmentDetailsBoardItem
              className="w-1/2"
              infoMessage="Estimated time of arrival to port of destination"
              title={estimatedArrivalTitle}
              value={destination}
            />
          </div>
        )}
        <div className="flex w-3/5 justify-between">
          {isTransportScheduleEnabled && (
            <div className="flex-1">
              <ShipmentDetailsBoardItem title={getTransportScheduleIdentifierLabel(modeOfTransport)} value={vesselNames} />
            </div>
          )}
          {isOceanShipment && canViewShipmentTracking && (
            <ShipmentDetailsBoardItem className="flex-1" title="SCAC code" value={transportCode} />
          )}

          {canViewShipmentTracking && (
            <div className={`mt-1.5 flex-1 ${carrierBookingRefClassName}`}>
              <CarrierBookingReferenceTransportScheduleInput
                canUpdateCarrierBookingReference={canUpdateCarrierBookingReference}
                carrierBookingReference={carrierBookingReference}
                zencargoReference={zencargoReference}
              />
            </div>
          )}

          {isFTLRoadShipment && (
            <div className="mt-1.5 flex-1" data-testid="vehicle-plate-number-input">
              <VehiclePlateNumberInput
                cargoId={cargoItems[0].id}
                disabled={!canUpdateVehiclePlateNumber}
                vehiclePlateNumber={cargoItems[0].collection?.vehiclePlateNumber || ''}
                zencargoReference={zencargoReference}
              />
            </div>
          )}

          {isFTLRoadShipment && (
            <div className="pl-5 flex-1">
              <div className="mt-1.5" data-testid="vehicle-trailer-id-input">
                <VehicleTrailerIdInput
                  cargoId={cargoItems[0].id}
                  disabled={!canUpdateVehicleTrailerId}
                  trailerId={cargoItems[0].trailerId || ''}
                />
              </div>
            </div>
          )}
          {hasItemsToDisplay && shouldShowTransportScheduleInformation && (
            <div className="flex flex-col flex-1 -mt-1">
              <span className="mr-2 text-grey-base">Transport schedule</span>
              <div className="self-end">
                <ContextMenu items={contextMenuItems} />
                <Dialog
                  buttonVariant="danger"
                  confirmLabel="Remove"
                  disabled={isRemoving}
                  header="Remove transport schedule?"
                  isOpen={showConfirmationModal}
                  message={`Would you like to remove this transport schedule from \n ${zencargoReference}? This action can’t be undone.`}
                  onClose={() => handleModalDisplay()}
                  onConfirm={() => removeTransportSchedule(zencargoReference, transportSchedule?.id || '', handleModalDisplay)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsBoardTransportScheduleSection;
