import cx from 'classnames';
import { FC, ReactNode, useEffect } from 'react';
import { Route, useParams } from 'react-router';
import { animateScroll, scroller } from 'react-scroll';

import { checkPermission } from '@zen/Auth/authHelper';
import useRoutePermissions from '@zen/Auth/RoutePermissions/useRoutePermissions';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Banner from '@zen/Components/Banner';
import Page from '@zen/Components/Page';
import { MAIN_CONTENT_PADDING_CLASS } from '@zen/Layout';
import Column from '@zen/Layout/components/Column';
import MainContentContainer from '@zen/Layout/components/MainContentContainer';
import Row from '@zen/Layout/components/Row';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import { StageValue } from '@zen/Quotes';
import { accrualRoutes } from '@zen/Routes';
import { DOCUMENT_ROUTE_PREFIX } from '@zen/Routes/documents';
import shipmentRoutes from '@zen/Routes/shipments';
import Section from '@zen/Shipment/ShipmentDetailsPage/Components/Section';
import type { ModeOfTransport } from '@zen/types';
import useIsVisible from '@zen/utils/hooks/useIsVisible';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Nullable } from '@zen/utils/typescript';

import MilestoneDateValidationProvider from './MilestoneDateProvider';
import type { NavItem } from './Navigation';
import Navigation from './Navigation';
import ShipmentDetailsBoard from './ShipmentDetailsBoard';
import ShipmentDetailsBoardHeader from './ShipmentDetailsBoardHeader';
import ShipmentDetailsNavigationHeader from './ShipmentDetailsNavigationHeader';
import { getOrderedColumns, getScrollableNavLinks, getSortingOrderType, prepareConfigParams } from './shipmentDetailsPageConfig';
import ShipmentDetailsTitleContent from './ShipmentDetailsTitleContent';
import TitleButtons from './TitleButtons';
import {
  RoadTrackingAction,
  RoadTrackingCategory,
  ShipmentDetailsPageData,
  ShipmentDetailsPageSectionConfig,
  ShipmentPageSectionUrl,
  SortingOrder,
  TrackingDetails
} from './types';

interface Props {
  canViewForwarder: boolean;
  shipmentDetailsPageData: ShipmentDetailsPageData;
  trackingDetails: Nullable<TrackingDetails>;
}

const ShipmentDetailsPage: FC<Props> = ({ canViewForwarder, trackingDetails, shipmentDetailsPageData }) => {
  const { sectionName } = useParams<{ sectionName: string }>();
  const [detailsBoardHeaderRef, isDetailsBoardHeaderVisible] = useIsVisible<HTMLDivElement>();
  const { check } = useGlobalPermissions();
  const { trackEvent } = useTracking();
  const { canAccessRoute } = useRoutePermissions();

  const navigationOffset: number = -200;

  useEffect(() => {
    if (sectionName) {
      setTimeout(() => {
        scroller.scrollTo(sectionName, { smooth: true, offset: navigationOffset });
      }, 2000);
    } else {
      animateScroll.scrollToTop();
    }
  }, [sectionName, navigationOffset]);

  const { pinned, accountUuid, stage, bookingRequest, modeOfTransport, quoteRequired, zencargoReference, consolidatedShipment } =
    shipmentDetailsPageData;

  const canArchiveShipment: boolean = checkPermission<ShipmentDetailsPageData>(shipmentDetailsPageData, 'canArchiveShipment');
  const canCloneShipment: boolean = checkPermission<ShipmentDetailsPageData>(shipmentDetailsPageData, 'canClone');
  const canPinShipment: boolean = checkPermission<ShipmentDetailsPageData>(shipmentDetailsPageData, 'canPin');
  const canManageQuotes: boolean = check('quotes.canAccessRoute');
  const canAccessBackoffice: boolean = check('backoffice.canAccessRoute');
  const canAccessAccruals: boolean = check('accruals.canAccessRoute');
  const isBookingRequest: boolean = stage?.value === StageValue.BOOKING_REQUESTED && !quoteRequired;
  const isShipmentArchived: boolean = !!shipmentDetailsPageData.archivedAt;
  const canAccessDocumentCreation: boolean = canAccessRoute(DOCUMENT_ROUTE_PREFIX);

  const sortingOrder: SortingOrder = getSortingOrderType(shipmentDetailsPageData.stage?.value!);

  const sectionConfig = prepareConfigParams(
    shipmentDetailsPageData,
    { canManageQuotes, canAccessBackoffice, canAccessAccruals, canAccessDocumentCreation },
    { canViewForwarder }
  );

  const [leftColumn, rightColumn] = getOrderedColumns(sectionConfig, sortingOrder);

  const handleTrackingLinkClick = (): void => {
    trackEvent({
      category: RoadTrackingCategory,
      action: RoadTrackingAction.TRACKING_LINK_CLICK,
      label: zencargoReference
    });
  };

  const navItems: NavItem[] = [
    ...getScrollableNavLinks(sectionConfig),
    {
      label: 'V & As',
      url: accrualRoutes.accrualIndex.getUrl(accountUuid, zencargoReference),
      isExternalLink: true,
      enabled: canAccessAccruals
    },
    {
      label: 'Tracking',
      url: trackingDetails?.mapUrl || '',
      isExternalLink: true,
      enabled: !!trackingDetails?.mapUrl,
      onClick: handleTrackingLinkClick
    }
  ];

  const renderSection = (shipmentDetailsPageSectionConfig: ShipmentDetailsPageSectionConfig): ReactNode => {
    const { label, url, component, ...rest } = shipmentDetailsPageSectionConfig;

    return (
      <Section key={url} anchor={url} title={label} {...rest}>
        {component}
      </Section>
    );
  };

  const titleContent: ReactNode = (
    <ShipmentDetailsTitleContent
      consolidatedShipmentId={consolidatedShipment?.id}
      consolidatedShipmentReference={consolidatedShipment?.reference}
      modeOfTransport={modeOfTransport as ModeOfTransport}
      trackingDetails={trackingDetails}
      zencargoReference={zencargoReference}
    />
  );

  const archivedShipmentContent: ReactNode = (
    <Banner
      className="mb-6 p-4 rounded"
      message="This shipment has been archived. Currently, archived shipments cannot be unarchived."
      type="warning"
    />
  );

  const actionButtons: ReactNode = (
    <div ref={detailsBoardHeaderRef}>
      <TitleButtons
        accountId={accountUuid}
        bookingRequest={bookingRequest}
        canAccessBackoffice={canAccessBackoffice}
        canArchiveShipment={canArchiveShipment}
        canCloneShipment={canCloneShipment}
        canPinShipment={canPinShipment}
        isBookingRequest={isBookingRequest}
        isPinned={pinned}
        isShipmentArchived={isShipmentArchived}
        zencargoReference={zencargoReference}
      />
    </div>
  );

  const navigationWrapperClassName = cx(`sticky z-20 ${MAIN_CONTENT_PADDING_CLASS} transition-all duration-300 ease-linear`, {
    'shadow-md bg-white py-5 top-28': !isDetailsBoardHeaderVisible,
    'bg-grey-lightest py-5 top-14': isDetailsBoardHeaderVisible
  });

  return (
    <MilestoneDateValidationProvider zencargoReference={zencargoReference}>
      <Page
        actionButtons={actionButtons}
        browserTitle={zencargoReference}
        contentClassName="bg-grey-lightest"
        defaultBackUrl={shipmentRoutes.shipmentIndex.getUrl()}
        title={titleContent}
      >
        <MainContentContainer className="bg-grey-lightest -mt-5">
          <ShipmentDetailsBoardHeader
            canViewForwarder={canViewForwarder}
            clientReference={shipmentDetailsPageData.clientReference}
            zencargoReference={zencargoReference}
          />
        </MainContentContainer>

        <div className="sticky z-20 top-16 h-px">
          <ShipmentDetailsNavigationHeader
            className={`py-2 ${MAIN_CONTENT_PADDING_CLASS} bg-white shadow`}
            subtitle={shipmentDetailsPageData.clientReference}
            title={titleContent}
            visible={!isDetailsBoardHeaderVisible}
          />
        </div>

        <div className={navigationWrapperClassName}>
          <Navigation navigationOffset={navigationOffset} navItems={navItems} />
        </div>

        <MainContentContainer>
          <div>
            {isShipmentArchived && archivedShipmentContent}
            <ShipmentDetailsBoard zencargoReference={zencargoReference} />
          </div>

          <Row className="relative mb-80">
            <Column data-testid="shipment-left-column" span={7}>
              {leftColumn.map(renderSection)}
            </Column>
            <Column data-testid="shipment-right-column" span={5}>
              {rightColumn.map(renderSection)}
            </Column>
          </Row>
        </MainContentContainer>
      </Page>

      <Route path={shipmentRoutes.shipmentDetailsPageOrders.path}>
        <OrderDetailsContainer
          originPath={shipmentRoutes.shipmentDetailsPage.getUrl(zencargoReference, ShipmentPageSectionUrl.ORDERS)}
        />
      </Route>
    </MilestoneDateValidationProvider>
  );
};

export default ShipmentDetailsPage;
