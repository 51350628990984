import type { ReactNode } from 'react';

import type { AccountStatusControlType } from './types';

interface AccountStatusConfigurationValues {
  confirmationText: string;
  formDescription: ReactNode;
}

interface AccountStatusControlUpdateValues {
  messageDescription: ReactNode;
}

export const getAccountStatusControlFormConfiguration = (
  configurationType: AccountStatusControlType,
  accountReference: string
): AccountStatusConfigurationValues => {
  switch (configurationType) {
    case 'status':
      return {
        formDescription: (
          <>
            The {accountReference} account will be blocked from Zencargo platform. <br />
            Type <span className="text-red-dark">"Deactivate {accountReference}"</span> to confirm.
          </>
        ),
        confirmationText: `Deactivate ${accountReference}`
      };
    case 'mode':
      return {
        formDescription: (
          <>
            The {accountReference} account will disable shipment booking and <br />
            customer access. Type <span className="text-red-dark">"Demo {accountReference}"</span> to confirm.
          </>
        ),
        confirmationText: `Demo ${accountReference}`
      };
  }
};

export const getAccountStatusControlUpdateConfiguration = (
  configurationType: AccountStatusControlType,
  accountReference: string,
  isActive?: boolean
): AccountStatusControlUpdateValues => {
  switch (configurationType) {
    case 'status':
      return {
        messageDescription: <>The {accountReference} account will have access to Zencargo platform.</>
      };
    case 'mode':
      return {
        messageDescription: (
          <>
            The {accountReference} account will enable shipment booking and business analysis
            {!isActive && ' after being activated'}.
          </>
        )
      };
  }
};
