import type { FC } from 'react';
import { Route, Switch, useParams } from 'react-router';

import { orderRoutes } from '@zen/Routes';

import LotAssignmentsBookingBasketProvider from './contexts/LotAssignmentsBookingBasketProvider';
import LotAssignmentsToBooking from './LotAssignmentsToBooking';

const LotAssignmentsContainer: FC = () => {
  const { lotAssignmentsToBooking } = orderRoutes;
  const { zencargoReference } = useParams<{ zencargoReference: string }>();

  return (
    <Switch>
      <Route path={lotAssignmentsToBooking.path}>
        <LotAssignmentsBookingBasketProvider {...{ zencargoReference }}>
          <LotAssignmentsToBooking {...{ zencargoReference }} />
        </LotAssignmentsBookingBasketProvider>
      </Route>
    </Switch>
  );
};

export default LotAssignmentsContainer;
