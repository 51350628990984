import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getEmptyState, hasEmptyState, mergeQueryParams, parseQueryParams, stringifyQueryParams } from './queryParams';
import type { QueryParams, QueryParamsType } from './types';

interface UseQueryParamsResult<T> {
  clearAllQueryParams: () => void;
  pushWithQueryParams: (path: string, params: Partial<T>, includeExistingQueryString?: boolean) => void;
  queryParams: T;
  setQueryParams: (params: Partial<T>, includeExistingQueryString?: boolean) => void;
}

const useQueryParams = <T extends {}>(objectKey?: string, initialValues: Partial<T> = {}): UseQueryParamsResult<T> => {
  const { search, pathname, ...rest } = useLocation();
  const history = useHistory();
  const parsedQueryParams: QueryParams = parseQueryParams(search);
  const queryStringParams: QueryParams = !objectKey ? parsedQueryParams : parsedQueryParams[objectKey];

  useEffect(() => {
    initialiseQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringParams]);

  const initialiseQueryParams = () => {
    if (isEmpty(queryStringParams) && initialValues) {
      appendQueryParams(initialValues);
    }
  };

  const setQueryParams = (params: Partial<T>, includeExistingQueryString: boolean = true): void => {
    const queryParams: QueryParamsType<T> = isEmpty(params) ? getEmptyState() : params;

    pushWithQueryParams(pathname, queryParams, includeExistingQueryString);
  };

  const appendQueryParams = (params: Partial<T>): void => {
    const queryString: string = getQueryString(params);

    history.replace({ pathname, search: queryString, ...rest });
  };

  const pushWithQueryParams = (path: string, params: QueryParamsType<T>, includeExistingQueryString: boolean = true): void => {
    const queryString: string = getQueryString(params, includeExistingQueryString);

    history.push(getUrl(path, queryString));
  };

  const clearAllQueryParams = (): void => {
    return setQueryParams({}, false);
  };

  const getQueryParams = (): T => {
    const xzy = queryStringParams || initialValues;
    const params = !hasEmptyState(xzy) ? xzy : {};

    return params as T;
  };

  const getQueryString = (params: QueryParamsType<T>, includeExistingQueryString: boolean = true): string => {
    if (!objectKey) {
      return stringifyQueryParams(params);
    }

    const latestBrowsersQueryString: string = document.location.search;
    const existingQueryString: string = includeExistingQueryString ? latestBrowsersQueryString : '';

    return mergeQueryParams(existingQueryString, { [objectKey]: params });
  };

  const getUrl = (pathName: string, queryString: string): string => {
    return `${pathName}${queryString}`;
  };

  return { queryParams: getQueryParams(), setQueryParams, clearAllQueryParams, pushWithQueryParams };
};

export default useQueryParams;
