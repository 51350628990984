import { LanguageTag } from '@zen/utils/languageSettings';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { convertJodaToDate, isParseableAsDate, parseDate } from '../jodaConnector';

type DatePart = { type: string; value: string };

export const noTimeZoneMessage: string = 'No timezone data';

export const formatTimeZone = (dateString: string, timeZone: Optional<string>): string => {
  if (!isParseableAsDate(dateString) || !timeZone) {
    return noTimeZoneMessage;
  }

  const date: Date = convertJodaToDate(parseDate(dateString));
  const formatOptions: Intl.DateTimeFormatOptions = { timeZone, timeZoneName: 'long' };

  try {
    const dateParts: DatePart[] = Intl.DateTimeFormat(LanguageTag.BritishEnglish, formatOptions).formatToParts(date);

    return timeZoneName(dateParts) || noTimeZoneMessage;
  } catch {
    return noTimeZoneMessage;
  }
};

const timeZoneName = (dateParts: DatePart[]): Nullable<string> => {
  const timeZonePart = dateParts.find(({ type }: DatePart) => type === 'timeZoneName');

  return timeZonePart?.value || null;
};
