import 'react-loading-skeleton/dist/skeleton.css';

import cx from 'classnames';
import type { FC } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Color } from '@zen/Styleguide';

interface Props {
  circle?: boolean;
  className?: string;
  count?: number;
  height?: number | string;
  lighter?: boolean;
  width?: string;
}

const SkeletonLoading: FC<Props> = ({ height = 20, count = 1, circle = false, width, className, lighter = false }) => {
  const classNames = cx('mx-2 z-0', width, className);
  const style = circle ? { borderRadius: '50%' } : {};

  return (
    <SkeletonTheme baseColor={lighter ? Color.GREY_LIGHTER : Color.GREY_LIGHTEST} highlightColor={Color.WHITE}>
      <div className={classNames} data-testid="skeleton-loading">
        <Skeleton className="skeleton" count={count} height={height} style={style} />
      </div>
    </SkeletonTheme>
  );
};

export type { Props as SkeletonLoadingProps };

export default SkeletonLoading;
