import cx from 'classnames';
import type { FC } from 'react';

interface Props {
  className?: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

const Gateway: FC<Props> = ({ isDisabled = false, onClick, children, className }) => {
  const hoverClasses: string = 'cursor-pointer transition-all duration-200 group hover:border-azure-base active:bg-azure-lighter';

  const classNames = cx(
    {
      [hoverClasses]: onClick,
      'bg-white': !isDisabled,
      'bg-grey-lightest': isDisabled
    },
    'rounded border border-solid border-grey-light',
    className
  );

  return (
    <div className={classNames} data-testid="gateway" onClick={onClick}>
      {children}
    </div>
  );
};

export type { Props as GatewayProps };

export default Gateway;
