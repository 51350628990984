import type { FC } from 'react';

import { Button, Headline, LegacyModal } from '@zen/DesignSystem';

import TemplatesList from '../TemplatesList';
import type { AccrualTemplate } from '../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreate: () => void;
  onTemplatePick: (accrualTemplate: AccrualTemplate) => void;
  title?: string;
}

const NewAccrualModal: FC<Props> = (props) => {
  const { isOpen, onClose, onCreate, onTemplatePick, title } = props;
  const modalTitle = title || 'Create a new blank accrual or select one of the templates below';

  return (
    <LegacyModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col py-10 px-24 text-center max-w-180">
        <Headline className="text-navy-dark mb-6" level={2}>
          {modalTitle}
        </Headline>
        <Button iconLeft="zicon-file" onClick={() => onCreate()} variant="secondary">
          Blank template
        </Button>
        <div className="text-grey-base mt-6">Or use an existing template.</div>
        <TemplatesList handleSelect={onTemplatePick} />
      </div>
    </LegacyModal>
  );
};

export default NewAccrualModal;
