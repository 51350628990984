import type { FC } from 'react';

import ShipmentDetailsBoardItem from '../ShipmentDetailsBoardItem';
import type { FlatShipmentDetailsBoard } from '../types';
import CustomsOnlyInput from './CustomsOnlyInput';
import HouseBillOfLadingCargoInfoInput from './HouseBillOfLadingCargoInfoInput';
import IncotermsCargoInfoInput from './IncotermsCargoInfoInput';
import MasterBillOfLadingCargoInfoInput from './MasterBillOfLadingCargoInfoInput';
import ModeOfTransportCargoInfoInput from './ModeOfTransportCargoInfoInput';
import ShipmentTypeCargoInfoInput from './ShipmentTypeCargoInfoInput';
import TradeRoleCargoInfoInput from './TradeRoleCargoInfoInput';

interface Props {
  refetchShipmentDetails: () => void;
  shipment: FlatShipmentDetailsBoard;
  zencargoReference: string;
}

const DetailsBoardCargoInfoSection: FC<Props> = (props) => {
  const { zencargoReference, refetchShipmentDetails, shipment } = props;
  const {
    consignor,
    consignee,
    customsOnly,
    keyContact,
    shipmentType,
    tradeRole,
    incoterms,
    masterBillOfLading,
    modeOfTransport,
    houseBillOfLading,
    canUpdateCustomsOnly,
    canUpdateShipmentType,
    canUpdateTradeRole,
    canUpdateIncoterms,
    canUpdateMasterBillOfLading,
    canUpdateHouseBillOfLading,
    canUpdateModeOfTransport,
    canViewKeyContact,
    canViewMasterBillOfLading,
    canViewOperationsManager: canViewOperationsCoordinator,
    operationsManager: operationsCoordinator
  } = shipment;

  return (
    <div className="flex py-6 border-t border-solid border-grey-lighter">
      <div className="w-2/5 pt-2.5 grid grid-cols-2 gap-y-5 gap-x-2 auto-rows-min">
        <ModeOfTransportCargoInfoInput
          canUpdateModeOfTransport={canUpdateModeOfTransport}
          modeOfTransport={modeOfTransport}
          refetchShipmentDetails={refetchShipmentDetails}
          zencargoReference={zencargoReference}
        />
        <ShipmentTypeCargoInfoInput
          canUpdateShipmentType={canUpdateShipmentType}
          refetchShipmentDetails={refetchShipmentDetails}
          shipmentType={shipmentType}
          zencargoReference={zencargoReference}
        />
        <IncotermsCargoInfoInput
          canUpdateIncoterms={canUpdateIncoterms}
          incoterms={incoterms}
          refetchShipmentDetails={refetchShipmentDetails}
          zencargoReference={zencargoReference}
        />
        <TradeRoleCargoInfoInput
          canUpdateTradeRole={canUpdateTradeRole}
          refetchShipmentDetails={refetchShipmentDetails}
          tradeRole={tradeRole}
          zencargoReference={zencargoReference}
        />
        {canUpdateCustomsOnly && <CustomsOnlyInput customsOnly={customsOnly} zencargoReference={zencargoReference} />}
      </div>
      <div className="w-1/5 pt-2.5 grid grid-cols-1 pl-4 pr-5 mx-4 gap-y-5 auto-rows-min border-r border-l border-solid border-grey-lighter">
        {canViewMasterBillOfLading && (
          <MasterBillOfLadingCargoInfoInput
            canUpdateMasterBillOfLading={canUpdateMasterBillOfLading}
            masterBillOfLading={masterBillOfLading}
            zencargoReference={zencargoReference}
          />
        )}
        <HouseBillOfLadingCargoInfoInput
          canUpdateHouseBillOfLading={canUpdateHouseBillOfLading}
          houseBillOfLading={houseBillOfLading}
          zencargoReference={zencargoReference}
        />
      </div>
      <div className="grid w-2/5 grid-cols-2 gap-y-7 gap-x-2 auto-rows-min">
        {canViewOperationsCoordinator && <ShipmentDetailsBoardItem title="Ops coordinator" value={operationsCoordinator} />}
        {canViewKeyContact && <ShipmentDetailsBoardItem title="Key contact" value={keyContact} />}
        <ShipmentDetailsBoardItem title="Consignee" value={consignee} />
        <ShipmentDetailsBoardItem title="Consignor" value={consignor} />
      </div>
    </div>
  );
};

export default DetailsBoardCargoInfoSection;
