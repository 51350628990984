/* eslint-disable react/no-unused-prop-types */
import cx from 'classnames';
import type { FC } from 'react';

import type { GeoCoordinates } from '../types';
import type { ClusterColor } from './types';

interface Props extends GeoCoordinates {
  color?: ClusterColor;
  isHighlighted?: boolean;
  onClick: () => void;
  pointCount: number;
  totalPoints: number;
}

const Cluster: FC<Props> = ({ color = 'blue', isHighlighted, onClick, pointCount, totalPoints }) => {
  const size: string = `${30 + (pointCount / totalPoints) * 20}px`;

  const className = cx(
    {
      'bg-azure-base': color === 'blue' && !isHighlighted,
      'bg-azure-light': color === 'blue' && isHighlighted,
      'hover:text-azure-base': color === 'blue',
      'bg-green-base': color === 'green' && !isHighlighted,
      'bg-green-dark': color === 'green' && isHighlighted,
      'hover:text-green-base': color === 'green',
      'bg-orange-base': color === 'orange' && !isHighlighted,
      'bg-orange-dark': color === 'orange' && isHighlighted,
      'hover:text-orange-base': color === 'orange',
      'bg-red-base': color === 'red' && !isHighlighted,
      'bg-red-dark': color === 'red' && isHighlighted,
      'hover:text-red-base': color === 'red'
    },
    'w-8 h-8 cursor-pointer rounded-full flex items-center font-sans justify-center',
    'border border-solid border-white text-white hover:bg-white hover:border-grey-lighter text-xs relative z-20 hover:z-40',
    'transform -translate-x-1/2 -translate-y-1/2'
  );

  return (
    <div className={className} data-testid="cluster" onClick={onClick} style={{ width: size, height: size }}>
      {pointCount}
    </div>
  );
};

export type { Props as ClusterType };

export default Cluster;
