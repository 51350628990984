import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { TableConfigurationProvider } from '@zen/DesignSystem';
import { SavedFiltersProvider } from '@zen/utils/Filtering/contexts/SavedFiltersContext';

import { SubscriptionContextProvider } from './contexts/SubscriptionContext';
import Shipments from './Shipments';
import type { ShipmentFilters } from './types';

const ShipmentsContainer = () => {
  const { check } = useGlobalPermissions();
  const canViewCustomers: boolean = check('bookings.canViewCustomers');
  const initialHiddenColumns: string[] = canViewCustomers ? [] : ['customerAndRef', 'shippingOrderStatus'];

  return (
    <SubscriptionContextProvider>
      <SavedFiltersProvider<ShipmentFilters> filterName="savedShipmentFilters">
        <TableConfigurationProvider initialHiddenColumns={initialHiddenColumns} tableId="shipments">
          <Shipments />
        </TableConfigurationProvider>
      </SavedFiltersProvider>
    </SubscriptionContextProvider>
  );
};

export default ShipmentsContainer;
