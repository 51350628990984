// whenever you update the HEADER_HEIGHT_IN_REM
// please update HEADER_HEIGHT value accordingly
export const HEADER_HEIGHT: number = 64;
export const HEADER_HEIGHT_IN_REM: number = 4;

// whenever you update the width of the sidebar
// please update SIDEBAR_WIDTH_IN_REM value accordingly
export const SIDEBAR_WIDTH_CLASS: string = 'w-60';
export const SIDEBAR_WIDTH_IN_REM: number = 15;

export const MAIN_CONTENT_PADDING_CLASS: string = 'px-10';

export const FIXED_HEADER_ZINDEX: string = 'z-50';
export const SIDEBAR_ZINDEX: string = 'z-30';
export const SLIDEOUT_OVERLAY_ZINDEX: string = 'z-30';
export const SLIDEOUT_ZINDEX: string = 'z-30';
export const TOP_NAVIGATION_HEADER_ZINDEX: string = 'z-40';
export const MODAL_OVERLAY_ZINDEX: string = 'z-50';
export const MODAL_ZINDEX: string = 'z-50';
