import { debounce } from 'lodash';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import type { Nullable } from '../typescript';

type DOMRect = Pick<DOMRectReadOnly, 'x' | 'y' | 'width' | 'height' | 'top' | 'left' | 'bottom' | 'right'>;

const emptyRect: DOMRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
};

const isResizeObserverSupported = typeof window === 'object' && window.ResizeObserver;

const useResizeObserver = <T extends Nullable<HTMLElement> = HTMLElement>(elRef: Nullable<MutableRefObject<T>>): [DOMRect] => {
  const [rect, setRect] = useState<DOMRect>(emptyRect);
  const observerRef = useRef(
    new window.ResizeObserver(
      debounce((entries) => {
        if (!entries.length) {
          return;
        }
        // we observe only one element, read first entry
        const { contentRect } = entries[0];

        setRect(contentRect);
      }, 50)
    )
  );

  useEffect(() => {
    if (!elRef?.current) {
      return;
    }
    const observer = observerRef.current;

    observer.observe(elRef.current!);

    return () => {
      observer.disconnect();
    };
  }, [elRef, observerRef]);

  return [rect];
};

const useNoopResizeObserver: typeof useResizeObserver = () => [emptyRect];

export default isResizeObserverSupported ? useResizeObserver : useNoopResizeObserver;
