import * as Yup from 'yup';

export const shipmentDelayReasonValidation = {
  reason: Yup.string()
    .when('description', {
      is: (value: string) => !!value,
      then: Yup.string().nullable().required('Please select delay reason')
    })
    .nullable()
};

export const shipmentDateFormValidation = Yup.object().shape({
  date: Yup.string().required('Please provide a date').nullable(),
  ...shipmentDelayReasonValidation
});
