import type { DocumentTemplateFormValues } from './types';

export const getDocumentTemplateFormInitialValues = (): Omit<DocumentTemplateFormValues, 'id'> => ({
  name: '',
  externalId: '',
  externalKey: '',
  fields: {
    cargo: {
      authorisationCode: false,
      cargoDescription: false,
      cargoType: false,
      cargoValue: false,
      cbm: false,
      containerNumbers: false,
      cpc: false,
      customsExport: false,
      customsImport: false,
      dan: false,
      doorToPortFreight: false,
      grossWeight: false,
      importerOfRecordNumber: false,
      incoterms: false,
      insuranceValue: false,
      markAndNumbers: false,
      netWeight: false,
      pieceCount: false,
      productType: false,
      ucrNumber: false
    },
    collection: {
      cargoReadyDate: false,
      collectionContactName: false,
      collectionDate: false,
      collectionDateTime: false,
      collectionPoint: false,
      equipmentBooked: false
    },
    contacts: {
      attn: false,
      to: false
    },
    delivery: {
      deliveryContactName: false,
      deliveryDate: false,
      deliveryDetails: false
    },
    invoiceDate: {
      customerInvoiceDate: false
    },
    manager: {
      operationsManager: false,
      operationsManagerEmail: false
    },
    other: {
      specialInstructions: false,
      today: false
    },
    rates: {
      agreedRate: false,
      clientSellRate: false,
      supplierBuyRate: false,
      vat: false
    },
    references: {
      clientReference: false,
      supplierReference: false,
      zencargoReference: false
    },
    supplier: {
      supplierCompanyName: false,
      supplierContactName: false
    },
    tradeParties: {
      accountCompanyAddress: false,
      accountCompanyName: false,
      clientContactName: false,
      consigneeAddress: false,
      consigneeName: false,
      notifyPartyDetails: false,
      shipperAddress: false,
      shipperName: false
    },
    voyage: {
      arrivalPort: false,
      countryOfOrigin: false,
      departurePort: false,
      flightNumber: false,
      houseBolNumber: false,
      originalBolNumber: false,
      scac: false,
      transhipmentPort: false,
      vessel: false,
      vesselEta: false,
      vesselEtd: false,
      vesselFirstUsArrival: false,
      vesselNumber: false
    }
  }
});
