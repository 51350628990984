import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AllAccountsQueryVariables = GraphQLTypes.Exact<{
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type AllAccountsQueryResult = { __typename?: 'Query' } & {
  accounts: { __typename?: 'AccountConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'name' | 'uuid'> & {
              division?: GraphQLTypes.Maybe<
                { __typename?: 'Division' } & Pick<GraphQLTypes.Division, 'id' | 'name' | 'accountId'>
              >;
            }
        >
      >
    >;
  };
};

export const AllAccountsDocument = /* #__PURE__ */ gql`
  query allAccounts($searchQuery: String) {
    accounts(textContains: $searchQuery, paginated: false) {
      nodes {
        name
        uuid
        division {
          id
          name
          accountId
        }
      }
    }
  }
`;

/**
 * __useAllAccountsQuery__
 *
 * To run a query within a React component, call `useAllAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAccountsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useAllAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AllAccountsQueryResult, AllAccountsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AllAccountsQueryResult, AllAccountsQueryVariables>(AllAccountsDocument, options);
}
export function useAllAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllAccountsQueryResult, AllAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AllAccountsQueryResult, AllAccountsQueryVariables>(AllAccountsDocument, options);
}
export type AllAccountsQueryHookResult = ReturnType<typeof useAllAccountsQuery>;
export type AllAccountsLazyQueryHookResult = ReturnType<typeof useAllAccountsLazyQuery>;
