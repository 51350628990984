import { Color } from '@zen/Styleguide';

export const getColor = (percentage: number): string => {
  if (percentage <= 25) {
    return Color.RED_BASE;
  }

  if (percentage <= 50) {
    return Color.ORANGE_BASE;
  }

  if (percentage <= 75) {
    return Color.AZURE_BASE;
  }

  return Color.GREEN_BASE;
};
