import AreaChart from '@zen/Components/AreaChart';
import CollapseContainer from '@zen/Components/CollapseContainer';

import type { DateRange, IntakeChartValue, WeeklyIntakeValue } from '../types';
import ChartHeader from './ChartHeader';
import { getIntakeTotals, getPreparedData } from './chartHelpers';
import ChartTooltip from './ChartTooltip';

interface IntakeChartContainerProps {
  data: WeeklyIntakeValue[];
  dateRange: DateRange;
  onDotClick?: (payload: IntakeChartValue) => void;
}

const boxShadow = '2px 5px 23px -6px rgba(163,163,163,1)';

const IntakeChartContainer = ({ data, dateRange: { startDate, endDate }, onDotClick }: IntakeChartContainerProps) => {
  const preparedData = getPreparedData(data);

  const { totalQuantityArrived, totalQuantityPlanned, totalValuePlanned, totalValueArrived } = getIntakeTotals(data);

  return (
    <CollapseContainer className="mt-8" collapseLabel="Collapse chart" showLabel="Reveal chart">
      <div className="bg-white p-8 pr-16 flex flex-1" style={{ boxShadow }}>
        <div className="w-0 flex-1">
          <div className="flex flex-col">
            <ChartHeader
              endDate={endDate}
              startDate={startDate}
              totalQuantityArrived={totalQuantityArrived}
              totalQuantityPlanned={totalQuantityPlanned}
              totalValueArrived={totalValueArrived}
              totalValuePlanned={totalValuePlanned}
            />
            <AreaChart<IntakeChartValue>
              areaDataKey="quantityPlanned"
              data={preparedData}
              lineDataKey="quantityArrivingPosition"
              onDotClick={onDotClick}
              tooltip={ChartTooltip}
            />
          </div>
        </div>
      </div>
    </CollapseContainer>
  );
};

export default IntakeChartContainer;
