import type { FC, ReactNode } from 'react';

import TerminalIcon from '@zen/Components/TerminalIcon';
import { apolloClient as apollo } from '@zen/graphql/GraphQLProvider';
import type { Terminal, TerminalTypeValue } from '@zen/Networks/types';
import { GetTerminalsDocument } from '@zen/TradeLanes/graphql';

import FormAsyncSelect from '../FormAsyncSelect';
import type { FormFieldProps } from '../FormField';
import { highlightQuery } from '../utils';

const getItemIcons = (terminalTypes: TerminalTypeValue[]) =>
  terminalTypes?.map(
    (terminalType: TerminalTypeValue, index: number): ReactNode => (
      <TerminalIcon key={index} className="ml-2" terminalType={terminalType} />
    )
  );

interface Props extends FormFieldProps {
  isClearable?: boolean;
  isDisabled?: boolean;
  terminalTypes?: TerminalTypeValue[];
}

const FormTerminalsSelect: FC<Props> = (props) => {
  const { isClearable = false, isDisabled = false, terminalTypes } = props;

  const handleInputChange = async (query: string): Promise<Terminal[]> => {
    const QUERY = {
      query: GetTerminalsDocument,
      variables: {
        query,
        terminalTypes
      }
    };
    const response = await apollo.query(QUERY);

    return response.data.terminals.nodes;
  };

  const getOptionLabel = (terminal: Terminal, inputValue: string): ReactNode => (
    <div className="flex justify-between items-center">
      <div>{highlightQuery(terminal.label || '', inputValue)}</div>
      {terminal.terminalTypes && <div className="flex">{getItemIcons(terminal.terminalTypes)}</div>}
    </div>
  );

  return (
    <FormAsyncSelect<Terminal>
      {...props}
      formatOptionLabel={getOptionLabel}
      isClearable={isClearable}
      isDisabled={isDisabled}
      loadOptions={handleInputChange}
    />
  );
};

export default FormTerminalsSelect;
