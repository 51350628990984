import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ReconfirmBookingStaleDataMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ReconfirmInput;
}>;

export type ReconfirmBookingStaleDataMutationResult = { __typename?: 'Mutation' } & {
  bookingStaleDataReconfirm?: GraphQLTypes.Maybe<
    { __typename?: 'ReconfirmPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const ReconfirmBookingStaleDataDocument = /* #__PURE__ */ gql`
  mutation reconfirmBookingStaleData($input: ReconfirmInput!) {
    bookingStaleDataReconfirm(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useReconfirmBookingStaleDataMutation__
 *
 * To run a mutation, you first call `useReconfirmBookingStaleDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconfirmBookingStaleDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconfirmBookingStaleDataMutation, { data, loading, error }] = useReconfirmBookingStaleDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReconfirmBookingStaleDataMutation(
  baseOptions?: Apollo.MutationHookOptions<ReconfirmBookingStaleDataMutationResult, ReconfirmBookingStaleDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ReconfirmBookingStaleDataMutationResult, ReconfirmBookingStaleDataMutationVariables>(
    ReconfirmBookingStaleDataDocument,
    options
  );
}
export type ReconfirmBookingStaleDataMutationHookResult = ReturnType<typeof useReconfirmBookingStaleDataMutation>;
