import type { FC } from 'react';

import { useRejectQuoteOptionReasonMutation } from '@zen/Quotes/graphql/rejectQuoteOptionReason.generated';
import type { QuoteOption, QuoteOptionRejectionReason } from '@zen/Quotes/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import QuoteOptionRejectReasonItem from './QuoteOptionRejectReasonItem';

interface Props {
  quoteOption: QuoteOption;
}

const QuoteOptionRejectReasonItemContainer: FC<Props> = ({ quoteOption }) => {
  const { addSuccess, addError } = useNotification();

  const [submitRejectionReason] = useRejectQuoteOptionReasonMutation();

  const handleSubmit = async (
    rejectionReason: QuoteOptionRejectionReason,
    reasonDescription?: string
  ): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        submitRejectionReason({
          variables: {
            input: {
              quoteOptionId: quoteOption?.id ?? '',
              rejectionReason,
              reasonDescription
            }
          }
        }),
      onError: addError,
      onSuccess: () => addSuccess('Thank you for your feedback.')
    });
  };

  return <QuoteOptionRejectReasonItem onSubmit={handleSubmit} quoteOption={quoteOption} />;
};

export default QuoteOptionRejectReasonItemContainer;
