import { useGetShipmentDetailsBoardQuery } from './graphql';

export const useGetShipmentDetailsBoardData = (zencargoReference: string, newCargoService: boolean) => {
  const queryResult = useGetShipmentDetailsBoardQuery({
    variables: { zencargoReference, newCargoService },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  return queryResult;
};
