import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import type { ActivityFeedOriginViewType } from '@zen/ActivityFeed/types';
import { checkPermission } from '@zen/Auth/authHelper';
import AutomaticAccountSwitcher from '@zen/Components/AutomaticAccountSwitcher';
import QueryHandler from '@zen/Components/QueryHandler';
import { isRoadShipment, ModeOfTransport } from '@zen/Shipments';
import { useControlTowerPermissionsQuery } from '@zen/Shipments/graphql';
import type { Optional } from '@zen/utils/typescript';

import { useGetShipmentDetailsPageDataQuery, useRoadTrackingDetailsQuery } from '../ShipmentDetailsPage/graphql';
import ShipmentDetailsPage from '../ShipmentDetailsPage/ShipmentDetailsPage';
import ShipmentDetailsPageContext from '../ShipmentDetailsPage/ShipmentDetailsPageContext/ShipmentDetailsPageContext';
import type { ControlTower, ShipmentDetailsPageData } from '../ShipmentDetailsPage/types';
import { prepareRoadTrackingDetails, RoadTrackingData } from './helpers';

interface Props {
  activityFeedViewType?: ActivityFeedOriginViewType;
}

const ShipmentDetailsPageContainer: FC<Props> = ({ activityFeedViewType }) => {
  const { id: zencargoReference } = useParams<{ id: string }>();

  const { data, error, loading } = useGetShipmentDetailsPageDataQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { zencargoReference }
  });

  const shipmentDetailsPageData: Optional<ShipmentDetailsPageData> = data?.bookings?.nodes?.[0];
  const accountId: Optional<string> = shipmentDetailsPageData?.accountUuid;
  const modeOfTransport: Optional<ModeOfTransport> = shipmentDetailsPageData?.modeOfTransport;
  const canViewRoadTracking: boolean = !!shipmentDetailsPageData?.canViewRoadTracking?.value;
  const shouldSkipRoadTrackingQuery: boolean = !isRoadShipment(modeOfTransport) || !canViewRoadTracking;

  const { data: controlTowerData } = useControlTowerPermissionsQuery({
    variables: { uuid: accountId! },
    skip: !accountId
  });

  const { data: roadTrackingResults } = useRoadTrackingDetailsQuery({
    fetchPolicy: 'no-cache',
    variables: { zencargoReference },
    skip: shouldSkipRoadTrackingQuery
  });

  const controlTower: ControlTower = controlTowerData?.account?.controlTower as ControlTower;
  const canViewForwarder: boolean = checkPermission<ControlTower>(controlTower, 'canViewForwardersOnShipments');
  const roadTrackingData: Optional<RoadTrackingData> = roadTrackingResults?.bookings?.nodes?.[0]?.roadTracking;

  return (
    <ShipmentDetailsPageContext.Provider value={{ activityFeedViewType }}>
      {accountId && <AutomaticAccountSwitcher accountIdForUrl={accountId} />}

      <QueryHandler data={shipmentDetailsPageData} error={!!error} isLoading={loading}>
        {(shipmentDatailsPageData: ShipmentDetailsPageData) => (
          <ShipmentDetailsPage
            canViewForwarder={canViewForwarder}
            shipmentDetailsPageData={shipmentDatailsPageData}
            trackingDetails={prepareRoadTrackingDetails(roadTrackingData)}
          />
        )}
      </QueryHandler>
    </ShipmentDetailsPageContext.Provider>
  );
};

export default ShipmentDetailsPageContainer;
