import type { ApolloError, FetchMoreOptions } from '@apollo/client/core';
import type { ApolloQueryResult } from '@apollo/client/core/types';
import type { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';

import type { PageInfo } from '@zen/types';
import type { Optional, Undefinable } from '@zen/utils/typescript';

export enum PaginationDirection {
  AFTER = 'after',
  BEFORE = 'before'
}

export type UseQueryHookType<Result, Variables> = (args: QueryHookOptions<Result, Variables>) => QueryResult<Result, Variables>;

export type PaginatedVariables = {
  after?: Optional<string>;
  before?: Optional<string>;
  first?: Optional<number>;
  last?: Optional<number>;
};

export interface PaginatedQueryResult<Result, Variables, NodeType = any> {
  error: Undefinable<ApolloError>;
  loading: boolean;
  nodes: NodeType[];
  paginationInfo: PageInfo;
  refetch: (variables?: Partial<Variables>) => Promise<ApolloQueryResult<Result>>;
  totalCount: number;
}

export type PaginatedQueryHookResult<Result, Variables, NodeType> = Omit<
  PaginatedQueryResult<Result, Variables, NodeType>,
  'paginationInfo'
> & { fetchMore: QueryResult<Result, Variables>['fetchMore']; pageInfo: PagePosition };

export type UpdateQueryType = FetchMoreOptions['updateQuery'];

export type PagePosition = { endCursor?: string; hasNextPage: boolean; hasPreviousPage: boolean; startCursor?: string };

export type PaginationQueryHookVariables<Variables> = Omit<Variables, 'first' | 'last' | 'after' | 'before'>;
