import type { IconName } from '@zen/Styleguide';
import { ModeOfTransport } from '@zen/types';

export const getModeOfTransportIconName = (transportMode: ModeOfTransport): IconName => {
  switch (transportMode) {
    case ModeOfTransport.AIR:
      return 'zicon-air';
    case ModeOfTransport.OCEAN:
      return 'zicon-ship';
    case ModeOfTransport.RAIL:
      return 'zicon-rail';
    case ModeOfTransport.TRUCK:
      return 'zicon-road';
  }
};
