import type { FC } from 'react';

import FormLabel from '@zen/Components/Form/FormLabel';
import { Loading, Select } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useGetBookingCoordinatorsByTypeQuery } from './graphql';
import { getCoordinatorsOptions } from './helpers';
import type { BookingManager, ManagerTeam } from './types';

interface Props {
  currentCoordinator?: Optional<string>;
  disabled?: boolean;
  label: string;
  name: string;
  onCoordinatorUpdate: (coordinatorId: string) => void;
  type: ManagerTeam;
}

const BookingCoordinatorSelect: FC<Props> = (props) => {
  const { currentCoordinator, disabled, label, name, onCoordinatorUpdate, type } = props;

  const { data, error, loading } = useGetBookingCoordinatorsByTypeQuery({
    variables: {
      managerType: type
    }
  });

  const handleChange = (coordinatorId: Nullable<string>): void => {
    if (coordinatorId) {
      onCoordinatorUpdate(coordinatorId);
    }
  };

  if (loading) {
    return <Loading size="small" withMargin={false} />;
  }

  return (
    <div>
      <FormLabel className="text-grey-base" label={label} />
      <Select
        key={label}
        isDisabled={disabled}
        name={name}
        onChange={handleChange}
        options={getCoordinatorsOptions(data?.bookingManagers as BookingManager[])}
        value={currentCoordinator}
      />
      {error && <div className="text-sm text-red-base">Something went wrong. Please try again later</div>}
    </div>
  );
};

export default BookingCoordinatorSelect;
