import moment from 'moment';

import { AmPm, SpecificTime } from '../../../types';

type Moment = moment.Moment;

interface RiskInput {
  estimatedArrival: string;
  freeTimeEnds: string;
}

class DeliveryRisk {
  private riskInput: RiskInput;

  constructor(riskInput: RiskInput) {
    this.riskInput = riskInput;
  }

  public dateRisk(date: string): string {
    const d = moment(date);

    if (this.stripDate(date) === this.stripDate(this.riskInput.estimatedArrival)) {
      return 'This delivery may not be possible depending on when the container clears customs';
    }

    if (d.isAfter(this.riskInput.freeTimeEnds)) {
      return 'You will incur demurrage if you select this date';
    }

    if (this.isWeekend(d)) {
      return 'Selecting a weekend might increase delivery costs';
    }

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    return null;
  }

  public timeRisk(t: SpecificTime): string {
    if (
      (t.hour < 9 && t.ampm === AmPm.AM) ||
      (t.hour === 12 && t.ampm === AmPm.AM) ||
      (t.hour >= 5 && t.hour < 12 && t.ampm === AmPm.PM)
    ) {
      return 'The selected delivery option may be subject to surcharge';
    }

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    return null;
  }

  private stripDate(date: string) {
    const d = moment(date);

    return d && d.startOf('day').toDate().getDate();
  }

  private isWeekend(d: Moment) {
    const weekday = d.isoWeekday();

    return weekday === 6 || weekday === 7;
  }
}

export default DeliveryRisk;
