import type { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import DocumentTeamplatesContainer from '@zen/DocumentTemplates/DocumentTemplatesContainer';
import productSettingsRoutes from '@zen/Routes/productSettings';
import TerminalsContainer from '@zen/Terminals/TerminalsContainer';

import ProductSettings from '../ProductSettings';

const ProductSettingsContainer: FC = () => {
  return (
    <Switch>
      <Route exact={true} path={productSettingsRoutes.productSettingsIndex.path}>
        <ProductSettings />
      </Route>
      <Route path={productSettingsRoutes.manageTerminals.path}>
        <TerminalsContainer />
      </Route>
      <Route path={productSettingsRoutes.documentTemplatesIndex.path}>
        <DocumentTeamplatesContainer />
      </Route>
    </Switch>
  );
};

export default ProductSettingsContainer;
