import { isEmpty } from 'lodash';
import type { FC } from 'react';

import { useGetConsolidatedShipmentLegsQuery } from '@zen/Consolidation/graphql/getConsolidatedShipmentLegsQuery.generated';
import { Loading } from '@zen/DesignSystem';
import { getNetworksFieldValueWithName } from '@zen/Networks';
import { consolidationRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import { useQueryParams } from '@zen/utils/QueryParams';
import type { Optional } from '@zen/utils/typescript';

import { emptyConsolidation, getEmptyConsolidationLeg, prepareConsolidationInput } from '../consolidation.helper';
import ConsolidationForm from '../ConsolidationForm';
import { useConsolidationCreateConsolidatedShipmentMutation } from '../graphql';
import type {
  ConsolidatedShipmentCreateInput,
  ConsolidatedShipmentMutationSuccess,
  ConsolidatedShipmentValues,
  ConsolidationLegValues,
  CreateConsolidatedShipmentInput
} from '../types';

const NewConsolidation: FC = () => {
  const { navigateBack } = useNavigationHistory();
  const { addSuccess, addError } = useNotification();
  const { accountUuid: accountId } = useAccount();
  const [createConsolidatedShipment] = useConsolidationCreateConsolidatedShipmentMutation();
  const defaultBackUrl: string = consolidationRoutes.consolidationIndex.getUrl();
  const { queryParams: consolidationId } = useQueryParams<string>('clone');

  const { data: cloneData, loading } = useGetConsolidatedShipmentLegsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      consolidationId
    },
    skip: isEmpty(consolidationId)
  });

  if (loading) {
    return <Loading />;
  }

  const clonedLegs: Optional<ConsolidationLegValues[]> = cloneData?.consolidations?.nodes?.[0]?.legs;

  const copiedLegs: ConsolidationLegValues[] = clonedLegs
    ? clonedLegs.map((leg: ConsolidationLegValues) => {
        const { networksOrigin, networksDestination } = leg;

        return {
          networksOrigin: getNetworksFieldValueWithName(networksOrigin),
          estimatedDepartureDate: null,
          actualDepartureDate: null,
          networksDestination: getNetworksFieldValueWithName(networksDestination),
          estimatedArrivalDate: null,
          actualArrivalDate: null
        };
      })
    : [getEmptyConsolidationLeg()];

  const clonedConsolidation: ConsolidatedShipmentValues = {
    ...emptyConsolidation,
    legs: copiedLegs
  };

  const setInitialValues: ConsolidatedShipmentValues = consolidationId ? clonedConsolidation : emptyConsolidation;

  const handleSubmit = (values: ConsolidatedShipmentValues): Promise<IOkOrErrorResult> => {
    const consolidatedShipmentCreateInput: ConsolidatedShipmentCreateInput = {
      ...prepareConsolidationInput(values),
      accountUuid: values.customerId ?? accountId
    };

    const input: CreateConsolidatedShipmentInput = {
      consolidatedShipment: consolidatedShipmentCreateInput
    };

    return performFormMutation({
      mutationFn: () =>
        createConsolidatedShipment({
          variables: {
            input
          }
        }),
      onError: addError
    });
  };

  const handleSuccess = (data?: ConsolidatedShipmentMutationSuccess): void => {
    const newConsolidationId: string = data?.consolidatedShipment?.id ?? '';

    addSuccess('Consolidation added');
    navigateBack(consolidationRoutes.consolidationShipments.getUrl(newConsolidationId));
  };

  const handleCancel = (): void => navigateBack(defaultBackUrl);

  return (
    <ConsolidationForm
      consolidation={setInitialValues}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      submitButtonText="Create"
    />
  );
};

export default NewConsolidation;
