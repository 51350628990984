export type PickerPosition = {
  x: 'left' | 'right';
  y: 'above' | 'below';
};

export enum Day {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6
}
