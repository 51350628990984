import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';

import CollapsibleSection from '@zen/Components/CollapsibleSection';
import type { ActionItemType } from '@zen/Dashboard/ActionItems/types';
import { InlineEmptyState } from '@zen/DesignSystem';
import { shipmentDetailsPageCollapsibleItemsKeyName } from '@zen/Shipment/ShipmentDetailsPage/shipmentDetailsPageConfig';
import { useCollapsibleElement } from '@zen/utils/hooks/useCollapsibleElement';

import ActionItem from './ActionItem';

interface Props {
  accountUuid: string;
  actionItems: Pick<ActionItemType, 'id' | 'dueDate' | 'name' | 'title' | 'type'>[];
  onShipmentChange: () => void;
  zencargoReference: string;
}

const ActionItems: FC<Props> = ({ actionItems, zencargoReference, onShipmentChange, accountUuid }) => {
  const [isExpanded, setIsExpanded] = useCollapsibleElement('actionItemsList', false, shipmentDetailsPageCollapsibleItemsKeyName);

  const hasActionItems: boolean = actionItems.length > 0;

  const renderActionItems = (): ReactNode[] => {
    return actionItems.map((actionItem, index) => (
      <ActionItem
        key={index}
        accountUuid={accountUuid}
        actionItem={actionItem}
        onShipmentChange={onShipmentChange}
        zencargoReference={zencargoReference}
      />
    ));
  };

  return (
    <>
      {hasActionItems && (
        <CollapsibleSection
          collapseLabel="Hide items"
          expandLabel={`Show all ${actionItems.length} ${pluralize('item', actionItems.length)}`}
          isExpandedInitially={isExpanded}
          onToggle={() => setIsExpanded(!isExpanded)}
          renderItems={renderActionItems()}
        />
      )}
      {!hasActionItems && (
        <InlineEmptyState title="There are no action items that require your attention.">
          Action items are requests that help ensure a smooth shipment of the goods.
        </InlineEmptyState>
      )}
    </>
  );
};

export default ActionItems;
