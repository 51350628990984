import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useUpdateTerminalMutation } from '../graphql';
import TerminalForm from '../TerminalForm';
import { Terminal, TerminalFormValues, TerminalTypeValue } from '../types';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  terminal: Terminal;
}

const EditTerminal: FC<Props> = ({ terminal, onClose, onSuccess }) => {
  const { addError, addSuccess } = useNotification();

  const initialValues: TerminalFormValues = {
    name: terminal.name || '',
    airportFunction: terminal.terminalTypes?.includes(TerminalTypeValue.AIRPORT),
    seaportFunction: terminal.terminalTypes?.includes(TerminalTypeValue.SEAPORT),
    railTerminalFunction: terminal.terminalTypes?.includes(TerminalTypeValue.RAIL_TERMINAL),
    roadTerminalFunction: terminal.terminalTypes?.includes(TerminalTypeValue.ROAD_TERMINAL)
  };

  const [updateTerminal] = useUpdateTerminalMutation();

  const handleUpdateTerminal = (formData: TerminalFormValues): Promise<IOkOrErrorResult> => {
    const { name, airportFunction, seaportFunction, railTerminalFunction, roadTerminalFunction } = formData;

    return performFormMutation({
      mutationFn: () =>
        updateTerminal({
          variables: {
            input: {
              id: terminal.id as number,
              name,
              airportFunction,
              seaportFunction,
              railTerminalFunction,
              roadTerminalFunction
            }
          }
        }),
      onError: addError
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Terminal updated');
    onSuccess();
    onClose();
  };

  return (
    <TerminalForm
      buttonLabel="Update"
      initialValues={initialValues}
      onCancel={onClose}
      onSubmit={handleUpdateTerminal}
      onSuccess={handleSuccess}
    />
  );
};

export default EditTerminal;
