import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInstance, FormSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { ShipmentStatusUpdateFormValues } from './types';

interface Props {
  initialValues: ShipmentStatusUpdateFormValues;
  onCancel: () => void;
  onShipmentStatusRemoval: () => void;
  onSubmit: (formData: ShipmentStatusUpdateFormValues) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
  shipmentStatusOptions: Option<string>[];
}

const ShipmentStatusUpdateForm: FC<Props> = (props) => {
  const { onCancel, onSubmit, onShipmentStatusRemoval, onSuccess, initialValues, shipmentStatusOptions } = props;

  const renderFormButtons = ({ isSubmitting }: FormInstance<ShipmentStatusUpdateFormValues>): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Update">
        <Button data-testid="remove-button" onClick={onShipmentStatusRemoval} variant="secondary">
          Remove
        </Button>
        <Button onClick={onCancel} variant="tertiary">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      {() => <FormSelect name="shipmentStatus" options={shipmentStatusOptions} placeholder="Select shipment status" />}
    </Form>
  );
};

export default ShipmentStatusUpdateForm;
