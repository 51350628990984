import { get } from 'lodash';
import type { FC, ReactElement } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import FormField, { FormFieldProps } from '@zen/Components/Form/FormField';
import type { Option } from '@zen/DesignSystem';
import { RadioInput, Switch } from '@zen/DesignSystem';
import { ShippingOrderState } from '@zen/Shipment/types';
import useTracking from '@zen/utils/hooks/useTracking';

import type { TrackingFormValues } from '../types';

interface Props extends FormFieldProps {
  canSetNotRequired: boolean;
  onValueChange?: (value: ShippingOrderState) => void;
}

const ShippingOrderStatusInput: FC<Props> = ({ canSetNotRequired, onValueChange, ...rest }) => {
  const { trackEvent } = useTracking();

  const trackShippingOrderChange = (label: string) => {
    trackEvent({
      action: 'SoRelease',
      category: 'SO',
      label
    });
  };

  const options: Option<ShippingOrderState>[] = [
    { value: ShippingOrderState.NOT_CONFIRMED, label: 'Not confirmed' },
    { value: ShippingOrderState.CONFIRMED, label: 'Confirmed' },
    ...(canSetNotRequired ? [{ value: ShippingOrderState.NOT_REQUIRED, label: 'Not required' }] : [])
  ];

  const renderRadioGroup = (field: { name: string; value: string }, form: FormInstance<TrackingFormValues>): ReactElement => (
    <div className="flex fs-so-radio" data-testid="radio-group">
      {options.map(({ label, value }: Option<ShippingOrderState>, i: number) => {
        const handleChange = (changedValue: string) => {
          trackShippingOrderChange(changedValue);
          form.setFieldValue(field.name, changedValue);
          onValueChange?.(value);
        };

        return (
          <div key={i} className="mr-10">
            <RadioInput {...field} checked={value === field.value} onChange={handleChange} value={value}>
              {label}
            </RadioInput>
          </div>
        );
      })}
    </div>
  );

  const renderSwitcher = (field: { name: string }, form: FormInstance<TrackingFormValues>): ReactElement => {
    const { name } = field;
    const isChecked: boolean = get(form.values, name) === ShippingOrderState.CONFIRMED;
    const labelClassName: string = 'text-sm font-bold ml-2';

    const handleChange = (checked: boolean): void => {
      const value: ShippingOrderState = checked ? ShippingOrderState.CONFIRMED : ShippingOrderState.NOT_CONFIRMED;

      trackShippingOrderChange(value);
      form.setFieldValue(name, value);
      onValueChange?.(value);
    };

    return (
      <div className="flex items-center fs-so-switcher">
        <Switch
          {...field}
          className={labelClassName}
          isChecked={isChecked}
          label="Space on vessel is confirmed"
          name={name}
          onChange={handleChange}
        />
      </div>
    );
  };

  return (
    <FormField {...rest} className="col-span-2" hideLabel={!canSetNotRequired} label="Space on vessel is" type="radio">
      {(field: { name: string; value: string }, form: FormInstance<TrackingFormValues>) => (
        <>
          {canSetNotRequired && renderRadioGroup(field, form)}
          {!canSetNotRequired && renderSwitcher(field, form)}
        </>
      )}
    </FormField>
  );
};

export default ShippingOrderStatusInput;
