/* eslint-disable react/jsx-no-useless-fragment */
import type { FC, ReactNode } from 'react';

import type { Optional } from '@zen/utils/typescript';

export interface Props {
  defaultValue?: ReactNode;
  formatValue?: (value: string | number) => string | number;
  value: Optional<string | number>;
}

const ValueWithDefault: FC<Props> = ({ value, defaultValue = '-', formatValue }) => {
  const isEmpty: boolean = value === null || value === undefined || !String(value);

  if (isEmpty) {
    return <>{defaultValue}</>;
  }

  const displayedValue: string | number = formatValue && value ? formatValue(value) : value!;

  return <>{displayedValue}</>;
};

export default ValueWithDefault;
