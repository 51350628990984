import { AsyncSelect, AsyncSelectProps } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import FormField, { FormFieldProps } from '../FormField';
import type { FormFieldInputProps, FormInstance } from '../types';

interface Props<OptionType> extends FormFieldProps, Omit<AsyncSelectProps<OptionType>, 'name'> {
  onSelect?: (name: string, value: Nullable<OptionType>, form: FormInstance<OptionType>) => void;
}

const FormAsyncSelect = <OptionType extends {}>(props: Props<OptionType>) => {
  const { className, ...rest } = props;

  return (
    <FormField {...props}>
      {(field: FormFieldInputProps<OptionType>, form: FormInstance<OptionType>) => (
        <AsyncSelect<OptionType>
          {...field}
          {...rest}
          hasError={field.error}
          onChange={(value: Nullable<OptionType>) => {
            form.setFieldValue(field.name, value);

            props.onSelect?.(field.name, value, form);
          }}
        />
      )}
    </FormField>
  );
};

export default FormAsyncSelect;
