import { get } from 'lodash';
import type { FC } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { Switch } from '@zen/DesignSystem';

import FormField, { FormFieldProps } from '../FormField';

interface Props extends FormFieldProps {
  hideLabel?: boolean;
  isDisabled?: boolean;
}

const FormSwitch: FC<Props> = (props) => {
  const { hideLabel, label, isDisabled } = props;

  return (
    <FormField {...props} hideLabel={true}>
      {(field: { id: string; name: string }, form: FormInstance<unknown>) => {
        const { name } = field;
        const isChecked: boolean = !!get(form.values, name);
        const handleChange = (): void => form.setFieldValue(name, !isChecked);

        return (
          <div className="flex items-center">
            <Switch
              {...field}
              isChecked={isChecked}
              isDisabled={isDisabled}
              label={hideLabel ? null : label}
              name={name}
              onChange={handleChange}
            />
          </div>
        );
      }}
    </FormField>
  );
};

export default FormSwitch;
