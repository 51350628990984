import type { FC, ReactNode } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import { Form, FormButtons, FormInstance, FormSelect } from '@zen/Components/Form';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import type { Option } from '@zen/DesignSystem';
import { Button, Modal } from '@zen/DesignSystem';
import { bookingRequestRoutes } from '@zen/Routes';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { useBookingRequestRejectMutation } from '../graphql';
import { RejectedReasonEnum, RejectInput } from '../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  zencargoReference: string;
}

interface FormInitialValues {
  rejectedAdditionalInfo: string;
  rejectedReason: Nullable<RejectedReasonEnum>;
}

interface FormValues {
  rejectedAdditionalInfo: string;
  rejectedReason: RejectedReasonEnum;
}

const validationSchema = Yup.object().shape({
  rejectedReason: Yup.string().nullable().required('Rejected reason is required.')
});

const BookingRequestRejectModal: FC<Props> = (props) => {
  const { onClose, isOpen, zencargoReference } = props;
  const { addError } = useNotification();
  const history = useHistory();

  const [rejectBookingRequest] = useBookingRequestRejectMutation();

  const handleReject = async (values: FormValues): Promise<IOkOrErrorResult> => {
    const input: RejectInput = {
      zencargoReference,
      ...values
    };

    return performMutation({
      mutationFn: () => rejectBookingRequest({ variables: { input } }),
      onError: () => addError(),
      onSuccess: () => {
        addError('Booking request rejected');
      }
    });
  };

  const options: Option<RejectedReasonEnum>[] = Object.values(RejectedReasonEnum)
    .filter((value: RejectedReasonEnum) => value !== RejectedReasonEnum.OTHER)
    .map((value: RejectedReasonEnum): Option<RejectedReasonEnum> => {
      let label: string = removeSpecialCharactersAndUpperFirst(value);

      if (value === RejectedReasonEnum.POS_ATTACHED_OR_ITEMS) {
        label = label.replace('Pos', 'POS');
      }

      return {
        value,
        label
      };
    });

  options.push({
    value: RejectedReasonEnum.OTHER,
    label: removeSpecialCharactersAndUpperFirst(RejectedReasonEnum.OTHER)
  });

  const handleSuccess = (): void => {
    history.push(bookingRequestRoutes.rejected.getUrl());
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<FormValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Reject booking">
      <Button onClick={onClose} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  const initialValues: FormInitialValues = {
    rejectedReason: null,
    rejectedAdditionalInfo: ''
  };

  return (
    <Modal isOpen={isOpen} modalOverflowY="visible" onClose={onClose} title="Reject booking request">
      <Form
        formButtons={renderFormButtons}
        formName="BookingRequestRejectForm"
        initialValues={initialValues}
        onSubmit={handleReject}
        onSuccess={handleSuccess}
        validationSchema={validationSchema}
      >
        {(): ReactNode => (
          <>
            <FormSelect label="Select a reason for rejection" name="rejectedReason" options={options} />
            <FormTextarea label="Add additional information" name="rejectedAdditionalInfo" />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default BookingRequestRejectModal;
