import type { FC, ReactNode } from 'react';

import { Button, ButtonVariant } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

import Modal from '../Modal';

export interface Props {
  buttonVariant?: ButtonVariant;
  cancelLabel?: string;
  closeOnClickAway?: boolean;
  confirmIcon?: IconName;
  confirmLabel?: string;
  disabled?: boolean;
  hasCancelButton?: boolean;
  header?: ReactNode;
  isLoading?: boolean;
  isOpen: boolean;
  message?: ReactNode;
  onClose: () => void;
  onCloseOnClickAway?: () => void;
  onConfirm: () => void;
}

const Dialog: FC<Props> = (props) => {
  const {
    cancelLabel = 'Cancel',
    closeOnClickAway = false,
    buttonVariant,
    confirmIcon,
    confirmLabel = 'Confirm',
    disabled = false,
    hasCancelButton = true,
    header = 'Are you sure?',
    isOpen,
    isLoading = false,
    message,
    onClose,
    onCloseOnClickAway,
    onConfirm
  } = props;

  return (
    <Modal
      className="mb-16 items-center"
      closeOnClickAway={closeOnClickAway}
      data-component="confirmation-modal"
      isOpen={isOpen}
      onClose={onClose}
      onCloseOnClickAway={onCloseOnClickAway}
      showCloseButton={false}
    >
      <div className="px-16 max-w-lg text-center py-12">
        <div className="font-bold text-lg text-grey-dark mb-4 leading-6" data-testid="modal-headline">
          {header}
        </div>
        {message && (
          <div className="text-grey-dark leading-normal text-sm whitespace-pre-line" data-testid="modal-message">
            {message}
          </div>
        )}
        <div className="mt-8 flex justify-center">
          {hasCancelButton && (
            <Button className="mr-4" data-testid="modal-cancel-button" onClick={onClose} variant="tertiary">
              {cancelLabel}
            </Button>
          )}
          <Button
            data-testid="modal-confirm-button"
            disabled={disabled}
            iconLeft={confirmIcon}
            isLoading={isLoading}
            onClick={onConfirm}
            variant={buttonVariant}
          >
            {confirmLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Dialog;
