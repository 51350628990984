import type { FC } from 'react';

import { getColor } from '@zen/Components/DataQualityIndicator/helper';
import { DonutChart } from '@zen/DesignSystem';
import { formatNumber } from '@zen/utils/formatting';

interface Props {
  averageFulfilmentRate: number;
  inFullPercentage: number;
}

const InFullChart: FC<Props> = ({ averageFulfilmentRate, inFullPercentage }) => (
  <>
    <div className="flex justify-center">
      <DonutChart color={getColor(inFullPercentage)} value={Math.round(inFullPercentage)} />
    </div>
    <div className="text-navy-base font-bold text-4xl text-center mt-4 mb-5">{formatNumber(averageFulfilmentRate)} %</div>
    <div className="text-grey-base text-3xl text-center">avg fulfilment</div>
  </>
);

export default InFullChart;
