import { FC, ReactNode, useState } from 'react';

import Map from '@zen/Components/Map';
import type { MarkerType } from '@zen/Components/Map/Marker';
import { Button, Container } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks';
import type { LocationDescriptorObject } from '@zen/types';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { Optional } from '@zen/utils/typescript';

import LocationListItem from './LocationListItem';
import type { LocationListLocation } from './types';

interface Props {
  isMyOrganisationPage?: boolean;
  locations: LocationListLocation[];
  organisationId: string;
}

const LocationList: FC<Props> = ({ locations, organisationId, isMyOrganisationPage = false }) => {
  const { routes } = useNetworksContext();
  const { navigate } = useNavigationHistory();
  const [hoveredLocationId, setHoveredLocationId] = useState<Optional<string>>(null);

  const newLocationUrl: LocationDescriptorObject = {
    pathname: routes.location.new.getUrl(organisationId),
    state: { fromMyOrganisationPage: isMyOrganisationPage }
  };

  const handleNewLocationButtonClick = () => {
    navigate(newLocationUrl);
  };

  const getMarkers = (): MarkerType[] =>
    locations.reduce((result: MarkerType[], location: LocationListLocation) => {
      const { geolocation } = location;

      if (geolocation) {
        result.push({
          lat: geolocation.latitude,
          lng: geolocation.longitude,
          isHighlighted: location.id === hoveredLocationId
        });
      }

      return result;
    }, []);

  const renderMap = (): ReactNode => (
    <div className="my-6" style={{ height: '26rem' }}>
      <Map markers={getMarkers()} />
    </div>
  );

  const actions: ReactNode = (
    <Button iconLeft="zicon-add" onClick={handleNewLocationButtonClick} variant="secondary">
      Add location
    </Button>
  );

  return (
    <Container actions={actions} title="Locations">
      {renderMap()}
      <div className="grid grid-cols-3 gap-4" data-testid="location-list">
        {locations.map((location: LocationListLocation) => (
          <div
            key={location.id}
            onMouseEnter={() => setHoveredLocationId(location?.id)}
            onMouseLeave={() => setHoveredLocationId(null)}
          >
            <LocationListItem isMyOrganisationPage={isMyOrganisationPage} location={location} organisationId={organisationId} />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default LocationList;
