import type { ReactNode } from 'react';
import type * as Yup from 'yup';

import { Form, FormButtons, FormInstance, FormMultiSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';
import { Button } from '@zen/DesignSystem';
import type { BookingManager, OperationsFilters } from '@zen/OperationsDashboard/types';
import type { Account } from '@zen/utils/hooks/useAccounts';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

interface Props<T> {
  children?: ((form: FormInstance<T>) => ReactNode) | ReactNode;
  coordinators: BookingManager[];
  customers: Account[];
  initialFilters: T;
  onClear: () => void;
  onSubmit: (values: T) => Promise<IOkOrErrorResult>;
  validation?: Yup.ObjectSchema<{}>;
}

const sortCoordinators = (coordinators: BookingManager[]) => {
  return [...coordinators].sort((left, right) => {
    const leftName = left?.firstName?.toLowerCase() || 0;
    const rightName = right?.firstName?.toLowerCase() || 0;

    return leftName < rightName ? -1 : 1;
  });
};

const FiltersForm = <T extends OperationsFilters = OperationsFilters>(props: Props<T>) => {
  const { customers, onSubmit, coordinators, initialFilters, onClear, children, validation } = props;
  const customersOptions: Option<string>[] = customers.map((customer: Account) => ({
    value: customer.uuid,
    label: customer.registeredCompanyName
  }));

  const coordinatorOptions: Option<string>[] = sortCoordinators(coordinators).map((coordinator: BookingManager) => {
    const value = coordinator?.id || '';
    const label = coordinator?.firstName && coordinator?.lastName ? `${coordinator?.firstName} ${coordinator?.lastName}` : '';

    return { value, label };
  });

  const renderButtons = ({ isSubmitting }: FormInstance<T>) => (
    <FormButtons isSubmitting={isSubmitting}>
      <Button onClick={onClear} variant="tertiary">
        Clear filters
      </Button>
    </FormButtons>
  );

  return (
    <Form
      className="min-w-lg"
      enableReinitialize={true}
      formButtons={renderButtons}
      formName="OperationsDashboardFiltersForm"
      initialValues={initialFilters}
      onSubmit={onSubmit}
      validationSchema={validation}
    >
      {(form: FormInstance<T>) => (
        <>
          <FormMultiSelect label="Coordinator" name="bookingCoordinatorIds" options={coordinatorOptions} />
          <FormMultiSelect label="Customers" name="accountIds" options={customersOptions} />
          {typeof children === 'function' ? children(form) : children}
        </>
      )}
    </Form>
  );
};

export default FiltersForm;
