import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetShipmentDetailsPageDataQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetShipmentDetailsPageDataQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<
            GraphQLTypes.Booking,
            'accountUuid' | 'pinned' | 'quoteRequired' | 'modeOfTransport' | 'zencargoReference' | 'clientReference'
          > & {
              archivedAt?: GraphQLTypes.Maybe<{ __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>>;
              bookingRequest?: GraphQLTypes.Maybe<
                { __typename?: 'BookingRequest' } & Pick<
                  GraphQLTypes.BookingRequest,
                  'customerApproved' | 'forwarderApproved' | 'forwarderApprovalRequired' | 'id' | 'rejected'
                > & {
                    canReject: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                    canUpdateCustomerApproved: { __typename?: 'AuthorizationResult' } & Pick<
                      GraphQLTypes.AuthorizationResult,
                      'value'
                    >;
                    canUpdateForwarderApproved: { __typename?: 'AuthorizationResult' } & Pick<
                      GraphQLTypes.AuthorizationResult,
                      'value'
                    >;
                    canRevertRejection: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                    canRevertRejectionAsManufacturer: { __typename?: 'AuthorizationResult' } & Pick<
                      GraphQLTypes.AuthorizationResult,
                      'value'
                    >;
                  }
              >;
              canArchiveShipment: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canClone: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canManageCargo: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canManageAssignedLots: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canManageCollectionAndDeliveryScheduling: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canPin: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewActivity: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewNotes: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewActionItems: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewRoadTracking: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewTradeParties: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canOpenIssue: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canModifyIssueVisibility: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              consolidatedShipment?: GraphQLTypes.Maybe<
                { __typename?: 'ConsolidatedShipment' } & Pick<GraphQLTypes.ConsolidatedShipment, 'id' | 'reference'>
              >;
              stage?: GraphQLTypes.Maybe<{ __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'value'>>;
            }
        >
      >
    >;
  };
};

export const GetShipmentDetailsPageDataDocument = /* #__PURE__ */ gql`
  query getShipmentDetailsPageData($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        accountUuid
        archivedAt {
          date
        }
        bookingRequest {
          canReject {
            value
          }
          canUpdateCustomerApproved {
            value
          }
          canUpdateForwarderApproved {
            value
          }
          canRevertRejection {
            value
          }
          canRevertRejectionAsManufacturer {
            value
          }
          customerApproved
          forwarderApproved
          forwarderApprovalRequired
          id
          rejected
        }
        canArchiveShipment {
          value
        }
        canClone {
          value
        }
        canManageCargo {
          value
        }
        canManageAssignedLots {
          value
        }
        canManageCollectionAndDeliveryScheduling {
          value
        }
        canPin {
          value
        }
        canViewActivity {
          value
        }
        canViewNotes {
          value
        }
        canViewActionItems {
          value
        }
        canViewRoadTracking {
          value
        }
        canViewTradeParties {
          value
        }
        canOpenIssue {
          value
        }
        canModifyIssueVisibility {
          value
        }
        consolidatedShipment {
          id
          reference
        }
        pinned
        stage {
          value
        }
        quoteRequired
        modeOfTransport
        zencargoReference
        clientReference
      }
    }
  }
`;

/**
 * __useGetShipmentDetailsPageDataQuery__
 *
 * To run a query within a React component, call `useGetShipmentDetailsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentDetailsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentDetailsPageDataQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetShipmentDetailsPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetShipmentDetailsPageDataQueryResult, GetShipmentDetailsPageDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetShipmentDetailsPageDataQueryResult, GetShipmentDetailsPageDataQueryVariables>(
    GetShipmentDetailsPageDataDocument,
    options
  );
}
export function useGetShipmentDetailsPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentDetailsPageDataQueryResult, GetShipmentDetailsPageDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetShipmentDetailsPageDataQueryResult, GetShipmentDetailsPageDataQueryVariables>(
    GetShipmentDetailsPageDataDocument,
    options
  );
}
export type GetShipmentDetailsPageDataQueryHookResult = ReturnType<typeof useGetShipmentDetailsPageDataQuery>;
export type GetShipmentDetailsPageDataLazyQueryHookResult = ReturnType<typeof useGetShipmentDetailsPageDataLazyQuery>;
