import type { FC } from 'react';
import { Link } from 'react-router-dom';

import { checkPermission } from '@zen/Auth/authHelper';
import CloseIcon from '@zen/Components/Icons/CloseIcon';
import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import { Icon, Tooltip } from '@zen/DesignSystem';
import { getCloseText } from '@zen/Orders/OrderList/OrdersContextMenu/OrdersContextMenu';
import { orderRoutes } from '@zen/Routes';

import type { OrderDetailsType } from '../../types';

type PickedOrder = Pick<OrderDetailsType, 'id' | 'canClose' | 'canRemove' | 'canUpdate' | 'orderReferenceNumber'>;

interface Props {
  onClose: () => void;
  onCloseOrder: (orderId: string) => void;
  onDeleteOrder: (orderId: string) => void;
  order: PickedOrder;
}

const OrderHeaderActions: FC<Props> = ({ order, onClose, onCloseOrder, onDeleteOrder }) => {
  const canRemoveOrder: boolean = checkPermission<PickedOrder>(order, 'canRemove');
  const canUpdateOrder: boolean = checkPermission<PickedOrder>(order, 'canUpdate');
  const canCloseOrder: boolean = checkPermission<PickedOrder>(order, 'canClose');

  const handleCloseOrder = (): void => {
    onCloseOrder(order.id);
  };

  const handleDeleteOrder = (): void => {
    onDeleteOrder(order.id);
  };

  return (
    <div className="absolute top-0 pl-3 right-4 flex items-center" data-testid="order-header-actions">
      {canUpdateOrder && (
        <Tooltip placement="bottom" tooltipContent="Edit">
          <Link to={orderRoutes.orderEdit.getUrl(order.id)}>
            <Icon className="text-grey-base text-base" icon="zicon-edit" interactive={true} />
          </Link>
        </Tooltip>
      )}
      {canCloseOrder && (
        <WithConfirmationModal
          header="Close a purchase order"
          message={getCloseText(order?.orderReferenceNumber || '')}
          onConfirm={handleCloseOrder}
        >
          <Tooltip placement="bottom" tooltipContent="Close PO">
            <Icon className="text-grey-base text-base" icon="zicon-archive" interactive={true} />
          </Tooltip>
        </WithConfirmationModal>
      )}
      {canRemoveOrder && (
        <WithConfirmationModal
          message="Do you really want to delete this PO? This process cannot be undone."
          onConfirm={handleDeleteOrder}
        >
          <Tooltip placement="bottom" tooltipContent="Delete">
            <Icon className="text-grey-base text-base" icon="zicon-trash" interactive={true} />
          </Tooltip>
        </WithConfirmationModal>
      )}
      <Icon className="text-grey-lighter text-base" icon="zicon-line" />
      <CloseIcon onClick={onClose} />
    </div>
  );
};

export default OrderHeaderActions;
