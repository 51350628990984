import type { DocumentNode } from 'graphql';
import { get } from 'lodash';

import { apolloClient } from '@zen/graphql/GraphQLProvider';
import type { Optional } from '@zen/utils/typescript';

import { AssignableQueryDocument } from '../graphql';
import type {
  AssignmentTargetTypeEnum,
  AssignmentTypeValue,
  BookingOrgLocTerminal,
  NetworksOrgLocOption,
  NetworksTerminal,
  OrgLoc
} from '../types';

export type IHandleChangeCallback = (query: string) => Promise<NetworksOrgLocOption[]>;
export type NetworksOrgLocLabel = Pick<OrgLoc | NetworksTerminal | BookingOrgLocTerminal, 'label'>;

const getOrgLocLabel = (orgLoc: Optional<NetworksOrgLocLabel>): string => {
  return orgLoc?.label?.long || '';
};

interface Props {
  accountUuid: string;
  assignmentType?: AssignmentTypeValue;
  domainName?: AssignmentTargetTypeEnum;
  queryDocument?: DocumentNode; // AssignableQueryDocument;
  resultsPath?: string; // = 'network.locations.nodes'
}
const handleChangeCallback =
  ({
    accountUuid,
    domainName,
    assignmentType,
    queryDocument = AssignableQueryDocument,
    resultsPath = 'network.locations.nodes'
  }: Props): IHandleChangeCallback =>
  async (query: string): Promise<NetworksOrgLocOption[]> => {
    const QUERY = {
      query: queryDocument,
      variables: {
        networkId: accountUuid,
        searchContains: {
          search: query,
          ...(domainName && { targetType: domainName }),
          ...(assignmentType && { assignmentName: assignmentType })
        }
      }
    };
    const { data } = await apolloClient.query(QUERY);

    return get(data, resultsPath, []);
  };

export { getOrgLocLabel, handleChangeCallback };
