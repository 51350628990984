import { ReactNode, useState } from 'react';
import type * as Yup from 'yup';

import type { FormInstance } from '@zen/Components/Form';
import { Loading } from '@zen/DesignSystem';
import Filters from '@zen/OperationsDashboard/Filters';
import { useBookingCoordinatorsQuery } from '@zen/OperationsDashboard/graphql';
import type { OperationsFilters } from '@zen/OperationsDashboard/types';
import useAccount from '@zen/utils/hooks/useAccount';
import useAccounts from '@zen/utils/hooks/useAccounts';

import OperationsContext from '../OperationsContext';

interface Props<T> {
  children: ReactNode;
  emptyValues?: T;
  filtersFields?: ((form: FormInstance<T>) => ReactNode) | ReactNode;
  filtersTitle: string;
  filtersValidation?: Yup.ObjectSchema<{}>;
  initialValues?: Partial<T>;
}

export type FiltersType<T> = T & OperationsFilters;

const OperationsContextProvider = <T extends {}>(props: Props<T>) => {
  const { children, filtersTitle, filtersFields, filtersValidation, initialValues = {}, emptyValues = {} } = props;
  const {
    userProfile: { uuid }
  } = useAccount();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const { data: accounts = [], isLoading: accountIsLoading } = useAccounts();
  const { data: coordinators, loading: isCoordinatorsQueryLoading } = useBookingCoordinatorsQuery();
  const bookingCoordinators = coordinators?.bookingManagers;

  if ((accountIsLoading && isCoordinatorsQueryLoading) || !bookingCoordinators) {
    return <Loading />;
  }

  const bookingCoordinatorIds: string[] = bookingCoordinators.filter(({ id }) => id === uuid).map(({ id }) => id);

  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ bookingManagerIds: string[]; accountIds: n... Remove this comment to see the full error message
  const operationsInitialFilters: FiltersType<T> = {
    bookingCoordinatorIds,
    accountIds: [],
    ...initialValues
  };

  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ status: null; bookingManagerIds: never[]; ... Remove this comment to see the full error message
  const emptyFilters: FiltersType<T> = {
    bookingCoordinatorIds: [],
    accountIds: [],
    ...emptyValues
  };

  const openFilters = () => setShowFilters(true);
  const closeFilters = () => setShowFilters(false);

  return (
    <OperationsContext.Provider value={{ accounts, bookingCoordinators, openFilters, closeFilters }}>
      {children}
      <Filters
        emptyFilters={emptyFilters}
        initialFiltersValue={operationsInitialFilters}
        onClose={closeFilters}
        showFilters={showFilters}
        title={filtersTitle}
        validation={filtersValidation}
      >
        {filtersFields}
      </Filters>
    </OperationsContext.Provider>
  );
};

export default OperationsContextProvider;
