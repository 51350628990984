import { parseDate } from '../jodaConnector';
import type { ComparableDate } from '../types';

export const compareDate = (date: string): ComparableDate => {
  const parsedDate = parseDate(date);

  const isAfter = (comparator: string): boolean => {
    const comparedDate = parseDate(comparator);

    return parsedDate.isAfter(comparedDate);
  };

  const isBefore = (comparator: string): boolean => {
    const comparedDate = parseDate(comparator);

    return parsedDate.isBefore(comparedDate);
  };

  const isEqual = (comparator: string): boolean => {
    const comparedDate = parseDate(comparator);

    return parsedDate.isEqual(comparedDate);
  };

  return {
    isAfter,
    isBefore,
    isEqual
  };
};
