import { FC, useEffect, useState } from 'react';

import { useCreateNewMessageMutation } from '@zen/ActivityFeed/graphql';
import type { ActivityFeedOriginViewType, ActivityFeedTargetTypeEnum, PermittedParties } from '@zen/ActivityFeed/types';
import useRole from '@zen/Auth/hooks/useRole';
import SubmittableInput from '@zen/Components/Form/SubmittableInput/SubmittableInput';
import type { PermissionsInput } from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@zen... Remove this comment to see the full error message
import { getItem, removeItem, saveItem } from '@zen/utils/localStorage';

import { getStoragePrefix } from '../../helpers/activityFeedHelper';
import { createPermissions } from '../../helpers/createPermissions';
import ActivityFeedInputPermissions from './ActivityFeedInputPermissions';

interface Props {
  permittedParties: PermittedParties;
  targetId: string;
  targetType: ActivityFeedTargetTypeEnum;
  viewType?: ActivityFeedOriginViewType;
}

const ActivityFeedInput: FC<Props> = ({ viewType, targetType, targetId, permittedParties }) => {
  const role = useRole();
  const [value, setValue] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [permissions, setPermissions] = useState<PermissionsInput>(createPermissions(role, viewType));

  const handlePermissionChange = (key: keyof PermissionsInput, permissionValue: boolean) => {
    setPermissions((currentPermissions: PermissionsInput) => ({
      ...currentPermissions,
      [key]: permissionValue
    }));
  };

  const { addError } = useNotification();
  const [createNewTextMessage] = useCreateNewMessageMutation();

  const storagePrefix = getStoragePrefix(targetId);

  useEffect(() => {
    const lastUnsavedMessage = getItem(storagePrefix);

    setValue(lastUnsavedMessage || '');
  }, [storagePrefix]);

  const handleChange = (inputValue: string): void => {
    saveItem(storagePrefix, inputValue);
    setValue(inputValue);
    setDisabled(!inputValue);
  };

  const handleSubmit = async (inputValue: string): Promise<void> => {
    const input = {
      content: inputValue,
      targetType,
      targetId,
      permissions
    };

    setDisabled(true);

    const mutationPayload = {
      variables: {
        input
      }
    };

    try {
      await createNewTextMessage(mutationPayload);
    } catch (err) {
      addError('Something went wrong. Please try sending message again');
    }

    removeItem(storagePrefix);
    setValue('');
    setDisabled(false);
  };

  const wrapperClassNames: string = 'border border-solid border-grey-lighter rounded';
  const permissionsStylesProps: Record<string, string> = {
    customBorder: 'border-b border-solid border-grey-lighter',
    customButtonClasses: 'text-lg w-12'
  };

  return (
    <div className={wrapperClassNames}>
      <SubmittableInput
        isDisabled={disabled}
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder="Type your message here..."
        value={value}
        {...permissionsStylesProps}
      />
      <ActivityFeedInputPermissions
        onPermissionChange={handlePermissionChange}
        permissions={permissions}
        permittedParties={permittedParties}
        userRole={role}
      />
    </div>
  );
};

export type { Props as ActivityFeedInputProps };
export default ActivityFeedInput;
