import cx from 'classnames';
import { useState } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import { IntakeSkuOverviewType } from '../types';

interface Props {
  count: Optional<number>;
  onClick: (type: IntakeSkuOverviewType) => void;
  type: IntakeSkuOverviewType;
}

const tileConfig = {
  [IntakeSkuOverviewType.ZERO_STOCK]: {
    color: 'red-base',
    label: {
      firstLine: 'SKUs with',
      secondLine: 'no intake'
    }
  },
  [IntakeSkuOverviewType.PARTIAL_FULFILMENT]: {
    color: 'orange-base',
    label: {
      firstLine: 'SKUs with',
      secondLine: 'intake shortfall'
    }
  },
  [IntakeSkuOverviewType.ON_TIME_IN_FULL]: {
    color: 'green-base',
    label: {
      firstLine: 'on time,',
      secondLine: 'in full SKUs'
    }
  },
  [IntakeSkuOverviewType.OVER_FULFILLED]: {
    color: 'azure-base',
    label: {
      firstLine: 'SKUs with',
      secondLine: 'intake surplus'
    }
  }
};

const SkuOverviewTile = ({ count, onClick, type }: Props) => {
  const [hover, setHover] = useState<boolean>(false);
  const { color, label } = tileConfig[type];
  const classNames = cx(
    { 'hover:shadow-navy-blue cursor-pointer': count ? count > 0 : false },
    'py-4 flex flex-1 border-l-8 border-solid items-center shadow',
    'font-bold text-navy-base rounded-r-lg ml-4 first:ml-0',
    `border-${color}`
  );

  const handleClick = () => count && onClick(type);

  const renderContent = () => {
    if (hover && count) {
      return (
        <div className="flex justify-center items-center w-full h-full">
          <Icon className="text-xl mr-2" icon="zicon-filter" />
          Filter on these SKUs
        </div>
      );
    }

    return (
      <>
        <div className="text-5xl ml-2p mr-4">{count}</div>
        <div className="text-sm leading-snug">
          <div>{label.firstLine}</div>
          <div>{label.secondLine}</div>
        </div>
      </>
    );
  };

  return (
    <div className={classNames} onClick={handleClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {renderContent()}
    </div>
  );
};

export default SkuOverviewTile;
