import type { Option } from '@zen/DesignSystem';
import { IncotermsValue, ModeOfTransport, Shipment, TradeRole } from '@zen/graphql/types.generated';

export const incotermsLabelMapping: Record<IncotermsValue, string> = {
  [IncotermsValue.CFR]: 'CFR',
  [IncotermsValue.CIF]: 'CIF',
  [IncotermsValue.CIP]: 'CIP',
  [IncotermsValue.CPT]: 'CPT',
  [IncotermsValue.DAP]: 'DAP',
  [IncotermsValue.DAT]: 'DAT',
  [IncotermsValue.DDP]: 'DDP',
  [IncotermsValue.EXWORKS]: 'EXW',
  [IncotermsValue.FAS]: 'FAS',
  [IncotermsValue.FCA]: 'FCA',
  [IncotermsValue.FOB]: 'FOB'
};

export const modeOfTransportLabelMapping: Record<ModeOfTransport, string> = {
  [ModeOfTransport.AIR]: 'Air',
  [ModeOfTransport.OCEAN]: 'Ocean',
  [ModeOfTransport.RAIL]: 'Rail',
  [ModeOfTransport.TRUCK]: 'Truck'
};

export const shipmentTypeLabelMapping: Record<Shipment, string> = {
  [Shipment.CROSS_TRADE]: 'Cross-trade',
  [Shipment.DOMESTIC]: 'Domestic',
  [Shipment.EXPORT]: 'Export',
  [Shipment.IMPORT]: 'Import'
};

export const tradeRoleLabelMapping: Record<TradeRole, string> = {
  [TradeRole.BUYER]: 'Buyer',
  [TradeRole.SELLER]: 'Seller',
  [TradeRole.TRANSFER]: 'Transfer'
};

export const incotermsOptions: Option<IncotermsValue>[] = [
  { label: incotermsLabelMapping[IncotermsValue.CFR], value: IncotermsValue.CFR },
  { label: incotermsLabelMapping[IncotermsValue.CIF], value: IncotermsValue.CIF },
  { label: incotermsLabelMapping[IncotermsValue.CIP], value: IncotermsValue.CIP },
  { label: incotermsLabelMapping[IncotermsValue.CPT], value: IncotermsValue.CPT },
  { label: incotermsLabelMapping[IncotermsValue.DAP], value: IncotermsValue.DAP },
  { label: incotermsLabelMapping[IncotermsValue.DAT], value: IncotermsValue.DAT },
  { label: incotermsLabelMapping[IncotermsValue.DDP], value: IncotermsValue.DDP },
  { label: incotermsLabelMapping[IncotermsValue.EXWORKS], value: IncotermsValue.EXWORKS },
  { label: incotermsLabelMapping[IncotermsValue.FAS], value: IncotermsValue.FAS },
  { label: incotermsLabelMapping[IncotermsValue.FCA], value: IncotermsValue.FCA },
  { label: incotermsLabelMapping[IncotermsValue.FOB], value: IncotermsValue.FOB }
];

export const modeOfTransportOptions: Option<ModeOfTransport>[] = [
  { label: modeOfTransportLabelMapping[ModeOfTransport.AIR], value: ModeOfTransport.AIR },
  { label: modeOfTransportLabelMapping[ModeOfTransport.OCEAN], value: ModeOfTransport.OCEAN },
  { label: modeOfTransportLabelMapping[ModeOfTransport.RAIL], value: ModeOfTransport.RAIL },
  { label: modeOfTransportLabelMapping[ModeOfTransport.TRUCK], value: ModeOfTransport.TRUCK }
];

export const shipmentTypeOptions: Option<Shipment>[] = [
  { label: shipmentTypeLabelMapping[Shipment.EXPORT], value: Shipment.EXPORT },
  { label: shipmentTypeLabelMapping[Shipment.IMPORT], value: Shipment.IMPORT },
  { label: shipmentTypeLabelMapping[Shipment.DOMESTIC], value: Shipment.DOMESTIC },
  { label: shipmentTypeLabelMapping[Shipment.CROSS_TRADE], value: Shipment.CROSS_TRADE }
];

export const tradeRoleOptions: Option<TradeRole>[] = [
  { label: tradeRoleLabelMapping[TradeRole.BUYER], value: TradeRole.BUYER },
  { label: tradeRoleLabelMapping[TradeRole.SELLER], value: TradeRole.SELLER },
  { label: tradeRoleLabelMapping[TradeRole.TRANSFER], value: TradeRole.TRANSFER }
];
