import type { FC } from 'react';

import { FormButtons } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';

interface Props {
  isSubmitting: boolean;
  onCancel: () => void;
}

const ScheduleActionFormButtons: FC<Props> = ({ isSubmitting, onCancel }) => (
  <FormButtons isSubmitting={isSubmitting} text="Submit">
    <Button data-testid="cancel-button" onClick={onCancel} variant="tertiary">
      Cancel
    </Button>
  </FormButtons>
);

export default ScheduleActionFormButtons;
