import type { Option } from '@zen/DesignSystem';
import { getNetworksFilterOptionArray } from '@zen/OperationsShipments/components/FilterFields/helpers';
import { getFilterOptionArray, getShipmentStatus, getWithinTimeRange } from '@zen/Shipments/Filters/helpers';
import type { Account } from '@zen/utils/hooks/useAccounts';

import type { CustomerAdminShipmentFilters, CustomerAdminShipmentFilterVariables } from './types';

export const getDivisionOptions = (accounts: Account[]): Option<string>[] =>
  accounts?.map((account: Account) => ({
    value: account.uuid,
    label: account.registeredCompanyName
  }));

export const prepareFilterVariables = (filters: CustomerAdminShipmentFilters): CustomerAdminShipmentFilterVariables => {
  return {
    accountIds: getFilterOptionArray(filters.accountIds),
    transportModes: getFilterOptionArray(filters.transportModes),
    ...getShipmentStatus(filters.status),
    origins: getNetworksFilterOptionArray(filters.origins),
    destinations: getNetworksFilterOptionArray(filters.destinations),
    withinTimeRange: getWithinTimeRange({
      withinTimeDays: filters.withinTimeDays,
      withinTimeStage: filters.withinTimeStage,
      withinTimeRange: filters.withinTimeRange,
      withinTimeDate: filters.withinTimeDate
    })
  };
};
