import cx from 'classnames';
import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import RolePermissionList from '@zen/Components/RolePermissions/RolePermissionList';
import { Headline } from '@zen/DesignSystem';
import { Issue, IssueState } from '@zen/Issues/types';

interface Props {
  className?: string;
  issue: Pick<Issue, 'canUpdate' | 'problemDescription' | 'state' | 'solutionDescription' | 'visibility'>;
}

const IssueDetails: FC<Props> = ({ issue, className }) => {
  const showIssueVisibility: boolean = checkPermission(issue, 'canUpdate'); // no BE permission for canView, basing on edit rights instead
  const { visibility, solutionDescription, problemDescription, state } = issue;
  const labelClassName: string = 'text-grey-dark';
  const problemLabel: string = state === IssueState.RESOLVED ? 'What was the issue?' : 'What is the issue?';
  const solutionLabel: string = state === IssueState.RESOLVED ? 'What action was required?' : 'What action is required?';

  return (
    <div className={cx('text-grey-dark leading-normal', className)}>
      <Headline className={labelClassName} level={4}>
        {problemLabel}
      </Headline>
      <p className="text-sm mb-6 break-words">{problemDescription}</p>

      {solutionDescription && (
        <>
          <Headline className={labelClassName} level={4}>
            {solutionLabel}
          </Headline>
          <p className="text-sm mb-6 break-words">{solutionDescription}</p>
        </>
      )}

      {showIssueVisibility && (
        <>
          <Headline className={cx(labelClassName, 'mb-2')} level={4}>
            Issues visible to...
          </Headline>
          <RolePermissionList className="mb-6" rolePermissions={visibility} />
        </>
      )}
    </div>
  );
};

export default IssueDetails;
