import { Icon } from '@zen/DesignSystem';

interface Props {
  closeToast: () => void;
}

const CloseButton = ({ closeToast }: Props) => {
  return <Icon className="text-xs text-white ml-6 mt-1 cursor-pointer" icon="zicon-close" onClick={closeToast} />;
};

export default CloseButton;
