import type { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { consolidationRoutes } from '@zen/Routes';

import ConsolidationAddShipments from './ConsolidationAddShipments';
import ConsolidationList from './ConsolidationList';
import ConsolidationPage from './ConsolidationPage';

const Consolidation: FC = () => {
  return (
    <Switch>
      <Route exact={true} path={consolidationRoutes.consolidationIndex.path}>
        <ConsolidationList />
      </Route>
      <Route path={consolidationRoutes.consolidationAddShipments.path}>
        <ConsolidationAddShipments />
      </Route>
      <Route path={consolidationRoutes.consolidationPage.path}>
        <ConsolidationPage />
      </Route>
    </Switch>
  );
};

export default Consolidation;
