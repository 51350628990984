import type { FC } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { TextArea } from '@zen/DesignSystem';

import FormField, { FormFieldProps } from '../FormField';

interface Props extends FormFieldProps {
  disabled?: boolean;
  name: string;
  onBlur?: () => void;
  onChange?: (name: string, value: string) => void;
  onFocus?: () => void;
  textareaRows?: number;
}

const FormTextarea: FC<Props> = (props) => (
  <FormField {...props}>
    {(field: { name: string; value: string | number }, form: FormInstance<unknown>) => {
      const hadleOnChange = (value: string) => {
        form.setFieldValue(field.name, value);

        if (props.onChange) {
          props.onChange(field.name, value);
        }
      };

      return (
        <TextArea
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          name={props.name}
          onBlur={props.onBlur}
          onChange={(e) => hadleOnChange(e.target.value)}
          onFocus={props.onFocus}
          placeholder={props.placeholder}
          rows={props.textareaRows}
          value={field.value}
        />
      );
    }}
  </FormField>
);

export default FormTextarea;
