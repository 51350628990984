import type { Env } from '@zen/App/AppContext';

interface StaticConfig {
  activeStorageDirectUploadsPath: string;
  applicationVersion: string;
  environment: Env;
  environmentName: string;
  googleMapsKey: string;
  graphqlUrl: string;
  openTelemetryTracesUrl: string;
  subscriptionsUrl: string;
}
declare global {
  interface Window {
    zencargoStaticConfig?: StaticConfig;
  }
}

const staticConfig = (): Readonly<StaticConfig> => {
  if (!window.zencargoStaticConfig) {
    throw new Error('Missing "zencargoStaticConfig" on window');
  }

  return window.zencargoStaticConfig;
};

export default staticConfig();
