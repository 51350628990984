import { FC, ReactElement, ReactNode, useState } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import Search from '@zen/Components/Search';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Modal, Pagination, Table } from '@zen/DesignSystem';
import type { BookingManager } from '@zen/OperationsDashboard/types';
import { SortingOrder, SortInput } from '@zen/types';
import usePagination from '@zen/utils/hooks/pagination/usePagination';

import EditOperationsCoordinator from './EditOperationsCoordinator';
import {
  GetOperationsCoordinatorListQueryResult,
  GetOperationsCoordinatorListQueryVariables,
  useGetOperationsCoordinatorListQuery
} from './graphql';
import getColumnsConfiguration from './operationsCoordinatorListTableConfiguration';
import type { OperationsCoordinatorItem } from './types';

interface Props {
  accountId: string;
}

const initialOrder: SortInput = {
  field: 'zencargoReference',
  direction: SortingOrder.ASC
};

const OperationsCoordinatorList: FC<Props> = ({ accountId }) => {
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isEditOperationsCoordinatorModalOpen, setIsEditOperationsCoordinatorModalOpen] = useState<boolean>(false);
  const [currentZencargoReference, setCurrentZencargoReference] = useState<string>('');
  const [currentOperationsCoordinator, setCurrentOperationsCoordinator] = useState<BookingManager>();

  const { loading, error, nodes, paginationInfo, totalCount, refetch } = usePagination<
    GetOperationsCoordinatorListQueryResult,
    GetOperationsCoordinatorListQueryVariables,
    OperationsCoordinatorItem
  >(useGetOperationsCoordinatorListQuery, 'bookings', { accountId, order, searchQuery }, 20, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const renderSearchOperationsCoordinatorField = (): ReactNode => (
    <div className="flex justify-end">
      <div className="w-80">
        <Search onClear={() => setSearchQuery('')} onSubmit={setSearchQuery} placeholder="Search for zencargo reference..." />
      </div>
    </div>
  );

  const handleEditOperationsCoordinator = (zencargoReference: string, operationsCoordinator: BookingManager): void => {
    setIsEditOperationsCoordinatorModalOpen(true);
    setCurrentZencargoReference(zencargoReference);
    setCurrentOperationsCoordinator(operationsCoordinator);
  };

  const columns = getColumnsConfiguration(handleEditOperationsCoordinator);

  return (
    <>
      {renderSearchOperationsCoordinatorField()}

      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonTableLoading columns={columns} order={order} tableId="operationsCoordinatorList" />}
      >
        {(operationsCoordinatorList: OperationsCoordinatorItem[]): ReactElement => (
          <>
            <Modal
              isOpen={isEditOperationsCoordinatorModalOpen}
              modalOverflowY="visible"
              onClose={() => setIsEditOperationsCoordinatorModalOpen(false)}
              title="Edit operations coordinator"
            >
              <EditOperationsCoordinator
                onCancel={() => setIsEditOperationsCoordinatorModalOpen(false)}
                onCoordinatorChange={refetch}
                operationsCoordinator={currentOperationsCoordinator}
                zencargoReference={currentZencargoReference}
              />
            </Modal>

            <Table<OperationsCoordinatorItem>
              columns={columns}
              data={operationsCoordinatorList}
              onOrderChange={setOrder}
              order={order}
              tableId="operationsCoordinatorList"
              totalCountConfig={{
                totalCount,
                entityName: 'item'
              }}
            />
            <Pagination pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
    </>
  );
};

export default OperationsCoordinatorList;
