import type { FC, ReactElement } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useClearShipmentStatusMutation, useGetShipmentActionTypesListQuery, useUpdateShipmentStatusMutation } from './graphql';
import { getShipmentStatusOptions } from './helpers';
import ShipmentStatusUpdateForm from './ShipmentStatusUpdateForm/ShipmentStatusUpdateForm';
import type { ShipmentStatusUpdateFormValues } from './ShipmentStatusUpdateForm/types';
import type { ShipmentActionTypeList, ShipmentActionTypeOption } from './types';

interface Props {
  currentShipmentStatus: string;
  onCancel: () => void;
  zencargoReference: string;
}

const ShipmentStatusUpdate: FC<Props> = ({ onCancel, currentShipmentStatus, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();

  const { data, loading, error } = useGetShipmentActionTypesListQuery({
    variables: { paginated: false }
  });

  const [updateShipmentStatus] = useUpdateShipmentStatusMutation();
  const [clearShipmentStatus] = useClearShipmentStatusMutation();

  const handleSuccess = (option: 'update' | 'remove'): void => {
    if (option === 'update') {
      addSuccess('Shipment status updated');
    }

    if (option === 'remove') {
      addSuccess('Shipment status removed');
    }

    onCancel();
  };

  const handleUpdateShipmentStatus = (formData: ShipmentStatusUpdateFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateShipmentStatus({
          variables: {
            input: {
              zencargoReference,
              status: formData.shipmentStatus
            }
          },
          refetchQueries: ['getShipmentDetailsBoard']
        }),
      onError: addError
    });
  };

  const handleRemoveShipmentStatus = (): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        clearShipmentStatus({
          variables: {
            input: {
              zencargoReference
            }
          },
          refetchQueries: ['getShipmentDetailsBoard']
        }),
      onSuccess: () => {
        handleSuccess('remove');
      },
      onError: addError
    });
  };

  return (
    <QueryHandler data={data?.shipmentActionTypes?.nodes} error={!!error} isLoading={loading}>
      {(shipmentActionTypesList: ShipmentActionTypeList): ReactElement => {
        return (
          <ShipmentStatusUpdateForm
            initialValues={{ shipmentStatus: currentShipmentStatus }}
            onCancel={onCancel}
            onShipmentStatusRemoval={handleRemoveShipmentStatus}
            onSubmit={handleUpdateShipmentStatus}
            onSuccess={() => handleSuccess('update')}
            shipmentStatusOptions={getShipmentStatusOptions(shipmentActionTypesList as ShipmentActionTypeOption[])}
          />
        );
      }}
    </QueryHandler>
  );
};

export default ShipmentStatusUpdate;
