import type { RoutesConfig } from './types';

export const CUSTOMER_ADMIN_ROUTE_PREFIX = '/dashboard/customer-admin';

interface CustomerAdminRoutes extends RoutesConfig {
  allShipments: {
    getUrl: () => string;
    path: string;
  };
  allShipmentsDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
  };
  index: {
    getUrl: () => string;
    path: string;
  };
}

const customerAdminRoutes: CustomerAdminRoutes = {
  allShipments: {
    path: `${CUSTOMER_ADMIN_ROUTE_PREFIX}/all-shipments`,
    getUrl: () => {
      return `${CUSTOMER_ADMIN_ROUTE_PREFIX}/all-shipments`;
    }
  },
  allShipmentsDetailsPage: {
    path: `${CUSTOMER_ADMIN_ROUTE_PREFIX}/all-shipments/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${CUSTOMER_ADMIN_ROUTE_PREFIX}/all-shipments/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  index: {
    path: CUSTOMER_ADMIN_ROUTE_PREFIX,
    getUrl: () => CUSTOMER_ADMIN_ROUTE_PREFIX
  }
};

export default customerAdminRoutes;
