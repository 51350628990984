const emptyDataCell = {
  name: '',
  company: '',
  email: '',
  street: '',
  city: '',
  zip: '',
  country: '',
  coordinates: '',
  action: ''
};

export const emptyTableData = [emptyDataCell, emptyDataCell, emptyDataCell, emptyDataCell, emptyDataCell];

export interface Contact {
  action: string;
  city: string;
  company: string;
  coordinates: string;
  country: string;
  email: string;
  name: string;
  street: string;
  zip: string;
}

export const tableDataMock: Contact[] = [
  {
    name: 'Constantine Mckee-Carson',
    company: 'Beijing Under My Wife’s Co. Ltd.',
    email: 'cursus.non.egestas@cursusvestibulum.edu',
    street: 'P.O. Box 446, 2255 Pede. St.',
    city: 'Almería',
    zip: '672615',
    country: 'Bulgaria',
    coordinates: '-74.50427, -36.3105',
    action: 'Edit'
  },
  {
    name: 'Preston Mckee',
    company: 'Hangzhou No Trouble Looking for Trouble',
    email: 'massa@tempus.net',
    street: '223-3968 Nunc. Rd.',
    city: 'Comblain-au-Pont',
    zip: '177238',
    country: 'Armenia',
    coordinates: '21.92778, 18.57072',
    action: 'Edit'
  },
  {
    name: 'Norman Carson',
    company: 'Anping County Products Factory',
    email: 'adipiscing@velarcu.net',
    street: '9781 Tempus Avenue',
    city: 'Cheyenne',
    zip: '122070',
    country: 'Yemen',
    coordinates: '74.20066, -115.21273',
    action: 'Edit'
  },
  {
    name: 'Marvin Baker',
    company: 'King of Nanning, Guangxi Trading Company Ltd.',
    email: 'Quisque.imperdiet.erat@ornareplaceratorci.net',
    street: '215-6085 Convallis Avenue',
    city: 'Kalininets',
    zip: '301054',
    country: 'Uruguay',
    coordinates: '-69.746, -87.70174',
    action: 'Edit with super long action name'
  },
  {
    name: 'Martin Wilson',
    company: 'Aliquet Inc.',
    email: 'et.magnis.dis@amet.org',
    street: 'P.O. Box 878, 4498 Libero Rd.',
    city: 'Jefferson City',
    zip: '1892',
    country: 'Antigua and Barbuda',
    coordinates: '-72.7735, -102.80573',
    action: 'Edit'
  },
  {
    name: 'Stewart Harrington',
    company: 'Consequat LLC',
    email: 'eleifend@MorbimetusVivamus.ca',
    street: 'Ap #447-8411 Ipsum Rd.',
    city: 'Longueville',
    zip: '82717',
    country: 'Egypt',
    coordinates: '-42.28174, 25.20585',
    action: 'Edit'
  },
  {
    name: 'Hunter Haynes',
    company: 'Convallis Ltd',
    email: 'lectus.quis@Nullatemporaugue.org',
    street: '9804 Urna Av.',
    city: 'Salem',
    zip: '79556',
    country: 'Timor-Leste',
    coordinates: '-61.76204, -2.60012',
    action: 'Edit'
  },
  {
    name: 'Harding Wolfe',
    company: 'Fusce Incorporated',
    email: 'rutrum@et.net',
    street: 'P.O. Box 729, 2398 Sagittis Av.',
    city: 'Körfez',
    zip: '934042',
    country: 'Andorra',
    coordinates: '-28.388, 64.25776',
    action: 'Edit'
  },
  {
    name: 'Armando Hinton',
    company: 'Erat Corporation',
    email: 'ornare.elit.elit@non.com',
    street: '237-4507 Nostra, Av.',
    city: 'Widooie',
    zip: '851346',
    country: 'Slovenia',
    coordinates: '-58.3241, 35.40496',
    action: 'Edit'
  },
  {
    name: 'Bruce Bullock',
    company: 'Placerat Cras Dictum Company',
    email: 'non.sollicitudin@fringilla.com',
    street: '4047 Ultrices Ave',
    city: 'Montpellier',
    zip: '51715',
    country: 'Switzerland',
    coordinates: '-10.58731, -22.90049',
    action: 'Edit'
  },
  {
    name: 'Todd Madden',
    company: 'Vivamus Consulting',
    email: 'mauris@faucibus.org',
    street: '568-1101 Odio Avenue',
    city: 'Rea',
    zip: 'Z4616',
    country: 'Djibouti',
    coordinates: '58.63332, -19.99396',
    action: 'Edit'
  },
  {
    name: 'Calvin Owens',
    company: 'Et Consulting',
    email: 'nisl.Nulla@vulputatevelit.edu',
    street: 'P.O. Box 444, 627 Dui. Rd.',
    city: 'New Orleans',
    zip: 'K3K 1W1',
    country: 'Bahrain',
    coordinates: '-38.69279, 138.15334',
    action: 'Edit'
  }
];
