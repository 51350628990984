import type { FC } from 'react';
import { Route, Switch } from 'react-router';

import NoResults from '@zen/Components/NoResults';
import { networks, NetworksRoutes, NetworksRouteType } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';

import ContactDetails from './Contacts/ContactDetails';
import EditContact from './Contacts/EditContact';
import NewContact from './Contacts/NewContact';
import EditLocationPage from './EditLocationPage';
import LocationDetails from './Locations/LocationDetails';
import NewLocation from './Locations/NewLocation';
import NetworksContext from './NetworksContext';
import EditOrganisation from './Organisations/EditOrganisation';
import MyOrganisation from './Organisations/MyOrganisation';
import EditMyOrganisation from './Organisations/MyOrganisation/EditMyOrganisation';
import NewOrganisation from './Organisations/NewOrganisation';
import OrganisationDetails from './Organisations/OrganisationDetails';

interface Props {
  isZencargoNetwork?: boolean;
  networkId: string;
}

const NetworksContainer: FC<Props> = ({ networkId, isZencargoNetwork = false }) => {
  const { userProfile } = useAccount();

  const hideNetworks: boolean = !!userProfile.division && !userProfile.isAdmin && !isZencargoNetwork;
  const networksType: NetworksRouteType = isZencargoNetwork ? 'zencargo' : 'customer';
  const routes: NetworksRoutes = networks(networksType);

  if (hideNetworks) {
    return (
      <NoResults
        headline="Please edit Network locations and organisations at the Account level."
        tagline={
          <div className="w-96">
            You can use the drop down switcher in the left hand menu to navigate from this Division to the Account.
          </div>
        }
      />
    );
  }

  return (
    <NetworksContext.Provider value={{ isZencargoNetwork, routes }}>
      <div className="flex flex-col h-full bg-white" data-testid="networks-container">
        <Switch>
          <Route path={routes.location.new.path}>
            <NewLocation networkId={networkId} />
          </Route>
          <Route path={routes.location.edit.path}>
            <EditLocationPage />
          </Route>
          <Route path={routes.location.details.path}>
            <LocationDetails />
          </Route>
          <Route path={routes.contact.new.path}>
            <NewContact networkId={networkId} />
          </Route>
          <Route path={routes.contact.edit.path}>
            <EditContact networkId={networkId} />
          </Route>
          <Route path={routes.contact.details.path}>
            <ContactDetails />
          </Route>
          <Route path={routes.organisation.new.path}>
            <NewOrganisation networkId={networkId} />
          </Route>
          <Route path={routes.organisation.edit.path}>
            <EditOrganisation networkId={networkId} />
          </Route>
          <Route path={routes.organisation.details.path}>
            <OrganisationDetails networkId={networkId} />
          </Route>
          <Route path={routes.myOrganisation.edit.path}>
            <EditMyOrganisation networkId={networkId} />
          </Route>
          <Route path={routes.myOrganisation.details.path}>
            <MyOrganisation networkId={networkId} />
          </Route>
        </Switch>
      </div>
    </NetworksContext.Provider>
  );
};

export default NetworksContainer;
