import type { FC, ReactNode } from 'react';
import { useParams } from 'react-router';

import Page from '@zen/Components/Page';
import PageNotFound from '@zen/Components/PageNotFound';
import QueryHandler from '@zen/Components/QueryHandler';
import SectionDescription from '@zen/Components/SectionDescription';
import { Button } from '@zen/DesignSystem';
import { OrganisationTypeValue, useNetworksContext } from '@zen/Networks';
import { ContactRemoveLevelType } from '@zen/Networks/Contacts/ContactTable';
import ContactTableContainer from '@zen/Networks/Contacts/ContactTableContainer';
import {
  NetworkOrganisationContactsQueryResult,
  NetworkOrganisationContactsQueryVariables,
  useNetworkOrganisationContactsQuery,
  useOrganisationDetailsQuery
} from '@zen/Networks/graphql';
import LocationList from '@zen/Networks/Locations/LocationList';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import OrganisationHeader from '../OrganisationHeader';
import OrganisationSearch from '../OrganisationSearch';
import type { OrganisationDetailsType } from './types';

interface Props {
  networkId: string;
}

const OrganisationDetails: FC<Props> = ({ networkId }) => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const { navigate } = useNavigationHistory();
  const { data, loading, error } = useOrganisationDetailsQuery({
    variables: {
      accountUuid: networkId,
      id: organisationId
    },
    fetchPolicy: 'no-cache'
  });

  const { routes } = useNetworksContext();
  const myOrganisationIndex: string = routes.myOrganisation.details.getUrl();

  const handleNewOrganisationButtonClick = () => {
    navigate(routes.organisation.new.getUrl());
  };

  const actionButtons: ReactNode = <Button onClick={handleNewOrganisationButtonClick}>Add a new organisation</Button>;

  const originAgentHelperDescription: ReactNode = (
    <SectionDescription
      className="mt-8"
      text="Bookings raised by contacts will auto assign their location as origin agent and subscribe all contacts that belong to it."
    />
  );

  return (
    <Page actionButtons={actionButtons} defaultBackUrl={myOrganisationIndex} title="Organisation details">
      <OrganisationSearch networkId={networkId} />
      <QueryHandler
        data={data?.network?.organisations?.nodes?.[0]}
        error={!!error}
        isLoading={loading}
        noResults={<PageNotFound />}
      >
        {(organisation: OrganisationDetailsType) => {
          const showOriginAgentLabel: boolean = organisation.type === OrganisationTypeValue.AGENT;

          return (
            <>
              <OrganisationHeader
                id={organisationId}
                name={organisation.name}
                roles={organisation.assignedRoles}
                type={organisation.type}
              />
              <LocationList locations={organisation.locations} organisationId={organisationId} />
              {showOriginAgentLabel && originAgentHelperDescription}
              <ContactTableContainer<NetworkOrganisationContactsQueryResult, NetworkOrganisationContactsQueryVariables>
                hasLocations={!!organisation.locations.length}
                isMyOrganisationPage={true}
                organisationId={organisationId}
                paginatedQueryHook={useNetworkOrganisationContactsQuery}
                paginatedQueryPath="networkOrganisationContacts.organisations.nodes.0.contacts"
                paginatedQueryVars={{ networkId, organisationId }}
                removeType={ContactRemoveLevelType.ORGANISATION}
              />
            </>
          );
        }}
      </QueryHandler>
    </Page>
  );
};

export default OrganisationDetails;
