import type { QueryResult } from '@apollo/client';

import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';

import {
  AuthorizationQueryResult,
  AuthorizationQueryVariables,
  AuthorizationQueryWithoutAnalyticsQueryResult,
  AuthorizationQueryWithoutAnalyticsQueryVariables,
  useAuthorizationQuery,
  useAuthorizationQueryWithoutAnalytics
} from '../graphql';

type QueryResults =
  | QueryResult<AuthorizationQueryResult, AuthorizationQueryVariables>
  | QueryResult<AuthorizationQueryWithoutAnalyticsQueryResult, AuthorizationQueryWithoutAnalyticsQueryVariables>;

const useAuthorization = (): QueryResults => {
  const { analytics } = useFeatureFlags();

  const useQuery = analytics ? useAuthorizationQuery : useAuthorizationQueryWithoutAnalytics;

  return useQuery({
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  });
};

export default useAuthorization;
