import type { ReactNode } from 'react';

import DatePicker from '@zen/Components/DatePicker';
import type { DatePickerSize } from '@zen/Components/DatePicker/types';

import FormField, { FormFieldProps } from '../FormField';
import type { FormFieldInputProps, FormInstance } from '../types';

interface Props extends FormFieldProps {
  disabledDayTooltip?: ReactNode;
  displayDateFormat?: string;
  highlightInitialDate?: boolean;
  initialDate?: string;
  maxDate?: string;
  minDate?: string;
  size?: DatePickerSize;
}

const FormDatePicker = (props: Props) => {
  return (
    <FormField {...props}>
      {(field: FormFieldInputProps<string>, form: FormInstance<unknown>) => (
        <DatePicker
          disabledDayTooltip={props.disabledDayTooltip}
          hasError={field.error}
          highlightInitialDate={props.highlightInitialDate}
          initialDate={props.initialDate}
          maxDate={props.maxDate}
          minDate={props.minDate}
          onChange={(value) => form.setFieldValue(field.name, value)}
          selectedDate={field.value}
          size={props.size}
        />
      )}
    </FormField>
  );
};

export default FormDatePicker;
