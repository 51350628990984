import pDebounce from 'p-debounce';
import type { FC } from 'react';

import { FormMultiSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';
import { useUsedAssignablesLazyQuery } from '@zen/Networks/graphql';
import type { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks/types';
import type { NetworksOrgLoc } from '@zen/Shipments';

interface Props {
  accountIds?: string[];
  assignmentTypes: AssignmentTypeValue[];
  domainNames: AssignmentTargetTypeEnum[];
  label: string;
  name: string;
}

const FormNetworksContactInput: FC<Props> = (props) => {
  const { accountIds, assignmentTypes, domainNames, label, name } = props;
  const [fetchAssignables, { data, loading }] = useUsedAssignablesLazyQuery();

  const handleInputChange = (query: string): void => {
    if (query) {
      fetchAssignables({
        variables: {
          networkIds: accountIds,
          assignmentNames: assignmentTypes,
          targetTypes: domainNames,
          textContains: query
        }
      });
    }
  };

  const debounceDelay: number = 300;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  const formatOptionLabel = (option: Option<NetworksOrgLoc>): string => option.value.label?.long || '';

  const options =
    data?.networksUsedAssignables?.map((assignable) => ({
      label: assignable.label?.long || '',
      value: assignable
    })) || [];

  return (
    <FormMultiSelect<NetworksOrgLoc>
      formatOptionLabel={formatOptionLabel}
      isClearable={true}
      isLoading={loading}
      label={label}
      name={name}
      onInputChange={debouncedHandleInputChange}
      options={options as Option<NetworksOrgLoc>[]}
    />
  );
};

export default FormNetworksContactInput;
