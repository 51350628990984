import type { FC } from 'react';

import { MODES } from '@zen/Booking/TransportModeSelect';
import { FormMultiSelect, FormSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';
import { Loading } from '@zen/DesignSystem';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks';
import FormNetworksContactInput from '@zen/Networks/NetworksContactPicker/FormNetworksContactInput';
import { useGetOperationsShipmentFiltersQuery } from '@zen/OperationsShipments/graphql';
import { customsOnlyOptions, mapIssues, stageFilterOptions } from '@zen/Shipments/Filters/helpers';
import StageWithinFields from '@zen/Shipments/Filters/StageWithinFields';

interface Props {
  showTransportMode: boolean;
}

const FilterFields: FC<Props> = ({ showTransportMode }) => {
  const { data, loading } = useGetOperationsShipmentFiltersQuery({
    fetchPolicy: 'no-cache'
  });

  if (loading) {
    return <Loading />;
  }

  const issueTitleOptions: Option<string>[] = data?.filters?.shipments?.issueTitles?.map(mapIssues) || [];

  return (
    <>
      <FormMultiSelect label="Customs" name="customsOnly" options={customsOnlyOptions} />
      <FormSelect isClearable={true} label="Shipment status" name="status" options={stageFilterOptions} placeholder="Select..." />
      {showTransportMode && <FormMultiSelect label="Transport mode" name="transportModes" options={MODES} />}
      <FormNetworksContactInput
        assignmentTypes={[AssignmentTypeValue.ORIGIN]}
        domainNames={[AssignmentTargetTypeEnum.BOOKING]}
        label="Origin"
        name="origins"
      />
      <FormNetworksContactInput
        assignmentTypes={[AssignmentTypeValue.DESTINATION]}
        domainNames={[AssignmentTargetTypeEnum.BOOKING]}
        label="Destination"
        name="destinations"
      />
      <StageWithinFields />
      <FormSelect isClearable={true} label="Issue types" name="issueTitle" options={issueTitleOptions} />
    </>
  );
};

export default FilterFields;
