import { LanguageTag } from '@zen/utils/languageSettings';
import type { Nullable } from '@zen/utils/typescript';

import { formatDate } from '../date/formatting';
import { isParseableAsDateTime, parseAndFormatDateTime } from '../jodaConnector';
import { formatTimeRange } from '../time/formatting';
import { getTimeFromString } from '../time/parsing';
import type { DateTimeFormat, DateTimeFormatOptions, DateTimeLocaleAndOptions, Time } from '../types';

const dateTimeFormats: DateTimeFormatOptions = {
  D_MMMM_YYYY_HH_MM: {
    locale: LanguageTag.BritishEnglish,
    options: { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }
  },
  MMMM_D_YYYY_HH_MM: {
    locale: LanguageTag.AmericanEnglish,
    options: { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }
  },
  MMM_D_YYYY_HH_MM: {
    locale: LanguageTag.AmericanEnglish,
    options: { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }
  }
};

const getDateTimeFormat = (formatString: DateTimeFormat): DateTimeLocaleAndOptions => {
  return dateTimeFormats[formatString];
};

export const formatDateTime = (inputString: string, dateTimeFormatString: DateTimeFormat = 'MMM_D_YYYY_HH_MM'): string => {
  const dateTimeFormat: DateTimeLocaleAndOptions = getDateTimeFormat(dateTimeFormatString);

  return parseAndFormatDateTime(inputString, dateTimeFormat) || '-';
};

export const formatDateTimeRange = (startDateTimeInputString: string, endDateTimeInputString: string): Nullable<string> => {
  if (!isParseableAsDateTime(startDateTimeInputString) || !isParseableAsDateTime(endDateTimeInputString)) {
    return null;
  }

  const startTime: Time = getTimeFromString(startDateTimeInputString);
  const endTime: Time = getTimeFromString(endDateTimeInputString);

  return `${formatDate(startDateTimeInputString)} ${formatTimeRange(startTime, endTime, false)}`;
};
