import { get } from 'lodash';
import { Link } from 'react-router-dom';

import BookingStatus from '@zen/Components/BookingStatus';
import DateWithDefault from '@zen/Components/DateWithDefault';
import ValueIndicator from '@zen/Components/ValueIndicator';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { TableColumn, TableLink } from '@zen/DesignSystem';
import { ProductLotsColumnEnum } from '@zen/IntakePlanning/types';
import { getCurrentStage } from '@zen/Orders/utils';
import { shipmentRoutes } from '@zen/Routes';
import { formatCurrency, formatNumber } from '@zen/utils/formatting';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { ProductLot } from '../../types';

const renderDate = (date: Optional<string>) => <DateWithDefault date={date} />;

export const getColumns = ({ pathname, search }: { pathname: string; search: string }): TableColumn<ProductLot>[] => [
  {
    key: ProductLotsColumnEnum.PURCHASEORDER,
    title: 'PO number',
    render: (_, { purchaseOrder: { id, orderReferenceNumber } }: ProductLot) => (
      <div className="truncate">
        <Link className="text-base text-navy-dark font-bold hover:underline" to={`${pathname}/${id}/items${search}`}>
          {orderReferenceNumber}
        </Link>
      </div>
    ),
    fixed: 'left'
  },
  {
    key: ProductLotsColumnEnum.QUANTITYPLANNED,
    title: 'Qty planned',
    render: (_, { quantityPlanned }: ProductLot) => <>{formatNumber(quantityPlanned)}</>
  },
  {
    key: ProductLotsColumnEnum.QUANTITYARRIVING,
    title: 'Qty arriving',
    render: (_, { quantityArriving, quantityPlanned }: ProductLot) => (
      <ValueIndicator comparedValue={quantityPlanned} value={quantityArriving} />
    )
  },
  {
    key: ProductLotsColumnEnum.VALUEPLANNED,
    title: 'Value planned',
    render: (_, { valuePlanned }: ProductLot) =>
      valuePlanned?.value ? formatCurrency(valuePlanned.currency, valuePlanned.value, 'symbol', true) : '-',
    sortable: false
  },
  {
    key: ProductLotsColumnEnum.VALUEARRIVING,
    title: 'Value arriving',
    render: (_, { valuePlanned, valueArriving }: ProductLot) => {
      if (!valuePlanned?.value || !valueArriving?.value) {
        return '-';
      }

      return <ValueIndicator comparedValue={valuePlanned.value} currency={valueArriving.currency} value={valueArriving.value} />;
    },
    sortable: false
  },
  {
    key: ProductLotsColumnEnum.ORDERED,
    dataKey: 'stages',
    title: 'Ordered',
    render: (_, stages: ProductLot) => {
      const date = get(stages, '[0].date.date');

      return renderDate(date);
    },
    sortable: false
  },
  {
    key: ProductLotsColumnEnum.READY,
    dataKey: 'stages',
    title: 'Ready',
    render: (_, stages: ProductLot) => {
      const date = get(stages, '[1].date.date');

      return renderDate(date);
    },
    sortable: false
  },
  {
    key: ProductLotsColumnEnum.INTRANSIT,
    dataKey: 'stages',
    title: 'In transit',
    render: (_, stages: ProductLot) => {
      const date = get(stages, '[2].date.date');

      return renderDate(date);
    },
    sortable: false
  },
  {
    key: ProductLotsColumnEnum.ARRIVING,
    dataKey: 'stages',
    title: 'Arrival port',
    render: (_, { stages }: ProductLot) => {
      const date = get(stages, '[3].date.date');

      return renderDate(date);
    },
    sortable: false
  },
  {
    key: 'orderedLineItem',
    title: 'Required delivery',
    render: (_, { orderedLineItem }: ProductLot) => {
      const date = get(orderedLineItem, 'requiredDeliveryDate.date');

      return renderDate(date);
    }
  },
  {
    key: ProductLotsColumnEnum.ESTIMATEDDELIVERYDATE,
    dataKey: 'stages',
    title: 'Est. delivery',
    render: (_, { stages }: ProductLot) => {
      const date = get(stages, '[4].date.date');

      return renderDate(date);
    },
    sortable: false,
    fixed: 'right'
  },
  {
    key: 'booking',
    title: 'Booking status',
    render: (_, { booking, atRisk }: ProductLot) => {
      if (!booking) {
        return <BookingStatus atRisk={!!atRisk} booked={false} />;
      }

      return (
        <TableLink linkTo={shipmentRoutes.shipmentDetailsPage.getUrl(booking.zencargoReference)}>
          {booking.zencargoReference}
        </TableLink>
      );
    },
    sortable: false,
    fixed: 'right'
  },
  {
    key: ProductLotsColumnEnum.STATUS,
    dataKey: 'stages',
    title: 'Status',
    render: (_, { stages }: ProductLot) => {
      const stage = getCurrentStage<{ currentStage?: Nullable<boolean>; name?: Nullable<string> }>(stages);

      return <ValueWithDefault value={stage?.name} />;
    },
    sortable: false,
    fixed: 'right'
  },
  {
    key: ProductLotsColumnEnum.LASTUPDATED,
    title: 'Last updated',
    render: (_, { lastUpdated }: ProductLot) => <span className="text-grey-base">{renderDate(lastUpdated)}</span>,
    fixed: 'right'
  }
];
