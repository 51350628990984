import { FC, useState } from 'react';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Pagination, Table } from '@zen/DesignSystem';
import useAccount from '@zen/utils/hooks/useAccount';
import type { Nullable } from '@zen/utils/typescript';

import Map from './components/Map';
import MapSkeleton from './components/MapSkeleton';
import NoResults from './components/NoResults';
import StatusFilters from './components/StatusFilters';
import Filters from './Filters';
import type { TrackedShipmentFiltersInput } from './Filters/types';
import { RoadTrackedShipmentFiltersQueryResult, useRoadTrackedShipmentFiltersQuery } from './graphql';
import { buildStatusCounts, getInitialActiveStatus, hasActiveShipments } from './helpers';
import { useRoadTrackedShipments, useRoadTrackedShipmentsDetails } from './hooks';
import { prepareShipments } from './LiveShipments/helpers';
import { columns } from './LiveShipments/tableConfiguration';
import type { RoadTrackedShipmentStatus } from './types';

const TrackedShipments: FC = () => {
  const { accountUuid: customerId } = useAccount();
  const [activeStatus, setActiveStatus] = useState<Nullable<RoadTrackedShipmentStatus>>(null);
  const [activeFilters, setActiveFilters] = useState<TrackedShipmentFiltersInput>({});
  const [hasTrackedShipments, setHasTrackedShipments] = useState<boolean>(false);

  const queryParams = {
    customerId,
    status: activeStatus,
    ...activeFilters
  };

  const handleCompleted = ({ roadTrackedShipmentFilters }: RoadTrackedShipmentFiltersQueryResult): void => {
    const initialActiveStatus: RoadTrackedShipmentStatus = getInitialActiveStatus(roadTrackedShipmentFilters);
    const hasShipments: boolean = hasActiveShipments(roadTrackedShipmentFilters);

    setActiveStatus(initialActiveStatus);
    setHasTrackedShipments(hasShipments);
  };

  const { loading: isFiltersLoading, error: filtersQueryError } = useRoadTrackedShipmentFiltersQuery({
    fetchPolicy: 'cache-and-network',
    variables: { customerId },
    onCompleted: handleCompleted,
    skip: !!activeStatus
  });

  const { data: filterData, refetch: refetchFiltersQuery } = useRoadTrackedShipmentFiltersQuery({
    fetchPolicy: 'cache-and-network',
    variables: { customerId, ...activeFilters },
    skip: !activeStatus
  });

  const { data, error: mapQueryError, isLoading: isMapLoading } = useRoadTrackedShipments(queryParams);

  const {
    loading: isLoadingDetails,
    error: detailsQueryError,
    nodes: shipmentsDetails,
    paginationInfo,
    totalCount
  } = useRoadTrackedShipmentsDetails(queryParams);

  const handleChange = (newStatus: RoadTrackedShipmentStatus): void => {
    setActiveStatus(newStatus);
    refetchFiltersQuery();
  };

  return (
    <Page title="Tracked road shipments">
      <QueryHandler
        data={hasTrackedShipments}
        error={!!filtersQueryError}
        isLoading={isFiltersLoading || !activeStatus}
        loadingComponent={<MapSkeleton />}
        noResults={<NoResults />}
      >
        {() => (
          <>
            <Filters onChange={setActiveFilters} />
            <QueryHandler data={activeStatus} error={!!mapQueryError} isLoading={isMapLoading} loadingComponent={<MapSkeleton />}>
              {(status: RoadTrackedShipmentStatus) => (
                <div className="flex">
                  <div className="w-56 mr-6">
                    <StatusFilters activeStatus={status} onChange={handleChange} statusCounts={buildStatusCounts(filterData)} />
                  </div>
                  <div className="flex-1">
                    <Map activeStatus={status} shipments={data} />
                  </div>
                </div>
              )}
            </QueryHandler>
            <QueryHandler
              data={shipmentsDetails}
              error={!!detailsQueryError}
              isLoading={isLoadingDetails}
              loadingComponent={<SkeletonTableLoading columns={columns} rowCount={10} tableId="liveTrackedShipments" />}
            >
              {() => (
                <div className="mt-6">
                  <Table
                    columns={columns}
                    data={prepareShipments(shipmentsDetails)}
                    tableId="liveTrackedShipments"
                    totalCountConfig={{ totalCount, entityName: 'shipment' }}
                  />
                  <Pagination pageInfo={paginationInfo} />
                </div>
              )}
            </QueryHandler>
          </>
        )}
      </QueryHandler>
    </Page>
  );
};

export default TrackedShipments;
