import type { Location } from 'history';
import type { ReactNode } from 'react';

import CargoValue from '@zen/Cargo/components/CargoValue';
import CargoSummary from '@zen/Components/CargoSummary';
import DateWithTimeZone from '@zen/Components/DateWithTimeZone';
import OrdersReferencesList from '@zen/Components/OrdersReferencesList';
import ShipmentReferences from '@zen/Components/ShipmentReferences';
import ShipmentStageProgress from '@zen/Components/ShipmentStageProgress';
import ShipmentStatus from '@zen/Components/ShipmentStatus';
import TableLink from '@zen/Components/TableLink';
import TransportModeIcon from '@zen/Components/TransportModeIcon';
import type { TableColumn } from '@zen/DesignSystem';
import IssueCounterPill from '@zen/Issues/components/IssueCounterPill';
import { shipmentRoutes } from '@zen/Routes';
import { ShipmentPageSectionUrl } from '@zen/Shipment/ShipmentDetailsPage/types';
import { ShippingOrderState } from '@zen/Shipment/types';
import { LABEL_FOR_INCOTERMS } from '@zen/utils/constants';

import ActionItemCounterPill from '../ActionItemCounterPill/ActionItemCounterPill';
import { FlatShipment, ShipmentsListCapabilities, VoyageMilestoneNameEnum } from '../types';

interface ColumnsProps {
  additionalColumns?: TableColumn[];
  capabilities?: ShipmentsListCapabilities;
  pathname?: string;
  tableLocation?: Location;
}

const shippingOrderStatusMapping: Record<ShippingOrderState, string> = {
  [ShippingOrderState.CONFIRMED]: 'Confirmed',
  [ShippingOrderState.NOT_CONFIRMED]: 'Not confirmed',
  [ShippingOrderState.NOT_REQUIRED]: ''
};

const actionItemsColumn = (pathname: string): TableColumn<FlatShipment> => ({
  key: 'actionItems',
  title: 'Actions',
  sortable: false,
  render: (_, { actionItems, zencargoReference }: FlatShipment): ReactNode => {
    if (!actionItems || !actionItems.length) {
      return null;
    }

    return (
      <TableLink
        linkTo={`${pathname}/details/${zencargoReference}/${ShipmentPageSectionUrl.ISSUES_AND_ACTIONS}`}
        state={{ defaultTab: ShipmentPageSectionUrl.ISSUES_AND_ACTIONS }}
      >
        <ActionItemCounterPill actionItems={actionItems} />
      </TableLink>
    );
  }
});

const cargoColumn: TableColumn<FlatShipment> = {
  key: 'cargo',
  title: 'Cargo details',
  sortable: false,
  render: (_, { cargoSummary }: FlatShipment): ReactNode => {
    return <CargoSummary summary={cargoSummary} />;
  }
};

const cargoValueColumn: TableColumn<FlatShipment> = {
  dataKey: 'cargo',
  key: 'cargoValue',
  title: 'Cargo value',
  sortKey: 'totalValueOfGoods',
  render: (_, { cargoSummary }: FlatShipment): ReactNode => {
    return (
      cargoSummary && (
        <CargoValue highestRiskLevel={cargoSummary?.highestRiskLevel} totalValueOfGoods={cargoSummary?.totalValueOfGoods} />
      )
    );
  }
};

const consignorColumn: TableColumn<FlatShipment> = {
  key: 'consignor',
  title: 'Consignor'
};

const consigneeColumn: TableColumn<FlatShipment> = {
  key: 'consignee',
  title: 'Consignee'
};

const customerColumn: TableColumn<FlatShipment> = {
  key: 'customerAndRef',
  title: 'Customer',
  render: (_, shipment): ReactNode => {
    const customerName = shipment.customer?.name;

    return customerName;
  },
  fixed: 'left'
};

const divisionColumn: TableColumn<FlatShipment> = {
  key: 'division',
  title: 'Division',
  render: (_, shipment): ReactNode => {
    return shipment.customer?.division?.name;
  },
  fixed: 'left',
  sortable: false
};

const delayColumn = (pathname: string): TableColumn<FlatShipment> => ({
  key: 'delay',
  title: 'Status update',
  sortable: false,
  render: (_, { delay, zencargoReference, estimatedDelivery, stageName }: FlatShipment): ReactNode => {
    if (!delay) {
      return null;
    }

    return (
      <TableLink linkTo={`${pathname}/details/${zencargoReference}/${ShipmentPageSectionUrl.ROUTE_PROGRESS}`}>
        <ShipmentStatus
          delay={delay}
          hasArrived={stageName ? stageName === VoyageMilestoneNameEnum.DELIVERED : false}
          hasLatestDeliveryEstimate={!!estimatedDelivery?.date}
        />
      </TableLink>
    );
  }
});

const destinationColumn: TableColumn<FlatShipment> = {
  key: 'destination',
  title: 'Destination',
  sortable: false
};

const poReferencesColumn: TableColumn<FlatShipment> = {
  key: 'purchaseOrders',
  title: 'PO references',
  sortable: false,
  render: (_, { purchaseOrderReferences, zencargoReference }: FlatShipment): ReactNode => (
    <OrdersReferencesList
      detailsUrl={shipmentRoutes.shipmentDetails.getUrl}
      orderReferencesList={purchaseOrderReferences}
      zencargoReference={zencargoReference}
    />
  )
};

const estimatedDepartureColumn: TableColumn<FlatShipment> = {
  key: 'estimatedDeparture',
  title: 'ETD',
  className: 'whitespace-nowrap',
  render: (_, { estimatedDeparture }: FlatShipment): ReactNode => <DateWithTimeZone dateWithTimeZone={estimatedDeparture} />
};

const estimatedArrivalColumn: TableColumn<FlatShipment> = {
  key: 'estimatedArrival',
  title: 'ETA',
  className: 'whitespace-nowrap',
  render: (_, { estimatedArrival }: FlatShipment): ReactNode => <DateWithTimeZone dateWithTimeZone={estimatedArrival} />
};

const forwarderColumn: TableColumn<FlatShipment> = {
  key: 'forwarder',
  title: 'Forwarder',
  render: (_, { forwarder, forwarderReference }: FlatShipment): ReactNode => {
    return (
      <div>
        <div className="mb-1">{forwarder}</div>
        <div className="text-grey-base text-xs">{forwarderReference}</div>
      </div>
    );
  }
};

const incotermsColumn: TableColumn<FlatShipment> = {
  key: 'incoterms',
  title: 'Incoterm',
  render: (_, { incoterms }: FlatShipment) => (incoterms ? LABEL_FOR_INCOTERMS[incoterms] : null)
};

const issuesColumn = (pathname: string): TableColumn<FlatShipment> => ({
  key: 'issues',
  title: 'Issues',
  sortable: false,
  render: (_, { zencargoReference, issues }: FlatShipment): ReactNode => {
    if (!issues || issues.length === 0) {
      return null;
    }

    return (
      <TableLink linkTo={`${pathname}/details/${zencargoReference}/${ShipmentPageSectionUrl.ISSUES_AND_ACTIONS}`}>
        <IssueCounterPill issues={issues} />
      </TableLink>
    );
  }
});

const latestCollectionEstimateColumn: TableColumn<FlatShipment> = {
  key: 'latestCollectionEstimate',
  title: 'Collection date',
  className: 'whitespace-nowrap',
  render: (_, { estimatedCollection }: FlatShipment): ReactNode => <DateWithTimeZone dateWithTimeZone={estimatedCollection} />
};

const latestDeliveryEstimateColumn: TableColumn<FlatShipment> = {
  key: 'latestDeliveryEstimate',
  title: 'Delivery estimate',
  render: (_, { estimatedDelivery }: FlatShipment): ReactNode => <DateWithTimeZone dateWithTimeZone={estimatedDelivery} />
};

const originColumn: TableColumn<FlatShipment> = {
  key: 'origin',
  title: 'Origin',
  sortable: false
};

const portOfLoadColumn: TableColumn<FlatShipment> = {
  key: 'portOfLoad',
  title: 'Port of Load'
};

const portOfDestinationColumn: TableColumn<FlatShipment> = {
  key: 'portOfDestination',
  title: 'Port of Destination'
};

const referencesColumn: TableColumn<FlatShipment> = {
  key: 'references',
  sortKey: 'zencargoReference',
  title: 'References',
  render: (_, { clientReference, zencargoReference }: FlatShipment): ReactNode => {
    return zencargoReference && <ShipmentReferences clientReference={clientReference} zencargoReference={zencargoReference} />;
  },
  width: '200px'
};

const shippingOrderStatusColumn: TableColumn<FlatShipment> = {
  key: 'shippingOrderStatus',
  title: 'Shipping order status',
  render: (_, { shippingOrderStatus }: FlatShipment): ReactNode => {
    return shippingOrderStatus && shippingOrderStatusMapping[shippingOrderStatus];
  }
};

const stageColumn: TableColumn<FlatShipment> = {
  key: 'stage',
  title: 'Stage progress',
  render: (_, { delay, stageName, modeOfTransport, stage }: FlatShipment): ReactNode => {
    if (stageName && modeOfTransport) {
      return (
        <ShipmentStageProgress stageName={stageName} statusUpdate={stage?.statusUpdate?.title} transportMode={modeOfTransport} />
      );
    }
  }
};

const transportModeColumn: TableColumn<FlatShipment> = {
  key: 'modeOfTransport',
  title: 'Mode',
  render: (_, { modeOfTransport }: FlatShipment): ReactNode => {
    if (!modeOfTransport) {
      return null;
    }

    return (
      <div className="text-center">
        <TransportModeIcon className="text-xl text-azure-base" mode={modeOfTransport} />
      </div>
    );
  }
};

const customsOnlyColumn: TableColumn<FlatShipment> = {
  key: 'customsOnly',
  title: 'Customs',
  render: (_, { customsOnly }: FlatShipment): ReactNode => {
    return customsOnly ? 'Customs only' : '';
  },
  sortable: false
};

const vesselNameColumn: TableColumn<FlatShipment> = {
  key: 'vesselName',
  title: 'Vessel name'
};

export const getColumns = ({
  pathname = '/dashboard/shipments',
  capabilities
}: ColumnsProps = {}): TableColumn<FlatShipment>[] => {
  return [
    { ...transportModeColumn, fixed: 'left' },
    { ...referencesColumn, fixed: 'left' },
    ...(capabilities?.canViewDivision ? [divisionColumn] : [customerColumn]),
    stageColumn,
    cargoColumn,
    originColumn,
    destinationColumn,
    poReferencesColumn,
    consignorColumn,
    consigneeColumn,
    ...(capabilities?.canViewForwarder ? [forwarderColumn] : []),
    cargoValueColumn,
    incotermsColumn,
    estimatedDepartureColumn,
    vesselNameColumn,
    estimatedArrivalColumn,
    portOfLoadColumn,
    portOfDestinationColumn,
    shippingOrderStatusColumn,
    latestCollectionEstimateColumn,
    latestDeliveryEstimateColumn,
    delayColumn(pathname),
    ...(capabilities?.canViewCustomsOnly ? [customsOnlyColumn] : []),
    issuesColumn(pathname),
    actionItemsColumn(pathname)
  ];
};
