import type { FC } from 'react';

import { AsyncSelect } from '@zen/DesignSystem';
import useZencargoNetwork from '@zen/Networks/hooks/useZencargoNetwork';
import {
  AssignmentTargetTypeEnum,
  AssignmentTypeValue,
  NetworksAssignableInterface,
  NetworksOrgLocOption
} from '@zen/Networks/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { ForwardersQueryDocument } from '../../graphql';
import { getOrgLocLabel, handleChangeCallback, IHandleChangeCallback } from '../networksContactHelper';

interface Props {
  domainName: AssignmentTargetTypeEnum;
  onChange: (value: Nullable<NetworksAssignableInterface>) => void;
  value?: Optional<NetworksAssignableInterface>;
}

const NetworksForwarderInput: FC<Props> = (props) => {
  const { onChange, domainName, value = null } = props;
  const { data: zencargoNetworkData } = useZencargoNetwork();
  const zencargoNetworkId = zencargoNetworkData?.zencargoNetwork?.id;
  const assignmentType = AssignmentTypeValue.FORWARDER;

  const handleInputChange: IHandleChangeCallback = handleChangeCallback({
    domainName,
    assignmentType,
    accountUuid: zencargoNetworkId,
    queryDocument: ForwardersQueryDocument,
    resultsPath: 'forwarders.nodes'
  });

  const formatOptionLabel = (option: NetworksOrgLocOption) => getOrgLocLabel(option);

  return (
    <AsyncSelect<NetworksOrgLocOption>
      formatOptionLabel={formatOptionLabel}
      isClearable={false}
      loadOptions={handleInputChange}
      onChange={onChange}
      value={value}
    />
  );
};

export default NetworksForwarderInput;
