import type { FC, ReactNode } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import NoResults from '@zen/Components/NoResults';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import BackofficeQuoteOptions from '@zen/Quotes/BackofficeQuoteOptions';
import QuoteOptionList from '@zen/Quotes/components/QuoteOptionList';

import type { QuoteOption } from '..';
import { useGetQuoteOptionsDetailsQuery } from './graphql';
import { ReactComponent as NoQuotesImage } from './no-quotes.svg';
import type { QuoteOptionDetails } from './types';

interface Props {
  accountUuid: string;
  showAddNewQuote?: boolean;
  zencargoReference: string;
}

const QuoteOptionsContainer: FC<Props> = ({ zencargoReference, accountUuid, showAddNewQuote }: Props) => {
  const { check } = useGlobalPermissions();
  const canAccessBackoffice: boolean = check('backoffice.canAccessRoute');
  const {
    data: quoteOptionsData,
    error,
    loading,
    refetch
  } = useGetQuoteOptionsDetailsQuery({
    variables: { zencargoReference, includeQuoteOptionDrafts: canAccessBackoffice },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const renderAdminQuoteOptions = (quoteOptions: QuoteOption[]): ReactNode => {
    return (
      <BackofficeQuoteOptions
        accountId={accountUuid}
        quotesOptions={quoteOptions}
        refetch={refetch}
        showAddNewQuote={showAddNewQuote}
        zencargoReference={zencargoReference}
      />
    );
  };

  const renderUserQuoteOptions = (quoteOptions: QuoteOption[]): ReactNode => {
    if (quoteOptions.length === 0) {
      return (
        <NoResults
          headline="We are working on it!"
          image={<NoQuotesImage />}
          isCentered={false}
          tagline={
            <>
              No quote options available yet, you will be notified <br /> when you have an active quote option.
            </>
          }
        />
      );
    }

    return <QuoteOptionList quoteOptions={quoteOptions} />;
  };

  return (
    <QueryHandler
      data={quoteOptionsData?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonLoading className="my-5" height={50} />}
    >
      {({ quoteOptions }: QuoteOptionDetails) => {
        return <div>{canAccessBackoffice ? renderAdminQuoteOptions(quoteOptions) : renderUserQuoteOptions(quoteOptions)}</div>;
      }}
    </QueryHandler>
  );
};

export default QuoteOptionsContainer;
