import type { FC } from 'react';

import type { NewCargo } from '@zen/Cargo';
import type { CargoAddress } from '@zen/CollectionAndDelivery/types';
import SectionDescription from '@zen/Components/SectionDescription';
import { AssignmentTypeValue } from '@zen/Networks/types';
import { isOceanShipment, ModeOfTransport } from '@zen/Shipments';
import { useNotification } from '@zen/utils/hooks/useNotification';

import SelectAddress from '../SelectAddress';
import { ScheduleActionType, ScheduleDetails, ScheduleItemType } from '../types';
import CancelSchedule from './CancelSchedule';
import CompleteScheduleForm from './CompleteScheduleForm';
import ConfirmSchedule from './ConfirmSchedule';
import { prepareScheduleDetailsInitialValues } from './helpers';
import ScheduleActionWrapper from './ScheduleActionWrapper';
import ScheduleCollectionForm from './ScheduleCollectionForm';
import ScheduleDeliveryForm from './ScheduleDeliveryForm';
import ScheduleDetailsForm from './ScheduleDetailsForm';

interface Props {
  accountUuid: string;
  cargoList: NewCargo[];
  close: () => void;
  modeOfTransport: ModeOfTransport;
  onError: (errorIds: string[]) => void;
  onSuccess: () => void;
  scheduleType: ScheduleItemType;
  type: ScheduleActionType;
  zencargoReference: string;
}

const ScheduleActions: FC<Props> = (props) => {
  const { accountUuid, zencargoReference, cargoList, type, close, modeOfTransport, onSuccess, scheduleType, onError } = props;
  const cargoIds: string[] = cargoList.map((cargo: NewCargo) => cargo.id);

  const { addError } = useNotification();

  const handleError = (errorIds: string[], message: string) => {
    addError(message);
    onError(errorIds);
  };

  const schedules: ScheduleDetails[] = cargoList
    .filter((cargo: NewCargo) => cargo[scheduleType])
    .map((cargo) => cargo[scheduleType]!);
  const locationValues: CargoAddress[] = schedules
    .filter((schedule: ScheduleDetails) => schedule.location)
    .map((schedule) => schedule.location!);

  const isCollection: boolean = scheduleType === 'collection';
  const assignment: AssignmentTypeValue = isCollection
    ? AssignmentTypeValue.COLLECTION_LOCATION
    : AssignmentTypeValue.DELIVERY_LOCATION;

  const actionComponents = {
    [ScheduleActionType.UPDATE_ADDRESS]: (
      <ScheduleActionWrapper>
        <SelectAddress
          accountUuid={accountUuid}
          assignment={assignment}
          cargoIds={cargoIds}
          field={scheduleType}
          onCancel={close}
          onError={handleError}
          onSuccess={onSuccess}
          values={locationValues}
        />
      </ScheduleActionWrapper>
    ),
    [ScheduleActionType.UPDATE_DETAILS]: (
      <ScheduleActionWrapper>
        <ScheduleDetailsForm
          cargoIds={cargoIds}
          initialValues={prepareScheduleDetailsInitialValues(schedules)}
          modeOfTransport={modeOfTransport}
          onCancel={close}
          onError={handleError}
          onSuccess={onSuccess}
          scheduleType={scheduleType}
          zencargoReference={zencargoReference}
        />
      </ScheduleActionWrapper>
    ),
    [ScheduleActionType.COMPLETE]: (
      <ScheduleActionWrapper>
        <CompleteScheduleForm
          cargoIds={cargoIds}
          onCancel={close}
          onError={handleError}
          onSuccess={onSuccess}
          schedules={schedules}
          type={scheduleType}
          zencargoReference={zencargoReference}
        />
      </ScheduleActionWrapper>
    ),
    [ScheduleActionType.CANCEL]: (
      <CancelSchedule
        cargoIds={cargoIds}
        handleError={handleError}
        handleSuccess={onSuccess}
        scheduleType={scheduleType}
        zencargoReference={zencargoReference}
      />
    ),
    [ScheduleActionType.CONFIRM]: (
      <ConfirmSchedule
        cargoIds={cargoIds}
        handleError={handleError}
        handleSuccess={onSuccess}
        scheduleType={scheduleType}
        zencargoReference={zencargoReference}
      />
    ),
    [ScheduleActionType.SCHEDULE]: isCollection ? (
      <ScheduleActionWrapper>
        <ScheduleCollectionForm
          accountUuid={accountUuid}
          cargoList={cargoList}
          modeOfTransport={modeOfTransport}
          onCancel={close}
          onError={handleError}
          onSuccess={onSuccess}
          zencargoReference={zencargoReference}
        />
      </ScheduleActionWrapper>
    ) : (
      <>
        {isOceanShipment(modeOfTransport) && (
          <SectionDescription text="Scheduling a delivery will turn off the automated estimated delivery date updates." />
        )}
        <ScheduleActionWrapper>
          <ScheduleDeliveryForm
            accountUuid={accountUuid}
            cargoList={cargoList}
            modeOfTransport={modeOfTransport}
            onCancel={close}
            onError={handleError}
            onSuccess={onSuccess}
            zencargoReference={zencargoReference}
          />
        </ScheduleActionWrapper>
      </>
    )
  };

  return actionComponents[type];
};

export default ScheduleActions;
