import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormCheckbox, FormInput, FormInstance } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { TerminalFormValues } from '../types';
import { terminalFormValidation } from './terminalForm.validation';

interface Props {
  buttonLabel?: string;
  initialValues?: TerminalFormValues;
  onCancel: () => void;
  onSubmit: (formData: TerminalFormValues) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
}

const TerminalForm: FC<Props> = (props) => {
  const { initialValues, onSubmit, onCancel, onSuccess, buttonLabel } = props;

  const renderFormButtons = ({ isSubmitting }: FormInstance<TerminalFormValues>): ReactNode => (
    <FormButtons containerClassName="mt-4" isSubmitting={isSubmitting} layout="fixed" text={buttonLabel}>
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={terminalFormValidation}
    >
      {() => (
        <>
          <FormInput isRequired={true} name="name" placeholder="Terminal name" />
          <div className="mb-4">
            <FormCheckbox className="mb-5" label="Airport funtion" name="airportFunction" />
            <FormCheckbox className="mb-5" label="Seaport funtion" name="seaportFunction" />
            <FormCheckbox className="mb-5" label="Rail terminal funtion" name="railTerminalFunction" />
            <FormCheckbox className="mb-5" label="Road terminal funtion" name="roadTerminalFunction" />
          </div>
        </>
      )}
    </Form>
  );
};

export default TerminalForm;
