import { kebabCase } from 'lodash';

import type { FormInstance } from '@zen/Components/Form';
import { Option, Select, SelectProps } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import FormField, { FormFieldProps } from '../FormField';

interface Props<T> extends Omit<SelectProps<T>, 'name' | 'onChange'>, FormFieldProps {
  onChange?: (value: Nullable<T>) => void;
}

const FormSelect = <T extends Nullable<{}>>(props: Props<T>) => {
  const { className, ...rest } = props;

  return (
    <FormField {...props}>
      {(field: { error: boolean; name: string; options: Option<T>[] }, form: FormInstance<unknown>) => (
        <div data-testid={`select-${kebabCase(field.name)}`}>
          <Select
            {...rest}
            {...field}
            hasError={field.error}
            onChange={(value: Nullable<T>) => {
              // when the option is being cleared, formik removes the field from the values object
              // and the validation does not work for the field anymore
              // casting undefined to null fixes the issue
              form.setFieldValue(field.name, value || null);

              if (props.onChange) {
                props.onChange(value);
              }
            }}
          />
        </div>
      )}
    </FormField>
  );
};

export default FormSelect;
