import { useParams } from 'react-router';

import useUrlPagination from '@zen/utils/hooks/pagination/useUrlPagination';
import useAccount from '@zen/utils/hooks/useAccount';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';

import type { ConsolidationAvailableShipmentsQueryResult, ConsolidationAvailableShipmentsQueryVariables } from '../../graphql';
import { useConsolidationAvailableShipmentsQuery } from '../../graphql';
import type { ConsolidationShipment } from '../types';

const useConsolidationAvailableShipments = (searchText: string) => {
  const { consolidationId } = useParams<{ consolidationId: string }>();
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();
  const {
    accountUuid,
    userProfile: { isAdmin }
  } = useAccount();

  return useUrlPagination<
    ConsolidationAvailableShipmentsQueryResult,
    ConsolidationAvailableShipmentsQueryVariables,
    ConsolidationShipment
  >(
    useConsolidationAvailableShipmentsQuery,
    'bookings',
    {
      ...(isAdmin && { customerUuid: accountUuid }),
      availableForConsolidation: consolidationId,
      newCargoService: !!isNewCargoServiceEnabled,
      textContains: searchText
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' },
    20
  );
};

export default useConsolidationAvailableShipments;
