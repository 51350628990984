import type { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
  label: string;
}

const LabelledValue: FC<Props> = ({ className, children, label }) => {
  return (
    <div className={className}>
      <div className="text-grey-base leading-tight text-xs">{label}</div>
      <div className="text-grey-dark text-sm leading-normal">{children}</div>
    </div>
  );
};

export default LabelledValue;
