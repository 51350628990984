import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type EditBookingDocumentTypeMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateBookingDocumentTypeInput;
}>;

export type EditBookingDocumentTypeMutationResult = { __typename?: 'Mutation' } & {
  bookingDocumentUpdateBookingDocumentType?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateBookingDocumentTypePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      bookingDocumentType?: GraphQLTypes.Maybe<
        { __typename?: 'BookingDocumentType' } & Pick<
          GraphQLTypes.BookingDocumentType,
          'id' | 'title' | 'description' | 'name'
        > & {
            permissions: { __typename?: 'Permissions' } & Pick<
              GraphQLTypes.Permissions,
              'agentForwarder' | 'customerUser' | 'manufacturer'
            >;
          }
      >;
    }
  >;
};

export const EditBookingDocumentTypeDocument = /* #__PURE__ */ gql`
  mutation editBookingDocumentType($input: UpdateBookingDocumentTypeInput!) {
    bookingDocumentUpdateBookingDocumentType(input: $input) {
      errors {
        message
        path
      }
      bookingDocumentType {
        id
        title
        description
        name
        permissions {
          agentForwarder
          customerUser
          manufacturer
        }
      }
    }
  }
`;

/**
 * __useEditBookingDocumentTypeMutation__
 *
 * To run a mutation, you first call `useEditBookingDocumentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBookingDocumentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBookingDocumentTypeMutation, { data, loading, error }] = useEditBookingDocumentTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditBookingDocumentTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<EditBookingDocumentTypeMutationResult, EditBookingDocumentTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<EditBookingDocumentTypeMutationResult, EditBookingDocumentTypeMutationVariables>(
    EditBookingDocumentTypeDocument,
    options
  );
}
export type EditBookingDocumentTypeMutationHookResult = ReturnType<typeof useEditBookingDocumentTypeMutation>;
