import { get } from 'lodash';
import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';

import { checkPermission } from '@zen/Auth/authHelper';
import AutomaticAccountSwitcher from '@zen/Components/AutomaticAccountSwitcher';
import DetailsInfoHeader from '@zen/Components/DetailsInfoHeader';
import ForwarderInfoHeader from '@zen/Components/ForwarderInfoHeader';
import PageNotFound from '@zen/Components/PageNotFound/PageNotFound';
import QueryHandler from '@zen/Components/QueryHandler';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import type { IOkOrErrorResult } from '../../utils/OkOrErrorResult';
import {
  useControlTowerOrdersPermissionsQuery,
  usePurchaseOrdersCloseOrderMutation,
  usePurchaseOrdersDeleteOrderMutation
} from '../graphql';
import { useOrderDetailsQuery } from './graphql';
import OrderDetailsHeader from './OrderDetailsHeader';
import OrderDetailsTabs from './OrderDetailsTabs';
import type { OrderDetailsType } from './types';

interface Props {
  onClose: () => void;
  originPath: string;
}

const OrderDetails: FC<Props> = ({ onClose, originPath }) => {
  const { id: orderId } = useParams<{ id: string }>();
  const { addSuccess, addError } = useNotification();
  const { push } = useHistory();

  const [deleteOrder] = usePurchaseOrdersDeleteOrderMutation();
  const [closeOrder] = usePurchaseOrdersCloseOrderMutation();

  const { data, error, loading } = useOrderDetailsQuery({
    variables: { orderId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const orderDetails = data?.purchaseOrders?.nodes?.[0];
  const orderAccountId: string = get(orderDetails, 'customer.uuid');

  const handleDeleteOrder = async (purchaseOrderId: string): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () =>
        deleteOrder({ variables: { input: { purchaseOrderId } }, refetchQueries: ['orderTableQuery', 'orderListQuery'] }),
      onError: addError,
      onSuccess: () => {
        push(originPath);

        addSuccess('Purchase order has been deleted.');
      }
    });

  const handleCloseOrderAction = (purchaseOrderId: string): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () =>
        closeOrder({
          variables: { input: { purchaseOrderId } },
          refetchQueries: ['orderTableQuery', 'orderListQuery']
        }),
      onError: addError,
      onSuccess: () => {
        push(originPath);

        addSuccess('Purchase order has been closed.');
      }
    });

  const { data: controlTowerPermissions } = useControlTowerOrdersPermissionsQuery({
    variables: { uuid: orderAccountId },
    skip: !orderAccountId
  });

  const controlTower = controlTowerPermissions?.account?.controlTower;
  const canViewForwarder: boolean = controlTower
    ? checkPermission<typeof controlTower>(controlTower, 'canViewForwardersOnPurchaseOrders')
    : false;

  return (
    <div className="w-220 h-full">
      {orderAccountId && <AutomaticAccountSwitcher accountIdForUrl={orderAccountId} />}
      <Helmet title="Order details" />
      <QueryHandler data={data?.purchaseOrders?.nodes?.[0]} error={!!error} isLoading={loading} noResults={<PageNotFound />}>
        {(details: OrderDetailsType) => {
          return (
            <div className="flex flex-col h-full overflow-hidden bg-white" data-testid="orders-container">
              {canViewForwarder && (
                <DetailsInfoHeader>
                  <ForwarderInfoHeader forwarderName={details.forwarder?.label?.short} />
                </DetailsInfoHeader>
              )}
              <OrderDetailsTabs
                header={
                  <OrderDetailsHeader
                    onClose={onClose}
                    onCloseOrder={handleCloseOrderAction}
                    onDeleteOrder={handleDeleteOrder}
                    order={details}
                  />
                }
                orderDetails={details}
              />
            </div>
          );
        }}
      </QueryHandler>
    </div>
  );
};

export default OrderDetails;
