import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IntakePlanningSkuAnalyticsQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  dateRange: GraphQLTypes.DateRangeInput;
  productId: GraphQLTypes.Scalars['String'];
  onTimeThreshold?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  inFullThreshold?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Float']>;
}>;

export type IntakePlanningSkuAnalyticsQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & {
      intakePlanningSkuAnalytics: { __typename?: 'Statistics' } & Pick<
        GraphQLTypes.Statistics,
        | 'earlyPercentage'
        | 'averageEarliness'
        | 'onTimePercentage'
        | 'latePercentage'
        | 'averageLateness'
        | 'inFullPercentage'
        | 'averageFulfilmentRate'
      > & {
          leadTimeDistribution?: GraphQLTypes.Maybe<
            Array<{ __typename?: 'Percentile' } & Pick<GraphQLTypes.Percentile, 'percentile' | 'value'>>
          >;
        };
    }
  >;
};

export const IntakePlanningSkuAnalyticsDocument = /* #__PURE__ */ gql`
  query intakePlanningSkuAnalytics(
    $accountUuid: String!
    $dateRange: DateRangeInput!
    $productId: String!
    $onTimeThreshold: Int
    $inFullThreshold: Float
  ) {
    account(uuid: $accountUuid) {
      intakePlanningSkuAnalytics(
        dateRange: $dateRange
        productId: $productId
        onTimeThreshold: $onTimeThreshold
        inFullThreshold: $inFullThreshold
      ) {
        earlyPercentage
        averageEarliness
        onTimePercentage
        latePercentage
        averageLateness
        inFullPercentage
        averageFulfilmentRate
        leadTimeDistribution {
          percentile
          value
        }
      }
    }
  }
`;

/**
 * __useIntakePlanningSkuAnalyticsQuery__
 *
 * To run a query within a React component, call `useIntakePlanningSkuAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntakePlanningSkuAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntakePlanningSkuAnalyticsQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      dateRange: // value for 'dateRange'
 *      productId: // value for 'productId'
 *      onTimeThreshold: // value for 'onTimeThreshold'
 *      inFullThreshold: // value for 'inFullThreshold'
 *   },
 * });
 */
export function useIntakePlanningSkuAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<IntakePlanningSkuAnalyticsQueryResult, IntakePlanningSkuAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<IntakePlanningSkuAnalyticsQueryResult, IntakePlanningSkuAnalyticsQueryVariables>(
    IntakePlanningSkuAnalyticsDocument,
    options
  );
}
export function useIntakePlanningSkuAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IntakePlanningSkuAnalyticsQueryResult, IntakePlanningSkuAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<IntakePlanningSkuAnalyticsQueryResult, IntakePlanningSkuAnalyticsQueryVariables>(
    IntakePlanningSkuAnalyticsDocument,
    options
  );
}
export type IntakePlanningSkuAnalyticsQueryHookResult = ReturnType<typeof useIntakePlanningSkuAnalyticsQuery>;
export type IntakePlanningSkuAnalyticsLazyQueryHookResult = ReturnType<typeof useIntakePlanningSkuAnalyticsLazyQuery>;
