import type { LocationDescriptorObject as HistoryLocationDescriptorObject } from 'history';

import type { Error as GraphQLError } from '@zen/graphql/types.generated';
import type { IconName } from '@zen/Styleguide';
import type { Modify } from '@zen/utils/typescript';

export type LocationDescriptorObject<T = unknown> = Modify<HistoryLocationDescriptorObject<T>, { pathname: string }>;

export type LocationDescriptor = string | LocationDescriptorObject;

export type {
  ControlTower,
  CountryCode,
  DateRangeInput,
  Error,
  Money,
  RichDateTime,
  SortInput
} from '@zen/graphql/types.generated';
export {
  CargoItemSubTypeEnum,
  CargoItemTypeEnum,
  CargoModeEnum,
  ContainerTypeEnum,
  Currency,
  IncotermsValue,
  LooseCargoTypeEnum,
  ModeOfTransport,
  PalletTypeEnum,
  SortingOrder,
  VehicleTypeEnum
} from '@zen/graphql/types.generated';

export type ServerError = {} | GraphQLError | GraphQLError[];

export enum ProductTabs {
  ANALYTICS = 'analytics',
  DETAILS = 'details',
  PO = 'po',
  WEEKLY = 'weekly'
}

export interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNext: () => void;
  onPrevious: () => void;
}

export interface BooleanMap {
  [key: string]: boolean;
}

export type ButtonClick = React.MouseEvent<HTMLButtonElement, MouseEvent>;

export type IconType = IconName | React.ReactElement;

export * from './labels';

export enum PageSize {
  TEN = 10,
  TWENTY = 20,
  FIFTY = 50
}
