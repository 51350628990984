import type { FC, ReactNode } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import { Form, FormButtons, FormInstance, FormMultiSelect, FormSelect } from '@zen/Components/Form';
import HeadingWithAction from '@zen/Components/HeadingWithAction';
import StickyFooter from '@zen/Components/StickyFooter';
import { Button } from '@zen/DesignSystem';
import { useUsedAssignables } from '@zen/Networks';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks/types';
import useOrderFilters from '@zen/Orders/hooks/useOrderFilters';
import type { OrderFilters } from '@zen/Orders/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { buildFilters } from '../helpers';
import type { FilterDescription } from '../types';
import { validationSchema } from './validation';

const domainNames: AssignmentTargetTypeEnum[] = [AssignmentTargetTypeEnum.PURCHASE_ORDER];
const assignmentTypes: AssignmentTypeValue[] = [AssignmentTypeValue.MANUFACTURER];

interface Props {
  initialValues: OrderFilters;
  onClose: () => void;
  onReset: () => void;
  onSubmit: (values: OrderFilters) => void;
}

const FiltersForm: FC<Props> = (props) => {
  const { initialValues, onSubmit, onClose, onReset } = props;

  const { data: manufacturers } = useUsedAssignables(domainNames, assignmentTypes);
  const { data: filterOptions } = useOrderFilters();

  const shouldRenderCustomerFilter: boolean = filterOptions ? checkPermission(filterOptions, 'canViewCustomerFilter') : false;

  const filtersOptions: FilterDescription[] = buildFilters(
    {
      customers: filterOptions?.customers || [],
      issueTitles: filterOptions?.issueTitles || [],
      manufacturers: manufacturers || [],
      polTerminals: filterOptions?.portsOfLoad || [],
      podTerminals: filterOptions?.portsOfDestination || []
    },
    shouldRenderCustomerFilter
  );

  const handleReset = (): void => {
    onReset();
    onClose();
  };

  const handleSubmit = (values: OrderFilters): Promise<IOkOrErrorResult> => {
    onSubmit(values);

    return Promise.resolve({
      ok: { data: values },
      error: null
    });
  };

  const handleSuccess = (): void => {
    onClose();
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<OrderFilters>): ReactNode => (
    <StickyFooter>
      <FormButtons isSubmitting={isSubmitting} text="Apply filters">
        <Button onClick={handleReset} variant="secondary">
          Clear filters
        </Button>
      </FormButtons>
    </StickyFooter>
  );

  const renderFields = (values: OrderFilters): ReactNode => {
    return filtersOptions.map(({ name, label, options = [], isMultiSelect, placeholder, render }, i: number): ReactNode => {
      return (
        <div key={i}>
          {isMultiSelect ? (
            <FormMultiSelect label={label} name={name} options={options} placeholder={placeholder} />
          ) : (
            <FormSelect isClearable={true} label={label} name={name} options={options} placeholder={placeholder} />
          )}
          {render && render(values)}
        </div>
      );
    });
  };

  return (
    <div data-testid="orders-filters-form">
      <Form
        className="relative w-160 p-0"
        enableReinitialize={true}
        formButtons={renderFormButtons}
        formName="OrderFiltersForm"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        validationSchema={validationSchema}
      >
        {({ values }: FormInstance<OrderFilters>) => {
          return (
            <div className="pl-8 pr-2">
              <HeadingWithAction onClick={onClose} title="Filter my purchase orders" />
              <div className="pr-4 overflow-y-scroll pb-4 lg:pb-16 h-[calc(100vh-12rem)]">{renderFields(values)}</div>
            </div>
          );
        }}
      </Form>
    </div>
  );
};

export default FiltersForm;
