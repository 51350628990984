import type { FC, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string;
  title: string;
}

const ConsolidationDetailsInfo: FC<Props> = ({ className = '', children, title }) => {
  return (
    <div className={`leading-normal ${className}`}>
      <div className="text-xs text-grey-base min-h-5 font-normal">{title}</div>
      <div className="text-sm truncate text-grey-dark" data-testid="content">
        {children || '-'}
      </div>
    </div>
  );
};

export default ConsolidationDetailsInfo;
