import type { FC } from 'react';

import type { IconName } from '@zen/Styleguide';

import Icon from '../Icon';

interface Props {
  icon: IconName;
  onClick: () => void;
}

const InputButton: FC<Props> = ({ icon, onClick }) => {
  return (
    <div data-component="input-button" data-testid={`field-button-${icon}`} onClick={onClick}>
      <Icon className="h-9 w-9 text-grey-base" icon={icon} interactive={true} />
    </div>
  );
};

export type { Props as InputButtonProps };

export default InputButton;
