import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetOperationsRoadShipmentsQueryVariables = GraphQLTypes.Exact<{
  accountIds?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>> | GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>
  >;
  active?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  bookingManagerIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  customsOnly?: GraphQLTypes.InputMaybe<
    | Array<GraphQLTypes.InputMaybe<GraphQLTypes.CustomsOnlyFilterEnum>>
    | GraphQLTypes.InputMaybe<GraphQLTypes.CustomsOnlyFilterEnum>
  >;
  destinations?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  issues?: GraphQLTypes.InputMaybe<GraphQLTypes.IssuesFilterInput>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  newCargoService: GraphQLTypes.Scalars['Boolean'];
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  origins?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  showInTransit?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  showSubscribed?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  withinTimeRange?: GraphQLTypes.InputMaybe<GraphQLTypes.WithinTimeRangeInput>;
  withSteps?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.BookingStep> | GraphQLTypes.BookingStep>;
}>;

export type GetOperationsRoadShipmentsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<
            GraphQLTypes.Booking,
            | 'accountUuid'
            | 'accrualsEarliestState'
            | 'cargoDescription'
            | 'clientReference'
            | 'consignorLabel'
            | 'consigneeLabel'
            | 'customsOnly'
            | 'modeOfTransport'
            | 'shipmentType'
            | 'tradeRole'
            | 'zencargoReference'
          > & {
              bookingStage?: GraphQLTypes.Maybe<{ __typename?: 'BookingStage' } & Pick<GraphQLTypes.BookingStage, 'name'>>;
              calculatedInfo: { __typename?: 'CalculatedInfo' } & {
                cargoSummary?: GraphQLTypes.Maybe<
                  { __typename?: 'CargoSummary' } & Pick<
                    GraphQLTypes.CargoSummary,
                    'highestRiskLevel' | 'label' | 'totalActualCbm' | 'type'
                  > & {
                      totalGrossWeight?: GraphQLTypes.Maybe<
                        Array<{ __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>>
                      >;
                      totalValueOfGoods?: GraphQLTypes.Maybe<
                        Array<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>
                      >;
                    }
                >;
                collectionInFull?: GraphQLTypes.Maybe<
                  { __typename?: 'InFull' } & Pick<GraphQLTypes.InFull, 'state'> & {
                      dateTimeRange?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeRange' } & {
                          startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                            GraphQLTypes.LocalDateTimeType,
                            'date' | 'time' | 'timeZone'
                          >;
                          endDateTime?: GraphQLTypes.Maybe<
                            { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                          >;
                        }
                      >;
                    }
                >;
                deliveryInFull?: GraphQLTypes.Maybe<
                  { __typename?: 'InFull' } & Pick<GraphQLTypes.InFull, 'state'> & {
                      dateTimeRange?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeRange' } & {
                          startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                            GraphQLTypes.LocalDateTimeType,
                            'date' | 'time' | 'timeZone'
                          >;
                          endDateTime?: GraphQLTypes.Maybe<
                            { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                          >;
                        }
                      >;
                    }
                >;
              };
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    cargoItems?: GraphQLTypes.Maybe<
                      Array<
                        | ({ __typename?: 'CargoContainerType' } & Pick<GraphQLTypes.CargoContainerType, 'id' | 'type'> & {
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  'driverDetails' | 'id' | 'reference' | 'vehiclePlateNumber'
                                >
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<GraphQLTypes.DeliveryDetails, 'id' | 'reference'>
                              >;
                            })
                        | ({ __typename?: 'CargoLooseCargoType' } & Pick<
                            GraphQLTypes.CargoLooseCargoType,
                            'trailerId' | 'id' | 'type'
                          > & {
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  'driverDetails' | 'id' | 'reference' | 'vehiclePlateNumber'
                                >
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<GraphQLTypes.DeliveryDetails, 'id' | 'reference'>
                              >;
                            })
                        | ({ __typename?: 'CargoVehicleType' } & Pick<
                            GraphQLTypes.CargoVehicleType,
                            'trailerId' | 'vehicleType' | 'id' | 'type'
                          > & {
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  'driverDetails' | 'id' | 'reference' | 'vehiclePlateNumber'
                                >
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<GraphQLTypes.DeliveryDetails, 'id' | 'reference'>
                              >;
                            })
                      >
                    >;
                  }
              >;
              cargoReadyDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
              cargos?: GraphQLTypes.Maybe<
                Array<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'cargoType' | 'id' | 'trailerId'>>
              >;
              delay?: GraphQLTypes.Maybe<{ __typename?: 'Delay' } & Pick<GraphQLTypes.Delay, 'days' | 'type' | 'description'>>;
              haulier?: GraphQLTypes.Maybe<{ __typename?: 'NetworkContact' } & Pick<GraphQLTypes.NetworkContact, 'id' | 'name'>>;
              incoterms?: GraphQLTypes.Maybe<{ __typename?: 'Incoterms' } & Pick<GraphQLTypes.Incoterms, 'value'>>;
              issues?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'BookingIssue' } & Pick<
                    GraphQLTypes.BookingIssue,
                    'id' | 'severity' | 'category' | 'state' | 'title'
                  >
                >
              >;
              networksDestination?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                      >;
                    })
              >;
              networksOrigin?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                      >;
                    })
              >;
              specialInstructions?: GraphQLTypes.Maybe<
                { __typename?: 'BookingSpecialInstructions' } & Pick<GraphQLTypes.BookingSpecialInstructions, 'message'>
              >;
              stage?: GraphQLTypes.Maybe<
                { __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'actionItemCount'> & {
                    statusUpdate?: GraphQLTypes.Maybe<{ __typename?: 'StatusUpdate' } & Pick<GraphQLTypes.StatusUpdate, 'title'>>;
                  }
              >;
              voyageMilestone?: GraphQLTypes.Maybe<
                { __typename?: 'VoyageMilestone' } & Pick<GraphQLTypes.VoyageMilestone, 'name'>
              >;
            }
        >
      >
    >;
    pageInfo: { __typename: 'PageInfo' } & Pick<
      GraphQLTypes.PageInfo,
      'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
    >;
  };
};

export const GetOperationsRoadShipmentsDocument = /* #__PURE__ */ gql`
  query getOperationsRoadShipments(
    $accountIds: [String]
    $active: Boolean
    $after: String
    $before: String
    $bookingManagerIds: [String!]
    $customsOnly: [CustomsOnlyFilterEnum]
    $destinations: [String!]
    $first: Int
    $issues: IssuesFilterInput
    $last: Int
    $newCargoService: Boolean!
    $order: SortInput
    $origins: [String!]
    $searchQuery: String
    $showInTransit: Boolean
    $showSubscribed: Boolean
    $withinTimeRange: WithinTimeRangeInput
    $withSteps: [BookingStep!]
  ) {
    bookings(
      active: $active
      after: $after
      before: $before
      customers: $accountIds
      customsOnly: $customsOnly
      destinations: $destinations
      first: $first
      issues: $issues
      last: $last
      onlyShipments: true
      onlySingleCargo: true
      operationsManagers: $bookingManagerIds
      order: $order
      origins: $origins
      showInTransit: $showInTransit
      showSubscribed: $showSubscribed
      textContains: $searchQuery
      transportModes: [TRUCK]
      withinTimeRange: $withinTimeRange
      withSteps: $withSteps
    ) {
      nodes {
        accountUuid
        accrualsEarliestState
        bookingStage {
          name
        }
        calculatedInfo {
          cargoSummary {
            highestRiskLevel
            label
            totalActualCbm
            totalGrossWeight {
              metric
              value
            }
            totalValueOfGoods {
              currency
              value
            }
            type
          }
          collectionInFull {
            dateTimeRange {
              startDateTime {
                date
                time
                timeZone
              }
              endDateTime {
                date
                time
              }
            }
            state
          }
          deliveryInFull {
            dateTimeRange {
              startDateTime {
                date
                time
                timeZone
              }
              endDateTime {
                date
                time
              }
            }
            state
          }
        }
        cargo {
          cargoItems {
            ... on CargoLooseCargoType {
              trailerId
            }
            ... on CargoVehicleType {
              trailerId
              vehicleType
            }
            collection {
              driverDetails
              id
              reference
              vehiclePlateNumber
            }
            delivery {
              id
              reference
            }
            id
            type
          }
          mode
        }
        cargoDescription
        cargoReadyDate {
          date
        }
        cargos @include(if: $newCargoService) {
          cargoType
          id
          trailerId
        }
        clientReference
        consignorLabel
        consigneeLabel
        customsOnly
        delay {
          days
          type
          description
        }
        haulier {
          id
          name
        }
        incoterms {
          value
        }
        issues {
          id
          severity
          category
          state
          title
        }
        modeOfTransport
        networksDestination {
          id
          label {
            long
            short
          }
        }
        networksOrigin {
          id
          label {
            long
            short
          }
        }
        shipmentType
        specialInstructions {
          message
        }
        tradeRole
        stage {
          actionItemCount
          statusUpdate {
            title
          }
        }
        voyageMilestone {
          name
        }
        zencargoReference
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetOperationsRoadShipmentsQuery__
 *
 * To run a query within a React component, call `useGetOperationsRoadShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationsRoadShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationsRoadShipmentsQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      active: // value for 'active'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      bookingManagerIds: // value for 'bookingManagerIds'
 *      customsOnly: // value for 'customsOnly'
 *      destinations: // value for 'destinations'
 *      first: // value for 'first'
 *      issues: // value for 'issues'
 *      last: // value for 'last'
 *      newCargoService: // value for 'newCargoService'
 *      order: // value for 'order'
 *      origins: // value for 'origins'
 *      searchQuery: // value for 'searchQuery'
 *      showInTransit: // value for 'showInTransit'
 *      showSubscribed: // value for 'showSubscribed'
 *      withinTimeRange: // value for 'withinTimeRange'
 *      withSteps: // value for 'withSteps'
 *   },
 * });
 */
export function useGetOperationsRoadShipmentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetOperationsRoadShipmentsQueryResult, GetOperationsRoadShipmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetOperationsRoadShipmentsQueryResult, GetOperationsRoadShipmentsQueryVariables>(
    GetOperationsRoadShipmentsDocument,
    options
  );
}
export function useGetOperationsRoadShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOperationsRoadShipmentsQueryResult, GetOperationsRoadShipmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetOperationsRoadShipmentsQueryResult, GetOperationsRoadShipmentsQueryVariables>(
    GetOperationsRoadShipmentsDocument,
    options
  );
}
export type GetOperationsRoadShipmentsQueryHookResult = ReturnType<typeof useGetOperationsRoadShipmentsQuery>;
export type GetOperationsRoadShipmentsLazyQueryHookResult = ReturnType<typeof useGetOperationsRoadShipmentsLazyQuery>;
