import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetQuotesQueryVariables = GraphQLTypes.Exact<{
  accountIds?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>> | GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>
  >;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type GetQuotesQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'Booking' } & Pick<
              GraphQLTypes.Booking,
              | 'zencargoReference'
              | 'clientReference'
              | 'modeOfTransport'
              | 'consigneeLabel'
              | 'consignorLabel'
              | 'activeQuoteOptionsCount'
              | 'activatedQuoteOptionsCount'
            > & {
                calculatedInfo: { __typename?: 'CalculatedInfo' } & {
                  cargoSummary?: GraphQLTypes.Maybe<
                    { __typename?: 'CargoSummary' } & Pick<GraphQLTypes.CargoSummary, 'highestRiskLevel' | 'label' | 'type'> & {
                        totalValueOfGoods?: GraphQLTypes.Maybe<
                          Array<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>
                        >;
                      }
                  >;
                };
                carriage?: GraphQLTypes.Maybe<
                  { __typename?: 'Carriage' } & Pick<GraphQLTypes.Carriage, 'vesselName'> & {
                      portOfDestination?: GraphQLTypes.Maybe<
                        { __typename?: 'PortOfDestination' } & Pick<GraphQLTypes.PortOfDestination, 'name'>
                      >;
                      portOfLoad?: GraphQLTypes.Maybe<{ __typename?: 'PortOfLoad' } & Pick<GraphQLTypes.PortOfLoad, 'name'>>;
                    }
                >;
                estimatedArrival?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                >;
                estimatedDeparture?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                >;
                networksOrigin?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                >;
                networksDestination?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                >;
                incoterms?: GraphQLTypes.Maybe<{ __typename?: 'Incoterms' } & Pick<GraphQLTypes.Incoterms, 'value'>>;
                issues?: GraphQLTypes.Maybe<
                  Array<
                    { __typename?: 'BookingIssue' } & Pick<GraphQLTypes.BookingIssue, 'category' | 'severity' | 'state' | 'title'>
                  >
                >;
                purchaseOrderReferences?: GraphQLTypes.Maybe<
                  { __typename?: 'PurchaseOrderReferences' } & Pick<GraphQLTypes.PurchaseOrderReferences, 'totalCount'> & {
                      nodes?: GraphQLTypes.Maybe<
                        Array<
                          { __typename?: 'PurchaseOrderReference' } & Pick<
                            GraphQLTypes.PurchaseOrderReference,
                            'orderReferenceNumber' | 'id'
                          >
                        >
                      >;
                    }
                >;
                stage?: GraphQLTypes.Maybe<
                  { __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'description' | 'value'> & {
                      statusUpdate?: GraphQLTypes.Maybe<
                        { __typename?: 'StatusUpdate' } & Pick<GraphQLTypes.StatusUpdate, 'title'>
                      >;
                    }
                >;
              }
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const GetQuotesDocument = /* #__PURE__ */ gql`
  query getQuotes($accountIds: [String], $order: SortInput, $after: String, $before: String, $first: Int, $last: Int) {
    bookings(
      onlyQuotes: true
      customers: $accountIds
      order: $order
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        zencargoReference
        clientReference
        modeOfTransport
        calculatedInfo {
          cargoSummary {
            highestRiskLevel
            label
            totalValueOfGoods {
              currency
              value
            }
            type
          }
        }
        consigneeLabel
        consignorLabel
        carriage {
          portOfDestination {
            name
          }
          portOfLoad {
            name
          }
          vesselName
        }
        estimatedArrival {
          date
          timeZone
        }
        estimatedDeparture {
          date
          timeZone
        }
        networksOrigin {
          id
          label {
            long
            short
          }
        }
        networksDestination {
          id
          label {
            long
            short
          }
        }
        activeQuoteOptionsCount
        activatedQuoteOptionsCount
        incoterms {
          value
        }
        issues {
          category
          severity
          state
          title
        }
        purchaseOrderReferences(first: 10) {
          nodes {
            orderReferenceNumber
            id
          }
          totalCount
        }
        stage {
          description
          value
          statusUpdate {
            title
          }
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetQuotesQuery__
 *
 * To run a query within a React component, call `useGetQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      order: // value for 'order'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetQuotesQuery(baseOptions?: Apollo.QueryHookOptions<GetQuotesQueryResult, GetQuotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetQuotesQueryResult, GetQuotesQueryVariables>(GetQuotesDocument, options);
}
export function useGetQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotesQueryResult, GetQuotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetQuotesQueryResult, GetQuotesQueryVariables>(GetQuotesDocument, options);
}
export type GetQuotesQueryHookResult = ReturnType<typeof useGetQuotesQuery>;
export type GetQuotesLazyQueryHookResult = ReturnType<typeof useGetQuotesLazyQuery>;
