import { FC, useState } from 'react';

import LoadingMask from '@zen/Components/LoadingMask';
import { Dialog } from '@zen/DesignSystem';
import { useArchiveBookingMutation } from '@zen/Shipment/ShipmentDetailsPage/ArchiveShipment/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import ActionHandle from './ActionHandle';

interface Props {
  accountId: string;
  zencargoReference: string;
}

const ActionHandleContainer: FC<Props> = ({ zencargoReference, accountId }) => {
  const { addError, addSuccess } = useNotification();
  const [isLoadingMaskVisible, setIsLoadingMaskVisible] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [archiveBooking] = useArchiveBookingMutation();

  const handleArchiveBooking = async (): Promise<void> => {
    setShowConfirmationModal(false);
    setIsLoadingMaskVisible(true);

    await performMutation({
      mutationFn: () =>
        archiveBooking({
          variables: {
            input: {
              zencargoReference
            }
          },
          awaitRefetchQueries: true,
          refetchQueries: ['getOperationsRoadShipments']
        }),
      onError: addError,
      onSuccess: () => {
        addSuccess('Shipment has been archived');
      }
    });

    setIsLoadingMaskVisible(false);
  };

  return (
    <>
      {isLoadingMaskVisible && <LoadingMask />}
      <ActionHandle
        accountId={accountId}
        onArchiveBooking={() => setShowConfirmationModal(true)}
        zencargoReference={zencargoReference}
      />
      <Dialog
        buttonVariant="danger"
        confirmLabel="Archive shipment"
        header="Archive shipment"
        isOpen={showConfirmationModal}
        message="Are you sure you want to archive this shipment?"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleArchiveBooking}
      />
    </>
  );
};

export default ActionHandleContainer;
