import { createContext, ReactNode, useContext } from 'react';

import type { DatePickerSize } from '../types';

interface DatePickerContextValues {
  disabledDayTooltip: ReactNode;
  isDisabled: (date: string) => boolean;
  isInDisplayedMonth: (date: string) => boolean;
  isInitialHighlighted: (date: string) => boolean;
  isSelected: (date: string) => boolean;
  isSuggestedHighlighted: (date: string) => boolean;
  onChange: (date: string) => void;
  onMonthChange: (date: string) => void;
  size: DatePickerSize;
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
const DatePickerContext = createContext<DatePickerContextValues>(undefined);

const useDatePickerContext = () => useContext<DatePickerContextValues>(DatePickerContext);

export { DatePickerContext, useDatePickerContext };
