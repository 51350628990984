import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateRoadShipmentMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateRoadShipmentInput;
}>;

export type UpdateRoadShipmentMutationResult = { __typename?: 'Mutation' } & {
  bookingUpdateRoadShipment?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateRoadShipmentPayload' } & {
      booking?: GraphQLTypes.Maybe<{ __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'>>;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const UpdateRoadShipmentDocument = /* #__PURE__ */ gql`
  mutation updateRoadShipment($input: UpdateRoadShipmentInput!) {
    bookingUpdateRoadShipment(input: $input) {
      booking {
        zencargoReference
      }
      errors {
        message
      }
    }
  }
`;

/**
 * __useUpdateRoadShipmentMutation__
 *
 * To run a mutation, you first call `useUpdateRoadShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoadShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoadShipmentMutation, { data, loading, error }] = useUpdateRoadShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoadShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRoadShipmentMutationResult, UpdateRoadShipmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateRoadShipmentMutationResult, UpdateRoadShipmentMutationVariables>(
    UpdateRoadShipmentDocument,
    options
  );
}
export type UpdateRoadShipmentMutationHookResult = ReturnType<typeof useUpdateRoadShipmentMutation>;
