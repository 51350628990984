import type { CSSProperties } from 'react';
import type { components } from 'react-select';

import { Icon } from '@zen/DesignSystem';

const ClearIndicator: typeof components.ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps }
  } = props;

  const { padding, ...defaultStyles } = getStyles('clearIndicator', props) as CSSProperties;

  return (
    <div {...restInnerProps} ref={ref} style={defaultStyles}>
      <Icon className="mr-3 text-grey-light text-base cursor-pointer hover:text-grey-base" icon="zicon-close" />
    </div>
  );
};

export default ClearIndicator;
