import type { FC } from 'react';

const DetailsCardDetailItem: FC = ({ children }) => {
  return (
    <div className="py-1 text-xs text-grey-dark bg-grey-lightest" data-testid="details-card-detail-item">
      {children}
    </div>
  );
};

export default DetailsCardDetailItem;
