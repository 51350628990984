import cx from 'classnames';
import type { ReactNode } from 'react';

interface LabelProps {
  children: ReactNode | string;
  className?: string;
  inline?: boolean;
}

const Label = ({ className, children, inline }: LabelProps) => {
  const classNames = cx(
    {
      'mb-3': !inline,
      'mr-3': inline
    },
    'flex',
    'text-sm',
    'text-grey-dark',
    className
  );

  return <label className={classNames}>{children}</label>;
};

export default Label;
