import { MultiSelect, MultiSelectProps } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import FormField, { FormFieldProps } from '../FormField';
import type { FormInstance } from '../types';

type Props<T> = MultiSelectProps<T> & FormFieldProps;

const FormMultiSelect = <T extends Nullable<{}>>(props: Props<T>) => {
  const { isSearchable = true, options, className, ...rest } = props;

  return (
    <FormField {...props}>
      {(field: { name: string; value: T[] }, form: FormInstance<unknown>) => {
        const handleChange = (values: T[]): void => form.setFieldValue(field.name, values);

        return (
          <div data-testid="multi-select">
            <MultiSelect
              {...rest}
              {...field}
              isSearchable={isSearchable}
              onChange={handleChange}
              options={options}
              value={field.value}
            />
          </div>
        );
      }}
    </FormField>
  );
};

export default FormMultiSelect;
