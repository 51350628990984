import type { FC } from 'react';
import { useHistory } from 'react-router-dom';

import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import { Button } from '@zen/DesignSystem';
import bookingRequestRoutes from '@zen/Routes/bookingRequests';
import shipmentRoutes from '@zen/Routes/shipments';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useBookingRequestsRevertRejectionMutation } from './graphql';

interface Props {
  zencargoReference: string;
}

const BookingResubmitForApprovalButton: FC<Props> = ({ zencargoReference }) => {
  const [revertRejection] = useBookingRequestsRevertRejectionMutation();
  const { addError, addSuccess } = useNotification();
  const { push } = useHistory();

  const handleResubmitBooking = async (customerApprovalRequired: boolean): Promise<void> => {
    const successMessage: string = customerApprovalRequired ? 'Booking request saved and sent for approval' : 'Booking saved';
    const url: string = customerApprovalRequired
      ? bookingRequestRoutes.readyForApproval.getUrl()
      : shipmentRoutes.shipmentIndex.getUrl();

    await performMutation({
      mutationFn: () =>
        revertRejection({
          variables: {
            input: {
              customerApprovalRequired,
              zencargoReference
            }
          }
        }),
      onError: () => {
        addError('Error resubmitting the booking');
      },
      onSuccess: () => {
        addSuccess(successMessage);
        push(url);
      }
    });
  };

  return (
    <WithConfirmationModal
      cancelLabel="Approval not needed"
      closeOnClickAway={true}
      confirmLabel="Send for approval"
      header="Send for customer approval"
      message="Does this booking request need to be reviewed and approved by the customer?"
      onClose={() => handleResubmitBooking(false)}
      onConfirm={() => handleResubmitBooking(true)}
    >
      <Button>Submit booking</Button>
    </WithConfirmationModal>
  );
};

export default BookingResubmitForApprovalButton;
