import type { PermittedParties, PermittedParty } from '@zen/ActivityFeed';
import type { NetworksOrgLoc } from '@zen/Networks/types';
import { AuthorizationResult, Role } from '@zen/Networks/types';

export interface PermittedRoles {
  manufacturers: NetworksOrgLoc[];
  organisationName: string;
  originAgent: NetworksOrgLoc;
  permissions: {
    canSendTextMessageToAgentForwarder: AuthorizationResult;
    canSendTextMessageToCustomerUser: AuthorizationResult;
    canSendTextMessageToManufacturer: AuthorizationResult;
  };
}

export default function createPermittedParties(permittedRoles: PermittedRoles, userRole?: Role): PermittedParties {
  const permittedParties: PermittedParties = {};

  const { manufacturers, originAgent, organisationName, permissions } = permittedRoles;

  const {
    canSendTextMessageToAgentForwarder: { value: toAgent },
    canSendTextMessageToCustomerUser: { value: toCustomer },
    canSendTextMessageToManufacturer: { value: toManufacturer }
  } = permissions;

  if (toCustomer && organisationName && userRole !== Role.CUSTOMER_USER) {
    permittedParties.customerUser = {
      role: Role.CUSTOMER_USER,
      organisationName
    };
  }

  if (toAgent && originAgent && userRole !== Role.AGENT_FORWARDER) {
    permittedParties.agentForwarder = {
      role: Role.AGENT_FORWARDER,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
      organisationName: originAgent.organisation.name
    };
  }

  if (toManufacturer && manufacturers && userRole !== Role.MANUFACTURER) {
    permittedParties.manufacturer = manufacturers.map(
      (item): PermittedParty => ({
        role: Role.MANUFACTURER,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
        organisationName: item.organisation.name
      })
    );
  }

  permittedParties.forwarder = {
    role: Role.ADMIN,
    organisationName: 'Zencargo'
  };

  return permittedParties;
}
