import type { Option } from '@zen/DesignSystem';
import { cargoTypeLabelMapping, VehicleTypeEnum } from '@zen/types';

export const commonCargoOptions: Option<VehicleTypeEnum>[] = [
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED],
    value: VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED
  },
  {
    value: VehicleTypeEnum.TRAILER_13_6_M_BOX,
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_13_6_M_BOX]
  },
  {
    value: VehicleTypeEnum.VAN_BOX,
    label: cargoTypeLabelMapping[VehicleTypeEnum.VAN_BOX]
  }
];

export const otherCargoOptions: Option<VehicleTypeEnum>[] = [
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER],
    value: VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF],
    value: VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_18_T_BOX],
    value: VehicleTypeEnum.TRAILER_18_T_BOX
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED],
    value: VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF],
    value: VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_7_5_T_BOX],
    value: VehicleTypeEnum.TRAILER_7_5_T_BOX
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED],
    value: VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF],
    value: VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.VAN_CURTAIN_SIDED],
    value: VehicleTypeEnum.VAN_CURTAIN_SIDED
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.VAN_SLIDING_ROOF],
    value: VehicleTypeEnum.VAN_SLIDING_ROOF
  }
];
