import { useAllPaginatedResults } from '@zen/utils/hooks/pagination';

import {
  ShipmentDelayReasonOptionsDocument,
  ShipmentDelayReasonOptionsQueryResult,
  ShipmentDelayReasonOptionsQueryVariables
} from '../graphql';
import type { ShipmentDelayReasonOption } from '../types';

export const useShipmentDelayReasonOptions = (variables: ShipmentDelayReasonOptionsQueryVariables = {}) => {
  return useAllPaginatedResults<
    ShipmentDelayReasonOptionsQueryResult,
    ShipmentDelayReasonOptionsQueryVariables,
    ShipmentDelayReasonOption
  >({
    document: ShipmentDelayReasonOptionsDocument,
    responsePath: 'shipmentDelayReasons',
    variables
  });
};
