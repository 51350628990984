import { get } from 'lodash';
import { Link } from 'react-router-dom';

import ValueIndicator from '@zen/Components/ValueIndicator';
import type { TableColumn } from '@zen/DesignSystem';
import { productPathPrefix } from '@zen/Products/ProductsContainer';
import { formatDate } from '@zen/utils/dateTime';
import { formatCurrency, formatNumber } from '@zen/utils/formatting';

import type { ProductIntake } from '../types';
import { ProductsColumnEnum } from '../types';

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'pathname' implicitly has an 'any'... Remove this comment to see the full error message
export const getColumns = ({ pathname, search }): TableColumn<ProductIntake>[] => [
  {
    key: 'product',
    title: 'Product',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '({ id, name, skuCode }: ProductIntake['produ... Remove this comment to see the full error message
    render: ({ id, name, skuCode }: ProductIntake['product']) => (
      <>
        <div className="mb-2 truncate">
          <Link
            className="text-base text-navy-dark font-bold hover:underline"
            to={{
              pathname: `${productPathPrefix}/${id}/po`,
              search,
              state: {
                previousUrl: `${pathname}${search}`
              }
            }}
          >
            {name}
          </Link>
        </div>
        <div className="grey-base text-sm truncate">{skuCode}</div>
      </>
    ),
    width: '30rem'
  },
  {
    key: ProductsColumnEnum.QUANTITYPLANNED,
    title: 'Qty planned',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: ProductIntake[ProductsColumnEnum.QUA... Remove this comment to see the full error message
    render: (value: ProductIntake[ProductsColumnEnum.QUANTITYPLANNED]) => <>{formatNumber(value)}</>
  },
  {
    key: ProductsColumnEnum.QUANTITYARRIVING,
    title: 'Qty arriving',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: ProductIntake[ProductsColumnEnum.QUA... Remove this comment to see the full error message
    render: (value: ProductIntake[ProductsColumnEnum.QUANTITYARRIVING], { quantityPlanned }: ProductIntake) => (
      <ValueIndicator comparedValue={quantityPlanned} value={value} />
    )
  },
  {
    key: ProductsColumnEnum.VALUEPLANNED,
    title: 'Value planned',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: ProductIntake[ProductsColumnEnum.VAL... Remove this comment to see the full error message
    render: (value: ProductIntake[ProductsColumnEnum.VALUEPLANNED]) =>
      // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
      get(value, 'value') ? formatCurrency(value.currency, value.value, 'symbol', true) : '-',
    sortable: false
  },
  {
    key: ProductsColumnEnum.VALUEARRIVING,
    title: 'Value arriving',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(valueArriving: ProductIntake[ProductsColumn... Remove this comment to see the full error message
    render: (valueArriving: ProductIntake[ProductsColumnEnum.VALUEARRIVING], { valuePlanned }: ProductIntake) => {
      if (!get(valueArriving, 'value') || !get(valuePlanned, 'value')) {
        return '-';
      }

      // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
      return <ValueIndicator comparedValue={valuePlanned.value} currency={valueArriving.currency} value={valueArriving.value} />;
    },
    sortable: false
  },
  {
    key: ProductsColumnEnum.LASTUPDATED,
    title: 'Last updated',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: ProductIntake[ProductsColumnEnum.LAS... Remove this comment to see the full error message
    render: (value: ProductIntake[ProductsColumnEnum.LASTUPDATED]) => (
      <span className="text-grey-base">{value ? formatDate(value) : '-'}</span>
    )
  }
];
