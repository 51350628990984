import type { FC, ReactChild } from 'react';

import { Tooltip } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

import Icon from '../../Icon';
import StyledButton, { StyledButtonProps } from '../StyledButton';
import { getStyles } from './helpers';
import type { IconButtonSize } from './types';

interface Props extends StyledButtonProps {
  className?: string;
  icon: IconName;
  size?: IconButtonSize;
  title?: string;
}

const IconButton: FC<Props> = (props) => {
  const { className = '', disabled = false, icon, onClick, size = 'large', title, type, ...rest } = props;

  const { buttonClassnames, iconClassnames } = getStyles(size);

  const button: ReactChild = (
    <StyledButton className={`${buttonClassnames} ${className}`} disabled={disabled} onClick={onClick} type={type} {...rest}>
      <Icon className={iconClassnames} icon={icon} />
    </StyledButton>
  );

  if (!title) {
    return button;
  }

  return (
    <Tooltip placement="bottom" tooltipContent={title}>
      {button}
    </Tooltip>
  );
};

export type { Props as IconButtonProps };

export default IconButton;
