import type { FC, ReactNode } from 'react';

import { Icon } from '@zen/DesignSystem';

import type { StageIndicatorType } from './types';

interface Props {
  type: StageIndicatorType;
}

const StageIndicator: FC<Props> = ({ type }) => {
  const indicators: Record<StageIndicatorType, ReactNode> = {
    completed: <Icon className="text-green-base text-base" icon="zicon-tickoval" />,
    warning: <Icon className="text-orange-base text-base" icon="zicon-warning-triangle-inverse" />,
    future: <div className="h-4 w-4 rounded-full bg-white border border-solid border-grey-light" data-testid="empty-circle" />
  };

  return <div className="bg-white top-px relative flex items-center justify-center">{indicators[type]}</div>;
};

export default StageIndicator;
