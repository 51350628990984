import pluralize from 'pluralize';
import type { FC } from 'react';

import type { Document } from '@zen/Components/Documents/types';
import QueryHandler from '@zen/Components/QueryHandler';
import { documentRoutes } from '@zen/Routes/routesConfig';
import {
  useGetBookingDocumentsQuery,
  useUploadBookingDocumentMutation
} from '@zen/Shipments/Documents/DocumentsContainer/graphql';
import { getDocuments } from '@zen/Shipments/Documents/DocumentsContainer/helpers';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Undefinable } from '@zen/utils/typescript';

import DocumentActions from '../DocumentActions';

interface Props {
  accountId: string;
  showDocumentCreation?: boolean;
  zencargoReference: string;
}
const DocumentActionsContainer: FC<Props> = ({ zencargoReference, accountId, showDocumentCreation }) => {
  const { addError, addSuccess } = useNotification();
  const [uploadBookingDocument] = useUploadBookingDocumentMutation();

  const {
    data: documentsData,
    loading: documentsLoading,
    error: documentsError
  } = useGetBookingDocumentsQuery({
    variables: { zencargoReference },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const handleAdd = async (docs: Document[]): Promise<void> => {
    for (const document of docs) {
      performMutation({
        mutationFn: () => {
          return uploadBookingDocument({
            variables: {
              input: {
                accountUuid: accountId,
                zencargoReference,
                description: document.description,
                bookingDocumentTypeName: document.documentType,
                id: document.uuid,
                permissions: document.permissions,
                attachmentId: document.attachmentId
              }
            },
            refetchQueries: ['getBookingDocuments', 'getIssuesAndActions']
          });
        },
        onError: () => addError(),
        onSuccess: () => {
          if (docs) {
            addSuccess(`We have received your ${pluralize('document', docs.length)}`);
          }
        }
      });
    }
  };

  const documentsCreationLink: string = documentRoutes.documentCreate.getUrl(accountId, zencargoReference);
  const documents: Undefinable<Document[]> = getDocuments(documentsData);

  return (
    <QueryHandler data={documentsData} error={!!documentsError} isLoading={documentsLoading}>
      {() => {
        return (
          <DocumentActions
            createDocumentsLink={documentsCreationLink}
            documents={documents}
            onAdd={handleAdd}
            showDocumentCreation={showDocumentCreation}
          />
        );
      }}
    </QueryHandler>
  );
};

export default DocumentActionsContainer;
