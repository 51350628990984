import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTerminalsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  query: GraphQLTypes.Scalars['String'];
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetTerminalsQueryResult = { __typename?: 'Query' } & {
  terminals?: GraphQLTypes.Maybe<
    { __typename?: 'TerminalConnection' } & Pick<GraphQLTypes.TerminalConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'Terminal' } & Pick<
                GraphQLTypes.Terminal,
                'id' | 'unlocode' | 'name' | 'iata' | 'subDiv' | 'location' | 'terminalTypes'
              >
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const GetTerminalsQueryDocument = /* #__PURE__ */ gql`
  query getTerminalsQuery($after: String, $before: String, $first: Int, $last: Int, $query: String!, $order: SortInput) {
    terminals(after: $after, before: $before, first: $first, last: $last, query: $query, order: $order) {
      nodes {
        id
        unlocode
        name
        iata
        subDiv
        location
        terminalTypes
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetTerminalsQuery__
 *
 * To run a query within a React component, call `useGetTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      query: // value for 'query'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetTerminalsQuery(baseOptions: Apollo.QueryHookOptions<GetTerminalsQueryResult, GetTerminalsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTerminalsQueryResult, GetTerminalsQueryVariables>(GetTerminalsQueryDocument, options);
}
export function useGetTerminalsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalsQueryResult, GetTerminalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTerminalsQueryResult, GetTerminalsQueryVariables>(GetTerminalsQueryDocument, options);
}
export type GetTerminalsQueryHookResult = ReturnType<typeof useGetTerminalsQuery>;
export type GetTerminalsQueryLazyQueryHookResult = ReturnType<typeof useGetTerminalsQueryLazyQuery>;
