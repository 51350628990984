import type { FC } from 'react';

import DataQualityIssue from '@zen/Components/DataQualityIssue';
import type { Product } from '@zen/Products/types';

import NoIssues from '../IssueList/NoIssues';

interface Props {
  products: Pick<Product, 'id' | 'name' | 'skuCode'>[];
}

const ProductIssues: FC<Props> = ({ products }) => {
  if (!products.length) {
    return <NoIssues />;
  }

  return (
    <div>
      {products.map(({ id, skuCode, name }, index) => {
        const link = {
          label: 'Edit product',
          url: `/dashboard/products/${id}/edit`
        };

        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
        return <DataQualityIssue key={index} issues={['Missing sell price']} link={link} tagline={skuCode} text={name} />;
      })}
    </div>
  );
};

export default ProductIssues;
