import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworksCreateOrganisationLocationMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateOrganisationLocationInput;
}>;

export type NetworksCreateOrganisationLocationMutationResult = { __typename?: 'Mutation' } & {
  networksCreateOrganisationLocation?: GraphQLTypes.Maybe<
    { __typename?: 'CreateOrganisationLocationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
      orgLoc?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id' | 'timeZone'> & {
            organisation?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksOrgLocOrganisation' } & Pick<GraphQLTypes.NetworksOrgLocOrganisation, 'id' | 'name'>
            >;
            label?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
            >;
            location?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
                  address?: GraphQLTypes.Maybe<
                    { __typename?: 'Address' } & Pick<
                      GraphQLTypes.Address,
                      'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                    > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>> }
                  >;
                  geolocation?: GraphQLTypes.Maybe<
                    { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                  >;
                }
            >;
            contact?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksOrgLocContact' } & Pick<
                GraphQLTypes.NetworksOrgLocContact,
                'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
              >
            >;
          }
      >;
      location?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLocation' } & Pick<GraphQLTypes.NetworksLocation, 'id'>>;
    }
  >;
};

export const NetworksCreateOrganisationLocationDocument = /* #__PURE__ */ gql`
  mutation networksCreateOrganisationLocation($input: CreateOrganisationLocationInput!) {
    networksCreateOrganisationLocation(input: $input) {
      errors {
        path
        message
      }
      orgLoc {
        id
        organisation {
          id
          name
        }
        label {
          long
          short
        }
        location {
          id
          name
          address {
            street
            city
            country {
              name
              code
            }
            postalCodeOrZip
            countyOrState
          }
          geolocation {
            latitude
            longitude
          }
        }
        contact {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        timeZone
      }
      location {
        id
      }
    }
  }
`;

/**
 * __useNetworksCreateOrganisationLocationMutation__
 *
 * To run a mutation, you first call `useNetworksCreateOrganisationLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworksCreateOrganisationLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networksCreateOrganisationLocationMutation, { data, loading, error }] = useNetworksCreateOrganisationLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNetworksCreateOrganisationLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NetworksCreateOrganisationLocationMutationResult,
    NetworksCreateOrganisationLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    NetworksCreateOrganisationLocationMutationResult,
    NetworksCreateOrganisationLocationMutationVariables
  >(NetworksCreateOrganisationLocationDocument, options);
}
export type NetworksCreateOrganisationLocationMutationHookResult = ReturnType<
  typeof useNetworksCreateOrganisationLocationMutation
>;
