import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCargoOptionDetailsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetCargoOptionDetailsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<
            GraphQLTypes.Booking,
            'collectionScheduleRequired' | 'deliveryScheduleRequired' | 'modeOfTransport' | 'zencargoReference'
          > & {
              cargo?: GraphQLTypes.Maybe<{ __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'>>;
              canManageCollectionAndDeliveryScheduling: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
            }
        >
      >
    >;
  };
};

export const GetCargoOptionDetailsQueryDocument = /* #__PURE__ */ gql`
  query getCargoOptionDetailsQuery($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        cargo {
          mode
        }
        canManageCollectionAndDeliveryScheduling {
          value
        }
        collectionScheduleRequired
        deliveryScheduleRequired
        modeOfTransport
        zencargoReference
      }
    }
  }
`;

/**
 * __useGetCargoOptionDetailsQuery__
 *
 * To run a query within a React component, call `useGetCargoOptionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCargoOptionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCargoOptionDetailsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetCargoOptionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCargoOptionDetailsQueryResult, GetCargoOptionDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCargoOptionDetailsQueryResult, GetCargoOptionDetailsQueryVariables>(
    GetCargoOptionDetailsQueryDocument,
    options
  );
}
export function useGetCargoOptionDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCargoOptionDetailsQueryResult, GetCargoOptionDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCargoOptionDetailsQueryResult, GetCargoOptionDetailsQueryVariables>(
    GetCargoOptionDetailsQueryDocument,
    options
  );
}
export type GetCargoOptionDetailsQueryHookResult = ReturnType<typeof useGetCargoOptionDetailsQuery>;
export type GetCargoOptionDetailsQueryLazyQueryHookResult = ReturnType<typeof useGetCargoOptionDetailsQueryLazyQuery>;
