import type { FC } from 'react';

import { Icon, Tooltip } from '@zen/DesignSystem';

interface Props {
  isCurrentMainContact: boolean;
}

const MainContactIndicator: FC<Props> = ({ isCurrentMainContact }) => {
  const tooltipMessage: string = isCurrentMainContact
    ? 'This is the main contact for this location'
    : 'This contact is a main contact in at least one location';

  return (
    <Tooltip tooltipContent={tooltipMessage}>
      <Icon className="text-xs text-azure-base mr-3" icon="zicon-star" />
    </Tooltip>
  );
};

export default MainContactIndicator;
