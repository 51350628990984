import type { FC, ReactChild } from 'react';

interface Props {
  children: ReactChild;
}

const CargoFormWrapper: FC<Props> = ({ children }) => {
  return <div className="mt-20 px-12 pt-12 pb-6 bg-grey-lightest relative">{children}</div>;
};

export default CargoFormWrapper;
