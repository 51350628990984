import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookingCoordinatorsQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type BookingCoordinatorsQueryResult = { __typename?: 'Query' } & {
  bookingManagers: Array<{ __typename?: 'BookingManager' } & Pick<GraphQLTypes.BookingManager, 'id' | 'lastName' | 'firstName'>>;
};

export const BookingCoordinatorsDocument = /* #__PURE__ */ gql`
  query bookingCoordinators {
    bookingManagers {
      id
      lastName
      firstName
    }
  }
`;

/**
 * __useBookingCoordinatorsQuery__
 *
 * To run a query within a React component, call `useBookingCoordinatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingCoordinatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingCoordinatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingCoordinatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<BookingCoordinatorsQueryResult, BookingCoordinatorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BookingCoordinatorsQueryResult, BookingCoordinatorsQueryVariables>(BookingCoordinatorsDocument, options);
}
export function useBookingCoordinatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingCoordinatorsQueryResult, BookingCoordinatorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BookingCoordinatorsQueryResult, BookingCoordinatorsQueryVariables>(
    BookingCoordinatorsDocument,
    options
  );
}
export type BookingCoordinatorsQueryHookResult = ReturnType<typeof useBookingCoordinatorsQuery>;
export type BookingCoordinatorsLazyQueryHookResult = ReturnType<typeof useBookingCoordinatorsLazyQuery>;
