import * as Yup from 'yup';

import { LooseCargoTypeEnum } from '@zen/Cargo';
import type { Nullable } from '@zen/utils/typescript';

import { quantityValidator } from '../validation';

export const validationSchema = Yup.object().shape({
  vehicleType: Yup.string().nullable().required('Vehicle type is required.'),
  looseCargoType: Yup.string().nullable().required('Cargo type is required.'),
  dimensions: Yup.object().shape({
    length: Yup.number().nullable().positive('Must be greater than 0.'),
    width: Yup.number().nullable().positive('Must be greater than 0.'),
    height: Yup.number().nullable().positive('Must be greater than 0.')
  }),
  actualCbm: Yup.number().nullable().positive('Must be greater than 0.'),
  grossWeight: Yup.object().shape({
    value: Yup.number().nullable().positive('Must be greater than 0.')
  }),
  quantity: Yup.number().when('looseCargoType', {
    is: (val: Nullable<LooseCargoTypeEnum>) => val !== LooseCargoTypeEnum.ASSORTED_CARGO,
    then: quantityValidator()
  })
});
