import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: ReactNode;
  url?: string;
}

const ProductName: FC<Props> = ({ url, children }) => {
  const styles = cx({
    'font-bold': true,
    'hover:underline': url!!
  });

  const nameComponent = <span className={styles}>{children}</span>;

  if (url) {
    return <Link to={url}>{nameComponent}</Link>;
  }

  return nameComponent;
};

export default ProductName;
