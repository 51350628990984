import type { FC } from 'react';

import type { FormFieldProps } from '@zen/Components/Form/FormField';
import { modeOfTransportOptions } from '@zen/Shipment/helpers';

import { FormMultiSelect, FormSelect } from '..';

interface Props extends FormFieldProps {
  isMulti?: boolean;
}

const FormModeOfTransportSelect: FC<Props> = (props) => {
  const { isMulti } = props;

  if (isMulti) {
    return <FormMultiSelect {...props} options={modeOfTransportOptions} placeholder="Select mode of transport" />;
  }

  return <FormSelect {...props} options={modeOfTransportOptions} placeholder="Select mode of transport" />;
};

export default FormModeOfTransportSelect;
