import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RemoveDeliveryScheduleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.RemoveDeliveryScheduleInput;
}>;

export type RemoveDeliveryScheduleMutationResult = { __typename?: 'Mutation' } & {
  bookingCollectionAndDeliveryRemoveDeliverySchedule?: GraphQLTypes.Maybe<
    { __typename?: 'RemoveDeliverySchedulePayload' } & Pick<GraphQLTypes.RemoveDeliverySchedulePayload, 'clientMutationId'>
  >;
};

export const RemoveDeliveryScheduleDocument = /* #__PURE__ */ gql`
  mutation removeDeliverySchedule($input: RemoveDeliveryScheduleInput!) {
    bookingCollectionAndDeliveryRemoveDeliverySchedule(input: $input) {
      clientMutationId
    }
  }
`;

/**
 * __useRemoveDeliveryScheduleMutation__
 *
 * To run a mutation, you first call `useRemoveDeliveryScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeliveryScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeliveryScheduleMutation, { data, loading, error }] = useRemoveDeliveryScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDeliveryScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveDeliveryScheduleMutationResult, RemoveDeliveryScheduleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RemoveDeliveryScheduleMutationResult, RemoveDeliveryScheduleMutationVariables>(
    RemoveDeliveryScheduleDocument,
    options
  );
}
export type RemoveDeliveryScheduleMutationHookResult = ReturnType<typeof useRemoveDeliveryScheduleMutation>;
