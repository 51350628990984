import type { FC, ReactNode } from 'react';

import type { NewCargoType } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

import CargoOptions, { CargoOptionsType } from '../CargoOptions';
import CargoTypeImage from '../CargoTypeImage';

interface Props {
  cargoOptions: CargoOptionsType;
  cargoType: Optional<NewCargoType>;
  isNewCargo?: boolean;
  title: ReactNode;
}

const CargoDetails: FC<Props> = ({ cargoOptions, cargoType, children, title, isNewCargo = false }) => {
  return (
    <div className="relative pt-8 pr-4 pb-4 mt-20 pl-8">
      {cargoType && <CargoTypeImage cargoType={cargoType} className="absolute h-16 -top-10" isNewCargo={isNewCargo} />}
      <div className="flex justify-between mb-4">
        <div className="font-bold text-lg leading-normal text-grey-dark">{title}</div>
        <CargoOptions {...cargoOptions} />
      </div>
      {children}
    </div>
  );
};

export default CargoDetails;
