import type { FC, ReactElement } from 'react';

import type { CalculatedInfoCargoSummary } from '@zen/Cargo';
import CargoSummary from '@zen/Cargo/components/CargoSummary';
import { useCargoSummaryQuery, useCargoSummaryUpdatedSubscription } from '@zen/Cargo/graphql';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import { InlineEmptyState } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  showNoCargoMessage?: boolean;
  zencargoReference: string;
}

const CargoSummaryContainer: FC<Props> = ({ zencargoReference, showNoCargoMessage = true }) => {
  const {
    data: queryData,
    loading,
    error
  } = useCargoSummaryQuery({
    variables: { zencargoReference },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const { data: subscriptionData } = useCargoSummaryUpdatedSubscription({
    variables: { zencargoReference },
    fetchPolicy: 'no-cache'
  });

  const cargoSummaryFromSubscription: Optional<CalculatedInfoCargoSummary> = subscriptionData?.cargoSummaryUpdated;

  const renderNoCargoMessage = (): ReactElement => {
    if (!showNoCargoMessage) {
      return <div />;
    }

    return (
      <InlineEmptyState title="There are no cargo items are created for this booking.">
        Cargo are the list of goods being transported in the shipment.
      </InlineEmptyState>
    );
  };

  return (
    <QueryHandler
      data={queryData?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonLoading className="my-5" height={50} lighter={true} />}
    >
      {({ cargo, calculatedInfo }): ReactElement => {
        if (!cargo || !calculatedInfo || !cargo.consolidatedCargo || !cargo.mode || !calculatedInfo.cargoSummary) {
          return renderNoCargoMessage();
        }

        const { mode, consolidatedCargo: consolidatedItems } = cargo;
        const cargoSummaryFromQuery: Optional<CalculatedInfoCargoSummary> = calculatedInfo?.cargoSummary;
        const cargoSummary: Optional<CalculatedInfoCargoSummary> = cargoSummaryFromSubscription || cargoSummaryFromQuery;

        return <CargoSummary cargoMode={mode} consolidatedItems={consolidatedItems} summary={cargoSummary} />;
      }}
    </QueryHandler>
  );
};

export default CargoSummaryContainer;
