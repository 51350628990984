import FormField, { FormFieldProps } from '@zen/Components/Form/FormField';

import type CollectionRisk from '../Details/ScheduleActions/ScheduleCollectionForm/CollectionRisk';
import type DeliveryRisk from '../Details/ScheduleActions/ScheduleDeliveryForm/DeliveryRisk';
import { AmPm, SpecificTime } from '../types';
import TimedConsignment from './TimedConsignment';

interface FormTimeConsigmentProps extends FormFieldProps {
  defaultSpecificTime?: SpecificTime;
  riskStrategy: CollectionRisk | DeliveryRisk;
}

const FormTimeConsigment = ({
  defaultSpecificTime = { hour: 9, ampm: AmPm.AM },
  riskStrategy,
  hideLabel = true,
  ...rest
}: FormTimeConsigmentProps) => {
  return (
    <FormField {...rest} hideLabel={hideLabel}>
      {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type. */}
      {(field, form) => (
        <TimedConsignment
          defaultSpecificTime={defaultSpecificTime}
          onTimeChange={(collectionTime) => form.setFieldValue(field.name, collectionTime)}
          timeRisk={riskStrategy.timeRisk}
          value={field.value}
        />
      )}
    </FormField>
  );
};

export default FormTimeConsigment;
