import { FC, useState } from 'react';

import NewAccrualModal from '@zen/Accruals/NewAccrualModal';
import type { AccrualTemplate } from '@zen/Accruals/types';
import { Button } from '@zen/DesignSystem';
import { useCreateQuoteOptionMutation } from '@zen/Quotes/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

interface Props {
  className?: string;
  onSuccess?: () => void;
  zencargoReference: string;
}

const NewQuoteOption: FC<Props> = ({ zencargoReference, onSuccess, className }) => {
  const [showAccrualTemplateModal, setShowAccrualTemplateModal] = useState<boolean>(false);
  const { addSuccess, addError } = useNotification();

  const handleSuccess = (): void => {
    onSuccess?.();
    addSuccess('Quote option saved.');
    setShowAccrualTemplateModal(false);
  };

  const [createQuoteOption] = useCreateQuoteOptionMutation();

  const handleAddNewQuoteFromTemplate = (): void => {
    setShowAccrualTemplateModal(true);
  };

  const handleAccrualTemplatePick = (template?: AccrualTemplate) => {
    performMutation({
      mutationFn: () =>
        createQuoteOption({
          variables: {
            input: {
              description: '',
              zencargoReference,
              ...(template?.id ? { templateId: template.id } : null)
            }
          },
          refetchQueries: ['getQuoteOptionsDetails']
        }),
      onError: () => addError('Unable to save quote opton'),
      onSuccess: handleSuccess
    });
  };

  return (
    <>
      <Button
        className={className}
        data-testid="new-quote-option"
        iconLeft="zicon-add"
        onClick={handleAddNewQuoteFromTemplate}
        variant="secondary"
      >
        Add new quote option
      </Button>
      <NewAccrualModal
        isOpen={showAccrualTemplateModal}
        onClose={() => setShowAccrualTemplateModal(false)}
        onCreate={handleAccrualTemplatePick}
        onTemplatePick={handleAccrualTemplatePick}
        title="Create new quote option with blank accrual or select one of the templates below"
      />
    </>
  );
};

export default NewQuoteOption;
