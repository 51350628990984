import type { RoutesConfig } from './types';

export const OPS_SETTINGS_PREFIX = '/dashboard/ops-settings';

interface OpsSettingsRoutes extends RoutesConfig {
  actionItemTypes: {
    getUrl: () => string;
    path: string;
  };
  agentAssingmentRulesCreate: {
    getUrl: () => string;
    path: string;
  };
  agentAssingmentRulesIndex: {
    getUrl: () => string;
    path: string;
  };
  agentAssingmentRulesUpdate: {
    getUrl: (ruleId: string) => string;
    path: string;
  };
  assignmentRulesCreate: {
    getUrl: () => string;
    path: string;
  };
  assignmentRulesEdit: {
    getUrl: (ruleId: string) => string;
    path: string;
  };
  assignmentRulesIndex: {
    getUrl: () => string;
    path: string;
  };
  bookingDocumentTypes: {
    getUrl: () => string;
    path: string;
  };
  chargeTypesCreate: {
    getUrl: () => string;
    path: string;
  };
  chargeTypesEdit: {
    getUrl: (chargeTypeId: string) => string;
    path: string;
  };
  chargeTypesIndex: {
    getUrl: () => string;
    path: string;
  };
  contactsCreate: {
    getUrl: () => string;
    path: string;
  };
  contactsEdit: {
    getUrl: (contactId: string) => string;
    path: string;
  };
  contactsIndex: {
    getUrl: () => string;
    path: string;
  };
  contractIdsIndex: {
    getUrl: () => string;
    path: string;
  };
  delayReasons: {
    getUrl: () => string;
    path: string;
  };
  documentsInformationIndex: {
    getUrl: () => string;
    path: string;
  };
  manageAccrualsIndex: {
    getUrl: () => string;
    path: string;
  };
  manageCommodityCodesIndex: {
    getUrl: () => string;
    path: string;
  };
  opsSettingsIndex: {
    getUrl: () => string;
    path: string;
  };
  shipmentActionTypes: {
    getUrl: () => string;
    path: string;
  };
  workflowRuleCreate: {
    getUrl: (ruleDefinitionId: string) => string;
    path: string;
  };
  workflowRuleDefinitionCreate: {
    getUrl: () => string;
    path: string;
  };
  workflowRuleDefinitionEdit: {
    getUrl: (ruleDefinitionId: string) => string;
    path: string;
  };
  workflowRuleEdit: {
    getUrl: (ruleId: string) => string;
    path: string;
  };
  workflowRulesIndex: {
    getUrl: () => string;
    path: string;
  };
}
const opsSettingsRoutes: OpsSettingsRoutes = {
  actionItemTypes: {
    path: `${OPS_SETTINGS_PREFIX}/action-item-types`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/action-item-types`
  },
  agentAssingmentRulesCreate: {
    path: `${OPS_SETTINGS_PREFIX}/agent-assignment-rules/new`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/agent-assignment-rules/new`
  },
  agentAssingmentRulesIndex: {
    path: `${OPS_SETTINGS_PREFIX}/agent-assignment-rules`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/agent-assignment-rules`
  },
  agentAssingmentRulesUpdate: {
    path: `${OPS_SETTINGS_PREFIX}/agent-assignment-rules/edit/:ruleId`,
    getUrl: (ruleId: string) => `${OPS_SETTINGS_PREFIX}/agent-assignment-rules/edit/${ruleId}`
  },
  assignmentRulesCreate: {
    path: `${OPS_SETTINGS_PREFIX}/assignment-rules/create`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/assignment-rules/create`
  },
  assignmentRulesEdit: {
    path: `${OPS_SETTINGS_PREFIX}/assignment-rules/edit/:ruleId`,
    getUrl: (ruleId: string) => `${OPS_SETTINGS_PREFIX}/assignment-rules/edit/${ruleId}`
  },
  assignmentRulesIndex: {
    path: `${OPS_SETTINGS_PREFIX}/assignment-rules`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/assignment-rules`
  },
  bookingDocumentTypes: {
    path: `${OPS_SETTINGS_PREFIX}/booking-document-types`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/booking-document-types`
  },
  chargeTypesCreate: {
    path: `${OPS_SETTINGS_PREFIX}/charge-types/create`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/charge-types/create`
  },
  chargeTypesEdit: {
    path: `${OPS_SETTINGS_PREFIX}/charge-types/edit/:chargeTypeId`,
    getUrl: (chargeTypeId: string) => `${OPS_SETTINGS_PREFIX}/charge-types/edit/${chargeTypeId}`
  },
  chargeTypesIndex: {
    path: `${OPS_SETTINGS_PREFIX}/charge-types`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/charge-types`
  },
  contactsCreate: {
    path: `${OPS_SETTINGS_PREFIX}/contacts/create`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/contacts/create`
  },
  contactsEdit: {
    path: `${OPS_SETTINGS_PREFIX}/contacts/edit/:contactId`,
    getUrl: (contactId: string) => `${OPS_SETTINGS_PREFIX}/contacts/edit/${contactId}`
  },
  contactsIndex: {
    path: `${OPS_SETTINGS_PREFIX}/contacts`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/contacts`
  },
  contractIdsIndex: {
    path: `${OPS_SETTINGS_PREFIX}/contract-id`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/contract-id`
  },
  delayReasons: {
    path: `${OPS_SETTINGS_PREFIX}/delay-reasons`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/delay-reasons`
  },
  documentsInformationIndex: {
    path: `${OPS_SETTINGS_PREFIX}/documents-information`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/documents-information`
  },
  manageAccrualsIndex: {
    path: `${OPS_SETTINGS_PREFIX}/manage-accruals`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/manage-accruals`
  },
  manageCommodityCodesIndex: {
    path: `${OPS_SETTINGS_PREFIX}/commodity-codes`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/commodity-codes`
  },
  opsSettingsIndex: {
    path: OPS_SETTINGS_PREFIX,
    getUrl: () => OPS_SETTINGS_PREFIX
  },
  shipmentActionTypes: {
    path: `${OPS_SETTINGS_PREFIX}/shipment-action-types`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/shipment-action-types`
  },
  workflowRuleCreate: {
    path: `${OPS_SETTINGS_PREFIX}/workflow-rules/create/:ruleDefinitionId`,
    getUrl: (ruleDefinitionId: string) => `${OPS_SETTINGS_PREFIX}/workflow-rules/create/${ruleDefinitionId}`
  },
  workflowRuleEdit: {
    path: `${OPS_SETTINGS_PREFIX}/workflow-rules/edit/:ruleId`,
    getUrl: (ruleId: string) => `${OPS_SETTINGS_PREFIX}/workflow-rules/edit/${ruleId}`
  },
  workflowRuleDefinitionCreate: {
    path: `${OPS_SETTINGS_PREFIX}/workflow-rules/workflow-rule-definition/create`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/workflow-rules/workflow-rule-definition/create`
  },
  workflowRuleDefinitionEdit: {
    path: `${OPS_SETTINGS_PREFIX}/workflow-rules/workflow-rule-definition/edit/:ruleDefinitionId`,
    getUrl: (ruleDefinitionId: string) =>
      `${OPS_SETTINGS_PREFIX}/workflow-rules/workflow-rule-definition/edit/${ruleDefinitionId}`
  },
  workflowRulesIndex: {
    path: `${OPS_SETTINGS_PREFIX}/workflow-rules`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/workflow-rules`
  }
};

export default opsSettingsRoutes;
