import { FC, useState } from 'react';

import type { ActionItemType } from '@zen/Dashboard/ActionItems/types';
import { Button } from '@zen/DesignSystem';

import ActionItemDocumentUpload from '../ActionItemDocumentUpload';

interface Props {
  accountUuid: string;
  actionItem: Pick<ActionItemType, 'name' | 'title' | 'dueDate'>;
  zencargoReference: string;
}

const ActionItemFileUpload: FC<Props> = ({ zencargoReference, actionItem, accountUuid }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      {!expanded && <Button onClick={() => setExpanded(true)}>Upload</Button>}
      {expanded && zencargoReference && (
        <div className="w-full">
          <ActionItemDocumentUpload
            accountId={accountUuid}
            onSelect={() => setExpanded(false)}
            selectedDocumentType={actionItem.name}
            zencargoReference={zencargoReference}
          />
          <Button className="mt-4" onClick={() => setExpanded(false)} variant="secondary">
            Cancel
          </Button>
        </div>
      )}
    </>
  );
};

export default ActionItemFileUpload;
