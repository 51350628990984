import type { FC, ReactNode } from 'react';

import { Container } from '@zen/DesignSystem';
import { IssueAssociatedObject } from '@zen/graphql/types.generated';
import AddIssueButton from '@zen/Issues/Issues/AddIssueButton';
import type { Issue } from '@zen/Issues/types';

import Issues from '../Issues';

interface Props {
  associatedObjectId?: string;
  associatedObjectReference: string;
  associatedType: IssueAssociatedObject;
  canEditIssueVisibility: boolean;
  canOpenIssue: boolean;
  issues: Issue[];
  onIssueChange: () => void;
}

const IssuesContainer: FC<Props> = (props) => {
  const {
    issues,
    associatedObjectReference,
    associatedObjectId,
    associatedType,
    canEditIssueVisibility,
    canOpenIssue,
    onIssueChange
  } = props;
  const showIssueButton: boolean = associatedType === IssueAssociatedObject.PURCHASE_ORDER;

  const action: ReactNode = canOpenIssue && showIssueButton && (
    <div className="flex justify-end mb-2">
      <AddIssueButton
        associatedObjectId={associatedObjectId}
        associatedObjectReference={associatedObjectReference}
        associatedType={associatedType}
        canEditIssueVisibility={canEditIssueVisibility}
        onIssueChange={onIssueChange}
      />
    </div>
  );

  return (
    <Container actions={action} title="Issues & Actions">
      <Issues associatedObjectReference={associatedObjectReference} issues={issues} onIssueChange={onIssueChange} />
    </Container>
  );
};

export type { Props as IssueContainerProps };
export default IssuesContainer;
