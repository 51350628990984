import { FC, useState } from 'react';

import BookingRequestApproveModal from '@zen/BookingRequests/BookingRequestApproveModal';
import { Switch } from '@zen/DesignSystem';

import type { BookingManagementPermissons } from '../../BookingManagement/types';

interface Props {
  isForwarderApproved: boolean;
  isLoading?: boolean;
  isPricingRequired: boolean;
  onForwarderSuccess: () => void;
  onForwarderToggleChange: (value: boolean) => void;
  onPricingRequiredToggle: (value: boolean) => void;
  permissions: BookingManagementPermissons;
  zencargoReference: string;
}

const BookingManagementSwitches: FC<Props> = (props) => {
  const {
    isForwarderApproved,
    isPricingRequired,
    onForwarderToggleChange,
    onForwarderSuccess,
    onPricingRequiredToggle,
    permissions,
    zencargoReference,
    isLoading = false
  } = props;
  const { canUpdateForwarderApproved, canUpdatePricingRequired, canViewForwarderApproved } = permissions;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleForwarderSwitcherChange = (value: boolean): void => {
    if (!isForwarderApproved) {
      setIsModalOpen(true);

      return;
    }
    onForwarderToggleChange(value);
  };

  return (
    <>
      {canViewForwarderApproved && (
        <>
          <div>
            <Switch
              className="mr-10"
              isChecked={isForwarderApproved}
              isDisabled={!canUpdateForwarderApproved}
              label="Zencargo approved"
              onChange={handleForwarderSwitcherChange}
            />
          </div>
          {isModalOpen && (
            <BookingRequestApproveModal
              forwarderApproved={isForwarderApproved}
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSuccess={onForwarderSuccess}
              zencargoReference={zencargoReference}
            />
          )}
        </>
      )}
      <div>
        <Switch
          isChecked={isPricingRequired}
          isDisabled={!canUpdatePricingRequired || isLoading}
          label="Pricing required"
          onChange={onPricingRequiredToggle}
        />
      </div>
    </>
  );
};

export default BookingManagementSwitches;
