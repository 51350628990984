import type { MenuItemType } from '@zen/DesignSystem';
import { isRoadShipment, ModeOfTransport } from '@zen/Shipments';

import type { MilestoneUpdateType } from '../../types';
import { VoyageMilestoneNameEnum, VoyageMilestoneTime } from '../../types';

const primaryMilestones: VoyageMilestoneNameEnum[] = [
  VoyageMilestoneNameEnum.COLLECTED,
  VoyageMilestoneNameEnum.DEPARTED_ORIGIN_TERMINAL,
  VoyageMilestoneNameEnum.ARRIVED_AT_DESTINATION_TERMINAL,
  VoyageMilestoneNameEnum.DELIVERED
];

export const getIsPrimaryMilestone = (milestone: VoyageMilestoneNameEnum): boolean => {
  return primaryMilestones.includes(milestone);
};

const getInTransitParentMilestone = (transportMode: ModeOfTransport): VoyageMilestoneNameEnum => {
  if (isRoadShipment(transportMode)) {
    return VoyageMilestoneNameEnum.COLLECTED;
  }

  return VoyageMilestoneNameEnum.DEPARTED_ORIGIN_TERMINAL;
};

export const shouldDisplayInTransit = (transportMode: ModeOfTransport, milestoneName: VoyageMilestoneNameEnum): boolean => {
  return milestoneName === getInTransitParentMilestone(transportMode);
};

export const configureMenuItems = (
  onItemClick: (updateType: MilestoneUpdateType, timeType: VoyageMilestoneTime) => void,
  isFutureMilestone: boolean,
  hasEstimate: boolean,
  hasActual: boolean,
  isPrimaryMilestone: boolean
): MenuItemType[] => {
  const occurredMilesoneItems: MenuItemType[] = [
    {
      label: 'Update actual',
      icon: 'zicon-edit',
      onClick: () => onItemClick('update', VoyageMilestoneTime.ACTUAL),
      addDivider: hasActual
    }
  ];

  if (hasActual) {
    occurredMilesoneItems.push({
      label: 'Remove actual',
      icon: 'zicon-trash',
      onClick: () => onItemClick('remove', VoyageMilestoneTime.ACTUAL)
    });
  }

  const futureMilestoneItems: MenuItemType[] = [
    {
      label: 'Set as current',
      icon: 'zicon-pin',
      onClick: () => onItemClick('update', VoyageMilestoneTime.ACTUAL),
      addDivider: hasEstimate
    }
  ];

  if (isPrimaryMilestone) {
    futureMilestoneItems.unshift({
      label: 'Update estimate',
      icon: 'zicon-edit',
      onClick: () => onItemClick('update', VoyageMilestoneTime.ESTIMATED)
    });
  }

  if (hasEstimate) {
    futureMilestoneItems.push({
      label: 'Remove estimate',
      icon: 'zicon-trash',

      onClick: () => onItemClick('remove', VoyageMilestoneTime.ESTIMATED)
    });
  }

  return isFutureMilestone ? futureMilestoneItems : occurredMilesoneItems;
};
