import type { FC } from 'react';

import { Select } from '@zen/DesignSystem';
import { modeOfTransportOptions } from '@zen/Shipment/helpers';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import type { ModeOfTransport } from '../../types';
import { useUpdateModeOfTransportMutation } from '../graphql';

interface Props {
  canUpdateModeOfTransport: boolean;
  modeOfTransport: ModeOfTransport;
  refetchShipmentDetails: () => void;
  zencargoReference: string;
}

const refetchQueries = ['getCargoDetailsQuery', 'cargoItems', 'orderDetailsQuery', 'getRouteProgressDetails', 'voyageMilestones'];

const ModeOfTransportCargoInfoInput: FC<Props> = ({
  modeOfTransport,
  zencargoReference,
  refetchShipmentDetails,
  canUpdateModeOfTransport
}) => {
  const { addSuccess, addError } = useNotification();
  const [updateModeOfTransport] = useUpdateModeOfTransportMutation();

  const handleUpdateModeOfTransport = async (modeOfTransportValue: Nullable<ModeOfTransport>): Promise<void> => {
    if (modeOfTransportValue) {
      await performMutation({
        mutationFn: () =>
          updateModeOfTransport({
            variables: {
              input: {
                zencargoReference,
                modeOfTransport: modeOfTransportValue
              }
            },
            refetchQueries
          }),
        onError: addError,
        onSuccess: () => {
          refetchShipmentDetails();
          addSuccess('Mode of transport updated');
        }
      });
    }
  };

  return (
    <Select
      className="w-36"
      isDisabled={!canUpdateModeOfTransport}
      name="modeOfTransport"
      onChange={handleUpdateModeOfTransport}
      options={modeOfTransportOptions}
      overLabel="Mode of transport"
      value={modeOfTransport}
    />
  );
};

export default ModeOfTransportCargoInfoInput;
