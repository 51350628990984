import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworksUpdateLocationContactMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateLocationContactInput;
}>;

export type NetworksUpdateLocationContactMutationResult = { __typename?: 'Mutation' } & {
  networksUpdateLocationContact?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateLocationContactPayload' } & {
      contact?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksContact' } & Pick<
          GraphQLTypes.NetworksContact,
          'email' | 'firstName' | 'id' | 'lastName' | 'phoneNumber' | 'autoSubscribeToOrganisationTargets'
        >
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const NetworksUpdateLocationContactDocument = /* #__PURE__ */ gql`
  mutation networksUpdateLocationContact($input: UpdateLocationContactInput!) {
    networksUpdateLocationContact(input: $input) {
      contact {
        email
        firstName
        id
        lastName
        phoneNumber
        autoSubscribeToOrganisationTargets
      }
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useNetworksUpdateLocationContactMutation__
 *
 * To run a mutation, you first call `useNetworksUpdateLocationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworksUpdateLocationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networksUpdateLocationContactMutation, { data, loading, error }] = useNetworksUpdateLocationContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNetworksUpdateLocationContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NetworksUpdateLocationContactMutationResult,
    NetworksUpdateLocationContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<NetworksUpdateLocationContactMutationResult, NetworksUpdateLocationContactMutationVariables>(
    NetworksUpdateLocationContactDocument,
    options
  );
}
export type NetworksUpdateLocationContactMutationHookResult = ReturnType<typeof useNetworksUpdateLocationContactMutation>;
