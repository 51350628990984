import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { checkPermission } from '@zen/Auth/authHelper';
import BookingRequestButtons from '@zen/BookingRequests/BookingRequestButtons';
import { Icon, IconButton, Tooltip } from '@zen/DesignSystem';
import { newBookingRoutes } from '@zen/Routes';
import ArchiveShipment from '@zen/Shipment/ShipmentDetailsPage/ArchiveShipment';
import PinShipment from '@zen/Shipments/PinShipment';
import type { Optional } from '@zen/utils/typescript';

import type { BookingRequest } from '../types';
import BookingManufacturerResubmitButton from './BookingManufacturerResubmitButton';
import BookingResubmitForApprovalButton from './BookingResubmitForApprovalButton';

interface Props {
  accountId: string;
  bookingRequest: Optional<BookingRequest>;
  canAccessBackoffice: boolean;
  canArchiveShipment: boolean;
  canCloneShipment: boolean;
  canPinShipment: boolean;
  isBookingRequest: boolean;
  isPinned: boolean;
  isShipmentArchived: boolean;
  zencargoReference: string;
}

const TitleButtons: FC<Props> = ({
  accountId,
  bookingRequest,
  canAccessBackoffice,
  canArchiveShipment,
  canCloneShipment,
  canPinShipment,
  isBookingRequest,
  isPinned,
  isShipmentArchived,
  zencargoReference
}) => {
  const destinationURL: string = newBookingRoutes.newBookingFromZencargoReference.getUrl(zencargoReference);
  const showBookingRequestButtons: boolean = !!(isBookingRequest && bookingRequest);
  const canRevertBookingRejection: boolean =
    !!bookingRequest && checkPermission<BookingRequest>(bookingRequest, 'canRevertRejection');

  const showResubmitForApprovalButton: boolean = canRevertBookingRejection && !!bookingRequest?.rejected;
  const showArchiveShipmentIcon: boolean = canArchiveShipment && !isShipmentArchived;
  const showResubmitForManufacturer: boolean =
    !!bookingRequest?.rejected && checkPermission<BookingRequest>(bookingRequest, 'canRevertRejectionAsManufacturer');

  const cloneButton: ReactNode = (
    <Tooltip placement="bottom" tooltipContent="Clone this booking">
      <Link data-testid="shipment-clone-button" to={destinationURL}>
        <IconButton icon="zicon-copy" size="medium" variant="tertiary" />
      </Link>
    </Tooltip>
  );

  const backOfficeButton: ReactNode = (
    <>
      <Icon className="text-grey-base" icon="zicon-line" />
      <Tooltip placement="bottom-end" tooltipContent="Go to backoffice">
        <a data-testid="backoffice-link" href={`/backoffice/accounts/${accountId}/booking_forms/${zencargoReference}`}>
          <IconButton icon="zicon-anchor" size="medium" variant="tertiary" />
        </a>
      </Tooltip>
    </>
  );

  const archiveShipmentIcon: ReactNode = (
    <Tooltip placement="bottom-end" tooltipContent="Archive shipment">
      <ArchiveShipment zencargoReference={zencargoReference} />
    </Tooltip>
  );

  return (
    <div className={cx('flex flex-col -mt-6 items-end', { 'justify-between items-center': showBookingRequestButtons })}>
      <div className="flex items-center mb-3">
        {canCloneShipment && cloneButton}
        {canPinShipment && <PinShipment iconSize="medium" isPinned={isPinned} zencargoReference={zencargoReference} />}
        {canAccessBackoffice && backOfficeButton}
        {showArchiveShipmentIcon && archiveShipmentIcon}
      </div>
      {showBookingRequestButtons && (
        <BookingRequestButtons bookingRequest={bookingRequest} zencargoReference={zencargoReference} />
      )}
      {showResubmitForApprovalButton && <BookingResubmitForApprovalButton zencargoReference={zencargoReference} />}
      {showResubmitForManufacturer && <BookingManufacturerResubmitButton zencargoReference={zencargoReference} />}
    </div>
  );
};

export default TitleButtons;
