import { useApolloClient } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { get, isEqual } from 'lodash';
import { useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import type { Optional } from '@zen/utils/typescript';

import type { PaginatedVariables } from './types';

export interface AllPaginatedResults<NodeType> {
  data: NodeType[];
  error: boolean;
  isLoading: boolean;
}

interface Props<T> {
  document: DocumentNode;
  limit?: number;
  responsePath: string;
  skip?: boolean;
  variables?: T;
}

const useAllPaginatedResults = <Result, Variables extends PaginatedVariables, NodeType>(
  props: Props<Variables>
): AllPaginatedResults<NodeType> => {
  const { document, limit = 50, responsePath, skip, variables } = props;

  const { query } = useApolloClient();
  const [data, setData] = useState<NodeType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const variablesRef = useRef<Optional<Variables>>(null);

  const fetchData = async (after: string | undefined = undefined): Promise<void> => {
    try {
      const { data: responseData } = await query<Result, Variables>({
        query: document,
        variables: { ...variables, first: limit, after } as Variables
      });

      if (!isEqual(variablesRef.current, variables)) return;

      const { nodes = [], pageInfo } = get(responseData, responsePath, {});

      setData((prev) => [...prev, ...nodes]);

      if (pageInfo.hasNextPage === true) {
        fetchData(pageInfo.endCursor);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setError(true);
    }
  };

  useDeepCompareEffect(() => {
    if (skip) return;

    variablesRef.current = variables;

    setData(() => []);
    fetchData();
  }, [variables, skip]);

  return { data, isLoading, error };
};

export default useAllPaginatedResults;
