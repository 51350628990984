import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetBookingOriginAndDestinationQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetBookingOriginAndDestinationQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'accountUuid' | 'zencargoReference'> & {
              networksOrigin?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >;
              networksDestination?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >;
            }
        >
      >
    >;
  };
};

export const GetBookingOriginAndDestinationDocument = /* #__PURE__ */ gql`
  query getBookingOriginAndDestination($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        accountUuid
        zencargoReference
        networksOrigin {
          id
          label {
            long
          }
        }
        networksDestination {
          id
          label {
            long
          }
        }
      }
    }
  }
`;

/**
 * __useGetBookingOriginAndDestinationQuery__
 *
 * To run a query within a React component, call `useGetBookingOriginAndDestinationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingOriginAndDestinationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingOriginAndDestinationQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetBookingOriginAndDestinationQuery(
  baseOptions: Apollo.QueryHookOptions<GetBookingOriginAndDestinationQueryResult, GetBookingOriginAndDestinationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetBookingOriginAndDestinationQueryResult, GetBookingOriginAndDestinationQueryVariables>(
    GetBookingOriginAndDestinationDocument,
    options
  );
}
export function useGetBookingOriginAndDestinationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBookingOriginAndDestinationQueryResult,
    GetBookingOriginAndDestinationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetBookingOriginAndDestinationQueryResult, GetBookingOriginAndDestinationQueryVariables>(
    GetBookingOriginAndDestinationDocument,
    options
  );
}
export type GetBookingOriginAndDestinationQueryHookResult = ReturnType<typeof useGetBookingOriginAndDestinationQuery>;
export type GetBookingOriginAndDestinationLazyQueryHookResult = ReturnType<typeof useGetBookingOriginAndDestinationLazyQuery>;
