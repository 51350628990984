import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import FormHeadline from '@zen/Components/Form/FormHeadline';

interface Props {
  actionButton?: ReactNode;
  children: ReactNode;
  className?: string;
  id?: string;
  isCompact?: boolean;
  title: string;
}

const Section: FC<Props> = ({ actionButton, children, className, isCompact, title, id, ...rest }) => {
  const classNames: string = cx(
    {
      'mb-6 border-b border-solid border-grey-lighter': !isCompact
    },
    'pb-2 max-w-lg',
    className
  );

  return (
    <div className={classNames} {...rest} id={id}>
      <div className="flex justify-between items-center mb-4">
        <FormHeadline isCompact={true} text={title} />
        <div>{actionButton}</div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default Section;
