import type { FC } from 'react';

import { Form, FormInstance, FormSelect } from '@zen/Components/Form';
import AutoSubmit from '@zen/Components/Form/AutoSubmit';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Nullable } from '@zen/utils/typescript';

import type { TrackedShipmentFilterOptions, TrackedShipmentFilters } from '../types';
import { eventOptions, initialValues, timeframeOptions } from './helpers';

interface Props {
  filterOptions: TrackedShipmentFilterOptions;
  onSubmit: (values: TrackedShipmentFilters) => Promise<IOkOrErrorResult>;
}

const FiltersForm: FC<Props> = ({ filterOptions, onSubmit }) => {
  const { lastStopCountryCodes, firstStopCountryCodes } = filterOptions;

  return (
    <div data-testid="filters-form">
      <Form className="flex space-x-4" formButtons={() => null} initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue, submitForm }: FormInstance<TrackedShipmentFilters>) => {
          const handleEventChange = (value: Nullable<string>): void => {
            if (!value) {
              setFieldValue('timeframe', null);
            }
          };

          return (
            <>
              <AutoSubmit submitForm={submitForm} values={values} />
              <FormSelect
                className="w-44"
                hideLabel={true}
                isClearable={true}
                isSearchable={false}
                name="event"
                onChange={handleEventChange}
                options={eventOptions}
                placeholder="Event"
              />
              <FormSelect
                className="w-44"
                hideLabel={true}
                isClearable={true}
                isDisabled={!values.event}
                isSearchable={false}
                name="timeframe"
                options={timeframeOptions}
                placeholder="Timeframe"
              />
              <FormSelect
                className="w-56"
                hideLabel={true}
                isClearable={true}
                name="originCountryCode"
                options={firstStopCountryCodes}
                placeholder="Origin country"
                showSearchIcon={true}
              />
              <FormSelect
                className="w-56"
                hideLabel={true}
                isClearable={true}
                name="destinationCountryCode"
                options={lastStopCountryCodes}
                placeholder="Destination country"
                showSearchIcon={true}
              />
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default FiltersForm;
