import { get } from 'lodash';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import DataQualityIndicator from '@zen/Components/DataQualityIndicator';
import { getDateRange } from '@zen/Products/ProductDetails/Analytics/Filters/helpers';
import { AnalyticsViewPeriod } from '@zen/Products/ProductDetails/Analytics/Filters/types';
import useAccount from '@zen/utils/hooks/useAccount';
import useTracking from '@zen/utils/hooks/useTracking';
import { mergeQueryParams, parseQueryParams, stringifyQueryParams } from '@zen/utils/QueryParams/queryParams';

import { getFilters, prepareFilters } from '../Filters/helpers';
import { useIntakePlanningDataQualityQuery } from '../graphql';
import { calculatePercentage } from '../helpers/dataQuality';
import type { DataQuality } from '../types';
import IssueList from './IssueList';

const defaultDataQuantity: DataQuality = {
  productsWithIssues: [],
  purchaseOrdersWithIssues: [],
  totalProducts: 0,
  totalPurchaseOrders: 0
};

const isInteger = (value: number | undefined): value is number => Number.isInteger(value);

const DataQualityContainer = () => {
  const { accountUuid } = useAccount();
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();
  const { productId } = useParams<{ productId: string }>();
  const filters = getFilters(location.search);
  const { analyticsPeriod = AnalyticsViewPeriod.THREE_MONTHS, showDataQuality = false } = parseQueryParams(location.search);
  const { dateRange, destinationId } = prepareFilters(location.search);
  const { trackEvent } = useTracking();
  const isAnalyticsPage = location.pathname.includes('analytics');

  const { data, loading } = useIntakePlanningDataQualityQuery({
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid,
      dateRange: isAnalyticsPage ? getDateRange(analyticsPeriod) : dateRange,
      destinationId,
      productId,
      analyticsPage: isAnalyticsPage
    }
  });

  const dataQuality: DataQuality = get(data, 'account.intakePlanningDataQuality', defaultDataQuantity);
  const percentage = calculatePercentage(dataQuality);
  const hasData = isInteger(percentage);
  const shouldTrackDataQuality = !loading && data;

  useEffect(() => {
    if (shouldTrackDataQuality) {
      trackEvent({
        category: document.title,
        label: 'DataQuality',
        action: 'DataQualityScore',
        value: hasData ? percentage : undefined
      });
    }
  }, [location.search, shouldTrackDataQuality, trackEvent, hasData, percentage]);

  const showDataQualityIssues = () => {
    history.replace({
      ...location,
      search: mergeQueryParams(location.search, { showDataQuality: true })
    });
  };

  const hideDataQualityIssues = () => {
    history.replace({
      ...location,
      pathname: url,
      search: stringifyQueryParams(filters)
    });
  };

  if (!hasData) {
    return null;
  }

  return (
    <>
      <DataQualityIndicator isLoading={loading} onClick={showDataQualityIssues} value={percentage as number} />
      <IssueList
        isVisible={showDataQuality}
        onClose={hideDataQualityIssues}
        orders={dataQuality.purchaseOrdersWithIssues}
        products={dataQuality.productsWithIssues}
      />
    </>
  );
};

export default DataQualityContainer;
