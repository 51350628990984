import cx from 'classnames';
import { FC, useState } from 'react';
import Dropzone from 'react-dropzone';

import { ReactComponent as Upload } from './upload.svg';

interface Props {
  className?: string;
  onDrop: (files: File[]) => void;
}

const DropZone: FC<Props> = ({ onDrop, className: dropZoneClassName }) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const classNames: string = cx(
    'flex items-center justify-center',
    'rounded border-solid',
    'px-6',
    'hover:border-azure-light hover:shadow-interaction',
    'cursor-pointer transition-all duration-200 ease-linear',
    {
      'border-2 border-azure-light': isActive,
      'border border-grey-lighter': !isActive
    },
    dropZoneClassName
  );

  const handleDrop = (files: File[]): void => {
    setIsActive(false);
    onDrop(files);
  };

  return (
    <Dropzone
      onDragEnter={() => {
        setIsActive(true);
      }}
      onDragLeave={() => {
        setIsActive(false);
      }}
      onDrop={handleDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            className: classNames,
            'data-component': 'dropzone',
            'data-testid': 'dropzone'
          })}
        >
          <input {...getInputProps()} />
          <div className="md:flex md:items-center text-center">
            <Upload className="inline md:mr-6" />
            <div>
              Drag and drop your files here to upload or <span className="underline font-bold text-navy-base">browse</span>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export type { Props as DropZoneProps };

export default DropZone;
