import type { FC } from 'react';

import { Form, FormArray, FormArrayHelpers, FormInstance } from '@zen/Components/Form';
import CloseIcon from '@zen/Components/Icons/CloseIcon';
import StickyFooter from '@zen/Components/StickyFooter';
import { Button } from '@zen/DesignSystem';
import type { UpdateLotsInput } from '@zen/graphql/types.generated';
import type { LotInput } from '@zen/Orders/types';
import type { Money } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional } from '@zen/utils/typescript';

import { validationSchema } from './LotForm.validation';
import LotItem from './LotItem';
import Summary from './Summary';

interface InitialValues {
  lots: LotInput[];
}

const emptyLineItem: LotInput = {
  quantityFulfilled: null,
  cbm: null
};

const getIds = (items: LotInput[]): string[] => items.filter(({ id }: LotInput) => !!id).map(({ id }) => id!);

const calculateFulfilledPercentage = (quantityFulfilled: Optional<number>, quantityOrdered: number): number => {
  if (!quantityFulfilled) {
    return 0;
  }

  return (quantityFulfilled / quantityOrdered) * 100;
};

interface Props {
  initialValues: InitialValues;
  onClose: () => void;
  onSubmit: (values: UpdateLotsInput) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
  productPrice: Money;
  quantityOrdered: number;
}

const LotForm: FC<Props> = (props) => {
  const { initialValues, onClose, onSubmit, onSuccess, productPrice, quantityOrdered } = props;

  const renderSubmitButton = (values: UpdateLotsInput) => {
    const fixedHeightValue = 29.875;
    const lotHeight = 4.625;
    const lotFieldsHeight = values.lots.length * lotHeight;

    return (
      <StickyFooter className="pt-4 pr-30 pb-4 pl-14" minWindowHeight={fixedHeightValue + lotFieldsHeight}>
        <Button className="w-full" data-testid="submit-btn" type="submit">
          Submit
        </Button>
      </StickyFooter>
    );
  };

  const handleSubmit = (values: UpdateLotsInput): Promise<IOkOrErrorResult> => {
    const initialIds = getIds(initialValues.lots);
    const ids = getIds(values.lots);
    const lotIdsToDelete = initialIds.filter((id: string) => !ids.includes(id));

    const payload = {
      ...values,
      lotIdsToDelete
    };

    return onSubmit(payload);
  };

  return (
    <Form
      className="h-full overflow-auto w-160 relative pt-7 pr-24 pb-4 pl-14"
      enableReinitialize={true}
      formButtons={({ values }: FormInstance<UpdateLotsInput>) => renderSubmitButton(values)}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {({ values: { lots } }: FormInstance<InitialValues>) => (
        <>
          <div className="absolute top-8 right-8">
            <CloseIcon onClick={onClose} />
          </div>
          <div className="text-navy-base font-bold	text-2xl mb-10">Update / Add a new lot</div>
          <FormArray addButtonText="Add new lot" empty={emptyLineItem} path="lots" values={lots}>
            {({ value: lot, index, remove, getFieldName }: FormArrayHelpers<LotInput>) => {
              const percentage = calculateFulfilledPercentage(lot.quantityFulfilled, quantityOrdered);
              const lastItem = lots.length === 1;

              return (
                <LotItem getFieldName={getFieldName} index={index} lastItem={lastItem} percentage={percentage} remove={remove} />
              );
            }}
          </FormArray>
          <Summary lots={lots} productPrice={productPrice} quantityOrdered={quantityOrdered} />
        </>
      )}
    </Form>
  );
};

export default LotForm;
