import pluralize from 'pluralize';
import { FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useUpdateEffect } from 'react-use';

import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import DataExportButton from '@zen/Components/DataExportButton';
import ProductSelect from '@zen/Components/Form/ProductSelect';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import type { TableColumn } from '@zen/DesignSystem';
import { Table, useColumnConfiguration } from '@zen/DesignSystem';
import { useUsedAssignables } from '@zen/Networks';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks/types';
import { getColumns } from '@zen/Products/ProductDetails/WeeklyIntake/tableConfiguration';
import { productPathPrefix } from '@zen/Products/ProductsContainer';
import type { Product } from '@zen/Products/types';
import PreferencesIndicator from '@zen/Settings/Preferences/PreferencesIndicator';
import useAccount from '@zen/utils/hooks/useAccount';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { mergeQueryParams } from '@zen/utils/QueryParams/queryParams';
import type { Nullable } from '@zen/utils/typescript';

import DataQualityContainer from '../DataQuality';
import Filters from '../Filters';
import { getFilters, prepareFilters } from '../Filters/helpers';
import { useIntakePlanningExportPeriodsDataMutation, useIntakePlanningPeriodsQuery } from '../graphql';
import { getColumnKeys } from '../helpers/dataExport';
import IntakeChart from '../IntakeChart';
import { intakePathPrefix } from '../IntakePlanningContainer';
import type { IntakeChartValue, IntakePlanningPeriod, PeriodsColumnEnum } from '../types';

const IntakeIndex: FC = () => {
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const { accountUuid } = useAccount();
  const { period } = getFilters(search);
  const filters = prepareFilters(search);
  const tableId: string = 'intakePeriods';
  const { hiddenColumns, setHiddenColumns } = useColumnConfiguration(tableId);
  const columns: TableColumn<IntakePlanningPeriod>[] = useMemo(() => getColumns(search), [search]);
  const [exportPeriodsData] = useIntakePlanningExportPeriodsDataMutation();

  const { data: destinations = [] } = useUsedAssignables(
    [AssignmentTargetTypeEnum.BOOKING_CARGO, AssignmentTargetTypeEnum.BOOKING, AssignmentTargetTypeEnum.PURCHASE_ORDER],
    [AssignmentTypeValue.DESTINATION, AssignmentTypeValue.DELIVERY_LOCATION]
  );
  const {
    data,
    loading: isLoading,
    error,
    refetch
  } = useIntakePlanningPeriodsQuery({
    variables: {
      accountUuid,
      productId: null,
      ...filters
    }
  });

  useUpdateEffect(() => {
    refetch();
  }, [search]);

  const state: { previousUrl: string } = { previousUrl: `${pathname}${search}` };

  const redirectToProductPage = (product: Nullable<Product>): void => {
    if (product) {
      push(`${productPathPrefix}/${product.id}/po`, state);
    }
  };

  const handleDotClick = (payload: IntakeChartValue): void => {
    const params: string = mergeQueryParams(search, { startDate: payload.dateRange.startDate });

    push(`${intakePathPrefix}/weekly${params}`);
  };

  const handlePeriodsExport = async (): Promise<IOkOrErrorResult> => {
    const { data: exportData, errors } = await exportPeriodsData({
      variables: {
        input: {
          accountUuid,
          exportData: {
            columns: getColumnKeys(columns, hiddenColumns) as PeriodsColumnEnum[],
            dataFilters: { dateRange: filters.dateRange, destinationId: filters.destinationId }
          }
        }
      }
    });

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const exportErrors: boolean = (errors?.length || exportData.intakePlanningExportPeriodsData?.errors?.length) > 0;

    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ ok: IntakePlanningExportPeriodsDataMutatio... Remove this comment to see the full error message
    return Promise.resolve({ ok: exportData, error: exportErrors });
  };

  return (
    <Page
      browserTitle="Intake index view"
      contentClassName="py-4"
      filterSwitcher={<PreferencesIndicator onChange={() => refetch()} />}
      qualityIndicator={<DataQualityContainer />}
      title="Intake planning"
    >
      <div className="px-10">
        <ProductSelect onChange={redirectToProductPage} />
      </div>
      <Filters className="px-10" destinations={destinations} />
      <QueryHandler
        data={data?.account?.intakePlanningPeriods}
        error={!!error}
        isLoading={isLoading}
        loadingComponent={<SkeletonTableLoading columns={columns} tableId={tableId} />}
      >
        {(intakePeriods: IntakePlanningPeriod[]) => (
          <>
            <IntakeChart data={intakePeriods} dateRange={filters.dateRange} onDotClick={handleDotClick} />
            <div className="pb-8 px-10">
              <div className="flex justify-between items-between mb-8">
                <div className="text-grey-dark font-bold text-2xl">
                  <div>Total SKU intake</div>
                  <div className="text-lg mt-3">
                    Displaying {period} {pluralize('week', period)}
                  </div>
                </div>
                <div className="flex">
                  <CustomiseTableButton columns={columns} hiddenColumns={hiddenColumns} onChange={setHiddenColumns} />
                  <DataExportButton
                    className="ml-3"
                    confirmLabel="Return to intake planning"
                    onClickPromise={handlePeriodsExport}
                  />
                </div>
              </div>
              <Table<IntakePlanningPeriod>
                columns={columns}
                data={intakePeriods}
                hiddenColumns={hiddenColumns}
                tableId={tableId}
              />
            </div>
          </>
        )}
      </QueryHandler>
    </Page>
  );
};

export default IntakeIndex;
