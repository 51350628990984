import { FC, SyntheticEvent, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

import ChevronIcon from '@zen/Components/Icons/ChevronIcon';
import LabelledValue from '@zen/Components/LabelledValue';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { Checkbox } from '@zen/DesignSystem';

import type { OrderOverviewProps } from '../types';

interface Props extends OrderOverviewProps {}

const OrderListItemSimpleHeadline: FC<Props> = (props) => {
  const { isOrderPartiallySelected, isOrderSelected, onClick, onOrderSelect, order, expanded } = props;
  const { isBasketMode } = useContext(BasketContext);
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { id, orderReferenceNumber, manufacturer } = order;
  const classNames: string = 'flex items-center p-4 font-medium hover:bg-azure-lightest cursor-pointer group';

  const handleSelect = (event: SyntheticEvent): void => {
    event.stopPropagation();

    onOrderSelect();
  };

  const handleOrderReferenceClick = (event: SyntheticEvent): void => {
    event.stopPropagation();

    push(`${pathname}/${id}`);
  };

  return (
    <div className={classNames} data-testid="order-list-item-headline" onClick={onClick}>
      {isBasketMode && (
        <div className="w-10">
          <Checkbox checked={!!isOrderSelected} indeterminate={isOrderPartiallySelected} onChange={handleSelect} />
        </div>
      )}

      <LabelledValue className="w-1/5 mr-4" label="PO number">
        <div className="break-all inline-block text-navy-base hover:underline" onClick={handleOrderReferenceClick}>
          {orderReferenceNumber}
        </div>
      </LabelledValue>

      <LabelledValue className="w-1/2 mr-4" label="Manufacturer">
        <ValueWithDefault value={manufacturer?.label?.short} />
      </LabelledValue>

      <div className="ml-auto flex h-6 items-center">
        <ChevronIcon className="text-sm" expanded={expanded} />
      </div>
    </div>
  );
};

export default OrderListItemSimpleHeadline;
