import type { FC } from 'react';
import * as Yup from 'yup';

import { Form, FormButtons, FormInstance } from '@zen/Components/Form';
import FormDatePicker from '@zen/Components/Form/FormDatePicker';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import StickyFooter from '@zen/Components/StickyFooter';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { ConfidenceLevel } from '../../types';
import type { CargoReadyDateFormInitialValues, RichDateInput } from '../CargoReadyDateForm/types';
import DatesIndicator from '../DatesIndicator';

const validationSchema = Yup.object().shape({
  date: Yup.string().nullable().required('Please select a new delivery estimate')
});

const getInitialValues = (date: string | undefined): CargoReadyDateFormInitialValues => ({
  date,
  reasonForChange: {
    reasonId: null,
    reasonDescription: ''
  },
  confidenceLevel: ConfidenceLevel.ESTIMATED
});

interface Props {
  cargoReadyDate?: string;
  deliveryDate?: string;
  onSkip: () => void;
  onSubmit: (values: RichDateInput) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
  previousDeliveryDate?: string;
}

const DeliveryDateForm: FC<Props> = ({ cargoReadyDate, deliveryDate, onSuccess, onSkip, onSubmit, previousDeliveryDate }) => {
  const renderButtons = ({ isSubmitting }: FormInstance<RichDateInput>) => (
    <StickyFooter minWindowHeight={57}>
      <FormButtons isSubmitting={isSubmitting} text="Submit">
        <Button onClick={onSkip} variant="secondary">
          Skip this step
        </Button>
      </FormButtons>
    </StickyFooter>
  );

  return (
    <Form
      className="h-screen overflow-y-scroll relative px-8 pb-16"
      enableReinitialize={true}
      formButtons={renderButtons}
      formName="DeliveryDateForm"
      initialValues={getInitialValues(deliveryDate)}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <>
          {previousDeliveryDate && <DatesIndicator newDate={values.date} previousDate={previousDeliveryDate} />}
          <FormDatePicker
            hideLabel={true}
            highlightInitialDate={!!previousDeliveryDate}
            initialDate={previousDeliveryDate || cargoReadyDate}
            minDate={cargoReadyDate}
            name="date"
          />
          <FormTextarea label="Add any additional information" name="reasonForChange.reasonDescription" textareaRows={7} />
        </>
      )}
    </Form>
  );
};

export default DeliveryDateForm;
