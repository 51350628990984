import type { FC, ReactNode } from 'react';

import { CargoModeEnum } from '@zen/Cargo';
import { cargoModeLabelMapping } from '@zen/Cargo/helpers';
import { Button, LegacyModal } from '@zen/DesignSystem';

interface Props {
  cargoMode: CargoModeEnum;
  isOpen: boolean;
  onChange: (cargoMode: CargoModeEnum) => void;
  onClose: () => void;
}

const CargoModeModal: FC<Props> = ({ cargoMode, isOpen, onChange, onClose }) => {
  const cargoModes = Object.values(CargoModeEnum) as CargoModeEnum[];
  const otherCargoModes: CargoModeEnum[] = cargoModes.filter((mode) => mode !== cargoMode);

  return (
    <LegacyModal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="p-8 max-w-160">
        <div className="text-navy-base font-bold text-center text-lg mb-4">Edit cargo type</div>
        <div className="text-sm text-center text-grey-dark leading-normal mb-6">
          Changing cargo type will mean you lose all data for cargo you have created. There’s no going back.
        </div>
        <div>
          <div className="flex justify-between">
            <Button onClick={onClose} variant="secondary">
              Close
            </Button>
            <div className="flex">
              {otherCargoModes.map(
                (mode: CargoModeEnum): ReactNode => (
                  <Button key={mode} className="ml-2" onClick={() => onChange(mode)}>
                    {cargoModeLabelMapping[mode]}
                  </Button>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </LegacyModal>
  );
};

export default CargoModeModal;
