import { FC, useEffect, useState } from 'react';

import CloseIcon from '@zen/Components/Icons/CloseIcon';
import Slideout from '@zen/Components/Slideout';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import CargoReadyDateForm from '../components/CargoReadyDateForm';
import type { RichDateInput } from '../components/CargoReadyDateForm/types';
import { usePurchaseOrdersUpdateLotEstimatesMutation } from '../graphql';
import type { CargoReadyDateMutationResponse } from './types';

type RichDateInputFormValues = { [P in keyof RichDateInput]: Optional<RichDateInput[P]> };

interface Props {
  initialValues: Optional<RichDateInputFormValues>;
  isVisible: boolean;
  lotIds: string[];
  onClose: () => void;
  onSuccess?: (values?: CargoReadyDateMutationResponse) => void;
  orderDate: Optional<string>;
  orderReferenceNumber?: Optional<string>;
}

const CargoReadyDateSlideout: FC<Props> = (props) => {
  const { initialValues, isVisible, lotIds, onClose, onSuccess, orderDate, orderReferenceNumber } = props;
  const { addSuccess, addError } = useNotification();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(isVisible);
  }, [isVisible]);

  const [updateCRD] = usePurchaseOrdersUpdateLotEstimatesMutation();

  const handleSubmit = (cargoReadyDate: RichDateInput): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () =>
        updateCRD({
          variables: {
            input: {
              lotIds,
              cargoReadyDate
            }
          }
        }),
      onError: () => addError()
    });

  const handleSuccess = (values: CargoReadyDateMutationResponse) => {
    addSuccess('Cargo ready date has been updated.');
    onClose();
    if (onSuccess) {
      onSuccess(values);
    }
  };

  return (
    <Slideout onOutsideClick={onClose} overlay={true} show={show}>
      <div className="flex flex-col h-full relative" data-testid="cargo-ready-slideout" style={{ width: '38rem' }}>
        <div className="flex justify-between p-8 items-center">
          <div className="text-grey-dark font-bold text-xl">
            Update cargo ready date {orderReferenceNumber && ` for ${orderReferenceNumber}`}
          </div>
          <CloseIcon onClick={onClose} />
        </div>
        <CargoReadyDateForm
          cargoReadyDate={initialValues?.date || ''}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          orderDate={orderDate}
        />
      </div>
    </Slideout>
  );
};

export default CargoReadyDateSlideout;
