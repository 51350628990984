import type { FC } from 'react';

import type { MenuItemType } from '@zen/DesignSystem';
import { Icon, IconProps, Tooltip, TooltipPlacement } from '@zen/DesignSystem';

interface Props {
  item: MenuItemType;
  tooltipPlacement?: TooltipPlacement;
}

const CargoDetailsAction: FC<Props> = ({ item, tooltipPlacement }) => {
  const { label, disabled, onClick, addDivider, icon } = item;

  const interactiveProps: Partial<IconProps> = disabled ? { disabled: true, className: 'p-2' } : { onClick, interactive: true };

  return (
    <>
      <Tooltip placement={tooltipPlacement} tooltipContent={label}>
        {icon && (
          <span className="py-2">
            <Icon icon={icon} interactive={!disabled} {...interactiveProps} />
          </span>
        )}
      </Tooltip>
      {addDivider && <Icon className="mr-1" icon="zicon-line" />}
    </>
  );
};

export default CargoDetailsAction;
