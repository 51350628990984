type ChartRadius = [number, number, number, number];

const BAR_RADIUS_LEFT: ChartRadius = [15, 0, 0, 15];
const BAR_RADIUS_RIGHT: ChartRadius = [0, 15, 15, 0];
const BAR_RADIUS_BOTH: ChartRadius = [15, 15, 15, 15];
const DEFAULT_RADIUS: ChartRadius = [0, 0, 0, 0];

export const getEarlyBarRadiusValue = (onTimePercentage: number, latePercentage: number): number | ChartRadius => {
  return !onTimePercentage && !latePercentage ? BAR_RADIUS_BOTH : BAR_RADIUS_LEFT;
};

export const getLateBarRadiusValue = (earlyPercentage: number, onTimePercentage: number): number | ChartRadius => {
  return !earlyPercentage && !onTimePercentage ? BAR_RADIUS_BOTH : BAR_RADIUS_RIGHT;
};

export const getOnTimeBarRadiusValue = (earlyPercentage: number, latePercentage: number): number | ChartRadius => {
  if (!earlyPercentage && !latePercentage) {
    return BAR_RADIUS_BOTH;
  }

  if (!earlyPercentage) {
    return BAR_RADIUS_LEFT;
  }

  if (!latePercentage) {
    return BAR_RADIUS_RIGHT;
  }

  return DEFAULT_RADIUS;
};
