import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetOriginAgentQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetOriginAgentQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              canEditAgent: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              originAgent?: GraphQLTypes.Maybe<
                | ({ __typename: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      typename: 'NetworksLegacyOrgLoc';
                    } & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
                | ({ __typename: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & { typename: 'NetworksOrgLoc' } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksOrgLocOrganisation,
                          'id' | 'name'
                        >
                      >;
                      location?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
                            address?: GraphQLTypes.Maybe<
                              { __typename?: 'Address' } & Pick<
                                GraphQLTypes.Address,
                                'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                              > & {
                                  country?: GraphQLTypes.Maybe<
                                    { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                  >;
                                }
                            >;
                          }
                      >;
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
                | ({ __typename: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      typename: 'NetworksTerminal';
                    } & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
              >;
            }
        >
      >
    >;
  };
};

export const GetOriginAgentDocument = /* #__PURE__ */ gql`
  query getOriginAgent($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        canEditAgent {
          value
        }
        originAgent {
          __typename
          typename: __typename
          id
          label {
            short
            long
          }
          ... on NetworksOrgLoc {
            organisation {
              id
              name
            }
            location {
              id
              name
              address {
                street
                city
                country {
                  name
                  code
                }
                postalCodeOrZip
                countyOrState
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOriginAgentQuery__
 *
 * To run a query within a React component, call `useGetOriginAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOriginAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOriginAgentQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetOriginAgentQuery(
  baseOptions: Apollo.QueryHookOptions<GetOriginAgentQueryResult, GetOriginAgentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetOriginAgentQueryResult, GetOriginAgentQueryVariables>(GetOriginAgentDocument, options);
}
export function useGetOriginAgentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOriginAgentQueryResult, GetOriginAgentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetOriginAgentQueryResult, GetOriginAgentQueryVariables>(GetOriginAgentDocument, options);
}
export type GetOriginAgentQueryHookResult = ReturnType<typeof useGetOriginAgentQuery>;
export type GetOriginAgentLazyQueryHookResult = ReturnType<typeof useGetOriginAgentLazyQuery>;
