import type { FC } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import type { UpdateOrganisationLocationInput } from '@zen/Networks';
import EditLocationForm, { IEditLocationFormFields } from '@zen/Networks/Form/EditLocationForm';
import { useEditOrganisationLocationQuery, useNetworksUpdateOrganisationLocationMutation } from '@zen/Networks/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import type { OrganisationLocationResponse } from '../Form/EditLocationForm/types';
import { getInitialValues, prepareUpdateLocationInput } from './helpers';

interface Props {
  locationId: string;
  onCancel: () => void;
  onSuccess: (data: OrganisationLocationResponse) => void;
  organisationId: string;
}

const EditLocation: FC<Props> = ({ locationId, onCancel, onSuccess, organisationId }) => {
  const { addError } = useNotification();

  const { data, loading, error } = useEditOrganisationLocationQuery({
    variables: {
      organisationId,
      locationId
    },
    fetchPolicy: 'cache-and-network'
  });

  const [updateOrganisation] = useNetworksUpdateOrganisationLocationMutation();

  const handleSubmit = async (values: IEditLocationFormFields): Promise<IOkOrErrorResult> => {
    const input: UpdateOrganisationLocationInput = prepareUpdateLocationInput(values, organisationId, locationId);

    return performFormMutation({
      mutationFn: () =>
        updateOrganisation({
          variables: {
            input
          }
        }),
      onError: addError
    });
  };

  return (
    <QueryHandler data={data?.location} error={!!error} isLoading={loading}>
      {(organisationLocation) => (
        <EditLocationForm
          initialValues={getInitialValues(organisationLocation)}
          onCancel={onCancel}
          onSubmit={handleSubmit}
          onSuccess={onSuccess}
        />
      )}
    </QueryHandler>
  );
};

export default EditLocation;
