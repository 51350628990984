import type { FC } from 'react';

import { ContextMenu, MenuItemType } from '@zen/DesignSystem';
import { backofficeRoutes, newBookingRoutes, opsShipmentRoutes, shipmentRoutes } from '@zen/Routes';
import { ShipmentPageSectionUrl } from '@zen/Shipment/ShipmentDetailsPage/types';

interface Props {
  accountId: string;
  onArchiveBooking: () => void;
  zencargoReference: string;
}

const ActionHandle: FC<Props> = ({ zencargoReference, accountId, onArchiveBooking }) => {
  const { shipmentActivity } = backofficeRoutes;
  const activityUrl: string = shipmentActivity.getUrl(zencargoReference, accountId);
  const originAgentUrl: string = shipmentRoutes.shipmentDetailsPage.getUrl(
    zencargoReference,
    ShipmentPageSectionUrl.TRADE_PARTIES
  );
  const documentsUrl: string = shipmentRoutes.shipmentDetailsPage.getUrl(zencargoReference, ShipmentPageSectionUrl.DOCUMENTS);
  const editShipmentUrl: string = opsShipmentRoutes.editRoadShipment.getUrl(zencargoReference);
  const newBookingUrl: string = newBookingRoutes.newBookingFromZencargoReference.getUrl(zencargoReference);

  const menuItems: MenuItemType[] = [
    { icon: 'zicon-edit', label: 'Edit shipment', linkTo: editShipmentUrl },
    { icon: 'zicon-agent', label: 'Add origin agent', linkTo: originAgentUrl },
    { icon: 'zicon-announcement', label: 'Update shipment status', linkTo: activityUrl, openInNewTab: true },
    { icon: 'zicon-action-item', label: 'Edit documents', linkTo: documentsUrl },
    { icon: 'zicon-copy', label: 'Clone', linkTo: newBookingUrl },
    { icon: 'zicon-archive', label: 'Archive', onClick: onArchiveBooking }
  ];

  return <ContextMenu inline={false} items={menuItems} />;
};

export default ActionHandle;
