import type { FC, ReactNode } from 'react';

import { cargoRiskLevelLabelMapping } from '@zen/Cargo/helpers';
import { Icon, Tooltip } from '@zen/DesignSystem';
import { RiskLevelsEnum } from '@zen/graphql/types.generated';
import type { IconName } from '@zen/Styleguide';

const riskLevelIconMapping: Record<RiskLevelsEnum, { className: string; icon: IconName }> = {
  [RiskLevelsEnum.LOW]: {
    className: 'text-green-base mr-1',
    icon: 'zicon-warning-triangle-inverse'
  },
  [RiskLevelsEnum.MEDIUM]: {
    className: 'text-orange-base mr-1',
    icon: 'zicon-warning-triangle-inverse-double'
  },
  [RiskLevelsEnum.HIGH]: {
    className: 'text-red-base mr-1',
    icon: 'zicon-warning-triangle-inverse-triple'
  }
};

interface Props {
  riskLevel: RiskLevelsEnum;
  showLabelInline?: boolean;
}

const CargoRiskLevel: FC<Props> = ({ riskLevel, showLabelInline = false }) => {
  const { className, icon } = riskLevelIconMapping[riskLevel];
  const cargoRiskLevelLabel: string = cargoRiskLevelLabelMapping[riskLevel].title;

  const renderIcon = (): ReactNode => {
    return <Icon className={className} icon={icon} />;
  };

  const renderToolip = (): ReactNode => {
    return <Tooltip tooltipContent={cargoRiskLevelLabel}>{renderIcon()}</Tooltip>;
  };

  const renderInline = (): ReactNode => {
    return (
      <>
        {renderIcon()}
        <div>{cargoRiskLevelLabel}</div>
      </>
    );
  };

  return (
    <div className="flex items-center" data-testid="highest-risk-level">
      {showLabelInline ? renderInline() : renderToolip()}
    </div>
  );
};

export default CargoRiskLevel;
