import { FC, useState } from 'react';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Pagination, Table } from '@zen/DesignSystem';
import OperationsSettingsSearch from '@zen/OperationsSettings/OperationsSettingsSearch';
import { productSettingsRoutes } from '@zen/Routes';
import type { SortInput } from '@zen/types';
import usePagination from '@zen/utils/hooks/pagination/usePagination';

import type { Terminal } from '../ManageTerminals/types';
import { GetUnloCodesQueryResult, GetUnloCodesQueryVariables, useGetUnloCodesQuery } from './graphql';
import { columns, initialOrder } from './unloCodeTableConfig';

const UNLOCodeTerminals: FC = () => {
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { loading, error, nodes, paginationInfo, totalCount } = usePagination<
    GetUnloCodesQueryResult,
    GetUnloCodesQueryVariables,
    Terminal
  >(
    useGetUnloCodesQuery,
    'terminals',
    {
      query: searchQuery,
      order
    },
    20
  );

  const backURL: string = productSettingsRoutes.manageTerminals.getUrl();

  return (
    <Page defaultBackUrl={backURL} title="Find terminal UNLOCODES">
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonTableLoading columns={columns} order={order} tableId="unloCodeTerminals" />}
      >
        {(terminals: Terminal[]) => {
          return (
            <>
              <OperationsSettingsSearch onSearch={setSearchQuery} placeholder="Search for a terminal..." />
              <Table<Terminal>
                columns={columns}
                data={terminals}
                onOrderChange={setOrder}
                order={order}
                tableId="unloCodeTerminals"
                totalCountConfig={{
                  totalCount,
                  entityName: 'terminal'
                }}
              />
              <Pagination pageInfo={paginationInfo} />
            </>
          );
        }}
      </QueryHandler>
    </Page>
  );
};

export default UNLOCodeTerminals;
