import type { FC } from 'react';

import type { IconName } from '@zen/Styleguide';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import AccountStatusControl from '../AccountStatusControl';
import { useActivateAccountMutation, useDeactivateAccountMutation } from '../graphql';

interface Props {
  accountId: string;
  accountReference: string;
  isActive: boolean;
  onStatusChange: () => void;
}

const AccountChangeStatus: FC<Props> = ({ accountId, accountReference, isActive, onStatusChange }) => {
  const { addSuccess, addError } = useNotification();

  const [activateAccount] = useActivateAccountMutation();
  const [deactivateAccount] = useDeactivateAccountMutation();

  const handleActivateAccount = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        activateAccount({
          variables: {
            input: {
              id: accountId
            }
          }
        }),
      onError: addError,
      onSuccess: () => {
        addSuccess('Account activated');
        onStatusChange();
      }
    });
  };

  const handleDeactivateAccount = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        deactivateAccount({
          variables: {
            input: {
              id: accountId
            }
          }
        }),
      onError: addError,
      onSuccess: () => {
        addSuccess('Account deactivated');
        onStatusChange();
      }
    });
  };

  const description: string = isActive
    ? 'Active accounts have access to Zencargo platform. Setting an account as inactive will block all users on this account and unsubscribe all shipments.'
    : 'Inactive accounts have no access to Zencargo platform. Setting an account as active will enable user access to the platform.';

  const accountStatusLabel: string = isActive ? 'Active' : 'Inactive';
  const accountAccessLabel: string = isActive ? 'access to platform' : 'no access to platform';
  const oppositeLabel: string = isActive ? 'inactive' : 'active';
  const iconClassName: string = isActive ? 'text-green-base' : 'text-red-base';
  const iconName: IconName = isActive ? 'zicon-tickoval' : 'zicon-cross-oval';
  const buttonIconName: Optional<IconName> = isActive ? 'zicon-warning-oval' : undefined;

  return (
    <AccountStatusControl
      accountAccessLabel={accountAccessLabel}
      accountLabel={accountStatusLabel}
      accountReference={accountReference}
      buttonIconName={buttonIconName}
      configurationType="status"
      currentStatus={isActive}
      description={description}
      iconClassName={iconClassName}
      iconName={iconName}
      onUpdate={isActive ? handleDeactivateAccount : handleActivateAccount}
      oppositeLabel={oppositeLabel}
    />
  );
};

export default AccountChangeStatus;
