import cx from 'classnames';
import { Field, FieldProps } from 'formik';
import { capitalize, has, isString, kebabCase } from 'lodash';
import type { ReactNode } from 'react';

import HelperText from '@zen/Components/HelperText';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';

import FormError from '../FormError/FormError';
import FormLabel from '../FormLabel';

export interface FormFieldProps {
  autoFocus?: boolean;
  className?: string;
  helperText?: string;
  hideErrorMessage?: boolean;
  hideLabel?: boolean;
  hint?: string;
  inputWrapperClassName?: string;
  isRequired?: boolean;
  label?: ReactNode;
  labelDescription?: string;
  name: string;
  noMargin?: boolean;
  placeholder?: string;
  warning?: string;
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'componentProps' implicitly has an 'any'... Remove this comment to see the full error message
const FormField = (componentProps) => {
  const {
    children,
    className = null,
    name,
    label = null,
    labelDescription = null,
    hideLabel = false,
    hint,
    warning,
    hideErrorMessage,
    inputWrapperClassName,
    isRequired,
    noMargin,
    helperText,
    ...rest
  } = componentProps;

  const renderLabel = (): ReactNode => {
    if (label && !isString(label)) {
      return label;
    }

    return (
      <>
        <FormLabel isRequired={isRequired} label={label || capitalize(removeSpecialCharactersAndUpperFirst(name))} name={name} />
        {labelDescription && <p className="mb-2 text-sm">{labelDescription}</p>}
      </>
    );
  };

  return (
    <Field id={name} label={label} name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { touched, errors } = form;
        const fieldName = field.name;
        const hasError = has(touched, fieldName) && has(errors, fieldName);
        const newProps = { ...field, label, error: hasError, ...rest };

        const classNames = cx(
          {
            'mb-4': !noMargin,
            'mb-0': noMargin,
            'js-field-error': hasError
          },
          className,
          'formField'
        );

        return (
          <div className={classNames} data-testid={`form-field-${kebabCase(name)}`}>
            {!hideLabel && renderLabel()}
            <div className={inputWrapperClassName}>{children(newProps, form, meta)}</div>
            {hint && <div className="text-xs text-grey-base italic mt-2">{hint}</div>}
            {warning && <div className="text-sm leading-tight text-orange-base mt-2">{warning}</div>}
            {hideErrorMessage || !hasError ? <HelperText helperText={helperText} /> : <FormError fieldName={fieldName} />}
          </div>
        );
      }}
    </Field>
  );
};

export default FormField;
