import type { CargoTransition } from '@zen/Cargo';
import type { DeepNonNullable, Modify, Optional } from '@zen/utils/typescript';

import type { GetOperationsRoadShipmentsQueryResult, GetOperationsRoadShipmentsQueryVariables } from '../graphql';
import type { GetOperationsRoadShipmentsTotalCountQueryVariables } from '../graphql/roadShipmentsTotalCount.generated';

export type RoadShipment = DeepNonNullable<GetOperationsRoadShipmentsQueryResult, 'nodes'>['bookings']['nodes'];

export type RoadShipmentExtended = Modify<
  RoadShipment,
  {
    cargo: Optional<CargoTransition>;
  }
>;

export type {
  CollectionAndDeliveryState,
  CollectionDetails,
  DeliveryDetails,
  InFull,
  LocalDateTimeRange,
  LocalDateTimeType,
  RichDate
} from '@zen/graphql/types.generated';

export const RoadShipmentTrackingCategory: string = 'RoadShipment';

export enum RoadShipmentTrackingAction {
  ROAD_SHIPMENT_CELL_EDITED = 'RoadShipmentCellEdited',
  ROAD_SHIPMENT_EDITED = 'RoadShipmentEdited',
  ROAD_SHIPMENT_FILTERS_APPLIED = 'RoadShipmentFiltersApplied',
  ROAD_SHIPMENT_ROAD_TRACKING_INPUT_CHANGED = 'RoadShipmentRoadTrackingInputChanged'
}

export type RoadShipmentQueryVariables =
  | GetOperationsRoadShipmentsQueryVariables
  | GetOperationsRoadShipmentsTotalCountQueryVariables;
