import * as Yup from 'yup';

import type { PackageInfo } from '../types';

const unitsPerCartonMessage = 'Units Per Master Carton must be a whole number greater than 0';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  skuCode: Yup.string().required('SKU code is required.'),
  hsCode: Yup.string()
    .matches(/^\d{6,}$/, {
      message: 'HS code must be a number with at least 6 digits'
    })
    .nullable(),
  htsCode: Yup.string()
    .matches(/^\d{10}$/, {
      message: 'HTS code must be a number with 10 digits'
    })
    .nullable(),
  packageInfo: Yup.object().shape({
    length: Yup.object()
      .nullable()
      .shape({
        value: Yup.number().nullable().moreThan(0)
      }),
    width: Yup.object()
      .nullable()
      .shape({
        value: Yup.number().nullable().moreThan(0)
      }),
    depth: Yup.object()
      .nullable()
      .shape({
        value: Yup.number().nullable().moreThan(0)
      })
  }),
  packageInfoValidator: Yup.string().when('packageInfo', {
    is: ({ length, width, depth }: PackageInfo) => {
      const values = [length?.value, width?.value, depth?.value];

      if (values.some((value) => !value) && values.some((value) => value)) {
        return true;
      }

      return false;
    },
    then: Yup.string().required('Please provide all carton dimensions')
  }),
  unitsPerMasterCarton: Yup.number().nullable().min(1, unitsPerCartonMessage).integer(unitsPerCartonMessage)
});
