import type { FC } from 'react';

import useAccount from '@zen/utils/hooks/useAccount';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { useRoadTrackedShipmentFilterOptionsQuery } from '../graphql';
import FiltersForm from './FiltersForm';
import { buildFilters, prepareFilterOptions } from './helpers';
import type { TrackedShipmentFilters, TrackedShipmentFiltersInput } from './types';

interface Props {
  onChange: (filters: TrackedShipmentFiltersInput) => void;
}

const Filters: FC<Props> = ({ onChange }) => {
  const { accountUuid: customerId } = useAccount();
  const { data } = useRoadTrackedShipmentFilterOptionsQuery({ variables: { customerId } });

  const handleSubmit = (values: TrackedShipmentFilters): Promise<IOkOrErrorResult> => {
    onChange(buildFilters(values));

    return Promise.resolve({ ok: true, error: null });
  };

  return (
    <div className="mb-2">
      <FiltersForm filterOptions={prepareFilterOptions(data?.roadTrackedShipmentFilterOptions)} onSubmit={handleSubmit} />
    </div>
  );
};

export default Filters;
