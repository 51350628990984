import { useState } from 'react';

import SaveQuickFiltersForm from '@zen/Shipments/SaveQuickFiltersForm';
import type { IconName } from '@zen/Styleguide';
import { useSavedFilters } from '@zen/utils/Filtering/contexts/SavedFiltersContext';
import { useHover } from '@zen/utils/hooks/useHover';

import QuickFiltersActionsButton from '../QuickFiltersActionsButton';

enum ButtonMode {
  ADD = 'ADD',
  REMOVE = 'REMOVE'
}
interface ButtonConfig {
  icon: IconName;
  label: string;
}

const buttonModes: Record<ButtonMode, ButtonConfig> = {
  [ButtonMode.ADD]: {
    label: 'Save current filters',
    icon: 'zicon-open-heart'
  },
  [ButtonMode.REMOVE]: {
    label: 'Remove filter',
    icon: 'zicon-no-heart'
  }
};

const buttonModeMapper = (isQuickFilterActive: boolean): ButtonConfig => {
  if (isQuickFilterActive) {
    return buttonModes[ButtonMode.REMOVE];
  }

  return buttonModes[ButtonMode.ADD];
};

interface Props<T extends {}> {
  filters: T;
}

const QuickFiltersAddRemoveButton = <T extends {}>({ filters }: Props<T>) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { removeSavedFilter, getActiveSavedFilter } = useSavedFilters();
  const [ref, isHovered] = useHover();

  const activeQuickFilter = getActiveSavedFilter(filters);

  const clickHandler = () => {
    if (activeQuickFilter) {
      removeSavedFilter(activeQuickFilter);
    } else {
      setShowModal(true);
    }
  };

  const handleCancel = (): void => setShowModal(false);

  const { label, icon } = buttonModeMapper(!!activeQuickFilter);

  return (
    <>
      <span ref={ref} className="inline-block">
        <QuickFiltersActionsButton iconName={icon} isHovered={isHovered} label={label} onClick={clickHandler} />
      </span>
      <SaveQuickFiltersForm filters={filters} onCancel={handleCancel} setShowModal={setShowModal} showModal={showModal} />
    </>
  );
};

export default QuickFiltersAddRemoveButton;
