import type { FC, ReactNode } from 'react';

import { Icon, Switch, Tooltip } from '@zen/DesignSystem';

export interface Props {
  className?: string;
  isEnabled: boolean;
  label: string;
  onChange?: (isEnabled: boolean) => void;
  tooltip: ReactNode;
}

const TooltipSwitch: FC<Props> = ({ className, isEnabled, label, onChange, tooltip }) => {
  const renderLabel = (): ReactNode => (
    <div className="flex items-center">
      <span>{label}</span>
      <Tooltip placement="bottom" tooltipContent={tooltip}>
        <Icon className="text-grey-dark ml-2" icon="zicon-help" />
      </Tooltip>
    </div>
  );

  return (
    <div className={className} data-testid="tooltip-switch">
      <Switch isChecked={isEnabled} label={renderLabel()} onChange={onChange} />
    </div>
  );
};

export default TooltipSwitch;
