import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import type { LocationDescriptor, LocationDescriptorObject } from '@zen/types';

import type { Undefinable } from '../typescript';
import NavigationHistoryContext from './NavigationHistoryContext';
import type { NavigationRecord } from './types';

const NavigationHistoryProvider: FC = ({ children }) => {
  const { pathname, search, state } = useLocation();

  const { push } = useHistory();
  const [navigationRecords, setNavigationRecords] = useState<Array<NavigationRecord>>([]);
  const NAVIGATION_RECORDS_SIZE = 10;

  const appendNavigationRecord = (target: LocationDescriptor) => {
    const path: string = typeof target === 'string' ? target : target.pathname;
    const records: NavigationRecord[] = [{ to: path, from: { pathname, search, state } }, ...navigationRecords];

    if (navigationRecords.length > NAVIGATION_RECORDS_SIZE) {
      records.pop();
    }

    setNavigationRecords(records);
  };

  const findNavigationRecord = (key: string): Undefinable<NavigationRecord> => {
    const record: Undefinable<NavigationRecord> = navigationRecords.find((navigationRecord: NavigationRecord) =>
      navigationRecord.to.includes(key)
    );

    if (record) {
      const records = navigationRecords.filter((navigationRecord: NavigationRecord) => navigationRecord.to !== record.to);

      setNavigationRecords(records);
    }

    return record;
  };

  const navigate = (target: LocationDescriptor): void => {
    appendNavigationRecord(target);

    if (typeof target === 'string') {
      push(target);
    } else {
      push(target);
    }
  };

  const navigateBack = (
    key: string,
    defaultPath: string,
    modifyTargetLocation: (location: LocationDescriptorObject) => LocationDescriptorObject = (location) => location
  ): void => {
    const record: Undefinable<NavigationRecord> = findNavigationRecord(key);
    const backPath: LocationDescriptorObject = record ? modifyTargetLocation(record.from) : { pathname: defaultPath };

    push(backPath);
  };

  return <NavigationHistoryContext.Provider value={{ navigate, navigateBack }}>{children}</NavigationHistoryContext.Provider>;
};

export default NavigationHistoryProvider;
