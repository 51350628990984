import type { FC } from 'react';

import { Icon, TextLink } from '@zen/DesignSystem';
import { shipmentRoutes } from '@zen/Routes';
import type { RoadTrackedShipmentStatus } from '@zen/TrackedShipments/types';
import { codeToCountryName } from '@zen/utils/countries';
import { formatDateTimeRange } from '@zen/utils/dateTime';

import StatusPill from '../StatusPill';

interface Props {
  arrivalEstimateEndDateTime: string;
  arrivalEstimateStartDateTime: string;
  destinationCountryCode?: string;
  onClose: () => void;
  originCountryCode?: string;
  status: RoadTrackedShipmentStatus;
  zencargoReference: string;
}

const ShipmentInfo: FC<Props> = (props) => {
  const {
    arrivalEstimateStartDateTime,
    arrivalEstimateEndDateTime,
    destinationCountryCode,
    onClose,
    originCountryCode,
    status,
    zencargoReference
  } = props;
  const shipmentDetailsUrl: string = shipmentRoutes.shipmentDetailsPage.getUrl(zencargoReference);

  return (
    <div className="bg-white absolute rounded shadow-interaction p-6 top-4 left-4 min-w-72">
      <div className="flex items-center justify-between mb-2" data-testid="shipment-info">
        <TextLink className="mr-2 whitespace-nowrap text-lg font-bold" linkTo={shipmentDetailsUrl}>
          {zencargoReference}
        </TextLink>
        <div className="flex items-center ml-auto">
          <StatusPill status={status} />
          <Icon className="text-base ml-2" icon="zicon-close" interactive={true} onClick={onClose} />
        </div>
      </div>
      <div className="whitespace-nowrap text-sm text-grey-dark leading-snug mb-2">
        <div className="text-grey-base text-xs mb-1">Delivery est:</div>
        <div className="text-grey-dark">{formatDateTimeRange(arrivalEstimateStartDateTime, arrivalEstimateEndDateTime)}</div>
      </div>
      <div className="mt-3">{codeToCountryName(originCountryCode)}</div>
      <Icon className="text-2xs" icon="zicon-arrow-down" />
      <div>{codeToCountryName(destinationCountryCode)}</div>
    </div>
  );
};

export default ShipmentInfo;
