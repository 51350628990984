import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IntakePlanningExportPeriodsDataMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ExportPeriodsRowsDataInput;
}>;

export type IntakePlanningExportPeriodsDataMutationResult = { __typename?: 'Mutation' } & {
  intakePlanningExportPeriodsData?: GraphQLTypes.Maybe<
    { __typename?: 'ExportPeriodsRowsDataPayload' } & {
      exportedData?: GraphQLTypes.Maybe<{ __typename?: 'ExportedData' } & Pick<GraphQLTypes.ExportedData, 'id'>>;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const IntakePlanningExportPeriodsDataDocument = /* #__PURE__ */ gql`
  mutation intakePlanningExportPeriodsData($input: ExportPeriodsRowsDataInput!) {
    intakePlanningExportPeriodsData(input: $input) {
      exportedData {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useIntakePlanningExportPeriodsDataMutation__
 *
 * To run a mutation, you first call `useIntakePlanningExportPeriodsDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntakePlanningExportPeriodsDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intakePlanningExportPeriodsDataMutation, { data, loading, error }] = useIntakePlanningExportPeriodsDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntakePlanningExportPeriodsDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IntakePlanningExportPeriodsDataMutationResult,
    IntakePlanningExportPeriodsDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<IntakePlanningExportPeriodsDataMutationResult, IntakePlanningExportPeriodsDataMutationVariables>(
    IntakePlanningExportPeriodsDataDocument,
    options
  );
}
export type IntakePlanningExportPeriodsDataMutationHookResult = ReturnType<typeof useIntakePlanningExportPeriodsDataMutation>;
