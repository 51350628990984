import cx from 'classnames';
import type { FC } from 'react';

import { CargoWeightUnitEnum } from '@zen/Cargo';
import { FormSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';

import FormNumberInput from '../../../Components/Form/FormNumberInput';

const metricOptions: Option<CargoWeightUnitEnum>[] = [
  {
    label: 'kg',
    value: CargoWeightUnitEnum.KG
  },
  {
    label: 'lbs',
    value: CargoWeightUnitEnum.LBS
  }
];

interface Props {
  autoFocus?: boolean;
  className?: string;
  hideErrorMessage?: boolean;
  isNewCargo?: boolean;
  label?: string;
  name: string;
}

const FormCargoWeightInput: FC<Props> = ({
  autoFocus,
  className,
  hideErrorMessage,
  label = 'Gross weight',
  name,
  isNewCargo = false
}) => {
  const classNames: string = cx('flex', className);
  const unitFieldName: string = isNewCargo ? `${name}.unit` : `${name}.metric`;

  return (
    <div className={classNames}>
      <FormNumberInput
        autoFocus={autoFocus}
        className="w-64 mr-4"
        hideErrorMessage={hideErrorMessage}
        label={label}
        name={`${name}.value`}
        placeholder="0.0"
        step={1}
      />
      <FormSelect
        hideErrorMessage={hideErrorMessage}
        isSearchable={false}
        label="Unit"
        name={unitFieldName}
        options={metricOptions}
      />
    </div>
  );
};

export default FormCargoWeightInput;
