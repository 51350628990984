import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type CargoItemsQueryVariables = GraphQLTypes.Exact<{
  newCargoService: GraphQLTypes.Scalars['Boolean'];
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type CargoItemsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference' | 'modeOfTransport'> & {
              canManageCargo: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    cargoItems?: GraphQLTypes.Maybe<
                      Array<
                        | ({ __typename?: 'CargoContainerType' } & Pick<
                            GraphQLTypes.CargoContainerType,
                            | 'containerType'
                            | 'containerNumber'
                            | 'containerSealNumber'
                            | 'overweight'
                            | 'id'
                            | 'type'
                            | 'cbm'
                            | 'hazardous'
                            | 'reefer'
                            | 'looseCargoType'
                            | 'quantity'
                          > & {
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                            })
                        | ({ __typename?: 'CargoLooseCargoType' } & Pick<
                            GraphQLTypes.CargoLooseCargoType,
                            | 'containerNumber'
                            | 'palletType'
                            | 'stackable'
                            | 'trailerId'
                            | 'id'
                            | 'type'
                            | 'cbm'
                            | 'hazardous'
                            | 'reefer'
                            | 'looseCargoType'
                            | 'quantity'
                          > & {
                              chargeableWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                              dimensions?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoDimensionsType' } & Pick<
                                  GraphQLTypes.CargoDimensionsType,
                                  'length' | 'width' | 'height' | 'metric'
                                >
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                            })
                        | ({ __typename?: 'CargoVehicleType' } & Pick<
                            GraphQLTypes.CargoVehicleType,
                            | 'trailerId'
                            | 'vehicleType'
                            | 'riskLevel'
                            | 'tailLift'
                            | 'id'
                            | 'type'
                            | 'cbm'
                            | 'hazardous'
                            | 'reefer'
                            | 'looseCargoType'
                            | 'quantity'
                          > & {
                              dimensions?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoDimensionsType' } & Pick<
                                  GraphQLTypes.CargoDimensionsType,
                                  'length' | 'width' | 'height' | 'metric'
                                >
                              >;
                              valueOfGoods?: GraphQLTypes.Maybe<
                                { __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                            })
                      >
                    >;
                  }
              >;
              cargos?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'CoreCargo' } & Pick<
                    GraphQLTypes.CoreCargo,
                    | 'cargoType'
                    | 'containerNumber'
                    | 'containerSealNumber'
                    | 'id'
                    | 'hazardous'
                    | 'refrigerated'
                    | 'overweight'
                    | 'quantity'
                    | 'riskLevel'
                    | 'stackable'
                    | 'tailLift'
                    | 'trailerId'
                  > & {
                      cargoRelations?: GraphQLTypes.Maybe<
                        Array<
                          { __typename?: 'CargoRelation' } & Pick<GraphQLTypes.CargoRelation, 'startTimestamp'> & {
                              inputCargo?: GraphQLTypes.Maybe<
                                { __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'cargoType' | 'id' | 'quantity'>
                              >;
                            }
                        >
                      >;
                      chargeableWeight?: GraphQLTypes.Maybe<
                        { __typename?: 'CargoWeight' } & Pick<GraphQLTypes.CargoWeight, 'value' | 'unit'>
                      >;
                      dimensions?: GraphQLTypes.Maybe<
                        { __typename?: 'Dimensions' } & Pick<GraphQLTypes.Dimensions, 'width' | 'height' | 'length' | 'unit'>
                      >;
                      grossWeight?: GraphQLTypes.Maybe<
                        { __typename?: 'CargoWeight' } & Pick<GraphQLTypes.CargoWeight, 'value' | 'unit'>
                      >;
                      valueOfGoods?: GraphQLTypes.Maybe<
                        { __typename?: 'ValueOfGoods' } & Pick<GraphQLTypes.ValueOfGoods, 'value' | 'currency'>
                      >;
                      volume?: GraphQLTypes.Maybe<{ __typename?: 'Volume' } & Pick<GraphQLTypes.Volume, 'value' | 'unit'>>;
                    }
                >
              >;
            }
        >
      >
    >;
  };
};

export const CargoItemsDocument = /* #__PURE__ */ gql`
  query cargoItems($newCargoService: Boolean!, $zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        canManageCargo {
          value
        }
        cargo {
          cargoItems {
            id
            type
            cbm
            hazardous
            reefer
            looseCargoType
            quantity
            grossWeight {
              metric
              value
            }
            ... on CargoContainerType {
              containerType
              containerNumber
              containerSealNumber
              overweight
            }
            ... on CargoLooseCargoType {
              chargeableWeight {
                metric
                value
              }
              containerNumber
              palletType
              stackable
              dimensions {
                length
                width
                height
                metric
              }
              trailerId
            }
            ... on CargoVehicleType {
              trailerId
              vehicleType
              dimensions {
                length
                width
                height
                metric
              }
              riskLevel
              tailLift
              valueOfGoods {
                currency
                value
              }
            }
          }
          mode
        }
        cargos @include(if: $newCargoService) {
          cargoRelations {
            inputCargo {
              cargoType
              id
              quantity
            }
            startTimestamp
          }
          cargoType
          chargeableWeight {
            value
            unit
          }
          containerNumber
          containerSealNumber
          dimensions {
            width
            height
            length
            unit
          }
          id
          grossWeight {
            value
            unit
          }
          hazardous
          refrigerated
          overweight
          quantity
          riskLevel
          stackable
          tailLift
          trailerId
          valueOfGoods {
            value
            currency
          }
          volume {
            value
            unit
          }
        }
        modeOfTransport
      }
    }
  }
`;

/**
 * __useCargoItemsQuery__
 *
 * To run a query within a React component, call `useCargoItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCargoItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCargoItemsQuery({
 *   variables: {
 *      newCargoService: // value for 'newCargoService'
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useCargoItemsQuery(baseOptions: Apollo.QueryHookOptions<CargoItemsQueryResult, CargoItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CargoItemsQueryResult, CargoItemsQueryVariables>(CargoItemsDocument, options);
}
export function useCargoItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CargoItemsQueryResult, CargoItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CargoItemsQueryResult, CargoItemsQueryVariables>(CargoItemsDocument, options);
}
export type CargoItemsQueryHookResult = ReturnType<typeof useCargoItemsQuery>;
export type CargoItemsLazyQueryHookResult = ReturnType<typeof useCargoItemsLazyQuery>;
