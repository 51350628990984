import { RadioInput } from '@zen/DesignSystem';

import TimePicker from '../TimePicker';
import { Mode, SpecificTime } from '../types';

export interface TimedConsignmentValue {
  mode: Mode;
  specificTime?: SpecificTime;
}

interface TimedConsignmentProps {
  defaultSpecificTime: SpecificTime;
  onTimeChange: (newValue: TimedConsignmentValue) => void;
  timeRisk: (t: SpecificTime) => string;
  value: TimedConsignmentValue;
}

const TimedConsignment = ({ onTimeChange, value, defaultSpecificTime, timeRisk }: TimedConsignmentProps) => {
  const clickHandler = (v: TimedConsignmentValue) => {
    onTimeChange(v);
  };
  const valueWithDefault: TimedConsignmentValue = value || { mode: null };

  return (
    <>
      <div className="flex flex-row py-3 mt-3">
        <RadioInput
          checked={valueWithDefault.mode === Mode.AM}
          className="mr-4"
          data-testid="input-am"
          name="deliveryTime"
          onChange={() => clickHandler({ mode: Mode.AM })}
          value="am"
        >
          AM
        </RadioInput>
        <RadioInput
          checked={valueWithDefault.mode === Mode.PM}
          className="mr-4"
          data-testid="input-pm"
          name="deliveryTime"
          onChange={() => clickHandler({ mode: Mode.PM })}
          value="pm"
        >
          PM
        </RadioInput>
        <RadioInput
          checked={valueWithDefault.mode === Mode.SPECIFIC}
          data-testid="specific-time"
          name="deliveryTime"
          onChange={() => clickHandler({ mode: Mode.SPECIFIC, specificTime: defaultSpecificTime })}
          value="scheduled"
        >
          Schedule time
        </RadioInput>
      </div>
      {valueWithDefault.mode === Mode.SPECIFIC && (
        <>
          <div className="w-[500px] py-4">
            <TimePicker
              isTimeRisky={(t: SpecificTime) => !!timeRisk(t)}
              onSelect={(time) => clickHandler({ mode: Mode.SPECIFIC, specificTime: time })}
              selection={valueWithDefault.specificTime}
            />
          </div>
          <div className="text-red-base align-middle text-sm" data-testid="time-risk-warning">
            {valueWithDefault.specificTime && timeRisk(valueWithDefault.specificTime)}
          </div>
        </>
      )}
    </>
  );
};

export default TimedConsignment;
