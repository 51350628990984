import type { FC, SyntheticEvent } from 'react';
import { useHistory, useLocation } from 'react-router';

import ChevronIcon from '@zen/Components/Icons/ChevronIcon';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import type { PackingListOrder } from '@zen/PackingLists/PackingListDetails/types';

interface Props {
  expanded: boolean;
  onClick: () => void;
  order: PackingListOrder;
}

const PackingListLineOverview: FC<Props> = ({ expanded, onClick, order }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { id, orderReferenceNumber, manufacturerName } = order;
  const classNames: string = 'items-center p-4 font-medium hover:bg-azure-lightest cursor-pointer group grid grid-cols-6 gap-4';

  const handleOrderReferenceClick = (event: SyntheticEvent): void => {
    event.stopPropagation();

    push(`${pathname}/${id}`);
  };

  return (
    <div className={classNames} data-testid="packing-list-line-overview" onClick={onClick}>
      <LabelledValue label="PO number">
        <span
          className="break-all inline-block text-navy-base hover:underline cursor-pointer"
          onClick={handleOrderReferenceClick}
        >
          {orderReferenceNumber}
        </span>
      </LabelledValue>

      <LabelledValue className="col-span-4" label="Manufacturer">
        <ValueWithDefault value={manufacturerName} />
      </LabelledValue>

      <div className="text-right">
        <ChevronIcon className="text-sm" expanded={expanded} />
      </div>
    </div>
  );
};

export default PackingListLineOverview;
