import cx from 'classnames';
import type { FC, ReactNode, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@zen/DesignSystem';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import type { MenuItemType } from '..';
import Divider from '../Divider';

interface Props extends Omit<MenuItemType, 'onClick'> {
  onClick?: (e: SyntheticEvent) => void;
}

const MenuItem: FC<Props> = ({ className, disabled, icon, label, linkTo, onClick, addDivider = false, openInNewTab }) => {
  const iconClassNames: string = cx({ 'text-grey-dark': !disabled, 'text-grey-lighter': disabled }, 'mr-2');
  const { navigate } = useNavigationHistory();

  const itemContent: ReactNode = (
    <>
      {icon && <Icon className={iconClassNames} icon={icon} />}
      <div className="text-sm leading-normal">{label}</div>
    </>
  );

  const renderItem = (): ReactNode => {
    const classNames: string = cx(
      {
        'text-grey-dark active:bg-azure-light hover:bg-azure-lightest cursor-pointer': !disabled,
        'text-grey-lighter cursor-not-allowed': disabled
      },
      'flex py-1.5 leading-6 items-center pl-6 pr-14 font-normal my-1',
      className
    );

    const handleClick = (e: SyntheticEvent): void => {
      if (disabled) {
        return;
      }

      onClick?.(e);
    };

    if (linkTo && !disabled) {
      const target: string | undefined = openInNewTab ? '_blank' : undefined;

      if (openInNewTab) {
        return (
          <Link className={classNames} onClick={handleClick} target={target} to={linkTo}>
            {itemContent}
          </Link>
        );
      }

      const handleInternalLinkClick = (e: SyntheticEvent) => {
        handleClick(e);
        if (typeof linkTo === 'string') {
          navigate(linkTo);
        } else {
          navigate(linkTo);
        }
      };

      return (
        <a className={classNames} onClick={handleInternalLinkClick}>
          {itemContent}
        </a>
      );
    }

    return (
      <div className={classNames} onClick={handleClick}>
        {itemContent}
      </div>
    );
  };

  return (
    <>
      <div className="cursor-pointer" data-component="context-menu-item">
        {renderItem()}
      </div>
      {addDivider && <Divider />}
    </>
  );
};

export default MenuItem;
