import { get, isEmpty, size } from 'lodash';
import type { FC, ReactElement } from 'react';
import { useParams } from 'react-router';

import { CargoModeEnum } from '@zen/Cargo';
import {
  useCreateLooseCargoItemMutation,
  useCreateVehicleCargoItemMutation,
  useUpdateLooseCargoItemMutation,
  useUpdateVehicleCargoItemMutation
} from '@zen/Cargo/graphql';
import { useUpdateCollectionMutation, useUpdateDeliveryMutation } from '@zen/CollectionAndDelivery/graphql';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { accrualRoutes, opsShipmentRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import {
  useAddDelayReasonsMutation,
  useGetOperationsRoadShipmentQuery,
  useUpdateCollectionDatesMutation,
  useUpdateDeliveryDatesMutation
} from '../graphql';
import { useUpdateRoadShipmentMutation } from '../graphql/updateRoadShipment.generated';
import { RoadShipmentTrackingAction, RoadShipmentTrackingCategory } from '../RoadShipments/types';
import {
  getDirtyFields,
  getEditedFieldNames,
  getEmptyCollectionDates,
  getEmptyDeliveryDates,
  hasCollectionDetailsChanged,
  hasDeliveryDetailsChanged,
  prepareBookingInput,
  prepareCollectionDatesPayload,
  prepareCollectionPayload,
  prepareCreateLooseCargoItemPayload,
  prepareCreateVehicleCargoItemPayload,
  prepareDelayReasons,
  prepareDeliveryDatesPayload,
  prepareDeliveryPayload,
  prepareInitialValues,
  prepareUpdateLooseCargoItemPayload,
  prepareUpdateVehicleCargoItemPayload
} from './helpers';
import RoadShipmentForm from './RoadShipmentForm';
import SkeletonEditRoadShipmentLoading from './RoadShipmentForm/Loading/SkeletonEditRoadShipmentLoading';
import { buildRoadTrackingMetricTrackingEvent } from './trackingHelpers';
import type { CargoItem, CollectionDates, DeliveryDates, RoadShipment, RoadShipmentFields } from './types';
import { RoadShipmentInput, VoyageDelayDataInput, VoyageMilestoneNameEnum } from './types';

const EditRoadShipment: FC = () => {
  const { id: zencargoReference } = useParams<{ id: string }>();
  const { accountUuid } = useAccount();
  const { trackEvent } = useTracking();
  const { navigateBack, navigate } = useNavigationHistory();

  const { addError, addSuccess } = useNotification();
  const { data, error, loading } = useGetOperationsRoadShipmentQuery({
    variables: { zencargoReference },
    fetchPolicy: 'cache-and-network'
  });
  const [updateRoadShipment] = useUpdateRoadShipmentMutation();
  const [createLooseCargoItem] = useCreateLooseCargoItemMutation();
  const [createVehicleCargoItem] = useCreateVehicleCargoItemMutation();
  const [updateLooseCargoItem] = useUpdateLooseCargoItemMutation();
  const [updateVehicleCargoItem] = useUpdateVehicleCargoItemMutation();
  const [updateCollection] = useUpdateCollectionMutation();
  const [updateDelivery] = useUpdateDeliveryMutation();
  const [updateCollectionDates] = useUpdateCollectionDatesMutation();
  const [updateDeliveryDates] = useUpdateDeliveryDatesMutation();
  const [addDelayReasons] = useAddDelayReasonsMutation();

  const shipmentData: Optional<RoadShipment> = data?.bookings?.nodes?.[0];
  const initialValues: Optional<RoadShipmentFields> = prepareInitialValues(shipmentData);
  const roadShipmentsUrl: string = opsShipmentRoutes.roadShipments.getUrl();
  const accrualsUrl: string = accrualRoutes.accrualIndex.getUrl(accountUuid, zencargoReference);

  const handleCancel = (): void => navigateBack(roadShipmentsUrl);

  const getCargoMutationFn = (cargoMode: CargoModeEnum, cargo: CargoItem) => {
    const action: string = cargo.id ? 'update' : 'create';

    const cargoMutationMapping = {
      [`${CargoModeEnum.FTL}_update`]: () =>
        updateVehicleCargoItem({ variables: { input: prepareUpdateVehicleCargoItemPayload(cargo) } }),
      [`${CargoModeEnum.FTL}_create`]: () =>
        createVehicleCargoItem({ variables: { input: prepareCreateVehicleCargoItemPayload(cargo, zencargoReference) } }),
      [`${CargoModeEnum.LOOSE}_update`]: () =>
        updateLooseCargoItem({ variables: { input: prepareUpdateLooseCargoItemPayload(cargo) } }),
      [`${CargoModeEnum.LOOSE}_create`]: () =>
        createLooseCargoItem({ variables: { input: prepareCreateLooseCargoItemPayload(cargo, zencargoReference) } })
    };

    return cargoMutationMapping[`${cargoMode}_${action}`];
  };

  const trackEditedFields = (values: RoadShipmentFields, initializedValues: Optional<RoadShipmentFields>): void => {
    const editedFields: string[] = getEditedFieldNames(values, initializedValues);

    trackEvent({
      action: RoadShipmentTrackingAction.ROAD_SHIPMENT_EDITED,
      category: RoadShipmentTrackingCategory,
      label: zencargoReference,
      properties: {
        zencargoReference,
        numberOfEditedFields: editedFields.length,
        editedFields
      }
    });
  };

  const trackRoadTrackingMetrics = (values: RoadShipmentFields): void => {
    const { cargoItem, collection, delivery, vehiclePlateNumber } = values;
    const cargoId: string = cargoItem.id;

    const trackingEvent = buildRoadTrackingMetricTrackingEvent(zencargoReference, cargoId, {
      collectionConfirmedDate: collection.confirmedByForwarder,
      collectionRequestedDate: collection.requestedWithForwarder,
      deliveryConfirmedDate: delivery.confirmedByForwarder,
      vehiclePlateNumber
    });

    trackEvent(trackingEvent);
  };

  const handleSubmit = async (values: RoadShipmentFields): Promise<IOkOrErrorResult> => {
    const dirtyFields: Partial<RoadShipmentFields> = getDirtyFields<RoadShipmentFields>(values, initialValues);
    const bookingInput: RoadShipmentInput = prepareBookingInput(dirtyFields);
    const hasBookingDataChanged: boolean = size(bookingInput) > 0;
    const hasCargoDataChanged: boolean = !!dirtyFields.cargoItem;
    const hasCollectionDatesChanged: boolean = !!dirtyFields.collection;
    const hasDeliveryDatesChanged: boolean = !!dirtyFields.delivery;
    const collectionDelays: Array<VoyageDelayDataInput> = prepareDelayReasons([values.collection.collectedOn]);
    const deliveryDelays: Array<VoyageDelayDataInput> = prepareDelayReasons([values.delivery.deliveredOn]);

    let hasError = false;
    let cargoId: string = values.cargoItem.id || '';

    if (hasBookingDataChanged) {
      await performMutation({
        mutationFn: () =>
          updateRoadShipment({
            variables: {
              input: {
                zencargoReference,
                bookingInput
              }
            }
          }),
        onError: () => {
          hasError = true;

          addError();
        }
      });
    }

    if (hasCargoDataChanged && values.cargoMode) {
      const result = await performMutation({
        mutationFn: getCargoMutationFn(values.cargoMode, values.cargoItem),
        onError: () => {
          hasError = true;

          addError();
        }
      });

      // if we create a cargo item, we need to grab the cargo id
      // and pass it to the mutations below
      cargoId = get(result, `ok.${Object.keys(result.ok || {})[0]}.cargoItem.id`);
    }

    if (hasCollectionDetailsChanged(dirtyFields)) {
      await performMutation({
        mutationFn: () =>
          updateCollection({
            variables: {
              input: {
                zencargoReference,
                cargoId,
                ...prepareCollectionPayload(dirtyFields)
              }
            }
          }),
        onError: () => {
          hasError = true;

          addError();
        }
      });
    }

    if (!isEmpty(collectionDelays)) {
      await performMutation({
        mutationFn: () =>
          addDelayReasons({
            variables: {
              input: { zencargoReference, milestoneName: VoyageMilestoneNameEnum.COLLECTED, delayData: collectionDelays }
            }
          }),
        onError: () => {
          hasError = true;

          addError();
        }
      });
    }

    if (!isEmpty(deliveryDelays)) {
      await performMutation({
        mutationFn: () =>
          addDelayReasons({
            variables: {
              input: { zencargoReference, milestoneName: VoyageMilestoneNameEnum.DELIVERED, delayData: deliveryDelays }
            }
          }),
        onError: () => {
          hasError = true;

          addError();
        }
      });
    }

    if (hasDeliveryDetailsChanged(dirtyFields)) {
      await performMutation({
        mutationFn: () =>
          updateDelivery({
            variables: {
              input: {
                zencargoReference,
                cargoId,
                ...prepareDeliveryPayload(dirtyFields)
              }
            }
          }),
        onError: () => {
          hasError = true;

          addError();
        }
      });
    }

    if (hasCollectionDatesChanged) {
      const initialCollectionDates: Optional<CollectionDates> = values.collection.clearSchedule
        ? getEmptyCollectionDates()
        : initialValues?.collection;

      const collectionDates: CollectionDates = getDirtyFields(values.collection, initialCollectionDates) as CollectionDates;

      await performMutation({
        mutationFn: () =>
          updateCollectionDates({
            variables: {
              input: prepareCollectionDatesPayload(collectionDates, zencargoReference, cargoId)
            }
          }),
        onError: () => {
          hasError = true;

          addError();
        }
      });
    }

    if (hasDeliveryDatesChanged) {
      const initialDeliveryDates: Optional<DeliveryDates> = values.delivery.clearSchedule
        ? getEmptyDeliveryDates()
        : initialValues?.delivery;

      const deliveryDates: DeliveryDates = getDirtyFields(values.delivery, initialDeliveryDates) as DeliveryDates;

      await performMutation({
        mutationFn: () =>
          updateDeliveryDates({
            variables: {
              input: prepareDeliveryDatesPayload(deliveryDates, zencargoReference, cargoId)
            }
          }),
        onError: () => {
          hasError = true;

          addError();
        }
      });
    }

    trackEditedFields(values, initialValues);

    return Promise.resolve({ ok: hasError ? null : {}, error: hasError });
  };

  const handleSuccess = (redirectToVAs: boolean, values: RoadShipmentFields): void => {
    trackRoadTrackingMetrics(values);
    addSuccess('Shipment has been updated');
    if (redirectToVAs) {
      navigate(accrualsUrl);
    } else {
      navigateBack(roadShipmentsUrl);
    }
  };

  return (
    <Page
      contentClassName="py-8 px-10"
      stickyHeader={true}
      tagline="Add or edit details for this road shipment."
      title={`Edit ${zencargoReference}`}
      width="full"
    >
      <QueryHandler
        data={shipmentData}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonEditRoadShipmentLoading />}
      >
        {(): ReactElement => (
          <RoadShipmentForm
            initialValues={initialValues}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
          />
        )}
      </QueryHandler>
    </Page>
  );
};

export default EditRoadShipment;
