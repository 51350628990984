import { useAllPaginatedResults } from '@zen/utils/hooks/pagination';

import {
  RoadTrackedShipment,
  RoadTrackedShipmentsDocument,
  RoadTrackedShipmentsQueryResult,
  RoadTrackedShipmentsQueryVariables
} from '../graphql';
import { RoadTrackedShipmentParams, RoadTrackedShipmentStatus } from '../types';

export const useRoadTrackedShipments = (variables: RoadTrackedShipmentParams) => {
  const { status, ...rest } = variables;

  return useAllPaginatedResults<RoadTrackedShipmentsQueryResult, RoadTrackedShipmentsQueryVariables, RoadTrackedShipment>({
    document: RoadTrackedShipmentsDocument,
    responsePath: 'roadTrackedShipments',
    variables: { ...rest, status: status || RoadTrackedShipmentStatus.ON_TIME },
    skip: !status
  });
};
