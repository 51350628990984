import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Route } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import NoResults from '@zen/Components/NoResults';
import QueryHandler from '@zen/Components/QueryHandler';
import Search from '@zen/Components/Search';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import type { TableColumn } from '@zen/DesignSystem';
import { Pagination, Table } from '@zen/DesignSystem';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import { consolidationRoutes } from '@zen/Routes';
import type { ShipmentDataFiltersInput } from '@zen/Shipments';
import { ShipmentsDataExportButton } from '@zen/Shipments';

import { getConsolidationShipmentsColumnsConfiguration } from './consolidationShipmentsTableConfiguration';
import useConsolidationShipments from './hooks/useConsolidationShipments';
import type { ConsolidationShipment } from './types';

const ConsolidationShipments: FC = () => {
  const { check } = useGlobalPermissions();
  const [searchText, setSearchText] = useState<string>('');
  const { nodes, totalCount, loading: isLoading, error, paginationInfo } = useConsolidationShipments(searchText);

  const canExportData: boolean = check('bookings.canExport');
  const canManageShipments: boolean = check('consolidation.canManageConsolidatedShipment');
  const { consolidationId } = useParams<{ consolidationId: string }>();

  const noResults: ReactNode = searchText ? (
    <NoResults headline="No results found." tagline="Please change your search query and try again" />
  ) : (
    <NoResults headline="You don’t have any shipments in this consolidation" tagline="Please add a couple to consolidate" />
  );

  const columns: TableColumn<ConsolidationShipment>[] = getConsolidationShipmentsColumnsConfiguration(
    canManageShipments,
    consolidationId
  );
  const exportDataFilters: ShipmentDataFiltersInput = { consolidatedShipmentId: consolidationId };

  return (
    <>
      <div className="flex mb-6">
        <div className="w-full">
          <Search onClear={() => setSearchText('')} onSubmit={setSearchText} />
        </div>
        {canExportData && <ShipmentsDataExportButton<ConsolidationShipment> columns={columns} dataFilters={exportDataFilters} />}
      </div>

      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={isLoading}
        loadingComponent={<SkeletonTableLoading columns={columns} tableId="consolidationShipments" />}
        noResults={noResults}
      >
        {(consolidationShipments: ConsolidationShipment[]) => (
          <div className="mt-4">
            <Table<ConsolidationShipment>
              columns={columns}
              data={consolidationShipments}
              tableId="consolidationShipments"
              totalCountConfig={{
                totalCount,
                entityName: 'shipment'
              }}
            />
            <Pagination pageInfo={paginationInfo} />
          </div>
        )}
      </QueryHandler>
      <Route path={consolidationRoutes.consolidationShipmentsOrderDetailsPage.path}>
        <OrderDetailsContainer originPath={consolidationRoutes.consolidationShipments.path} />
      </Route>
    </>
  );
};

export default ConsolidationShipments;
