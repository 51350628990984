import cx from 'classnames';
import { truncate } from 'lodash';
import moment from 'moment';
import { FC, useState } from 'react';
import { useHistory } from 'react-router';

import { bookingRequestTitleMapping } from '@zen/ActivityFeed/ActivityFeedItem/BookingItem';
import { Icon } from '@zen/DesignSystem';
import { shipmentRoutes } from '@zen/Routes';
import orderRoutes from '@zen/Routes/orders';
import { ShipmentPageSectionUrl } from '@zen/Shipment/ShipmentDetailsPage/types';
import type { IconName } from '@zen/Styleguide';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';

import { NotificationsTargetTypeEnum, TargetNotifications } from '../../types';

interface Props {
  displayText?: string;
  notification: TargetNotifications;
  onClick: () => void;
  targetReference: string;
}

const NotificationListItem: FC<Props> = (props) => {
  const { targetReference, displayText, onClick } = props;
  const [notification, setNotification] = useState<TargetNotifications>(props.notification);
  const { unreadNotificationIds, lastNotificationItem } = notification;
  const unreadNofificationCount = unreadNotificationIds.length;
  const fromNow = moment(lastNotificationItem?.createdAt).fromNow();
  const history = useHistory();
  const itemLabelMapping = { ...bookingRequestTitleMapping };

  const handleClick = async () => {
    setNotification({
      ...notification,
      unreadNotificationIds: []
    });

    handleRedirect();

    onClick();
  };

  const notificationsBookingLink: string = shipmentRoutes.shipmentDetailsPage.getUrl(
    notification.targetId,
    ShipmentPageSectionUrl.ACTIVITY_FEED_EXPANDED
  );

  const handleRedirect = () => {
    switch (notification.targetType) {
      case NotificationsTargetTypeEnum.BOOKING:
        history.push(notificationsBookingLink);
        break;
      case NotificationsTargetTypeEnum.PURCHASE_ORDER:
        history.push(`${orderRoutes.orderList.getUrl()}/${notification.targetId}`);
        break;
    }
  };

  const hasUnreadMessages: boolean = notification.unreadNotificationIds.length > 0;
  const notificationTracking: string = `fs-notification-item-${lastNotificationItem?.itemId}`;

  const itemClasses: string = cx(
    {
      'hover:bg-grey-lightest': hasUnreadMessages
    },
    'list-item hover:shadow-overlay cursor-pointer',
    notificationTracking
  );

  const textClasses: string = cx(
    {
      'font-bold': hasUnreadMessages
    },
    'text-grey-dark text-sm leading-normal'
  );

  const icon: IconName = hasUnreadMessages ? 'zicon-mail' : 'zicon-mail-open';

  const formattedDisplayText = (): string => {
    if (displayText) {
      const text = truncate(displayText, { length: 30 });

      if (lastNotificationItem.user) {
        const {
          user: { firstName, lastName }
        } = lastNotificationItem;
        const initials = `${firstName} ${lastName.charAt(0).toUpperCase()}`;

        return `${initials}.: ${text}`;
      }

      return text;
    }

    const { itemType } = lastNotificationItem;

    return itemLabelMapping[itemType] || removeSpecialCharactersAndUpperFirst(itemType);
  };

  return (
    <div className={itemClasses} data-testid="notification-list-item" onClick={handleClick}>
      <div className="border-b border-solid border-grey-lighter flex py-4 px-5">
        <div className="mt-1 pr-4 text-navy-base relative">
          <Icon icon={icon} />
          {hasUnreadMessages && (
            <div className="absolute w-1.5 h-1.5 box-content top-0.5 right-2 bg-azure-base border-solid border-white border-2 rounded-full" />
          )}
        </div>
        <div className="flex-1 min-w-0">
          <div className={textClasses}>
            {unreadNofificationCount === 0 && 'No new updates'}
            {unreadNofificationCount === 1 && 'New update'}
            {unreadNofificationCount > 1 && `${unreadNofificationCount} new updates`}
            &nbsp;in&nbsp;
            {targetReference}
          </div>
          <div className="text-xs">
            <div className="pt-1 text-grey-base">{fromNow}</div>
            <div className="pt-2 truncate">{formattedDisplayText()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationListItem;
