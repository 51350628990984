import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AssignTransportScheduleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.AssignTransportScheduleInput;
}>;

export type AssignTransportScheduleMutationResult = { __typename?: 'Mutation' } & {
  assignTransportSchedule?: GraphQLTypes.Maybe<
    { __typename?: 'AssignTransportSchedulePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const AssignTransportScheduleDocument = /* #__PURE__ */ gql`
  mutation assignTransportSchedule($input: AssignTransportScheduleInput!) {
    assignTransportSchedule(input: $input) {
      errors {
        message
      }
    }
  }
`;

/**
 * __useAssignTransportScheduleMutation__
 *
 * To run a mutation, you first call `useAssignTransportScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTransportScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTransportScheduleMutation, { data, loading, error }] = useAssignTransportScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignTransportScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignTransportScheduleMutationResult, AssignTransportScheduleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<AssignTransportScheduleMutationResult, AssignTransportScheduleMutationVariables>(
    AssignTransportScheduleDocument,
    options
  );
}
export type AssignTransportScheduleMutationHookResult = ReturnType<typeof useAssignTransportScheduleMutation>;
