import { FC, ReactNode, useState } from 'react';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Button, Modal, Pagination, Table } from '@zen/DesignSystem';
import OperationsSettingsSearch from '@zen/OperationsSettings/OperationsSettingsSearch';
import { productSettingsRoutes } from '@zen/Routes';
import { SortingOrder, SortInput } from '@zen/types';
import usePagination from '@zen/utils/hooks/pagination/usePagination';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import EditTerminal from './EditTerminal';
import { GetTerminalsQueryResult, GetTerminalsQueryVariables, useGetTerminalsQuery } from './graphql';
import { getManageTerminalsColumnsConfiguration } from './manageTerminalsColumnConfiguration';
import type { Terminal } from './types';
import UploadTerminals from './UploadTerminals';

const initialOrder: SortInput = {
  field: 'name',
  direction: SortingOrder.ASC
};

const ManageTerminals: FC = () => {
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [currentEditTerminal, setCurrentEditTerminal] = useState<Terminal>();
  const { navigate } = useNavigationHistory();

  const { loading, error, nodes, paginationInfo, totalCount, refetch } = usePagination<
    GetTerminalsQueryResult,
    GetTerminalsQueryVariables,
    Terminal
  >(
    useGetTerminalsQuery,
    'terminals',
    {
      query: searchQuery,
      order
    },
    20
  );

  const handleEditTerminal = (terminal: Terminal): void => {
    setShowEditModal(true);
    setCurrentEditTerminal(terminal);
  };

  const handleCloseEditModal = (): void => {
    setShowEditModal(false);
    setCurrentEditTerminal(undefined);
  };

  const handleCloseUploadModal = (): void => setShowUploadModal(false);

  const handleImportTerminalCodes = (): void => {
    navigate(productSettingsRoutes.unloCodeTerminals.getUrl());
  };

  const importTerminalCodesButton: ReactNode = (
    <>
      <Button className="mr-5" onClick={handleImportTerminalCodes} variant="secondary">
        Lookup UNLOCODE
      </Button>

      <Button iconLeft="zicon-upload" onClick={() => setShowUploadModal(true)}>
        Import terminal data
      </Button>
    </>
  );

  const columns = getManageTerminalsColumnsConfiguration(handleEditTerminal);

  return (
    <Page
      actionButtons={importTerminalCodesButton}
      defaultBackUrl={productSettingsRoutes.productSettingsIndex.getUrl()}
      tagline="Manage all terminals within the Zencargo system."
      title="Terminals"
    >
      <>
        <OperationsSettingsSearch onSearch={setSearchQuery} placeholder="Search for a terminal..." />
        <QueryHandler
          data={nodes}
          error={!!error}
          isLoading={loading}
          loadingComponent={<SkeletonTableLoading columns={columns} order={order} tableId="manageTerminalsList" />}
        >
          {(terminals: Terminal[]) => {
            return (
              <>
                <Table<Terminal>
                  columns={columns}
                  data={terminals}
                  onOrderChange={setOrder}
                  order={order}
                  tableId="manageTerminalsList"
                  totalCountConfig={{
                    totalCount,
                    entityName: 'terminal'
                  }}
                />
                <Pagination pageInfo={paginationInfo} />
              </>
            );
          }}
        </QueryHandler>
        {currentEditTerminal && (
          <Modal isOpen={showEditModal} onClose={handleCloseEditModal} title="Edit terminal">
            <EditTerminal onClose={handleCloseEditModal} onSuccess={refetch} terminal={currentEditTerminal} />
          </Modal>
        )}

        <Modal isOpen={showUploadModal} onClose={handleCloseUploadModal} title="Upload terminals">
          <UploadTerminals onClose={handleCloseUploadModal} onComplete={refetch} />
        </Modal>
      </>
    </Page>
  );
};

export default ManageTerminals;
