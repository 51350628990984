import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import { ModeOfTransport } from '@zen/types';

interface Props {
  className?: string;
  mode: ModeOfTransport;
}

const TransportModeIcon: FC<Props> = ({ mode, className }) => {
  const modeOfTransportIconMapping: Record<ModeOfTransport, IconName> = {
    [ModeOfTransport.RAIL]: 'zicon-rail',
    [ModeOfTransport.TRUCK]: 'zicon-road',
    [ModeOfTransport.AIR]: 'zicon-air',
    [ModeOfTransport.OCEAN]: 'zicon-ship'
  };

  return <Icon className={className} icon={modeOfTransportIconMapping[mode]} />;
};

export type { Props as TransportModeIconProps };

export default TransportModeIcon;
