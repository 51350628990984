import type { FC } from 'react';

import {
  useRemoveCollectionScheduleMutation,
  useRemoveDeliveryScheduleMutation,
  useRequireCollectionScheduleMutation,
  useRequireDeliveryScheduleMutation
} from '@zen/Cargo/graphql';
import { Switch } from '@zen/DesignSystem';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

interface CollectionAndDeliveryPayload {
  input: {
    accountUuid: string;
    zencargoReference: string;
  };
}

interface Props {
  collectionScheduleRequired: boolean;
  deliveryScheduleRequired: boolean;
  onChange: () => void;
  zencargoReference: string;
}

const CollectionAndDeliveryScheduling: FC<Props> = (props) => {
  const { collectionScheduleRequired, deliveryScheduleRequired, onChange, zencargoReference } = props;
  const { accountUuid } = useAccount();
  const { addError, addSuccess } = useNotification();

  const [requireCollectionSchedule] = useRequireCollectionScheduleMutation();
  const [requireDeliverySchedule] = useRequireDeliveryScheduleMutation();
  const [removeCollectionSchedule] = useRemoveCollectionScheduleMutation();
  const [removeDeliverySchedule] = useRemoveDeliveryScheduleMutation();

  const variables: CollectionAndDeliveryPayload = {
    input: {
      accountUuid,
      zencargoReference
    }
  };

  const refetchOptions = {
    refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
    awaitRefetchQueries: true
  };

  const handleSuccess = (successMessage: string) => {
    addSuccess(successMessage);

    onChange();
  };

  const handleCollectionScheduleChange = (checked: boolean): void => {
    const mutateFn = checked ? requireCollectionSchedule : removeCollectionSchedule;
    const successMessage = checked
      ? 'Collection scheduling has been switched on.'
      : 'Collection scheduling has been switched off.';

    performMutation({
      mutationFn: () => mutateFn({ variables, ...refetchOptions }),
      onError: addError,
      onSuccess: () => handleSuccess(successMessage)
    });
  };

  const handleDeliveryScheduleChange = (checked: boolean): void => {
    const mutateFn = checked ? requireDeliverySchedule : removeDeliverySchedule;
    const successMessage = checked ? 'Delivery scheduling has been switched on.' : 'Delivery scheduling has been switched off.';

    performMutation({
      mutationFn: () => mutateFn({ variables, ...refetchOptions }),
      onError: addError,
      onSuccess: () => handleSuccess(successMessage)
    });
  };

  return (
    <div data-testid="collection-and-delivery-scheduling">
      <div className="text-grey-base text-sm mb-3">Scheduling</div>
      <div className="flex mr-4 items-center mb-4">
        <Switch
          className="mr-2"
          isChecked={collectionScheduleRequired}
          label="Collection"
          name="collection-scheduling"
          onChange={handleCollectionScheduleChange}
        />
      </div>
      <div className="flex items-center">
        <Switch
          className="mr-2"
          isChecked={deliveryScheduleRequired}
          label="Delivery"
          name="delivery-scheduling"
          onChange={handleDeliveryScheduleChange}
        />
      </div>
    </div>
  );
};

export default CollectionAndDeliveryScheduling;
