import type { FC, ReactNode } from 'react';

import CargoTypeImage from '@zen/Cargo/components/CargoTypeImage';
import FormCargoWeightInput from '@zen/Cargo/forms/FormCargoWeightInput';
import { looseNewCargoOptions } from '@zen/Cargo/helpers';
import { Form, FormInput, FormInstance, FormNumberInput, FormSelect } from '@zen/Components/Form';
import { FormObserver } from '@zen/Components/Form/FormObserver';
import FormSwitch from '@zen/Components/Form/FormSwitch';
import { cargoValues } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { CargoUpdateMutationResponse, FullContainerFormData } from '../../types';
import { containerOptions, mergeInitialValues } from './helpers';
import { isActualCbmValid, validationSchema } from './validation';

export interface Props {
  className?: string;
  formButtons?: (formInstance: FormInstance<FullContainerFormData>) => ReactNode;
  initialValues: Partial<FullContainerFormData>;
  onChange?: (isDirty: boolean) => void;
  onSubmit: (values: FullContainerFormData) => Promise<IOkOrErrorResult>;
  onSuccess?: ({ cargoItem }: CargoUpdateMutationResponse) => void;
  showImage?: boolean;
}

const FullContainerForm: FC<Props> = (props) => {
  const { className, formButtons, initialValues, onChange, onSubmit, showImage = true, onSuccess } = props;

  const renderForm = ({ dirty, values }: FormInstance<FullContainerFormData>) => {
    const { actualCbm, containerType, looseCargoType } = values;

    const showWarningMessage: boolean = !!actualCbm && actualCbm > 0 && !isActualCbmValid(containerType, actualCbm);
    const showQuantity: boolean = !!looseCargoType && looseCargoType !== cargoValues.looseAssortedCargo;
    const warning: string = showWarningMessage ? 'Value entered is greater than container capacity.' : '';

    return (
      <>
        {showImage && containerType && (
          <CargoTypeImage cargoType={containerType} className="absolute -top-10 h-18" isNewCargo={true} />
        )}
        <div className="flex">
          <FormSelect
            className="w-64 mr-4"
            isRequired={true}
            label="Container type"
            name="containerType"
            options={containerOptions}
          />
          <FormInput className="w-48 mr-4" label="Container number" name="containerNumber" />
          <FormInput className="w-48" label="Seal number" name="containerSealNumber" />
        </div>
        <div className="flex">
          <FormSwitch className="mr-12" label="Hazardous" name="hazardous" />
          <FormSwitch className="mr-12" label="Refrigerated" name="refrigerated" />
          <FormSwitch label="Overweight" name="overweight" />
        </div>
        <div className="flex">
          <FormSelect className="w-64 mr-4" label="Loose cargo type" name="looseCargoType" options={looseNewCargoOptions} />
          {showQuantity && <FormNumberInput className="w-48 mr-4" label="Piece count" name="quantity" />}
          <FormNumberInput className="w-48" label="CBM" name="actualCbm" placeholder="0.0" warning={warning} />
        </div>
        <div className="flex">
          <FormCargoWeightInput className="w-64" isNewCargo={true} name="grossWeight" />
        </div>
        <FormObserver onChange={onChange} value={dirty} />
      </>
    );
  };

  return (
    <Form
      className={className}
      enableReinitialize={true}
      formButtons={formButtons}
      formName="FullContainerForm"
      initialValues={mergeInitialValues(initialValues)}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {renderForm}
    </Form>
  );
};

export default FullContainerForm;
