import './switcher.css';

import cx from 'classnames';
import type { FC, ReactElement, ReactNode } from 'react';
import SwitchComponent from 'react-switch';

import { Color } from '@zen/Styleguide';

interface Props {
  className?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
  name?: string;
  onChange?: (isChecked: boolean) => void;
}

const Switch: FC<Props> = ({ className, isDisabled, isChecked, label, name, onChange }) => {
  const handleChange = (): void => {
    if (isDisabled) {
      return;
    }

    onChange?.(!isChecked);
  };

  const classNames: string = cx({
    className: !!label
  });

  const wrapWithLabel = (children: ReactElement, containerClassName: string | undefined): ReactElement => {
    if (!label) {
      return (
        <span className="inline-block" data-component="switch">
          {children}
        </span>
      );
    }

    const labelClassNames: string = cx(
      {
        'cursor-not-allowed text-grey-base': isDisabled,
        'cursor-pointer text-grey-dark': !isDisabled
      },
      'text-sm leading-normal',
      'ml-4'
    );

    return (
      <label
        className={`inline-flex items-center ${containerClassName}`}
        data-component="switch"
        data-testid="switch-label-container"
      >
        {children}
        <span className={labelClassNames}>{label}</span>
      </label>
    );
  };

  const additionalAttrs = label ? {} : { 'data-component': 'switch' };

  return wrapWithLabel(
    <SwitchComponent
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      checked={isChecked}
      checkedIcon={false}
      className={classNames}
      data-testid="switch"
      disabled={isDisabled}
      handleDiameter={14}
      height={16}
      id={name}
      name={name}
      offColor={isDisabled ? Color.GREY_LIGHTEST : Color.GREY_LIGHT}
      offHandleColor={isDisabled ? Color.GREY_LIGHT : Color.WHITE}
      onChange={handleChange}
      onColor={Color.AZURE_BASE}
      uncheckedIcon={false}
      width={32}
      {...additionalAttrs}
    />,
    className
  );
};

export type { Props as SwitchProps };

export default Switch;
