import classnames from 'classnames';
import type { FC, ReactElement } from 'react';

import { Counter } from '@zen/DesignSystem';

interface Props {
  active: boolean;
  className?: string;
  onClick?: () => void;
  totalCount?: number | string;
}

const Tab: FC<Props> = ({ active, onClick, children, totalCount, className }) => {
  const tabClassName = classnames(
    {
      'text-grey-dark border-azure-base active-tab': active
    },
    {
      'text-grey-base': !active
    },
    'text-sm pb-1 mr-6 font-medium cursor-pointer border-b-2 inline-flex',
    'border-solid border-transparent whitespace-nowrap',
    'transition-all duration-150 ease-linear hover:border-azure-base group',
    'items-center',
    className
  );

  const renderCountBadge = (count: number | string): ReactElement => {
    const counterType = active ? 'selected' : 'deselected';
    const counterClassNames = 'transition-all duration-150 ease-linear';

    return <Counter className="ml-2" counterClassNames={counterClassNames} type={counterType} value={count} />;
  };

  return (
    <div className={tabClassName} data-component="tab" data-testid="tab" onClick={onClick}>
      {children}
      {!!totalCount && renderCountBadge(totalCount)}
    </div>
  );
};

export default Tab;
