import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetOperationsCoordinatorListQueryVariables = GraphQLTypes.Exact<{
  accountId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetOperationsCoordinatorListQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
                operationsManager?: GraphQLTypes.Maybe<
                  { __typename?: 'NetworksOrgLocContact' } & Pick<
                    GraphQLTypes.NetworksOrgLocContact,
                    'id' | 'firstName' | 'lastName'
                  >
                >;
              }
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const GetOperationsCoordinatorListDocument = /* #__PURE__ */ gql`
  query getOperationsCoordinatorList(
    $accountId: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchQuery: String
    $order: SortInput
  ) {
    bookings(
      customerUuid: $accountId
      after: $after
      before: $before
      first: $first
      last: $last
      textContains: $searchQuery
      order: $order
    ) {
      nodes {
        zencargoReference
        operationsManager {
          id
          firstName
          lastName
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetOperationsCoordinatorListQuery__
 *
 * To run a query within a React component, call `useGetOperationsCoordinatorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationsCoordinatorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationsCoordinatorListQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchQuery: // value for 'searchQuery'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetOperationsCoordinatorListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOperationsCoordinatorListQueryResult, GetOperationsCoordinatorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetOperationsCoordinatorListQueryResult, GetOperationsCoordinatorListQueryVariables>(
    GetOperationsCoordinatorListDocument,
    options
  );
}
export function useGetOperationsCoordinatorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOperationsCoordinatorListQueryResult, GetOperationsCoordinatorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetOperationsCoordinatorListQueryResult, GetOperationsCoordinatorListQueryVariables>(
    GetOperationsCoordinatorListDocument,
    options
  );
}
export type GetOperationsCoordinatorListQueryHookResult = ReturnType<typeof useGetOperationsCoordinatorListQuery>;
export type GetOperationsCoordinatorListLazyQueryHookResult = ReturnType<typeof useGetOperationsCoordinatorListLazyQuery>;
