import type { CargoModeEnum } from '@zen/Cargo';
import type {
  CargoDimensionsType,
  CargoWeightType,
  CollectionDetails,
  Currency,
  DeliveryDetails,
  LooseCargoTypeEnum,
  MultiStopEnum,
  NetworkContact,
  NetworksLabelType,
  PalletTypeEnum,
  RiskLevelsEnum,
  Shipment,
  TradeRole,
  TruckSwapEnum,
  VehicleTypeEnum
} from '@zen/graphql/types.generated';
import type { BusinessHours } from '@zen/Networks';
import type { IncotermsValue } from '@zen/types';
import type { DeepNonNullable, Nullable, Optional } from '@zen/utils/typescript';

import type { GetOperationsRoadShipmentQueryResult } from '../graphql';

export type { RoadShipmentInput, VoyageDelayDataInput } from '@zen/graphql/types.generated';
export { VoyageMilestoneNameEnum } from '@zen/graphql/types.generated';

export type RoadShipment = DeepNonNullable<GetOperationsRoadShipmentQueryResult, 'nodes'>['bookings']['nodes'];

export type RoadShipmentCargoItem = DeepNonNullable<RoadShipment, 'cargoItems'>['cargo']['cargoItems'];

export interface NetworkField {
  businessHours?: BusinessHours;
  id: Optional<string>;
  label: Optional<NetworksLabelType>;
  timeZone?: Optional<string>;
}
export interface CargoItem {
  cbm: Nullable<number>;
  dimensions: CargoDimensionsType;
  extendedLooseCargoType: Nullable<LooseCargoTypeEnum | PalletTypeEnum>;
  grossWeight: CargoWeightType;
  hazardous: boolean;
  id: string;
  looseCargoType: Nullable<LooseCargoTypeEnum>;
  palletType?: Nullable<PalletTypeEnum>;
  quantity: Nullable<number>;
  reefer: Nullable<boolean>;
  riskLevel?: Nullable<RiskLevelsEnum>;
  stackable?: boolean;
  tailLift?: boolean;
  trailerId: Nullable<string>;
  valueOfGoods?: {
    currency: Currency;
    value: Nullable<number>;
  };
  vehicleType?: Optional<VehicleTypeEnum>;
}

type ContactField = Pick<NetworkContact, 'id' | 'name'>;

export interface Time {
  hour: Nullable<number>;
  minute: Nullable<number>;
}

export type DateWithTimeAndDelayReason = DateWithTime & DelayReason;

export type DateWithTimeRangeAndDelayReason = DateWithTimeRange & DelayReason;

export interface DateWithTime {
  date: Optional<string>;
  startTime: Optional<string>;
}
export interface DateWithTimeRange extends DateWithTime {
  endTime?: Optional<string>;
}

export interface DelayReason {
  description?: Optional<string>;
  reason?: Optional<string>;
}

export interface CollectionDates {
  clearSchedule?: boolean;
  collectedOn: DateWithTimeRangeAndDelayReason;
  confirmedByForwarder: DateWithTimeRange;
  requestedWithForwarder: DateWithTimeRange;
}

export interface DeliveryDates {
  clearSchedule?: boolean;
  confirmedByForwarder: DateWithTimeRange;
  deliveredOn: DateWithTimeAndDelayReason;
  requestedWithForwarder: DateWithTimeRange;
}

export interface RoadShipmentFields {
  cargoDescription: Optional<string>;
  cargoItem: CargoItem;
  cargoMode: CargoModeEnum;
  cargoReadyDate: Optional<string>;
  clientReference: Optional<string>;
  collection: CollectionDates;
  collectionLocation: Optional<NetworkField>;
  collectionReference: Optional<string>;
  consignee: Optional<NetworkField>;
  consignor: Optional<NetworkField>;
  customsOnly: boolean;
  delivery: DeliveryDates;
  deliveryLocation: Optional<NetworkField>;
  deliveryReference: Optional<string>;
  driverDetails: Optional<string>;
  haulier: Optional<ContactField>;
  incoterms: Optional<IncotermsValue>;
  multiStop: Optional<MultiStopEnum>;
  shipmentType: Optional<Shipment>;
  specialInstructions: Optional<string>;
  tradeRole: Optional<TradeRole>;
  truckSwap: Optional<TruckSwapEnum>;
  vehiclePlateNumber: Optional<string>;
}

export type ScheduleDetails = Pick<CollectionDetails | DeliveryDetails, 'driverDetails' | 'vehiclePlateNumber'>;

export enum ScheduleAction {
  CONFIRM_SCHEDULE = 'confirmedByForwarder',
  REQUEST_SCHEDULE = 'requestedWithForwarder'
}

export enum FormSections {
  BOOKING_DETAILS = 'bookingDetails'
}

export interface TrackingInputMetricData {
  collectionConfirmedEnd?: Optional<string>;
  collectionConfirmedStart?: Optional<string>;
  collectionRequestedEnd?: Optional<string>;
  collectionRequestedStart?: Optional<string>;
  deliveryConfirmedEnd?: Optional<string>;
  deliveryConfirmedStart?: Optional<string>;
  vehiclePlateNumber?: Optional<string>;
}

export interface RoadShipmentTrackedFields {
  collectionConfirmedDate: DateWithTimeRange;
  collectionRequestedDate: DateWithTimeRange;
  deliveryConfirmedDate: DateWithTimeRange;
  vehiclePlateNumber: Optional<string>;
}
