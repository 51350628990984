import type { FC } from 'react';

import Gateway from '@zen/Components/Gateway';
import { TextLink } from '@zen/DesignSystem';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

interface Props {
  description?: string;
  link: string;
  title: string;
}

const ActionCard: FC<Props> = ({ title, description, link }) => {
  const { navigate } = useNavigationHistory();

  return (
    <Gateway className="p-5 flex flex-col justify-between">
      <div className="font-bold mb-4 text-lg">{title}</div>
      <p className="mb-8 text-sm leading-normal">{description}</p>
      <TextLink className="font-bold text-sm" onClick={() => navigate(link)}>
        View more details
      </TextLink>
    </Gateway>
  );
};

export type { Props as ActionCardProps };

export default ActionCard;
