import { createContext } from 'react';

import type { UserProfile } from '@zen/Auth/UserDataProvider/types';
import type { Role } from '@zen/graphql/types.generated';

import type { TrackingEvent, TrackingTimeEvent } from './types';

interface InitialContext {
  identify: (accountUuid: string, userProfile: UserProfile, role: Role) => void;
  trackEvent: (event: TrackingEvent) => void;
  trackTiming: (event: TrackingTimeEvent) => void;
}

const initialContext = {
  trackEvent: () => {},
  trackTiming: () => {},
  identify: () => {}
};

const TrackingContext = createContext<InitialContext>(initialContext);

export default TrackingContext;
