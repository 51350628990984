import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateDeliveryEstimateMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateEddInput;
}>;

export type UpdateDeliveryEstimateMutationResult = { __typename?: 'Mutation' } & {
  shipmentUpdateEdd?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateEddPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const UpdateDeliveryEstimateDocument = /* #__PURE__ */ gql`
  mutation updateDeliveryEstimate($input: UpdateEddInput!) {
    shipmentUpdateEdd(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateDeliveryEstimateMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryEstimateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryEstimateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryEstimateMutation, { data, loading, error }] = useUpdateDeliveryEstimateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryEstimateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryEstimateMutationResult, UpdateDeliveryEstimateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateDeliveryEstimateMutationResult, UpdateDeliveryEstimateMutationVariables>(
    UpdateDeliveryEstimateDocument,
    options
  );
}
export type UpdateDeliveryEstimateMutationHookResult = ReturnType<typeof useUpdateDeliveryEstimateMutation>;
