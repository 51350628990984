import type { FC } from 'react';
import { useParams } from 'react-router';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { Button } from '@zen/DesignSystem';
import { consolidationRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

interface Props {}

const ConsolidationShipmentsButton: FC<Props> = () => {
  const { navigate } = useNavigationHistory();
  const { check } = useGlobalPermissions();
  const { consolidationId } = useParams<{ consolidationId: string }>();

  const canShowAddShipmentsButton: boolean = check('consolidation.canManageConsolidatedShipment');

  if (!canShowAddShipmentsButton) {
    return null;
  }

  return (
    <Button iconLeft="zicon-add" onClick={() => navigate(consolidationRoutes.consolidationAddShipments.getUrl(consolidationId))}>
      Add shipments
    </Button>
  );
};

export default ConsolidationShipmentsButton;
