import type { FC } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import { buildRoadTrackingMetricTrackingEvent } from '@zen/OperationsShipments';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import { useRoadTrackingDataQuery, useUpdateRoadTrackingDataMutation } from '../graphql';
import { prepareInitialValues, prepareInput } from './helpers';
import RoadTrackingDataForm from './RoadTrackingDataForm';
import type { RoadTrackingFormValues } from './RoadTrackingDataForm/types';
import type { RoadTrackingData as RoadTrackingDataType, RoadTrackingDataResponse } from './types';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
  zencargoReference: string;
}

const RoadTrackingData: FC<Props> = ({ onCancel, onSuccess, zencargoReference }) => {
  const { addError, addSuccess } = useNotification();
  const { trackEvent } = useTracking();
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();

  const { data, error, loading } = useRoadTrackingDataQuery({
    variables: {
      zencargoReference,
      newCargoService: !!isNewCargoServiceEnabled
    },
    fetchPolicy: 'cache-and-network'
  });

  const roadTrackingData: Optional<RoadTrackingDataType> = data?.bookings?.nodes?.[0];
  const cargoId: string = roadTrackingData?.cargo?.cargoItems?.[0].id || roadTrackingData?.cargos?.[0]?.id || '';

  const [updateRoadTrackingData] = useUpdateRoadTrackingDataMutation();

  const handleSuccess = (_: RoadTrackingDataResponse, values: RoadTrackingFormValues): void => {
    const { collectionConfirmedDate, deliveryConfirmedDate, vehiclePlateNumber } = values;

    const trackingEvent = buildRoadTrackingMetricTrackingEvent(zencargoReference, cargoId, {
      collectionConfirmedDate,
      deliveryConfirmedDate,
      vehiclePlateNumber
    });

    trackEvent(trackingEvent);
  };

  const handleSubmit = (values: RoadTrackingFormValues): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateRoadTrackingData({
          variables: {
            input: prepareInput(values, cargoId, zencargoReference)
          }
        }),
      onError: addError,
      onSuccess: () => {
        addSuccess('Form submitted successfully');
        onSuccess();
      }
    });
  };

  return (
    <div data-testid="road-tracking-data">
      <QueryHandler data={roadTrackingData} error={!!error} isLoading={loading}>
        {(trackingData: RoadTrackingDataType) => (
          <RoadTrackingDataForm
            initialValues={prepareInitialValues(trackingData)}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
          />
        )}
      </QueryHandler>
    </div>
  );
};

export default RoadTrackingData;
