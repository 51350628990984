import { Role } from '@zen/Auth/types';
import { consolidationRoutes, orderRoutes, shipmentRoutes, taskListRoutes, trackedShipmentRoutes } from '@zen/Routes';

import type { SidebarLink } from '../types';
import type { CustomerLinksFeatureFlags } from './CustomerLinks';

const getShipmentsMenuItem = ({ consolidation, trackedShipments }: CustomerLinksFeatureFlags): SidebarLink => {
  if (consolidation || trackedShipments) {
    return {
      icon: 'zicon-shipment',
      label: 'Shipments',
      url: '',
      children: [
        {
          label: 'All shipments',
          url: shipmentRoutes.shipmentIndex.getUrl()
        },
        {
          label: 'Tracked road shipments',
          url: trackedShipmentRoutes.trackedShipmentIndex.getUrl(),
          featureFlagName: 'trackedShipments'
        },
        {
          label: 'Consolidation',
          url: consolidationRoutes.consolidationIndex.getUrl(),
          featureFlagName: 'consolidation'
        }
      ]
    };
  }

  return {
    icon: 'zicon-shipment',
    label: 'Shipments',
    url: shipmentRoutes.shipmentIndex.getUrl()
  };
};

const taskList: SidebarLink = {
  icon: 'zicon-orders',
  label: 'Task list',
  url: taskListRoutes.taskListIndex.path,
  counter: 'taskListCount'
};

export const getConfig = (role: Role, featureFlags: CustomerLinksFeatureFlags): SidebarLink[] => {
  const isAgent: boolean = role === Role.AGENT_FORWARDER;

  return [
    {
      icon: 'zicon-dashboard',
      label: 'Dashboard',
      url: '/dashboard',
      isExact: true
    },
    ...(isAgent ? [taskList] : []),
    {
      icon: 'zicon-orders',
      label: 'Orders',
      url: orderRoutes.orderList.getUrl()
    },
    {
      icon: 'zicon-quotes',
      label: 'Quotes',
      url: '/dashboard/quotes',
      counter: 'quotesCount'
    },
    {
      icon: 'zicon-booking-request',
      label: 'Booking requests',
      url: '/dashboard/booking-requests',
      counter: 'bookingRequestsCount'
    },
    getShipmentsMenuItem(featureFlags),
    {
      icon: 'zicon-products',
      label: 'Products',
      url: '/dashboard/products'
    },
    {
      icon: 'zicon-api',
      label: 'API console',
      url: '/dashboard/api-console'
    },
    {
      icon: 'zicon-intake-planning',
      label: 'Insights',
      url: '',
      children: [
        {
          label: 'Analytics',
          url: '/dashboard/analytics'
        },
        {
          label: 'Intake planning',
          url: '/dashboard/intake-planning'
        },
        {
          featureFlagName: 'utilisation',
          label: 'Utilisation',
          url: '/dashboard/utilisation'
        },
        {
          featureFlagName: 'freightSpend',
          label: 'Freight spend',
          url: '/dashboard/freight-spend'
        }
      ]
    },
    {
      icon: 'zicon-adjust',
      label: 'Admin',
      url: '',
      children: [
        {
          label: 'Networks',
          url: '/dashboard/networks'
        },
        {
          label: 'Settings',
          url: '/dashboard/settings'
        }
      ]
    }
  ];
};
