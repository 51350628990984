import { PaginatedQueryResult, usePagination } from '@zen/utils/hooks/pagination';

import { PackingListFindByEnum } from '../../PackingListDetails/types';
import {
  PackingListsQueryResult,
  PackingListsQueryVariables,
  usePackingListsQuery
} from '../graphql/packingListOverview.generated';
import type { PackingListOrderResult } from '../types';

export type PackingListPaginatedResult = PaginatedQueryResult<
  PackingListsQueryResult,
  PackingListsQueryVariables,
  PackingListOrderResult
>;
interface Params {
  searchTerm?: string;
  zencargoReference: string;
}

const usePackingLists = ({ searchTerm, zencargoReference }: Params): PackingListPaginatedResult => {
  return usePagination<PackingListsQueryResult, PackingListsQueryVariables, PackingListOrderResult>(
    usePackingListsQuery,
    'packingLists',
    {
      findBy: { key: PackingListFindByEnum.BOOKING_REFERENCE, value: zencargoReference },
      textContains: searchTerm || undefined
    },
    20
  );
};

export default usePackingLists;
