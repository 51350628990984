import type { FC, ReactNode } from 'react';

import NoResultsComponent, { ButtonConfig } from '@zen/Components/NoResults';
import { dashboardRoutes, shipmentRoutes } from '@zen/Routes';

const NoResults: FC = () => {
  const tagline: ReactNode = (
    <>
      <p>Tracking is currently only available for full truck load road shipments.</p>
      <p>If you think this is an error please reach out to us.</p>
    </>
  );

  const button: ButtonConfig = {
    label: 'All shipments',
    linkTo: shipmentRoutes.shipmentIndex.getUrl()
  };

  const secondaryButton: ButtonConfig = {
    label: 'Dashboard',
    linkTo: dashboardRoutes.dashboard.getUrl()
  };

  return (
    <NoResultsComponent
      button={button}
      headline="You have no tracked shipments"
      secondaryButton={secondaryButton}
      tagline={tagline}
    />
  );
};

export default NoResults;
