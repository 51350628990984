import { ReactChild, useEffect } from 'react';
import { useLocation } from 'react-router';

import type { UserProfile } from '@zen/Auth/UserDataProvider/types';
import type { Role } from '@zen/graphql/types.generated';

import TrackingContext from './TrackingContext';
import type { TrackingEvent, TrackingTimeEvent } from './types';

interface TrackingProviderProps {
  children: ReactChild;
}

const TrackingProvider = ({ children }: TrackingProviderProps) => {
  const location = useLocation();

  // Send pageviews
  useEffect(() => {
    if (!window.analytics) return;
    window.analytics.page();
  }, [location]);

  const identify = (accountUuid: string, userProfile: UserProfile, role: Role): void => {
    const {
      preferences: { level, roles },
      uuid,
      email,
      firstName,
      lastName,
      phoneNumber,
      registeredCompanyName,
      isAdmin
    } = userProfile;

    if (!window.analytics) return;
    window.analytics.identify(uuid, {
      uuid,
      accountUuid,
      email,
      firstName,
      lastName,
      displayName: `${firstName || ''} ${lastName || ''}`.trim(),
      companyName: registeredCompanyName,
      userRole: role,
      roles,
      level,
      // Intercom
      isAdmin,
      phone: phoneNumber,
      profileLevel: level,
      profileRoles: roles || []
    });
    if (!isAdmin) {
      // Send the group only if the user isn't an admin
      window.analytics.group(accountUuid, {
        company: registeredCompanyName
      });
    }
  };

  const trackEvent = (event: TrackingEvent): void => {
    if (!window.analytics) return;
    window.analytics.track(event.action, {
      category: event.category,
      label: event.label,
      value: event.value,
      ...event.properties
    });
  };

  const trackTiming = (event: TrackingTimeEvent): void => {
    if (!window.analytics) return;
    window.analytics.ready(() => {
      // Sends the timing hit to Google Analytics directly once Segment loads it
      window.ga('send', 'timing', event.category, event.variable, event.value);
    });
  };

  return <TrackingContext.Provider value={{ trackEvent, trackTiming, identify }}>{children}</TrackingContext.Provider>;
};

export default TrackingProvider;
