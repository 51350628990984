import cx from 'classnames';
import pluralize from 'pluralize';
import type { FC } from 'react';

interface Props {
  className?: string;
  subscriberCount: number;
}

const SubscriberCounter: FC<Props> = ({ className, subscriberCount }) => {
  const classNames = cx(
    'inline-flex items-center bg-white rounded-full shadow-interaction z-50 font-bold py-2 pl-3 pr-5 cursor-pointer',
    className
  );

  return (
    <div className={classNames} data-testid="subscriber-counter">
      <span className="text-sm text-white bg-navy-base rounded-full px-3 py-2 mr-3">{subscriberCount}</span>
      <span className="text-navy-base">{pluralize('subscriber', subscriberCount)}</span>
    </div>
  );
};

export default SubscriberCounter;
