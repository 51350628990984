import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { FIXED_HEADER_ZINDEX, SIDEBAR_WIDTH_IN_REM } from '@zen/Layout';

interface Props {
  children: ReactNode;
  classNames?: string;
  reduceWidthBy?: number;
  reduceWidthBySidebar?: boolean;
}

const FixedHeader: FC<Props> = ({ children, classNames, reduceWidthBy = 0, reduceWidthBySidebar = true }) => {
  const widthAdjustment: number = (reduceWidthBySidebar ? SIDEBAR_WIDTH_IN_REM : 0) + reduceWidthBy;
  const width: string = `calc(100% - ${widthAdjustment}rem)`;

  const className: string = cx('fixed top-0 bg-azure-lightest', classNames, FIXED_HEADER_ZINDEX);

  return (
    <div className={className} style={{ width }}>
      {children}
    </div>
  );
};

export default FixedHeader;
