import type { ReactNode } from 'react';

import FormHeadline from '@zen/Components/Form/FormHeadline';
import CloseIcon from '@zen/Components/Icons/CloseIcon';
import Label from '@zen/Components/Label';
import SelectableBox from '@zen/Components/SelectableBox';
import { Option, Select } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import CargoTypeCover from '../CargoTypeCover';
import type { CargoType } from './types';

interface Props<T> {
  commonCargoTypes: Option<T>[];
  onCancel: () => void;
  onSelect: (type: T) => void;
  otherCargoLabel: string;
  otherCargoOptions: Option<T>[];
  title?: string;
}

const CargoSelector = <T extends CargoType>(props: Props<T>) => {
  const { commonCargoTypes, otherCargoLabel, otherCargoOptions, onCancel, onSelect, title = 'Select cargo' } = props;

  const handleChange = (value: Nullable<T>): void => {
    if (value) {
      onSelect(value);
    }
  };

  return (
    <div className="bg-grey-lightest px-12 py-10" data-testid="cargo-selector">
      <div className="flex justify-between items-start">
        <FormHeadline text={title} />
        <CloseIcon onClick={onCancel} />
      </div>

      <div className="grid grid-cols-3 gap-4 mb-8" data-testid="common-cargo-tiles">
        {commonCargoTypes.map(
          ({ value }: Option<T>): ReactNode => (
            <SelectableBox key={value.toString()} onClick={() => onSelect(value)}>
              <CargoTypeCover key={value} cargoType={value} />
            </SelectableBox>
          )
        )}
      </div>

      <div className="w-3/5">
        <Label>{otherCargoLabel}</Label>
        <Select name="otherCargo" onChange={handleChange} options={otherCargoOptions} placeholder="" />
      </div>
    </div>
  );
};

export default CargoSelector;
