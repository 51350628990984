import type { FC, ReactNode } from 'react';
import { useParams } from 'react-router';

import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import { IconButton } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateBookingsAssignedToConsolidatedShipmentMutation } from '../../graphql';

interface Props {
  zencargoReference: string;
}

const ActionHandleContainer: FC<Props> = ({ zencargoReference }) => {
  const { consolidationId } = useParams<{ consolidationId: string }>();
  const { addSuccess, addError } = useNotification();
  const [updateConsolidationBookings] = useUpdateBookingsAssignedToConsolidatedShipmentMutation();

  const handleRemoveShipment = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateConsolidationBookings({
          variables: {
            input: {
              consolidatedShipmentId: consolidationId,
              zencargoReferencesToUnassign: [zencargoReference]
            }
          },
          awaitRefetchQueries: true,
          refetchQueries: ['consolidationShipmentsQuery']
        }),
      onError: addError,
      onSuccess: () => {
        addSuccess('Shipment has been removed');
      }
    });
  };

  const modalMessage: ReactNode = (
    <>
      Are you sure you want to remove this shipment? <br />
      This will only remove the shipment from consolidated shipment.
    </>
  );

  return (
    <WithConfirmationModal
      header="Remove shipment from consolidated shipment"
      message={modalMessage}
      onConfirm={handleRemoveShipment}
    >
      <IconButton icon="zicon-trash" title="Remove" variant="tertiary" />
    </WithConfirmationModal>
  );
};

export default ActionHandleContainer;
