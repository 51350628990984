import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetBookingDocumentsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetBookingDocumentsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              bookingDocuments: Array<
                { __typename?: 'BookingDocument' } & Pick<
                  GraphQLTypes.BookingDocument,
                  'id' | 'description' | 'documentType' | 'actionItemDocument' | 'assetUrl' | 'state' | 'error'
                > & {
                    createdAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'dateTime'>;
                    permissions: { __typename?: 'Permissions' } & Pick<
                      GraphQLTypes.Permissions,
                      'manufacturer' | 'agentForwarder' | 'customerUser'
                    >;
                  }
              >;
            }
        >
      >
    >;
  };
};

export const GetBookingDocumentsDocument = /* #__PURE__ */ gql`
  query getBookingDocuments($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        bookingDocuments {
          id
          createdAt {
            dateTime
          }
          description
          documentType
          actionItemDocument
          permissions {
            manufacturer
            agentForwarder
            customerUser
          }
          assetUrl
          state
          error
        }
      }
    }
  }
`;

/**
 * __useGetBookingDocumentsQuery__
 *
 * To run a query within a React component, call `useGetBookingDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingDocumentsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetBookingDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBookingDocumentsQueryResult, GetBookingDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetBookingDocumentsQueryResult, GetBookingDocumentsQueryVariables>(GetBookingDocumentsDocument, options);
}
export function useGetBookingDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookingDocumentsQueryResult, GetBookingDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetBookingDocumentsQueryResult, GetBookingDocumentsQueryVariables>(
    GetBookingDocumentsDocument,
    options
  );
}
export type GetBookingDocumentsQueryHookResult = ReturnType<typeof useGetBookingDocumentsQuery>;
export type GetBookingDocumentsLazyQueryHookResult = ReturnType<typeof useGetBookingDocumentsLazyQuery>;
