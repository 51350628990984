import type { ChangeEvent, FC, FocusEvent } from 'react';

import { PasswordInput } from '@zen/DesignSystem';

import FormField, { FormFieldProps } from '../FormField';

interface Props extends FormFieldProps {
  disabled?: boolean;
}

const FormPasswordInput: FC<Props> = (props) => {
  return (
    <FormField {...props}>
      {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type. */}
      {(field) => (
        <PasswordInput
          {...field}
          id={field.name}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            field.onBlur(event);
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            field.onChange(event);
          }}
          type="password"
        />
      )}
    </FormField>
  );
};

export default FormPasswordInput;
