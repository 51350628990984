import cx from 'classnames';
import type { FC, ReactNode } from 'react';

interface Props {
  className?: string;
  hasError?: boolean;
  isRequired?: boolean;
  label: ReactNode;
  name?: string;
}

const FormLabel: FC<Props> = ({ isRequired = false, label, hasError = false, name = '', className = '' }) => {
  const requiredClassName: string = cx({ 'text-grey-base': !hasError, 'text-red-base': hasError }, 'text-xs');
  const classNames: string = cx(
    {
      'text-red-base': hasError,
      'text-grey-dark': !hasError
    },
    'block flex leading-normal items-baseline mb-1 h-5',
    className
  );

  return (
    <label className={classNames} htmlFor={name}>
      <span className="text-sm mr-1">{label}</span>
      {isRequired && <span className={requiredClassName}>*Required</span>}
    </label>
  );
};

export default FormLabel;
