import type { FC } from 'react';

import type { NewCargo } from '@zen/Cargo';
import { useCollectionAndDelivery } from '@zen/CollectionAndDelivery/CollectionAndDeliveryContext';
import { ContextMenu, MenuItemType } from '@zen/DesignSystem';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import { getExtendedOptions, getReducedOptions } from '../helper';
import type { ScheduleActionType, ScheduleItemType } from '../types';

interface Props {
  cargo: NewCargo;
  disabled?: boolean;
  optionsType: ScheduleItemType;
  setActionType: (type: ScheduleActionType) => void;
}

const ScheduleActionDropdown: FC<Props> = (props) => {
  const { navigate } = useNavigationHistory();
  const { optionsType, cargo, setActionType, disabled } = props;
  const { cargoSchedulePermissions, canViewOperationsDashboard, isSingleCargoRoadShipment, zencargoReference } =
    useCollectionAndDelivery();

  const generateOptions = (): MenuItemType[] => {
    if (isSingleCargoRoadShipment) {
      return getReducedOptions(setActionType, zencargoReference, optionsType, canViewOperationsDashboard, navigate);
    }

    return getExtendedOptions([cargo], cargoSchedulePermissions, optionsType, setActionType);
  };

  const options: MenuItemType[] = generateOptions();

  return <ContextMenu disabled={disabled} items={options} />;
};

export default ScheduleActionDropdown;
