import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import { useUpdateBookingStageMutation } from '../graphql';
import BookingConfirmedStage from './BookingConfirmedStage';
import BookingStageCard from './components/BookingStageCard';
import { mapStages } from './helpers';
import type { BookingStageMap } from './types';
import { BookingStage, BookingStageEnum } from './types';

interface Props {
  allowUpdateStage: boolean;
  isCurrentInBookingStages: boolean;
  stages: BookingStage[];
  zencargoReference: string;
}

const BookingStages: FC<Props> = (props) => {
  const { isCurrentInBookingStages, stages, zencargoReference, allowUpdateStage } = props;
  const { addError, addSuccess } = useNotification();

  const bookingStages: BookingStageMap = mapStages(stages, isCurrentInBookingStages);
  const currentStage: BookingStage | undefined = Object.values(bookingStages).find((stage: BookingStage) => stage.current);

  const [updateBookingStage] = useUpdateBookingStageMutation();

  const isBookingConfirmed: boolean = currentStage?.name === BookingStageEnum.BOOKING_CONFIRMED;
  const isBookingReceived: boolean = currentStage?.name === BookingStageEnum.BOOKING_RECEIVED;
  const canConfirm: boolean = isCurrentInBookingStages && allowUpdateStage && isBookingReceived;
  const canEditConfirmedStage: boolean = isBookingConfirmed && allowUpdateStage;

  const handleSetStageClick = async (stageName: BookingStageEnum, occurredAt?: Optional<string>): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () =>
        updateBookingStage({
          variables: {
            input: {
              zencargoReference,
              stage: stageName,
              occurredAt
            }
          },
          refetchQueries: ['getShipmentDetailsBoard', 'getRouteProgressDetails']
        }),
      onError: addError,
      onSuccess: () => {
        addSuccess('Booking stage has been updated');
      }
    });

  return (
    <div data-testid="booking-stages">
      {bookingStages.BOOKING_REQUESTED && <BookingStageCard isFirst={true} stage={bookingStages.BOOKING_REQUESTED} />}
      <BookingStageCard isFirst={!bookingStages.BOOKING_REQUESTED} stage={bookingStages.BOOKING_RECEIVED} />
      <BookingConfirmedStage
        canConfirmStage={canConfirm}
        canEditStage={canEditConfirmedStage}
        onUpdateStage={handleSetStageClick}
        stage={bookingStages.BOOKING_CONFIRMED}
      />
    </div>
  );
};

export type { Props as BookingStagesProps };

export default BookingStages;
