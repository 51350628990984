import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrganisationLocationsQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  ids?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
}>;

export type OrganisationLocationsQueryResult = { __typename?: 'Query' } & {
  network?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      organisations?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksOrganisationInterfaceConnection' } & {
          nodes?: GraphQLTypes.Maybe<
            Array<
              GraphQLTypes.Maybe<
                | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'id' | 'name'> & {
                      locations: Array<
                        { __typename?: 'NetworksLocation' } & Pick<
                          GraphQLTypes.NetworksLocation,
                          'id' | 'name' | 'locationType'
                        > & {
                            address: { __typename?: 'Address' } & Pick<
                              GraphQLTypes.Address,
                              'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
                            > & {
                                country?: GraphQLTypes.Maybe<
                                  { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>
                                >;
                              };
                            geolocation?: GraphQLTypes.Maybe<
                              { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                            >;
                          }
                      >;
                    })
                | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'id' | 'name'> & {
                      locations: Array<
                        { __typename?: 'NetworksLocation' } & Pick<
                          GraphQLTypes.NetworksLocation,
                          'id' | 'name' | 'locationType'
                        > & {
                            address: { __typename?: 'Address' } & Pick<
                              GraphQLTypes.Address,
                              'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
                            > & {
                                country?: GraphQLTypes.Maybe<
                                  { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>
                                >;
                              };
                            geolocation?: GraphQLTypes.Maybe<
                              { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                            >;
                          }
                      >;
                    })
                | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'id' | 'name'> & {
                      locations: Array<
                        { __typename?: 'NetworksLocation' } & Pick<
                          GraphQLTypes.NetworksLocation,
                          'id' | 'name' | 'locationType'
                        > & {
                            address: { __typename?: 'Address' } & Pick<
                              GraphQLTypes.Address,
                              'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
                            > & {
                                country?: GraphQLTypes.Maybe<
                                  { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>
                                >;
                              };
                            geolocation?: GraphQLTypes.Maybe<
                              { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                            >;
                          }
                      >;
                    })
                | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'id' | 'name'> & {
                      locations: Array<
                        { __typename?: 'NetworksLocation' } & Pick<
                          GraphQLTypes.NetworksLocation,
                          'id' | 'name' | 'locationType'
                        > & {
                            address: { __typename?: 'Address' } & Pick<
                              GraphQLTypes.Address,
                              'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
                            > & {
                                country?: GraphQLTypes.Maybe<
                                  { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>
                                >;
                              };
                            geolocation?: GraphQLTypes.Maybe<
                              { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                            >;
                          }
                      >;
                    })
              >
            >
          >;
        }
      >;
    }
  >;
};

export const OrganisationLocationsQueryDocument = /* #__PURE__ */ gql`
  query organisationLocationsQuery($accountUuid: String!, $ids: [String!]) {
    network(id: $accountUuid) {
      organisations(ids: $ids) {
        nodes {
          id
          name
          locations {
            id
            name
            address {
              city
              country {
                code
                name
              }
              countyOrState
              postalCodeOrZip
              street
            }
            locationType
            geolocation {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;

/**
 * __useOrganisationLocationsQuery__
 *
 * To run a query within a React component, call `useOrganisationLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationLocationsQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useOrganisationLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganisationLocationsQueryResult, OrganisationLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrganisationLocationsQueryResult, OrganisationLocationsQueryVariables>(
    OrganisationLocationsQueryDocument,
    options
  );
}
export function useOrganisationLocationsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganisationLocationsQueryResult, OrganisationLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrganisationLocationsQueryResult, OrganisationLocationsQueryVariables>(
    OrganisationLocationsQueryDocument,
    options
  );
}
export type OrganisationLocationsQueryHookResult = ReturnType<typeof useOrganisationLocationsQuery>;
export type OrganisationLocationsQueryLazyQueryHookResult = ReturnType<typeof useOrganisationLocationsQueryLazyQuery>;
