import type { FC, ReactNode } from 'react';

import { Form, FormInput, FormInstance } from '@zen/Components/Form';
import SectionTitle from '@zen/Components/SectionTitle';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { AccountDetails, AccountDetailsItem } from '../../types';
import { validationSchema } from './CompanyDetailsForm.validation';

interface Props {
  initialValues: Partial<AccountDetailsItem>;
  onSubmit: (values: AccountDetails) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const CompanyDetailsForm: FC<Props> = ({ initialValues, onSubmit, onSuccess }) => {
  const renderFields = (): ReactNode => (
    <div className="max-w-lg">
      <FormInput label="Registered company name" name="name" placeholder="Add company name" />
      <FormInput label="Email" name="companyEmailAddress" placeholder="Add email" />
      <FormInput label="Phone" name="companyPhoneNumber" placeholder="Add phone number" />
      <FormInput label="EORI" name="eoriNumber" placeholder="Add EORI" />
      <FormInput label="VAT" name="vat" placeholder="Add VAT" />
    </div>
  );

  const renderFormButtons = ({ dirty, isSubmitting }: FormInstance<AccountDetailsItem>): ReactNode => {
    const isDisabled: boolean = !dirty || isSubmitting;

    return (
      <Button disabled={isDisabled} type="submit" variant="primary">
        Save
      </Button>
    );
  };

  return (
    <div className="max-w-screen-lg">
      <SectionTitle title="Company details" />
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        validationSchema={validationSchema}
      >
        {renderFields}
      </Form>
    </div>
  );
};

export default CompanyDetailsForm;
