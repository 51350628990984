import type { FC, ReactElement, ReactNode } from 'react';
import { useParams } from 'react-router';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import RouteTabs, { RouteTabConfig } from '@zen/Components/RouteTabs';
import RouteTabSwitcher from '@zen/Components/RouteTabSwitcher';
import { Pill } from '@zen/DesignSystem';
import accountsRoutes from '@zen/Routes/accounts';
import BillingDetails from '@zen/Settings/BillingDetails';

import AccountStatus from './AccountStatus';
import CompanyDetails from './CompanyDetails';
import { useGetAccountDetailsQuery } from './graphql';
import OperationsCoordinatorList from './OperationsCoordinatorList';
import type { AccountDetailsItem } from './types';

const AccountDetails: FC = () => {
  const { id: accountId } = useParams<{ id: string }>();

  const { data, error, loading, refetch } = useGetAccountDetailsQuery({
    variables: { accountId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const companyDetails = (accountDetails: AccountDetailsItem): RouteTabConfig => ({
    path: 'company-details',
    label: 'Company details',
    component: <CompanyDetails accountDetails={accountDetails} refetchAccountDetails={refetch} />
  });

  const accountStatus = (accountDetails: AccountDetailsItem): RouteTabConfig => ({
    path: 'account-status',
    label: 'Account status',
    component: <AccountStatus accountDetails={accountDetails} accountId={accountId} onStatusChange={refetch} />
  });

  const billingDetails: RouteTabConfig = {
    path: 'billing',
    label: 'Billing',
    component: <BillingDetails accountId={accountId} />
  };

  const opsCoordinators: RouteTabConfig = {
    path: 'ops-coordinatorts',
    label: 'Ops coordinators',
    component: <OperationsCoordinatorList accountId={accountId} />
  };

  const renderTagLine = (companyName: string, isActive: boolean, isDemo: boolean): ReactNode => {
    const statusTooltip: string = isActive
      ? 'This account has access to the platform.'
      : 'This account has no access to the platform.';

    const modeTooltip: string = isDemo ? 'This account is an internal account.' : 'This account is a customer account.';

    return (
      <>
        <span>{companyName}</span>
        <div className="mt-4">
          <Pill className="mr-4" tooltip={statusTooltip} type={isActive ? 'success' : 'problem'}>
            {isActive ? 'Active' : 'Inactive'}
          </Pill>
          <Pill tooltip={modeTooltip} type={isDemo ? 'default' : 'success'}>
            {isDemo ? 'Demo' : 'Real'}
          </Pill>
        </div>
      </>
    );
  };

  return (
    <QueryHandler data={data?.account} error={!!error} isLoading={loading}>
      {(accountDetails: AccountDetailsItem): ReactElement => {
        const { name, isActive, isDemo, referencePrefix } = accountDetails;

        const tabsConfig: RouteTabConfig[] = [
          companyDetails(accountDetails),
          accountStatus(accountDetails),
          billingDetails,
          opsCoordinators
        ];

        return (
          <Page
            defaultBackUrl={accountsRoutes.accountsIndex.getUrl()}
            tabsComponent={<RouteTabs tabsConfig={tabsConfig} />}
            tagline={renderTagLine(name, isActive, isDemo)}
            title={referencePrefix}
          >
            <RouteTabSwitcher tabsConfig={tabsConfig} />
          </Page>
        );
      }}
    </QueryHandler>
  );
};

export default AccountDetails;
