import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type CancelCollectionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CancelCollectionInput;
}>;

export type CancelCollectionMutationResult = { __typename?: 'Mutation' } & {
  bookingCollectionAndDeliveryCancelCollection?: GraphQLTypes.Maybe<
    { __typename?: 'CancelCollectionPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const CancelCollectionMutationDocument = /* #__PURE__ */ gql`
  mutation cancelCollectionMutation($input: CancelCollectionInput!) {
    bookingCollectionAndDeliveryCancelCollection(input: $input) {
      errors {
        message
      }
    }
  }
`;

/**
 * __useCancelCollectionMutation__
 *
 * To run a mutation, you first call `useCancelCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCollectionMutation, { data, loading, error }] = useCancelCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelCollectionMutationResult, CancelCollectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CancelCollectionMutationResult, CancelCollectionMutationVariables>(
    CancelCollectionMutationDocument,
    options
  );
}
export type CancelCollectionMutationHookResult = ReturnType<typeof useCancelCollectionMutation>;
