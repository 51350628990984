import type { FC, ReactNode } from 'react';

import ActionCard from '@zen/Components/ActionCard';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { Button } from '@zen/DesignSystem';
import { productSettingsRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import { useGetDocumentTemplatesListQuery } from '../graphql';
import type { DocumentTemplateListItem } from '../types';

const DocumentTemplatesList: FC = () => {
  const { navigate } = useNavigationHistory();

  const { data, error, loading } = useGetDocumentTemplatesListQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const newDocumentTemplateButton: ReactNode = (
    <Button iconLeft="zicon-add" onClick={() => navigate(productSettingsRoutes.documentTemplatesCreate.getUrl())}>
      Add new document template
    </Button>
  );

  const renderDocumentTemplateActionCard = ({ id, name }: DocumentTemplateListItem): ReactNode => (
    <ActionCard key={id} link={productSettingsRoutes.documentTemplatesEdit.getUrl(id.toString())} title={name} />
  );

  return (
    <Page
      actionButtons={newDocumentTemplateButton}
      defaultBackUrl={productSettingsRoutes.productSettingsIndex.getUrl()}
      tagline="Manage all document templates within Zencargo"
      title="Document templates"
    >
      <QueryHandler data={data?.documentTemplates} error={!!error} isLoading={loading}>
        {(documentTemplates: DocumentTemplateListItem[]) => {
          return (
            <div className="grid grid-cols-4 gap-8 mt-4" data-testid="document-template-grid">
              {documentTemplates.map(renderDocumentTemplateActionCard)}
            </div>
          );
        }}
      </QueryHandler>
    </Page>
  );
};

export default DocumentTemplatesList;
