import type { DeepNonNullable, Nullable, Optional } from '@zen/utils/typescript';

import type { RoadTrackingDetailsQueryResult } from '../ShipmentDetailsPage/graphql';
import type { TrackingDetails } from '../ShipmentDetailsPage/types';

export type RoadTrackingData = DeepNonNullable<
  RoadTrackingDetailsQueryResult,
  'roadTracking'
>['bookings']['nodes']['roadTracking'];

export const prepareRoadTrackingDetails = (data: Optional<RoadTrackingData>): Nullable<TrackingDetails> => {
  if (!data) {
    return null;
  }

  const { trackingInput } = data;

  return {
    isTrackable: !!trackingInput?.trackable,
    status: trackingInput?.trackingSubscription?.status,
    mapUrl: trackingInput?.trackingSubscription?.mapUrl
  };
};
