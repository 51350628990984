import type { ApolloCache } from '@apollo/client';
import type { FC, SyntheticEvent } from 'react';

import PinIcon from '@zen/Components/Icons/PinIcon';
import type { IconButtonSize } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';

import type { BookingPinBookingMutationResult, BookingUnpinBookingMutationResult } from '../graphql';
import { useBookingPinBookingMutation, useBookingUnpinBookingMutation } from '../graphql';

interface Props {
  iconSize?: IconButtonSize;
  isPinned: boolean;
  onSuccess?: () => void;
  zencargoReference: string;
}

const PinShipment: FC<Props> = ({ iconSize, isPinned, onSuccess, zencargoReference }) => {
  const { trackEvent } = useTracking();
  const { addSuccess, addError } = useNotification();

  const onError = () => addError();

  const onCompleted = () => {
    trackEvent({
      category: 'Shipments pinning',
      action: isPinned ? 'Unpin' : 'Pin',
      label: zencargoReference
    });

    addSuccess(`${zencargoReference} has been ${isPinned ? 'pinned' : 'unpinned'}.`);
    onSuccess?.();
  };

  const updateCache = <T extends BookingPinBookingMutationResult | BookingUnpinBookingMutationResult>(
    cache: ApolloCache<T>,
    pinned: boolean
  ) => {
    return cache.modify({
      id: cache.identify({ __typename: 'Booking', zencargoReference }),
      fields: {
        pinned() {
          return pinned;
        }
      }
    });
  };

  const mutationOptions = {
    variables: { input: { zencargoReference } },
    onError,
    onCompleted
  };

  const [pinShipment] = useBookingPinBookingMutation({
    ...mutationOptions,
    update: (cache: ApolloCache<BookingPinBookingMutationResult>) => updateCache(cache, true)
  });
  const [unpinShipment] = useBookingUnpinBookingMutation({
    ...mutationOptions,
    update: (cache: ApolloCache<BookingUnpinBookingMutationResult>) => updateCache(cache, false)
  });

  const handleClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    return isPinned ? unpinShipment() : pinShipment();
  };

  return <PinIcon className="fs-shipments-pinning" iconSize={iconSize} isPinned={isPinned} onClick={handleClick} />;
};

export default PinShipment;
