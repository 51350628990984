import type { DataQuality } from '../types';

export const calculatePercentage = (data: DataQuality): number | undefined => {
  const { productsWithIssues, purchaseOrdersWithIssues, totalProducts, totalPurchaseOrders } = data;
  const productsWithoutIssues = totalProducts - productsWithIssues.length;
  const purchaseOrdersWithoutIssues = totalPurchaseOrders - purchaseOrdersWithIssues.length;
  const productsPart = (productsWithoutIssues / totalProducts) * 0.5;
  const purchaseOrdersPart = (purchaseOrdersWithoutIssues / totalPurchaseOrders) * 0.5;

  if (!totalProducts && !totalPurchaseOrders) {
    return;
  }

  return Math.floor((productsPart + purchaseOrdersPart) * 100);
};
