import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import '@zen/Styleguide/assets/fonts.css';
import './styles/main.css';
import '../utils/polyfill';
import './styles/animate.css';
import './styles/style.css';

import { FC, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import ErrorBoundary from '@zen/Components/ErrorBoundary';
import StatusPage from '@zen/Components/StatusPage';
import { configure } from '@zen/utils/api';
import logger from '@zen/utils/logger';
import NavigationHistoryProvider from '@zen/utils/NavigationHistory/NavigationHistoryProvider';

import NotificationProvider from '../Components/NotificationProvider';
import TrackingProvider from '../Components/TrackingProvider';
import GraphQLProvider from '../graphql/GraphQLProvider';
import staticConfig from '../utils/staticConfig';
import AccountIdProvider from './AccountIdContext';
import AppContext from './AppContext';
import Routes from './Routes';

configure();

const App: FC = () => {
  const { environment } = staticConfig;
  const isProduction: boolean = environment === 'production';

  useEffect(() => {
    if (isProduction) {
      logger.disable();
    } else {
      logger.enable();
      logger.info('Logger enabled');
    }
  }, [isProduction]);

  return (
    <>
      <Router>
        <CompatRouter>
          <ErrorBoundary>
            <AppContext.Provider value={{ env: environment }}>
              <GraphQLProvider>
                <AccountIdProvider>
                  <TrackingProvider>
                    <NotificationProvider>
                      <NavigationHistoryProvider>
                        <Routes env={environment} />
                      </NavigationHistoryProvider>
                    </NotificationProvider>
                  </TrackingProvider>
                </AccountIdProvider>
              </GraphQLProvider>
            </AppContext.Provider>
          </ErrorBoundary>
        </CompatRouter>
      </Router>
      {isProduction && <StatusPage />}
    </>
  );
};

export default App;
