import { get, isNil, omitBy } from 'lodash';
import moment, { Moment } from 'moment';
import type { ReactNode } from 'react';

import FormDateRangeFilter from '@zen/Components/Form/FormDateRangeFilter';
import type { Option } from '@zen/DesignSystem';
import type { NetworksAssignableInterface } from '@zen/Networks';
import { Customer, OrderFilters, PurchaseOrderBookingStateEnum } from '@zen/Orders';
import type { OrderListQueryVariables } from '@zen/Orders/graphql';
import { incotermsOptions } from '@zen/Shipment/helpers';
import type { IssuesFilterInput } from '@zen/Shipments/types';
import type { DateRangeInput } from '@zen/types';
import { getDateRangeInput } from '@zen/utils/date';
import { ALL_DAYS_IN_THE_PAST } from '@zen/utils/Filtering';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { FilterDescription, OrderFilterValues, Terminal } from './types';
import { PurchaseOrderStatusEnum } from './types';

export const mapIssues = (title: Nullable<string>): Option<string> => ({
  label: title || 'User created',
  // BE sends null as a value we convert that to empty string here and back to null when submitting
  value: title || ''
});

export const initialFilters: OrderFilters = {
  status: PurchaseOrderStatusEnum.OPEN
};

export const buildFilters = (options: OrderFilterValues, shouldRenderCustomerFilter: boolean): FilterDescription[] => {
  const { customers, issueTitles, manufacturers, polTerminals, podTerminals } = options;

  const customerFilterOption: FilterDescription = {
    name: 'customer',
    label: 'Customer',
    options: customers.map((customer: Customer) => ({
      value: customer,
      label: get(customer, 'name', '') || ''
    })),
    placeholder: 'Select customer'
  };

  const closedPOStatusOptions: Option<PurchaseOrderStatusEnum>[] = [
    { label: 'Open', value: PurchaseOrderStatusEnum.OPEN },
    { label: 'Closed', value: PurchaseOrderStatusEnum.CLOSED }
  ];

  return [
    {
      name: 'status' as keyof OrderFilters,
      label: 'Order status',
      options: closedPOStatusOptions,
      placeholder: 'Select order status'
    },
    {
      name: 'bookingState',
      label: 'Booking status',
      isMultiSelect: true,
      options: [
        { label: 'Fully booked', value: PurchaseOrderBookingStateEnum.FULLY_BOOKED },
        { label: 'Partially booked', value: PurchaseOrderBookingStateEnum.PARTIALLY_BOOKED },
        { label: 'Not booked', value: PurchaseOrderBookingStateEnum.NOT_BOOKED }
      ],
      placeholder: 'Select booking status(es)'
    },
    ...(shouldRenderCustomerFilter ? [customerFilterOption] : []),
    {
      name: 'manufacturerIds',
      isMultiSelect: true,
      label: 'Manufacturer',
      options: manufacturers.map((manufacturer: NetworksAssignableInterface) => ({
        value: get(manufacturer, 'id') || '',
        label: get(manufacturer, 'label.short', '')
      })),
      placeholder: 'Select manufacturer(s)'
    },
    {
      name: 'cargoReadyDateIn',
      label: 'Cargo ready dates (CRD)',
      options: [
        { label: 'Custom range', value: null },
        { label: 'Next 1 day', value: 1 },
        { label: 'Next 3 days', value: 3 },
        { label: 'Next 7 days', value: 7 },
        { label: 'Next 14 days', value: 14 },
        { label: 'Next 21 days', value: 21 },
        { label: 'Next 28 days', value: 28 },
        { label: 'In the past', value: ALL_DAYS_IN_THE_PAST } // simulate getting all past shipments, thus setting -10 years
      ],
      placeholder: 'Select date range',
      render: ({ cargoReadyDateIn }: OrderFilters): ReactNode => {
        const isCustomCRDRange = !cargoReadyDateIn && cargoReadyDateIn !== undefined;

        if (!isCustomCRDRange) {
          return null;
        }

        return <FormDateRangeFilter namePrefix="cargoReadyDateBetween" />;
      }
    },
    {
      name: 'portOfLoadUnlocode',
      label: 'Port of Load',
      options: polTerminals.map(({ unlocode, label }: Terminal) => ({
        value: unlocode || '',
        label: label || ''
      })),
      placeholder: 'Select port of load'
    },
    {
      name: 'portOfDestinationUnlocode',
      label: 'Port of Destination',
      options: podTerminals.map(({ unlocode, label }: Terminal) => ({
        value: unlocode || '',
        label: label || ''
      })),
      placeholder: 'Select port of destination'
    },
    {
      name: 'incoterms',
      label: 'Incoterms',
      options: incotermsOptions,
      placeholder: 'Select incoterm'
    },
    {
      name: 'issueTitle',
      label: 'Issue types',
      options: issueTitles.map(mapIssues),
      placeholder: 'Select issue type'
    }
  ];
};

const isEmptyValue = (value: unknown): boolean => {
  return Array.isArray(value) ? value.length === 0 : isNil(value);
};

const getIssuesFilterValue = (issueTitle: Optional<string>): IssuesFilterInput | undefined => {
  if (issueTitle === null || issueTitle === undefined) {
    return undefined;
  }

  return {
    titleEq: issueTitle === '' ? null : issueTitle,
    active: true
  };
};

const getCargoReadyDateBetweenFilterValue = (
  cargoReadyDateIn: Optional<number>,
  cargoReadyDateBetween: Optional<DateRangeInput>
): DateRangeInput | undefined => {
  // if user sepcified custom date range use it
  if (cargoReadyDateBetween?.startDate && cargoReadyDateBetween?.endDate) {
    return cargoReadyDateBetween;
  }

  // if no relative date range was used, skip filter
  if (!cargoReadyDateIn) {
    return undefined;
  }

  // calculate date range for a given relative value
  const now = moment();
  const startFrom: Moment = cargoReadyDateIn < 0 ? now.add(-1, 'days') : now;

  return getDateRangeInput(cargoReadyDateIn, startFrom);
};

export const prepareFilterVariables = (appliedFilters: OrderFilters): Partial<OrderListQueryVariables> => {
  const cleanedFilters: Partial<OrderFilters> = omitBy(appliedFilters, isEmptyValue);
  const {
    bookingState,
    cargoReadyDateIn,
    cargoReadyDateBetween,
    customer,
    filterBy,
    incoterms,
    issueTitle,
    manufacturerIds,
    portOfDestinationUnlocode,
    portOfLoadUnlocode,
    lastUpdatedBetween,
    status
  } = cleanedFilters;

  return {
    bookingState,
    cargoReadyDateBetween: getCargoReadyDateBetweenFilterValue(cargoReadyDateIn, cargoReadyDateBetween),
    customerUuid: customer?.uuid,
    filterBy,
    incoterms,
    issues: getIssuesFilterValue(issueTitle),
    manufacturerIds,
    portOfDestinationUnlocode,
    portOfLoadUnlocode,
    lastUpdatedBetween,
    status
  };
};
