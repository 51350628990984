import type { ReactChild } from 'react';

interface BorderBoxProps {
  children: ReactChild;
  className?: string;
  title: string;
}

const ShadowBox = ({ children, className = '', title }: BorderBoxProps) => (
  <div className={`bg-white shadow rounded-lg p-6 ${className}`}>
    <div className="text-navy-base font-bold text-2xl">{title}</div>
    {children}
  </div>
);

export default ShadowBox;
