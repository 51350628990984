import type { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import productSettingsRoutes from '@zen/Routes/productSettings';

import DocumentTemplatesList from './DocumentTemplatesList';
import EditDocumentTemplate from './EditDocumentTemplate';
import NewDocumentTemplate from './NewDocumentTemplate';

const DocumentTeamplatesContainer: FC = () => {
  return (
    <Switch>
      <Route exact={true} path={productSettingsRoutes.documentTemplatesIndex.path}>
        <DocumentTemplatesList />
      </Route>
      <Route path={productSettingsRoutes.documentTemplatesCreate.path}>
        <NewDocumentTemplate />
      </Route>
      <Route path={productSettingsRoutes.documentTemplatesEdit.path}>
        <EditDocumentTemplate />
      </Route>
    </Switch>
  );
};

export default DocumentTeamplatesContainer;
