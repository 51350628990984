import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateVehicleTrailerIdMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateVehicleTrailerIdInput;
}>;

export type UpdateVehicleTrailerIdMutationResult = { __typename?: 'Mutation' } & {
  cargoUpdateVehicleTrailerId?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateVehicleTrailerIdPayload' } & {
      cargoItem?: GraphQLTypes.Maybe<
        | ({ __typename?: 'CargoContainerType' } & Pick<GraphQLTypes.CargoContainerType, 'id'>)
        | ({ __typename?: 'CargoLooseCargoType' } & Pick<GraphQLTypes.CargoLooseCargoType, 'trailerId' | 'id'>)
        | ({ __typename?: 'CargoVehicleType' } & Pick<GraphQLTypes.CargoVehicleType, 'trailerId' | 'id'>)
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const UpdateVehicleTrailerIdDocument = /* #__PURE__ */ gql`
  mutation updateVehicleTrailerId($input: UpdateVehicleTrailerIdInput!) {
    cargoUpdateVehicleTrailerId(input: $input) {
      cargoItem {
        id
        ... on CargoLooseCargoType {
          trailerId
        }
        ... on CargoVehicleType {
          trailerId
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateVehicleTrailerIdMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleTrailerIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleTrailerIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleTrailerIdMutation, { data, loading, error }] = useUpdateVehicleTrailerIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleTrailerIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVehicleTrailerIdMutationResult, UpdateVehicleTrailerIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateVehicleTrailerIdMutationResult, UpdateVehicleTrailerIdMutationVariables>(
    UpdateVehicleTrailerIdDocument,
    options
  );
}
export type UpdateVehicleTrailerIdMutationHookResult = ReturnType<typeof useUpdateVehicleTrailerIdMutation>;
