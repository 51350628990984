import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { DateTimeRangeFragmentDoc } from '../../graphql/fragments/DateTimeRange.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateDeliveryDatesMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateDeliveryDatesInput;
}>;

export type UpdateDeliveryDatesMutationResult = { __typename?: 'Mutation' } & {
  bookingCollectionAndDeliveryUpdateDeliveryDates?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateDeliveryDatesPayload' } & {
      delivery?: GraphQLTypes.Maybe<
        { __typename?: 'DeliveryDetails' } & Pick<GraphQLTypes.DeliveryDetails, 'id'> & {
            arrivedAtWarehouse?: GraphQLTypes.Maybe<
              { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
            >;
            confirmedByForwarder?: GraphQLTypes.Maybe<
              { __typename: 'LocalDateTimeRange' } & {
                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
                endDateTime?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                >;
              }
            >;
            requestedWithForwarder?: GraphQLTypes.Maybe<
              { __typename: 'LocalDateTimeRange' } & {
                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
                endDateTime?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                >;
              }
            >;
          }
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const UpdateDeliveryDatesDocument = /* #__PURE__ */ gql`
  mutation updateDeliveryDates($input: UpdateDeliveryDatesInput!) {
    bookingCollectionAndDeliveryUpdateDeliveryDates(input: $input) {
      delivery {
        arrivedAtWarehouse {
          date
          time
        }
        confirmedByForwarder {
          ...DateTimeRange
        }
        id
        requestedWithForwarder {
          ...DateTimeRange
        }
      }
      errors {
        message
      }
    }
  }
  ${DateTimeRangeFragmentDoc}
`;

/**
 * __useUpdateDeliveryDatesMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryDatesMutation, { data, loading, error }] = useUpdateDeliveryDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryDatesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryDatesMutationResult, UpdateDeliveryDatesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateDeliveryDatesMutationResult, UpdateDeliveryDatesMutationVariables>(
    UpdateDeliveryDatesDocument,
    options
  );
}
export type UpdateDeliveryDatesMutationHookResult = ReturnType<typeof useUpdateDeliveryDatesMutation>;
