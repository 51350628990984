import { ContextMenu, TableColumn, TableLink } from '@zen/DesignSystem';
import OrganisationRoles from '@zen/Networks/OrganisationRoles';
import type { NetworksRoutes } from '@zen/Routes';

import type { Organisation } from './types';

export const getOrganisationTableConfig = (routes: NetworksRoutes): TableColumn<Organisation>[] => [
  {
    title: 'Name',
    key: 'name',
    render: (_, { name, id }) => <TableLink linkTo={routes.organisation.details.getUrl(id)}>{name}</TableLink>,
    sortable: false
  },
  {
    title: 'Org type',
    key: 'roles',
    render: (_, { assignedRoles }) => <OrganisationRoles roles={assignedRoles} />,
    sortable: false
  },
  {
    title: '',
    key: 'actions',
    render: (_, { id }) => (
      <ContextMenu inline={false} items={[{ label: 'Edit', linkTo: routes.organisation.edit.getUrl(id) }]} />
    ),
    sortable: false
  }
];
