import { Loader } from '@googlemaps/js-api-loader';
import { useState } from 'react';

import type { Optional } from '../typescript';

interface Props {
  key: string;
  onLoad?: () => void;
}

interface ApiState {
  api: Optional<object>;
  error: Optional<Error>;
  loading: boolean;
}

declare global {
  interface Window {
    google?: {
      maps?: object;
    };
  }
}

let loader: Loader;

export const useGoogleMapsApi = ({ key, onLoad }: Props): ApiState => {
  const [api, setApi] = useState(window.google?.maps);
  const [error, setError] = useState<Error>();
  const loading: boolean = !api && !error;

  // do not attempt any loading if api is already loaded
  if (!api) {
    // we only keep one loader instance per all load attempts
    if (!loader) {
      loader = new Loader({
        apiKey: key,
        libraries: ['places']
      });
    }
    loader
      .load()
      .then(() => {
        setApi(window.google?.maps);
        onLoad?.();
      })
      .catch((err: Error) => {
        setError(err);
      });
  } else {
    onLoad?.();
  }

  return {
    api,
    error,
    loading
  };
};
