import type { FC } from 'react';
import { useHistory, useLocation } from 'react-router';

import Search from '@zen/Components/Search';
import useTracking from '@zen/utils/hooks/useTracking';
import { mergeQueryParams, parseQueryParams } from '@zen/utils/QueryParams/queryParams';

const OrdersSearch: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { textContains } = parseQueryParams(location.search);
  const { trackEvent } = useTracking();

  const handleSearch = (value: string): void => {
    updateQuerySearch(value);

    trackEvent({
      action: 'PurchaseOrders',
      category: 'Search',
      label: value
    });
  };

  const updateQuerySearch = (value: string = ''): void => {
    history.replace({
      ...location,
      search: mergeQueryParams(location.search, { textContains: value || null })
    });
  };

  return (
    <Search
      initialValue={textContains}
      onClear={updateQuerySearch}
      onSubmit={handleSearch}
      placeholder="Search for a PO with order number reference, SKU code, SKU name or manufacturer name"
    />
  );
};

export default OrdersSearch;
