import type { FC, ReactChild } from 'react';

import { headlineStyles } from './helpers';
import type { HeadlineLevel, HeadlineTagVariant } from './types';

interface Props {
  children: ReactChild;
  className?: string;
  level: HeadlineLevel;
}

const Headline: FC<Props> = ({ level, className = '', children }) => {
  const HeadlineTag: HeadlineTagVariant = `h${level}` as HeadlineTagVariant;

  const classNames: string = `${headlineStyles[level]} ${className}`.trim();

  return (
    <HeadlineTag className={classNames} data-component="headline" data-testid={`headline-${level}`}>
      {children}
    </HeadlineTag>
  );
};

export type { Props as HeadlineProps };

export default Headline;
