import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormCheckbox } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';
import { Button } from '@zen/DesignSystem';
import type { ContactFormFields } from '@zen/Networks/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import FormContactFields from '../FormContactFields/FormContactFields';
import { contactFormValidation } from '../validation';
import ContactFormLocations from './ContactFormLocations/ContactFormLocations';

interface Props {
  initialValues: ContactFormFields;
  isCustomerOwnOrganisation?: boolean;
  isEmailDisabled?: boolean;
  locationId: string | undefined;
  locationsList: Option<string>[];
  onCancel: () => void;
  onSubmit: (values: ContactFormFields) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const ContactForm: FC<Props> = ({
  initialValues,
  locationsList,
  locationId,
  onCancel,
  onSubmit,
  onSuccess,
  isEmailDisabled,
  isCustomerOwnOrganisation
}: Props) => {
  const filterSelectedLocation = (selectedLocations: { locationId: string; value: string }[] = []) => {
    return locationsList.map((location) => {
      const isDisabled = selectedLocations.findIndex((item) => item?.locationId === location.value) > -1;

      return {
        ...location,
        isDisabled
      };
    });
  };

  const renderFormButtons = ({ isSubmitting }: { isSubmitting: boolean }): ReactNode => (
    <FormButtons isSubmitting={isSubmitting}>
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      className="mt-4"
      formButtons={renderFormButtons}
      formName="ContactForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={contactFormValidation}
    >
      {({ errors, setFieldValue, touched, values }) => {
        return (
          <>
            <FormContactFields isEmailDisabled={isEmailDisabled} />
            {isCustomerOwnOrganisation && (
              <div className="mb-4">
                <FormCheckbox
                  label="Auto-Subscribe to all new bookings for this organisation"
                  name="autoSubscribeToOrganisationTargets"
                />
              </div>
            )}
            <ContactFormLocations
              errors={errors}
              locationId={locationId}
              locationsList={filterSelectedLocation(values.contactLocationAssignments)}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
            />
          </>
        );
      }}
    </Form>
  );
};

export default ContactForm;
