import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateHouseBillOfLadingMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateHouseBillOfLadingInput;
}>;

export type UpdateHouseBillOfLadingMutationResult = { __typename?: 'Mutation' } & {
  bookingUpdateHouseBillOfLading?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateHouseBillOfLadingPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const UpdateHouseBillOfLadingDocument = /* #__PURE__ */ gql`
  mutation updateHouseBillOfLading($input: UpdateHouseBillOfLadingInput!) {
    bookingUpdateHouseBillOfLading(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateHouseBillOfLadingMutation__
 *
 * To run a mutation, you first call `useUpdateHouseBillOfLadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHouseBillOfLadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHouseBillOfLadingMutation, { data, loading, error }] = useUpdateHouseBillOfLadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHouseBillOfLadingMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHouseBillOfLadingMutationResult, UpdateHouseBillOfLadingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateHouseBillOfLadingMutationResult, UpdateHouseBillOfLadingMutationVariables>(
    UpdateHouseBillOfLadingDocument,
    options
  );
}
export type UpdateHouseBillOfLadingMutationHookResult = ReturnType<typeof useUpdateHouseBillOfLadingMutation>;
