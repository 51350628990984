import cx from 'classnames';
import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

type IconSize = 'small' | 'normal';

interface IconSet {
  downIcon: IconName;
  rightIcon: IconName;
  upIcon: IconName;
}

const iconSizeMapping: Record<IconSize, IconSet> = {
  normal: {
    downIcon: 'zicon-chevron-down',
    rightIcon: 'zicon-chevron-right',
    upIcon: 'zicon-chevron-up'
  },
  small: {
    downIcon: 'zicon-chevron-down-small',
    rightIcon: 'zicon-chevron-right-small',
    upIcon: 'zicon-chevron-up-small'
  }
};

interface Props {
  className?: string;
  collapsedIconPosition?: 'down' | 'right';
  expanded: boolean;
  expandedIconPosition?: 'up' | 'down';
  onClick?: () => void;
  size?: IconSize;
}

const ChevronIcon: FC<Props> = ({
  size = 'normal',
  className,
  expanded,
  onClick,
  collapsedIconPosition,
  expandedIconPosition
}) => {
  const iconConfig: IconSet = iconSizeMapping[size];
  const collapsedIcon: IconName = collapsedIconPosition === 'right' ? iconConfig.rightIcon : iconConfig.downIcon;
  const expandedIcon: IconName = expandedIconPosition === 'down' ? iconConfig.downIcon : iconConfig.upIcon;

  const icon: IconName = expanded ? expandedIcon : collapsedIcon;

  const classNames: string = cx(
    {
      'group-hover:text-grey-dark': !className
    },
    className,
    'text-grey-base'
  );

  return <Icon className={classNames} icon={icon} onClick={onClick} />;
};

export default ChevronIcon;
