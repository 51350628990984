import { FC, useState } from 'react';

import type { NewCargo } from '@zen/Cargo';
import { useCollectionAndDelivery } from '@zen/CollectionAndDelivery/CollectionAndDeliveryContext';
import { getAddressOptionOnly, getExtendedOptions } from '@zen/CollectionAndDelivery/Details/helper';
import ScheduleActions from '@zen/CollectionAndDelivery/Details/ScheduleActions';
import type { ScheduleActionType, ScheduleItemType } from '@zen/CollectionAndDelivery/Details/types';
import { Checkbox, ContextMenu, MenuItemType, TooltipPlacement } from '@zen/DesignSystem';
import type { ModeOfTransport } from '@zen/types';
import type { Nullable } from '@zen/utils/typescript';

import { useMultiCargoSchedule } from '../../MultiCargoScheduleProvider';
import CargoDetailsAction from './CargoDetailsAction';

interface Props {
  accountUuid: string;
  items: NewCargo[];
  modeOfTransport: ModeOfTransport;
  zencargoReference: string;
}

const CargoDetailsTitle: FC<Props> = ({ zencargoReference, accountUuid, items, modeOfTransport }) => {
  const { active, handleActiveToggle, cargoList, scheduleType, setErrorIds, setScheduleType, setCargos } =
    useMultiCargoSchedule();
  const [type, setType] = useState<Nullable<ScheduleActionType>>(null);
  const { refetchCargoDetails, cargoSchedulePermissions, isSingleCargoRoadShipment } = useCollectionAndDelivery();

  const generateOptions = (): MenuItemType[] => {
    if (isSingleCargoRoadShipment) {
      return getAddressOptionOnly(setType);
    }

    return getExtendedOptions(cargoList, cargoSchedulePermissions, scheduleType, setType);
  };

  const actionOptions: MenuItemType[] = generateOptions();
  const closeActions = () => setType(null);
  const hasItems = !!cargoList.length;
  const allCargosSelected = cargoList.length === items.length;

  const handleSuccess = () => {
    setType(null);
    refetchCargoDetails();
  };

  const handleError = (errorIds: string[]) => {
    if (errorIds.length !== items.length) {
      handleActiveToggle(false);
    }
    setErrorIds(errorIds);
  };

  const handleScheduleSelect = (itemType: ScheduleItemType) => {
    if (active) {
      closeActions();
    }

    handleActiveToggle(true);
    setScheduleType(itemType);
    setCargos(items);
  };

  const handleDeselectCheckbox = () => {
    if (active || allCargosSelected) {
      handleActiveToggle(false);
      setCargos([]);
      setScheduleType(null);
    }
  };

  const getTooltipAlign = (index: number): TooltipPlacement => {
    const shouldAlignLeft: boolean = actionOptions.length - 3 < index;

    return shouldAlignLeft ? 'top-start' : 'top';
  };

  const menuOptions: MenuItemType[] = [
    {
      icon: 'zicon-calendar',
      label: 'Collection',
      onClick: () => handleScheduleSelect('collection')
    },
    { icon: 'zicon-road', label: 'Delivery', onClick: () => handleScheduleSelect('delivery') }
  ];

  return (
    <>
      <div className="flex justify-between items-center h-12">
        <div className="ml-1">
          <ContextMenu align="left" disabled={active || allCargosSelected} hoverDisabled={true} items={menuOptions}>
            <Checkbox checked={active || allCargosSelected} label="Select all" onChange={handleDeselectCheckbox} />
          </ContextMenu>
        </div>
        {scheduleType && (
          <div className="flex items-center text-grey-base relative">
            {actionOptions.map((item: MenuItemType, index: number) => (
              <CargoDetailsAction key={index} item={item} tooltipPlacement={getTooltipAlign(index)} />
            ))}
          </div>
        )}
      </div>
      {type && scheduleType && hasItems && (
        <ScheduleActions
          accountUuid={accountUuid}
          cargoList={cargoList}
          close={closeActions}
          modeOfTransport={modeOfTransport}
          onError={handleError}
          onSuccess={handleSuccess}
          scheduleType={scheduleType}
          type={type}
          zencargoReference={zencargoReference}
        />
      )}
    </>
  );
};

export default CargoDetailsTitle;
