import cx from 'classnames';
import type { FC } from 'react';

interface Props {
  isActive: boolean;
  isHovered: boolean;
}

const StageUpdateWrapper: FC<Props> = ({ isActive, isHovered, children }) => {
  const classNames: string = cx(
    {
      'opacity-100': isHovered || isActive,
      'opacity-0': !isHovered && !isActive
    },
    'transition duration-300 ease-linear'
  );

  return (
    <div className={classNames} data-testid="stage-update-wrapper">
      {children}
    </div>
  );
};

export default StageUpdateWrapper;
