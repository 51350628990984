import * as Yup from 'yup';

import type { Optional } from '@zen/utils/typescript';

export const accountStatusControlFormValidationMessages: Record<string, string> = {
  confirmationText: 'Confirmation text is required',
  wrongText: 'Wrong confirmation text'
};

export const accountStatusControlFormValidation = (requiredConfirmationText: string) => {
  return Yup.object().shape({
    confirmationText: Yup.string()
      .required(accountStatusControlFormValidationMessages.confirmationText)
      .test('match', accountStatusControlFormValidationMessages.wrongText, (currentValue: Optional<string>) => {
        return currentValue === requiredConfirmationText;
      })
  });
};
