import { useApolloClient } from '@apollo/client';
import type { FC, ReactNode } from 'react';

import { highlightQuery } from '@zen/Components/Form/utils';
import { AsyncSelect, TextLink } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import { ContractIdsDocument } from './graphql';
import type { ContractId } from './types';

interface Props {
  error?: boolean;
  name: string;
  onChange?: (value: Nullable<ContractId>) => void;
  value?: ContractId;
}

const ContractIdAsyncSelect: FC<Props> = (props) => {
  const apolloClient = useApolloClient();
  const handleInput = async (query: string = ''): Promise<ContractId[]> => {
    const QUERY = {
      query: ContractIdsDocument,
      variables: {
        textContains: query
      }
    };

    const response = await apolloClient.query(QUERY);

    return response.data.getContracts.nodes;
  };

  const suggestion: ReactNode = (
    <>
      If you think this is a mistake please contact our capacity team{' '}
      <TextLink onClick={() => window.open('mailto:capacity-planners@zencargo.com')}>here</TextLink>
    </>
  );

  const getOptionLabel = (contractId: ContractId, inputValue: string): ReactNode =>
    highlightQuery(contractId.simplifiedContractId, inputValue);

  return (
    <AsyncSelect<ContractId>
      {...props}
      cacheOptions={false}
      className="min-w-48"
      defaultOptions={true}
      formatOptionLabel={getOptionLabel}
      hasError={props.error}
      isClearable={true}
      loadOptions={handleInput}
      name={props.name}
      overLabel="Contract ID"
      placeholder="Start typing"
      suggestion={suggestion}
    />
  );
};

export default ContractIdAsyncSelect;
