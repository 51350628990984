import type { FC } from 'react';

import { Button } from '@zen/DesignSystem';

interface Props {
  onCancel: () => void;
  onSave: () => void;
  savingDisabled: boolean;
}

const ConsolidationManageShipmentsButtons: FC<Props> = ({ onCancel, onSave, savingDisabled }) => (
  <div className="flex">
    <Button className="mr-4" onClick={onCancel} variant="secondary">
      Cancel
    </Button>
    <Button disabled={savingDisabled} onClick={onSave}>
      Add to consolidate
    </Button>
  </div>
);

export default ConsolidationManageShipmentsButtons;
