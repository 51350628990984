import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrganisationContactQueryVariables = GraphQLTypes.Exact<{
  organisationId: GraphQLTypes.Scalars['String'];
  contactId: GraphQLTypes.Scalars['String'];
}>;

export type OrganisationContactQueryResult = { __typename?: 'Query' } & {
  locationContact?: GraphQLTypes.Maybe<
    { __typename?: 'NetworksContact' } & Pick<
      GraphQLTypes.NetworksContact,
      'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'autoSubscribeToOrganisationTargets'
    > & {
        invitation?: GraphQLTypes.Maybe<
          { __typename?: 'Invitation' } & Pick<GraphQLTypes.Invitation, 'acceptUrl' | 'status'> & {
              canSend: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'message' | 'value'> & {
                  reasons?: GraphQLTypes.Maybe<
                    { __typename?: 'FailureReasons' } & Pick<GraphQLTypes.FailureReasons, 'details' | 'fullMessages'>
                  >;
                };
            }
        >;
        canSendResetPasswordInstructions: { __typename?: 'AuthorizationResult' } & Pick<
          GraphQLTypes.AuthorizationResult,
          'value'
        >;
        organisation:
          | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'name'>)
          | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'name'>)
          | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'name'>)
          | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'name'>);
        locations: Array<
          { __typename?: 'NetworksLocationInContact' } & Pick<
            GraphQLTypes.NetworksLocationInContact,
            'id' | 'name' | 'locationType' | 'asMainContact'
          > & {
              address: { __typename?: 'Address' } & Pick<
                GraphQLTypes.Address,
                'street' | 'countyOrState' | 'city' | 'postalCodeOrZip'
              > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>> };
              geolocation?: GraphQLTypes.Maybe<
                { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'longitude' | 'latitude'>
              >;
            }
        >;
      }
  >;
};

export const OrganisationContactQueryDocument = /* #__PURE__ */ gql`
  query organisationContactQuery($organisationId: String!, $contactId: String!) {
    locationContact(organisationId: $organisationId, contactId: $contactId) {
      id
      firstName
      lastName
      email
      phoneNumber
      autoSubscribeToOrganisationTargets
      invitation {
        acceptUrl
        canSend {
          message
          reasons {
            details
            fullMessages
          }
          value
        }
        status
      }
      canSendResetPasswordInstructions {
        value
      }
      organisation {
        name
      }
      locations {
        id
        name
        locationType
        address {
          street
          countyOrState
          city
          country {
            code
            name
          }
          postalCodeOrZip
        }
        geolocation {
          longitude
          latitude
        }
        asMainContact
      }
    }
  }
`;

/**
 * __useOrganisationContactQuery__
 *
 * To run a query within a React component, call `useOrganisationContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationContactQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useOrganisationContactQuery(
  baseOptions: Apollo.QueryHookOptions<OrganisationContactQueryResult, OrganisationContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrganisationContactQueryResult, OrganisationContactQueryVariables>(
    OrganisationContactQueryDocument,
    options
  );
}
export function useOrganisationContactQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganisationContactQueryResult, OrganisationContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrganisationContactQueryResult, OrganisationContactQueryVariables>(
    OrganisationContactQueryDocument,
    options
  );
}
export type OrganisationContactQueryHookResult = ReturnType<typeof useOrganisationContactQuery>;
export type OrganisationContactQueryLazyQueryHookResult = ReturnType<typeof useOrganisationContactQueryLazyQuery>;
