import type { FC } from 'react';

import InlineEditableField from '@zen/Components/InlineEditableField';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateVehicleTrailerIdMutation } from './graphql';

interface Props {
  cargoId: string;
  disabled: boolean;
  trailerId: string;
}

const VehicleTrailerIdInput: FC<Props> = ({ cargoId, disabled, trailerId }) => {
  const { addSuccess, addError } = useNotification();

  const [updateTrailerId] = useUpdateVehicleTrailerIdMutation();

  const handleTrailerIdUpdate = async (value: string): Promise<void> => {
    const input = {
      id: cargoId,
      trailerId: value
    };

    await performMutation({
      mutationFn: () => updateTrailerId({ variables: { input } }),
      onSuccess: () => addSuccess('Trailer ID updated.'),
      onError: () => addError()
    });
  };

  return (
    <InlineEditableField
      className="py-3.5 px-2"
      disabled={disabled}
      name="trailerId"
      onUpdate={handleTrailerIdUpdate}
      overLabel="Trailer ID"
      value={trailerId}
    />
  );
};

export default VehicleTrailerIdInput;
