import type { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import Page from '@zen/Components/Page';
import PageNotFound from '@zen/Components/PageNotFound';
import { Loading } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks';
import ContactForm from '@zen/Networks/Form/ContactForm';
import { useNetworksCreateLocationContactMutation, useOrganisationLocationsQuery } from '@zen/Networks/graphql';
import type { ContactFormFields } from '@zen/Networks/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { getEmptyContact, prepareContactData, prepareLocationsData } from '../contactsHelpers';
import ContactTitle from '../ContactTitle';

type LocationState = {
  fromMyOrganisationPage?: boolean;
  locationId?: string;
};

interface Props {
  networkId: string;
}

const NewContact: FC<Props> = ({ networkId }) => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const location = useLocation<LocationState>();
  const history = useHistory();

  const isFromMyOrganisationPage: boolean = !!location.state?.fromMyOrganisationPage;
  const locationId: string | undefined = location.state?.locationId;

  const { data, loading, error } = useOrganisationLocationsQuery({
    variables: {
      accountUuid: networkId,
      ids: [organisationId]
    },
    fetchPolicy: 'no-cache'
  });
  const organisationLocationData = data?.network?.organisations?.nodes || [];

  const [createContact] = useNetworksCreateLocationContactMutation();

  const { addSuccess } = useNotification();
  const { routes } = useNetworksContext();

  if (loading) {
    return <Loading />;
  }

  if (error || !organisationLocationData[0]) {
    return <PageNotFound />;
  }

  const organisationPage: string = isFromMyOrganisationPage
    ? routes.myOrganisation.details.getUrl()
    : routes.organisation.details.getUrl(organisationId);

  const { name: organisationName, locations } = organisationLocationData[0];

  const pageTitle = (
    <ContactTitle locationId={locationId || null} organisationId={organisationId} organisationName={organisationName} />
  );

  const handleSubmit = async (values: ContactFormFields): Promise<IOkOrErrorResult> => {
    const submitData = prepareContactData(values, organisationId);

    const results = await createContact({
      variables: {
        input: {
          ...submitData
        }
      }
    });

    return Promise.resolve({ ok: results.data || null, error: results.errors });
  };

  const handleSuccess = async () => {
    addSuccess('New contact has been created.');

    history.push(organisationPage);
  };

  const handleCancel = (): void => history.push(organisationPage);

  return (
    <Page browserTitle="Add a new contact" defaultBackUrl={organisationPage} title={pageTitle} width="narrow">
      <ContactForm
        initialValues={getEmptyContact(locationId)}
        locationId={locationId}
        locationsList={prepareLocationsData(locations)}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
      />
    </Page>
  );
};

export default NewContact;
