import type { FC, ReactNode } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { highlightQuery } from '@zen/Components/Form/utils';
import { AsyncSelect } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks';
import { handleOrganisationInputLoad } from '@zen/Networks/networksHelpers';
import { NetworksOrganisationInterface, Organisation, OrganisationTypeValue } from '@zen/Networks/types';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Nullable } from '@zen/utils/typescript';

interface Props {
  networkId: string;
}

const OrganisationSearch: FC<Props> = ({ networkId }) => {
  const { trackEvent } = useTracking();
  const { push } = useHistory();
  const { routes } = useNetworksContext();

  const handleSelect = (option: Nullable<NetworksOrganisationInterface>): void => {
    if (option) {
      const selectedOrganisationId: string = option.id;

      trackEvent({
        action: 'Networks',
        category: 'Search',
        label: option.name || ''
      });

      push(routes.organisation.details.getUrl(selectedOrganisationId));
    }
  };

  const suggestion: ReactNode = (
    <span>
      If you want to add a new organisation&nbsp;
      <Link className="font-bold text-navy-base" to={routes.organisation.new.getUrl()}>
        click here
      </Link>
    </span>
  );

  const getOptionLabel = (props: Organisation, inputValue: string = ''): ReactNode => {
    const { name, __typename: organisationType } = props;

    const organisationTypes: OrganisationTypeValue[] = [OrganisationTypeValue.AGENT, OrganisationTypeValue.FORWARDER];
    const organisationTypeName: string | undefined = organisationTypes
      .map((type) => removeSpecialCharactersAndUpperFirst(type))
      .find((type) => organisationType === `${type}Organisation`);

    return (
      <div>
        <div className="text-base text-grey-dark">{highlightQuery(name, inputValue)}</div>
        {organisationTypeName && <div className="text-xs text-grey-base mt-1">{organisationTypeName}</div>}
      </div>
    );
  };

  return (
    <div className="mb-6" data-testid="networks-search">
      <AsyncSelect<Organisation>
        formatOptionLabel={getOptionLabel}
        isClearable={true}
        loadOptions={handleOrganisationInputLoad(networkId)}
        onChange={handleSelect}
        placeholder="Search my network..."
        suggestion={suggestion}
      />
    </div>
  );
};

export default OrganisationSearch;
