import transitionCargo from '@zen/Cargo/cargoTransition.helper';
import type { CargoTransition, NewCargo } from '@zen/Cargo/types';
import {
  ShippingOrderState,
  Tracking,
  TrackingContainerInput,
  TrackingMode,
  TrackingSubscriptionStatus,
  UpdateTrackingInput
} from '@zen/Shipment';
import { IncotermsValue } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

import type { TrackingData } from '../types';
import type { TrackingDetailsFormPermissions, TrackingFormInitialValues, TrackingFormValues } from './TrackingDetailsForm';
import type { MissingDataPoint, OceanTrackingPermissionProperties, OceanTrackingResults } from './types';

const trackingTypeLabelMapping: Record<TrackingMode, string> = {
  [TrackingMode.AUTO]: 'Automatic',
  [TrackingMode.MANUAL]: 'Manual'
};

const trackingStatusLabelMapping: Record<TrackingSubscriptionStatus, string> = {
  [TrackingSubscriptionStatus.ACTIVE]: 'Active',
  [TrackingSubscriptionStatus.ARCHIVED]: 'Archived',
  [TrackingSubscriptionStatus.CANCELLED]: 'Cancelled',
  [TrackingSubscriptionStatus.COMPLETED]: 'Completed',
  [TrackingSubscriptionStatus.INITIALIZING]: 'Initialising',
  [TrackingSubscriptionStatus.NONE]: 'None',
  [TrackingSubscriptionStatus.INVALID]: 'Invalid'
};

export const prepareFormInitialValues = (data: OceanTrackingResults): TrackingFormInitialValues => {
  const tracking: Optional<Tracking> = data?.cargo?.tracking;
  const newCargo: Optional<CargoTransition> = transitionCargo(data?.cargo, data?.cargos);

  return {
    billOfLading: tracking?.billOfLading,
    carrierBookingReference: tracking?.carrierBookingReference,
    scacCode: tracking?.scacCode,
    containers: newCargo?.cargoList || [],
    shippingOrderState: tracking?.shippingOrderState,
    shippingOrderConfirmedOn: tracking?.shippingOrderConfirmedOn,
    shippingOrderReleasedOn: tracking?.shippingOrderReleasedOn
  };
};

export const prepareFormPermissions = <T extends OceanTrackingPermissionProperties>(
  data: Optional<T>
): TrackingDetailsFormPermissions => {
  const incoterms: Optional<IncotermsValue> = data?.incoterms?.value;
  const canToggleShippingOrderConfirmed: boolean = !!data?.canToggleShippingOrderConfirmed?.value;
  const canSetShippingOrderNotRequired: boolean = !!data?.canSetShippingOrderNotRequired?.value;
  const hasShippingOrderReleasedOn: boolean = incoterms === IncotermsValue.FOB || incoterms === IncotermsValue.FCA;

  return {
    canToggleShippingOrderConfirmed,
    canSetShippingOrderNotRequired,
    canSetShippingOrderReleasedOn: hasShippingOrderReleasedOn
  };
};

export const prepareMissingDataPoints = (tracking: Optional<Tracking>, containers: Optional<NewCargo[]>): string[] => {
  const trackingData: MissingDataPoint[] = [
    { label: 'Bill of lading', isProvided: !!tracking?.billOfLading },
    { label: 'Carrier booking ref', isProvided: !!tracking?.carrierBookingReference },
    { label: 'Carrier / SCAC code', isProvided: !!tracking?.scacCode },
    { label: 'Container number', isProvided: !containers?.some(({ containerNumber }) => !containerNumber) },
    { label: 'Shipping order', isProvided: tracking?.shippingOrderState === ShippingOrderState.CONFIRMED }
  ];

  return trackingData.filter(({ isProvided }) => !isProvided).map(({ label }) => label);
};

export const prepareTrackingData = (data: OceanTrackingResults): TrackingData => {
  const tracking: Optional<Tracking> = data?.cargo?.tracking;
  const trackingStatus: TrackingSubscriptionStatus = tracking?.subscriptionStatus || TrackingSubscriptionStatus.NONE;
  const trackingType: TrackingMode = tracking?.trackingType || TrackingMode.MANUAL;
  const newCargo: Optional<CargoTransition> = transitionCargo(data?.cargo, data?.cargos);

  return {
    trackingStatus: trackingStatusLabelMapping[trackingStatus],
    trackingType: trackingTypeLabelMapping[trackingType],
    missingDataPoints: prepareMissingDataPoints(data?.cargo?.tracking, newCargo?.cargoList)
  };
};

const prepareContainers = (containers: NewCargo[] = []): TrackingContainerInput[] => {
  return containers.map(({ id, containerNumber }) => ({ id, containerNumber }));
};

export const prepareUpdateTrackingPayload = (
  zencargoReference: string,
  values: TrackingFormValues,
  { canSetShippingOrderReleasedOn }: TrackingDetailsFormPermissions
): UpdateTrackingInput => {
  const isShippingOrderStateConfirmed: boolean = values.shippingOrderState === ShippingOrderState.CONFIRMED;
  const includeShippingOrderReleaseDate: boolean = isShippingOrderStateConfirmed && canSetShippingOrderReleasedOn;

  const {
    shippingOrderConfirmedOn,
    shippingOrderReleasedOn,
    scacCode,
    containers,
    carrierBookingReference,
    shippingOrderState,
    billOfLading
  } = values;

  return {
    zencargoReference,
    trackingData: {
      scacCode,
      carrierBookingReference,
      shippingOrderState,
      ...(isShippingOrderStateConfirmed && { shippingOrderConfirmedOn }),
      ...(includeShippingOrderReleaseDate && { shippingOrderReleasedOn }),
      billOfLading,
      containers: prepareContainers(containers)
    }
  };
};
