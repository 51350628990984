import type { FC } from 'react';

import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import { getToday } from '@zen/utils/date';

interface Props {
  name: string;
}

const FormDateFilter: FC<Props> = ({ name }) => {
  return (
    <div className="mb-4 bg-grey-lightest p-3" data-testid="form-date-filter">
      <FormDatePickerInput
        className="w-48"
        initialDate={getToday()}
        label="Select specific date"
        name={name}
        noMargin={true}
        pickerPositionX="left"
        placeholder="01/01/2020"
      />
    </div>
  );
};

export default FormDateFilter;
