import { mergeWith } from 'lodash';

import { CargoWeightUnitEnum } from '@zen/Cargo';
import type { Option } from '@zen/DesignSystem';
import type { NewCargoType } from '@zen/types';
import { cargoValues, newCargoContainerTypeLabelMapping } from '@zen/types';
import type { DeepNullable } from '@zen/utils/typescript';
import { deepMergeNilValues } from '@zen/utils/utils';

import type { FullContainerFormData } from '../../types';

interface ContainerType {
  label: string;
  type: NewCargoType;
}

export const newCargoContainerTypes: ContainerType[] = [
  {
    label: newCargoContainerTypeLabelMapping[cargoValues.containerX20Dv].long,
    type: cargoValues.containerX20Dv
  },
  {
    label: newCargoContainerTypeLabelMapping[cargoValues.containerX20Hc].long,
    type: cargoValues.containerX20Hc
  },
  {
    label: newCargoContainerTypeLabelMapping[cargoValues.containerX40Dv].long,
    type: cargoValues.containerX40Dv
  },
  {
    label: newCargoContainerTypeLabelMapping[cargoValues.containerX40Hc].long,
    type: cargoValues.containerX40Hc
  },
  {
    label: newCargoContainerTypeLabelMapping[cargoValues.containerX45Hc].long,
    type: cargoValues.containerX45Hc
  }
];

export const containerOptions: Option<NewCargoType>[] = newCargoContainerTypes.map(
  ({ type, label }: ContainerType): { label: string; value: NewCargoType } => ({
    value: type,
    label
  })
);

export const mergeInitialValues = (values: Partial<FullContainerFormData>): DeepNullable<FullContainerFormData> => {
  const defaultValues: DeepNullable<FullContainerFormData> = {
    actualCbm: null,
    containerType: null,
    containerNumber: null,
    containerSealNumber: null,
    grossWeight: {
      value: null,
      unit: CargoWeightUnitEnum.KG
    },
    looseCargoType: null,
    quantity: null,
    hazardous: false,
    refrigerated: false,
    overweight: false
  };

  return mergeWith(values, defaultValues, deepMergeNilValues);
};
