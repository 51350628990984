import type { Optional } from '@zen/utils/typescript';

export enum IntakeSkuOverviewType {
  ON_TIME_IN_FULL = 'ON_TIME_IN_FULL',
  OVER_FULFILLED = 'OVER_FULFILLED',
  PARTIAL_FULFILMENT = 'PARTIAL_FULFILMENT',
  ZERO_STOCK = 'ZERO_STOCK'
}

export interface IntakeSkuOverview {
  onTimeInFull: Optional<number>;
  overFulfilled: Optional<number>;
  partialFulfilment: Optional<number>;
  zeroStock: Optional<number>;
}
