import type { FC } from 'react';

import { Select } from '@zen/DesignSystem';
import { shipmentTypeOptions } from '@zen/Shipment/helpers';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import type { Shipment } from '../../types';
import { useUpdateShipmentTypeMutation } from '../graphql';

interface Props {
  canUpdateShipmentType: boolean;
  refetchShipmentDetails: () => void;
  shipmentType: Shipment;
  zencargoReference: string;
}

const ShipmentTypeCargoInfoInput: FC<Props> = ({
  shipmentType,
  zencargoReference,
  refetchShipmentDetails,
  canUpdateShipmentType
}) => {
  const { addSuccess, addError } = useNotification();
  const [updateShipmentType] = useUpdateShipmentTypeMutation();

  const handleUpdateShipmentType = async (shipmentTypeValue: Nullable<Shipment>): Promise<void> => {
    if (shipmentTypeValue) {
      await performMutation({
        mutationFn: () =>
          updateShipmentType({
            variables: {
              input: {
                zencargoReference,
                shipmentType: shipmentTypeValue
              }
            }
          }),
        onError: () => addError(),
        onSuccess: () => {
          refetchShipmentDetails();
          addSuccess('Shipment type updated.');
        }
      });
    }
  };

  return (
    <Select
      className="w-36"
      isDisabled={!canUpdateShipmentType}
      name="shipmentType"
      onChange={handleUpdateShipmentType}
      options={shipmentTypeOptions}
      overLabel="Shipment type"
      value={shipmentType}
    />
  );
};

export default ShipmentTypeCargoInfoInput;
