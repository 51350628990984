import type { FC } from 'react';

import FormTextarea from '@zen/Components/Form/FormTextarea';

interface Props {
  autoFocusedField?: string;
}

const SpecialInstructionFields: FC<Props> = ({ autoFocusedField }) => {
  return (
    <div data-testid="special-instruction-fields">
      <FormTextarea
        autoFocus={autoFocusedField === 'specialInstructions'}
        className="max-w-lg"
        name="specialInstructions"
        textareaRows={3}
      />
    </div>
  );
};

export default SpecialInstructionFields;
