import type { FC, ReactNode } from 'react';

import Card from '@zen/Components/Card';
import ActionItemDetails from '@zen/Dashboard/ActionItems/ActionItemDetails';
import { ActionItemType, ActionItemTypeEnum } from '@zen/Dashboard/ActionItems/types';

import ActionItemApproval from './ActionItemApproval';
import ActionItemFileupload from './ActionItemFileUpload';

interface Props {
  accountUuid: string;
  actionItem: Pick<ActionItemType, 'id' | 'dueDate' | 'name' | 'title' | 'type'>;
  onShipmentChange: () => void;
  zencargoReference: string;
}

const ActionItem: FC<Props> = ({ actionItem, zencargoReference, onShipmentChange, accountUuid }) => {
  const renderActionButtons = (): ReactNode => {
    switch (actionItem.type) {
      case ActionItemTypeEnum.FILEUPLOAD:
        return <ActionItemFileupload accountUuid={accountUuid} actionItem={actionItem} zencargoReference={zencargoReference} />;

      case ActionItemTypeEnum.APPROVAL:
        return (
          <ActionItemApproval actionItem={actionItem} onShipmentChange={onShipmentChange} zencargoReference={zencargoReference} />
        );
      default:
        return null;
    }
  };

  return (
    <Card actions={renderActionButtons()} className="mt-4" leftIcon="zicon-flag">
      <ActionItemDetails actionItem={actionItem} />
    </Card>
  );
};

export default ActionItem;
