import type { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { animateScroll as scroll } from 'react-scroll';

import Page from '@zen/Components/Page';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import Routing from '@zen/utils/Routing/index';
import type { Undefinable } from '@zen/utils/typescript';

import { useProductsCreateProductMutation } from '../graphql';
import ProductForm, { prepareInitialProductValues } from '../ProductForm';
import type { InitialProductFormValues, ProductFormType } from '../ProductForm/types';
import type { ProductDetails as ProductDetailsType, ProductInput, ProductMutationResults, ProductProperty } from '../types';

const NewProduct: FC = () => {
  const { accountUuid } = useAccount();
  const { push } = useHistory();
  const { addSuccess, addError } = useNotification();
  const [addProduct] = useProductsCreateProductMutation();
  const { state } = useLocation<{ initialValue: ProductDetailsType }>();
  const duplicatedProductAttributes: ProductDetailsType = state?.initialValue || {};

  const initialValues: InitialProductFormValues = prepareInitialProductValues(duplicatedProductAttributes);

  const onSubmit = (values: ProductFormType): Promise<IOkOrErrorResult> => {
    const properties: Undefinable<ProductProperty[]> = values.properties?.filter(
      (property) => property?.name !== '' && property?.value !== ''
    );

    scroll.scrollToTop();

    const productValues: ProductInput = {
      ...values,
      packageInfo: values.packageInfo,
      properties
    };

    return performFormMutation({
      mutationFn: () => addProduct({ variables: { input: { accountUuid, product: productValues } } }),
      onError: addError
    });
  };

  const handleSuccess = (values: ProductMutationResults, addNext: boolean) => {
    addSuccess('Product has been saved.');

    if (!addNext) {
      push(Routing.showProductPath(values.product?.id || ''));
    }
  };

  return (
    <Page defaultBackUrl="/dashboard/products" title="Add new product" width="narrow">
      <ProductForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSuccess={handleSuccess}
        showAddNew={!state?.initialValue}
      />
    </Page>
  );
};

export default NewProduct;
