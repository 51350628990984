import { FC, useContext, useState } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import QueryHandler from '@zen/Components/QueryHandler';
import type { CargoReadyDateValues } from '@zen/Orders/components/CargoReadyDateForm';
import UpdateCRDContext from '@zen/Orders/contexts/UpdateCRDContext';
import type {
  OrderListViewItem,
  OrderListViewLot,
  OrderListViewLotWithoutPurchaseOrder,
  OrderListViewPurchaseOrder
} from '@zen/Orders/types';
import { getCargoReadyDateData } from '@zen/Orders/utils';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import type { Optional } from '@zen/utils/typescript';

import {
  OrderListLotsAndPurchaseOrderQueryVariables,
  useControlTowerOrdersPermissionsQuery,
  useOrderListLotsAndPurchaseOrderQueryLazyQuery
} from '../../graphql';
import { mergePurchaseOrderIntoLots } from './helpers';
import OrderListItemDetails from './OrderListItemDetails';
import OrderListItemHeadline from './OrderListItemHeadline';
import type { OrderOverviewProps } from './types';

interface Props {
  order: OrderListViewItem;
  orderDetailsComponent?: FC<{ canViewForwarder: boolean; orderLots: OrderListViewLot[] }>;
  orderLotsQueryVariables: Partial<OrderListLotsAndPurchaseOrderQueryVariables>;
  orderOverviewComponent?: FC<OrderOverviewProps>;
}

const OrderListItem: FC<Props> = (props) => {
  const {
    order,
    orderDetailsComponent: OrderDetailsComponent = OrderListItemDetails,
    orderOverviewComponent: OrderOverviewComponent = OrderListItemHeadline,
    orderLotsQueryVariables
  } = props;
  const { setCargoReadyDateValues } = useContext(UpdateCRDContext);
  const { addItems, initializeOrderLots, isOrderSelected, isOrderPartiallySelected, removeItems } = useContext(BasketContext);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [cargoReadyDateUpdateRequested, setCargoReadyDateUpdateRequested] = useState<boolean>(false);
  const [orderSelectionRequested, setOrderSelectionRequested] = useState<boolean>(false);
  const orderAccountId: Optional<string> = order?.customer?.uuid;
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();

  // the handle complete is used here, because function returned
  // by the lazy query is not a promise and we need to open cargo slide out or toggle order selection
  // once we receive the response from the query
  const handleComplete = (): void => {
    if (orderLots && orderLots.length > 0) {
      initializeOrderLots(orderLots);
    }

    if (cargoReadyDateUpdateRequested) {
      setCargoReadyDateUpdateRequested(false);

      openCargoReadyDateSlideout();
    }

    if (orderSelectionRequested) {
      setOrderSelectionRequested(false);

      toggleOrderSelection();
    }
  };

  const [getOrderDetails, { data: orderLotsResult, error, loading }] = useOrderListLotsAndPurchaseOrderQueryLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      purchaseOrderIds: [order.id],
      paginated: false,
      newCargoService: !!isNewCargoServiceEnabled,
      ...orderLotsQueryVariables
    } as OrderListLotsAndPurchaseOrderQueryVariables,
    onCompleted: handleComplete
  });

  const { data: controlTowerPermissions } = useControlTowerOrdersPermissionsQuery({
    variables: { uuid: orderAccountId || '' },
    skip: !orderAccountId
  });

  const lotsFromQuery = orderLotsResult?.purchaseOrdersLots?.nodes as Optional<OrderListViewLotWithoutPurchaseOrder[]>;
  const purchaseOrderFromQuery: Optional<OrderListViewPurchaseOrder> = orderLotsResult?.purchaseOrders?.nodes?.[0];

  const orderLots: Optional<OrderListViewLot[]> = mergePurchaseOrderIntoLots(purchaseOrderFromQuery, lotsFromQuery);
  const controlTower = controlTowerPermissions?.account?.controlTower;
  const canViewForwarder: boolean = controlTower ? checkPermission(controlTower, 'canViewForwardersOnPurchaseOrders') : false;
  const selectableLotIds: Optional<string[]> = orderLots && orderLots.map(({ id }) => id);
  const isOrderItemsSelected: boolean = isOrderSelected(order, orderLots);
  const isOrderItemsPartiallySelected: boolean = isOrderPartiallySelected(order, orderLots);
  const isLoading: boolean = loading && !orderLots;

  // we can not await getOrderDetails, because function returned
  // by the lazy query is not a promise
  const handleCargoReadyUpdate = (): void => {
    if (!orderLots) {
      getOrderDetails();

      setCargoReadyDateUpdateRequested(true);
    } else {
      openCargoReadyDateSlideout();
    }
  };

  // we can not await getOrderDetails, because function returned
  // by the lazy query is not a promise
  const handleOrderSelect = (): void => {
    if (!orderLots) {
      getOrderDetails();

      setOrderSelectionRequested(true);
    } else {
      toggleOrderSelection();
    }
  };

  const handleToggle = (): void => {
    if (!orderLots) {
      getOrderDetails();
    }

    setExpanded(!expanded);
  };

  const openCargoReadyDateSlideout = (): void => {
    if (!orderLots) return;

    const cargoReadyDateValues: CargoReadyDateValues = getCargoReadyDateData(orderLots);

    setCargoReadyDateValues(cargoReadyDateValues);
  };

  const toggleOrderSelection = (): void => {
    if (isOrderItemsSelected && selectableLotIds) {
      removeItems(selectableLotIds);
    } else {
      if (!orderLots) return;
      addItems(orderLots);
    }
  };

  return (
    <div className="border-b border-solid border-grey-lightest" data-testid="order-list-item">
      <OrderOverviewComponent
        expanded={expanded}
        isOrderPartiallySelected={isOrderItemsPartiallySelected}
        isOrderSelected={isOrderItemsSelected}
        onCargoReadyUpdate={handleCargoReadyUpdate}
        onClick={handleToggle}
        onOrderSelect={handleOrderSelect}
        order={order}
      />
      {expanded && (
        <div className="bg-white">
          <QueryHandler data={orderLots} error={!!error} isLoading={isLoading}>
            {(orderLotsData) => <OrderDetailsComponent canViewForwarder={canViewForwarder} orderLots={orderLotsData} />}
          </QueryHandler>
        </div>
      )}
    </div>
  );
};

export default OrderListItem;
