import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import type { NotificationAction } from '../types';

interface Props {
  action?: NotificationAction;
  message: string;
}

const ToastMessage: FC<Props> = ({ action, message }) => {
  const renderActionLink = (): ReactNode => {
    if (!action) return;

    const className: string = 'hover:underline cursor-pointer whitespace-nowrap';
    const { label, linkTo, onClick } = action;

    if (linkTo) {
      return (
        <Link className={className} onClick={onClick} to={linkTo}>
          {label}
        </Link>
      );
    }

    return (
      <span className={className} onClick={onClick}>
        {label}
      </span>
    );
  };

  return (
    <div className="flex text-white items-start text-sm font-normal font-sans space-x-6 leading-normal">
      <div className="max-w-72">{message}</div>
      {renderActionLink()}
    </div>
  );
};

export default ToastMessage;
