import { FC, ReactNode, useCallback } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import PageContent from '@zen/Components/PageContent';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Pagination, Table, useTableConfiguration } from '@zen/DesignSystem';
import useOrdersTableView from '@zen/Orders/hooks/useOrdersTableView';
import type { OrderTableView } from '@zen/Orders/types';
import type { SortInput } from '@zen/types';

import NoResults from '../NoResults';
import { getColumns } from './ordersTableConfiguration';
import type { OrderListCapabilities } from './types';

interface Props {
  canViewCustomer?: boolean;
  filterByUserPreferences: boolean;
  hasFilters: boolean;
  onRequestFilterChange?: () => void;
  onSortChange: (sortInput: SortInput) => void;
  sortOrder: SortInput;
}

const capability = { canPerformOrderActions: false, canViewCustomer: false };

const TableView: FC<Props> = ({
  canViewCustomer = true,
  filterByUserPreferences,
  hasFilters,
  onRequestFilterChange,
  onSortChange,
  sortOrder
}) => {
  const { hiddenColumns, tableId } = useTableConfiguration();

  const handleButtonClick = useCallback(() => onRequestFilterChange?.(), [onRequestFilterChange]);

  const noResults: ReactNode = (
    <PageContent width="wide">
      <NoResults hasFilters={hasFilters} onButtonClick={handleButtonClick} />
    </PageContent>
  );

  const { nodes, paginationInfo, loading, error, totalCount } = useOrdersTableView(filterByUserPreferences, sortOrder);

  return (
    <QueryHandler
      data={nodes}
      error={!!error}
      isLoading={loading}
      loadingComponent={
        <SkeletonTableLoading
          columns={getColumns({ capabilities: capability })}
          hiddenColumns={hiddenColumns}
          tableId={tableId}
        />
      }
      noResults={noResults}
    >
      {(purchaseOrders: OrderTableView[]) => {
        const canPerformAction = (key: keyof OrderTableView): boolean => checkPermission<OrderTableView>(purchaseOrders[0], key);
        const permissionsList: Array<keyof OrderTableView> = ['canRemove', 'canUpdate', 'canClose'];
        const canPerformOrderActions: boolean = permissionsList.some(canPerformAction);

        const capabilities: OrderListCapabilities = { canPerformOrderActions, canViewCustomer };

        return (
          <div data-testid="order-table-view">
            <Table<OrderTableView>
              columns={getColumns({ capabilities })}
              data={purchaseOrders}
              hiddenColumns={hiddenColumns}
              onOrderChange={onSortChange}
              order={sortOrder}
              tableId={tableId}
              totalCountConfig={{
                totalCount,
                entityName: 'order'
              }}
            />
            <Pagination pageInfo={paginationInfo} />
          </div>
        );
      }}
    </QueryHandler>
  );
};

export default TableView;
