import type { Account as AccountType } from '@zen/Components/AccountSwitcherForm';
import { useAllAccountsQuery } from '@zen/Components/AccountSwitcherForm';
import type { Division } from '@zen/graphql/types.generated';

import type { ServerError } from '../../types';
import type { Undefinable } from '../typescript';
import useAccount from './useAccount';

export interface Account {
  division?: Division;
  registeredCompanyName: string;
  uuid: string;
}

interface QueryResult {
  data: Undefinable<Account[]>;
  error: Undefinable<ServerError>;
  isLoading: boolean;
}

export const getAccountLabel = (account: AccountType, isAdmin: boolean = false): string => {
  const { division, name } = account;

  return division ? `${division.name}${isAdmin ? ` (${name})` : ''}` : name;
};

const useAccounts = (): QueryResult => {
  const { userProfile } = useAccount();
  const { data, loading, error } = useAllAccountsQuery({
    fetchPolicy: 'cache-and-network'
  });

  const accounts: Undefinable<Account[]> = data?.accounts?.nodes?.map((accountItem) => ({
    uuid: accountItem?.uuid || '',
    registeredCompanyName: accountItem ? getAccountLabel(accountItem, userProfile.isAdmin) : ''
  }));

  return {
    data: accounts,
    error,
    isLoading: loading
  };
};

export default useAccounts;
