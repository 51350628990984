import type { FC } from 'react';

import type { Nullable, Undefinable } from '@zen/utils/typescript';

import ContractIdAsyncSelect from './ContractIdAsyncSelect';
import type { ContractId } from './ContractIdAsyncSelect/types';
import { useContractIdQuery } from './graphql';
import { useContractIdAssignment } from './hooks/useContractIdAssignment';

interface Props {
  zencargoReference: string;
}

const ContractIdSection: FC<Props> = ({ zencargoReference }) => {
  const { data } = useContractIdQuery({ variables: { zencargoReference }, fetchPolicy: 'no-cache' });
  const [updateContractId] = useContractIdAssignment();
  const assignedContractId: Undefinable<ContractId> = data?.getContract || undefined;

  const handleChange = async (contractId: Nullable<ContractId>): Promise<void> => {
    await updateContractId(zencargoReference, contractId?.id);
  };

  return <ContractIdAsyncSelect name="contractId" onChange={handleChange} value={assignedContractId} />;
};

export default ContractIdSection;
