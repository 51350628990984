import cx from 'classnames';
import { CSSProperties, FC, ReactNode, RefObject, SyntheticEvent, useMemo } from 'react';
import { Resizable } from 'react-resizable';

import { Loading } from '@zen/DesignSystem';
import type { SortingOrder } from '@zen/types';
import { useHover } from '@zen/utils/hooks/useHover';

import DragHandle from '../../DragHandle';
import ResizeHandle from '../../ResizeHandle';
import SortIndicator from '../../SortIndicator';
import { getMaxResizeWidth } from '../helpers';

interface Props {
  children?: ReactNode;
  className?: string;
  draggable?: boolean;
  fixed?: boolean;
  isDragging: boolean;
  isOver: boolean;
  isSorted?: boolean;
  loading?: boolean;
  offsetElementRef: RefObject<HTMLDivElement>;
  onClick: () => void;
  onResizeEnd?: (width: number) => void;
  resizable?: boolean;
  resizableWidth?: number;
  setCellRef: (element: HTMLTableHeaderCellElement) => void;
  setDragRef: (element: HTMLDivElement) => void;
  setResizabeleWidth: (width: number) => void;
  sortDirection?: SortingOrder;
  sortable?: boolean;
  style: CSSProperties;
  title: ReactNode;
}

const StyledTableHeaderCell: FC<Props> = (props) => {
  const [ref, isHovered] = useHover();
  const {
    children,
    className,
    title,
    draggable,
    fixed,
    isDragging,
    isOver,
    onClick,
    resizable,
    setCellRef,
    setDragRef,
    sortable,
    sortDirection,
    style,
    resizableWidth,
    offsetElementRef,
    onResizeEnd,
    setResizabeleWidth,
    isSorted,
    loading = false,
    ...rest
  } = props;

  const cellClassNames: string = cx(
    'zen-table-resize-wrap',
    'group',
    'h-10',
    'align-middle',
    'text-grey-dark text-xs font-bold',
    'hover:bg-grey-lighter',
    {
      'active:bg-azure-lighter': sortable || resizable,
      'opacity-50': isDragging,
      'bg-grey-lightest': !isOver,
      'bg-azure-lighter': isOver,
      'cursor-pointer': sortable,
      relative: resizable && !fixed
    },
    className
  );

  const resizableClassnames: string = cx('absolute inset-0');

  const contentClassNames: string = 'flex justify-between items-center h-10 px-4 ';

  const renderSort = useMemo(() => {
    if (!sortable) {
      return null;
    }

    return loading ? (
      <Loading color="grey" size="smallest" />
    ) : (
      <SortIndicator className="text-grey-base ml-2 z-50" direction={sortDirection} isHovered={isHovered} />
    );
  }, [sortable, loading, isHovered]);

  const handleResize = (_: SyntheticEvent, data: { size: { width: number } }): void => {
    if (!resizable) {
      return;
    }

    setResizabeleWidth(data?.size?.width);
  };

  const handleResizeStop = (_: SyntheticEvent, data: { size: { width: number } }): void => {
    if (!resizable) {
      return;
    }

    onResizeEnd?.(data?.size?.width);
  };

  const renderStyledHeader = (): JSX.Element => {
    return (
      <>
        <div ref={ref} className={contentClassNames} data-testid="column-title" onClick={onClick}>
          <div className="flex-1 text-left mr-2">{title}</div>
          {draggable && (
            <DragHandle
              ref={setDragRef}
              className="group-hover:opacity-100 opacity-0 transition-opacity z-50"
              isDragging={isDragging}
            />
          )}
          {renderSort}
        </div>
        {children}
      </>
    );
  };

  return (
    <th ref={setCellRef} {...rest} className={cellClassNames} style={style}>
      {!!(resizable && typeof resizableWidth === 'number') && (
        <div className={resizableClassnames} style={{ width: resizableWidth }}>
          <Resizable
            handle={<ResizeHandle />}
            height={0}
            maxConstraints={[getMaxResizeWidth(offsetElementRef.current), 1]}
            onResize={handleResize}
            onResizeStop={handleResizeStop}
            width={resizableWidth}
          >
            <div />
          </Resizable>
        </div>
      )}
      {renderStyledHeader()}
    </th>
  );
};

export default StyledTableHeaderCell;
