import { FC, ReactNode, useState } from 'react';

import { Dialog } from '@zen/DesignSystem';

type RenderFunction = ({ requestConfirm }: { requestConfirm: () => void }) => ReactNode;

interface Props {
  cancelLabel?: string;
  children: ReactNode | RenderFunction;
  closeOnClickAway?: boolean;
  confirmLabel?: string;
  disabled?: boolean;
  header?: string;
  message: ReactNode;
  onClose?: () => void;
  onConfirm: () => void;
}

const WithConfirmationModal: FC<Props> = ({
  cancelLabel,
  children,
  closeOnClickAway,
  confirmLabel,
  disabled,
  header,
  message,
  onConfirm,
  onClose
}) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const requestConfirm = () => {
    setShowConfirmation(true);
  };

  const renderChildren = () => {
    const isRenderFunction = typeof children === 'function';

    if (isRenderFunction) {
      const childrenFunction = children as RenderFunction;

      return childrenFunction({ requestConfirm });
    }

    return <span onClick={requestConfirm}>{children}</span>;
  };

  const handleClose = (): void => {
    onClose?.();

    setShowConfirmation(false);
  };

  const handleCloseOnClickAway = (): void => {
    if (closeOnClickAway) {
      setShowConfirmation(false);
    }
  };

  return (
    <>
      {renderChildren()}

      {showConfirmation && (
        <Dialog
          cancelLabel={cancelLabel}
          closeOnClickAway={closeOnClickAway}
          confirmLabel={confirmLabel}
          disabled={disabled}
          header={header}
          isOpen={showConfirmation}
          message={message}
          onClose={handleClose}
          onCloseOnClickAway={handleCloseOnClickAway}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export type { Props as WithConfirmationModalProps };

export default WithConfirmationModal;
