import type { DeepNonNullable } from '@zen/utils/typescript';

import type { ProductDetailsQueryResult, ProductListQueryResult, ProductsCreateProductMutationResult } from './graphql';

export type {
  MetricValueWithSi,
  PackageInfo,
  PerUnitCbm,
  Product,
  ProductCategory,
  ProductInput,
  ProductLot,
  ProductProperty
} from '@zen/graphql/types.generated';
export { PackageTypeEnum as PackageType } from '@zen/graphql/types.generated';

export enum DimensionUnit {
  CM = 'cm',
  M = 'm'
}

export enum WeightUnit {
  KG = 'kg',
  T = 't'
}

export type ProductListItem = DeepNonNullable<ProductListQueryResult, 'nodes'>['products']['nodes'];

export type ProductDetails = DeepNonNullable<ProductDetailsQueryResult, 'nodes'>['products']['nodes'];

export type ProductMutationResults = NonNullable<ProductsCreateProductMutationResult['productsCreateProduct']>;
