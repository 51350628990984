import { useEffect } from 'react';

interface Props<T> {
  onChange?: (value: T) => void;
  value: T;
}

export function FormObserver<T>(props: Props<T>) {
  const { onChange, value } = props;

  useEffect(() => {
    onChange?.(value);
  }, [onChange, value]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
