import cx from 'classnames';
import type { FC, MouseEvent, ReactNode } from 'react';

import { Loading } from '@zen/DesignSystem';

import type { ButtonType, ButtonVariant } from '../types';

interface Props {
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => unknown;
  type?: ButtonType;
  variant?: ButtonVariant;
}

const StyledButton: FC<Props> = (props) => {
  const { className = '', variant = 'primary', type = 'button', children, disabled = false, onClick, isLoading, ...rest } = props;

  const baseClassNames: string =
    'leading-6 font-bold flex relative text-sm \
  items-center justify-center transition duration-200 ease-in-out';

  const variantClasses: Record<ButtonVariant, string> = {
    primary: 'text-grey-lightest bg-navy-base',
    secondary: 'border border-grey-light text-navy-base',
    tertiary: 'text-navy-base',
    danger: 'border border-red-dark text-red-dark',
    dark: 'text-white'
  };

  const isDangerVariant: boolean = variant === 'danger';
  const isDarkVariant: boolean = variant === 'dark';
  const disabledClasses: string = 'bg-grey-light text-white cursor-not-allowed';

  const interactionClasses: string =
    'hover:bg-azure-base hover:text-white \
     active:bg-azure-light active:text-white hover:border-transparent \
     active:border-transparent';

  const dangerVariantInteractionClasses: string =
    'hover:bg-red-dark hover:text-white \
     active:bg-red-light active:text-white hover:border-transparent \
     active:border-transparent active:text-red-dark';

  const darkVariantInteractionClasses: string = 'hover:bg-white/20 active:bg-white/40';
  const darkVariantDisabledClasses: string = 'text-grey-light cursor-not-allowed';

  const classNames: string = cx(
    {
      'cursor-not-allowed': isLoading,
      [disabledClasses]: disabled && !isDarkVariant,
      [darkVariantDisabledClasses]: disabled && isDarkVariant,
      [variantClasses[variant]]: !disabled,
      [interactionClasses]: !disabled && !isLoading && !isDangerVariant && !isDarkVariant,
      [dangerVariantInteractionClasses]: !disabled && !isLoading && isDangerVariant,
      [darkVariantInteractionClasses]: !disabled && !isLoading && isDarkVariant
    },
    baseClassNames,
    className
  );

  const renderContent = (): ReactNode => {
    if (isLoading) {
      return (
        // including "children" here, so that the width doesn't change when loading
        <div>
          <div className="invisible">{children}</div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Loading size="small" />
          </div>
        </div>
      );
    }

    return children;
  };

  return (
    <button className={classNames} disabled={disabled || isLoading} onClick={onClick} type={type} {...rest}>
      {renderContent()}
    </button>
  );
};

export type { Props as StyledButtonProps };

export default StyledButton;
