import { PageInfo, PageSize } from '@zen/types';

import { getNextPageVariables, getPreviousPageVariables } from './helpers';
import type {
  PaginatedQueryResult,
  PaginatedVariables,
  PaginationQueryHookVariables,
  UpdateQueryType,
  UseQueryHookType
} from './types';
import usePaginatedQuery from './usePaginatedQuery';

const usePagination = <Result, Variables extends PaginatedVariables, NodeType>(
  useQueryHook: UseQueryHookType<Result, Variables>,
  responsePath: string,
  variables: PaginationQueryHookVariables<Variables>,
  limit: number = PageSize.TWENTY,
  options: object = {}
): PaginatedQueryResult<Result, Variables, NodeType> => {
  const { fetchMore, pageInfo, ...queryResults } = usePaginatedQuery<Result, Variables, NodeType>(
    useQueryHook,
    responsePath,
    { ...variables, first: limit },
    options
  );

  const { startCursor, endCursor, hasNextPage, hasPreviousPage } = pageInfo;
  const updateQuery: UpdateQueryType = (_, { fetchMoreResult }) => fetchMoreResult;

  const handleNext = () =>
    fetchMore({
      variables: getNextPageVariables(limit, endCursor),
      updateQuery
    });

  const handlePrevious = () =>
    fetchMore({
      variables: getPreviousPageVariables(limit, startCursor),
      updateQuery
    });

  const paginationInfo: PageInfo = { hasNextPage, hasPreviousPage, onNext: handleNext, onPrevious: handlePrevious };

  return { ...queryResults, paginationInfo };
};

export default usePagination;
export type { PaginatedVariables };
