import type { FC } from 'react';

import type { ActionItemType } from '@zen/Dashboard/ActionItems/types';
import { Button } from '@zen/DesignSystem';
import api from '@zen/utils/api';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  actionItem: Pick<ActionItemType, 'id' | 'dueDate' | 'title'>;
  onShipmentChange: () => void;
  zencargoReference: string;
}

const ActionItemApproval: FC<Props> = ({ actionItem, zencargoReference, onShipmentChange }) => {
  const { addError, addSuccess } = useNotification();
  const handleApprove = async () => {
    try {
      await api.approveActionItem({ zencargo_reference: zencargoReference, id: actionItem.id });
      addSuccess('You approved this item successfully.');
    } catch (err) {
      addError();
    }

    onShipmentChange();
  };

  return <Button onClick={handleApprove}>Approve</Button>;
};

export default ActionItemApproval;
