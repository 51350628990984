import cx from 'classnames';
import { uniqueId } from 'lodash';
import type { FC, ReactNode } from 'react';

import { Icon } from '@zen/DesignSystem';

interface Props {
  children: ReactNode;
  hasError?: boolean;
  id?: string;
  onClick: () => void;
  selected?: boolean;
  small?: boolean;
}

const SelectableBox: FC<Props> = (props) => {
  const { hasError = false, onClick, children, selected = false, small = false, id = uniqueId() } = props;

  const buttonTestId: string = small ? 'button-small' : 'button';
  const selectableBoxTestId: string = small ? 'selectable-box-small' : 'selectable-box';

  const iconClassName: string = 'text-navy-dark absolute top-0 right-0 mt-2 mr-2';
  const buttonClassNames: string = cx('focus:outline-none m-0 p-0', { 'w-auto h-32': small }, { 'w-full h-full': !small });
  const selectableBoxClassNames: string = cx(
    'w-auto py-4 px-2 text-navy-dark border-solid flex flex-col justify-center items-center select-none outline-none',
    'relative rounded bg-white font-bold text-xl cursor-pointer transition-shadow hover:shadow-navy-blue',
    { 'border-2 border-navy-dark js-selected': selected },
    { 'border border-grey-lighter': !selected && !hasError },
    { 'border border-red-dark': hasError },
    { 'h-32': small, 'h-full': !small }
  );

  const renderIcon: ReactNode = selected && !small && <Icon className={iconClassName} icon="zicon-tickoval" />;

  return (
    <button className={buttonClassNames} data-testid={buttonTestId} id={id} onClick={onClick} type="button">
      <div className={selectableBoxClassNames} data-testid={selectableBoxTestId}>
        {renderIcon}
        {children}
      </div>
    </button>
  );
};

export default SelectableBox;
