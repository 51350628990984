import type { TableColumn } from '@zen/DesignSystem';
import { TextLink } from '@zen/DesignSystem';

import type { Terminal } from './types';

export const getManageTerminalsColumnsConfiguration = (onEdit: (terminal: Terminal) => void): TableColumn<Terminal>[] => {
  return [
    {
      key: 'name',
      title: 'Name'
    },
    {
      key: 'unlocode',
      title: 'UN/LOCODE'
    },
    {
      key: 'iata',
      title: 'IATA'
    },
    {
      key: 'subDiv',
      title: 'Sub div'
    },
    {
      key: 'location',
      title: 'Location'
    },
    {
      key: 'actions',
      title: 'Action',
      sortable: false,
      render: (_, terminal) => <TextLink onClick={() => onEdit(terminal)}>Edit</TextLink>
    }
  ];
};
