import type { FC } from 'react';
import Helmet from 'react-helmet';

import PageContent from '@zen/Components/PageContent';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import type { Product } from '@zen/Products/types';
import { formatNumber } from '@zen/utils/formatting';

import ProductSection from './Section';
import type { ProductDetail } from './types';
import { getPropertiesAttributes, renderPriceValue, renderUnitValue } from './utils';

interface Props {
  product: Product;
}

const ProductDetails: FC<Props> = ({ product }) => {
  const { packageInfo, perUnitCbm } = product;

  const renderDimensions = () => {
    // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
    return <>{['length', 'width', 'depth'].map((key) => renderUnitValue(packageInfo[key])).join(' x ')}</>;
  };

  const itemPhysicalAttributes: ProductDetail[] = [
    { key: 'Carton dimensions', value: renderDimensions() },
    { key: 'Units per master carton', value: product.unitsPerMasterCarton || '-' },
    { key: 'CBM per unit', value: perUnitCbm?.value ? formatNumber(perUnitCbm.value, 10) : '-' },
    { key: 'Weight', value: renderUnitValue(product.weight) }
  ];

  const commercialAttributes: ProductDetail[] = [
    { key: 'Cost price', value: renderPriceValue(product.costPrice) },

    { key: 'Sell price', value: renderPriceValue(product.sellPrice) }
  ];

  const commodityAttributes: ProductDetail[] = [
    { key: 'HS code', value: <ValueWithDefault value={product.hsCode} /> },
    { key: 'HTS code', value: <ValueWithDefault value={product.htsCode} /> }
  ];

  const propertiesAttributes: ProductDetail[] = getPropertiesAttributes(product.properties, product.categoryName);

  return (
    <div className="py-8" data-testid="details">
      <Helmet title="Product details view" />
      <PageContent className="grid grid-cols-2 gap-6">
        <ProductSection list={itemPhysicalAttributes} title="Physical" />
        <ProductSection list={propertiesAttributes} title="Properties" />

        <ProductSection list={commercialAttributes} title="Commercial" />
        <ProductSection list={commodityAttributes} title="Commodity codes" />
      </PageContent>
    </div>
  );
};

export default ProductDetails;
