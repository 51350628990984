import { ApolloClient, ApolloLink, ApolloProvider, NextLink, Operation } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { getMainDefinition } from '@apollo/client/utilities';
import { createConsumer } from '@rails/actioncable';
import type { OperationDefinitionNode } from 'graphql';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import omitDeep from 'omit-deep-lodash';
import type { ReactNode } from 'react';

import applicationVersion from '@zen/utils/applicationVersion';
import csrfToken from '@zen/utils/csrfToken';
import staticConfig from '@zen/utils/staticConfig';

import { cache } from './cache';

const cable = createConsumer(staticConfig.subscriptionsUrl);

const batchHttpLink = new BatchHttpLink({
  batchMax: 10,
  batchInterval: 100,
  headers: {
    accept: 'application/json'
  },
  uri: staticConfig.graphqlUrl
});

const hasSubscriptionOperation = ({ query: { definitions } }: Operation): boolean => {
  return definitions.some(
    ({ kind, operation }: { kind: string; operation?: string }) => kind === 'OperationDefinition' && operation === 'subscription'
  );
};

const isOperationAllowedForBatching = ({ operationName }: Operation): boolean => {
  const notAllowedOperationsForBatching: string[] = ['roadTrackedShipments', 'roadTrackedShipmentsDetails'];

  return !notAllowedOperationsForBatching.includes(operationName);
};

const httpLink = ApolloLink.split(isOperationAllowedForBatching, batchHttpLink, new HttpLink({ uri: staticConfig.graphqlUrl }));

const link = ApolloLink.split(hasSubscriptionOperation, new ActionCableLink({ cable }), httpLink);

// we omit `__typename` fields before executing mutations
// it's useful especially when we fetch and pass data directly into a form
// so there is no need to manually omit __typename fields before executing mutation
const cleanTypenameLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const keysToOmit = ['__typename'];

  const def = getMainDefinition(operation.query) as OperationDefinitionNode;

  if (def && def.operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }

  return forward ? forward(operation) : null;
});

const authenticationLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'X-CSRF-Token': csrfToken.get()
    }
  });

  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError && 'statusCode' in networkError) {
    switch (networkError.statusCode) {
      case 503:
        window.location.reload();
        break;
      case 401:
        window.localStorage.clear();
        window.location.reload();
        break;
    }
  }
});

export const apolloClient = new ApolloClient({
  connectToDevTools: true,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  },
  link: ApolloLink.from([cleanTypenameLink, authenticationLink, errorLink, link]),
  name: window.location.hostname,
  version: applicationVersion,
  cache
});

interface GraphQLProviderProps {
  children: ReactNode;
}

const GraphQLProvider = ({ children }: GraphQLProviderProps) => <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;

export default GraphQLProvider;
