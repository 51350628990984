import { isNil, set } from 'lodash';
import { FC, ReactNode, useState } from 'react';

import {
  Form,
  FormActions,
  FormArray,
  FormArrayHelpers,
  FormButtons,
  FormInput,
  FormInstance,
  FormSelect
} from '@zen/Components/Form';
import FormCurrencyInput from '@zen/Components/Form/FormCurrencyInput';
import FormLabel from '@zen/Components/Form/FormLabel';
import FormNumberInput from '@zen/Components/Form/FormNumberInput/FormNumberInput';
import type { Option } from '@zen/DesignSystem';
import { Button, Headline, IconButton, TextInput } from '@zen/DesignSystem';
import { formatNumber } from '@zen/utils/formatting';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional } from '@zen/utils/typescript';
import { trimObjectValues } from '@zen/utils/utils';

import { calculatePerUnitCbm } from '../ProductDetails/Details/utils';
import { DimensionUnit, MetricValueWithSi, ProductMutationResults, WeightUnit } from '../types';
import PackageInfo from './PackageInfo';
import type { FormProductProperty, InitialProductFormValues, ProductFormType, ProductFormValues } from './types';
import { validationSchema } from './validation';

const weightOptions: Option<WeightUnit>[] = [
  { value: WeightUnit.KG, label: 'KG' },
  { value: WeightUnit.T, label: 'T' }
];

interface Props {
  initialValues: InitialProductFormValues;
  onSubmit: (values: ProductFormType) => Promise<IOkOrErrorResult>;
  onSuccess: (values: ProductMutationResults, addNext: boolean) => void;
  showAddNew?: boolean;
}

const ProductForm: FC<Props> = (props) => {
  const { initialValues, onSubmit, onSuccess, showAddNew } = props;
  const [addNew, setAddNew] = useState<boolean>(false);

  const handleSubmitAndNew = (submitForm: () => Promise<void>) => {
    setAddNew(true);

    submitForm();
  };

  const prepareInitialValues = (values: InitialProductFormValues): ProductFormValues => {
    const unitValue: Optional<string> = values.packageInfo?.depth?.unit;
    const areUnitValuesSame: boolean = ['length', 'width', 'depth'].every((attribute: string) => {
      const key = attribute as keyof NonNullable<ProductFormType['packageInfo']>;
      const metric = values.packageInfo?.[key] as MetricValueWithSi;

      return metric?.unit === unitValue;
    });

    const unit: DimensionUnit = areUnitValuesSame && unitValue ? (unitValue as DimensionUnit) : DimensionUnit.CM;

    return {
      ...values,
      packageInfoValidator: '',
      unit
    };
  };

  const prepareData = (values: ProductFormValues): ProductFormType => {
    const { unit, ...productValues } = values;

    ['length', 'width', 'depth'].forEach((attribute: string) => set(productValues, `packageInfo.${attribute}.unit`, unit));

    const getValue = (obj: Optional<{ value: Optional<number> }>) => (isNil(obj?.value) ? null : obj);

    const { categoryName, costPrice, sellPrice, weight, packageInfo, packageInfoValidator, ...rest } = productValues;

    return trimObjectValues({
      ...rest,
      categoryName: categoryName || null,
      costPrice: getValue(costPrice),
      sellPrice: getValue(sellPrice),
      weight: getValue(weight),
      packageInfo: {
        ...packageInfo,
        depth: getValue(packageInfo?.depth),
        length: getValue(packageInfo?.length),
        width: getValue(packageInfo?.width)
      }
    });
  };

  const getCbmPerUnitValue = ({ touched, values }: FormInstance<ProductFormValues>): string => {
    const { packageInfo, perUnitCbm, unitsPerMasterCarton } = values;

    if (packageInfo && (touched.packageInfo || touched.unitsPerMasterCarton)) {
      return calculatePerUnitCbm(packageInfo, unitsPerMasterCarton);
    }

    return perUnitCbm?.value ? formatNumber(perUnitCbm.value, 10) : '';
  };

  const handleSubmit = (values: ProductFormValues): Promise<IOkOrErrorResult> => {
    const data = prepareData(values);

    return onSubmit(data);
  };

  const handleSuccess = (
    values: ProductMutationResults,
    _: InitialProductFormValues,
    { resetForm }: FormActions<InitialProductFormValues>
  ): void => {
    onSuccess(values, addNew);

    if (addNew) {
      setAddNew(false);
      resetForm();
    }
  };

  const handleError = (): void => setAddNew(false);

  const renderFormButtons = ({ isSubmitting, submitForm }: FormInstance<ProductFormValues>): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting}>
        {showAddNew && (
          <Button
            data-testid="submit-and-new"
            disabled={isSubmitting}
            onClick={() => handleSubmitAndNew(submitForm)}
            type="submit"
            variant="secondary"
          >
            Save and New
          </Button>
        )}
      </FormButtons>
    );
  };

  return (
    <Form
      className="mt-4"
      enableReinitialize={true}
      formButtons={renderFormButtons}
      formName="ProductForm"
      initialValues={prepareInitialValues(initialValues)}
      onError={handleError}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      validationSchema={validationSchema}
    >
      {(form: FormInstance<ProductFormValues>) => {
        const { properties } = form.values;
        const cbmPerUnitValue = getCbmPerUnitValue(form);

        return (
          <div>
            <FormInput label="Product name" name="name" placeholder="Blue jean shorts" />
            <FormInput label="SKU code" name="skuCode" placeholder="SKU1234" />
            <FormInput label="Product category" name="categoryName" placeholder="Books" />
            <Headline level={2}>Physical</Headline>
            <div className="flex">
              <div className="flex items-end w-1/2">
                <FormNumberInput className="mr-2" label="Net weight" name="weight.value" placeholder="1" />
                <FormSelect hideLabel={true} name="weight.unit" options={weightOptions} />
              </div>
            </div>

            <div className="flex justify-between">
              <div className="w-1/2 mr-4">
                <FormLabel label="Carton dimensions" name="packageInfo.length.value" />
                <PackageInfo />
              </div>
              <div className="flex justify-between">
                <FormNumberInput className="mr-2" label="Units per master carton" name="unitsPerMasterCarton" placeholder="12" />

                <div>
                  <FormLabel label="CBM per unit" />
                  <TextInput disabled={true} value={cbmPerUnitValue} />
                </div>
              </div>
            </div>

            <FormArray addButtonText="Add more" empty={{ name: '', value: '' }} path="properties" values={properties || []}>
              {({ remove, getFieldName }: FormArrayHelpers<FormProductProperty>) => (
                <div className="flex items-center">
                  <FormInput className="mr-4 flex-1" label="Name" name={getFieldName('name')} placeholder="Colour" />
                  <FormInput className="mr-2 flex-1" label="Value" name={getFieldName('value')} placeholder="Blue" />
                  <IconButton className="mt-2" icon="zicon-trash" onClick={remove} variant="secondary" />
                </div>
              )}
            </FormArray>

            <Headline level={2}>Commodity codes</Headline>
            <div className="flex justify-between">
              <FormInput className="w-100 mr-4" label="HS code" name="hsCode" placeholder="12345678" />
              <FormInput className="w-100" label="HTS code" name="htsCode" placeholder="12345678" />
            </div>

            <Headline level={2}>Commercial</Headline>
            <div className="flex justify-between">
              <FormCurrencyInput name="costPrice" />
              <FormCurrencyInput name="sellPrice" />
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default ProductForm;
