import type { FC } from 'react';

import Card from '@zen/Components/Card';

import type { ProductDetail } from './types';

interface Props {
  list: ProductDetail[];
  title: string;
}

const Section: FC<Props> = ({ list, title }) => (
  <Card title={title}>
    <div className="grid grid-cols-2 gap-4">
      {list.map((listItem: ProductDetail) => {
        const { key, value } = listItem;

        return (
          <div key={key}>
            <p className="text-xs text-grey-base">{key}</p>
            <p className="text-sm text-grey-dark">{value}</p>
          </div>
        );
      })}
    </div>
  </Card>
);

export default Section;
