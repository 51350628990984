import { mergeWith } from 'lodash';

import { Currency } from '@zen/types';
import { deepMergeNilValues } from '@zen/utils/utils';

import { DimensionUnit, PackageType, ProductDetails, WeightUnit } from '../types';
import type { InitialProductFormValues } from './types';

export const getProductFormInitialValues = (): InitialProductFormValues => ({
  name: '',
  skuCode: '',
  categoryName: '',
  properties: [{ name: '', value: '' }],
  hsCode: '',
  htsCode: '',
  packageInfo: {
    width: {
      unit: DimensionUnit.CM,
      value: null
    },
    length: {
      unit: DimensionUnit.CM,
      value: null
    },
    depth: {
      unit: DimensionUnit.CM,
      value: null
    },
    type: PackageType.CARTON
  },
  weight: {
    unit: WeightUnit.KG,
    value: null
  },
  costPrice: {
    currency: Currency.GBP,
    value: null
  },
  sellPrice: {
    currency: Currency.GBP,
    value: null
  },
  unitsPerMasterCarton: null
});

export const prepareInitialProductValues = (data: ProductDetails): InitialProductFormValues => {
  const { id, accountUuid, ...rest } = data;

  return mergeWith({}, rest, getProductFormInitialValues(), deepMergeNilValues);
};
