import pluralize from 'pluralize';
import type { FC } from 'react';

import { Pill } from '@zen/DesignSystem';

interface Props {
  onDelete: (zencargoReference: string) => void;
  shipmentReferencesList: string[];
}

const ShipmentsForConsolidationList: FC<Props> = ({ shipmentReferencesList, onDelete }) => (
  <div className="flex flex-wrap">
    <p className="text-xs text-grey-dark mr-4 mb-4">
      {shipmentReferencesList.length} {pluralize('shipment', shipmentReferencesList.length)} selected
    </p>
    {shipmentReferencesList.map((shipment: string) => (
      <Pill key={shipment} className="mr-4 mb-3" iconRight="zicon-close" onClick={() => onDelete(shipment)}>
        {shipment}
      </Pill>
    ))}
  </div>
);

export default ShipmentsForConsolidationList;
