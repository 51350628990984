import type { FC } from 'react';

import { Button, Tooltip } from '@zen/DesignSystem';
import { incotermsOptions } from '@zen/Shipment/helpers';

import FormLabel from '../FormLabel';
import FormSelect from '../FormSelect';

const incotermsLink: string = 'https://s3-eu-west-1.amazonaws.com/zencargo-frontend/incoterms-explained.pdf';
const tooltipMsg: string = `This is the international commercial term that has been agreed between
                  seller and buyer for the transaction`;

interface Props {
  isDisabled?: boolean;
  name: string;
}

const FormIncotermsSelect: FC<Props> = ({ isDisabled = false, name }) => {
  const label = (
    <div className="flex items-center">
      <FormLabel label="Incoterms" name={name} />
      <Tooltip tooltipContent={tooltipMsg} />
    </div>
  );

  return (
    <div className="flex">
      <div className="w-64 pr-4">
        <FormSelect isDisabled={isDisabled} label={label} name={name} options={incotermsOptions} />
      </div>
      <Button className="mt-6" iconLeft="zicon-help" onClick={() => window.open(incotermsLink, '_blank')} variant="secondary">
        Incoterms explained
      </Button>
    </div>
  );
};

export default FormIncotermsSelect;
