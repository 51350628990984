import { FC, MutableRefObject, ReactNode, useRef } from 'react';

import CollapsableElement from '@zen/Components/CollapsableElement';
import DateWithDefault from '@zen/Components/DateWithDefault';
import StageName from '@zen/Components/StageName/StageName';
import { Tooltip } from '@zen/DesignSystem';
import DetailsCardContent from '@zen/Shipment/components/DetailsCardContent';
import ExpandableDetailsCard from '@zen/Shipment/components/ExpandableDetailsCard';
import { formatTimeZone, noTimeZoneMessage } from '@zen/utils/dateTime';
import type { Nullable } from '@zen/utils/typescript';

import VerticalPathIndicators from '../../../VoyageMilestones/components/MilestoneCard/VerticalPathIndicators';
import type { BookingStage } from '../../types';
import StageChecklist from '../StageChecklist';

interface Props {
  action?: ReactNode;
  isActive?: boolean;
  isFirst?: boolean;
  stage: BookingStage;
}

const BookingStageCard: FC<Props> = (props) => {
  const { stage: bookingStage, action, isActive, isFirst } = props;
  const titleRef: MutableRefObject<Nullable<HTMLDivElement>> = useRef<HTMLDivElement>(null);
  const hasCheckList: boolean = !!bookingStage.checklist && bookingStage.checklist.length !== 0;
  const isCompleted: boolean = !!(bookingStage.started && !bookingStage.current);

  const tooltipContent: string = bookingStage.startedAt?.date
    ? formatTimeZone(bookingStage.startedAt.date, bookingStage.startedAt.timeZone)
    : noTimeZoneMessage;

  const mainCardSection: ReactNode = (
    <DetailsCardContent
      action={<div className="h-8">{action}</div>}
      date={
        <Tooltip placement="top-start" tooltipContent={tooltipContent}>
          <div className="font-bold" data-testid="booking-stage-tooltip">
            <DateWithDefault date={bookingStage.startedAt?.date} />
          </div>
        </Tooltip>
      }
      title={
        <div ref={titleRef}>
          <StageName name={bookingStage.name} />
        </div>
      }
    />
  );

  const renderSubCardSection = (isHovered: boolean): ReactNode => {
    if (!hasCheckList) {
      return null;
    }

    return (
      <CollapsableElement isOpened={isHovered}>
        <StageChecklist checklist={bookingStage.checklist} />
      </CollapsableElement>
    );
  };

  return (
    <div data-testid="booking-stage-card">
      <ExpandableDetailsCard
        animationDelay={500}
        indicator={
          <VerticalPathIndicators
            isCompleted={isCompleted}
            isCurrent={!!bookingStage.current}
            isFirst={isFirst}
            referenceElementRef={titleRef}
          />
        }
        mainCardSection={mainCardSection}
        opened={isActive}
        renderSubCardSection={renderSubCardSection}
      />
    </div>
  );
};

export default BookingStageCard;
