import type { FC } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import type { FeatureFlags } from '@zen/Auth/UserDataProvider/types';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';

import SidebarLinks from '../components/SidebarLinks';
import type { SidebarCounters } from '../types';
import { getConfig } from './config';

export type CustomerLinksFeatureFlags = Pick<FeatureFlags, 'consolidation' | 'trackedShipments'>;

interface Props {
  counters: SidebarCounters;
}

const CustomerLinks: FC<Props> = ({ counters }) => {
  const { consolidation, trackedShipments } = useFeatureFlags();
  const role = useRole();

  return <SidebarLinks config={getConfig(role, { consolidation, trackedShipments })} counters={counters} />;
};

export default CustomerLinks;
