import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInstance } from '@zen/Components/Form';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import { Button } from '@zen/DesignSystem';
import { getDateValidationMessage, getRangeValidationMessage } from '@zen/Shipment/ShipmentDetailsPage/MilestoneDateProvider';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import FormMilestoneDelayReasonFields from '../FormMilestoneDelayReasonFields';
import type { MilestoneDateFormType } from './types';
import { shipmentDateFormValidation } from './validation';

interface Props {
  className?: string;
  initialValues: Partial<MilestoneDateFormType>;
  isDatePickerDisabled?: boolean;
  isInitialDateEntry?: boolean;
  maxDate?: string;
  minDate?: string;
  onCancel: () => void;
  onSubmit: (values: MilestoneDateFormType) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
}

const MilestoneDateForm: FC<Props> = (props) => {
  const {
    initialValues,
    onSuccess,
    onCancel,
    onSubmit,
    className,
    minDate,
    maxDate,
    isInitialDateEntry = false,
    isDatePickerDisabled = false
  } = props;

  const renderFormButtons = ({ isSubmitting }: FormInstance<MilestoneDateFormType>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Submit">
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      className={className}
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      scrollToError={false}
      validationSchema={shipmentDateFormValidation}
    >
      {() => {
        return (
          <>
            <FormDatePickerInput
              className="w-1/2"
              disabled={isDatePickerDisabled}
              disabledDayTooltip={getDateValidationMessage(minDate, maxDate)}
              helperText={getRangeValidationMessage(isDatePickerDisabled)}
              initialDate={minDate}
              isRequired={true}
              label="New date"
              maxDate={maxDate}
              minDate={minDate}
              name="date"
              pickerPositionY="below"
            />
            {!isInitialDateEntry && <FormMilestoneDelayReasonFields />}
          </>
        );
      }}
    </Form>
  );
};

export type { Props as MilestoneDateFormProps };
export default MilestoneDateForm;
