import type { FC } from 'react';

import { FormNumberInput } from '@zen/Components/Form';
import FormHiddenInput from '@zen/Components/Form/FormHiddenInput';
import { IconButton } from '@zen/DesignSystem';
import { formatNumber } from '@zen/utils/formatting';

interface Props {
  getFieldName: (name: string) => string;
  index: number;
  lastItem?: boolean;
  percentage: number;
  remove: () => void;
}

const LotItem: FC<Props> = ({ remove, getFieldName, index, percentage = 0, lastItem = false }) => {
  const lotFieldClassNames = 'mb-0 fs-update-lot grow-0 w-1/5';

  return (
    <>
      {index === 0 && (
        <div className="flex justify-between items-start mb-6 text-sm text-grey-base" data-testid="heading">
          <div className="grow-0 w-1/5" />
          <div className="grow-0 w-1/5">Quantity</div>
          <div className="grow-0 w-1/5">
            <div className="grow-0 pl-4">%</div>
          </div>
          <div className="grow-0 w-1/5">Updated CBM</div>
          <div className="w-1/5" />
        </div>
      )}
      <div className="flex justify-between items-center mb-6 text-sm">
        <div className="grow-0 w-1/5 font-bold">Lot {index + 1}.</div>
        <FormHiddenInput name={getFieldName('id')} />
        <FormNumberInput
          className={lotFieldClassNames}
          hideLabel={true}
          name={getFieldName('quantityFulfilled')}
          placeholder="0"
          step={1}
        />
        <div className="grow-0 w-1/5">
          <div className="pl-4" data-testid="order-percentage">
            {formatNumber(percentage)}%
          </div>
        </div>
        <FormNumberInput className={lotFieldClassNames} hideLabel={true} name={getFieldName('cbm')} placeholder="0" />
        <div className="w-1/5 pl-4">
          {!lastItem && (
            <IconButton
              className="fs-update-lot w-12 h-12"
              data-testid="delete-button"
              icon="zicon-trash"
              onClick={remove}
              title="Remove"
              variant="secondary"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LotItem;
