import { FC, memo } from 'react';

import { DonutChart, Loading } from '@zen/DesignSystem';
import { Color } from '@zen/Styleguide';

import { getColor } from './helper';

const size: string = '5.5rem';
const classNames: string =
  'bg-white shadow-overlay rounded-lg flex items-center \
  justify-center cursor-pointer hover:shadow-navy-blue';

interface Props {
  isLoading: boolean;
  onClick?: () => void;
  value: number;
}

const DataQualityIndicator: FC<Props> = ({ onClick = () => {}, value, isLoading }) => {
  return (
    <div className={classNames} onClick={onClick} style={{ width: size, height: size }}>
      {isLoading && <Loading />}
      {!isLoading && (
        <DonutChart
          color={getColor(value)}
          label={[`${value}`, 'Data', 'Quality']}
          labelLineHeight={[1.1, 1, 1]}
          labelStyle={[
            { fontSize: 100, fill: Color.NAVY_BASE, fontWeight: 600 },
            { fontSize: 50, fill: '#3B4446' },
            { fontSize: 50, fill: '#3B4446' }
          ]}
          labelVerticalPosition={220}
          value={value}
        />
      )}
    </div>
  );
};

export type { Props as DataQualityIndicatorProps };
export default memo(DataQualityIndicator);
