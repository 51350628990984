import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { productSettingsRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import DocumentTemplateForm from '../DocumentTemplateForm';
import { useGetDocumentTemplateQuery, useUpdateDocumentTemplateMutation } from '../graphql';
import type { DocumentTemplate, DocumentTemplateFormValues } from '../types';

const EditDocumentTemplate: FC = () => {
  const { documentTemplateId } = useParams<{ documentTemplateId: string }>();
  const { navigateBack } = useNavigationHistory();
  const { addError, addSuccess } = useNotification();

  const [updateDocumentTemplate] = useUpdateDocumentTemplateMutation();

  const { data, loading, error } = useGetDocumentTemplateQuery({
    variables: {
      id: +documentTemplateId
    },
    fetchPolicy: 'no-cache'
  });

  const defaultBackUrl: string = productSettingsRoutes.documentTemplatesIndex.getUrl();

  const handleUpdateDocumentTemplate = async (input: DocumentTemplateFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateDocumentTemplate({
          variables: {
            input
          }
        }),
      onError: addError
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Document template updated');
    navigateBack(defaultBackUrl);
  };

  return (
    <Page defaultBackUrl={defaultBackUrl} tagline="Edit a document template" title="Editing a document template">
      <QueryHandler data={data?.documentTemplate} error={!!error} isLoading={loading}>
        {(documentTemplate: DocumentTemplate) => {
          return (
            <DocumentTemplateForm
              initialValues={documentTemplate as DocumentTemplateFormValues}
              onSubmit={handleUpdateDocumentTemplate}
              onSuccess={handleSuccess}
              submitButtonText="Save"
            />
          );
        }}
      </QueryHandler>
    </Page>
  );
};

export default EditDocumentTemplate;
