import type { FC, ReactNode } from 'react';

import { Container, IconButton } from '@zen/DesignSystem';
import { Role, useNetworksContext } from '@zen/Networks';
import OrganisationRoles from '@zen/Networks/OrganisationRoles';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

interface Props {
  eori: string;
  name: string;
  roles: Role[];
  vat: string;
}

const MyOrganisationHeader: FC<Props> = ({ eori, name, vat, roles }) => {
  const { routes } = useNetworksContext();
  const { navigate } = useNavigationHistory();
  const hasAssignedRoles = roles && !!roles.length;
  const renderValue = (label: string, value: string): ReactNode => (
    <div className="w-1/2">
      <div className="text-large font-bold mb-2">{label}</div>
      <div className="text-large">{value}</div>
    </div>
  );

  const handleEditMyOrganisationClick = () => {
    navigate(routes.myOrganisation.edit.getUrl());
  };

  return (
    <Container
      actions={<IconButton icon="zicon-edit" onClick={handleEditMyOrganisationClick} title="Edit" variant="secondary" />}
      className="mb-6"
      title="My registered company name"
    >
      <div className="flex items-center mt-3">
        <span className="text-navy-dark text-lg font-bold mr-4">{name}</span>
        {hasAssignedRoles && <OrganisationRoles roles={roles} />}
      </div>
      <div className="flex pt-8">
        {renderValue('VAT number', vat || '-')}
        {renderValue('EORI number', eori || '-')}
      </div>
    </Container>
  );
};

export default MyOrganisationHeader;
