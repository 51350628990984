import { pull } from 'lodash';
import { FC, ReactNode, useEffect, useState } from 'react';

import { ModeOfTransport } from '@zen/Booking/types';
import SelectableBox from '@zen/Components/SelectableBox';
import { Headline } from '@zen/DesignSystem';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import type { Nullable } from '@zen/utils/typescript';

import air from './air.png';
import rail from './rail.png';
import road from './road.png';
import sea from './sea.png';

export interface TransportModeOption {
  label: string;
  value: ModeOfTransport;
}

type TransportModeIcons = 'air' | 'road' | 'sea' | 'rail';

interface Props {
  hasError: boolean;
  isSingleSelect?: boolean;
  onSelect: (value: ModeOfTransport[]) => void;
  selected?: Nullable<ModeOfTransport[]>;
}

const ICONS = { air, road, sea, rail };

export const MODES: TransportModeOption[] = [
  { label: 'Sea', value: ModeOfTransport.OCEAN },
  { label: 'Air', value: ModeOfTransport.AIR },
  { label: 'Road', value: ModeOfTransport.TRUCK },
  { label: 'Rail', value: ModeOfTransport.RAIL }
];

const TransportModeSelect: FC<Props> = (props) => {
  const { hasError, isSingleSelect = false, onSelect } = props;
  const [selected, setSelected] = useState<ModeOfTransport[]>([]);

  useEffect(() => {
    setSelected(props.selected || []);
  }, [props.selected]);

  const getSelectedItems = (value: ModeOfTransport): ModeOfTransport[] => {
    let result = [];

    if (isSingleSelect) {
      result = selected.includes(value) ? [] : [value];
    } else {
      const selectedModes: ModeOfTransport[] = selected;

      result = selected.includes(value) ? pull([...selectedModes], value) : [...selectedModes, value];
    }

    return result;
  };

  const handleSelect = (value: ModeOfTransport): void => {
    const result: ModeOfTransport[] = getSelectedItems(value);

    setSelected(result);
    onSelect(result);
  };

  const renderBox = (item: TransportModeOption): ReactNode => {
    const isSelected = selected && selected.includes(item.value);
    const label = removeSpecialCharactersAndUpperFirst(item.label);

    return (
      <div key={item.value} className="w-1/3 mr-5">
        <SelectableBox hasError={hasError} onClick={() => handleSelect(item.value)} selected={isSelected}>
          <img alt={label} className="mt-4 w-24 h-24" src={ICONS[item.label.toLowerCase() as TransportModeIcons]} />
          <Headline className="mt-6" level={3}>
            {label}
          </Headline>
        </SelectableBox>
      </div>
    );
  };

  return <div className="flex justify-center -mr-5 h-48">{MODES.map(renderBox)}</div>;
};

export default TransportModeSelect;
