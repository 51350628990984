import { FC, ReactElement, useEffect, useState } from 'react';

import { TabConfig, Tabs } from '@zen/DesignSystem';

import { getDefaultTab, prepareComponentMapping, prepareTabsConfig } from './helpers';
import type { TabViewConfig } from './types';

type TabViewControllerChildren = (args: { Content: () => ReactElement; Tabs: () => ReactElement }) => ReactElement;

interface Props {
  children: TabViewControllerChildren;
  tabViewConfig: TabViewConfig[];
}

const TabViewController: FC<Props> = ({ tabViewConfig, children }) => {
  const [activeTab, setActiveTab] = useState<string>();

  useEffect(() => {
    if (!tabViewConfig.length) {
      return;
    }

    const defaultTab: string = getDefaultTab(tabViewConfig);

    setActiveTab(defaultTab);
  }, [tabViewConfig]);

  const componentMapping: Record<string, ReactElement> = prepareComponentMapping(tabViewConfig);
  const tabsConfig: TabConfig[] = prepareTabsConfig(tabViewConfig, activeTab);

  if (!activeTab) {
    return null;
  }

  const renderTabs = (): ReactElement => <Tabs onTabClick={setActiveTab} tabsConfig={tabsConfig} />;

  const renderContent = (): ReactElement => componentMapping[activeTab];

  return children({ Tabs: renderTabs, Content: renderContent });
};

export default TabViewController;
