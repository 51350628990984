import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type PackingListsQueryVariables = GraphQLTypes.Exact<{
  findBy: GraphQLTypes.PackingListFindBy;
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type PackingListsQueryResult = { __typename?: 'Query' } & {
  packingLists?: GraphQLTypes.Maybe<
    { __typename?: 'PackingListConnection' } & Pick<GraphQLTypes.PackingListConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'PackingList' } & Pick<GraphQLTypes.PackingList, 'customerId'> & {
                  orders?: GraphQLTypes.Maybe<
                    Array<
                      { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id' | 'orderReferenceNumber'> & {
                          manufacturer?: GraphQLTypes.Maybe<
                            | ({ __typename?: 'NetworksLegacyOrgLoc' } & {
                                label?: GraphQLTypes.Maybe<
                                  { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                                >;
                              })
                            | ({ __typename?: 'NetworksOrgLoc' } & {
                                label?: GraphQLTypes.Maybe<
                                  { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                                >;
                              })
                            | ({ __typename?: 'NetworksTerminal' } & {
                                label?: GraphQLTypes.Maybe<
                                  { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                                >;
                              })
                          >;
                        }
                    >
                  >;
                  cargo?: GraphQLTypes.Maybe<
                    | ({ __typename?: 'CargoContainerType' } & Pick<
                        GraphQLTypes.CargoContainerType,
                        | 'containerType'
                        | 'containerNumber'
                        | 'containerSealNumber'
                        | 'id'
                        | 'type'
                        | 'cbm'
                        | 'looseCargoType'
                        | 'quantity'
                      > & {
                          grossWeight?: GraphQLTypes.Maybe<
                            { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                          >;
                        })
                    | ({ __typename?: 'CargoLooseCargoType' } & Pick<
                        GraphQLTypes.CargoLooseCargoType,
                        'containerNumber' | 'palletType' | 'trailerId' | 'id' | 'type' | 'cbm' | 'looseCargoType' | 'quantity'
                      > & {
                          chargeableWeight?: GraphQLTypes.Maybe<
                            { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                          >;
                          dimensions?: GraphQLTypes.Maybe<
                            { __typename?: 'CargoDimensionsType' } & Pick<
                              GraphQLTypes.CargoDimensionsType,
                              'length' | 'width' | 'height' | 'metric'
                            >
                          >;
                          grossWeight?: GraphQLTypes.Maybe<
                            { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                          >;
                        })
                    | ({ __typename?: 'CargoVehicleType' } & Pick<
                        GraphQLTypes.CargoVehicleType,
                        | 'trailerId'
                        | 'vehicleType'
                        | 'riskLevel'
                        | 'tailLift'
                        | 'id'
                        | 'type'
                        | 'cbm'
                        | 'looseCargoType'
                        | 'quantity'
                      > & {
                          dimensions?: GraphQLTypes.Maybe<
                            { __typename?: 'CargoDimensionsType' } & Pick<
                              GraphQLTypes.CargoDimensionsType,
                              'length' | 'width' | 'height' | 'metric'
                            >
                          >;
                          valueOfGoods?: GraphQLTypes.Maybe<
                            { __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>
                          >;
                          grossWeight?: GraphQLTypes.Maybe<
                            { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                          >;
                        })
                  >;
                }
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const PackingListsQueryDocument = /* #__PURE__ */ gql`
  query packingListsQuery(
    $findBy: PackingListFindBy!
    $textContains: String
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    packingLists(findBy: $findBy, textContains: $textContains, after: $after, before: $before, first: $first, last: $last) {
      nodes {
        customerId
        orders {
          id
          orderReferenceNumber
          manufacturer {
            label {
              short
            }
          }
        }
        cargo {
          id
          type
          cbm
          looseCargoType
          quantity
          grossWeight {
            metric
            value
          }
          ... on CargoContainerType {
            containerType
            containerNumber
            containerSealNumber
          }
          ... on CargoLooseCargoType {
            chargeableWeight {
              metric
              value
            }
            containerNumber
            palletType
            dimensions {
              length
              width
              height
              metric
            }
            trailerId
          }
          ... on CargoVehicleType {
            trailerId
            vehicleType
            dimensions {
              length
              width
              height
              metric
            }
            riskLevel
            tailLift
            valueOfGoods {
              currency
              value
            }
          }
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __usePackingListsQuery__
 *
 * To run a query within a React component, call `usePackingListsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackingListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackingListsQuery({
 *   variables: {
 *      findBy: // value for 'findBy'
 *      textContains: // value for 'textContains'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function usePackingListsQuery(baseOptions: Apollo.QueryHookOptions<PackingListsQueryResult, PackingListsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<PackingListsQueryResult, PackingListsQueryVariables>(PackingListsQueryDocument, options);
}
export function usePackingListsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PackingListsQueryResult, PackingListsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<PackingListsQueryResult, PackingListsQueryVariables>(PackingListsQueryDocument, options);
}
export type PackingListsQueryHookResult = ReturnType<typeof usePackingListsQuery>;
export type PackingListsQueryLazyQueryHookResult = ReturnType<typeof usePackingListsQueryLazyQuery>;
