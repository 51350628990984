import type { FC } from 'react';

import { getConsolidatedShipmentLegColumnsConfiguration } from '@zen/Consolidation/ShowConsolidationDetails/consolidatedShipmentLegColumnsConfiguration';
import type { ConsolidationLegType } from '@zen/Consolidation/types';
import { Table } from '@zen/DesignSystem';

export interface Props {
  legs?: ConsolidationLegType[];
}

const ConsolidationLegsDetails: FC<Props> = ({ legs = [] }) => {
  return (
    <Table<ConsolidationLegType>
      columns={getConsolidatedShipmentLegColumnsConfiguration()}
      data={legs}
      tableId="consolidatedShipmentLegsTable"
    />
  );
};

export default ConsolidationLegsDetails;
