import type { FC } from 'react';

import { useUpdateCollectionMutation, useUpdateDeliveryMutation } from '@zen/CollectionAndDelivery/graphql';
import InlineEditableField from '@zen/Components/InlineEditableField';
import { buildRoadTrackingMetricTrackingEvent } from '@zen/OperationsShipments';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import { performMutation } from '@zen/utils/performMutation';

interface Props {
  cargoId: string;
  disabled: boolean;
  vehiclePlateNumber: string;
  zencargoReference: string;
}

const VehiclePlateNumberInput: FC<Props> = ({ cargoId, disabled, vehiclePlateNumber, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const { trackEvent } = useTracking();

  const [updateCollection] = useUpdateCollectionMutation();
  const [updateDelivery] = useUpdateDeliveryMutation();

  const trackRoadTrackingMetrics = (plateNumber: string): void => {
    const trackingEvent = buildRoadTrackingMetricTrackingEvent(zencargoReference, cargoId, { vehiclePlateNumber: plateNumber });

    trackEvent(trackingEvent);
  };

  const handleUpdateVehiclePlateNumber = async (value: string): Promise<void> => {
    let error;

    const input = {
      cargoId,
      vehiclePlateNumber: value,
      zencargoReference
    };

    await performMutation({
      mutationFn: () =>
        updateCollection({
          variables: {
            input
          }
        }),
      onError: () => {
        error = true;
      }
    });

    await performMutation({
      mutationFn: () =>
        updateDelivery({
          variables: {
            input
          }
        }),
      onError: () => {
        error = true;
      }
    });

    if (error) {
      addError();
    } else {
      addSuccess('Vehicle plate number updated.');
      trackRoadTrackingMetrics(value);
    }
  };

  return (
    <InlineEditableField
      className="py-3.5 px-2"
      disabled={disabled}
      name="vehiclePlateNumber"
      onUpdate={handleUpdateVehiclePlateNumber}
      overLabel="Vehicle plate number"
      value={vehiclePlateNumber}
    />
  );
};

export default VehiclePlateNumberInput;
