import { TerminalTypeValue } from '@zen/Terminals/ManageTerminals/types';
import { ModeOfTransport } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

import type { TransportScheduleLegType } from './types';

export const getVesselNames = (transportScheduleLegs: Optional<TransportScheduleLegType[]>): string[] => {
  return transportScheduleLegs
    ? transportScheduleLegs.map((transportScheduleLeg: TransportScheduleLegType) => transportScheduleLeg.vesselName || '')
    : [];
};

export const getVoyageNumbers = (transportScheduleLegs: Optional<TransportScheduleLegType[]>): string[] => {
  return transportScheduleLegs
    ? transportScheduleLegs.map((transportScheduleLeg: TransportScheduleLegType) => transportScheduleLeg.voyageNumber || '')
    : [];
};

export const modeOfTransportTerminalTypeMapping: Record<ModeOfTransport, TerminalTypeValue> = {
  [ModeOfTransport.AIR]: TerminalTypeValue.AIRPORT,
  [ModeOfTransport.OCEAN]: TerminalTypeValue.SEAPORT,
  [ModeOfTransport.RAIL]: TerminalTypeValue.RAIL_TERMINAL,
  [ModeOfTransport.TRUCK]: TerminalTypeValue.ROAD_TERMINAL
};

export const transportScheduleLabelMapping: Record<ModeOfTransport, string> = {
  [ModeOfTransport.AIR]: 'Flight number',
  [ModeOfTransport.OCEAN]: 'Vessel name',
  [ModeOfTransport.RAIL]: 'Rail number',
  [ModeOfTransport.TRUCK]: ''
};

export const transportSchedulePlaceholderMapping: Record<ModeOfTransport, string> = {
  [ModeOfTransport.AIR]: 'F54408',
  [ModeOfTransport.OCEAN]: 'EVER URBAN',
  [ModeOfTransport.RAIL]: '45526',
  [ModeOfTransport.TRUCK]: ''
};

export const getTransportScheduleIdentifierLabel = (modeOfTransport: ModeOfTransport): string => {
  return transportScheduleLabelMapping[modeOfTransport];
};

export const getTransportScheduleIdentifierPlaceholder = (modeOfTransport: ModeOfTransport): string => {
  return transportSchedulePlaceholderMapping[modeOfTransport];
};
