import type { RoutesConfig } from './types';

export const OPS_DASHBOARD_ROUTE_PREFIX = '/dashboard/ops-dashboard';

interface OpsDashboardRoutes extends RoutesConfig {
  dashboardIndex: {
    getUrl: () => string;
    path: string;
  };
}

const opsDashboardRoutes: OpsDashboardRoutes = {
  dashboardIndex: {
    path: OPS_DASHBOARD_ROUTE_PREFIX,
    getUrl: () => OPS_DASHBOARD_ROUTE_PREFIX
  }
};

export default opsDashboardRoutes;
