import type { FC, ReactNode } from 'react';

export interface Props {
  className?: string;
  tagline?: ReactNode;
  title: ReactNode;
}

const PageTitle: FC<Props> = ({ className, tagline, title }) => (
  <div className={className} data-testid="page-title">
    <div className="text-2xl font-black leading-normal text-grey-dark">{title}</div>
    {tagline && <div className="text-sm text-grey-base leading-normal">{tagline}</div>}
  </div>
);

export type { Props as PageTitleProps };
export default PageTitle;
