import { lazy, Suspense } from 'react';

import ErrorBoundary from '@zen/Components/ErrorBoundary';
import { Loading } from '@zen/DesignSystem';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'importCallback' implicitly has an 'any'... Remove this comment to see the full error message
const loadable = (importCallback) => {
  const LoadedComponent = lazy(importCallback);

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  return (props) => (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <LoadedComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default loadable;
