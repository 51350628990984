import type { Location } from 'history';
import { FC, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router';
import { Prompt, withRouter } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';

import { Dialog } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

interface NavigationPromptState {
  callback: boolean;
  confirmedNavigation: boolean;
  lastLocation: Nullable<Location>;
  modalVisible: boolean;
}

interface NavigationPromptProps {
  allowedPaths?: string[];
  confirmationNeeded?: boolean;
  description?: string;
  header: string;
  leaveLabel: string;
  onLeave?: () => void;
  stayLabel: string;
}

export interface Props extends NavigationPromptProps, RouteComponentProps {}

const NavigationPrompt: FC<Props> = (props) => {
  const {
    allowedPaths = [],
    stayLabel,
    leaveLabel,
    header,
    history,
    location,
    onLeave = () => {},
    confirmationNeeded = true,
    description
  } = props;

  useBeforeUnload(confirmationNeeded, "You'll lose your data!");

  const [state, setState] = useState<NavigationPromptState>({
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
    callback: false
  });

  const setStateWith = (payload: Partial<NavigationPromptState>) => setState({ ...state, ...payload });

  useEffect(() => {
    const { lastLocation, confirmedNavigation } = state;

    if (lastLocation && confirmedNavigation) {
      setStateWith({ confirmedNavigation: false });

      history.push(lastLocation.pathname);
    }
  }, [state, history]);

  const showModal = (lastLocation: Location) => setStateWith({ modalVisible: true, lastLocation });

  const closeModal = () => setStateWith({ modalVisible: false, callback: false });

  const handleLeaveClick = () => {
    setStateWith({
      modalVisible: false,
      confirmedNavigation: true,
      callback: true
    });

    onLeave();
  };

  const handleNavigationPrompt = (nextLocation: Location) => {
    const { confirmedNavigation } = state;
    const isNewLocation = location.pathname !== nextLocation.pathname;
    const isAllowed = allowedPaths.some((allowPath) => nextLocation.pathname.includes(allowPath));

    if (!confirmedNavigation && isNewLocation && !isAllowed) {
      showModal(nextLocation);

      return false;
    }

    return true;
  };

  return (
    <>
      <Prompt message={handleNavigationPrompt} when={confirmationNeeded} />
      <Dialog
        cancelLabel={leaveLabel}
        confirmLabel={stayLabel}
        header={header}
        isOpen={state.modalVisible}
        message={description}
        onClose={handleLeaveClick}
        onConfirm={closeModal}
      />
    </>
  );
};

export default withRouter(NavigationPrompt);

export type { NavigationPromptProps };
