import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UsedAssignablesQueryVariables = GraphQLTypes.Exact<{
  networkIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  targetTypes: Array<GraphQLTypes.AssignmentTargetTypeEnum> | GraphQLTypes.AssignmentTargetTypeEnum;
  assignmentNames: Array<GraphQLTypes.AssignmentTypeValue> | GraphQLTypes.AssignmentTypeValue;
  assignableIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type UsedAssignablesQueryResult = { __typename?: 'Query' } & {
  networksUsedAssignables?: GraphQLTypes.Maybe<
    Array<
      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
            label?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
            >;
          })
      | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
            label?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
            >;
          })
      | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'unlocode' | 'id'> & {
            label?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
            >;
          })
    >
  >;
};

export const UsedAssignablesDocument = /* #__PURE__ */ gql`
  query usedAssignables(
    $networkIds: [String!]
    $targetTypes: [AssignmentTargetTypeEnum!]!
    $assignmentNames: [AssignmentTypeValue!]!
    $assignableIds: [String!]
    $textContains: String
  ) {
    networksUsedAssignables(
      targetTypes: $targetTypes
      assignmentNames: $assignmentNames
      networkIds: $networkIds
      assignableIds: $assignableIds
      textContains: $textContains
    ) {
      id
      label {
        long
        short
      }
      ... on NetworksTerminal {
        unlocode
      }
    }
  }
`;

/**
 * __useUsedAssignablesQuery__
 *
 * To run a query within a React component, call `useUsedAssignablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsedAssignablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsedAssignablesQuery({
 *   variables: {
 *      networkIds: // value for 'networkIds'
 *      targetTypes: // value for 'targetTypes'
 *      assignmentNames: // value for 'assignmentNames'
 *      assignableIds: // value for 'assignableIds'
 *      textContains: // value for 'textContains'
 *   },
 * });
 */
export function useUsedAssignablesQuery(
  baseOptions: Apollo.QueryHookOptions<UsedAssignablesQueryResult, UsedAssignablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsedAssignablesQueryResult, UsedAssignablesQueryVariables>(UsedAssignablesDocument, options);
}
export function useUsedAssignablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsedAssignablesQueryResult, UsedAssignablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UsedAssignablesQueryResult, UsedAssignablesQueryVariables>(UsedAssignablesDocument, options);
}
export type UsedAssignablesQueryHookResult = ReturnType<typeof useUsedAssignablesQuery>;
export type UsedAssignablesLazyQueryHookResult = ReturnType<typeof useUsedAssignablesLazyQuery>;
