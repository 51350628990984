export type IconName =
  | 'zicon-arrow-diagonal-oval'
  | 'zicon-arrow-down-oval'
  | 'zicon-arrow-left-oval'
  | 'zicon-arrow-right-oval'
  | 'zicon-arrow-up-oval'
  | 'zicon-help-inverse'
  | 'zicon-warehouse'
  | 'zicon-user'
  | 'zicon-unarchive'
  | 'zicon-trash'
  | 'zicon-textinput'
  | 'zicon-strike-through'
  | 'zicon-profile'
  | 'zicon-un-pinner'
  | 'zicon-pinner'
  | 'zicon-pin'
  | 'zicon-phone'
  | 'zicon-orders'
  | 'zicon-mail'
  | 'zicon-mail-open'
  | 'zicon-logout'
  | 'zicon-file'
  | 'zicon-copy'
  | 'zicon-book'
  | 'zicon-bell'
  | 'zicon-archive'
  | 'zicon-announcement'
  | 'zicon-add-user'
  | 'zicon-diagonal-arrow'
  | 'zicon-chevron-up-small'
  | 'zicon-chevron-right-small'
  | 'zicon-chevron-left-small'
  | 'zicon-chevron-down-small'
  | 'zicon-refresh'
  | 'zicon-list-lines'
  | 'zicon-sort-ascending'
  | 'zicon-sort-descending'
  | 'zicon-tick-small'
  | 'zicon-minus-small'
  | 'zicon-close-small'
  | 'zicon-add-small'
  | 'zicon-attach'
  | 'zicon-bold'
  | 'zicon-bullets'
  | 'zicon-italic'
  | 'zicon-redo'
  | 'zicon-underline'
  | 'zicon-undo'
  | 'zicon-packing-list'
  | 'zicon-warning-triangle-inverse'
  | 'zicon-warning-triangle-inverse-double'
  | 'zicon-warning-triangle-inverse-triple'
  | 'zicon-preview'
  | 'zicon-flag'
  | 'zicon-utilisation'
  | 'zicon-list'
  | 'zicon-anchor'
  | 'zicon-chess-king'
  | 'zicon-chess-runner'
  | 'zicon-upload'
  | 'zicon-action-item'
  | 'zicon-send'
  | 'zicon-time'
  | 'zicon-close'
  | 'zicon-dashboard'
  | 'zicon-labs'
  | 'zicon-intake-planning'
  | 'zicon-booking-request'
  | 'zicon-quotes'
  | 'zicon-warehouse-intake'
  | 'zicon-products'
  | 'zicon-help-centre'
  | 'zicon-network'
  | 'zicon-analytics'
  | 'zicon-finance'
  | 'zicon-guides'
  | 'zicon-adjust'
  | 'zicon-shipment'
  | 'zicon-api'
  | 'zicon-manufacturer'
  | 'zicon-agent'
  | 'zicon-reciept'
  | 'zicon-full-heart'
  | 'zicon-no-heart'
  | 'zicon-open-heart'
  | 'zicon-collapse'
  | 'zicon-tail-lift'
  | 'zicon-tick'
  | 'zicon-warning'
  | 'zicon-warning-triangle'
  | 'zicon-tickoval'
  | 'zicon-warning-oval'
  | 'zicon-add'
  | 'zicon-air'
  | 'zicon-alert'
  | 'zicon-approval'
  | 'zicon-arrow-down'
  | 'zicon-arrow-left'
  | 'zicon-arrow-right'
  | 'zicon-arrow-up'
  | 'zicon-box'
  | 'zicon-calendar'
  | 'zicon-chevron-down'
  | 'zicon-chevron-left'
  | 'zicon-chevron-right'
  | 'zicon-chevron-up'
  | 'zicon-cross'
  | 'zicon-cross-oval'
  | 'zicon-customs'
  | 'zicon-delay'
  | 'zicon-dots'
  | 'zicon-download'
  | 'zicon-edit'
  | 'zicon-edit-table'
  | 'zicon-filter'
  | 'zicon-frozen'
  | 'zicon-hazardous'
  | 'zicon-help'
  | 'zicon-line'
  | 'zicon-menu'
  | 'zicon-minus'
  | 'zicon-new-booking'
  | 'zicon-overweight'
  | 'zicon-pallet'
  | 'zicon-plane-down'
  | 'zicon-plane-up'
  | 'zicon-port'
  | 'zicon-rail'
  | 'zicon-refrigerated'
  | 'zicon-road'
  | 'zicon-search'
  | 'zicon-settings'
  | 'zicon-ship'
  | 'zicon-stackable'
  | 'zicon-tower'
  | 'zicon-updown'
  | 'zicon-intake'
  | 'zicon-star'
  | 'zicon-union'
  | 'zicon-drag-handle'
  | 'zicon-trending-down'
  | 'zicon-trending-up'
  | 'zicon-trophy'
  | 'zicon-hidden'
  | 'zicon-information-in'
  | 'zicon-visible'
  | 'zicon-information';

export enum Color {
  AZURE_LIGHTEST = '#F2FAFF',
  AZURE_LIGHTER = '#D9F0FF',
  AZURE_LIGHT = '#8CD1FF',
  AZURE_BASE = '#0D97F2',
  BRONZE = '#D98200',
  GOLD = '#D9B600',
  GREEN_LIGHT = '#DAF2E7',
  GREEN_BASE = '#36B37E',
  GREEN_DARK = '#007A52',
  GREY_LIGHTEST = '#F7F7F8',
  GREY_LIGHTER = '#E6E7E9',
  GREY_LIGHT = '#B4B6BB',
  GREY_BASE = '#686B76',
  GREY_DARK = '#1D2231',
  NAVY_LIGHT = '#878BBF',
  NAVY_BASE = '#0E177A',
  NAVY_DARK = '#080E49',
  ORANGE_LIGHT = '#FFE8D9',
  ORANGE_BASE = '#F79A3E',
  ORANGE_DARK = '#A65517',
  RED_LIGHT = '#FFDFDE',
  RED_BASE = '#F07C78',
  RED_DARK = '#B33532',
  TRANSPARENT = '#ffffff00',
  WHITE = '#ffffff',
  WHITE_10 = '#ffffff1A',
  WHITE_20 = '#ffffff33'
}

export enum FontSize {
  TWO_XS = '0.625rem',
  XS = '0.75rem',
  SM = '0.875rem',
  BASE = '1rem',
  LG = '1.125rem',
  XL = '1.25rem',
  TWO_XL = '1.5rem',
  THREE_XL = '1.875rem',
  FOUR_XL = '2.25rem',
  FIVE_XL = '3rem',
  SIX_XL = '4rem'
}
