import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { DateTimeRangeFragmentDoc } from '../../graphql/fragments/DateTimeRange.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetOperationsRoadShipmentQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetOperationsRoadShipmentQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<
            GraphQLTypes.Booking,
            | 'cargoDescription'
            | 'clientReference'
            | 'customsOnly'
            | 'multiStop'
            | 'shipmentType'
            | 'tradeRole'
            | 'truckSwap'
            | 'zencargoReference'
          > & {
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    cargoItems?: GraphQLTypes.Maybe<
                      Array<
                        | ({ __typename?: 'CargoContainerType' } & Pick<
                            GraphQLTypes.CargoContainerType,
                            'cbm' | 'hazardous' | 'looseCargoType' | 'id' | 'quantity' | 'reefer'
                          > & {
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  'driverDetails' | 'id' | 'reference' | 'vehiclePlateNumber'
                                > & {
                                    arrivedAtWarehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                    confirmedByForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                    departedFromWarehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    requestedWithForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<GraphQLTypes.DeliveryDetails, 'id' | 'reference'> & {
                                    arrivedAtWarehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                    confirmedByForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    requestedWithForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                            })
                        | ({ __typename?: 'CargoLooseCargoType' } & Pick<
                            GraphQLTypes.CargoLooseCargoType,
                            | 'palletType'
                            | 'stackable'
                            | 'trailerId'
                            | 'cbm'
                            | 'hazardous'
                            | 'looseCargoType'
                            | 'id'
                            | 'quantity'
                            | 'reefer'
                          > & {
                              dimensions?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoDimensionsType' } & Pick<
                                  GraphQLTypes.CargoDimensionsType,
                                  'length' | 'width' | 'height' | 'metric'
                                >
                              >;
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  'driverDetails' | 'id' | 'reference' | 'vehiclePlateNumber'
                                > & {
                                    arrivedAtWarehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                    confirmedByForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                    departedFromWarehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    requestedWithForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<GraphQLTypes.DeliveryDetails, 'id' | 'reference'> & {
                                    arrivedAtWarehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                    confirmedByForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    requestedWithForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                            })
                        | ({ __typename?: 'CargoVehicleType' } & Pick<
                            GraphQLTypes.CargoVehicleType,
                            | 'palletType'
                            | 'riskLevel'
                            | 'tailLift'
                            | 'trailerId'
                            | 'vehicleType'
                            | 'cbm'
                            | 'hazardous'
                            | 'looseCargoType'
                            | 'id'
                            | 'quantity'
                            | 'reefer'
                          > & {
                              dimensions?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoDimensionsType' } & Pick<
                                  GraphQLTypes.CargoDimensionsType,
                                  'length' | 'width' | 'height' | 'metric'
                                >
                              >;
                              valueOfGoods?: GraphQLTypes.Maybe<
                                { __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>
                              >;
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  'driverDetails' | 'id' | 'reference' | 'vehiclePlateNumber'
                                > & {
                                    arrivedAtWarehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                    confirmedByForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                    departedFromWarehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    requestedWithForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<GraphQLTypes.DeliveryDetails, 'id' | 'reference'> & {
                                    arrivedAtWarehouse?: GraphQLTypes.Maybe<
                                      { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                    >;
                                    confirmedByForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            businessHours?: GraphQLTypes.Maybe<
                                              { __typename?: 'BusinessHours' } & {
                                                monday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                tuesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                wednesday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                thursday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                friday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                saturday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                                sunday?: GraphQLTypes.Maybe<
                                                  { __typename?: 'TimeRange' } & Pick<
                                                    GraphQLTypes.TimeRange,
                                                    'startTime' | 'endTime'
                                                  >
                                                >;
                                              }
                                            >;
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    requestedWithForwarder?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                            })
                      >
                    >;
                  }
              >;
              cargoReadyDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
              consignee?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >;
              consignor?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >;
              haulier?: GraphQLTypes.Maybe<{ __typename?: 'NetworkContact' } & Pick<GraphQLTypes.NetworkContact, 'id' | 'name'>>;
              incoterms?: GraphQLTypes.Maybe<{ __typename?: 'Incoterms' } & Pick<GraphQLTypes.Incoterms, 'value'>>;
              specialInstructions?: GraphQLTypes.Maybe<
                { __typename?: 'BookingSpecialInstructions' } & Pick<GraphQLTypes.BookingSpecialInstructions, 'message'>
              >;
            }
        >
      >
    >;
  };
};

export const GetOperationsRoadShipmentDocument = /* #__PURE__ */ gql`
  query getOperationsRoadShipment($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        cargo {
          cargoItems {
            cbm
            collection {
              arrivedAtWarehouse {
                date
                time
              }
              confirmedByForwarder {
                ...DateTimeRange
              }
              departedFromWarehouse {
                date
                time
              }
              driverDetails
              id
              location {
                businessHours {
                  monday {
                    startTime
                    endTime
                  }
                  tuesday {
                    startTime
                    endTime
                  }
                  wednesday {
                    startTime
                    endTime
                  }
                  thursday {
                    startTime
                    endTime
                  }
                  friday {
                    startTime
                    endTime
                  }
                  saturday {
                    startTime
                    endTime
                  }
                  sunday {
                    startTime
                    endTime
                  }
                }
                id
                label {
                  long
                  short
                }
                timeZone
              }
              reference
              requestedWithForwarder {
                ...DateTimeRange
              }
              vehiclePlateNumber
            }
            delivery {
              arrivedAtWarehouse {
                date
                time
              }
              confirmedByForwarder {
                ...DateTimeRange
              }
              id
              location {
                businessHours {
                  monday {
                    startTime
                    endTime
                  }
                  tuesday {
                    startTime
                    endTime
                  }
                  wednesday {
                    startTime
                    endTime
                  }
                  thursday {
                    startTime
                    endTime
                  }
                  friday {
                    startTime
                    endTime
                  }
                  saturday {
                    startTime
                    endTime
                  }
                  sunday {
                    startTime
                    endTime
                  }
                }
                id
                label {
                  long
                  short
                }
                timeZone
              }
              reference
              requestedWithForwarder {
                ...DateTimeRange
              }
            }
            grossWeight {
              metric
              value
            }
            hazardous
            looseCargoType
            id
            quantity
            reefer
            ... on CargoLooseCargoType {
              dimensions {
                length
                width
                height
                metric
              }
              palletType
              stackable
              trailerId
            }
            ... on CargoVehicleType {
              dimensions {
                length
                width
                height
                metric
              }
              palletType
              riskLevel
              tailLift
              trailerId
              valueOfGoods {
                currency
                value
              }
              vehicleType
            }
          }
          mode
        }
        cargoDescription
        cargoReadyDate {
          date
        }
        clientReference
        consignee {
          id
          label {
            long
          }
        }
        consignor {
          id
          label {
            long
          }
        }
        customsOnly
        haulier {
          id
          name
        }
        incoterms {
          value
        }
        multiStop
        shipmentType
        specialInstructions {
          message
        }
        tradeRole
        truckSwap
        zencargoReference
      }
    }
  }
  ${DateTimeRangeFragmentDoc}
`;

/**
 * __useGetOperationsRoadShipmentQuery__
 *
 * To run a query within a React component, call `useGetOperationsRoadShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationsRoadShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationsRoadShipmentQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetOperationsRoadShipmentQuery(
  baseOptions: Apollo.QueryHookOptions<GetOperationsRoadShipmentQueryResult, GetOperationsRoadShipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetOperationsRoadShipmentQueryResult, GetOperationsRoadShipmentQueryVariables>(
    GetOperationsRoadShipmentDocument,
    options
  );
}
export function useGetOperationsRoadShipmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOperationsRoadShipmentQueryResult, GetOperationsRoadShipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetOperationsRoadShipmentQueryResult, GetOperationsRoadShipmentQueryVariables>(
    GetOperationsRoadShipmentDocument,
    options
  );
}
export type GetOperationsRoadShipmentQueryHookResult = ReturnType<typeof useGetOperationsRoadShipmentQuery>;
export type GetOperationsRoadShipmentLazyQueryHookResult = ReturnType<typeof useGetOperationsRoadShipmentLazyQuery>;
