import type { FC, ReactNode } from 'react';
import { useLocation } from 'react-router';

import { TextLink } from '@zen/DesignSystem';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { Undefinable } from '@zen/utils/typescript';

interface Props {
  children: ReactNode;
  linkTo: string;
  preserveCurrentQueryString?: boolean;
  state?: object;
}

const TableLink: FC<Props> = ({ children, linkTo, state, preserveCurrentQueryString = false }) => {
  const { navigate } = useNavigationHistory();
  const { search } = useLocation();

  const queryString: Undefinable<string> = preserveCurrentQueryString ? search : undefined;

  return <TextLink onClick={() => navigate({ pathname: linkTo, state, search: queryString })}>{children}</TextLink>;
};

export default TableLink;
