import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import { Button, IconButton } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import { performMutation } from '@zen/utils/performMutation';

import { useIssuesArchiveIssueMutation, useIssuesResolveIssueMutation, useIssuesStartIssueMutation } from '../../graphql';
import type { Issue } from '../../types';

interface Props {
  issue: Issue;
  onEdit: () => void;
  onStateChange: () => void;
}

const IssueActionButtons: FC<Props> = ({ issue, onStateChange, onEdit }) => {
  const { addError, addSuccess } = useNotification();
  const { trackEvent } = useTracking();
  const [startIssue] = useIssuesStartIssueMutation();
  const [resolveIssue] = useIssuesResolveIssueMutation();
  const [archiveIssue] = useIssuesArchiveIssueMutation();

  const canStart = checkPermission(issue, 'canStart');
  const canResolve = checkPermission(issue, 'canResolve');
  const canUpdate = checkPermission(issue, 'canUpdate');
  const canArchive = checkPermission(issue, 'canArchive');

  const buttonClassNames = 'mr-4';

  const handleSuccess = (message: string) => {
    addSuccess(message);
    onStateChange();
  };

  const onStart = async () => {
    trackEvent({
      action: 'StartIssue',
      category: 'Issues',
      label: issue.category
    });

    return performMutation({
      mutationFn: () =>
        startIssue({
          variables: {
            id: issue.id
          }
        }),
      onSuccess: () => handleSuccess('Investigation started'),
      onError: () => addError('Unable to start investigation')
    });
  };

  const onResolve = async () => {
    trackEvent({
      action: 'ResolveIssue',
      category: 'Issues',
      label: issue.category
    });

    return performMutation({
      mutationFn: () =>
        resolveIssue({
          variables: {
            id: issue.id
          }
        }),
      onSuccess: () => handleSuccess('Issue has been resolved'),
      onError: () => addError('Unable to resolve issue')
    });
  };

  const onArchive = async () => {
    trackEvent({
      action: 'ArchiveIssue',
      category: 'Issues',
      label: issue.category
    });

    return performMutation({
      mutationFn: () =>
        archiveIssue({
          variables: {
            id: issue.id
          }
        }),
      onSuccess: () => handleSuccess('Issue has been archived'),
      onError: () => addError('Unable to archive issue')
    });
  };

  return (
    <div className="flex" data-testid="issue-state-buttons">
      {canStart && (
        <Button className={buttonClassNames} onClick={onStart} variant="primary">
          Investigating
        </Button>
      )}
      {canResolve && (
        <WithConfirmationModal
          message="Resolving this issue will permanently close it and you will not be able to re-open the issue."
          onConfirm={onResolve}
        >
          <Button className={buttonClassNames} variant="primary">
            Resolve
          </Button>
        </WithConfirmationModal>
      )}
      {canUpdate && (
        <IconButton className={buttonClassNames} icon="zicon-edit" onClick={onEdit} title="Edit" variant="secondary" />
      )}
      {canArchive && (
        <WithConfirmationModal
          message="Archiving this issue will permanently remove it, and you will not be able to view or re-open the issue."
          onConfirm={onArchive}
        >
          <IconButton className={buttonClassNames} icon="zicon-archive" title="Archive" variant="secondary" />
        </WithConfirmationModal>
      )}
    </div>
  );
};

export default IssueActionButtons;
