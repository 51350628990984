/* eslint-disable react/no-unused-prop-types */
import cx from 'classnames';
import type { FC, ReactElement, ReactNode } from 'react';

import { Icon, Tooltip } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

import { iconMapping } from './helpers';
import { ReactComponent as PinIcon } from './pin.svg';
import type { MarkerColor, MarkerIcon } from './types';

interface Props {
  color?: MarkerColor;
  icon?: MarkerIcon;
  isHighlighted?: boolean;
  isSelected?: boolean;
  lat: number;
  lng: number;
  onClick?: () => void;
  tooltipContent?: ReactNode;
}

const Marker: FC<Props> = (props) => {
  const { color = 'blue', icon = 'pin', isHighlighted = false, isSelected = false, onClick, tooltipContent } = props;
  const iconName: IconName = iconMapping[icon];
  const isActive: boolean = isHighlighted || isSelected;

  const wrapperClassNames: string = cx(
    {
      'z-20': isSelected,
      'z-30': isHighlighted
    },
    'relative flex justify-center items-center transform -translate-x-1/2 -translate-y-full group z-10 hover:z-40'
  );

  const pinClassNames: string = cx(
    {
      'fill-azure-base': color === 'blue' && !isActive,
      'fill-azure-light ': color === 'blue' && isActive,
      'fill-green-base': color === 'green' && !isActive,
      'fill-green-dark': color === 'green' && isActive,
      'fill-orange-base': color === 'orange' && !isActive,
      'fill-orange-dark': color === 'orange' && isActive,
      'fill-red-base': color === 'red' && !isActive,
      'fill-red-dark': color === 'red' && isActive,
      'stroke-white': isActive
    },
    'absolute z-10 cursor-pointer group-hover:fill-white group-hover:stroke-grey-lighter'
  );

  const iconClassNames: string = cx(
    {
      'group-hover:text-azure-base': color === 'blue',
      'group-hover:text-green-base': color === 'green',
      'group-hover:text-orange-base': color === 'orange',
      'group-hover:text-red-base': color === 'red'
    },
    'text-white pointer-events-none -mt-0.5 z-20'
  );

  const renderMarker = (): ReactElement => {
    return (
      <div className={wrapperClassNames} data-testid="marker" onClick={onClick} style={{ width: '24px', height: '32px' }}>
        <PinIcon className={pinClassNames} />
        <span className={iconClassNames} style={{ fontSize: '16px' }}>
          <Icon className="pointer-events-none" icon={iconName} />
        </span>
      </div>
    );
  };

  const renderMarkerWithTooltip = (): ReactElement => {
    return (
      <Tooltip placement="bottom-start" tooltipContent={tooltipContent}>
        {renderMarker()}
      </Tooltip>
    );
  };

  return tooltipContent ? renderMarkerWithTooltip() : renderMarker();
};

export type { Props as MarkerType };

export default Marker;
