import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import { ActivityFeedItemTypeEnum } from '@zen/ActivityFeed/types';
import CloseIcon from '@zen/Components/Icons/CloseIcon';
import SectionTitle from '@zen/Components/SectionTitle';
import Slideout from '@zen/Components/Slideout';
import { Button, Container } from '@zen/DesignSystem';
import ShipmentActivityFeed from '@zen/Shipment/ShipmentActivityFeed';
import { ShipmentPageSectionUrl } from '@zen/Shipment/ShipmentDetailsPage/types';

import { useShipmentDetailsPageContext } from '../ShipmentDetailsPageContext/ShipmentDetailsPageContext';

interface Props {
  accountId: string;
  zencargoReference: string;
}

const ActivityFeedContainer: FC<Props> = ({ zencargoReference, accountId }) => {
  const { sectionName } = useParams<{ sectionName: string }>();
  const history = useHistory();
  const location = useLocation();
  const { activityFeedViewType } = useShipmentDetailsPageContext();

  useEffect(() => {
    if (sectionName === ShipmentPageSectionUrl.ACTIVITY_FEED_EXPANDED) {
      setSeeAllActivity(true);
    }
  }, [sectionName]);

  const [seeAllActivity, setSeeAllActivity] = useState<boolean>(false);

  const activityFeedAllActivityUrl: string = `${zencargoReference}/${ShipmentPageSectionUrl.ACTIVITY_FEED_EXPANDED}`;

  const handleOpenActivityFeedAllActivity = (): void => {
    setSeeAllActivity(true);
    history.push(activityFeedAllActivityUrl);
  };

  const handleCloseActivityFeedAllActivity = (): void => {
    setSeeAllActivity(false);
    history.push(location.pathname.replace(`/${ShipmentPageSectionUrl.ACTIVITY_FEED_EXPANDED}`, ''));
  };

  return (
    <Container
      actions={
        <Button iconLeft="zicon-time" onClick={handleOpenActivityFeedAllActivity} variant="tertiary">
          See all activity
        </Button>
      }
      title="Recent messages"
    >
      <div className="mt-9 mb-12 relative z-0" data-testid="custom-activity-feed" style={{ height: '35rem' }}>
        <ShipmentActivityFeed
          accountId={accountId}
          hideTimeline={true}
          itemTypes={[ActivityFeedItemTypeEnum.TEXT_MESSAGE, ActivityFeedItemTypeEnum.ZENSEI_TEXT_MESSAGE]}
          viewType={activityFeedViewType}
          zencargoReference={zencargoReference}
        />
      </div>

      <Slideout onOutsideClick={handleCloseActivityFeedAllActivity} show={seeAllActivity}>
        <div className="h-full w-220">
          <div className="flex justify-between bg-grey-lightest px-6 pt-6 relative z-10">
            <SectionTitle className="-mt-4" title="All activity" />
            <CloseIcon onClick={handleCloseActivityFeedAllActivity} />
          </div>
          <div className="h-full mt-5 pb-32 px-7 relative z-0 mr-5" data-testid="activity-feed">
            <ShipmentActivityFeed accountId={accountId} viewType={activityFeedViewType} zencargoReference={zencargoReference} />
          </div>
        </div>
      </Slideout>
    </Container>
  );
};

export default ActivityFeedContainer;
