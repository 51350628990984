import type { FC } from 'react';

import { CargoItemType, CargoItemTypeEnum } from '@zen/Cargo';
import { getLooseCargoIcon, getLooseCargoLabel } from '@zen/Cargo/helpers';
import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import { cargoTypeLabelMapping } from '@zen/types';

interface Props {
  cargo: Partial<CargoItemType>;
}

const CargoHeadline: FC<Props> = ({ cargo }) => {
  const { containerType, looseCargoType, palletType, vehicleType, type } = cargo;

  const cargoTypeMapping: Record<CargoItemTypeEnum, { icon: IconName; label: string }> = {
    [CargoItemTypeEnum.CONTAINER]: {
      icon: 'zicon-shipment',
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      label: cargoTypeLabelMapping[containerType]
    },
    [CargoItemTypeEnum.LOOSE_CARGO]: {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'LooseCargoTypeEnum | undefined' ... Remove this comment to see the full error message
      icon: getLooseCargoIcon(looseCargoType),
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'LooseCargoTypeEnum | undefined' ... Remove this comment to see the full error message
      label: getLooseCargoLabel(looseCargoType, palletType)
    },
    [CargoItemTypeEnum.VEHICLE]: {
      icon: 'zicon-road',
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      label: cargoTypeLabelMapping[vehicleType]
    }
  };

  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const { icon, label } = cargoTypeMapping[type];

  return (
    <div className="flex items-center text-navy-base text-sm font-bold" data-testid="cargo-headline">
      <Icon className="text-xl mr-2" icon={icon} />
      <span>{label}</span>
    </div>
  );
};

export default CargoHeadline;
