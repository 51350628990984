import type { FC, ReactNode } from 'react';

import InlineEditableField from '@zen/Components/InlineEditableField';
import { Icon } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateMasterBillOfLadingMutation } from '../graphql';

interface Props {
  canUpdateMasterBillOfLading: boolean;
  masterBillOfLading: string;
  zencargoReference: string;
}

const MasterBillOfLadingCargoInfoInput: FC<Props> = ({ masterBillOfLading, zencargoReference, canUpdateMasterBillOfLading }) => {
  const { addSuccess, addError } = useNotification();
  const [updateMasterBillOfLading] = useUpdateMasterBillOfLadingMutation();

  const handleUpdateMasterBillOfLading = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateMasterBillOfLading({
          variables: {
            input: {
              zencargoReference,
              masterBillOfLading: value
            }
          },
          refetchQueries: ['getTrackingData']
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Master bill of lading updated.');
      }
    });
  };

  const renderTrackAndTraceIcon = (): ReactNode => {
    return (
      <a href={`https://connect.track-trace.com/for/zencargo/billoflading/${masterBillOfLading}`} target="_blank">
        <Icon
          className="text-grey-base text-sm rounded hover:text-navy-base hover:bg-grey-lighter p-1 ml-1"
          icon="zicon-diagonal-arrow"
        />
      </a>
    );
  };

  return (
    <InlineEditableField
      additionalElement={renderTrackAndTraceIcon()}
      className="py-3.5 px-2"
      disabled={!canUpdateMasterBillOfLading}
      name="Master bill of lading"
      onUpdate={handleUpdateMasterBillOfLading}
      overLabel="Master bill of lading"
      value={masterBillOfLading}
    />
  );
};

export default MasterBillOfLadingCargoInfoInput;
