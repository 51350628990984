import { BookingStageCheck, BookingStageCheckEnum, StageCheckConfig } from '../types';

export const getStageItemConfig = (stageCheck: BookingStageCheck): StageCheckConfig => {
  const stageConfig = {
    [BookingStageCheckEnum.FORWARDER_APPROVED]: {
      label: 'Booking:',
      getStatus: (isCompleted: boolean) => (isCompleted ? 'Approved' : 'Not approved')
    },
    [BookingStageCheckEnum.QUOTE_ACCEPTED]: {
      label: 'Quote:',
      getStatus: (isCompleted: boolean) => (isCompleted ? 'Accepted' : 'Not accepted')
    }
  };

  const { label, getStatus } = stageConfig[stageCheck.name];

  return { label, status: getStatus(stageCheck.completed) };
};
