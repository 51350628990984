import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import QueryHandler from '@zen/Components/QueryHandler';
import { isOceanShipment } from '@zen/Shipments';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';

import ContractIdSection from './ContractIdSection';
import DetailsBoardCargoInfoSection from './DetailsBoardCargoInfoSection';
import DetailsBoardInstructionsSection from './DetailsBoardInstructionsSection';
import DetailsBoardNetworksSection from './DetailsBoardNetworksSection';
import DetailsBoardTransportScheduleSection from './DetailsBoardTransportScheduleSection';
import { getFlatShipmentDetailsBoard } from './helpers';
import SkeletonShipmentDetails from './Loading/SkeletonShipmentDetails';
import type { FlatShipmentDetailsBoard, ShipmentDetailsBoardData } from './types';
import { useGetShipmentDetailsBoardData } from './useGetShipmentDetailsBoardData';

interface Props {
  zencargoReference: string;
}

const ShipmentDetailsBoard: FC<Props> = ({ zencargoReference }) => {
  const { contractId: isContractIdEnabled, newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();
  const { data, error, loading, refetch } = useGetShipmentDetailsBoardData(zencargoReference, !!isNewCargoServiceEnabled);

  return (
    <QueryHandler
      data={data?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonShipmentDetails />}
    >
      {(shipmentDetails: ShipmentDetailsBoardData) => {
        const shipment: FlatShipmentDetailsBoard = getFlatShipmentDetailsBoard(shipmentDetails);
        const canManageSchedule: boolean = checkPermission<ShipmentDetailsBoardData>(shipmentDetails, 'canManageSchedule');
        const canViewSchedule: boolean = checkPermission<ShipmentDetailsBoardData>(shipmentDetails, 'canViewSchedule');
        const canViewContractId: boolean = checkPermission<ShipmentDetailsBoardData>(shipmentDetails, 'canViewContractId');
        const showContractIdSection: boolean =
          canViewContractId && isContractIdEnabled && isOceanShipment(shipment.modeOfTransport);

        return (
          <div className="relative bg-white shadow-md px-8 py-2 rounded z-10">
            <DetailsBoardNetworksSection shipment={shipment} zencargoReference={zencargoReference} />

            <DetailsBoardTransportScheduleSection
              canManageSchedule={canManageSchedule}
              canViewSchedule={canViewSchedule}
              shipment={shipment}
              zencargoReference={zencargoReference}
            />
            {showContractIdSection && (
              <div className="flex pb-3" data-testid="contract-id-section">
                <div className="w-2/5" />
                <div className="w-3/5 flex pl-5">
                  <ContractIdSection zencargoReference={zencargoReference} />
                </div>
              </div>
            )}
            <DetailsBoardCargoInfoSection
              refetchShipmentDetails={refetch}
              shipment={shipment}
              zencargoReference={zencargoReference}
            />
            {shipment.specialInstructions && (
              <DetailsBoardInstructionsSection specialInstructions={shipment.specialInstructions} />
            )}
          </div>
        );
      }}
    </QueryHandler>
  );
};

export default ShipmentDetailsBoard;
