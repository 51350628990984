import { Route, Switch } from 'react-router';

import IntakeIndex from './IntakeIndex';
import IntakeWeekly from './IntakeWeekly';

export const intakePathPrefix = '/dashboard/intake-planning';
export const intakeWeeklyPathPrefix = `${intakePathPrefix}/weekly`;

export const IntakePlanningContainer = () => {
  return (
    <Switch>
      <Route path={intakeWeeklyPathPrefix}>
        <IntakeWeekly />
      </Route>
      <Route path={intakePathPrefix}>
        <IntakeIndex />
      </Route>
    </Switch>
  );
};

export default IntakePlanningContainer;
