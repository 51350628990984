import type { FC, ReactNode } from 'react';

import Card from '@zen/Components/Card';
import { Currency, QuoteOption, QuoteOptionState, QuoteOptionTotal } from '@zen/Quotes/types';
import { accrualRoutes } from '@zen/Routes';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';

import QuoteOptionItemField from '../QuoteOptionItemField';
import QuoteOptionAccrual from './QuoteOptionAccrual/QuoteOptionAccrual';

interface Props {
  accountId: string;
  children: ReactNode;
  quoteOption?: QuoteOption;
  zencargoReference?: string;
}

const defaultTotal: QuoteOptionTotal = {
  value: 0,
  currency: Currency.GBP
};

const getEmptyQuoteOption = (zencargoReference: string): QuoteOption => ({
  id: null,
  zencargoReference,
  quoteReference: '',
  accrualId: '',
  description: '',
  state: QuoteOptionState.DRAFT,
  total: defaultTotal,
  expiryDate: {
    date: ''
  }
});

const QuoteOptionItem: FC<Props> = (props) => {
  const { accountId, children, zencargoReference = '' } = props;

  const quoteOption = { ...getEmptyQuoteOption(zencargoReference), ...props.quoteOption };
  const accrualLink = accrualRoutes.accrualDetails.getUrl(accountId, quoteOption.zencargoReference, quoteOption?.accrualId || '');
  const quoteOptionId = quoteOption.id;

  const renderAccrualLink = (): ReactNode => (
    <QuoteOptionAccrual
      accrualId={quoteOption?.accrualId || ''}
      accrualLink={accrualLink}
      quoteOptionTotal={quoteOption?.total || defaultTotal}
    />
  );

  const quoteOptionStaticFields: { content: ReactNode; label: string }[] = [
    {
      label: 'Quote reference',
      content: quoteOptionId ? quoteOption.quoteReference : '-'
    },
    {
      label: 'Accrual',
      content: quoteOption.accrualId ? renderAccrualLink() : '-'
    },
    {
      label: 'Status',
      content: removeSpecialCharactersAndUpperFirst(quoteOption?.state || '')
    }
  ];

  return (
    <Card>
      <div className="max-w-2xl flex flex-row justify-between text-grey-base">
        {quoteOptionStaticFields.map((field, i) => (
          <QuoteOptionItemField key={i} className="w-1/3 text-left" {...field} />
        ))}
      </div>
      {children}
    </Card>
  );
};

export default QuoteOptionItem;
