import type { NetworksAssignableInterface } from '@zen/Networks';
import type { NetworkField } from '@zen/OperationsShipments/EditRoadShipment/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { OriginDestinationFormData } from '../types';

export const prepareNetworkField = (location: Optional<NetworksAssignableInterface>): Nullable<NetworkField> => {
  if (!location) {
    return null;
  }

  return {
    id: location.id,
    label: location.label
  };
};

export const checkInitialValueEqual = (
  currentValues: OriginDestinationFormData,
  initialValues: OriginDestinationFormData,
  fieldName: keyof OriginDestinationFormData
): boolean => {
  return currentValues[fieldName]?.id === initialValues[fieldName]?.id;
};
