import { get } from 'lodash';
import pDebounce from 'p-debounce';
import type { FC, ReactNode } from 'react';

import FormAsyncSelect from '@zen/Components/Form/FormAsyncSelect';
import { highlightQuery } from '@zen/Components/Form/utils';
import { apolloClient } from '@zen/graphql/GraphQLProvider';
import { SortingOrder } from '@zen/types';

import type { ContactTypeEnum } from '../types';
import { GetContactListDocument } from './graphql';

interface Contact {
  id: string;
  name: string;
}

interface Props {
  autoFocus?: boolean;
  className?: string;
  contactTypes?: ContactTypeEnum[];
  isRequired?: boolean;
  label: string;
  name: string;
}

const FormContactInput: FC<Props> = ({ autoFocus, className, contactTypes, label, name, isRequired }) => {
  const debounceDelay: number = 300;

  const handleChange = async (searchQuery: string): Promise<Contact[]> => {
    const QUERY = {
      query: GetContactListDocument,
      variables: {
        order: {
          field: 'name',
          direction: SortingOrder.ASC
        },
        searchQuery,
        types: contactTypes
      }
    };

    const { data } = await apolloClient.query(QUERY);

    return get(data, 'contacts.nodes', []);
  };

  const debouncedHandleInputChange = pDebounce(handleChange, debounceDelay);

  const getOptionLabel = (contact: Contact, inputValue: string): ReactNode => highlightQuery(contact.name, inputValue);

  return (
    <FormAsyncSelect<Contact>
      autoFocus={autoFocus}
      className={className}
      formatOptionLabel={getOptionLabel}
      isClearable={true}
      isRequired={isRequired}
      label={label}
      loadOptions={debouncedHandleInputChange}
      name={name}
    />
  );
};

export default FormContactInput;
