import type { FC, ReactNode } from 'react';

import type { LooseCargoFormData } from '@zen/Cargo';
import CargoTypeImage from '@zen/Cargo/components/CargoTypeImage';
import {
  getDefaultNewCargoGrossWeight,
  isNewCargoPalletType,
  isTemplatedPalletNewCargoType,
  looseNewCargoOptions
} from '@zen/Cargo/helpers';
import { Form, FormHiddenInput, FormInput, FormInstance, FormNumberInput, FormSelect } from '@zen/Components/Form';
import FormCargoDimensionsInput from '@zen/Components/Form/FormCargoDimensionsInput';
import { FormObserver } from '@zen/Components/Form/FormObserver';
import FormSwitch from '@zen/Components/Form/FormSwitch';
import { isAirShipment, isOceanShipment, isRoadShipment, ModeOfTransport } from '@zen/Shipments';
import type { NewCargoType } from '@zen/types';
import { cargoValues } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { DeepNullable, Nullable } from '@zen/utils/typescript';

import type { CargoUpdateMutationResponse, CargoWeight, Dimensions } from '../../types';
import FormCargoWeightInput from '../FormCargoWeightInput';
import { getDefaultDimensionsNewCargo, mergeInitialValues, palletNewCargoOptions } from './helpers';
import { validationSchema } from './validation';

export interface Props {
  className?: string;
  formButtons?: (formBag: FormInstance<LooseCargoFormData>) => ReactNode;
  initialValues?: Partial<LooseCargoFormData>;
  modeOfTransport: ModeOfTransport;
  onChange?: (isDirty: boolean) => void;
  onSubmit: (values: LooseCargoFormData) => Promise<IOkOrErrorResult>;
  onSuccess?: ({ cargoItem }: CargoUpdateMutationResponse) => void;
  showImage?: boolean;
}

const LooseForm: FC<Props> = (props) => {
  const { className, formButtons, initialValues, modeOfTransport, onChange, onSubmit, showImage = true, onSuccess } = props;

  const renderForm = ({ dirty, setFieldValue, values }: FormInstance<LooseCargoFormData>): ReactNode => {
    const { looseCargoType, cargoType } = values;
    const isAssortedCargo: boolean = looseCargoType === cargoValues.looseAssortedCargo;

    const handlePalletTypeChange = (value: Nullable<NewCargoType>): void => {
      const dimensions: Dimensions = getDefaultDimensionsNewCargo(value);
      const grossWeight: DeepNullable<CargoWeight> = getDefaultNewCargoGrossWeight(value);

      setFieldValue('dimensions', dimensions);
      setFieldValue('grossWeight', grossWeight);
    };

    const handleLooseCargoChange = (selectedCargoType: Nullable<NewCargoType>): void => {
      if (!isNewCargoPalletType(selectedCargoType)) {
        setFieldValue('cargoType', null);
      }

      setFieldValue('looseCargoType', selectedCargoType);
    };

    return (
      <>
        {showImage && looseCargoType && (
          <CargoTypeImage cargoType={looseCargoType} className="absolute -top-10 h-18" isNewCargo={true} />
        )}

        <FormHiddenInput name="looseCargoType" />

        {isOceanShipment(modeOfTransport) && <FormInput className="w-64" label="Container number" name="containerNumber" />}
        {isRoadShipment(modeOfTransport) && <FormInput className="w-64" label="Trailer ID" name="trailerId" />}

        <div className="flex">
          <FormSwitch className="mr-12" label="Hazardous" name="hazardous" />
          <FormSwitch className="mr-12" label="Refrigerated" name="refrigerated" />
          <FormSwitch label="Stackable" name="stackable" />
        </div>

        <div className="flex">
          <FormSelect
            className="w-64 mr-4"
            isRequired={true}
            label="Loose cargo type"
            name="looseCargoType"
            onChange={handleLooseCargoChange}
            options={looseNewCargoOptions}
          />
          {looseCargoType === cargoValues.loosePallet && (
            <FormSelect
              className="w-64 mr-4"
              isRequired={true}
              label="Pallet type"
              name="cargoType"
              onChange={handlePalletTypeChange}
              options={palletNewCargoOptions}
            />
          )}
          {!isAssortedCargo && <FormNumberInput className="w-64 mr-4" isRequired={true} name="quantity" />}
          <FormNumberInput className="w-64" label="CBM" name="actualCbm" placeholder="0.0" />
        </div>
        <div className="flex">
          <FormCargoWeightInput className="w-64 mr-4" isNewCargo={true} name="grossWeight" />
          {isAirShipment(modeOfTransport) && (
            <FormCargoWeightInput className="w-64" isNewCargo={true} label="Chargeable weight" name="chargeableWeight" />
          )}
        </div>
        <FormCargoDimensionsInput disabled={isTemplatedPalletNewCargoType(cargoType)} isNewCargo={true} name="dimensions" />

        <FormObserver onChange={onChange} value={dirty} />
      </>
    );
  };

  return (
    <Form
      className={className}
      enableReinitialize={true}
      formButtons={formButtons}
      formName="LooseForm"
      initialValues={mergeInitialValues(initialValues)}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {renderForm}
    </Form>
  );
};

export default LooseForm;
