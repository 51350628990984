import cx from 'classnames';
import type { ReactNode } from 'react';
import { useWindowSize } from 'react-use';

interface StickyFooterProps {
  children: ReactNode;
  className?: string;
  minWindowHeight?: number;
}

const StickyFooter = ({ children, minWindowHeight = 0, className = '' }: StickyFooterProps) => {
  const { height } = useWindowSize();

  const isButtonFixed = (): boolean => {
    const html: HTMLElement | null = document.querySelector('html');

    if (minWindowHeight && html) {
      const fontSize = parseInt(window.getComputedStyle(html).fontSize.slice(0, -2), 10);

      return minWindowHeight > height / fontSize;
    }

    return true;
  };

  const fixedButtonClassName: string = `fixed flex px-4 mr-0 justify-between bg-white
    items-center left-0 right-0 bottom-0 border-top-grey-light
    border-top border-top-solid z-10 shadow-top`;

  const buttonClassName: string = cx({ [fixedButtonClassName]: isButtonFixed() }, className);

  return <div className={buttonClassName}>{children}</div>;
};

export default StickyFooter;
