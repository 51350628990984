import cx from 'classnames';
import type { CSSProperties, FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import type { TableColumn } from '../types';

export interface Props {
  className?: string;
  columnConfiguration?: Pick<TableColumn, 'key' | 'width'> & { onEdit?: () => void }; // skipped by rc-library for colspans, eg. empty data row
  style: CSSProperties;
}

const TableCell: FC<Props> = (props) => {
  const { children, className, columnConfiguration, style, ...rest } = props;
  const { key, onEdit, width } = columnConfiguration || {};

  const classNames: string = cx('h-12 px-4 py-1 bg-white text-sm text-grey-dark whitespace-nowrap align-middle group', className);
  const colKey: Nullable<string> = key ? `col-${key}` : null;
  const cellStyle: CSSProperties = {
    ...style,
    minWidth: width,
    maxWidth: width
  };

  return (
    <td {...rest} className={classNames} data-component="table-cell" data-testid={colKey} style={cellStyle}>
      <div className="flex items-center justify-between">
        {children}
        {onEdit && (
          <div className="opacity-0 group-hover:opacity-100 duration-500 cursor-pointer ml-2 text-sm" onClick={onEdit}>
            <Icon icon="zicon-edit" />
          </div>
        )}
      </div>
    </td>
  );
};

export default TableCell;
