import { isPlainObject, omit } from 'lodash';
import moment, { Moment } from 'moment';

import type { Address, NetworksAssignable } from '@zen/Networks';
import type { Customer } from '@zen/Orders/types';
import {
  BookingStep,
  BookingUpcomingEventEnum,
  Country,
  CustomsOnlyFilterEnum,
  FilterDescription,
  IncotermsValue,
  IssuesFilterInput,
  ModeOfTransport,
  NetworksOrgLoc,
  ShipmentFilterOption,
  ShipmentFilters,
  ShipmentFilterStageWithin,
  ShipmentFilterStatus,
  ShipmentsFilter,
  StageValue,
  WithinTimeRangeInput
} from '@zen/Shipments/types';
import type { DateRangeInput } from '@zen/types';
import { getDateRangeInput } from '@zen/utils/date';
import { omitEmptyValues } from '@zen/utils/Filtering/helpers';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type {
  GetWithinTimeRangeProps,
  NetworkAssignments,
  ShipmentFiltersCapabilities,
  ShipmentFilterVariables,
  ShipmentStatusFilterInput
} from './types';

export const withinDaysFilterOptions: ShipmentFilterOption<BookingUpcomingEventEnum>[] = [
  { value: BookingUpcomingEventEnum.ARRIVAL, label: 'Arriving at terminal' },
  { value: BookingUpcomingEventEnum.DEPARTURE, label: 'Departing from terminal' },
  { value: BookingUpcomingEventEnum.COLLECTION, label: 'Collecting' },
  { value: BookingUpcomingEventEnum.DELIVERY, label: 'Delivering' }
];

export const customsOnlyOptions: ShipmentFilterOption<CustomsOnlyFilterEnum>[] = [
  { value: CustomsOnlyFilterEnum.SHOW_CUSTOMS_ONLY, label: 'Customs only' },
  { value: CustomsOnlyFilterEnum.SHOW_NOT_CUSTOMS_ONLY, label: 'Not customs only' }
];

export const stageFilterOptions: ShipmentFilterOption<ShipmentFilterStatus>[] = [
  { value: ShipmentFilterStatus.ACTIVE, label: 'Active' },
  { value: ShipmentFilterStatus.DELIVERED, label: 'Delivered' },
  { value: ShipmentFilterStatus.CONFIRMED, label: 'Confirmed' },
  { value: ShipmentFilterStatus.IN_TRANSIT, label: 'In transit' },
  { value: ShipmentFilterStatus.PENDING, label: 'Pending' }
];

export const incotermsOptions: ShipmentFilterOption<IncotermsValue>[] = [
  { value: IncotermsValue.CFR, label: 'CFR' },
  { value: IncotermsValue.CIF, label: 'CIF' },
  { value: IncotermsValue.CIP, label: 'CIP' },
  { value: IncotermsValue.CPT, label: 'CPT' },
  { value: IncotermsValue.DAP, label: 'DAP' },
  { value: IncotermsValue.DAT, label: 'DAT' },
  { value: IncotermsValue.DDP, label: 'DDP' },
  { value: IncotermsValue.EXWORKS, label: 'EXWORKS' },
  { value: IncotermsValue.FAS, label: 'FAS' },
  { value: IncotermsValue.FCA, label: 'FCA' },
  { value: IncotermsValue.FOB, label: 'FOB' }
];

export const stageOptions: ShipmentFilterOption<StageValue>[] = [
  { value: StageValue.BOOKING_REQUESTED, label: 'Booking requested' },
  { value: StageValue.QUOTE_REQUESTED, label: 'Quote requested' },
  { value: StageValue.PENDING, label: 'Booking Received' },
  { value: StageValue.BOOKED, label: 'Booking Confirmed' },
  { value: StageValue.CONTAINER_OUT, label: 'On Route to Pick Up' },
  { value: StageValue.DEPARTED_WAREHOUSE, label: 'Departed Warehouse' },
  { value: StageValue.IN_GATE, label: 'Arrived at Port of Origin' },
  { value: StageValue.CARGO_ABOARD, label: 'Cargo aboard' },
  { value: StageValue.DEPARTED_POL, label: 'Departed Port of Origin' },
  { value: StageValue.ARRIVED_POD, label: 'Arrived at Port of Destination' },
  { value: StageValue.DISCHARGED, label: 'Unloaded' },
  { value: StageValue.ON_ROUTE_TO_FINAL_DESTINATION, label: 'On Route to Final Destination' },
  { value: StageValue.ARRIVED, label: 'Shipment Delivered' }
];

export const daysFilterOptions: ShipmentFilterOption<ShipmentFilterStageWithin>[] = [
  { value: ShipmentFilterStageWithin.SPECIFIC_DATE, label: 'Specific date' },
  { value: ShipmentFilterStageWithin.DATE_RANGE, label: 'Date range' },
  { value: ShipmentFilterStageWithin.YESTERDAY, label: 'Yesterday' },
  { value: ShipmentFilterStageWithin.NEXT_1_DAY, label: 'Next 1 day' },
  { value: ShipmentFilterStageWithin.NEXT_3_DAYS, label: 'Next 3 days' },
  { value: ShipmentFilterStageWithin.NEXT_7_DAYS, label: 'Next 7 days' },
  { value: ShipmentFilterStageWithin.NEXT_14_DAYS, label: 'Next 14 days' },
  { value: ShipmentFilterStageWithin.NEXT_21_DAYS, label: 'Next 21 days' },
  { value: ShipmentFilterStageWithin.NEXT_28_DAYS, label: 'Next 28 days' }
];

export const initialFilters: Partial<ShipmentFilters> = {
  status: ShipmentFilterStatus.ACTIVE
};

export const emptyFilters: Partial<ShipmentFilters> = {
  status: undefined,
  transportModes: [],
  incoterms: [],
  stages: [],
  withinTimeDate: '',
  withinTimeRange: {
    startDate: '',
    endDate: ''
  },
  withinTimeStage: null,
  withinTimeDays: null
};

export const mapCustomer = (customer: Customer): ShipmentFilterOption<string> => ({
  label: customer.name || '',
  value: customer.uuid || ''
});

export const mapCountry = (country: Country): ShipmentFilterOption<string> => ({
  label: country.name,
  value: country.code
});

export const getAddress = ({ street, city, postalCodeOrZip }: Address): string => {
  return [street, city, postalCodeOrZip].filter(Boolean).join(', ');
};

export const mapContacts = ({ id, label }: NetworksAssignable): ShipmentFilterOption<string> => ({
  label: label.short,
  value: id
});

export const mapNetworksAssignable = ({ id, label, unlocode }: NetworksAssignable): ShipmentFilterOption<string> => ({
  label: label.long,
  value: unlocode || id
});

export const mapVesselNames = (vesselName: Nullable<string>): ShipmentFilterOption<string> => {
  const name: string = vesselName || '';

  return { label: name, value: name };
};

export const mapForwarders = ({ label, organisation }: NetworksOrgLoc): ShipmentFilterOption<string> => {
  return {
    label: label?.short || '',
    value: organisation?.id || ''
  };
};

export const mapIssues = (title: Nullable<string>): ShipmentFilterOption<string> => ({
  label: title || 'User created',
  // BE sends null as a value we convert that to empty string here and back to null when submitting
  value: title || ''
});

export type ShipmentFilterRestrictedStageWithin = Exclude<
  ShipmentFilterStageWithin,
  ShipmentFilterStageWithin.DATE_RANGE | ShipmentFilterStageWithin.SPECIFIC_DATE
>;

export const getShipmentFiltersDateRange = (stageWithin: ShipmentFilterRestrictedStageWithin): DateRangeInput => {
  const yesterday: Moment = moment().add(-1, 'days');

  const stageWithinMapping: Record<ShipmentFilterRestrictedStageWithin, DateRangeInput> = {
    [ShipmentFilterStageWithin.YESTERDAY]: getDateRangeInput(0, yesterday),
    [ShipmentFilterStageWithin.NEXT_1_DAY]: getDateRangeInput(1),
    [ShipmentFilterStageWithin.NEXT_3_DAYS]: getDateRangeInput(3),
    [ShipmentFilterStageWithin.NEXT_7_DAYS]: getDateRangeInput(7),
    [ShipmentFilterStageWithin.NEXT_14_DAYS]: getDateRangeInput(14),
    [ShipmentFilterStageWithin.NEXT_21_DAYS]: getDateRangeInput(21),
    [ShipmentFilterStageWithin.NEXT_28_DAYS]: getDateRangeInput(28)
  };

  return stageWithinMapping[stageWithin];
};

export const getWithinTimeRange = (values: GetWithinTimeRangeProps): WithinTimeRangeInput | undefined => {
  const { withinTimeStage: eventType, withinTimeDays, withinTimeDate, withinTimeRange } = values;

  if (!eventType || !withinTimeDays) {
    return undefined;
  }

  if (withinTimeDays === ShipmentFilterStageWithin.DATE_RANGE) {
    return {
      eventType,
      dateRange: withinTimeRange
    };
  }

  if (withinTimeDays === ShipmentFilterStageWithin.SPECIFIC_DATE) {
    return {
      eventType,
      dateRange: getDateRangeInput(0, moment(withinTimeDate))
    };
  }

  return {
    eventType,
    dateRange: getShipmentFiltersDateRange(withinTimeDays)
  };
};

export const getTransportModes = (transportModes: Nullable<ModeOfTransport[]>): ModeOfTransport[] | undefined => {
  return transportModes ? getFilterOptionArray<ModeOfTransport>(transportModes) : [];
};

export const getIsActive = (shipmentStatus: Optional<string>): boolean | undefined => {
  if (!shipmentStatus) {
    return undefined;
  }

  if (shipmentStatus === ShipmentFilterStatus.ACTIVE) {
    return true;
  }

  return false;
};

export const getShipmentStatus = (shipmentStatus: Optional<ShipmentFilterStatus>): Optional<ShipmentStatusFilterInput> => {
  switch (shipmentStatus) {
    case ShipmentFilterStatus.ACTIVE:
    case ShipmentFilterStatus.DELIVERED:
      return { active: getIsActive(shipmentStatus) };
    case ShipmentFilterStatus.IN_TRANSIT:
      return { showInTransit: true };

    case ShipmentFilterStatus.PENDING:
      return { withSteps: [BookingStep.PENDING] };
    case ShipmentFilterStatus.CONFIRMED:
      return { withSteps: [BookingStep.BOOKED] };
    default:
      return undefined;
  }
};

export const getFilterOptionArray = <T = string>(shipmentFilters: Optional<T[]>): T[] | undefined => {
  if (shipmentFilters && shipmentFilters.length > 0) {
    return shipmentFilters;
  }

  return undefined;
};

export const getIssuesFilterValue = (issueTitle: Optional<string>): IssuesFilterInput | undefined => {
  if (issueTitle === null || issueTitle === undefined) {
    return undefined;
  }

  const titleEq: Nullable<string> = issueTitle === '' ? null : issueTitle;

  return {
    titleEq,
    active: true
  };
};

const defaultFiltersValue: ShipmentsFilter = {
  customers: [],
  originCountries: [],
  destinationCountries: [],
  forwarders: [],
  issueTitles: [],
  vesselNames: [],
  canViewCustomerFilter: {
    value: false
  }
};

export const buildFilters = (
  filters: ShipmentsFilter | undefined = defaultFiltersValue,
  networkAssignments: NetworkAssignments,
  capabilities: ShipmentFiltersCapabilities = {}
): FilterDescription[] => {
  const { origins = [], consignors = [], consignees = [], destinations = [] } = networkAssignments;
  const {
    customers = [],
    originCountries = [],
    destinationCountries = [],
    forwarders = [],
    issueTitles = [],
    vesselNames = [],
    canViewCustomerFilter: { value: canViewCustomer }
  } = filters;

  return [
    ...(customers !== null && canViewCustomer
      ? [
          {
            isMultiSelect: true,
            name: 'customers',
            label: 'Customer',
            options: customers.map(mapCustomer)
          }
        ]
      : []),
    {
      isMultiSelect: true,
      name: 'originCountries',
      label: 'Origin Country',
      options: originCountries.map(mapCountry)
    },
    {
      isMultiSelect: true,
      name: 'origins',
      label: 'Origin',
      options: origins.map(mapNetworksAssignable)
    },
    {
      isMultiSelect: true,
      name: 'vesselNames',
      label: 'Vessel Names',
      options: vesselNames.map(mapVesselNames)
    },
    {
      isMultiSelect: true,
      name: 'destinationCountries',
      label: 'Destination Country',
      options: destinationCountries.map(mapCountry)
    },
    {
      isMultiSelect: true,
      name: 'destinations',
      label: 'Destination',
      options: destinations.map(mapNetworksAssignable)
    },
    {
      isMultiSelect: true,
      name: 'consignors',
      label: 'Consignor',
      options: consignors.map(mapContacts)
    },
    {
      isMultiSelect: true,
      name: 'consignees',
      label: 'Consignee',
      options: consignees.map(mapContacts)
    },
    ...(capabilities.canViewForwarder
      ? [
          {
            isMultiSelect: true,
            name: 'forwarders',
            label: 'Forwarder',
            options: forwarders.map(mapForwarders)
          }
        ]
      : []),
    {
      isMultiSelect: false,
      name: 'issueTitle',
      label: 'Issue types',
      options: issueTitles.map(mapIssues)
    }
  ];
};

export const countFilters = <T extends {}>(filters: T): number => {
  const countableFilters = omit(filters, '__typename');

  return Object.values(countableFilters).reduce((prev: number, curr) => {
    if (!curr || isPlainObject(curr)) {
      return prev;
    }

    if (Array.isArray(curr)) {
      return curr.length > 0 ? prev + 1 : prev;
    }

    return prev + 1;
  }, 0) as number;
};

export const prepareFilterVariables = (filters: ShipmentFilters): ShipmentFilterVariables => {
  const {
    status,
    transportModes,
    origins,
    destinations,
    consignees,
    consignors,
    forwarders,
    issueTitle,
    customers,
    originCountries,
    destinationCountries,
    vesselNames,
    incoterms,
    stages,
    withinTimeRange,
    withinTimeDate,
    withinTimeDays,
    withinTimeStage
  } = filters;

  return {
    ...getShipmentStatus(status),
    consignees: getFilterOptionArray(consignees),
    consignors: getFilterOptionArray(consignors),
    customers: getFilterOptionArray(customers),
    destinationCountries: getFilterOptionArray(destinationCountries),
    destinations: getFilterOptionArray(destinations),
    forwarders: getFilterOptionArray(forwarders),
    incoterms: getFilterOptionArray(incoterms),
    issues: getIssuesFilterValue(issueTitle),
    originCountries: getFilterOptionArray(originCountries),
    origins: getFilterOptionArray(origins),
    stages: getFilterOptionArray(stages),
    transportModes: getTransportModes(transportModes),
    vesselNames: getFilterOptionArray(vesselNames),
    withinTimeRange: getWithinTimeRange({ withinTimeRange, withinTimeDays, withinTimeStage, withinTimeDate })
  };
};

export const removeEmptyShipmentFilters = (values: Partial<ShipmentFilters>): Partial<ShipmentFilters> => {
  const { issueTitle } = values;
  const consideredEmptyValues = issueTitle === '' ? { issueTitle } : {};

  return { ...omitEmptyValues(values), ...consideredEmptyValues };
};
