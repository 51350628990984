import pluralize from 'pluralize';

import DocumentActions from '@zen/Components/Documents/DocumentActions';
import type { Document } from '@zen/Components/Documents/types';
import SectionDescription from '@zen/Components/SectionDescription';
import { useUploadBookingDocumentMutation } from '@zen/Shipments/Documents/DocumentsContainer/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

interface ActionItemDocumentUploadProps {
  accountId: string;
  onSelect: () => void;
  selectedDocumentType: string;
  zencargoReference: string;
}

const ActionItemDocumentUpload = (props: ActionItemDocumentUploadProps) => {
  const { onSelect, selectedDocumentType, zencargoReference, accountId } = props;
  const { addError, addSuccess } = useNotification();

  const [uploadBookingDocument] = useUploadBookingDocumentMutation();

  const handleAdd = async (documents: Document[]): Promise<void> => {
    for (const document of documents) {
      const { description, documentType: bookingDocumentTypeName, uuid: id, permissions, attachmentId } = document;

      performMutation({
        mutationFn: () => {
          return uploadBookingDocument({
            variables: {
              input: {
                accountUuid: accountId,
                zencargoReference,
                description,
                bookingDocumentTypeName,
                id,
                permissions,
                attachmentId
              }
            },
            refetchQueries: ['getBookingDocuments', 'getIssuesAndActions']
          });
        },
        onError: () => addError(),
        onSuccess: () => {
          if (documents) {
            addSuccess(`We have received your ${pluralize('document', documents.length)}`);
          }
        }
      });
    }
    onSelect();
  };

  return (
    <div className="flex justify-between items-center">
      <SectionDescription text="Upload a document via the menu on the right." />
      <DocumentActions documentType={selectedDocumentType} onAdd={handleAdd} />
    </div>
  );
};

export default ActionItemDocumentUpload;
