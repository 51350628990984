import moment from 'moment';
import { FC, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import CollapsableElement from '@zen/Components/CollapsableElement';
import DatesDifference from '@zen/Components/DatesDifference';
import DateWithDefault from '@zen/Components/DateWithDefault';
import IndicatorIcon from '@zen/Components/Icons/IndicatorIcon';
import { getIndicatorType } from '@zen/Components/Icons/IndicatorIcon/IndicatorIcon';
import { IndicatorSize, IndicatorType } from '@zen/Components/Icons/IndicatorIcon/types';
import { Icon, Modal } from '@zen/DesignSystem';
import { productRoutes } from '@zen/Routes';
import { PurchaseOrderTab } from '@zen/Routes/products';
import DetailCardContent from '@zen/Shipment/components/DetailsCardContent';
import ExpandableDetailsCard from '@zen/Shipment/components/ExpandableDetailsCard';
import { getToday } from '@zen/utils/date';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Optional } from '@zen/utils/typescript';

import { CargoLot, CargoLotTracking, CargoLotTrackingAction, OrderLotCargoReadyDateHistory } from '../types';
import InlineCargoReadyDateForm from './InlineCargoReadyDateForm';
import { buildHistoryList } from './utils';

interface Props {
  lot: CargoLot;
}

const BookingOrderLot: FC<Props> = ({ lot }) => {
  const { product, quantityFulfilled, quantityOrdered, estimatedDeliveryDate, cargoReadyDate } = lot;
  const { check } = useGlobalPermissions();
  const [updating, setUpdating] = useState<boolean>(false);
  const { trackEvent } = useTracking();

  const canAccessProductsRoute: boolean = check('products.canAccessRoute');
  const productUrl: string = product
    ? productRoutes.productDetails.getUrl(product.id, PurchaseOrderTab.ORDER, getToday(), '12')
    : '';
  const type: IndicatorType = getIndicatorType(quantityFulfilled || 0, quantityOrdered || 0);
  const historyList: OrderLotCargoReadyDateHistory[] = buildHistoryList(cargoReadyDate);
  const referenceDates: Optional<string>[] = historyList.map((historyItem: OrderLotCargoReadyDateHistory) => historyItem.date);
  const initialCargoReadyDate: Optional<string> = cargoReadyDate?.history?.[0]?.date || cargoReadyDate?.date;
  const shouldRenderOriginalCrd: boolean =
    initialCargoReadyDate && cargoReadyDate?.date ? !moment(initialCargoReadyDate).isSame(cargoReadyDate?.date) : false;

  const renderProductName = (): ReactNode => {
    const productInfo: string = `${quantityFulfilled}/${quantityOrdered} x ${product?.name}`;

    if (!canAccessProductsRoute) {
      return <div>{productInfo}</div>;
    }

    return (
      <Link className="text-navy-base text-sm font-bold hover:underline" data-testid="order-lot-link" to={productUrl}>
        {productInfo}
      </Link>
    );
  };

  const renderDate = (date: Optional<string>): ReactNode => <DateWithDefault date={date} />;

  const renderMainDateDifference = () => {
    if (initialCargoReadyDate && cargoReadyDate?.date) {
      return <DatesDifference date={cargoReadyDate.date} referenceDate={initialCargoReadyDate} />;
    }
  };

  const handleUpdateSuccess = () => {
    trackEvent({
      category: CargoLotTracking,
      action: CargoLotTrackingAction.INLINE_SCHEDULE_UPDATE,
      label: CargoLotTracking
    });

    setUpdating(false);
  };

  const historyItems: ReactNode[] = historyList.map((historyItem: OrderLotCargoReadyDateHistory, i: number): ReactNode => {
    const referenceDate: Optional<string> = referenceDates[i + 1] || initialCargoReadyDate;
    const { reasonForChange, date } = historyItem;
    const dateDifference: ReactNode | undefined = date && referenceDate && (
      <DatesDifference date={date} noDifferenceMessage="0 days" referenceDate={referenceDate} />
    );

    return (
      <DetailCardContent
        key={i}
        className="text-xs text-grey-dark py-2"
        date={renderDate(date)}
        dateDifference={dateDifference}
        detailLabel="Updated to"
        title={
          <>
            <div className="font-bold">{reasonForChange?.category}</div>
            <div className="mt-1">{reasonForChange?.description}</div>
          </>
        }
      />
    );
  });

  const renderSubCardSection = (isHovered: boolean): ReactNode => (
    <CollapsableElement duration={300} isOpened={isHovered}>
      <DetailCardContent
        date={
          <>
            {shouldRenderOriginalCrd && <div>{renderDate(initialCargoReadyDate)}</div>}
            <div>{renderDate(estimatedDeliveryDate)}</div>
          </>
        }
        detailLabel={
          <>
            {shouldRenderOriginalCrd && <div>Original CRD</div>}
            <div>Delivery estimate</div>
          </>
        }
        title={<div className="text-grey-base text-xs">SKU: {product?.skuCode}</div>}
      />
    </CollapsableElement>
  );

  const mainCardSection: ReactNode = (
    <DetailCardContent
      action={
        <Icon
          className="text-base opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          icon="zicon-edit"
          interactive={true}
          onClick={() => setUpdating(true)}
        />
      }
      date={<div className="font-bold">{renderDate(cargoReadyDate?.date)}</div>}
      dateDifference={renderMainDateDifference()}
      detailLabel={<div className="font-bold">Cargo ready date</div>}
      title={renderProductName()}
    />
  );

  const formTitle: string = !cargoReadyDate?.date ? 'Add cargo ready date' : 'Update cargo ready date estimate';

  return (
    <>
      <ExpandableDetailsCard
        animationDelay={500}
        details={historyItems}
        indicator={
          <div className="absolute top-3 left-4">
            <IndicatorIcon size={IndicatorSize.MEDIUM} type={type} />
          </div>
        }
        mainCardSection={mainCardSection}
        opened={updating}
        renderSubCardSection={renderSubCardSection}
      />
      <Modal isOpen={updating} modalOverflowY="visible" onClose={() => setUpdating(false)} title={formTitle}>
        <InlineCargoReadyDateForm
          isInitialEntry={!cargoReadyDate?.date}
          lotId={lot.id}
          onCancel={() => setUpdating(false)}
          onSuccess={handleUpdateSuccess}
        />
      </Modal>
    </>
  );
};

export default BookingOrderLot;
