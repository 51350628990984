import type { FC } from 'react';

import SkeletonLoading from '@zen/Components/SkeletonLoading';

interface Props {
  count?: number;
}
const RepeatedSkeletons: FC<Props> = ({ count = 1 }) => (
  <>
    {[...Array(count)].map((_, i) => (
      <div key={i}>
        <SkeletonLoading className="my-2" height={14} width="w-20" />
        <SkeletonLoading height={14} width="w-28" />
      </div>
    ))}
  </>
);
const SkeletonShipmentDetails: FC = () => {
  return (
    <div className="relative bg-white shadow-md px-8 py-2 rounded z-10" data-testid="skeleton-shipment">
      <div className="flex justify-between my-5 mx-2 w-2/5">
        <SkeletonLoading height={14} width="w-28" />
        <SkeletonLoading height={14} width="w-28" />
        <SkeletonLoading circle={true} height={14} width="w-3.5" />
        <SkeletonLoading height={14} width="w-28" />
      </div>
      <div className="py-4 border-t border-solid border-grey-lighter">
        <RepeatedSkeletons />
      </div>
      <div className="flex py-6 border-t border-solid border-grey-lighter">
        <div className="w-2/5 grid grid-cols-2 gap-y-5 gap-x-2 auto-rows-min">
          <RepeatedSkeletons count={6} />
        </div>
        <div className="w-1/5 grid grid-cols-1 pl-4 pr-5 mx-4 gap-y-5 auto-rows-min border-r border-l border-solid border-grey-lighter">
          <RepeatedSkeletons count={3} />
        </div>
        <div className="w-2/5 grid grid-cols-2 gap-y-7 gap-x-2 auto-rows-min">
          <RepeatedSkeletons count={4} />
        </div>
      </div>
    </div>
  );
};

export default SkeletonShipmentDetails;
