import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { get } from 'lodash';

import type { Document } from '@zen/Components/Documents/types';

import { addFlashMessage, FlashType } from '../Components/NotificationProvider/actions';
import csrfTokenManager from './csrfToken';

interface RollbarType {
  error: (message: string, error: Error) => void;
}

interface UpdateEstimatedDeliveryDatePayload {
  accountUuid: string;
  delayReason: string;
  description: string;
  uedd: string;
  zencargoReference: string;
}

interface EstimatedDeliveryDatePayload {
  accountUuid: string;
  edd: string;
  zencargoReference: string;
}

export interface AcceptInvitationInput {
  password: string;
  passwordConfirmation: string;
  token: string;
}
declare global {
  interface Window {
    Rollbar: RollbarType;
  }
}

interface Api extends AxiosInstance {
  acceptInvitation: ({
    password,
    passwordConfirmation,
    token
  }: {
    password: string;
    passwordConfirmation: string;
    token: string;
  }) => Promise<AxiosResponse<unknown>>;
  addEstimatedDeliveryDate: ({
    zencargoReference,
    edd,
    accountUuid
  }: EstimatedDeliveryDatePayload) => Promise<AxiosResponse<unknown>>;
  approveActionItem: ({ zencargo_reference, id }: { id: string; zencargo_reference: string }) => Promise<AxiosResponse<unknown>>;
  deleteDocument: ({ id, accountUuid }: { accountUuid: string; id: string }) => Promise<AxiosResponse<unknown>>;
  deleteTradeLane: ({ id }: { id: number }) => Promise<AxiosResponse<unknown>>;
  findReference: (query: string) => Promise<AxiosResponse<unknown>>;
  getAccounts: () => Promise<string>;
  getBillingDetails: () => Promise<AxiosResponse<unknown>>;
  getCompanyDetails: () => Promise<AxiosResponse<unknown>>;
  getDocumentTypes: () => Promise<unknown>;
  getDocuments: ({ id, accountUuid }: { accountUuid: string; id: string }) => Promise<AxiosResponse<unknown>>;
  getTradeLanes: () => Promise<AxiosResponse<unknown>>;
  logIn: ({ email, password }: { email: string; password: string }) => Promise<AxiosResponse<unknown>>;
  logOut: () => Promise<AxiosResponse<unknown>>;
  postTradeLane: ({ payload }: { payload: object }) => Promise<AxiosResponse<unknown>>;
  queryPorts: (path: string, query: string, limit: number) => Promise<AxiosResponse<unknown>>;
  submitBillingDetails: ({ payload }: { payload: object }) => Promise<AxiosResponse<unknown>>;
  submitChangePassword: ({ params }: { params: object }) => Promise<AxiosResponse<unknown>>;
  submitCompanyDetails: ({ payload }: { payload: object }) => Promise<AxiosResponse<unknown>>;
  submitForgotPassword: ({ email }: { email: string }) => Promise<AxiosResponse<unknown>>;
  submitResetPassword: ({
    password,
    passwordConfirmation,
    token
  }: {
    password: string;
    passwordConfirmation: string;
    token: string;
  }) => Promise<AxiosResponse<unknown>>;
  updateDocument: ({
    id,
    accountUuid,
    params
  }: {
    accountUuid: string;
    id: string;
    params: Pick<Document, 'description' | 'documentType' | 'permissions'>;
  }) => Promise<AxiosResponse<unknown>>;
  updateEstimatedDeliveryDate: ({
    zencargoReference,
    accountUuid,
    uedd,
    delayReason,
    description
  }: UpdateEstimatedDeliveryDatePayload) => Promise<AxiosResponse<{ success: boolean }>>;
  updateShipmentDetails: (shipment: object) => Promise<AxiosResponse<unknown>>;
}

const api: Api = Object.assign(
  axios.create({
    timeout: 10000
  }),
  {
    getAccounts: (): Promise<string> => {
      return api.get('/api/accounts/').then((response) => {
        if (response?.data?.accounts?.length) {
          const { uuid } = response.data.accounts[0];

          return uuid;
        }

        return ''; // TODO: in te future, this should be changed to return null, and nullability should be propagated
      });
    },

    getTradeLanes: () => api.getAccounts().then((account_uuid) => api.get('/api/trade_lanes', { params: { account_uuid } })),

    postTradeLane: ({ payload }: { payload: object }) =>
      api.getAccounts().then((account_uuid) =>
        api.post('/api/trade_lanes', {
          ...payload,
          account_uuid
        })
      ),

    deleteTradeLane: ({ id }: { id: number }) =>
      api.getAccounts().then((account_uuid) => api.delete(`/api/trade_lanes/${id}`, { params: { account_uuid } })),

    acceptInvitation: ({ password, passwordConfirmation, token }: AcceptInvitationInput) =>
      api.post(`/api/invitations/${token}/accept`, {
        password,
        password_confirmation: passwordConfirmation
      }),

    logIn: ({ email, password }: { email: string; password: string }) =>
      api.post('/api/sessions', {
        user: {
          email,
          password
        }
      }),

    logOut: () => api.delete('/api/sessions'),

    getCompanyDetails: () => api.getAccounts().then((account_uuid) => api.get(`/api/accounts/${account_uuid}/company_details`)),

    submitCompanyDetails: ({ payload }: { payload: object }) =>
      api.getAccounts().then((account_uuid) =>
        api.post(`/api/accounts/${account_uuid}/company_details`, {
          ...payload
        })
      ),

    getBillingDetails: () => api.getAccounts().then((account_uuid) => api.get(`/api/accounts/${account_uuid}/billing_details`)),

    submitBillingDetails: ({ payload }: { payload: object }) =>
      api.getAccounts().then((account_uuid) =>
        api.post(`/api/accounts/${account_uuid}/billing_details`, {
          ...payload
        })
      ),

    addEstimatedDeliveryDate: ({
      zencargoReference,
      edd,
      accountUuid
    }: {
      accountUuid: string;
      edd: string;
      zencargoReference: string;
    }) =>
      api.post(`/backoffice/accounts/${accountUuid}/shipment_edd/${zencargoReference}`, undefined, {
        params: { 'shipment[edd]': edd }
      }),

    updateEstimatedDeliveryDate: ({
      zencargoReference,
      uedd,
      accountUuid,
      delayReason,
      description
    }: UpdateEstimatedDeliveryDatePayload) =>
      api.patch(`/backoffice/accounts/${accountUuid}/shipment_edd/${zencargoReference}`, undefined, {
        params: {
          'shipment[uedd]': uedd,
          'shipment[delay_reason_type]': delayReason,
          'shipment[delay_reason_description]': description
        }
      }),

    submitForgotPassword: ({ email }: { email: string }) =>
      api.post('/api/password', {
        user: { email }
      }),

    submitResetPassword: ({
      password,
      passwordConfirmation,
      token
    }: {
      password: string;
      passwordConfirmation: string;
      token: string;
    }) =>
      api.put('/api/password', {
        user: {
          password,
          password_confirmation: passwordConfirmation,
          reset_password_token: token
        }
      }),

    submitChangePassword: ({ params }: { params: object }) =>
      api.patch('/api/change_password', {
        ...params
      }),

    getDocuments: ({ id, accountUuid }: { accountUuid: string; id: string }) =>
      api.get(`/api/${accountUuid}/booking_documents`, {
        params: {
          zencargo_reference: id
        }
      }),

    updateDocument: ({
      id,
      accountUuid,
      params
    }: {
      accountUuid: string;
      id: string;
      params: Pick<Document, 'description' | 'documentType' | 'permissions'>;
    }) =>
      api.patch(`/api/${accountUuid}/booking_documents/${id}`, {
        document: {
          description: params.description,
          document_type: params.documentType,
          permissions: params.permissions
        }
      }),

    deleteDocument: ({ id, accountUuid }: { accountUuid: string; id: string }) =>
      api.delete(`/api/${accountUuid}/booking_documents/${id}`),

    approveActionItem: ({ zencargo_reference, id }: { id: string; zencargo_reference: string }) =>
      api.put(`/api/shipments/${zencargo_reference}/action_items/${id}/approve`),

    getDocumentTypes: () => api.get('/api/booking_document_types').then((response) => response.data.booking_document_types),

    findReference: (query: string) => api.get(`/backoffice/references?query=${query}`).then((response) => response.data.results),

    updateShipmentDetails: (shipment: object) =>
      api.getAccounts().then((account_uuid) =>
        api.put(`/api/${account_uuid}/shipment_details/client_reference`, {
          shipment_detail: {
            ...shipment
          }
        })
      ),

    queryPorts: (path: string, query: string, limit: number) => api.get(`/${path}?search=${query}&limit=${limit}`)
  }
);

api.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      'X-CSRF-Token': csrfTokenManager.get()
    }
  }),
  (error) => {
    window.Rollbar.error('Request Error', error);

    return Promise.reject(error);
  }
);

export const configure = () => {
  // eslint-disable-next-line consistent-return
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = get(error, 'response.status', false);
      const message = get(error, 'response.data.message', false);

      if (status === 401 && message === 'Invalid token') {
        addFlashMessage({
          type: FlashType.ERROR,
          text: 'Your session has expired, please log in again.',
          options: {
            toastId: 'session_expired'
          }
        });
      } else if (status === 403) {
        addFlashMessage({
          type: FlashType.ERROR,
          text: "You're not authorised to access this resource.",
          options: {
            toastId: 'not_authorized'
          }
        });
      } else if (status === 503) {
        window.location.reload();
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export default api;
