import { createContext, useContext } from 'react';

import type { NewCargo } from '@zen/Cargo';
import type { ScheduleItemType } from '@zen/CollectionAndDelivery/Details/types';
import type { Nullable } from '@zen/utils/typescript';

interface MultiCargoSchedule {
  active: boolean;
  cargoList: NewCargo[];
  handleActiveToggle: (value: boolean) => void;
  hasError: (id: string) => boolean;
  isCargoSelected: (id: string) => boolean;
  resetCargos: () => void;
  scheduleType: Nullable<ScheduleItemType>;
  setCargos: (cargos: NewCargo[]) => void;
  setErrorIds: (errors: string[]) => void;
  setScheduleType: (type: Nullable<ScheduleItemType>) => void;
  toggleCargo: (Cargo: NewCargo, scheduleType: ScheduleItemType) => void;
}

const initialContext = {
  cargoList: [],
  toggleCargo: () => {},
  setScheduleType: () => {},
  scheduleType: null,
  active: false,
  handleActiveToggle: () => {},
  isCargoSelected: () => false,
  hasError: () => false,
  setErrorIds: () => {},
  resetCargos: () => {},
  setCargos: () => {}
};

const MultiCargoScheduleContext = createContext<MultiCargoSchedule>(initialContext);

export const useMultiCargoSchedule = () => useContext<MultiCargoSchedule>(MultiCargoScheduleContext);

export default MultiCargoScheduleContext;
