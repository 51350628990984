import { lowerCase, upperFirst } from 'lodash';

import { browserLanguage } from './languageSettings';

export const formatNumber = (value: number, maximumFractionDigits: number = 2) =>
  new Intl.NumberFormat(browserLanguage(), {
    maximumFractionDigits,
    minimumFractionDigits: 0
  }).format(value);

export const formatCurrency = (
  currency: string,
  value: number,
  currencyDisplay = 'symbol',
  disableFraction = false,
  locale = browserLanguage()
) => {
  const fractionConfig = disableFraction ? { maximumFractionDigits: 0, minimumFractionDigits: 0 } : {};

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay,
    ...fractionConfig
  }).format(value);
};

export const FORMAT_DATE_TRANSFERABLE = 'YYYY-MM-DD'; // eg. 14th April 2020, 11:36am
export const FORMAT_DATE_TIME_TRANSFERABLE = 'YYYY-MM-DDTHH:mm:ss.sssZ'; // ISO 8601 Extended Format

export type ContactName = { firstName: string; lastName: string };

export const getFullName = ({ firstName, lastName }: ContactName) => `${firstName} ${lastName}`;

export const truncateText = (text: string, maxLength: number) => {
  if (maxLength > text.length) {
    return text;
  }

  const slicedText = text.slice(0, maxLength);

  return `${slicedText}...`;
};

export const removeSpecialCharactersAndUpperFirst = (text: string): string => {
  return upperFirst(lowerCase(text));
};
