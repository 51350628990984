import { FC, useState } from 'react';

import Slideout from '@zen/Components/Slideout';
import { Headline, IconButton } from '@zen/DesignSystem';
import OrgLocForm from '@zen/Networks/Form/OrgLocForm';
import type { NetworksOrgLoc } from '@zen/Networks/types';

interface Props {
  accountUuid: string;
  className?: string;
  handleAdd?: (orgLoc: NetworksOrgLoc) => void;
  title: string;
}

const NetworksContactAddButton: FC<Props> = (props) => {
  const { accountUuid, className, handleAdd = null, title } = props;
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleSuccess = (orgLoc: NetworksOrgLoc) => {
    setShowForm(false);
    handleAdd?.(orgLoc);
  };

  return (
    <>
      <div className={className}>
        <IconButton icon="zicon-add-user" onClick={() => setShowForm(true)} title={title} variant="secondary" />
      </div>
      <Slideout onOutsideClick={() => setShowForm(false)} overlay={true} show={showForm}>
        <div className="py-4 px-6 h-screen overflow-y-scroll">
          <Headline className="mb-6" level={3}>
            {title}
          </Headline>
          <OrgLocForm accountUuid={accountUuid} handleAdd={handleSuccess} handleCancel={() => setShowForm(false)} />
        </div>
      </Slideout>
    </>
  );
};

export default NetworksContactAddButton;
