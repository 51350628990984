import { FC, ReactElement, ReactNode, useState } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Card from '@zen/Components/Card';
import type { Document } from '@zen/Components/Documents/types';
import { BookingDocumentState } from '@zen/Components/Documents/types';
import InformationIcon from '@zen/Components/InformationIcon';
import RolePermissionList from '@zen/Components/RolePermissions/RolePermissionList';
import type { RolePermissionMap } from '@zen/Components/RolePermissions/types';
import type { MenuItemType } from '@zen/DesignSystem';
import { ContextMenu, Dialog, IconButton, Option, TextLink } from '@zen/DesignSystem';
import loadable from '@zen/utils/component/loadable';
import { formatDateTime } from '@zen/utils/dateTime';
import useTracking from '@zen/utils/hooks/useTracking';

import { getFileExtension } from '../../helpers';
import EditDocumentForm from './EditDocumentForm';

const DocumentPreviewModal = loadable(() => import('./DocumentPreviewModal'));

const getLabel = (documentTypes: Option<string>[], documentType: string | undefined) => {
  const document: Option<string> | undefined = documentTypes.find(({ value }) => value === documentType);

  if (!document) {
    return null;
  }

  return document.label;
};

export interface Props {
  document: Document;
  documentTypes: Option<string>[];
  onDelete: (id: string) => void;
  onEdit: (document: Document) => void;
  showDocumentDate?: boolean;
}

const DocumentItem: FC<Props> = ({ document, documentTypes, onEdit, onDelete, showDocumentDate = true }) => {
  const { assetUrl, createdAt, description, documentType, uuid, actionItemDocument } = document;

  const { trackEvent } = useTracking();
  const { check } = useGlobalPermissions();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const canReadPermissions: boolean = check('bookings.canViewDocumentPermissions');
  const fileExtension: string = getFileExtension(assetUrl);

  const documentHasError: boolean = !!document?.error;
  const documentProperlyUploaded: boolean = document?.state === BookingDocumentState.UPLOADED && !documentHasError;
  const canPreviewDocument: boolean = documentProperlyUploaded;
  const canEditDocument: boolean = documentProperlyUploaded;
  const canDownloadDocument: boolean = documentProperlyUploaded;

  const handleDocumentPreview = (): void => {
    setIsPreviewModalOpen(true);

    trackEvent({
      category: 'Documents',
      action: 'PreviewDocument',
      label: fileExtension,
      properties: {
        documentId: uuid,
        fileExtension
      }
    });
  };

  const dropdownPreviewItem: MenuItemType = {
    label: 'Preview',
    onClick: handleDocumentPreview
  };

  const downloadItem: MenuItemType = {
    label: 'Download',
    onClick: () => window.open(`${assetUrl}?disposition=attachment`, '_blank')
  };

  const dropdownEditItem: MenuItemType = {
    label: 'Edit',
    onClick: () => setIsEditMode(true)
  };

  const dropdownDeleteItem: MenuItemType = {
    label: 'Delete',
    onClick: () => setIsDeleteModalOpen(true)
  };

  const dropdownItems: MenuItemType[] = [
    ...(canPreviewDocument ? [dropdownPreviewItem] : []),
    ...(canDownloadDocument ? [downloadItem] : []),
    ...(canEditDocument ? [dropdownEditItem] : []),
    dropdownDeleteItem
  ];

  const handleSubmit = (values: Document): void => {
    onEdit(values);
  };

  const handleDelete = (): void => {
    setIsDeleteModalOpen(false);
    onDelete(uuid);
  };

  const handleDeleteSuccess = (): void => {
    setIsEditMode(false);
  };

  if (isEditMode) {
    return (
      <EditDocumentForm
        documentTypes={documentTypes}
        initialValues={document}
        onCancel={() => setIsEditMode(false)}
        onSubmit={handleSubmit}
        onSuccess={handleDeleteSuccess}
      />
    );
  }

  const errorTooltipContent: ReactNode = (
    <div className="text-red-base" data-testid="error-tooltip-content">
      {document.error}
    </div>
  );

  const renderDocumentName = (documentName: string): ReactNode => {
    if (canPreviewDocument) {
      return (
        <TextLink className="font-bold" data-testid="document-preview-link" onClick={() => setIsPreviewModalOpen(true)}>
          {documentName}
        </TextLink>
      );
    }

    return <div className="font-bold text-grey-dark truncate">{documentName}</div>;
  };

  const renderContextMenu = (): ReactElement => {
    return (
      <ContextMenu items={dropdownItems}>
        <IconButton className="text-lg" icon="zicon-dots" size="small" variant="tertiary" />
      </ContextMenu>
    );
  };

  return (
    <>
      <Card className="mt-4" rightIcon={renderContextMenu()} title={renderDocumentName(description)}>
        <div className="flex items-center">
          <span>{actionItemDocument ? documentType : getLabel(documentTypes, documentType)}</span>
          {showDocumentDate && !documentHasError && (
            <>
              <span className="text-grey-light px-2">&bull;</span>
              <div className="text-grey-base" data-testid="creation-date">
                Uploaded on {createdAt && formatDateTime(createdAt, 'D_MMMM_YYYY_HH_MM')}
              </div>
            </>
          )}
          {documentHasError && <InformationIcon className="text-red-base text-base ml-2" tooltipContent={errorTooltipContent} />}
        </div>
        {canReadPermissions && (
          <RolePermissionList
            className="mt-4"
            rolePermissions={document.permissions as RolePermissionMap}
            showAdminPermission={false}
          />
        )}
      </Card>
      {canPreviewDocument && (
        <DocumentPreviewModal
          fileTitle={document.description}
          fileUrl={document.assetUrl}
          isOpen={isPreviewModalOpen}
          onClosePreview={() => setIsPreviewModalOpen(false)}
        />
      )}
      <Dialog
        confirmLabel="Delete"
        header="Are you sure you want to delete this document?"
        isOpen={isDeleteModalOpen}
        message="Deleting will remove visibility and access to this document for all parties"
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default DocumentItem;
