import type { ReactNode } from 'react';
import { components, NoticeProps } from 'react-select';

import { InlineEmptyState } from '@zen/DesignSystem';

const searchNoResultsText: string = 'No results found matching your query.';
const noOptionsAvailable: string = 'No options available.';

const getNoOptionsMessage =
  <T extends {}, K extends boolean>(suggestion: ReactNode = null, isAsync: boolean = false) =>
  (props: NoticeProps<T, K>) => {
    return (
      <components.NoOptionsMessage {...props}>
        <InlineEmptyState>
          {isAsync || props.options.length ? searchNoResultsText : noOptionsAvailable}
          {suggestion}
        </InlineEmptyState>
      </components.NoOptionsMessage>
    );
  };

export default getNoOptionsMessage;
