import type { FC } from 'react';

import { DropZone } from '@zen/DesignSystem';
import useAccount from '@zen/utils/hooks/useAccount';
import { UploadedFileData, useDirectUploads } from '@zen/utils/hooks/useDirectUploads';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useProductsUploadProductsMutation } from './graphql';

interface Props {
  onComplete: () => void;
}

const ProductsCsvImport: FC<Props> = (props) => {
  const { onComplete } = props;
  const { addSuccess, addError } = useNotification();
  const uploadFile = useDirectUploads();
  const [uploadProducts] = useProductsUploadProductsMutation();
  const { accountUuid } = useAccount();

  const onDrop = (files: File[]): void => {
    if (files.length > 1) {
      addError('Please upload a single CSV file');

      return;
    }

    uploadFile({ file: files[0], onError: addError }).then((uploadedFileData: UploadedFileData) => {
      const uploadInput = { accountId: accountUuid, signedBlobId: uploadedFileData.signedBlobId };

      performMutation({
        mutationFn: () => uploadProducts({ variables: { input: uploadInput } }),
        onError: () => addError('There was a problem uploading your file. Please try again.'),
        onSuccess: () => {
          addSuccess('Your CSV is being uploaded, we will email you when your products are ready to view.');
          onComplete();
        }
      });
    });
  };

  return (
    <div data-testid="csv-dropzone">
      <p className="pb-5">Please upload a single CSV file with the products you would like to import</p>
      <DropZone onDrop={onDrop} />
    </div>
  );
};

export default ProductsCsvImport;
