import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateAccountCompanyDetailsMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateCompanyDetailsInput;
}>;

export type UpdateAccountCompanyDetailsMutationResult = { __typename?: 'Mutation' } & {
  accountUpdateCompanyDetails?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateCompanyDetailsPayload' } & {
      account: { __typename?: 'Account' } & Pick<
        GraphQLTypes.Account,
        'uuid' | 'name' | 'companyEmailAddress' | 'companyPhoneNumber' | 'eoriNumber' | 'vat'
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const UpdateAccountCompanyDetailsDocument = /* #__PURE__ */ gql`
  mutation updateAccountCompanyDetails($input: UpdateCompanyDetailsInput!) {
    accountUpdateCompanyDetails(input: $input) {
      account {
        uuid
        name
        companyEmailAddress
        companyPhoneNumber
        eoriNumber
        vat
      }
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateAccountCompanyDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountCompanyDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountCompanyDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountCompanyDetailsMutation, { data, loading, error }] = useUpdateAccountCompanyDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountCompanyDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountCompanyDetailsMutationResult,
    UpdateAccountCompanyDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateAccountCompanyDetailsMutationResult, UpdateAccountCompanyDetailsMutationVariables>(
    UpdateAccountCompanyDetailsDocument,
    options
  );
}
export type UpdateAccountCompanyDetailsMutationHookResult = ReturnType<typeof useUpdateAccountCompanyDetailsMutation>;
