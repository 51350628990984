import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import type { AccountDetails, AccountDetailsItem } from '../types';
import CompanyDetailsForm from './CompanyDetailsForm';
import { useUpdateAccountCompanyDetailsMutation } from './graphql';

interface Props {
  accountDetails: AccountDetailsItem;
  refetchAccountDetails: () => void;
}

const CompanyDetails: FC<Props> = ({ accountDetails, refetchAccountDetails }) => {
  const { addSuccess, addError } = useNotification();
  const [updateAccountCompanyDetails] = useUpdateAccountCompanyDetailsMutation();

  const handleUpdateAccountCompanyDetails = (values: AccountDetails): Promise<IOkOrErrorResult> => {
    const { name, companyEmailAddress, companyPhoneNumber, eoriNumber, vat } = values;

    const id: string = accountDetails.uuid;

    return performFormMutation({
      mutationFn: () =>
        updateAccountCompanyDetails({
          variables: {
            input: {
              id,
              registeredCompanyName: name,
              companyEmailAddress,
              companyPhoneNumber,
              eoriNumber,
              vat
            }
          }
        }),
      onError: addError
    });
  };

  const handleOnSuccess = (): void => {
    refetchAccountDetails();
    addSuccess('Company details updated');
  };

  return (
    <CompanyDetailsForm initialValues={accountDetails} onSubmit={handleUpdateAccountCompanyDetails} onSuccess={handleOnSuccess} />
  );
};

export default CompanyDetails;
