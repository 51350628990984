import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type CurrentUserUpdateProfileMutationVariables = GraphQLTypes.Exact<{
  contactDetails: GraphQLTypes.CurrentUserUpdateProfileInput;
  rolesAndLevel: GraphQLTypes.CurrentUserUpdatePreferencesInput;
}>;

export type CurrentUserUpdateProfileMutationResult = { __typename?: 'Mutation' } & {
  currentUserUpdateProfile?: GraphQLTypes.Maybe<
    { __typename?: 'CurrentUserUpdateProfilePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
  currentUserUpdatePreferences?: GraphQLTypes.Maybe<
    { __typename?: 'CurrentUserUpdatePreferencesPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const CurrentUserUpdateProfileDocument = /* #__PURE__ */ gql`
  mutation currentUserUpdateProfile(
    $contactDetails: CurrentUserUpdateProfileInput!
    $rolesAndLevel: CurrentUserUpdatePreferencesInput!
  ) {
    currentUserUpdateProfile(input: $contactDetails) {
      errors {
        message
        path
      }
    }
    currentUserUpdatePreferences(input: $rolesAndLevel) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useCurrentUserUpdateProfileMutation__
 *
 * To run a mutation, you first call `useCurrentUserUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [currentUserUpdateProfileMutation, { data, loading, error }] = useCurrentUserUpdateProfileMutation({
 *   variables: {
 *      contactDetails: // value for 'contactDetails'
 *      rolesAndLevel: // value for 'rolesAndLevel'
 *   },
 * });
 */
export function useCurrentUserUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<CurrentUserUpdateProfileMutationResult, CurrentUserUpdateProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CurrentUserUpdateProfileMutationResult, CurrentUserUpdateProfileMutationVariables>(
    CurrentUserUpdateProfileDocument,
    options
  );
}
export type CurrentUserUpdateProfileMutationHookResult = ReturnType<typeof useCurrentUserUpdateProfileMutation>;
