import type { FC, ReactElement, ReactNode } from 'react';

import { useGetBookingCoordinatorsByTypeQuery } from '@zen/Components/BookingCoordinatorSelect/graphql';
import { createCoordinatorOption, getCoordinatorsOptions } from '@zen/Components/BookingCoordinatorSelect/helpers';
import { Form, FormButtons, FormInput, FormInstance, FormSelect } from '@zen/Components/Form';
import QueryHandler from '@zen/Components/QueryHandler';
import { Button } from '@zen/DesignSystem';
import { BookingManager, ManagerTeam } from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useUpdateOperationsCoordinatorMutation } from './graphql';

interface Props {
  onCancel?: () => void;
  onCoordinatorChange: () => void;
  operationsCoordinator?: BookingManager;
  zencargoReference: string;
}

const EditOperationsCoordinator: FC<Props> = ({ zencargoReference, operationsCoordinator, onCoordinatorChange, onCancel }) => {
  const { addSuccess, addError } = useNotification();
  const [updateOperationsCoordinator] = useUpdateOperationsCoordinatorMutation();

  const { data, error, loading } = useGetBookingCoordinatorsByTypeQuery({
    variables: {
      managerType: ManagerTeam.OPERATIONS
    }
  });

  const handleEditOperationsCoordinator = async (formData: { operationsCoordinator: string }): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateOperationsCoordinator({
          variables: {
            input: {
              zencargoReference,
              managerId: formData.operationsCoordinator
            }
          }
        }),
      onError: addError
    });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<BookingManager>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Save">
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  const handleSuccess = (): void => {
    addSuccess('Operations coordinator updated');
    onCoordinatorChange();
    onCancel?.();
  };

  return (
    <QueryHandler data={data?.bookingManagers} error={!!error} isLoading={loading}>
      {(bookingCoordinators: BookingManager[]): ReactElement => (
        <Form
          formButtons={renderFormButtons}
          initialValues={{
            zencargoReference,
            operationsCoordinator: createCoordinatorOption(operationsCoordinator)
          }}
          onSubmit={handleEditOperationsCoordinator}
          onSuccess={handleSuccess}
        >
          {() => (
            <>
              <FormInput disabled={true} name="zencargoReference" />
              <FormSelect
                label="Operations coordinator"
                name="operationsCoordinator"
                options={getCoordinatorsOptions(bookingCoordinators)}
                placeholder="Search for a coordinator..."
              />
            </>
          )}
        </Form>
      )}
    </QueryHandler>
  );
};

export default EditOperationsCoordinator;
