import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';

import CollapsibleSection from '@zen/Components/CollapsibleSection';
import type { Issue } from '@zen/Issues/types';
import { shipmentDetailsPageCollapsibleItemsKeyName } from '@zen/Shipment/ShipmentDetailsPage/shipmentDetailsPageConfig';
import { useCollapsibleElement } from '@zen/utils/hooks/useCollapsibleElement';

import IssueListItem from '../IssueListItem';
import { issueSeverityComparator, issueStateComparator, sortResolvedIssueByDescDate } from './helpers';

interface Props {
  issues: Issue[];
  onIssueClick: (issue: Issue) => void;
}

const IssueListSection: FC<Props> = ({ issues, onIssueClick }) => {
  const [isExpanded, setIsExpanded] = useCollapsibleElement('issuesList', false, shipmentDetailsPageCollapsibleItemsKeyName);

  if (!issues.length) {
    return null;
  }

  const issuesToSort: Issue[] = [...issues];
  const issuesList: Issue[] = sortResolvedIssueByDescDate(issuesToSort.sort(issueSeverityComparator).sort(issueStateComparator));

  const renderIssueListItems = (): ReactNode[] => {
    return issuesList.map((issue: Issue, index: number) => (
      <IssueListItem key={index} issue={issue} onViewIssueClick={() => onIssueClick(issue)} />
    ));
  };

  return (
    <div data-testid="issue-list-section">
      <CollapsibleSection
        collapseLabel="Hide issues"
        expandLabel={`Show all ${issues.length} ${pluralize('issue', issues.length)}`}
        isExpandedInitially={isExpanded}
        onToggle={() => setIsExpanded(!isExpanded)}
        renderItems={renderIssueListItems()}
      />
    </div>
  );
};

export default IssueListSection;
