import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import type { CargoDetailsData, CargoTransition, NewCargo } from '@zen/Cargo';
import transitionCargo from '@zen/Cargo/cargoTransition.helper';
import NewCargoItem from '@zen/Cargo/components/NewCargoItem';
import { useGetCargoDetailsQuery } from '@zen/Cargo/graphql';
import CollectionAndDeliveryContext, {
  CollectionAndDeliveryContextValues,
  initialCollectionAndDeliveryContext
} from '@zen/CollectionAndDelivery/CollectionAndDeliveryContext';
import Details from '@zen/CollectionAndDelivery/Details';
import CollapsibleSection from '@zen/Components/CollapsibleSection';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import { InlineEmptyState } from '@zen/DesignSystem';
import { shipmentDetailsPageCollapsibleItemsKeyName } from '@zen/Shipment/ShipmentDetailsPage/shipmentDetailsPageConfig';
import { isRoadShipment } from '@zen/Shipments';
import useAccount from '@zen/utils/hooks/useAccount';
import { useCollapsibleElement } from '@zen/utils/hooks/useCollapsibleElement';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import type { Optional } from '@zen/utils/typescript';

import MultiCargoScheduleProvider from '../MultiCargoScheduleProvider';
import CargoDetailsTitle from './CargoDetailsTitle';

export interface Props {
  zencargoReference: string;
}

const CargoDetails: FC<Props> = ({ zencargoReference }) => {
  const { accountUuid } = useAccount();
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();

  const { check } = useGlobalPermissions();
  const [customerId, setCustomerId] = useState<string>(accountUuid);
  const {
    data: bookingData,
    loading,
    error,
    refetch
  } = useGetCargoDetailsQuery({
    variables: {
      newCargoService: !!isNewCargoServiceEnabled,
      zencargoReference,
      customerId: null
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const [isExpanded, setIsExpanded] = useCollapsibleElement('cargoList', true, shipmentDetailsPageCollapsibleItemsKeyName);

  const data: CargoDetailsData = bookingData?.bookings?.nodes?.[0];
  const canManageCargo: boolean = checkPermission<CargoDetailsData>(data, 'canManageCargo');
  const canViewOperationsDashboard: boolean = check('operationsDashboard.canAccessRoute');

  const noResults: ReactNode = (
    <InlineEmptyState title="There is no cargo assigned to this booking.">
      Cargo is information about the goods listed in shipment.
    </InlineEmptyState>
  );

  const getInitialContext = (): CollectionAndDeliveryContextValues => {
    if (data) {
      const canCancelCollection: boolean = checkPermission(data, 'canCancelCollection');
      const canCancelDelivery: boolean = checkPermission(data, 'canCancelDelivery');
      const canCompleteCollection: boolean = checkPermission(data, 'canCompleteCollection');
      const canCompleteDelivery: boolean = checkPermission(data, 'canCompleteDelivery');
      const canConfirmCollection: boolean = checkPermission(data, 'canConfirmCollection');
      const canConfirmDelivery: boolean = checkPermission(data, 'canConfirmDelivery');
      const canScheduleCollection: boolean = checkPermission(data, 'canScheduleCollection');
      const canScheduleDelivery: boolean = checkPermission(data, 'canScheduleDelivery');
      const canViewCompleteCollection: boolean = checkPermission(data, 'canViewCompleteCollection');

      const { cargo: legacyCargo, cargos, collectionScheduleRequired, deliveryScheduleRequired, ...rest } = data;
      const newCargo: Optional<CargoTransition> = transitionCargo(legacyCargo, cargos);
      const isSingleCargoRoadShipment: boolean =
        isRoadShipment(data?.modeOfTransport) && !!newCargo?.cargoList && !(newCargo?.cargoList?.length > 1);

      return {
        ...rest,
        cargoSchedulePermissions: {
          canCancelCollection,
          canCancelDelivery,
          canCompleteCollection,
          canCompleteDelivery,
          canConfirmCollection,
          canConfirmDelivery,
          canScheduleCollection,
          canScheduleDelivery,
          canViewCompleteCollection
        },
        zencargoReference,
        collectionScheduleRequired,
        estimatedArrival: data?.estimatedArrival?.date,
        estimatedDeparture: data?.estimatedDeparture?.date,
        deliveryScheduleRequired,
        isSingleCargoRoadShipment,
        canViewOperationsDashboard,
        refetchCargoDetails: refetch
      };
    }

    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ zencargoReference: null; deliveryScheduleR... Remove this comment to see the full error message
    return initialCollectionAndDeliveryContext;
  };

  useEffect(() => {
    if (data && data.customer) {
      setCustomerId(data.customer.uuid);
    }
  }, [data, setCustomerId]);

  return (
    <QueryHandler
      data={data}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonLoading className="my-5" height={50} />}
      noResults={noResults}
    >
      {(booking: CargoDetailsData): ReactElement => {
        const newCargo: Optional<CargoTransition> = transitionCargo(booking.cargo, booking.cargos);
        const { modeOfTransport } = booking;
        const hasCargoItems: boolean = !!newCargo?.cargoList && newCargo?.cargoList.length > 0;
        const cargoList: NewCargo[] = newCargo?.cargoList ? newCargo?.cargoList : [];

        const renderCargoDetailsItem = (item: NewCargo): ReactNode => (
          <Details
            key={item.id}
            accountUuid={customerId}
            canManageCargo={canManageCargo}
            cargo={item}
            modeOfTransport={modeOfTransport}
          />
        );

        const cargoItemsRender: ReactNode[] = [
          <CargoDetailsTitle
            key="cargo-title"
            accountUuid={customerId}
            items={cargoList}
            modeOfTransport={modeOfTransport}
            zencargoReference={zencargoReference}
          />,
          <div key="cargo-list" className="-mt-10" data-testid="cargo-list">
            {cargoList.map(renderCargoDetailsItem)}
          </div>
        ];

        return (
          <CollectionAndDeliveryContext.Provider value={getInitialContext()}>
            <MultiCargoScheduleProvider>
              <>
                {!hasCargoItems && (
                  <InlineEmptyState title="There are no cargo items are created for this booking.">
                    Cargo are the list of goods being transported in the shipment.
                  </InlineEmptyState>
                )}

                {hasCargoItems && (
                  <CollapsibleSection
                    collapseLabel="Hide cargo details"
                    expandLabel="Show cargo details"
                    initialDisplayedItems={0}
                    isExpandedInitially={isExpanded}
                    onToggle={() => setIsExpanded(!isExpanded)}
                    renderItems={cargoItemsRender}
                  />
                )}
                {canManageCargo && newCargo?.mode && (
                  <NewCargoItem
                    cargoMode={newCargo?.mode}
                    hasCargo={cargoList.length > 0}
                    modeOfTransport={modeOfTransport}
                    onSuccess={refetch}
                    zencargoReference={zencargoReference}
                  />
                )}
              </>
            </MultiCargoScheduleProvider>
          </CollectionAndDeliveryContext.Provider>
        );
      }}
    </QueryHandler>
  );
};

export default CargoDetails;
