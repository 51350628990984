import type { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { customerAdminRoutes } from '@zen/Routes';
import ShipmentDetailsPageContainer from '@zen/Shipment/ShipmentDetailsPageContainer';

import AllShipments from './AllShipments';

const CustomerAdmin: FC = () => {
  return (
    <Switch>
      <Route path={customerAdminRoutes.allShipmentsDetailsPage.path}>
        <ShipmentDetailsPageContainer />
      </Route>
      <Route path={customerAdminRoutes.allShipments.path}>
        <AllShipments />
      </Route>
      <Redirect to={customerAdminRoutes.allShipments.getUrl()} />
    </Switch>
  );
};

export default CustomerAdmin;
