import cx from 'classnames';
import Align from 'rc-align';
import { FC, ReactChild, ReactNode, RefObject, SyntheticEvent, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { useClickAway } from 'react-use';

import { IconButton } from '@zen/DesignSystem';

import Divider from './Divider';
import MenuItem from './MenuItem';
import type { MenuItemType } from './types';

interface Props {
  align?: 'left' | 'right';
  disabled?: boolean;
  footer?: ReactChild;
  hoverDisabled?: boolean;
  inline?: boolean;
  items: MenuItemType[];
  menuOffset?: [number, number];
  onClose?: () => void;
  onOpen?: () => void;
}

const ContextMenu: FC<Props> = (props) => {
  const {
    disabled = false,
    children = <IconButton icon="zicon-dots" variant="tertiary" />,
    footer,
    hoverDisabled = false,
    inline = true,
    menuOffset = [0, 6],
    items,
    onOpen,
    onClose,
    align = 'right'
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const menuRef: RefObject<HTMLDivElement> = useRef(null);
  const triggerRef: RefObject<HTMLDivElement> = useRef(null);

  const alignClassName: string = align === 'left' ? 'left-0' : 'right-0';

  const classNames: string = cx(
    {
      'cursor-not-allowed': disabled,
      'bg-grey-lightest text-grey-dark': isExpanded && !hoverDisabled,
      'text-navy-base active:bg-grey-light hover:bg-grey-lighter': !hoverDisabled && !isExpanded && !disabled,
      'text-grey-lighter': disabled,
      'text-navy-base': !disabled
    },
    'transition-all duration-200 ease-linear rounded'
  );

  useClickAway(menuRef, (event: MouseEvent): void => {
    const isTriggerClicked: boolean = !!triggerRef.current?.contains(event.target as Node);

    if (isExpanded && !isTriggerClicked) {
      setIsExpanded(false);
      onClose?.();
    }
  });

  const handleMenuChange = (expanded: boolean): void => {
    return expanded ? onClose?.() : onOpen?.();
  };

  const handleClick = (e: SyntheticEvent): void => {
    e.stopPropagation();

    if (!disabled) {
      setIsExpanded(!isExpanded);
      handleMenuChange(isExpanded);
    }
  };

  const renderMenuItem = (
    { className, disabled: isDisabled, onClick, icon, label, linkTo, addDivider, openInNewTab }: MenuItemType,
    i: number
  ): ReactNode => {
    const handleItemClick = (e: SyntheticEvent): void => {
      e.stopPropagation();

      onClick?.();
      setIsExpanded(false);
    };

    return (
      <MenuItem
        key={i}
        addDivider={addDivider}
        className={className}
        disabled={isDisabled}
        icon={icon}
        label={label}
        linkTo={linkTo}
        onClick={handleItemClick}
        openInNewTab={openInNewTab}
      />
    );
  };

  const renderMenuItems = (): ReactNode => {
    const target = triggerRef.current;

    const menuClassNames: string = cx(
      {
        'opacity-0 pointer-events-none': !isExpanded,
        'opacity-100': isExpanded
      },
      alignClassName,
      'absolute min-w-40 w-max max-w-xs z-20 top-1 mt-2 py-1 rounded shadow-overlay bg-white'
    );

    const menu = target && (
      <Align align={alignConfig} target={() => target}>
        <div ref={menuRef} className={menuClassNames} data-testid="dropdown-menu">
          <div className="max-h-100 overflow-y-auto">{menuItems.map(renderMenuItem)}</div>
          {footer && (
            <>
              <Divider />
              <div className="py-1.5 leading-normal my-1 px-6">{footer}</div>
            </>
          )}
        </div>
      </Align>
    );

    const menuInPortal = (
      <div id="context-menu-root">
        <Portal>{menu}</Portal>
      </div>
    );

    return inline ? menu : menuInPortal;
  };

  const renderChildren = (): ReactNode => {
    if (typeof children === 'function') {
      return children({ isExpanded });
    }

    return <div className={classNames}>{children}</div>;
  };

  // make sure there is no divider after the last menu item
  const menuItems = items.map((item: MenuItemType, i: number): MenuItemType => {
    if (i === items.length - 1) {
      return { ...item, addDivider: false };
    }

    return item;
  });

  const alignConfig = {
    points: ['tl', 'bl'], // align top left point of menu node with bottom left point of trigger node
    offset: menuOffset, // the offset sourceNode by 0px in x and 20px in y,
    overflow: { adjustX: true, adjustY: true }
  };

  return (
    <div className="inline-block relative" data-testid="context-menu">
      <div ref={triggerRef} data-component="context-menu-toggler" data-testid="dropdown-toggler" onClick={handleClick}>
        {renderChildren()}
      </div>
      {renderMenuItems()}
    </div>
  );
};

export type { Props as ContextMenuProps };

export default ContextMenu;
