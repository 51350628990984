import { components } from 'react-select';

import { Icon } from '@zen/DesignSystem';

const ValueContainer: typeof components.ValueContainer = (props) => {
  return (
    <div className="flex items-center">
      <Icon className="text-grey-base text-sm ml-2" icon="zicon-search" />
      <components.ValueContainer {...props}>{props.children}</components.ValueContainer>
    </div>
  );
};

export default ValueContainer;
