import type { FC } from 'react';

import SkeletonLoading from '@zen/Components/SkeletonLoading';

const SkeletonRouteProgress: FC = () => {
  return (
    <>
      <div className="pb-3 mb-3 border-b border-solid border-grey-lighter" data-testid="skeleton-progress-first">
        <SkeletonLoading className="my-2" height={14} width="w-20" />
        <SkeletonLoading height={14} width="w-28" />
      </div>
      <div className="bg-grey-lightest px-3 mb-3 pb-32 pt-4 rounded z-10" data-testid="skeleton-progress-second">
        <SkeletonLoading height={14} lighter={true} width="w-60" />
      </div>
    </>
  );
};

export default SkeletonRouteProgress;
