import type { ReactNode } from 'react';

import { TableColumn, TableLink } from '@zen/DesignSystem';
import InvitationStatusPill from '@zen/Networks/InvitationStatusPill';

import ContactTableActions from './ContactTableActions';
import MainContactIndicator from './MainContactIndicator';
import type { ContactTableDataInput, ContactTableDataType } from './types';

export const prepareContactLocations = (locations: ContactTableDataType['locations'] = []): string =>
  locations.map((location) => location.name || location.address?.street).join(', ');

export const getColumns = <T extends ContactTableDataType>({
  isMyOrganisationPage,
  locationId,
  onInvite,
  onRemove,
  onResendPassword,
  organisationId,
  removeType,
  routes,
  throughLocationDetails
}: ContactTableDataInput): TableColumn<T>[] => [
  {
    key: 'name',
    title: 'Name',
    render: (_, contact: T) => {
      const { firstName, lastName, id } = contact;
      const { asMainContact, main } = contact;
      const contactUrl: string = routes.contact.details.getUrl(organisationId, id);

      return (
        <div className="flex items-center mr-2">
          {main && <MainContactIndicator isCurrentMainContact={!!asMainContact} />}
          <TableLink linkTo={contactUrl} state={{ fromMyOrganisationPage: isMyOrganisationPage }}>
            <div className="font-bold text-navy-base hover:underline hover:cursor-pointer">
              {firstName} {lastName}
            </div>
          </TableLink>
        </div>
      );
    },
    sortable: false
  },
  {
    key: 'locations',
    title: 'Locations',
    render: (_, { locations }: T) => <div className="truncate">{prepareContactLocations(locations)}</div>,
    sortable: false
  },
  {
    key: 'email',
    title: 'Email',
    sortable: false
  },
  {
    key: 'phoneNumber',
    title: 'Phone number',
    sortable: false
  },
  {
    key: 'invitation',
    title: 'Status',
    render: (_, { invitation }: T) => invitation?.status && <InvitationStatusPill invitationStatus={invitation.status} />,
    sortable: false
  },
  {
    key: 'actions',
    title: 'Actions',
    fixed: 'right',
    sortable: false,
    render: (_, contact: T): ReactNode => {
      return (
        <ContactTableActions
          contact={contact}
          handleInvite={onInvite}
          handleRemove={onRemove}
          handleResendPassword={onResendPassword}
          isMyOrganisationPage={isMyOrganisationPage}
          locationId={locationId}
          organisationId={organisationId}
          removeType={removeType}
          throughLocationDetails={throughLocationDetails}
        />
      );
    }
  }
];
