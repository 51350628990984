import cx from 'classnames';
import moment from 'moment';
import type { FC, ReactElement } from 'react';

import { Tooltip } from '@zen/DesignSystem';

import { useDatePickerContext } from '../context';

interface Props {
  day: string; // YYYY-MM-DD
}

const Day: FC<Props> = ({ day }) => {
  const {
    disabledDayTooltip,
    isDisabled,
    onChange,
    isSelected,
    isInitialHighlighted,
    isSuggestedHighlighted,
    isInDisplayedMonth,
    onMonthChange,
    size
  } = useDatePickerContext();

  const isDayDisabled: boolean = isDisabled(day);
  const isDayInSelectedMonth: boolean = isInDisplayedMonth(day);
  const isDaySelected: boolean = isSelected(day) && !isDayDisabled;
  const isInitialDay: boolean = isInitialHighlighted(day) && !isDayDisabled;
  const isSuggestedDay: boolean = isSuggestedHighlighted(day) && !isDayDisabled;
  const label: number = moment(day).date();
  const showTooltip: boolean = isDayDisabled && !!disabledDayTooltip;

  const handleClick = (): void => {
    if (!isDayDisabled) {
      if (!isDayInSelectedMonth) {
        onMonthChange(day);
      }
      onChange(day);
    }
  };

  const getHoverStatus = (): boolean => {
    if (isDaySelected || isDayDisabled) {
      return false;
    }

    return true;
  };

  const classNames: string = cx(
    {
      'text-grey-base': !isDayInSelectedMonth,
      'text-grey-light cursor-not-allowed js-disabled': isDayDisabled,
      'bg-azure-base text-white': isDaySelected,
      'bg-grey-dark text-white': isInitialDay && !isDaySelected,
      'border border-solid border-navy-base': isSuggestedDay,
      'hover:bg-grey-lighter cursor-pointer': getHoverStatus(),
      'py-3': size === 'small',
      'py-4': size === 'medium',
      'py-5': size === 'large'
    },
    'text-center transition duration-200 ease-in-out text-sm h-full w-full rounded'
  );

  const cellContent: ReactElement = (
    <div className={classNames} data-testid={`day-${day}`}>
      {label}
    </div>
  );

  const renderCell = (): ReactElement => {
    if (showTooltip) {
      return (
        <Tooltip className="w-48" tooltipContent={disabledDayTooltip}>
          {cellContent}
        </Tooltip>
      );
    }

    return cellContent;
  };

  return (
    <td aria-label={`day-${label}`} className="border-solid border-2 border-white" onClick={handleClick}>
      {renderCell()}
    </td>
  );
};

export default Day;
