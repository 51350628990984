import type { FC } from 'react';

import { Headline, Toggle } from '@zen/DesignSystem';

import { AnalyticsViewPeriod } from './types';

const viewOptions = [
  { value: AnalyticsViewPeriod.SEVEN_DAYS, label: '7 days' },
  { value: AnalyticsViewPeriod.ONE_MONTH, label: '1 Month' },
  { value: AnalyticsViewPeriod.THREE_MONTHS, label: '3 Months' },
  { value: AnalyticsViewPeriod.SIX_MONTHS, label: '6 Months' },
  { value: AnalyticsViewPeriod.TWELVE_MONTHS, label: '12 Months' },
  { value: AnalyticsViewPeriod.ALL_TIME, label: 'All time' }
];

interface Props {
  onChange: (value: AnalyticsViewPeriod) => void;
  value: AnalyticsViewPeriod;
}

const Filters: FC<Props> = ({ onChange, value }) => {
  return (
    <div className="py-8 px-10 w-1/2">
      <Headline className="text-grey-dark mb-4" level={2}>
        View options
      </Headline>
      <Toggle<AnalyticsViewPeriod> onChange={onChange} options={viewOptions} value={value} />
    </div>
  );
};

export default Filters;
