import type { MultiValueProps } from 'react-select';

import { Chip } from '@zen/DesignSystem';

const MultiValue =
  <T extends { label: string }, K extends boolean>() =>
  ({ data, removeProps }: MultiValueProps<T, K>) => {
    return <Chip isSelected={true} label={data.label} onDelete={removeProps.onClick} />;
  };

export default MultiValue;
