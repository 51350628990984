import type { ReactNode } from 'react';

import type { PurchaseOrdersWithIssues } from '@zen/graphql/types.generated';
import type { Product } from '@zen/Products/types';

export type { PurchaseOrdersWithIssues } from '@zen/graphql/types.generated';
export { PurchaseOrdersIntakePlanningIssue } from '@zen/graphql/types.generated';

export enum IssueType {
  ORDER = 'order',
  PRODUCT = 'product'
}

export type TabItem = Product | PurchaseOrdersWithIssues;

export interface IIssueTab {
  component: (item: TabItem, i: number) => ReactNode;
  items: TabItem[];
  label: string;
  type: IssueType;
}
