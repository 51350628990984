import DateTimeRange from '@zen/Components/DateTimeRange';
import ShipmentReferences from '@zen/Components/ShipmentReferences';
import type { TableColumn } from '@zen/DesignSystem';
import { TextLink } from '@zen/DesignSystem';
import { codeToCountryName } from '@zen/utils/countries';

import StatusPill from '../components/StatusPill';
import { getDatePrefix } from '../helpers';
import type { RoadTrackedLiveShipment } from './types';

export const columns: TableColumn<RoadTrackedLiveShipment>[] = [
  {
    key: 'zencargoReference',
    fixed: 'left',
    title: 'References',
    render: (_, { clientReference, zencargoReference }) => {
      return zencargoReference && <ShipmentReferences clientReference={clientReference} zencargoReference={zencargoReference} />;
    },
    sortable: false,
    width: '200px'
  },
  {
    key: 'status',
    title: 'Status',
    render: (_, { status }) => {
      return <StatusPill status={status} />;
    },
    sortable: false
  },
  {
    key: 'orginalDeliveryDate',
    title: 'Original delivery date',
    render: (_, { originalDelivery }) => {
      return <DateTimeRange dateTimeRange={originalDelivery} />;
    },
    sortable: false
  },
  {
    key: 'currentDeliveryDate',
    title: 'Current delivery date',
    render: (_, { delivery }) => {
      return <DateTimeRange dateTimeRange={delivery?.arrivalWindow} prefix={getDatePrefix(delivery?.confidenceLevel)} />;
    },
    sortable: false
  },
  {
    key: 'originCountry',
    title: 'Origin country',
    render: (_, { collection }) => {
      return codeToCountryName(collection?.countryCode);
    },
    sortable: false
  },
  {
    key: 'collectionDate',
    title: 'Collection date',
    render: (_, { collection }) => {
      return <DateTimeRange dateTimeRange={collection?.arrivalWindow} prefix={getDatePrefix(collection?.confidenceLevel)} />;
    },
    sortable: false
  },
  {
    key: 'destinationCountry',
    title: 'Destination country',
    render: (_, { delivery }) => {
      return codeToCountryName(delivery?.countryCode);
    },
    sortable: false
  },
  {
    key: 'deliveryDate',
    title: 'Delivery date',
    render: (_, { delivery }) => {
      return <DateTimeRange dateTimeRange={delivery?.arrivalWindow} prefix={getDatePrefix(delivery?.confidenceLevel)} />;
    },
    sortable: false
  },
  {
    key: 'mapUrl',
    fixed: 'right',
    title: 'Tracking',
    render: (_, { mapUrl }) => {
      if (!mapUrl) return '-';

      return (
        <TextLink isExternal={true} linkTo={mapUrl}>
          View tracking
        </TextLink>
      );
    },
    sortable: false,
    width: 145
  }
];
