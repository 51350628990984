import type { FC } from 'react';

import MilestoneDateForm from '@zen/Shipment/RouteProgress/VoyageMilestones/components/MilestoneDateForm';
import type { MilestoneDateFormType } from '@zen/Shipment/RouteProgress/VoyageMilestones/components/MilestoneDateForm/types';
import { VoyageMilestoneNameEnum } from '@zen/Shipment/RouteProgress/VoyageMilestones/types';
import { useMilestoneDateValidation } from '@zen/Shipment/ShipmentDetailsPage/MilestoneDateProvider';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useUpdateDeliveryEstimateMutation } from '../graphql';

interface Props {
  initialValues: { date: string | undefined };
  onClose: () => void;
  onSuccess: () => void;
  zencargoReference: string;
}

const UpdateDeliveryEstimate: FC<Props> = ({ zencargoReference, initialValues, onClose, onSuccess }) => {
  const { addSuccess, addError } = useNotification();
  const [updateDeliveryEstimate] = useUpdateDeliveryEstimateMutation();

  const { minDate } = useMilestoneDateValidation(VoyageMilestoneNameEnum.DELIVERED);

  const handleSubmit = async (values: MilestoneDateFormType): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateDeliveryEstimate({
          variables: { input: { zencargoReference, uedd: values.date } }
        }),
      onError: addError
    });
  };

  const handleSuccess = (): void => {
    onSuccess();
    addSuccess('Estimated delivery date updated');
    onClose();
  };

  return (
    <MilestoneDateForm
      initialValues={initialValues}
      isInitialDateEntry={true}
      minDate={minDate}
      onCancel={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default UpdateDeliveryEstimate;
