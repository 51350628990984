import { useEffect } from 'react';

interface AutoSubmitProps {
  submitForm: () => void;
  values: object;
}

const AutoSubmit = ({ values, submitForm }: AutoSubmitProps) => {
  const formValues = Object.values(values);

  useEffect(() => {
    submitForm();
  }, [...formValues, submitForm]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default AutoSubmit;
