import { getToday } from '@zen/utils/date';
import { ComparableDate, compareDate, formatDate } from '@zen/utils/dateTime';
import type { Undefinable } from '@zen/utils/typescript';

import type { BookingStageEnum, DateValidationStageType, MilestoneDateValidationType, VoyageMilestoneNameEnum } from './types';

const getFirstAvailableDate = (milestones: DateValidationStageType[]): Undefinable<string> => {
  const milestone: Undefinable<DateValidationStageType> = milestones.find((element: DateValidationStageType) => element?.date);

  return milestone?.date;
};

const getClosestMinDate = (milestones: DateValidationStageType[], index: number): Undefinable<string> => {
  const isFirstElement: boolean = index === 0;

  if (isFirstElement) {
    return undefined;
  }

  const previousMilestones: DateValidationStageType[] = milestones.slice(0, index).reverse();

  return getFirstAvailableDate(previousMilestones);
};

const getClosestMaxDate = (milestones: DateValidationStageType[], index: number): Undefinable<string> => {
  const lastIndex: number = milestones.length - 1;
  const isLastElement: boolean = index === lastIndex;

  if (isLastElement) {
    return undefined;
  }

  const nextMilestones: DateValidationStageType[] = milestones.slice(index + 1, milestones.length);

  return getFirstAvailableDate(nextMilestones);
};

const validateDateRange = (minDate?: string, maxDate?: string): boolean => {
  if (!minDate || !maxDate) {
    return true;
  }

  const comparedDate: ComparableDate = compareDate(minDate);

  return comparedDate.isBefore(maxDate) || comparedDate.isEqual(maxDate);
};

export const getRangeValidationMessage = (isInvalidRange: boolean): Undefinable<string> => {
  if (isInvalidRange) {
    return 'No valid dates available, past or future milestones are incorrect, please update to proceed.';
  }
};

export const getDateValidationMessage = (minDate?: string, maxDate?: string): Undefinable<string> => {
  if (minDate && maxDate) {
    return `Date selected must occur between ${formatDate(minDate)} and ${formatDate(maxDate)}.`;
  }

  if (minDate) {
    return `Date selected must occur after previous milestone of ${formatDate(minDate)}`;
  }

  if (maxDate) {
    return `Date selected must occur before ${formatDate(maxDate)}`;
  }
};

export const getDateValidation = (
  milestones: DateValidationStageType[],
  name: VoyageMilestoneNameEnum | BookingStageEnum,
  isActualDateUpdate: boolean
): MilestoneDateValidationType => {
  const milestoneIndex: number = milestones.findIndex((milestone) => milestone.name === name);
  const closestMaxDate: Undefinable<string> = getClosestMaxDate(milestones, milestoneIndex);
  const today: string = getToday();
  const isClosestMaxDateInPast = closestMaxDate && compareDate(closestMaxDate).isBefore(today);
  const maxActualDate: Undefinable<string> = isClosestMaxDateInPast ? closestMaxDate : today;

  const minDate: Undefinable<string> = getClosestMinDate(milestones, milestoneIndex);
  const maxDate: Undefinable<string> = isActualDateUpdate ? maxActualDate : closestMaxDate;
  const isValid: boolean = validateDateRange(minDate, maxDate);

  return { minDate, maxDate, isValid };
};
