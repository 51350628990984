import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceGetBookingDataQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
  newCargoService: GraphQLTypes.Scalars['Boolean'];
}>;

export type FinanceGetBookingDataQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference' | 'modeOfTransport'> & {
              networksOrigin?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >;
              networksDestination?: GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >;
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    cargoItems?: GraphQLTypes.Maybe<
                      Array<
                        | ({ __typename: 'CargoContainerType' } & Pick<
                            GraphQLTypes.CargoContainerType,
                            'containerType' | 'containerNumber' | 'id' | 'type'
                          >)
                        | ({ __typename: 'CargoLooseCargoType' } & Pick<
                            GraphQLTypes.CargoLooseCargoType,
                            'quantity' | 'looseCargoType' | 'id' | 'type'
                          >)
                        | ({ __typename: 'CargoVehicleType' } & Pick<
                            GraphQLTypes.CargoVehicleType,
                            'quantity' | 'looseCargoType' | 'vehicleType' | 'id' | 'type'
                          >)
                      >
                    >;
                  }
              >;
              cargos?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'cargoType' | 'containerNumber' | 'id' | 'quantity'>
                >
              >;
            }
        >
      >
    >;
  };
};

export const FinanceGetBookingDataDocument = /* #__PURE__ */ gql`
  query financeGetBookingData($zencargoReference: String!, $newCargoService: Boolean!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        networksOrigin {
          id
          label {
            long
          }
        }
        networksDestination {
          id
          label {
            long
          }
        }
        modeOfTransport
        cargo {
          mode
          cargoItems {
            id
            type
            ... on CargoContainerType {
              __typename
              containerType
              containerNumber
            }
            ... on CargoVehicleType {
              __typename
              quantity
              looseCargoType
              vehicleType
            }
            ... on CargoLooseCargoType {
              __typename
              quantity
              looseCargoType
            }
          }
        }
        cargos @include(if: $newCargoService) {
          cargoType
          containerNumber
          id
          quantity
        }
      }
    }
  }
`;

/**
 * __useFinanceGetBookingDataQuery__
 *
 * To run a query within a React component, call `useFinanceGetBookingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceGetBookingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceGetBookingDataQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *      newCargoService: // value for 'newCargoService'
 *   },
 * });
 */
export function useFinanceGetBookingDataQuery(
  baseOptions: Apollo.QueryHookOptions<FinanceGetBookingDataQueryResult, FinanceGetBookingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<FinanceGetBookingDataQueryResult, FinanceGetBookingDataQueryVariables>(
    FinanceGetBookingDataDocument,
    options
  );
}
export function useFinanceGetBookingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FinanceGetBookingDataQueryResult, FinanceGetBookingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<FinanceGetBookingDataQueryResult, FinanceGetBookingDataQueryVariables>(
    FinanceGetBookingDataDocument,
    options
  );
}
export type FinanceGetBookingDataQueryHookResult = ReturnType<typeof useFinanceGetBookingDataQuery>;
export type FinanceGetBookingDataLazyQueryHookResult = ReturnType<typeof useFinanceGetBookingDataLazyQuery>;
