import { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import Page from '@zen/Components/Page';
import PageNotFound from '@zen/Components/PageNotFound';
import QueryHandler from '@zen/Components/QueryHandler';
import { Dialog } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks';
import { ContactRemoveLevelType } from '@zen/Networks/Contacts/ContactTable';
import ContactTableContainer from '@zen/Networks/Contacts/ContactTableContainer';
import {
  OrganisationLocationContactsQueryResult,
  OrganisationLocationContactsQueryVariables,
  useNetworksDeleteLocationMutation,
  useOrganisationLocationContactsQuery,
  useOrganisationLocationDetailsQuery
} from '@zen/Networks/graphql';
import { prepareBusinessHoursInitialValues } from '@zen/Networks/networksHelpers';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import LocationAddressDetails from './LocationAddressDetails';
import LocationDetailsHeader from './LocationDetailsHeader';
import type { OrganisationLocationType } from './types';

const LocationDetails = () => {
  const { locationId, organisationId } = useParams<{ locationId: string; organisationId: string }>();
  const { push } = useHistory();
  const { data, loading, error, refetch } = useOrganisationLocationDetailsQuery({
    variables: {
      organisationId,
      locationId
    },
    fetchPolicy: 'no-cache'
  });

  const { routes } = useNetworksContext();
  const { addSuccess, addError } = useNotification();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteLocation] = useNetworksDeleteLocationMutation();

  const handleDeleteLocation = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        deleteLocation({
          variables: {
            input: {
              locationId
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Location has been deleted');
        refetch();

        push(routes.organisation.details.getUrl(organisationId));
      }
    });
  };

  const onEdit = (): void => push(routes.location.edit.getUrl(organisationId, locationId));

  return (
    <Page defaultBackUrl={routes.myOrganisation.details.getUrl()} title="Location details">
      <QueryHandler data={data?.location} error={!!error} errorComponent={<PageNotFound />} isLoading={loading}>
        {(organisationLocation: OrganisationLocationType) => {
          const { organisation, address, businessHours, geolocation, name } = organisationLocation;

          return (
            <>
              <LocationDetailsHeader
                locationId={locationId}
                locationName={name || ''}
                onCopy={() => addSuccess('Location id has been copied to clipboard')}
                onDelete={() => setIsModalOpen(true)}
                onEdit={onEdit}
                organisationName={organisation.name}
              />
              <LocationAddressDetails
                address={address}
                businessHours={prepareBusinessHoursInitialValues(businessHours)}
                geolocation={geolocation}
              />
              <ContactTableContainer<OrganisationLocationContactsQueryResult, OrganisationLocationContactsQueryVariables>
                locationId={locationId}
                locationName={name || ''}
                organisationId={organisationId}
                paginatedQueryHook={useOrganisationLocationContactsQuery}
                paginatedQueryPath="organisationLocationContacts.contacts"
                paginatedQueryVars={{ locationId, organisationId }}
                removeType={ContactRemoveLevelType.LOCATION}
                throughLocationDetails={true}
              />
              <Dialog
                confirmLabel="Delete"
                header="Are you sure you want to delete this location?"
                isOpen={isModalOpen}
                message="Deleting this location will permanently remove it and all the contacts associated with it."
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleDeleteLocation}
              />
            </>
          );
        }}
      </QueryHandler>
    </Page>
  );
};

export default LocationDetails;
