import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().nullable().required('First name is required.'),
  lastName: Yup.string().nullable().required('Last name is required.'),
  email: Yup.string().nullable().email('Email address is not valid.').required('Email is required.'),
  phone: Yup.string().nullable().required('Phone is required.'),
  country: Yup.string().nullable().required('Country is required.'),
  city: Yup.string().nullable().required('City is required.'),
  postalCode: Yup.string().nullable().required('Postal code is required.'),
  state: Yup.string().nullable().required('State is required.'),
  street: Yup.string().nullable().required('Street is required.')
});
