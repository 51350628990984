import { FC, useState } from 'react';

import { Button } from '@zen/DesignSystem';

import BookingRequestRejectModal from '../BookingRequestRejectModal';

interface Props {
  zencargoReference: string;
}

const BookingRequestRejectButton: FC<Props> = (props) => {
  const { zencargoReference } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleRejectClick = (): void => {
    setIsModalOpen(true);
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button data-testid="reject-button" onClick={handleRejectClick} variant="danger">
        Reject
      </Button>
      <BookingRequestRejectModal isOpen={isModalOpen} onClose={handleModalClose} zencargoReference={zencargoReference} />
    </>
  );
};

export default BookingRequestRejectButton;
