import useAccount from '@zen/utils/hooks/useAccount';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';

interface PermissionSuppress {
  suppressPermission: boolean;
}

const usePermissionSuppress = (): PermissionSuppress => {
  const { userProfile } = useAccount();
  const { topLevelAccountSuspension: topLevelAccountSuspensionEnabled } = useFeatureFlags();

  const topLevelAccount = userProfile.isParent;
  const suppressPermission = topLevelAccount && topLevelAccountSuspensionEnabled;

  return {
    suppressPermission
  };
};

export default usePermissionSuppress;
