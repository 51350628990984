import cx from 'classnames';
import type { FC } from 'react';

import type { LotInput } from '@zen/Orders/types';
import type { Money } from '@zen/types';
import { formatCurrency, formatNumber } from '@zen/utils/formatting';

import type { SummaryRowProps } from './SummaryTable';
import SummaryTable from './SummaryTable';
import { ReactComponent as WarningIcon } from './warning.svg';

const calculateQuantity = (lots: LotInput[]) => {
  return lots.reduce((prev: number, curr: LotInput) => {
    return prev + +(curr?.quantityFulfilled ? curr.quantityFulfilled : 0);
  }, 0);
};

interface Props {
  lots: LotInput[];
  productPrice: Money;
  quantityOrdered: number;
}

type SummaryRowValues = Pick<SummaryRowProps, 'value' | 'percentage' | 'quantity'>;

const Summary: FC<Props> = (props) => {
  const { lots, productPrice, quantityOrdered } = props;
  const initialValue = +productPrice.value * quantityOrdered;
  const currentQuantity = calculateQuantity(lots);
  const currentPercentage = (currentQuantity / quantityOrdered) * 100;
  const currentValue = +productPrice.value * currentQuantity;
  const finalQuantity = currentQuantity - quantityOrdered;
  const prefix = finalQuantity > 0 ? '+' : '';
  const hasWarning = finalQuantity !== 0;
  const { value: productValue } = productPrice;

  const originalPO: SummaryRowValues = {
    value: productValue && formatCurrency(productPrice.currency, initialValue),
    percentage: '100',
    quantity: formatNumber(quantityOrdered)
  };

  const sumOfLots: SummaryRowValues = {
    value: productValue && formatCurrency(productPrice.currency, currentValue),
    percentage: formatNumber(currentPercentage),
    quantity: formatNumber(currentQuantity)
  };

  const difference: SummaryRowValues = {
    value: productValue && `${prefix}${formatCurrency(productPrice.currency, currentValue - initialValue)}`,
    percentage: `${prefix}${formatNumber(currentPercentage - 100)}`,
    quantity: `${prefix}${formatNumber(finalQuantity)}`
  };

  return (
    <SummaryTable testId="summary-table">
      <SummaryTable.Row
        name="Original PO"
        percentage={originalPO.percentage}
        quantity={originalPO.quantity}
        testId="original-po"
        value={originalPO.value}
      />
      <SummaryTable.Row
        name="Sum of lots"
        percentage={sumOfLots.percentage}
        quantity={sumOfLots.quantity}
        testId="sum-of-lot"
        value={sumOfLots.value}
      />
      <SummaryTable.Row
        className={cx({ 'bg-orange-light': hasWarning })}
        icon={hasWarning && <WarningIcon className="w-full h-full" data-testid="warning-icon" />}
        name="Difference"
        percentage={difference.percentage}
        quantity={difference.quantity}
        testId="difference"
        value={difference.value}
      />
    </SummaryTable>
  );
};

export type { Props as SummaryProps };
export default Summary;
