import type { FC } from 'react';

import { Button } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  isExpanded: boolean;
  onToggle: (isExpanded: boolean) => void;
}

const ExpandRejectionReasonButton: FC<Props> = ({ isExpanded, onToggle }) => {
  const icon: IconName = isExpanded ? 'zicon-chevron-up' : 'zicon-chevron-down';
  const text: string = isExpanded ? 'Hide rejection reason' : 'View rejection reason';

  return (
    <Button iconLeft={icon} onClick={() => onToggle(!isExpanded)} variant="tertiary">
      {text}
    </Button>
  );
};

export type { Props as ExpandRejectionReasonButtonProps };

export default ExpandRejectionReasonButton;
