import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetOperationsRoadShipmentsTotalCountQueryVariables = GraphQLTypes.Exact<{
  accountIds?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>> | GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>
  >;
  active?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  bookingManagerIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  customsOnly?: GraphQLTypes.InputMaybe<
    | Array<GraphQLTypes.InputMaybe<GraphQLTypes.CustomsOnlyFilterEnum>>
    | GraphQLTypes.InputMaybe<GraphQLTypes.CustomsOnlyFilterEnum>
  >;
  destinations?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  issues?: GraphQLTypes.InputMaybe<GraphQLTypes.IssuesFilterInput>;
  origins?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  showInTransit?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  showSubscribed?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  withinTimeRange?: GraphQLTypes.InputMaybe<GraphQLTypes.WithinTimeRangeInput>;
  withSteps?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.BookingStep> | GraphQLTypes.BookingStep>;
}>;

export type GetOperationsRoadShipmentsTotalCountQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'>;
};

export const GetOperationsRoadShipmentsTotalCountDocument = /* #__PURE__ */ gql`
  query getOperationsRoadShipmentsTotalCount(
    $accountIds: [String]
    $active: Boolean
    $bookingManagerIds: [String!]
    $customsOnly: [CustomsOnlyFilterEnum]
    $destinations: [String!]
    $issues: IssuesFilterInput
    $origins: [String!]
    $searchQuery: String
    $showInTransit: Boolean
    $showSubscribed: Boolean
    $withinTimeRange: WithinTimeRangeInput
    $withSteps: [BookingStep!]
  ) {
    bookings(
      active: $active
      customers: $accountIds
      customsOnly: $customsOnly
      destinations: $destinations
      issues: $issues
      origins: $origins
      onlyShipments: true
      operationsManagers: $bookingManagerIds
      showInTransit: $showInTransit
      showSubscribed: $showSubscribed
      textContains: $searchQuery
      transportModes: [TRUCK]
      withinTimeRange: $withinTimeRange
      withSteps: $withSteps
      onlySingleCargo: true
    ) {
      totalCount
    }
  }
`;

/**
 * __useGetOperationsRoadShipmentsTotalCountQuery__
 *
 * To run a query within a React component, call `useGetOperationsRoadShipmentsTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationsRoadShipmentsTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationsRoadShipmentsTotalCountQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      active: // value for 'active'
 *      bookingManagerIds: // value for 'bookingManagerIds'
 *      customsOnly: // value for 'customsOnly'
 *      destinations: // value for 'destinations'
 *      issues: // value for 'issues'
 *      origins: // value for 'origins'
 *      searchQuery: // value for 'searchQuery'
 *      showInTransit: // value for 'showInTransit'
 *      showSubscribed: // value for 'showSubscribed'
 *      withinTimeRange: // value for 'withinTimeRange'
 *      withSteps: // value for 'withSteps'
 *   },
 * });
 */
export function useGetOperationsRoadShipmentsTotalCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOperationsRoadShipmentsTotalCountQueryResult,
    GetOperationsRoadShipmentsTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetOperationsRoadShipmentsTotalCountQueryResult, GetOperationsRoadShipmentsTotalCountQueryVariables>(
    GetOperationsRoadShipmentsTotalCountDocument,
    options
  );
}
export function useGetOperationsRoadShipmentsTotalCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOperationsRoadShipmentsTotalCountQueryResult,
    GetOperationsRoadShipmentsTotalCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetOperationsRoadShipmentsTotalCountQueryResult, GetOperationsRoadShipmentsTotalCountQueryVariables>(
    GetOperationsRoadShipmentsTotalCountDocument,
    options
  );
}
export type GetOperationsRoadShipmentsTotalCountQueryHookResult = ReturnType<typeof useGetOperationsRoadShipmentsTotalCountQuery>;
export type GetOperationsRoadShipmentsTotalCountLazyQueryHookResult = ReturnType<
  typeof useGetOperationsRoadShipmentsTotalCountLazyQuery
>;
