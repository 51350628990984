import cx from 'classnames';
import type { FC, ReactNode } from 'react';

interface Props {
  checked: boolean;
  children: ReactNode;
  className?: string;
  description?: string;
  disabled?: boolean;
  error?: boolean;
  name: string;
  onChange: (value: string) => void;
  value: string;
}

export const RadioInput: FC<Props> = ({
  checked,
  children,
  description,
  disabled,
  error = false,
  value = '',
  className = '',
  onChange,
  name
}) => {
  const radioContainerClasses: string = cx(
    {
      'text-grey-dark cursor-pointer': !disabled,
      'text-grey-base cursor-not-allowed': disabled
    },
    'group inline-flex items-center justify-start mb-2',
    className
  );

  const outerCircleClasses: string = cx(
    {
      'border-grey-light group-hover:border-azure-base border group-hover:border': !checked && !disabled && !error,
      'border-azure-base border': checked,
      'bg-white': !disabled && !error,
      'border-grey-lighter border bg-grey-lightest': disabled,
      'border-red-dark border': error
    },
    'w-4 h-4',
    'rounded-full circle border-solid',
    'flex items-center justify-center',
    'mr-2.5'
  );

  const innerCircleClasses: string = `
    w-2 h-2
    rounded-full border circle border-solid border-azure-base
    bg-azure-base`;

  const handleChange = (): void => {
    if (disabled) {
      return;
    }
    onChange(value);
  };

  // the ml class is the total of the outerCircleClasses width + margin right class values converted to rem
  const descriptionClasses: string = 'ml-[1.625rem] mb-2 -mt-2 text-grey-base';

  return (
    <>
      <label className={radioContainerClasses}>
        <div className={outerCircleClasses} data-testid="radio-input">
          {checked && <div className={innerCircleClasses} data-testid="check" />}
          <input
            checked={checked}
            className="hidden"
            data-component="radio-input"
            disabled={disabled}
            name={name}
            onChange={handleChange}
            readOnly={true}
            type="radio"
            value={value}
          />
        </div>
        <span className="text-sm leading-normal">{children}</span>
      </label>
      {description && <div className={descriptionClasses}>{description}</div>}
    </>
  );
};

export type { Props as RadioInputProps };

export default RadioInput;
