import * as yup from 'yup';

export const createSchema = () => {
  return yup.object().shape({
    category: yup.string().required('Category is required').nullable(),
    severity: yup.string().required('Severity is required').nullable(),
    state: yup.string().required('Status is required').nullable(),
    occurredOn: yup.string().required('Date issue began is required'),
    problemDescription: yup.string().required('Problem description is required'),
    solutionDescription: yup.string().required('Solution description is required').nullable()
  });
};
