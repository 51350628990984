import type { FC } from 'react';
import { useLocation, useParams } from 'react-router';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Page from '@zen/Components/Page';
import RouteTabs, { RouteTabConfig } from '@zen/Components/RouteTabs';
import RouteTabSwitcher from '@zen/Components/RouteTabSwitcher';
import { consolidationRoutes } from '@zen/Routes';

import type { Undefinable } from '../../utils/typescript';
import ConsolidationShipments from '../ConsolidationShipments/ConsolidationShipments';
import { useConsolidatedShipmentReferenceQuery } from '../graphql';
import type { ConfigItem } from './ConsolidationPage.helper';
import { consolidationDetailsTabConfig, ConsolidationDetailsTabType } from './ConsolidationPage.helper';
import ConsolidationShipmentsButton from './ConsolidationShipmentsButton/ConsolidationShipmentsButton';

const ConsolidationPage: FC = () => {
  const { check } = useGlobalPermissions();
  const { pathname } = useLocation();
  const { consolidationId } = useParams<{ consolidationId: string }>();
  const isCreateUrl: boolean = pathname.includes(consolidationRoutes.consolidationCreate.getUrl());

  const { data } = useConsolidatedShipmentReferenceQuery({
    fetchPolicy: 'no-cache',
    variables: {
      consolidationId
    },
    skip: !consolidationId
  });

  const consolidationReference: Undefinable<string> = data?.consolidations?.nodes?.[0]?.reference;

  const canCreateConsolidation: boolean = check('consolidation.canAddConsolidatedShipment');
  const canManageConsolidation: boolean = check('consolidation.canManageConsolidatedShipment');
  const renderAddShipmentsButton: boolean = pathname.includes(consolidationRoutes.consolidationShipments.getUrl(consolidationId));

  const getDetailsTabType = (): ConsolidationDetailsTabType => {
    if (isCreateUrl) {
      return ConsolidationDetailsTabType.CREATE;
    }

    if (canCreateConsolidation && canManageConsolidation) {
      return ConsolidationDetailsTabType.EDIT;
    }

    return ConsolidationDetailsTabType.DETAILS;
  };

  const detailsTabElement: ConfigItem = consolidationDetailsTabConfig[getDetailsTabType()];

  const tabsConfig: RouteTabConfig[] = [
    {
      component: detailsTabElement.component,
      isDefault: true,
      label: 'Consolidation details',
      path: detailsTabElement.path
    },
    ...(!isCreateUrl
      ? [
          {
            component: <ConsolidationShipments />,
            label: 'Shipments',
            path: 'shipments'
          }
        ]
      : [])
  ];

  return (
    <Page
      actionButtons={renderAddShipmentsButton && <ConsolidationShipmentsButton />}
      defaultBackUrl={consolidationRoutes.consolidationIndex.getUrl()}
      tabsComponent={<RouteTabs tabsConfig={tabsConfig} />}
      tagline={consolidationReference}
      title={detailsTabElement.pageTitle}
    >
      <RouteTabSwitcher tabsConfig={tabsConfig} />
    </Page>
  );
};

export default ConsolidationPage;
