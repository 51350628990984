import type { UpdateOrganisationLocationInput } from '@zen/Networks';
import type { IEditLocationFormFields } from '@zen/Networks/Form/EditLocationForm';
import { prepareBusinessHoursInitialValues, prepareLocationInput } from '@zen/Networks/networksHelpers';
import type { DeepNonNullable, DeepNullable } from '@zen/utils/typescript';

import type { EditOrganisationLocationQueryResult } from '../graphql';

export type Location = DeepNonNullable<EditOrganisationLocationQueryResult, 'location'>['location'];

export const getInitialValues = (location: Location): DeepNullable<IEditLocationFormFields> => {
  const { businessHours, name, address, geolocation, locationType } = location;
  const { city, country, postalCodeOrZip, countyOrState, street } = address;

  return {
    name,
    businessHours: prepareBusinessHoursInitialValues(businessHours),
    city,
    countryCode: country?.code,
    street,
    locationAddress: '',
    locationType,
    postalCode: postalCodeOrZip,
    state: countyOrState,
    lat: geolocation ? geolocation.latitude : null,
    lng: geolocation ? geolocation.longitude : null,
    addressFieldsVisible: true,
    timeZone: ''
  };
};

export const prepareUpdateLocationInput = (
  values: IEditLocationFormFields,
  organisationId: string,
  locationId: string
): UpdateOrganisationLocationInput => {
  return {
    organisationId,
    locationId,
    location: prepareLocationInput(values)
  };
};
