import type { Option } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import { cargoTypeLabelMapping, cargoValues, ModeOfTransport, newCargoTypeLabelMapping, VehicleTypeEnum } from '@zen/types';
import { formatNumber } from '@zen/utils/formatting';
import type { DeepNullable, Nullable, Optional } from '@zen/utils/typescript';

import type { NewCargoType } from '../types';
import { getLegacyCargoType, palletTypes, templatedPalletTypes } from './cargoDictionaryMapping.helper';
import type {
  CargoWeight,
  Dimensions,
  FullContainerFormData,
  UpdateContainerCargoItemInput,
  UpdateLooseCargoItemInput,
  UpdateVehicleCargoItemInput
} from './types';
import {
  CargoDimensionsInputType,
  CargoDimensionsType,
  CargoDimensionsUnitEnum,
  CargoItemTypeEnum,
  CargoModeEnum,
  CargoWeightType,
  CargoWeightUnitEnum,
  ContainerTypeEnum,
  LooseCargoFormData,
  LooseCargoTypeEnum,
  PalletTypeEnum,
  RiskLevelsEnum,
  VehicleCargoFormData
} from './types';

export const getWeight = (weight: Optional<CargoWeightType | CargoWeight>): Nullable<CargoWeightType> => {
  if (!weight || !weight?.value) {
    return null;
  }

  const metric = (weight as CargoWeight).unit || (weight as CargoWeightType).metric;

  return {
    metric: metric as CargoWeightUnitEnum,
    value: weight.value
  };
};

export const getDimensions = (dimensions: Optional<CargoDimensionsType | Dimensions>): Nullable<CargoDimensionsInputType> => {
  if (!dimensions || !dimensions.length || !dimensions.width || !dimensions.height) {
    return null;
  }
  const metric = ((dimensions as Dimensions).unit || (dimensions as CargoDimensionsType).metric) as CargoDimensionsUnitEnum;

  return {
    height: dimensions.height,
    width: dimensions.width,
    length: dimensions.length,
    metric
  };
};

export const getLooseCargoIcon = (looseCargoType: LooseCargoTypeEnum): IconName => {
  if (looseCargoType === LooseCargoTypeEnum.PALLETS) {
    return 'zicon-pallet';
  }

  return looseCargoType === LooseCargoTypeEnum.BOXES_OR_CRATES ? 'zicon-box' : 'zicon-shipment';
};

export const isNewCargoPalletType = (cargoType: Optional<NewCargoType>): boolean =>
  !!cargoType && palletTypes.includes(cargoType);

export const getLooseNewCargoIcon = (cargoType: NewCargoType): IconName => {
  if (isNewCargoPalletType(cargoType) || cargoType === cargoValues.loosePallet) {
    return 'zicon-pallet';
  }

  return cargoType === cargoValues.looseBoxesOrCrates ? 'zicon-box' : 'zicon-shipment';
};

export const getLooseCargoLabel = (
  looseCargoType: LooseCargoTypeEnum,
  palletType: Nullable<PalletTypeEnum> | undefined = undefined
): string => {
  if (looseCargoType === LooseCargoTypeEnum.PALLETS) {
    return (palletType && cargoTypeLabelMapping[palletType]) || 'Pallets';
  }

  return cargoTypeLabelMapping[looseCargoType];
};

export const formatWeight = (weight: Optional<CargoWeightType | CargoWeight>): string => {
  if (!weight) {
    return '-';
  }

  const weightUnit: string = (weight as CargoWeightType).metric?.toLowerCase() || (weight as CargoWeight).unit?.toLowerCase();

  return weight?.value ? `${formatNumber(weight.value, 4)} ${weightUnit}` : '-';
};

export const formatDimensions = (dimensions: Optional<CargoDimensionsType | Dimensions>): string => {
  if (!dimensions) {
    return '-';
  }

  const { width, height, length } = dimensions;

  const unit = (dimensions as Dimensions).unit || (dimensions as CargoDimensionsType).metric;

  return `${length} x ${width} x ${height} ${unit?.toLowerCase()}`;
};

export type FullContainerCargoRequestPayload = Omit<UpdateContainerCargoItemInput, 'id'>;
export type VehicleCargoRequestPayload = Omit<UpdateVehicleCargoItemInput, 'id'>;
export type LooseCargoRequestPayload = Omit<UpdateLooseCargoItemInput, 'id'>;

export const prepareLooseCargoRequestPayload = (values: LooseCargoFormData): LooseCargoRequestPayload => {
  const {
    actualCbm,
    chargeableWeight,
    containerNumber,
    dimensions,
    grossWeight,
    hazardous,
    looseCargoType,
    cargoType,
    refrigerated,
    quantity,
    stackable,
    trailerId
  } = values;

  const payload = {
    actualCbm,
    chargeableWeight: getWeight(chargeableWeight as Nullable<CargoWeight>),
    containerNumber,
    dimensions: getDimensions(dimensions),
    grossWeight: getWeight(grossWeight as Nullable<CargoWeight>),
    hazardous: !!hazardous,
    looseCargoType: getLegacyCargoType(looseCargoType as NewCargoType) as LooseCargoTypeEnum,
    reefer: !!refrigerated,
    quantity,
    stackable: !!stackable,
    ...(trailerId && { trailerId })
  };

  if (looseCargoType === cargoValues.loosePallet) {
    return {
      ...payload,
      palletType: getLegacyCargoType(cargoType) as PalletTypeEnum
    };
  }

  return payload;
};

export const prepareVehicleCargoRequestPayload = (values: VehicleCargoFormData): VehicleCargoRequestPayload => {
  const {
    actualCbm,
    dimensions,
    grossWeight,
    hazardous,
    looseCargoType,
    refrigerated,
    riskLevel,
    quantity,
    tailLift,
    valueOfGoods,
    vehicleType,
    trailerId
  } = values;

  return {
    actualCbm,
    dimensions: getDimensions(dimensions),
    grossWeight: getWeight(grossWeight as Nullable<CargoWeight>),
    hazardous: !!hazardous,
    looseCargoType: getLegacyCargoType(looseCargoType as NewCargoType) as LooseCargoTypeEnum,
    reefer: !!refrigerated,
    riskLevel,
    quantity,
    tailLift: !!tailLift,
    valueOfGoods: valueOfGoods?.value ? valueOfGoods : null,
    vehicleType: getLegacyCargoType(vehicleType as NewCargoType) as VehicleTypeEnum,
    trailerId
  };
};

export const prepareContainerCargoRequestPayload = (values: FullContainerFormData): FullContainerCargoRequestPayload => {
  const {
    actualCbm,
    containerNumber,
    containerSealNumber,
    containerType,
    grossWeight,
    hazardous,
    looseCargoType,
    overweight,
    quantity,
    refrigerated
  } = values;

  return {
    actualCbm,
    containerNumber,
    containerSealNumber,
    containerType: getLegacyCargoType(containerType) as ContainerTypeEnum,
    grossWeight: getWeight(grossWeight as Nullable<CargoWeight>),
    hazardous: !!hazardous,
    looseCargoType: looseCargoType ? (getLegacyCargoType(looseCargoType) as LooseCargoTypeEnum) : null,
    overweight: !!overweight,
    quantity,
    reefer: !!refrigerated
  };
};

export const cargoItemTypeLabelMapping: Record<CargoItemTypeEnum, string> = {
  [CargoItemTypeEnum.CONTAINER]: 'Full container load',
  [CargoItemTypeEnum.LOOSE_CARGO]: 'Loose',
  [CargoItemTypeEnum.VEHICLE]: 'Full truck load'
};

export const cargoModeLabelMapping: Record<CargoModeEnum, string> = {
  [CargoModeEnum.FCL]: 'Full container load',
  [CargoModeEnum.FTL]: 'Full truck load',
  [CargoModeEnum.LOOSE]: 'Loose'
};

export const cargoRiskLevelLabelMapping: Record<RiskLevelsEnum, { description: string; title: string }> = {
  [RiskLevelsEnum.LOW]: {
    title: 'Lowest risk',
    description: 'Will be shipped using equipment appropriate for the cargo according to your SLA'
  },
  [RiskLevelsEnum.MEDIUM]: {
    title: 'Theft attractive',
    description: 'Will be shipped with added safety measures according to your SLA'
  },
  [RiskLevelsEnum.HIGH]: {
    title: 'Highly theft attractive',
    description: 'Will be shipped with added safety measures according to your SLA'
  }
};

export const looseCargoOptions: Option<LooseCargoTypeEnum>[] = [
  {
    label: cargoTypeLabelMapping[LooseCargoTypeEnum.ASSORTED_CARGO],
    value: LooseCargoTypeEnum.ASSORTED_CARGO
  },
  {
    label: cargoTypeLabelMapping[LooseCargoTypeEnum.BOXES_OR_CRATES],
    value: LooseCargoTypeEnum.BOXES_OR_CRATES
  },
  {
    label: cargoTypeLabelMapping[LooseCargoTypeEnum.PALLETS],
    value: LooseCargoTypeEnum.PALLETS
  }
];

export const looseNewCargoOptions: Option<NewCargoType>[] = [
  {
    label: newCargoTypeLabelMapping[cargoValues.looseAssortedCargo],
    value: cargoValues.looseAssortedCargo
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.looseBoxesOrCrates],
    value: cargoValues.looseBoxesOrCrates
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.loosePallet],
    value: cargoValues.loosePallet
  }
];

export const vehicleOptions: Option<VehicleTypeEnum>[] = [
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_13_6_M_BOX],
    value: VehicleTypeEnum.TRAILER_13_6_M_BOX
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED],
    value: VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER],
    value: VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF],
    value: VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_18_T_BOX],
    value: VehicleTypeEnum.TRAILER_18_T_BOX
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED],
    value: VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF],
    value: VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_7_5_T_BOX],
    value: VehicleTypeEnum.TRAILER_7_5_T_BOX
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED],
    value: VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF],
    value: VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.VAN_BOX],
    value: VehicleTypeEnum.VAN_BOX
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.VAN_CURTAIN_SIDED],
    value: VehicleTypeEnum.VAN_CURTAIN_SIDED
  },
  {
    label: cargoTypeLabelMapping[VehicleTypeEnum.VAN_SLIDING_ROOF],
    value: VehicleTypeEnum.VAN_SLIDING_ROOF
  }
];

export const newCargoVehicleOptions: Option<NewCargoType>[] = [
  {
    label: newCargoTypeLabelMapping[cargoValues.trailer136MBox],
    value: cargoValues.trailer136MBox
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailer136MCurtainSided],
    value: cargoValues.trailer136MCurtainSided
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailer136MMegatrailer],
    value: cargoValues.trailer136MMegatrailer
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailer136MSlidingRoof],
    value: cargoValues.trailer136MSlidingRoof
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailer18TBox],
    value: cargoValues.trailer18TBox
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailer18TCurtainSided],
    value: cargoValues.trailer18TCurtainSided
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailer18TSlidingRoof],
    value: cargoValues.trailer18TSlidingRoof
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailer75TBox],
    value: cargoValues.trailer75TBox
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailer75TCurtainSided],
    value: cargoValues.trailer75TCurtainSided
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailer75TSlidingRoof],
    value: cargoValues.trailer75TSlidingRoof
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailerVanBox],
    value: cargoValues.trailerVanBox
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailerVanCurtainSided],
    value: cargoValues.trailerVanCurtainSided
  },
  {
    label: newCargoTypeLabelMapping[cargoValues.trailerVanSlidingRoof],
    value: cargoValues.trailerVanSlidingRoof
  }
];

export const maxCargoHeightForRoadShipment: Record<CargoDimensionsUnitEnum, number> = {
  [CargoDimensionsUnitEnum.CM]: 265,
  [CargoDimensionsUnitEnum.MM]: 2650,
  [CargoDimensionsUnitEnum.IN]: 104.33
};

export const isValidHeightForRoadShipment = (
  height: Optional<number>,
  dimensionsUnit: CargoDimensionsUnitEnum,
  modeOfTransport: Optional<ModeOfTransport>
): boolean => {
  if (!height || modeOfTransport !== ModeOfTransport.TRUCK) {
    return true;
  }

  return height <= maxCargoHeightForRoadShipment[dimensionsUnit];
};

export const isTemplatedPalletType = (palletType: Optional<PalletTypeEnum>): boolean => {
  if (!palletType) {
    return false;
  }

  return [PalletTypeEnum.EURO, PalletTypeEnum.UK, PalletTypeEnum.US].includes(palletType);
};

export const isTemplatedPalletNewCargoType = (palletType: Optional<NewCargoType>): boolean => {
  if (!palletType) {
    return false;
  }

  return templatedPalletTypes.includes(palletType);
};

export const getDefaultGrossWeight = (palletType: Optional<PalletTypeEnum>): CargoWeightType => {
  const metric: CargoWeightUnitEnum = palletType === PalletTypeEnum.US ? CargoWeightUnitEnum.LBS : CargoWeightUnitEnum.KG;

  return {
    value: null,
    metric
  };
};

export const getDefaultNewCargoGrossWeight = (palletType: Optional<NewCargoType>): DeepNullable<CargoWeight> => {
  const unit: CargoWeightUnitEnum = palletType === cargoValues.loosePalletUs ? CargoWeightUnitEnum.LBS : CargoWeightUnitEnum.KG;

  return {
    value: null,
    unit
  };
};

export const getNewCargoTypeLabel = (cargoType: Optional<NewCargoType>): string => {
  return cargoType ? newCargoTypeLabelMapping[cargoType] : '';
};
