import type { RoutesConfig } from './types';

export const SHIPMENT_ROUTE_PREFIX = '/dashboard/shipments';

interface ShipmentRoutes extends RoutesConfig {
  shipmentDetails: {
    getUrl: (zencargoReference: string, tab?: string) => string;
    path: string;
  };
  shipmentDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
  };
  shipmentDetailsPageIndex: {
    getUrl: () => string;
    path: string;
  };
  shipmentDetailsPageOrders: {
    getUrl: (zencargoReference: string, orderId: string, tab?: string) => string;
    path: string;
  };
  shipmentEditCargo: {
    getUrl: (zencargoReference: string, cargoId: string) => string;
    path: string;
  };
  shipmentIndex: {
    getUrl: () => string;
    path: string;
  };
  shipmentTransportScheduleCreate: {
    getUrl: (zencargoReference: string) => string;
    path: string;
  };
  shipmentTransportScheduleUpdate: {
    getUrl: (zencargoReference: string) => string;
    path: string;
  };
}

const shipmentRoutes: ShipmentRoutes = {
  shipmentDetails: {
    path: `${SHIPMENT_ROUTE_PREFIX}/:id?`,
    getUrl: (zencargoReference: string, tab?: string): string => {
      const url = `${SHIPMENT_ROUTE_PREFIX}/${zencargoReference}`;

      return `${url}/${tab || 'activity'}`;
    }
  },
  shipmentIndex: {
    path: SHIPMENT_ROUTE_PREFIX,
    getUrl: () => SHIPMENT_ROUTE_PREFIX
  },
  shipmentDetailsPage: {
    path: `${SHIPMENT_ROUTE_PREFIX}/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${SHIPMENT_ROUTE_PREFIX}/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  shipmentDetailsPageIndex: {
    path: `${SHIPMENT_ROUTE_PREFIX}/details`,
    getUrl: () => `${SHIPMENT_ROUTE_PREFIX}/details`
  },
  shipmentDetailsPageOrders: {
    path: `${SHIPMENT_ROUTE_PREFIX}/details/:zencargoReference/orders/:id?`,
    getUrl: (zencargoReference: string, orderId: string, tab?: string) =>
      `${SHIPMENT_ROUTE_PREFIX}/details/${zencargoReference}/orders/${orderId}/${tab}`
  },
  shipmentEditCargo: {
    path: `${SHIPMENT_ROUTE_PREFIX}/:id/cargo/:cargoId`,
    getUrl: (zencargoReference: string, cargoId: string) => `${SHIPMENT_ROUTE_PREFIX}/${zencargoReference}/cargo/${cargoId}`
  },
  shipmentTransportScheduleCreate: {
    path: `${SHIPMENT_ROUTE_PREFIX}/:id/transport-schedule/create`,
    getUrl: (zencargoReference: string) => `${SHIPMENT_ROUTE_PREFIX}/${zencargoReference}/transport-schedule/create`
  },
  shipmentTransportScheduleUpdate: {
    path: `${SHIPMENT_ROUTE_PREFIX}/:id/transport-schedule/update`,
    getUrl: (zencargoReference: string) => `${SHIPMENT_ROUTE_PREFIX}/${zencargoReference}/transport-schedule/update`
  }
};

export default shipmentRoutes;
