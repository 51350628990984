import * as Yup from 'yup';

import { BookingUpcomingEventEnum, ShipmentFilterStageWithin } from '@zen/Shipments/types';
import type { Nullable } from '@zen/utils/typescript';

const dateRequiredLabel: string = 'Please select date';

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  withinTimeDays: Yup.string()
    .nullable()
    .when('withinTimeStage', {
      is: (stage: Nullable<BookingUpcomingEventEnum>) => !!stage,
      then: Yup.string().nullable().required('Please select option')
    }),
  withinTimeDate: Yup.string().when('withinTimeDays', {
    is: (withinTimeDays: ShipmentFilterStageWithin) => withinTimeDays === ShipmentFilterStageWithin.SPECIFIC_DATE,
    then: Yup.string().required(dateRequiredLabel)
  }),
  withinTimeRange: Yup.object().when('withinTimeDays', {
    is: (withinTimeDays: Nullable<ShipmentFilterStageWithin>) => withinTimeDays === ShipmentFilterStageWithin.DATE_RANGE,
    then: Yup.object().shape({
      startDate: Yup.string().nullable().required(dateRequiredLabel),
      endDate: Yup.string().nullable().required(dateRequiredLabel)
    })
  })
});
