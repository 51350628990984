import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import type { NewCargo } from '@zen/Cargo';
import CargoInfo from '@zen/Cargo/components/CargoInfo';

import CargoActionDropdown from '../components/CargoActionDropdown';
import CargoDetails from '../components/CargoDetails';
import { formatWeight, getNewCargoTypeLabel } from '../helpers';

interface Props {
  canManageCargo: boolean;
  cargo: NewCargo;
  onDelete: (cargoId: string) => void;
  zencargoReference: string;
}

const FullContainerDetails: FC<Props> = ({ canManageCargo, cargo, onDelete, zencargoReference }) => {
  const { cargoType, containerNumber, containerSealNumber, grossWeight, hazardous, id, overweight, refrigerated, volume } = cargo;

  const title: string = getNewCargoTypeLabel(cargoType);
  const canManageLots: boolean = checkPermission(cargo, 'canManageAssignedLots');
  const showCargoActionDropdown: boolean = canManageCargo || canManageLots;

  return (
    <CargoDetails
      cargoOptions={{ hazardous: !!hazardous, overweight: !!overweight, reefer: !!refrigerated }}
      cargoType={cargoType}
      isNewCargo={true}
      title={title}
    >
      {showCargoActionDropdown && (
        <CargoActionDropdown
          canManageAssignedLots={canManageLots}
          canManageCargo={canManageCargo}
          cargoId={cargo.id}
          onDelete={() => onDelete(id)}
          zencargoReference={zencargoReference}
        />
      )}
      <div className="grid grid-cols-4 gap-4">
        <CargoInfo title="Container number">{containerNumber}</CargoInfo>
        <CargoInfo title="CBM">{volume?.value}</CargoInfo>
        <CargoInfo title="Gross weight">{formatWeight(grossWeight)}</CargoInfo>
        <CargoInfo title="Seal number">{containerSealNumber}</CargoInfo>
      </div>
    </CargoDetails>
  );
};

export default FullContainerDetails;
