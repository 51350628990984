import type { FC } from 'react';

import { Switch } from '@zen/DesignSystem';
import { useSubscriptionContext } from '@zen/Shipments/contexts/SubscriptionContext';

const SubscriptionIndicator: FC = () => {
  const { showSubscribed, setShowSubscribed } = useSubscriptionContext();

  return (
    <Switch className="mb-2" isChecked={showSubscribed} label="Show subscribed shipments only" onChange={setShowSubscribed} />
  );
};

export default SubscriptionIndicator;
