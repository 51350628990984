import type { FC, ReactNode } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { TabConfig, Tabs } from '@zen/DesignSystem';

export interface RouteTabConfig {
  className?: string;
  component: ReactNode;
  isDefault?: boolean;
  label: ReactNode;
  path: string;
  routePath?: string;
  totalCount?: number;
}

interface Props {
  tabsConfig: RouteTabConfig[];
}

const RouteTabs: FC<Props> = ({ tabsConfig }) => {
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const { url } = useRouteMatch();

  const routes: string[] = pathname.replace(`${url}/`, '').split('/');
  const activeTab: string = routes[0];

  const prepareConfigForTabs = (config: RouteTabConfig[]): TabConfig[] => {
    return config.map(
      ({ label, path, totalCount, className }: RouteTabConfig): TabConfig => ({
        isActive: activeTab === path,
        label,
        value: `${url}/${path}${search}`,
        totalCount,
        className
      })
    );
  };

  const handleTabClick = (tab: string) => push(tab);

  return <Tabs onTabClick={handleTabClick} tabsConfig={prepareConfigForTabs(tabsConfig)} />;
};

export default RouteTabs;
