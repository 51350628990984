import moment from 'moment';
import pluralize from 'pluralize';

import { Pill, PillType } from '@zen/DesignSystem';

interface Props {
  className?: string;
  date: string;
  noDifferenceMessage?: string;
  referenceDate: string;
  renderOnTime?: boolean;
}

const DatesDifference = (props: Props) => {
  const { className, date, referenceDate, noDifferenceMessage = 'On time', renderOnTime = true } = props;
  const delayInDays: number = moment(date).diff(referenceDate, 'days');
  const count: number = Math.abs(delayInDays);
  const onTime: boolean = delayInDays === 0;
  const sign: string = delayInDays > 0 ? '+' : '-';
  const label: string = onTime ? noDifferenceMessage : `${sign}${count} ${pluralize('day', count)}`;

  if (!renderOnTime) {
    return null;
  }

  const renderPill = () => {
    const getPillType = (): PillType | undefined => {
      if (delayInDays > 0) {
        return 'problem';
      }

      if (delayInDays < 0) {
        return 'warning';
      }
    };

    return (
      <Pill className={className} type={getPillType()}>
        {label}
      </Pill>
    );
  };

  return (
    <div className={className} data-testid="dates-difference">
      {renderPill()}
    </div>
  );
};

export default DatesDifference;
