import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IntakePlanningPeriodsQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  productId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  dateRange: GraphQLTypes.DateRangeInput;
  destinationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type IntakePlanningPeriodsQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & {
      intakePlanningPeriods: Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Period' } & Pick<GraphQLTypes.Period, 'quantityArriving' | 'quantityPlanned'> & {
              dateRange: { __typename?: 'DateRange' } & Pick<GraphQLTypes.DateRange, 'startDate' | 'endDate'>;
              valueArriving?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>;
              valuePlanned?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>;
            }
        >
      >;
    }
  >;
};

export const IntakePlanningPeriodsDocument = /* #__PURE__ */ gql`
  query intakePlanningPeriods($accountUuid: String!, $productId: String, $dateRange: DateRangeInput!, $destinationId: String) {
    account(uuid: $accountUuid) {
      intakePlanningPeriods(dateRange: $dateRange, productId: $productId, destinationId: $destinationId) {
        dateRange {
          startDate
          endDate
        }
        quantityArriving
        quantityPlanned
        valueArriving {
          currency
          value
        }
        valuePlanned {
          currency
          value
        }
      }
    }
  }
`;

/**
 * __useIntakePlanningPeriodsQuery__
 *
 * To run a query within a React component, call `useIntakePlanningPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntakePlanningPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntakePlanningPeriodsQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      productId: // value for 'productId'
 *      dateRange: // value for 'dateRange'
 *      destinationId: // value for 'destinationId'
 *   },
 * });
 */
export function useIntakePlanningPeriodsQuery(
  baseOptions: Apollo.QueryHookOptions<IntakePlanningPeriodsQueryResult, IntakePlanningPeriodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<IntakePlanningPeriodsQueryResult, IntakePlanningPeriodsQueryVariables>(
    IntakePlanningPeriodsDocument,
    options
  );
}
export function useIntakePlanningPeriodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IntakePlanningPeriodsQueryResult, IntakePlanningPeriodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<IntakePlanningPeriodsQueryResult, IntakePlanningPeriodsQueryVariables>(
    IntakePlanningPeriodsDocument,
    options
  );
}
export type IntakePlanningPeriodsQueryHookResult = ReturnType<typeof useIntakePlanningPeriodsQuery>;
export type IntakePlanningPeriodsLazyQueryHookResult = ReturnType<typeof useIntakePlanningPeriodsLazyQuery>;
