import type { FC, ReactNode } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import { Form } from '@zen/Components/Form';
import FormLevelSelect from '@zen/Components/Form/FormLevelSelect';
import FormRoleSelect from '@zen/Components/Form/FormRoleSelect';
import LandingPage from '@zen/Components/LandingPage';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useUpdateUserPreferencesMutation } from '../graphql';
import type { ProfileRolesAndLevel } from '../types';

const validationSchema = Yup.object().shape({
  roles: Yup.array().min(1).required(),
  level: Yup.string().nullable().required('Please select your level')
});

const initialValues: ProfileRolesAndLevel = {
  roles: [],
  level: null
};

interface Props {
  onSuccess?: () => void;
}

const CaptureProfileRolesPage: FC<Props> = ({ onSuccess }) => {
  const [updateProfile] = useUpdateUserPreferencesMutation();
  const { push } = useHistory();
  const { addError } = useNotification();

  const handleSubmit = (values: ProfileRolesAndLevel): Promise<IOkOrErrorResult> =>
    performFormMutation({
      mutationFn: () =>
        updateProfile({
          variables: { input: values }
        }),
      onError: addError
    });

  const handleSuccess = (): void => {
    return onSuccess ? onSuccess() : push('/dashboard');
  };

  const subTitle: ReactNode = (
    <>
      {' '}
      So that we can provide you a richer experience on Zencargo,
      <br /> we need a couple of details about your role.{' '}
    </>
  );

  return (
    <LandingPage data-testid="capture-profile-roles-page" subTitle={subTitle} title="Tell us a little more about yourself">
      <Form
        buttonText="Proceed to your account"
        formName="CaptureProfileRolesForm"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        validationSchema={validationSchema}
      >
        {() => (
          <>
            <FormRoleSelect />
            <FormLevelSelect />
          </>
        )}
      </Form>
    </LandingPage>
  );
};

export default CaptureProfileRolesPage;
