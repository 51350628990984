import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceGetAccrualsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type FinanceGetAccrualsQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & {
      booking?: GraphQLTypes.Maybe<
        { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
            accruals?: GraphQLTypes.Maybe<
              Array<
                { __typename?: 'Accrual' } & Pick<
                  GraphQLTypes.Accrual,
                  'id' | 'state' | 'totalCost' | 'totalRevenue' | 'totalMarginPercentage' | 'totalMargin'
                > & {
                    canArchive: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                    publishedAt?: GraphQLTypes.Maybe<
                      { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
                    >;
                    publishedBy?: GraphQLTypes.Maybe<{ __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username'>>;
                    updatedAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>;
                    updatedBy?: GraphQLTypes.Maybe<{ __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username'>>;
                  }
              >
            >;
          }
      >;
    }
  >;
};

export const FinanceGetAccrualsDocument = /* #__PURE__ */ gql`
  query financeGetAccruals($accountId: String!, $zencargoReference: String!) {
    account(uuid: $accountId) {
      booking(zencargoReference: $zencargoReference) {
        zencargoReference
        accruals {
          canArchive {
            value
          }
          id
          publishedAt {
            date
            dateTime
          }
          publishedBy {
            username
          }
          state
          totalCost
          totalRevenue
          totalMarginPercentage
          totalMargin
          updatedAt {
            date
            dateTime
          }
          updatedBy {
            username
          }
        }
      }
    }
  }
`;

/**
 * __useFinanceGetAccrualsQuery__
 *
 * To run a query within a React component, call `useFinanceGetAccrualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceGetAccrualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceGetAccrualsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useFinanceGetAccrualsQuery(
  baseOptions: Apollo.QueryHookOptions<FinanceGetAccrualsQueryResult, FinanceGetAccrualsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<FinanceGetAccrualsQueryResult, FinanceGetAccrualsQueryVariables>(FinanceGetAccrualsDocument, options);
}
export function useFinanceGetAccrualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FinanceGetAccrualsQueryResult, FinanceGetAccrualsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<FinanceGetAccrualsQueryResult, FinanceGetAccrualsQueryVariables>(
    FinanceGetAccrualsDocument,
    options
  );
}
export type FinanceGetAccrualsQueryHookResult = ReturnType<typeof useFinanceGetAccrualsQuery>;
export type FinanceGetAccrualsLazyQueryHookResult = ReturnType<typeof useFinanceGetAccrualsLazyQuery>;
