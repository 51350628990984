import cx from 'classnames';
import { forwardRef, SyntheticEvent } from 'react';

import { Icon } from '@zen/DesignSystem';

interface Props {
  className?: string;
  isDragging?: boolean;
}

const DragHandle = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  const { isDragging, className } = props;
  const classNames: string = cx(
    'text-grey-base',
    {
      'cursor-grab': !isDragging,
      'cursor-grabbing': isDragging
    },
    className
  );

  const handleClick = (event: SyntheticEvent): void => event.stopPropagation();

  return (
    <div
      ref={forwardedRef}
      className={classNames}
      data-component="table-drag-handle"
      data-testid="drag-handle"
      onClick={handleClick}
    >
      <Icon icon="zicon-drag-handle" />
    </div>
  );
});

export default DragHandle;
