import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type VoyageMilestonesQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type VoyageMilestonesQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference' | 'modeOfTransport'> & {
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    tracking?: GraphQLTypes.Maybe<
                      { __typename?: 'Tracking' } & Pick<
                        GraphQLTypes.Tracking,
                        'shippingOrderState' | 'shippingOrderReleasedOn' | 'shippingOrderConfirmedOn'
                      >
                    >;
                  }
              >;
              canUpdateStage: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              milestones?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'VoyageMilestone' } & Pick<
                    GraphQLTypes.VoyageMilestone,
                    'id' | 'name' | 'vesselName' | 'current' | 'occurred' | 'portName' | 'primary'
                  > & {
                      actual?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                      >;
                      originalEstimate?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                      >;
                      latestEstimate?: GraphQLTypes.Maybe<
                        { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
                      >;
                      updatedAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>;
                      updatedBy?: GraphQLTypes.Maybe<
                        { __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username' | 'zencargoEmployee'>
                      >;
                      delays: Array<
                        { __typename?: 'VoyageDelay' } & Pick<GraphQLTypes.VoyageDelay, 'id' | 'description' | 'reason'>
                      >;
                      location?: GraphQLTypes.Maybe<
                        | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            })
                        | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            })
                        | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            })
                      >;
                    }
                >
              >;
            }
        >
      >
    >;
  };
};

export const VoyageMilestonesDocument = /* #__PURE__ */ gql`
  query voyageMilestones($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        modeOfTransport
        cargo {
          mode
          tracking {
            shippingOrderState
            shippingOrderReleasedOn
            shippingOrderConfirmedOn
          }
        }
        canUpdateStage {
          value
        }
        milestones {
          id
          name
          vesselName
          current
          occurred
          actual {
            date
            timeZone
          }
          originalEstimate {
            date
            timeZone
          }
          latestEstimate {
            date
            timeZone
          }
          updatedAt {
            date
          }
          updatedBy {
            username
            zencargoEmployee
          }
          portName
          delays {
            id
            description
            reason
          }
          location {
            id
            label {
              short
              long
            }
          }
          primary
          vesselName
        }
      }
    }
  }
`;

/**
 * __useVoyageMilestonesQuery__
 *
 * To run a query within a React component, call `useVoyageMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoyageMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoyageMilestonesQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useVoyageMilestonesQuery(
  baseOptions: Apollo.QueryHookOptions<VoyageMilestonesQueryResult, VoyageMilestonesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<VoyageMilestonesQueryResult, VoyageMilestonesQueryVariables>(VoyageMilestonesDocument, options);
}
export function useVoyageMilestonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VoyageMilestonesQueryResult, VoyageMilestonesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<VoyageMilestonesQueryResult, VoyageMilestonesQueryVariables>(VoyageMilestonesDocument, options);
}
export type VoyageMilestonesQueryHookResult = ReturnType<typeof useVoyageMilestonesQuery>;
export type VoyageMilestonesLazyQueryHookResult = ReturnType<typeof useVoyageMilestonesLazyQuery>;
