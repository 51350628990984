import type { FC } from 'react';

import type { NewCargo } from '@zen/Cargo';
import { CargoItemTypeEnum } from '@zen/Cargo';
import { getLegacyCargoItemType } from '@zen/Cargo/cargoDictionaryMapping.helper';
import { getLooseNewCargoIcon } from '@zen/Cargo/helpers';
import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import type { NewCargoType } from '@zen/types';
import { newCargoTypeLabelMapping } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  cargo: Partial<NewCargo>;
}

const NewCargoHeadline: FC<Props> = ({ cargo }) => {
  const { cargoType } = cargo;
  const legacyCargoType: Optional<CargoItemTypeEnum> = getLegacyCargoItemType(cargoType);

  if (!cargoType || !legacyCargoType) {
    return null;
  }

  const cargoTypeMapping: Record<CargoItemTypeEnum, { icon: IconName; label: string }> = {
    [CargoItemTypeEnum.CONTAINER]: {
      icon: 'zicon-shipment',
      label: newCargoTypeLabelMapping[cargoType as NewCargoType]
    },
    [CargoItemTypeEnum.LOOSE_CARGO]: {
      icon: getLooseNewCargoIcon(cargoType as NewCargoType),
      label: newCargoTypeLabelMapping[cargoType as NewCargoType]
    },
    [CargoItemTypeEnum.VEHICLE]: {
      icon: 'zicon-road',
      label: newCargoTypeLabelMapping[cargoType as NewCargoType]
    }
  };

  const { icon, label } = cargoTypeMapping[legacyCargoType];

  return (
    <div className="flex items-center text-navy-base text-sm font-bold" data-testid="cargo-headline">
      <Icon className="text-xl mr-2" icon={icon} />
      <span>{label}</span>
    </div>
  );
};

export default NewCargoHeadline;
