import { mergeWith } from 'lodash';

import type { CargoDimensionsType, Dimensions, LooseCargoFormData, PalletNewCargoType } from '@zen/Cargo';
import { CargoDimensionsUnitEnum, LooseCargoTypeEnum, PalletTypeEnum } from '@zen/Cargo';
import type { Option } from '@zen/DesignSystem';
import type { NewCargoType } from '@zen/types';
import { cargoTypeLabelMapping, cargoValues, newCargoTypeLabelMapping } from '@zen/types';
import type { DeepNullable, Modify, Nullable, Optional } from '@zen/utils/typescript';
import { deepMergeNilValues } from '@zen/utils/utils';

import { isNewCargoPalletType } from '../../helpers';

export const palletOptions: Option<PalletTypeEnum>[] = [
  { label: cargoTypeLabelMapping[PalletTypeEnum.EURO], value: PalletTypeEnum.EURO },
  { label: cargoTypeLabelMapping[PalletTypeEnum.UK], value: PalletTypeEnum.UK },
  { label: cargoTypeLabelMapping[PalletTypeEnum.US], value: PalletTypeEnum.US },
  { label: cargoTypeLabelMapping[PalletTypeEnum.OTHER], value: PalletTypeEnum.OTHER }
];

export const palletNewCargoOptions: Option<NewCargoType>[] = [
  { label: newCargoTypeLabelMapping[cargoValues.loosePalletEuro], value: cargoValues.loosePalletEuro },
  { label: newCargoTypeLabelMapping[cargoValues.loosePalletUk], value: cargoValues.loosePalletUk },
  { label: newCargoTypeLabelMapping[cargoValues.loosePalletUs], value: cargoValues.loosePalletUs },
  { label: newCargoTypeLabelMapping[cargoValues.loosePalletOther], value: cargoValues.loosePalletOther }
];

const palletTypeNewCargoDimensionsMapping: Record<PalletNewCargoType, Dimensions> = {
  [cargoValues.loosePalletEuro]: {
    length: 120,
    width: 80,
    height: null,
    unit: CargoDimensionsUnitEnum.CM
  },
  [cargoValues.loosePalletUk]: {
    length: 120,
    width: 100,
    height: null,
    unit: CargoDimensionsUnitEnum.CM
  },
  [cargoValues.loosePalletUs]: {
    length: 48,
    width: 40,
    height: null,
    unit: CargoDimensionsUnitEnum.IN
  },
  [cargoValues.loosePalletOther]: {
    length: null,
    width: null,
    height: null,
    unit: CargoDimensionsUnitEnum.CM
  }
};

const palletTypeDimensionsMapping: Record<PalletTypeEnum, CargoDimensionsType> = {
  [PalletTypeEnum.EURO]: {
    length: 120,
    width: 80,
    height: null,
    metric: CargoDimensionsUnitEnum.CM
  },
  [PalletTypeEnum.UK]: {
    length: 120,
    width: 100,
    height: null,
    metric: CargoDimensionsUnitEnum.CM
  },
  [PalletTypeEnum.US]: {
    length: 48,
    width: 40,
    height: null,
    metric: CargoDimensionsUnitEnum.IN
  },
  [PalletTypeEnum.OTHER]: {
    length: null,
    width: null,
    height: null,
    metric: CargoDimensionsUnitEnum.CM
  }
};

export const getDefaultDimensionsNewCargo = (cargoType: Optional<NewCargoType>): Dimensions => {
  if (!cargoType || !isNewCargoPalletType(cargoType)) {
    return {
      length: null,
      width: null,
      height: null,
      unit: CargoDimensionsUnitEnum.CM
    };
  }

  return palletTypeNewCargoDimensionsMapping[cargoType as PalletNewCargoType];
};

export const getDefaultDimensions = (palletType: Optional<PalletTypeEnum>): CargoDimensionsType => {
  if (!palletType) {
    return {
      length: null,
      width: null,
      height: null,
      metric: CargoDimensionsUnitEnum.CM
    };
  }

  return palletTypeDimensionsMapping[palletType];
};

export const mergeInitialValues = (values: Partial<LooseCargoFormData> = {}): DeepNullable<LooseCargoFormData> => {
  const defaultValues: Modify<DeepNullable<LooseCargoFormData>, { quantity: number }> = {
    actualCbm: null,
    containerNumber: null,
    chargeableWeight: {
      value: null,
      unit: 'KG'
    },
    dimensions: getDefaultDimensionsNewCargo(values?.cargoType),
    grossWeight: {
      value: null,
      unit: 'KG'
    },
    hazardous: false,
    looseCargoType: null,
    cargoType: null,
    quantity: 1,
    refrigerated: false,
    stackable: false,
    trailerId: null
  };

  return mergeWith({}, values, defaultValues, deepMergeNilValues);
};

export const isPalletType = (cargoType: Nullable<PalletTypeEnum | LooseCargoTypeEnum>): boolean => {
  return (
    cargoType === PalletTypeEnum.EURO ||
    cargoType === PalletTypeEnum.OTHER ||
    cargoType === PalletTypeEnum.UK ||
    cargoType === PalletTypeEnum.US
  );
};
