import { get } from 'lodash';

import type { CargoTransition, CoreCargo } from '@zen/Cargo';
import transitionCargo from '@zen/Cargo/cargoTransition.helper';
import type { Optional } from '@zen/utils/typescript';

import type { FlatShipmentDetailsBoard, ShipmentDetailsBoardData } from './types';

export const getFlatShipmentDetailsBoard = (shipment: ShipmentDetailsBoardData): FlatShipmentDetailsBoard => {
  const { cargo, cargos, keyContact, operationsManager } = shipment;

  const cargoInfo: Optional<CargoTransition> = transitionCargo(cargo, cargos as CoreCargo[]);
  const keyContactName: string = keyContact ? `${keyContact.firstName} ${keyContact.lastName}` : '-';
  const operationsManagerName: string = operationsManager ? `${operationsManager.firstName} ${operationsManager.lastName}` : '-';

  return {
    archivedAt: get(shipment, 'archivedAt.date'),
    canEditOrigin: get(shipment, 'canEditOrigin.value'),
    canEditDestination: get(shipment, 'canEditDestination.value'),
    canUpdateCarrierBookingReference: get(shipment, 'canUpdateCarrierBookingReference.value'),
    canUpdateCustomsOnly: get(shipment, 'canUpdateCustomsOnly.value'),
    canUpdateIncoterms: get(shipment, 'canUpdateIncoterms.value'),
    canUpdateMasterBillOfLading: get(shipment, 'canUpdateMasterBillOfLading.value'),
    canUpdateModeOfTransport: get(shipment, 'canUpdateModeOfTransport.value'),
    canUpdateHouseBillOfLading: get(shipment, 'canUpdateHouseBillOfLading.value'),
    canUpdateShipmentStatus: get(shipment, 'canUpdateShipmentStatus.value'),
    canUpdateShipmentType: get(shipment, 'canUpdateShipmentType.value'),
    canUpdateTradeRole: get(shipment, 'canUpdateTradeRole.value'),
    canUpdateVehiclePlateNumber: get(shipment, 'canUpdateVehiclePlateNumber.value'),
    canViewKeyContact: get(shipment, 'canViewKeyContact.value'),
    canViewMasterBillOfLading: get(shipment, 'canViewMasterBillOfLading.value'),
    canViewOperationsManager: get(shipment, 'canViewOperationsManager.value'),
    canViewShipmentTracking: get(shipment, 'canViewShipmentTracking.value'),
    cargoMode: cargoInfo?.mode!,
    cargoItems: cargoInfo?.cargoList ? cargoInfo?.cargoList : [],
    carrierBookingReference: get(shipment, 'cargo.tracking.carrierBookingReference'),
    consignee: get(shipment, 'consignee.label.short', '-'),
    consignor: get(shipment, 'consignor.label.short', '-'),
    customsOnly: shipment.customsOnly,
    estimatedArrival: shipment.estimatedArrival,
    estimatedDeparture: shipment.estimatedDeparture,
    houseBillOfLading: get(shipment, 'houseBillOfLading')!,
    incoterms: get(shipment, 'incoterms.value'),
    keyContact: keyContactName,
    masterBillOfLading: get(shipment, 'masterBillOfLading')!,
    modeOfTransport: get(shipment, 'modeOfTransport')!,
    networksDestination: get(shipment, 'networksDestination.label.short'),
    networksOrigin: get(shipment, 'networksOrigin.label.short'),
    operationsManager: operationsManagerName,
    shipmentStatus: get(shipment, 'stage.statusUpdate.title'),
    shipmentType: get(shipment, 'shipmentType')!,
    specialInstructions: get(shipment, 'specialInstructions.message'),
    stageName: shipment?.voyageMilestone?.name || shipment?.bookingStage?.name,
    tradeRole: get(shipment, 'tradeRole')!,
    transportSchedule: get(shipment, 'transportSchedule')
  };
};
