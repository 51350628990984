import type { FC } from 'react';

import { IconButton } from '@zen/DesignSystem';

interface Props {
  cargoIds: string[];
  currentCargoId: string;
  onChange: (cargoId: string) => void;
}

const CargoSwitcher: FC<Props> = ({ cargoIds, currentCargoId, onChange }) => {
  const cargoIndex: number = cargoIds.findIndex((cargoId) => cargoId === currentCargoId);
  const isFirstCargo: boolean = cargoIndex === 0;
  const isLastCargo: boolean = cargoIndex === cargoIds.length - 1;

  const handlePrevClick = (): void => onChange(cargoIds[cargoIndex - 1]);

  const handleNextClick = (): void => onChange(cargoIds[cargoIndex + 1]);

  if (cargoIds.length === 1) {
    return null;
  }

  return (
    <div className="flex items-center relative" data-testid="cargo-switcher">
      <IconButton
        className="mr-4"
        disabled={isFirstCargo}
        icon="zicon-chevron-left-small"
        onClick={handlePrevClick}
        variant="secondary"
      />
      <IconButton disabled={isLastCargo} icon="zicon-chevron-right-small" onClick={handleNextClick} variant="secondary" />
    </div>
  );
};

export default CargoSwitcher;
