import { FC, useEffect } from 'react';

import type { ScheduleItemType } from '@zen/CollectionAndDelivery/Details/types';
import { useConfirmCollectionMutation, useConfirmDeliveryMutation } from '@zen/CollectionAndDelivery/graphql';
import { useMutationIteration } from '@zen/utils/hooks/useMutationIteration';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  cargoIds: string[];
  handleError?: (errorIds: string[], message: string) => void;
  handleSuccess: () => void;
  scheduleType: ScheduleItemType;
  zencargoReference: string;
}

const ConfirmSchedule: FC<Props> = (props) => {
  const { zencargoReference, cargoIds, scheduleType, handleSuccess, handleError } = props;
  const multiCargo = cargoIds.length > 1;
  const { addSuccess } = useNotification();

  const onError = (errorItems: string[]) => {
    const totalErrors = errorItems.length;
    const errorMessage = multiCargo
      ? `There was an error confirming ${totalErrors} cargos`
      : 'There was an error confirming this cargo';

    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleError(errorItems, errorMessage);
  };

  const onSuccess = () => {
    const successMessage = multiCargo ? 'All selected cargos have been confirmed' : 'Cargo has been confirmed';

    addSuccess(successMessage);
    handleSuccess();
  };

  const { handleMutation } = useMutationIteration(cargoIds, onSuccess, onError);

  const [confirmCollection] = useConfirmCollectionMutation();

  const [confirmDelivery] = useConfirmDeliveryMutation();

  const confirm = scheduleType === 'collection' ? confirmCollection : confirmDelivery;

  useEffect(() => {
    handleMutation((id: string) =>
      confirm({
        variables: { input: { zencargoReference, id } },
        refetchQueries: ['voyageMilestones', 'getRouteProgressDetails'],
        awaitRefetchQueries: true
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, cargoIds, zencargoReference]);

  return null;
};

export default ConfirmSchedule;
