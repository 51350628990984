import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import QueryHandler from '@zen/Components/QueryHandler';
import { isOceanShipment, isRoadShipment } from '@zen/Shipments';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';
import type { Optional } from '@zen/utils/typescript';

import OceanTrackingStatus from '../Tracking/OceanTrackingStatus';
import RoadTrackingStatus from '../Tracking/RoadTrackingStatus';
import BookingManagement from './BookingManagement';
import BookingStages from './BookingStages';
import { useGetRouteProgressDetailsQuery } from './graphql';
import SkeletonRouteProgress from './Loading/SkeletonRouteProgress';
import { CargoModeEnum, RouteProgressDetails, StageValue } from './types';
import VoyageMilestones from './VoyageMilestones';

interface Props {
  zencargoReference: string;
}

const RouteProgress: FC<Props> = ({ zencargoReference }) => {
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();
  const {
    data: bookingStagesData,
    loading: isBookingStagesLoading,
    error: bookingStagesError,
    refetch: refetchBookingStages
  } = useGetRouteProgressDetailsQuery({
    variables: {
      newCargoService: !!isNewCargoServiceEnabled,
      zencargoReference
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const currentStage: Optional<StageValue> = bookingStagesData?.bookings?.nodes?.[0]?.stage?.value;
  const isConfirmed: boolean = currentStage === StageValue.BOOKED;

  const isInVoyageStage: boolean =
    !!currentStage &&
    currentStage !== StageValue.QUOTE_REQUESTED &&
    currentStage !== StageValue.BOOKING_REQUESTED &&
    currentStage !== StageValue.PENDING &&
    !isConfirmed;
  const isInBookingStage: boolean = !!currentStage && !isInVoyageStage;

  const handleBookingSettingsChange = (): void => {
    window.setTimeout(() => {
      refetchBookingStages();
    }, 1000);
  };

  return (
    <>
      <QueryHandler
        data={bookingStagesData?.bookings?.nodes?.[0]}
        error={!!bookingStagesError}
        isLoading={isBookingStagesLoading}
        loadingComponent={<SkeletonRouteProgress />}
      >
        {(booking: RouteProgressDetails) => {
          const { cargo, cargos, modeOfTransport } = booking;
          const isOceanFCLShipment: boolean = isOceanShipment(modeOfTransport) && cargo?.mode === CargoModeEnum.FCL;
          const isRoadFTLShipment: boolean = isRoadShipment(modeOfTransport) && cargo?.mode === CargoModeEnum.FTL;
          const hasCargoItems: boolean = !!cargos?.length || !!cargo?.cargoItems?.length;
          const canViewShipmentTracking: boolean = checkPermission<RouteProgressDetails>(booking, 'canViewShipmentTracking');
          const showShipmentTracking: boolean = canViewShipmentTracking && hasCargoItems;
          const canAccessBookingManagement: boolean = checkPermission<RouteProgressDetails>(
            booking,
            'canUpdateBookingManagementSettings'
          );

          return (
            <>
              {canAccessBookingManagement && (
                <BookingManagement onSettingsChanged={handleBookingSettingsChange} zencargoReference={zencargoReference} />
              )}

              {showShipmentTracking && (
                <>
                  {isRoadFTLShipment && (
                    <RoadTrackingStatus
                      onTrackingDataChange={handleBookingSettingsChange}
                      zencargoReference={zencargoReference}
                    />
                  )}

                  {isOceanFCLShipment && (
                    <OceanTrackingStatus
                      onTrackingDataChange={handleBookingSettingsChange}
                      zencargoReference={zencargoReference}
                    />
                  )}
                </>
              )}

              <BookingStages
                allowUpdateStage={booking.canUpdateStage?.value && isInBookingStage}
                isCurrentInBookingStages={isInBookingStage}
                stages={booking.bookingStages || []}
                zencargoReference={zencargoReference}
              />
            </>
          );
        }}
      </QueryHandler>
      <VoyageMilestones
        consolidatedShipmentId={bookingStagesData?.bookings?.nodes?.[0]?.consolidatedShipment?.id}
        isCurrentInVoyageMilestones={isInVoyageStage || isConfirmed}
        zencargoReference={zencargoReference}
      />
    </>
  );
};

export default RouteProgress;
