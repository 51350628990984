import { RouteComponentProps, withRouter } from 'react-router';

import { Headline } from '@zen/DesignSystem';
import type { NetworksAssignableInterface } from '@zen/Networks';
import { mergeQueryParams } from '@zen/utils/QueryParams/queryParams';

import type { IntakeFilters } from '../types';
import FiltersForm from './FiltersForm';
import { getFilters } from './helpers';

interface FiltersProps extends RouteComponentProps {
  className?: string;
  destinations: NetworksAssignableInterface[];
}

const Filters = ({ className = '', destinations, history, location }: FiltersProps) => {
  const filters = getFilters(location.search);

  const setFilters = (values: IntakeFilters) => {
    history.replace({
      ...location,
      search: mergeQueryParams(location.search, values)
    });
  };

  return (
    <div className={`pt-8 ${className}`}>
      <Headline className="text-grey-dark mb-4" level={2}>
        View options
      </Headline>
      <FiltersForm destinations={destinations} initialValues={filters} onSubmit={setFilters} />
    </div>
  );
};

export default withRouter(Filters);
