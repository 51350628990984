import type { FC } from 'react';

import PageWithTabs from '@zen/Components/PageWithTabs';
import Slideout from '@zen/Components/Slideout';
import type { Product } from '@zen/Products/types';

import OrderIssues from '../OrderIssues';
import ProductIssues from '../ProductIssues';
import IssueListHeader from './IssueListHeader';
import { IssueType, PurchaseOrdersWithIssues } from './types';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  orders: PurchaseOrdersWithIssues[];
  products: Product[];
}

export const IssueList: FC<Props> = ({ isVisible, onClose, products, orders }) => {
  const renderCount = (items: PurchaseOrdersWithIssues[] | Product[]) => {
    if (!items.length) {
      return null;
    }

    return <div className="bg-red-base ml-2 text-white py-1 px-2 rounded-lg">{items.length}</div>;
  };

  const tabs = [
    {
      label: <>Products with issues {renderCount(products)}</>,
      path: IssueType.PRODUCT,
      component: <ProductIssues products={products} />
    },
    {
      isDefault: true,
      label: 'Orders with issues',
      path: IssueType.ORDER,
      component: <OrderIssues ordersWithIssues={orders} />
    }
  ];

  return (
    <Slideout onOutsideClick={onClose} overlay={true} show={isVisible}>
      <div className="flex flex-col h-full w-160">
        <PageWithTabs
          contentClassName="px-10 flex-1 overflow-y-auto"
          header={<IssueListHeader onClose={onClose} />}
          tabsConfig={tabs}
        />
      </div>
    </Slideout>
  );
};

export default IssueList;
