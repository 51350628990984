import { ReactElement, useEffect, useState } from 'react';
import { Element, scroller } from 'react-scroll';

import { useNotificationsMarkAsReadMutation } from '@zen/Notifications/graphql';

import ActivityFeedList from './ActivityFeedList';
import ActivityFeedInput from './components/ActivityFeedInput';
import EmptyState from './components/EmptyState';
import PermittedPartiesContext from './context/PermittedPartiesContext';
import { useActivityAddedSubscription } from './graphql';
import useActivityQuery from './hooks/useActivityQuery';
import { SkeletonActivityFeedLoading } from './SkeletonActivityFeedLoading';
import SubscriptionsContainer from './Subscriptions/SubscriptionsContainer';
import type {
  Activity,
  ActivityFeedItemTypeEnum,
  ActivityFeedOriginViewType,
  ActivityFeedTargetTypeEnum,
  PermittedParties
} from './types';

interface Props<T> {
  accountId: string;
  activityFeedItemsResult: T;
  fetchActivityItemsData: (activityFeed: Activity[]) => void;
  hideTimeline?: boolean;
  itemTypes?: ActivityFeedItemTypeEnum[];
  permittedParties: PermittedParties;
  targetId: string;
  targetType: ActivityFeedTargetTypeEnum;
  viewType?: ActivityFeedOriginViewType;
}

const ActivityFeed = <T extends {}>(props: Props<T>): ReactElement => {
  const {
    viewType,
    targetId,
    targetType,
    permittedParties,
    fetchActivityItemsData,
    activityFeedItemsResult,
    accountId,
    itemTypes,
    hideTimeline
  } = props;

  const [activityFeed, hasMoreResults, refetch, isLoading] = useActivityQuery(targetId, targetType, itemTypes);
  const [updatedFeed, setUpdatedFeed] = useState<Activity[]>([]);

  const [markAsRead] = useNotificationsMarkAsReadMutation();
  const hasActivityFeedItems = activityFeed.length > 0;
  const shouldDisplayList = !isLoading && hasActivityFeedItems;

  const runMarkAsReadMutation = (list: Activity[]) => {
    const lastItem: Activity = list[list.length - 1];

    const variables = {
      input: {
        targetId,
        lastNotificationItemCreatedAt: lastItem.createdAt
      }
    };

    markAsRead({ variables });
  };

  useEffect(() => {
    if (shouldDisplayList) {
      runMarkAsReadMutation(activityFeed);
    }
  }, [shouldDisplayList]); // eslint-disable-line

  useEffect(() => {
    if (updatedFeed.length) {
      runMarkAsReadMutation(updatedFeed);
      scroller.scrollTo('listStart', {
        containerId: 'activity-feed-list'
      });
    }
  }, [updatedFeed]); // eslint-disable-line

  useActivityAddedSubscription({
    fetchPolicy: 'no-cache',
    variables: { targetId, targetType },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.activityAdded) {
        const item = subscriptionData.data.activityAdded as Activity;

        if (!updatedFeed.find((feedItem) => feedItem.id === item.id)) {
          if (itemTypes && !itemTypes.find((itemType: ActivityFeedItemTypeEnum) => item.itemType === itemType)) {
            return;
          }
          setUpdatedFeed([...updatedFeed, item]);
        }
      }
    }
  });

  const list: Activity[] = [...activityFeed, ...updatedFeed];
  const hasItems = list.length > 0;

  return (
    <PermittedPartiesContext.Provider value={permittedParties}>
      <div className="h-full flex flex-col relative" data-testid="activity-feed">
        <SubscriptionsContainer accountId={accountId} targetId={targetId} targetType={targetType} />
        {isLoading && <SkeletonActivityFeedLoading />}
        <div
          className="flex-1 flex flex-col-reverse activity-feed overflow-y-auto overflow-x-hidden -ml-8 -mr-4 -mt-10"
          id="activity-feed-list"
        >
          <div className="h-0 w-full">
            &nbsp; <Element name="listStart" />
          </div>
          {!hasItems && !isLoading && <EmptyState targetType={targetType} />}
          {hasItems && (
            <ActivityFeedList<T>
              activityFeed={list}
              activityFeedItemsResult={activityFeedItemsResult}
              fetchActivityItemsData={fetchActivityItemsData}
              hasMoreResults={hasMoreResults}
              hideTimeline={hideTimeline}
              refetch={refetch}
            />
          )}
        </div>
        <div className="w-full -mb-10 pb-4 z-40">
          <ActivityFeedInput
            permittedParties={permittedParties}
            targetId={targetId}
            targetType={targetType}
            viewType={viewType}
          />
        </div>
      </div>
    </PermittedPartiesContext.Provider>
  );
};

export default ActivityFeed;
