import type { FC, ReactNode } from 'react';
import { Link as ScrollLink } from 'react-scroll';

import { Icon, TextLink } from '@zen/DesignSystem';

import type { NavItem } from './types';

interface Props {
  navItems: NavItem[];
  navigationOffset?: number;
}

const Navigation: FC<Props> = ({ navItems, navigationOffset }) => {
  const enabledNavItems: NavItem[] = navItems.filter(
    ({ enabled = true, showInNavigation = true }) => enabled && showInNavigation
  );

  const renderLink = ({ label, url, isExternalLink, onClick }: NavItem, index: number) => {
    const labelClassNames: string = 'mt-2';
    const lastItem: boolean = enabledNavItems.length - 1 === index;

    const renderScrollLink = (): ReactNode => (
      <ScrollLink
        duration={500}
        ignoreCancelEvents={true}
        isDynamic={true}
        offset={navigationOffset}
        onClick={onClick}
        smooth={true}
        to={url}
      >
        <TextLink className={labelClassNames}>{label}</TextLink>
      </ScrollLink>
    );

    const renderExternalLink = (): ReactNode => (
      <TextLink className="mt-0.75" isExternal={true} linkTo={url} onClick={onClick}>
        {label}
      </TextLink>
    );

    return (
      <div key={index}>
        {isExternalLink ? renderExternalLink() : renderScrollLink()}
        {!lastItem && <Icon className="mx-2 text-grey-lighter" icon="zicon-line" />}
      </div>
    );
  };

  return <div className="flex flex-wrap items-center">{enabledNavItems.map(renderLink)}</div>;
};

export default Navigation;
