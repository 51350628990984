import type { DateRangeInput, PurchaseOrdersWithIssues, SortInput } from '@zen/graphql/types.generated';
import type { Money } from '@zen/types';

import type { Product } from '../Products/types';
import type { IntakePlanningPeriodsQueryResult } from './graphql';

export type { ProductIntake } from '@zen/graphql/types.generated';
export { PeriodsColumnEnum, ProductLotsColumnEnum, ProductsColumnEnum } from '@zen/graphql/types.generated';

export enum IntakeViewOptions {
  ONE_WEEK = 1,
  TWO_WEEKS = 2,
  FOUR_WEEKS = 4,
  EIGHT_WEEKS = 8,
  TWELVE_WEEKS = 12,
  TWENTY_SIX_WEEKS = 26,
  FIFTY_TWO_WEEKS = 52
}

export interface IntakeFilters {
  destinationId: string;
  period: IntakeViewOptions;
  startDate: string;
}

export interface DateRange {
  endDate: string;
  startDate: string;
}

export interface IntakeFilterValues {
  dateRange: DateRangeInput;
  destinationId: string;
  order: SortInput;
}

export interface WeeklyIntakeValue {
  dateRange: DateRange;
  quantityArriving: number;
  quantityPlanned: number;
  valueArriving: Money;
  valuePlanned: Money;
}

export interface IntakeChartValue {
  dateRange: DateRange;
  endDate: string;
  quantityArriving: number;
  quantityArrivingPosition: number;
  quantityPlanned: number;
  startDate: string;
  valueArriving: string;
  valuePlanned: string;
}

export interface DataQuality {
  productsWithIssues: Product[];
  purchaseOrdersWithIssues: PurchaseOrdersWithIssues[];
  totalProducts: number;
  totalPurchaseOrders: number;
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'intakePlanningPeriods' does not exist on... Remove this comment to see the full error message
export type IntakePlanningPeriod = IntakePlanningPeriodsQueryResult['account']['intakePlanningPeriods'][0];
