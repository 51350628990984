import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import type { NewCargo } from '@zen/Cargo';
import { isAirShipment, isOceanShipment, isRoadShipment, ModeOfTransport } from '@zen/Shipments';

import { cargoValues } from '../../types/labels';
import CargoActionDropdown from '../components/CargoActionDropdown';
import CargoDetails from '../components/CargoDetails';
import CargoInfo from '../components/CargoInfo';
import { formatDimensions, formatWeight, getNewCargoTypeLabel } from '../helpers';

interface Props {
  canManageCargo: boolean;
  cargo: NewCargo;
  modeOfTransport: ModeOfTransport;
  onDelete: (cargoId: string) => void;
  zencargoReference: string;
}

const LooseCargoDetails: FC<Props> = ({ canManageCargo, cargo, modeOfTransport, onDelete, zencargoReference }) => {
  const {
    id,
    volume,
    chargeableWeight,
    containerNumber,
    cargoType,
    quantity,
    grossWeight,
    refrigerated,
    stackable,
    hazardous,
    dimensions,
    trailerId
  } = cargo;

  const isAssortedType: boolean = cargoType === cargoValues.looseAssortedCargo;
  const quantityLabel: string = quantity && !isAssortedType ? `${quantity} x ` : '';
  const cargoLabel: string = getNewCargoTypeLabel(cargoType);
  const title: string = `${quantityLabel} ${cargoLabel}`;
  const canManageLots: boolean = checkPermission(cargo, 'canManageAssignedLots');
  const showCargoActionDropdown: boolean = canManageCargo || canManageLots;

  return (
    <CargoDetails
      cargoOptions={{ hazardous: !!hazardous, reefer: !!refrigerated, stackable: !!stackable }}
      cargoType={cargoType}
      isNewCargo={true}
      title={title}
    >
      {showCargoActionDropdown && (
        <CargoActionDropdown
          canManageAssignedLots={canManageLots}
          canManageCargo={canManageCargo}
          cargoId={cargo.id}
          onDelete={() => onDelete(id)}
          zencargoReference={zencargoReference}
        />
      )}
      <div className="grid grid-cols-4 gap-4">
        <CargoInfo title="Dimensions">{formatDimensions(dimensions)}</CargoInfo>
        <CargoInfo title="CBM">{volume?.value}</CargoInfo>
        <CargoInfo title="Gross weight">{formatWeight(grossWeight)}</CargoInfo>
        {isOceanShipment(modeOfTransport) && <CargoInfo title="Container number">{containerNumber}</CargoInfo>}
        {isRoadShipment(modeOfTransport) && <CargoInfo title="Trailer ID">{trailerId}</CargoInfo>}
        {isAirShipment(modeOfTransport) && <CargoInfo title="Chargeable weight">{formatWeight(chargeableWeight)}</CargoInfo>}
      </div>
    </CargoDetails>
  );
};

export default LooseCargoDetails;
