import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type CargoSummaryUpdatedSubscriptionVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type CargoSummaryUpdatedSubscriptionResult = { __typename?: 'Subscription' } & {
  cargoSummaryUpdated: { __typename?: 'CargoSummary' } & Pick<
    GraphQLTypes.CargoSummary,
    'highestRiskLevel' | 'label' | 'totalActualCbm' | 'type'
  > & {
      totalGrossWeight?: GraphQLTypes.Maybe<
        Array<{ __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>>
      >;
      totalValueOfGoods?: GraphQLTypes.Maybe<Array<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>>;
    };
};

export const CargoSummaryUpdatedDocument = /* #__PURE__ */ gql`
  subscription cargoSummaryUpdated($zencargoReference: String!) {
    cargoSummaryUpdated(zencargoReference: $zencargoReference) {
      highestRiskLevel
      label
      totalActualCbm
      totalGrossWeight {
        metric
        value
      }
      totalValueOfGoods {
        currency
        value
      }
      type
    }
  }
`;

/**
 * __useCargoSummaryUpdatedSubscription__
 *
 * To run a query within a React component, call `useCargoSummaryUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCargoSummaryUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCargoSummaryUpdatedSubscription({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useCargoSummaryUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<CargoSummaryUpdatedSubscriptionResult, CargoSummaryUpdatedSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSubscription<CargoSummaryUpdatedSubscriptionResult, CargoSummaryUpdatedSubscriptionVariables>(
    CargoSummaryUpdatedDocument,
    options
  );
}
export type CargoSummaryUpdatedSubscriptionHookResult = ReturnType<typeof useCargoSummaryUpdatedSubscription>;
