import { useHistory } from 'react-router';

import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import AccountFormWrapper from '../components/AccountFormWrapper';
import useAuth from '../hooks/useAuth';
import LoginForm, { LoginPayload } from './LoginForm';

const Login = () => {
  const { login } = useAuth();
  const { push } = useHistory();

  const onSubmit = ({ email, password }: LoginPayload): Promise<IOkOrErrorResult> => {
    return login(email, password);
  };

  const handleSuccess = (): void => {
    push('/dashboard');
  };

  return (
    <AccountFormWrapper title="Sign in to Zencargo">
      <div className="text-grey-base text-sm leading-normal -mt-3 mb-6">Enter your details below.</div>
      <LoginForm onSubmit={onSubmit} onSuccess={handleSuccess} />
    </AccountFormWrapper>
  );
};

export default Login;
