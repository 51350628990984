import type { ActionCardConfigItem } from '@zen/Components/ActionCard/types';
import { productSettingsRoutes } from '@zen/Routes';

export const productSettingsConfig: ActionCardConfigItem[] = [
  {
    title: 'Document Templates',
    description: 'Configuration of document templates.',
    link: productSettingsRoutes.documentTemplatesIndex.getUrl()
  },
  {
    title: 'Terminals',
    description: 'Manage all seaports, airport and rail terminals.',
    link: productSettingsRoutes.manageTerminals.getUrl()
  }
];
