import type { FC } from 'react';

import useAccount from '@zen/utils/hooks/useAccount';
import { useHover } from '@zen/utils/hooks/useHover';

import type { Undefinable } from '../../utils/typescript';
import SubscriberCounter from './SubscriberCounter';
import SubscriptionsMenu from './SubscriptionsMenu';
import type { Stakeholder, Subscriber, SubscriptionStakeholder } from './types';

interface Props {
  onSubscribe: (stakeholder: SubscriptionStakeholder) => void;
  onUnsubscribe: (stakeholder: SubscriptionStakeholder) => void;
  stakeholders: Stakeholder[];
  subscribers: Subscriber[];
}

const Subscriptions: FC<Props> = ({ onSubscribe, onUnsubscribe, stakeholders, subscribers }) => {
  const { userProfile } = useAccount();
  const [ref, isHovered] = useHover();

  let currentUser: Undefinable<SubscriptionStakeholder> = subscribers.find(
    ({ contactDetails: { id } }) => id === userProfile.uuid
  );
  const isCurrentUserSubscribed: boolean = !!currentUser;

  if (!currentUser) {
    currentUser = stakeholders.find(({ contactDetails }) => contactDetails?.id === userProfile.uuid);
  }

  return (
    <div className="absolute -top-6 right-0 z-50 p-4">
      <div ref={ref} data-testid="hooverable">
        {!isHovered && <SubscriberCounter subscriberCount={subscribers.length} />}
        {isHovered && (
          <SubscriptionsMenu
            currentUser={currentUser}
            isCurrentUserSubscribed={isCurrentUserSubscribed}
            onSubscribe={onSubscribe}
            onUnsubscribe={onUnsubscribe}
            stakeholders={stakeholders}
            subscribers={subscribers}
          />
        )}
      </div>
    </div>
  );
};

export default Subscriptions;
