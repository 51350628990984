import type { FC, ReactNode } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Icon } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  text: ReactNode;
  textToCopy?: string;
}

const Copyable: FC<Props> = ({ text, textToCopy }) => {
  const { addSuccess } = useNotification();
  const iconClassNames: string =
    'ml-1 text-grey-dark cursor-pointer opacity-0 group-hover:opacity-100 hover:text-navy-base hover:bg-grey-lighter transition-all duration-150 fs-copy-icon p-1 rounded';

  const handleClick = (): void => addSuccess('Copied to clipboard');

  return (
    <div className="group">
      {text}
      <CopyToClipboard text={textToCopy || (text as string)}>
        <Icon className={iconClassNames} icon="zicon-copy" onClick={handleClick} />
      </CopyToClipboard>
    </div>
  );
};

export default Copyable;
