import type { FC, ReactElement, ReactNode } from 'react';

import Tooltip from '../Tooltip';

interface Props {
  body: ReactNode;
  children: ReactElement;
  footer: ReactNode;
  title: string;
}

const InlineDialog: FC<Props> = ({ title, body, footer, children }) => {
  const Content: ReactNode = (
    <div className="p-6 text-sm text-grey-dark leading-5">
      <h1 className="text-lg mb-2 font-bold">{title}</h1>
      <div>{body}</div>
      <div className="mt-5">{footer}</div>
    </div>
  );

  return (
    <Tooltip offset={[0, 10]} placement="bottom-start" showArrow={false} tooltipContent={Content} trigger="click">
      {children}
    </Tooltip>
  );
};

export type { Props as InlineDialogProps };
export default InlineDialog;
