import type { RoutesConfig } from './types';

interface ExchangeRatesRoutes extends RoutesConfig {
  manageExchangeRatesIndex: {
    getUrl: () => string;
    path: string;
  };
}

const exchangeRatesRoutes: ExchangeRatesRoutes = {
  manageExchangeRatesIndex: {
    getUrl: () => '/dashboard/manage-exchange-rates',
    path: '/dashboard/manage-exchange-rates'
  }
};

export default exchangeRatesRoutes;
