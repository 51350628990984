import cx from 'classnames';
import type { FC } from 'react';

type ContentWidth = 'full' | 'wide' | 'medium' | 'narrow';

interface Props {
  className?: string;
  width?: ContentWidth;
}

const PageContent: FC<Props> = (props) => {
  const { width = 'full', className } = props;

  const classNames = cx(
    {
      'max-w-screen-xl': width === 'wide',
      'max-w-screen-lg': width === 'medium',
      'max-w-screen-md': width === 'narrow'
    },
    'mx-10',
    className
  );

  return (
    <div className={classNames} data-testid="content">
      {props.children}
    </div>
  );
};

export default PageContent;
