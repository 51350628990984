import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Headline, Icon } from '@zen/DesignSystem';
import type { IconType } from '@zen/types';

interface Props {
  className?: string;
  icon?: IconType;
  subtitle?: string;
  title: string | ReactNode;
}

const renderIcon = (icon: IconType) => {
  return typeof icon === 'string' ? <Icon className="mr-2" icon={icon} /> : icon;
};

const SectionTitle: FC<Props> = ({ icon, title, subtitle, className, children }) => {
  const titleClassNames = cx('flex items-center h-12 mb-4', className);

  return (
    <div className={titleClassNames} data-testid="section-title">
      <div className="leading-normal">
        <Headline className="flex items-center text-grey-dark" level={2}>
          <>
            {icon && renderIcon(icon)}
            {title}
          </>
        </Headline>
        {subtitle && <div className="text-sm text-grey-dark">{subtitle}</div>}
      </div>
      {children}
    </div>
  );
};

export default SectionTitle;
