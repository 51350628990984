import type { Option } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import type {
  ContactFormFields,
  ContactLocationAssignment,
  CreateLocationContactInput,
  NetworksLocation,
  NetworksLocationInContact,
  UpdateLocationContactInput
} from '../types';

type ContactInput = CreateLocationContactInput | UpdateLocationContactInput;

const prepareContactData = (
  values: ContactFormFields,
  organisationId: string,
  contactId: Nullable<string> = null
): ContactInput => ({
  organisationId,
  ...(contactId && { contactId }),
  contact: {
    firstName: values.firstName,
    lastName: values.lastName,
    phoneNumber: values.phoneNumber,
    email: values.email,
    autoSubscribeToOrganisationTargets: values.autoSubscribeToOrganisationTargets
  },
  contactLocationAssignments: values.contactLocationAssignments
});

type NetworksLocationOptionFields = Pick<NetworksLocation, 'name' | 'address' | 'id'>;

const prepareLocationsData = (contactlocations: NetworksLocationOptionFields[]): Option<string>[] =>
  contactlocations.map((location: NetworksLocationOptionFields) => ({
    label: location?.name || location?.address?.street || '',
    value: location.id || ''
  }));

const getEmptyContact = (locationId: string = ''): ContactFormFields => ({
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  autoSubscribeToOrganisationTargets: false,
  contactLocationAssignments: [
    {
      locationId,
      mainContact: false
    }
  ]
});

type NetworksLocationEditFields = Pick<NetworksLocationInContact, 'id' | 'asMainContact'>;

const prepareContactLocationToEdit = (locations: NetworksLocationEditFields[]): ContactLocationAssignment[] => {
  return locations.map((location: NetworksLocationEditFields) => ({
    locationId: location.id || '',
    mainContact: location.asMainContact
  }));
};

export { getEmptyContact, prepareContactData, prepareContactLocationToEdit, prepareLocationsData };
