import type { ReactNode } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import TableLink from '@zen/Components/TableLink';
import type { TableColumn } from '@zen/DesignSystem';
import { consolidationRoutes } from '@zen/Routes';

import { formatDate } from '../../utils/dateTime/date/formatting';
import type { ConsolidatedShipment } from '../types';
import ActionHandleContainer from './ActionHandleContainer';

export const getColumnsConfiguration = (canManageConsolidation: boolean): TableColumn<ConsolidatedShipment>[] => {
  return [
    {
      key: 'reference',
      title: 'Reference',
      render: (_, consolidation: ConsolidatedShipment): ReactNode => {
        const canViewConsolidationDetails: boolean = checkPermission(consolidation, 'canViewDetails');
        const { reference, id } = consolidation;

        if (canViewConsolidationDetails) {
          return <TableLink linkTo={consolidationRoutes.consolidationDetails.getUrl(id)}>{reference}</TableLink>;
        }

        return <span>{consolidation.reference}</span>;
      },
      sortable: true
    },
    {
      key: 'origin',
      title: 'From',
      render: (_, { networksOrigin }: ConsolidatedShipment): ReactNode => {
        return <span>{networksOrigin?.label?.long || ''}</span>;
      },
      sortable: false,
      ellipsis: true,
      width: '200px'
    },
    {
      key: 'destination',
      title: 'To',
      render: (_, { networksDestination }: ConsolidatedShipment): ReactNode => {
        return <span>{networksDestination?.label?.long || ''}</span>;
      },
      sortable: false,
      width: '200px'
    },
    {
      key: 'cutOffDate',
      title: 'Cut off date',
      render: (_, { cutOffDate }: ConsolidatedShipment): ReactNode => {
        return <span>{formatDate(cutOffDate)}</span>;
      },
      sortable: true
    },
    {
      key: 'vehiclePlateNumber',
      title: 'Vehicle plate',
      render: (_, { vehiclePlateNumber }: ConsolidatedShipment): ReactNode => {
        return <span>{vehiclePlateNumber}</span>;
      },
      sortable: true
    },
    {
      key: 'actualDepartureDate',
      title: 'Actual departure date',
      render: (_, { actualDepartureDate }: ConsolidatedShipment): ReactNode => {
        return <span>{formatDate(actualDepartureDate || '')}</span>;
      },
      sortable: true
    },
    {
      key: 'estimatedArrivalDate',
      title: 'Estimated arrival date',
      render: (_, { estimatedArrivalDate }: ConsolidatedShipment): ReactNode => {
        return <span>{formatDate(estimatedArrivalDate || '')}</span>;
      },
      sortable: true
    },
    {
      key: 'actualArrivalDate',
      title: 'Actual arrival date',
      render: (_, { actualArrivalDate }: ConsolidatedShipment): ReactNode => {
        return <span>{formatDate(actualArrivalDate || '')}</span>;
      },
      sortable: true
    },
    ...(canManageConsolidation
      ? [
          {
            key: 'actions',
            fixed: 'right' as const,
            title: 'Actions',
            render: (_: unknown, consolidation: ConsolidatedShipment): ReactNode => {
              const canCopyConsolidation: boolean = checkPermission(consolidation, 'canCopy');
              const canEditConsolidation: boolean = checkPermission(consolidation, 'canEdit');
              const canManageConsolidationShipments: boolean = checkPermission(consolidation, 'canManage');

              return (
                <ActionHandleContainer
                  canCopy={canCopyConsolidation}
                  canEdit={canEditConsolidation}
                  canManageShipments={canManageConsolidationShipments}
                  consolidationId={consolidation.id}
                />
              );
            },
            sortable: false,
            width: '100px'
          }
        ]
      : [])
  ];
};
