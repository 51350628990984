import cx from 'classnames';
import type { FC, SyntheticEvent } from 'react';

import type { IconName } from '@zen/Styleguide';

interface Props {
  className?: string;
  disabled?: boolean;
  icon: IconName;
  interactive?: boolean;
  onClick?: (event: SyntheticEvent) => void;
}

export interface IconConfig {
  className?: string;
  icon: IconName;
}

const Icon: FC<Props> = ({ className, disabled = false, icon, onClick, interactive = false, ...rest }) => {
  const iconStyles = cx(
    {
      'opacity-50 cursor-not-allowed': disabled,
      'hover:bg-grey-lighter hover:text-grey-dark rounded p-2 active:bg-grey-base cursor-pointer': interactive
    },
    'zicon',
    icon,
    className
  );

  return <span className={iconStyles} data-component="icon" data-testid={`icon-${icon}`} onClick={onClick} {...rest} />;
};

export type { Props as IconProps };
export default Icon;
