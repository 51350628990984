import { FC, useState } from 'react';

import { ContextMenu, Modal } from '@zen/DesignSystem';
import DetailsCardContent from '@zen/Shipment/components/DetailsCardContent';
import DetailsCardDetailItem from '@zen/Shipment/components/DetailsCardContent/DetailsCardDetailItem';
import type { MilestoneDelay } from '@zen/Shipment/RouteProgress/VoyageMilestones/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import MilestoneDelayForm from '../../MilestoneDelayForm';
import type { MilestoneDelayFormType } from '../../MilestoneDelayForm/type';
import { useDeleteMilestoneDelayMutation, useUpdateMilestoneDelayMutation } from './graphql';

interface Props {
  canUpdateMilestoneDelay: boolean;
  delay: MilestoneDelay;
  onUpdateModalClose: () => void;
  onUpdateModalOpen: () => void;
  onUpdateSuccess?: () => void;
}

const MilestoneDelayItem: FC<Props> = (props) => {
  const { delay, onUpdateModalOpen, onUpdateModalClose, onUpdateSuccess, canUpdateMilestoneDelay } = props;
  const { reason, description, id } = delay;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [updateMilestoneDelay] = useUpdateMilestoneDelayMutation();
  const [deleteMilestoneDelay] = useDeleteMilestoneDelayMutation();
  const { addError } = useNotification();

  const handleDeleteMilestoneDelay = async (): Promise<void> => {
    await performMutation({
      mutationFn: () => deleteMilestoneDelay({ variables: { input: { delayId: id } } }),
      onError: () => addError(),
      onSuccess: () => handleSuccess()
    });
  };

  const handleUpdateMilestoneDelay = (values: MilestoneDelayFormType): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () =>
        updateMilestoneDelay({
          variables: {
            input: { delayId: id, reason: values.reason, description: values.description }
          }
        }),
      onError: () => addError()
    });

  const closeModal = (): void => {
    onUpdateModalClose();
    setIsModalOpen(false);
  };

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const handleSuccess = (): void => {
    onUpdateSuccess?.();
    closeModal();
  };

  return (
    <>
      <DetailsCardDetailItem>
        <DetailsCardContent
          action={
            canUpdateMilestoneDelay && (
              <ContextMenu
                inline={false}
                items={[
                  { icon: 'zicon-edit', label: 'Update delay information', onClick: openModal, addDivider: true },
                  { icon: 'zicon-trash', label: 'Delete delay reason', onClick: handleDeleteMilestoneDelay }
                ]}
                onClose={onUpdateModalClose}
                onOpen={onUpdateModalOpen}
              />
            )
          }
          title={
            <>
              <div className="leading-6 flex items-center">
                <div className="p-1 bg-red-base rounded-full mr-4" />
                <div className="text-sm text-grey-dark">{reason}</div>
              </div>
              <div className="text-grey-base ml-6 leading-normal">{description}</div>
            </>
          }
        />
      </DetailsCardDetailItem>
      <Modal isOpen={isModalOpen} modalOverflowY="visible" title="Update delay reason">
        <MilestoneDelayForm
          initialValues={{ reason, description }}
          onCancel={closeModal}
          onSubmit={handleUpdateMilestoneDelay}
          onSuccess={handleSuccess}
        />
      </Modal>
    </>
  );
};

export default MilestoneDelayItem;
