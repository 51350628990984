import { mergeWith } from 'lodash';

import type { VehicleCargoFormData } from '@zen/Cargo';
import { CargoDimensionsUnitEnum, CargoWeightUnitEnum, Currency, RiskLevelsEnum } from '@zen/Cargo';
import { cargoRiskLevelLabelMapping } from '@zen/Cargo/helpers';
import type { Option } from '@zen/DesignSystem';
import type { DeepNullable } from '@zen/utils/typescript';
import { deepMergeNilValues } from '@zen/utils/utils';

export const riskLevelOptions: Option<RiskLevelsEnum>[] = [
  {
    label: cargoRiskLevelLabelMapping[RiskLevelsEnum.LOW].title,
    value: RiskLevelsEnum.LOW
  },
  {
    label: cargoRiskLevelLabelMapping[RiskLevelsEnum.MEDIUM].title,
    value: RiskLevelsEnum.MEDIUM
  },
  {
    label: cargoRiskLevelLabelMapping[RiskLevelsEnum.HIGH].title,
    value: RiskLevelsEnum.HIGH
  }
];

export const mergeInitialValues = (values: Partial<VehicleCargoFormData>): DeepNullable<VehicleCargoFormData> => {
  const defaultValues: DeepNullable<VehicleCargoFormData> = {
    actualCbm: null,
    vehicleType: null,
    trailerId: null,
    looseCargoType: null,
    quantity: 1,
    grossWeight: {
      value: null,
      unit: CargoWeightUnitEnum.KG
    },
    dimensions: {
      length: null,
      width: null,
      height: null,
      unit: CargoDimensionsUnitEnum.CM
    },
    hazardous: false,
    refrigerated: false,
    riskLevel: null,
    valueOfGoods: {
      currency: Currency.GBP,
      value: null
    },
    tailLift: false
  };

  return mergeWith(values, defaultValues, deepMergeNilValues);
};
