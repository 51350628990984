const IS_AUTHENTICATED_KEY = 'loggedIn';

const authStore = () => {
  const setLoggedIn = () => window.localStorage.setItem(IS_AUTHENTICATED_KEY, 'true');

  const getLoggedIn = () => window.localStorage.getItem(IS_AUTHENTICATED_KEY);

  const unsetLoggedIn = () => window.localStorage.removeItem(IS_AUTHENTICATED_KEY);

  const removeAccountInfo = () => {
    window.localStorage.removeItem('accountName');
    window.localStorage.removeItem('isAdmin');
    window.localStorage.removeItem('isDivision');
    window.localStorage.removeItem('userId');
    window.localStorage.removeItem('profileLevel');
    window.localStorage.removeItem('profileRoles');
    window.localStorage.removeItem('role');
  };

  return {
    setLoggedIn,
    getLoggedIn,
    unsetLoggedIn,
    removeAccountInfo
  };
};

export default authStore;
