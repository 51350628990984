import { useState } from 'react';

import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import FiltersButton from '@zen/Components/FiltersButton';
import Search from '@zen/Components/Search';
import Slideout from '@zen/Components/Slideout';
import { TableColumn, useTableConfiguration } from '@zen/DesignSystem';
import { ShipmentFilterStatus } from '@zen/Shipments';
import { countFilters } from '@zen/Shipments/Filters/helpers';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import FiltersForm from '../FiltersForm';
import type { CustomerAdminShipmentFilters } from '../FiltersForm/types';

interface Props<T> {
  columns: TableColumn<T>[];
}

const Toolbar = <T extends {}>({ columns }: Props<T>) => {
  const [isSlideoutOpened, setIsSlideoutOpened] = useState<boolean>(false);

  const { filters, search, setFilters, setSearch } = useUrlFilters<CustomerAdminShipmentFilters>({
    status: ShipmentFilterStatus.ACTIVE
  });

  const { hiddenColumns, setHiddenColumns } = useTableConfiguration();

  const clearFilters = (): void => {
    setFilters({});
    setIsSlideoutOpened(false);
  };

  const closeSlideout = (): void => setIsSlideoutOpened(false);

  const openSlideout = (): void => setIsSlideoutOpened(true);

  const submitFilters = (values: CustomerAdminShipmentFilters): Promise<IOkOrErrorResult> => {
    setFilters(values);

    return Promise.resolve({ ok: true, error: null });
  };

  return (
    <div data-testid="all-shipments-toolbar">
      <div className="flex space-x-4">
        <div className="flex-1">
          <Search
            initialValue={search}
            onClear={() => setSearch(undefined)}
            onSubmit={setSearch}
            placeholder="Search for a shipment..."
          />
        </div>
        <FiltersButton className="mx-4" filterCount={countFilters(filters)} onClick={openSlideout} />
      </div>
      <div className="flex justify-end mt-4">
        <CustomiseTableButton columns={columns} hiddenColumns={hiddenColumns} onChange={setHiddenColumns} />
      </div>
      <Slideout onOutsideClick={closeSlideout} overlay={true} show={isSlideoutOpened}>
        <FiltersForm
          initialValues={filters}
          onClose={closeSlideout}
          onReset={clearFilters}
          onSubmit={submitFilters}
          onSuccess={closeSlideout}
        />
      </Slideout>
    </div>
  );
};

export default Toolbar;
