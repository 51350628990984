import type { ReactNode } from 'react';
import type * as Yup from 'yup';

import type { FormInstance } from '@zen/Components/Form';
import HeadingWithAction from '@zen/Components/HeadingWithAction';
import Slideout from '@zen/Components/Slideout';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { useOperationsContext } from '../context/OperationsContext';
import type { FiltersType } from '../context/OperationsContextProvider/OperationsContextProvider';
import type { OperationsFilters } from '../types';
import FiltersForm from './FiltersForm';
import useOperationsPersistedFilters from './FiltersForm/hooks/useOperationsPersistedFilters';
import { removeEmptyOperationsFilters } from './helpers';
import type { PersistedFiltersType } from './types';

interface Props<T> {
  children?: ((form: FormInstance<T>) => ReactNode) | ReactNode;
  emptyFilters: T;
  initialFiltersValue?: FiltersType<T>;
  onClose: () => void;
  showFilters: boolean;
  title: string;
  validation?: Yup.ObjectSchema<{}>;
}

const Filters = <T extends OperationsFilters = OperationsFilters>(props: Props<T>) => {
  const { showFilters, onClose, children, emptyFilters, title, validation, initialFiltersValue = {} } = props;
  const { bookingCoordinators, accounts } = useOperationsContext();
  const { filters, setFilters } = useOperationsPersistedFilters<PersistedFiltersType<T>>(initialFiltersValue);

  const handleSetFilters = (values: T) => {
    const filterValues = removeEmptyOperationsFilters(values) as Partial<PersistedFiltersType<T>>;

    setFilters(filterValues);
    onClose();
  };

  const handleClearFilters = () => {
    setFilters({});
    onClose();
  };

  const handleSubmit = async (values: T): Promise<IOkOrErrorResult> => {
    handleSetFilters(values);

    return { ok: true, error: false };
  };

  const initialFilters = {
    ...emptyFilters,
    ...filters
  };

  return (
    <Slideout onOutsideClick={onClose} show={showFilters}>
      <div className="relative h-full overflow-y-auto w-160">
        <div className="px-8 py-6">
          <HeadingWithAction onClick={onClose} title={title} />
          <FiltersForm<T>
            coordinators={bookingCoordinators}
            customers={accounts}
            initialFilters={initialFilters}
            onClear={handleClearFilters}
            onSubmit={handleSubmit}
            validation={validation}
          >
            {children}
          </FiltersForm>
        </div>
      </div>
    </Slideout>
  );
};

export default Filters;
