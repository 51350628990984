import { useQueryParams } from '../QueryParams';

const useUrlFilters = <T extends {}>(initialFilters?: Partial<T>) => {
  const { queryParams, setQueryParams, pushWithQueryParams } = useQueryParams<T & { search?: string }>('filters', initialFilters);

  const { search, ...otherFilters } = queryParams;

  const setFilters = (filters: Partial<T>): void => {
    const searchValue = search ? { search } : {};

    setQueryParams({ ...filters, ...searchValue }, false);
  };

  const setSearch = (value: string | undefined): void => {
    setQueryParams({ ...(queryParams as T), search: value }, false);
  };

  const pushWithFilters = (path: string, filters: Partial<T>, includeExistingParams: boolean = true) => {
    pushWithQueryParams(path, filters, includeExistingParams);
  };

  return {
    search: queryParams.search && String(queryParams.search),
    setSearch,
    filters: otherFilters as T,
    setFilters,
    pushWithFilters
  };
};

export default useUrlFilters;
