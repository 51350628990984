import { useState } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import type { MenuItemType } from '@zen/DesignSystem';
import { ContextMenu, Dialog } from '@zen/DesignSystem';
import { useAssignUserAsCoordinatorMutation } from '@zen/Networks/graphql';
import InvitationLinkModal from '@zen/Networks/InvitationLinkModal';
import { useNetworksContext } from '@zen/Networks/NetworksContext';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { ContactRemoveLevelType, ContactTableDataType, ManagerTypeEnum, ModalConfiguration } from '../types';

interface Props<T> {
  contact: T;
  handleInvite: (contactId: string) => void;
  handleRemove: (contactId: string) => void;
  handleResendPassword: (contactId: string) => void;
  isMyOrganisationPage?: boolean;
  locationId: Nullable<string>;
  organisationId: string;
  removeType: ContactRemoveLevelType;
  throughLocationDetails?: boolean;
}

const ContactTableActions = <T extends ContactTableDataType>({
  contact,
  handleRemove,
  handleInvite,
  handleResendPassword,
  isMyOrganisationPage = false,
  throughLocationDetails = false,
  organisationId,
  locationId,
  removeType
}: Props<T>) => {
  const { addSuccess, addError } = useNotification();
  const { isZencargoNetwork, routes } = useNetworksContext();

  const { email, id: contactId, invitation } = contact;

  const canInvite: boolean = !!contact.invitation && checkPermission(contact.invitation, 'canSend');
  const canSendResetPasswordInstructions: boolean = checkPermission(contact, 'canSendResetPasswordInstructions');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isInvitationLinkModalOpen, setIsInvitationLinkModalOpen] = useState<boolean>(false);

  const [modalData, setModalData] = useState<Partial<ModalConfiguration>>({});

  const [assignUserAsCoordinator] = useAssignUserAsCoordinatorMutation();

  const coordinatorLabel: Record<ManagerTypeEnum, string> = {
    [ManagerTypeEnum.COMMERCIAL]: 'customer',
    [ManagerTypeEnum.OPERATIONS]: 'operations'
  };

  const handleAssignUserAsCoordinator = (userId: string, coordinatorType: ManagerTypeEnum): Promise<IOkOrErrorResult> => {
    const label: string = coordinatorLabel[coordinatorType];

    return performMutation({
      mutationFn: () =>
        assignUserAsCoordinator({
          variables: {
            input: {
              userId,
              managerType: coordinatorType
            }
          }
        }),
      onError: () => addError(`User already assigned as ${label} coordinator`),
      onSuccess: () => addSuccess(`User assigned as ${label} coordinator`)
    });
  };

  const assignCoordinatorModal = (userId: string, coordinatorType: ManagerTypeEnum): ModalConfiguration => {
    const label: string = coordinatorLabel[coordinatorType];

    return {
      confirmLabel: 'Assign contact',
      header: `Are you sure you want to assign this contact as ${label} coordinator?`,
      message: `This contact will be able to manage bookings as ${label} coordinator.`,
      onConfirm: async () => {
        handleAssignUserAsCoordinator(userId, coordinatorType);
        setIsModalOpen(false);
      }
    };
  };

  const archiveModal = (): ModalConfiguration => {
    return {
      confirmLabel: 'Archive',
      header: 'Are you sure you want to archive this contact?',
      message:
        removeType === ContactRemoveLevelType.ORGANISATION
          ? 'Deleting this contact will permanently remove it from the organisation and all its assigned locations.'
          : 'Deleting this contact will remove it from the assigned location.',
      onConfirm: async () => {
        handleRemove(contactId);
        setIsModalOpen(false);
      }
    };
  };

  const inviteModal = (): ModalConfiguration => {
    return {
      confirmLabel: 'Send invite',
      cancelLabel: 'Return',
      header: 'Are you sure  you want to invite this contact to the platform?',
      message: `An invitation will be sent to ${email},
    once the invitation is sent you will not be able to change the email.
    If you need to edit the email please return to the form.`,
      onConfirm: async () => {
        handleInvite(contactId);
        setIsModalOpen(false);
      }
    };
  };

  const resendPasswordModal = (): ModalConfiguration => {
    return {
      confirmLabel: 'Reset password',
      cancelLabel: 'Return',
      header: 'Are you sure  you want to reset password?',
      onConfirm: async () => {
        handleResendPassword(contactId);
        setIsModalOpen(false);
      }
    };
  };

  const getDropdownOptions = (): MenuItemType[] => {
    const editPath: string = routes.contact.edit.getUrl(organisationId, contactId);
    const editState = {
      fromMyOrganisationPage: isMyOrganisationPage,
      locationId,
      throughLocationDetails
    };

    const inviteUserDropdownOption: MenuItemType = {
      label: 'Invite user',
      onClick: () => {
        setModalData(inviteModal());
        setIsModalOpen(true);
      },
      icon: 'zicon-add-user'
    };

    const resendInviteDropdownOption: MenuItemType = {
      label: 'Resend invitation',
      onClick: () => {
        setIsInvitationLinkModalOpen(true);
      },
      icon: 'zicon-mail'
    };

    const resendPasswordDropdownOption: MenuItemType = {
      label: 'Reset password',
      onClick: () => {
        setModalData(resendPasswordModal());
        setIsModalOpen(true);
      },
      icon: 'zicon-help-centre'
    };

    const editContactDropdownOption: MenuItemType = {
      label: 'Edit contact',
      linkTo: { pathname: editPath, state: editState },
      icon: 'zicon-edit'
    };

    const archiveContactDropdownOption: MenuItemType = {
      label: 'Archive contact',
      onClick: () => {
        setModalData(archiveModal());
        setIsModalOpen(true);
      },
      icon: 'zicon-trash'
    };

    const assignUserAsOperationsCoordinator: MenuItemType = {
      label: 'Assign as operations coordinator',
      onClick: () => {
        setModalData(assignCoordinatorModal(contactId, ManagerTypeEnum.OPERATIONS));
        setIsModalOpen(true);
      },
      icon: 'zicon-user'
    };

    const assignUserAsCustomerCoordinator: MenuItemType = {
      label: 'Assign as customer coordinator',
      onClick: () => {
        setModalData(assignCoordinatorModal(contactId, ManagerTypeEnum.COMMERCIAL));
        setIsModalOpen(true);
      },
      icon: 'zicon-user'
    };

    return [
      ...(canInvite ? [inviteUserDropdownOption] : []),
      ...(invitation && invitation.acceptUrl ? [resendInviteDropdownOption] : []),
      ...(canSendResetPasswordInstructions ? [resendPasswordDropdownOption] : []),
      ...(isZencargoNetwork ? [assignUserAsOperationsCoordinator] : []),
      ...(isZencargoNetwork ? [assignUserAsCustomerCoordinator] : []),
      editContactDropdownOption,
      archiveContactDropdownOption
    ];
  };

  const dropdownOptions: MenuItemType[] = getDropdownOptions();

  return (
    <div>
      <ContextMenu inline={false} items={dropdownOptions} />
      {modalData.onConfirm && (
        <Dialog
          cancelLabel={modalData.cancelLabel}
          confirmLabel={modalData.confirmLabel}
          header={modalData.header}
          isOpen={isModalOpen}
          message={modalData.message}
          onClose={() => {
            setIsModalOpen(false);
          }}
          onConfirm={modalData.onConfirm}
        />
      )}
      {invitation?.acceptUrl && (
        <InvitationLinkModal
          invitationLink={invitation.acceptUrl}
          isOpen={isInvitationLinkModalOpen}
          onClose={() => {
            setIsInvitationLinkModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ContactTableActions;
