import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInput } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { DeepNullable } from '@zen/utils/typescript';

import FormAddressFields from '../FormAddressFields';
import { editLocationValidation } from '../validation';
import type { IEditLocationFormFields, OrganisationLocationResponse } from './types';

interface Props {
  initialValues: DeepNullable<IEditLocationFormFields>;
  onCancel: () => void;
  onSubmit: (values: IEditLocationFormFields) => Promise<IOkOrErrorResult>;
  onSuccess: (data: OrganisationLocationResponse) => void;
}

const EditLocationForm: FC<Props> = ({ initialValues, onSubmit, onSuccess, onCancel }) => {
  const renderFormButtons = ({ isSubmitting }: { isSubmitting: boolean }): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} text="Save changes">
      <Button data-testid="cancel-button" onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      className="mt-4"
      formButtons={renderFormButtons}
      formName="EditLocationForm"
      initialValues={initialValues}
      isNested={true}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={editLocationValidation}
    >
      {() => (
        <>
          <FormInput label="Location name" name="name" />
          <FormAddressFields />
        </>
      )}
    </Form>
  );
};

export default EditLocationForm;
