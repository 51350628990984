import { LooseCargoTypeEnum, PalletTypeEnum } from '@zen/Cargo';
import type { Option } from '@zen/DesignSystem';
import { cargoTypeLabelMapping } from '@zen/types';

export const commonCargoOptions: Option<PalletTypeEnum | LooseCargoTypeEnum>[] = [
  { value: PalletTypeEnum.EURO, label: cargoTypeLabelMapping[PalletTypeEnum.EURO] },
  {
    value: LooseCargoTypeEnum.BOXES_OR_CRATES,
    label: cargoTypeLabelMapping[LooseCargoTypeEnum.BOXES_OR_CRATES]
  },
  {
    value: LooseCargoTypeEnum.ASSORTED_CARGO,
    label: cargoTypeLabelMapping[LooseCargoTypeEnum.ASSORTED_CARGO]
  }
];

export const otherCargoOptions: Option<PalletTypeEnum>[] = [
  { value: PalletTypeEnum.UK, label: cargoTypeLabelMapping[PalletTypeEnum.UK] },
  { value: PalletTypeEnum.OTHER, label: cargoTypeLabelMapping[PalletTypeEnum.OTHER] }
];
