import cx from 'classnames';
import { FC, ReactNode, useState } from 'react';

import { useHover } from '@zen/utils/hooks/useHover';

import ChevronIcon from '../Icons/ChevronIcon';

interface Props {
  children: ReactNode;
  className?: string;
  collapseLabel: string;
  showLabel: string;
}

const CollapseContainer: FC<Props> = ({ children, className = '', collapseLabel, showLabel }) => {
  const [collapse, setCollapse] = useState<boolean>(false);
  const [ref, isItemHovered] = useHover();

  const buttonLabel: string = collapse ? showLabel : collapseLabel;
  const buttonClasses: string = 'flex items-center justify-center bg-white border-solid cursor-pointer rounded-full border py-3';
  const containerClasses: string = cx(
    {
      'h-0 p-b border-b-0': collapse,
      'py-8 px-10 border-b': !collapse
    },
    'bg-grey-lightest border-t border-grey-lighter border-solid flex flex-1 flex-row',
    className
  );

  const togglerClasses: string = cx(
    {
      'border-navy-base text-navy-base px-4': isItemHovered,
      'border-grey-lighter text-grey-base px-3': !isItemHovered
    },
    'group',
    buttonClasses
  );

  return (
    <div>
      <div className={containerClasses}>
        <div className={cx('flex flex-1 flex-row', { hidden: collapse })}>{children}</div>
      </div>
      <div className="relative w-full justify-center flex flex-1 flex-row -top-6">
        <div ref={ref} className={togglerClasses} data-testid="toggler" onClick={() => setCollapse(!collapse)}>
          <ChevronIcon expanded={!collapse} />
          {isItemHovered && <span className="ml-2 text-navy-base font-bold">{buttonLabel}</span>}
        </div>
      </div>
    </div>
  );
};

export default CollapseContainer;
