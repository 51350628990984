import cx from 'classnames';
import type { FC } from 'react';

import type { CounterType } from './types';

type TypeToClassNamesMapping = {
  [key in CounterType]: string;
};

export interface Props {
  className?: string;
  counterClassNames?: string;
  prefix?: string;
  size?: 'small' | 'normal';
  type?: CounterType;
  value: number | string;
}

const Counter: FC<Props> = (props) => {
  const { className, value, size = 'normal', type = 'default', counterClassNames, prefix = '' } = props;

  const defaultClasses: TypeToClassNamesMapping = {
    default: 'bg-azure-lightest text-grey-dark font-bold',
    selected: 'bg-azure-base text-white',
    deselected: 'bg-grey-lightest text-grey-base',
    active: 'bg-azure-base text-white font-bold',
    warning: 'bg-orange-base text-white font-bold',
    problem: 'bg-red-base text-white font-bold',
    success: 'bg-green-base text-white font-bold'
  };

  const wrapperClassNames = cx('inline-block', className);

  const classNames = cx(
    {
      'text-sm': size === 'normal',
      'text-xs': size === 'small'
    },
    defaultClasses[type],
    'flex items-center justify-center min-w-5 h-5 px-1 rounded-full',
    counterClassNames
  );

  return (
    <span className={wrapperClassNames}>
      <span className={classNames} data-component="counter" data-testid={`counter-${type}`}>
        {prefix && prefix}
        {value}
      </span>
    </span>
  );
};

export default Counter;
