import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateDocumentTemplateMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateDocumentTemplateInput;
}>;

export type CreateDocumentTemplateMutationResult = { __typename?: 'Mutation' } & {
  documentTemplatesCreateDocumentTemplate?: GraphQLTypes.Maybe<
    { __typename?: 'CreateDocumentTemplatePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      documentTemplate?: GraphQLTypes.Maybe<
        { __typename?: 'DocumentTemplate' } & Pick<GraphQLTypes.DocumentTemplate, 'id' | 'name'>
      >;
    }
  >;
};

export const CreateDocumentTemplateDocument = /* #__PURE__ */ gql`
  mutation createDocumentTemplate($input: CreateDocumentTemplateInput!) {
    documentTemplatesCreateDocumentTemplate(input: $input) {
      errors {
        message
        path
      }
      documentTemplate {
        id
        name
      }
    }
  }
`;

/**
 * __useCreateDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useCreateDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentTemplateMutation, { data, loading, error }] = useCreateDocumentTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDocumentTemplateMutationResult, CreateDocumentTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateDocumentTemplateMutationResult, CreateDocumentTemplateMutationVariables>(
    CreateDocumentTemplateDocument,
    options
  );
}
export type CreateDocumentTemplateMutationHookResult = ReturnType<typeof useCreateDocumentTemplateMutation>;
