import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountDetailsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetAccountDetailsQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & Pick<
      GraphQLTypes.Account,
      | 'uuid'
      | 'name'
      | 'referencePrefix'
      | 'companyEmailAddress'
      | 'companyPhoneNumber'
      | 'eoriNumber'
      | 'vat'
      | 'isActive'
      | 'isDemo'
    >
  >;
};

export const GetAccountDetailsDocument = /* #__PURE__ */ gql`
  query getAccountDetails($accountId: String!) {
    account(uuid: $accountId) {
      uuid
      name
      referencePrefix
      companyEmailAddress
      companyPhoneNumber
      eoriNumber
      vat
      isActive
      isDemo
    }
  }
`;

/**
 * __useGetAccountDetailsQuery__
 *
 * To run a query within a React component, call `useGetAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
}
export function useGetAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
}
export type GetAccountDetailsQueryHookResult = ReturnType<typeof useGetAccountDetailsQuery>;
export type GetAccountDetailsLazyQueryHookResult = ReturnType<typeof useGetAccountDetailsLazyQuery>;
