import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import PageContent from '../PageContent';
import PageTitle from '../PageTitle';
import { ReactComponent as Logo } from './logo.svg';

interface Props {
  subTitle: ReactNode;
  title: string;
}

const LandingPage: FC<Props> = ({ children, title, subTitle, ...rest }) => (
  <div className="flex bg-white" {...rest}>
    <div className="fixed min-h-screen z-50 w-20 bg-navy-dark p-4" data-testid="sidebar">
      <Link className="p-2 block" to="/">
        <Logo className="w-8" />
      </Link>
    </div>
    <div className="w-full mt-40 pl-16">
      <PageContent width="narrow">
        <PageTitle title={title} />
        <p className="py-5 text-grey-dark text-sm leading-normal mb-8">{subTitle}</p>
        {children}
      </PageContent>
    </div>
  </div>
);

export default LandingPage;
