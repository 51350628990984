import type { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import useAuth from '@zen/Auth/hooks/useAuth';
import Login from '@zen/Auth/Login';
import OutdatedBrowser from '@zen/Components/OutdatedBrowser';
import Rollbar from '@zen/Components/Rollbar';
import { authRoutes } from '@zen/Routes';
import loadable from '@zen/utils/component/loadable';

import type { Env } from '../AppContext';
import DashboardContainer from '../DashboardContainer';

interface Props extends RouteComponentProps {
  env: Env;
}

const AccountSwitcher = loadable(
  () =>
    import(
      /* webpackChunkName: "account-switcher" */
      '@zen/Components/AccountSwitcher'
    )
);
const CaptureProfileRolesPage = loadable(
  () =>
    import(
      /* webpackChunkName: "capture-profile-roles-page" */
      '@zen/Settings/CaptureProfileRolesPage'
    )
);
const ForgotPassword = loadable(
  () =>
    import(
      /* webpackChunkName: "forgot-password" */
      '@zen/Auth/ForgotPassword'
    )
);
const ResetPassword = loadable(
  () =>
    import(
      /* webpackChunkName: "reset-password" */
      '@zen/Auth/ResetPassword'
    )
);
const SignUp = loadable(
  () =>
    import(
      /* webpackChunkName: "sign-up" */
      '@zen/Auth/SignUp'
    )
);
const NoMatch = loadable(
  () =>
    import(
      /* webpackChunkName: "no-match" */
      '../NoMatch'
    )
);

export const Routes: FC<Props> = ({ env }) => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="antialiased">
      <Rollbar env={env} />
      <OutdatedBrowser />
      <Switch>
        <Route path="/dashboard">{isAuthenticated ? <DashboardContainer /> : <Redirect to="/login" />}</Route>
        <Route path={authRoutes.accountSwitcher.path}>{isAuthenticated ? <AccountSwitcher /> : <Redirect to="/login" />}</Route>
        <Route path="/login">{!isAuthenticated ? <Login /> : <Redirect to="/dashboard" />}</Route>
        <Route path="/forgot-password">{!isAuthenticated ? <ForgotPassword /> : <Redirect to="/dashboard" />}</Route>
        <Route path="/reset-password">{!isAuthenticated ? <ResetPassword /> : <Redirect to="/dashboard" />}</Route>
        <Route path="/signup">{!isAuthenticated ? <SignUp /> : <Redirect to="/dashboard" />}</Route>
        <Route path="/complete-profile-setup">
          <CaptureProfileRolesPage />
        </Route>
        <Redirect from="/" to="/dashboard" />
        <Route>
          <NoMatch />
        </Route>
      </Switch>
    </div>
  );
};

export default withRouter(Routes);
