import type { FC } from 'react';

import { Button, ButtonType, ButtonVariant, Loading } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import type { ButtonClick } from '@zen/types';
import type { Undefinable } from '@zen/utils/typescript';

interface Props {
  className?: string;
  iconLeft?: IconName;
  isDisabled?: boolean;
  isInProgress: boolean;
  onClick?: (event: ButtonClick) => void;
  text: string;
  type?: ButtonType;
  variant?: ButtonVariant;
}
const LoadingButton: FC<Props> = (props) => {
  const { className, isDisabled, isInProgress, onClick, text, type = 'button', variant = 'secondary', iconLeft, ...rest } = props;
  const icon: Undefinable<IconName> = !isInProgress ? iconLeft : undefined;

  return (
    <Button
      className={className}
      data-testid="loading-btn"
      disabled={isDisabled || isInProgress}
      iconLeft={icon}
      onClick={onClick}
      type={type}
      variant={variant}
      {...rest}
    >
      {isInProgress ? <Loading size="small" withMargin={false} /> : text}
    </Button>
  );
};

export type { Props as LoadingButtonProps };

export default LoadingButton;
