import { isEmpty, isEqual } from 'lodash';
import SelectComponent, { MultiValue } from 'react-select';

import type { Nullable, Optional } from '@zen/utils/typescript';

import { ClearIndicator, getMenuList, getMultiValue, getNoOptionsMessage } from '../components';
import { customTheme, multiSelectStyles } from '../select-styles';
import type { CommonSelectProps, Option } from '../types';

interface Props<Value> extends CommonSelectProps<Value> {
  onChange?: (values: Value[]) => void;
  value?: Optional<Value[]>;
}

const MultiSelect = <Value extends Nullable<{}>>(props: Props<Value>) => {
  const {
    autoFocus,
    className,
    formatOptionLabel,
    hasError,
    isClearable = true,
    isDisabled,
    isLoading,
    inputValue,
    isSearchable,
    shouldFilterResults = true,
    name,
    onChange,
    onInputChange,
    options,
    placeholder,
    dropdownFooterRenderer,
    suggestion,
    value
  } = props;

  const getValue = (): Option<Value>[] => {
    return options.filter((option: Option<Value>) => value && value.some((valueData: Value) => isEqual(valueData, option.value)));
  };

  const handleChange = (selectedOptions: MultiValue<Option<Value>>): void => {
    const optionValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];

    onChange?.(optionValues);
  };

  const handleInputChange = (query: string) => {
    if (query) {
      onInputChange?.(query);
    }
  };

  const filterOption = shouldFilterResults ? undefined : () => true;

  return (
    <SelectComponent
      autoFocus={autoFocus}
      className={className}
      components={{
        ClearIndicator,
        MenuList: getMenuList(dropdownFooterRenderer),
        MultiValue: getMultiValue<Option<Value>, true>(),
        NoOptionsMessage: getNoOptionsMessage<Option<Value>, true>(suggestion)
      }}
      filterOption={filterOption}
      formatOptionLabel={formatOptionLabel}
      inputId={name}
      inputValue={inputValue}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={true}
      isSearchable={isSearchable}
      menuPlacement="auto"
      name={name}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      placeholder={placeholder}
      styles={multiSelectStyles(hasError, 'outlined', isClearable && !isEmpty(getValue()))}
      theme={customTheme}
      value={getValue()}
    />
  );
};

export type { Props as MultiSelectProps };
export default MultiSelect;
