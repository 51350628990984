import { useMutation, useQuery } from '@apollo/client';
import { Dictionary, get, isEqual, isNil, omit, omitBy } from 'lodash';
import { createContext, ReactNode, useContext, useEffect } from 'react';

import { UpdateUserPreferencesDocument } from '@zen/Settings/graphql';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';

import { GetSavedFiltersDocument } from './graphql';

export interface SavedFilter<T> {
  filters: T;
  name: string;
}

interface SavedFiltersContextValues<T> {
  addSavedFilter: (savedFilter: SavedFilter<T>) => void;
  getActiveSavedFilter: (appliedFilters: T) => SavedFilter<T>;
  removeSavedFilter: (savedFilter: SavedFilter<T>) => void;
  savedFilters: SavedFilter<T>[];
}

const initialContext = {
  savedFilters: [],
  addSavedFilter: undefined,
  removeSavedFilter: undefined,
  getActiveSavedFilter: undefined
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ savedFilters: never[]; addSave... Remove this comment to see the full error message
const SavedFiltersContext = createContext<SavedFiltersContextValues<any>>(initialContext);

const useSavedFilters = () => useContext(SavedFiltersContext);

type SavedFilters = 'savedShipmentFilters';

interface Props {
  children: ReactNode;
  filterName: SavedFilters;
}

function SavedFiltersProvider<T extends {}>({ filterName, children }: Props) {
  const { userProfile } = useAccount();
  const { addSuccess, addError } = useNotification();
  const { data, refetch } = useQuery<SavedFilter<T>[]>(GetSavedFiltersDocument);
  const [updatePreferences] = useMutation(UpdateUserPreferencesDocument, {
    refetchQueries: [{ query: GetSavedFiltersDocument }],
    awaitRefetchQueries: true,
    onError: () => addError('There was an error updating your filters.')
  });

  const getCleanedSavedFilters = (savedFilter: SavedFilter<T>): SavedFilter<Omit<T, '__typename'>> => {
    const { name, filters } = savedFilter;
    const cleanedSavedFilter: Omit<T, '__typename'> = getFiltersWithoutTypeName(filters);

    return { name, filters: cleanedSavedFilter };
  };

  const getFiltersWithoutTypeName = (filters: T & { __typename?: string }): Omit<T, '__typename'> => {
    return omit(filters, ['__typename']);
  };

  const savedFilters = get(data, `currentUser.preferences.${filterName}`, []).map(getCleanedSavedFilters);

  useEffect(() => {
    refetch();
  }, [filterName, userProfile, refetch]);

  const addSavedFilter = async (savedFilter: SavedFilter<T>) => {
    const filtersInput = { [filterName]: [...savedFilters, savedFilter] };
    const results = await updatePreferences({ variables: { input: filtersInput } });

    if (results) {
      addSuccess('Filters saved.');
    }
  };

  const removeSavedFilter = async (filter: SavedFilter<T>) => {
    const filters = savedFilters.filter((savedFilter: SavedFilter<T>) => !isEqual(savedFilter, filter));
    const filtersInput = { [filterName]: filters };
    const results = await updatePreferences({ variables: { input: filtersInput } });

    if (results) {
      addSuccess('Filters removed.');
    }
  };

  const getFiltersWithValues = (filtersObject: T): Dictionary<unknown> =>
    omitBy<unknown>(filtersObject as Dictionary<unknown>, isNil);

  const getActiveSavedFilter = (appliedFilters: T): SavedFilter<T> => {
    return savedFilters.find((savedFilter: SavedFilter<T>) =>
      isEqual(getFiltersWithValues(savedFilter.filters), getFiltersWithValues(appliedFilters))
    );
  };

  return (
    <SavedFiltersContext.Provider value={{ savedFilters, addSavedFilter, removeSavedFilter, getActiveSavedFilter }}>
      {children}
    </SavedFiltersContext.Provider>
  );
}

export { SavedFiltersContext, SavedFiltersProvider, useSavedFilters };
