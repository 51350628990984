import { Route, Switch } from 'react-router-dom';

import EditProduct from './EditProduct/index';
import NewProduct from './NewProduct';
import ProductDetails from './ProductDetails';
import ProductList from './ProductList';

export const productPathPrefix = '/dashboard/products';

const ProductContainer = () => {
  return (
    <Switch>
      <Route path={`${productPathPrefix}/new`}>
        <NewProduct />
      </Route>
      <Route path={`${productPathPrefix}/:id/edit`}>
        <EditProduct />
      </Route>
      <Route path={`${productPathPrefix}/:productId`}>
        <ProductDetails />
      </Route>
      <Route path={productPathPrefix}>
        <ProductList />
      </Route>
    </Switch>
  );
};

export default ProductContainer;
