import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { DateTimeRangeFragmentDoc } from '../../graphql/fragments/DateTimeRange.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCargoDetailsQueryVariables = GraphQLTypes.Exact<{
  newCargoService: GraphQLTypes.Scalars['Boolean'];
  zencargoReference: GraphQLTypes.Scalars['String'];
  customerId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetCargoDetailsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<
            GraphQLTypes.Booking,
            | 'zencargoReference'
            | 'polDemurrageFreeTimeBegins'
            | 'podDemurrageFreeTimeEnds'
            | 'podDetentionFreeTime'
            | 'deliveryScheduleRequired'
            | 'collectionScheduleRequired'
            | 'modeOfTransport'
          > & {
              canCompleteDelivery: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canScheduleDelivery: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canCancelDelivery: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canConfirmDelivery: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canCompleteCollection: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canScheduleCollection: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canCancelCollection: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canConfirmCollection: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canManageCargo: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewCompleteCollection: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canManageCollectionAndDeliveryScheduling: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              estimatedArrival?: GraphQLTypes.Maybe<
                { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
              >;
              estimatedDeparture?: GraphQLTypes.Maybe<
                { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'timeZone'>
              >;
              customer?: GraphQLTypes.Maybe<
                { __typename?: 'BookingCustomerType' } & Pick<GraphQLTypes.BookingCustomerType, 'uuid'>
              >;
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    cargoItems?: GraphQLTypes.Maybe<
                      Array<
                        | ({ __typename?: 'CargoContainerType' } & Pick<
                            GraphQLTypes.CargoContainerType,
                            | 'containerType'
                            | 'containerNumber'
                            | 'containerSealNumber'
                            | 'overweight'
                            | 'id'
                            | 'type'
                            | 'cbm'
                            | 'hazardous'
                            | 'reefer'
                          > & {
                              canManageAssignedLots: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canManageCollectionLocation: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canManageDeliveryLocation: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canViewDeliveryDetails: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canViewCollectionDetails: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<
                                  GraphQLTypes.DeliveryDetails,
                                  | 'completedOn'
                                  | 'id'
                                  | 'state'
                                  | 'reference'
                                  | 'scheduleDate'
                                  | 'scheduleTime'
                                  | 'timeMode'
                                  | 'timedDelivery'
                                  | 'vehiclePlateNumber'
                                  | 'driverDetails'
                                > & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    specificTime?: GraphQLTypes.Maybe<
                                      { __typename?: 'SpecificTime' } & Pick<GraphQLTypes.SpecificTime, 'ampm' | 'hour'>
                                    >;
                                    latestDate?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  | 'state'
                                  | 'completedOn'
                                  | 'id'
                                  | 'reference'
                                  | 'scheduleDate'
                                  | 'scheduleTime'
                                  | 'timeMode'
                                  | 'timedDelivery'
                                  | 'vehiclePlateNumber'
                                  | 'driverDetails'
                                > & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    specificTime?: GraphQLTypes.Maybe<
                                      { __typename?: 'SpecificTime' } & Pick<GraphQLTypes.SpecificTime, 'ampm' | 'hour'>
                                    >;
                                    latestDate?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                            })
                        | ({ __typename?: 'CargoLooseCargoType' } & Pick<
                            GraphQLTypes.CargoLooseCargoType,
                            | 'containerNumber'
                            | 'looseCargoType'
                            | 'palletType'
                            | 'quantity'
                            | 'stackable'
                            | 'trailerId'
                            | 'id'
                            | 'type'
                            | 'cbm'
                            | 'hazardous'
                            | 'reefer'
                          > & {
                              chargeableWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                              dimensions?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoDimensionsType' } & Pick<
                                  GraphQLTypes.CargoDimensionsType,
                                  'length' | 'width' | 'height' | 'metric'
                                >
                              >;
                              canManageAssignedLots: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canManageCollectionLocation: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canManageDeliveryLocation: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canViewDeliveryDetails: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canViewCollectionDetails: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<
                                  GraphQLTypes.DeliveryDetails,
                                  | 'completedOn'
                                  | 'id'
                                  | 'state'
                                  | 'reference'
                                  | 'scheduleDate'
                                  | 'scheduleTime'
                                  | 'timeMode'
                                  | 'timedDelivery'
                                  | 'vehiclePlateNumber'
                                  | 'driverDetails'
                                > & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    specificTime?: GraphQLTypes.Maybe<
                                      { __typename?: 'SpecificTime' } & Pick<GraphQLTypes.SpecificTime, 'ampm' | 'hour'>
                                    >;
                                    latestDate?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  | 'state'
                                  | 'completedOn'
                                  | 'id'
                                  | 'reference'
                                  | 'scheduleDate'
                                  | 'scheduleTime'
                                  | 'timeMode'
                                  | 'timedDelivery'
                                  | 'vehiclePlateNumber'
                                  | 'driverDetails'
                                > & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    specificTime?: GraphQLTypes.Maybe<
                                      { __typename?: 'SpecificTime' } & Pick<GraphQLTypes.SpecificTime, 'ampm' | 'hour'>
                                    >;
                                    latestDate?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                            })
                        | ({ __typename?: 'CargoVehicleType' } & Pick<
                            GraphQLTypes.CargoVehicleType,
                            | 'trailerId'
                            | 'vehicleType'
                            | 'quantity'
                            | 'looseCargoType'
                            | 'riskLevel'
                            | 'tailLift'
                            | 'id'
                            | 'type'
                            | 'cbm'
                            | 'hazardous'
                            | 'reefer'
                          > & {
                              dimensions?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoDimensionsType' } & Pick<
                                  GraphQLTypes.CargoDimensionsType,
                                  'length' | 'width' | 'height' | 'metric'
                                >
                              >;
                              valueOfGoods?: GraphQLTypes.Maybe<
                                { __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>
                              >;
                              canManageAssignedLots: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canManageCollectionLocation: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canManageDeliveryLocation: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canViewDeliveryDetails: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              canViewCollectionDetails: { __typename?: 'AuthorizationResult' } & Pick<
                                GraphQLTypes.AuthorizationResult,
                                'value'
                              >;
                              grossWeight?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
                              >;
                              delivery?: GraphQLTypes.Maybe<
                                { __typename?: 'DeliveryDetails' } & Pick<
                                  GraphQLTypes.DeliveryDetails,
                                  | 'completedOn'
                                  | 'id'
                                  | 'state'
                                  | 'reference'
                                  | 'scheduleDate'
                                  | 'scheduleTime'
                                  | 'timeMode'
                                  | 'timedDelivery'
                                  | 'vehiclePlateNumber'
                                  | 'driverDetails'
                                > & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    specificTime?: GraphQLTypes.Maybe<
                                      { __typename?: 'SpecificTime' } & Pick<GraphQLTypes.SpecificTime, 'ampm' | 'hour'>
                                    >;
                                    latestDate?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                              collection?: GraphQLTypes.Maybe<
                                { __typename?: 'CollectionDetails' } & Pick<
                                  GraphQLTypes.CollectionDetails,
                                  | 'state'
                                  | 'completedOn'
                                  | 'id'
                                  | 'reference'
                                  | 'scheduleDate'
                                  | 'scheduleTime'
                                  | 'timeMode'
                                  | 'timedDelivery'
                                  | 'vehiclePlateNumber'
                                  | 'driverDetails'
                                > & {
                                    location?: GraphQLTypes.Maybe<
                                      | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksLegacyOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksOrgLoc' } & Pick<
                                          GraphQLTypes.NetworksOrgLoc,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                      | ({ __typename?: 'NetworksTerminal' } & Pick<
                                          GraphQLTypes.NetworksTerminal,
                                          'id' | 'timeZone'
                                        > & {
                                            label?: GraphQLTypes.Maybe<
                                              { __typename?: 'NetworksLabelType' } & Pick<
                                                GraphQLTypes.NetworksLabelType,
                                                'long' | 'short'
                                              >
                                            >;
                                          })
                                    >;
                                    specificTime?: GraphQLTypes.Maybe<
                                      { __typename?: 'SpecificTime' } & Pick<GraphQLTypes.SpecificTime, 'ampm' | 'hour'>
                                    >;
                                    latestDate?: GraphQLTypes.Maybe<
                                      { __typename: 'LocalDateTimeRange' } & {
                                        startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >;
                                        endDateTime?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeType' } & Pick<
                                            GraphQLTypes.LocalDateTimeType,
                                            'date' | 'time'
                                          >
                                        >;
                                      }
                                    >;
                                  }
                              >;
                            })
                      >
                    >;
                  }
              >;
              cargos?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'CoreCargo' } & Pick<
                    GraphQLTypes.CoreCargo,
                    | 'cargoType'
                    | 'containerNumber'
                    | 'containerSealNumber'
                    | 'hazardous'
                    | 'id'
                    | 'overweight'
                    | 'quantity'
                    | 'refrigerated'
                    | 'riskLevel'
                    | 'stackable'
                    | 'tailLift'
                    | 'trailerId'
                  > & {
                      cargoRelations?: GraphQLTypes.Maybe<
                        Array<
                          { __typename?: 'CargoRelation' } & Pick<GraphQLTypes.CargoRelation, 'startTimestamp'> & {
                              inputCargo?: GraphQLTypes.Maybe<
                                { __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'cargoType' | 'id' | 'quantity'>
                              >;
                            }
                        >
                      >;
                      chargeableWeight?: GraphQLTypes.Maybe<
                        { __typename?: 'CargoWeight' } & Pick<GraphQLTypes.CargoWeight, 'value' | 'unit'>
                      >;
                      dimensions?: GraphQLTypes.Maybe<
                        { __typename?: 'Dimensions' } & Pick<GraphQLTypes.Dimensions, 'width' | 'height' | 'length' | 'unit'>
                      >;
                      grossWeight?: GraphQLTypes.Maybe<
                        { __typename?: 'CargoWeight' } & Pick<GraphQLTypes.CargoWeight, 'value' | 'unit'>
                      >;
                      valueOfGoods?: GraphQLTypes.Maybe<
                        { __typename?: 'ValueOfGoods' } & Pick<GraphQLTypes.ValueOfGoods, 'value' | 'currency'>
                      >;
                      volume?: GraphQLTypes.Maybe<{ __typename?: 'Volume' } & Pick<GraphQLTypes.Volume, 'value' | 'unit'>>;
                    }
                >
              >;
            }
        >
      >
    >;
  };
};

export const GetCargoDetailsQueryDocument = /* #__PURE__ */ gql`
  query getCargoDetailsQuery($newCargoService: Boolean!, $zencargoReference: String!, $customerId: String) {
    bookings(zencargoReferences: [$zencargoReference], customerUuid: $customerId) {
      nodes {
        canCompleteDelivery {
          value
        }
        canScheduleDelivery {
          value
        }
        canCancelDelivery {
          value
        }
        canConfirmDelivery {
          value
        }
        canCompleteCollection {
          value
        }
        canScheduleCollection {
          value
        }
        canCancelCollection {
          value
        }
        canConfirmCollection {
          value
        }
        canManageCargo {
          value
        }
        canViewCompleteCollection {
          value
        }
        canManageCollectionAndDeliveryScheduling {
          value
        }
        zencargoReference
        estimatedArrival {
          date
          timeZone
        }
        estimatedDeparture {
          date
          timeZone
        }
        polDemurrageFreeTimeBegins
        podDemurrageFreeTimeEnds
        podDetentionFreeTime
        deliveryScheduleRequired
        collectionScheduleRequired
        customer {
          uuid
        }
        cargo {
          cargoItems {
            canManageAssignedLots {
              value
            }
            canManageCollectionLocation {
              value
            }
            canManageDeliveryLocation {
              value
            }
            canViewDeliveryDetails {
              value
            }
            canViewCollectionDetails {
              value
            }
            id
            type
            cbm
            hazardous
            reefer
            grossWeight {
              metric
              value
            }
            delivery {
              location {
                id
                label {
                  long
                  short
                }
                timeZone
              }
              completedOn
              id
              state
              reference
              scheduleDate
              scheduleTime
              specificTime {
                ampm
                hour
              }
              latestDate {
                ...DateTimeRange
              }
              timeMode
              timedDelivery
              vehiclePlateNumber
              driverDetails
            }
            collection {
              state
              location {
                id
                label {
                  long
                  short
                }
                timeZone
              }
              completedOn
              id
              reference
              scheduleDate
              scheduleTime
              specificTime {
                ampm
                hour
              }
              latestDate {
                ...DateTimeRange
              }
              timeMode
              timedDelivery
              vehiclePlateNumber
              driverDetails
            }
            ... on CargoContainerType {
              containerType
              containerNumber
              containerSealNumber
              overweight
            }
            ... on CargoLooseCargoType {
              chargeableWeight {
                metric
                value
              }
              containerNumber
              looseCargoType
              palletType
              quantity
              stackable
              dimensions {
                length
                width
                height
                metric
              }
              trailerId
            }
            ... on CargoVehicleType {
              trailerId
              vehicleType
              quantity
              looseCargoType
              dimensions {
                length
                width
                height
                metric
              }
              riskLevel
              tailLift
              valueOfGoods {
                currency
                value
              }
            }
          }
          mode
        }
        cargos @include(if: $newCargoService) {
          cargoRelations {
            inputCargo {
              cargoType
              id
              quantity
            }
            startTimestamp
          }
          cargoType
          chargeableWeight {
            value
            unit
          }
          containerNumber
          containerSealNumber
          dimensions {
            width
            height
            length
            unit
          }
          grossWeight {
            value
            unit
          }
          hazardous
          id
          overweight
          quantity
          refrigerated
          riskLevel
          stackable
          tailLift
          trailerId
          valueOfGoods {
            value
            currency
          }
          volume {
            value
            unit
          }
        }
        modeOfTransport
      }
    }
  }
  ${DateTimeRangeFragmentDoc}
`;

/**
 * __useGetCargoDetailsQuery__
 *
 * To run a query within a React component, call `useGetCargoDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCargoDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCargoDetailsQuery({
 *   variables: {
 *      newCargoService: // value for 'newCargoService'
 *      zencargoReference: // value for 'zencargoReference'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCargoDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCargoDetailsQueryResult, GetCargoDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCargoDetailsQueryResult, GetCargoDetailsQueryVariables>(GetCargoDetailsQueryDocument, options);
}
export function useGetCargoDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCargoDetailsQueryResult, GetCargoDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCargoDetailsQueryResult, GetCargoDetailsQueryVariables>(GetCargoDetailsQueryDocument, options);
}
export type GetCargoDetailsQueryHookResult = ReturnType<typeof useGetCargoDetailsQuery>;
export type GetCargoDetailsQueryLazyQueryHookResult = ReturnType<typeof useGetCargoDetailsQueryLazyQuery>;
