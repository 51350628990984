import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import FiltersButton from '@zen/Components/FiltersButton';
import PageSizeSelector from '@zen/Components/PageSizeSelector';
import Search from '@zen/Components/Search';
import { TableColumn, useTableConfiguration } from '@zen/DesignSystem';
import { useOperationsContext } from '@zen/OperationsDashboard/context/OperationsContext';
import { PageSize } from '@zen/types';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

interface Props<T> {
  columns: TableColumn<T>[];
  filtersCount: number;
  onPageSizeChange: (size: PageSize) => void;
  pageSize?: PageSize;
}

const Toolbar = <T extends {}>({ columns, filtersCount, onPageSizeChange, pageSize = PageSize.TWENTY }: Props<T>) => {
  const { search, setSearch } = useUrlFilters();
  const { hiddenColumns, setHiddenColumns } = useTableConfiguration();
  const { openFilters } = useOperationsContext();

  return (
    <div className="flex mb-4" data-testid="operations-toolbar">
      <div className="flex w-full">
        <div className="grow">
          <Search
            initialValue={search}
            onClear={() => setSearch(undefined)}
            onSubmit={setSearch}
            placeholder="Search for a shipment..."
          />
        </div>
        <FiltersButton className="mx-4" filterCount={filtersCount} onClick={openFilters} />
        <CustomiseTableButton columns={columns} hiddenColumns={hiddenColumns} onChange={setHiddenColumns} />
        <PageSizeSelector onChange={onPageSizeChange} pageSize={pageSize} />
      </div>
    </div>
  );
};

export default Toolbar;
