import { FC, ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import NoResults from '@zen/Components/NoResults';
import QueryHandler from '@zen/Components/QueryHandler';
import { authRoutes } from '@zen/Routes';
import type { Optional } from '@zen/utils/typescript';

import { GlobalPermissionsContextProvider } from './GlobalPermissionsContext';
import useAuthorization from './hooks/useAuthorization';
import { Role } from './role';
import RoleContext from './RoleContext';
import type { Authorization, GlobalPermissions } from './types';

const getRole = (data: Optional<Authorization>): Role => {
  let role = Role.CUSTOMER_USER;

  if (!data) {
    return role;
  }

  const { agentForwarderAccounts, manufacturerAccounts, customerUserAccounts, isAdmin } = data;

  if (agentForwarderAccounts.length > 0) {
    role = Role.AGENT_FORWARDER;
  }

  if (manufacturerAccounts.length > 0) {
    role = Role.MANUFACTURER;
  }

  if (customerUserAccounts.length > 0) {
    role = Role.CUSTOMER_USER;
  }

  if (isAdmin) {
    role = Role.ADMIN;
  }

  return role;
};

const RoleProvider: FC = ({ children }) => {
  const { data, loading: isLoading, error } = useAuthorization();
  const history = useHistory();
  const authorization: Optional<Authorization> = data?.authorization;

  useEffect(() => {
    const storageListener = () => {
      if (authorization) {
        const role: Role = getRole(authorization);

        if (window.localStorage.getItem('role') !== role && role !== Role.ADMIN) {
          history.push(authRoutes.accountSwitcher.getUrl());
        }
      }
    };

    window.addEventListener('storage', storageListener);

    return () => {
      window.removeEventListener('storage', storageListener);
    };
  });

  if (!data) {
    return isLoading ? null : <NoResults headline="Something went wrong." tagline="Please try again later" />;
  }

  const role = getRole(authorization);

  window.localStorage.setItem('role', role);

  return (
    <QueryHandler data={data?.globalPermissions} error={!!error} isLoading={isLoading}>
      {(globalPermissions: GlobalPermissions): ReactElement => (
        <RoleContext.Provider value={role}>
          <GlobalPermissionsContextProvider value={globalPermissions}>{children}</GlobalPermissionsContextProvider>
        </RoleContext.Provider>
      )}
    </QueryHandler>
  );
};

export default RoleProvider;
