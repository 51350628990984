import { useEffect } from 'react';

import { PageInfo, PageSize } from '@zen/types';
import { useQueryParams } from '@zen/utils/QueryParams';

import { useComponentDidMount } from '../useComponentDidMount';
import { getNextPageVariables, getPreviousPageVariables } from './helpers';
import type { PaginatedQueryResult, PaginatedVariables, PaginationQueryHookVariables, UseQueryHookType } from './types';
import usePaginatedQuery from './usePaginatedQuery';

const useUrlPagination = <Result, Variables extends PaginatedVariables, NodeType>(
  useQueryHook: UseQueryHookType<Result, Variables>,
  responsePath: string,
  variables: PaginationQueryHookVariables<Variables>,
  options: object = {},
  limit: number = PageSize.TWENTY
): PaginatedQueryResult<Result, Variables, NodeType> => {
  const { queryParams, setQueryParams } = useQueryParams<PaginatedVariables>('page', { first: limit });
  const isComponentMounted: boolean = useComponentDidMount();

  const { fetchMore, pageInfo, ...queryResults } = usePaginatedQuery<Result, Variables, NodeType>(
    useQueryHook,
    responsePath,
    { ...variables, ...queryParams },
    options
  );

  useEffect(() => {
    if (isComponentMounted) {
      setQueryParams({ first: limit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const { startCursor, endCursor, hasNextPage, hasPreviousPage } = pageInfo;

  const handleNext = (): void => {
    setQueryParams(getNextPageVariables(limit, endCursor));
  };

  const handlePrevious = (): void => {
    setQueryParams(getPreviousPageVariables(limit, startCursor));
  };

  const paginationInfo: PageInfo = { hasNextPage, hasPreviousPage, onNext: handleNext, onPrevious: handlePrevious };

  return { ...queryResults, paginationInfo };
};

export default useUrlPagination;
