import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetDocumentTemplateFieldsQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetDocumentTemplateFieldsQueryResult = { __typename?: 'Query' } & {
  documentTemplateFields: Array<
    { __typename?: 'DocumentTemplateField' } & Pick<GraphQLTypes.DocumentTemplateField, 'label' | 'value'> & {
        options?: GraphQLTypes.Maybe<
          Array<{ __typename?: 'DocumentTemplateField' } & Pick<GraphQLTypes.DocumentTemplateField, 'label' | 'value'>>
        >;
      }
  >;
};

export const GetDocumentTemplateFieldsDocument = /* #__PURE__ */ gql`
  query getDocumentTemplateFields {
    documentTemplateFields {
      label
      value
      options {
        label
        value
      }
    }
  }
`;

/**
 * __useGetDocumentTemplateFieldsQuery__
 *
 * To run a query within a React component, call `useGetDocumentTemplateFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTemplateFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTemplateFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocumentTemplateFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDocumentTemplateFieldsQueryResult, GetDocumentTemplateFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetDocumentTemplateFieldsQueryResult, GetDocumentTemplateFieldsQueryVariables>(
    GetDocumentTemplateFieldsDocument,
    options
  );
}
export function useGetDocumentTemplateFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTemplateFieldsQueryResult, GetDocumentTemplateFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetDocumentTemplateFieldsQueryResult, GetDocumentTemplateFieldsQueryVariables>(
    GetDocumentTemplateFieldsDocument,
    options
  );
}
export type GetDocumentTemplateFieldsQueryHookResult = ReturnType<typeof useGetDocumentTemplateFieldsQuery>;
export type GetDocumentTemplateFieldsLazyQueryHookResult = ReturnType<typeof useGetDocumentTemplateFieldsLazyQuery>;
