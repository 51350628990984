import { get, remove } from 'lodash';
import { FC, useState } from 'react';
import * as Yup from 'yup';

import { Form, FormInstance } from '@zen/Components/Form';
import { getOrgLocId } from '@zen/Networks/networksHelpers';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, OrgLoc } from '@zen/Networks/types';
import type {
  CreateOrderResponse,
  OrderFormPayload,
  OrderFormValues,
  OrderLineItemPayload,
  OrderLineItemValues
} from '@zen/Orders/types';
import useAccount from '@zen/utils/hooks/useAccount';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Nullable } from '@zen/utils/typescript';

import GeneralInformation from './GeneralInformation';
import OrderContactsFields from './OrderContactsFields';
import OrderLineItems from './OrderLineItems';
import { emptyLineItem } from './OrderLineItems/OrderLineItems';
import RoutingInformation from './RoutingInformation';

export const emptyOrder: OrderFormValues = {
  orderReferenceNumber: null,
  orderDate: null,
  buyer: null,
  manufacturer: null,
  seller: null,
  incoterms: null,
  modeOfTransport: null,
  collectionWarehouse: null,
  deliveryWarehouse: null,
  portOfLoadUnlocode: null,
  originAgent: null,
  portOfDestinationUnlocode: null,
  orderedLineItems: [emptyLineItem],
  forwarder: null
};

const validationSchema = Yup.object().shape({
  orderReferenceNumber: Yup.string().nullable(true).required('Order number is required.'),
  orderedLineItems: Yup.array().of(
    Yup.object().shape({
      cbm: Yup.number().nullable().positive('Must be greater than 0.'),
      product: Yup.object().nullable().required('Product name / SKU is required.'),
      quantityOrdered: Yup.number()
        .integer('Qty must be whole number.')
        .typeError('Qty is required.')
        .positive('Qty is required.')
        .required('Qty is required.')
    })
  )
});

const preparePayload = (values: OrderFormValues): OrderFormPayload => {
  const {
    orderedLineItems,
    portOfLoadUnlocode,
    portOfDestinationUnlocode,
    manufacturer,
    seller,
    buyer,
    collectionWarehouse,
    deliveryWarehouse,
    originAgent,
    forwarder,
    id,
    ...rest
  } = values;

  const lineItems = orderedLineItems.map((item: OrderLineItemValues) => {
    const { product, cbm, quantityOrdered, initialCargoReadyDate, requiredDeliveryDate, totalCost } = item;
    const lineItem: OrderLineItemPayload = {
      productSku: product?.skuCode || '',
      cbm,
      quantityOrdered: quantityOrdered || 0,
      initialCargoReadyDate,
      requiredDeliveryDate,
      totalCost: totalCost.value ? { value: totalCost.value, currency: totalCost.currency } : null
    };

    if (item.id) {
      lineItem.id = item.id;
    }

    return lineItem;
  });

  return {
    ...rest,
    manufacturerId: manufacturer && getOrgLocId(manufacturer),
    sellerId: seller && getOrgLocId(seller),
    buyerId: buyer && getOrgLocId(buyer),
    originId: collectionWarehouse && getOrgLocId(collectionWarehouse),
    destinationId: deliveryWarehouse && getOrgLocId(deliveryWarehouse),
    originAgentId: originAgent && getOrgLocId(originAgent),
    forwarderId: forwarder && getOrgLocId(forwarder),
    orderedLineItems: lineItems,
    portOfLoadUnlocode: get(portOfLoadUnlocode, 'unlocode', null),
    portOfDestinationUnlocode: get(portOfDestinationUnlocode, 'unlocode', null)
  };
};

export interface NetworkFieldProps {
  accountUuid: string;
  assignmentType: AssignmentTypeValue;
  domainName: AssignmentTargetTypeEnum;
  formTitle: string;
  label: string;
  name: string;
  onSelect: (fieldName: string, value: Nullable<OrgLoc>) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

interface Props {
  buttonText: string;
  initialValues?: OrderFormValues;
  onSubmit: (values: OrderFormPayload, assignmentNames?: string[]) => Promise<IOkOrErrorResult>;
  onSuccess: (values: CreateOrderResponse) => void;
}

const OrderForm: FC<Props> = (props) => {
  const { initialValues = emptyOrder, buttonText, onSubmit, onSuccess } = props;
  const { accountUuid } = useAccount();
  const [deletedFields, setDeletedFields] = useState<AssignmentTypeValue[]>([]);

  const handleSubmit = (values: OrderFormValues): Promise<IOkOrErrorResult> => {
    const formValues = preparePayload(values);

    return onSubmit(formValues, deletedFields);
  };

  const handleSelectWithDelete = (
    fieldName: keyof FormInstance<OrderFormValues>['values'],
    value: Nullable<OrgLoc>,
    form: FormInstance<OrderFormValues>,
    assignmentType: AssignmentTypeValue
  ) => {
    if (value === null && form.values[fieldName]) {
      setDeletedFields([...deletedFields, assignmentType]);
    } else {
      remove(deletedFields, (element: string) => element === assignmentType);
      setDeletedFields([...deletedFields]);
    }
  };

  return (
    <Form
      buttonClassName="my-8"
      buttonText={buttonText}
      className="mt-4"
      enableReinitialize={true}
      formName="OrderForm"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {(form) => {
        const {
          setFieldValue,
          values: { orderedLineItems }
        } = form;

        const getFieldProps = (name: string, label: string, assignmentType: AssignmentTypeValue): NetworkFieldProps => ({
          accountUuid,
          domainName: AssignmentTargetTypeEnum.PURCHASE_ORDER,
          assignmentType,
          formTitle: `Add new ${label}`,
          label,
          name,
          onSelect: (fieldName, value) => handleSelectWithDelete(fieldName as keyof OrderFormValues, value, form, assignmentType),
          setFieldValue
        });

        return (
          <>
            <GeneralInformation />
            <OrderContactsFields getFieldProps={getFieldProps} />
            <RoutingInformation getFieldProps={getFieldProps} />
            <OrderLineItems form={form} items={orderedLineItems} />
          </>
        );
      }}
    </Form>
  );
};

export default OrderForm;
