import { range } from 'lodash';
import moment, { Moment } from 'moment';
import type { FC } from 'react';

import { DATE_FORMAT } from '../utils';
import Week from '../Week';

interface Props {
  day: string; // YYYY-MM-DD
}

const Month: FC<Props> = (props) => {
  const { day } = props;

  const isWeekInMonth = (startOfWeek: Moment): boolean => {
    const endOfWeek: Moment = startOfWeek.clone().add(6, 'days');

    return startOfWeek.isSame(day, 'month') || endOfWeek.isSame(day, 'month');
  };

  const renderHeader = () => {
    const startOfWeek: Moment = moment(day).startOf('month').startOf('isoWeek');

    return (
      <table className="w-full table-fixed bg-grey-lightest border-separate table-fixed px-3 py-5">
        <tbody>
          <tr className="text-center text-grey-base text-xs">
            {range(7).map((offset, i) => (
              <td key={i}>{startOfWeek.clone().add(offset, 'days').format('dd').charAt(0)}</td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  const renderWeeks = () => {
    const startOfMonth: Moment = moment(day, DATE_FORMAT).clone().startOf('month').startOf('isoWeek');
    // ts-error-ignore
    const weeks: Moment[] = range(6).map((offset) => startOfMonth.clone().add(offset, 'week'));
    const weeksInMonth: Moment[] = weeks.filter((startOfWeek) => isWeekInMonth(startOfWeek));

    return weeksInMonth.map((startOfWeek, offset) => <Week key={offset} day={moment(startOfWeek).format(DATE_FORMAT)} />);
  };

  return (
    <div className="bg-white">
      {renderHeader()}
      <table className="w-full border-separate min-h-64 px-3.5 py-4 table-fixed">
        <tbody>{renderWeeks()}</tbody>
      </table>
    </div>
  );
};

export default Month;
