import type { FC } from 'react';

import { Select } from '@zen/DesignSystem';
import { tradeRoleOptions } from '@zen/Shipment/helpers';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import type { TradeRole } from '../../types';
import { useUpdateTradeRoleMutation } from '../graphql';

interface Props {
  canUpdateTradeRole: boolean;
  refetchShipmentDetails: () => void;
  tradeRole: TradeRole;
  zencargoReference: string;
}

const TradeRoleCargoInfoInput: FC<Props> = ({ tradeRole, zencargoReference, refetchShipmentDetails, canUpdateTradeRole }) => {
  const { addSuccess, addError } = useNotification();
  const [updateTradeRole] = useUpdateTradeRoleMutation();

  const handleUpdateTradeRole = async (tradeRoleValue: Nullable<TradeRole>): Promise<void> => {
    if (tradeRoleValue) {
      await performMutation({
        mutationFn: () =>
          updateTradeRole({
            variables: {
              input: {
                zencargoReference,
                tradeRole: tradeRoleValue
              }
            }
          }),
        onError: () => addError(),
        onSuccess: () => {
          refetchShipmentDetails();
          addSuccess('Trade role updated.');
        }
      });
    }
  };

  return (
    <Select
      className="w-28"
      isDisabled={!canUpdateTradeRole}
      name="tradeRole"
      onChange={handleUpdateTradeRole}
      options={tradeRoleOptions}
      overLabel="Trade role"
      value={tradeRole}
    />
  );
};

export default TradeRoleCargoInfoInput;
