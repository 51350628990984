import type { Currency } from '@zen/graphql/types.generated';

import type { GetQuotesQueryNode } from './graphql';

export type { Cargo, Booking as Quote, QuoteOption } from '@zen/graphql/types.generated';
export { Currency, QuoteOptionRejectionReason, QuoteOptionState, StageValue } from '@zen/graphql/types.generated';

export enum CargoType {
  ASSORTED = 'ASSORTED',
  BOX = 'BOX',
  BOXES_OR_CRATES = 'BOXES_OR_CRATES',
  CONTAINER = 'CONTAINER',
  PALLET = 'PALLET',
  VEHICLE = 'VEHICLE'
}
export type QuotesTableItem = GetQuotesQueryNode;

export interface QuoteOptionTotal {
  currency: Currency;
  value: number;
}
