import type { FC } from 'react';

import { formatDate } from '@zen/utils/dateTime';

import type { LastUpdatedType } from './types';
import { ReactComponent as ZencargoIcon } from './zencargo-icon.svg';

interface Props {
  lastUpdate: LastUpdatedType;
}

const LastUpdated: FC<Props> = ({ lastUpdate }) => {
  const { updatedAt, updatedBy } = lastUpdate;

  const renderUpdateBy = () => (
    <>
      {' '}
      by {updatedBy?.zencargoEmployee && <ZencargoIcon className="inline-block w-5 h-5" data-testid="zencargo-icon" />}{' '}
      <span className="font-bold">{updatedBy?.username}</span>
    </>
  );

  return (
    <div className="text-grey-dark text-xs inline-block mr-2">
      {updatedAt && <>Last updated: {formatDate(updatedAt)}</>}
      {updatedBy && updatedBy.username && renderUpdateBy()}.
    </div>
  );
};

export default LastUpdated;
