import { get, isEqual, mergeWith, pick } from 'lodash';

import { getDefaultDimensions } from '@zen/Cargo/forms/LooseForm/helpers';
import { getDimensions } from '@zen/Cargo/helpers';
import {
  CargoModeEnum,
  CargoWeightUnitEnum,
  CreateLooseCargoItemInput,
  CreateVehicleCargoItemInput,
  Currency,
  RoadShipmentInput,
  UpdateCollectionDatesInput,
  UpdateDeliveryDatesInput,
  UpdateLooseCargoItemInput,
  UpdateVehicleCargoItemInput,
  VoyageDelayDataInput
} from '@zen/graphql/types.generated';
import { getLocationData } from '@zen/Networks';
import type { NetworksAssignableInterface } from '@zen/Shipment';
import { formatDateAsISO8601, formatTime } from '@zen/utils/dateTime';
import type { Nullable, Optional } from '@zen/utils/typescript';
import { deepMergeNilValues } from '@zen/utils/utils';

import type { LocalDateTimeType } from '../types';
import type {
  CargoItem,
  CollectionDates,
  DateWithTime,
  DateWithTimeRange,
  DateWithTimeRangeAndDelayReason,
  DelayReason,
  DeliveryDates,
  NetworkField,
  RoadShipment,
  RoadShipmentCargoItem,
  RoadShipmentFields
} from './types';

const getNetworkField = (location: Optional<NetworksAssignableInterface>): Nullable<NetworkField> => {
  if (!location) {
    return null;
  }

  return {
    id: location.id,
    label: location.label
  };
};

const prepareVehicleCargoRequestPayload = (cargo: CargoItem) => {
  const {
    cbm,
    dimensions,
    grossWeight,
    hazardous,
    looseCargoType,
    palletType,
    reefer,
    riskLevel,
    quantity,
    tailLift,
    trailerId,
    valueOfGoods,
    vehicleType
  } = cargo;

  return {
    actualCbm: cbm,
    dimensions: getDimensions(dimensions),
    grossWeight: grossWeight?.value ? grossWeight : null,
    hazardous,
    looseCargoType,
    palletType,
    reefer,
    riskLevel,
    quantity,
    tailLift,
    trailerId,
    valueOfGoods: valueOfGoods?.value ? valueOfGoods : null,
    vehicleType
  };
};

const prepareLooseCargoRequestPayload = (cargo: CargoItem) => {
  const { cbm, dimensions, grossWeight, hazardous, looseCargoType, palletType, reefer, quantity, stackable, trailerId } = cargo;

  const payload = {
    actualCbm: cbm,
    dimensions: getDimensions(dimensions),
    grossWeight: grossWeight?.value ? grossWeight : null,
    hazardous,
    looseCargoType,
    palletType,
    reefer,
    quantity,
    stackable,
    trailerId
  };

  return payload;
};

const getCargoFields = (cargoItem: Optional<RoadShipmentCargoItem>): CargoItem => {
  const defaultValues: CargoItem = {
    cbm: null,
    grossWeight: {
      value: null,
      metric: CargoWeightUnitEnum.KG
    },
    dimensions: getDefaultDimensions(get(cargoItem, 'palletType')),
    hazardous: false,
    id: '',
    looseCargoType: null,
    extendedLooseCargoType: null,
    palletType: null,
    quantity: 1,
    reefer: false,
    riskLevel: null,
    stackable: false,
    tailLift: false,
    trailerId: null,
    valueOfGoods: {
      currency: Currency.GBP,
      value: null
    },
    vehicleType: null
  };

  const cargoFields = pick(cargoItem, Object.keys(defaultValues));
  const valuesWithDefaults = mergeWith({}, cargoFields, defaultValues, deepMergeNilValues);

  return {
    ...valuesWithDefaults,
    extendedLooseCargoType: valuesWithDefaults.palletType ? valuesWithDefaults.palletType : valuesWithDefaults.looseCargoType
  };
};

const getCollectionDates = (cargoItem: Optional<RoadShipmentCargoItem>): CollectionDates => {
  if (!cargoItem?.collection) {
    return getEmptyCollectionDates();
  }

  const { arrivedAtWarehouse, departedFromWarehouse, confirmedByForwarder, requestedWithForwarder } = cargoItem.collection;

  return {
    collectedOn: {
      ...prepareDateTimeRangeFields(arrivedAtWarehouse, departedFromWarehouse),
      reason: null,
      description: null
    },
    confirmedByForwarder: prepareDateTimeRangeFields(confirmedByForwarder?.startDateTime, confirmedByForwarder?.endDateTime),
    requestedWithForwarder: prepareDateTimeRangeFields(requestedWithForwarder?.startDateTime, requestedWithForwarder?.endDateTime)
  };
};

const prepareTimeField = (time: Optional<string>): Nullable<string> => {
  return time ? formatTime(time, false) : null;
};

const prepareDateTimeFields = (dateTime: Optional<LocalDateTimeType>): DateWithTime => {
  return {
    date: dateTime?.date || null,
    startTime: prepareTimeField(dateTime?.time)
  };
};

const getEmptyDateTimeRange = (): DateWithTimeRange => {
  return {
    date: null,
    startTime: null,
    endTime: null
  };
};

export const getEmptyCollectionDates = (): CollectionDates => {
  const dateTimeRange: DateWithTimeRange = getEmptyDateTimeRange();

  return {
    collectedOn: {
      ...dateTimeRange,
      reason: null,
      description: null
    },
    confirmedByForwarder: dateTimeRange,
    requestedWithForwarder: dateTimeRange
  };
};

export const getEmptyDeliveryDates = (): DeliveryDates => {
  const dateTimeRange: DateWithTimeRange = getEmptyDateTimeRange();

  return {
    deliveredOn: {
      date: null,
      startTime: null,
      reason: null,
      description: null
    },
    confirmedByForwarder: dateTimeRange,
    requestedWithForwarder: dateTimeRange
  };
};

export const prepareDateTimeRangeFields = (
  startDateTime: Optional<LocalDateTimeType>,
  endDateTime: Optional<LocalDateTimeType>
): DateWithTimeRangeAndDelayReason => {
  return {
    ...prepareDateTimeFields(startDateTime),
    endTime: prepareTimeField(endDateTime?.time)
  };
};

const getDeliveryDates = (cargoItem: Optional<RoadShipmentCargoItem>): DeliveryDates => {
  if (!cargoItem?.delivery) {
    return getEmptyDeliveryDates();
  }

  const { arrivedAtWarehouse, confirmedByForwarder, requestedWithForwarder } = cargoItem.delivery;

  return {
    deliveredOn: {
      ...prepareDateTimeFields(arrivedAtWarehouse),
      reason: null,
      description: null
    },
    confirmedByForwarder: prepareDateTimeRangeFields(confirmedByForwarder?.startDateTime, confirmedByForwarder?.endDateTime),
    requestedWithForwarder: prepareDateTimeRangeFields(requestedWithForwarder?.startDateTime, requestedWithForwarder?.endDateTime)
  };
};

export const prepareInitialValues = (roadShipment: Optional<RoadShipment>): RoadShipmentFields | undefined => {
  if (!roadShipment) {
    return;
  }

  const {
    cargo,
    cargoDescription,
    cargoReadyDate,
    clientReference,
    consignee,
    consignor,
    customsOnly,
    haulier,
    incoterms,
    multiStop,
    shipmentType,
    specialInstructions,
    tradeRole,
    truckSwap
  } = roadShipment;

  const cargoItem: Optional<RoadShipmentCargoItem> = cargo?.cargoItems?.[0];

  return {
    cargoDescription,
    cargoItem: getCargoFields(cargoItem),
    cargoMode: cargo?.mode || CargoModeEnum.FTL,
    cargoReadyDate: cargoReadyDate?.date,
    clientReference,
    collection: getCollectionDates(cargoItem),
    collectionLocation: getLocationData(cargoItem?.collection?.location),
    collectionReference: cargoItem?.collection?.reference,
    consignee: getNetworkField(consignee),
    consignor: getNetworkField(consignor),
    customsOnly,
    delivery: getDeliveryDates(cargoItem),
    deliveryLocation: getLocationData(cargoItem?.delivery?.location),
    deliveryReference: cargoItem?.delivery?.reference,
    driverDetails: cargoItem?.collection?.driverDetails,
    haulier,
    incoterms: incoterms?.value,
    multiStop,
    shipmentType,
    specialInstructions: specialInstructions?.message,
    tradeRole,
    truckSwap,
    vehiclePlateNumber: cargoItem?.collection?.vehiclePlateNumber
  };
};

const collectionFields: Array<keyof RoadShipmentFields> = ['collectionReference', 'driverDetails', 'vehiclePlateNumber'];
const deliveryFields: Array<keyof RoadShipmentFields> = ['deliveryReference', 'driverDetails', 'vehiclePlateNumber'];

export const prepareCollectionPayload = (values: Partial<RoadShipmentFields>): Partial<RoadShipmentFields> => {
  return pick(values, collectionFields);
};

export const prepareDeliveryPayload = (values: Partial<RoadShipmentFields>): Partial<RoadShipmentFields> => {
  return pick(values, deliveryFields);
};

export const hasDataChanged = (values: Partial<RoadShipmentFields>, fields: Array<keyof RoadShipmentFields>): boolean => {
  return fields.some((field) => field in values);
};

export const hasCollectionDetailsChanged = (values: Partial<RoadShipmentFields>): boolean => {
  return hasDataChanged(values, collectionFields);
};

export const hasDeliveryDetailsChanged = (values: Partial<RoadShipmentFields>): boolean => {
  return hasDataChanged(values, deliveryFields);
};

export const getDirtyFields = <T extends {}>(values: T, initialValues: Optional<T>): Partial<T> => {
  const dirtyFields: string[] = getDirtyFieldNames(values, initialValues);

  return pick(values, dirtyFields);
};

export const getDirtyFieldNames = <T extends {}>(values: T, initialValues: Optional<T>): string[] => {
  return Object.keys(values).filter((key) => {
    const fieldName = key as keyof T;

    return !isEqual(values[fieldName], initialValues?.[fieldName]);
  });
};

export const getEditedFieldNames = (values: RoadShipmentFields, initialValues: Optional<RoadShipmentFields>): string[] => {
  const nestedFields: string[] = ['cargoItem', 'collection', 'delivery'];

  const editedFields: string[] = getDirtyFieldNames(values, initialValues).reduce((prevVal: string[], currentValue: string) => {
    if (nestedFields.includes(currentValue)) {
      const fieldName = currentValue as 'cargoItem' | 'collection' | 'delivery';
      const editedNestedFields: string[] = getDirtyFieldNames(values[fieldName], initialValues?.[fieldName]);

      return prevVal.concat(editedNestedFields.map((field) => `${currentValue}.${field}`));
    }

    return prevVal.concat([currentValue]);
  }, []);

  return editedFields;
};

export const prepareBookingInput = (values: Partial<RoadShipmentFields>): RoadShipmentInput => {
  const isFieldPresent = (fieldName: string): boolean => fieldName in values;

  return {
    ...(isFieldPresent('cargoDescription') ? { cargoDescription: values.cargoDescription } : {}),
    ...(isFieldPresent('cargoReadyDate') ? { cargoReadyDate: values.cargoReadyDate } : {}),
    ...(isFieldPresent('clientReference') ? { clientReference: values.clientReference } : {}),
    ...(isFieldPresent('collectionLocation') ? { originId: values.collectionLocation?.id || null } : {}),
    ...(isFieldPresent('consignor') ? { consignorId: values.consignor?.id || null } : {}),
    ...(isFieldPresent('consignee') ? { consigneeId: values.consignee?.id || null } : {}),
    ...(isFieldPresent('customsOnly') ? { customsOnly: values.customsOnly } : {}),
    ...(isFieldPresent('deliveryLocation') ? { destinationId: values.deliveryLocation?.id || null } : {}),
    ...(isFieldPresent('haulier') ? { haulierId: values.haulier?.id || null } : {}),
    ...(isFieldPresent('incoterms') ? { incoterms: values.incoterms } : {}),
    ...(isFieldPresent('multiStop') ? { multiStop: values.multiStop } : {}),
    ...(isFieldPresent('shipmentType') ? { shipmentType: values.shipmentType } : {}),
    ...(isFieldPresent('specialInstructions') ? { specialInstructions: values.specialInstructions } : {}),
    ...(isFieldPresent('tradeRole') ? { tradeRole: values.tradeRole } : {}),
    ...(isFieldPresent('truckSwap') ? { truckSwap: values.truckSwap } : {})
  };
};

const prepareDateAndTime = (date: Optional<string>, time: Optional<string>): LocalDateTimeType => {
  return {
    date: date ? formatDateAsISO8601(date) : null,
    time: time ? formatTime(time) : null
  } as LocalDateTimeType;
};

export const prepareDateTimePayload = (confirmedByForwarder: DateWithTimeRange) => {
  const { date, startTime, endTime } = confirmedByForwarder;

  return {
    startDateTime: prepareDateAndTime(date, startTime),
    endDateTime: endTime ? prepareDateAndTime(date, endTime) : null
  };
};

const prepareArrivedAtWarehousePayload = (arrivedAtWarehouse: DateWithTime) => {
  const { date, startTime } = arrivedAtWarehouse;

  return {
    arrivedAtWarehouse: prepareDateAndTime(date, startTime)
  };
};

export const prepareCollectedOnPayload = (collectedOn: CollectionDates['collectedOn']) => {
  const { date, startTime, endTime } = collectedOn;

  return {
    ...prepareArrivedAtWarehousePayload({ date, startTime }),
    departedFromWarehouse: endTime ? prepareDateAndTime(date, endTime) : null
  };
};

const isFieldPresent = (fieldName: string, dirtyFields: CollectionDates | DeliveryDates): boolean => fieldName in dirtyFields;

const getDateTimePayload = (
  fieldName: 'confirmedByForwarder' | 'requestedWithForwarder',
  dirtyFields: CollectionDates | DeliveryDates
) => {
  if (isFieldPresent(fieldName, dirtyFields)) {
    return {
      [fieldName]: prepareDateTimePayload(dirtyFields[fieldName])
    };
  }

  return {};
};

export const prepareCollectionDatesPayload = (
  collection: CollectionDates,
  zencargoReference: string,
  cargoId: string
): UpdateCollectionDatesInput => {
  return {
    ...(isFieldPresent('collectedOn', collection) ? prepareCollectedOnPayload(collection.collectedOn) : {}),
    ...getDateTimePayload('confirmedByForwarder', collection),
    ...getDateTimePayload('requestedWithForwarder', collection),
    clearSchedule: collection.clearSchedule,
    zencargoReference,
    cargoId
  } as UpdateCollectionDatesInput;
};

export const prepareDeliveryDatesPayload = (
  delivery: DeliveryDates,
  zencargoReference: string,
  cargoId: string
): UpdateDeliveryDatesInput => {
  return {
    ...(isFieldPresent('deliveredOn', delivery) ? prepareArrivedAtWarehousePayload(delivery.deliveredOn) : {}),
    ...getDateTimePayload('confirmedByForwarder', delivery),
    ...getDateTimePayload('requestedWithForwarder', delivery),
    clearSchedule: delivery.clearSchedule,
    zencargoReference,
    cargoId
  } as UpdateDeliveryDatesInput;
};

export const prepareCreateVehicleCargoItemPayload = (
  cargo: CargoItem,
  zencargoReference: string
): CreateVehicleCargoItemInput => {
  return {
    ...prepareVehicleCargoRequestPayload(cargo),
    zencargoReference
  } as CreateVehicleCargoItemInput;
};

export const prepareUpdateVehicleCargoItemPayload = (cargo: CargoItem): UpdateVehicleCargoItemInput => {
  return {
    ...prepareVehicleCargoRequestPayload(cargo),
    id: cargo.id
  } as UpdateVehicleCargoItemInput;
};

export const prepareCreateLooseCargoItemPayload = (cargo: CargoItem, zencargoReference: string): CreateLooseCargoItemInput => {
  return {
    ...prepareLooseCargoRequestPayload(cargo),
    zencargoReference
  } as CreateLooseCargoItemInput;
};

export const prepareUpdateLooseCargoItemPayload = (cargo: CargoItem): UpdateLooseCargoItemInput => {
  return {
    ...prepareLooseCargoRequestPayload(cargo),
    id: cargo.id
  } as UpdateLooseCargoItemInput;
};

export const prepareDelayReasons = <T extends DelayReason>(delayReasons: Array<T>): Array<VoyageDelayDataInput> => {
  return delayReasons
    .filter((delayReason: T) => !!delayReason?.reason)
    .map((delayReason: T) => ({ reason: delayReason.reason || '', description: delayReason.description || '' }));
};
