import type { FC } from 'react';
import { useParams } from 'react-router';

import QueryHandler from '@zen/Components/QueryHandler';
import DetailsCard from '@zen/Consolidation/ShowConsolidationDetails/components/DetailsCard';
import Section from '@zen/Shipment/ShipmentDetailsPage/Components/Section';
import { cargoTypeLabelMapping } from '@zen/types';
import { formatDate } from '@zen/utils/dateTime';
import { formatNumber } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

import { useConsolidatedShipmentDetailsQuery } from '../graphql';
import type { ConsolidatedShipmentEditType, ConsolidationLooseCargoTypeEnum, ConsolidationWeightInputType } from '../types';
import ConsolidationLegsDetails from './components/ConsolidationLegsDetails';
import type { DetailsCardItem } from './components/DetailsCard/DetailsCard';

const ShowConsolidationDetails: FC = () => {
  const { consolidationId } = useParams<{ consolidationId: string }>();

  const { data, error, loading } = useConsolidatedShipmentDetailsQuery({
    variables: { consolidationId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const consolidatedShipmentData = data?.consolidations?.nodes?.[0];

  const formatWeight = (weight: Optional<ConsolidationWeightInputType>): string => {
    return weight?.value ? `${formatNumber(weight.value, 0)} ${weight?.metric?.toLowerCase()}` : '-';
  };

  const formatLooseCargoType = (
    looseCargoType: Optional<ConsolidationLooseCargoTypeEnum>,
    quantity: Optional<number>
  ): string => {
    return looseCargoType ? `${quantity} x ${cargoTypeLabelMapping[looseCargoType]}` : '-';
  };

  const generalInfo: DetailsCardItem[] = [
    { title: 'Reference', value: consolidatedShipmentData?.reference },
    { title: 'Transit document reference', value: consolidatedShipmentData?.transitDocumentReference },
    { title: 'Cut off', value: formatDate(consolidatedShipmentData?.cutOffDate || '') },
    { title: 'Vehicle plate', value: consolidatedShipmentData?.vehiclePlateNumber },
    { title: 'Total CBM', value: consolidatedShipmentData?.cbm },
    { title: 'Shipment weight', value: formatWeight(consolidatedShipmentData?.grossWeight) },
    {
      title: 'Loose cargo type',
      value: formatLooseCargoType(consolidatedShipmentData?.looseCargoType, consolidatedShipmentData?.quantity)
    }
  ];

  const agentInfo: DetailsCardItem[] = [
    {
      title: 'Origin agent',
      value: consolidatedShipmentData?.originAgent?.label?.long
    }
  ];

  return (
    <QueryHandler data={consolidatedShipmentData} error={!!error} isLoading={loading}>
      {(consolidatedShipment: ConsolidatedShipmentEditType) => (
        <>
          <DetailsCard dataId="consolidation-details" details={generalInfo} />
          <ConsolidationLegsDetails legs={consolidatedShipment.legs} />
          <Section anchor="agent-info" title="Agent">
            <DetailsCard dataId="agent" details={agentInfo} />
          </Section>
        </>
      )}
    </QueryHandler>
  );
};

export default ShowConsolidationDetails;
