import type { FC } from 'react';

import { CollectionAndDeliveryState } from '@zen/CollectionAndDelivery/types';
import { Pill, PillType } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

type PillConfig = { label: string; type: PillType };

interface Props {
  completedlabel: string;
  state: Optional<CollectionAndDeliveryState>;
}

const ScheduleStatePill: FC<Props> = ({ state, completedlabel }) => {
  if (!state) {
    return <Pill type="problem">Not requested with Zencargo</Pill>;
  }

  const pillConfig: { [key: string]: PillConfig } = {
    [CollectionAndDeliveryState.REQUESTED]: { type: 'warning', label: 'Requested with Zencargo' },
    [CollectionAndDeliveryState.CONFIRMED]: { type: 'success', label: 'Confirmed with Zencargo' },
    [CollectionAndDeliveryState.COMPLETED]: { type: 'default', label: completedlabel }
  };
  const { type, label } = pillConfig[state];

  return <Pill type={type}>{label}</Pill>;
};

export default ScheduleStatePill;
