import type { FC, ReactChild } from 'react';
import { Link } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import type { PackingListOrderLot } from '@zen/PackingLists/PackingListDetails/types';
import { productRoutes } from '@zen/Routes';
import { PurchaseOrderTab } from '@zen/Routes/products';
import { formatNumber } from '@zen/utils/formatting';

interface Props {
  lot: PackingListOrderLot;
}

const OrderLot: FC<Props> = ({ lot }) => {
  const { cbm, productName, productSkuCode, productId, quantityFulfilled } = lot;
  const { check } = useGlobalPermissions();
  const canAccessProductsRoute: boolean = check('products.canAccessRoute');

  const renderProductName = (): ReactChild => {
    if (!canAccessProductsRoute) {
      return <ValueWithDefault value={productName} />;
    }

    return (
      <Link
        className="font-bold hover:underline"
        rel="noreferrer"
        target="_blank"
        to={productRoutes.productDetails.getUrl(productId, PurchaseOrderTab.WEEKLY)}
      >
        {productName}
      </Link>
    );
  };

  return (
    <div className="grid grid-cols-6 gap-4 text-sm mb-4" data-testid="lot-line-item">
      <div className="col-span-4">
        <div className="text-base text-grey-dark mb-1">{renderProductName()}</div>
        <div className="text-grey-base leading-normal">SKU code: {productSkuCode}</div>
      </div>
      <div>{formatNumber(quantityFulfilled)}</div>
      <div>{cbm ? formatNumber(cbm) : '-'}</div>
    </div>
  );
};

export default OrderLot;
