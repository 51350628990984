import type { FC, ReactNode } from 'react';

import CollapsableElement from '@zen/Components/CollapsableElement';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  className?: string;
  subtitle?: Optional<string>;
  title: ReactNode;
  visible: boolean;
}

const ShipmentDetailsNavigationHeader: FC<Props> = ({ className = '', subtitle, title, visible }) => {
  return (
    <CollapsableElement isOpened={visible}>
      <div className={`flex mb-2 ${className}`} data-testid="navigation-header">
        {title}
        <div className="flex-1 flex-wrap grow min-w-0 truncate mt-5 text-grey-base text-sm leading-normal">{subtitle}</div>
      </div>
    </CollapsableElement>
  );
};

export default ShipmentDetailsNavigationHeader;
