import type { FC, ReactNode } from 'react';

import type { RiskLevelsEnum } from '@zen/graphql/types.generated';
import type { Money } from '@zen/types';
import { formatCurrency } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

import CargoRiskLevel from '../CargoRiskLevel';

interface Props {
  highestRiskLevel: Optional<RiskLevelsEnum>;
  totalValueOfGoods: Optional<Money[]>;
}

const CargoValue: FC<Props> = ({ highestRiskLevel, totalValueOfGoods }) => {
  if (!highestRiskLevel && !totalValueOfGoods) {
    return null;
  }

  const renderTotalValueOfGoods = ({ currency, value }: Money): ReactNode => {
    return <div key={currency}>{formatCurrency(currency, value, 'symbol', true)}</div>;
  };

  return (
    <div className="flex items-center justify-end">
      <div className="mr-1">{totalValueOfGoods && totalValueOfGoods.map(renderTotalValueOfGoods)}</div>
      {highestRiskLevel && <CargoRiskLevel riskLevel={highestRiskLevel} />}
    </div>
  );
};

export default CargoValue;
