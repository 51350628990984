import type { ReactNode } from 'react';

import { Button } from '@zen/DesignSystem';

import FormFieldArray, { FormFieldArrayHelpers } from '../FormFieldArray';

export type FormArrayHelpers<T> = {
  getFieldName: (key: string) => string;
  index: number;
  remove: () => void;
  value: T;
};

interface Props<T> {
  addButtonText?: string;
  children: (helpers: FormArrayHelpers<T>) => ReactNode;
  empty?: T;
  hideAddButton?: boolean;
  onAddItem?: () => void;
  path: string;
  values: T[];
}

const FormArray = <T extends {}>({
  onAddItem,
  path,
  values,
  children,
  empty,
  addButtonText = 'Add',
  hideAddButton = false
}: Props<T>) => {
  return (
    <FormFieldArray name={path}>
      {(arrayHelpers: FormFieldArrayHelpers): ReactNode => {
        const add = (): void => {
          onAddItem?.();
          arrayHelpers.push(empty);
        };

        return (
          <>
            {values.map((value: T, index: number) => {
              const remove = (): void => arrayHelpers.remove(index);

              const getFieldName = (key: string): string => `${path}[${index}].${key}`;

              return (
                <div key={index} data-testid={`${path}-${index}`}>
                  {children({ remove, getFieldName, index, value })}
                </div>
              );
            })}
            {!hideAddButton && (
              <Button className="mt-4" iconLeft="zicon-add" onClick={add} variant="secondary">
                {addButtonText}
              </Button>
            )}
          </>
        );
      }}
    </FormFieldArray>
  );
};

export default FormArray;
