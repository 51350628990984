import type { FC } from 'react';

import type { ScheduleDateLabels } from '@zen/CollectionAndDelivery/Details/types';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { Tooltip } from '@zen/DesignSystem';
import { isRoadShipment, ModeOfTransport } from '@zen/Shipments';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  driverDetails: Optional<string>;
  modeOfTransport: ModeOfTransport;
  reference: Optional<string>;
  scheduleDate: ScheduleDateLabels;
  scheduleLabel: Optional<string>;
  vehiclePlateNumber: Optional<string>;
}

const DetailsItemInfo: FC<Props> = ({
  scheduleDate,
  scheduleLabel,
  reference,
  driverDetails,
  vehiclePlateNumber,
  modeOfTransport
}) => {
  const { date, time, timeZone } = scheduleDate;
  const labelClassNames: string = 'text-grey-base text-xs leading-normal min-h-5';
  const detailsClassNames: string = 'leading-normal text-sm';

  return (
    <div className="grid grid-cols-4 gap-4" data-testid="schedule-info">
      <div className="flex flex-col">
        <span className={labelClassNames}>{scheduleLabel}</span>
        <Tooltip placement="top-start" tooltipContent={timeZone}>
          <div>
            <div className={detailsClassNames}>{date}</div>
            <div className={detailsClassNames}>{time}</div>
          </div>
        </Tooltip>
      </div>
      <div>
        <div className={labelClassNames}>Reference</div>
        <div className={detailsClassNames}>
          <ValueWithDefault value={reference} />
        </div>
      </div>
      {isRoadShipment(modeOfTransport) && (
        <>
          <div>
            <div className={labelClassNames}>Driver details</div>
            <div className={detailsClassNames}>
              <ValueWithDefault value={driverDetails} />
            </div>
          </div>
          <div>
            <div className={labelClassNames}>Vehicle plate number</div>
            <div className={detailsClassNames}>
              <ValueWithDefault value={vehiclePlateNumber} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DetailsItemInfo;
