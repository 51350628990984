import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTerminalsQueryVariables = GraphQLTypes.Exact<{
  query: GraphQLTypes.Scalars['String'];
  terminalTypes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.TerminalTypeValue> | GraphQLTypes.TerminalTypeValue>;
}>;

export type GetTerminalsQueryResult = { __typename?: 'Query' } & {
  terminals?: GraphQLTypes.Maybe<
    { __typename?: 'TerminalConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'label' | 'name' | 'unlocode' | 'terminalTypes'>
          >
        >
      >;
    }
  >;
};

export const GetTerminalsDocument = /* #__PURE__ */ gql`
  query getTerminals($query: String!, $terminalTypes: [TerminalTypeValue!]) {
    terminals(query: $query, terminalTypes: $terminalTypes) {
      nodes {
        label
        name
        unlocode
        terminalTypes
      }
    }
  }
`;

/**
 * __useGetTerminalsQuery__
 *
 * To run a query within a React component, call `useGetTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      terminalTypes: // value for 'terminalTypes'
 *   },
 * });
 */
export function useGetTerminalsQuery(baseOptions: Apollo.QueryHookOptions<GetTerminalsQueryResult, GetTerminalsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTerminalsQueryResult, GetTerminalsQueryVariables>(GetTerminalsDocument, options);
}
export function useGetTerminalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalsQueryResult, GetTerminalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTerminalsQueryResult, GetTerminalsQueryVariables>(GetTerminalsDocument, options);
}
export type GetTerminalsQueryHookResult = ReturnType<typeof useGetTerminalsQuery>;
export type GetTerminalsLazyQueryHookResult = ReturnType<typeof useGetTerminalsLazyQuery>;
