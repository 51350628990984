import cx from 'classnames';
import type { FC } from 'react';

export enum GridFlow {
  COL = 'col',
  ROW = 'row'
}

interface Props {
  className?: string;
  flow?: GridFlow;
}

const Row: FC<Props> = ({ children, className = '', flow = GridFlow.COL, ...props }) => {
  const classNames = cx(
    {
      'grid-flow-col grid-cols-12': flow === GridFlow.COL,
      'grid-flow-row': flow === GridFlow.ROW
    },
    'grid gap-6',
    className
  );

  return (
    <div className={classNames} data-component="row" data-testid="row" {...props}>
      {children}
    </div>
  );
};

export type { Props as RowProps };
export default Row;
