import type { ReactNode } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import type { NewCargo } from '@zen/Cargo';
import type { MenuItemType } from '@zen/DesignSystem';
import { FormSections } from '@zen/OperationsShipments/EditRoadShipment';
import { opsShipmentRoutes } from '@zen/Routes';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { CargoSchedulePermissions, CollectionAndDeliveryState } from '../types';
import { ScheduleActionType, ScheduleDetails, ScheduleItemType } from './types';

interface PermissiveMenuItemType extends MenuItemType {
  allowed: boolean;
  isVisible: boolean;
}

const getExtendedOptions = (
  cargos: NewCargo[],
  cargoSchedulePermissions: Nullable<CargoSchedulePermissions>,
  optionsType: Nullable<ScheduleItemType>,
  setActionType: (action: ScheduleActionType) => void
): MenuItemType[] => {
  if (!optionsType) {
    return [];
  }

  const schedules: Optional<ScheduleDetails[]> =
    optionsType && cargos.filter((cargo: NewCargo) => cargo[optionsType]).map((cargo: NewCargo) => cargo[optionsType]!);
  const states: Optional<CollectionAndDeliveryState>[] = schedules?.map((schedule: ScheduleDetails) => schedule?.state);

  const showConfirm: boolean = states.every((state) => state === CollectionAndDeliveryState.REQUESTED);
  const showCancel: boolean = states.every(
    (state) => state === CollectionAndDeliveryState.CONFIRMED || state === CollectionAndDeliveryState.REQUESTED
  );

  const canManageCollectionLocation: boolean = cargos.every((cargo) =>
    checkPermission<NewCargo>(cargo, 'canManageCollectionLocation')
  );

  const canManageDeliveryLocation: boolean = cargos.every((cargo) =>
    checkPermission<NewCargo>(cargo, 'canManageDeliveryLocation')
  );

  const isCollection = optionsType === 'collection';

  if (!cargoSchedulePermissions) {
    return [];
  }

  const {
    canScheduleCollection,
    canScheduleDelivery,
    canConfirmCollection,
    canConfirmDelivery,
    canCancelCollection,
    canCancelDelivery,
    canCompleteCollection,
    canCompleteDelivery,
    canViewCompleteCollection
  } = cargoSchedulePermissions;

  const canManageLocation: boolean = isCollection ? canManageCollectionLocation : canManageDeliveryLocation;
  const canSchedule: boolean = isCollection ? canScheduleCollection : canScheduleDelivery;
  const canConfirm: boolean = isCollection ? canConfirmCollection : canConfirmDelivery;
  const canCancel: boolean = isCollection ? canCancelCollection : canCancelDelivery;
  const canComplete: boolean = isCollection ? canCompleteCollection : canCompleteDelivery;
  const isCollectionCompletedAllowed: boolean = isCollection ? canViewCompleteCollection : canComplete;

  const collectionLabel: ReactNode = <div>{canComplete ? 'Mark as collected' : 'Confirm the booking first'}</div>;

  const markLabel: ReactNode = isCollection ? collectionLabel : 'Mark as delivered';
  const updateDetailsLabel: string = isCollection ? 'Update collection details' : 'Update delivery details';
  const isUpdateDetailsVisible: boolean = schedules.every((schedule) => !!schedule?.scheduleDate || !!schedule?.completedOn);
  const isCompletedVisible: boolean = states.every((state) => state !== CollectionAndDeliveryState.COMPLETED);

  const scheduleActionOptions: PermissiveMenuItemType[] = [
    {
      icon: 'zicon-pin',
      label: 'Update address',
      onClick: () => setActionType(ScheduleActionType.UPDATE_ADDRESS),
      allowed: canManageLocation,
      isVisible: true,
      addDivider: !isUpdateDetailsVisible
    },
    {
      icon: 'zicon-edit',
      label: updateDetailsLabel,
      onClick: () => setActionType(ScheduleActionType.UPDATE_DETAILS),
      allowed: canManageLocation,
      isVisible: isUpdateDetailsVisible,
      addDivider: true
    },
    {
      icon: 'zicon-calendar',
      label: `Schedule ${optionsType}`,
      onClick: () => setActionType(ScheduleActionType.SCHEDULE),
      allowed: canSchedule,
      isVisible: true
    },
    {
      icon: 'zicon-tickoval',
      label: 'Confirm schedule',
      onClick: () => setActionType(ScheduleActionType.CONFIRM),
      isVisible: showConfirm,
      allowed: canConfirm
    },
    {
      icon: 'zicon-road',
      label: markLabel,
      onClick: () => setActionType(ScheduleActionType.COMPLETE),
      isVisible: isCompletedVisible,
      disabled: !canComplete,
      allowed: isCollectionCompletedAllowed,
      addDivider: showCancel
    },
    {
      icon: 'zicon-cross-oval',
      label: 'Cancel request',
      onClick: () => setActionType(ScheduleActionType.CANCEL),
      isVisible: showCancel,
      allowed: canCancel
    }
  ];

  return onlyPermittedMenuItems(scheduleActionOptions);
};

const redirectToEditForm = (
  collectionOrDelivery: Nullable<ScheduleItemType>,
  zencargoReference: string,
  push: (pathname: string, state: object) => void
): void => {
  if (!collectionOrDelivery) {
    return;
  }

  push(opsShipmentRoutes.editRoadShipment.getUrl(zencargoReference), { autoFocusedSection: FormSections.BOOKING_DETAILS });
};

const getReducedOptions = (
  setActionType: (action: ScheduleActionType) => void,
  zencargoReference: string,
  collectionOrDelivery: Nullable<ScheduleItemType>,
  canViewOperationsDashboard: boolean,
  push: (pathname: string, state: object) => void
): MenuItemType[] => {
  const options: PermissiveMenuItemType[] = [
    {
      icon: 'zicon-pin',
      label: 'Update address',
      onClick: () => setActionType(ScheduleActionType.UPDATE_ADDRESS),
      isVisible: true,
      allowed: true,
      addDivider: true
    },
    {
      icon: 'zicon-road',
      label: `Update ${collectionOrDelivery || ''} information`,
      onClick: () => redirectToEditForm(collectionOrDelivery, zencargoReference, push),
      isVisible: canViewOperationsDashboard,
      allowed: canViewOperationsDashboard,
      addDivider: false
    }
  ];

  return onlyPermittedMenuItems(options);
};

const getAddressOptionOnly = (setActionType: (action: ScheduleActionType) => void): MenuItemType[] => {
  return [
    {
      icon: 'zicon-pin',
      label: 'Update address',
      onClick: () => setActionType(ScheduleActionType.UPDATE_ADDRESS),
      addDivider: false
    }
  ];
};

const onlyPermittedMenuItems = (options: PermissiveMenuItemType[]): MenuItemType[] => {
  const permittedItems = options.filter(({ isVisible, allowed }) => isVisible && allowed);

  return permittedItems.map(({ isVisible, allowed, ...rest }) => ({ ...rest }));
};

export { getAddressOptionOnly, getExtendedOptions, getReducedOptions };
