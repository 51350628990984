import { FC, Fragment, ReactNode } from 'react';

import type { ListItemType } from '@zen/Components/VerticalList/ListItem';
import ListItem from '@zen/Components/VerticalList/ListItem';

export interface Props {
  customListItem?: ({ item }: { item: ListItemType }) => ReactNode;
  items: ListItemType[];
  onItemClick?: (itemId: string) => void;
}
const VerticalList: FC<Props> = (props) => {
  const { items, onItemClick, customListItem } = props;

  if (items?.length === 0) return null;

  const renderListItem = (item: ListItemType, i: number): ReactNode => {
    let handleClick: () => void = () => {};

    if (onItemClick) {
      handleClick = () => onItemClick(item.id);
    }
    if (customListItem) {
      return <Fragment key={i}>{customListItem({ item })}</Fragment>;
    }

    return (
      <div key={i} className="mb-2">
        <ListItem isSelected={false} item={item} onClick={handleClick} />
      </div>
    );
  };

  return <>{items.map(renderListItem)}</>;
};

export default VerticalList;
