import cx from 'classnames';
import type { FC } from 'react';

import { QuoteOption, QuoteOptionState } from '@zen/Quotes/types';
import { formatDate } from '@zen/utils/dateTime';
import { formatCurrency } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

import CopyQuoteDetailsButton from '../CopyQuoteDetailsButton';
import QuoteOptionIcon from '../QuoteOptionIcon';
import QuoteOptionPanel from '../QuoteOptionPanel';
import ExpandRejectionReasonButton from './ExpandRejectionReasonButton';
import QuoteOptionButtons from './QuoteOptionButtons';
import QuoteOptionHeader from './QuoteOptionHeader';

export interface Props {
  isRejectionReasonExpanded: boolean;
  onApprove?: () => void;
  onReject?: () => void;
  onRejectionReasonToggle: (expanded: boolean) => void;
  quoteOption: QuoteOption;
}

const QuoteOptionItem: FC<Props> = ({ isRejectionReasonExpanded, quoteOption, onApprove, onReject, onRejectionReasonToggle }) => {
  const { description, expiryDate, quoteReference, state, total } = quoteOption;

  const expireDateFormatted: Optional<string> = expiryDate?.date && formatDate(expiryDate.date);
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  const priceFormatted: string = total && formatCurrency(total.currency, total.value);
  const isActive: boolean = state === QuoteOptionState.ACTIVE;
  const isRejected: boolean = state === QuoteOptionState.REJECTED;
  const expiredClassName: string = cx({ 'text-orange-base': state === QuoteOptionState.EXPIRED });

  return (
    <QuoteOptionPanel className="mb-2">
      <div className="flex mb-6" data-testid="quote-option-list-item">
        <div className="mr-6">
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<QuoteOptionState> | undefined' is not ... Remove this comment to see the full error message */}
          <QuoteOptionIcon quoteOptionState={state} />
        </div>
        <div className="leading-normal">
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<QuoteOptionState> | undefined' is not ... Remove this comment to see the full error message */}
          <QuoteOptionHeader quoteState={state} />
          <div className="text-sm text-grey-dark whitespace-pre-line" data-testid="description">
            {description}
          </div>
        </div>
        <div className="whitespace-nowrap text-right text-grey-base ml-auto text-sm leading-normal pl-8">
          <div className="mb-2" data-testid="price">
            Price: <b className="text-base text-grey-dark">{priceFormatted}</b>
          </div>
          <div className={expiredClassName} data-testid="expiry-date">
            Expiry date: <b>{expireDateFormatted}</b>
          </div>
          <div data-testid="reference">
            Reference: <b>{quoteReference}</b>
          </div>
        </div>
      </div>
      <div className="flex justify-between pl-6">
        <CopyQuoteDetailsButton quoteOption={quoteOption} />
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '(() => void) | undefined' is not assignable ... Remove this comment to see the full error message */}
        {isActive && <QuoteOptionButtons onApprove={onApprove} onReject={onReject} />}
        {isRejected && <ExpandRejectionReasonButton isExpanded={isRejectionReasonExpanded} onToggle={onRejectionReasonToggle} />}
      </div>
    </QuoteOptionPanel>
  );
};

export default QuoteOptionItem;
