import type { FC, ReactNode } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router';

import type { RouteTabConfig } from '../RouteTabs';

interface Props {
  tabsConfig: RouteTabConfig[];
}

const RouteTabSwitcher: FC<Props> = ({ tabsConfig }) => {
  const { url, path: currentPath } = useRouteMatch();
  const { search } = useLocation();

  const defaultTab: RouteTabConfig = tabsConfig.find((tabConfig: RouteTabConfig) => tabConfig.isDefault) || tabsConfig[0];

  const renderRoutes = () =>
    tabsConfig.map(
      ({ component: Component, path, routePath }: RouteTabConfig, i): ReactNode => (
        <Route key={`${path}${i}`} path={`${currentPath}/${routePath || path}`}>
          {Component}
        </Route>
      )
    );

  return (
    <Switch>
      {renderRoutes()}
      <Redirect to={`${url}/${defaultTab.path}${search}`} />
    </Switch>
  );
};

export default RouteTabSwitcher;
