import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetOperationsShipmentFiltersQueryVariables = GraphQLTypes.Exact<{
  accountUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetOperationsShipmentFiltersQueryResult = { __typename?: 'Query' } & {
  filters: { __typename?: 'FiltersFiltersType' } & {
    shipments?: GraphQLTypes.Maybe<{ __typename?: 'ShipmentsFilter' } & Pick<GraphQLTypes.ShipmentsFilter, 'issueTitles'>>;
  };
};

export const GetOperationsShipmentFiltersDocument = /* #__PURE__ */ gql`
  query getOperationsShipmentFilters($accountUuid: String) {
    filters(accountUuid: $accountUuid) {
      shipments {
        issueTitles
      }
    }
  }
`;

/**
 * __useGetOperationsShipmentFiltersQuery__
 *
 * To run a query within a React component, call `useGetOperationsShipmentFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationsShipmentFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationsShipmentFiltersQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *   },
 * });
 */
export function useGetOperationsShipmentFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOperationsShipmentFiltersQueryResult, GetOperationsShipmentFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetOperationsShipmentFiltersQueryResult, GetOperationsShipmentFiltersQueryVariables>(
    GetOperationsShipmentFiltersDocument,
    options
  );
}
export function useGetOperationsShipmentFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOperationsShipmentFiltersQueryResult, GetOperationsShipmentFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetOperationsShipmentFiltersQueryResult, GetOperationsShipmentFiltersQueryVariables>(
    GetOperationsShipmentFiltersDocument,
    options
  );
}
export type GetOperationsShipmentFiltersQueryHookResult = ReturnType<typeof useGetOperationsShipmentFiltersQuery>;
export type GetOperationsShipmentFiltersLazyQueryHookResult = ReturnType<typeof useGetOperationsShipmentFiltersLazyQuery>;
