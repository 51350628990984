import useAccount from '@zen/utils/hooks/useAccount';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';

import { useGetTaskListCountersQuery } from '../graphql';
import type { SidebarCounters } from '../types';

interface UseAdminSidebarCountersResult {
  adminCounters: SidebarCounters;
  adminCountersRefetch: () => void;
}

const useAdminSidebarCounters = (): UseAdminSidebarCountersResult => {
  const {
    userProfile: { isAdmin }
  } = useAccount();
  const { workflows } = useFeatureFlags();

  const shouldSkipQueryCall: boolean = !isAdmin || !workflows;

  const { data, refetch } = useGetTaskListCountersQuery({
    fetchPolicy: 'no-cache',
    skip: shouldSkipQueryCall
  });

  return {
    adminCounters: {
      taskListCount: data?.taskListCount?.activeTaskCount || 0
    },
    adminCountersRefetch: refetch
  };
};

export default useAdminSidebarCounters;
