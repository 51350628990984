import { ElementType, FC, RefObject, useRef } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import scrollparent from 'scrollparent';

import { checkPermission } from '@zen/Auth/authHelper';
import type { NewCargo, RiskLevelsEnum } from '@zen/Cargo';
import { CargoItemTypeEnum, CargoManagementTrackingAction, CargoManagementTrackingCategory } from '@zen/Cargo';
import { getLegacyCargoItemType } from '@zen/Cargo/cargoDictionaryMapping.helper';
import CargoInfo from '@zen/Cargo/components/CargoInfo';
import CargoRiskLevel from '@zen/Cargo/components/CargoRiskLevel';
import FullContainerDetails from '@zen/Cargo/FullContainerDetails';
import { DeleteCargoItemMutationVariables, useDeleteCargoItemMutation } from '@zen/Cargo/graphql';
import LooseCargoDetails from '@zen/Cargo/LooseCargoDetails';
import VehicleDetails from '@zen/Cargo/VehicleDetails';
import type { ModeOfTransport } from '@zen/Shipment';
import { formatCurrency } from '@zen/utils/formatting';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useCollectionAndDelivery } from '../CollectionAndDeliveryContext';
import DetailsItem from './DetailsItem';

type CargoItemComponentProps = {
  canManageCargo: boolean;
  cargo: NewCargo;
  modeOfTransport: ModeOfTransport;
  onDelete: (cargoId: string) => void;
  zencargoReference: string;
};

interface Props {
  accountUuid: string;
  canManageCargo: boolean;
  cargo: NewCargo;
  modeOfTransport: ModeOfTransport;
}

const Details: FC<Props> = ({ accountUuid, canManageCargo, cargo, modeOfTransport }) => {
  const { addSuccess, addError } = useNotification();
  const [deleteCargo] = useDeleteCargoItemMutation();
  const { trackEvent } = useTracking();
  const cargoItemRef: RefObject<HTMLDivElement> = useRef(null);

  const { deliveryScheduleRequired, collectionScheduleRequired, refetchCargoDetails, zencargoReference } =
    useCollectionAndDelivery();

  const canViewCollectionDetails = checkPermission<NewCargo>(cargo, 'canViewCollectionDetails');
  const canViewDeliveryDetails = checkPermission<NewCargo>(cargo, 'canViewDeliveryDetails');

  const { delivery, collection, valueOfGoods, riskLevel, cargoType } = cargo;
  const legacyCargoType: Optional<CargoItemTypeEnum> = getLegacyCargoItemType(cargoType);

  const cargoTypeComponentMapping: Record<CargoItemTypeEnum, ElementType<CargoItemComponentProps>> = {
    [CargoItemTypeEnum.CONTAINER]: FullContainerDetails,
    [CargoItemTypeEnum.LOOSE_CARGO]: LooseCargoDetails,
    [CargoItemTypeEnum.VEHICLE]: VehicleDetails
  };

  const CargoDetailsComponent: Nullable<ElementType<CargoItemComponentProps>> = legacyCargoType
    ? cargoTypeComponentMapping[legacyCargoType]
    : null;

  const handleDelete = async (cargoId: string): Promise<IOkOrErrorResult> => {
    const variables: DeleteCargoItemMutationVariables = {
      input: {
        cargoId
      }
    };

    return performMutation({
      mutationFn: () =>
        deleteCargo({
          variables,
          refetchQueries: ['cargoSummary', 'voyageMilestones', 'getRouteProgressDetails', 'getTrackingData']
        }),
      onError: () => addError(),
      onSuccess: () => {
        refetchCargoDetails();
        trackEvent({
          category: CargoManagementTrackingCategory,
          action: CargoManagementTrackingAction.DELETE_CARGO_ITEM,
          label: (cargo?.cargoType && getLegacyCargoItemType(cargo?.cargoType)) || ''
        });
        addSuccess('Cargo has been deleted.');
      }
    });
  };

  const handleCargoChange = (): void => {
    refetchCargoDetails();
    // scroll inside the scrollparent to show the updated cargo item
    const scrollParent: HTMLElement | null = cargoItemRef.current ? scrollparent(cargoItemRef.current) : null;

    // we need to calculate the offset manually, because offset is not caculated properly
    // when using `Element` from react-scroll library
    const additionalOffset: number = 40;
    const cargoItemOffsetTop: number = cargoItemRef?.current?.offsetTop || 0;
    const scrollParentOffsetTop: number = scrollParent?.offsetTop || 0;
    const offset: number = cargoItemOffsetTop - scrollParentOffsetTop - additionalOffset;

    scroll.scrollTo(offset, { container: scrollParent });
  };
  const isLegacyVehicleType: boolean = legacyCargoType === CargoItemTypeEnum.VEHICLE;

  return (
    <div ref={cargoItemRef} className="bg-grey-lightest pb-2 mb-4">
      {CargoDetailsComponent && (
        <CargoDetailsComponent
          canManageCargo={canManageCargo}
          cargo={cargo}
          modeOfTransport={modeOfTransport}
          onDelete={handleDelete}
          zencargoReference={zencargoReference}
        />
      )}
      {canViewCollectionDetails && collectionScheduleRequired && collection && (
        <DetailsItem
          accountUuid={accountUuid}
          cargo={cargo}
          detailsItemType="collection"
          modeOfTransport={modeOfTransport}
          onCargoChange={handleCargoChange}
        />
      )}
      {canViewDeliveryDetails && deliveryScheduleRequired && delivery && (
        <DetailsItem
          accountUuid={accountUuid}
          cargo={cargo}
          detailsItemType="delivery"
          modeOfTransport={modeOfTransport}
          onCargoChange={handleCargoChange}
        />
      )}
      {isLegacyVehicleType && (
        <div className="bg-white mx-2 pl-10 py-2 pr-2 rounded grid grid-cols-4 gap-4">
          <CargoInfo className="col-start-3" title="Value of cargo">
            {valueOfGoods && formatCurrency(valueOfGoods.currency, valueOfGoods.value, 'symbol', true)}
          </CargoInfo>
          <CargoInfo title="Risk level">
            {riskLevel && <CargoRiskLevel riskLevel={riskLevel as RiskLevelsEnum} showLabelInline={true} />}
          </CargoInfo>
        </div>
      )}
    </div>
  );
};

export default Details;
