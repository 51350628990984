import { FC, useState } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';

import ShipmentTrackingStatus from '../../components/ShipmentTrackingStatus';
import SkeletonMilestones from '../../RouteProgress/VoyageMilestones/Loading/SkeletonMilestones';
import { useRoadTrackingStatusQuery } from './graphql';
import { prepareTrackingData } from './helpers';
import RoadTrackingData from './RoadTrackingData';
import type { TrackingResponse } from './types';

interface Props {
  onTrackingDataChange: () => void;
  zencargoReference: string;
}

const RoadTrackingStatus: FC<Props> = ({ onTrackingDataChange, zencargoReference }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { data, loading, refetch } = useRoadTrackingStatusQuery({
    variables: {
      zencargoReference
    },
    fetchPolicy: 'no-cache'
  });

  const handleEdit = (): void => {
    setIsEditMode(true);
  };

  const handleSuccess = (): void => {
    setIsEditMode(false);

    window.setTimeout(() => {
      refetch();
      onTrackingDataChange();
    }, 1500);
  };

  const handleCancel = (): void => {
    setIsEditMode(false);
  };

  return (
    <QueryHandler
      data={data?.bookings?.nodes?.[0]?.roadTracking}
      error={false}
      isLoading={loading}
      loadingComponent={<SkeletonMilestones />}
      noResults={null}
    >
      {(trackingData: TrackingResponse) => {
        const { trackingType, trackingStatus, missingDataPoints } = prepareTrackingData(trackingData);

        return (
          <ShipmentTrackingStatus
            isEditMode={isEditMode}
            missingDataPoints={missingDataPoints}
            onEdit={handleEdit}
            trackingStatus={trackingStatus}
            trackingType={trackingType}
          >
            <RoadTrackingData onCancel={handleCancel} onSuccess={handleSuccess} zencargoReference={zencargoReference} />
          </ShipmentTrackingStatus>
        );
      }}
    </QueryHandler>
  );
};

export default RoadTrackingStatus;
