import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetNotificationsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetNotificationsQueryResult = { __typename?: 'Query' } & {
  notifications?: GraphQLTypes.Maybe<
    { __typename?: 'Notifications' } & {
      notificationsPerTarget: { __typename?: 'TargetNotificationsConnection' } & Pick<
        GraphQLTypes.TargetNotificationsConnection,
        'totalCount'
      > & {
          nodes?: GraphQLTypes.Maybe<
            Array<
              GraphQLTypes.Maybe<
                { __typename?: 'TargetNotifications' } & Pick<
                  GraphQLTypes.TargetNotifications,
                  'unreadNotificationIds' | 'targetId' | 'targetType'
                > & {
                    lastNotificationItem: { __typename: 'Activity' } & Pick<
                      GraphQLTypes.Activity,
                      'createdAt' | 'itemType' | 'id' | 'itemId'
                    > & {
                        user?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
                        >;
                      };
                  }
              >
            >
          >;
          pageInfo: { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >;
        };
    }
  >;
};

export const GetNotificationsDocument = /* #__PURE__ */ gql`
  query getNotifications($after: String, $before: String) {
    notifications {
      notificationsPerTarget(after: $after, before: $before) {
        nodes {
          unreadNotificationIds
          targetId
          targetType
          lastNotificationItem {
            ... on Activity {
              __typename
              createdAt
              itemType
              id
              itemId
              user {
                firstName
                lastName
              }
            }
          }
        }
        totalCount
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNotificationsQueryResult, GetNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetNotificationsQueryResult, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQueryResult, GetNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetNotificationsQueryResult, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
