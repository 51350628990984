import { useParams } from 'react-router';

import { PageSize } from '@zen/types';
import useUrlPagination from '@zen/utils/hooks/pagination/useUrlPagination';
import useAccount from '@zen/utils/hooks/useAccount';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';

import type { ConsolidationShipmentsQueryResult, ConsolidationShipmentsQueryVariables } from '../../graphql';
import { useConsolidationShipmentsQuery } from '../../graphql';
import type { ConsolidationShipment } from '../types';

const useConsolidationShipments = (searchText: string = '') => {
  const { consolidationId } = useParams<{ consolidationId: string }>();
  const { newCargoService: isNewCargoServiceEnabled } = useFeatureFlags();
  const {
    accountUuid,
    userProfile: { isAdmin }
  } = useAccount();

  return useUrlPagination<ConsolidationShipmentsQueryResult, ConsolidationShipmentsQueryVariables, ConsolidationShipment>(
    useConsolidationShipmentsQuery,
    'bookings',
    {
      ...(isAdmin && { customerUuid: accountUuid }),
      consolidatedShipmentId: consolidationId,
      newCargoService: !!isNewCargoServiceEnabled,
      textContains: searchText
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' },
    PageSize.TWENTY
  );
};

export default useConsolidationShipments;
