import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ShipmentActivityFeedItemsQueryVariables = GraphQLTypes.Exact<{
  actionItemsIds: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
  textMessagesIdsByTarget: Array<GraphQLTypes.TextMessagesInput> | GraphQLTypes.TextMessagesInput;
  quoteOptionsIds: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
  zencargoReferences: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
}>;

export type ShipmentActivityFeedItemsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              bookingRequest?: GraphQLTypes.Maybe<
                { __typename?: 'BookingRequest' } & Pick<
                  GraphQLTypes.BookingRequest,
                  'id' | 'rejectedReason' | 'rejectedAdditionalInfo'
                >
              >;
              quoteOptions: Array<
                GraphQLTypes.Maybe<
                  { __typename?: 'QuoteOption' } & Pick<
                    GraphQLTypes.QuoteOption,
                    | 'id'
                    | 'zencargoReference'
                    | 'quoteReference'
                    | 'accrualId'
                    | 'description'
                    | 'state'
                    | 'reasonDescription'
                    | 'rejectionReason'
                  > & {
                      total?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>>;
                      expiryDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
                    }
                >
              >;
              actionItems?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'ActionItem' } & Pick<
                    GraphQLTypes.ActionItem,
                    'id' | 'description' | 'dueDate' | 'name' | 'resolvedAt' | 'title' | 'type' | 'status'
                  >
                >
              >;
            }
        >
      >
    >;
  };
  activityFeed: { __typename?: 'ActivityFeed' } & {
    textMessages?: GraphQLTypes.Maybe<
      Array<
        { __typename?: 'TextMessage' } & Pick<GraphQLTypes.TextMessage, 'id' | 'content' | 'archivedAt'> & {
            canArchive: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
          }
      >
    >;
  };
};

export const ShipmentActivityFeedItemsDocument = /* #__PURE__ */ gql`
  query shipmentActivityFeedItems(
    $actionItemsIds: [String!]!
    $textMessagesIdsByTarget: [TextMessagesInput!]!
    $quoteOptionsIds: [String!]!
    $zencargoReferences: [String!]!
  ) {
    bookings(zencargoReferences: $zencargoReferences) {
      nodes {
        zencargoReference
        bookingRequest {
          id
          rejectedReason
          rejectedAdditionalInfo
        }
        quoteOptions(ids: $quoteOptionsIds) {
          id
          zencargoReference
          quoteReference
          accrualId
          description
          state
          total {
            value
            currency
          }
          expiryDate {
            date
          }
          reasonDescription
          rejectionReason
        }
        actionItems(ids: $actionItemsIds) {
          id
          description
          dueDate
          name
          resolvedAt
          title
          type
          status
        }
      }
    }
    activityFeed {
      textMessages(idsByTarget: $textMessagesIdsByTarget) {
        id
        content
        archivedAt
        canArchive {
          value
        }
      }
    }
  }
`;

/**
 * __useShipmentActivityFeedItemsQuery__
 *
 * To run a query within a React component, call `useShipmentActivityFeedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentActivityFeedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentActivityFeedItemsQuery({
 *   variables: {
 *      actionItemsIds: // value for 'actionItemsIds'
 *      textMessagesIdsByTarget: // value for 'textMessagesIdsByTarget'
 *      quoteOptionsIds: // value for 'quoteOptionsIds'
 *      zencargoReferences: // value for 'zencargoReferences'
 *   },
 * });
 */
export function useShipmentActivityFeedItemsQuery(
  baseOptions: Apollo.QueryHookOptions<ShipmentActivityFeedItemsQueryResult, ShipmentActivityFeedItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ShipmentActivityFeedItemsQueryResult, ShipmentActivityFeedItemsQueryVariables>(
    ShipmentActivityFeedItemsDocument,
    options
  );
}
export function useShipmentActivityFeedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentActivityFeedItemsQueryResult, ShipmentActivityFeedItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ShipmentActivityFeedItemsQueryResult, ShipmentActivityFeedItemsQueryVariables>(
    ShipmentActivityFeedItemsDocument,
    options
  );
}
export type ShipmentActivityFeedItemsQueryHookResult = ReturnType<typeof useShipmentActivityFeedItemsQuery>;
export type ShipmentActivityFeedItemsLazyQueryHookResult = ReturnType<typeof useShipmentActivityFeedItemsLazyQuery>;
