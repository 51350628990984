import type { FC, ReactNode } from 'react';

import type { PackingListOrderLot } from '@zen/PackingLists/PackingListDetails/types';

import OrderLot from './OrderLot';

interface Props {
  orderLots: PackingListOrderLot[];
}

const PackingListLineDetails: FC<Props> = ({ orderLots }) => {
  const renderLot = (lot: PackingListOrderLot): ReactNode => <OrderLot key={lot.id} lot={lot} />;

  return (
    <div className="my-4 px-4 py-6 bg-grey-lightest" data-testid="packing-list-line-details">
      <div className="grid grid-cols-6 gap-4 font-bold text-sm mb-5 leading-normal text-grey-base">
        <div className="col-span-4">Product / SKU</div>
        <div>Quantity</div>
        <div>CBM</div>
      </div>
      {orderLots.map(renderLot)}
    </div>
  );
};

export default PackingListLineDetails;
