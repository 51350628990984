import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useCollectionAndDelivery } from '@zen/CollectionAndDelivery/CollectionAndDeliveryContext';
import { ContextMenu, Dialog, MenuItemType } from '@zen/DesignSystem';
import { PackingListsTrackingAction, PackingListsTrackingCategory } from '@zen/PackingLists';
import { opsShipmentRoutes, packingListsRoutes, shipmentRoutes } from '@zen/Routes';
import useTracking from '@zen/utils/hooks/useTracking';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

interface Props {
  canManageAssignedLots: boolean;
  canManageCargo: boolean;
  cargoId: string;
  onDelete: () => void;
  zencargoReference: string;
}

const CargoActionDropdown: FC<Props> = (props) => {
  const { canManageAssignedLots, canManageCargo, cargoId, onDelete, zencargoReference } = props;
  const { isSingleCargoRoadShipment, canViewOperationsDashboard } = useCollectionAndDelivery();
  const { trackEvent } = useTracking();
  const { navigate } = useNavigationHistory();
  const location = useLocation();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const handleUpdateClick = () => {
    const editCargoUrl: string = shipmentRoutes.shipmentEditCargo.getUrl(zencargoReference, cargoId);

    if (isSingleCargoRoadShipment && canViewOperationsDashboard) {
      const editRoadShipmentUrl: string = opsShipmentRoutes.editRoadShipment.getUrl(zencargoReference);

      return navigate({ pathname: editRoadShipmentUrl, state: location.state });
    }

    return navigate({ pathname: editCargoUrl, state: location.state });
  };

  const handlePackingListClick = (): void => {
    const packingListPath: string = packingListsRoutes.packingListDetails.getUrl(zencargoReference, cargoId);

    trackEvent({
      category: PackingListsTrackingCategory,
      action: PackingListsTrackingAction.OPEN_TRACKING_LIST,
      label: `${zencargoReference}|${cargoId}`
    });

    navigate(packingListPath);
  };

  const editPackingListItem: MenuItemType = {
    className: 'fs-packingList',
    icon: 'zicon-list',
    label: 'Edit packing list',
    onClick: handlePackingListClick,
    addDivider: true
  };

  const manageCargoItems: MenuItemType[] = [
    {
      icon: 'zicon-edit',
      label: 'Update cargo',
      onClick: handleUpdateClick
    },
    { icon: 'zicon-trash', label: 'Delete cargo', onClick: () => setShowConfirmationModal(true) }
  ];

  const items: MenuItemType[] = [
    ...(canManageAssignedLots ? [editPackingListItem] : []),
    ...(canManageCargo ? manageCargoItems : [])
  ];

  const handleConfirm = (): void => {
    setShowConfirmationModal(false);
    onDelete();
  };

  return (
    <div className="flex justify-end -mb-8">
      <ContextMenu items={items} />
      <Dialog
        isOpen={showConfirmationModal}
        message="Are you sure you want to permanently delete this cargo item?"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default CargoActionDropdown;
