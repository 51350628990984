import { get } from 'lodash';
import { FC, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { checkPermission } from '@zen/Auth/authHelper';
import Page from '@zen/Components/Page';
import PageNotFound from '@zen/Components/PageNotFound';
import QueryHandler from '@zen/Components/QueryHandler';
import type { DialogProps } from '@zen/DesignSystem';
import { Button, Dialog, IconButton } from '@zen/DesignSystem';
import { useNetworksContext } from '@zen/Networks';
import {
  useInviteMutation,
  useNetworksDeleteContactMutation,
  useOrganisationContactQuery,
  useResetPasswordMutation
} from '@zen/Networks/graphql';
import InvitationLinkModal from '@zen/Networks/InvitationLinkModal';
import InvitationStatusPill from '@zen/Networks/InvitationStatusPill';
import LocationList from '@zen/Networks/Locations/LocationList';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import type {
  InvitationButtonConfig,
  InvitationModalConfig,
  OrganisationContactDetails,
  OrganisationContactDetailsInvitation
} from './types';

type ModalConfiguration = Pick<DialogProps, 'header' | 'onConfirm' | 'message' | 'cancelLabel' | 'confirmLabel'>;

const ContactDetails: FC = () => {
  const { organisationId, contactId } = useParams<{ contactId: string; organisationId: string }>();
  const { routes } = useNetworksContext();
  const location = useLocation();
  const history = useHistory();
  const { navigate } = useNavigationHistory();
  const isFromMyOrganisationPage: boolean = get(location, 'state.fromMyOrganisationPage', false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalConfiguration>();
  const [isInvitationLinkModalOpen, setIsInvitationLinkModalOpen] = useState<boolean>(false);

  const [deleteContact] = useNetworksDeleteContactMutation({
    variables: {
      input: {
        contactId
      }
    },
    onCompleted: () => {
      addSuccess('Contact has been archived');
      history.push(routes.organisation.details.getUrl(organisationId));
    },
    onError: () => addError()
  });

  const [invite] = useInviteMutation({
    variables: {
      input: {
        inviteeId: contactId
      }
    },
    onCompleted: () => {
      addSuccess('Contact has been invited');
      refetchContact();
    },
    onError: () => addError()
  });

  const [resetPassword] = useResetPasswordMutation({
    variables: {
      input: {
        userId: contactId
      }
    },
    onCompleted: () => addSuccess('Password has been reset'),
    onError: () => addError()
  });

  const { addSuccess, addError } = useNotification();
  const {
    data,
    loading,
    error,
    refetch: refetchContact
  } = useOrganisationContactQuery({
    variables: {
      organisationId,
      contactId
    },
    fetchPolicy: 'no-cache'
  });

  const onArchive = (canSendResetPassword: boolean): void => {
    setModalData(archiveModal(canSendResetPassword));
    setIsModalOpen(true);
  };

  const archiveModal = (canSendResetPassword: boolean): InvitationModalConfig => {
    return {
      confirmLabel: 'Archive',
      header: 'Are you sure you want to archive this contact?',
      message: `Deleting this contact will permanently remove it from the organisation and all its assigned locations.
       ${
         canSendResetPassword &&
         `\n IMPORTANT: This contact is a user on our platform.
       Their account will be removed and they will not be able to access our platform any longer.`
       }`,
      onConfirm: () => {
        deleteContact();
        setIsModalOpen(false);
      }
    };
  };

  const inviteModal = (email: string): InvitationModalConfig => {
    return {
      confirmLabel: 'Send invite',
      cancelLabel: 'Return',
      header: 'Are you sure  you want to invite this contact to the platform?',
      message: `An invitation will be sent to ${email},
    once the invitation is sent you will not be able to change the email.
    If you need to edit the email please return to the form.`,
      onConfirm: () => {
        invite();
        setIsModalOpen(false);
      }
    };
  };

  const resendPasswordModal = (): InvitationModalConfig => {
    return {
      confirmLabel: 'Reset password',
      cancelLabel: 'Return',
      header: 'Are you sure you want to reset password?',
      onConfirm: () => {
        resetPassword();
        setIsModalOpen(false);
      }
    };
  };

  const getButtonConfig = (
    canInvite: boolean,
    canSendResetPassword: boolean,
    invitation: OrganisationContactDetailsInvitation,
    email: string
  ): InvitationButtonConfig | undefined => {
    if (canInvite) {
      return {
        label: 'Invite user',
        onClick: () => {
          setModalData(inviteModal(email));
          setIsModalOpen(true);
        }
      };
    }

    if (invitation && invitation.acceptUrl) {
      return {
        label: 'Resend invitation',
        onClick: () => {
          setIsInvitationLinkModalOpen(true);
        }
      };
    }

    if (canSendResetPassword) {
      return {
        label: 'Reset password',
        onClick: () => {
          setModalData(resendPasswordModal());
          setIsModalOpen(true);
        }
      };
    }
  };

  return (
    <Page defaultBackUrl={routes.myOrganisation.details.getUrl()} title="Contact details">
      <QueryHandler data={data?.locationContact} error={!!error} isLoading={loading} noResults={<PageNotFound />}>
        {(contact: OrganisationContactDetails) => {
          const { firstName, lastName, phoneNumber, locations, organisation, invitation, email } = contact;
          const contactName: string = `${firstName} ${lastName}`;
          const editUrl: string = routes.contact.edit.getUrl(organisationId, contactId);
          const canInvite: boolean = contact.invitation ? checkPermission(contact.invitation, 'canSend') : false;
          const canSendResetPasswordInstructions: boolean = checkPermission(contact, 'canSendResetPasswordInstructions');
          const buttonConfig: InvitationButtonConfig | undefined = getButtonConfig(
            canInvite,
            canSendResetPasswordInstructions,
            invitation,
            email
          );

          const handleEditClick = (): void =>
            navigate({
              pathname: editUrl,
              state: {
                throughContactDetails: true,
                isFromMyOrganisationPage
              }
            });

          return (
            <>
              <div className="flex justify-between">
                <div>
                  <p className="text-xl font-bold text-navy-dark pb-4">{organisation.name}</p>
                  <div className="flex">
                    <p className="text-3xl text-navy-dark font-bold">{contactName}</p>
                    {invitation?.status && (
                      <div className="ml-4 flex items-end">
                        <InvitationStatusPill invitationStatus={invitation.status} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-start">
                  {buttonConfig && (
                    <Button data-testid="invitation-button" onClick={buttonConfig.onClick} variant="secondary">
                      {buttonConfig.label}
                    </Button>
                  )}
                  <IconButton className="mr-2" icon="zicon-edit" onClick={handleEditClick} title="Edit" variant="secondary" />
                  <IconButton
                    icon="zicon-trash"
                    onClick={() => onArchive(canSendResetPasswordInstructions)}
                    title="Archive"
                    variant="secondary"
                  />
                </div>
              </div>
              <div className="text-grey-dark text-xl">
                <div className="mt-4">{email}</div>
                <div className="mt-4">{phoneNumber}</div>
              </div>
              <div data-testid="location-list">
                <LocationList locations={locations} organisationId={organisationId} />
              </div>
              {modalData && (
                <Dialog
                  confirmLabel={modalData.confirmLabel}
                  header={modalData.header}
                  isOpen={isModalOpen}
                  message={modalData.message}
                  onClose={() => setIsModalOpen(false)}
                  onConfirm={modalData.onConfirm}
                />
              )}
              {invitation?.acceptUrl && (
                <InvitationLinkModal
                  invitationLink={invitation.acceptUrl}
                  isOpen={isInvitationLinkModalOpen}
                  onClose={() => setIsInvitationLinkModalOpen(false)}
                />
              )}
            </>
          );
        }}
      </QueryHandler>
    </Page>
  );
};

export default ContactDetails;
