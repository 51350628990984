import { camelCase } from 'lodash';
import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormCheckbox, FormInput, FormInstance } from '@zen/Components/Form';
import FormLabel from '@zen/Components/Form/FormLabel';
import QueryHandler from '@zen/Components/QueryHandler';
import { Button } from '@zen/DesignSystem';
import productSettingsRoutes from '@zen/Routes/productSettings';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { useGetDocumentTemplateFieldsQuery } from '../graphql';
import type { DocumentTemplate, DocumentTemplateField, DocumentTemplateFormValues } from '../types';
import { documentTemplateFormValidation } from './documentTemplateForm.validation';

interface Props {
  initialValues: Omit<DocumentTemplateFormValues, 'id'>;
  onSubmit: (formData: DocumentTemplateFormValues) => Promise<IOkOrErrorResult>;
  onSuccess?: () => void;
  submitButtonText?: string;
}

const DocumentTemplateForm: FC<Props> = ({ onSubmit, onSuccess, initialValues, submitButtonText }) => {
  const { navigate } = useNavigationHistory();

  const { data, error, loading } = useGetDocumentTemplateFieldsQuery();

  const renderFormButtons = ({ isSubmitting }: FormInstance<DocumentTemplate>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} text={submitButtonText}>
      <Button
        data-testid="cancel-button"
        onClick={() => navigate(productSettingsRoutes.documentTemplatesIndex.getUrl())}
        variant="tertiary"
      >
        Cancel
      </Button>
    </FormButtons>
  );

  const renderFields = (documentTemplateFields: DocumentTemplateField[]): ReactNode => {
    return (
      <>
        <div className="max-w-lg">
          <FormInput isRequired={true} label="Template name" name="name" placeholder="Add template name" />
          <FormInput isRequired={true} label="Formstack ID" name="externalId" placeholder="Add formstack ID" />
          <FormInput isRequired={true} label="Formstack key" name="externalKey" placeholder="Add formstack key" />
        </div>

        {documentTemplateFields.map(({ label, value, options }: DocumentTemplateField) => (
          <div key={label} className="mb-8">
            <FormLabel className="font-bold mb-3" label={label} />
            <div className="grid grid-cols-3 gap-4">
              {options?.map((option: DocumentTemplateField, index: number) => (
                <FormCheckbox key={index} label={option.label} name={`fields.${camelCase(value)}.${camelCase(option.value)}`} />
              ))}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <QueryHandler data={data?.documentTemplateFields} error={!!error} isLoading={loading}>
      {(documentTemplateFields: DocumentTemplateField[]) => {
        return (
          <Form
            formButtons={renderFormButtons}
            formName="DocumentTemplateForm"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            validationSchema={documentTemplateFormValidation}
          >
            {() => renderFields(documentTemplateFields)}
          </Form>
        );
      }}
    </QueryHandler>
  );
};

export default DocumentTemplateForm;
