import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type CancelDeliveryMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CancelDeliveryInput;
}>;

export type CancelDeliveryMutationResult = { __typename?: 'Mutation' } & {
  bookingCollectionAndDeliveryCancelDelivery?: GraphQLTypes.Maybe<
    { __typename?: 'CancelDeliveryPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const CancelDeliveryMutationDocument = /* #__PURE__ */ gql`
  mutation cancelDeliveryMutation($input: CancelDeliveryInput!) {
    bookingCollectionAndDeliveryCancelDelivery(input: $input) {
      errors {
        message
      }
    }
  }
`;

/**
 * __useCancelDeliveryMutation__
 *
 * To run a mutation, you first call `useCancelDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDeliveryMutation, { data, loading, error }] = useCancelDeliveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelDeliveryMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelDeliveryMutationResult, CancelDeliveryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CancelDeliveryMutationResult, CancelDeliveryMutationVariables>(
    CancelDeliveryMutationDocument,
    options
  );
}
export type CancelDeliveryMutationHookResult = ReturnType<typeof useCancelDeliveryMutation>;
