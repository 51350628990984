import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountStatusQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetAccountStatusQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'uuid' | 'name' | 'referencePrefix' | 'isActive' | 'isDemo'>
  >;
};

export const GetAccountStatusDocument = /* #__PURE__ */ gql`
  query getAccountStatus($accountId: String!) {
    account(uuid: $accountId) {
      uuid
      name
      referencePrefix
      isActive
      isDemo
    }
  }
`;

/**
 * __useGetAccountStatusQuery__
 *
 * To run a query within a React component, call `useGetAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountStatusQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountStatusQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountStatusQueryResult, GetAccountStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountStatusQueryResult, GetAccountStatusQueryVariables>(GetAccountStatusDocument, options);
}
export function useGetAccountStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountStatusQueryResult, GetAccountStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountStatusQueryResult, GetAccountStatusQueryVariables>(GetAccountStatusDocument, options);
}
export type GetAccountStatusQueryHookResult = ReturnType<typeof useGetAccountStatusQuery>;
export type GetAccountStatusLazyQueryHookResult = ReturnType<typeof useGetAccountStatusLazyQuery>;
