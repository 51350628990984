import type { Placement } from '@popperjs/core';
import cx from 'classnames';
import type { CSSProperties, FC } from 'react';

import type { Optional } from '@zen/utils/typescript';

interface Props {
  placement: Optional<Placement>;
  style: CSSProperties;
}

const TooltipArrow: FC<Props> = ({ placement, style }) => {
  const topPlacement: boolean = placement === 'top' || placement === 'top-start' || placement === 'top-end';
  const bottomPlacement: boolean = placement === 'bottom' || placement === 'bottom-start' || placement === 'bottom-end';
  const leftPlacement: boolean = placement === 'left';
  const rightPlacement: boolean = placement === 'right';

  const className: string = cx(
    {
      'border-t-white border-t-8 border-x-transparent border-x-8 border-b-0 -bottom-2': topPlacement,
      'border-b-white border-b-8 border-x-transparent border-x-8 border-t-0 -top-2': bottomPlacement,
      'border-r-white border-r-8 border-y-transparent border-y-8 border-l-0 -left-2': rightPlacement,
      'border-l-white border-l-8 border-y-transparent border-y-8 border-r-0 -right-2': leftPlacement
    },
    'border-solid pointer-events-none'
  );

  return <div className={className} data-popper-arrow={true} data-testid="tooltip-arrow" style={style} />;
};

export default TooltipArrow;
