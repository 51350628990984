import { FC, ReactNode, useState } from 'react';

import EditableSection from '@zen/Components/EditableSection';
import { useForm } from '@zen/Components/Form/useForm';
import type { ButtonVariant } from '@zen/DesignSystem';
import { Tooltip } from '@zen/DesignSystem';
import HintWrapper from '@zen/OperationsShipments/components/HintWrapper';
import type { DateWithTimeRangeAndDelayReason, RoadShipmentFields } from '@zen/OperationsShipments/EditRoadShipment/types';
import { shipmentDelayReasonValidation } from '@zen/Shipment/RouteProgress/VoyageMilestones/components/MilestoneDateForm/validation';
import { getToday } from '@zen/utils/date';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional } from '@zen/utils/typescript';

import DateWithTimeRangeForm from '../../DateWithTimeRangeForm';
import type { FormLabels } from '../../DateWithTimeRangeForm/types';
import DateWithTimeRangePresenter from '../../DateWithTimeRangePresenter';
import { createDateAndTimeValidationSchema } from '../../RoadShipmentForm.validation';

const DeliveredOn: FC = () => {
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  const { setFieldValue, values: formValues, initialValues } = useForm<RoadShipmentFields>();

  const { collection, customsOnly, delivery, deliveryLocation } = formValues;
  const { deliveredOn } = delivery;

  const allowDelayReasonEntry: boolean = !!initialValues.delivery.deliveredOn.date;
  const collectedOnDate: Optional<string> = collection.collectedOn?.date;
  const isSectionDisabled: boolean = !collectedOnDate && !customsOnly;

  const buttonText: string = deliveredOn.date ? 'Edit' : 'Add';
  const buttonVariant: ButtonVariant = deliveredOn.date ? 'secondary' : 'primary';

  const handleSubmit = (values: DateWithTimeRangeAndDelayReason): Promise<IOkOrErrorResult> => {
    setFieldValue('delivery', { ...delivery, deliveredOn: values });

    return Promise.resolve({ ok: {}, error: null });
  };

  const handleCancel = (): void => setIsUpdateMode(false);

  const handleSuccess = (): void => setIsUpdateMode(false);

  const renderForm = (): ReactNode => {
    const labels: FormLabels = {
      date: 'Delivery date',
      startTime: 'Time'
    };

    return (
      <DateWithTimeRangeForm
        disabledDayTooltip="Date cannot be in the future or before the actual collection date"
        initialValues={deliveredOn}
        labels={labels}
        maxDate={getToday()}
        minDate={collectedOnDate || undefined}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        timeZone={deliveryLocation?.timeZone}
        validationSchema={createDateAndTimeValidationSchema(false, shipmentDelayReasonValidation)}
        withDelayReason={allowDelayReasonEntry}
        withRange={false}
      />
    );
  };

  const renderValues = (): ReactNode => {
    return <DateWithTimeRangePresenter dateLabel="Delivery date" dateWithTimeRange={deliveredOn} timeLabel="Time" />;
  };

  const renderSection = (): ReactNode => {
    return (
      <div data-testid="delivered-on">
        <EditableSection
          buttonText={buttonText}
          buttonVariant={buttonVariant}
          isDisabled={isSectionDisabled}
          isUpdateMode={isUpdateMode}
          label="Delivered on date"
          onUpdate={() => setIsUpdateMode(true)}
        >
          {isUpdateMode ? renderForm() : renderValues()}
        </EditableSection>
      </div>
    );
  };

  const renderSectionWithTooltip = (): ReactNode => {
    const tooltipContent: ReactNode = (
      <div data-testid="tooltip-content">
        The <span className="font-bold">actual</span> collection date must be <br />
        added before the actual delivery date <br /> can be added
      </div>
    );

    return (
      <Tooltip offset={[0, 10]} placement="bottom-start" tooltipContent={tooltipContent}>
        {renderSection()}
      </Tooltip>
    );
  };

  return (
    <HintWrapper hintText="This is the actual date the delivery happened on">
      {isSectionDisabled ? renderSectionWithTooltip() : renderSection()}
    </HintWrapper>
  );
};

export default DeliveredOn;
