import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type PackingListLinesQueryVariables = GraphQLTypes.Exact<{
  newCargoService: GraphQLTypes.Scalars['Boolean'];
  orderId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  findBy: GraphQLTypes.PackingListFindBy;
}>;

export type PackingListLinesQueryResult = { __typename?: 'Query' } & {
  packingLists?: GraphQLTypes.Maybe<
    { __typename?: 'PackingListConnection' } & Pick<GraphQLTypes.PackingListConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'PackingList' } & {
                lines?: GraphQLTypes.Maybe<
                  Array<
                    { __typename?: 'PackingListLine' } & {
                      lot?: GraphQLTypes.Maybe<
                        { __typename?: 'Lot' } & Pick<GraphQLTypes.Lot, 'cbm' | 'id' | 'quantityFulfilled'> & {
                            cargo?: GraphQLTypes.Maybe<
                              | ({ __typename?: 'CargoContainerType' } & Pick<GraphQLTypes.CargoContainerType, 'id'>)
                              | ({ __typename?: 'CargoLooseCargoType' } & Pick<GraphQLTypes.CargoLooseCargoType, 'id'>)
                              | ({ __typename?: 'CargoVehicleType' } & Pick<GraphQLTypes.CargoVehicleType, 'id'>)
                            >;
                            cargos?: GraphQLTypes.Maybe<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'id'>>;
                            purchaseOrder?: GraphQLTypes.Maybe<
                              { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id'>
                            >;
                          }
                      >;
                      product?: GraphQLTypes.Maybe<
                        { __typename?: 'Product' } & Pick<GraphQLTypes.Product, 'id' | 'skuCode' | 'name'>
                      >;
                    }
                  >
                >;
              }
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const PackingListLinesQueryDocument = /* #__PURE__ */ gql`
  query packingListLinesQuery($newCargoService: Boolean!, $orderId: String, $findBy: PackingListFindBy!) {
    packingLists(findBy: $findBy) {
      nodes {
        lines(orderId: $orderId) {
          lot {
            cargo {
              id
            }
            cargos @include(if: $newCargoService) {
              id
            }
            cbm
            id
            purchaseOrder {
              id
            }
            quantityFulfilled
          }
          product {
            id
            skuCode
            name
          }
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __usePackingListLinesQuery__
 *
 * To run a query within a React component, call `usePackingListLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackingListLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackingListLinesQuery({
 *   variables: {
 *      newCargoService: // value for 'newCargoService'
 *      orderId: // value for 'orderId'
 *      findBy: // value for 'findBy'
 *   },
 * });
 */
export function usePackingListLinesQuery(
  baseOptions: Apollo.QueryHookOptions<PackingListLinesQueryResult, PackingListLinesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<PackingListLinesQueryResult, PackingListLinesQueryVariables>(PackingListLinesQueryDocument, options);
}
export function usePackingListLinesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PackingListLinesQueryResult, PackingListLinesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<PackingListLinesQueryResult, PackingListLinesQueryVariables>(PackingListLinesQueryDocument, options);
}
export type PackingListLinesQueryHookResult = ReturnType<typeof usePackingListLinesQuery>;
export type PackingListLinesQueryLazyQueryHookResult = ReturnType<typeof usePackingListLinesQueryLazyQuery>;
