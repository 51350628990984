import type { FC } from 'react';

import Input, { InputProps } from '../Input';

interface Props extends Omit<InputProps, 'type'> {
  max?: number | string;
  min?: number | string;
  step?: string;
}

const NumberInput: FC<Props> = (props) => {
  const { step = '0.01', placeholder = '0.00', min, max } = props;

  return (
    <Input {...props} data-component="number-input" max={max} min={min} placeholder={placeholder} step={step} type="number" />
  );
};

export type { Props as NumberInputProps };

export default NumberInput;
