import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { RadioInput } from '@zen/DesignSystem';

import type { RadioGroupOption } from './types';

interface Props {
  name: string;
  onChange: (value: string) => void;
  options: RadioGroupOption[];
  radioAlignment?: 'row' | 'column';
  value: string;
}

const RadioGroup: FC<Props> = (props) => {
  const { options, radioAlignment, value } = props;

  const isColumnAlignment: boolean = radioAlignment === 'column';
  const classNames: string = cx({ 'flex-col': isColumnAlignment }, 'flex mb-4');
  const radioInputClassName: string = cx({ 'mr-4': !isColumnAlignment });

  return (
    <div className={classNames} data-component="radio-group" data-testid="radio-group">
      {options.map(
        (option: RadioGroupOption, index: number): ReactNode => (
          <RadioInput
            {...props}
            key={index}
            checked={option.value === value}
            className={radioInputClassName}
            description={option?.description}
            value={option.value}
          >
            {option.label}
          </RadioInput>
        )
      )}
    </div>
  );
};

export default RadioGroup;
