import type {
  CargoDimensionsInputType,
  CargoDimensionsUnitEnum,
  CargoWeightInputType,
  CargoWeightUnitEnum,
  LooseCargoTypeEnum
} from '@zen/Cargo';
import type { ModeOfTransport } from '@zen/Shipments';
import type { ContainerTypeEnum, PalletTypeEnum } from '@zen/types';
import { VehicleTypeEnum } from '@zen/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { Document } from '../Components/Documents/types';
import type { IncotermType } from '../Components/Form/FormIncotermsSelect/types';
import type { CargoModeEnum, Currency, RiskLevelsEnum, TruckInput } from '../graphql/types.generated';
import { MultiStopEnum, Shipment as ShipmentType, TradeRole, TruckSwapEnum } from '../graphql/types.generated';
import type { NetworksOrgLocOption } from '../Networks/types';

export type { Booking, NetworksOrgLoc, TruckInput } from '@zen/graphql/types.generated';
export { ModeOfTransport, MultiStopEnum, TruckSwapEnum } from '@zen/graphql/types.generated';

export enum CargoParam {
  hazardous = 'hazardous',
  overweight = 'overweight',
  refrigerated = 'refrigerated',
  stackable = 'stackable',
  tailLift = 'tail-lift'
}

export enum CargoParamIconSize {
  NORMAL = 'text-base',
  SMALL = 'text-sm'
}

export enum CargoType {
  ASSORTED = 'assorted_cargo',
  BOXES = 'boxes_or_crates',
  PALLETS = 'pallets'
}

export enum SectionType {
  BOOKING_TYPE = 'booking-type',
  CARGO = 'cargo',
  CARGO_READY_DATE = 'cargo-ready-date',
  CLIENT_REFERENCE = 'client-reference',
  CONSIGNEE = 'consignee',
  CONSIGNOR = 'consignor',
  CUSTOMER = 'customer',
  DESTINATION = 'destination',
  DOCUMENT_UPLOAD = 'document-upload',
  FORWARDER = 'forwarder',
  INCOTERMS = 'incoterms',
  ORIGIN = 'origin',
  SPECIAL_INFO = 'special-info',
  TRANSPORT = 'transport'
}

export enum BookingFormSectionType {
  BOOKING_TYPE = 'booking-type',
  CARGO = 'cargo',
  CUSTOMER = 'customer-select',
  DOCUMENT_UPLOAD = 'document-upload',
  FORWARDER = 'forwarder',
  ORIGIN_AND_DESTINATION = 'origin-and-destination',
  PARTIES_AND_TERMS = 'parties-and-terms',
  PICK_UP_DETAILS = 'pick-up-details',
  SPECIAL_INFO = 'special-info',
  TRANSPORT = 'transport-mode'
}

export interface CargoDimensions {
  height: Nullable<number>;
  length: Nullable<number>;
  metric: CargoDimensionsUnitEnum;
  width: Nullable<number>;
}

export interface BaseCargoType {
  hazardous?: boolean;
  id?: string;
  label?: string;
  refrigerated?: boolean;
  type?: Nullable<ContainerTypeEnum | LooseCargoTypeEnum | VehicleTypeEnum>;
}

export type LimitedTruckLoadType = Pick<ITruckLoadType, 'id' | 'type' | 'label'>;

export interface ITruckLoadType extends BaseCargoType {
  looseCargoConfig: ILclType[];
  riskLevel?: Nullable<RiskLevelsEnum>;
  tailLift?: boolean;
  valueOfGoods?: {
    currency: Currency;
    value: Nullable<number>;
  };
}

export interface IContainerType extends BaseCargoType {
  overweight?: boolean;
  quantity?: number;
}

export interface ILessThanContainerType extends BaseCargoType {
  dimensions?: CargoDimensions;
  grossWeight?: {
    metric: CargoWeightUnitEnum;
    value: Nullable<number>;
  };
  quantity?: number;
  stackable?: boolean;
}

export interface MeasurementOptionType extends ILessThanContainerType {
  documents?: Document[];
  measurementOption?: Nullable<MeasurementType>;
}

export interface IPalletType extends MeasurementOptionType {
  value?: Nullable<PalletTypeEnum>;
}

export type CargoLoadType = ILessThanContainerType | ITruckLoadType | IContainerType;

export interface ITotalShipmentType extends ILessThanContainerType {
  volume?: Nullable<number>;
}

export type ILclType = IPalletType | MeasurementOptionType | ITotalShipmentType;

export interface ICargoConfiguration {
  cargoType: CargoModeEnum;
  fcl: IContainerType[];
  ftl: ITruckLoadType;
  lcl: ILclType[];
}

export enum FTLVehicleType {
  BOXED_TRAILER = '13.6m - box',
  CURTAIN_SIDED = '13.6m - curtain sided',
  EXPRESS_VAN = 'van - curtain sided'
}

export type RestrictedVehicleTypeEnum =
  | VehicleTypeEnum.TRAILER_13_6_M_BOX
  | VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED
  | VehicleTypeEnum.VAN_CURTAIN_SIDED;

export const FTLVehicleTypeEnumMap: Record<RestrictedVehicleTypeEnum, FTLVehicleType> = {
  [VehicleTypeEnum.TRAILER_13_6_M_BOX]: FTLVehicleType.BOXED_TRAILER,
  [VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED]: FTLVehicleType.CURTAIN_SIDED,
  [VehicleTypeEnum.VAN_CURTAIN_SIDED]: FTLVehicleType.EXPRESS_VAN
};

export interface IRequestContainerType {
  containerType: ContainerTypeEnum;
  hazardous: boolean;
  overweight: boolean;
  quantity: number;
  reefer: boolean;
}

export interface IRequestLooseType {
  dimensions?: CargoDimensions;
  dimensionsWithMetric: Optional<CargoDimensionsInputType>;
  grossWeight?: Nullable<CargoWeightInputType>;
  hazardous: boolean;
  palletType?: Nullable<PalletTypeEnum>;
  quantity?: number;
  reefer: boolean;
  stackable: boolean;
  volume?: number;
}

export interface IRequestCargo {
  assorted: IRequestLooseType[];
  boxes: IRequestLooseType[];
  fcl: IRequestContainerType[];
  ftl: Nullable<TruckInput>;
  mode: CargoModeEnum;
  pallets: IRequestLooseType[];
}

interface BookingRequestBase {
  accountUuid: Nullable<string> | undefined;
  cargo: IRequestCargo;
  cargoReadyDate: Optional<string>;
  clientReference: string;
  documents: Document[];
  incoterms: IncotermType;
  modeOfTransport: ModeOfTransport;
  networksDestinationId: Optional<string>;
  networksOriginId: Optional<string>;
  specialInstructions: string;
  tradeLaneId: Optional<string>;
}

export type RequestBookingRequest = BookingRequestBase;

export interface INewBookingRequest extends BookingRequestBase {
  cargoDescription?: string;
  consigneeId: Optional<string>;
  consignorId: Optional<string>;
  customsOnly?: boolean;
  forwarderId?: Nullable<string>;
  forwarderReference?: string;
  multiStop?: Nullable<MultiStopEnum>;
  shipmentType?: ShipmentType;
  tradeRole?: TradeRole;
  truckSwap?: Nullable<TruckSwapEnum>;
}

export interface RequestBookingFormValues {
  cargoConfiguration: ICargoConfiguration;
  cargoReadyDate: Nullable<string>;
  clientReference: string;
  customerId: Nullable<string>;
  documents: Document[];
  incoterms: IncotermType;
  modeOfTransport: ModeOfTransport;
  networksDestination: Nullable<NetworksOrgLocOption>;
  networksOrigin: Nullable<NetworksOrgLocOption>;
  specialInstructions: string;
}

export interface INewBooking extends RequestBookingFormValues {
  accountUuid: string;
  approvalType?: Nullable<BookingApprovalType>;
  bookingType: BookingType | string;
  cargoDescription?: string;
  consignee: Nullable<NetworksOrgLocOption>;
  consignor: Nullable<NetworksOrgLocOption>;
  customsOnly?: boolean;
  forwarder?: Nullable<NetworksOrgLocOption>;
  forwarderReference?: string;
  multiStop: Nullable<MultiStopEnum>;
  shipmentType?: ShipmentType;
  tradeRole?: TradeRole;
  truckSwap: Nullable<TruckSwapEnum>;
}
export interface BookingData {
  booking: {
    bookingRequest?: {
      forwarderApproved: boolean;
    };
    cargo: {
      mode: CargoModeEnum;
    };
    modeOfTransport: ModeOfTransport;
    quoteRequired: boolean;
    zencargoReference: string;
  };
  errors: string[];
}

export enum BookingType {
  EASY = 'easyBooking',
  EXTERNAL = 'externalBooking',
  REQUIRES_APPROVAL = 'requiresApproval'
}

export enum BookingApprovalType {
  GENERAL_DATA = 'generalData',
  PRICING = 'pricing'
}

export enum MeasurementType {
  MANUAL_INPUT = 'MANUAL',
  PACKING_LIST = 'PACKING_LIST',
  SKIP_FOR_NOW = 'SKIP'
}

export interface BookingTypeSelectCapabilities {
  canCreateExternalForwarderBooking?: boolean;
  canMakeQuotedBooking: boolean;
  canRequestBooking: boolean;
}

export { ShipmentType, TradeRole };
