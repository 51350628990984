import { keyBy, mapValues } from 'lodash';

import type { TabConfig } from '@zen/DesignSystem';
import type { Undefinable } from '@zen/utils/typescript';

import type { TabViewConfig } from './types';

export const prepareComponentMapping = (tabViewConfig: TabViewConfig[]) => {
  const tabConfigByLabel = keyBy(tabViewConfig, (config: TabViewConfig) => config.label);

  return mapValues(tabConfigByLabel, (value: TabViewConfig) => value.component);
};

export const prepareTabsConfig = (tabViewConfig: TabViewConfig[], activeTab?: string): TabConfig[] => {
  return tabViewConfig.map(
    ({ label, totalCount }: TabViewConfig): TabConfig => ({
      isActive: label === activeTab,
      value: label,
      totalCount,
      label
    })
  );
};

export const getDefaultTab = (tabViewConfig: TabViewConfig[]): string => {
  const defaultTab: Undefinable<string> = tabViewConfig.find((tabView: TabViewConfig) => tabView.isDefault)?.label;
  const firstTab: string = tabViewConfig[0].label;

  return defaultTab || firstTab;
};
