import LabelledDate, { LabelledDateType } from '../LabelledDate';

export enum ScheduleType {
  COLLECTION,
  DELIVERY
}

interface SchedulerHeaderProps {
  detentionDate?: string;
  detentionFreeTime?: number;
  freeTime?: string;
  hideTitle?: boolean;
  schedule: ScheduleType;
  scheduleDate?: string;
}

const SchedulerHeader = ({
  schedule,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
  scheduleDate = null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
  freeTime = null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
  detentionFreeTime = null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
  detentionDate = null,
  hideTitle = false
}: SchedulerHeaderProps) => {
  const headerValues = {
    [ScheduleType.COLLECTION]: {
      title: 'Schedule collection',
      scheduleDateLabel: 'Vessel ETD:',
      freeTimeLabel: 'Free time begins'
    },
    [ScheduleType.DELIVERY]: {
      title: 'Schedule delivery',
      scheduleDateLabel: 'Estimated arrival',
      freeTimeLabel: 'Free time ends'
    }
  };

  const { title, scheduleDateLabel, freeTimeLabel } = headerValues[schedule];

  return (
    <div className="flex items-center mb-6">
      {!hideTitle && <div className="text-grey-dark mr-4 leading-normal font-bold">{title}</div>}
      <div className="flex">
        {scheduleDate && <LabelledDate date={scheduleDate} label={scheduleDateLabel} type={LabelledDateType.info} />}
        {freeTime && <LabelledDate date={freeTime} label={freeTimeLabel} type={LabelledDateType.warning} />}
        {detentionFreeTime && <LabelledDate date={detentionDate} label="Detention begins" type={LabelledDateType.warning} />}
      </div>
    </div>
  );
};

export default SchedulerHeader;
