import { pick } from 'lodash';
import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performFormMutation } from '@zen/utils/performMutation';

import IssueForm, { IssueSubmitData } from '../components/IssueForm';
import { IssuesUpdateIssueMutationResult, useIssuesUpdateIssueMutation } from '../graphql';
import type { Issue, IssueFlatData, UpdateIssueInput } from '../types';

interface Props {
  issue: Issue;
  onCancel: () => void;
  onSuccess: () => void;
}

const EditIssue: FC<Props> = ({ onCancel, onSuccess, issue }) => {
  const { addError, addSuccess } = useNotification();
  const [updateIssue] = useIssuesUpdateIssueMutation();
  const canEditCategory = checkPermission(issue, 'canUpdateCategory');
  const canEditProblem = checkPermission(issue, 'canUpdateProblemDescription');
  const canEditVisibility = checkPermission(issue, 'canModifyVisibility');

  const handleSubmit = async (values: IssueSubmitData, changedValues?: Partial<IssueSubmitData>) => {
    const editedValues: UpdateIssueInput = {
      id: values.id,
      ...pick(changedValues, ['category', 'occurredOn', 'problemDescription', 'severity', 'solutionDescription', 'visibility'])
    };

    return performFormMutation<IssuesUpdateIssueMutationResult>({
      mutationFn: () =>
        updateIssue({
          variables: {
            input: editedValues
          }
        }),
      onSuccess: () => {
        addSuccess('Issue updated successfully');
        onSuccess();
      },
      onError: () => addError('There was an error updating this issue')
    });
  };
  const initialValues: IssueFlatData = {
    ...issue,
    occurredOn: issue.occurredOn.date || ''
  };

  return (
    <IssueForm
      allowChooseState={false}
      allowEditCategory={canEditCategory}
      allowEditProblemDescription={canEditProblem}
      allowEditVisibility={canEditVisibility}
      associatedType={issue.associatedType}
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default EditIssue;
