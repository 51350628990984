import type { FC } from 'react';

import TabViewController from '@zen/Components/TabViewController';
import type { TabViewConfig } from '@zen/Components/TabViewController/types';

import type { ModalProps } from '../Modal';
import Modal from '../Modal';

type Props = Omit<ModalProps, 'children' | 'control'> & { tabViewConfig: TabViewConfig[] };

const ModalWithTabs: FC<Props> = ({ tabViewConfig, ...modalProps }) => {
  return (
    <TabViewController tabViewConfig={tabViewConfig}>
      {({ Tabs, Content }) => (
        <Modal control={<Tabs />} {...modalProps}>
          <Content />
        </Modal>
      )}
    </TabViewController>
  );
};

export default ModalWithTabs;
