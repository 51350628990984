import { FC, ReactNode, useContext } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { Button } from '@zen/DesignSystem';

import BasketContext from '../OrderBasket/BasketContext';
import PageContent from '../PageContent';

interface Props {
  className?: string;
  headline: ReactNode;
  isSubmitDisabled: boolean;
  onCancel: () => void;
  onDeselectAll?: () => void;
  onSelectAll?: () => void;
  onSubmit: () => void;
  openCustomerModal?: () => void;
  submitText: string;
}

const BasketHeader: FC<Props> = (props) => {
  const { customer } = useContext(BasketContext);
  const { check } = useGlobalPermissions();
  const canViewOrdersCustomers = check('purchaseOrders.canViewCustomers');

  const {
    className = 'items-center py-10',
    submitText,
    headline,
    isSubmitDisabled,
    openCustomerModal,
    onSelectAll,
    onDeselectAll,
    onCancel,
    onSubmit
  } = props;
  const displayLinks = onSelectAll || onDeselectAll;

  return (
    <div className="bg-azure-lightest" data-testid="basket-header">
      <PageContent className={`flex text-navy-base font-bold justify-between ${className}`}>
        <div>
          <div className="text-2xl">{headline}</div>
          {canViewOrdersCustomers && customer && (
            <div className="flex items-baseline mt-2" data-testid="customer-basket-details">
              <p className="text-2xl mr-2">{customer.name}</p>
              <p className="underline" data-testid="header-open-modal-link" onClick={openCustomerModal}>
                Change customer
              </p>
            </div>
          )}
          {displayLinks && (
            <div className="pt-6 underline text-sm cursor-pointer">
              {onSelectAll && (
                <span className="mr-10" onClick={onSelectAll}>
                  Select all
                </span>
              )}
              {onDeselectAll && <span onClick={onDeselectAll}>De-select all</span>}
            </div>
          )}
        </div>
        <div className="flex">
          <Button className="mr-4" data-testid="cancel-button" onClick={onCancel} variant="tertiary">
            Cancel
          </Button>
          <Button data-testid="submit-button" disabled={isSubmitDisabled} onClick={onSubmit}>
            {submitText}
          </Button>
        </div>
      </PageContent>
    </div>
  );
};

export { BasketHeader };
export type { Props as BasketHeaderProps };
export default BasketHeader;
