import type { FC, ReactNode } from 'react';
import type { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import useRoutePermissions from '@zen/Auth/RoutePermissions/useRoutePermissions';

interface Props extends Omit<RouteProps, 'component' | 'render'> {
  children: ReactNode;
  path: string;
  redirectPath?: string;
}

const AuthorisedRoute: FC<Props> = (props) => {
  const { redirectPath, children, ...remainingProps } = props;
  const { path } = remainingProps;

  const { canAccessRoute } = useRoutePermissions();

  const renderRoute = (): ReactNode => {
    if (canAccessRoute(path)) {
      return children;
    }

    if (redirectPath) {
      return <Redirect to={redirectPath} />;
    }

    return null;
  };

  return <Route {...remainingProps} render={renderRoute} />;
};

export default AuthorisedRoute;
