import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworksCreateLocationContactMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateLocationContactInput;
}>;

export type NetworksCreateLocationContactMutationResult = { __typename?: 'Mutation' } & {
  networksCreateLocationContact?: GraphQLTypes.Maybe<
    { __typename?: 'CreateLocationContactPayload' } & {
      contact?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksContact' } & Pick<
          GraphQLTypes.NetworksContact,
          'email' | 'firstName' | 'id' | 'lastName' | 'phoneNumber'
        >
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const NetworksCreateLocationContactDocument = /* #__PURE__ */ gql`
  mutation networksCreateLocationContact($input: CreateLocationContactInput!) {
    networksCreateLocationContact(input: $input) {
      contact {
        email
        firstName
        id
        lastName
        phoneNumber
      }
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useNetworksCreateLocationContactMutation__
 *
 * To run a mutation, you first call `useNetworksCreateLocationContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworksCreateLocationContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networksCreateLocationContactMutation, { data, loading, error }] = useNetworksCreateLocationContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNetworksCreateLocationContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NetworksCreateLocationContactMutationResult,
    NetworksCreateLocationContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<NetworksCreateLocationContactMutationResult, NetworksCreateLocationContactMutationVariables>(
    NetworksCreateLocationContactDocument,
    options
  );
}
export type NetworksCreateLocationContactMutationHookResult = ReturnType<typeof useNetworksCreateLocationContactMutation>;
