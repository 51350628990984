import { Table, TableColumn, useTableConfiguration } from '@zen/DesignSystem';
import type { SortInput } from '@zen/types';

import type { FlatShipment } from '../types';

interface TableViewProps {
  columns: TableColumn<FlatShipment>[];
  onOrderChange: (order: SortInput) => void;
  order: SortInput;
  shipments: FlatShipment[];
  totalCount: number;
}

const TableView = ({ onOrderChange, order, shipments, totalCount, columns }: TableViewProps) => {
  const { hiddenColumns, tableId } = useTableConfiguration();

  return (
    <Table<FlatShipment>
      columns={columns}
      data={shipments}
      hiddenColumns={hiddenColumns}
      onOrderChange={onOrderChange}
      order={order}
      tableId={tableId}
      totalCountConfig={{
        totalCount,
        entityName: 'shipment'
      }}
    />
  );
};

export default TableView;
