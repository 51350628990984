import * as Yup from 'yup';

import type { Nullable } from '@zen/utils/typescript';

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  cargoReadyDateBetween: Yup.object().when('cargoReadyDateIn', {
    is: (value: Nullable<number>) => value === null,
    then: Yup.object().shape({
      startDate: Yup.string().nullable().required('Start date is required.'),
      endDate: Yup.string().nullable().required('End date is required.')
    })
  })
});
