import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ShipmentDelayReasonOptionsQueryVariables = GraphQLTypes.Exact<{
  search?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type ShipmentDelayReasonOptionsQueryResult = { __typename?: 'Query' } & {
  shipmentDelayReasons: { __typename?: 'ShipmentDelayReasonConnection' } & Pick<
    GraphQLTypes.ShipmentDelayReasonConnection,
    'totalCount'
  > & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'ShipmentDelayReason' } & Pick<GraphQLTypes.ShipmentDelayReason, 'reason' | 'description'>
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const ShipmentDelayReasonOptionsDocument = /* #__PURE__ */ gql`
  query shipmentDelayReasonOptions($search: String, $order: SortInput, $before: String, $after: String, $last: Int, $first: Int) {
    shipmentDelayReasons(textContains: $search, order: $order, before: $before, after: $after, last: $last, first: $first) {
      nodes {
        reason
        description
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useShipmentDelayReasonOptionsQuery__
 *
 * To run a query within a React component, call `useShipmentDelayReasonOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentDelayReasonOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentDelayReasonOptionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      order: // value for 'order'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useShipmentDelayReasonOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ShipmentDelayReasonOptionsQueryResult, ShipmentDelayReasonOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ShipmentDelayReasonOptionsQueryResult, ShipmentDelayReasonOptionsQueryVariables>(
    ShipmentDelayReasonOptionsDocument,
    options
  );
}
export function useShipmentDelayReasonOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentDelayReasonOptionsQueryResult, ShipmentDelayReasonOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ShipmentDelayReasonOptionsQueryResult, ShipmentDelayReasonOptionsQueryVariables>(
    ShipmentDelayReasonOptionsDocument,
    options
  );
}
export type ShipmentDelayReasonOptionsQueryHookResult = ReturnType<typeof useShipmentDelayReasonOptionsQuery>;
export type ShipmentDelayReasonOptionsLazyQueryHookResult = ReturnType<typeof useShipmentDelayReasonOptionsLazyQuery>;
