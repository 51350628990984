import { groupBy } from 'lodash';
import pluralize from 'pluralize';
import { ReactNode, useState } from 'react';

import type { OrderBasketLot } from '@zen/Orders/types';
import { formatNumber } from '@zen/utils/formatting';

import ChevronIcon from '../Icons/ChevronIcon';
import OrderBasketItem from '../OrderBasketItem/OrderBasketItem';

interface OrderBasketListProps {
  items: OrderBasketLot[];
  onDelete?: (lotId: string) => void;
  onEdit?: (lotId: string) => void;
  removeLastItem?: boolean;
}

const OrderBasketList = ({ items, onEdit, onDelete, removeLastItem = true }: OrderBasketListProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const onDeleteHandler = !removeLastItem && items.length === 1 ? undefined : onDelete;

  const groupedItems = groupBy(items, 'orderReferenceNumber');

  const renderSummary = (): ReactNode => {
    const totalLots: number = items.length;
    const totalCbm: number = items.reduce((prev, curr): number => {
      const cbm: number = curr.cbm ? curr.cbm : 0;

      return prev + cbm;
    }, 0);
    const summary: string = `${totalLots} ${pluralize('lot', totalLots)} selected`;
    const cbmInfo: string = `total of ${formatNumber(totalCbm)} CBM`;
    const missingCbm: ReactNode = <span className="text-sm italic">(some CBMs missing)</span>;

    const hasCBMs: boolean = items.every((item) => !!item.cbm);
    const hasNoCBMs: boolean = items.every((item) => !item.cbm);

    if (hasCBMs) {
      return (
        <>
          {summary}, {cbmInfo}
        </>
      );
    }

    if (hasNoCBMs) {
      return summary;
    }

    return (
      <>
        {summary}, {cbmInfo}* {missingCbm}
      </>
    );
  };

  const renderBasketItems: () => ReactNode = () =>
    expanded && (
      <>
        <div className="w-4/5 px-4 max-h-[30vh] z-10 overflow-y-scroll">
          {Object.values(groupedItems).map((item: OrderBasketLot[]) => (
            <OrderBasketItem key={item[0].id} className="mb-4" items={item} onDelete={onDeleteHandler} onEdit={onEdit} />
          ))}
        </div>
        <div className="flex flex-row h-px bg-navy-dark w-full mb-4" />
      </>
    );

  return (
    <div className="flex flex-col bg-azure-lightest py-8 items-center" data-testid="order-basket-list" id="order-basket-list">
      <div className="text-navy-base text-center text-xl pb-4 font-bold" data-testid="summary">
        {renderSummary()}
      </div>
      {renderBasketItems()}
      <div className="cursor-pointer hover:font-bold" onClick={() => setExpanded(!expanded)}>
        <ChevronIcon className="text-base" expanded={expanded} />
        <span className="text-navy-base underline pl-2">{expanded ? 'Hide' : 'Show'} selection</span>
      </div>
    </div>
  );
};

export default OrderBasketList;
