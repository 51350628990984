import { pull } from 'lodash';
import type { FC } from 'react';

import DataExportButton from '@zen/Components/DataExportButton';
import type { TableColumn } from '@zen/DesignSystem';
import type {
  PurchaseOrdersColumnEnum,
  PurchaseOrdersDataFiltersInput,
  PurchaseOrdersExportDataInput,
  SortInput
} from '@zen/graphql/types.generated';
import { useExportRowsOrdersMutation } from '@zen/Orders/graphql';
import type { OrderFilters, OrderTableView } from '@zen/Orders/types';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { getColumns } from '../TableView/ordersTableConfiguration';
import type { OrderListCapabilities } from '../TableView/types';

interface Props {
  capabilities: OrderListCapabilities;
  filters: OrderFilters;
  hiddenColumns: string[];
  sortInput: SortInput;
}

const OrderListExportData: FC<Props> = ({ capabilities, filters, sortInput, hiddenColumns }) => {
  const [exportRows] = useExportRowsOrdersMutation();
  const { accountUuid } = useAccount();
  const { addError, addSuccess } = useNotification();
  const accountId: Nullable<string> = accountUuid !== '' ? accountUuid : null;
  const tableColumns: TableColumn<OrderTableView>[] = getColumns({ capabilities });

  const { cargoReadyDateIn, customer, issueTitle, ...rest } = filters;

  const titleEq = issueTitle || null;

  const dataFilters: PurchaseOrdersDataFiltersInput = {
    filterBy: null,
    issues: issueTitle !== undefined ? { titleEq } : undefined,
    ...rest
  };

  if (customer) {
    dataFilters.customerUuid = customer.uuid;
  }

  // NEEDS BIGGER REFACTOR MAYBE WHERE WE USE ENUM FOR EVERY TABLE CONFIGURATION COLUMN KEYS
  const columnKeys: string[] = [...tableColumns].map(({ key }) => key);
  // SKIP DELIVERY DELAY AS IT CAN BE CALCULATED FROM OTHER VALUES
  const columnKeysToExport = pull(columnKeys, 'deliveryDelay') as PurchaseOrdersColumnEnum[];

  const columns: PurchaseOrdersColumnEnum[] = columnKeysToExport.filter(
    (column: PurchaseOrdersColumnEnum) => !hiddenColumns.includes(column)
  );

  const handleExport = async (): Promise<IOkOrErrorResult> => {
    const exportData: PurchaseOrdersExportDataInput = {
      columns,
      dataFilters,
      order: sortInput
    };

    return performMutation({
      mutationFn: () =>
        exportRows({
          variables: {
            input: {
              accountUuid: accountId,
              exportData
            }
          }
        }),
      onError: addError,
      onSuccess: () => addSuccess('Table exported successfully.')
    });
  };

  return <DataExportButton className="fs-orders-export-csv ml-3" confirmLabel="Return" onClickPromise={handleExport} />;
};

export default OrderListExportData;
