import accounting from 'accounting';
import { pick } from 'lodash';

import type { UpdateAccrualMutationInput } from './graphql';
import { Accrual, AccrualState, Cost, CostItem, Margin, Revenue } from './types';

const replaceChargeType = (item: Cost | Margin | Revenue) => {
  const { chargeType, ...rest } = item;

  return {
    ...rest,
    chargeTypeId: chargeType.id
  };
};

export const isAccrualPublished = (state: AccrualState): boolean => {
  return state === AccrualState.PUBLISHED;
};

export const prepareSaveAccrualPayload = (accrualData: Accrual): UpdateAccrualMutationInput => {
  const lineItems = accrualData.costItems.map((accrualLineItem: CostItem) => {
    return {
      id: accrualLineItem.id,
      chargeDetails: accrualLineItem.chargeDetails,
      cost: replaceChargeType(accrualLineItem.cost),
      margin: replaceChargeType(accrualLineItem.margin),
      revenue: replaceChargeType(accrualLineItem.revenue)
    };
  });

  const fieldsList = [
    'totalCost',
    'totalRevenue',
    'totalMargin',
    'totalMarginPercentage',
    'invoiceCurrency',
    'invoiceExchangeRate',
    'invoiceRevenue'
  ];

  const accrualParamsFields = pick(accrualData, fieldsList);

  return {
    id: accrualData.id,
    accrualParams: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; chargeDetails: Maybe<string> |... Remove this comment to see the full error message
      costItems: lineItems,
      ...accrualParamsFields
    }
  };
};

export const toRoundedFloat = (value: number): number => {
  return parseFloat(accounting.toFixed(value, 2));
};
