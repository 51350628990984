import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { Button } from '@zen/DesignSystem';
import { consolidationRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

const NewConsolidationButton: FC = () => {
  const { navigate } = useNavigationHistory();
  const { check } = useGlobalPermissions();
  const canShowNewConsolidationButton: boolean = check('consolidation.canAddConsolidatedShipment');

  if (!canShowNewConsolidationButton) {
    return null;
  }

  return (
    <Button iconLeft="zicon-add" onClick={() => navigate(consolidationRoutes.consolidationCreate.getUrl())}>
      New consolidated shipment
    </Button>
  );
};

export default NewConsolidationButton;
