import type { CurrentUserResponse, FeatureFlags, UserProfile } from './types';
import { featureFlags as featureFlagsDefault } from './UserContext';

export const prepareUserProfile = ({ currentUser, authorization, account }: CurrentUserResponse): UserProfile => {
  return {
    firstName: currentUser?.profile?.firstName || '',
    lastName: currentUser?.profile?.lastName || '',
    email: currentUser?.profile?.email || '',
    phoneNumber: currentUser?.profile?.phoneNumber || '',
    ...authorization,
    division: account?.division,
    isDivision: account?.isDivision || false,
    isParent: account?.isParent || false,
    uuid: currentUser?.uuid || '',
    preferences: currentUser?.preferences || {},
    role: null,
    registeredCompanyName: account?.name || ''
  };
};

export const prepareFeatureFlags = ({ currentUser }: CurrentUserResponse): FeatureFlags => {
  if (!currentUser?.featureFlags) {
    return featureFlagsDefault;
  }

  return currentUser?.featureFlags.reduce((flags, flag) => ({ ...flags, [flag.name]: flag.enabled }), <FeatureFlags>{});
};

export const saveUserData = ({
  division,
  isAdmin,
  isParent,
  registeredCompanyName,
  uuid,
  preferences: { roles, level }
}: UserProfile) => {
  window.localStorage.setItem('accountName', registeredCompanyName);
  window.localStorage.setItem('isAdmin', `${isAdmin}`);
  window.localStorage.setItem('isDivision', `${!!division}`);
  window.localStorage.setItem('isParent', `${!!isParent}`);
  window.localStorage.setItem('userId', uuid);
  window.localStorage.setItem('profileRoles', JSON.stringify(roles));
  window.localStorage.setItem('profileLevel', level || '');
};
