import { useEffect, useState } from 'react';

const defaultBreakPoints: IBreakPoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1300
};

interface IBreakPoints {
  lg: number;
  md: number;
  sm: number;
  xl: number;
}

export enum Sizes {
  SM,
  MD,
  LG,
  XL
}

const useBreakPoints = (customBreakPoints: IBreakPoints = defaultBreakPoints) => {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [currentBreakPoint, setCurrentBreakPoint] = useState<Sizes>(null);
  const { md, lg, xl } = customBreakPoints;

  const onResize = () => {
    const width = window.innerWidth;
    let nextBreakPoint;

    if (width < md) {
      nextBreakPoint = Sizes.SM;
    } else if (width > md && width < lg) {
      nextBreakPoint = Sizes.MD;
    } else if (width > lg && width < xl) {
      nextBreakPoint = Sizes.LG;
    } else {
      nextBreakPoint = Sizes.XL;
    }

    if (nextBreakPoint !== currentBreakPoint) {
      setCurrentBreakPoint(nextBreakPoint);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  });

  return {
    currentBreakPoint
  };
};

export default useBreakPoints;
