import type { ReactNode } from 'react';

import type { TableColumn } from '@zen/DesignSystem';
import NotesTableCell from '@zen/OperationsShipments/components/NotesTableCell';
import type { FlatShipment, ShipmentsListCapabilities } from '@zen/Shipments';
import { getColumns as getShipmentsColumns } from '@zen/Shipments/TableView/tableConfiguration';

export const getColumns = (capabilities: ShipmentsListCapabilities, pathname: string): TableColumn<FlatShipment>[] => [
  ...getShipmentsColumns({
    capabilities,
    pathname
  }),
  {
    key: 'note',
    title: 'Notes',
    sortable: false,
    render: (_, { note, zencargoReference }: FlatShipment): ReactNode => {
      if (note?.body && zencargoReference) {
        return <NotesTableCell note={note.body} zencargoReference={zencargoReference} />;
      }
    }
  }
];
