import type { ReactNode } from 'react';

import CargoSummary from '@zen/Components/CargoSummary';
import OrdersReferencesList from '@zen/Components/OrdersReferencesList';
import ShipmentReferences from '@zen/Components/ShipmentReferences';
import type { TableColumn } from '@zen/DesignSystem';
import { consolidationRoutes } from '@zen/Routes';
import { ShipmentColumnEnum } from '@zen/Shipments';
import { formatDate } from '@zen/utils/dateTime/date/formatting';
import { getNetworkContactLabel } from '@zen/utils/networks';

import { getConsolidationCargoLabel } from '../consolidation.helper';
import ActionHandleContainer from './ActionHandleContainer/ActionHandleContainer';
import type { ConsolidationShipment } from './types';

export const getConsolidationShipmentsColumnsConfiguration = (
  canRemoveShipmentFromConsolidation: boolean,
  consolidatedShipmentId: string
): TableColumn<ConsolidationShipment>[] => [
  {
    key: ShipmentColumnEnum.ZENCARGOREFERENCE,
    title: 'Reference',
    render: (_, { clientReference, zencargoReference }: ConsolidationShipment): ReactNode => {
      return zencargoReference && <ShipmentReferences clientReference={clientReference} zencargoReference={zencargoReference} />;
    },
    sortKey: 'zencargoReference',
    fixed: 'left'
  },
  {
    key: ShipmentColumnEnum.ORIGIN,
    title: 'Origin',
    render: (_, { networksOrigin }: ConsolidationShipment): ReactNode => {
      return getNetworkContactLabel(networksOrigin, 'long');
    },
    sortable: false,
    ellipsis: true,
    width: '200px'
  },
  {
    key: ShipmentColumnEnum.DESTINATION,
    title: 'Destination',
    render: (_, { networksDestination }: ConsolidationShipment): ReactNode => {
      return getNetworkContactLabel(networksDestination, 'long');
    },
    sortable: false,
    ellipsis: true,
    width: '200px'
  },
  {
    key: ShipmentColumnEnum.LATESTCOLLECTIONESTIMATE,
    title: 'Collection date',
    className: 'whitespace-nowrap',
    render: (_, { calculatedInfo }: ConsolidationShipment): ReactNode => {
      const latestCollectionEstimate: string = calculatedInfo?.collectionInFull?.dateTimeRange?.endDateTime?.date || '';

      return formatDate(latestCollectionEstimate);
    }
  },
  {
    key: ShipmentColumnEnum.PURCHASEORDERS,
    title: 'PO references',
    sortable: false,
    render: (_, { purchaseOrderReferences, zencargoReference }: ConsolidationShipment): ReactNode => (
      <OrdersReferencesList
        detailsUrl={(id: string) =>
          consolidationRoutes.consolidationShipmentsOrderDetailsPage.getUrl(consolidatedShipmentId, id, 'items')
        }
        orderReferencesList={purchaseOrderReferences}
        zencargoReference={zencargoReference}
      />
    )
  },
  {
    key: ShipmentColumnEnum.CARGODESCRIPTION,
    title: 'Cargo',
    render: (_, { calculatedInfo, cargo, cargos }: ConsolidationShipment): ReactNode => {
      const showVehicleLabel: boolean = !!cargos?.[0]?.cargoType || !!cargo?.vehicle?.vehicleType;

      return (
        <div>
          {showVehicleLabel && <div className="leading-normal mb-1">{getConsolidationCargoLabel(cargos, cargo)}</div>}
          <CargoSummary summary={calculatedInfo?.cargoSummary} />
        </div>
      );
    },
    sortable: false
  },
  ...(canRemoveShipmentFromConsolidation
    ? [
        {
          key: 'actionItems',
          fixed: 'right' as const,
          title: 'Remove',
          render: (_: unknown, { zencargoReference }: ConsolidationShipment): ReactNode => {
            return <ActionHandleContainer zencargoReference={zencargoReference} />;
          },
          sortable: false,
          width: '100px'
        }
      ]
    : [])
];
