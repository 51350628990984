import type { FC, ReactNode } from 'react';
import type { ObjectSchema } from 'yup';

import { Form, FormHiddenInput, FormInstance } from '@zen/Components/Form';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import FormTimeInput from '@zen/Components/Form/FormTimeInput';
import { Icon, Tooltip } from '@zen/DesignSystem';
import type { DateWithTimeRangeAndDelayReason } from '@zen/OperationsShipments/EditRoadShipment/types';
import FormMilestoneDelayReasonFields from '@zen/Shipment/RouteProgress/VoyageMilestones/components/FormMilestoneDelayReasonFields';
import { getToday } from '@zen/utils/date';
import { formatTimeZone } from '@zen/utils/dateTime';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Optional } from '@zen/utils/typescript';

import CollectionAndDeliveryFormButtons from '../CollectionAndDeliveryFormButtons';
import type { FormLabels } from './types';

interface Props {
  disabledDayTooltip?: string;
  initialValues: DateWithTimeRangeAndDelayReason;
  labels: FormLabels;
  maxDate?: string;
  minDate?: string;
  onCancel: () => void;
  onSubmit: (values: DateWithTimeRangeAndDelayReason) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
  timeZone: Optional<string>;
  validationSchema?: ObjectSchema<{}>;
  withDelayReason?: boolean;
  withRange?: boolean;
}

const DateWithTimeRangeForm: FC<Props> = (props) => {
  const {
    disabledDayTooltip,
    initialValues,
    labels,
    minDate,
    maxDate,
    onCancel,
    onSubmit,
    onSuccess,
    timeZone,
    withRange = true,
    withDelayReason,
    validationSchema
  } = props;
  const { date, startTime, endTime } = labels;

  const renderFormButtons = ({ dirty, isSubmitting }: FormInstance<object>): ReactNode => (
    <CollectionAndDeliveryFormButtons isDisabled={!dirty} isSubmitting={isSubmitting} onCancel={onCancel} />
  );

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      initialValues={initialValues}
      isNested={true}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      submitOnEnter={false}
      validationSchema={validationSchema}
    >
      {({ values }: FormInstance<DateWithTimeRangeAndDelayReason>) => {
        return (
          <div className="w-96 js-dateAndTimeRangeForm" data-testid="date-and-time-range-form">
            <div className="flex">
              <FormDatePickerInput
                className="w-40 mr-2"
                disabledDayTooltip={disabledDayTooltip}
                hideErrorMessage={true}
                label={date}
                maxDate={maxDate}
                minDate={minDate}
                name="date"
                noMargin={true}
                placeholder="July 21, 2021"
              />
              <FormTimeInput hideErrorMessage={true} label={startTime} name="startTime" noMargin={true} placeholder="12:00" />
              {withRange && (
                <FormTimeInput
                  className="w-20 ml-2 whitespace-nowrap"
                  hideErrorMessage={true}
                  label={endTime}
                  name="endTime"
                  noMargin={true}
                  placeholder="17:00"
                />
              )}
              <Tooltip
                placement="right"
                tooltipContent={formatTimeZone(values.date || getToday(), timeZone)}
                triggerClassName="pl-2 mt-6 h-10"
              >
                <Icon className="text-grey-base text-base leading-10" icon="zicon-information" />
              </Tooltip>
            </div>
            <FormHiddenInput name="date" />
            <FormHiddenInput name="startTime" />
            {withRange && <FormHiddenInput name="endTime" />}
            {withDelayReason && (
              <div className="mt-6" data-testid="delay-reason-fields">
                <FormMilestoneDelayReasonFields />
                <div className="text-sm mt-2">To update existing delays please go to route progress.</div>
              </div>
            )}
          </div>
        );
      }}
    </Form>
  );
};

export default DateWithTimeRangeForm;
