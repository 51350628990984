import type { FC } from 'react';

import PageNotFound from '@zen/Components/PageNotFound';
import QueryHandler from '@zen/Components/QueryHandler';
import { ContactRemoveLevelType } from '@zen/Networks/Contacts/ContactTable';
import ContactTableContainer from '@zen/Networks/Contacts/ContactTableContainer';
import type { MyOrganisationContactsQueryResult, MyOrganisationContactsQueryVariables } from '@zen/Networks/graphql';
import { useMyOrganisationContactsQuery, useMyOrganisationQuery } from '@zen/Networks/graphql';
import LocationList from '@zen/Networks/Locations/LocationList';

import MyOrganisationHeader from '../MyOrganisationHeader';

interface Props {
  networkId: string;
}

const MyOrganisationDetails: FC<Props> = ({ networkId }) => {
  const { data, loading, error } = useMyOrganisationQuery({
    variables: {
      networkId
    },
    fetchPolicy: 'no-cache'
  });

  return (
    <QueryHandler data={data?.network?.myOrganisation} error={!!error} errorComponent={<PageNotFound />} isLoading={loading}>
      {(myOrganisation) => {
        const { vat, eori, name, locations, id, assignedRoles } = myOrganisation;

        return (
          <>
            <MyOrganisationHeader eori={eori || ''} name={name} roles={assignedRoles} vat={vat || ''} />
            <LocationList isMyOrganisationPage={true} locations={locations} organisationId={id} />
            <ContactTableContainer<MyOrganisationContactsQueryResult, MyOrganisationContactsQueryVariables>
              hasLocations={!!locations.length}
              isMyOrganisationPage={true}
              organisationId={id}
              paginatedQueryHook={useMyOrganisationContactsQuery}
              paginatedQueryPath="myOrganisationContacts.myOrganisation.contacts"
              paginatedQueryVars={{ networkId }}
              removeType={ContactRemoveLevelType.ORGANISATION}
            />
          </>
        );
      }}
    </QueryHandler>
  );
};

export default MyOrganisationDetails;
