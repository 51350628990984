import type { FC, ReactNode } from 'react';

import { Pill } from '@zen/DesignSystem';

import { Role } from '../types';

interface Props {
  roles: Role[];
}

const OrganisationRoles: FC<Props> = ({ roles }) => {
  const organisationLabelMapping: Record<Role.AGENT_FORWARDER | Role.MANUFACTURER, string> = {
    [Role.AGENT_FORWARDER]: 'agent',
    [Role.MANUFACTURER]: 'manufacturer'
  };

  const renderRole = (role: Role, index: number): ReactNode => {
    const canShowRole = role === Role.MANUFACTURER || role === Role.AGENT_FORWARDER;

    if (!canShowRole) return null;

    const label = organisationLabelMapping[role];

    return <Pill key={index}>Assigned as {label}</Pill>;
  };

  return <div data-testid="organisation-roles">{roles.map(renderRole)}</div>;
};

export default OrganisationRoles;
