import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  quantityFulfilled: Yup.number()
    .nullable()
    .positive('Must be greater than 0')
    .integer('Must be whole number')
    .required('Quantity is required'),
  cbm: Yup.number().nullable().positive('Must be greater than 0')
});
