import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetContactListQueryVariables = GraphQLTypes.Exact<{
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  types?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.ContactTypeEnum> | GraphQLTypes.ContactTypeEnum>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetContactListQueryResult = { __typename?: 'Query' } & {
  contacts?: GraphQLTypes.Maybe<
    { __typename?: 'NetworkContactConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<GraphQLTypes.Maybe<{ __typename?: 'NetworkContact' } & Pick<GraphQLTypes.NetworkContact, 'id' | 'name'>>>
      >;
    }
  >;
};

export const GetContactListDocument = /* #__PURE__ */ gql`
  query getContactList($searchQuery: String, $types: [ContactTypeEnum!], $order: SortInput) {
    contacts(paginated: false, textContains: $searchQuery, statuses: ACTIVE, types: $types, order: $order) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useGetContactListQuery__
 *
 * To run a query within a React component, call `useGetContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactListQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      types: // value for 'types'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetContactListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetContactListQueryResult, GetContactListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetContactListQueryResult, GetContactListQueryVariables>(GetContactListDocument, options);
}
export function useGetContactListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContactListQueryResult, GetContactListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetContactListQueryResult, GetContactListQueryVariables>(GetContactListDocument, options);
}
export type GetContactListQueryHookResult = ReturnType<typeof useGetContactListQuery>;
export type GetContactListLazyQueryHookResult = ReturnType<typeof useGetContactListLazyQuery>;
