import copy from 'copy-to-clipboard';
import type { FC } from 'react';

import { Button } from '@zen/DesignSystem';
import type { QuoteOption } from '@zen/Quotes/types';
import { useNotification } from '@zen/utils/hooks/useNotification';

import { buildClipboardDetailsText } from '../../quoteOption.helpers';

interface Props {
  quoteOption: Pick<QuoteOption, 'description' | 'expiryDate' | 'quoteReference' | 'total'>;
}

const CopyQuoteDetailsButton: FC<Props> = ({ quoteOption }) => {
  const { addSuccess } = useNotification();

  const handleCopy = (): void => {
    copy(buildClipboardDetailsText(quoteOption));
    addSuccess('Quote option details copied');
  };

  return (
    <Button iconLeft="zicon-copy" onClick={handleCopy} variant="tertiary">
      Copy details
    </Button>
  );
};

export type { Props as CopyQuoteDetailsButtonProps };

export default CopyQuoteDetailsButton;
