import { createRef, FC, ReactNode, Ref, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import NoResults from '@zen/Components/NoResults';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { Button, Modal, Pagination, SearchInput, TabConfig, Tabs } from '@zen/DesignSystem';
import PreferencesIndicator from '@zen/Settings/Preferences/PreferencesIndicator';
import usePagination from '@zen/utils/hooks/pagination/usePagination';
import useAccount from '@zen/utils/hooks/useAccount';
import { useConsumeLocationState } from '@zen/utils/hooks/useConsumeLocationState';
import useTracking from '@zen/utils/hooks/useTracking';

import { ProductListQueryResult, ProductListQueryVariables, useProductListQuery } from '../graphql';
import ProductsCsvImport from '../ProductsCsvImport';
import type { ProductListItem } from '../types';
import SkeletonProductListLoading from './Loading/SkeletonProductListLoading';
import ProductItem from './ProductItem';

enum ProductTab {
  ACTIVE = 'active',
  ARCHIVED = 'archived'
}

const isArchived = (activeTab: ProductTab): boolean => activeTab === ProductTab.ARCHIVED;

const ProductList: FC = () => {
  const { accountUuid } = useAccount();
  const { trackEvent } = useTracking();
  const { check } = useGlobalPermissions();
  const [activeTab, setActiveTab] = useState<ProductTab>(ProductTab.ACTIVE);
  const [query, setQuery] = useState<string>('');
  const [isUploadCsvModalOpen, setIsUploadCsvModalOpen] = useState<boolean>(false);
  const [filterByUserPreferences, setUseUserPreferences] = useState<boolean>();
  const reverseActiveTab = isArchived(activeTab) ? ProductTab.ACTIVE : ProductTab.ARCHIVED;
  const canImportProductsCsv: boolean = check('productsCsvImport.canImport');

  const inputRef: Ref<HTMLInputElement> = createRef<HTMLInputElement>();

  const { nodes, loading, error, paginationInfo, refetch } = usePagination<
    ProductListQueryResult,
    ProductListQueryVariables,
    ProductListItem
  >(
    useProductListQuery,
    'products',
    {
      accountUuid,
      archived: isArchived(activeTab),
      query
    },
    18,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );

  useEffect(() => {
    refetch();
  }, [filterByUserPreferences, refetch]);

  useConsumeLocationState((searchTerm: string) => {
    if (searchTerm) {
      setQuery(searchTerm);

      inputRef?.current?.focus();
    }
  }, 'searchTerm');

  const handleSearch = (): void => {
    trackEvent({
      action: 'Products',
      category: 'Search',
      label: query
    });
  };

  const tabsConfig: TabConfig<ProductTab>[] = [
    {
      isActive: activeTab === ProductTab.ACTIVE,
      label: 'Active',
      value: ProductTab.ACTIVE
    },
    {
      isActive: activeTab === ProductTab.ARCHIVED,
      label: 'Archived',
      value: ProductTab.ARCHIVED
    }
  ];

  const actionButtons: ReactNode = (
    <>
      {canImportProductsCsv && (
        <Button
          className="mr-2"
          data-testid="csv-import-link"
          iconLeft="zicon-upload"
          onClick={() => setIsUploadCsvModalOpen(true)}
        >
          Upload products CSV
        </Button>
      )}
      <Link to="/dashboard/products/new">
        <Button data-testid="new-link">Add new product</Button>
      </Link>
    </>
  );

  const noResults: ReactNode = (
    <div className="mt-10" data-testid="empty-products">
      <NoResults headline="No products found" tagline={`You can add a new product or view ${reverseActiveTab} products`} />
    </div>
  );

  const closeCsvUploadModal = (): void => setIsUploadCsvModalOpen(false);

  const tabs: ReactNode = <Tabs<ProductTab> onTabClick={setActiveTab} tabsConfig={tabsConfig} />;

  const filterSwitcher: ReactNode = <PreferencesIndicator onChange={setUseUserPreferences} />;

  return (
    <Page actionButtons={actionButtons} filterSwitcher={filterSwitcher} tabsComponent={tabs} title="Products">
      <Modal isOpen={isUploadCsvModalOpen} onClose={closeCsvUploadModal} title="Upload products CSV">
        <ProductsCsvImport onComplete={closeCsvUploadModal} />
      </Modal>
      <SearchInput
        ref={inputRef}
        className="fs-products-search"
        onChange={setQuery}
        onClear={() => setQuery('')}
        onSearch={handleSearch}
        placeholder="Search for a product"
        value={query}
      />
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonProductListLoading />}
        noResults={noResults}
      >
        {(products: ProductListItem[]) => (
          <div className="grid grid-cols-3 gap-4 mt-6" data-testid="product-list">
            {products.map((product: ProductListItem, i: number) => (
              <ProductItem key={i} product={product} query={query} />
            ))}
          </div>
        )}
      </QueryHandler>
      <Pagination pageInfo={paginationInfo} />
    </Page>
  );
};

export default ProductList;
