import type { NewCargoType } from '../types';
import { cargoTypeLabelMapping, newCargoTypeLabelMapping } from '../types';
import type { Nullable } from '../utils/typescript';
import type {
  ConsolidatedShipmentCommonInput,
  ConsolidatedShipmentValues,
  ConsolidationLegacyCargo,
  ConsolidationLegInputType,
  ConsolidationLegValues,
  ConsolidationNewCargo,
  NetworksAssignableInterface
} from './types';

export const getEmptyConsolidationLeg = (
  previousLegDestination?: Nullable<NetworksAssignableInterface>
): ConsolidationLegValues => ({
  networksOrigin: previousLegDestination || null,
  estimatedDepartureDate: null,
  actualDepartureDate: null,
  networksDestination: null,
  estimatedArrivalDate: null,
  actualArrivalDate: null
});

export const emptyConsolidation: ConsolidatedShipmentValues = {
  cbm: null,
  cutOffDate: null,
  customerId: null,
  grossWeight: {
    metric: null,
    value: null
  },
  looseCargoType: null,
  originAgent: null,
  quantity: null,
  reference: null,
  transitDocumentReference: null,
  vehiclePlateNumber: null,
  legs: [getEmptyConsolidationLeg()]
};

export const getConsolidationCargoLabel = (newCargo: ConsolidationNewCargo, legacyCargo: ConsolidationLegacyCargo): string => {
  const newCargoLabel: string = newCargo?.[0]?.cargoType
    ? newCargoTypeLabelMapping[newCargo?.[0]?.cargoType as NewCargoType]
    : '';
  const legacyCargoLabel: string = legacyCargo?.vehicle?.vehicleType
    ? cargoTypeLabelMapping[legacyCargo.vehicle.vehicleType]
    : '';

  return newCargoLabel || legacyCargoLabel;
};

const prepareConsolidationInput = (formValues: ConsolidatedShipmentValues): ConsolidatedShipmentCommonInput => {
  const {
    originAgent,
    grossWeight,
    looseCargoType,
    legs,
    cbm,
    cutOffDate,
    quantity,
    reference,
    transitDocumentReference,
    vehiclePlateNumber
  } = formValues;

  const legsInput: ConsolidationLegInputType[] = legs.map((leg: ConsolidationLegValues) => {
    const { estimatedDepartureDate, estimatedArrivalDate, networksDestination, networksOrigin, ...rest } = leg;

    return {
      ...rest,
      estimatedDepartureDate: estimatedDepartureDate ?? '',
      estimatedArrivalDate: estimatedArrivalDate ?? '',
      networksDestinationId: networksDestination?.id ?? '',
      networksOriginId: networksOrigin?.id ?? ''
    };
  });

  return {
    cbm,
    cutOffDate: cutOffDate ?? '',
    grossWeight: grossWeight?.value ? grossWeight : null,
    legs: legsInput,
    looseCargoType,
    originAgentId: originAgent?.id ?? null,
    quantity,
    reference: reference ?? '',
    transitDocumentReference,
    vehiclePlateNumber: vehiclePlateNumber ?? ''
  };
};

export { prepareConsolidationInput };
