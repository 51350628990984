import type { FC, ReactNode } from 'react';
import { v4 } from 'uuid';

import NetworkContactItem from '@zen/Components/NetworkContactItem';
import { useAssignNotifiedPartyMutation, useUnassignNotifiedPartyMutation } from '@zen/Networks/graphql';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, OrganisationLocation } from '@zen/Networks/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import type { BookingTradeParties, NotifiedParty } from '../types';

interface Props {
  accountUuid: string;
  canManageTradeParties: boolean;
  data: BookingTradeParties;
  zencargoReference: string;
}

const NotifiedPartiesFields: FC<Props> = (props) => {
  const { accountUuid, canManageTradeParties, data, zencargoReference } = props;
  const { networksNotifiedParties } = data;

  const [assignNotifiedParty] = useAssignNotifiedPartyMutation({
    refetchQueries: ['getBookingTradeParties'],
    awaitRefetchQueries: true
  });
  const [removeNotifiedPartyAssignment] = useUnassignNotifiedPartyMutation({
    refetchQueries: ['getBookingTradeParties'],
    awaitRefetchQueries: true
  });
  const { addSuccess, addError } = useNotification();

  const hasNotifiedParties: boolean = !!networksNotifiedParties?.length;
  const text: string = hasNotifiedParties ? 'Add another notify party' : 'Notify party';

  const handleInputChange = async (
    value: Nullable<OrganisationLocation>,
    party: Nullable<NotifiedParty> = null
  ): Promise<void> => {
    if (hasNotifiedParties && value) {
      const isNotifiedPartyAlreadySelected = !!networksNotifiedParties?.find(
        (element: NotifiedParty) => element.assignable?.id === value.id
      );

      if (isNotifiedPartyAlreadySelected) {
        addError('Notify party already selected');

        return;
      }
    }

    const confirmationText: string = value ? 'removed' : 'saved';

    const variables = {
      zencargoReference,
      notifiedPartyId: (party && party.notifiedPartyId) || v4()
    };

    const onSuccess = () => addSuccess(`Notify party has been ${confirmationText}.`);

    if (!value) {
      performMutation({
        mutationFn: () => removeNotifiedPartyAssignment({ variables: { input: variables } }),
        onError: addError,
        onSuccess
      });
    } else {
      const assignableId: string = value?.id || '';

      performMutation({
        mutationFn: () =>
          assignNotifiedParty({
            variables: { input: { ...variables, assignableId } }
          }),
        onSuccess,
        onError: addError
      });
    }
  };

  const renderTradeParty = (
    label: string,
    party: Nullable<NotifiedParty> = null,
    inputValue: OrganisationLocation = null,
    key: Nullable<string> = null
  ): ReactNode => (
    <NetworkContactItem
      {...(key && { key })}
      accountUuid={accountUuid}
      assignmentType={AssignmentTypeValue.PARTY}
      domainName={AssignmentTargetTypeEnum.BOOKING_NOTIFIED_PARTY}
      isEditable={canManageTradeParties}
      label={label}
      name="notifyParty"
      onChange={(value: Nullable<OrganisationLocation>) => handleInputChange(value, party)}
      value={inputValue}
    />
  );

  const renderNotifiedParties = (): ReactNode =>
    networksNotifiedParties?.map((party: NotifiedParty): ReactNode => {
      const notifiedParty: OrganisationLocation = party.assignable as OrganisationLocation;

      return renderTradeParty('Notify party', party, notifiedParty, notifiedParty?.id);
    });

  return (
    <>
      {renderNotifiedParties()}
      {(!hasNotifiedParties || canManageTradeParties) && renderTradeParty(text)}
    </>
  );
};

export default NotifiedPartiesFields;
