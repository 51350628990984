import cx from 'classnames';
import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { AsyncSelect } from '@zen/DesignSystem';
import type { AssignmentTargetTypeEnum, AssignmentTypeValue, NetworksOrgLoc } from '@zen/Networks/types';
import useAccount from '@zen/utils/hooks/useAccount';
import type { Nullable } from '@zen/utils/typescript';

import NetworksContactAddButton from '../NetworksContactAddButton';
import { getOrgLocLabel, handleChangeCallback, IHandleChangeCallback } from '../networksContactHelper';

interface Props {
  accountUuid: string;
  assignmentType: AssignmentTypeValue;
  domainName: AssignmentTargetTypeEnum;
  formTitle: string;
  isClearable?: boolean;
  onChange: (value: Nullable<NetworksOrgLoc>) => void;
  value?: NetworksOrgLoc;
}

const NetworksContactInput: FC<Props> = (props) => {
  const { accountUuid, formTitle, onChange, domainName, assignmentType, value, isClearable = true } = props;
  const {
    userProfile: { division }
  } = useAccount();
  const { check } = useGlobalPermissions();

  const accountId: string = division ? division.parentAccountId : accountUuid;
  const canManageNetworks: boolean = check('networks.canManage');
  const classNames: string = cx('flex-1', { 'pr-4': canManageNetworks });

  const handleInputChange: IHandleChangeCallback = handleChangeCallback({
    accountUuid: accountId,
    domainName,
    assignmentType
  });

  return (
    <div className="flex justify-between items-start" data-testid="networks-contact-input">
      <div className={classNames}>
        <AsyncSelect<NetworksOrgLoc>
          formatOptionLabel={getOrgLocLabel}
          isClearable={isClearable}
          loadOptions={handleInputChange}
          onChange={onChange}
          value={value}
        />
      </div>
      {canManageNetworks && <NetworksContactAddButton accountUuid={accountId} handleAdd={onChange} title={formTitle} />}
    </div>
  );
};

export default NetworksContactInput;
