import { get } from 'lodash';
import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import useControlTowerEnabled from '@zen/ControlTower/hooks/useControlTowerEnabled';
import { Headline } from '@zen/DesignSystem';
import useZencargoNetwork from '@zen/Networks/hooks/useZencargoNetwork';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';
import NetworksForwarderFormInput from '@zen/Networks/NetworksContactPicker/NetworksForwarderFormInput';
import { AssignmentTypeValue } from '@zen/Networks/types';

import type { NetworkFieldProps } from '../OrderForm';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'allProps' implicitly has an 'any' type.
const extractForwarderFieldProps = (allProps) => {
  const { domainName, name, label, accountUuid } = allProps;

  return {
    forwarderInputFieldProps: { domainName, name, label },
    accountId: accountUuid
  };
};

interface Props {
  getFieldProps: (name: string, label: string, assignmentType: AssignmentTypeValue) => NetworkFieldProps;
}

const OrderContactsFields: FC<Props> = ({ getFieldProps }) => {
  const { check } = useGlobalPermissions();
  const { data } = useZencargoNetwork();
  const zencargoNetworkId = get(data, 'zencargoNetwork.id');
  const canAssignAgent = check('purchaseOrders.canAssignAgent');
  const { forwarderInputFieldProps, accountId } = extractForwarderFieldProps(
    getFieldProps('forwarder', 'Forwarder', AssignmentTypeValue.FORWARDER)
  );
  const canAssignForwarder = useControlTowerEnabled(accountId);

  return (
    <>
      <Headline level={2}>Contacts</Headline>
      <NetworksContactFormInput {...getFieldProps('manufacturer', 'Manufacturer', AssignmentTypeValue.MANUFACTURER)} />
      <NetworksContactFormInput {...getFieldProps('seller', 'Seller', AssignmentTypeValue.SELLER)} />
      <NetworksContactFormInput {...getFieldProps('buyer', 'Buyer', AssignmentTypeValue.BUYER)} />

      {canAssignAgent && (
        <NetworksContactFormInput
          {...getFieldProps('originAgent', 'Origin agent', AssignmentTypeValue.ORIGIN_AGENT)}
          accountUuid={zencargoNetworkId}
        />
      )}

      {canAssignForwarder && <NetworksForwarderFormInput {...forwarderInputFieldProps} />}
    </>
  );
};

export default OrderContactsFields;
