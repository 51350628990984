import type { FC, ReactElement } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { useGetPreferencesIndicatorQuery, useUpdateUserPreferencesMutation } from '@zen/Settings/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';

import PreferencesIndicator from './PreferencesIndicator';

interface PreferencesIndicatorProps {
  handleChange: () => void;
  isEnabled: boolean;
}

interface Props {
  children?: (isEnabled: boolean, handleChange: () => void) => ReactElement<PreferencesIndicatorProps>;
  onChange?: (preferencesEnabled: boolean) => void;
}

const PreferencesIndicatorContainer: FC<Props> = ({ onChange, children }) => {
  const { data, refetch } = useGetPreferencesIndicatorQuery();
  const isEnabled: boolean = data?.currentUser?.preferences?.filterViews || false;
  const { check } = useGlobalPermissions();
  const [updatePreferences] = useUpdateUserPreferencesMutation();
  const canSetPreferences = check('settings.canSetPreferences');
  const { addError } = useNotification();

  const togglePreferences = async () => {
    const results = await updatePreferences({
      variables: {
        input: {
          filterViews: !isEnabled
        }
      }
    });

    if (results.errors || !!results?.data?.currentUserUpdatePreferences?.errors?.length) {
      throw new Error();
    }

    refetch();

    if (onChange) {
      onChange(!isEnabled);
    }
  };

  const handleChange = async () => {
    try {
      await togglePreferences();
    } catch (e) {
      addError();
    }
  };

  if (!canSetPreferences) {
    return null;
  }

  if (children) {
    return children(isEnabled, handleChange);
  }

  return <PreferencesIndicator isEnabled={isEnabled} onChange={handleChange} />;
};

export default PreferencesIndicatorContainer;
