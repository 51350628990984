import type { RoutesConfig } from './types';

const ROLES_PREFIX = '/dashboard/roles';

interface RolesRoutes extends RoutesConfig {
  editRole: {
    getUrl: (roleId: string) => string;
    path: string;
  };
  index: {
    getUrl: () => string;
    path: string;
  };
  newRole: {
    getUrl: () => string;
    path: string;
  };
}

const rolesRoutes: RolesRoutes = {
  index: {
    path: ROLES_PREFIX,
    getUrl: () => ROLES_PREFIX
  },
  newRole: {
    path: `${ROLES_PREFIX}/new`,
    getUrl: () => `${ROLES_PREFIX}/new`
  },
  editRole: {
    path: `${ROLES_PREFIX}/edit/:roleId`,
    getUrl: (roleId: string) => `${ROLES_PREFIX}/edit/${roleId}`
  }
};

export default rolesRoutes;
