import type { FC, ReactElement } from 'react';

import { Button, Icon } from '@zen/DesignSystem';

interface Props {
  onApprove: () => void;
  onReject: () => void;
}

const QuoteOptionButtons: FC<Props> = ({ onApprove, onReject }) => {
  const iconLeft: ReactElement = <Icon className="text-xl" icon="zicon-tick" />;

  return (
    <div className="flex" data-testid="action-buttons">
      <Button className="mr-4 fs-af-reject-option" iconLeft="zicon-close" onClick={onReject} variant="secondary">
        Reject
      </Button>
      <Button className="fs-af-accept-quote-option" iconLeft={iconLeft} onClick={onApprove}>
        Approve
      </Button>
    </div>
  );
};

export default QuoteOptionButtons;
