import cx from 'classnames';
import type { FC } from 'react';

import type { BookingStageCheck } from '@zen/graphql/types.generated';

import type { StageCheckConfig } from '../types';
import { getStageItemConfig } from './helpers';

interface Props {
  check: BookingStageCheck;
}

const StageCheckItem: FC<Props> = ({ check }) => {
  const { completed } = check;
  const { label, status }: StageCheckConfig = getStageItemConfig(check);
  const statusClassName: string = cx(
    {
      'text-green-dark': completed,
      'text-red-dark': !completed
    },
    'font-bold'
  );

  return (
    <div className="flex items-center text-sm leading-normal mr-10" data-testid="stage-check-item">
      <div className="mr-1">{label}</div>
      <div className={statusClassName}>{status}</div>
    </div>
  );
};

export type { Props as StageCheckItemProps };

export default StageCheckItem;
