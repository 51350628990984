import { CSSProperties, FC, RefObject, useLayoutEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { SortingOrder, SortInput } from '@zen/types';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Optional } from '@zen/utils/typescript';

import { getElementWidth } from '../helpers';
import { TableConfigurationTrackingAction, TableConfigurationTrackingCategory } from '../types';
import StyledTableHeaderCell from './StyledTableHeaderCell';
import { COLUMN, DragAndDropItem, DragData, DropData } from './types';
import useTableHeaderStyle from './useHeaderStyle';

export interface Props {
  className?: string;
  columnKey: string;
  draggable?: boolean;
  fixed?: boolean;
  headerOffset?: number;
  loading?: boolean;
  offsetElementRef: RefObject<HTMLDivElement>;
  onColumnDragEnd?: (options: { draggedColumnKey: Optional<string> }) => void;
  onColumnDraggedOver?: (options: {
    draggedColumnKey: string;
    targetCellElement: HTMLTableCellElement | undefined;
    targetColumnKey: string;
  }) => void;
  onColumnDropped?: (options: { draggedColumnKey: string; targetColumnKey: string }) => void;
  onOrderChange?: (order: SortInput) => void;
  onResizeEnd?: (width: number) => void;
  order?: SortInput;
  resizable?: boolean;
  sortKey: string;
  sortable?: boolean;
  style?: object;
  tableId: string;
  title?: string;
  // rc-table internal
  width?: string | number;
}

const TableHeaderCell: FC<Props> = (props) => {
  const {
    children,
    className,
    columnKey,
    draggable,
    headerOffset = 0,
    fixed,
    onColumnDraggedOver,
    onColumnDropped,
    onColumnDragEnd,
    onOrderChange,
    offsetElementRef,
    order,
    resizable,
    sortable,
    sortKey,
    style,
    tableId,
    title,
    width,
    loading = false,
    ...rest
    // NOTE: rc-table calls this component, even if there are no columns to display, pretty much all passed properties are null
  } = props;

  const { trackEvent } = useTracking();
  const [resizableWidth, setResizabeleWidth] = useState<number>();
  const isSorted: boolean = order?.field === sortKey;
  const isSortLoading: boolean = Boolean(sortable && isSorted && loading);
  const direction: SortingOrder | undefined = isSorted ? order?.direction : undefined;

  const [{ isDragging }, setDragRef, setPreviewRef] = useDrag<DragAndDropItem, undefined, DragData>({
    type: COLUMN,
    item: { id: columnKey },
    end: (item) => {
      if (!draggable) {
        return;
      }
      onColumnDragEnd?.({ draggedColumnKey: item?.id });
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const [{ isOver }, setDropRef] = useDrop<DragAndDropItem, void, DropData>({
    accept: COLUMN,
    canDrop: () => {
      return !fixed;
    },
    drop: (item) => {
      if (!draggable) {
        return;
      }
      onColumnDropped?.({ targetColumnKey: columnKey, draggedColumnKey: item.id });
    },
    hover: (item) => {
      if (!draggable) {
        return;
      }

      onColumnDraggedOver?.({
        targetColumnKey: columnKey,
        draggedColumnKey: item.id,
        targetCellElement: cellEl
      });
    },
    collect: (monitor) => ({
      isOver: monitor.canDrop() && monitor.isOver()
    })
  });

  const [cellEl, setCellEl] = useState<HTMLTableHeaderCellElement>();

  const setCellRef = (elem: HTMLTableHeaderCellElement): void => {
    setCellEl(elem);
    setDropRef(elem);
    setPreviewRef(elem);
  };

  const tableHeaderStyle: CSSProperties = useTableHeaderStyle({
    referenceElement: offsetElementRef?.current,
    topOffsetInRem: headerOffset
  });
  const cellZIndex: number = fixed ? 4 : 3;

  useLayoutEffect(() => {
    // render the cell but do not paint, get the actual width,
    // save it and rerender with a resizeable version
    const renderedWidth: number = getElementWidth(cellEl);

    setResizabeleWidth(renderedWidth);
  }, [cellEl, width]);

  const handleHeaderClick = (): void => {
    if (!sortable) {
      return;
    }

    const newDirection: SortingOrder = isSorted && order?.direction === SortingOrder.ASC ? SortingOrder.DESC : SortingOrder.ASC;

    trackEvent({
      category: TableConfigurationTrackingCategory,
      action: TableConfigurationTrackingAction.SORT,
      label: tableId,
      properties: {
        tableId,
        columnName: sortKey,
        sortDirection: newDirection
      }
    });

    onOrderChange?.({ field: sortKey, direction: newDirection });
  };

  return (
    <StyledTableHeaderCell
      className={className}
      data-component="table-header-cell"
      draggable={draggable}
      fixed={fixed}
      isDragging={isDragging}
      isOver={isOver}
      loading={isSortLoading}
      offsetElementRef={offsetElementRef}
      onClick={handleHeaderClick}
      resizable={resizable}
      resizableWidth={resizableWidth}
      setCellRef={setCellRef}
      setDragRef={setDragRef}
      setResizabeleWidth={setResizabeleWidth}
      sortable={sortable}
      sortDirection={direction}
      style={{ ...style, ...tableHeaderStyle, zIndex: cellZIndex, width }}
      title={children}
      {...rest}
    />
  );
};

export default TableHeaderCell;
