import type { FC, ReactNode } from 'react';

import useRoutePermissions from '@zen/Auth/RoutePermissions/useRoutePermissions';
import useFeatureFlags from '@zen/utils/hooks/useFeatureFlags';

import type { EnhancedSidebarLink, SidebarCounters, SidebarLink } from '../../types';
import NavDropdown from '../NavDropdown';
import NavLink from '../NavLink';

interface Props {
  config: SidebarLink[];
  counters?: SidebarCounters;
}

export const SidebarLinks: FC<Props> = ({ config, counters = { bookingRequestsCount: 0, quotesCount: 0 } }) => {
  const { canAccessRoute } = useRoutePermissions();
  const featureFlags = useFeatureFlags();

  const isItemVisible = ({ children, featureFlagName, url }: SidebarLink): boolean => {
    if (children) {
      return children.some(isItemVisible);
    }

    if (featureFlagName && !featureFlags[featureFlagName]) {
      return false;
    }

    return canAccessRoute(url);
  };

  const mapItems = (item: SidebarLink): EnhancedSidebarLink => {
    return {
      ...item,
      isVisible: isItemVisible(item),
      children: item.children ? item.children.filter(isItemVisible) : []
    };
  };

  const items: EnhancedSidebarLink[] = config.map(mapItems);

  const renderNavItem = (item: EnhancedSidebarLink, index: number): ReactNode => {
    if (!item.isVisible) {
      return null;
    }

    if (item.children.length > 0) {
      return <NavDropdown key={index} item={item} />;
    }

    return <NavLink key={index} counters={counters} item={item} />;
  };

  return (
    <div className="pt-2 pb-4" data-testid="sidebar-links">
      {items.map(renderNavItem)}
    </div>
  );
};

export default SidebarLinks;
