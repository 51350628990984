import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCollectionDeliveryDetailsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
  customerId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetCollectionDeliveryDetailsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<
            GraphQLTypes.Booking,
            'zencargoReference' | 'deliveryScheduleRequired' | 'collectionScheduleRequired'
          >
        >
      >
    >;
  };
};

export const GetCollectionDeliveryDetailsQueryDocument = /* #__PURE__ */ gql`
  query getCollectionDeliveryDetailsQuery($zencargoReference: String!, $customerId: String) {
    bookings(zencargoReferences: [$zencargoReference], customerUuid: $customerId) {
      nodes {
        zencargoReference
        deliveryScheduleRequired
        collectionScheduleRequired
      }
    }
  }
`;

/**
 * __useGetCollectionDeliveryDetailsQuery__
 *
 * To run a query within a React component, call `useGetCollectionDeliveryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionDeliveryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionDeliveryDetailsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCollectionDeliveryDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCollectionDeliveryDetailsQueryResult, GetCollectionDeliveryDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCollectionDeliveryDetailsQueryResult, GetCollectionDeliveryDetailsQueryVariables>(
    GetCollectionDeliveryDetailsQueryDocument,
    options
  );
}
export function useGetCollectionDeliveryDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionDeliveryDetailsQueryResult, GetCollectionDeliveryDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCollectionDeliveryDetailsQueryResult, GetCollectionDeliveryDetailsQueryVariables>(
    GetCollectionDeliveryDetailsQueryDocument,
    options
  );
}
export type GetCollectionDeliveryDetailsQueryHookResult = ReturnType<typeof useGetCollectionDeliveryDetailsQuery>;
export type GetCollectionDeliveryDetailsQueryLazyQueryHookResult = ReturnType<
  typeof useGetCollectionDeliveryDetailsQueryLazyQuery
>;
