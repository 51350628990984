import { FC, useCallback, useEffect } from 'react';

import { HEADER_HEIGHT } from '@zen/Layout';

import { useForm } from '../useForm';

interface Props {
  additionalYOffset?: number;
}

const ScrollToFirstError: FC<Props> = ({ additionalYOffset = 0 }) => {
  const { errors, isSubmitting, isValidating } = useForm();

  const scrollTo = useCallback(
    (el: Element): void => {
      const HEADER_ADDITIONAL_OFFSET = 10;

      const y: number =
        el.getBoundingClientRect().top + window.pageYOffset - HEADER_HEIGHT - HEADER_ADDITIONAL_OFFSET - additionalYOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    },
    [additionalYOffset]
  );

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const hasErrors: boolean = Object.keys(errors).length > 0;

      if (hasErrors) {
        const errorElements: NodeListOf<Element> = document.querySelectorAll('.js-field-error');

        if (errorElements.length > 0) {
          scrollTo(errorElements[0]);
        }
      }
    }
  }, [errors, isSubmitting, isValidating, scrollTo]);

  return null;
};

export default ScrollToFirstError;
