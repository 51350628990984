import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Headline, Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  actions?: ReactNode;
  children?: ReactNode;
  className?: string;
  control?: ReactNode;
  footer?: ReactNode;
  id?: string;
  subtitle?: string;
  title: string;
  titleIcon?: IconName;
}

const Container: FC<Props> = ({ title, subtitle, actions, control, children, footer, titleIcon, className = '', ...rest }) => {
  const headerClassNames: string = cx(
    {
      'border-b border-solid border-grey-lighter': children
    },
    'px-6 pt-6'
  );
  const titleClassNames: string = cx(subtitle || actions ? 'pb-4' : 'pb-6', 'flex justify-between items-start');
  const containerClassNames: string = `bg-white border border-solid border-grey-lighter rounded ${className}`;

  return (
    <div className={containerClassNames} {...rest}>
      <div className={headerClassNames} data-testid="container-header">
        <div className={titleClassNames}>
          <div>
            <Headline level={2}>
              <>
                {titleIcon && <Icon className="mr-2" icon={titleIcon} />}
                {title}
              </>
            </Headline>
            {subtitle && <p className="text-sm text-grey-dark mt-1 leading-6">{subtitle}</p>}
          </div>
          {actions && <div className="flex space-x-4">{actions}</div>}
        </div>
        {control}
      </div>
      {children && <div className="py-4 px-6">{children}</div>}
      {footer && <div className="pb-4 pt-6 pl-2">{footer}</div>}
    </div>
  );
};

export type { Props as ContainerProps };
export default Container;
