import { useQuery } from '@apollo/client';

import useAccount from '@zen/utils/hooks/useAccount';

import { OrderCountQueryDocument } from '../graphql';

const useOrderCount = () => {
  const { accountUuid: accountId } = useAccount();

  return useQuery(OrderCountQueryDocument, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      ...(accountId && { customerUuid: accountId })
    }
  });
};

export default useOrderCount;
