import type { FC } from 'react';
import { useState } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import { ConsolidationLegsDetails, useGetConsolidatedShipmentLegsQuery } from '@zen/Consolidation';
import { Headline, LegacyModal, TextLink } from '@zen/DesignSystem';
import type { ConsolidatedShipment } from '@zen/Shipment/RouteProgress/VoyageMilestones/types';

type ConsolidatedShipmentDetails = Pick<ConsolidatedShipment, 'legs' | 'reference'>;

interface Props {
  consolidatedShipmentId: string;
}

const BookingConsolidationDetailsModal: FC<Props> = ({ consolidatedShipmentId }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { data, error, loading } = useGetConsolidatedShipmentLegsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      consolidationId: consolidatedShipmentId
    }
  });

  return (
    <div data-testid="booking-consolidation-details">
      <TextLink className="mr-4" onClick={() => setModalOpen(true)}>
        See more details
      </TextLink>
      <LegacyModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <LegacyModal.Content>
          <QueryHandler data={data?.consolidations?.nodes?.[0]} error={!!error} isLoading={loading}>
            {(consolidatedShipment: ConsolidatedShipmentDetails) => (
              <>
                <div className="flex items-baseline">
                  <Headline className="mb-4 mr-2" level={2}>
                    Consolidation details
                  </Headline>
                  <p>{consolidatedShipment.reference}</p>
                </div>
                <ConsolidationLegsDetails legs={consolidatedShipment.legs} />
              </>
            )}
          </QueryHandler>
        </LegacyModal.Content>
      </LegacyModal>
    </div>
  );
};

export default BookingConsolidationDetailsModal;
