import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type AssignableQueryVariables = GraphQLTypes.Exact<{
  networkId: GraphQLTypes.Scalars['String'];
  searchContains?: GraphQLTypes.InputMaybe<GraphQLTypes.LocationSearchInput>;
}>;

export type AssignableQueryResult = { __typename?: 'Query' } & {
  network?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      locations?: GraphQLTypes.Maybe<
        { __typename?: 'LocationAssignableConnection' } & {
          nodes?: GraphQLTypes.Maybe<
            Array<
              GraphQLTypes.Maybe<
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'timeZone'>)
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id' | 'timeZone'> & {
                      typename: 'NetworksOrgLoc';
                    } & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<GraphQLTypes.NetworksOrgLocOrganisation, 'id'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<
                    GraphQLTypes.NetworksTerminal,
                    'unlocode' | 'name' | 'id' | 'timeZone'
                  > & { typename: 'NetworksTerminal' } & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >
            >
          >;
        }
      >;
    }
  >;
};

export const AssignableQueryDocument = /* #__PURE__ */ gql`
  query assignableQuery($networkId: String!, $searchContains: LocationSearchInput) {
    network(id: $networkId) {
      locations(searchContains: $searchContains) {
        nodes {
          ... on NetworksTerminal {
            typename: __typename
            unlocode
            name
            label {
              long
            }
            id
          }
          ... on NetworksOrgLoc {
            typename: __typename
            id
            label {
              long
            }
            organisation {
              id
            }
          }
          timeZone
        }
      }
    }
  }
`;

/**
 * __useAssignableQuery__
 *
 * To run a query within a React component, call `useAssignableQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignableQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *      searchContains: // value for 'searchContains'
 *   },
 * });
 */
export function useAssignableQuery(baseOptions: Apollo.QueryHookOptions<AssignableQueryResult, AssignableQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AssignableQueryResult, AssignableQueryVariables>(AssignableQueryDocument, options);
}
export function useAssignableQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssignableQueryResult, AssignableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AssignableQueryResult, AssignableQueryVariables>(AssignableQueryDocument, options);
}
export type AssignableQueryHookResult = ReturnType<typeof useAssignableQuery>;
export type AssignableQueryLazyQueryHookResult = ReturnType<typeof useAssignableQueryLazyQuery>;
