import pluralize from 'pluralize';
import { FC, ReactNode, useState } from 'react';

import VerticalList from '@zen/Components/VerticalList';
import type { ListItemType } from '@zen/Components/VerticalList/ListItem';
import { SearchInput } from '@zen/DesignSystem';

import { filterList, isSearchableListItemArray } from './searchableListUtils';

interface ICustomSearchInput {
  filters: ISearchableListFilters;
  setFilters: (filters: ISearchableListFilters) => void;
}

interface ISearchableListFilters {
  query: string;
}

export interface Props {
  customFilter?: (list: ListItemType[], filters: ISearchableListFilters) => ListItemType[];
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'T' implicitly has an 'any' type.
  customListItem?: ({ item: T }) => ReactNode;
  customSearchInput?: (props: ICustomSearchInput) => ReactNode;
  list: ListItemType[];
  listHeader?: string;
  noResultsMessage?: string;
  searchInputPlaceholder?: string;
  selectedItem?: ListItemType;
}

const SearchableList: FC<Props> = (props) => {
  const {
    customFilter,
    customSearchInput,
    customListItem = null,
    list,
    listHeader = '',
    noResultsMessage = '',
    searchInputPlaceholder = '',
    selectedItem = null
  } = props;

  const [filters, setFilters] = useState<ISearchableListFilters>({ query: '' });
  const { query } = filters;

  const prepareFullFilteredList = () => {
    if (!isSearchableListItemArray(list)) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      return customFilter(list, filters);
    }

    if (isSearchableListItemArray(list)) {
      return filterList(list, query);
    }

    return [];
  };

  const fullFilteredList = list.length ? prepareFullFilteredList() : [];

  const displayedFilteredList: Array<ListItemType> = selectedItem ? [selectedItem] : fullFilteredList;

  const searchableListPlaceholder: string = searchInputPlaceholder || 'Search items';
  const isNoSearchResults: boolean = !fullFilteredList.length && query.length > 0;
  const noResultsText: string =
    noResultsMessage || 'Sorry but we can’t find any items matching this description. \
    Please try an alternative name';

  const renderResultsMessage = (): ReactNode => {
    const totalTemplatesListItemsAmount: number = query.length ? fullFilteredList.length : list.length;
    const queryMessage: ReactNode = query.length > 0 && (
      <div className="inline">
        containing the query <span className="font-bold">"{query}"</span>
      </div>
    );

    const typeOfItem: string = listHeader || 'template';
    const resultsMessage: string = ` ${totalTemplatesListItemsAmount} ${pluralize(typeOfItem, totalTemplatesListItemsAmount)} `;

    return (
      <div className="flex justify-center pb-6 text-grey-base text-center">
        <div>
          Displaying <span className="font-bold">{resultsMessage}</span> {queryMessage}
        </div>
      </div>
    );
  };

  const renderSearchInput = (): ReactNode => {
    if (customSearchInput) {
      return customSearchInput({ filters, setFilters });
    }

    return (
      <div className="mb-8 w-full" data-testid="searchable-list-input-wrapper">
        <SearchInput
          onChange={(searchQuery: string) => setFilters({ query: searchQuery })}
          placeholder={searchableListPlaceholder}
          value={query}
        />
      </div>
    );
  };

  return (
    <div className="w-full">
      {renderResultsMessage()}
      {renderSearchInput()}
      {isNoSearchResults && (
        <span
          className="flex justify-center pt-5 pb-6 text-grey-base px-16 text-center leading-snug"
          data-testid="no-search-results"
        >
          {noResultsText}
        </span>
      )}
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '(({ item: T }: { item: any; }) => ReactNode)... Remove this comment to see the full error message */}
      <VerticalList customListItem={customListItem} items={displayedFilteredList} />
    </div>
  );
};

export default SearchableList;
