import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ControlTowerOrdersPermissionsQueryVariables = GraphQLTypes.Exact<{
  uuid: GraphQLTypes.Scalars['String'];
}>;

export type ControlTowerOrdersPermissionsQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & {
      controlTower: { __typename?: 'ControlTower' } & {
        canViewForwardersOnPurchaseOrders: { __typename?: 'AuthorizationResult' } & Pick<
          GraphQLTypes.AuthorizationResult,
          'value'
        >;
      };
    }
  >;
};

export const ControlTowerOrdersPermissionsDocument = /* #__PURE__ */ gql`
  query controlTowerOrdersPermissions($uuid: String!) {
    account(uuid: $uuid) {
      controlTower {
        canViewForwardersOnPurchaseOrders {
          value
        }
      }
    }
  }
`;

/**
 * __useControlTowerOrdersPermissionsQuery__
 *
 * To run a query within a React component, call `useControlTowerOrdersPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useControlTowerOrdersPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useControlTowerOrdersPermissionsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useControlTowerOrdersPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<ControlTowerOrdersPermissionsQueryResult, ControlTowerOrdersPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ControlTowerOrdersPermissionsQueryResult, ControlTowerOrdersPermissionsQueryVariables>(
    ControlTowerOrdersPermissionsDocument,
    options
  );
}
export function useControlTowerOrdersPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ControlTowerOrdersPermissionsQueryResult, ControlTowerOrdersPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ControlTowerOrdersPermissionsQueryResult, ControlTowerOrdersPermissionsQueryVariables>(
    ControlTowerOrdersPermissionsDocument,
    options
  );
}
export type ControlTowerOrdersPermissionsQueryHookResult = ReturnType<typeof useControlTowerOrdersPermissionsQuery>;
export type ControlTowerOrdersPermissionsLazyQueryHookResult = ReturnType<typeof useControlTowerOrdersPermissionsLazyQuery>;
