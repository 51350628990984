import * as Yup from 'yup';

import { shipmentDelayReasonValidation } from '@zen/Shipment/RouteProgress/VoyageMilestones/components/MilestoneDateForm/validation';

export const createValidationSchema = () => {
  return Yup.object().shape({
    collectionFrom: Yup.object().required('Location must be selected.').nullable(),
    ...shipmentDelayReasonValidation
  });
};
