import type { FC } from 'react';

import { Tooltip } from '@zen/DesignSystem';
import type { LocalDateTimeRange } from '@zen/graphql/types.generated';
import { formatDateRange, formatTimeRange, formatTimeZone } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

import type { DatePrefix } from './types';

interface Props {
  dateTimeRange: Optional<LocalDateTimeRange>;
  prefix?: Optional<DatePrefix>;
}

const DateTimeRange: FC<Props> = ({ dateTimeRange, prefix = '' }) => {
  if (!dateTimeRange) {
    return null;
  }

  const { startDateTime, endDateTime } = dateTimeRange;

  return (
    <Tooltip placement="top-start" tooltipContent={formatTimeZone(startDateTime.date, startDateTime.timeZone)}>
      <div data-testid="date-time-range">
        <div className="text-sm leading-normal">
          <span>{prefix}</span> {formatDateRange(startDateTime.date, endDateTime?.date)}
        </div>
        {startDateTime.time && (
          <div className="text-xs text-grey-base">{formatTimeRange(startDateTime.time, endDateTime?.time, false)}</div>
        )}
      </div>
    </Tooltip>
  );
};

export default DateTimeRange;
