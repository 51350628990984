import { Chip } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type { ChipOption } from './types';

interface Props<T> {
  disabled?: boolean;
  onChange: (value: T) => void;
  onDeselect?: () => void;
  options: ChipOption<T>[];
  value: Optional<T>;
}

const ChipSelect = <T extends {}>({ options, onChange, value, disabled = false, onDeselect }: Props<T>) => {
  const isSelected = (chipValue: T): boolean => chipValue === value;

  return (
    <div className="flex flex-wrap">
      {options.map(({ label, value: chipValue, isDisabled = disabled }: ChipOption<T>, i: number) => (
        <Chip
          key={i}
          isDisabled={isDisabled}
          isSelected={isSelected(chipValue)}
          label={label?.toString() ?? ''}
          onDelete={onDeselect}
          onSelect={() => onChange(chipValue)}
        />
      ))}
    </div>
  );
};

export default ChipSelect;
