import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { TableLink } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

interface Props {
  clientReference?: Nullable<string>;
  zencargoReference: string;
}

const ShipmentReferences: FC<Props> = ({ clientReference, zencargoReference }) => {
  const { pathname } = useLocation();

  const shipmentUrl: string = `${pathname}/details/${zencargoReference}`;

  return (
    <div className="leading-normal js-intercom-shipment-references">
      <TableLink linkTo={shipmentUrl}>{zencargoReference}</TableLink>

      {clientReference && (
        <div className="text-xs overflow-hidden truncate text-grey-base" data-testid="client-reference">
          {clientReference}
        </div>
      )}
    </div>
  );
};

export type { Props as ShipmentReferencesProps };

export default ShipmentReferences;
