import type { FC } from 'react';

import Search from '@zen/Components/Search';

interface Props {
  onSearch: (query: string) => void;
  placeholder: string;
}

const OperationsSettingsSearch: FC<Props> = ({ onSearch, placeholder }) => {
  return (
    <div className="flex justify-end">
      <div className="w-80">
        <Search onClear={() => onSearch('')} onSubmit={onSearch} placeholder={placeholder} />
      </div>
    </div>
  );
};

export default OperationsSettingsSearch;
