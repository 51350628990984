import type { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '@zen/DesignSystem';

import { getOrdersUrl } from '../bookingButtonHelper';

const BookingRequestButton: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const url = getOrdersUrl(location);

  const handleClick = () => {
    history.push(url);
  };

  return (
    <Button className="outline-none w-full" data-testid="request-booking-button" onClick={handleClick} variant="secondary">
      Request booking
    </Button>
  );
};

export default BookingRequestButton;
