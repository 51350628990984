import cx from 'classnames';
import { isNil } from 'lodash';
import { FC, forwardRef, InputHTMLAttributes, ReactNode, Ref } from 'react';

import HelperText from '@zen/Components/HelperText';
import { useInputStyles } from '@zen/DesignSystem/hooks/useInputStyles';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'size' | 'required'> {
  className?: string;
  error?: boolean;
  fullWidth?: boolean;
  hasPrefix?: boolean;
  hasSuffix?: boolean;
  helperText?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  locked?: boolean;
  ref?: Ref<HTMLInputElement>;
  value: string | number | null;
}

const Input: FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    value = '',
    type = 'text',
    className,
    disabled,
    hasPrefix,
    hasSuffix,
    error,
    fullWidth = true,
    locked,
    iconLeft,
    iconRight,
    placeholder,
    name,
    onBlur,
    onChange,
    onKeyPress,
    helperText,
    ...rest
  } = props;

  const inputValue: string | number = isNil(value) ? '' : value;
  const inputClassNames: string = useInputStyles({
    disabled,
    error
  });
  const classNames: string = cx(
    inputClassNames,
    {
      'pl-8': !!iconLeft,
      'pr-12': !!iconRight,
      'rounded-l-none border-l-0': hasPrefix,
      'rounded-r-none border-r-0': hasSuffix,
      'w-full': fullWidth
    },
    'flex items-center px-3 h-10',
    className
  );
  const isDisabled = disabled || locked;

  const iconClassNames: string = cx(
    {
      'text-grey-light': isDisabled,
      'text-grey-base hover:text-grey-dark': !isDisabled
    },
    'absolute flex items-center justify-center'
  );

  return (
    <>
      <div className="relative flex items-center">
        {iconLeft && (
          <div className={`left-2 pointer-events-none ${iconClassNames}`} data-testid="icon-left">
            {iconLeft}
          </div>
        )}
        <input
          data-component="input"
          {...rest}
          ref={ref}
          className={classNames}
          disabled={isDisabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          type={type}
          value={inputValue}
        />
        {iconRight && (
          <div className={`right-2 ${iconClassNames}`} data-testid="icon-right">
            {iconRight}
          </div>
        )}
      </div>
      <HelperText helperText={helperText} />
    </>
  );
});

export type { Props as InputProps };

export default Input;
