export type NetworksRouteType = 'customer' | 'zencargo';

export interface NetworksRoutes {
  contact: {
    details: {
      getUrl: (organisationId: string, contactId: string) => string;
      path: string;
    };
    edit: {
      getUrl: (organisationId: string, contactId: string) => string;
      path: string;
    };
    new: {
      getUrl: (organisationId: string) => string;
      path: string;
    };
  };
  location: {
    details: {
      getUrl: (organisationId: string, locationId: string) => string;
      path: string;
    };
    edit: {
      getUrl: (organisationId: string, locationId: string) => string;
      path: string;
    };
    new: {
      getUrl: (organisationId: string) => string;
      path: string;
    };
  };
  myOrganisation: {
    details: {
      getUrl: () => string;
      path: string;
    };
    edit: {
      getUrl: () => string;
      path: string;
    };
  };
  organisation: {
    details: {
      getUrl: (organisationId: string) => string;
      path: string;
    };
    edit: {
      getUrl: (organisationId: string) => string;
      path: string;
    };
    new: {
      getUrl: () => string;
      path: string;
    };
  };
}

const networks = (type: NetworksRouteType = 'customer'): NetworksRoutes => {
  const networksRootPath = type === 'customer' ? '/dashboard/networks' : '/dashboard/zencargo-network';
  const networksOrganisationsPath: string = `${networksRootPath}/organisations`;

  return {
    myOrganisation: {
      details: {
        path: networksRootPath,
        getUrl: () => networksRootPath
      },
      edit: {
        path: `${networksRootPath}/edit`,
        getUrl: () => `${networksRootPath}/edit`
      }
    },
    location: {
      new: {
        path: `${networksOrganisationsPath}/:organisationId/locations/new`,
        getUrl: (organisationId: string) => `${networksOrganisationsPath}/${organisationId}/locations/new`
      },
      edit: {
        path: `${networksOrganisationsPath}/:organisationId/locations/:locationId/edit`,
        getUrl: (organisationId: string, locationId: string) =>
          `${networksOrganisationsPath}/${organisationId}/locations/${locationId}/edit`
      },
      details: {
        path: `${networksOrganisationsPath}/:organisationId/locations/:locationId`,
        getUrl: (organisationId: string, locationId: string) =>
          `${networksOrganisationsPath}/${organisationId}/locations/${locationId}`
      }
    },
    contact: {
      new: {
        path: `${networksOrganisationsPath}/:organisationId/contacts/new`,
        getUrl: (organisationId: string) => `${networksOrganisationsPath}/${organisationId}/contacts/new`
      },
      edit: {
        path: `${networksOrganisationsPath}/:organisationId/contacts/:contactId/edit`,
        getUrl: (organisationId: string, contactId: string) =>
          `${networksOrganisationsPath}/${organisationId}/contacts/${contactId}/edit`
      },
      details: {
        path: `${networksOrganisationsPath}/:organisationId/contacts/:contactId`,
        getUrl: (organisationId: string, contactId: string) =>
          `${networksOrganisationsPath}/${organisationId}/contacts/${contactId}`
      }
    },
    organisation: {
      new: {
        path: `${networksOrganisationsPath}/new`,
        getUrl: () => `${networksOrganisationsPath}/new`
      },
      edit: {
        path: `${networksOrganisationsPath}/:organisationId/edit`,
        getUrl: (organisationId: string) => `${networksOrganisationsPath}/${organisationId}/edit`
      },
      details: {
        path: `${networksOrganisationsPath}/:organisationId`,
        getUrl: (organisationId: string) => `${networksOrganisationsPath}/${organisationId}`
      }
    }
  };
};

export default networks;
