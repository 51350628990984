import { get } from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { Link } from 'react-router-dom';

import ValueIndicator from '@zen/Components/ValueIndicator';
import type { TableColumn } from '@zen/DesignSystem';
import { getFilters } from '@zen/IntakePlanning/Filters/helpers';
import { intakePathPrefix } from '@zen/IntakePlanning/IntakePlanningContainer';
import { IntakePlanningPeriod, PeriodsColumnEnum } from '@zen/IntakePlanning/types';
import { formatCurrency, formatNumber } from '@zen/utils/formatting';

const getDateDayMonth = (date: string) => moment(date).format('MMM DD');

export const getColumns = (queryString: string): TableColumn<IntakePlanningPeriod>[] => [
  {
    key: 'dateRange',
    title: 'Week no.',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '({ startDate, endDate }: { startDate: any; e... Remove this comment to see the full error message
    render: ({ startDate, endDate }) => {
      const params = qs.stringify({ ...getFilters(queryString), startDate }, { addQueryPrefix: true });

      return (
        <div className="truncate">
          <Link className="text-base text-navy-dark font-bold hover:underline" to={`${intakePathPrefix}/weekly${params}`}>
            {`${getDateDayMonth(startDate)} - ${getDateDayMonth(endDate)}`}
          </Link>
        </div>
      );
    },
    sortable: false
  },
  {
    key: PeriodsColumnEnum.QUANTITYPLANNED,
    title: 'Qty planned',
    render: (value: IntakePlanningPeriod[PeriodsColumnEnum.QUANTITYPLANNED]) => <>{formatNumber(value)}</>,
    sortable: false
  },
  {
    key: PeriodsColumnEnum.QUANTITYARRIVING,
    title: 'Qty arriving',
    render: (value: IntakePlanningPeriod[PeriodsColumnEnum.QUANTITYARRIVING], { quantityPlanned }: IntakePlanningPeriod) => (
      <ValueIndicator comparedValue={quantityPlanned} value={value} />
    ),
    sortable: false
  },
  {
    key: PeriodsColumnEnum.VALUEPLANNED,
    title: 'Value planned',
    render: (value: IntakePlanningPeriod[PeriodsColumnEnum.VALUEPLANNED]) =>
      value?.value ? formatCurrency(value.currency, value.value, 'symbol', true) : '-',
    sortable: false
  },
  {
    key: PeriodsColumnEnum.VALUEARRIVING,
    title: 'Value arriving',
    render: (valueArriving: IntakePlanningPeriod[PeriodsColumnEnum.VALUEARRIVING], { valuePlanned }: IntakePlanningPeriod) => {
      if (!get(valueArriving, 'value') || !get(valuePlanned, 'value')) {
        return '-';
      }

      return <ValueIndicator comparedValue={valuePlanned.value} currency={valueArriving.currency} value={valueArriving.value} />;
    },
    sortable: false
  }
];
