import type { FC, ReactChild } from 'react';

import { Body, Headline } from '@zen/DesignSystem';

interface Props {
  bodyText: ReactChild;
  headerText: string;
}

const EmptyState: FC<Props> = ({ headerText, bodyText }) => {
  return (
    <div data-testid="container-empty-state">
      <Headline className="text-grey-dark font-bold mt-2 mb-1" level={4}>
        {headerText}
      </Headline>
      <Body className="text-grey-base">{bodyText}</Body>
    </div>
  );
};

export type { Props as ContainerEmptyStateProps };
export default EmptyState;
