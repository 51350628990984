import { FC, useState } from 'react';

import { Dialog } from '@zen/DesignSystem';

import useApproveBookingRequest from '../hooks/useApproveBookingRequest';
import ApprovalMessage from './ApprovalMessage';

interface Props {
  forwarderApproved: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  zencargoReference: string;
}

const BookingRequestApproveModal: FC<Props> = ({ zencargoReference, isOpen, onClose, forwarderApproved, onSuccess }) => {
  const [isCustomerApproval, setCustomerApproval] = useState<boolean>(false);

  const { onApprove } = useApproveBookingRequest(zencargoReference, isCustomerApproval, forwarderApproved, onClose, onSuccess);

  return (
    <Dialog
      confirmIcon="zicon-tick"
      confirmLabel="Approve booking"
      header="Approve booking request"
      isOpen={isOpen}
      message={
        <ApprovalMessage
          isChecked={isCustomerApproval}
          isForwarderApproval={!forwarderApproved}
          onSwitcherChange={(value: boolean) => setCustomerApproval(value)}
          zencargoReference={zencargoReference}
        />
      }
      onClose={onClose}
      onConfirm={() => onApprove()}
    />
  );
};

export default BookingRequestApproveModal;
