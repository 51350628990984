import type { FC } from 'react';

import TabViewController from '@zen/Components/TabViewController';
import type { TabViewConfig } from '@zen/Components/TabViewController/types';

import Container, { ContainerProps } from '../Container';

type Props = Omit<ContainerProps, 'children' | 'control'> & { tabViewConfig: TabViewConfig[] };

const ContainerWithTabs: FC<Props> = ({ tabViewConfig, ...containerProps }) => {
  return (
    <TabViewController tabViewConfig={tabViewConfig}>
      {({ Tabs, Content }) => (
        <Container
          control={
            <div className="-mt-1">
              <Tabs />
            </div>
          }
          {...containerProps}
        >
          <Content />
        </Container>
      )}
    </TabViewController>
  );
};

export default ContainerWithTabs;
