import transitionCargo from '@zen/Cargo/cargoTransition.helper';
import type { DatePrefix } from '@zen/Components/DateTimeRange';

import { InFullStateType } from '../types';
import type { RoadShipment, RoadShipmentExtended } from './types';

export const getLatestStatePrefix = (state: InFullStateType): DatePrefix => {
  const statePrefixMapping: Record<InFullStateType, DatePrefix> = {
    [InFullStateType.REQUESTED]: 'req.',
    [InFullStateType.CONFIRMED]: 'conf.',
    [InFullStateType.ACTUAL]: 'act.',
    [InFullStateType.ESTIMATE]: 'est.'
  };

  return statePrefixMapping[state];
};

export const prepareShipments = (shipments: RoadShipment[]): RoadShipmentExtended[] => {
  return shipments.map((shipment) => ({ ...shipment, cargo: transitionCargo(shipment.cargo, shipment.cargos) }));
};
