import { FC, useCallback, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

interface Props {
  className?: string;
  collapsedHeight?: number;
  delay?: number;
  duration?: number;
  isOpened: boolean;
}

const CollapsableElement: FC<Props> = ({ isOpened, children, delay = 0, duration = 300, className, collapsedHeight = 0 }) => {
  const [renderChildren, setRenderChildren] = useState<boolean>(false);
  const timeoutRef = useRef<number>(0);

  const visibleByDefault: boolean = collapsedHeight > 0;
  const openedHeight: string | number = renderChildren ? 'auto' : collapsedHeight;
  const height: string | number = isOpened ? openedHeight : collapsedHeight;
  const shouldRenderChildren: boolean = visibleByDefault || renderChildren;

  const openElement = (): void => setRenderChildren(true);

  const closeElement = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => setRenderChildren(false), delay + duration);
  }, [setRenderChildren, delay, duration]);

  const toggleChildren = isOpened ? openElement : closeElement;

  useEffect(() => {
    toggleChildren();

    return () => {
      if (timeoutRef?.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isOpened, toggleChildren, closeElement]);

  return (
    <AnimateHeight className={className} data-testid="collapsable-element" delay={delay} duration={duration} height={height}>
      {shouldRenderChildren && children}
    </AnimateHeight>
  );
};

export type { Props as CollapsableElementProps };
export default CollapsableElement;
