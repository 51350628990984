import * as Yup from 'yup';

export const positiveWholeNumberValidator = (): Yup.BaseSchema => {
  return Yup.number().nullable().positive('Must be greater than 0.').integer('Must be whole number.');
};

export const quantityValidator = (isRequired: boolean = true): Yup.BaseSchema => {
  const validator = Yup.number().nullable().positive('Must be greater than 0.').integer('Must be whole number.');

  if (!isRequired) {
    return validator;
  }

  return validator.required('Quantity is required.');
};
