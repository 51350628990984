import { isEmpty, trim } from 'lodash';
import type { FC, ReactNode } from 'react';
import { useHistory } from 'react-router';
import sanitizeHtml from 'sanitize-html';

import { opsShipmentRoutes } from '@zen/Routes';
import { ShipmentPageSectionUrl } from '@zen/Shipment/ShipmentDetailsPage/types';
import { truncateText } from '@zen/utils/formatting';

interface Props {
  note: string; // html string
  zencargoReference: string;
}

const NotesTableCell: FC<Props> = ({ note, zencargoReference }) => {
  const { push } = useHistory();

  const innerHtmlText: string = sanitizeHtml(note, {
    allowedTags: [],
    textFilter: (text: string) => {
      return `${text}\n`;
    }
  });

  const htmlTextLines: string[] = innerHtmlText.split('\n');
  const lines: string[] = htmlTextLines.filter((htmlTextLine) => !isEmpty(trim(htmlTextLine)));

  const handleOnClick = (): void => {
    const url: string = opsShipmentRoutes.allShipmentsDetailsPage.getUrl(zencargoReference, ShipmentPageSectionUrl.NOTES);

    push(url);
  };

  return (
    <div className="cursor-pointer" data-testid="operations-notes-cell" onClick={handleOnClick}>
      {lines.slice(0, 3).map(
        (line: string, i: number): ReactNode => (
          <div key={i}>{truncateText(line, 30)}</div>
        )
      )}
    </div>
  );
};

export default NotesTableCell;
