import type { ReactNode } from 'react';

import type { RoadTrackingStatus } from '@zen/graphql/types.generated';
import type { ControlTowerPermissionsQueryResult } from '@zen/Shipments/graphql';
import type { DeepNonNullable, Optional } from '@zen/utils/typescript';

import type { GetShipmentDetailsPageDataQueryResult } from './graphql';
import type { NavItem } from './Navigation';

export type { BookingRequest, StageValue } from '@zen/graphql/types.generated';

export type ShipmentDetailsPageData = DeepNonNullable<GetShipmentDetailsPageDataQueryResult, 'nodes'>['bookings']['nodes'];

export type ControlTower = DeepNonNullable<ControlTowerPermissionsQueryResult, 'controlTower'>['account']['controlTower'];

export interface ShipmentDetailsPageSectionConfig extends NavItem {
  actions?: ReactNode;
  component: ReactNode;
  enabled?: boolean;
  label: string;
  showInDefaultContainer?: boolean;
  url: ShipmentPageSectionUrl;
}

export interface ConfigParams {
  accountUuid: string;
  canAccessAccruals: boolean;
  canAccessDocumentCreation: boolean;
  canEditIssueVisibility: boolean;
  canManageAssignedLots: boolean;
  canOpenIssue: boolean;
  canViewActionItems: boolean;
  canViewActivity: boolean;
  canViewForwarder: boolean;
  canViewNotes: boolean;
  canViewTradeParties: boolean;
  shouldDisplayQuotesSection: boolean;
  showCargoOptions: boolean;
  showNewQuoteButton: boolean;
  zencargoReference: string;
}

export interface ShipmentPageSection {
  activityFeed: ShipmentDetailsPageSectionConfig;
  cargo: ShipmentDetailsPageSectionConfig;
  documents: ShipmentDetailsPageSectionConfig;
  issuesAndActions: ShipmentDetailsPageSectionConfig;
  notes: ShipmentDetailsPageSectionConfig;
  orders: ShipmentDetailsPageSectionConfig;
  quoteOptions: ShipmentDetailsPageSectionConfig;
  routeProgress: ShipmentDetailsPageSectionConfig;
  tradeParties: ShipmentDetailsPageSectionConfig;
}

export enum SortingOrder {
  DEFAULT,
  BOOKING_REQUESTED,
  SHIPMENT_CONFIRMED
}

export enum ShipmentPageSectionUrl {
  ACTIVITY_FEED = 'activity-feed',
  ACTIVITY_FEED_EXPANDED = 'activity-feed-expanded',
  CARGO = 'cargo',
  DOCUMENTS = 'documents',
  ISSUES_AND_ACTIONS = 'issues-and-actions',
  NOTES = 'notes',
  ORDERS = 'orders',
  QUOTE_OPTIONS = 'quote-options',
  ROUTE_PROGRESS = 'route-progress',
  TRADE_PARTIES = 'trade-parties'
}

export type RoutePermissions = {
  canAccessAccruals: boolean;
  canAccessBackoffice: boolean;
  canAccessDocumentCreation: boolean;
  canManageQuotes: boolean;
};

export type AccountPermissions = {
  canViewForwarder: boolean;
};

export interface TrackingDetails {
  isTrackable: boolean;
  mapUrl: Optional<string>;
  status: Optional<RoadTrackingStatus>;
}

export const RoadTrackingCategory = 'RoadTracking';

export enum RoadTrackingAction {
  TRACKING_LINK_CLICK = 'TrackingLinkClick'
}
