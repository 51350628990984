import {
  CountryCode as LibCountryCode,
  getCountryCallingCode as parseCountryCallingCode,
  isSupportedCountry,
  parsePhoneNumberFromString
} from 'libphonenumber-js';

import type { CountryCode } from '@zen/Components/CountrySelect/types';

type PhoneNumber = {
  countryCode: CountryCode | undefined;
  nationalNumber: string;
};

const toLibCountryCode = <T>(countryCode: CountryCode | undefined): LibCountryCode | T => countryCode as LibCountryCode;

export const getCountryCallingCode = (countryCode: CountryCode): string | undefined => {
  if (!isCountrySupported(countryCode)) {
    return undefined;
  }

  return parseCountryCallingCode(toLibCountryCode(countryCode)) as string;
};

export const isCountrySupported = (countryCode: CountryCode): boolean => isSupportedCountry(toLibCountryCode(countryCode));

export const getPhoneNumber = (countryCode: CountryCode, input: string): string => {
  const callingCode: string = getCountryCallingCode(countryCode) || '';

  return `+${callingCode}${input}`;
};

export const parsePhoneNumber = (input: string, defaultCountryCode: CountryCode | undefined = undefined): PhoneNumber => {
  const phoneNumber = parsePhoneNumberFromString(input, toLibCountryCode(defaultCountryCode));

  return {
    countryCode: phoneNumber?.country as CountryCode | undefined,
    nationalNumber: (phoneNumber?.nationalNumber || '') as string
  };
};
