import type { FC } from 'react';
import { useHistory, useParams } from 'react-router';

import Page from '@zen/Components/Page';
import PageNotFound from '@zen/Components/PageNotFound';
import QueryHandler from '@zen/Components/QueryHandler';
import { useNetworksContext } from '@zen/Networks';
import EditOrganisationForm from '@zen/Networks/Form/EditOrganisationForm';
import type { EditOrganisationFormValues } from '@zen/Networks/Form/EditOrganisationForm/EditOrganisationForm';
import { useNetworksRenameOrganisationMutation } from '@zen/Networks/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useEditOrganisationQuery } from './graphql';

interface Props {
  networkId: string;
}

const EditOrganisation: FC<Props> = ({ networkId }) => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const { push } = useHistory();
  const [renameOrganisation] = useNetworksRenameOrganisationMutation();
  const { addSuccess, addError } = useNotification();
  const { data, loading, error } = useEditOrganisationQuery({
    variables: {
      accountUuid: networkId,
      id: organisationId
    },
    fetchPolicy: 'no-cache'
  });
  const { routes } = useNetworksContext();
  const organisationDetailsUrl: string = routes.organisation.details.getUrl(organisationId);

  const onCancel = (): void => push(organisationDetailsUrl);

  const onSubmit = async ({ name }: EditOrganisationFormValues): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        renameOrganisation({
          variables: {
            input: {
              organisationId,
              networkId,
              name
            }
          }
        }),
      onError: addError
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Organisation renamed.');

    push(organisationDetailsUrl);
  };

  return (
    <Page defaultBackUrl={organisationDetailsUrl} title="Edit organisation" width="narrow">
      <QueryHandler
        data={data?.network?.organisations?.nodes?.[0]}
        error={!!error}
        isLoading={loading}
        noResults={<PageNotFound />}
      >
        {(organisation) => {
          const initialValues: EditOrganisationFormValues = {
            name: organisation.name
          };

          return (
            <EditOrganisationForm
              initialValues={initialValues}
              onCancel={onCancel}
              onSubmit={onSubmit}
              onSuccess={handleSuccess}
            />
          );
        }}
      </QueryHandler>
    </Page>
  );
};

export default EditOrganisation;
