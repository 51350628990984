import type { FC } from 'react';
import { useParams } from 'react-router';
import { validate as uuidValidate } from 'uuid';

import Slideout from '@zen/Components/Slideout';
import { ORDERS_PREFIX } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import OrderDetails from '../OrderDetails';

interface Props {
  originPath?: string;
}

const OrderDetailsContainer: FC<Props> = ({ originPath = ORDERS_PREFIX }) => {
  const { id } = useParams<{ id: string }>();
  const { navigateBack } = useNavigationHistory();

  // we need to check whether the id param is a valid uuid
  const isUuid = uuidValidate(id);

  const handleClose = () => navigateBack(originPath);

  return (
    <Slideout onOutsideClick={handleClose} show={!!isUuid}>
      <OrderDetails onClose={handleClose} originPath={originPath} />
    </Slideout>
  );
};

export default OrderDetailsContainer;
