import type { FC } from 'react';

import { FormSelect } from '@zen/Components/Form';
import { prepareLevelOptions } from '@zen/Settings/ProfileDetails/ProfileDetailsForm/helper';

interface Props {
  className?: string;
  name?: string;
}

const FormLevelSelect: FC<Props> = ({ className = '', name = 'level' }) => (
  <FormSelect className={className} isSearchable={false} label="Level" name={name} options={prepareLevelOptions()} />
);

export default FormLevelSelect;
