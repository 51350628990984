import type { ReactNode } from 'react';

import CargoSummary from '@zen/Components/CargoSummary';
import OrdersReferencesList from '@zen/Components/OrdersReferencesList';
import ShipmentReferences from '@zen/Components/ShipmentReferences';
import type { TableColumn } from '@zen/DesignSystem';
import { Checkbox } from '@zen/DesignSystem';
import { consolidationRoutes } from '@zen/Routes';
import { formatDate } from '@zen/utils/dateTime/date/formatting';
import { getNetworkContactLabel } from '@zen/utils/networks';

import { getConsolidationCargoLabel } from '../consolidation.helper';
import type { ConsolidationShipment } from './types';

export const getColumnsConfiguration = (
  onSelect: (zencargoReference: string) => void,
  isShipmentSelected: (zencargoReference: string) => boolean,
  consolidatedShipmentId: string
): TableColumn<ConsolidationShipment>[] => {
  return [
    {
      key: 'addToConsolidation',
      title: '',
      render: (_, { zencargoReference }: ConsolidationShipment): ReactNode => {
        const checkShipment: boolean = isShipmentSelected(zencargoReference);

        return <Checkbox checked={checkShipment} onChange={() => onSelect(zencargoReference)} />;
      },
      sortable: false,
      fixed: 'left'
    },
    {
      key: 'reference',
      title: 'Reference',
      render: (_, { clientReference, zencargoReference }: ConsolidationShipment): ReactNode => {
        return (
          zencargoReference && <ShipmentReferences clientReference={clientReference} zencargoReference={zencargoReference} />
        );
      },
      sortKey: 'zencargoReference',
      fixed: 'left'
    },
    {
      key: 'networksOrigin',
      title: 'Origin',
      render: (_, { networksOrigin }: ConsolidationShipment): ReactNode => {
        return getNetworkContactLabel(networksOrigin, 'long');
      },
      sortable: false,
      ellipsis: true,
      width: '200px'
    },
    {
      key: 'networksDestination',
      title: 'Destination',
      render: (_, { networksDestination }: ConsolidationShipment): ReactNode => {
        return getNetworkContactLabel(networksDestination, 'long');
      },
      sortable: false,
      ellipsis: true,
      width: '200px'
    },
    {
      key: 'latestCollectionEstimate',
      title: 'Collection date',
      className: 'whitespace-nowrap',
      render: (_, { calculatedInfo }: ConsolidationShipment): ReactNode => {
        const latestCollectionEstimate: string = calculatedInfo?.collectionInFull?.dateTimeRange?.endDateTime?.date || '';

        return formatDate(latestCollectionEstimate);
      }
    },
    {
      key: 'purchaseOrders',
      title: 'PO references',
      sortable: false,
      render: (_, { purchaseOrderReferences, zencargoReference }: ConsolidationShipment): ReactNode => (
        <OrdersReferencesList
          detailsUrl={(id: string) =>
            consolidationRoutes.consolidationAddShipmentsOrderDetailsPage.getUrl(consolidatedShipmentId, id, 'items')
          }
          orderReferencesList={purchaseOrderReferences}
          zencargoReference={zencargoReference}
        />
      )
    },
    {
      key: 'cargo',
      title: 'Cargo',
      render: (_, { calculatedInfo, cargo, cargos }: ConsolidationShipment): ReactNode => {
        const showVehicleLabel: boolean = !!cargos?.[0]?.cargoType || !!cargo?.vehicle?.vehicleType;

        return (
          <div>
            {showVehicleLabel && <div className="leading-normal mb-1">{getConsolidationCargoLabel(cargos, cargo)}</div>}
            <CargoSummary summary={calculatedInfo?.cargoSummary} />
          </div>
        );
      },
      sortable: false
    }
  ];
};
