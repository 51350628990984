import { FC, useState } from 'react';

import { Button } from '@zen/DesignSystem';

import BookingRequestApproveModal from '../BookingRequestApproveModal';

interface Props {
  forwarderApproved: boolean;
  zencargoReference: string;
}

const BookingRequestApproveButton: FC<Props> = (props) => {
  const { forwarderApproved, zencargoReference } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleApproveClick = (): void => {
    setIsModalOpen(true);
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button data-testid="approve-button" onClick={handleApproveClick}>
        Approve
      </Button>
      {isModalOpen && (
        <BookingRequestApproveModal
          forwarderApproved={forwarderApproved}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          zencargoReference={zencargoReference}
        />
      )}
    </>
  );
};

export default BookingRequestApproveButton;
