import type { FC, ReactNode } from 'react';

import { FormHiddenInput, FormLabel } from '@zen/Components/Form';
import FormTimeInput from '@zen/Components/Form/FormTimeInput';
import { Button } from '@zen/DesignSystem';

const daysOfTheWeek: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

interface Props {
  onCopy: () => void;
}

const FormBusinessHoursFields: FC<Props> = ({ onCopy }) => {
  const renderFields = (): ReactNode => {
    return daysOfTheWeek.map((dayOfTheWeek: string, index: number) => {
      const firstRow: boolean = index === 0;

      return (
        <div key={dayOfTheWeek}>
          <div className="flex items-center">
            <span className="w-24 capitalize">{dayOfTheWeek}</span>
            <FormTimeInput
              hideErrorMessage={true}
              hideLabel={true}
              name={`businessHours.${dayOfTheWeek}.startTime`}
              noMargin={true}
              placeholder="08:00"
            />
            <span className="px-2">to</span>
            <FormTimeInput
              hideErrorMessage={true}
              hideLabel={true}
              name={`businessHours.${dayOfTheWeek}.endTime`}
              noMargin={true}
              placeholder="17:00"
            />
            {firstRow && (
              <Button className="ml-2" data-testid="copy-to-all" iconLeft="zicon-copy" onClick={onCopy} variant="tertiary">
                Copy to all
              </Button>
            )}
          </div>
          <div className="mb-2">
            <FormHiddenInput name={`businessHours.${dayOfTheWeek}.startTime`} />
            <FormHiddenInput name={`businessHours.${dayOfTheWeek}.endTime`} />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="mb-5" data-testid="form-business-hours-fields">
      <FormLabel label="Opening hours" />
      {renderFields()}
    </div>
  );
};

export default FormBusinessHoursFields;
