import { flatten } from 'lodash';
import type { ReactNode } from 'react';
import { Area, AreaChart as Chart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Color } from '@zen/Styleguide';
import type { Undefinable } from '@zen/utils/typescript';

export interface AreaColors {
  dot: string;
  fill?: string;
  stroke: string;
}

interface Props<T> {
  areaColors?: AreaColors;
  areaDataKey?: string;
  data: T[];
  lineColors?: AreaColors;
  lineDataKey: string;
  onDotClick?: (value: T) => void;
  tooltip: ReactNode;
  xAxisDataKey?: string;
}

const defaultLineColors = {
  stroke: Color.NAVY_BASE,
  dot: Color.NAVY_BASE
};

const defaultAreaColors = {
  stroke: Color.AZURE_BASE,
  dot: Color.AZURE_BASE,
  fill: Color.AZURE_LIGHT
};

const AreaChart = <T extends {}>({
  data,
  lineDataKey,
  areaDataKey,
  lineColors = defaultLineColors,
  areaColors = defaultAreaColors,
  tooltip,
  onDotClick = () => {},
  xAxisDataKey = 'startDate'
}: Props<T>) => {
  const handleClick = (_: any, { payload }: any): void => {
    onDotClick(payload);
  };

  const measureText = (text: string): number => {
    let ctx: Undefinable<CanvasRenderingContext2D>;

    if (!ctx) {
      ctx = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D as CanvasRenderingContext2D;

      ctx.font = '1rem "Lato"';
    }

    return ctx.measureText(text).width;
  };

  const getLeftMargin = () => {
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const dataValues = data.map((item) => [item[areaDataKey], item[lineDataKey]]);
    const maxValue = Math.max(...flatten(dataValues));

    return measureText(`${maxValue}`);
  };

  return (
    <div style={{ width: '100%', height: 275 }}>
      <ResponsiveContainer>
        <Chart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: getLeftMargin(),
            bottom: 25
          }}
        >
          <XAxis
            angle={-45}
            axisLine={true}
            dataKey={xAxisDataKey}
            interval={0}
            stroke={Color.GREY_BASE}
            textAnchor="end"
            tickLine={true}
          />
          <YAxis stroke={Color.GREY_BASE} tickLine={false} />
          <CartesianGrid stroke={Color.GREY_LIGHT} />
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Tooltip content={tooltip} />
          {areaDataKey && (
            <Area
              activeDot={{ onClick: handleClick }}
              baseLine={0}
              baseValue="dataMin"
              dataKey={areaDataKey}
              dot={{ fill: areaColors.dot, fillOpacity: '1', r: 6, strokeWidth: 0 }}
              fill={areaColors.fill}
              fillOpacity="0.3"
              stackId="1"
              stroke={areaColors.stroke}
              strokeOpacity="0.99"
              strokeWidth={5}
              type="linear"
            />
          )}
          <Area
            activeDot={{ onClick: handleClick }}
            baseLine={0}
            baseValue="dataMin"
            dataKey={lineDataKey}
            dot={{ fill: lineColors.dot, stroke: '3', fillOpacity: '1', r: 6 }}
            fillOpacity="0"
            stackId="1"
            stroke={lineColors.stroke}
            strokeWidth={5}
            type="linear"
          />
        </Chart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChart;
