import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { SortingOrder } from '@zen/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';
import { PaginatedQueryResult, usePagination } from '@zen/utils/hooks/pagination';
import useAccount from '@zen/utils/hooks/useAccount';
import type { QueryParams } from '@zen/utils/QueryParams';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

import { prepareFilterVariables } from '../components/Filters/helpers';
import { OrderListQueryResult, OrderListQueryVariables, useOrderListQuery } from '../graphql';
import type { OrderFilters, OrderListViewItem } from '../types';

interface Params {
  availableForBooking?: string;
  availableForCargo?: string;
  filterByUserPreferences?: boolean;
  withLotCountsForCargo?: boolean;
}

const useOrdersListQuery = (
  params: Params = {}
): PaginatedQueryResult<OrderListQueryResult, OrderListQueryVariables, OrderListViewItem> => {
  const { availableForBooking, availableForCargo, filterByUserPreferences, withLotCountsForCargo = false } = params;
  const { accountUuid: accountId } = useAccount();
  const { search } = useLocation();
  const { appliedFilters = {} } = useAppliedFilters<OrderFilters>();
  const { textContains }: QueryParams = parseQueryParams(search);

  useEffect(() => {
    results.refetch();
  }, [filterByUserPreferences]); // eslint-disable-line react-hooks/exhaustive-deps

  const results = usePagination<OrderListQueryResult, OrderListQueryVariables, OrderListViewItem>(
    useOrderListQuery,
    'purchaseOrders',
    {
      ...prepareFilterVariables(appliedFilters),
      ...(accountId && { customerUuid: accountId }),
      availableForBooking,
      availableForCargo,
      textContains: textContains ? textContains.toString() : '',
      order: {
        field: 'createdAt',
        direction: SortingOrder.DESC
      },
      withLotCountsForCargo
    },
    20,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );

  return results;
};

export default useOrdersListQuery;
