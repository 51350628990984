import type { ReactNode, ReactText } from 'react';
import { Link } from 'react-router-dom';

import { checkPermission } from '@zen/Auth/authHelper';
import DateWithDefault from '@zen/Components/DateWithDefault';
import InformationIcon from '@zen/Components/InformationIcon';
import type { ReferenceListItem } from '@zen/Components/ReferencesList';
import ReferencesList from '@zen/Components/ReferencesList';
import TableLink from '@zen/Components/TableLink';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { ViewMode } from '@zen/Components/ViewSwitcher';
import type { TableColumn } from '@zen/DesignSystem';
import { Pill, PillType } from '@zen/DesignSystem';
import IssueCounterPill from '@zen/Issues/components/IssueCounterPill';
import type { OrderTableView } from '@zen/Orders/types';
import { orderRoutes } from '@zen/Routes';
import { ModeOfTransport } from '@zen/Shipments/types';
import { formatDate } from '@zen/utils/dateTime';
import { formatNumber } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

import LotsBooked from '../LotsBooked';
import OrdersContextMenu from '../OrdersContextMenu';
import { getDaysDeltaForPill, getDaysDeltaLabel } from './helpers';
import OrderStageCell from './OrderStageCell';
import type { OrderListCapabilities } from './types';

interface ColumnsProps {
  capabilities?: OrderListCapabilities;
}
const { orderDetails } = orderRoutes;

const transportModeLabelMapping: Record<ModeOfTransport, string> = {
  [ModeOfTransport.AIR]: 'Air',
  [ModeOfTransport.OCEAN]: 'Sea',
  [ModeOfTransport.RAIL]: 'Rail',
  [ModeOfTransport.TRUCK]: 'Truck'
};

const renderDate = (date: Optional<string>) => <DateWithDefault date={date} />;

const renderCbm = (cbm: Optional<number>): string => {
  const hasValue: boolean = !!cbm && cbm > 0;

  return hasValue ? `${formatNumber(cbm as number)} CBM` : '-';
};

const orderColumn: TableColumn<OrderTableView> = {
  key: 'orderReferenceNumber',
  title: 'PO number',
  ellipsis: true,
  width: '200px',
  render: (_, { orderReferenceNumber, id }: OrderTableView): ReactNode => {
    return <TableLink linkTo={orderDetails.getUrl(id)}>{orderReferenceNumber}</TableLink>;
  },
  sortable: true
};

const cbmOrderedColumn: TableColumn<OrderTableView> = {
  key: 'orderedCbm',
  title: 'CBM: Ordered',
  render: (_, { orderedCbm }: OrderTableView) => renderCbm(orderedCbm),
  sortable: false
};

const cbmUpdatedColumn: TableColumn<OrderTableView> = {
  key: 'fulfilledCbm',
  title: 'CBM: Updated',
  render: (_, { fulfilledCbm }: OrderTableView) => renderCbm(fulfilledCbm),
  sortable: false
};

const cbmBookedColumn: TableColumn<OrderTableView> = {
  key: 'bookedCbm',
  title: 'CBM: Booked',
  render: (_, { bookedCbm }: OrderTableView) => renderCbm(bookedCbm),
  sortable: false
};

const getShipmentsColumn = (): TableColumn<OrderTableView> => ({
  key: 'bookings',
  title: 'Shipment(s)',
  render: (_, { bookings }: OrderTableView): ReactNode => {
    const zencargoReferences: ReferenceListItem[] = bookings
      ? bookings.map(({ zencargoReference }) => {
          const url: string = orderRoutes.orderDetailsPage.getUrl(zencargoReference);

          return {
            referenceLabel: zencargoReference,
            url
          };
        })
      : [];

    return <ReferencesList hasLimitation={false} referencesList={zencargoReferences} />;
  },
  sortable: false
});

const manufacturerColumn: TableColumn<OrderTableView> = {
  key: 'manufacturer',
  title: 'Manufacturer',
  render: (_, { manufacturer }: OrderTableView): ReactNode => {
    return <ValueWithDefault value={manufacturer?.label?.short} />;
  },
  sortable: false
};

const customerColumn: TableColumn<OrderTableView> = {
  key: 'customer',
  title: 'Customer',
  render: (_, { customer }: OrderTableView): ReactNode => {
    return <ValueWithDefault value={customer?.name} />;
  },
  sortable: false
};

const destinationColumn: TableColumn<OrderTableView> = {
  key: 'destination',
  title: 'Destination',
  render: (_, { destination }: OrderTableView): ReactNode => {
    return <ValueWithDefault value={destination?.label?.long} />;
  },
  sortable: false
};

const modeOfTransportColumn: TableColumn<OrderTableView> = {
  key: 'modeOfTransport',
  title: 'Planned mode of transport',
  render: (_, { modeOfTransport }: OrderTableView): ReactNode => {
    return (
      <ValueWithDefault
        formatValue={(value: ReactText) => transportModeLabelMapping[value as ModeOfTransport]}
        value={modeOfTransport}
      />
    );
  },
  sortable: false
};

const crdColumn: TableColumn<OrderTableView> = {
  key: 'cargoReadyDateEstimate',
  sortKey: 'cargoReadyDate',
  title: 'Cargo ready date',
  render: (_, { cargoReadyDateEstimate }: OrderTableView): ReactNode => {
    const date: Optional<string> = cargoReadyDateEstimate?.date;
    const delayInDays: Optional<number> = cargoReadyDateEstimate?.delayInDays;
    const hasDaysDifference: boolean = delayInDays !== null && delayInDays !== undefined;

    return (
      <>
        <p>{renderDate(date)}</p>
        {hasDaysDifference && getDaysDeltaLabel(delayInDays as number)}
      </>
    );
  }
};

const bookingColumn: TableColumn<OrderTableView> = {
  key: 'booking',
  title: 'Booking',
  render: (nonExistent, order: OrderTableView): ReactNode => {
    return <LotsBooked bookedLotCount={order.lotsBookedCount} lotCount={order.lotsTotalCount} />;
  },
  sortable: false
};

const issuesColumn: TableColumn<OrderTableView> = {
  key: 'issues',
  title: 'Issues',
  render: (_, { issues, id }: OrderTableView) => {
    const orderDetailsPath: string = orderRoutes.orderIssues.getUrl(id);

    return (
      issues && (
        <Link to={orderDetailsPath}>
          <IssueCounterPill issues={issues} />
        </Link>
      )
    );
  },
  sortable: false
};

const requiredDeliveryColumn: TableColumn<OrderTableView> = {
  key: 'requiredDeliveryDate',
  title: (
    <div className="flex items-center">
      RDD <InformationIcon className="ml-2 text-grey-base" tooltipContent="Required delivery date (RDD)" />
    </div>
  ),
  render: (_, { requiredDeliveryDate }: OrderTableView): ReactNode => {
    return <span>{requiredDeliveryDate ? formatDate(requiredDeliveryDate) : '-'}</span>;
  },
  sortable: true
};

const deliveryDelayColumn: TableColumn<OrderTableView> = {
  key: 'deliveryDelay',
  dataKey: 'deliveryEstimate',
  title: 'Delivery delay',
  render: (_, { deliveryEstimate }: OrderTableView): ReactNode => {
    const delayInDays: Optional<number> = deliveryEstimate?.delayInDays;
    const hasDaysDifference: boolean = delayInDays !== null && delayInDays !== undefined;

    const getDaysDeltaPill = (): ReactNode => {
      const pillType: PillType = delayInDays !== 0 ? 'problem' : 'default';

      return <Pill type={pillType}>{getDaysDeltaForPill(delayInDays as number)}</Pill>;
    };

    return <span>{hasDaysDifference ? getDaysDeltaPill() : '-'}</span>;
  },
  sortable: false
};

const estimatedDeliveryColumn: TableColumn<OrderTableView> = {
  key: 'deliveryEstimate',
  title: 'Estimated delivery',
  render: (_, { deliveryEstimate }: OrderTableView): ReactNode => renderDate(deliveryEstimate?.date),
  sortable: false
};

const lastUpdatedColumn: TableColumn<OrderTableView> = {
  key: 'cargoReadyDateUpdatedAt',
  dataKey: 'cargoReadyDateEstimate',
  title: 'Ready date last updated',
  sortable: true,
  render: (_, { cargoReadyDateEstimate }: OrderTableView): ReactNode => renderDate(cargoReadyDateEstimate?.lastUpdatedAt)
};

const stageColumn: TableColumn<OrderTableView> = {
  key: 'lotsByStageCount',
  title: 'Stage',
  render: (_, { lotsByStageCount, lotsTotalCount }: OrderTableView): ReactNode => {
    if (lotsByStageCount) {
      return <OrderStageCell lotsByStage={lotsByStageCount} lotsTotalCount={lotsTotalCount} />;
    }
  },
  sortable: false
};

const actionsColumn: TableColumn<OrderTableView> = {
  key: 'actions',
  title: 'Actions',
  render: (nonExistent, order: OrderTableView): ReactNode => {
    const canRemove: boolean = checkPermission<OrderTableView>(order, 'canRemove');
    const canUpdate: boolean = checkPermission<OrderTableView>(order, 'canUpdate');
    const canClose: boolean = checkPermission<OrderTableView>(order, 'canClose');

    const { id, orderReferenceNumber } = order;

    return (
      <OrdersContextMenu
        canClose={canClose}
        canRemove={canRemove}
        canUpdate={canUpdate}
        id={id}
        mode={ViewMode.TABLE}
        orderReferenceNumber={orderReferenceNumber || ''}
      />
    );
  },
  sortable: false
};

export const getColumns = ({ capabilities }: ColumnsProps): TableColumn<OrderTableView>[] => {
  const showActionsColumn: boolean = !!capabilities?.canPerformOrderActions;

  return [
    { ...orderColumn, fixed: 'left' },
    stageColumn,
    getShipmentsColumn(),
    cbmOrderedColumn,
    cbmUpdatedColumn,
    cbmBookedColumn,
    manufacturerColumn,
    ...(capabilities?.canViewCustomer ? [customerColumn] : []),
    destinationColumn,
    modeOfTransportColumn,
    requiredDeliveryColumn,
    deliveryDelayColumn,
    estimatedDeliveryColumn,
    lastUpdatedColumn,
    { ...crdColumn, fixed: 'right' },
    { ...bookingColumn, fixed: 'right' },
    { ...issuesColumn, fixed: 'right' },
    ...(showActionsColumn ? [{ ...actionsColumn, fixed: 'right' as const }] : [])
  ];
};
