import type { FC } from 'react';

import FormTextarea from '@zen/Components/Form/FormTextarea';

import FormShipmentDelayReasonSelect from '../FormShipmentDelayReasonSelect';

interface Props {}

const FormMilestoneDelayReasonFields: FC<Props> = () => {
  return (
    <>
      <FormShipmentDelayReasonSelect label="Delay reason" name="reason" />
      <FormTextarea label="Additional information" name="description" />
    </>
  );
};

export default FormMilestoneDelayReasonFields;
