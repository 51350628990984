import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetBookingManagementSettingsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetBookingManagementSettingsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              bookingManagementSettings?: GraphQLTypes.Maybe<
                { __typename?: 'BookingManagementSettings' } & Pick<GraphQLTypes.BookingManagementSettings, 'pricingRequired'>
              >;
              bookingRequest?: GraphQLTypes.Maybe<
                { __typename?: 'BookingRequest' } & Pick<GraphQLTypes.BookingRequest, 'forwarderApproved' | 'id' | 'rejected'> & {
                    canUpdateForwarderApproved: { __typename?: 'AuthorizationResult' } & Pick<
                      GraphQLTypes.AuthorizationResult,
                      'value'
                    >;
                  }
              >;
              canViewForwarderApproved: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdatePricingRequired: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              bookingStage?: GraphQLTypes.Maybe<{ __typename?: 'BookingStage' } & Pick<GraphQLTypes.BookingStage, 'name'>>;
            }
        >
      >
    >;
  };
};

export const GetBookingManagementSettingsDocument = /* #__PURE__ */ gql`
  query getBookingManagementSettings($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        bookingManagementSettings {
          pricingRequired
        }
        bookingRequest {
          canUpdateForwarderApproved {
            value
          }
          forwarderApproved
          id
          rejected
        }
        canViewForwarderApproved {
          value
        }
        canUpdatePricingRequired {
          value
        }
        bookingStage {
          name
        }
      }
    }
  }
`;

/**
 * __useGetBookingManagementSettingsQuery__
 *
 * To run a query within a React component, call `useGetBookingManagementSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingManagementSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingManagementSettingsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetBookingManagementSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBookingManagementSettingsQueryResult, GetBookingManagementSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetBookingManagementSettingsQueryResult, GetBookingManagementSettingsQueryVariables>(
    GetBookingManagementSettingsDocument,
    options
  );
}
export function useGetBookingManagementSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookingManagementSettingsQueryResult, GetBookingManagementSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetBookingManagementSettingsQueryResult, GetBookingManagementSettingsQueryVariables>(
    GetBookingManagementSettingsDocument,
    options
  );
}
export type GetBookingManagementSettingsQueryHookResult = ReturnType<typeof useGetBookingManagementSettingsQuery>;
export type GetBookingManagementSettingsLazyQueryHookResult = ReturnType<typeof useGetBookingManagementSettingsLazyQuery>;
