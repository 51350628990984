import type { FC, ReactNode } from 'react';

import PageWithTabs, { TabConfig } from '@zen/Components/PageWithTabs';
import { getActiveIssues } from '@zen/Issues/issueHelpers';

import Details from '../Details';
import LineItems from '../LineItems';
import OrderActivityFeed from '../OrderActivityFeed';
import OrderIssues from '../OrderIssues';
import type { OrderDetailsType } from '../types';

interface Props {
  header: ReactNode;
  orderDetails: OrderDetailsType;
}

const OrderDetailsTabs: FC<Props> = ({ header, orderDetails }) => {
  const { id: orderId, issues, orderDate } = orderDetails;
  const numberOfActiveIssues: number = issues ? getActiveIssues(issues).length : 0;

  const config: TabConfig[] = [
    {
      label: 'Activity',
      path: 'activity',
      component: <OrderActivityFeed orderId={orderId} />
    },
    {
      label: 'Line items',
      path: 'items',
      component: <LineItems orderDate={orderDate} orderId={orderId} />
    },
    {
      label: 'Other details',
      path: 'details',
      component: <Details orderId={orderId} />
    },
    {
      label: 'Issues',
      totalCount: numberOfActiveIssues,
      path: 'issues',
      component: <OrderIssues orderId={orderId} />
    }
  ];

  return <PageWithTabs contentClassName="p-10 flex-1 overflow-auto" header={header} headerMargin="" tabsConfig={config} />;
};

export default OrderDetailsTabs;
