import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateContainerCargoItemMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateContainerCargoItemInput;
}>;

export type UpdateContainerCargoItemMutationResult = { __typename?: 'Mutation' } & {
  cargoUpdateContainerCargoItem?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateContainerCargoItemPayload' } & {
      cargoItem?: GraphQLTypes.Maybe<
        | ({ __typename?: 'CargoContainerType' } & Pick<
            GraphQLTypes.CargoContainerType,
            | 'containerType'
            | 'containerNumber'
            | 'containerSealNumber'
            | 'overweight'
            | 'id'
            | 'type'
            | 'cbm'
            | 'hazardous'
            | 'reefer'
            | 'looseCargoType'
            | 'quantity'
          > & {
              grossWeight?: GraphQLTypes.Maybe<
                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
              >;
            })
        | ({ __typename?: 'CargoLooseCargoType' } & Pick<
            GraphQLTypes.CargoLooseCargoType,
            'id' | 'type' | 'cbm' | 'hazardous' | 'reefer' | 'looseCargoType' | 'quantity'
          > & {
              grossWeight?: GraphQLTypes.Maybe<
                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
              >;
            })
        | ({ __typename?: 'CargoVehicleType' } & Pick<
            GraphQLTypes.CargoVehicleType,
            'id' | 'type' | 'cbm' | 'hazardous' | 'reefer' | 'looseCargoType' | 'quantity'
          > & {
              grossWeight?: GraphQLTypes.Maybe<
                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
              >;
            })
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateContainerCargoItemDocument = /* #__PURE__ */ gql`
  mutation updateContainerCargoItem($input: UpdateContainerCargoItemInput!) {
    cargoUpdateContainerCargoItem(input: $input) {
      cargoItem {
        id
        type
        cbm
        hazardous
        reefer
        looseCargoType
        quantity
        grossWeight {
          metric
          value
        }
        ... on CargoContainerType {
          containerType
          containerNumber
          containerSealNumber
          overweight
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateContainerCargoItemMutation__
 *
 * To run a mutation, you first call `useUpdateContainerCargoItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContainerCargoItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContainerCargoItemMutation, { data, loading, error }] = useUpdateContainerCargoItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContainerCargoItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContainerCargoItemMutationResult, UpdateContainerCargoItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateContainerCargoItemMutationResult, UpdateContainerCargoItemMutationVariables>(
    UpdateContainerCargoItemDocument,
    options
  );
}
export type UpdateContainerCargoItemMutationHookResult = ReturnType<typeof useUpdateContainerCargoItemMutation>;
