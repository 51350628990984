import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderListFiltersQueryVariables = GraphQLTypes.Exact<{
  accountUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type OrderListFiltersQueryResult = { __typename?: 'Query' } & {
  filters: { __typename?: 'FiltersFiltersType' } & {
    purchaseOrders?: GraphQLTypes.Maybe<
      { __typename?: 'PurchaseOrdersFilter' } & Pick<GraphQLTypes.PurchaseOrdersFilter, 'issueTitles'> & {
          canViewCustomerFilter: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
          customers: Array<{ __typename?: 'FiltersCustomerType' } & Pick<GraphQLTypes.FiltersCustomerType, 'name' | 'uuid'>>;
          portsOfLoad?: GraphQLTypes.Maybe<
            Array<{ __typename?: 'FiltersTerminalType' } & Pick<GraphQLTypes.FiltersTerminalType, 'label' | 'unlocode'>>
          >;
          portsOfDestination?: GraphQLTypes.Maybe<
            Array<{ __typename?: 'FiltersTerminalType' } & Pick<GraphQLTypes.FiltersTerminalType, 'label' | 'unlocode'>>
          >;
        }
    >;
  };
};

export const OrderListFiltersQueryDocument = /* #__PURE__ */ gql`
  query orderListFiltersQuery($accountUuid: String) {
    filters(accountUuid: $accountUuid) {
      purchaseOrders {
        canViewCustomerFilter {
          value
        }
        customers {
          name
          uuid
        }
        issueTitles
        portsOfLoad: terminals(portType: POL) {
          label
          unlocode
        }
        portsOfDestination: terminals(portType: POD) {
          label
          unlocode
        }
      }
    }
  }
`;

/**
 * __useOrderListFiltersQuery__
 *
 * To run a query within a React component, call `useOrderListFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderListFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderListFiltersQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *   },
 * });
 */
export function useOrderListFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderListFiltersQueryResult, OrderListFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderListFiltersQueryResult, OrderListFiltersQueryVariables>(OrderListFiltersQueryDocument, options);
}
export function useOrderListFiltersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderListFiltersQueryResult, OrderListFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderListFiltersQueryResult, OrderListFiltersQueryVariables>(OrderListFiltersQueryDocument, options);
}
export type OrderListFiltersQueryHookResult = ReturnType<typeof useOrderListFiltersQuery>;
export type OrderListFiltersQueryLazyQueryHookResult = ReturnType<typeof useOrderListFiltersQueryLazyQuery>;
