import { createContext, FC, ReactNode } from 'react';

import { useColumnConfiguration } from '@zen/DesignSystem';
import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

interface TableConfiguration {
  hiddenColumns: string[];
  setHiddenColumns: (hiddenColumns: string[]) => void;
  tableId: string;
}

const TableConfigurationContext = createContext<TableConfiguration>({
  hiddenColumns: [],
  setHiddenColumns: () => {},
  tableId: ''
});

interface Props {
  children: ReactNode;
  initialHiddenColumns?: string[];
  tableId: string;
}
export const TableConfigurationProvider: FC<Props> = ({ children, tableId, initialHiddenColumns = [] }) => {
  const { hiddenColumns, setHiddenColumns } = useColumnConfiguration(tableId, initialHiddenColumns);

  return (
    <TableConfigurationContext.Provider
      value={{
        hiddenColumns,
        setHiddenColumns,
        tableId
      }}
    >
      {children}
    </TableConfigurationContext.Provider>
  );
};

export default TableConfigurationContext;

export const useTableConfiguration = (): TableConfiguration => {
  return useContextOrThrowError<TableConfiguration>(
    TableConfigurationContext,
    'useTableConfiguration must be used inside TableConfigurationProvider'
  );
};
