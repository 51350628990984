import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTrackingDataQueryVariables = GraphQLTypes.Exact<{
  newCargoService: GraphQLTypes.Scalars['Boolean'];
  zencargoReferences?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
}>;

export type GetTrackingDataQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              incoterms?: GraphQLTypes.Maybe<{ __typename?: 'Incoterms' } & Pick<GraphQLTypes.Incoterms, 'value'>>;
              canSetShippingOrderNotRequired: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              canToggleShippingOrderConfirmed: { __typename?: 'AuthorizationResult' } & Pick<
                GraphQLTypes.AuthorizationResult,
                'value'
              >;
              stage?: GraphQLTypes.Maybe<{ __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'value'>>;
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & {
                  cargoItems?: GraphQLTypes.Maybe<
                    Array<
                      | ({ __typename?: 'CargoContainerType' } & Pick<
                          GraphQLTypes.CargoContainerType,
                          'containerType' | 'containerNumber' | 'id' | 'type'
                        >)
                      | ({ __typename?: 'CargoLooseCargoType' } & Pick<GraphQLTypes.CargoLooseCargoType, 'id' | 'type'>)
                      | ({ __typename?: 'CargoVehicleType' } & Pick<GraphQLTypes.CargoVehicleType, 'id' | 'type'>)
                    >
                  >;
                  tracking?: GraphQLTypes.Maybe<
                    { __typename?: 'Tracking' } & Pick<
                      GraphQLTypes.Tracking,
                      | 'subscriptionStatus'
                      | 'trackingType'
                      | 'scacCode'
                      | 'billOfLading'
                      | 'carrierBookingReference'
                      | 'shippingOrderState'
                      | 'shippingOrderConfirmedOn'
                      | 'shippingOrderReleasedOn'
                    >
                  >;
                }
              >;
              cargos?: GraphQLTypes.Maybe<
                Array<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'cargoType' | 'containerNumber' | 'id'>>
              >;
            }
        >
      >
    >;
  };
};

export const GetTrackingDataDocument = /* #__PURE__ */ gql`
  query getTrackingData($newCargoService: Boolean!, $zencargoReferences: [String!]) {
    bookings(zencargoReferences: $zencargoReferences) {
      nodes {
        zencargoReference
        incoterms {
          value
        }
        canSetShippingOrderNotRequired {
          value
        }
        canToggleShippingOrderConfirmed {
          value
        }
        stage {
          value
        }
        cargo {
          cargoItems {
            id
            type
            ... on CargoContainerType {
              containerType
              containerNumber
            }
          }
          tracking {
            subscriptionStatus
            trackingType
            scacCode
            billOfLading
            carrierBookingReference
            shippingOrderState
            shippingOrderConfirmedOn
            shippingOrderReleasedOn
          }
        }
        cargos @include(if: $newCargoService) {
          cargoType
          containerNumber
          id
        }
      }
    }
  }
`;

/**
 * __useGetTrackingDataQuery__
 *
 * To run a query within a React component, call `useGetTrackingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrackingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrackingDataQuery({
 *   variables: {
 *      newCargoService: // value for 'newCargoService'
 *      zencargoReferences: // value for 'zencargoReferences'
 *   },
 * });
 */
export function useGetTrackingDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetTrackingDataQueryResult, GetTrackingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTrackingDataQueryResult, GetTrackingDataQueryVariables>(GetTrackingDataDocument, options);
}
export function useGetTrackingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTrackingDataQueryResult, GetTrackingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTrackingDataQueryResult, GetTrackingDataQueryVariables>(GetTrackingDataDocument, options);
}
export type GetTrackingDataQueryHookResult = ReturnType<typeof useGetTrackingDataQuery>;
export type GetTrackingDataLazyQueryHookResult = ReturnType<typeof useGetTrackingDataLazyQuery>;
