import type { FC } from 'react';

import { Pill, PillType } from '@zen/DesignSystem';

import { InvitationStatus } from '../types';

type PillMapping = {
  [key in InvitationStatus]: {
    label: string;
    status: PillType;
  };
};

interface Props {
  invitationStatus: InvitationStatus;
}

const InvitationStatusPill: FC<Props> = ({ invitationStatus }) => {
  const pillType: PillMapping = {
    [InvitationStatus.NOT_SENT]: { status: 'problem', label: 'Offline' },
    [InvitationStatus.PENDING]: { status: 'pending', label: 'Pending' },
    [InvitationStatus.ACCEPTED]: { status: 'default', label: 'Active' }
  };

  const { status, label } = pillType[invitationStatus];

  return <Pill type={status}>{label}</Pill>;
};

export default InvitationStatusPill;
