import type { FC } from 'react';

import NoResultsComponent, { ButtonConfig } from '@zen/Components/NoResults';

interface Props {
  onButtonClick: () => void;
}

const NoResults: FC<Props> = ({ onButtonClick }) => {
  const noResultButton: ButtonConfig = {
    label: 'Adjust filters',
    onClick: onButtonClick
  };

  return (
    <NoResultsComponent
      button={noResultButton}
      headline="We can't find any results"
      tagline="There are no results for your query, try adjusting your search or filter criteria."
    />
  );
};

export default NoResults;
