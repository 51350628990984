/* eslint-disable react/jsx-no-useless-fragment */
import { isEmpty, isObject } from 'lodash';
import type { ReactElement, ReactNode } from 'react';

import { Loading } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import NoResults from '../NoResults';

interface Props<T> {
  children: (result: T) => ReactElement;
  data: Optional<T>;
  error: boolean;
  errorComponent?: ReactNode;
  isLoading: boolean;
  loadingComponent?: ReactNode;
  noResults?: ReactNode;
}

const defaultErrorComponent = <NoResults headline="Something went wrong." tagline="Please try again later" />;
const defaultNoResult = <NoResults headline="No results found." tagline="Please change your search query and try again" />;

const QueryHandler = <T extends {}>({
  children,
  error,
  data,
  isLoading,
  errorComponent = defaultErrorComponent,
  noResults = defaultNoResult,
  loadingComponent = <Loading />
}: Props<T>) => {
  const hasData: boolean = Array.isArray(data) || isObject(data) ? !isEmpty(data) : !!data;

  // the hasData check needed to return the cache first while making network request
  if (!hasData && isLoading) {
    return <>{loadingComponent}</>;
  }

  if (error) {
    return <>{errorComponent}</>;
  }

  if (!hasData) {
    return <>{noResults}</>;
  }

  return children(data!);
};

export default QueryHandler;
