import type { FC } from 'react';

import type { Option } from '@zen/DesignSystem';
import useCustomers, { Customer } from '@zen/utils/hooks/useCustomers';
import type { Nullable } from '@zen/utils/typescript';

import { FormSelect } from '..';

interface Props {
  className?: string;
  isDisabled?: boolean;
  label?: string;
  name: string;
  onSelect?: (customerId: Nullable<string>) => void;
}

const prepareCustomersOptions = (customersList: Customer[]): Option<string>[] =>
  customersList.map((customer: Customer): Option<string> => {
    const { division, name, uuid } = customer;
    const divisionName = division ? ` - ${division?.name}` : '';

    return {
      label: name + divisionName,
      value: uuid || ''
    };
  });

const CustomerSelectFormInput: FC<Props> = ({ className, isDisabled = false, name, label = '', onSelect = () => {} }) => {
  const { data: customers } = useCustomers();

  const customerOptions = customers ? prepareCustomersOptions(customers) : [];

  return (
    <div className={className}>
      <FormSelect
        hideLabel={!label}
        isDisabled={isDisabled}
        label={label}
        name={name}
        onChange={onSelect}
        options={customerOptions}
      />
    </div>
  );
};

export { prepareCustomersOptions };
export default CustomerSelectFormInput;
