import { FC, ReactChild, ReactNode, useState } from 'react';

import type { CreateContainerCargoItemInput } from '@zen/Cargo';
import CargoSelector from '@zen/Cargo/components/CargoSelector';
import FullContainerForm from '@zen/Cargo/forms/FullContainerForm';
import { useCreateContainerCargoItemMutation } from '@zen/Cargo/graphql';
import type { FormInstance } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { ContainerTypeEnum } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { legacyNewTypesMapping } from '../cargoDictionaryMapping.helper';
import CargoFormButtons from '../components/CargoFormButtons';
import CargoFormWrapper from '../components/CargoFormWrapper';
import { prepareContainerCargoRequestPayload } from '../helpers';
import type { FullContainerFormData } from '../types';
import { CargoItemTypeEnum, CargoManagementTrackingAction, CargoManagementTrackingCategory } from '../types';
import { commonCargoOptions, otherCargoOptions } from './helpers';

export interface Props {
  onSuccess: () => void;
  zencargoReference: string;
}

const NewFullContainer: FC<Props> = ({ onSuccess, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const { trackEvent } = useTracking();
  const [selectedCargoType, setSelectedCargoType] = useState<Nullable<ContainerTypeEnum>>();
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [createContainer] = useCreateContainerCargoItemMutation();

  const handleSubmit = async (values: FullContainerFormData): Promise<IOkOrErrorResult> => {
    const input: CreateContainerCargoItemInput = {
      zencargoReference,
      ...prepareContainerCargoRequestPayload(values)
    };

    return performFormMutation({
      mutationFn: () =>
        createContainer({
          variables: {
            input
          },
          refetchQueries: ['cargoSummary', 'voyageMilestones', 'getRouteProgressDetails', 'getTrackingData']
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (): void => {
    addSuccess('New container has been created');
    setInitialState();
    onSuccess();

    trackEvent({
      category: CargoManagementTrackingCategory,
      action: CargoManagementTrackingAction.ADD_NEW_CARGO_ITEM,
      label: CargoItemTypeEnum.CONTAINER
    });
  };

  const handleCargoTypeSelect = (cargoType: ContainerTypeEnum): void => {
    setSelectedCargoType(cargoType);
  };

  const handleButtonClick = (): void => {
    setIsFormVisible(true);
  };

  const setInitialState = (): void => {
    setIsFormVisible(false);
    setSelectedCargoType(null);
  };

  const renderButton = (): ReactChild => {
    return (
      <div className="flex mb-4">
        <Button className="inline-block" iconLeft="zicon-add" onClick={handleButtonClick} variant="secondary">
          Add container
        </Button>
      </div>
    );
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<FullContainerFormData>): ReactNode => {
    return <CargoFormButtons isSubmitting={isSubmitting} onCancel={setInitialState} submitButtonText="Add" />;
  };

  const renderForm = (): ReactChild => {
    if (!selectedCargoType) {
      return (
        <CargoSelector<ContainerTypeEnum>
          commonCargoTypes={commonCargoOptions}
          onCancel={setInitialState}
          onSelect={handleCargoTypeSelect}
          otherCargoLabel="Other containers"
          otherCargoOptions={otherCargoOptions}
        />
      );
    }

    return (
      <CargoFormWrapper>
        <FullContainerForm
          formButtons={renderFormButtons}
          initialValues={{ containerType: legacyNewTypesMapping[selectedCargoType] }}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
        />
      </CargoFormWrapper>
    );
  };

  return (
    <div className="mt-3" data-testid="new-full-container">
      {isFormVisible ? renderForm() : renderButton()}
    </div>
  );
};

export default NewFullContainer;
