import type { FC } from 'react';

import CurrencyInput from '@zen/Components/CurrencyInput';
import type { Currency, Money } from '@zen/types';

import FormField, { FormFieldProps } from '../FormField';
import type { FormInstance } from '../types';

interface Props extends FormFieldProps {
  availableCurrencies?: Currency[];
  onChange?: (value: Money) => void;
  value?: Money;
}

const FormCurrencyInput: FC<Props> = (props) => (
  <FormField {...props}>
    {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type. */}
    {(field, form: FormInstance<unknown>) => (
      <CurrencyInput
        {...field}
        onChange={(value: Money) => {
          if (props.onChange) {
            return props.onChange(value);
          }

          form.setFieldValue(field.name, value);
        }}
      />
    )}
  </FormField>
);

export default FormCurrencyInput;
