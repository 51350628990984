import * as Yup from 'yup';

import { isValidTime } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

const startTimeValidator = Yup.string()
  .nullable()
  .test('time validation', function (startTime: Optional<string>) {
    if (this.parent.endTime && !startTime) {
      return this.createError({ message: 'Please enter start time.' });
    }

    if (startTime && !isValidTime(startTime)) {
      return this.createError({ message: 'Please enter a valid start time.' });
    }

    return true;
  });

const endTimeValidator = Yup.string()
  .nullable()
  .test('time validation', function (endTime: Optional<string>) {
    if (this.parent.startTime && !endTime) {
      return this.createError({ message: 'Please enter end time.' });
    }

    if (this.parent.startTime && endTime && !isValidTime(endTime)) {
      return this.createError({ message: 'Please enter a valid end time.' });
    }

    if (endTime && this.parent.startTime > endTime) {
      return this.createError({ message: 'End time can not be earlier than start time.' });
    }

    return true;
  });

const timeRangeValidator = Yup.object().shape({
  startTime: startTimeValidator,
  endTime: endTimeValidator
});

export const contactValidation = {
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  email: Yup.string().email('Please enter valid email address').required('Email is required')
};

export const addressValidation = {
  locationAddress: Yup.string()
    .nullable()
    .when('addressFieldsVisible', {
      is: (addressFieldsVisible: boolean) => !addressFieldsVisible,
      then: Yup.string().nullable().required('Location address is required')
    }),
  businessHours: Yup.object().shape({
    monday: timeRangeValidator,
    tuesday: timeRangeValidator,
    wednesday: timeRangeValidator,
    thursday: timeRangeValidator,
    friday: timeRangeValidator,
    saturday: timeRangeValidator,
    sunday: timeRangeValidator
  }),
  countryCode: Yup.string().nullable().required('Country is required'),
  street: Yup.string().trim().nullable().required('Street is required'),
  lat: Yup.number().nullable().required('Latitude is required'),
  lng: Yup.number().nullable().required('Longitude is required')
};

export const organisationFormValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Organisation name is required'),
    type: Yup.string().required('Organisation type is required').nullable(),
    ...addressValidation
  });

export const locationFormValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Location name is required'),
    ...addressValidation
  });

export const editLocationValidation = Yup.object().shape({
  ...addressValidation
});

export const contactFormValidation = Yup.object().shape({
  ...contactValidation,
  contactLocationAssignments: Yup.array().of(
    Yup.object().shape({
      locationId: Yup.string().required('Location is required.')
    })
  )
});
