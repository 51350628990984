import moment from 'moment';
import { FC, useState } from 'react';

import CloseIcon from '@zen/Components/Icons/CloseIcon';
import Slideout from '@zen/Components/Slideout';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation, performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import CargoReadyDateForm from '../components/CargoReadyDateForm';
import type { RichDateInput } from '../components/CargoReadyDateForm/types';
import DeliveryDateForm from '../components/DeliveryDateForm';
import { usePurchaseOrdersUpdateLotEstimatesMutation } from '../graphql';

interface Props {
  estimates: {
    cargoReadyDate?: string;
    deliveryDate?: string;
  };
  isVisible: boolean;
  lotIds: string[];
  onClose: () => void;
  onSuccess: () => void;
  orderDate: Optional<string>;
}

enum EstimateStep {
  CARGO_READY_DATE = 1,
  DELIVERY_DATE = 2
}

const isCargoReadyStep = (step: EstimateStep) => step === EstimateStep.CARGO_READY_DATE;

const LotEstimatesSlideout: FC<Props> = ({ estimates, isVisible, lotIds, onClose, onSuccess, orderDate }) => {
  const [step, setStep] = useState<EstimateStep>(EstimateStep.CARGO_READY_DATE);
  const [cargoReadyDate, setCargoReadyDate] = useState<RichDateInput | undefined>();
  const [updateEstimates] = usePurchaseOrdersUpdateLotEstimatesMutation();
  const { addSuccess, addError } = useNotification();

  const handleCargoReadyDateSubmit = (values: RichDateInput) => {
    setCargoReadyDate(values);
    setStep(EstimateStep.DELIVERY_DATE);

    return Promise.resolve({ ok: true, error: null });
  };

  const handleSubmit = async (deliveryEstimate: RichDateInput): Promise<IOkOrErrorResult> => {
    if (!cargoReadyDate) {
      // this should never happen according to the form validation but need a return value
      return { ok: null, error: true };
    }

    return performFormMutation({
      mutationFn: () => updateEstimates({ variables: { input: { lotIds, cargoReadyDate, deliveryEstimate } } }),
      onError: addError
    });
  };

  const handleSubmitSuccess = (): void => {
    addSuccess('Cargo ready date and delivery estimate have been updated.');
    onSuccess();
  };

  const handleSkip = async (): Promise<IOkOrErrorResult> => {
    if (!cargoReadyDate) {
      // this should never happen according to the form validation but need a return value
      return { ok: null, error: true };
    }

    return performMutation({
      mutationFn: () => updateEstimates({ variables: { input: { lotIds, cargoReadyDate } } }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Cargo ready date has been updated.');
        onSuccess();
      }
    });
  };

  const renderCargoReadyDateForm = () => (
    <>
      <div className="text-grey-dark font-bold text-xl px-8 pb-8 pt-4">Step 1 / 2 - Update cargo ready date</div>
      <CargoReadyDateForm cargoReadyDate={estimates.cargoReadyDate} onSubmit={handleCargoReadyDateSubmit} orderDate={orderDate} />
    </>
  );

  const renderDeliveryDateForm = () => {
    const delayInDays = cargoReadyDate ? moment(cargoReadyDate.date).diff(estimates.cargoReadyDate, 'days') : 0;
    const newDate = moment(estimates?.deliveryDate).add(delayInDays, 'd').format(FORMAT_DATE_TRANSFERABLE);
    const newDeliveryDate = estimates.deliveryDate ? newDate : undefined;

    return (
      <>
        <div className="text-grey-dark font-bold text-xl px-8 py-4">Step 2 / 2 - Update delivery estimate</div>
        <DeliveryDateForm
          cargoReadyDate={cargoReadyDate?.date}
          deliveryDate={newDeliveryDate}
          onSkip={handleSkip}
          onSubmit={handleSubmit}
          onSuccess={handleSubmitSuccess}
          previousDeliveryDate={estimates.deliveryDate}
        />
      </>
    );
  };

  return (
    <Slideout onOutsideClick={onClose} overlay={true} show={isVisible}>
      <div className="flex flex-col h-full relative" style={{ width: '38rem' }}>
        <div className="flex justify-between p-8 pb-0 items-center">
          <div className="text-navy-base font-bold text-2xl">Updating PO schedule</div>
          <CloseIcon onClick={onClose} />
        </div>
        {isCargoReadyStep(step) ? renderCargoReadyDateForm() : renderDeliveryDateForm()}
      </div>
    </Slideout>
  );
};

export default LotEstimatesSlideout;
