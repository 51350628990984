import type { FC, ReactChild, ReactNode } from 'react';

import { Form, FormButtons, FormInput, FormInstance } from '@zen/Components/Form';
import DateWithTimeRangeFields from '@zen/Components/Form/DateWithTimeRangeFields';
import FormContactInput from '@zen/Contacts/FormContactInput';
import { Button } from '@zen/DesignSystem';
import { ContactTypeEnum } from '@zen/graphql/types.generated';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';
import useAccount from '@zen/utils/hooks/useAccount';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { RoadTrackingDataResponse } from '../types';
import { validationSchema } from './RoadTrackingDataForm.validation';
import type { RoadTrackingFormValues } from './types';

interface Props {
  initialValues: RoadTrackingFormValues;
  onCancel: () => void;
  onSubmit: (values: RoadTrackingFormValues) => Promise<IOkOrErrorResult>;
  onSuccess: (_: RoadTrackingDataResponse, values: RoadTrackingFormValues) => void;
}

const RoadTrackingDataForm: FC<Props> = ({ initialValues, onCancel, onSubmit, onSuccess }) => {
  const { accountUuid } = useAccount();

  const renderFormButtons = ({ isSubmitting }: FormInstance<RoadTrackingFormValues>): ReactChild => (
    <FormButtons containerClassName="mt-6" isSubmitting={isSubmitting}>
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      formName="RoadTrackingDetailsForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      scrollToError={false}
      validationSchema={validationSchema}
    >
      {({ values }: FormInstance<RoadTrackingFormValues>): ReactNode => {
        const { collectionLocation, deliveryLocation } = values;

        return (
          <div className="max-w-lg" data-testid="road-tracking-data-form">
            <NetworksContactFormInput
              accountUuid={accountUuid}
              assignmentType={AssignmentTypeValue.COLLECTION_LOCATION}
              domainName={AssignmentTargetTypeEnum.BOOKING_CARGO}
              formTitle="Add a new collection warehouse"
              isEditable={true}
              label="Origin/Collection address"
              name="collectionLocation"
            />
            <DateWithTimeRangeFields
              businessHours={collectionLocation?.businessHours}
              label="Collection confirmed"
              name="collectionConfirmedDate"
              timeZone={collectionLocation?.timeZone}
            />
            <NetworksContactFormInput
              accountUuid={accountUuid}
              assignmentType={AssignmentTypeValue.DELIVERY_LOCATION}
              domainName={AssignmentTargetTypeEnum.BOOKING_CARGO}
              formTitle="Add a new delivery warehouse"
              isEditable={true}
              label="Destination/Delivery address"
              name="deliveryLocation"
            />
            <DateWithTimeRangeFields
              businessHours={deliveryLocation?.businessHours}
              label="Delivery confirmed"
              name="deliveryConfirmedDate"
              timeZone={deliveryLocation?.timeZone}
            />
            <FormContactInput className="mr-7" contactTypes={[ContactTypeEnum.HAULIER]} label="Haulier" name="haulier" />
            <div className="flex space-x-4">
              <FormInput className="w-48" label="Vehicle plate" name="vehiclePlateNumber" placeholder="e.g. TS17UTY" />
              <FormInput className="w-48" label="Trailer ID" name="trailerId" placeholder="e.g. TS17UTY" />
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default RoadTrackingDataForm;
