import type { FC } from 'react';

import DataQualityIssue, { Link } from '@zen/Components/DataQualityIssue';
import { orderRoutes } from '@zen/Routes';

import NoIssues from '../IssueList/NoIssues';
import { PurchaseOrdersIntakePlanningIssue, PurchaseOrdersWithIssues } from '../IssueList/types';

const issueLabel = {
  [PurchaseOrdersIntakePlanningIssue.MISSING_ORDER_DATE]: 'Missing order date',
  [PurchaseOrdersIntakePlanningIssue.MISSING_REQUIRED_DELIVERY_DATE]: 'Missing required delivery date'
};

interface Props {
  ordersWithIssues: PurchaseOrdersWithIssues[];
}

const OrderIssues: FC<Props> = ({ ordersWithIssues }) => {
  if (!ordersWithIssues.length) {
    return <NoIssues />;
  }

  return (
    <div>
      {ordersWithIssues.map(({ purchaseOrder: { id, orderReferenceNumber }, relatedIssues }, index) => {
        const link: Link = { label: 'Edit order', url: orderRoutes.orderEdit.getUrl(id) };
        // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
        const issueList = relatedIssues.map((issue) => issueLabel[issue]);

        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
        return <DataQualityIssue key={index} issues={issueList} link={link} text={orderReferenceNumber} />;
      })}
    </div>
  );
};

export default OrderIssues;
