import { range } from 'lodash';
import moment, { Moment } from 'moment';
import type { FC } from 'react';

import Day from '../Day';
import { DATE_FORMAT } from '../utils';

interface Props {
  day: string; // YYYY-MM-DD
}

const Week: FC<Props> = ({ day }) => {
  const startOfWeek: Moment = moment(day, DATE_FORMAT).startOf('isoWeek');

  const renderDay = (offset: number) => {
    const dateOfDay: string = startOfWeek.clone().add(offset, 'days').format(DATE_FORMAT);

    return <Day key={offset} day={dateOfDay} />;
  };

  return <tr>{range(7).map(renderDay)}</tr>;
};

export default Week;
