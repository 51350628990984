import { FC, ReactNode, useState } from 'react';

import { Form, FormButtons, FormInput, FormInstance } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useImportTerminalsMutation } from '../graphql';
import type { UploadTerminalFormData } from './types';
import { uploadTerminalsFormValidation } from './validation';

interface Props {
  onClose?: () => void;
  onComplete: () => void;
}

const initialValues: UploadTerminalFormData = { fileURI: '' };

const UploadTerminals: FC<Props> = ({ onComplete, onClose }) => {
  const [isUploading, setUploading] = useState<boolean>(false);
  const { addError, addSuccess } = useNotification();
  const [importTerminals] = useImportTerminalsMutation();

  const handleUploadTerminals = async ({ fileURI }: UploadTerminalFormData): Promise<IOkOrErrorResult> => {
    setUploading(true);

    return performFormMutation({
      mutationFn: () =>
        importTerminals({
          variables: {
            input: {
              uri: fileURI
            }
          }
        }),
      onError: () => {
        addError('There was a problem uploading your file. Please try again');
        setUploading(false);
      }
    });
  };

  const handleSuccess = (): void => {
    setUploading(false);
    addSuccess('Terminals uploaded successfully');
    onComplete();
    onClose?.();
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<UploadTerminalFormData>): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Upload terminals">
        <Button data-testid="cancel-btn" disabled={isUploading} onClick={onClose} variant="tertiary">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={handleUploadTerminals}
      onSuccess={handleSuccess}
      validationSchema={uploadTerminalsFormValidation}
    >
      {() => <FormInput isRequired={true} label="File URI" name="fileURI" placeholder="https://www.filelocation.com" />}
    </Form>
  );
};

export default UploadTerminals;
