import cx from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';

import { SLIDEOUT_OVERLAY_ZINDEX, SLIDEOUT_ZINDEX } from '@zen/Layout';
import { HEADER_HEIGHT_IN_REM } from '@zen/Layout/constants';

export interface SlideoutProps {
  disableOverlay?: boolean;
  isTransformed?: boolean;
  onOutsideClick?: () => void;
  overlay?: boolean;
  show: boolean;
  unmountOnClose?: boolean;
}

const Slideout: FC<SlideoutProps> = (props) => {
  const {
    overlay = false,
    children,
    onOutsideClick = () => {},
    show,
    unmountOnClose = false,
    isTransformed = true,
    disableOverlay = false
  } = props;
  const [contentMounted, setContentMounted] = useState<boolean>(show);

  const unmountContent = useCallback(() => setTimeout(() => setContentMounted(false), 500), [setContentMounted]);

  useEffect(() => {
    if (!show) {
      if (unmountOnClose) {
        unmountContent();
      }

      return;
    }

    setContentMounted(true);
  }, [show, unmountContent, unmountOnClose]);

  const slideoutClassNames = cx(
    {
      'translate-x-0': isTransformed,
      'translate-x-full slideout-closed': !show,
      'slideout-opened': show
    },
    'fixed right-0 bottom-0 overflow-hidden bg-white w-auto',
    'border border-grey-lighter shadow-overlay transition-all transform duration-500',
    SLIDEOUT_ZINDEX
  );

  const overlayClassNames: string = cx(
    {
      'bg-white opacity-80': overlay,
      'opacity-0': !overlay
    },
    'fixed top-0 right-0 left-0 bottom-0',
    SLIDEOUT_OVERLAY_ZINDEX
  );

  const renderContentComponent = () => (
    <div className={slideoutClassNames} data-testid="slideout" style={{ top: `${HEADER_HEIGHT_IN_REM}rem` }}>
      {contentMounted && children}
    </div>
  );

  if (disableOverlay) {
    return renderContentComponent();
  }

  return (
    <>
      {show && <div className={overlayClassNames} onClick={onOutsideClick} />}
      {renderContentComponent()}
    </>
  );
};

export default Slideout;
