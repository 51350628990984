import type { FC } from 'react';

import { Form, FormInstance, FormSelect } from '@zen/Components/Form';
import AutoSubmit from '@zen/Components/Form/AutoSubmit';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import FormToggle from '@zen/Components/Form/FormToggle';
import type { Option } from '@zen/DesignSystem';
import { Day } from '@zen/DesignSystem';
import type { IntakeFilters } from '@zen/IntakePlanning/types';
import type { NetworksAssignableInterface } from '@zen/Networks';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { periodOptions } from '../periodOptions';

const getDestinationOptions = (list: NetworksAssignableInterface[]): Option<string>[] => {
  const destinationsList = list.map(({ id, label }) => ({
    label: label?.long || '',
    value: id || ''
  }));

  return [{ label: 'All destinations', value: '' }, ...destinationsList];
};

interface Props {
  destinations: NetworksAssignableInterface[];
  initialValues: IntakeFilters;
  onSubmit: (values: IntakeFilters) => void;
}

const FiltersForm: FC<Props> = ({ destinations, initialValues, onSubmit }) => {
  const handleSubmit = (formValues: IntakeFilters): Promise<IOkOrErrorResult> => {
    onSubmit(formValues);

    return Promise.resolve({ ok: {}, error: null });
  };

  return (
    <Form formButtons={() => null} formName="IntakePlanningFiltersForm" initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, submitForm }: FormInstance<IntakeFilters>) => (
        <div className="flex">
          <FormDatePickerInput className="mr-6" enabledDays={[Day.MONDAY]} name="startDate" />
          <FormToggle className="mr-6 period" inline={false} label="Week view range" name="period" options={periodOptions} />
          <FormSelect
            className="w-96"
            isSearchable={false}
            label="Destination"
            name="destinationId"
            options={getDestinationOptions(destinations)}
          />
          <AutoSubmit submitForm={submitForm} values={values} />
        </div>
      )}
    </Form>
  );
};

export default FiltersForm;
