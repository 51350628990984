import type { FC } from 'react';

import { FormNumberInput, FormSelect } from '@zen/Components/Form';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import FormInput from '@zen/Components/Form/FormInput';
import type { Option } from '@zen/DesignSystem';
import { cargoTypeLabelMapping } from '@zen/types';

import { ConsolidationLooseCargoTypeEnum, WeightUnitEnum } from '../../types';

const weightOptions: Option<WeightUnitEnum>[] = [
  { value: WeightUnitEnum.KG, label: 'kg' },
  { value: WeightUnitEnum.LBS, label: 'lbs' }
];

export const consolidationLooseCargoOptions: Option<ConsolidationLooseCargoTypeEnum>[] = [
  {
    label: cargoTypeLabelMapping[ConsolidationLooseCargoTypeEnum.ASSORTED_CARGO],
    value: ConsolidationLooseCargoTypeEnum.ASSORTED_CARGO
  },
  {
    label: cargoTypeLabelMapping[ConsolidationLooseCargoTypeEnum.BOXES_OR_CRATES],
    value: ConsolidationLooseCargoTypeEnum.BOXES_OR_CRATES
  },
  {
    label: cargoTypeLabelMapping[ConsolidationLooseCargoTypeEnum.PALLETS],
    value: ConsolidationLooseCargoTypeEnum.PALLETS
  }
];

const GeneralInformation: FC = () => {
  return (
    <div className="flex" data-testid="consolidation-general-information">
      <div className="flex max-w-3xl mr-10">
        <div className="flex flex-col justify-between w-2/6 mr-10">
          <FormDatePickerInput isRequired={true} name="cutOffDate" pickerPositionY="below" />
          <FormNumberInput label="CBM" name="cbm" placeholder="0" step={1} />
        </div>
        <div className="flex flex-col justify-between w-7/12 mr-10">
          <FormInput isRequired={true} name="reference" placeholder="e.g. B011812S" />
          <div className="flex">
            <FormNumberInput className="mr-4" label="Shipment weight" name="grossWeight.value" placeholder="0" step={1} />
            <FormSelect label="Unit" name="grossWeight.metric" options={weightOptions} placeholder="kg" />
          </div>
        </div>
        <div className="flex flex-col justify-between w-7/12">
          <FormInput name="transitDocumentReference" placeholder="e.g. T1 reference" />
          <div className="flex">
            <FormSelect
              className="mr-4 min-w-7/12"
              label="Loose cargo type"
              name="looseCargoType"
              options={consolidationLooseCargoOptions}
            />
            <FormNumberInput label="Quantity" name="quantity" placeholder="0" step={1} />
          </div>
        </div>
      </div>
      <div className="w-3/12">
        <FormInput isRequired={true} name="vehiclePlateNumber" placeholder="e.g. TS17UTY" />
      </div>
    </div>
  );
};

export default GeneralInformation;
