import { uniq } from 'lodash';
import getScrollParent from 'scrollparent';

import type { Optional } from '@zen/utils/typescript';

import type { TableColumn } from './types';

export type ColumnWidthMap = Record<string, TableColumn['width']>;

export const areColumnKeysUnique = <T>(columns: TableColumn<T>[]): boolean => {
  const keys: string[] = columns.map(({ key }) => key);

  return uniq(keys).length === keys.length;
};

export const buildColumnMap = <T>(columns: TableColumn<T>[]): Record<TableColumn['key'], TableColumn<T>> => {
  return columns.reduce((mapping: Record<TableColumn['key'], TableColumn<T>>, column: TableColumn<T>) => {
    mapping[column.key] = column;

    return mapping;
  }, {});
};

export const mergeColumnWidths = <T>(columns: TableColumn<T>[], savedWidths: ColumnWidthMap): ColumnWidthMap => {
  const configWidths = columns.reduce((widths: ColumnWidthMap, column: TableColumn<T>) => {
    const columnWidth: ColumnWidthMap = column.width ? { [column.key]: column.width } : {};

    return {
      ...widths,
      ...columnWidth
    };
  }, {});

  return {
    ...configWidths,
    ...savedWidths
  };
};

export const reorderColumns = ({
  columnOrder,
  targetColumnKey,
  draggedColumnKey
}: {
  columnOrder: string[];
  draggedColumnKey: string;
  targetColumnKey: string;
}): string[] => {
  const toIndex: number = columnOrder.indexOf(targetColumnKey);
  const fromIndex: number = columnOrder.indexOf(draggedColumnKey);
  const newColumnOrder: string[] = [...columnOrder];

  newColumnOrder.splice(fromIndex, 1);
  newColumnOrder.splice(toIndex, 0, draggedColumnKey);

  return newColumnOrder;
};

export const calculateDropMarkerOffset = ({
  columnOrder,
  targetColumnKey,
  draggedColumnKey,
  targetCellElement
}: {
  columnOrder: string[];
  draggedColumnKey: string;
  targetCellElement: Optional<HTMLTableCellElement>;
  targetColumnKey: string;
}): number => {
  // we want to show the drop marked either before or after the column that is being dragged over, this depends on the direction of drag
  const toIndex: number = columnOrder.indexOf(targetColumnKey);
  const fromIndex: number = columnOrder.indexOf(draggedColumnKey);
  const insertBefore: boolean = fromIndex > toIndex;
  const headerCellOffset: number = getElementLeftOffset(targetCellElement);
  const headerCellWidth: number = getElementWidth(targetCellElement);
  // since table is scrollable, we need to deduct whatever value it was scrolled to position marker properly with relation to the start of the table
  const scrollParentOffset: number = targetCellElement ? getScrollParent(targetCellElement)?.scrollLeft || 0 : 0;

  return insertBefore ? headerCellOffset - scrollParentOffset : headerCellOffset - scrollParentOffset + headerCellWidth;
};

export const getElementLeftOffset = (element: Optional<HTMLElement>): number => element?.offsetLeft || 0;

export const getElementWidth = (element: Optional<HTMLElement>): number => element?.clientWidth || 0;
