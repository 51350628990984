import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { DateTimeRangeFragmentDoc } from '../../graphql/fragments/DateTimeRange.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateCollectionDatesMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateCollectionDatesInput;
}>;

export type UpdateCollectionDatesMutationResult = { __typename?: 'Mutation' } & {
  bookingCollectionAndDeliveryUpdateCollectionDates?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateCollectionDatesPayload' } & {
      collection?: GraphQLTypes.Maybe<
        { __typename?: 'CollectionDetails' } & Pick<GraphQLTypes.CollectionDetails, 'id'> & {
            arrivedAtWarehouse?: GraphQLTypes.Maybe<
              { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
            >;
            confirmedByForwarder?: GraphQLTypes.Maybe<
              { __typename: 'LocalDateTimeRange' } & {
                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
                endDateTime?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                >;
              }
            >;
            departedFromWarehouse?: GraphQLTypes.Maybe<
              { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
            >;
            requestedWithForwarder?: GraphQLTypes.Maybe<
              { __typename: 'LocalDateTimeRange' } & {
                startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>;
                endDateTime?: GraphQLTypes.Maybe<
                  { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                >;
              }
            >;
          }
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const UpdateCollectionDatesDocument = /* #__PURE__ */ gql`
  mutation updateCollectionDates($input: UpdateCollectionDatesInput!) {
    bookingCollectionAndDeliveryUpdateCollectionDates(input: $input) {
      collection {
        arrivedAtWarehouse {
          date
          time
        }
        confirmedByForwarder {
          ...DateTimeRange
        }
        departedFromWarehouse {
          date
          time
        }
        id
        requestedWithForwarder {
          ...DateTimeRange
        }
      }
      errors {
        message
      }
    }
  }
  ${DateTimeRangeFragmentDoc}
`;

/**
 * __useUpdateCollectionDatesMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionDatesMutation, { data, loading, error }] = useUpdateCollectionDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionDatesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCollectionDatesMutationResult, UpdateCollectionDatesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateCollectionDatesMutationResult, UpdateCollectionDatesMutationVariables>(
    UpdateCollectionDatesDocument,
    options
  );
}
export type UpdateCollectionDatesMutationHookResult = ReturnType<typeof useUpdateCollectionDatesMutation>;
