import type { RoutesConfig } from './types';

export const ORDERS_PREFIX = '/dashboard/orders';

// This matcher will make sure the param is in a certain format,
// if not router won't take this url as a match.
export const uuidMatcher = '(\\w+-\\w+-\\w+-\\w+-\\w+)';

interface OrderRoutes extends RoutesConfig {
  lotAssignments: {
    getUrl: (zencargoReference: string, cargoId: string) => string;
    path: string;
  };
  lotAssignmentsToBooking: {
    getUrl: (zencargoReference: string) => string;
    path: string;
  };
  orderDetails: {
    getUrl: (orderId: string) => string;
    path: string;
  };
  orderDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
  };
  orderEdit: {
    getUrl: (orderId: string) => string;
    path: string;
  };
  orderIssues: {
    getUrl: (orderId: string) => string;
    path: string;
  };
  orderItems: {
    getUrl: (orderId: string) => string;
    path: string;
  };
  orderList: {
    getUrl: () => string;
    path: string;
  };
  orderNew: {
    getUrl: () => string;
    path: string;
  };
}

const orderRoutes: OrderRoutes = {
  orderNew: {
    path: `${ORDERS_PREFIX}/new`,
    getUrl: () => `${ORDERS_PREFIX}/new`
  },
  orderEdit: {
    path: `${ORDERS_PREFIX}/:id${uuidMatcher}/edit`,
    getUrl: (orderId: string) => `${ORDERS_PREFIX}/${orderId}/edit`
  },
  orderItems: {
    path: `${ORDERS_PREFIX}/:id${uuidMatcher}/items`,
    getUrl: (orderId: string) => `${ORDERS_PREFIX}/${orderId}/items`
  },
  orderIssues: {
    path: `${ORDERS_PREFIX}/:id${uuidMatcher}/items`,
    getUrl: (orderId: string) => `${ORDERS_PREFIX}/${orderId}/issues`
  },
  orderDetails: {
    path: `${ORDERS_PREFIX}/:id?`,
    getUrl: (orderId: string) => `${ORDERS_PREFIX}/${orderId}`
  },
  orderList: {
    path: ORDERS_PREFIX,
    getUrl: () => ORDERS_PREFIX
  },
  orderDetailsPage: {
    path: `${ORDERS_PREFIX}/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${ORDERS_PREFIX}/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  lotAssignments: {
    path: `${ORDERS_PREFIX}/assignments/:zencargoReference/:cargoId?`,
    getUrl: (zencargoReference: string, cargoId?: string) => `${ORDERS_PREFIX}/assignments/${zencargoReference}/${cargoId}`
  },
  lotAssignmentsToBooking: {
    path: `${ORDERS_PREFIX}/assignments/:zencargoReference`,
    getUrl: (zencargoReference: string) => `${ORDERS_PREFIX}/assignments/${zencargoReference}`
  }
};

export default orderRoutes;
