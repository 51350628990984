import { FC, ReactChild, ReactNode, useState } from 'react';

import VehicleForm from '@zen/Cargo/forms/VehicleForm';
import { useCreateVehicleCargoItemMutation } from '@zen/Cargo/graphql';
import { prepareVehicleCargoRequestPayload } from '@zen/Cargo/helpers';
import type { FormInstance } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { VehicleTypeEnum } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { legacyNewTypesMapping } from '../cargoDictionaryMapping.helper';
import CargoFormButtons from '../components/CargoFormButtons';
import CargoFormWrapper from '../components/CargoFormWrapper';
import CargoSelector from '../components/CargoSelector';
import type { CreateVehicleCargoItemInput } from '../types';
import {
  CargoItemTypeEnum,
  CargoManagementTrackingAction,
  CargoManagementTrackingCategory,
  VehicleCargoFormData
} from '../types';
import { commonCargoOptions, otherCargoOptions } from './helpers';

export interface Props {
  onSuccess: () => void;
  zencargoReference: string;
}

const NewVehicle: FC<Props> = ({ onSuccess, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const { trackEvent } = useTracking();
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState<Nullable<VehicleTypeEnum>>();
  const [createVehicle] = useCreateVehicleCargoItemMutation();

  const handleSubmit = async (values: VehicleCargoFormData): Promise<IOkOrErrorResult> => {
    const input: CreateVehicleCargoItemInput = {
      zencargoReference,
      ...prepareVehicleCargoRequestPayload(values)
    };

    return performMutation({
      mutationFn: () =>
        createVehicle({
          variables: {
            input
          },
          refetchQueries: ['cargoSummary', 'getTrackingData']
        }),
      onError: () => addError()
    });
  };

  const handleCargoTypeSelect = (cargoType: VehicleTypeEnum): void => {
    setSelectedVehicleType(cargoType);
  };

  const setInitialState = (): void => {
    setIsFormVisible(false);
    setSelectedVehicleType(null);
  };

  const handleSuccess = (): void => {
    addSuccess('New vehicle has been created');
    setInitialState();
    onSuccess();

    trackEvent({
      category: CargoManagementTrackingCategory,
      action: CargoManagementTrackingAction.ADD_NEW_CARGO_ITEM,
      label: CargoItemTypeEnum.VEHICLE
    });
  };

  const handleButtonClick = (): void => {
    setIsFormVisible(true);
  };

  const renderButton = (): ReactChild => {
    return (
      <div className="flex mb-4">
        <Button className="inline-block" iconLeft="zicon-add" onClick={handleButtonClick} variant="secondary">
          Add vehicle
        </Button>
      </div>
    );
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<VehicleCargoFormData>): ReactNode => {
    return <CargoFormButtons isSubmitting={isSubmitting} onCancel={setInitialState} submitButtonText="Add" />;
  };

  const renderForm = (): ReactChild => {
    if (!selectedVehicleType) {
      return (
        <CargoSelector<VehicleTypeEnum>
          commonCargoTypes={commonCargoOptions}
          onCancel={setInitialState}
          onSelect={handleCargoTypeSelect}
          otherCargoLabel="Other vehicles"
          otherCargoOptions={otherCargoOptions}
          title="Select vehicle"
        />
      );
    }

    return (
      <CargoFormWrapper>
        <VehicleForm
          formButtons={renderFormButtons}
          initialValues={{ vehicleType: legacyNewTypesMapping[selectedVehicleType] }}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
        />
      </CargoFormWrapper>
    );
  };

  return (
    <div className="mt-3" data-testid="new-vehicle">
      {isFormVisible ? renderForm() : renderButton()}
    </div>
  );
};

export default NewVehicle;
