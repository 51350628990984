import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import DateWithDefault from '@zen/Components/DateWithDefault';
import QueryHandler from '@zen/Components/QueryHandler';
import ShipmentStatus from '@zen/Components/ShipmentStatus';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import { Tooltip } from '@zen/DesignSystem';
import { formatTimeZone, noTimeZoneMessage } from '@zen/utils/dateTime';
import type { Optional, Undefinable } from '@zen/utils/typescript';

import { FlatShipmentDetailsBoard, VoyageMilestoneNameEnum } from '../../types';
import { useMilestoneEstimatesQuery } from './graphql';
import NetworksAndDeliveryEstimateContextMenu from './NetworksAndDeliveryEstimateContextMenu';
import type { MilestoneLatestEstimate, MiltestoneEstimatesResult } from './types';

interface Props {
  shipment: FlatShipmentDetailsBoard;
  zencargoReference: string;
}

const NetworksAndDeliveryEstimate: FC<Props> = ({ zencargoReference, shipment }) => {
  const { stageName, shipmentStatus, canEditDestination, canEditOrigin, canUpdateShipmentStatus } = shipment;

  const { data, loading, error, refetch } = useMilestoneEstimatesQuery({
    variables: { zencargoReference },
    fetchPolicy: 'network-only'
  });

  const { check } = useGlobalPermissions();

  const canAccessBackoffice: boolean = check('backoffice.canAccessRoute');
  const canEditOriginAndDestination: boolean = canEditOrigin && canEditDestination;

  const hasArrived: boolean = stageName === VoyageMilestoneNameEnum.DELIVERED;

  return (
    <QueryHandler
      data={data?.bookings.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={
        <div className="flex">
          <SkeletonLoading height={14} width="w-28" />
          <SkeletonLoading height={14} width="w-28" />
        </div>
      }
    >
      {(milestoneEstimatesResult: MiltestoneEstimatesResult) => {
        const milestoneEstimates: MilestoneLatestEstimate[] = milestoneEstimatesResult.milestones || [];
        const deliveryMilestone: Undefinable<MilestoneLatestEstimate> = milestoneEstimates.find(
          (milestone: MilestoneLatestEstimate) => milestone.name === VoyageMilestoneNameEnum.DELIVERED
        );
        const originalEstimate: Optional<string> = deliveryMilestone?.originalEstimate?.date;
        const latestDeliveryEstimate: Optional<string> = deliveryMilestone?.latestEstimate?.date || originalEstimate;
        const timeZone: Optional<string> =
          deliveryMilestone?.originalEstimate?.timeZone || deliveryMilestone?.latestEstimate?.timeZone;
        const { delay } = milestoneEstimatesResult;

        const tooltipContent = latestDeliveryEstimate ? formatTimeZone(latestDeliveryEstimate, timeZone) : noTimeZoneMessage;

        return (
          <div className="flex items-center">
            <Tooltip placement="top-start" tooltipContent={tooltipContent}>
              <p className="mr-3">
                Delivery est.{' '}
                <span className="font-bold" data-testid="delivery-tooltip">
                  <DateWithDefault date={latestDeliveryEstimate} />
                </span>
              </p>
            </Tooltip>
            <ShipmentStatus delay={delay} hasArrived={hasArrived} hasLatestDeliveryEstimate={!!latestDeliveryEstimate} />
            {canAccessBackoffice && (
              <div className="ml-2">
                <NetworksAndDeliveryEstimateContextMenu
                  canEditOriginAndDestination={canEditOriginAndDestination}
                  canUpdateShipmentStatus={canUpdateShipmentStatus}
                  onDateChange={refetch}
                  originalEstimate={originalEstimate}
                  shipmentStatus={shipmentStatus}
                  zencargoReference={zencargoReference}
                />
              </div>
            )}
          </div>
        );
      }}
    </QueryHandler>
  );
};

export default NetworksAndDeliveryEstimate;
