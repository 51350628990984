import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceGetAccrualTemplatesQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type FinanceGetAccrualTemplatesQueryResult = { __typename?: 'Query' } & {
  accrualTemplates?: GraphQLTypes.Maybe<
    Array<{ __typename?: 'AccrualTemplate' } & Pick<GraphQLTypes.AccrualTemplate, 'id' | 'templateName'>>
  >;
};

export const FinanceGetAccrualTemplatesDocument = /* #__PURE__ */ gql`
  query financeGetAccrualTemplates {
    accrualTemplates {
      id
      templateName
    }
  }
`;

/**
 * __useFinanceGetAccrualTemplatesQuery__
 *
 * To run a query within a React component, call `useFinanceGetAccrualTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceGetAccrualTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceGetAccrualTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinanceGetAccrualTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<FinanceGetAccrualTemplatesQueryResult, FinanceGetAccrualTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<FinanceGetAccrualTemplatesQueryResult, FinanceGetAccrualTemplatesQueryVariables>(
    FinanceGetAccrualTemplatesDocument,
    options
  );
}
export function useFinanceGetAccrualTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FinanceGetAccrualTemplatesQueryResult, FinanceGetAccrualTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<FinanceGetAccrualTemplatesQueryResult, FinanceGetAccrualTemplatesQueryVariables>(
    FinanceGetAccrualTemplatesDocument,
    options
  );
}
export type FinanceGetAccrualTemplatesQueryHookResult = ReturnType<typeof useFinanceGetAccrualTemplatesQuery>;
export type FinanceGetAccrualTemplatesLazyQueryHookResult = ReturnType<typeof useFinanceGetAccrualTemplatesLazyQuery>;
