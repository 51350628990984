import { FC, useContext } from 'react';

import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import { Checkbox } from '@zen/DesignSystem';
import type { UpdateLotsInput } from '@zen/Orders';
import { usePurchaseOrdersUpdateLotsMutation } from '@zen/Orders/graphql';
import type { OrderListViewLot } from '@zen/Orders/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import LotForm from './LotForm';
import type { Lot } from './types';

interface Props {
  lot: OrderListViewLot;
}

const LotLineItem: FC<Props> = ({ lot }) => {
  const { id, cbm, orderedLineItem, quantityFulfilled } = lot;
  const product = orderedLineItem?.product;
  const orderedLineItemId: Optional<string> = orderedLineItem?.id;
  const { addError, addSuccess } = useNotification();
  const [updateLot] = usePurchaseOrdersUpdateLotsMutation();

  const { isSelected, addItems, removeItems } = useContext(BasketContext);

  const handleChange = (): void => {
    if (isSelected(id)) {
      removeItems([id]);
    } else {
      addItems([lot]);
    }
  };

  const handleSubmit = (values: Lot): Promise<IOkOrErrorResult> => {
    const input: UpdateLotsInput = {
      orderedLineItemId: orderedLineItemId || '',
      lots: [values],
      lotIdsToDelete: []
    };

    return performMutation({
      mutationFn: () =>
        updateLot({ variables: { input }, refetchQueries: ['orderListLotsAndPurchaseOrderQuery'], awaitRefetchQueries: true }),
      onError: addError
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Lot has been updated');
  };

  return (
    <div className="flex items-center text-sm mb-4" data-testid="lot-line-item">
      <div className="w-10">
        <Checkbox checked={isSelected(id)} onChange={handleChange} />
      </div>
      <div className="w-1/2">
        <div className="text-base text-grey-dark mb-1">{product?.name}</div>
        <div className="text-grey-base leading-normal">SKU code: {product?.skuCode}</div>
      </div>
      <div className="ml-auto min-w-md mr-4" data-testid="lot-form">
        <LotForm initialValues={{ id, cbm, quantityFulfilled }} onSubmit={handleSubmit} onSuccess={handleSuccess} />
      </div>
    </div>
  );
};

export default LotLineItem;
