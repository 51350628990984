import type { FC } from 'react';

import { FormButtons } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';

interface Props {
  isSubmitting: boolean;
  onCancel: () => void;
  submitButtonText: string;
}

const CargoFormButtons: FC<Props> = ({ isSubmitting, onCancel, submitButtonText }) => {
  return (
    <FormButtons isSubmitting={isSubmitting} text={submitButtonText}>
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
    </FormButtons>
  );
};

export default CargoFormButtons;
