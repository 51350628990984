import { FC, useState } from 'react';

import { FormSelect } from '@zen/Components/Form';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import type { Option } from '@zen/DesignSystem';

import { useShipmentDelayReasonOptions } from './hooks/useShipmentDelayReasonOptions';

const FormShipmentDelayReasonSelect: FC<FormFieldProps> = (props) => {
  const [search, setSearch] = useState<string>('');
  const { data, isLoading } = useShipmentDelayReasonOptions({ search });

  const options: Option<string>[] = data.map((shipmentDelayReasonOption) => ({
    value: shipmentDelayReasonOption?.reason,
    label: shipmentDelayReasonOption?.reason
  }));

  const handleInput = (query: string = '') => {
    setSearch(query);
  };

  return <FormSelect isClearable={true} isLoading={isLoading} onInputChange={handleInput} options={options} {...props} />;
};

export default FormShipmentDelayReasonSelect;
