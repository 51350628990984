import type { StageNameEnum } from '@zen/Components/StageName/types';
import type {
  Booking as Shipment,
  CargoSummary,
  CustomsOnlyFilterEnum,
  DateRangeInput,
  IncotermsValue,
  ModeOfTransport,
  ShippingOrderState,
  Stage,
  StageValue
} from '@zen/graphql/types.generated';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { RichDate } from '../Orders/types';

export type {
  ActionItem,
  CalculatedInfo,
  CargoConsolidatedItemType,
  Delay,
  IssuesFilterInput,
  LocalDateTimeType,
  NetworksOrgLoc,
  PurchaseOrder,
  PurchaseOrderConnection,
  Booking as Shipment,
  ShipmentDataFiltersInput,
  ShipmentsFilter,
  Stage,
  WithinTimeRangeInput
} from '@zen/graphql/types.generated';
export {
  ActionItemStatus,
  BookingStageEnum,
  BookingStep,
  CustomsOnlyFilterEnum,
  IncotermsValue,
  ModeOfTransport,
  ShipmentColumnEnum,
  ShippingOrderState,
  StageValue,
  VoyageMilestoneNameEnum,
  WithinDaysLabel
} from '@zen/graphql/types.generated';

export interface ShipmentFilterOption<T = any> { //eslint-disable-line
  label: string;
  value: T;
}

export interface DateTime {
  dateTime?: string;
}

export enum BookingUpcomingEventEnum {
  ARRIVAL = 'ARRIVAL',
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  DEPARTURE = 'DEPARTURE'
}

export interface ShipmentFilters {
  consignees: string[];
  consignors: string[];
  customers: string[];
  customsOnly: CustomsOnlyFilterEnum[];
  destinationCountries: string[];
  destinations: string[];
  forwarders: string[];
  incoterms: IncotermsValue[];
  issueTitle: Nullable<string>;
  originCountries: string[];
  origins: string[];
  stages: StageValue[];
  status: Nullable<ShipmentFilterStatus>;
  transportModes: Nullable<ModeOfTransport[]>;
  vesselNames: string[];
  withinTimeDate: string;
  withinTimeDays: Optional<ShipmentFilterStageWithin>;
  withinTimeRange: DateRangeInput;
  withinTimeStage: Optional<BookingUpcomingEventEnum>;
}

export enum ShipmentFilterStatus {
  ACTIVE = 'ACTIVE',
  CONFIRMED = 'CONFIRMED',
  DELIVERED = 'DELIVERED',
  IN_TRANSIT = 'IN_TRANSIT',
  PENDING = 'PENDING'
}

export enum ShipmentFilterStageWithin {
  DATE_RANGE = 'DATE_RANGE',
  NEXT_14_DAYS = 'NEXT_14_DAYS',
  NEXT_1_DAY = 'NEXT_1_DAY',
  NEXT_21_DAYS = 'NEXT_21_DAYS',
  NEXT_28_DAYS = 'NEXT_28_DAYS',
  NEXT_3_DAYS = 'NEXT_3_DAYS',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  SPECIFIC_DATE = 'SPECIFIC_DATE',
  YESTERDAY = 'YESTERDAY'
}

export interface Carriage {
  portOfDestination: PortOfDestination;
  portOfLoad: PortOfLoad;
  vesselName: string;
}

export interface PortOfLoad {
  arrivalDate: RichDate;
  name: string;
  unlocode: string;
}

export interface PortOfDestination {
  departureDate: RichDate;
  name: string;
  unlocode: string;
}

export interface Country {
  code: string;
  name: string;
}
export interface FilterDescription {
  isMultiSelect?: boolean;
  label: string;
  name: string;
  options: ShipmentFilterOption[];
}

type CommonTypes =
  | 'cargo'
  | 'carriage'
  | 'customsOnly'
  | 'modeOfTransport'
  | 'stage'
  | 'clientReference'
  | 'zencargoReference'
  | 'actionItems'
  | 'delay'
  | 'cargo'
  | 'customer'
  | 'estimatedArrival'
  | 'estimatedDeparture'
  | 'estimatedCollection'
  | 'estimatedDelivery'
  | 'issues'
  | 'note'
  | 'pinned'
  | 'purchaseOrderReferences';

export interface FlatShipment extends Pick<Shipment, CommonTypes> {
  cargoSummary: Optional<CargoSummary>;
  consignee: string;
  consignor: string;
  delayInDays?: number;
  destination: string;
  destinationShort: string;
  forwarder: string;
  forwarderReference: string;
  incoterms: Optional<IncotermsValue>;
  origin: string;
  originShort: string;
  portOfDestination: string;
  portOfLoad: string;
  shippingOrderStatus: Nullable<ShippingOrderState>;
  stage: Nullable<Partial<Stage>>;
  stageName: Optional<StageNameEnum>;
  updatedAt: string;
  vesselName: string;
}

export interface ShipmentsListCapabilities {
  canViewCustomsOnly?: boolean;
  canViewDivision?: boolean;
  canViewForwarder?: boolean;
}
