import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetShipmentActionTypesListQueryVariables = GraphQLTypes.Exact<{
  paginated?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
}>;

export type GetShipmentActionTypesListQueryResult = { __typename?: 'Query' } & {
  shipmentActionTypes: { __typename?: 'ShipmentActionTypeConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<{ __typename?: 'ShipmentActionType' } & Pick<GraphQLTypes.ShipmentActionType, 'id' | 'name' | 'title'>>
      >
    >;
  };
};

export const GetShipmentActionTypesListDocument = /* #__PURE__ */ gql`
  query getShipmentActionTypesList($paginated: Boolean) {
    shipmentActionTypes(paginated: $paginated) {
      nodes {
        id
        name
        title
      }
    }
  }
`;

/**
 * __useGetShipmentActionTypesListQuery__
 *
 * To run a query within a React component, call `useGetShipmentActionTypesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentActionTypesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentActionTypesListQuery({
 *   variables: {
 *      paginated: // value for 'paginated'
 *   },
 * });
 */
export function useGetShipmentActionTypesListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetShipmentActionTypesListQueryResult, GetShipmentActionTypesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetShipmentActionTypesListQueryResult, GetShipmentActionTypesListQueryVariables>(
    GetShipmentActionTypesListDocument,
    options
  );
}
export function useGetShipmentActionTypesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentActionTypesListQueryResult, GetShipmentActionTypesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetShipmentActionTypesListQueryResult, GetShipmentActionTypesListQueryVariables>(
    GetShipmentActionTypesListDocument,
    options
  );
}
export type GetShipmentActionTypesListQueryHookResult = ReturnType<typeof useGetShipmentActionTypesListQuery>;
export type GetShipmentActionTypesListLazyQueryHookResult = ReturnType<typeof useGetShipmentActionTypesListLazyQuery>;
