import cx from 'classnames';
import type { ReactNode } from 'react';

import Tab from './Tab';

export interface TabConfig<T = string> {
  className?: string;
  isActive: boolean;
  label: ReactNode;
  totalCount?: number;
  value: T;
}

interface Props<T = string> {
  className?: string;
  onTabClick: (tab: T) => void;
  tabsConfig: TabConfig<T>[];
}

function Tabs<T = string>({ className, onTabClick, tabsConfig }: Props<T>) {
  const classNames = cx('flex', className);

  const calculateTotalCountLabel = (totalCount: number | undefined): string | number => {
    const totalCountNumber = Number(totalCount);

    if (!totalCountNumber) {
      return 0;
    }

    return totalCountNumber < 100 ? totalCountNumber : '99+';
  };

  const renderTab = ({ isActive, label, value, totalCount, className: tabClassName }: TabConfig<T>, i: number): ReactNode => {
    const totalCountLabel = calculateTotalCountLabel(totalCount);

    return (
      <Tab key={i} active={isActive} className={tabClassName} onClick={() => onTabClick(value)} totalCount={totalCountLabel}>
        {label}
      </Tab>
    );
  };

  return (
    <div className={classNames} data-component="tabs" data-testid="tabs">
      {tabsConfig.map(renderTab)}
    </div>
  );
}

export type { Props as TabsProps };

export default Tabs;
